import { Switch } from "@headlessui/react";
import { clsx } from "@/utils";

export interface ToggleProps {
  size?: "xs" | "sm" | "md" | "lg";
  checked: boolean;
  onChange: (checked: boolean) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export const Toggle = ({ size = "md", checked, onChange, children, className, disabled, ...props }: ToggleProps) => {
  const sizeClass = {
    "h-5 w-8": size === "xs",
    "h-6 w-10": size === "sm",
    "h-8 w-14": size === "md",
    "h-12 w-20": size === "lg",
  };

  const switchClass = {
    "h-3 w-3": size === "xs",
    "h-4 w-4": size === "sm",
    "h-6 w-6": size === "md",
    "h-10 w-10": size === "lg",
  };

  const checkedClass = {
    "translate-x-4": size === "xs" && checked,
    "translate-x-5": size === "sm" && checked,
    "translate-x-7": size === "md" && checked,
    "translate-x-9": size === "lg" && checked,
  };

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={clsx(
        "relative inline-flex items-center rounded-full",
        checked ? "bg-success" : "bg-neutral-mid-gray",
        sizeClass,
        className
      )}
      disabled={disabled}
      {...props}
    >
      <span
        className={clsx(
          "flex transform items-center justify-center rounded-full bg-white transition",
          { "translate-x-1": !checked },
          switchClass,
          checkedClass
        )}
      >
        {children}
      </span>
    </Switch>
  );
};

Toggle.displayName = "Toggle";
