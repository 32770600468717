import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "@/components/atoms";
import { DataTableRowProps } from "../types";
import { DataTableCell } from "./DataTableCell";

export const DraggableRow = <T extends object>({ row, ...props }: DataTableRowProps<T>) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id: row.id });
  const style = {
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div className="flex" ref={setNodeRef} style={style} {...props}>
      {row.getVisibleCells().map((cell) => {
        if (cell.column.id === "reorder") {
          return (
            <div key={cell.id} className="td  px-4 py-2 align-middle text-base font-medium text-neutral-black max-lg:p-2">
              <Icon name="drag" className="outline-none" isCustom {...attributes} {...listeners} />
            </div>
          );
        } else {
          return (
            <DataTableCell
              key={cell.id}
              cell={cell}
              className="px-4 py-2 align-middle text-base font-medium text-neutral-black max-lg:p-2"
            />
          );
        }
      })}
    </div>
  );
};
