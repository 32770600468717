import { useRef } from "react";
import { useInfiniteScroll } from "@/hooks";
import { useLazyGetBookingHistoryLogsQuery } from "@/redux/apis/booking/endpoints/actions";
import { pageState } from "@/redux/types";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

export const useInfiniteJobHistoryLogs = () => {
  const { id } = useSelectedBooking();
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const [getBookingHistoryLogs, { isFetching, data: logsResponse }] = useLazyGetBookingHistoryLogsQuery();

  const page = logsResponse ? logsResponse.data.page : pageState;
  const { current, count } = page;

  useInfiniteScroll({
    scroller: scrollerRef.current,
    hasMore: current < count,
    pageStart: current,
    loadMore: (page: string) => getBookingHistoryLogs({ id, page }),
    isFetching,
  });

  return {
    getBookingHistoryLogs,
    isFetching,
    ref: scrollerRef,
  };
};
