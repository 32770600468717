import { useEffect } from "react";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useLazyGetAccreditationsQuery } from "@/redux/apis/accreditation/accreditationApi";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAction } from "@/redux/slices/accreditation/accreditationSlice";
import { accreditationsPageSelector, accreditationsSelector } from "@/redux/slices/accreditation/selectors";
import { countryStatesSelector } from "@/redux/slices/resource/selectors";
import { addToast } from "@/utils";
import { AccreditationsTable } from "./tables/AccreditationsTable";
import { defaultCountrySelector } from "@/redux/slices/operator/selectors";

export const AccreditationsPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const country = useAppSelector(defaultCountrySelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const { isFetching: isFetchingStates } = useGetCountryStatesQuery(country);
  const [getAccreditations, { isFetching: isFetchingAccreditations }] = useLazyGetAccreditationsQuery();
  const items = useAppSelector(accreditationsSelector);
  const stateItems = useAppSelector(countryStatesSelector);
  const { current, count, total, size } = useAppSelector(accreditationsPageSelector);

  useEffect(() => {
    getAccreditations({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getAccreditations]);

  return (
    <div className={className} {...props}>
      {isFetchingAccreditations || isFetchingStates ? (
        <Skeleton />
      ) : (
        <>
          <Panel>
            <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
              <div className="flex-1">
                <Typography variant="h3" className="leading-8">
                  Upload your Registration Certificates
                </Typography>
                <Typography className="text-neutral-dark-gray">
                  Complete the requirements by uploading a registration certificate to confirm the states that you&apos;re authorized in.
                </Typography>
              </div>
              {items.length > 0 && items.length < stateItems.length && (
                <div className="flex items-center">
                  <Button onClick={() => dispatch(setAction("add"))} startIcon="Add" className="w-full sm:w-fit">
                    New State
                  </Button>
                </div>
              )}
            </div>
            {items.length < 1 ? (
              <EmptyState
                title="Manage Accreditations"
                description="It looks like you haven't added any accreditations yet. Begin by inviting your first accreditation to get started"
              >
                <Button onClick={() => dispatch(setAction("add"))} className="mt-4 !inline-flex" startIcon="Add">
                  New State
                </Button>
              </EmptyState>
            ) : (
              <AccreditationsTable items={items} className="mt-4" />
            )}
          </Panel>
          <Pagination
            onPageClick={handlePageClick}
            onSizeChange={handleSizeChange}
            currentPage={current}
            pageSize={size}
            totalItems={total}
            pageCount={count}
          />
        </>
      )}
    </div>
  );
};