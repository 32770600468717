import { Container } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, ResetPasswordRequestForm } from "@/components/organisms";

export const ForgotPassword = () => {
  return (
    <div className="flex flex-row min-h-screen overflow-hidden">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader action="login" />
        <Container className="flex flex-1 justify-center w-full max-w-[1040px] gap-5 py-8 md:py-[100px] px-5">
          <div className="w-full max-w-[440px]">
            <ResetPasswordRequestForm />
          </div>
        </Container>
        <GeneralFooter />
      </div>
    </div>
  );
};
