/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { BookingDriverAssignmentLog, BookingFormOptions, BookingTripLog } from "@/redux/slices/booking/types";
import { createSearchParams } from "@/utils";
import {
  transformBookingFormOptions,
  transformBookings,
  transformBookingDetails,
  transformDriverAssignmentLogs,
  transformBookingTripLog,
} from "./helpers";
import { GetBookingsParams, GetBookingsResponse, GetBookingsSummaryRawResponse, RawBookingDetails, UpdateBookingParams } from "./types";

export const updateBookingQueryData = async (
  { bookingId: id }: { bookingId: string },
  { dispatch, queryFulfilled }: { dispatch: any; queryFulfilled: any }
) => {
  try {
    const { data: rawBooking } = await queryFulfilled;
    const updatedBooking = transformBookingDetails(rawBooking);

    dispatch(
      bookingApi.util.updateQueryData("getBookingDetails", id, (draft: any) => {
        Object.assign(draft, updatedBooking);
      })
    );

    dispatch(
      bookingApi.util.updateQueryData("getBookings", {}, (draft: any) => {
        const index = draft.data?.bookings.findIndex((booking: any) => booking.id === id);
        if (index !== -1) Object.assign(draft.data?.bookings[index], rawBooking);
      })
    );
  } catch (e) {
    console.error(e);
    dispatch(bookingApi.util.invalidateTags([{ type: "Booking", id }]));
    dispatch(bookingApi.util.invalidateTags(["BookingDetails"]));
  }
};

export const bookingApi = createApi({
  reducerPath: "bookingApi",
  baseQuery: baseQueryWithProvider("job"),
  endpoints: (builder) => ({
    getBookings: builder.query<GetBookingsResponse, GetBookingsParams>({
      query: (params) => `?${createSearchParams(params).toString()}`,
      providesTags: (res) => (res ? [...res.data.bookings.map(({ id }) => ({ type: "Booking" as const, id })), "Booking"] : ["Booking"]),
      transformResponse: transformBookings,
    }),
    getNewBookings: builder.query<GetBookingsResponse, GetBookingsParams>({
      query: (params) => `?${createSearchParams(params).toString()}`,
      transformResponse: transformBookings,
    }),
    getBookingsSummary: builder.query<GetBookingsSummaryRawResponse, GetBookingsParams>({
      query: (params) => `?${createSearchParams(params).toString()}&summary=1`,
      providesTags: ["BookingSummary"],
    }),
    getBookingFormOptions: builder.query<BookingFormOptions, void>({
      query: () => "/options",
      transformResponse: transformBookingFormOptions,
    }),
    getBookingDetails: builder.query<RawBookingDetails, string>({
      query: (bookingId) => `/${bookingId}`,
      providesTags: (_, __, bookingId) => [{ type: "BookingDetails", id: bookingId }],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: rawBookingDetails } = await queryFulfilled;
        const bookingDetails = transformBookingDetails(rawBookingDetails);

        dispatch(
          bookingApi.util.updateQueryData("getBookings", {}, (draft) => {
            const index = draft.data?.bookings.findIndex((booking) => booking.id === bookingDetails.id);
            if (index !== -1) Object.assign(draft.data?.bookings[index], bookingDetails);
          })
        );
      },
    }),
    updateBooking: builder.mutation<RawBookingDetails, UpdateBookingParams>({
      query: ({ bookingId, ...data }) => ({
        url: `/${bookingId}`,
        method: "PATCH",
        body: data,
      }),
      onQueryStarted: updateBookingQueryData,
      // invalidatesTags: ["BookingSummary"],
    }),
    getDriverAssignmentLogs: builder.query<BookingDriverAssignmentLog[], string>({
      query: (bookingId) => `/${bookingId}/history/logs?event=driver`,
      transformResponse: transformDriverAssignmentLogs,
    }),
    getBookingTripLogs: builder.query<BookingTripLog, string>({
      query: (bookingId) => `/${bookingId}/trip-log`,
      providesTags: ["BookingTripLog"],
      transformResponse: transformBookingTripLog,
    }),
  }),
  tagTypes: ["Booking", "BookingDetails", "BookingSummary", "BookingTripLog"],
});

export const {
  useUpdateBookingMutation,
  useGetBookingsQuery,
  useLazyGetBookingsQuery,
  useLazyGetNewBookingsQuery,
  useGetBookingFormOptionsQuery,
  useGetBookingDetailsQuery,
  useLazyGetBookingDetailsQuery,
  useGetBookingsSummaryQuery,
  useLazyGetBookingsSummaryQuery,
  useLazyGetDriverAssignmentLogsQuery,
  useLazyGetBookingFormOptionsQuery,
  useGetBookingTripLogsQuery,
} = bookingApi;
