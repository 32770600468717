import { createSlice } from "@reduxjs/toolkit";
import { assetApi } from "@/redux/apis/config/asset/assetApi";
import { BrandingAndAcquisitionState } from "./types";
import { linksAndDocumentsApi } from "@/redux/apis/config/linksAndDocuments";

const initialState: BrandingAndAcquisitionState = {
  operatorBranding: {
    assets: [],
  },
  registration: {
    deepLinks: [],
    appLinks: [],
  }
};

export const brandingAndAcquisitionSlice = createSlice({
  name: "brandingAndAcquisition",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(assetApi.endpoints.getAssets.matchFulfilled, (state, { payload }) => {
      state.operatorBranding.assets = payload.data.assets;
    });
    builder.addMatcher(linksAndDocumentsApi.endpoints.getDeepLinks.matchFulfilled, (state, { payload }) => {
      state.registration.deepLinks = payload.data.deepLinks;
    });
    builder.addMatcher(linksAndDocumentsApi.endpoints.getAppLinks.matchFulfilled, (state, { payload }) => {
      state.registration.appLinks = payload.data.appLinks;
    });
  },
});

export default brandingAndAcquisitionSlice.reducer;
