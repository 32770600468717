import { Params, createSearchParams, useLocation, useMatches, useSearchParams } from "react-router-dom";

type RouteWithHandle = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: Record<string, string>;
};

export const usePage = () => {
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const matches = useMatches();

  const pages = (matches as RouteWithHandle[])
    .filter((match) => Boolean(match.handle?.pageName) || Boolean(match.handle?.pageKey))
    .map((match) => ({
      name: params.get(match.handle?.pageKey) || match.handle.pageName,
      to: `${match.pathname}${match.handle?.pageKey ? `?${createSearchParams(params)}` : ""}`,
      current: match.pathname === pathname,
    }));

  const depth = pages.length;

  return {
    parentTitle: pages[0]?.name,
    currentTitle: pages[depth - 1]?.name,
    pathname,
    pages,
  };
};
