import { useState } from "react";
import { Alert, Icon, ImageSelectItem, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { StatesOfOperationField } from "../forms/fields";
import { useFormContext } from "react-hook-form";
import { SignUpOperatorFormData } from "../forms/types";

export const SignUpFormOperatorStep1 = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    setValue
  } = useFormContext<SignUpOperatorFormData>();
  const [isAuthorised, setIsAuthorised] = useState<boolean>();

  return (
    <div className={clsx("relative animate-enter", className)} {...props}>
      <Typography variant="title">Are you an authorised transport provider in your state?</Typography>
      <div className="flex flex-row gap-5 mt-4">
        <ImageSelectItem 
          label="Yes" 
          icon={<Icon name="TickCircle" variant="Bold" size={48} className="text-success" />} 
          selected={isAuthorised}
          onClick={() => setIsAuthorised(true)}
          className="!w-full h-32"
        />
        <ImageSelectItem 
          label="No" 
          icon={<Icon name="CloseCircle" variant="Bold" size={48} className="text-danger" />} 
          selected={isAuthorised === false}
          onClick={() => {
            setIsAuthorised(false);
            setValue("statesOfOperation", [], { shouldDirty: true });
          }}
          className="!w-full h-32"
        />
      </div>
      {isAuthorised === false && <Alert type="warning" message="This platform is designed for authorized transport operators, please revisit this site once you are authorized" className="mt-6" />}
      {isAuthorised && <StatesOfOperationField className="mt-8" />}
    </div>
  );
};
