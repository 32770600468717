import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Divider, Icon, IconButton, SidePanel, Typography } from "@/components/atoms";
import { AutoInvoiceGenerationFilter } from "./filters";
import { defaultUninvoicedJobsFilters, uninvoicedJobsFilterFormSchema } from "./fixtures";
import { useGetUninvoicedJobsCompaniesParams } from "./hooks";
import { UninvoicedJobsFilterFormData } from "./types";

interface UninvoicedJobsFilterPanelProps {
  open: boolean;
  onClose: () => void;
}

export const UninvoicedJobsFilterPanel = ({ open, onClose }: UninvoicedJobsFilterPanelProps) => {
  const { filters, setFilters } = useGetUninvoicedJobsCompaniesParams();
  const methods = useForm<UninvoicedJobsFilterFormData>({
    defaultValues: filters,
    resolver: zodResolver(uninvoicedJobsFilterFormSchema),
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit((filters: UninvoicedJobsFilterFormData) => {
    setFilters(filters);
    onClose();
  });

  return (
    <SidePanel open={open} onClose={onClose} width={428} focused>
      <div className="flex items-center justify-between px-5 py-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="Sort" size="lg" />
          <Typography variant="title">Filter</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="relative flex h-full flex-col" onSubmit={onSubmit}>
          <div className="h-full flex-1 overflow-y-auto">
            <div className="flex flex-col gap-y-4 px-4 py-6">
              <AutoInvoiceGenerationFilter />
            </div>
          </div>
          <div>
            <Divider />
            <div className="flex flex-col gap-3 px-4 py-5">
              <Button type="submit" className="w-full text-center" size="lg">
                Filter Results
              </Button>
              <Button className="w-full text-center" size="lg" variant="secondary" onClick={() => reset(defaultUninvoicedJobsFilters)}>
                Reset Filters
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </SidePanel>
  );
};
