import { Panel, Typography, Skeleton } from "@/components/atoms";
import { Restricted } from "@/components/pages";
import { PricingStateList } from "../PricingStateList";
import { useFixedPricingContext } from "../contexts/fixedPricing";
import { useFixedPricingStateContext } from "../contexts/fixedPricingState";

export const FixedPricingStatePanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isFetching: isFetchingPricingProfiles, isRestricted } = useFixedPricingContext();
  const { isFetching: isFetchingPricingStates, pricingStates } = useFixedPricingStateContext();
  const isFetching = isFetchingPricingProfiles || isFetchingPricingStates;

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <Panel className={className} {...props}>
          <Typography variant="h3" className="leading-8">
            State Based Fixed Pricing
          </Typography>
          <Typography className="text-neutral-dark-gray">
            Select a state and customize your own predetermined pricing for every trip.
          </Typography>
          <PricingStateList pricingStates={pricingStates} actionLabel="Create Custom Pricing" />
        </Panel>
      )}
    </>
  );
};
