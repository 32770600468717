import { createSlice } from "@reduxjs/toolkit";
import { vehicleGuestApi } from "@/redux/apis";
import { VehicleGuestState } from "./types";

const initialState: VehicleGuestState = {
  vehicleModels: [],
};

export const vehicleGuestSlice = createSlice({
  name: "vehicleGuest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(vehicleGuestApi.endpoints.searchVehicleModel.matchFulfilled, (state, { payload }) => {
      state.vehicleModels = payload.models;
    });
  },
});

export default vehicleGuestSlice.reducer;
