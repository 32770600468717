import { Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useDeleteAccreditationMutation } from "@/redux/apis/accreditation/accreditationApi";
import { AccreditationItem } from "@/redux/slices/accreditation/types";
import { addToast, clsx } from "@/utils";

export interface StateRemoveProps extends React.HTMLAttributes<HTMLDivElement> {
  item: AccreditationItem;
  onClose: () => void;
}

export const StateRemove = ({ item, onClose, className, ...props }: StateRemoveProps) => {
  const [deleteAccreditation, { isLoading }] = useDeleteAccreditationMutation();

  const handleRemoveClick = () => {
    deleteAccreditation(item.id)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully deleted accreditation");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      {isLoading && <Loading />}
      <div className="max-w-lg">
        <div className="flex flex-col">
          <Typography variant="title">Remove State</Typography>
          <Typography variant="paragraph" className="mt-3">
            Are you sure you want to remove <span className="text-info">{`${item.stateName} (${item.stateAbbrev})`}</span> state from
            RideMinder?
          </Typography>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="secondary" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleRemoveClick} size="md" className="bg-danger hover:bg-danger-dark">
          Remove
        </Button>
      </div>
    </div>
  );
};
