import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const BusinessRegistrationIdNumberField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();
  const watchBusinessRegistrationType = watch("businessRegistrationType");

  return (
    <div className={clsx("flex flex-col gap-1", { hidden: !watchBusinessRegistrationType }, className)} {...props}>
      <Typography>
        {watchBusinessRegistrationType} ID Number <span className="text-danger">*</span>
      </Typography>
      <TextInput
        type="number"
        className="w-full"
        placeholder="00 000 000 000"
        maxLength={16}
        hasError={!!errors.businessRegistrationIdNumber}
        {...register("businessRegistrationIdNumber")}
      />
      <ErrorMessage errors={errors} name="businessRegistrationIdNumber" />
    </div>
  );
};
