import { Icon, Typography } from "@/components/atoms";
import { useAppDispatch } from "@/redux/hooks";
import { setAction } from "@/redux/slices/booking/bookingSlice";
import { useIsOperatorOwnJob } from "../../../hooks/useIsOperatorOwnJob";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const FeesExtras = () => {
  const dispatch = useAppDispatch();
  const {
    extras,
    fees,
    status,
    metadata: { isOffloaded },
  } = useSelectedBooking();

  const isOwnJob = useIsOperatorOwnJob();

  const handleExtrasClick = () => {
    dispatch(setAction("view-closing-extras-notes"));
  };

  const isClosed = status === "closed" || status === "complete_awaiting_review";

  return (
    <div className="relative space-y-3">
      <Icon name="EmptyWallet" variant="Bold" size="lg" className="absolute -inset-1.5 rounded-full bg-primary p-1 text-white" />
      <div className=" flex flex-col gap-1.5 max-2xl:!mt-0 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="w-[50px] shrink-0 text-neutral-black/70">
          Extras:
        </Typography>
        <div className={`grid gap-3 ${isClosed && "cursor-pointer"}`} {...(isClosed && { onClick: handleExtrasClick })}>
          {extras.length > 0 ? (
            extras.map((i, idx) => (
              <Typography key={idx} variant="small" className="flex items-center gap-2 break-all hover:text-info">
                <i className="inline-flex h-[18px] w-[18px] shrink-0 items-center justify-center self-start rounded-full bg-neutral-gray">
                  <Icon name={`extras-${i.image}`} isCustom size="xxs" />
                </i>
                {i.name}
              </Typography>
            ))
          ) : (
            <Typography variant="small">-</Typography>
          )}
        </div>
      </div>

      {(!isOffloaded || isOwnJob) && (
        <div className=" flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="w-[50px] shrink-0 text-neutral-black/70">
            Fees:
          </Typography>
          <div className="grid gap-3">
            {fees?.length ? (
              fees.map((i, idx) => (
                <Typography key={idx} variant="small" className="flex items-center gap-2 break-all">
                  <i className="inline-flex h-[18px] w-[18px] shrink-0 items-center justify-center self-start rounded-full bg-neutral-gray">
                    <Icon name={`extras-${i.image}`} isCustom size="xxs" />
                  </i>
                  <span>{i.name}</span>
                </Typography>
              ))
            ) : (
              <Typography variant="small">-</Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
