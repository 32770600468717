import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { DeleteAssetResponse, GetAssetsResponse, UploadAssetResponse, transformAssets } from "./";

export const assetApi = createApi({
  reducerPath: "assetApi",
  baseQuery: baseQueryWithProvider("assets"),
  endpoints: (builder) => ({
    getAssets: builder.query<GetAssetsResponse, void>({
      query: () => "",
      transformResponse: transformAssets,
    }),
    uploadAsset: builder.mutation<UploadAssetResponse, FormData>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
    deleteAsset: builder.mutation<DeleteAssetResponse, string>({
      query: (type) => ({
        url: `/${type}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetAssetsQuery, useUploadAssetMutation, useDeleteAssetMutation } = assetApi;
