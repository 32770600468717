import { z } from "zod";
import isValidABN from "is-valid-abn";
import isValidACN from "is-valid-acn";

export const signUpDriverFormSchema = z.object({
  firstName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  lastName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  email: z.string().nonempty("Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  contactNumber: z.string().nonempty("Field is required and can't be empty."),
  dateOfBirth: z.date({ required_error: "Field is required and can't be empty." }),
  password: z
    .object({
      password: z
        .string()
        .nonempty("Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
      confirmPassword: z
        .string()
        .nonempty("Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match. Please try again.",
      path: ["confirmPassword"],
    }),
  stateRegistered: z.string({ required_error: "Field is required and can't be empty." }),
  driverLicense: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(1, "Minimum of 1 characters.")
    .max(45, "Maximum of 45 characters."),
  vehicle: z.object({
    makeAndModel: z.object({
      id: z.string(),
      make: z.string(),
      model: z.string(),
      avatar: z.string(),
      serviceLevel: z.string(),
      type: z.string(),
    }),
    year: z.coerce.number(),
    color: z.string(),
  }).optional(),
});

export const signUpOperatorFormSchema = z.object({
  statesOfOperation: z.array(z.string()).min(1, "Minimum of 1 state."),
  firstName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  lastName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  email: z.string().nonempty("Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  contactNumber: z.string().nonempty("Field is required and can't be empty."),
  password: z
    .object({
      password: z
        .string()
        .nonempty("Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
      confirmPassword: z
        .string()
        .nonempty("Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match. Please try again.",
      path: ["confirmPassword"],
    }),
  howDidYouHearAboutUs: z.string({ required_error: "Field is required and can't be empty." }),
  businessType: z.string({ required_error: "Field is required and can't be empty." }),
  businessRegistration: z
    .object({
      businessRegistrationType: z.string({ required_error: "Field is required and can't be empty." }),
      businessRegistrationIdNumber: z.string().nonempty("Field is required and can't be empty."),
    })
    .refine((data) => !(data.businessRegistrationType === "ABN" && !isValidABN(data.businessRegistrationIdNumber)), {
      message: "Field must be a valid ABN.",
      path: ["businessRegistrationIdNumber"],
    })
    .refine((data) => !(data.businessRegistrationType === "ACN" && !isValidACN(data.businessRegistrationIdNumber)), {
      message: "Field must be a valid ACN.",
      path: ["businessRegistrationIdNumber"],
    }),
  businessOfficialName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  businessTradingName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  websiteAddress:  z.string().url("Field must be a valid URL.").max(128, "Maximum of 128 characters.").optional().or(z.literal("")),
  businessTagline: z.string().max(32, "Maximum of 32 characters.").optional(),
  businessAddress: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(128, "Maximum of 128 characters."),
  yearsInOperation: z.coerce.number().max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.").step(1, "Field must be a whole number."),
  totalAnnualTurnover: z.coerce
    .number()
    .max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.")
    .step(0.01, "Field must be a maximum of 2 decimal places."),
  numberOfAnticipatedAnnualBookings: z.coerce
    .number()
    .max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.")
    .step(1, "Field must be a whole number."),
  averageTripCost: z.coerce
    .number()
    .max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.")
    .step(0.01, "Field must be a maximum of 2 decimal places."),
  numberOfIndividualTravelers: z.coerce
    .number()
    .max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.")
    .step(1, "Field must be a whole number."),
  numberOfVehiclesYouOwnAndOperate: z.coerce
    .number()
    .max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.")
    .step(1, "Field must be a whole number."),
  numberOfInternalDrivers: z.coerce
    .number()
    .max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.")
    .step(1, "Field must be a whole number."),
  numberOfPartnerDrivers: z.coerce
    .number()
    .max(Number.MAX_SAFE_INTEGER, "Maximum of 16 characters.")
    .step(1, "Field must be a whole number."),
  officeHandlingOperations: z.string({ invalid_type_error: "Field is required. Please select one." }),
  platformGoals: z.string({ required_error: "Field is required and can't be empty." }),
  platformFit: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(255, "Maximum of 255 characters."),
});

export const signUpClientFormSchema = z.object({
  firstName: z
    .string()
    .min(1, "Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  lastName: z
    .string()
    .min(1, "Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  company: z.string().max(100, "Maximum of 100 characters.").optional(),
  jobTitle: z.string().max(100, "Maximum of 100 characters.").optional(),
  email: z.string().min(1, "Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  contactNumber: z.string().nonempty("Field is required and can't be empty."),
  password: z
    .object({
      password: z
        .string()
        .min(1, "Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
      confirmPassword: z
        .string()
        .min(1, "Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match. Please try again.",
      path: ["confirmPassword"],
    }),
  howDidYouHearAboutUs: z.string({ required_error: "Field is required and can't be empty." }).optional(),
});

const currentYear = new Date().getFullYear();

export const addYourVehicleFormSchema = z.object({
  vehicleMakeAndModel: z.object({
    id: z.string(),
    make: z.string(),
    model: z.string(),
    avatar: z.string(),
    serviceLevel: z.string(),
    type: z.string(),
  }, { required_error: "Field is required and can't be empty." }),
  vehicleYear: z.coerce.number().min(1920, `Field must be within 1920 - ${currentYear}.`).max(currentYear,`Field must be within 1920 - ${currentYear}.`).step(1, "Field must be a whole number."),
  vehicleColor: z.string({ required_error: "Field is required. Please select one." }),
});

export const signUpInviteFormSchema = z.object({
  firstName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  lastName: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(45, "Maximum of 45 characters."),
  email: z.string().nonempty("Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  contactNumber: z.string().nonempty("Field is required and can't be empty."),
  password: z
    .object({
      password: z
        .string()
        .nonempty("Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
      confirmPassword: z
        .string()
        .nonempty("Field is required and can't be empty.")
        .min(6, "Minimum of 6 characters.")
        .max(65, "Maximum of 65 characters."),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match. Please try again.",
      path: ["confirmPassword"],
    }),
});