import { format, parseISO } from "date-fns";
import { Button, DropdownMenu, Icon, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { DebitHistoryItem, DebitRecordsItem, DebitUpcomingItem } from "@/redux/slices/payment/types";
import { clsx, formatCurrency } from "@/utils";
import { CurrentPendingDebitAmountTableData, DebitHistoryTableData, DebitRecordsTableData } from "./types";

export const generateDebitHistoryColumns = () => {
  const columns: DataTableColumnDef<DebitHistoryTableData>[] = [
    {
      accessorKey: "creationDate",
      header: "Creation Date",
      id: "creation_date",
      cell: (props) => props.row.original.creationDate,
      size: 200,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      id: "amount",
      cell: (props) => props.row.original.amount,
      size: 150,
    },
    {
      accessorKey: "currency",
      header: "Currency",
      id: "currency",
      cell: (props) => props.row.original.currency,
      size: 150,
    },
    {
      accessorKey: "description",
      header: "Description",
      id: "description",
      cell: (props) => props.row.original.description,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      enableSorting: false,
      size: 100,
    },
  ];

  return columns;
};

export const generateDebitHistoryData = (
  items: DebitHistoryItem[],
  dateFormat: string,
  handleDownloadAction: (item: DebitHistoryItem, document: string) => void,
  handleViewDebitDetails: (item: DebitHistoryItem) => void
) => {
  const data = items.map(
    (i) =>
      ({
        creationDate: format(parseISO(i.creationDate), dateFormat),
        amount: formatCurrency(i.amount, 2, i.currency),
        currency: i.currency,
        description: (
          <div className="flex">
            <Tooltip content={i.description} placement="bottom">
              <Typography variant="paragraph" className="truncate">
                {i.description}
              </Typography>
            </Tooltip>
          </div>
        ),
        status: renderDebitHistoryStatus(i, dateFormat),
        action: renderDebitHistoryActions(i, handleDownloadAction, handleViewDebitDetails),
      } as DebitHistoryTableData)
  );

  return data;
};

const renderDebitHistoryStatus = (item: DebitHistoryItem, dateFormat: string) => {
  const { status, payableDate } = item;

  if (status) {
    if (status === "DRAFT") {
      return (
        <div className="flex flex-row items-center gap-x-1.5">
          <Typography variant="paragraph" className="flex flex-row items-center gap-x-2">
            {renderStatusIcon(status)} Due on {format(parseISO(payableDate), dateFormat)}
          </Typography>
          <Tooltip
            content="Estimated date when the transaction will be processed and debited from your account."
            placement="bottom"
            maxWidth={250}
          >
            <Icon name="InfoCircle" variant="Bold" size="sm" className="rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </div>
      );
    }

    return (
      <Typography variant="paragraph" className="flex flex-row items-center gap-x-2 truncate capitalize">
        {renderStatusIcon(status)} {status.replaceAll("_", " ").toLowerCase()}
      </Typography>
    );
  }
};

export const renderStatusIcon = (status: string) => {
  const iconColorClass = {
    "bg-success": status === "PAID" || status === "SUCCESSFUL",
    "bg-warning": status === "REFUNDED",
    "bg-warning-dark": status === "PARTIALLY_REFUNDED",
    "bg-danger": status === "FAILED" || status === "CANCELLED" || status === "ERROR",
    "bg-danger opacity-60": status === "CANCELLED",
    "bg-info": status === "PROCESSING" || status === "IN_TRANSIT",
  };

  return <span className={clsx("h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray", iconColorClass)} />;
};

const renderDebitHistoryActions = (
  item: DebitHistoryItem,
  handleDownloadAction: (item: DebitHistoryItem, document: string) => void,
  handleViewDebitDetails: (item: DebitHistoryItem) => void
) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => handleViewDebitDetails(item)}>
        <Icon name="Eye" className="mr-2.5" size="sm" />
        <Typography>View Debit Details</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => handleDownloadAction(item, "csv")}>
        <Icon name="ImportCurve" className="mr-2.5" size="sm" />
        <Typography>Download CSV File</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => handleDownloadAction(item, "pdf")}>
        <Icon name="ImportCurve" className="mr-2.5" size="sm" />
        <Typography>Download PDF</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export const generateDebitRecordsColumns = () => {
  const columns: DataTableColumnDef<DebitRecordsTableData>[] = [
    {
      accessorKey: "description",
      header: "Description",
      cell: (props) => props.row.original.description,
      enableSorting: false,
    },
    {
      accessorKey: "jobDate",
      header: "Job Date (if applicable)",
      id: "date",
      cell: (props) => props.row.original.jobDate,
      size: 200,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: (props) => props.row.original.amount,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "currency",
      header: "Currency",
      cell: (props) => props.row.original.currency,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "",
      cell: (props) => props.row.original.action,
      enableSorting: false,
      size: 150,
    },
  ];

  return columns;
};

export const generateDebitRecordsData = (items: DebitRecordsItem[], dateFormat: string, timeFormat: string) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        description: <Typography variant="paragraph">{i.description}</Typography>,
        jobDate: i.dateTime ? format(parseISO(i.dateTime), dateFormat) : "-",
        time: i.dateTime ? format(parseISO(i.dateTime), timeFormat) : "-",
        status: (
          <Typography variant="paragraph" className="flex flex-row items-center gap-x-2 capitalize">
            {i.status ? i.status.toLowerCase() : "-"}
          </Typography>
        ),
        amount: formatCurrency(i.amount, 2, i.currency),
        currency: i.currency,
        action: i.accountId && (
          <a href={`${phpHostUrl}/passenger/${i.accountId}/booking/view/${i.identifier}`} target="_blank" rel="noreferrer">
            <Button variant="tertiary" startIcon="Archive" size="xs" className="!text-info hover:bg-info-light">
              View Job
            </Button>
          </a>
        ),
      } as DebitRecordsTableData)
  );

  return data;
};

export const generateCurrentPendingDebitAmountColumns = () => {
  const columns: DataTableColumnDef<CurrentPendingDebitAmountTableData>[] = [
    {
      accessorKey: "job",
      header: "Job",
      cell: (props) => props.row.original.job,
      enableSorting: false,
      size: 200,
    },
    {
      accessorKey: "jobDate",
      header: "Job Date",
      cell: (props) => props.row.original.jobDate,
      enableSorting: false,
      size: 200,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: (props) => props.row.original.amount,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateCurrentPendingDebitAmountData = (items: DebitUpcomingItem[], dateFormat: string, currency: string) => {
  const data = items.map(
    (i) =>
      ({
        job: i.identifier,
        jobDate: format(parseISO(i.dateTime), dateFormat),
        status: renderCurrentPendingDebitAmountStatus(i),
        amount: formatCurrency(i.amount, 2, currency),
      } as CurrentPendingDebitAmountTableData)
  );

  return data;
};

const renderCurrentPendingDebitAmountStatus = (item: DebitUpcomingItem) => {
  const { status } = item;

  switch (status) {
    case "FAILED":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-x-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-danger" /> Failed
        </Typography>
      );
    case "ERROR":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-x-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-danger" /> Error
        </Typography>
      );
    case "PAID":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-x-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-success" /> Paid
        </Typography>
      );
    case "PROCESSING":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-x-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-info" /> Processing
        </Typography>
      );
    case "DRAFT":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-x-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> Draft
        </Typography>
      );
    case "PENDING":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-x-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> Processing
        </Typography>
      );
    default:
      if (status) {
        return (
          <Typography variant="paragraph" className="flex flex-row items-center gap-x-2 capitalize">
            <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> {status.toLowerCase()}
          </Typography>
        );
      }
  }
};
