import { useNavigate } from "react-router-dom";
import { Icon, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useAppResetApi } from "@/redux/hooks";
import { Operator } from "@/redux/slices/operator/types";
import { clsx } from "@/utils";

interface OperatorListProps extends React.HTMLAttributes<HTMLDivElement> {
  operators: Operator[];
}

export const OperatorList = ({ operators, className, ...props }: OperatorListProps) => {
  const { id: activeOperatorId } = useOperator();
  const resetApi = useAppResetApi();
  const navigate = useNavigate();

  const handleOperatorSelect = (operatorId: string) => {
    if (activeOperatorId === operatorId) navigate(-1);

    resetApi();
    navigate(`/operator/${operatorId}/bookings`);
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      <div className="pointer-events-none absolute bottom-[-1px] z-10 h-1/6 w-full bg-gradient-to-b from-transparent to-[#370a47]" />
      <div
        className={clsx(
          "mt-6 grid h-[466px] w-[320px] grid-cols-2 place-content-start justify-items-center gap-5 overflow-y-auto overflow-x-hidden sm:w-[490px] sm:grid-cols-3 p-1",
          {
            "!w-[320px] !grid-cols-2": operators.length === 2,
            "!w-[150px] !grid-cols-1": operators.length === 1,
          }
        )}
      >
        {operators.map((operator) => (
          <div
            key={operator.id}
            className={clsx(
              "relative flex h-[150px] w-[150px] cursor-pointer flex-col items-center justify-center gap-1 rounded-lg border border-[#370a47] px-3 py-6 hover:border-[#e7e3ed]",
              {
                "!bg-[#7958D2]": activeOperatorId === operator.id,
              }
            )}
            style={{ backgroundColor: operator.branding.primary === "#370A47" ? "#431A52" : operator.branding.primary }}
            onClick={() => handleOperatorSelect(operator.id)}
          >
            {activeOperatorId === operator.id && (
              <Icon name="TickCircle" variant="Bold" size="md" className="absolute left-3 top-3 text-success" />
            )}
            <div className="flex h-14 w-14 flex-1 items-center justify-center">
              <img className="max-h-14 max-w-[120px]" src={operator.images.emblem} alt={operator.name} />
            </div>
            <div className="flex h-[50px] w-[126px] items-center justify-center">
              <Typography variant="action" className="line-clamp-2 text-center">
                {operator.name}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
