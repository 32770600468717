import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { AddOnTextInput, Button, Loading, Panel, Typography, Modal, Skeleton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetWebsiteDetailsQuery, useUpdateWebsiteDetailsMutation } from "@/redux/apis/config/admin/websiteDetails";
import { useAppSelector } from "@/redux/hooks";
import { websiteDetailsSelector } from "@/redux/slices/admin/selectors";
import { WebsiteDetails } from "@/redux/slices/admin/types";
import { addToast } from "@/utils/addToast";

const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save Website Details",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

type FormData = WebsiteDetails;

export const WebsiteDetailsEdit = () => {
  const { isFetching } = useGetWebsiteDetailsQuery();
  const { details } = useAppSelector(websiteDetailsSelector);
  const [updateWebsiteDetails, { isLoading }] = useUpdateWebsiteDetailsMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields, isSubmitted },
  } = useForm<FormData>({ defaultValues: details });
  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  useEffect(() => {
    reset(details);
  }, [details, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as WebsiteDetails;
    const { brochureUrl, termsAndConditionsUrl, meetingPointsUrl, priceGuideUrl, findYourDriverUrl, googleReviewsUrl } = { ...dirtyValues };

    setActiveModal(null);
    updateWebsiteDetails({
      brochure_url: brochureUrl,
      terms_and_conditions_url: termsAndConditionsUrl,
      meeting_points_url: meetingPointsUrl,
      price_guide_url: priceGuideUrl,
      find_your_driver_url: findYourDriverUrl,
      google_reviews_url: googleReviewsUrl,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Website Details");
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Website Details
              </Typography>
              <Typography className="text-neutral-dark-gray">Update your Website details here.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
          <ConfigDetail title="Brochure Website URL" description="Your public website address.">
            <AddOnTextInput
              className="w-full lg:w-3/5"
              startAddOn="URL"
              maxLength={256}
              type="url"
              autoComplete="url"
              {...register("brochureUrl")}
            />
          </ConfigDetail>
          <ConfigDetail title="Terms & Conditions URL" description="">
            <AddOnTextInput
              className="w-full lg:w-3/5"
              startAddOn="URL"
              maxLength={256}
              type="url"
              autoComplete="url"
              {...register("termsAndConditionsUrl")}
            />
          </ConfigDetail>
          <ConfigDetail title="Meeting points URL" description="A directory of common pickup / drop-off locations.">
            <AddOnTextInput
              className="w-full lg:w-3/5"
              startAddOn="URL"
              maxLength={256}
              type="url"
              autoComplete="url"
              {...register("meetingPointsUrl")}
            />
          </ConfigDetail>
          <ConfigDetail title="Price Guide URL" description="">
            <AddOnTextInput
              className="w-full lg:w-3/5"
              startAddOn="URL"
              maxLength={256}
              type="url"
              autoComplete="url"
              {...register("priceGuideUrl")}
            />
          </ConfigDetail>
          <ConfigDetail title="Find your Driver URL" description="What to do when a passenger cannot find their driver.">
            <AddOnTextInput
              className="w-full lg:w-3/5"
              startAddOn="URL"
              maxLength={256}
              type="url"
              autoComplete="url"
              {...register("findYourDriverUrl")}
            />
          </ConfigDetail>
          <ConfigDetail title="Google Reviews URL" description="https://goo.gl/maps/XXXXXXXXXXXX">
            <AddOnTextInput
              className="w-full lg:w-3/5"
              startAddOn="URL"
              maxLength={256}
              type="url"
              autoComplete="url"
              {...register("googleReviewsUrl")}
            />
          </ConfigDetail>
        </div>
      </Panel>
    </form>
  );
};
