import { FixedNationwidePricingContextProvider, FixedPricingContextProvider, PricingProfilePanel } from "@/components/organisms";
import { Nationwide } from "./Nationwide";
import { PricingLocation } from "./PricingLocation";
import { PricingState } from "./PricingState";

export const PricingProfile = () => {
  return (
    <FixedPricingContextProvider>
      <FixedNationwidePricingContextProvider>
        <PricingProfilePanel />
      </FixedNationwidePricingContextProvider>
    </FixedPricingContextProvider>
  );
};

PricingProfile.Nationwide = Nationwide;
PricingProfile.PricingState = PricingState;
PricingProfile.PricingLocation = PricingLocation;
