import { isEmpty } from "ramda";
import { useMemo } from "react";
import { Loading, Skeleton, Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useGetPaymentHistoryQuery, useLazyGetInvoicePaymentQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInvoicingModalAction, setSelectedInvoicePayment } from "@/redux/slices/payment/paymentSlice";
import { invoicingInvoicesSelector } from "@/redux/slices/payment/selectors";
import { Invoice, InvoicePayment } from "@/redux/slices/payment/types";
import { addToast, clsx, downloadFile } from "@/utils";
import { generatePaymentHistoryColumns, generatePaymentHistoryData } from "../helpers";

interface PaymentHistoryTableProps extends React.HTMLAttributes<HTMLDivElement> {
  invoice: Invoice;
}

export const PaymentHistoryTable = ({ invoice, className, ...props }: PaymentHistoryTableProps) => {
  const dispatch = useAppDispatch();
  const { dateFormat, timeFormat } = useProfile();
  const [getInvoicePayment, { isFetching: isFetchingGetInvoicePayment }] = useLazyGetInvoicePaymentQuery();
  const { isFetching: isFetchingGetPaymentHistory } = useGetPaymentHistoryQuery({ id: invoice.id }, { refetchOnMountOrArgChange: true });
  const { paymentHistory } = useAppSelector(invoicingInvoicesSelector);

  const handleUndoPayment = (payment: InvoicePayment) => {
    dispatch(setSelectedInvoicePayment(payment));
    dispatch(setInvoicingModalAction("undoPayment"));
  };

  const handleDownloadReceipt = (payment: InvoicePayment) => {
    getInvoicePayment({ invoiceId: invoice.id, paymentId: payment.id })
      .unwrap()
      .then((res) => downloadFile(res.data.metadata.receipt, "receipt"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const data = useMemo(
    () => generatePaymentHistoryData(paymentHistory, dateFormat, timeFormat, invoice.currency, handleUndoPayment, handleDownloadReceipt),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentHistory]
  );
  const columns = useMemo(() => generatePaymentHistoryColumns(), []);

  if (isFetchingGetPaymentHistory) return <Skeleton />;

  return (
    <>
      {isEmpty(data) ? (
        <div className={clsx("flex rounded-lg p-6 ring-1 ring-neutral-mid-gray", className)}>
          <Typography variant="paragraph">
            Currently, there is no payment history available. However, as soon as transactions occur and payments are made, you&apos;ll see
            them right here.
          </Typography>
        </div>
      ) : (
        <div className={clsx("relative flex", className)}>
          {isFetchingGetInvoicePayment && <Loading className="rounded-lg" />}
          <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
        </div>
      )}
    </>
  );
};
