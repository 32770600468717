import SignupConfetti from "@/assets/images/signup/confetti.svg";
import { useAppAbility } from "@/casl";
import { Button, Icon, Panel, Tooltip, Typography } from "@/components/atoms";
import { useOperator, useSiteConfig } from "@/hooks";
import { addToast, copyToClipboard } from "@/utils";
import { useState } from "react";
import { TransportProviderCodeEditModal } from "../../TransportProviderCode";

export const SignUpSuccessOperator = () => {
  const { baseUrl } = useSiteConfig();
  const { name, providerCode } = useOperator();
  const ability = useAppAbility();
  const canEditTransportProviderCode = ability.can("manage", "providerConfigKey");
  const [step, setStep] = useState<string>();

  const handleRedirect = () => window.location.href = baseUrl;

  return (
    <div className="flex flex-col items-center text-center">
      <img src={SignupConfetti} className="h-32 w-32" />
      <Typography variant="h2" className="mt-5">Your account is ready! Log in at any time</Typography>
      <Button variant="primary" size="lg" className="w-full md:w-auto mt-7" onClick={handleRedirect}>
        Explore {name}
      </Button>
      <div className="flex flex-col md:flex-row mt-10 gap-5">
        <div>
          <Panel className="flex flex-col text-start">
            <Typography variant="title">Important Notes</Typography>
            <div className="flex flex-col mt-[22px] gap-y-6">
              <Typography variant="paragraph">1. You will receive a series of ONBOARDING EMAILS to help setup your account.</Typography>
              <Typography variant="paragraph">2. You cannot accept bookings until you add your clients and setup your banking & payment details.</Typography>
              <Typography variant="paragraph">3. By default automated offloading is disabled. Enable this feature in your settings when ready.</Typography>
            </div>
          </Panel>
        </div>
        <div>
          <Panel className="flex-1">
            <div className="flex flex-row items-center text-start gap-x-1.5">
              <Typography variant="paragraph">Here is your unique transport operator code</Typography>
              <Tooltip content="This code is generated by the system and is your unique operator identifier. You can share you code with your users (clients & drivers) so they are attached to your operation." placement="top">
                <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" />
              </Tooltip>
            </div>
            <div className="flex items-center justify-center bg-neutral-surface-gray rounded-lg w-full md:w-[344px] mt-4 py-4 px-2">
              <Typography variant="h2" className="uppercase">{providerCode}</Typography>
            </div>
            <div className="flex flex-row mt-4 gap-x-4">
              {canEditTransportProviderCode && <Button variant="secondary" size="lg" startIcon="Edit2" className="w-full" onClick={() => setStep("edit")}>Edit</Button>}
              <Button variant="secondary" size="lg" startIcon="Copy" className="w-full" onClick={() => copyToClipboard(providerCode.toUpperCase())}>Copy</Button>
            </div>
          </Panel>
        </div>
      </div>
      {canEditTransportProviderCode && (
        <TransportProviderCodeEditModal
          open={step === "edit"}
          onClose={() => setStep(undefined)}
          onSuccess={() => {
            addToast("success", "Successfully Updated Transport Provider Code");
            setTimeout(handleRedirect, 1250);
          }}
        />
      )}
    </div>
  );
};
