import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryEnableCustomOffloadField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
    resetField,
    watch,
    setFocus,
  } = useFormContext<AddVehicleCategoryFormData>();

  const resetOffloadFields = useCallback(() => {
    resetField("offloadEngineTypes");
    resetField("offloadMaxVehicleAge");
    resetField("offloadMinimumRating");
    resetField("offloadVehicleColors");
    resetField("offloadEnableSpecificModels");
    resetField("offloadVehicleModels");
  }, [resetField]);

  const isDisabled = !watch("type") || !watch("class");

  return (
    <div className={clsx("flex w-full flex-col gap-y-1", className)} {...props}>
      <Controller
        name="enableCustomOffload"
        control={control}
        render={({ field }) => (
          <div className="mt-1 flex items-center gap-4">
            <Toggle
              checked={field.value && !isDisabled}
              onChange={(checked) => {
                if (isDisabled) {
                  setFocus("class");
                  return;
                }
                field.onChange(checked);
                if (!checked) resetOffloadFields();
              }}
            />
            <Typography className="inline">{field.value ? "Enabled" : "Disabled"}</Typography>
            {isDisabled && <Typography className="inline text-danger">Please select vehicle class and type first</Typography>}
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="enableCustomOffload" />
    </div>
  );
};
