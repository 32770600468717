import { Typography } from "@/components/atoms";
import { useSiteConfig } from "@/hooks";
import { clsx } from "@/utils";

export const GeneralFooterOld = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    name: footerName,
    urls: { contact, privacy, terms },
  } = useSiteConfig();

  return (
    <footer
      className={clsx("relative bottom-0 flex w-full flex-shrink-0 flex-col items-center gap-2 p-5  md:absolute md:flex-row ", className)}
      {...props}
    >
      <div className="w-full text-center md:w-[50%] md:text-left">
        <Typography>
          © Copyright {new Date().getFullYear()} - {footerName}
        </Typography>
      </div>
      <div className="w-full md:w-[50%]">
        <ul className="flex justify-center divide-x divide-neutral-black md:justify-end">
          {contact && (
            <li>
              <a href={contact} className="inline-block px-4">
                <Typography>Contact Us</Typography>
              </a>
            </li>
          )}
          {privacy && (
            <li>
              <a href={privacy} className="inline-block px-4">
                <Typography>Privacy Policy</Typography>
              </a>
            </li>
          )}
          {terms && (
            <li>
              <a href={terms} className="inline-block px-4">
                <Typography>Terms & Conditions</Typography>
              </a>
            </li>
          )}
        </ul>
      </div>
    </footer>
  );
};