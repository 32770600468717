import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { ExternalSuppliersFormOptions } from "@/redux/slices/externalSupplier/types";
import { createSearchParams } from "@/utils";
import { transformExternalSuppliers, transformExternalSuppliersFormOptions } from "./helpers";
import { AddExternalSupplierParams, GetExternalSuppliersParams, GetExternalSuppliersResponse } from "./types";

export const externalSupplierApi = createApi({
  reducerPath: "externalSupplierApi",
  baseQuery: baseQueryWithProvider("external-supplier"),
  endpoints: (builder) => ({
    getExternalSuppliers: builder.query<GetExternalSuppliersResponse, GetExternalSuppliersParams>({
      query: (params) => `?${createSearchParams(params).toString()}`,
      providesTags: ["ExternalSuppliers"],
      transformResponse: transformExternalSuppliers,
    }),
    getExternalSuppliersFormOptions: builder.query<ExternalSuppliersFormOptions, void>({
      query: () => `/options`,
      transformResponse: transformExternalSuppliersFormOptions,
    }),
    addExternalSupplier: builder.mutation<void, AddExternalSupplierParams>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ExternalSuppliers"],
    }),
  }),
  tagTypes: ["ExternalSuppliers"],
});

export const { useGetExternalSuppliersQuery, useGetExternalSuppliersFormOptionsQuery, useAddExternalSupplierMutation } =
  externalSupplierApi;
