import { useState } from "react";
import { Card, DropdownSelect, IconButton, Typography, SidePanel } from "@/components/atoms";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { ViewTripDriverActivity } from "./components/ViewTripDriverActivity";
import { closeJobTypes } from "./fixtures";
import { CloseNoExtras } from "./forms/CloseNoExtras";
import { CloseNoShow } from "./forms/CloseNoShow";
import { CloseWithExtras } from "./forms/CloseWithExtras";

interface CloseJobCompletedProps {
  open: boolean;
  onClose: () => void;
}

export const CloseJobCompleted = ({ open, onClose }: CloseJobCompletedProps) => {
  const booking = useSelectedBooking();
  const [closeJobType, setCloseJobType] = useState(booking.closeMeta?.reason || "completed_no_extras");

  const renderForm = () => {
    switch (closeJobType) {
      case "completed_no_extras":
        return <CloseNoExtras booking={booking} onClose={onClose} />;
      case "completed_no_show":
        return <CloseNoShow booking={booking} onClose={onClose} />;
      case "completed_with_extras":
        return <CloseWithExtras booking={booking} onClose={onClose} />;
      case "completed_no_show_with_extras":
        return <CloseWithExtras booking={booking} onClose={onClose} noShow />;
      default:
        return null;
    }
  };

  const showExtrasWarning = closeJobType?.includes("with_extras") && !booking.metadata.isDriverExtrasAllowed;

  return (
    <SidePanel open={open} onClose={onClose} width={550} focused childContent={<ViewTripDriverActivity open={open} />}>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <i className="h-[10px] w-[10px] rounded-full bg-primary"></i>
          <Typography variant="title">Close Job ({booking.id})</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="flex min-h-full flex-col p-5">
        <Card>
          <Typography className="mb-1 flex place-items-center gap-2">Please select one of the following options</Typography>
          <DropdownSelect options={closeJobTypes} value={closeJobType} onChange={setCloseJobType} />
          {showExtrasWarning && (
            <Typography variant="small" className="mt-2 leading-loose text-danger">
              This job was created with driver extras disabled - as per the company configuration. Please exercise caution in adding any
              unexpected extras
            </Typography>
          )}
        </Card>

        {renderForm()}
      </section>
    </SidePanel>
  );
};
