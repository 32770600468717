import { useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { usePagination, useProfile, useSorting } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  setCreditCardPaymentsModalAction,
  setCreditCardPaymentsSidePanelAction,
  setSelectedCreditCardPaymentsItem,
} from "@/redux/slices/payment/paymentSlice";
import { creditCardPaymentsSelector } from "@/redux/slices/payment/selectors";
import { CreditCardPaymentsItem } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generateCreditCardPaymentsColumns, generateCreditCardPaymentsData } from "./helpers";

interface CreditCardPaymentsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: CreditCardPaymentsItem[];
  status: string;
}

export const CreditCardPaymentsTable = ({ items, status, className, ...props }: CreditCardPaymentsTableProps) => {
  const dispatch = useAppDispatch();
  const { dateFormat, timeFormat } = useProfile();
  const { sorting, setSorting } = useSorting();

  const handleViewPaymentLog = (item: CreditCardPaymentsItem) => {
    dispatch(setSelectedCreditCardPaymentsItem(item));
    dispatch(setCreditCardPaymentsSidePanelAction("paymentLogs"));
  };

  const handleTriggerPayment = (item: CreditCardPaymentsItem) => {
    dispatch(setSelectedCreditCardPaymentsItem(item));
    dispatch(setCreditCardPaymentsModalAction("triggerPayment"));
  };

  const handleTakeManualPayment = (item: CreditCardPaymentsItem) => {
    dispatch(setSelectedCreditCardPaymentsItem(item));
    dispatch(setCreditCardPaymentsSidePanelAction("takeManualPayment"));
  };

  const data = useMemo(
    () =>
      generateCreditCardPaymentsData(
        items,
        status,
        dateFormat,
        timeFormat,
        handleViewPaymentLog,
        handleTriggerPayment,
        handleTakeManualPayment
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateCreditCardPaymentsColumns(status), [status]);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} sorting={sorting} setSorting={setSorting} {...props} />
    </div>
  );
};

const CreditCardPaymentsTablePagination = () => {
  const {
    page: { current, count, total, size },
  } = useAppSelector(creditCardPaymentsSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

CreditCardPaymentsTable.Pagination = CreditCardPaymentsTablePagination;
