import { z } from "zod";

const defaultFormSchema = z.object({
  phoneDriverHotline: z.string(),
  emailDriverHotline: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
  enabledBccAllEmails: z.boolean(),
  emailAddressBccAll: z.string(),
  enabledBookingNotifications: z.boolean(),
  emailBookingNotifications: z.string(),
  enabledDriverNotifications: z.boolean(),
  emailDriverNotifications: z.string(),
  enabledLowJobRatingNotifications: z.boolean(),
  emailLowJobNotifications: z.string(),
  enabledMemberRegistrationNotifications: z.boolean(),
  emailMemberRegistrationNotifications: z.string(),
  enabledFailedPaymentNotifications: z.boolean(),
  emailFailedPaymentNotifications: z.string(),
});

const enabledBccAllEmailsSchema = z.object({
  enabledBccAllEmails: z.literal<boolean>(true),
  emailAddressBccAll: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
});
const disabledBccAllEmailsSchema = z.object({
  enabledBccAllEmails: z.literal<boolean>(false),
  emailAddressBccAll: z.string().optional(),
});
const bccAllEmailsSchemaUnion = z.discriminatedUnion("enabledBccAllEmails", [enabledBccAllEmailsSchema, disabledBccAllEmailsSchema]);

const enabledBookingNotificationsSchema = z.object({
  enabledBookingNotifications: z.literal<boolean>(true),
  emailBookingNotifications: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
});
const disabledBookingNotificationsSchema = z.object({
  enabledBookingNotifications: z.literal<boolean>(false),
  emailBookingNotifications: z.string().optional(),
});
const bookingNotificationsSchemaUnion = z.discriminatedUnion("enabledBookingNotifications", [
  enabledBookingNotificationsSchema,
  disabledBookingNotificationsSchema,
]);

const enabledDriverNotificationsSchema = z.object({
  enabledDriverNotifications: z.literal<boolean>(true),
  emailDriverNotifications: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
});
const disabledDriverNotificationsSchema = z.object({
  enabledDriverNotifications: z.literal<boolean>(false),
  emailDriverNotifications: z.string().optional(),
});
const driverNotificationsSchemaUnion = z.discriminatedUnion("enabledDriverNotifications", [
  enabledDriverNotificationsSchema,
  disabledDriverNotificationsSchema,
]);

const enabledLowJobRatingNotificationsSchema = z.object({
  enabledLowJobRatingNotifications: z.literal<boolean>(true),
  emailLowJobNotifications: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
});
const disabledLowJobRatingNotificationsSchema = z.object({
  enabledLowJobRatingNotifications: z.literal<boolean>(false),
  emailLowJobNotifications: z.string().optional(),
});
const lowJobRatingNotificationsSchemaUnion = z.discriminatedUnion("enabledLowJobRatingNotifications", [
  enabledLowJobRatingNotificationsSchema,
  disabledLowJobRatingNotificationsSchema,
]);

const enabledMemberRegistrationNotificationsSchema = z.object({
  enabledMemberRegistrationNotifications: z.literal<boolean>(true),
  emailMemberRegistrationNotifications: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
});
const disabledMemberRegistrationNotificationsSchema = z.object({
  enabledMemberRegistrationNotifications: z.literal<boolean>(false),
  emailMemberRegistrationNotifications: z.string().optional(),
});
const memberRegistrationNotificationsSchemaUnion = z.discriminatedUnion("enabledMemberRegistrationNotifications", [
  enabledMemberRegistrationNotificationsSchema,
  disabledMemberRegistrationNotificationsSchema,
]);

const enabledQuoteNotificationsSchema = z.object({
  enabledQuoteNotifications: z.literal<boolean>(true),
  emailQuoteNotifications: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
});
const disabledQuoteNotificationsSchema = z.object({
  enabledQuoteNotifications: z.literal<boolean>(false),
  emailQuoteNotifications: z.string().optional(),
});
const quoteNotificationsSchemaUnion = z.discriminatedUnion("enabledQuoteNotifications", [
  enabledQuoteNotificationsSchema,
  disabledQuoteNotificationsSchema,
]);

const enabledFailedPaymentNotificationsSchema = z.object({
  enabledFailedPaymentNotifications: z.literal<boolean>(true),
  emailFailedPaymentNotifications: z.string().min(1, "Field is required and can't be empty.").email("Email address is not valid"),
});
const disabledFailedPaymentNotificationsSchema = z.object({
  enabledFailedPaymentNotifications: z.literal<boolean>(false),
  emailFailedPaymentNotifications: z.string().optional(),
});
const failedPaymentNotificationsSchemaUnion = z.discriminatedUnion("enabledFailedPaymentNotifications", [
  enabledFailedPaymentNotificationsSchema,
  disabledFailedPaymentNotificationsSchema,
]);

export const formSchema = defaultFormSchema
  .and(bccAllEmailsSchemaUnion)
  .and(bookingNotificationsSchemaUnion)
  .and(driverNotificationsSchemaUnion)
  .and(lowJobRatingNotificationsSchemaUnion)
  .and(memberRegistrationNotificationsSchemaUnion)
  .and(quoteNotificationsSchemaUnion)
  .and(failedPaymentNotificationsSchemaUnion);

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
};
