import { useMemo } from "react";
import { DataTable, EmptyState } from "@/components/molecules";
import { useSorting } from "@/hooks";
import { VehicleCategory } from "@/redux/slices/vehicle/types";
import { generateAssociatedCategoriesColumns, generateAssociatedCategoriesTableData } from "./helpers";

export interface AssociatedCategoriesTableProps {
  items: VehicleCategory[];
  checkedIds: string[];
  onCheckAllChanged: (checked: boolean) => void;
  onCheckChanged: (vehicleId: string) => void;
}

export const AssociatedCategoriesTable = ({ items, checkedIds, onCheckChanged, onCheckAllChanged }: AssociatedCategoriesTableProps) => {
  const { sorting, setSorting } = useSorting();
  const allChecked = checkedIds.length > 0 && checkedIds.length === items.length;
  const columns = useMemo(() => generateAssociatedCategoriesColumns(onCheckAllChanged, allChecked), [allChecked, onCheckAllChanged]);
  const data = useMemo(() => generateAssociatedCategoriesTableData(items, checkedIds, onCheckChanged), [checkedIds, items, onCheckChanged]);
  return (
    <DataTable
      className="max-h-[400px] w-full overflow-auto [&_table]:table-auto [&_tr]:bg-white"
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      emptyState={<EmptyState title="No Vehicle Category Found" description="Vehicle categories will show here" />}
    />
  );
};
