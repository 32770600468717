import Slider, { SliderProps } from "rc-slider";
import "rc-slider/assets/index.css";
import "./style.css";

export interface RangeSliderProps extends SliderProps {
  value?: number;
  onChange?: (value: number | number[]) => void;
  min: number;
  max: number;
  className?: string;
}

export const RangeSlider = (rangeSliderProps: RangeSliderProps) => {
  const { value, onChange, min, max, className, ...props } = rangeSliderProps;

  return <Slider className={className} min={min} max={max} value={value} onChange={onChange} {...props} />;
};
