import { forwardRef } from "react";
import { Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface FilterNavButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  onClick: () => void;
  isActive: boolean;
  children: React.ReactNode;
}
export const FilterNavButton = ({ onClick, isActive, children, ...props }: FilterNavButtonProps) => {
  return (
    <FilterButton
      onClick={onClick}
      iconPosition="right"
      customIcon={isActive ? <Icon isCustom name="check" size="sm" className="text-success" /> : undefined}
      {...props}
    >
      {children}
    </FilterButton>
  );
};

export const FilterButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & {
    icon?: string;
    iconPosition?: "left" | "right";
    customIcon?: JSX.Element;
  }
>(({ icon, customIcon, iconPosition = "left", className, children, disabled, ...props }, ref) => {
  const classes = clsx(
    "flex items-center gap-3 px-4 py-3 hover:bg-primary-light",
    {
      "opacity-50 cursor-not-allowed": disabled,
      "flex-row-reverse": iconPosition === "right",
    },
    className
  );

  return (
    <button className={classes} disabled={disabled} {...props} ref={ref}>
      {customIcon ? customIcon : icon ? <Icon name={icon} size="sm" /> : null}
      <div className="line-clamp-1 flex-1 overflow-ellipsis">
        <Typography className="text-left">{children}</Typography>
      </div>
    </button>
  );
});

FilterButton.displayName = "FilterButton";
