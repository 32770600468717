import { Notification } from "@/redux/slices/notification/types";
import { GetNotificationsRawResponse, GetNotificationsResponse } from "./types";

export const transformNotifications = (res: GetNotificationsRawResponse) => {
  const {
    _embedded: { notification },
    page,
    page_count,
    page_size,
    total_items,
  } = res;
  let newNotifications: Notification[] = [];
  let earlierNotifications: Notification[] = [];

  notification.forEach((n) => {
    const { uuid, category, action, subject, message, created, read } = n;
    const value = {
      id: uuid,
      category,
      action,
      subject,
      message,
      created,
      read,
    } as Notification;
    read ? (earlierNotifications = [...earlierNotifications, value]) : (newNotifications = [...newNotifications, value]);
  });

  const response: GetNotificationsResponse = {
    data: {
      newNotifications,
      earlierNotifications,
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: res._links,
  };

  return response;
};
