import { useMemo } from "react";
import { DataTable, Pagination, NoSearchResults } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { offloadDriversPageSelector } from "@/redux/slices/driver/selectors";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { generateOffloadDriversData } from "../helpers";
import { OffloadDriversStep } from "../types";
import { offloadDriversColumns } from "../fixtures";
import { OffloadDriversEmptyState } from "../contents/OffloadDriversEmptyState";
import { useGetOffloadDriversParams } from "../hooks/useGetOffloadDriversParams";

interface OffloadDriversTableProps {
  items: OffloadDriver[];
  setDriver: (driver?: OffloadDriver) => void;
  setStep: (step: OffloadDriversStep) => void;
}

export const OffloadDriversTable = ({ items, setDriver, setStep }: OffloadDriversTableProps) => {
  const { sorting, setSorting, search, filters } = useGetOffloadDriversParams();
  const isFiltersEmpty = Object.values(filters).every(el => el === undefined);

  const onBlockDriver = (driver: OffloadDriver) => {
    setDriver(driver);
    setStep("block");
  };

  const renderEmptyState = () => {
    if (search) return <NoSearchResults />;
    if (!isFiltersEmpty) return <OffloadDriversEmptyState hasFilters />;

    return <OffloadDriversEmptyState />;
  };

  const data = useMemo(
    () => generateOffloadDriversData(items, onBlockDriver),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  return (
    <div className="relative">
      <DataTable
        className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
        data={data}
        columns={offloadDriversColumns}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={renderEmptyState()} 
      />
    </div>
  );
};

const OffloadDriversTablePagination = () => {
  const { current, size, total, count } = useAppSelector(offloadDriversPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("offload");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

OffloadDriversTable.Pagination = OffloadDriversTablePagination;