import { Icon, Panel, Typography, Skeleton, URLDetail, Button } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader, QRCodeGuide } from "@/components/organisms";
import { useOperator } from "@/hooks";
import { useGetAppLinksQuery, useGetDeepLinksQuery } from "@/redux/apis/config/linksAndDocuments";
import { useAppSelector } from "@/redux/hooks";
import { registrationSelector } from "@/redux/slices/brandingAndAcquisition/selectors";
import { copyToClipboard } from "@/utils";
import { SendToPrinter } from "../SendToPrinter/SendToPrinter";

export const ClientTools = () => {
  const { providerCode } = useOperator();
  const { isFetching: isFetchingDeepLinks } = useGetDeepLinksQuery();
  const { isFetching: isFetchingAppLinks } = useGetAppLinksQuery();
  const isFetching = isFetchingDeepLinks || isFetchingAppLinks;

  const { deepLinks } = useAppSelector(registrationSelector);
  const registration = deepLinks.find(link => link.document.slug === "client-registration");

  const renderLink = () => {
    const link = registration?.document.link;

    if (link) {
      return (
        <div className="flex flex-col lg:items-center lg:flex-row gap-4">
          <div className="flex flex-col">
            <URLDetail href={link} />
            <div className="flex flex-row items-center ml-9 gap-x-2">
              <Typography variant="paragraph" className="text-neutral-dark-gray">Transport Provider Code:</Typography>
              <Typography variant="action" className="text-neutral-black uppercase">{providerCode}</Typography>
            </div>
          </div>
          <Button
            variant="tertiary"
            startIcon="Copy"
            className="!text-info hover:bg-info-light"
            size="sm"
            onClick={() => copyToClipboard(`${link}\nTransport Provider Code: ${providerCode.toUpperCase()}`)}
          >
            Copy
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <PageHeader.Actions />
      <div className="flex flex-col gap-y-5">
        {isFetching ? <Skeleton /> : (
          <Panel>
            <div className="flex flex-1 flex-col gap-y-1">
              <Typography variant="h3">Client Tools</Typography>
              <Typography variant="paragraph" className="text-neutral-dark-gray">QR Code, Links and tools to help you register your clients</Typography>
            </div>
            <ConfigDetail 
              title="Client Registration Link"
              titleEndIcon={(
                <div className="flex flex-row items-center gap-x-1">
                  <Icon name="Global" variant="Bold" size="sm" />
                  <Icon isCustom name="app-google-playstore" size="sm" />
                  <Icon isCustom name="app-ios" size="sm" />
                </div>
              )}
              description="One dynamic link for all devices: web, Android, and iOS."
            >
              {renderLink()}
            </ConfigDetail>
          </Panel>
        )}
        <QRCodeGuide />
      </div>
    </>
  );
};

ClientTools.SendToPrinter = SendToPrinter;