import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import z from "zod";
import { Alert, Button, Icon, TextInput, Typography, Modal, Loading } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateTransportProviderCodeMutation } from "@/redux/apis/config/system";
import { clsx } from "@/utils";
import { useOperator } from "@/hooks";

type FormData = {
  providerCode: string;
};

const schema = z.object({
  providerCode: z
    .string()
    .min(3)
    .max(32)
    .regex(/^[a-zA-Z0-9\-_]+$/, { message: "Transport Provider Code cannot contain any special characters." }),
});

interface TransportProviderCodeEditModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const TransportProviderCodeEditModal = ({ open, onClose, onSuccess }: TransportProviderCodeEditModalProps) => {
  const operator = useOperator();
  const [updateTransportProviderCode, { isLoading }] = useUpdateTransportProviderCodeMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      providerCode: operator.providerCode.toUpperCase(),
    },
  });

  useMemo(() => {
    const providerCodeErrorMessage = errors.providerCode?.message;
    if (providerCodeErrorMessage) setErrorMessage(providerCodeErrorMessage);
  }, [errors]);

  const onSubmit = handleSubmit((data) => {
    if (isLoading) return;
    setErrorMessage("");

    updateTransportProviderCode(data.providerCode)
      .unwrap()
      .then(onSuccess)
      .catch((e) => getErrorMessages(e).forEach((m) => setErrorMessage(m)));
  });

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Edit Transport Provider Code
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );

  return (
    <Modal open={open} onClose={onClose} className="w-[85vw] md:w-[550px] lg:w-[550px]">
      {renderHeader()}
      <div>
        <div
          className={clsx(
            "mt-6 flex flex-row gap-2.5 rounded-lg px-5 pb-3.5 pt-5",
            errorMessage ? "bg-neutral-gray text-neutral-dark-gray" : "bg-danger-light text-danger"
          )}
        >
          <Icon name="Danger" variant="Bold" />
          <div className="flex flex-1 flex-col gap-2.5">
            <Typography variant="title">Please Read</Typography>
            <Typography variant="paragraph">
              Changing your transport provider code will invalidate any previous links or QR codes you may have used. Passengers and Drivers
              will need to use the new code only to register and your old code will become invalid. Please make sure you use this feature
              carefully as it is non reversable.
            </Typography>
          </div>
        </div>
        <form className="mt-4 flex flex-col gap-6" onSubmit={onSubmit}>
          <TextInput type="text" maxLength={32} {...register("providerCode")} />
          <Button type="submit" size="lg" disabled={isLoading} className="relative">
            {isLoading && <Loading className="!bg-transparent" />}
            Update
          </Button>
          {errorMessage && <Alert type="danger" message={errorMessage} />}
        </form>
      </div>
    </Modal>
  );
};
