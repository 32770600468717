import { useEffect } from "react";
import { Divider, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetManualPaymentDetailsMutation } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { creditCardPaymentsTakeManualPaymentSelector } from "@/redux/slices/payment/selectors";
import { CreditCardPaymentsItem, PaymentMethodDetails } from "@/redux/slices/payment/types";
import { addToast, clsx, formatCurrency } from "@/utils";

interface ManualPaymentDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  payment: CreditCardPaymentsItem;
  paymentMethodDetails: PaymentMethodDetails;
}

export const ManualPaymentDetails = ({ payment, paymentMethodDetails, className, ...props }: ManualPaymentDetailsProps) => {
  const { id, currency } = payment;
  const [getManualPaymentDetails, { isLoading }] = useGetManualPaymentDetailsMutation();
  const { manualPaymentDetails } = useAppSelector(creditCardPaymentsTakeManualPaymentSelector);

  useEffect(() => {
    getManualPaymentDetails({ id, paymentMethodId: paymentMethodDetails.id })
      .unwrap()
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  }, [id, paymentMethodDetails, getManualPaymentDetails]);

  return (
    <div className={clsx("relative", className)} {...props}>
      {isLoading && <Loading />}
      <div className="mb-[18px] flex flex-row items-center text-neutral-dark-gray">
        <Typography variant="action" className="flex-1">
          Item
        </Typography>
        <Typography variant="action" className="flex">
          Amount
        </Typography>
      </div>
      <div className="mb-4 flex flex-col">
        <div className="flex flex-row">
          <Typography variant="paragraph" className="flex">
            Total before GST:
          </Typography>
          <div className="mb-[4px] flex-1 border-b-[1px] border-dashed border-neutral-dark-gray" />
          {manualPaymentDetails && (
            <Typography variant="paragraph" className="flex">{`${currency} ${formatCurrency(
              manualPaymentDetails.amounts.subTotal,
              2,
              currency
            )}`}</Typography>
          )}
        </div>
        <div className="flex flex-row">
          <Typography variant="paragraph" className="flex">
            Credit Card Fee:
          </Typography>
          <div className="mb-[4px] flex-1 border-b-[1px] border-dashed border-neutral-dark-gray" />
          {manualPaymentDetails && (
            <Typography variant="paragraph" className="flex">{`${currency} ${formatCurrency(
              manualPaymentDetails.amounts.fees,
              2,
              currency
            )}`}</Typography>
          )}
        </div>
      </div>
      <Divider className="mb-5" />
      <div className="mb-[18px] flex flex-row items-center">
        <Typography variant="paragraph" className="flex-1">
          Total Amount
        </Typography>
        {manualPaymentDetails && (
          <Typography variant="h2" className="flex">{`${currency} ${formatCurrency(
            manualPaymentDetails.amounts.total,
            2,
            currency
          )}`}</Typography>
        )}
      </div>
    </div>
  );
};
