import { useState } from "react";
import { ErrorPanel, Skeleton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetVehiclesQuery } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { ArchivedVehiclesTable } from "./ArchivedVehiclesTable";
import { useGetArchivedVehiclesParams } from "./hooks/useGetArchivedVehiclesParams";
import { ReactivateVehicleModal } from "./modals/ReactivateVehicleModal";

export const ArchivedVehiclesPanel = () => {
  const { params } = useGetArchivedVehiclesParams();
  const {
    isFetching,
    data: response,
    error,
  } = useGetVehiclesQuery(params, {
    refetchOnMountOrArgChange: true,
  });
  const [pendingReactivate, setPendingReactivate] = useState<Vehicle | undefined>();

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  if (response && response.vehicles.length > 0)
    return (
      <>
        <ArchivedVehiclesTable items={response.vehicles ?? []} setPendingReactivate={setPendingReactivate} />
        <ArchivedVehiclesTable.Pagination page={response.page} />
        <ReactivateVehicleModal pendingReactivate={pendingReactivate} setPendingReactivate={setPendingReactivate} />
      </>
    );

  return <EmptyState title="Vehicle Manager" description="There are no vehicles matching your filter criteria at the moment." />;
};
