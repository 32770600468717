import { Button, Loading, Typography } from "@/components/atoms";
import { useLazyGetGoogleTagManagerConfigurationQuery } from "@/redux/apis/addOn/addOnApi";
import { GoogleTagManagerConfiguration } from "@/redux/slices/addOn/types";
import { useEffect } from "react";

interface GoogleTagManagerDetailsProps {
  onEdit: () => void;
  configuration?: GoogleTagManagerConfiguration;
}

export const GoogleTagManagerDetails = ({ onEdit, configuration }: GoogleTagManagerDetailsProps) => {
  const [getGoogleTagManagerConfiguration, { isFetching }] = useLazyGetGoogleTagManagerConfigurationQuery();

  useEffect(() => {
    getGoogleTagManagerConfiguration();
  }, [getGoogleTagManagerConfiguration]);

  return (
    <div className="relative flex flex-col w-full bg-neutral-surface-gray rounded-lg p-5 my-4">
      {isFetching && <Loading className="rounded-lg" />}
      <div className="flex flex-col sm:flex-row sm:items-center gap-2">
        <div className="flex flex-1 flex-col">
          <Typography variant="action">GTM Property ID</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">Set your property ID</Typography>
        </div>
        <Typography variant="paragraph">{configuration?.property}</Typography>
      </div>
      <div className="flex flex-row w-full items-center justify-end mt-8">
        <Button variant="primary" size="sm" onClick={onEdit}>Edit Settings</Button>
      </div>
    </div>
  );
};
