import { Button, ErrorMessage, Loading, TextInput, Typography } from "@/components/atoms";
import { GoogleTagManagerConfiguration } from "@/redux/slices/addOn/types";
import { useFormContext } from "react-hook-form";

interface GoogleTagManagerEditProps {
  onSubmit: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

export const GoogleTagManagerEdit = ({ onSubmit, onClose, isLoading }: GoogleTagManagerEditProps) => {
  const {
    formState: { isDirty, errors },
    register,
  } = useFormContext<GoogleTagManagerConfiguration>();

  const renderButtons = () => (
    <div className="flex flex-row w-full items-center justify-end gap-x-4 mt-8">
      <Button variant="secondary" size="sm" onClick={onClose}>Cancel</Button>
      <Button variant="primary" size="sm" type="submit" disabled={!isDirty || isLoading}>Save Settings</Button>
    </div>
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="relative flex flex-col w-full bg-neutral-surface-gray rounded-lg p-5 my-4 border border-primary pointer-events-auto">
        {isLoading && <Loading className="rounded-lg" />}
        <div className="flex flex-col sm:flex-row sm:items-center gap-2">
          <div className="flex flex-1 flex-col">
            <Typography variant="action">GTM Property ID</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray">Set your property ID</Typography>
          </div>
          <TextInput className="w-full sm:w-60" placeholder="GTM-XXXXXXXX" maxLength={12} hasError={!!errors.property} {...register("property")} />
        </div>
        <ErrorMessage errors={errors} name="property" className="self-start sm:self-end" />
        {renderButtons()}
      </div>
    </form>
  );
};
