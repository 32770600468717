import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRemoveNetworkMutation } from "@/redux/apis/network/networkApi";
import { useAppDispatch } from "@/redux/hooks";
import { updateNetworkStatus } from "@/redux/slices/network/networkSlice";
import { NetworkItem } from "@/redux/slices/network/types";
import { addToast } from "@/utils";

interface DisconnectRelationshipModalProps {
  pendingDisconnectRelationship: NetworkItem | undefined;
  setPendingDisconnectRelationship: (item: NetworkItem | undefined) => void;
}

export const DisconnectRelationshipModal = ({ pendingDisconnectRelationship, setPendingDisconnectRelationship }: DisconnectRelationshipModalProps) => {
  const [removeNetwork, { isLoading }] = useRemoveNetworkMutation();
  const dispatch = useAppDispatch();

  const disconnectRelationship = () => {
    if (!pendingDisconnectRelationship) return;

    removeNetwork(pendingDisconnectRelationship.id)
      .unwrap()
      .then(() => {
        dispatch(updateNetworkStatus({ id: pendingDisconnectRelationship.id, status: "disconnected" }));
        addToast("success", `Removed ${pendingDisconnectRelationship.operator.name} company`);
        setPendingDisconnectRelationship(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal 
      open={!!pendingDisconnectRelationship} 
      onClose={() => setPendingDisconnectRelationship(undefined)} 
      className="relative w-[85vw] md:w-[514px] lg:w-[514px]"
    >
      {isLoading && <Loading />}
      <div className="max-w-lg sm:flex sm:items-start ">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Typography variant="title">Disconnect Relationship</Typography>
          <div className="mt-3">
            <Typography className="text-neutral-dark-gray">{`Are you sure you want to disconnect your relationship with ${pendingDisconnectRelationship?.operator.name || ""}?`}</Typography>
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-end gap-4">
        <Button variant="secondary" onClick={() => setPendingDisconnectRelationship(undefined)} className="px-8">
          Cancel
        </Button>
        <Button variant="primary" onClick={disconnectRelationship} className="!bg-danger px-8 hover:!bg-danger-dark">
          Disconnect
        </Button>
      </div>
    </Modal>
  );
};
