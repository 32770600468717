import { saveAs } from "file-saver";
import { Button, Icon, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyGetManualPaymentReceiptQuery } from "@/redux/apis/payInvoice/payInvoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { resetPayByCreditCard } from "@/redux/slices/payInvoice/payInvoiceSlice";
import { payByCreditCardSelector } from "@/redux/slices/payInvoice/selectors";
import { addToast, formatCurrency } from "@/utils";
import { usePayInvoicePageContext } from "../context";

export const PayByCreditCardStep3 = () => {
  const { params, payInvoice } = usePayInvoicePageContext();
  const { currency } = payInvoice;
  const dispatch = useAppDispatch();
  const { manualPaymentDetails } = useAppSelector(payByCreditCardSelector);
  const [getManualPaymentReceipt, { isFetching }] = useLazyGetManualPaymentReceiptQuery();

  const downloadPDFReceipt = (paymentRecordId: string) => {
    getManualPaymentReceipt({
      ...params,
      paymentRecordId,
    })
      .unwrap()
      .then((res) => saveAs(res.data.receipt, `receipt-${paymentRecordId}`))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderTotalAmount = (totalAmount: number) => (
    <Typography variant="h1">{`${currency} ${formatCurrency(totalAmount, 2, currency)}`}</Typography>
  );

  const renderDisplayMessage = () => {
    if (manualPaymentDetails) {
      switch (manualPaymentDetails.status) {
        case "FAILED":
          return (
            <div className="flex flex-col items-center">
              <Icon className="text-inherit mb-8 flex-shrink-0 self-center text-danger" name="CloseCircle" variant="Bold" size={116} />
              {renderTotalAmount(manualPaymentDetails.amounts.total)}
              <Typography variant="title">Your payment is unsuccessful</Typography>
              <Typography variant="paragraph" className="mt-5 text-center text-neutral-dark-gray">
                Pay by Credit Card transaction has failed. Please try
                <br />
                again later
              </Typography>
            </div>
          );
        default:
          return (
            <div className="flex flex-col items-center">
              <Icon className="text-inherit mb-8 flex-shrink-0 self-center text-success" name="TickCircle" variant="Bold" size={116} />
              {renderTotalAmount(manualPaymentDetails.amounts.total)}
              <Typography variant="title">Your payment is complete</Typography>
              <Typography variant="paragraph" className="mt-5 text-center text-neutral-dark-gray">
                You can now close this tab and go back to the
                <br />
                Payments page
              </Typography>
            </div>
          );
      }
    }
  };

  const renderActionButtons = () => {
    if (manualPaymentDetails) {
      switch (manualPaymentDetails.status) {
        case "SUCCESSFUL":
          return (
            <div className="flex flex-col gap-3 px-4 py-5">
              <Button
                className="w-full text-center"
                size="md"
                startIcon="ImportCurve"
                onClick={() => downloadPDFReceipt(manualPaymentDetails.id)}
              >
                Download PDF Receipt
              </Button>
            </div>
          );
        default:
          return (
            <div className="flex flex-col gap-3 px-4 py-5">
              <Button className="w-full text-center" size="md" onClick={() => dispatch(resetPayByCreditCard())}>
                Go back
              </Button>
            </div>
          );
      }
    }

    return null;
  };

  return (
    <div className="relative flex h-full w-full">
      {isFetching && <Loading />}
      <div className="relative flex h-full w-full flex-col">
        <div className="h-full flex-1 overflow-y-auto">
          <div className="flex h-full flex-col items-center justify-center">{renderDisplayMessage()}</div>
        </div>
        {renderActionButtons()}
      </div>
    </div>
  );
};
