import { z } from "zod";

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  delete: {
    title: "Are you sure?",
    description: "This will delete this multiplier permanently. You cannot undo this action, do you wish to continue?",
  },
  error: {
    title: "Unable to do this action",
    description: "Unable to do this action because some error occured.",
  },
};

export const timeAndDayFormSchema = z.object({
  name: z.string().nonempty("Field is required and can't be empty.").max(128, "Maximum of 128 characters."),
  costMultiplier: z.string().nonempty("Field is required and can't be empty."),
  timeStart: z.string().nonempty("Time Start is required and can't be empty."),
  timeEnd: z.string().nonempty("Time End is required and can't be empty."),
  dateStart: z.date().optional(),
  dateEnd: z.date().optional(),
  specificDate: z.boolean(),
  allDay: z.boolean(),
  repeatYearly: z.boolean(),
  treatAsSpecialEvent: z.boolean(),
  status: z.boolean(),
}).refine((data) => !(data.specificDate && !data.dateStart), {
  message: "Date Start is required and can't be empty.",
  path: ["dateStart"],
}).refine((data) => !(data.specificDate && !data.dateEnd), {
  message: "Date End is required and can't be empty.",
  path: ["dateEnd"],
});