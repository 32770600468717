import { useEffect } from "react";
import { matchPath, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { Tabs } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { DailyHire } from "./DailyHire";
import { HourlyHire } from "./HourlyHire";

const pageTabs = [
  { name: "Hourly Hire", to: "hourly" },
  { name: "Daily Hire", to: "daily" },
];

export const HourlyDailyHire = () => {
  const { pathname } = useLocation();
  const outlet = useOutlet();
  const navigate = useNavigate();

  useEffect(() => {
    if (!outlet) navigate("../");
  }, [outlet, navigate]);

  const isParentPage = Boolean(
    matchPath({ path: "/config/pricing/hourly-daily-hire/:slug/" }, pathname) ||
      matchPath({ path: "operator/:operatorId/config/pricing/hourly-daily-hire/:slug/" }, pathname)
  );

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader.Actions onBack={() => isParentPage ? navigate("../../") : navigate(-1)} />
      {isParentPage && (
        <Tabs className="mb-4 gap-3">
          {pageTabs.map((t, i) => (
            <Tabs.Tab key={i} to={t.to} name={t.name} variant="tertiary" />
          ))}
        </Tabs>
      )}
      {outlet}
    </div>
  );
};

HourlyDailyHire.Hourly = HourlyHire;
HourlyDailyHire.Daily = DailyHire;
