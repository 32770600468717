import { Modal } from "@/components/atoms";
import { useUserAccountsContext } from "@/contexts/userAccounts";
import { UserAccountsInviteForm } from "./UserAccountsInviteForm";
import { UserAccountsRemoveAccess } from "./UserAccountsRemoveAccess";
import { UserAccountsRoleForm } from "./UserAccountsRoleForm";

export const UserAccountsModals = () => {
  const { step, setStep } = useUserAccountsContext();

  return (
    <>
      <Modal open={step === "add"} onClose={() => setStep(undefined)} className="w-[85vw] md:w-[550px] lg:w-[550px]">
        <UserAccountsInviteForm />
      </Modal>
      <Modal open={step === "edit"} onClose={() => setStep(undefined)} className="w-[85vw] md:w-[550px] lg:w-[550px]">
        <UserAccountsRoleForm />
      </Modal>
      <Modal open={step === "delete"} onClose={() => setStep(undefined)}>
        <UserAccountsRemoveAccess />
      </Modal>
    </>
  );
};