import { Icon, IconButton, Toggle, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { VehicleCategory } from "@/redux/slices/pricing/types";
import { VehicleCategory as VehicleCategoryV2 } from "@/redux/slices/vehicle/types";
import { capitalize, formatCurrency, getCurrencySymbol } from "@/utils";
import { VehicleCategoriesEditorTableDataV2, VehicleCategoriesTableData } from "./types";

export const generateColumns = (data: VehicleCategoriesTableData[]) => {
  const columns: DataTableColumnDef<VehicleCategoriesTableData>[] = [
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "passenger", header: "Passenger", enableSorting: false },
    { accessorKey: "bags", header: "Bags", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "waitTimeCost", header: "Wait Time Cost", enableSorting: false },
    { header: "Active", cell: (props) => props.row.original.active, enableSorting: false },
    { header: " ", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];
  return columns;
};

export const generateTableData = (items: VehicleCategory[]) => {
  return items.map(
    (i) =>
      ({
        name: <Typography className="leading-6">{i.name}</Typography>,
        passenger: `${i.passenger} pax`,
        bags: i.bags,
        costIncrease: `${i.costIncrease > 0 ? "+" : ""}${i.costIncrease}%`,
        waitTimeCost: `${getCurrencySymbol(i.currency)}${i.waitTimeCost} /minute`,
        active: i.active ? (
          <Icon name="TickCircle" variant="Bold" className="text-success" />
        ) : (
          <Icon name="CloseCircle" variant="Bold" className="text-danger" />
        ),
      } as VehicleCategoriesTableData)
  );
};

export const generateEditorColumns = (data: VehicleCategoriesTableData[]) => {
  const columns: DataTableColumnDef<VehicleCategoriesTableData>[] = [
    {
      accessorKey: "reorder",
      header: "",
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "passenger", header: "Passenger", enableSorting: false },
    { accessorKey: "bags", header: "Bags", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "waitTimeCost", header: "Wait Time Cost", enableSorting: false },
    { header: "Active", cell: (props) => props.row.original.active, enableSorting: false },
    { header: " ", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];
  return columns;
};

export const generateTableEditorData = (
  items: VehicleCategory[],
  onToggleStatus: (id: string, active: boolean) => void,
  onEditItem: (item: VehicleCategory) => void,
  onDeleteItem: (item: VehicleCategory) => void
) => {
  return items.map(
    (i) =>
      ({
        id: i.id,
        name: <Typography className="leading-6">{i.name}</Typography>,
        passenger: `${i.passenger} pax`,
        bags: i.bags,
        costIncrease: `${i.costIncrease > 0 ? "+" : ""}${i.costIncrease}%`,
        waitTimeCost: `${getCurrencySymbol(i.currency)}${i.waitTimeCost} /minute`,
        actions: (
          <span className="flex gap-2">
            <Toggle size="sm" checked={i.active} onChange={(checked) => onToggleStatus(i.id, checked)} className="mt-0" />
            <IconButton
              iconName="Edit2"
              variant="tertiary"
              onClick={() => onEditItem(i)}
              className="border-none bg-transparent p-1 !text-info hover:bg-info-light"
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              onClick={() => onDeleteItem(i)}
              className="border-none bg-transparent p-1 !text-danger hover:bg-danger-light"
            />
          </span>
        ),
      } satisfies VehicleCategoriesTableData)
  );
};

export const generateEditorV2Columns = (data: VehicleCategoriesEditorTableDataV2[]) => {
  const columns: DataTableColumnDef<VehicleCategoriesEditorTableDataV2>[] = [
    {
      accessorKey: "reorder",
      header: "",
      size: 50,
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },

    {
      accessorKey: "class",
      header: "Vehicle Class",
      cell: (props) => capitalize(props.row.original.class.toLowerCase()),
      enableSorting: false,
    },
    {
      accessorKey: "type",
      header: "Vehicle Type",
      cell: (props) => getVehicleTypeLabel(props.row.original.type),
      enableSorting: false,
    },
    { accessorKey: "passenger", header: "Capacity", enableSorting: false },
    { accessorKey: "bags", header: "No. of Bags", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Adjustment", enableSorting: false },
    { accessorKey: "waitTimeCost", header: "Waiting Time", enableSorting: false },
    { accessorKey: "actions", header: "Active", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];
  return columns;
};

export const generateTableEditorV2Data = (
  items: VehicleCategoryV2[],
  onToggleStatus: (id: string, active: boolean) => void,
  onEditItem: (item: VehicleCategoryV2) => void,
  onDeleteItem: (item: VehicleCategoryV2) => void
) => {
  return items.map(
    (i) =>
      ({
        id: i.uuid,
        name: <Typography className="leading-6">{i.name}</Typography>,
        class: i.dispatchRestrictions.vehicleClass,
        type: i.dispatchRestrictions.vehicleType,
        passenger: `${i.vehicleCapacity.pax} pax`,
        bags: i.vehicleCapacity.bags,
        costIncrease: `${i.perDistanceUnitMultiplier > 0 ? "+" : ""}${i.perDistanceUnitMultiplier}%`,
        waitTimeCost: `${formatCurrency(i.waitTimeCost, 2, i.metadata.currency)} /minute`,
        actions: (
          <span className="flex items-center gap-1.5">
            <Toggle size="xs" checked={i.isActive} onChange={(checked) => onToggleStatus(i.uuid, checked)} className="mr-1" />
            <IconButton
              iconName="Edit2"
              variant="tertiary"
              onClick={() => onEditItem(i)}
              className="border-none bg-transparent p-1 !text-info hover:bg-info-light"
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              onClick={() => onDeleteItem(i)}
              className="border-none bg-transparent p-1 !text-danger hover:bg-danger-light"
            />
          </span>
        ),
      } as VehicleCategoriesEditorTableDataV2)
  );
};
