import { clsx } from "@/utils";

export interface BasicTableProps extends React.ComponentProps<"table"> {
  children: [React.ReactNode, React.ReactNode];
}

export const BasicTable = ({ className, children, ...props }: BasicTableProps) => {
  return (
    <div className={clsx("relative w-full overflow-auto ring-1 ring-neutral-mid-gray ring-opacity-50 md:rounded-lg", className)}>
      <table
        className="min-w-full table-fixed border-collapse divide-y divide-neutral-mid-gray divide-opacity-50 max-2xl:w-full"
        {...props}
      >
        <thead>{children[0]}</thead>
        <tbody className="divide-y divide-neutral-mid-gray divide-opacity-50">{children[1]}</tbody>
      </table>
    </div>
  );
};

const TableRow = ({ children, ...props }: React.ComponentProps<"tr">) => {
  return <tr {...props}>{children}</tr>;
};

const TableCell = ({ className, children, ...props }: React.ComponentProps<"td">) => {
  return (
    <td className={clsx("px-4  py-2 align-middle text-base font-medium text-neutral-black max-lg:p-2", className)} {...props}>
      {children}
    </td>
  );
};

const TableHeaderCell = ({ className, children, ...props }: React.ComponentProps<"th">) => {
  return (
    <th
      className={clsx(
        "truncate bg-neutral-surface-gray px-4 py-2 text-left text-sm font-semibold text-neutral-black max-lg:p-2",
        className
      )}
      {...props}
    >
      {children}
    </th>
  );
};

BasicTable.Row = TableRow;
BasicTable.Cell = TableCell;
BasicTable.HeaderCell = TableHeaderCell;
