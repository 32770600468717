import { useIntervalEffect } from "@react-hookz/web";
import { useCallback, useState } from "react";
import { useLazyGetNewBookingsQuery } from "@/redux/apis/booking/bookingApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setBooking, setBookings } from "@/redux/slices/booking/bookingSlice";
import { bookingsSelector } from "@/redux/slices/booking/selectors";
import { useGetBookingsParams } from "./hooks/useGetBookingsParams";
import { addNewBookingNotification } from "./utils";

export const BookingTracker = () => {
  const [hasNewBookings, setHasNewBookings] = useState(false);
  const dispatch = useAppDispatch();
  const currentBookings = useAppSelector(bookingsSelector);
  const { params } = useGetBookingsParams();
  const [getNewBookings] = useLazyGetNewBookingsQuery();

  const checkForNewBookings = useCallback(async () => {
    try {
      const res = await getNewBookings(params).unwrap();
      if (!res) return;
      const {
        data: { bookings, page },
      } = res;

      if (bookings.length <= 0) return;

      const newBookings = bookings.filter((i) => !currentBookings.find((j) => j.id === i.id));

      if (newBookings.length > 0) {
        const callback = () => {
          dispatch(setBookings({ bookings, page }));
        };

        if (!hasNewBookings) {
          addNewBookingNotification(`${newBookings.length} New bookings added recently`, callback);
          console.log("New bookings added recently", newBookings);
          setHasNewBookings(true);
        }
      }

      currentBookings.forEach((i) => {
        const booking = bookings.find((j) => j.id === i.id);
        if (booking && booking.metadata.hash !== i.metadata.hash) {
          dispatch(setBooking(booking));
          const el = document.getElementById(`booking-${booking.id}`);
          if (el) {
            el.classList.add("highlight-booking");
            setTimeout(() => {
              el.classList.remove("highlight-booking");
            }, 10000);
          }
        }
      });
    } catch (error) {
      console.error("Error fetching new bookings:", {
        error,
        params,
      });
    }
  }, [currentBookings, dispatch, getNewBookings, hasNewBookings, params]);

  useIntervalEffect(
    () => {
      checkForNewBookings();
    },
    import.meta.env.MODE === "production" ? 60000 : 10000
  );

  return null;
};
