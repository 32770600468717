import { ExternalSuppliersFormOptions } from "@/redux/slices/externalSupplier/types";
import { GetExternalSuppliersFormOptionsRawResponse, GetExternalSuppliersRawResponse, GetExternalSuppliersResponse } from "./types";

export const transformExternalSuppliers = (res: GetExternalSuppliersRawResponse) => {
  const {
    _embedded: { external_supplier: suppliers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetExternalSuppliersResponse = {
    data: {
      suppliers: suppliers.map((i) => ({
        id: i.uuid,
        name: i.name,
        email: i.email,
        type: i.type,
        phone: i.phone,
        state: i.state[0],
        status: i.status,
        avatar: i.avatar ?? "",
        profileCompletion: Math.floor(i.metadata.profile_completion / 100),
        rating: i.metadata.rating,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformExternalSuppliersFormOptions = (res: GetExternalSuppliersFormOptionsRawResponse) => {
  const { account_status, address_state, type, rating } = res;

  const response: ExternalSuppliersFormOptions = {
    ratings: Object.entries(rating).map((i) => ({ name: i[1], value: i[0] })),
    states: Object.entries(address_state).map((i) => ({ name: i[1], value: i[0] })),
    statuses: Object.entries(account_status).map((i) => ({ name: i[1], value: i[0] })),
    types: Object.entries(type).map((i) => ({ name: i[1], value: i[0] })),
  };

  return response;
};
