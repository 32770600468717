import { Circle, GoogleMap, Libraries, useLoadScript } from "@react-google-maps/api";
import cx from "classnames";
import { useEffect, useState } from "react";
import { getGoogleApiKey } from "@/helpers/apiHelpers";
import { Spinner } from "../";

export interface MapProps {
  circleRadius?: number;
  center?: Coordinates | null;
  className?: string;
}

export type Coordinates = {
  lat: number;
  lng: number;
};

// Sydney, New South Wales, Australia
const sydneyCenter: Coordinates = {
  lat: -33.865143,
  lng: 151.2099,
};

const libraries = ["places"] as Libraries;

export const Map = ({ circleRadius, center, className }: MapProps) => {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: getGoogleApiKey(), libraries: libraries });
  const [currentPosition, setCurrentPosition] = useState<Coordinates | null>(null);
  const [isFetchingCurrentPosition, setIsFetchingCurrentPosition] = useState(true);
  const centerPosition = center ? center : currentPosition ? currentPosition : sydneyCenter;

  // the process of fetching the users current location/position
  useEffect(() => {
    if (navigator.geolocation) {
      setIsFetchingCurrentPosition(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setIsFetchingCurrentPosition(false);
        },
        () => setIsFetchingCurrentPosition(false)
      );
    } else {
      setIsFetchingCurrentPosition(false);
    }
  }, [setCurrentPosition]);

  return (
    <>
      {isLoaded && !isFetchingCurrentPosition ? (
        <GoogleMap
          mapContainerClassName={cx("w-full h-full rounded-lg", className)}
          center={centerPosition}
          zoom={10}
          options={{ disableDefaultUI: true }}
        >
          {circleRadius && (
            <Circle
              center={centerPosition}
              radius={circleRadius}
              options={{
                strokeWeight: 0,
                fillColor: "#5B9DFF",
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                zIndex: 1,
              }}
            />
          )}
        </GoogleMap>
      ) : (
        <div className="flex w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    </>
  );
};
