import NoItemsStandard from "@/assets/images/no-items-standard.svg";
import { Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface EmptyStateProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string | JSX.Element;
  image?: string;
  children?: React.ReactNode;
  hideImage?: boolean;
}

export const EmptyState = ({
  className,
  title,
  description,
  image = NoItemsStandard,
  hideImage = false,
  children,
  ...props
}: EmptyStateProps) => {
  return (
    <section className={clsx("flex flex-col place-items-center p-8 lg:p-16", className)} {...props}>
      {!hideImage && <img src={image} alt="No item" className="max-w-sm" />}
      <div className={clsx("text-center", { "-mt-3": !hideImage })}>
        <Typography variant="action" className="mb-1 block">
          {title}
        </Typography>
        <Typography className="max-w-[400px] text-neutral-dark-gray">{description}</Typography>
      </div>
      <div className="mt-5 flex items-center gap-4">{children}</div>
    </section>
  );
};
