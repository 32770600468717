import { Panel, Typography } from "@/components/atoms";
import { PageList } from "@/components/molecules";

type Subpage = {
  name: string;
  desc: string;
  to: string;
  icon: string;
};

export const RegistrationToolsPanel = () => {
  const subpages: Subpage[] = [
    {
      name: "Client Tools",
      desc: "QR Code, Links and tools to help you register your clients",
      to: "client-tools",
      icon: "Profile2User",
    },
    {
      name: "Driver Tools",
      desc: "Links and tools to help you register your drivers",
      to: "driver-tools",
      icon: "UserSquare",
    },
  ];

  return (
    <Panel className="flex flex-1 flex-col">
      <Typography variant="h3">Registration Tools</Typography>
      <PageList className="-mx-5 mt-5">
        {subpages.map(({ name, desc, to, icon }) => (
          <PageList.Item
            key={name}
            title={name}
            description={desc}
            to={to}
            icon={icon}
          />
        ))}
      </PageList>
    </Panel>
  );
};
