import { DisclosurePanel } from "@/components/molecules";
import { VehicleCategoryCommissionField } from "./fields/VehicleCategoryCommissionField";
import { VehicleCategoryEnableCustomCommissionField } from "./fields/VehicleCategoryEnableCustomCommissionField";

export const VehicleCategoryAdvancedSettings = () => {
  return (
    <DisclosurePanel title="Advanced Settings" className="col-span-2 flex flex-col">
      <div className="flex w-full flex-col space-y-6">
        <VehicleCategoryEnableCustomCommissionField />
        <VehicleCategoryCommissionField />
      </div>
    </DisclosurePanel>
  );
};
