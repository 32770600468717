import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, ErrorMessage, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateDepositsDetailsMutation } from "@/redux/apis/payment/stripe/stripeApi";
import { addToast } from "@/utils";
import { depositsFormSchema } from "../fixtures";
import { DepositsFormData } from "../types";
import { Userpilot } from "userpilot";

export const DepositsEditor = () => {
  const navigate = useNavigate();
  const [updateDepositsDetails] = useUpdateDepositsDetailsMutation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<DepositsFormData>({
    resolver: zodResolver(depositsFormSchema),
    defaultValues: {
      bsb: "",
      accountNumber: "",
      confirmAccountNumber: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit((data) => {
    setIsSubmitting(true);
    updateDepositsDetails({
      account_number: data.accountNumber,
      account_routing_number: data.bsb.replace(/-/g, ""),
    })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          Userpilot.track("Deposits Configured");
          addToast("success", "Successfully set up BECS Direct Deposit");
          setTimeout(() => window.location.replace("../"), 1250);
        }, 8000);
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
        setIsSubmitting(false);
      });
  });

  return (
    <form id="stripe-deposits-form" className="relative flex flex-col" onSubmit={onSubmit}>
      {isSubmitting && <Loading />}
      <div className="flex flex-col gap-y-3 rounded-lg bg-neutral-surface-gray p-5 lg:w-[573px]">
        <div className="flex flex-row gap-x-3">
          <div className="flex w-full flex-col gap-y-1">
            <Typography variant="paragraph">BSB</Typography>
            <TextInput placeholder="BSB" size="md" hasError={!!errors.bsb} {...register("bsb")} />
            <ErrorMessage errors={errors} name="bsb" />
          </div>
          <div className="flex w-full flex-col gap-y-1">
            <Typography variant="paragraph">Account number</Typography>
            <TextInput
              type="number"
              placeholder="Account number"
              size="md"
              hasError={!!errors.accountNumber}
              {...register("accountNumber")}
            />
            <ErrorMessage errors={errors} name="accountNumber" />
          </div>
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Confirm Account number</Typography>
          <TextInput
            type="number"
            placeholder="Confirm Account number"
            size="md"
            hasError={!!errors.confirmAccountNumber}
            {...register("confirmAccountNumber")}
          />
          <ErrorMessage errors={errors} name="confirmAccountNumber" />
        </div>
      </div>
      <div className="mt-4 flex flex-row gap-x-4">
        <Button variant="primary" type="submit">
          Set up BECS Direct Deposit
        </Button>
        <Button variant="secondary" onClick={() => navigate("../")}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
