import { Button, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { useProviderCode } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";
import { profileSelector } from "@/redux/slices/profile/selectors";
import { clsx } from "@/utils";
import { isEmpty } from "ramda";
import { useNavigate } from "react-router-dom";
import { SidebarLogo } from "../Sidebar/SidebarLogo";
import { GeneralLogo } from "./GeneralLogo";

export interface GeneralHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  action?: "signUp" | "login" | "contact" | "skip";
  isPrivateContent?: boolean;
}

export const GeneralHeader = ({ action, isPrivateContent, className, ...props }: GeneralHeaderProps) => {
  const navigate = useNavigate();
  const phpHostUrl = getPhpHostUrl();
  const providerCode = useProviderCode();
  const profile = useAppSelector(profileSelector);
  const operator = useAppSelector(activeOperatorSelector);
  const signUpEnabled = operator && (!isEmpty(operator.availableAccountTypesOnRegister) || !operator.singleTenant);

  const renderAction = () => {
    switch (action) {
      case "signUp":
        if (signUpEnabled) {
          return (
            <div className="flex flex-row items-center gap-x-4">
              <Typography className="hidden md:block">Don&apos;t have an account?</Typography>
              <Button variant="primary" size="md" onClick={() => navigate(`/signup${providerCode ? `/${providerCode}` : ""}`)}>Sign Up</Button>
            </div>
          );
        }
        break;
      case "login":
        return (
          <div className="flex flex-row items-center gap-x-4">
            <Typography className="hidden md:block">Already have an account?</Typography>
            <Button variant="secondary" size="md" onClick={() => navigate("/login")}>Login</Button>
          </div>
        );
      case "contact":
        return (
          <div className="flex flex-row items-center gap-x-4">
            <Typography className="hidden md:block">Contact us at <a href = "mailto:support@rideminder.com" className="text-primary underline">support@rideminder.com</a></Typography>
          </div>
        );
      case "skip":
        return (
          <div className="flex flex-row items-center">
            <a href={`${phpHostUrl}/passenger/${profile.id}/booking/history`}>
              <Button variant="secondary" size="md">Skip for now</Button>
            </a>
          </div>
        );
    }
    return null;
  };

  return (
    <header 
      className={clsx(
        "flex relative items-center justify-center top-0 p-2 border-y-[1px] border-x-0 border-neutral-mid-gray bg-white", 
        { "py-6": !isPrivateContent },
        className
      )} 
      {...props}
    >
      <div className="flex flex-row w-full max-w-[1040px]">
        {isPrivateContent ? <SidebarLogo ignoreSidebarOpen className="flex flex-1 -ml-4" /> : <GeneralLogo />}
        {renderAction()}
      </div>
    </header>
  );
};
