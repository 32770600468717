import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Icon, Loading, Typography } from "@/components/atoms";
import { useEditTransferTypeMutation } from "@/redux/apis/config/pricing/transferTypes";
import { TransferTypeItem } from "@/redux/slices/pricing/types";
import { TransferTypeForm } from "./TransferTypeForm";
import { transferTypeFormSchema } from "./fixtures";
import { TransferTypeFormData } from "./types";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { addToast } from "@/utils";

interface EditTransferTypeFormProps {
  item: TransferTypeItem;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditTransferTypeForm = ({ item, onClose, onSuccess, ...props }: EditTransferTypeFormProps) => {
  const [editTransferType, { isLoading }] = useEditTransferTypeMutation();
  const methods = useForm<TransferTypeFormData>({
    defaultValues: {
      name: item.name,
      description: item.description,
      costMultiplier: item.multiplier.toString(),
      treatAsSpecialEvent: item.isSpecialEvent,
      tripType: item.type,
      status: item.isActive,
      adminOnlyTransferType: item.isAdminOnly,
      automatedDispatchEnabled: item.isDispatchEnabled,
      transferTypeTaxExempt: item.taxStatus === "exempt" ? true : false,
    },
    resolver: zodResolver(transferTypeFormSchema),
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    editTransferType({
      uuid: item.id,
      name: data.name,
      is_active: data.status,
      is_special_event: data.treatAsSpecialEvent,
      is_admin_only: data.adminOnlyTransferType,
      is_dispatch_enabled: data.automatedDispatchEnabled,
      description: data.description,
      multiplier: Number(data.costMultiplier),
      type: data.tripType,
      tax_status: data.transferTypeTaxExempt ? "exempt" : "liable",
    })
      .unwrap()
      .then(onSuccess)
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const renderModalHeader = () => {
    return (
      <div className="sm:flex sm:items-start">
        <div className="my-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Edit Transfer Type
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
      {renderModalHeader()}
      <form id="edit-transfer-type-form" className="relative flex flex-col" onSubmit={onSubmit} {...props}>
        {isLoading && <Loading />}
        <TransferTypeForm className="mt-2 flex-1" />
        <div className="mt-[42px]">
          <div className="flex justify-end gap-4">
            <Button variant="secondary" onClick={onClose} className="px-8">
              Back
            </Button>
            <Button variant="primary" type="submit" form="edit-transfer-type-form" className="px-8" disabled={!isDirty}>
              Update Transfer Type
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
