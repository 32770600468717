import { Status, TableColumn } from "../types";

export const openStatuses: Status[] = [
  {
    legend: "ADA",
    id: "awaiting_driver_allocation",
    name: "Awaiting Driver Allocation",
    color: "#F86262",
    icon: "circle",
  },
  {
    legend: "ADC",
    id: "driver_allocated_not_confirmed",
    name: "Awaiting Driver Confirmation",
    color: "#FEBC58",
    icon: "circle",
  },
  {
    legend: "ALC",
    id: "driver_allocated_confirmed",
    name: "Driver Allocated Confirmed",
    color: "#88D79A",
    icon: "circle",
  },
  {
    legend: "DOW",
    id: "on_way_to_job",
    name: "Driver On The Way",
    color: "#8DAD95",
    icon: "circle",
  },
  {
    legend: "COM",
    id: "commenced",
    name: "Commenced",
    color: "#78B4FC",
    icon: "circle",
  },
];

export const closedStatuses: Status[] = [
  {
    legend: "CRV",
    id: "complete_awaiting_review",
    name: "Complete Awaiting Review",
    color: "#FDF07B",
    icon: "circle",
  },
  {
    legend: "CSD",
    id: "closed",
    name: "Closed",
    color: "#D1D2D5",
    icon: "circle",
    tooltip: "Closed",
  },
  {
    legend: "CND",
    id: "cancelled",
    name: "Cancelled",
    color: "#EA5B5B",
    icon: "close",
  },
];

export const allStatuses = [...openStatuses, ...closedStatuses];

export const statusMapping = allStatuses.reduce((acc, curr) => {
  acc[curr.id] = curr;
  return acc;
}, {} as Record<string, Status>);

const remainingScreenWidth = document.documentElement.clientWidth - 1500;

export const allColumns: TableColumn[] = [
  {
    id: "id",
    header: "ID",
    sortId: "job_id",
    isJSX: true,
    isDefault: true,
    size: 125,
    isFixed: true,
    isVisibleMobile: true,
  },
  {
    id: "date",
    header: "Date",
    isDefault: true,
    isJSX: true,
    isVisibleMobile: true,
    size: 100,
  },
  {
    id: "time",
    header: "Time",
    size: 100,
    isDefault: true,
    isJSX: true,
    isVisibleMobile: true,
  },
  {
    id: "passenger",
    header: "Passenger",
    size: 150,
    isDefault: true,
    isJSX: true,
    isVisibleMobile: true,
  },
  {
    id: "client",
    header: "Client",
    size: 150,
    sortId: "client_name",
    isDefault: false,
  },
  {
    id: "bookedBy",
    size: 150,
    header: "Booked by",
    sortId: "booked_by",
  },
  {
    id: "company",
    header: "Company",
    size: 150,
  },
  {
    id: "pickupLocation",
    sortId: "pickup",
    header: "Pick-up Location",
    isDefault: true,
    isVisibleMobile: true,
    size: Math.max(200, remainingScreenWidth / 2),
  },
  {
    id: "destination",
    header: "Destination",
    isDefault: true,
    isVisibleMobile: true,
    size: Math.max(200, remainingScreenWidth / 2),
  },
  {
    id: "driver",
    header: "Driver",
    size: 150,
    isDefault: true,
    isJSX: true,
    isVisibleMobile: true,
  },
  {
    id: "vehicleType",
    header: "Vehicle Type",
    sortId: "vehicle_type",
    size: 150,
  },
  {
    id: "transferType",
    header: "Transfer Type",
    sortId: "transfer_type",
    size: 150,
  },
  {
    id: "cost",
    header: "Cost",
    isDefault: true,
    size: 100,
  },
  {
    id: "status",
    header: "Job Status",
    isJSX: true,
    isDefault: true,
    isVisibleMobile: true,
    size: 115,
  },
  {
    id: "paymentStatus",
    header: "Payment Status",
    sortId: "payment_status",
    isJSX: true,
    isDefault: false,
    size: 150,
  },
  {
    id: "operator",
    header: "Operator",
    isSortDisabled: true,
    isDefault: false,
    size: 200,
  },
  {
    id: "dashboardNotes",
    header: "Dashboard Notes",
    isDefault: false,
    size: 200,
  },
  {
    id: "appBooking",
    header: "",
    isJSX: true,
    isDefault: true,
    size: 50,
    isFixed: true,
    isSortDisabled: true,
  },
  {
    id: "mobileMetadata",
    header: "",
    isJSX: true,
    isDefault: true,
    isFixed: true,
    isHiddenDesktop: true,
    isSortDisabled: true,
    isVisibleMobile: true,
    size: 200,
  },
];

export const paymentStatuses = {
  invoiced: "Invoiced",
  invoice_ready: "Invoice Ready",
  invoice_pending: "Invoice Pending",
  paid: "Paid",
  partially_paid: "Partially Paid",
  unpaid: "Unpaid",
  prepaid: "Pre-Paid",
  partially_prepaid: "Partially Pre-Paid",
  refunded: "Refunded",
  no_charge: "No Charge",
  pending: "Pending",
};

export const paymentMethods = {
  credit_card: "Credit Card",
  invoice: "Invoice",
};

export const offloadOptions = [
  { id: "all", name: "All" },
  { id: "offloaded-to-network", name: "Offloaded to Network" },
  {
    id: "accepted-from-network",
    name: "Accepted from Network",
  },
];
