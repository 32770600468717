import { Link } from "react-router-dom";
import { Icon, Typography, AlertType, Tooltip, renderAlertIcon } from "@/components/atoms";

export type PricingListProps = React.HTMLAttributes<HTMLDivElement>;
export interface PricingListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  title: string;
  to: string;
  alert?: {
    type: AlertType;
    message: string;
  };
  address?: string;
  managed?: boolean;
}

export const PricingList = ({ className, children, ...props }: PricingListProps) => {
  return (
    <div className={className} {...props}>
      <ul role="list" className="flex flex-col gap-y-3">
        {children}
      </ul>
    </div>
  );
};

const PricingListItem = ({ title, to, alert, address, managed, ...props }: PricingListItemProps) => {
  return (
    <li {...props} role="listItem">
      {managed ? (
        <div className="group flex items-center rounded-lg bg-neutral-surface-gray py-3 pl-4 pr-3">
          <div className="flex flex-1 flex-row items-center gap-x-1.5">
            <Typography variant="paragraph" className="leading-6 text-neutral-dark-gray">
              {title}
            </Typography>
          </div>
          <div className="flex flex-row items-center gap-x-4">
            {address && (
              <div className="flex flex-row items-center gap-x-2.5">
                <Icon name="Location" size="sm" variant="Bold" className="text-info" />
                <Typography variant="paragraph" className="leading-6 text-neutral-dark-gray">
                  {address}
                </Typography>
              </div>
            )}
            <Tooltip
              content="The pricing is managed by RideMinder. Please contact RideMinder to make changes"
              placement="left"
              maxWidth={300}
            >
              <span>
                <Icon name="Lock1" variant="Bold" />
              </span>
            </Tooltip>
            <Icon name="ArrowRight2" size="sm" className="group-hover:text-primary" aria-hidden="true" />
          </div>
        </div>
      ) : (
        <Link to={to} className="group flex items-center rounded-lg bg-neutral-surface-gray py-3 pl-4 pr-3 hover:bg-primary-light">
          <div className="flex flex-1 flex-row items-center gap-x-1.5">
            <Typography variant="paragraph" className="leading-6 group-hover:text-primary">
              {title}
            </Typography>
            {alert && (
              <Tooltip content={alert.message} placement="bottom">
                <span>{renderAlertIcon(alert.type)}</span>
              </Tooltip>
            )}
          </div>
          <div className="flex flex-row items-center gap-x-4">
            {address && (
              <div className="flex flex-row items-center gap-x-2.5">
                <Icon name="Location" size="sm" variant="Bold" className="text-info" />
                <Typography variant="paragraph" className="leading-6 text-neutral-dark-gray">
                  {address}
                </Typography>
              </div>
            )}
            <Icon name="ArrowRight2" size="sm" className="group-hover:text-primary" aria-hidden="true" />
          </div>
        </Link>
      )}
    </li>
  );
};

PricingList.Item = PricingListItem;
