import { useParams } from "react-router-dom";
import { Panel, Skeleton } from "@/components/atoms";
import { Restricted } from "@/components/pages";
import { clsx } from "@/utils";
import { useFixedPricingContext, useFixedPricingLocationContext } from "../contexts";
import { CoverageAreaEditor } from "./CoverageAreaEditor";
import { LocationBasedEditor } from "./LocationBasedEditor";
import { CoverageArea } from "./contents/CoverageArea";
import { LocationBasedTable } from "./tables/LocationBasedTable";

export const LocationBasedPanel = () => {
  const { mode, type } = useParams();
  const { isFetching: isFetchingPricingProfiles, pricingProfile, isRestricted: isRestrictedPricingProfile } = useFixedPricingContext();
  const {
    isFetching: isFetchingPricingLocations,
    pricingLocation,
    isRestricted: isRestrictedPricingLocation,
  } = useFixedPricingLocationContext();
  const isFetching = isFetchingPricingProfiles || isFetchingPricingLocations || !pricingProfile || !pricingLocation;
  const isRestricted = isRestrictedPricingProfile || isRestrictedPricingLocation;

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="w-full">
            <Panel>
              {mode === "edit" && type !== "coverage-area" ? (
                <LocationBasedEditor pricing={pricingLocation} pricingProfile={pricingProfile} />
              ) : (
                <LocationBasedTable
                  className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type === "coverage-area" })}
                  pricing={pricingLocation}
                />
              )}
            </Panel>
          </div>
          <div className="w-[363px] flex-shrink-0">
            <Panel>
              {mode === "edit" && type === "coverage-area" ? (
                <CoverageAreaEditor pricing={pricingLocation} pricingProfile={pricingProfile} />
              ) : (
                <CoverageArea
                  className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type !== "coverage-area" })}
                  pricing={pricingLocation}
                />
              )}
            </Panel>
          </div>
        </div>
      )}
    </>
  );
};
