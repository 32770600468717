import { useParams, useSearchParams } from "react-router-dom";

export const useProviderCode = () => {
  const [params] = useSearchParams();
  const paramProviderCode = params.get("providerKey");
  const { providerCode: routeProviderCode } = useParams();

  if (paramProviderCode) return paramProviderCode.toLowerCase();
  if (routeProviderCode) return routeProviderCode.toLowerCase();
  return null;
};
