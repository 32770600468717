import { isEmpty } from "ramda";
import { FieldErrors, useFieldArray, useFormContext } from "react-hook-form";
import { Button, CheckboxInput, DropdownSelect, ErrorMessage, Icon, IconButton, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../types";

export const PricingAdjustmentsForm = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "pricingAdjustments",
  });

  const renderPricingAdjustments = () => {
    if (isEmpty(fields)) return;

    return (
      <div className="flex flex-col gap-y-2">
        {fields.map((field, i) => {
          const watchIsPriceIncrease = watch(`pricingAdjustments.${i}.isPriceIncrease`);
          return (
            <div key={field.id} className="flex flex-col gap-y-1">
              <div className="flex flex-col lg:flex-row md:flex-row lg:items-center">
                <div className="flex flex-row items-center mb-1">
                  <Typography variant="paragraph" className="mr-6">{`${i + 1}`}</Typography>
                  <IconButton
                    onClick={() => remove(i)}
                    iconName="Trash"
                    variant="tertiary"
                    className={clsx("flex flex-1 justify-end sm:hidden !text-danger hover:bg-danger-light ml-3")}
                  />
                </div>
                <div className="flex flex-col lg:flex-row w-full rounded-lg border border-neutral-dark-gray gap-2.5 px-4 py-3">
                  {renderPriceIncreaseDecrease(watchIsPriceIncrease, i)}
                  <div className="flex flex-1 flex-col sm:flex-row lg:justify-end sm:justify-normal md:justify-normal gap-2">
                    <div className="flex flex-1 lg:flex-initial flex-col sm:flex-row sm:items-center gap-x-3">
                      <Typography variant="paragraph" className="text-neutral-dark-gray">
                        Set Price % <span className="text-danger">*</span>
                      </Typography>
                      <TextInput
                        type="number"
                        variant="search-box"
                        size="sm"
                        className="w-full sm:w-[85px]"
                        placeholder="0"
                        step="any"
                        {...register(`pricingAdjustments.${i}.price`)}
                      />
                    </div>
                    <div className="flex flex-row items-center">
                      <CheckboxInput
                        id={`pricingAdjustments.${i}.displayToClient`}
                        label="Display to Client"
                        variant="check"
                        {...register(`pricingAdjustments.${i}.displayToClient`)}
                      />
                    </div>
                  </div>
                </div>
                <IconButton
                  onClick={() => remove(i)}
                  iconName="Trash"
                  variant="tertiary"
                  className={clsx("hidden sm:flex !text-danger hover:bg-danger-light ml-3")}
                />
              </div>
              <div className="flex flex-row gap-2 xl:gap-4">
                {/* The Typography below is only used as spacing */}
                <Typography variant="paragraph" className="text-transparent">{`${i + 1}`}</Typography>
                {errors.pricingAdjustments?.[i] && <ErrorMessage errors={errors.pricingAdjustments[i] as FieldErrors} name="price" />}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderPriceIncreaseDecrease = (isPriceIncrease: boolean, i: number) => {
    return (
      <div className="col-span-6 flex flex-col sm:flex-row gap-x-16 gap-y-1 rounded-md bg-neutral-gray px-2.5 py-1">
        <div className="flex flex-row items-center gap-x-2">
          {isPriceIncrease ? (
            <Icon name="AddCircle" variant="Bold" size="sm" className="text-success" />
          ) : (
            <Icon name="MinusCircle" variant="Bold" size="sm" className="text-danger" />
          )}
          <DropdownSelect
            options={[
              {
                name: "Price Increase",
                value: "increase",
              },
              {
                name: "Price Decrease",
                value: "decrease",
              },
            ]}
            value={isPriceIncrease ? "increase" : "decrease"}
            onChange={(value) => setValue(`pricingAdjustments.${i}.isPriceIncrease`, value === "increase", { shouldDirty: true })}
            buttonClassName="bg-transparent border-0 shadow-none pl-2"
            optionsClassName="w-44"
            size="sm"
            className="lg:w-fit w-full"
          />
        </div>
        <div className="flex flex-1 items-center justify-normal sm:justify-end">
          <TextInput
            className="w-full sm:max-w-[300px] bg-transparent placeholder-neutral-dark-gray"
            placeholder="Enter Description (Optional)"
            size="sm"
            maxLength={128}
            {...register(`pricingAdjustments.${i}.description`)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={clsx("flex flex-col gap-2.5 lg:gap-5", className)} {...props}>
      <Typography variant="paragraph" className="text-neutral-dark-gray">
        Price Adjustments not displayed to client will be applied to the base fare before any displayed adjustments
      </Typography>
      {renderPricingAdjustments()}
      <div className="flex flex-col items-center">
        <Button
          startIcon="Add"
          variant="tertiary"
          size="lg"
          className="w-full"
          onClick={() => append({ isPriceIncrease: true, price: 0, displayToClient: true })}
          iconClassName="flex-shrink-0"
        >
          Add Pricing Adjustment
        </Button>
      </div>
    </div>
  );
};
