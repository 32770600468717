import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { Toggle, Typography } from "@/components/atoms";
import { BookingFilters } from "@/redux/slices/booking/types";
import { useBookingFilters } from "../../../hooks/useBookingFilters";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  onlyShowUnvalidated: z.boolean(),
});

type FormData = z.infer<typeof schema>;

const getValue = (onlyShowUnvalidated: BookingFilters["onlyShowUnvalidated"]) => {
  if (onlyShowUnvalidated === null) return null;
  return onlyShowUnvalidated ? "Yes" : "No";
};

export const ValidatedJobFilter = () => {
  const {
    filters: { onlyShowUnvalidated },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => getValue(onlyShowUnvalidated), [onlyShowUnvalidated]);

  const onClose = () => setFilter("onlyShowUnvalidated", undefined);

  return (
    <FilterPopover name="onlyShowUnvalidated" value={value} label="Only Unvalidated Jobs" onClose={onClose}>
      <ValidatedJobFilterForm />
    </FilterPopover>
  );
};

interface ValidatedJobFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const ValidatedJobFilterDrawer = ({ open, onOpenChange }: ValidatedJobFilterDrawerProps) => {
  const {
    filters: { onlyShowUnvalidated },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => getValue(onlyShowUnvalidated), [onlyShowUnvalidated]);

  const onClose = () => setFilter("onlyShowUnvalidated", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Only Unvalidated Jobs" onClose={onClose}>
      <ValidatedJobFilterForm />
    </FilterDrawer>
  );
};

const ValidatedJobFilterForm = () => {
  const { setFilter, filters } = useBookingFilters();

  const { control } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { onlyShowUnvalidated: filters.onlyShowUnvalidated ? filters.onlyShowUnvalidated : undefined },
  });

  return (
    <div className="contents">
      <div className="flex gap-2">
        <Controller
          name="onlyShowUnvalidated"
          control={control}
          render={({ field }) => (
            <Toggle
              size="sm"
              checked={Boolean(field.value)}
              onChange={(value) => {
                field.onChange(value);
                setFilter("onlyShowUnvalidated", value);
              }}
            />
          )}
        />
        <Typography variant="paragraph">Only Unvalidated Jobs</Typography>
      </div>
    </div>
  );
};
