import { DebitHistoryPanel } from "@/components/organisms";
import { DebitRecords } from "./DebitRecords";

export const DebitHistory = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <DebitHistoryPanel />
    </div>
  );
};

DebitHistory.DebitRecords = DebitRecords;
