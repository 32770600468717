import { forwardRef } from "react";
import { clsx, slugify } from "@/utils";
import { Typography } from "../Typography/Typography";

export interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  description?: string;
  htmlFor?: string;
}

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ label, description, className, htmlFor = "", disabled, ...props }, ref) => {
    return (
      <div className={clsx("peer inline-flex items-center", className)}>
        <input
          ref={ref}
          id={htmlFor || `radio-${slugify(label)}`}
          type="radio"
          className={clsx("h-5 w-5 cursor-pointer border-neutral-black text-primary focus:ring-0", {
            "self-start": !!description,
            "border-neutral-dark-gray": disabled,
          })}
          disabled={disabled}
          {...props}
        />
        <label
          htmlFor={htmlFor || `radio-${slugify(label)}`}
          className={clsx("ml-2 block cursor-pointer text-base font-medium leading-4", disabled && "text-neutral-dark-gray")}
        >
          {label}
          {description && (
            <Typography className={clsx("block text-neutral-dark-gray", disabled && "text-neutral-gray")}>{description}</Typography>
          )}
        </label>
      </div>
    );
  }
);

RadioInput.displayName = "RadioInput";
