import { Operator } from "@/redux/slices/operator/types";
import { GetOperatorsRawResponse, GetOperatorRawResponse, GetOperatorsResponse, GetOperatorResponse } from "./types";

export const transformOperators = (res: GetOperatorsRawResponse) => {
  const operators = res._embedded.providers.map((p) => {
    const {
      provider_uuid,
      provider_key,
      domain_driver,
      domain,
      country,
      currency,
      bookable_country_list,
      available_account_types_on_register,
      email,
      joined,
      images,
      name,
      phone,
      primary,
      urls: { brochure, contact_us, privacy_policy, terms },
      permissions: { roles, permissions },
      flags,
      branding: { primary_color, secondary_color, tertiary_color },
    } = p;
    const { logo, emblem, qr, qr_transparent } = images;

    return {
      id: provider_uuid,
      providerCode: provider_key.toLowerCase(),
      singleTenant: provider_key.toLowerCase() !== "rideminder",
      domain: domain.toLowerCase(),
      domainDriver: domain_driver.toLowerCase(),
      name,
      email,
      joined,
      images: {
        logo,
        emblem,
        qr,
        qrTransparent: qr_transparent,
      },
      phone,
      primary,
      urls: {
        brochure,
        privacy: privacy_policy,
        terms,
        contact: contact_us,
      },
      country,
      currency,
      bookableCountryList: bookable_country_list,
      availableAccountTypesOnRegister: available_account_types_on_register,
      permissions,
      roles,
      branding: {
        primary: primary_color,
        dark: secondary_color,
        light: tertiary_color,
      },
      registerLinks: {
        driver: p.deep_links?.driver_registration ?? "",
        client: p.deep_links?.client_registration ?? "",
      },
      flags: {
        paymentInvoiceConfigured: Boolean(flags.payment_invoice_configured),
        vehicleAssignmentEnabled: Boolean(flags.vehicle_assignment_enabled),
        dashboardNotesEnabled: Boolean(flags.dashboard_notes_enabled),
        useOptimizedLayout: Boolean(flags.optimized_layout),
        driverPaidByPlatform: Boolean(flags.driver_paid_by_platform),
        paymentConfigured: Boolean(flags.payment_configured),
        isSuspended: Boolean(flags.is_suspended),
        isSimpleSignupEnabled: Boolean(flags.is_simple_signup_enabled),
      },
    } as Operator;
  });

  const response: GetOperatorsResponse = {
    data: {
      operators,
    },
    links: res._links,
  };

  return response;
};

export const transformOperator = (res: GetOperatorRawResponse) => {
  const {
    provider_uuid,
    provider_key,
    domain_driver,
    domain,
    country,
    currency,
    bookable_country_list,
    available_account_types_on_register,
    email,
    joined,
    images,
    name,
    phone,
    primary,
    urls: { brochure, contact_us, privacy_policy, terms },
    permissions: { roles, permissions },
    flags,
    branding: { primary_color, secondary_color, tertiary_color },
  } = res;
  const { logo, emblem, qr, qr_transparent } = images;

  const response: GetOperatorResponse = {
    data: {
      id: provider_uuid,
      providerCode: provider_key.toLowerCase(),
      singleTenant: provider_key.toLowerCase() !== "rideminder",
      domain: domain.toLowerCase(),
      domainDriver: domain_driver.toLowerCase(),
      name,
      email,
      joined,
      images: {
        logo,
        emblem,
        qr,
        qrTransparent: qr_transparent,
      },
      phone,
      primary,
      urls: {
        brochure,
        privacy: privacy_policy,
        terms,
        contact: contact_us,
      },
      country,
      currency,
      bookableCountryList: bookable_country_list,
      availableAccountTypesOnRegister: available_account_types_on_register,
      permissions,
      roles,
      branding: {
        primary: primary_color,
        dark: secondary_color,
        light: tertiary_color,
      },
      registerLinks: {
        driver: res.deep_links?.driver_registration ?? "",
        client: res.deep_links?.client_registration ?? "",
      },
      flags: {
        paymentInvoiceConfigured: Boolean(flags.payment_invoice_configured),
        vehicleAssignmentEnabled: Boolean(flags.vehicle_assignment_enabled),
        dashboardNotesEnabled: Boolean(flags.dashboard_notes_enabled),
        useOptimizedLayout: Boolean(flags.optimized_layout),
        driverPaidByPlatform: Boolean(flags.driver_paid_by_platform),
        paymentConfigured: Boolean(flags.payment_configured),
        isSuspended: Boolean(flags.is_suspended),
        isSimpleSignupEnabled: Boolean(flags.is_simple_signup_enabled),
      },
    },
  };

  return response;
};
