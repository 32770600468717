import { isSameDay } from "date-fns";
import { DateRangePicker, SearchInput } from "@/components/atoms";
import { usePagination, useProfile, useSearch } from "@/hooks";
import { useBookingDates } from "./hooks/useBookingDates";

export const BookingControls = () => {
  return (
    <div className="mt-2.5 flex items-center gap-2 sm:justify-between">
      <BookingDateRangePicker />
      <BookingSearchInput />
    </div>
  );
};

const BookingDateRangePicker = () => {
  const { dateFormat } = useProfile();
  const [selectedDates, setSelectedDates] = useBookingDates();
  const { clearPagination } = usePagination();

  const handleSelectDates = (dates?: Date[]) => {
    clearPagination();
    setSelectedDates({
      dateFrom: dates ? dates[0] : undefined,
      dateTo: dates ? dates[1] : undefined,
    });
  };

  return (
    <DateRangePicker
      containerClassName="min-w-0"
      dates={[selectedDates.dateFrom, selectedDates.dateTo]}
      onDatesChange={handleSelectDates}
      dateFormat={dateFormat}
      size="sm"
      preset={isSameDay(selectedDates.dateFrom, selectedDates.dateTo) ? "today" : undefined}
    />
  );
};

const BookingSearchInput = () => {
  const [search, setSearch] = useSearch();
  const { clearPagination } = usePagination();

  const handleSearch = (value?: string) => {
    clearPagination();
    setSearch(value);
  };

  return (
    <SearchInput
      search={search}
      onSearch={handleSearch}
      placeholder="Enter Job Number or Search Anything"
      className="max-w-[375px] p-0 sm:w-1/2 [&_input]:transition-size max-sm:[&_input]:w-[34px] max-sm:[&_input]:py-4  max-sm:[&_input]:pr-2 max-sm:focus:[&_input]:w-[305px]"
      size="sm"
    />
  );
};
