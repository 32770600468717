import { useEffect, useState } from "react";
import { Button, CardPreview, Divider, Loading } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyGetPaymentMethodDetailsQuery, useTriggerManualPaymentMutation } from "@/redux/apis/payment/record/recordApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setTakeManualPaymentStep } from "@/redux/slices/payment/paymentSlice";
import { creditCardPaymentsTakeManualPaymentSelector } from "@/redux/slices/payment/selectors";
import { CreditCardPaymentsItem } from "@/redux/slices/payment/types";
import { addToast } from "@/utils";
import { ManualPaymentDetails } from "./ManualPaymentDetails";

interface TakeManualPaymentStep2Props {
  payment: CreditCardPaymentsItem;
}

export const TakeManualPaymentStep2 = ({ payment }: TakeManualPaymentStep2Props) => {
  const dispatch = useAppDispatch();
  const [getPaymentMethodDetails, { isFetching }] = useLazyGetPaymentMethodDetailsQuery();
  const [triggerManualPayment, { isLoading: isLoadingTriggerManualPayment }] = useTriggerManualPaymentMutation();
  const { paymentMethodSetupId, paymentMethodDetails } = useAppSelector(creditCardPaymentsTakeManualPaymentSelector);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentMethodSetupId) {
        getPaymentMethodDetails({
          id: payment.id,
          setupId: paymentMethodSetupId,
        })
          .unwrap()
          .then(() => setIsLoading(false))
          .catch((e) => {
            getErrorMessages(e).forEach((m) => addToast("danger", m));
            setIsLoading(false);
          });
      } else addToast("danger", "Something went wrong. Please contact your administrator.");
    }, 8000);

    return () => clearTimeout(timer);
  }, [paymentMethodSetupId, payment, getPaymentMethodDetails]);

  const handlePayNow = () => {
    if (paymentMethodDetails) {
      triggerManualPayment({
        id: payment.id,
        paymentMethodId: paymentMethodDetails.id,
      })
        .unwrap()
        .then(() => dispatch(setTakeManualPaymentStep(3)))
        .catch((e) => {
          getErrorMessages(e).forEach((m) => addToast("danger", m));
        });
    } else addToast("danger", "Something went wrong. Please contact your administrator.");
  };

  return (
    <div className="relative flex w-full">
      <div className="relative flex h-full w-full flex-col">
        {(isLoading || isFetching || isLoadingTriggerManualPayment) && <Loading />}
        <div className="h-full flex-1 overflow-y-auto p-5">
          <StepIndicator currentStep={2} numSteps={2} className="mb-16 mt-10 justify-center" />
          {paymentMethodDetails && (
            <>
              <CardPreview
                number={paymentMethodDetails.paymentMethod.last4}
                expiryMonth={paymentMethodDetails.paymentMethod.expiryMonth}
                expiryYear={paymentMethodDetails.paymentMethod.expiryYear}
                issuer={paymentMethodDetails.paymentMethod.issuer}
                className="mb-14"
              />
              <ManualPaymentDetails className="flex flex-col px-[22px]" payment={payment} paymentMethodDetails={paymentMethodDetails} />
            </>
          )}
        </div>
        <div>
          <Divider />
          <div className="flex flex-col gap-3 px-4 py-5">
            <Button className="w-full text-center" size="lg" onClick={handlePayNow}>
              Pay Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
