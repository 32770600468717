import z from "zod";
import { getTimeMultiplier } from "./helpers";

export const formSchema = z
  .object({
    enabledAutomaticDispatchPrivateNetwork: z.boolean(),
    dispatchAutoPrivateNetworkTime: z.string().min(1, "Field is required and can't be empty."),
    dispatchAutoPrivateNetworkTimeframe: z.string(),
    enabledAutomaticDispatchPublicNetwork: z.boolean(),
    dispatchAutoPublicNetworkTime: z.string().min(1, "Field is required and can't be empty."),
    dispatchAutoPublicNetworkTimeframe: z.string(),
  })
  .refine((data) => validateAutoDispatchSettings(data), {
    message: "Private network time before pickup should be greater than public network.",
    path: ["dispatchAutoPrivateNetworkTime"],
  })
  .refine((data) => validateAutoDispatchSettings(data), {
    message: "Public network time before pickup should be less than public network.",
    path: ["dispatchAutoPublicNetworkTime"],
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validateAutoDispatchSettings = (data: any) => {
  if (data.enabledAutomaticDispatchPublicNetwork && data.enabledAutomaticDispatchPrivateNetwork) {
    const privatePickupDelay = Number(data.dispatchAutoPrivateNetworkTime) * getTimeMultiplier(data.dispatchAutoPrivateNetworkTimeframe);
    const publicPickupDelay = Number(data.dispatchAutoPublicNetworkTime) * getTimeMultiplier(data.dispatchAutoPublicNetworkTimeframe);

    return privatePickupDelay > publicPickupDelay;
  }
  return true;
};