import { ResizeOptions } from "sharp";

// eslint-disable-next-line @typescript-eslint/ban-types
export const getAssetUrl = (key = "", resize?: ResizeOptions) => {
  const imageRequest = JSON.stringify({
    key,
    edits: {
      resize,
    },
  });

  const encoded = btoa(imageRequest);
  const url = `${import.meta.env.VITE_RIDEMINDER_CDN_URL}/${encoded}`;

  return url;
};
