export type RawPage = {
  page_count: number;
  page_size: number;
  total_items: number;
  page: number;
};

export type Page = {
  current: number;
  count: number;
  size: number;
  total: number;
};

export type SearchParams = {
  page?: string;
  page_size?: string;
  sort?: string;
  direction?: string;
};

export const pageState = {
  current: 1,
  count: 1,
  size: 12,
  total: 0,
};
