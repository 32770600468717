import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TextInput, Typography } from "@/components/atoms";
import { useBookingFilters } from "../../../hooks/useBookingFilters";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  client: z.string(),
});

type FormData = z.infer<typeof schema>;

export const ClientFilter = () => {
  const {
    filters: { client },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => client, [client]);

  const onClose = () => setFilter("client", undefined);

  return (
    <FilterPopover name="client" value={value} label="Client" onClose={onClose}>
      <ClientFilterForm />
    </FilterPopover>
  );
};

interface ClientFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const ClientFilterDrawer = ({ open, onOpenChange }: ClientFilterDrawerProps) => {
  const {
    filters: { client },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => client, [client]);

  const onClose = () => setFilter("client", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Client" onClose={onClose}>
      <ClientFilterForm />
    </FilterDrawer>
  );
};

const ClientFilterForm = () => {
  const close = useClose();
  const { setFilter, filters } = useBookingFilters();

  const { watch, register } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { client: filters.client ? filters.client : "" },
  });

  const value = watch("client");

  useDebouncedEffect(
    () => {
      setFilter("client", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Search Client</Typography>
      <TextInput {...register("client")} size="md" iconName="SearchNormal1" placeholder="Search Client" />
    </form>
  );
};
