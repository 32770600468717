import { useNavigate } from "react-router-dom";
import { Icon, Typography } from "@/components/atoms";
import { clsx, formatCurrency, getCurrencySymbol } from "@/utils";
import { useAppSelector } from "@/redux/hooks";
import { asapPricingSelector } from "@/redux/slices/pricing/selectors";

export const AsapPricing = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const { cancellationFee, noFeeWindow, currency } = useAppSelector(asapPricingSelector);

  return (
    <div className={clsx("flex flex-col gap-4 sm:flex-row sm:items-center", className)}>
      <div className="border-1 relative w-[300px] rounded-lg border border-neutral-mid-gray  bg-white p-4" {...props}>
        <div className="flex justify-between space-x-3">
          <div className="min-w-0 flex-1">
            <Typography variant="h2" className="leading-6">
              {`< ${noFeeWindow / 60} minutes`}
            </Typography>
          </div>
          <span className="flex-shrink-0 whitespace-nowrap">
            <button onClick={() => navigate("./edit/free-window")}>
              <Icon className="h-5 w-5 text-primary" name="Edit2" />
            </button>
          </span>
        </div>
        <div className="mt-1">
          <Typography variant="action">{`${currency} ${getCurrencySymbol(currency)} 0 Fee`}</Typography>
          <Typography variant="paragraph" className="leading-4 text-neutral-dark-gray">
            Free Cancellation window
          </Typography>
        </div>
      </div>
      <div className="border-1 relative w-[300px] rounded-lg border border-neutral-mid-gray  bg-white p-4" {...props}>
        <div className="flex justify-between space-x-3">
          <div className="min-w-0 flex-1">
            <Typography variant="h2" className="leading-6">
              {formatCurrency(cancellationFee, 2, currency)}
            </Typography>
          </div>
          <span className="flex-shrink-0 whitespace-nowrap">
            <button onClick={() => navigate("./edit/cancellation-fee")}>
              <Icon className="h-5 w-5 text-primary" name="Edit2" />
            </button>
          </span>
        </div>
        <div className="mt-1">
          <Typography variant="action">More than {(noFeeWindow / 60).toFixed(0)} minutes</Typography>
          <Typography variant="paragraph" className="leading-4 text-neutral-dark-gray">
            Cancellation fee outside window
          </Typography>
        </div>
      </div>
    </div>
  );
};
