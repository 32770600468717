import { GetAppLinksResponse, GetDeepLinksResponse, GetLinksAndDocumentsRawResponse, GetOtherLinksResponse, LinkAndDocument } from "./types";

export const transformDeepLinks = (res: GetLinksAndDocumentsRawResponse) => {
  const {
    _embedded: { links_and_documents: deepLinks },
  } = res;

  const response: GetDeepLinksResponse = {
    data: {
      deepLinks: deepLinks.map(
        (i) =>
          ({
            id: i.uuid,
            type: i.type,
            icon: i.icon,
            visibility: i.visibility,
            document: i.document,
          } as LinkAndDocument)
      ),
    },
  };

  return response;
};

export const transformAppLinks = (res: GetLinksAndDocumentsRawResponse) => {
  const {
    _embedded: { links_and_documents: appLinks },
  } = res;

  const response: GetAppLinksResponse = {
    data: {
      appLinks: appLinks.map(
        (i) =>
          ({
            id: i.uuid,
            type: i.type,
            icon: i.icon,
            visibility: i.visibility,
            document: i.document,
          } as LinkAndDocument)
      ),
    },
  };

  return response;
};

export const transformOtherLinks = (res: GetLinksAndDocumentsRawResponse) => {
  const {
    _embedded: { links_and_documents: otherLinks },
  } = res;

  const response: GetOtherLinksResponse = {
    data: {
      otherLinks: otherLinks.map(
        (i) =>
          ({
            id: i.uuid,
            type: i.type,
            icon: i.icon,
            visibility: i.visibility,
            document: i.document,
          } as LinkAndDocument)
      ),
    },
  };

  return response;
};