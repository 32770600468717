import { matchPath, useLocation, useOutlet } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { AppAction, AppSubject } from "@/casl/types";
import { Tabs } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { clsx } from "@/utils";
import { DynamicPricing } from "./DynamicPricing/DynamicPricing";
import { FixedPricing } from "./FixedPricing/FixedPricing";
import { UniversalPricing } from "./UniversalPricing";

type Tab = {
  name: string;
  to: string;
  permission?: [AppAction, AppSubject];
};

const pageTabs: Tab[] = [
  { name: "Universal Pricing", to: "universal-pricing" },
  { name: "Dynamic Pricing", to: "dynamic-pricing" },
  { name: "Fixed Pricing", to: "fixed-pricing", permission: ["manage", "providerConfigPricingFixed"] },
];

export const TripCost = () => {
  const { pathname } = useLocation();
  const ability = useAppAbility();
  const outlet = useOutlet();
  const prevPage = pathname.substring(0, pathname.lastIndexOf('/'));
  const isParentPage = Boolean(
    matchPath({ path: "/config/pricing/trip-cost/:slug/" }, pathname) ||
      matchPath({ path: "operator/:operatorId/config/pricing/trip-cost/:slug/" }, pathname)
  );

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader.Actions prevPage={isParentPage ? "../" : prevPage} />
      {isParentPage && (
        <Tabs className="mb-4 gap-3">
          {pageTabs.map((t, i) => (
            <Tabs.Tab
              key={i}
              to={t.to}
              name={t.name}
              variant="tertiary"
              className={clsx({ hidden: t.permission ? !ability.can(t.permission[0], t.permission[1]) : false })}
            />
          ))}
        </Tabs>
      )}
      {outlet}
    </div>
  );
};

TripCost.UniversalPricing = UniversalPricing;
TripCost.DynamicPricing = DynamicPricing;
TripCost.FixedPricing = FixedPricing;
