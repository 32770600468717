import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Divider, ErrorMessage, Icon, IconButton, TextArea, Typography, SidePanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRecallJobMutation } from "@/redux/apis/booking/endpoints/actions";
import { addNotification, addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

interface RecallJobProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  reason: z.string().min(2).max(1024),
});

type FormData = z.infer<typeof schema>;

export const RecallJob = ({ open, onClose }: RecallJobProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [recallJob] = useRecallJobMutation();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      reason: "",
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting, errors },
  } = form;

  const onSubmit = async (data: FormData) => {
    await recallJob({
      bookingId,
      reason: data.reason,
    })
      .unwrap()
      .then(() => {
        addNotification("success", "Recall Job", "Successfully recalled job");
        reset();
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <SidePanel open={open} onClose={onClose} width={550} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="ArchiveBook" size="lg" />
          <Typography variant="title">Re-call Job</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
        <div className="flex-1 p-5">
          <div className="bg-neutral-surface-gray p-5">
            <Typography variant="paragraph">Reason for re-calling job</Typography>
            <TextArea placeholder="Enter Reason for re-calling job" className="mb-2 mt-1 h-[6rem]" {...register("reason")} />
            <ErrorMessage errors={errors} name="reason" />
            <Typography className="text-neutral-dark-gray">
              By re-calling you will remove the Job from being available to the network. Any assigned drivers will be removed. Please ensure
              you have notified any drivers before completing the re-call.
            </Typography>
          </div>
        </div>
        <div className="p-5">
          <Divider className="-mx-5" />
          <Button type="submit" disabled={!isDirty || isSubmitting} className="mt-5 w-full" variant="primary" size="lg">
            Re-Call Job
          </Button>
        </div>
      </form>
    </SidePanel>
  );
};
