import { NavLink, useParams } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Icon, Tooltip, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { isUATorStaging } from "@/helpers/urlHelpers";
import { useOperator } from "@/hooks";
import { clsx } from "@/utils";
import { Tab } from "./types";

interface SidebarNavItemProps {
  item: Tab;
  sidebarOpen: boolean;
  className?: string;
  showNotification?: boolean;
}

export const SidebarNavItem = ({ item, sidebarOpen, showNotification, className }: SidebarNavItemProps) => {
  const ability = useAppAbility();
  const phpHostUrl = getPhpHostUrl();
  const { operatorId: hasOperatorId } = useParams();
  const { id: operatorId } = useOperator();

  const hasPermission = item.permission ? ability.can(item.permission[0], item.permission[1]) : true;
  const hasLegacyPermission = item.legacyPermission
    ? ability.can(item.legacyPermission[0], item.legacyPermission[1])
    : item.legacyTo
    ? true
    : false;

  const baseClass = clsx("items-center rounded-md text-neutral-black hidden p-2.5", {
    flex: hasPermission || hasLegacyPermission,
  });

  const getClassName = ({ isActive }: { isActive: boolean }) => {
    return clsx(baseClass, className, {
      "bg-primary-light text-primary-dark": isActive,
      "hover:bg-neutral-gray hover:text-neutral-black": !isActive,
    });
  };

  const navItem = (
    <span className={clsx("relative flex w-full gap-x-3 text-neutral-black", { "justify-center": !sidebarOpen })}>
      {showNotification && <div className="absolute -top-0.5 left-[18px] h-2 w-2 rounded-full bg-danger" />}
      <Icon name={item.icon} isCustom={item.isCustomIcon} className="flex-shrink-0" aria-hidden="true" />
      <Typography variant="action" className={clsx({ hidden: !sidebarOpen })}>
        {item.name}
      </Typography>
    </span>
  );

  const basePath = hasOperatorId ? `/operator/${operatorId}` : "";

  if (item.onClick) {
    return (
      <Tooltip content={item.name} placement="right" disabled={sidebarOpen}>
        <div className={clsx(baseClass, className, "cursor-pointer hover:bg-neutral-gray hover:text-neutral-black")} onClick={item.onClick}>
          {navItem}
        </div>
      </Tooltip>
    );
  }

  if (isUATorStaging || import.meta.env.DEV) {
    return (
      <Tooltip content={item.name} placement="right" disabled={sidebarOpen}>
        <NavLink to={`${basePath}${item.to}`} className={getClassName}>
          {navItem}
        </NavLink>
      </Tooltip>
    );
  }

  if (hasLegacyPermission) {
    return (
      <Tooltip content={item.name} placement="right" disabled={sidebarOpen}>
        <a href={`${phpHostUrl}${item.legacyTo}`} className={clsx(baseClass, className, "hover:bg-neutral-gray hover:text-neutral-black")}>
          {navItem}
        </a>
      </Tooltip>
    );
  }

  return (
    <Tooltip content={item.name} placement="right" disabled={sidebarOpen}>
      <NavLink to={`${basePath}${item.to}`} className={getClassName}>
        {navItem}
      </NavLink>
    </Tooltip>
  );
};
