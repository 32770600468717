import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, Loading, Spinner, Typography, Modal } from "@/components/atoms";
import { useGetProfileQuery } from "@/redux/apis/profile/profileApi";
import { getAssetUrl, getTimezoneFlag } from "@/utils";
import { ProfileAvatarUploader } from "./ProfileAvatarUploader";

export const ProfileHeader = () => {
  const navigate = useNavigate();
  const { refetch, isFetching, data: profile } = useGetProfileQuery();
  const [showModal, setShowModal] = useState(false);

  if (!profile) return <Spinner />;

  const { firstName, lastName, title, timezone, avatar } = profile;
  const avatarUrl =
    getAssetUrl(avatar, { height: 328, width: 328, fit: "cover" }) ||
    `https://via.placeholder.com/328x328/cccccc/969696?text=${firstName[0]}`;

  return (
    <div className="relative">
      {isFetching && <Loading />}
      <div className="relative h-[128px] bg-gradient-to-r from-[#A18CD1] to-[#FBC2EB] lg:h-[164px]"></div>
      <div className="-mt-12 flex items-center gap-8 bg-white">
        <div className="flex-shrink-0 relative ml-8 lg:ml-12">
          <img src={avatarUrl} className="h-[128px] w-[128px] rounded-full border-[11px] border-white bg-white lg:h-[164px] lg:w-[164px]" />
          <IconButton
            onClick={() => setShowModal(true)}
            iconName="Camera"
            iconVariant="Bold"
            iconSize="md"
            className="absolute bottom-3 right-3 !rounded-full !bg-info !p-2 hover:opacity-80"
          />
        </div>
        <div className=" mt-12 flex-1">
          <Typography variant="h2" className="leading-loose">
            {title} {firstName} {lastName}
          </Typography>
          <Typography className="text-neutral-dark-gray">
            {getTimezoneFlag(timezone.name)} <span className="ml-1">{timezone.name}</span>
          </Typography>
        </div>
        <div className="mr-6 mt-12">
          <Button variant="primary" onClick={() => navigate("edit")}>
            Edit Profile
          </Button>
        </div>
      </div>
      <Modal open={showModal} onClose={() => setShowModal(false)} title="Upload Profile Photo">
        <ProfileAvatarUploader
          onClose={() => setShowModal(false)}
          onSuccess={() => {
            setShowModal(false);
            refetch();
          }}
        />
      </Modal>
    </div>
  );
};