import { Icon } from "@/components/atoms";

export interface BookingStatusIconProps {
  status: {
    icon: string;
    color: string;
  };
}

export const BookingStatusIcon = ({ status }: BookingStatusIconProps) => {
  if (status.icon === "close")
    return (
      <Icon
        name="close"
        size={20}
        className="-ml-[5px] -mr-[5px] -mt-[2px] inline-block shrink-0"
        style={{ color: status.color }}
        isCustom
      />
    );

  return <i style={{ backgroundColor: status.color }} className="inline-block h-[10px] w-[10px] shrink-0 rounded-full"></i>;
};
