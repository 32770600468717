import { Asset } from "@/redux/slices/brandingAndAcquisition/types";
import { GetAssetsRawResponse, GetAssetsResponse } from "./types";

export const transformAssets = (res: GetAssetsRawResponse) => {
  const rawAssets = res._embedded.assets;

  const assets = rawAssets.map((a) => {
    const { type, path } = a;

    return {
      type,
      path,
    } as Asset;
  });

  const response: GetAssetsResponse = {
    data: { assets },
    links: res._links,
  };

  return response;
};
