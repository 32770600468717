import { Float, FloatProps } from "@headlessui-float/react";
import { Menu, MenuItemProps, MenuProps } from "@headlessui/react";
import { clsx } from "@/utils";

export interface DropdownMenuProps extends MenuProps<"menu"> {
  button: JSX.Element;
  isCustomButton?: boolean;
  position?: FloatProps["placement"];
  offset?: number;
  disabled?: boolean;
  children: React.ReactNode;
}

export const DropdownMenu = ({
  button,
  isCustomButton,
  position = "bottom-end",
  offset = 10,
  disabled,
  children,
  className,
  ...props
}: DropdownMenuProps) => {
  return (
    <Menu as="menu" className={clsx("relative inline-flex items-center", className?.toString())} {...props}>
      <Float
        placement={position}
        offset={offset}
        enter="duration-100 ease-out"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-50"
        strategy="fixed"
        shift
        flip
        portal
        tailwindcssOriginClass
      >
        {isCustomButton ? (
          button
        ) : (
          <Menu.Button disabled={disabled} className="inline-flex w-full disabled:bg-neutral-surface-gray">
            {button}
          </Menu.Button>
        )}
        <Menu.Items className={clsx("rounded-md border bg-white py-2.5 text-base shadow-lg focus:outline-none")}>{children}</Menu.Items>
      </Float>
    </Menu>
  );
};

const DropdownMenuItem = ({ className, children, ...props }: MenuItemProps<"li">) => {
  return (
    <Menu.Item
      as="li"
      className={clsx("flex w-56 cursor-pointer items-center px-3 py-3 hover:bg-primary-light hover:text-primary", className?.toString())}
      {...props}
    >
      {children}
    </Menu.Item>
  );
};

DropdownMenu.Button = Menu.Button;
DropdownMenu.Item = DropdownMenuItem;
