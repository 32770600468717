import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetWaitingTimesQuery } from "@/redux/apis/config/admin/waitingTimes";
import { useAppSelector } from "@/redux/hooks";
import { waitingTimesSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { WaitingTimesEdit } from "./WaitingTimesEdit";

export const WaitingTimes = () => {
  const { isFetching } = useGetWaitingTimesQuery();
  const { errors, details } = useAppSelector(waitingTimesSelector);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  const renderWaitingTime = (time: string, recommended?: string) => {
    if (time) {
      return (
        <div className="flex flex-row">
          <Typography variant="paragraph" className="leading-8">
            {`${time} Minute${Number(time) > 1 ? "s" : ""}`}
          </Typography>
          {time === recommended && (
            <div className="ml-4 inline-flex rounded-full bg-neutral-dark-gray/10 px-4 py-1 text-neutral-dark-gray">
              <Typography className="self-center" variant="action">
                Recommended
              </Typography>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <Button className="ml-auto animate-enter" startIcon="Edit2" onClick={() => navigate("edit")}>
            Edit
          </Button>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Waiting Times
            </Typography>
            <Typography className="text-neutral-dark-gray">
              Set free waiting time periods to all drivers and customers for all your own jobs.
            </Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {renderErrors(errors)}
        <ConfigDetail title="International Airport" description="Free (default) waiting times for an international airport pickup.">
          {renderWaitingTime(details.internationalAirport, "60")}
        </ConfigDetail>
        <ConfigDetail title="Domestic Airport" description="Free (default) waiting times for a domestic airport pickup.">
          {renderWaitingTime(details.domesticAirport, "30")}
        </ConfigDetail>
        <ConfigDetail title="All other trips" description="Free (default) waiting times for all other trip.">
          {renderWaitingTime(details.standard, "10")}
        </ConfigDetail>
      </Panel>
    </>
  );
};

WaitingTimes.Edit = WaitingTimesEdit;
