import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "@/redux/hooks";
import { accessTokenSelector } from "@/redux/slices/auth/selectors";

export const RequireAuth = () => {
  const accessToken = useAppSelector(accessTokenSelector);

  if (accessToken) return <Outlet />;
  return <Navigate to="/login" replace />;
};
