import { Alert } from "@/components/atoms";
import { clsx } from "@/utils";
import { ConfirmPasswordField, ContactNumberField, EmailField, FirstNameField, HowDidYouHearAboutUsField, LastNameField, PasswordField } from "../forms/fields";

export const SignUpFormOperatorStep2 = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx("flex flex-col", className)}>
      <Alert 
        type="info" 
        message="Enter your personal contact details as the account owner, not your business details. You can add your business name and details later." 
        className="-mt-3 mb-[30px]"
      />
      <div className="animate-enter grid grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6" {...props}>
        <FirstNameField className="col-span-2 lg:col-span-1" />
        <LastNameField className="col-span-2 lg:col-span-1" />
        <EmailField className="col-span-2 lg:col-span-1" />
        <ContactNumberField className="col-span-2 lg:col-span-1" />
        <PasswordField className="col-span-2 lg:col-span-1" />
        <ConfirmPasswordField className="col-span-2 lg:col-span-1" />
        <HowDidYouHearAboutUsField className="col-span-2" />
      </div>
    </div>
  );
};
