import { useState } from "react";
import { Button, Typography } from "@/components/atoms";

export interface CostDetailsProps {
  subtotal: number;
  discount: number;
  postage: number;
}
export const CostDetails = ({ subtotal, discount, postage }: CostDetailsProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="flex flex-col">
      <Button
        className="-mr-2 mb-2 self-end !p-2 !py-0 text-neutral-dark-gray hover:!bg-none"
        variant="tertiary"
        endIcon={expanded ? "ArrowDown2" : "ArrowUp2"}
        onClick={handleClick}
      >
        {expanded ? "Close" : "View"} Cost Details{" "}
      </Button>
      <div className={`grid gap-2 ${expanded ? "grid" : "hidden"} mb-3`}>
        <div className="flex">
          <Typography variant="action" className="flex-1 text-neutral-dark-gray">
            Sub-total
          </Typography>
          <Typography variant="action" className="text-black">
            ${subtotal}
          </Typography>
        </div>
        <div className="flex">
          <Typography variant="action" className="flex-1 text-neutral-dark-gray">
            Postage Fee
          </Typography>
          <Typography variant="action" className="text-black">
            ${postage}
          </Typography>
        </div>
        <div className="flex">
          <Typography variant="action" className="flex-1 text-neutral-dark-gray">
            Discount
          </Typography>
          <Typography variant="action" className="text-black">
            - ${discount}
          </Typography>
        </div>
      </div>
      <div className="flex">
        <Typography variant="title" className="flex-1 text-black">
          Total Cost
        </Typography>
        <Typography variant="title" className="text-black">
          ${subtotal + postage - discount}
        </Typography>
      </div>
    </div>
  );
};
