import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { Option } from "@/redux/types";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

interface BusinessRegistrationTypeFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option;
}

export const BusinessRegistrationTypeField = ({ options, className, ...props }: BusinessRegistrationTypeFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <Typography>Business Registration Type</Typography>
      <Controller
        control={control}
        name="businessRegistrationType"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Registration Type"
            options={Object.entries(options).map(([key, value]) => ({ name: value, value: key }))}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            hasError={!!errors.businessRegistrationType}
            isNullable
          />
        )}
      />
      <ErrorMessage errors={errors} name="businessRegistrationType" />
    </div>
  );
};
