import { Alert, Button, Icon, Loading, Panel, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyGetOffloadingConfigurationQuery, useUpdateOffloadingConfigurationMutation } from "@/redux/apis/addOn/addOnApi";
import { AddOnItem, OffloadingConfiguration } from "@/redux/slices/addOn/types";
import { RawErrorResponse } from "@/redux/types";
import { addToast, clsx } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useToggleAddOn } from "../hooks/useToggleAddOn";
import { formSchema } from "./fixtures";
import { OffloadingDetailsDispatchSettings } from "./OffloadingDetailsDispatchSettings";
import { OffloadingEditDispatchSettings } from "./OffloadingEditDispatchSettings";

interface OffloadingPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  offloading: AddOnItem;
}

export const OffloadingPanel = ({ offloading, className, ...props }: OffloadingPanelProps) => {
  const navigate = useNavigate();
  const { mode, type } = useParams();
  const [getOffloadingConfiguration, { isFetching: isFetchingConfiguration }] = useLazyGetOffloadingConfigurationQuery();
  const configuration = offloading.configuration as OffloadingConfiguration | undefined;

  const [updateOffloadingConfiguration, { isLoading: isLoadingUpdate }] = useUpdateOffloadingConfigurationMutation();
  const methods = useForm<OffloadingConfiguration>({
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit, formState: { isDirty } } = methods;

  const { toggleAddOn, isLoading: isLoadingToggle } = useToggleAddOn({ 
    onEnableSuccess: () => addToast("success", "Offloading has been successfully enabled."),
    onDisableSuccess: () => null, 
    onEnableError: (e: RawErrorResponse) => getErrorMessages(e).forEach((message) => addToast("danger", message)),
    onDisableError: () => null,
    shouldUpdateOperator: false,
  });

  const isLoading = isFetchingConfiguration || isLoadingUpdate || isLoadingToggle;

  useEffect(() => {
    getOffloadingConfiguration();
  }, [getOffloadingConfiguration]);

  const onSave = handleSubmit((data) => {
    const {
      enabledAutomaticDispatchPrivateNetwork,
      dispatchAutoPrivateNetworkTime,
      dispatchAutoPrivateNetworkTimeframe,
      enabledAutomaticDispatchPublicNetwork,
      dispatchAutoPublicNetworkTime,
      dispatchAutoPublicNetworkTimeframe,
    } = data;

    updateOffloadingConfiguration({
      enabled_automatic_dispatch_private_network: enabledAutomaticDispatchPrivateNetwork,
      dispatch_auto_private_network_time: dispatchAutoPrivateNetworkTime,
      dispatch_auto_private_network_timeframe: dispatchAutoPrivateNetworkTimeframe,
      enabled_automatic_dispatch_public_network: enabledAutomaticDispatchPublicNetwork,
      dispatch_auto_public_network_time: dispatchAutoPublicNetworkTime,
      dispatch_auto_public_network_timeframe: dispatchAutoPublicNetworkTimeframe,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Offloading");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  });
    
  return (
    <Panel className={clsx("relative", className)} {...props}>
      {isLoading && <Loading className="rounded-lg" />}
      <FormProvider {...methods}>
        <form onSubmit={onSave}>
          <div className="flex flex-row items-center">
            <div className="flex flex-1 flex-row items-center gap-x-3">
              <div className="flex items-center justify-center bg-primary-dark rounded-md p-1.5">
                <Icon name="ArrangeVertical" size="sm" variant="Bold" className="text-white" />
              </div>
              <Typography variant="title" className="flex-1">Offloading (Add On)</Typography>
            </div>
            {offloading.enabled && (
              (mode === "edit" && type === "offloading") ? (
                <div className="flex flex-row items-center gap-4">
                  <Button variant="secondary" size="md" onClick={() => navigate("../")}>Cancel</Button>
                  <Button variant="primary" size="md" type="submit" disabled={!isDirty || isLoading}>Save</Button>
                </div>
              ) : <Button variant="primary" size="md" startIcon="Edit2" onClick={() => navigate("edit/offloading")}>Edit</Button>
            )}
          </div>
          {offloading.enabled ? (
            (mode === "edit" && type === "offloading") ? 
              <OffloadingEditDispatchSettings configuration={configuration} /> : 
              <OffloadingDetailsDispatchSettings configuration={configuration} />
          ) : (
            <Alert className="mt-4" type="info" message={(
              <Typography variant="action">This feature is an Add On. <span className="underline cursor-pointer" onClick={() => toggleAddOn("offloading", true)}>Activate Offloading</span></Typography>
            )}/>
          )}
        </form>
      </FormProvider>
    </Panel>
  );
};
