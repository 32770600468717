import { GetVehicleListRawResponse, GetVehicleListResponse, GetVehicleCategoriesRawResponse, GetVehicleCategoriesResponse } from "./types";

export const transformVehicleCategories = (res: GetVehicleCategoriesRawResponse) => {
  const {
    _embedded: { type: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetVehicleCategoriesResponse = {
    data: {
      items: items.map((i) => ({
        id: i.uuid,
        name: i.name,
        mappedGlobalVehicle: i.offload_to_global_vehicle_type,
        canCollectFromVehicles: i.inherit_from_vehicle_types,
        active: i.is_active,
        bags: i.attributes.bags,
        passenger: i.attributes.passengers,
        commissionRate: i.custom_commission.rate,
        commissionRateEnabled: i.custom_commission.enabled,
        waitTimeCost: i.wait_time_cost / 100,
        currency: i.currency,
        costIncrease: i.per_distance_unit_multiplier,
        order: i.order_score,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};

export const transformVehicleList = (res: GetVehicleListRawResponse) => {
  const { offload_to, inherit_from } = res;
  const response: GetVehicleListResponse = {
    data: {
      list: {
        offloadTo: offload_to.map((i) => ({
          name: i.name,
          id: i.uuid,
          bags: i.attributes.bags,
          passenger: i.attributes.passengers,
        })),
        inheritFrom: inherit_from.map((i) => ({
          name: i.name,
          id: i.uuid,
          bags: i.attributes.bags,
          passenger: i.attributes.passengers,
        })),
      },
    },
  };

  return response;
};
