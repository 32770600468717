import { Button, Divider, Icon, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { creditCardPaymentsTakeManualPaymentSelector } from "@/redux/slices/payment/selectors";
import { CreditCardPaymentsItem } from "@/redux/slices/payment/types";
import { formatCurrency } from "@/utils";
import { useGetCreditCardPaymentsParams } from "./hooks/useGetCreditCardPaymentsParams";

interface TakeManualPaymentStep3Props {
  payment: CreditCardPaymentsItem;
  onClose: () => void;
}

export const TakeManualPaymentStep3 = ({ payment, onClose }: TakeManualPaymentStep3Props) => {
  const { currency } = payment;
  const { manualPaymentDetails } = useAppSelector(creditCardPaymentsTakeManualPaymentSelector);
  const { setSelectedStatus } = useGetCreditCardPaymentsParams();

  const renderTotalAmount = (totalAmount: number) => (
    <Typography variant="h1">{`${currency} ${formatCurrency(totalAmount, 2, currency)}`}</Typography>
  );

  const renderDisplayMessage = () => {
    if (manualPaymentDetails) {
      switch (manualPaymentDetails.status) {
        case "FAILED":
          return (
            <div className="flex flex-col items-center">
              <Icon className="text-inherit mb-8 flex-shrink-0 self-center text-danger" name="CloseCircle" variant="Bold" size={116} />
              {renderTotalAmount(manualPaymentDetails.amounts.total)}
              <Typography variant="title">Your payment is unsuccessful</Typography>
              <Typography variant="paragraph" className="mt-5 text-center text-neutral-dark-gray">
                Manual payment failed and has been moved to the
                <br />
                &quot;Failed Collections&quot; tab for follow-up.
              </Typography>
            </div>
          );
        default:
          return (
            <div className="flex flex-col items-center">
              <Icon className="text-inherit mb-8 flex-shrink-0 self-center text-success" name="TickCircle" variant="Bold" size={116} />
              {renderTotalAmount(manualPaymentDetails.amounts.total)}
              <Typography variant="title">Your payment is complete</Typography>
              <Typography variant="paragraph" className="mt-5 text-center text-neutral-dark-gray">
                You can now close this tab and go back to the
                <br />
                Payments page
              </Typography>
            </div>
          );
      }
    }
  };

  const renderViewInFailedCollectionsButton = () => {
    if (manualPaymentDetails && manualPaymentDetails.status === "FAILED") {
      return (
        <div>
          <Divider />
          <div className="flex flex-col gap-3 px-4 py-5">
            <Button
              className="w-full text-center"
              size="lg"
              onClick={() => {
                setSelectedStatus({ status: "failed" });
                onClose();
              }}
            >
              View in Failed Collections
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="relative flex w-full">
      <div className="relative flex h-full w-full flex-col">
        <div className="h-full flex-1 overflow-y-auto">
          <div className="flex h-full flex-col items-center pt-[210px]">{renderDisplayMessage()}</div>
        </div>
        {renderViewInFailedCollectionsButton()}
      </div>
    </div>
  );
};
