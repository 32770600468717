import { format, parseISO } from "date-fns";
import { isEmpty } from "ramda";
import { IconButton, Loading, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useDeleteInvoiceNoteMutation, useGetInvoiceNotesQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppSelector } from "@/redux/hooks";
import { invoicingInvoicesSelector } from "@/redux/slices/payment/selectors";
import { Invoice } from "@/redux/slices/payment/types";
import { addToast, clsx, getAssetUrl, getInitials } from "@/utils";
import { InvoiceNotesSkeleton } from "./InvoiceNotesSkeleton";

interface InvoiceNotesProps extends React.HTMLAttributes<HTMLDivElement> {
  invoice: Invoice;
}

export const InvoiceNotes = ({ invoice, className, ...props }: InvoiceNotesProps) => {
  const { dateFormat, timeFormat, id } = useProfile();
  const [deleteInvoiceNote, { isLoading }] = useDeleteInvoiceNoteMutation();
  const { isFetching } = useGetInvoiceNotesQuery({ id: invoice.id }, { refetchOnMountOrArgChange: true });
  const { invoiceNotes } = useAppSelector(invoicingInvoicesSelector);
  const containerClassName = "flex flex-col relative gap-y-4";

  const renderAvatar = (avatar: string | null, name: string, order: number) => {
    if (avatar) {
      return <img src={getAssetUrl(avatar)} alt={name} className="h-8 w-8 rounded-full bg-white object-cover" />;
    }

    return (
      <div
        className={clsx("flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full text-white", {
          "bg-[#3498DB]": order % 1 === 0,
          "bg-[#E74C3C]": order % 2 === 0,
          "bg-[#2ECC71]": order % 3 === 0,
          "bg-[#F39C12]": order % 4 === 0,
          "bg-[#9B59B6]": order % 5 === 0,
        })}
      >
        <Typography variant="small">{getInitials(name)}</Typography>
      </div>
    );
  };

  const renderDeleteButton = (invoiceNoteId: string, accountId: string) => {
    if (id === accountId) {
      return (
        <IconButton
          variant="tertiary"
          iconName="Trash"
          size="sm"
          className="!p-[5px] text-neutral-dark-gray hover:bg-danger-light hover:text-danger"
          onClick={() => {
            deleteInvoiceNote({ invoiceId: invoice.id, noteId: invoiceNoteId })
              .unwrap()
              .then(() => addToast("success", "Successfully Deleted Note"))
              .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
          }}
        />
      );
    }
  };

  if (isFetching) return <InvoiceNotesSkeleton className={clsx(containerClassName, className)} />;

  return (
    <div className={clsx(containerClassName, className)} {...props}>
      {isLoading && <Loading />}
      {isEmpty(invoiceNotes) ? (
        <EmptyState title="Invoice Notes" description="There are currently no notes available" />
      ) : (
        <>
          {invoiceNotes.map((invoiceNote, i) => {
            const { account, isDeleted } = invoiceNote;
            const name = `${account.firstName} ${account.lastName}`;
            return (
              <div
                key={invoiceNote.id}
                className={clsx("flex flex-col rounded-lg bg-neutral-surface-gray px-5 pb-2.5 pt-5", { "bg-danger-light": isDeleted })}
              >
                <div className="flex flex-row">
                  <div className="flex flex-row items-center gap-x-3">
                    {renderAvatar(account.avatar, name, i + 1)}
                    <Typography variant="action">{name}</Typography>
                  </div>
                  <div className="flex flex-1 items-center justify-end">
                    {isDeleted ? (
                      <Typography variant="small" className="text-danger">
                        Deleted
                      </Typography>
                    ) : (
                      renderDeleteButton(invoiceNote.id, account.id)
                    )}
                  </div>
                </div>
                <Typography variant="paragraph" className={clsx("mt-3", { "line-through": isDeleted })}>
                  {invoiceNote.content}
                </Typography>
                <div className="mt-2.5 flex flex-row gap-x-4">
                  <Typography variant="small" className="text-neutral-dark-gray">
                    {format(parseISO(invoiceNote.createdAt), dateFormat)}
                  </Typography>
                  <Typography variant="small" className="text-neutral-dark-gray">
                    {format(parseISO(invoiceNote.createdAt), timeFormat)}
                  </Typography>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
