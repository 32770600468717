import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAccount } from "@/redux/baseQuery";
import { transformNotifications } from "./helpers";
import { GetNotificationsResponse, MarkReadNotificationsResponse } from "./types";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: baseQueryWithAccount("notifications"),
  endpoints: (builder) => ({
    getNotifications: builder.query<GetNotificationsResponse, string>({
      query: (page) => {
        let queryString = "?";
        if (page) queryString = `${queryString}&page=${page}`;
        return queryString;
      },
      providesTags: ["Notification"],
      transformResponse: transformNotifications,
    }),
    markReadNotifications: builder.mutation<MarkReadNotificationsResponse, void>({
      query: () => ({
        url: "/all",
        method: "POST",
        body: { read: true },
      }),
    }),
  }),
  tagTypes: ["Notification"],
});

export const { useGetNotificationsQuery, useLazyGetNotificationsQuery, useMarkReadNotificationsMutation } = notificationApi;
