import { z } from "zod";

export const profileSchema = z.object({
  title: z.string().nonempty({ message: "Title is required" }),
  firstName: z.string().nonempty({ message: "First name is required" }),
  lastName: z.string().nonempty({ message: "Last name is required" }),
  email: z.string().email({ message: "Email is invalid" }),
  mobile: z.string().nonempty({ message: "Mobile number is required" }),
  notificationEmail: z.string().email({ message: "Notification email is invalid" }),
  timezone: z.string().nonempty({ message: "Timezone is required" }),
  dateFormat: z.string().nonempty({ message: "Date format is required" }),
  timeFormat: z.string().nonempty({ message: "Time format is required" }),
});
