import SignupConfetti from "@/assets/images/signup/confetti.svg";
import { Button, Typography } from "@/components/atoms";
import { useOperator, useSiteConfig } from "@/hooks";

export const SignUpSuccessClient = () => {
  const { baseUrl } = useSiteConfig();
  const { name } = useOperator();

  const handleRedirect = () => window.location.href = baseUrl;

  return (
    <div className="flex flex-col items-center text-center">
      <img src={SignupConfetti} className="h-[256px] w-[256px]" />
      <Typography variant="h2" className="mt-5">Passenger sign up successful!</Typography>
      <Typography variant="paragraph" className="mt-7 max-w-[355px]">{`Your account has been created! Start using ${name} right away!`}</Typography>
      <Button variant="primary" size="lg" className="w-full md:w-auto mt-7" onClick={handleRedirect}>
        Explore {name}
      </Button>
    </div>
  );
};
