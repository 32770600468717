import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { DynamicPricingState } from "@/redux/slices/pricing/types";
import { generateDynamicPricingColumns, generateDynamicPricingData } from "./helpers";

interface StateBasedDynamicPricingTableProps extends React.HTMLAttributes<HTMLDivElement> {
  pricing: DynamicPricingState;
}

export const StateBasedDynamicPricingTable = ({ pricing, className, ...props }: StateBasedDynamicPricingTableProps) => {
  const navigate = useNavigate();
  const { name, state, background, baseInterval, intervals, currency, distanceUnit } = pricing;

  const data = useMemo(
    () => generateDynamicPricingData(baseInterval, intervals, currency, distanceUnit),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intervals]
  );

  const columns = useMemo(
    () => generateDynamicPricingColumns(distanceUnit),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={className} {...props}>
      <div className="flex items-center">
        <div className="flex flex-1 items-center gap-x-4">
          <img src={background} className="h-12 w-12 shrink-0 rounded-full object-cover" />
          <Typography variant="h3" className="leading-8">
            {name}
          </Typography>
        </div>
        <div className="flex-shrink-0">
          <Button startIcon="Edit2" onClick={() => navigate("edit")} className="bg-primary px-6 hover:bg-primary-dark">
            Edit
          </Button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        className="[&_table]:table-auto overflow-x-auto w-full mt-6"
        emptyState={
          <div className="flex flex-col items-center justify-center bg-white py-28">
            <Button variant="tertiary" size="lg" startIcon="Add" onClick={() => navigate("edit")}>
              Add First Interval
            </Button>
            <Typography variant="paragraph" className="mt-3 text-center text-neutral-dark-gray">
              {`Set up your minimum Trip Cost for ${state.toUpperCase()} and add`}
              <br />
              your custom cost intervals
            </Typography>
          </div>
        }
      />
    </div>
  );
};
