import { useEffect } from "react";
import { Icon, IconButton, Typography, SidePanel, Loading } from "@/components/atoms";
import { useLazyGetBookingDetailsQuery } from "@/redux/apis/booking/bookingApi";
import { transformBookingDetails } from "@/redux/apis/booking/helpers";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInlineEditing, setPaymentAction, setSelectedBooking } from "@/redux/slices/booking/bookingSlice";
import { bookingPaymentActionSelector } from "@/redux/slices/booking/selectors";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { PaymentActions } from "./PaymentActions";
import { PaymentLogs } from "./PaymentLogs";

interface PaymentsProps {
  open: boolean;
  onClose: () => void;
  bookingId?: string;
}

export const Payments = ({ open, onClose, bookingId }: PaymentsProps) => {
  const dispatch = useAppDispatch();
  const selectedBooking = useSelectedBooking(true);
  const action = useAppSelector(bookingPaymentActionSelector);
  const [getBookingDetails, { isFetching }] = useLazyGetBookingDetailsQuery();

  useEffect(() => {
    if (bookingId) {
      getBookingDetails(bookingId)
        .unwrap()
        .then((booking) => {
          dispatch(setSelectedBooking(transformBookingDetails(booking)));
        })
        .catch(() => {
          throw new Error(`Error fetching booking details for: ${bookingId}`);
        });

      return () => {
        dispatch(setSelectedBooking(undefined));
        dispatch(setInlineEditing(undefined));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  const handleClose = () => {
    onClose();
    dispatch(setPaymentAction(undefined));
  };

  return (
    <SidePanel open={open} onClose={handleClose} width={800} focused>
      <header className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="DollarCircle" size="lg" />
          <Typography variant="title">Payment Logs {selectedBooking && `(${selectedBooking.id})`}</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={handleClose} className="-mr-2" variant="custom" />
        </div>
      </header>
      {selectedBooking && !isFetching ? action ? <PaymentActions action={action} /> : <PaymentLogs /> : <Loading />}
    </SidePanel>
  );
};
