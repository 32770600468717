import { minutesToHours } from "date-fns";
import { Typography } from "@/components/atoms";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const TransferDetails = () => {
  const { vehicleType, transferType } = useSelectedBooking();

  return (
    <div className="space-y-3">
      <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="w-[105px] shrink-0 self-start text-neutral-black/70">
          Transfer Type:
        </Typography>
        <Typography variant="small" className="truncate">
          {["hourly_rate", "daily_rate"].includes(transferType.type)
            ? `${transferType.name} (${minutesToHours(transferType.duration)} hours)`
            : transferType.name}
        </Typography>
      </div>
      <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="w-[105px] shrink-0 self-start text-neutral-black/70">
          Vehicle Category:
        </Typography>
        <Typography variant="small" className="overflow-clip overflow-ellipsis whitespace-nowrap ">
          {vehicleType.name}
        </Typography>
      </div>
    </div>
  );
};
