import { Textarea } from "@headlessui/react";
import { forwardRef, useState } from "react";
import { clsx } from "@/utils";
import { Typography } from "..";

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hideCharCount?: boolean;
  hasError?: boolean;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((textAreaProps, ref) => {
  const { maxLength, className, rows = 4, hideCharCount = false, hasError = false, ...props } = textAreaProps;
  const [charCount, setCharCount] = useState(0);

  const errorClass = {
    "border-danger focus:border-danger": hasError,
  };

  const renderCharCount = () => {
    if (!hideCharCount && maxLength) {
      const charLeft = maxLength - charCount;
      return <Typography variant="small" className="text-neutral-dark-gray">{`${charLeft} characters left`}</Typography>;
    }
  };

  return (
    <div>
      <Textarea
        ref={ref}
        className={clsx(
          "block w-full resize-none rounded-lg border-neutral-mid-gray px-4 text-base text-neutral-black placeholder-neutral-dark-gray focus:border-primary focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-surface-gray",
          errorClass,
          className
        )}
        rows={rows}
        onInput={(e) => setCharCount((e.target as HTMLInputElement).value.length)}
        maxLength={maxLength}
        {...props}
      />
      {renderCharCount()}
    </div>
  );
});

TextArea.displayName = "TextArea";
