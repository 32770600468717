import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table";
import { isEmpty } from "ramda";
import { clsx } from "@/utils";
import { NoSearchResults } from "../EmptyStates/NoSearchResults";
import { DataTableProps } from "./types";
import { DefaultTable } from "./variants/DefaultTable";
import { SortableTable } from "./variants/SortableTable";

export const tableClass = "rounded-lg w-full divide-y divide-neutral-mid-gray divide-opacity-50";

export const DataTable = <T extends object>({
  data,
  columns,
  onReorder,
  sorting,
  setSorting,
  canExpand,
  renderSubComponent,
  getRowProps,
  getIsRowExpanded,
  emptyState,
  className,
  ...props
}: DataTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    ...(sorting && {
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      manualSorting: true,
    }),
    ...(canExpand && {
      getRowCanExpand: () => true,
      getExpandedRowModel: getExpandedRowModel(),
      getIsRowExpanded: getIsRowExpanded,
    }),
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const renderTable = () => {
    const restProps = { renderSubComponent, getRowProps };

    switch (true) {
      case isEmpty(data):
        return (
          <DefaultTable table={table}>
            <tr className="hover:!bg-white [&_section]:w-full">
              <td colSpan={columns.length}>{emptyState ? emptyState : <NoSearchResults />}</td>
            </tr>
          </DefaultTable>
        );

      case !!onReorder: {
        const onDragEnd = (e: DragEndEvent) => {
          const { active, over } = e;
          const rows = table.getRowModel().rows;

          if (active.id !== over?.id) {
            const activeIndex = rows.findIndex((i) => i.id === active.id);
            const overIndex = rows.findIndex((i) => i.id === over?.id);
            onReorder!(arrayMove(data, activeIndex, overIndex));
          }
        };
        return <SortableTable table={table} onDragEnd={onDragEnd} {...restProps} />;
      }

      default:
        return <DefaultTable table={table} {...restProps} />;
    }
  };

  return (
    <div
      className={clsx(
        "relative rounded-lg ring-1 ring-neutral-mid-gray ring-opacity-50 [&_tbody_tr:hover]:bg-neutral-gray [&_#icon]:[&_tbody_tr:hover]:bg-neutral-mid-gray",
        className
      )}
      {...props}
    >
      {renderTable()}
    </div>
  );
};
