import { Client } from "@/redux/slices/client/types";
import { GetClientOptionsRawResponse, GetClientOptionsResponse, GetClientsRawResponse, GetClientsResponse } from "./types";

export const transformClients = (res: GetClientsRawResponse) => {
  const {
    _embedded: { client: clients },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetClientsResponse = {
    data: {
      clients: clients.map(
        (i) =>
          ({
            id: i.uuid,
            name: i.name,
            email: i.email,
            phone: i.phone,
            companyId: i.company?.uuid,
            companyName: i.company?.name,
            accountType: i.account_type,
            hearAboutUs: i.hear_about_us,
            status: i.status,
            joined: i.join_date,
          } as Client)
      ),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformClientOptions = (res: GetClientOptionsRawResponse) => {
  const response: GetClientOptionsResponse = {
    data: {
      options: {
        accountType: res.account_type,
        status: res.status,
      },
    },
  };

  return response;
};
