import { useAppAbility } from "@/casl";
import { AppAction, AppSubject } from "@/casl/types";
import { Panel, Typography } from "@/components/atoms";
import { PageList } from "@/components/molecules";
import { AccessControl } from "./AccessControl/AccessControl";
import { BillingSettings } from "./BillingSettings/BillingSettings";
import { BusinessDetails } from "./BusinessDetails/BusinessDetails";
import { DispatchSettings } from "./DispatchSettings/DispatchSettings";
import { DisplayOptions } from "./DisplayOptions/DisplayOptions";
import { EmailNotifications } from "./EmailNotifications/EmailNotifications";
import { SMSVoiceNotifications } from "./SMSVoiceNotifications/SMSVoiceNotifications";
import { WaitingTimes } from "./WaitingTimes/WaitingTimes";
import { WebsiteDetails } from "./WebsiteDetails/WebsiteDetails";

type Subpage = {
  name: string;
  desc: string;
  to: string;
  icon: string;
  permission?: [AppAction, AppSubject];
};

const subpages: Subpage[] = [
  {
    name: "Business Details",
    desc: "Your public company information.",
    to: "business-details",
    icon: "Buildings",
  },
  {
    name: "Website Details",
    desc: "URLs for your website, google reviews and important links.",
    to: "website-details",
    icon: "Global",
  },
  {
    name: "Email Notifications",
    desc: "Email notification settings and hotline phone numbers.",
    to: "email-notifications",
    icon: "Notification",
  },
  {
    name: "SMS / Voice Notifications",
    desc: "SMS and Voice notification settings.",
    to: "sms-voice-notifications",
    icon: "MessageNotif",
  },
  {
    name: "Billing Settings",
    desc: "Billing configuration options.",
    to: "billing-settings",
    icon: "ReceiptText",
    permission: ["manage", "providerConfigProfileAdvanced"],
  },
  {
    name: "Access Control",
    desc: "Controls enabling / disabling site features.",
    to: "access-control",
    icon: "Setting3",
    permission: ["manage", "providerConfigProfileAdvanced"],
  },
  {
    name: "Dispatch Settings",
    desc: "Configuration settings to determine when to dispatch jobs.",
    to: "dispatch-settings",
    icon: "Export",
    permission: ["manage", "providerConfigProfileAdvanced"],
  },
  {
    name: "Waiting Times",
    desc: "Set default airport and other waiting times.",
    to: "waiting-times",
    icon: "Timer1",
    permission: ["manage", "providerConfigProfileAdvanced"],
  },
  {
    name: "Display Options",
    desc: "Configure what your users can see.",
    to: "display-options",
    icon: "Eye",
  },
];

export const Admin = () => {
  const ability = useAppAbility();

  return (
    <Panel>
      <Typography variant="h3">Admin Settings</Typography>
      <PageList className="-mx-5 mt-2">
        {subpages.map(({ name, desc, to, icon, permission }) => (
          <PageList.Item
            key={name}
            title={name}
            description={desc}
            to={to}
            icon={icon}
            locked={permission ? !ability.can(permission[0], permission[1]) : false}
          />
        ))}
      </PageList>
    </Panel>
  );
};

Admin.BusinessDetails = BusinessDetails;
Admin.WebsiteDetails = WebsiteDetails;
Admin.EmailNotifications = EmailNotifications;
Admin.SMSVoiceNotifications = SMSVoiceNotifications;
Admin.BillingSettings = BillingSettings;
Admin.AccessControl = AccessControl;
Admin.DispatchSettings = DispatchSettings;
Admin.WaitingTimes = WaitingTimes;
Admin.DisplayOptions = DisplayOptions;
