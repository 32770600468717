import { Button, Typography } from "@/components/atoms";

interface RangeDeleteProps extends React.HTMLAttributes<HTMLDivElement> {
  range: number;
  onDeleteRow: () => void;
  onClose: () => void;
}

export const RangeDelete = ({ range, onDeleteRow, onClose, className, ...props }: RangeDeleteProps) => {
  const rangeDisplay = range + 2;

  return (
    <div className={className} {...props}>
      <div className="max-w-lg">
        <div className="flex flex-col">
          <Typography variant="title">Delete Range {rangeDisplay}</Typography>
          <Typography variant="paragraph" className="mt-3">
            This will delete Range {rangeDisplay}. You cannot undue this action, do you wish to continue?
          </Typography>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="secondary" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button variant="primary" onClick={onDeleteRow} size="md" className="bg-danger hover:bg-danger-dark">
          Delete
        </Button>
      </div>
    </div>
  );
};
