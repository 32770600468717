import { useMemo } from "react";
import { DataTable } from "@/components/molecules";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { generateColumns, generateTableData } from "./helpers";
import { clsx } from "@/utils";
import { useOperator } from "@/hooks";

interface ExtrasTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: CostExtrasItem[];
}

export const ExtrasTable = ({ items, className, ...props }: ExtrasTableProps) => {
  const { currency } = useOperator();

  const data = useMemo(
    () => generateTableData(items, currency),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateColumns(data), [data]);

  return <DataTable className={clsx("[&_table]:table-auto overflow-x-auto w-full", className)} columns={columns} data={data} {...props} />;
};
