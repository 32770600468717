import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { DropdownSelect, type DropdownSelectOption, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { bookingFormOptionsSelector } from "@/redux/slices/booking/selectors";
import { BookingFilters } from "@/redux/slices/booking/types";
import { useBookingFilters } from "../../../hooks/useBookingFilters";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  assignedVehicle: z.string(),
});

type FormData = z.infer<typeof schema>;

const getValue = (assignedVehicle: BookingFilters["assignedVehicle"], assignedVehicles: DropdownSelectOption[]) => {
  if (!assignedVehicle) return assignedVehicle;
  return assignedVehicles.find((s) => s.value === assignedVehicle)?.name;
};

export const AssignedVehicleFilter = () => {
  const { assignedVehicles } = useAppSelector(bookingFormOptionsSelector);
  const {
    filters: { assignedVehicle },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => {
    return getValue(assignedVehicle, assignedVehicles);
  }, [assignedVehicle, assignedVehicles]);

  const onClose = () => setFilter("assignedVehicle", undefined);

  return (
    <FilterPopover className="overflow-visible" name="assignedVehicle" value={value} label="Assigned vehicle" onClose={onClose}>
      <AssignedVehicleFilterForm />
    </FilterPopover>
  );
};

interface AssignedVehicleFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const AssignedVehicleFilterDrawer = ({ open, onOpenChange }: AssignedVehicleFilterDrawerProps) => {
  const { assignedVehicles } = useAppSelector(bookingFormOptionsSelector);
  const {
    filters: { assignedVehicle },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => {
    return getValue(assignedVehicle, assignedVehicles);
  }, [assignedVehicle, assignedVehicles]);

  const onClose = () => setFilter("assignedVehicle", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Assigned vehicle" onClose={onClose} minHeight={400}>
      <AssignedVehicleFilterForm />
    </FilterDrawer>
  );
};

const AssignedVehicleFilterForm = () => {
  const { assignedVehicles } = useAppSelector(bookingFormOptionsSelector);
  const { setFilter, filters } = useBookingFilters();

  const {
    control,
    formState: { errors: formErrors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { assignedVehicle: filters.assignedVehicle ? filters.assignedVehicle : undefined },
  });

  return (
    <div className="contents">
      <Typography className="mb-4">Assigned Vehicle</Typography>

      <Controller
        control={control}
        name="assignedVehicle"
        render={({ field }) => (
          <DropdownSelect
            size="md"
            placeholder="Select Assigned Vehicle"
            isNullable
            options={assignedVehicles}
            value={field.value}
            onChange={(value: DropdownSelectOption["value"]) => {
              field.onChange(value);
              setFilter("assignedVehicle", value);
            }}
            hasError={!!formErrors.assignedVehicle}
            disabled={assignedVehicles.length === 0}
            optionsClassName="z-30"
          />
        )}
      />
    </div>
  );
};
