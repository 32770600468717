import cx from "classnames";
import { Icon, Typography } from "@/components/atoms";

export interface StepIndicator extends React.HTMLAttributes<HTMLOListElement> {
  numSteps: number;
  currentStep: number;
  onStepClick?: (step: number) => void;
}

export const StepIndicator = ({ currentStep, numSteps, className, onStepClick, ...props }: StepIndicator) => {
  return (
    <ol role="list" className={cx("flex items-center pb-8", className)} {...props}>
      {[...Array(numSteps).keys()].map((step) => (
        <li key={step} className={cx(step !== numSteps - 1 ? "pr-8 sm:pr-32" : "", "relative")}>
          {step < currentStep - 1 ? (
            <>
              <div className="absolute inset-0 flex animate-enter items-center" aria-hidden="true">
                <div className="h-0.5 w-full bg-primary" />
              </div>
              <button
                onClick={onStepClick ? () => onStepClick(step) : undefined}
                className="relative flex h-8 w-8 items-center justify-center rounded-full bg-white"
              >
                <Icon name="TickCircle" variant="Bold" size={38} className="absolute inline-block text-primary" aria-hidden="true" />
                <Typography variant="action" className="absolute top-full mt-1 whitespace-nowrap text-primary">
                  {`Step ${step + 1}`}
                </Typography>
              </button>
            </>
          ) : step === currentStep - 1 ? (
            <>
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="h-0.5 w-full bg-neutral-dark-gray" />
              </div>
              <button
                onClick={onStepClick ? () => onStepClick(step) : undefined}
                className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white"
                aria-current="step"
              >
                <Typography variant="title" className="leading-none text-primary">
                  {step + 1}
                </Typography>

                <Typography variant="action" className="absolute top-full mt-1 whitespace-nowrap text-primary">
                  {`Step ${step + 1}`}
                </Typography>
              </button>
            </>
          ) : (
            <>
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="h-0.5 w-full bg-neutral-dark-gray" />
              </div>
              <button
                onClick={onStepClick ? () => onStepClick(step) : undefined}
                className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-neutral-dark-gray bg-white"
              >
                <Typography variant="title" className="leading-none text-neutral-dark-gray">
                  {step + 1}
                </Typography>
                <Typography variant="action" className="absolute top-full mt-1 whitespace-nowrap text-neutral-dark-gray">
                  {`Step ${step + 1}`}
                </Typography>
              </button>
            </>
          )}
        </li>
      ))}
    </ol>
  );
};
