import { isEmpty } from "ramda";
import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { Option } from "@/redux/types";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

interface InvoiceGenerateLevelFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option;
}

export const InvoiceGenerateLevelField = ({ options, className, ...props }: InvoiceGenerateLevelFieldProps) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();
  const watchBillingContacts = watch("billingContacts");

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <Typography>Set Invoice Level</Typography>
      <Controller
        control={control}
        name="invoiceGenerateLevel"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Level"
            options={Object.entries(options).map(([key, value]) => ({ name: value, value: key }))}
            value={field.value?.toString()}
            onChange={(value) => {
              const isCompanyLevel = value === "company" || value === "client_no_invoice";
              if (isCompanyLevel && isEmpty(watchBillingContacts)) {
                setValue("billingContacts", [{ name: "", emailAddress: "", contactNumber: "" }]);
              }
              field.onChange(value);
            }}
            hasError={!!errors.invoiceGenerateLevel}
          />
        )}
      />
      <ErrorMessage errors={errors} name="invoiceGenerateLevel" />
    </div>
  );
};
