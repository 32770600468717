import { useNavigate } from "react-router-dom";
import Warning from "@/assets/images/warning.png";
import { Button, Container, Typography } from "@/components/atoms";
import { GeneralFooter, GeneralHeader } from "@/components/organisms";

export interface ExpiredProps {
  title?: string;
  description?: string;
}

export const Expired = ({ title = "Expired", description = "" }: ExpiredProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row min-h-screen overflow-hidden">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        <Container className="flex flex-1 justify-center w-full max-w-[1040px] gap-5 py-8 md:py-[100px] px-5">
          <div className="flex flex-col items-center max-w-[450px]">
            <img src={Warning} alt="Warning" className="max-w-[128px]" />
            <Typography variant="h1" className="mb-4 text-center">
              {title}
            </Typography>
            <Typography variant="paragraph" className="text-center text-neutral-dark-gray">
              {description}
            </Typography>
            <Button size="lg" onClick={() => navigate("/forgot-password")} className="mt-8">
              Go Back
            </Button>
          </div>
        </Container>
        <div className="fixed bottom-0 bg-city min-h-[50%] w-full bg-cover bg-no-repeat pointer-events-none" />
        <GeneralFooter />
      </div>
    </div>
  );
};
