import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Loading, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useDriversFilters } from "../useDriversFilters";
import { useGetFleetOptionsQuery } from "@/redux/apis/driver/driverApi";
import { fleetOptionsSelector } from "@/redux/slices/driver/selectors";

const schema = z.object({
  tier: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const TierFilter = () => {
  const {
    filters: { tier },
    setFilter,
  } = useDriversFilters();

  const value = useMemo(() => {
    if (!tier) return undefined;

    return tier.filter(Boolean).join(", ");
  }, [tier]);

  const onClose = () => setFilter("tier", undefined);

  return (
    <FilterPopover name="tier" value={value} label="Tier" onClose={onClose}>
      <TierFilterForm />
    </FilterPopover>
  );
};

interface TierFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const TierFilterDrawer = ({ open, onOpenChange }: TierFilterDrawerProps) => {
  const {
    filters: { tier },
    setFilter,
  } = useDriversFilters();

  const value = useMemo(() => {
    if (!tier) return undefined;

    return tier.filter(Boolean).join(", ");
  }, [tier]);

  const onClose = () => setFilter("tier", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Tier" onClose={onClose}>
      <TierFilterForm />
    </FilterDrawer>
  );
};

const TierFilterForm = () => {
  const { isFetching } = useGetFleetOptionsQuery();
  const { tier: tiers } = useAppSelector(fleetOptionsSelector);
  const { setFilter, filters } = useDriversFilters();

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { tier: (filters.tier as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "tier",
          tiers.map((tier) => tier.value)
        );
        setValue(
          "tier",
          tiers.map((tier) => tier.value)
        );
      } else {
        setFilter("tier", null);
        setValue("tier", []);
      }
    },
    [setFilter, setValue, tiers]
  );

  const isAllTiersSelected = useMemo(() => {
    if (!filters.tier) return false;
    return filters.tier.length === tiers.length;
  }, [filters.tier, tiers]);

  return (
    <div className="relative contents">
      {isFetching && <Loading className="rounded-lg" />}
      <Typography className="mb-4">Tier</Typography>
      <CheckboxInput label="All Tiers" onChange={handleCheckAll} variant="check" checked={isAllTiersSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="tier"
          render={({ field }) => (
            <>
              {tiers.map((tier) => (
                <CheckboxInput
                  key={tier.value}
                  label={tier.name}
                  value={tier.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newTier = [...field.value, e.target.value];
                      field.onChange(newTier);
                      setFilter("tier", newTier.length > 0 ? newTier : null);
                    } else {
                      const newTier = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newTier);
                      setFilter("tier", newTier.length > 0 ? newTier : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(tier.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
