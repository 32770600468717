import { GetAddOnsRawResponse, GetAddOnsResponse, GetGoogleTagManagerConfigurationRawResponse, GetGoogleTagManagerConfigurationResponse, GetInvoicingConfigurationRawResponse, GetInvoicingConfigurationResponse, GetOffloadingConfigurationRawResponse, GetOffloadingConfigurationResponse } from "./types";

export const transformAddOns = (res: GetAddOnsRawResponse) => {
  const { _embedded: { add_on } } = res;

  const response: GetAddOnsResponse = {
    data: { 
      addOns: add_on.map(i => ({
        identifier: i.identifier,
        name: i.name,
        cost: i.cost,
        costType: i.cost_type,
        currency: i.currency,
        freeTrialPeriod: i.free_trial_period,
        freeTrialExpiry: i.free_trial_expiry,
        enabled: i.enabled,
        featured: i.featured,
        recommended: i.recommended,
        available: i.available,
        premium: i.premium,
      }))
    },
  };

  return response;
};

export const transformOffloadingConfiguration = (res: GetOffloadingConfigurationRawResponse) => {
  const response: GetOffloadingConfigurationResponse = {
    data: { 
      enabledAutomaticDispatchPublicNetwork: res.enabled_automatic_dispatch_public_network,
      dispatchAutoPublicNetworkTime: res.dispatch_auto_public_network_time.toString(),
      dispatchAutoPublicNetworkTimeframe: res.dispatch_auto_public_network_timeframe,
      enabledAutomaticDispatchPrivateNetwork: res.enabled_automatic_dispatch_private_network,
      dispatchAutoPrivateNetworkTime: res.dispatch_auto_private_network_time.toString(),
      dispatchAutoPrivateNetworkTimeframe: res.dispatch_auto_private_network_timeframe,
    },
  };

  return response;
};

export const transformInvoicingConfiguration = (res: GetInvoicingConfigurationRawResponse) => {
  const response: GetInvoicingConfigurationResponse = {
    data: { 
      invoiceModuleEnabled: res.invoice_module_enabled,
    },
  };

  return response;
};

export const transformGoogleTagManagerConfiguration = (res: GetGoogleTagManagerConfigurationRawResponse) => {
  const response: GetGoogleTagManagerConfigurationResponse = {
    data: { 
      property: res.property,
    },
  };

  return response;
};