import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetAccreditationHistoryQuery, useGetAccreditationItemQuery } from "@/redux/apis/accreditation/accreditationApi";
import { useAppSelector } from "@/redux/hooks";
import { accreditationHistoryPageSelector, accreditationHistorySelector } from "@/redux/slices/accreditation/selectors";
import { addToast } from "@/utils";
import { AccreditationHistoryTable } from "./tables/AccreditationHistoryTable";

interface AccreditationHistoryPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  accreditationId: string;
}

export const AccreditationHistoryPanel = ({ accreditationId, className, ...props }: AccreditationHistoryPanelProps) => {
  const navigate = useNavigate();
  const { isFetching: isFetchingAccreditationItem, data: accreditationItem } = useGetAccreditationItemQuery(accreditationId);
  const [getAccreditationHistory, { isFetching: isFetchingAccreditationHistory }] = useLazyGetAccreditationHistoryQuery();
  const items = useAppSelector(accreditationHistorySelector);
  const { current, count, total, size } = useAppSelector(accreditationHistoryPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();

  useEffect(() => {
    if (accreditationId && accreditationItem) {
      getAccreditationHistory({ id: accreditationId, page, pageSize })
        .unwrap()
        .catch((e) => {
          const message = e.data?.detail;
          addToast("danger", message ? message : "Something went wrong.");
        });
    }
  }, [accreditationId, accreditationItem, page, pageSize, getAccreditationHistory]);

  return (
    <div className={className} {...props}>
      {isFetchingAccreditationItem || isFetchingAccreditationHistory ? (
        <Skeleton />
      ) : (
        <>
          <div className="pb-4">
            <Button startIcon="ArrowLeft" variant="tertiary" className="!bg-transparent" onClick={() => navigate("/accreditations")}>
              Back
            </Button>
          </div>
          <Panel>
            <div className="flex">
              <div className="flex-1">
                <div className="flex flex-row items-center gap-2">
                  <Typography variant="h3" className="leading-8">
                    {accreditationItem?.stateName}
                  </Typography>
                </div>
                <Typography className="text-neutral-dark-gray">{accreditationItem?.stateAbbrev} History Logs</Typography>
              </div>
            </div>
            {items.length < 1 ? (
              <EmptyState
                title="Your Accreditation History"
                description="There are currently no records available for your accreditation history"
              />
            ) : (
              <AccreditationHistoryTable className="mt-4" items={items} />
            )}
          </Panel>
          <Pagination
            totalItems={total}
            pageCount={count}
            currentPage={current}
            onPageClick={handlePageClick}
            pageSize={size}
            onSizeChange={handleSizeChange}
          />
        </>
      )}
    </div>
  );
};
