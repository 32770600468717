import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import z from "zod";
import {
  Button,
  Icon,
  Loading,
  Map,
  Panel,
  RangeSlider,
  TextInput,
  Typography,
  LocationInput,
  Skeleton,
  ErrorMessage,
} from "@/components/atoms";
import { Restricted } from "@/components/pages";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddFixedPricingLocationMutation } from "@/redux/apis/config/pricing/tripCost";
import { addToast } from "@/utils";
import { useFixedPricingContext } from "../contexts";

const formSchema = z.object({
  locationProfileName: z.string().nonempty("Field is required and can't be empty."),
  referencePoint: z.object({
    id: z.string().nonempty("Field is required and can't be empty."),
    address: z.string().nonempty("Field is required and can't be empty."),
    coordinates: z.object({
      lat: z.number(),
      lng: z.number(),
    }),
  }),
  coverageRange: z.number(),
});

type FormData = z.infer<typeof formSchema>;

export const AddLocationPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const [addLocation, { isLoading }] = useAddFixedPricingLocationMutation();
  const { isFetching, pricingProfile, isRestricted } = useFixedPricingContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { coverageRange: 20 },
    resolver: zodResolver(formSchema),
  });
  const watchReferencePoint = watch("referencePoint");
  const watchCoverageRange = watch("coverageRange");

  const onSubmit = handleSubmit((data) => {
    if (pricingProfile) {
      addLocation({
        id: pricingProfile.id,
        name: data.locationProfileName,
        pickup_address: data.referencePoint.address,
        pickup_latitude: data.referencePoint.coordinates.lat,
        pickup_longitude: data.referencePoint.coordinates.lng,
        pickup_radius: data.coverageRange,
      })
        .unwrap()
        .then(() => {
          addToast("success", "Successfully Added New Location");
          navigate("../");
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  });

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <Panel className={className} {...props}>
          <form onSubmit={onSubmit} className="relative">
            {isLoading && <Loading />}
            <div className="flex flex-col">
              <Typography variant="h3" className="leading-8">
                Add Location
              </Typography>
              <Typography className="text-neutral-dark-gray">
                Set a reference point and a coverage area to get started, allowing for the addition of trip pricing at a later stage.
              </Typography>
            </div>
            <div className="mt-6 flex flex-col">
              <Typography variant="paragraph">Coverage Area</Typography>
              <Typography className="text-neutral-dark-gray">
                This represents the coverage area range to which this pricing will be applied.
              </Typography>
            </div>
            <div className="mt-2 flex flex-row gap-x-5">
              <div className="flex h-[354px] w-full">
                <Map circleRadius={watchCoverageRange * 1000} center={watchReferencePoint?.coordinates} />
              </div>
              <div className="flex w-full flex-col">
                <div className="flex flex-col">
                  <Typography variant="paragraph">Location Profile Name</Typography>
                  <TextInput
                    placeholder="Enter Name"
                    maxLength={65}
                    hasError={!!errors.locationProfileName}
                    {...register("locationProfileName")}
                  />
                  <ErrorMessage errors={errors} name="locationProfileName" />
                </div>
                <div className="flex flex-col">
                  <Typography variant="paragraph">Reference point</Typography>
                  <Controller
                    name="referencePoint"
                    control={control}
                    render={({ field }) => (
                      <LocationInput
                        placeholder="Enter Reference Point"
                        renderIcon={() => <Icon name="Location" size="sm" className="text-neutral-dark-gray" />}
                        onLocationChange={(value) => field.onChange(value)}
                        hasError={!!errors.referencePoint}
                      />
                    )}
                  />
                  <ErrorMessage errors={errors} name="referencePoint" />
                </div>
                <div className="flex flex-col">
                  <Typography variant="paragraph">Coverage Range</Typography>
                  <div className="flex flex-row items-center gap-x-4">
                    <Typography variant="paragraph" className="text-info">
                      Within {watchCoverageRange} km
                    </Typography>
                    <Controller
                      name="coverageRange"
                      control={control}
                      render={({ field }) => (
                        <RangeSlider
                          className="flex-1"
                          min={0}
                          max={30}
                          step={0.5}
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="mt-16 flex flex-row justify-end gap-x-4">
                  <Button size="lg" variant="secondary" onClick={() => navigate("../")}>
                    Cancel
                  </Button>
                  <Button type="submit" size="lg" variant="primary">
                    Add Location
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Panel>
      )}
    </>
  );
};
