import { useContext, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, Divider, IconButton, Spinner, TextInput, Typography, Modal, SidePanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRequestPrintQrMutation } from "@/redux/apis/qr/qrApi";
import { useAppSelector } from "@/redux/hooks";
import { qrTemplatesSelector } from "@/redux/slices/qr/selectors";
import { QRTemplate } from "@/redux/slices/qr/types";
import { addToast } from "@/utils";
import { CostDetails } from "./CostDetails";
import { SendToPrinterContext } from "./context";

type FormData = {
  orders: Array<{
    template: string;
    qty: number;
  }>;
};

export const Step2 = () => {
  const { step, setStep } = useContext(SendToPrinterContext);

  return (
    <>
      <SidePanel
        open={step > 1}
        onClose={() => setStep(1)}
        title="Your Cart"
        description="Enter the number of stickers you wanted to print out per QR template"
        focused
      >
        <Form />
      </SidePanel>
    </>
  );
};

const Form = () => {
  const qrTemplates = useAppSelector(qrTemplatesSelector);
  const { setStep, selectedTemplates, setSelectedTemplates } = useContext(SendToPrinterContext);
  const [request, { isLoading }] = useRequestPrintQrMutation();
  const [pendingDelete, setPendingDelete] = useState<QRTemplate>();
  const [pendingSubmit, setPendingSubmit] = useState(false);

  const templates = qrTemplates
    .filter((i) => selectedTemplates.includes(i.slug))
    .reduce((prev, curr) => {
      prev[curr.slug] = curr;
      return prev;
    }, {} as Record<string, QRTemplate>);

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      orders: selectedTemplates.map((i) => ({ template: i, qty: 0 })),
    },
  });

  const { fields, remove } = useFieldArray({
    control,
    name: "orders",
  });

  const handleDelete = () => {
    if (pendingDelete) {
      remove(fields.findIndex((i) => i.template === pendingDelete.slug));
      setSelectedTemplates(selectedTemplates.filter((i) => i !== pendingDelete.slug));
      setPendingDelete(undefined);
    }
  };

  const onSubmit = handleSubmit((data) => {
    setPendingSubmit(false);
    request({
      requested: data.orders.map((i) => ({ type: i.template, quantity: i.qty })),
    })
      .unwrap()
      .then(() => setStep(3))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const orders = watch("orders");
  const subtotal = orders.reduce((prev, curr) => prev + templates[curr.template].price * (curr.qty || 0), 0);
  const qty = orders.reduce((prev, curr) => prev + (curr.qty || 0), 0);
  const discountPercent = qty >= 100 ? 25 : qty >= 50 ? 20 : qty >= 25 ? 10 : 0;
  const discountAmount = Math.round(subtotal * (discountPercent / 100));

  return (
    <div className="relative flex h-full flex-col">
      {pendingDelete && (
        <Modal
          open={!!pendingDelete}
          title={`Remove ${pendingDelete?.name}?`}
          description={`Are you sure you want to remove the ${pendingDelete?.name} from your cart orders.`}
          onClose={() => setPendingDelete(undefined)}
          className="max-w-[470px]"
          renderButtons={() => (
            <>
              <Button variant="secondary" onClick={() => setPendingDelete(undefined)} className="px-8">
                Cancel
              </Button>
              <Button variant="primary" onClick={handleDelete} className="!bg-danger px-8 hover:!bg-danger-dark">
                Delete
              </Button>
            </>
          )}
        />
      )}
      {pendingSubmit && (
        <Modal open={pendingSubmit} title="" onClose={() => setPendingSubmit(false)}>
          <div className="flex max-w-[470px] flex-col">
            <Typography variant="title" className="mb-4">
              Confirm orders of QR stickers
            </Typography>
            <Typography className="mb-4 text-neutral-dark-gray">
              Clicking “Continue” would send an email to our Print Supplier of your Order. The Print supplier will contact you for payment.
              Turn around time is <span className="text-info">4 working days</span>
            </Typography>
            <Typography className="mb-4 text-neutral-dark-gray">Do you wish to continue?</Typography>
            <div className="mt-2 flex justify-end gap-3">
              <Button variant="secondary" onClick={() => setPendingSubmit(false)} className="px-8">
                Cancel
              </Button>
              <Button variant="primary" onClick={onSubmit} className="px-8 ">
                Continue
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {isLoading && (
        <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white/90">
          <Spinner className="h-8 w-8" />
        </div>
      )}
      <div className="h-full flex-1 divide-y divide-neutral-mid-gray overflow-y-auto">
        {fields.map((i, idx) => (
          <div key={i.id} className="flex gap-1 px-4 py-4">
            <div className="flex flex-1 items-center gap-4">
              <img src={templates[i.template].previewUrl} className="max-w-[30px] border border-neutral-mid-gray" />
              <div className="grid">
                <Typography variant="action">{templates[i.template].name}</Typography>
                <Typography variant="action" className="leading-4 text-success">
                  ${templates[i.template].price}
                </Typography>
              </div>
            </div>
            <TextInput
              className="w-[70px]"
              type="number"
              placeholder="0"
              min="0"
              max={templates[i.template].maxQty}
              step="1"
              maxLength={3}
              required
              size="md"
              {...register(`orders.${idx}.qty`, { valueAsNumber: true, required: true })}
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              className="-mr-2 text-danger"
              onClick={() => setPendingDelete(templates[i.template])}
            />
          </div>
        ))}
      </div>
      <div>
        <Divider />
        <div className="min-h-[100px] p-4">
          <CostDetails subtotal={subtotal} discount={discountAmount} postage={18} />
          <Button onClick={() => setPendingSubmit(true)} className="mt-5 w-full text-center" disabled={!isDirty || qty < 1}>
            Send to Printer Supplier
          </Button>
        </div>
      </div>
    </div>
  );
};
