import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const PaidCustomerExtrasField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <Typography variant="paragraph">Paid Customer Extras</Typography>
      <Typography variant="paragraph" className="text-neutral-dark-gray">
        If enabled: Extras charged on top of fixed rate.
        <br />
        If disabled: Extras absorbed into fixed price.
      </Typography>
      <Controller
        name="paidCustomerExtras"
        control={control}
        render={({ field }) => (
          <div className="flex items-center gap-4">
            <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
            <Typography className="inline">{field.value ? "Enabled" : "Disabled"}</Typography>
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="paidCustomerExtras" />
    </div>
  );
};
