import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryEnableSpecificModels = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<AddVehicleCategoryFormData>();

  const resetMakeModels = useCallback(() => {
    setValue("offloadVehicleModels", [], {
      shouldDirty: true,
    });
  }, [setValue]);

  return (
    <div className={clsx("flex w-full flex-col gap-y-1", className)} {...props}>
      <Controller
        name="offloadEnableSpecificModels"
        control={control}
        render={({ field }) => (
          <div className="mt-1 flex items-center gap-4">
            <Typography className="flex-1">Include specific makes and models for this category</Typography>
            <Toggle
              checked={field.value}
              onChange={(checked) => {
                field.onChange(checked);
                if (!checked) resetMakeModels();
              }}
            />
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="offloadEnableSpecificModels" />
    </div>
  );
};
