import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, Loading, Panel, Toggle, Typography, Modal, Skeleton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetAccessControlQuery, useUpdateAccessControlMutation } from "@/redux/apis/config/admin/accessControl";
import { useAppSelector } from "@/redux/hooks";
import { accessControlSelector } from "@/redux/slices/admin/selectors";
import { AccessControl } from "@/redux/slices/admin/types";
import { addToast } from "@/utils/addToast";

const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save Access Controls",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

type FormData = AccessControl;

export const AccessControlEdit = () => {
  const { isFetching } = useGetAccessControlQuery();
  const { details } = useAppSelector(accessControlSelector);
  const [updateAccessControl, { isLoading }] = useUpdateAccessControlMutation();
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, dirtyFields, isSubmitted },
  } = useForm<FormData>({ defaultValues: details });
  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  useEffect(() => {
    reset(details);
  }, [details, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as AccessControl;
    const { enabledClientSignup, enabledDriverSignup } = { ...dirtyValues };

    setActiveModal(null);
    updateAccessControl({
      enabled_client_signup: enabledClientSignup,
      enabled_driver_signup: enabledDriverSignup,
    })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Updated Access Control`);
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="ml-auto flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Access Control
              </Typography>
              <Typography className="text-neutral-dark-gray">Enable Access controls for this site.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
          <ConfigDetail title="Driver signup enabled?" description="Allow driver signups?">
            <Controller
              name="enabledDriverSignup"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail title="Client signup enabled?" description="Allow client signups?">
            <Controller
              name="enabledClientSignup"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
        </div>
      </Panel>
    </form>
  );
};
