import { Alert, Button, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useEffect, useState } from "react";
import { useUpdateInvoicingConfigurationMutation } from "@/redux/apis/addOn/addOnApi";
import { addOnItemSelector } from "@/redux/slices/addOn/selectors";
import { renderAddOnCost, renderAddOnIcon, renderAddOnLockIcon, renderPostFreeTrialInfo, renderPreFreeTrialInfo } from "../helpers";
import { useProfile } from "@/hooks";
import InvoicingPreview from "@/assets/images/add-ons/invoicing-preview.png";
import { InvoicingDetails } from "./InvoicingDetails";
import { InvoicingEdit } from "./InvoicingEdit";
import { addOnsModalDescription } from "../fixtures";
import { FormProvider, useForm } from "react-hook-form";
import { InvoicingConfiguration } from "@/redux/slices/addOn/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { formSchema } from "./fixtures";
import { AddOnDisableModal } from "../modals/AddOnDisableModal";
import { AddOnSaveModal } from "../modals/AddOnSaveModal";
import { isEmpty } from "ramda";
import { AddOnDisableErrorModal } from "../modals/AddOnDisableErrorModal";
import { useToggleAddOn } from "../hooks/useToggleAddOn";
import { RawErrorResponse } from "@/redux/types";

interface InvoicingModalProps {
  open: boolean;
  onClose: () => void;
  isAdmin: boolean;
}

export const InvoicingModal = ({ open, onClose, isAdmin }: InvoicingModalProps) => {
  const { dateFormat } = useProfile();
  const invoicing = useAppSelector(addOnItemSelector("invoicing"));
  const configuration = invoicing?.configuration as InvoicingConfiguration | undefined;
  const [updateInvoicingConfiguration, { isLoading: isLoadingUpdate }] = useUpdateInvoicingConfigurationMutation();

  const [modal, setModal] = useState<"save" | "disable" | "disableError" | "disableErrorInvoicing" | undefined>(undefined);
  const [action, setAction] = useState<"edit" | undefined>(undefined);

  const methods = useForm<InvoicingConfiguration>({
    resolver: zodResolver(formSchema),
    defaultValues: configuration,
  });
  const { reset, handleSubmit, formState: { isDirty, errors } } = methods;

  const { toggleAddOn, isLoading } = useToggleAddOn({ 
    onEnableSuccess: () => addToast("success", "Invoicing has been successfully enabled."), 
    onDisableSuccess: () => {
      addToast("info", "Invoicing has been disabled.");
      onClose(); 
    }, 
    onEnableError: (e: RawErrorResponse) => getErrorMessages(e).forEach((message) => addToast("danger", message)),
    onDisableError: (e: RawErrorResponse) => {
      if (e.data.error === "ADD-ON-INVOICING-DEACTIVATION-ERROR") setModal("disableErrorInvoicing");
      else setModal("disableError");    
    },
    shouldUpdateOperator: true,
  });

  useEffect(() => {
    reset(configuration);
  }, [configuration, reset]);

  useEffect(() => {
    if (!isEmpty(errors)) setModal(undefined);
  }, [errors]);

  const handleClose = () => {
    if (isDirty) setModal("save");
    else onClose();
  };

  const onSave = handleSubmit((data) => {
    const { invoiceModuleEnabled } = data;

    updateInvoicingConfiguration({
      invoice_module_enabled: invoiceModuleEnabled,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Invoicing");
        setAction(undefined);
        setModal(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  });

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Add-ons
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleClose} />
    </div>
  );

  const renderButton = () => {
    if (invoicing && invoicing.available) {
      if (isAdmin) {
        if (invoicing.enabled) return <Button variant="secondary" size="lg" startIcon="MinusCircle" iconVariant="Bold" iconClassName="text-danger" className="w-full mt-8" onClick={() => setModal("disable")}>Disable Invoicing</Button>;
        else return (
          <div className="relative mt-8">
            {isLoading && <Loading />}
            <Button variant="primary" size="lg" className="w-full" onClick={() => toggleAddOn("invoicing", true)}>Enable Invoicing</Button>
          </div>
        );
      } else return <Alert type="info" message={`Request access from your admin for invoicing.`} className="w-full mt-4" />;
    }
  };

  const renderModals = () => (invoicing && (
    <>
      <AddOnSaveModal open={modal === "save"} onClose={() => setModal(undefined)} onSave={onSave} isLoading={isLoadingUpdate} />
      <AddOnDisableModal id="invoicing" name={invoicing.name} open={modal === "disable"} onClose={() => setModal(undefined)} onDisable={() => toggleAddOn("invoicing", false)} isLoading={isLoading} />
      <AddOnDisableErrorModal 
        open={modal === "disableError" || modal === "disableErrorInvoicing"} 
        onClose={() => setModal(undefined)} 
        id={modal === "disableErrorInvoicing" ? "invoicing" : undefined} 
      />
    </>
  ));
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="mt-3">
        {renderPreFreeTrialInfo(invoicing)}
        <div className="flex flex-col md:flex-row md:items-center bg-neutral-surface-gray rounded-lg gap-y-2.5 py-2.5 pl-2.5 pr-5 mt-3">
          <div className="flex flex-1 items-center flex-row gap-x-4">
            <div className="flex items-center justify-center bg-primary-dark rounded-md p-2.5">
              {renderAddOnIcon("invoicing")}
            </div>
            <Typography variant="h3">Invoicing</Typography>
          </div>
          <div className="flex flex-col items-start sm:items-end">
            {renderAddOnCost(invoicing)}
          </div>
        </div> 
        {invoicing?.enabled ? (
          action === "edit" ? (
            <FormProvider {...methods}>
              <InvoicingEdit onSubmit={onSave} onClose={() => setAction(undefined)} isLoading={isLoadingUpdate} />
            </FormProvider>
          ) : <InvoicingDetails onEdit={() => setAction("edit")} configuration={configuration} />
        ) : (
          <div className="relative bg-neutral-gray rounded-lg w-full mt-4 px-[25px] pt-[30px]">
            {renderAddOnLockIcon(false)}
            <img src={InvoicingPreview} />
          </div>
        )}
        <Typography variant="paragraph" className="mt-4">{addOnsModalDescription["invoicing"]}</Typography>
        {renderPostFreeTrialInfo(dateFormat, invoicing)}
        {renderButton()}
      </div>
      {renderModals()}
    </Modal>
  );
};
