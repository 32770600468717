import { flexRender } from "@tanstack/react-table";
import { Icon } from "@/components/atoms";
import { clsx } from "@/utils";
import { DataTableHeader as Header } from "../types";

export interface DataTableHeaderProps<T extends object> extends React.ComponentProps<"thead"> {
  headers: Header<T, unknown>[];
  withFiller?: boolean;
}

export const DataTableHeader = <T extends object>({ className, headers, withFiller = false, ...props }: DataTableHeaderProps<T>) => {
  return (
    <thead className={className} {...props}>
      <tr className="!p-0 [&>th:first-child]:rounded-tl-lg [&>th:last-child]:rounded-tr-lg">
        {withFiller && (
          <th
            className="whitespace-nowrap bg-neutral-surface-gray px-4 py-3 text-left text-sm font-semibold text-neutral-black max-lg:p-2"
            style={{
              width: 1,
            }}
          >
            &nbsp;
          </th>
        )}
        {headers.map((header) => (
          <th
            key={header.id}
            colSpan={header.colSpan}
            className="whitespace-nowrap bg-neutral-surface-gray px-4 py-3 text-left text-sm font-semibold text-neutral-black max-lg:p-2"
            style={{ width: header.getSize() === Number.MAX_SAFE_INTEGER ? "auto" : header.getSize() }}
          >
            {header.isPlaceholder ? null : (
              <div
                className={clsx({
                  "flex cursor-pointer select-none place-items-center gap-1": header.column.getCanSort(),
                })}
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() &&
                  ({
                    asc: <Icon name="ArrowDown2" variant="Bold" className="inline h-4 w-4 flex-shrink-0" />,
                    desc: <Icon name="ArrowUp2" variant="Bold" className="inline h-4 w-4 flex-shrink-0" />,
                  }[header.column.getIsSorted() as string] ?? (
                    <Icon name="double-arrow" isCustom className="inline h-4 w-4 flex-shrink-0" />
                  ))}
              </div>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
