import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setCreditCardPaymentsModalAction } from "@/redux/slices/payment/paymentSlice";
import { creditCardPaymentsPageStateSelector } from "@/redux/slices/payment/selectors";
import { TriggerPaymentModal } from "./TriggerPaymentModal";

export const CreditCardPaymentsModals = () => {
  const { modalAction: action } = useAppSelector(creditCardPaymentsPageStateSelector);
  const dispatch = useAppDispatch();

  const onClose = () => dispatch(setCreditCardPaymentsModalAction(undefined));

  return (
    <>
      <TriggerPaymentModal open={action === "triggerPayment"} onClose={onClose} />
    </>
  );
};
