import { saveAs } from "file-saver";
import { Button, Divider, Icon, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyGetInvoiceManualPaymentReceiptQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppSelector } from "@/redux/hooks";
import { invoicingInvoicesPayByCreditCardSelector } from "@/redux/slices/payment/selectors";
import { Invoice } from "@/redux/slices/payment/types";
import { addToast, formatCurrency } from "@/utils";

interface PayByCreditCardStep3Props {
  invoice: Invoice;
}

export const PayByCreditCardStep3 = ({ invoice }: PayByCreditCardStep3Props) => {
  const { currency } = invoice;
  const { manualPaymentDetails } = useAppSelector(invoicingInvoicesPayByCreditCardSelector);
  const [getInvoiceManualPaymentReceipt, { isFetching }] = useLazyGetInvoiceManualPaymentReceiptQuery();

  const downloadPDFReceipt = (invoiceId: string, paymentRecordId: string) => {
    getInvoiceManualPaymentReceipt({
      id: invoiceId,
      paymentRecordId,
    })
      .unwrap()
      .then((res) => saveAs(res.data.receipt, `receipt-${paymentRecordId}`))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderTotalAmount = (totalAmount: number) => (
    <Typography variant="h1">{`${currency} ${formatCurrency(totalAmount, 2, currency)}`}</Typography>
  );

  const renderDisplayMessage = () => {
    if (manualPaymentDetails) {
      switch (manualPaymentDetails.status) {
        case "FAILED":
          return (
            <div className="flex flex-col items-center">
              <Icon className="text-inherit mb-8 flex-shrink-0 self-center text-danger" name="CloseCircle" variant="Bold" size={116} />
              {renderTotalAmount(manualPaymentDetails.amounts.total)}
              <Typography variant="title">Your payment is unsuccessful</Typography>
              <Typography variant="paragraph" className="mt-5 text-center text-neutral-dark-gray">
                Pay by Credit Card transaction has failed. Please try
                <br />
                again later
              </Typography>
            </div>
          );
        default:
          return (
            <div className="flex flex-col items-center">
              <Icon className="text-inherit mb-8 flex-shrink-0 self-center text-success" name="TickCircle" variant="Bold" size={116} />
              {renderTotalAmount(manualPaymentDetails.amounts.total)}
              <Typography variant="title">Your payment is complete</Typography>
              <Typography variant="paragraph" className="mt-5 text-center text-neutral-dark-gray">
                You can now close this tab and go back to the
                <br />
                Payments page
              </Typography>
            </div>
          );
      }
    }
  };

  const renderDownloadPDFReceiptButton = () => {
    if (manualPaymentDetails && manualPaymentDetails.status === "SUCCESSFUL") {
      return (
        <div>
          <Divider />
          <div className="flex flex-col gap-3 px-4 py-5">
            <Button
              className="w-full text-center"
              size="lg"
              startIcon="ImportCurve"
              onClick={() => downloadPDFReceipt(invoice.id, manualPaymentDetails.id)}
            >
              Download PDF Receipt
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="relative flex w-full">
      {isFetching && <Loading />}
      <div className="relative flex h-full w-full flex-col">
        <div className="h-full flex-1 overflow-y-auto">
          <div className="flex h-full flex-col items-center pt-[210px]">{renderDisplayMessage()}</div>
        </div>
        {renderDownloadPDFReceiptButton()}
      </div>
    </div>
  );
};
