import {
  GetBillingSettingsRawResponse,
  GetBillingSettingsResponse,
  UpdateBillingSettingsRawResponse,
  UpdateBillingSettingsResponse,
} from "./types";

export const transformBillingSettings = (res: GetBillingSettingsRawResponse) => {
  const { _links, ...billingSettings } = res;
  const response: GetBillingSettingsResponse = {
    data: {
      details: {
        defaultOperatorCommissionRate: billingSettings.default_operator_commission_rate,
        enabledPassOnCreditCardFees: billingSettings.enabled_pass_on_credit_card_fees,
        defaultServiceFeeRate: billingSettings.default_service_fee_rate,
        enabledOperatorPaysOwnDrivers: billingSettings.enabled_operator_pays_own_drivers,
        enabledPriceInGuestEmail: billingSettings.enabled_price_in_guest_email,
        closeJobAutoApproveValue: billingSettings.close_job_auto_approve_value ? `${Number(billingSettings.close_job_auto_approve_value) / 100}` : "0",
        defaultUniversalPricingAdjustmentRate: billingSettings.default_universal_pricing_adjustment_rate,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateBillingSettings = (res: UpdateBillingSettingsRawResponse) => {
  return {
    links: res._links,
  } as UpdateBillingSettingsResponse;
};
