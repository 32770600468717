import { ReactNode, ElementType } from "react";
import { OverwritableType } from "@/components/types";
import { clsx } from "@/utils";

export interface CardProps<T> {
  as?: T;
  children?: ReactNode;
}

export const Card = <T extends ElementType = "div">({ as, className, children, ...props }: OverwritableType<CardProps<T>, T>) => {
  const Component: ElementType = as || "div";

  return (
    <Component className={clsx("rounded-lg bg-neutral-surface-gray p-5", className)} {...props}>
      {children}
    </Component>
  );
};
