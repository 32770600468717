import { useParams } from "react-router-dom";
import { Panel, Skeleton } from "@/components/atoms";
import { HourlyHireEditor, HourlyHireMinimum, HourlyHireMinimumEditor, HourlyHireTable } from "@/components/organisms";
import { useGetHourlyHireMinimumQuery, useGetHourlyHireQuery } from "@/redux/apis/config/pricing/hourlyHire";
import { useAppSelector } from "@/redux/hooks";
import { hourlyHireMinimumSelector, hourlyHireSelector } from "@/redux/slices/pricing/selectors";
import { clsx } from "@/utils";

export const HourlyHire = () => {
  const { isFetching: isFetchingHourlyHire } = useGetHourlyHireQuery();
  const { isFetching: isFetchingHourlyHireMinimum } = useGetHourlyHireMinimumQuery();
  const { basePrice, intervals, currency, currencySymbol } = useAppSelector(hourlyHireSelector);
  const hourlyHireMinimum = useAppSelector(hourlyHireMinimumSelector);
  const { mode, type } = useParams();

  return (
    <div className="space-y-6">
      <Panel>
        {isFetchingHourlyHireMinimum ? (
          <Skeleton variant="short" />
        ) : (
          <>
            {mode === "edit" && type === "minimum" ? (
              <HourlyHireMinimumEditor hourlyHireMinimum={hourlyHireMinimum} />
            ) : (
              <HourlyHireMinimum
                className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type !== "minimum" })}
                hourlyHireMinimum={hourlyHireMinimum}
              />
            )}
          </>
        )}
      </Panel>
      <Panel>
        {isFetchingHourlyHire ? (
          <Skeleton />
        ) : (
          <>
            {mode === "edit" && type !== "minimum" ? (
              <HourlyHireEditor basePrice={basePrice} intervals={intervals} currency={currency} currencySymbol={currencySymbol} />
            ) : (
              <HourlyHireTable
                className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type === "minimum" })}
                intervals={[basePrice, ...intervals]}
                currencySymbol={currencySymbol}
              />
            )}
          </>
        )}
      </Panel>
    </div>
  );
};
