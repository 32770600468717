import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useGetVehicleFormOptionsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  color: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const ColorFilter = () => {
  const {
    filters: { color },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!color) return undefined;

    return color.filter(Boolean).join(", ");
  }, [color]);

  const onClose = () => setFilter("color", undefined);

  return (
    <FilterPopover className="overflow-visible" name="color" value={value} label="Vehicle Color" onClose={onClose}>
      <ColorFilterForm />
    </FilterPopover>
  );
};

interface ColorFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const ColorFilterDrawer = ({ open, onOpenChange }: ColorFilterDrawerProps) => {
  const {
    filters: { color },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!color) return undefined;

    return color.filter(Boolean).join(", ");
  }, [color]);

  const onClose = () => setFilter("color", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Color" onClose={onClose}>
      <ColorFilterForm />
    </FilterDrawer>
  );
};

const ColorFilterForm = () => {
  const { setFilter, filters } = useVehicleFilters();
  const { isFetching, data: options } = useGetVehicleFormOptionsQuery();

  const colors = useMemo(() => {
    if (isFetching || !options) return [];

    return options.colors.map((color) => ({
      name: color.name,
      value: color.key,
    }));
  }, [isFetching, options]);

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { color: (filters.color as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "color",
          colors.map((color) => color.value)
        );
        setValue(
          "color",
          colors.map((color) => color.value)
        );
      } else {
        setFilter("color", null);
        setValue("color", []);
      }
    },
    [colors, setFilter, setValue]
  );

  const isAllColorsSelected = useMemo(() => {
    if (!filters.color) return false;
    return filters.color.length === colors.length;
  }, [colors.length, filters.color]);

  return (
    <div className="contents">
      <Typography className="mb-4">Color </Typography>
      <CheckboxInput label="All Colors" onChange={handleCheckAll} variant="check" checked={isAllColorsSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="color"
          render={({ field }) => (
            <>
              {colors.map((color) => (
                <CheckboxInput
                  key={color.value}
                  className="capitalize"
                  label={color.name.toLowerCase()}
                  value={color.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newColor = [...field.value, e.target.value];
                      field.onChange(newColor);
                      setFilter("color", newColor.length > 0 ? newColor : null);
                    } else {
                      const newColor = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newColor);
                      setFilter("color", newColor.length > 0 ? newColor : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(color.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
