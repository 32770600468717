import { Dropzone, Icon, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateAvatarMutation } from "@/redux/apis/profile/profileApi";
import { addToast } from "@/utils/addToast";

export interface ProfileAvatarUploaderProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const ProfileAvatarUploader = ({ onClose, onSuccess }: ProfileAvatarUploaderProps) => {
  const [updateAvatar, { isLoading }] = useUpdateAvatarMutation();

  const handleFileDrop = (files: File[]) => {
    if (files.length < 1) return;

    const avatar = files[0];
    const formData = new FormData();
    formData.append("avatar", avatar);
    updateAvatar(formData)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully updated avatar");
        onSuccess && onSuccess();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <form id="update-avatar-form" className="relative flex w-[550px] max-w-full flex-col">
      {isLoading && (
        <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white/90 pt-2">
          <Spinner className="h-8 w-8" />
        </div>
      )}
      <button type="button" className="-mt-8 ml-auto rounded-md text-black" onClick={onClose}>
        <Icon name="close" isCustom size="lg" aria-hidden="true" />
      </button>
      <div className="mt-2">
        <Dropzone
          className="mt-1 h-[350px]"
          accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"] }}
          maxSize={20000000}
          onDrop={handleFileDrop}
          detail={
            <div className="flex h-full flex-col  items-center justify-center gap-y-4">
              <Icon name="Gallery" variant="Bold" size="xxl" aria-hidden="true" />
              <div className="flex flex-col items-center justify-center">
                <Typography variant="action">
                  Drag & Drop your photo here or <span className="text-info underline">choose file</span>
                </Typography>
                <Typography variant="paragraph">20 MB max file size</Typography>
              </div>
            </div>
          }
        />
      </div>
    </form>
  );
};
