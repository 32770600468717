import { useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersFormOptionsSelector } from "@/redux/slices/externalSupplier/selectors";
import { ExternalSuppliersFilters } from "../types";

export const StatusFilter = () => {
  const { statuses } = useAppSelector(externalSuppliersFormOptionsSelector);
  const { watch, setValue } = useFormContext<ExternalSuppliersFilters>();
  const selected = watch("accountStatus");

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) setValue("accountStatus", [...(selected || []), target.value]);
    else
      setValue(
        "accountStatus",
        (selected || []).filter((value) => value !== target.value)
      );
  };

  const handleSelectAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    target.checked
      ? setValue(
          "accountStatus",
          statuses.map((i) => i.value)
        )
      : setValue("accountStatus", []);
  };

  const isAllSelected = selected && selected.length > 0 && selected.length === statuses.length;

  return (
    <div className="rounded bg-neutral-surface-gray p-4">
      <Typography className="mb-3">Select Status</Typography>
      <div className="grid gap-4">
        <CheckboxInput id="statusSelectAll" label="Select all" onChange={handleSelectAll} checked={isAllSelected} />
        {statuses.map((i) => (
          <CheckboxInput
            onChange={handleChange}
            key={i.value}
            id={i.value}
            label={i.name}
            value={i.value}
            checked={selected?.includes(i.value)}
            variant="check"
          />
        ))}
      </div>
    </div>
  );
};
