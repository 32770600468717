import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddOnTextInput, Button, ErrorMessage, IconButton, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProcessRefundMutation } from "@/redux/apis/booking/endpoints/payment";
import { useAppDispatch } from "@/redux/hooks";
import { setPaymentAction } from "@/redux/slices/booking/bookingSlice";
import { BookingPaymentLog } from "@/redux/slices/booking/types";
import { addToast, getCurrencySymbol } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

const schema = z.object({
  amount: z.coerce.number({ invalid_type_error: "This field is required." }).min(1),
  reason: z.string().nonempty(),
});

type FormData = z.infer<typeof schema>;

interface ProcessRefundProps {
  payment: BookingPaymentLog;
}

export const ProcessRefund = ({ payment }: ProcessRefundProps) => {
  const dispatch = useAppDispatch();
  const [processRefund, { isLoading }] = useProcessRefundMutation();

  const {
    id: bookingId,
    cost: { currency },
  } = useSelectedBooking();

  const {
    register,
    formState: { isDirty, isSubmitting, errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: 0,
      reason: "",
    },
  });

  const onSubmit = async (data: FormData) =>
    processRefund({
      bookingId,
      paymentId: payment.id,
      amount: data.amount * 100,
      reason: data.reason,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Payment refunded successfully");
        dispatch(setPaymentAction(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  const handleClose = () => dispatch(setPaymentAction(undefined));

  return (
    <section onSubmit={handleSubmit(onSubmit)} className="relative m-5 bg-neutral-surface-gray p-5">
      {isLoading && <Loading />}
      <header>
        <Typography variant="title">Process Refund</Typography>
        <Typography variant="paragraph" className="mt-2 text-neutral-dark-gray">
          You may refund up to the value available
        </Typography>
        <IconButton onClick={handleClose} iconName="close" variant="custom" isCustomIcon iconSize="lg" className="absolute right-5 top-5" />
      </header>
      <form className="mt-6 space-y-6 ">
        <fieldset className="w-1/2">
          <Typography className="block leading-loose">Enter Refund Amount</Typography>
          <div className="flex items-center gap-3">
            <AddOnTextInput
              startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
              {...register("amount", { valueAsNumber: true })}
              type="number"
              step="0.01"
              autoFocus
            />
            <ErrorMessage className="leading-loose" errors={errors} name="amount" />
            <Typography variant="action" className="shrink-0 text-neutral-dark-gray">
              {getCurrencySymbol(currency)}
              {Number(payment.balance) / 100} Available
            </Typography>
          </div>
        </fieldset>
        <fieldset className="w-full">
          <Typography className="block leading-loose">Reason for Return</Typography>
          <TextInput placeholder="Enter reason for return" {...register("reason")} />
        </fieldset>
        <Typography className="text-neutral-dark-gray">
          {
            //todo: pull refudn fee from API
          }
          Note: For all refunds, there will be a <span className="text-primary">2% + $0.30</span> fee (capped at a maximum of $4), which
          will be debited from your bank account. Refunds will remain pending until the payment is successfully processed.
        </Typography>
        <fieldset className="flex justify-end">
          <Button variant="secondary" onClick={handleClose} className="mr-4" size="md">
            Cancel Return
          </Button>
          <Button type="submit" disabled={isSubmitting || !isDirty} size="md">
            Process Return
          </Button>
        </fieldset>
      </form>
    </section>
  );
};
