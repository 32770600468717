import { ClientsAddPanel, PageHeader } from "@/components/organisms";

export const ClientsAdd = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader title="Clients" />
      <PageHeader.Actions />
      <ClientsAddPanel />
    </div>
  );
};
