import { useState } from "react";
import { clsx } from "@/utils";
import { Icon, Typography } from "..";

export type AlertType = "info" | "danger" | "warning" | "success";

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  type: AlertType;
  message: string | JSX.Element;
  noIcon?: boolean;
  hasClose?: boolean;
  customIcon?: JSX.Element;
}

export const renderAlertIcon = (type: AlertType) => {
  switch (type) {
    case "info":
      return <Icon className="flex-shrink-0 rotate-180 self-center text-info" name="InfoCircle" variant="Bold" size="lg" />;
    case "danger":
      return <Icon className="text-inherit flex-shrink-0 self-center text-danger" name="InfoCircle" variant="Bold" size="lg" />;
    case "warning":
      return <Icon className="text-inherit flex-shrink-0 self-center text-warning" name="Danger" variant="Bold" size="lg" />;
    case "success":
      return <Icon className="text-inherit flex-shrink-0 self-center text-success" name="TickCircle" variant="Bold" size="lg" />;
    default:
      return null;
  }
};

export const Alert = (alertProps: AlertProps) => {
  const { type, message, className, noIcon, hasClose = false, customIcon, ...props } = alertProps;
  const [show, setShow] = useState(true);

  const typeClass = {
    "bg-info-light text-info": type === "info",
    "bg-danger-light text-danger": type === "danger",
    "bg-warning-light text-warning-dark": type === "warning",
    "bg-success-light text-success": type === "success",
  };

  return (
    <div
      className={clsx("min-h-12 flex flex-row items-center rounded-lg px-[18px] py-3", { hidden: !show }, typeClass, className)}
      data-testid="alert"
      {...props}
    >
      <div className="flex flex-1 flex-row items-center gap-x-3">
        {!noIcon && (customIcon ? customIcon : renderAlertIcon(type))}
        <Typography className="self-center leading-tight" variant="action">
          {message}
        </Typography>
      </div>
      {hasClose && <Icon onClick={() => setShow(false)} name="close" isCustom size="lg" className="cursor-pointer" />}
    </div>
  );
};
