import { Divider, Icon, Tooltip, Typography } from "@/components/atoms";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const Preferences = () => {
  const {
    passenger: { preferences },
    notes: { passenger: passengerNotes },
  } = useSelectedBooking();
  const filteredPreferences = Object.values(preferences).filter((i) => i);

  return (
    <div className="relative space-y-3">
      <Icon name="User" variant="Bold" size="lg" className="absolute -inset-1.5 z-10 rounded-full bg-primary p-1 text-white" />
      {preferences.seating && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3">
          <Typography variant="small" className="shrink-0 text-neutral-black/70 xl:w-auto 2xl:w-[120px]">
            Seating Preference:
          </Typography>
          <Typography variant="small" className=" min-h-[12px] text-sm ">
            {preferences.seating.replace("seat", "")}
          </Typography>
        </div>
      )}
      {preferences.radio && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3">
          <Typography variant="small" className="shrink-0 text-neutral-black/70  xl:w-auto 2xl:w-[120px]">
            Radio Preference:
          </Typography>
          <Typography variant="small" className=" min-h-[12px] text-sm ">
            {preferences.radio}
          </Typography>
        </div>
      )}
      {preferences.quietRide && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3">
          <Typography variant="small" className="shrink-0 text-neutral-black/70  xl:w-auto 2xl:w-[120px]">
            Quiet Ride:
          </Typography>
          <Typography variant="small" className=" min-h-[12px] text-sm ">
            {preferences.quietRide}
          </Typography>
        </div>
      )}
      {preferences.vehicleTemperature && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3">
          <Typography variant="small" className="shrink-0 text-neutral-black/70  xl:w-auto 2xl:w-[120px]">
            Vehicle Temp:
          </Typography>
          <Typography variant="small" className=" min-h-[12px] text-sm ">
            {preferences.vehicleTemperature}° Celsius
          </Typography>
        </div>
      )}
      {filteredPreferences.length > 0 && <Divider className="my-4" />}

      <div className="flex flex-1 flex-col gap-1.5 first-of-type:!mt-0">
        <Typography variant="small" className="flex shrink-0 items-center gap-1 text-neutral-black/70">
          Passenger Notes:
          <Tooltip content="Notes from the passenger regarding ride preferences" placement="left">
            <Icon name="InfoCircle" variant="Bold" size="md" className="rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </Typography>
        <Typography variant="small" className="min-h-[12px] text-sm ">
          {passengerNotes || "-"}
        </Typography>
      </div>
    </div>
  );
};
