import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Icon, Toggle, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const AllowDriverExtrasField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph">Allow Driver Extras</Typography>
        <Tooltip
          content="Enabling or disabling this toggle determines whether the driver can add extras when completing a job. Adding extras will adjust the final price to the client"
          placement="right"
          maxWidth={330}
        >
          <span>
            <Icon name="InfoCircle" size="lg" variant="Bold" className="text-neutral-dark-gray" />
          </span>
        </Tooltip>
      </div>
      <Controller
        name="allowDriverExtras"
        control={control}
        render={({ field }) => (
          <div className="flex items-center gap-4">
            <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
            <Typography className="inline">{field.value ? "Enabled" : "Disabled"}</Typography>
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="allowDriverExtras" />
    </div>
  );
};
