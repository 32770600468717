import { RootState } from "@/redux/store";

export const accessControlSelector = (state: RootState) => state.admin.accessControl;
export const billingSettingsSelector = (state: RootState) => state.admin.billingSettings;
export const businessDetailsSelector = (state: RootState) => state.admin.businessDetails;
export const dispatchSettingsSelector = (state: RootState) => state.admin.dispatchSettings;
export const displayOptionsSelector = (state: RootState) => state.admin.displayOptions;
export const emailNotificationsSelector = (state: RootState) => state.admin.emailNotifications;
export const smsVoiceNotificationsSelector = (state: RootState) => state.admin.smsVoiceNotifications;
export const websiteDetailsSelector = (state: RootState) => state.admin.websiteDetails;
export const waitingTimesSelector = (state: RootState) => state.admin.waitingTimes;
