import { BagFilter, BagFilterDrawer } from "./filters/BagCapacityFilter";
import { ClassFilter, ClassFilterDrawer } from "./filters/ClassFilter";
import { ColorFilter, ColorFilterDrawer } from "./filters/ColorFilter";
import { EngineTypeFilter, EngineTypeFilterDrawer } from "./filters/EngineTypeFilter";
import { MakeFilter, MakeFilterDrawer } from "./filters/MakeFilter";
import { ModelFilter, ModelFilterDrawer } from "./filters/ModelFilter";
import { PaxFilter, PaxFilterDrawer } from "./filters/PaxCapacityFilter";
import { RegistrationFilter, RegistrationFilterDrawer } from "./filters/RegistrationFilter";
import { TypeFilter, TypeFilterDrawer } from "./filters/TypeFilter";
import { YearFilter, YearFilterDrawer } from "./filters/YearFilter";

export const getFilterPopover = (key: string) => {
  switch (key) {
    case "make":
      return <MakeFilter key={key} />;
    case "model":
      return <ModelFilter key={key} />;
    case "minYear":
      return <YearFilter key={key} />;
    case "color":
      return <ColorFilter key={key} />;
    case "minCapacityPax":
      return <PaxFilter key={key} />;
    case "minCapacityBags":
      return <BagFilter key={key} />;
    case "engineType":
      return <EngineTypeFilter key={key} />;
    case "type":
      return <TypeFilter key={key} />;
    case "class":
      return <ClassFilter key={key} />;
    case "isRegistrationVerified":
      return <RegistrationFilter key={key} />;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};

export const getFilterDrawer = (key: string, asJSX = false) => {
  switch (key) {
    case "make":
      return asJSX ? <MakeFilterDrawer key={key} /> : MakeFilterDrawer;
    case "model":
      return asJSX ? <ModelFilterDrawer key={key} /> : ModelFilterDrawer;
    case "minYear":
      return asJSX ? <YearFilterDrawer key={key} /> : YearFilterDrawer;
    case "color":
      return asJSX ? <ColorFilterDrawer key={key} /> : ColorFilterDrawer;
    case "minCapacityPax":
      return asJSX ? <PaxFilterDrawer key={key} /> : PaxFilterDrawer;
    case "minCapacityBags":
      return asJSX ? <BagFilterDrawer key={key} /> : BagFilterDrawer;
    case "engineType":
      return asJSX ? <EngineTypeFilterDrawer key={key} /> : EngineTypeFilterDrawer;
    case "type":
      return asJSX ? <TypeFilterDrawer key={key} /> : TypeFilterDrawer;
    case "class":
      return asJSX ? <ClassFilterDrawer key={key} /> : ClassFilterDrawer;
    case "isRegistrationVerified":
      return asJSX ? <RegistrationFilterDrawer key={key} /> : RegistrationFilterDrawer;

    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};
