import { createSlice } from "@reduxjs/toolkit";
import { bookingOfferApi } from "@/redux/apis/bookingOffer/bookingOfferApi";
import { BookingOffer, BookingOfferState } from "./types";

const initialState: BookingOfferState = {
  bookingOffer: {} as BookingOffer,
  documents: [],
};

export const bookingOfferSlice = createSlice({
  name: "bookingOffer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(bookingOfferApi.endpoints.getBookingOffer.matchFulfilled, (state, { payload }) => {
      state.bookingOffer = payload;
    });
    builder.addMatcher(bookingOfferApi.endpoints.updateBookingOffer.matchFulfilled, (state, { payload }) => {
      state.bookingOffer = payload;
    });
    builder.addMatcher(bookingOfferApi.endpoints.getBookingOfferDocuments.matchFulfilled, (state, { payload }) => {
      state.documents = payload.data.documents;
    });
  },
});

export default bookingOfferSlice.reducer;
