import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { BooleanDisplay, Button, Panel, PhoneNumberDetail, Typography } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { EmailNotifications } from "@/redux/slices/admin/types";
import { PageHeader } from "../PageHeader/PageHeader";

interface EmailNotificationsViewPanelProps {
  details: EmailNotifications;
}

export const EmailNotificationsViewPanel = ({ details }: EmailNotificationsViewPanelProps) => {
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <Button className="ml-auto animate-enter" startIcon="Edit2" onClick={() => navigate("edit")}>
            Edit
          </Button>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Email Notifications
            </Typography>
            <Typography className="text-neutral-dark-gray">Set your Notifications for the site.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        <ConfigDetail title="Phone Driver Hotline" description="Phone number for when drivers need urgent support.">
          <PhoneNumberDetail number={details.phoneDriverHotline} />
        </ConfigDetail>
        <ConfigDetail title="Email - Driver Hotline" description="Email address for non-urgent support requests.">
          <Typography variant="paragraph" className="leading-8">
            {details.emailDriverHotline}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="All Emails - BCC all emails" description="BCC a copy of all emails">
          <BooleanDisplay status={details.enabledBccAllEmails} />
        </ConfigDetail>
        <ConfigDetail
          title="Email Address - For Notifications"
          description="BCC email address for all email notifications."
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.emailAddressBccAll}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Email - Booking Notifications" description="BCC on all booking notification emails">
          <BooleanDisplay status={details.enabledBookingNotifications} />
        </ConfigDetail>
        <ConfigDetail
          title="Email Address - For Notifications"
          description="BCC email address for all booking created, edited or cancelled."
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.emailBookingNotifications}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Email - Driver Notifications" description="Receive an email notification for driver related activities">
          <BooleanDisplay status={details.enabledDriverNotifications} />
        </ConfigDetail>
        <ConfigDetail
          title="Email Address - For Notifications"
          description="BCC email address for all driver registration or 100% completion notifications."
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.emailDriverNotifications}
          </Typography>
        </ConfigDetail>
        <ConfigDetail
          title="Low Job Rating Notifications Enabled"
          description="Notifications for when a low job rating is received after a booking has been completed."
        >
          <BooleanDisplay status={details.enabledLowJobRatingNotifications} />
        </ConfigDetail>
        <ConfigDetail
          title="Email - Low Job Notifications"
          description="Email address to send low job notifications to."
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.emailLowJobNotifications}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Email - Member Registration Notifications" description="Receive an email upon each new member registration">
          <BooleanDisplay status={details.enabledMemberRegistrationNotifications} />
        </ConfigDetail>
        <ConfigDetail
          title="Email Address - For Notifications"
          description="BCC email address used for all passenger registrations."
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.emailMemberRegistrationNotifications}
          </Typography>
        </ConfigDetail>

        <ConfigDetail title="Email - Instant Quote Notifications" description="Receive a notification when your instant quote widget is used to send an email">
          <BooleanDisplay status={details.enabledQuoteNotifications} />
        </ConfigDetail>
        <ConfigDetail
          title="Email Address - For Quote Notifications"
          description="BCC email address used for all quote notifications"
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.emailQuoteNotifications}
          </Typography>
        </ConfigDetail>

        <ConfigDetail title="Failed Payment Notifications" description="Email address to BCC all failed payment notifications.">
          <BooleanDisplay status={details.enabledFailedPaymentNotifications} />
        </ConfigDetail>
        <ConfigDetail
          title="Email Address - For Failed Payment Notifications"
          description="BCC email address used for all failed payment notifications"
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.emailFailedPaymentNotifications}
          </Typography>
        </ConfigDetail>
      </Panel>
    </>
  );
};
