import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useAppAbility } from "@/casl";
import { Skeleton } from "@/components/atoms";
import {
  StripeConfigurationPanel,
  BankDetailsDebitsPanel,
  BankDetailsDepositsPanel,
  AccountOwnerAccessRequiredModal,
} from "@/components/organisms";
import { getStripePublishableKey } from "@/helpers/apiHelpers";
import { BankDetailsDebitsHistory } from "./BankDetailsDebitsHistory";
import { BankDetailsDepositsHistory } from "./BankDetailsDepositsHistory";

export const PaymentConfiguration = () => {
  const ability = useAppAbility();
  const stripePublishableKey = getStripePublishableKey();
  const stripePromise = loadStripe(stripePublishableKey);
  const [isModalOpen, setIsModalOpen] = useState(true);

  if (!ability.can("manage", "providerPaymentsConfig"))
    return <AccountOwnerAccessRequiredModal open={isModalOpen} onClose={() => setIsModalOpen(false)} />;

  if (!stripePromise) return <Skeleton />;

  return (
    <Elements stripe={stripePromise}>
      <div className="flex flex-col gap-y-6">
        <StripeConfigurationPanel />
        <BankDetailsDepositsPanel />
        <BankDetailsDebitsPanel />
      </div>
    </Elements>
  );
};

PaymentConfiguration.DebitsHistory = BankDetailsDebitsHistory;
PaymentConfiguration.DepositsHistory = BankDetailsDepositsHistory;
