import { useAppAbility } from "@/casl";
import { IconButton } from "@/components/atoms";
import { useState } from "react";
import { NotificationsSidePanel } from "../Notifications";
import { SidebarLogo } from "../Sidebar/SidebarLogo";
import { HeaderModals } from "./HeaderModals";
import { HeaderNav } from "./HeaderNav";
import { HeaderProfileMenu } from "./HeaderProfileMenu";
import { HeaderSearch } from "./HeaderSearch";
import { HeaderSidePanel } from "./HeaderSidePanel";
import { HeaderSteps } from "./types";

export const Header = () => {
  const ability = useAppAbility();
  const showHeaderSearch = ability.can("manage", "providerClient");
  const [step, setStep] = useState<HeaderSteps>(undefined);
  const viewTransportProviderCode = () => setStep("viewTransportProviderCode");
  const viewNotifications = () => setStep("viewNotifications");

  return (
    <div className="z-40">
      <nav className="bg-white border-b border-neutral-mid-gray lg:bg-transparent lg:border-0">
        {/* Desktop View */}
        <div className="items-center bg-neutral-surface-gray h-12 px-5 py-2 hidden lg:flex">
          <div className="flex flex-1">
            {showHeaderSearch && <HeaderSearch />}
          </div>
          <HeaderProfileMenu />
          <HeaderNav viewTransportProviderCode={viewTransportProviderCode} viewNotifications={viewNotifications} />
        </div>
        {/* Tablet/Mobile View */}
        <div className="flex items-center justify-between h-20 px-5 gap-4 lg:hidden">
          <SidebarLogo ignoreSidebarOpen className="flex flex-1 -ml-4" />
          <div className="flex items-center -mr-2">
            <IconButton iconName="SearchNormal1" variant="tertiary" iconSize="lg" onClick={() => setStep("viewHeaderSearchModal")} className="ring-0" />
          </div>
          <div className="flex items-center -mr-2">
            <IconButton iconName="HambergerMenu" variant="tertiary" iconSize="lg" onClick={() => setStep("viewHeaderSidePanel")} />
          </div>
        </div>
      </nav>
      <HeaderSidePanel open={step === "viewHeaderSidePanel"} onClose={() => setStep(undefined)} viewTransportProviderCode={viewTransportProviderCode} viewNotifications={viewNotifications} />
      <NotificationsSidePanel open={step === "viewNotifications"} onClose={() => setStep(undefined)} />
      <HeaderModals step={step} setStep={setStep} />
    </div>
  );
};