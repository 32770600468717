import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Button, Icon, Panel, Typography, Skeleton, BooleanDisplay, Tooltip, IconButton } from "@/components/atoms";
import { ProfileDetail as Detail } from "@/components/molecules";
import { useOperator } from "@/hooks";
import { useGetCompanyOptionsQuery, useLazyGetCompanyQuery } from "@/redux/apis/company/companyApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setSelectedCompany } from "@/redux/slices/company/companySlice";
import { companiesPageStateSelector, companyOptionsSelector } from "@/redux/slices/company/selectors";
import { AssociatedClientsPanel } from "./AssociatedClientsPanel";
import { clsx } from "@/utils";
import { CompanyAvatar } from "./CompanyAvatar";
import { CompanyAvatarUploadModal } from "./modals/CompanyAvatarUploadModal";

interface CompaniesViewPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  companyId: string;
}

export const CompaniesViewPanel = ({ companyId, ...props }: CompaniesViewPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id: operatorId } = useOperator();
  const ability = useAppAbility();
  const dispatch = useAppDispatch();
  const [getCompany, { isFetching: isLoadingCompany }] = useLazyGetCompanyQuery();
  const { isFetching: isLoadingOptions } = useGetCompanyOptionsQuery();

  const options = useAppSelector(companyOptionsSelector);
  const { selectedCompany: company } = useAppSelector(companiesPageStateSelector);

  const [step, setStep] = useState<"upload" | undefined>(undefined);

  useEffect(() => {
    getCompany(companyId)
      .unwrap()
      .then((company) => {
        dispatch(setSelectedCompany(company));
      })
      .catch(() => {
        throw new Error(`Error fetching company details for : ${companyId}`);
      });

    return () => {
      dispatch(setSelectedCompany(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company) {
      searchParams.set("companyName", company.name);
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const renderPricingAdjustments = () => {
    if (isEmpty(company?.pricing.adjustments)) return;

    return (
      <div className="flex flex-col gap-y-2">
        {company?.pricing.adjustments.map((pricingAdjustment, i) => {
          const isPriceIncrease = pricingAdjustment.amount > -1;
          const amount = isPriceIncrease ? pricingAdjustment.amount : pricingAdjustment.amount * -1;
          return (
            <div key={i} className="flex flex-row items-center gap-x-4">
              <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-primary-light">
                <Typography variant="paragraph" className="text-primary">
                  {i + 1}
                </Typography>
              </div>
              <div className="flex flex-row gap-x-2">
                <div className="flex flex-shrink-0 flex-row items-center gap-x-2">
                  {isPriceIncrease ? (
                    <Icon name="AddCircle" variant="Bold" size="sm" className="text-success" />
                  ) : (
                    <Icon name="MinusCircle" variant="Bold" size="sm" className="text-danger" />
                  )}
                  <Typography variant="action">{`${amount}% Price ${isPriceIncrease ? "Increase" : "Decrease"}`}</Typography>
                </div>
                <Typography variant="action" className="text-neutral-dark-gray">
                  {pricingAdjustment.description}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  if (isLoadingCompany || isLoadingOptions || !company) return <Skeleton />;

  return (
    <>
      <div className="flex flex-col gap-y-4" {...props}>
        <Panel className="flex flex-row gap-x-5 pb-5 px-5 pt-10">
          <CompanyAvatar company={company} openUploader={() => setStep("upload")} className="mx-5 hidden lg:flex" />
          <div className="flex flex-1 flex-col">
            <div className="flex flex-col sm:flex-row gap-y-2">
              <div className="flex flex-row">
                <div className="flex flex-1 mr-6 lg:hidden" onClick={() => setStep("upload")}>
                  <CompanyAvatar company={company} openUploader={() => setStep("upload")} />
                </div>
                <HeaderButton className="flex sm:hidden" />
              </div>
              <div className="flex flex-1 flex-col gap-x-2">
                <Typography variant="h2">{company.name}</Typography>
                <Typography variant="action" className="text-neutral-dark-gray">
                  {company.address}
                </Typography>
              </div>
              <HeaderButton className="hidden sm:flex" />
            </div>
            <div className="mt-8 flex flex-col gap-y-6">
              <Detail label="Contact Number">
                <Typography variant="action">{company.phone}</Typography>
              </Detail>
              <Detail label="Customer Reference Field Description">
                <Typography variant="action">{company.notes.reference}</Typography>
              </Detail>
              <Detail label={company.businessRegistrationType}>
                <Typography variant="action">{company.businessRegistrationId}</Typography>
              </Detail>
              <Detail label="Key Contact">
                <div className="flex flex-col gap-x-1">
                  <Typography variant="action">{company.keyContact.name}</Typography>
                  <div className="flex flex-col">
                    <Typography variant="action" className="text-neutral-dark-gray">
                      {company.keyContact.email}
                    </Typography>
                    <Typography variant="action" className="text-neutral-dark-gray">
                      {company.keyContact.phone}
                    </Typography>
                  </div>
                </div>
              </Detail>
              <Detail label="Pricing Adjustments">{renderPricingAdjustments()}</Detail>
              {ability.can("view", "providerCompanyInvoicing") && (
                <Detail label="Invoicing">
                  <div className="flex flex-row justify-between sm:justify-normal gap-x-2.5">
                    <BooleanDisplay status={company.invoice.enabled} />
                    {ability.can("manage", "providerPaymentsInvoice") && company.invoice.enabled && (
                      <a
                        href={`/dashboard/operator/${operatorId}/payments/invoicing?companyMember=${company.id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button variant="tertiary" startIcon="Eye" size="sm">
                          View Invoices
                        </Button>
                      </a>
                    )}
                  </div>
                </Detail>
              )}
              <Detail
                label={
                  <Typography className="flex gap-1">
                    Pricing Profile
                    <Tooltip content="The Pricing Profile you set determines all bookings costs for this company">
                      <Icon name="InfoCircle" size="lg" variant="Bold" className="rotate-180 text-neutral-dark-gray" />
                    </Tooltip>
                  </Typography>
                }
              >
                <div className="flex items-center justify-between">
                  <Typography>
                    {company.pricing.profileId ? options.pricingProfile[company.pricing.profileId] : "Dynamic Pricing"}
                  </Typography>
                  <Typography variant="action" className="text-neutral-dark-gray">
                    Advance Settings
                  </Typography>
                </div>
              </Detail>
              <Detail
                label={
                  <Typography className="flex gap-1">
                    Driver Extras
                    <Tooltip content="Enabling or disabling this toggle determines whether the driver can add extras when completing a job. Adding extras will adjust the final price to the client">
                      <Icon name="InfoCircle" size="lg" variant="Bold" className="rotate-180 text-neutral-dark-gray" />
                    </Tooltip>
                  </Typography>
                }
              >
                <div className="flex items-center justify-between">
                  <BooleanDisplay status={company.pricing.allowDriverExtras} />
                  <Typography variant="action" className="text-neutral-dark-gray">
                    Advance Settings
                  </Typography>
                </div>
              </Detail>
              <Detail
                label={
                  <Typography className="flex gap-1">
                    Paid Customer Extras
                    <Tooltip
                      content={
                        <Typography>
                          If enabled: Extras charged on top of fixed rate.
                          <br />
                          If disabled: Extras absorbed into fixed price.
                        </Typography>
                      }
                    >
                      <Icon name="InfoCircle" size="lg" variant="Bold" className="rotate-180 text-neutral-dark-gray" />
                    </Tooltip>
                  </Typography>
                }
              >
                <div className="flex items-center justify-between">
                  <BooleanDisplay status={company.pricing.allowDriverExtras} />
                  <Typography variant="action" className="text-neutral-dark-gray">
                    Advance Settings
                  </Typography>
                </div>
              </Detail>
            </div>
          </div>
        </Panel>
        <AssociatedClientsPanel companyId={companyId} />
      </div>
      <CompanyAvatarUploadModal companyId={companyId} open={step === "upload"} onClose={() => setStep(undefined)} />
    </>
  );
};

const HeaderButton = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();

  return (
    <div className={clsx("flex ml-2", className)} {...props}>
      {/* Desktop & Tablet View */}
      <div className="hidden sm:block">
        <Button startIcon="Edit2" onClick={() => navigate("edit")} size="md">
          Edit
        </Button>
      </div>
      {/* Mobile View */}
      <div className="block sm:hidden">
        <IconButton iconName="Edit2" onClick={() => navigate("edit")} size="md" />
      </div>
    </div>
  );
};