import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetProviderQuery } from "@/redux/apis/auth/authApi";
import { useLazyGetBookingOfferQuery } from "@/redux/apis/bookingOffer/bookingOfferApi";
import { useAppDispatch } from "@/redux/hooks";
import { BookingOffer } from "@/redux/slices/bookingOffer/types";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";

// import intlBooking from "./mocks/international.json";
// import localBooking from "./mocks/local.json";

interface BookingOfferPageState {
  params: {
    providerId: string;
    accountId: string;
    jobId: string;
    jobKey: string;
  };
  bookingOffer: BookingOffer;
  activeModal: "accept" | "reject" | undefined;
  setActiveModal: React.Dispatch<React.SetStateAction<"accept" | "reject" | undefined>>;
  isLoading: boolean;
}

const BookingOfferPageContext = createContext<BookingOfferPageState | null>(null);

export const BookingOfferPageContextProvider = ({ children }: React.PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const { providerId, accountId, jobId, jobKey } = useParams();
  const [getProvider, { isError: isProviderError, isFetching }] = useLazyGetProviderQuery();
  const [getBookingOffer, { isError: isBookingOfferError, data: bookingOffer }] = useLazyGetBookingOfferQuery();
  const [activeModal, setActiveModal] = useState<"accept" | "reject" | undefined>(undefined);

  if (!accountId || !jobId || !jobKey || !providerId) throw new Error("Missing booking offer params");
  if (isBookingOfferError) throw new Error("Booking offer not found");
  if (isProviderError) throw new Error("Provider not found");

  const params = useMemo(() => {
    return { providerId, accountId, jobId, jobKey };
  }, [providerId, accountId, jobId, jobKey]);

  useEffect(() => {
    getProvider(providerId)
      .unwrap()
      .then((operator) => dispatch(setActiveOperator(operator)));
  }, [dispatch, getProvider, providerId]);

  useEffect(() => {
    getBookingOffer(params);
  }, [getBookingOffer, params]);

  // const bookingOffer = transformBookingOffer(intlBooking);

  return (
    <BookingOfferPageContext.Provider
      value={{
        params,
        activeModal,
        setActiveModal,
        bookingOffer: bookingOffer!,
        isLoading: isFetching,
      }}
    >
      {children}
    </BookingOfferPageContext.Provider>
  );
};

export const useBookingOfferPageContext = () => {
  const context = useContext(BookingOfferPageContext);
  if (!context) throw new Error("useBookingOfferPageContext must be used within BookingOfferPageContextProvider");

  return context;
};
