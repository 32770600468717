import { isSameDay } from "date-fns";
import { isEmpty } from "ramda";
import { useEffect } from "react";
import { DateRangePicker, Panel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { useGetCreditCardPaymentsQuery, useLazyGetCreditCardPaymentsSummaryQuery } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { creditCardPaymentsSelector } from "@/redux/slices/payment/selectors";
import { CreditCardPaymentsModals } from "./CreditCardPaymentsModals";
import { CreditCardPaymentsSidePanels } from "./CreditCardPaymentsSidePanels";
import { CreditCardPaymentsTable } from "./CreditCardPaymentsTable";
import { PaymentsAmountDisplay } from "./PaymentsAmountDisplay";
import { useGetCreditCardPaymentsParams } from "./hooks/useGetCreditCardPaymentsParams";

export const CreditCardPaymentsPanel = () => {
  const { dateFormat } = useProfile();
  const { params, selectedStatus, setSelectedStatus, selectedDates, setSelectedDates, clearPagination, provideDateParams } =
    useGetCreditCardPaymentsParams();
  const { isFetching: isFetchingCreditCardPayments } = useGetCreditCardPaymentsQuery(params, { refetchOnMountOrArgChange: true });
  const [getCreditCardPaymentsSummary, { isFetching: isFetchingCreditCardPaymentsSummary }] = useLazyGetCreditCardPaymentsSummaryQuery();
  const { summary, items } = useAppSelector(creditCardPaymentsSelector);
  const isCreditCardPaymentsEmpty = isEmpty(items) && !isFetchingCreditCardPayments;
  const { from_date, to_date } = params;

  useEffect(() => {
    getCreditCardPaymentsSummary({ from_date, to_date });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from_date, to_date]);

  const renderEmptyState = () => {
    switch (selectedStatus.status) {
      case "paid":
        return (
          <EmptyState
            title="Successful Collections"
            description="There are currently no successful collections available for credit card payments"
          />
        );
      case "ready":
        return (
          <EmptyState
            title="Awaiting Collections"
            description="There are currently no awaiting collections available for credit card payments"
          />
        );
      case "failed":
        return (
          <EmptyState
            title="Failed Collections"
            description="There are currently no failed collections available for credit card payments"
          />
        );
      case "refund":
        return <EmptyState title="Refund Collections" description="There are currently no refunds available for credit card payments" />;
      default:
        return <EmptyState title="Credit Card Payments" description="There are currently no credit card payments records available" />;
    }
  };

  const handleSelectDates = (dates?: Date[]) => {
    clearPagination();
    setSelectedDates({
      fromDate: dates ? dates[0] : undefined,
      toDate: dates ? dates[1] : undefined,
    });
  };

  const handleSelectStatus = (status: string) => {
    clearPagination();
    setSelectedStatus({ status });
  };

  return (
    <>
      <Panel>
        <header className="mb-4 flex flex-col">
          <div className="flex">
            <Typography variant="h3" className="leading-8">
              Credit Card Payments
            </Typography>
          </div>
          <div className="flex flex-col gap-3 xl:flex-row">
            <div className="mt-3 flex flex-1 flex-row items-center gap-x-3 overflow-auto">
              <PaymentsAmountDisplay
                title="Successful Collections"
                amount={summary.paid}
                currency={summary.currency}
                isLoading={isFetchingCreditCardPaymentsSummary}
                isActive={selectedStatus.status === "paid"}
                onClick={() => handleSelectStatus("paid")}
              />
              <PaymentsAmountDisplay
                title="Awaiting Collection"
                amount={summary.ready}
                currency={summary.currency}
                isLoading={isFetchingCreditCardPaymentsSummary}
                isActive={selectedStatus.status === "ready"}
                onClick={() => handleSelectStatus("ready")}
              />
              <PaymentsAmountDisplay
                title="Failed Collections"
                amount={summary.failed}
                currency={summary.currency}
                isLoading={isFetchingCreditCardPaymentsSummary}
                isActive={selectedStatus.status === "failed"}
                onClick={() => handleSelectStatus("failed")}
              />
              <PaymentsAmountDisplay
                title="Refunds"
                amount={summary.refund}
                currency={summary.currency}
                isLoading={isFetchingCreditCardPaymentsSummary}
                isActive={selectedStatus.status === "refund"}
                onClick={() => handleSelectStatus("refund")}
              />
            </div>
            {provideDateParams && (
              <div className="flex items-end">
                <DateRangePicker
                  dates={[selectedDates.fromDate, selectedDates.toDate]}
                  onDatesChange={handleSelectDates}
                  dateFormat={dateFormat}
                  size="md"
                  preset={isSameDay(selectedDates.fromDate, selectedDates.toDate) ? "today" : undefined}
                  position="bottom-end"
                />
              </div>
            )}
          </div>
        </header>
        {isCreditCardPaymentsEmpty ? (
          renderEmptyState()
        ) : isFetchingCreditCardPayments ? (
          <Skeleton />
        ) : (
          <CreditCardPaymentsTable items={items} status={selectedStatus.status} />
        )}
      </Panel>
      {!isFetchingCreditCardPayments && <CreditCardPaymentsTable.Pagination />}
      <CreditCardPaymentsSidePanels />
      <CreditCardPaymentsModals />
    </>
  );
};
