import { useParams } from "react-router-dom";
import { PageHeader, AccreditationHistoryPanel } from "@/components/organisms";
import { Restricted } from "../Restricted/Restricted";

export const AccreditationHistory = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) return <Restricted />;

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader title="Accreditations" />
      <AccreditationHistoryPanel accreditationId={id} />
    </div>
  );
};
