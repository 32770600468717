import { Icon, Panel, Typography, Skeleton, Button } from "@/components/atoms";
import { PageList } from "@/components/molecules";
import { Restricted } from "@/components/pages";
import { useAppDispatch } from "@/redux/hooks";
import { setFixedPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { clsx } from "@/utils";
import { HowItWorks } from "../HowItWorks";
import { useFixedNationwidePricingContext, useFixedPricingContext } from "../contexts";
import { PricingProfileModals } from "./PricingProfileModals";

type Subpage = {
  name: string;
  desc: string;
  to: string;
  icon: number;
  priceNotSet: boolean;
  managed?: boolean;
};

export const PricingProfilePanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();

  const { pricingProfile, isFetching: isFetchingPricingProfiles, isRestricted } = useFixedPricingContext();
  const { nationwidePricing, isFetching: isFetchingNationwidePricing } = useFixedNationwidePricingContext();
  const isFetching = isFetchingPricingProfiles || isFetchingNationwidePricing;

  const subpages: Subpage[] = [
    {
      name: "Nationwide Fixed Pricing",
      desc: "Set nationwide fixed pricing",
      to: "nationwide",
      icon: 1,
      priceNotSet: !pricingProfile?.hasNationwidePricing,
      managed: nationwidePricing.managed,
    },
    {
      name: "State Based Fixed Pricing",
      desc: "Set fixed pricing per state",
      to: "state-based",
      icon: 2,
      priceNotSet: !pricingProfile?.hasStatePricing,
    },
    {
      name: "Location Based Fixed Pricing",
      desc: "Set fixed pricing per location of your choice",
      to: "location-based",
      icon: 3,
      priceNotSet: !pricingProfile?.hasLocationPricing,
    },
  ];

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="flex flex-1 flex-col items-start gap-5 lg:flex-row">
          <div className="flex flex-1 flex-col">
            <Panel className={clsx("flex flex-col", className)} {...props}>
              <div className="flex flex-row items-center gap-x-2">
                <Typography variant="h3" className="leading-8">
                  {pricingProfile?.name}
                </Typography>
                <Icon
                  name="Edit2"
                  size="lg"
                  className="cursor-pointer text-primary"
                  onClick={() => dispatch(setFixedPricingAction("editPricingProfile"))}
                />
              </div>
              <Typography className="text-neutral-dark-gray">
                Set your pricing for nationwide, state, and location based areas manually. Define how you want to charge your customers for
                each trip using fixed pricing.
              </Typography>
              <PageList className="-mx-5 mt-2">
                {subpages.map(({ name, desc, to, icon, priceNotSet, managed }) => (
                  <PageList.Item
                    key={name}
                    title={name}
                    description={desc}
                    to={to}
                    icon={icon}
                    alert={priceNotSet ? { type: "warning", message: "Price not set" } : undefined}
                    managed={managed}
                  />
                ))}
              </PageList>
            </Panel>
            <div className="mt-6">
              <Button
                variant="tertiary"
                size="sm"
                endIcon="ArrowRight"
                onClick={() => dispatch(setFixedPricingAction("attachedCompanies"))}
              >
                View Attached Companies
              </Button>
            </div>
          </div>
          <HowItWorks
            description="The pricing hierarchy for Fixed Pricing:"
            items={[
              "Apply Location Based Pricing if the pickup point falls within the coverage area.",
              "If no Location Based Pricing is applicable, utilize State Based Pricing.",
              "If neither Location Based nor State Based Pricing is applicable, resort to Nationwide Pricing.",
              "If none of the above options are applicable, fallback to Dynamic Pricing.",
            ]}
          />
          {pricingProfile && <PricingProfileModals pricingProfile={pricingProfile} />}
        </div>
      )}
    </>
  );
};
