import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpClientFormData, SignUpOperatorFormData } from "../types";
import { hearFromUsOptions } from "../../fixtures";

export const HowDidYouHearAboutUsField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData | SignUpClientFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">How did you hear about us?</Typography>
      <Controller
        name="howDidYouHearAboutUs"
        control={control}
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            hasError={!!errors.howDidYouHearAboutUs}
            placeholder="Select Answer"
            options={hearFromUsOptions}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            position="top"
          />
        )}
      />
      <ErrorMessage errors={errors} name="howDidYouHearAboutUs" />
    </div>
  );
};
