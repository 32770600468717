import { Container } from "@/components/atoms";
import { PageHeader, PrivateNetworkFleetLeadersPanel } from "@/components/organisms";

export const PrivateNetworkFleetLeaders = () => {
  return (
    <Container className="flex flex-1 flex-col w-full mb-12">
      <PageHeader title="Private Network" skipCrumbs={1} />
      <PrivateNetworkFleetLeadersPanel />
    </Container>
  );
};
