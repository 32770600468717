import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpOperatorFormData } from "../types";

export const YearsInOperationField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();

  return (
    <div className={clsx("flex flex-row items-center gap-x-2", className)} {...props}>
      <div className="flex flex-col w-full">
        <Typography variant="paragraph">How many years has your business been established?</Typography>
        <ErrorMessage errors={errors} name="yearsInOperation" />
      </div>
      <TextInput
        className="flex-1 w-[72px]"
        type="number"
        placeholder="0"
        step="any"
        hasError={!!errors.yearsInOperation}
        {...register("yearsInOperation")}
      />
    </div>
  );
};
