import { useSearch } from "@/hooks";
import { GetInvoicesCompaniesParams } from "@/redux/apis/company/types";

// const FiltersParam = {
//   type: ArrayParam,
//   accountStatus: ArrayParam,
//   addressState: ArrayParam,
//   rating: ArrayParam,
// };

export const useGetInvoicesCompaniesParams = () => {
  const [search, setSearch] = useSearch();

  const params: GetInvoicesCompaniesParams = {
    search: search ?? undefined,
  };

  return {
    params,
    search: params.search,
    setSearch,
  };
};
