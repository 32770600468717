import { useSignUpContext } from "./context";
import { SignUpFormClient } from "./forms/SignUpFormClient";
import { SignUpFormDriver } from "./forms/SignUpFormDriver";
import { SignUpFormOperator } from "./forms/SignUpFormOperator";

export const SignUpForms = () => {
  const { userTypeData } = useSignUpContext();
  if (!userTypeData) throw Error("userTypeData is undefined");

  switch (userTypeData.type) {
    case "operator":
      return <SignUpFormOperator />;
    case "driver":
      return <SignUpFormDriver />;
    default:
      return <SignUpFormClient />;
  }
};
