import { Divider, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface ConfigDetailsItemProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  icon?: JSX.Element;
  titleEndIcon?: JSX.Element;
  ps?: string; // a small line of text under the description
  detail?: string;
  isBullet?: boolean;
  indentPadding?: string; // only use padding left ("pl-"). Ref: https://tailwindcss.com/docs/padding
  hideDivider?: boolean;
}

export const ConfigDetail = ({
  title,
  description,
  icon,
  titleEndIcon,
  ps,
  className,
  isBullet,
  indentPadding,
  hideDivider,
  children,
  ...props
}: ConfigDetailsItemProps) => {
  return (
    <div className={className} {...props}>
      {isBullet || indentPadding ? <div className="pt-5" /> : <Divider className={clsx("py-6", { "hidden": hideDivider })} />}
      <div className="flex flex-col gap-y-4 gap-x-8 sm:flex-row">
        <div className={clsx("flex w-full sm:w-2/5", indentPadding)}>
          <div>
            <div className="flex flex-row items-center gap-2">
              {isBullet && <span className="h-2 w-2 shrink-0 grow-0 rounded-full bg-neutral-dark-gray" />}
              <div className="flex flex-row items-center gap-x-2">
                {icon}
                <Typography variant="action" className="leading-8">
                  {title}
                </Typography>
                {titleEndIcon}
              </div>
            </div>
            <Typography className={clsx("text-neutral-dark-gray", { "ml-4": isBullet })}>{description}</Typography>
            {ps ? (
              <div className="mt-3.5">
                <Typography className="text-neutral-dark-gray" variant="small">
                  {ps}
                </Typography>
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full grow self-center">{children}</div>
      </div>
    </div>
  );
};
