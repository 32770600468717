import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { AppAbility, AppAction, AppSubject } from "./types";

const ability = Ability as AbilityClass<AppAbility>;

export const defineAbilities = (permissions: string[]) => {
  const { can, rules } = new AbilityBuilder(ability);

  permissions.forEach((permission) => {
    const [subject, action] = permission.split(":");
    can(action as AppAction, subject as AppSubject);
  });

  return rules;
};
