import { z } from "zod";

export const jobRatingFormSchema = z
  .object({
    vehicleRating: z.number().min(1, "Minimum of 1 star.").max(5),
    driverRating: z.number().min(1, "Minimum of 1 star.").max(5),
    professionalismRating: z.number().min(1, "Minimum of 1 star.").max(5),
    overallServiceRating: z.number().min(1, "Minimum of 1 star.").max(5),
    bookingExperienceRating: z.number().min(1, "Minimum of 1 star.").max(5),
    comments: z.string().optional(),
    doNotMatch: z.boolean(),
  });