import { DropdownSelectOption } from "@/components/atoms";

export const closeJobTypes: DropdownSelectOption[] = [
  { name: "Completed - No Extras", value: "completed_no_extras" },
  { name: "Completed - With Extras", value: "completed_with_extras" },
  { name: "No Show", value: "completed_no_show" },
  { name: "No Show - With Extras", value: "completed_no_show_with_extras" },
];

export const extraTypeOptions = [
  {
    name: "Split",
    value: "general",
  },
  {
    name: "Driver",
    value: "out-of-pocket",
  },
  {
    name: "Operator",
    value: "out-of-pocket-operator",
  },
];
