import { useFormContext } from "react-hook-form";
import { AddOnTextInput, Typography } from "@/components/atoms";
import { DisclosurePanel } from "@/components/molecules";
import { EditVehicleFormData } from "../form";

export const VehicleAdvancedSettings = () => {
  const { register } = useFormContext<EditVehicleFormData>();

  return (
    <DisclosurePanel title="Advanced Settings" className="col-span-2 flex flex-col">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-1 flex-col">
          <Typography>Vehicle Owner Maintenance Fee</Typography>
          <Typography className="text-neutral-dark-gray">
            Portion of the driver payment paid directly to the owner of the vehicle
          </Typography>
          <a
            href="https://rideminder.freshdesk.com/support/solutions/articles/51000404585-vehicle-maintenance-fee-in-rideminder"
            target="_blank"
            rel="noreferrer"
          >
            <Typography className="text-info">Learn more</Typography>
          </a>
        </div>
        <div className="shrink-0">
          <AddOnTextInput
            endAddOn="%"
            placeholder="0"
            step="0.01"
            defaultValue={0}
            min={0}
            max={100}
            {...register("vehicleOwnerFee", { valueAsNumber: true })}
          />
        </div>
      </div>
    </DisclosurePanel>
  );
};
