import { DPDay, useDaysPropGetters } from "@rehookify/datepicker";
import { format } from "date-fns";
import { Button } from "../Button/Button";
import { useDateRangePicker } from "./context";
import { getDayClassName } from "./helpers";

export interface DayProps {
  day: DPDay;
}

export const Day = ({ day }: DayProps) => {
  const { setPreset, dpState } = useDateRangePicker();
  const { dayButton } = useDaysPropGetters(dpState);

  return (
    <div
      className={getDayClassName("w-full text-center hover:cursor-pointer [&>button]:hover:bg-primary [&>button]:hover:text-white", day)}
      {...dayButton(day, { onClick: () => setPreset(undefined) })}
    >
      <Button variant="tertiary" className="inline-block !h-7 !w-7 rounded-md !p-0  !text-base xl:rounded-[9px] ">
        {format(day.$date, "d")}
      </Button>
    </div>
  );
};
