import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useReopenBookingMutation } from "@/redux/apis/booking/endpoints/jobStatus";
import { addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

interface ReopenJobProps {
  open: boolean;
  onClose: () => void;
}

export const ReopenJob = ({ open, onClose }: ReopenJobProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [reopenJob, { isLoading: isReopeningJob }] = useReopenBookingMutation();

  const handleReopen = () => {
    reopenJob({ bookingId })
      .unwrap()
      .then(() => {
        addToast("success", "Job reopened successfully");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderButtons = () => {
    return (
      <div className="contents">
        <Button variant="secondary" onClick={onClose} className="px-8">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleReopen}>
          Reopen Job
        </Button>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose} title="Reopen Job?" renderButtons={renderButtons} className="w-[515px] !p-5">
      {isReopeningJob && <Loading />}
      <Typography>This action will restore the job to its previous state. Are you sure you want to continue?</Typography>
    </Modal>
  );
};
