import { useMemo } from "react";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetVehiclesParams } from "@/redux/apis/vehicle/types";
import { useVehicleFilters } from "../../features/Filters/useVehicleFilters";

export const useGetMyVehiclesParams = () => {
  const { page, pageSize, clearPagination } = usePagination("my-vehicles", 25);
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const { params: filterParams, filters, setFilters } = useVehicleFilters();
  const [search, setSearch] = useSearch();

  const params: GetVehiclesParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      sort: sortKey,
      direction,
      search,
      ...filterParams,
    }),
    [page, pageSize, sortKey, direction, search, filterParams]
  );

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    search,
    setSearch,
    filters,
    setFilters,
    clearPagination,
  };
};
