import { useNavigate } from "react-router-dom";
import { Icon, Typography } from "@/components/atoms";

interface DailyHireMinimumProps extends React.HTMLAttributes<HTMLDivElement> {
  dailyHireMinimum: number;
}

export const DailyHireMinimum = ({ dailyHireMinimum, className, ...props }: DailyHireMinimumProps) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <div className="flex flex-col">
        <Typography variant="h3" className="leading-8">
          Daily Hire - Minimum booking time
        </Typography>
        <Typography className="text-neutral-dark-gray">Set the minimum daily hire that you allow customers to book.</Typography>
      </div>
      <div className="border-1 relative mt-5 w-[300px] rounded-lg border border-neutral-mid-gray bg-white p-4" {...props}>
        <div className="flex justify-between space-x-3">
          <div className="min-w-0 flex-1">
            <Typography variant="h2" className="leading-6">
              {`${dailyHireMinimum} day${dailyHireMinimum > 1 ? "s" : ""}`}
            </Typography>
          </div>
          <span className="flex-shrink-0 whitespace-nowrap">
            <button onClick={() => navigate("./edit/minimum")}>
              <Icon className="h-5 w-5 text-primary" name="Edit2" />
            </button>
          </span>
        </div>
        <div className="mt-1">
          <Typography variant="action">Minimum daily booking</Typography>
        </div>
      </div>
    </div>
  );
};
