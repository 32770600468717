import { Link } from "react-router-dom";
import { EmptyState } from "@/components/molecules";

export const CompaniesAttachedEmptyState = () => {
  return (
    <EmptyState
      title="No Companies Attached"
      description={
        <>
          To add companies to this profile, please go to the{" "}
          <Link to="/companies" className="text-info">
            Company Manager
          </Link>{" "}
          &gt; Update &gt; Advanced Settings and select the price profile.
        </>
      }
    />
  );
};
