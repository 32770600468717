import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAccount } from "@/redux/baseQuery";
import { Profile, ProfileOptions } from "@/redux/slices/profile/types";
import { transformProfile, transformProfileOptions } from "./helpers";
import { UpdateProfileParams, UpdatePasswordParams } from "./types";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: baseQueryWithAccount("profile"),
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, void>({
      query: () => "",
      transformResponse: transformProfile,
      providesTags: ["Profile"],
    }),
    getProfileOptions: builder.query<ProfileOptions, void>({
      query: () => `/options`,
      transformResponse: transformProfileOptions,
    }),
    updateProfile: builder.mutation<void, UpdateProfileParams>({
      query: (body) => ({
        url: "",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
    updateAvatar: builder.mutation<void, FormData>({
      query: (body) => ({
        url: "/avatar",
        method: "POST",
        body,
      }),
    }),
    updatePassword: builder.mutation<void, UpdatePasswordParams>({
      query: (body) => ({
        url: "/password",
        method: "PUT",
        body,
      }),
    }),
    verifyEmailRequest: builder.query<void, void>({
      query: () => ({
        url: "/verify?type=email",
      }),
    }),
    verifyEmail: builder.mutation<void, string>({
      query: (code) => ({
        url: "/verify?type=email",
        method: "PUT",
        body: { code },
      }),
    }),
    verifyPhoneRequest: builder.query<void, void>({
      query: () => ({
        url: "/verify?type=mobile",
      }),
    }),
    verifyPhone: builder.mutation<void, string>({
      query: (code) => ({
        url: "/verify?type=mobile",
        method: "PUT",
        body: { code },
      }),
    }),
  }),
  tagTypes: ["Profile"],
});

export const {
  useUpdateAvatarMutation,
  useLazyGetProfileQuery,
  useGetProfileQuery,
  useGetProfileOptionsQuery,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
  useLazyVerifyEmailRequestQuery,
  useVerifyEmailMutation,
  useLazyVerifyPhoneRequestQuery,
  useVerifyPhoneMutation,
} = profileApi;
