import { PageHeader, AccreditationsPanel, AccreditationsModals } from "@/components/organisms";
import { AccreditationHistory } from "./AccreditationHistory";

export const Accreditations = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader skipCrumbs={1} title="Accreditations" />
      <AccreditationsPanel />
      <AccreditationsModals />
    </div>
  );
};

Accreditations.History = AccreditationHistory;
