import { useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersFormOptionsSelector } from "@/redux/slices/externalSupplier/selectors";
import { ExternalSuppliersFilters } from "../types";

export const TypeFilter = () => {
  const { types } = useAppSelector(externalSuppliersFormOptionsSelector);
  const { watch, setValue } = useFormContext<ExternalSuppliersFilters>();
  const selected = watch("type");

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) setValue("type", [...(selected || []), target.value]);
    else
      setValue(
        "type",
        (selected || []).filter((value) => value !== target.value)
      );
  };

  const handleSelectAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    target.checked
      ? setValue(
          "type",
          types.map((i) => i.value)
        )
      : setValue("type", []);
  };

  const isAllSelected = selected && selected.length > 0 && selected.length === types.length;

  return (
    <div className="rounded bg-neutral-surface-gray p-4">
      <Typography className="mb-3">Type</Typography>
      <div className="grid gap-4">
        <CheckboxInput id="typeSelectAll" label="Select all" onChange={handleSelectAll} checked={isAllSelected} />
        <div className="flex gap-4">
          {types.map((i) => (
            <CheckboxInput
              onChange={handleChange}
              key={i.value}
              id={i.value}
              label={i.name}
              value={i.value}
              checked={selected?.includes(i.value)}
              variant="check"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
