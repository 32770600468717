import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { companyApi, documentApi } from "@/redux/apis";
import { invoiceApi } from "@/redux/apis/payment/invoice/invoiceApi";
import { recordApi } from "@/redux/apis/payment/record/recordApi";
import { stripeApi } from "@/redux/apis/payment/stripe/stripeApi";
import { Page, pageState } from "@/redux/types";
import {
  PaymentState,
  InvoicingSidePanelAction,
  InvoicingModalAction,
  stripeInitialState,
  Invoice,
  InvoicePayment,
  ClientJob,
  Job,
  DebitHistorySidePanelAction,
  CreditCardPaymentsSidePanelAction,
  CreditCardPaymentsItem,
  CreditCardPaymentsModalAction,
} from "./types";

const initialState: PaymentState = {
  invoicing: {
    settings: {
      details: {
        accountName: "",
        accountNumber: "",
        bsb: "",
        beneficiaryBankName: "",
        beneficiaryBankAddress: "",
        swiftCode: null,
        enablePaymentOfInvoicesViaCreditCard: false,
        creditCardFeePassThrough: false,
      },
      history: [],
      historyPage: pageState,
    },
    uninvoicedJobs: {
      companies: [],
      page: pageState,
    },
    invoices: {
      companies: [],
      companiesPage: pageState,
      paymentHistory: [],
      invoiceHistory: [],
      invoiceNotes: [],
      payByCreditCard: {
        paymentStep: 1,
      },
    },
    options: {
      currency: [],
      generateOnFrequency: [],
    },
    pageState: {},
  },
  creditCardPayments: {
    payments: {
      summary: {
        paid: 0,
        failed: 0,
        ready: 0,
        refund: 0,
        currency: "",
      },
      items: [],
      page: pageState,
    },
    takeManualPayment: {
      paymentStep: 1,
    },
    pageState: {},
  },
  payoutHistory: {
    history: {
      items: [],
      page: pageState,
    },
    records: {
      items: [],
      page: pageState,
    },
  },
  debitHistory: {
    history: {
      items: [],
      page: pageState,
    },
    upcoming: {
      summary: {
        amount: 0,
        currency: "",
      },
      items: [],
      page: pageState,
    },
    records: {
      items: [],
      page: pageState,
    },
    pageState: {},
  },
  stripe: stripeInitialState,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setInvoicingSidePanelAction: (state, { payload }: PayloadAction<InvoicingSidePanelAction | undefined>) => {
      state.invoicing.pageState.sidePanelAction = payload;
    },
    setInvoicingModalAction: (state, { payload }: PayloadAction<InvoicingModalAction | undefined>) => {
      state.invoicing.pageState.modalAction = payload;
    },
    setSelectedInvoice: (state, { payload }: PayloadAction<Invoice | undefined>) => {
      state.invoicing.pageState.selectedInvoice = payload;
    },
    setSelectedInvoicePayment: (state, { payload }: PayloadAction<InvoicePayment | undefined>) => {
      state.invoicing.pageState.selectedPayment = payload;
    },
    setClientJobs: (state, { payload }: PayloadAction<{ companyIndex: number; clientJobs: Array<ClientJob>; clientJobsPage: Page }>) => {
      state.invoicing.uninvoicedJobs.companies[payload.companyIndex].clientJobs = payload.clientJobs;
      state.invoicing.uninvoicedJobs.companies[payload.companyIndex].clientJobsPage = payload.clientJobsPage;
    },
    setJobs: (state, { payload }: PayloadAction<{ companyIndex: number; jobs: Array<Job>; jobsPage: Page }>) => {
      state.invoicing.uninvoicedJobs.companies[payload.companyIndex].jobs = payload.jobs;
      state.invoicing.uninvoicedJobs.companies[payload.companyIndex].jobsPage = payload.jobsPage;
    },
    setInvoices: (state, { payload }: PayloadAction<{ companyIndex: number; invoices: Array<Invoice>; invoicesPage: Page }>) => {
      state.invoicing.invoices.companies[payload.companyIndex].invoices = payload.invoices;
      state.invoicing.invoices.companies[payload.companyIndex].invoicesPage = payload.invoicesPage;
    },
    setDebitHistorySidePanelAction: (state, { payload }: PayloadAction<DebitHistorySidePanelAction | undefined>) => {
      state.debitHistory.pageState.sidePanelAction = payload;
    },
    setCreditCardPaymentsSidePanelAction: (state, { payload }: PayloadAction<CreditCardPaymentsSidePanelAction | undefined>) => {
      state.creditCardPayments.pageState.sidePanelAction = payload;
    },
    setCreditCardPaymentsModalAction: (state, { payload }: PayloadAction<CreditCardPaymentsModalAction | undefined>) => {
      state.creditCardPayments.pageState.modalAction = payload;
    },
    setSelectedCreditCardPaymentsItem: (state, { payload }: PayloadAction<CreditCardPaymentsItem | undefined>) => {
      state.creditCardPayments.pageState.selectedPayment = payload;
    },
    resetTakeManualPayment: (state) => {
      state.creditCardPayments.takeManualPayment = { paymentStep: 1 };
    },
    setTakeManualPaymentStep: (state, { payload }: PayloadAction<number>) => {
      state.creditCardPayments.takeManualPayment.paymentStep = payload;
    },
    setPaymentMethodSetupId: (state, { payload }: PayloadAction<string>) => {
      state.creditCardPayments.takeManualPayment.paymentMethodSetupId = payload;
    },
    resetPayByCreditCard: (state) => {
      state.invoicing.invoices.payByCreditCard = { paymentStep: 1 };
    },
    setPayByCreditCardStep: (state, { payload }: PayloadAction<number>) => {
      state.invoicing.invoices.payByCreditCard.paymentStep = payload;
    },
    setPayByCreditCardPaymentMethodSetupId: (state, { payload }: PayloadAction<string>) => {
      state.invoicing.invoices.payByCreditCard.paymentMethodSetupId = payload;
    },
    clearPreviewInvoice: (state) => {
      state.invoicing.uninvoicedJobs.previewInvoice = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(companyApi.endpoints.getUninvoicedJobsCompanies.matchFulfilled, (state, { payload }) => {
      const { companies, page } = payload.data;
      state.invoicing.uninvoicedJobs.companies = page.current > 1 ? [...state.invoicing.uninvoicedJobs.companies, ...companies] : companies;
      state.invoicing.uninvoicedJobs.page = page;
    }),
      builder.addMatcher(companyApi.endpoints.getInvoicesCompanies.matchFulfilled, (state, { payload }) => {
        const { companies, companiesPage } = payload.data;
        state.invoicing.invoices.companies = companiesPage.current > 1 ? [...state.invoicing.invoices.companies, ...companies] : companies;
        state.invoicing.invoices.companiesPage = companiesPage;
      }),
      builder.addMatcher(invoiceApi.endpoints.getInvoicingOptions.matchFulfilled, (state, { payload }) => {
        const { options } = payload.data;
        state.invoicing.options = options;
      }),
      builder.addMatcher(invoiceApi.endpoints.getInvoiceSettings.matchFulfilled, (state, { payload }) => {
        const { details } = payload.data;
        state.invoicing.settings.details = details;
      }),
      builder.addMatcher(invoiceApi.endpoints.getInvoiceSettingsHistory.matchFulfilled, (state, { payload }) => {
        const { history, historyPage } = payload.data;
        state.invoicing.settings.history = historyPage.current > 1 ? [...state.invoicing.settings.history, ...history] : history;
        state.invoicing.settings.historyPage = historyPage;
      }),
      builder.addMatcher(invoiceApi.endpoints.getPaymentHistory.matchFulfilled, (state, { payload }) => {
        const { paymentHistory } = payload.data;
        state.invoicing.invoices.paymentHistory = paymentHistory;
      }),
      builder.addMatcher(invoiceApi.endpoints.getInvoiceHistory.matchFulfilled, (state, { payload }) => {
        const { invoiceHistory } = payload.data;
        state.invoicing.invoices.invoiceHistory = invoiceHistory;
      }),
      builder.addMatcher(invoiceApi.endpoints.getInvoiceNotes.matchFulfilled, (state, { payload }) => {
        const { invoiceNotes } = payload.data;
        state.invoicing.invoices.invoiceNotes = invoiceNotes;
      }),
      builder.addMatcher(companyApi.endpoints.getCompanyPaymentMethodSecret.matchFulfilled, (state, { payload }) => {
        state.invoicing.invoices.payByCreditCard.paymentMethodSecret = payload.data.paymentMethodSecret;
      }),
      builder.addMatcher(companyApi.endpoints.getCompanyPaymentMethodDetails.matchFulfilled, (state, { payload }) => {
        state.invoicing.invoices.payByCreditCard.paymentMethodDetails = payload.data;
      }),
      builder.addMatcher(invoiceApi.endpoints.getInvoiceManualPaymentDetails.matchFulfilled, (state, { payload }) => {
        state.invoicing.invoices.payByCreditCard.manualPaymentDetails = payload.data;
      }),
      builder.addMatcher(invoiceApi.endpoints.triggerInvoiceManualPayment.matchFulfilled, (state, { payload }) => {
        state.invoicing.invoices.payByCreditCard.manualPaymentDetails = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getCreditCardPayments.matchFulfilled, (state, { payload }) => {
        const { items, page } = payload.data;
        state.creditCardPayments.payments.items = items;
        state.creditCardPayments.payments.page = page;
      }),
      builder.addMatcher(recordApi.endpoints.getCreditCardPaymentsSummary.matchFulfilled, (state, { payload }) => {
        state.creditCardPayments.payments.summary = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getPaymentMethodSecret.matchFulfilled, (state, { payload }) => {
        state.creditCardPayments.takeManualPayment.paymentMethodSecret = payload.data.paymentMethodSecret;
      }),
      builder.addMatcher(recordApi.endpoints.getPaymentMethodDetails.matchFulfilled, (state, { payload }) => {
        state.creditCardPayments.takeManualPayment.paymentMethodDetails = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getManualPaymentDetails.matchFulfilled, (state, { payload }) => {
        state.creditCardPayments.takeManualPayment.manualPaymentDetails = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.triggerManualPayment.matchFulfilled, (state, { payload }) => {
        state.creditCardPayments.takeManualPayment.manualPaymentDetails = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getPayoutHistory.matchFulfilled, (state, { payload }) => {
        state.payoutHistory.history = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getPayoutRecords.matchFulfilled, (state, { payload }) => {
        state.payoutHistory.records = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getDebitHistory.matchFulfilled, (state, { payload }) => {
        state.debitHistory.history = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getDebitUpcoming.matchFulfilled, (state, { payload }) => {
        const { items, page } = payload.data;
        state.debitHistory.upcoming.items = page.current > 1 ? [...state.debitHistory.upcoming.items, ...items] : items;
        state.debitHistory.upcoming.page = page;
      }),
      builder.addMatcher(recordApi.endpoints.getDebitUpcomingSummary.matchFulfilled, (state, { payload }) => {
        state.debitHistory.upcoming.summary = payload.data;
      }),
      builder.addMatcher(recordApi.endpoints.getDebitRecords.matchFulfilled, (state, { payload }) => {
        state.debitHistory.records = payload.data;
      }),
      builder.addMatcher(stripeApi.endpoints.connectStripe.matchFulfilled, (state, { payload }) => {
        state.stripe.config = payload.data.config;
      }),
      builder.addMatcher(stripeApi.endpoints.getDebitsSecret.matchFulfilled, (state, { payload }) => {
        state.stripe.debits.clientSecret = payload.data.clientSecret;
      }),
      builder.addMatcher(stripeApi.endpoints.getDebitsDetails.matchFulfilled, (state, { payload }) => {
        state.stripe.debits = { ...state.stripe.debits, ...payload.data };
      }),
      builder.addMatcher(stripeApi.endpoints.getDebitsHistory.matchFulfilled, (state, { payload }) => {
        state.stripe.debits.history = payload.data.history;
      }),
      builder.addMatcher(stripeApi.endpoints.getDepositsDetails.matchFulfilled, (state, { payload }) => {
        state.stripe.deposits = { ...state.stripe.deposits, ...payload.data };
      }),
      builder.addMatcher(stripeApi.endpoints.getDepositsHistory.matchFulfilled, (state, { payload }) => {
        state.stripe.deposits.history = payload.data.history;
      }),
      builder.addMatcher(documentApi.endpoints.getInvoicePreview.matchFulfilled, (state, { payload }) => {
        state.invoicing.uninvoicedJobs.previewInvoice = payload.data.previewInvoice;
      });
  },
});

export const {
  setInvoicingSidePanelAction,
  setInvoicingModalAction,
  setSelectedInvoice,
  setSelectedInvoicePayment,
  setClientJobs,
  setJobs,
  setInvoices,
  setDebitHistorySidePanelAction,
  setCreditCardPaymentsSidePanelAction,
  setCreditCardPaymentsModalAction,
  setSelectedCreditCardPaymentsItem,
  resetTakeManualPayment,
  setTakeManualPaymentStep,
  setPaymentMethodSetupId,
  resetPayByCreditCard,
  setPayByCreditCardStep,
  setPayByCreditCardPaymentMethodSetupId,
  clearPreviewInvoice,
} = paymentSlice.actions;
export default paymentSlice.reducer;
