import { format } from "date-fns";
import { useEffect } from "react";
import { Icon, IconButton, Typography, SidePanel, Loading } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { useLazyGetDriverAssignmentLogsQuery } from "@/redux/apis/booking/bookingApi";
import { BookingDriverAssignmentLog, BookingDriverAssignmentType } from "@/redux/slices/booking/types";
import { bookingDriverAssignmentColumns } from "../../fixtures/driver";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { BookingDriverAssignmentLogData } from "../types";

interface DriverAssignmentLogsProps {
  open: boolean;
  onClose: () => void;
}

export const DriverAssignmentLogs = ({ open, onClose }: DriverAssignmentLogsProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [getLogs, { isFetching, data: logs }] = useLazyGetDriverAssignmentLogsQuery();

  useEffect(() => {
    if (open) getLogs(bookingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <SidePanel open={open} onClose={onClose} width={800} focused>
      <section className="flex items-center justify-between p-5">
        {isFetching && <Loading />}
        <div className="flex items-center gap-2">
          <Icon name="Stickynote" size="lg" />
          <Typography variant="title">Driver Assignment Log</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="flex h-full flex-col  p-5">
        {logs?.length ? (
          <DriverAssignmentTable logs={logs} />
        ) : (
          <div className="mt-5 place-items-center rounded-lg border border-neutral-mid-gray p-5">
            <Typography>There are currently no driver assignment logs available. </Typography>
          </div>
        )}
      </section>
    </SidePanel>
  );
};

const DriverAssignmentTable = ({ logs }: { logs: BookingDriverAssignmentLog[] }) => {
  const { dateFormat, fullTimeFormat } = useProfile();

  const data: BookingDriverAssignmentLogData[] = logs.map((log) => ({
    id: log.id,
    dateTime: (
      <div className="flex flex-col">
        <span className="leading-snug">{format(new Date(log.createdAt), dateFormat)}</span>
        <span className="leading-snug text-neutral-dark-gray">{format(new Date(log.createdAt), fullTimeFormat)}</span>
      </div>
    ),
    modifier: log.modifier,
    event: <DriverAssignmentLogType type={log.type} />,
    action: <DriverAssignmentLogAction log={log} />,
  }));

  return <DataTable data={data} columns={bookingDriverAssignmentColumns} className="mt-5 [&_td]:text-sm" />;
};

const DriverAssignmentLogType = ({ type }: { type: BookingDriverAssignmentType }) => {
  switch (type) {
    case "added":
      return (
        <div className="flex place-items-center gap-2 ">
          <Icon name="TickCircle" size="md" />
          <Typography variant="small" className="whitespace-nowrap font-medium">
            Added Driver
          </Typography>
        </div>
      );
    case "removed":
      return (
        <div className="flex place-items-center gap-2 ">
          <Icon name="Trash" size="md" />
          <Typography variant="small" className="whitespace-nowrap font-medium">
            Removed Driver
          </Typography>
        </div>
      );
    case "swapped":
      return (
        <div className="flex place-items-center gap-2 ">
          <Icon name="ArrowSwapHorizontal" size="md" />
          <Typography variant="small" className="whitespace-nowrap font-medium">
            Swapped Driver
          </Typography>
        </div>
      );
    case "reason":
      return (
        <div className="flex place-items-center gap-2 ">
          <Icon name="ArrowSwapHorizontal" size="md" />
          <Typography variant="small" className="whitespace-nowrap font-medium">
            Reason for changing
          </Typography>
        </div>
      );
    default:
      return null;
  }
};

const DriverAssignmentLogAction = ({ log }: { log: BookingDriverAssignmentLog }) => {
  switch (log.type) {
    case "added":
      return (
        <div className="flex flex-col">
          <span className="leading-snug">Driver: {log.field.to}</span>
        </div>
      );

    case "removed":
      return (
        <div className="flex flex-col">
          <span className="leading-snug">Driver: {log.field.from}</span>
        </div>
      );

    case "swapped":
      return (
        <div className="flex flex-col">
          <span className="leading-snug">
            <span className="inline-block w-[5ch]">To: </span>
            {log.field.to}
          </span>
          <span className="leading-snug">
            <span className="inline-block w-[5ch]">From: </span>
            {log.field.from}
          </span>
        </div>
      );

    case "reason":
      return (
        <div className="flex flex-col">
          <span className="leading-snug">{log.field.to}</span>
        </div>
      );
    default:
      return <span className="contents">-</span>;
  }
};
