import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton, ErrorPanel } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetVehicleCategoriesQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { VehicleCategoriesTableEditorV2 } from "./VehicleCategoriesTableEditorV2";
import { useGetVehicleCategoriesParams } from "./hooks/useGetVehicleCategoriesParams";

export const VehicleCategoriesPanel = () => {
  const navigate = useNavigate();
  const { params } = useGetVehicleCategoriesParams();
  const { data: response, isFetching, error } = useGetVehicleCategoriesQuery(params, { refetchOnMountOrArgChange: true });

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  return (
    <>
      <Panel className="mt-4">
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Vehicle Categories
            </Typography>
            <Typography className="text-neutral-dark-gray">
              Manage vehicle categories to ensure appropriate options for each transfer type.
            </Typography>
          </div>
          <div className="flex items-center">
            <Button onClick={() => navigate("add")} variant="tertiary" startIcon="Add">
              Add Vehicle Category
            </Button>
          </div>
        </div>
        {response && response.categories.length > 0 ? (
          <>
            <VehicleCategoriesTableEditorV2 className="mt-4" items={response.categories} />
            <VehicleCategoriesTableEditorV2.Pagination page={response.page} />
          </>
        ) : (
          <EmptyState
            title="Vehicle Categories"
            description="It looks like you haven't added any vehicle categories yet. Begin by adding your first vehicle category to get started"
          >
            <Button onClick={() => navigate("add")} startIcon="Add" variant="tertiary">
              Add Vehicle Category
            </Button>
          </EmptyState>
        )}
      </Panel>
    </>
  );
};
