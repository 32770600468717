import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { NationwideFixedPricing } from "@/redux/slices/pricing/types";
import { generateFixedPricingColumns, generateFixedPricingData } from "../helpers";
import { useAppSelector } from "@/redux/hooks";
import { defaultCountrySelector } from "@/redux/slices/operator/selectors";

export interface NationwideTableProps extends React.HTMLAttributes<HTMLDivElement> {
  pricing: NationwideFixedPricing;
}

export const NationwideTable = ({ pricing, className, ...props }: NationwideTableProps) => {
  const { baseInterval, intervals, currency, distanceUnit } = pricing;
  const navigate = useNavigate();
  const country = useAppSelector(defaultCountrySelector);
  

  const data = useMemo(
    () => generateFixedPricingData(baseInterval, intervals, currency, distanceUnit),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intervals]
  );

  const columns = useMemo(
    () => generateFixedPricingColumns(distanceUnit),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={className} {...props}>
      <div className="flex items-center">
        <div className="flex-1">
          <Typography variant="h3" className="leading-8">
            Nationwide Fixed Pricing
          </Typography>
          <Typography className="text-neutral-dark-gray">{`Set your pricing for each trip across ${country}.`}</Typography>
        </div>
        <div className="flex-shrink-0">
          <Button startIcon="Edit2" onClick={() => navigate("edit")} className="bg-primary px-6 hover:bg-primary-dark">
            Edit
          </Button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        className="[&_table]:table-auto overflow-x-auto w-full mt-6"
        emptyState={
          <div className="flex flex-col items-center justify-center bg-white py-28">
            <Button variant="tertiary" size="lg" startIcon="Add" onClick={() => navigate("edit")}>
              Add First Range
            </Button>
            <Typography variant="paragraph" className="mt-3 text-center text-neutral-dark-gray">
              Set up your minimum Trip Cost Nationwide and
              <br />
              add your custom cost ranges
            </Typography>
          </div>
        }
      />
    </div>
  );
};
