import { createContext } from "react";
import { QRTemplate } from "@/redux/slices/qr/types";

export type SendToPrinterStep = 1 | 2 | 3;

export interface ISendToPrinterContext {
  step: SendToPrinterStep;
  setStep: (step: SendToPrinterStep) => void;
  selectedTemplates: string[];
  setSelectedTemplates: (templates: string[]) => void;
  selectedTemplate?: QRTemplate;
  setSelectedTemplate: (template?: QRTemplate) => void;
}
export const SendToPrinterContext = createContext<ISendToPrinterContext>({
  step: 1,
  setStep: () => ({}),
  selectedTemplates: [],
  setSelectedTemplates: () => ({}),
  selectedTemplate: undefined,
  setSelectedTemplate: () => ({}),
});
