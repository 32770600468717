import { useAppSelector } from "@/redux/hooks";
import { bookingPaymentsMetaSelector, selectedBookingPaymentSelector } from "@/redux/slices/booking/selectors";
import { BookingPaymentAction, BookingPaymentLedgerLog, BookingPaymentLog } from "@/redux/slices/booking/types";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { DisburseFunds } from "./actions/DisburseFunds";
import { ProcessRefund } from "./actions/ProcessRefund";
import { ReturnFunds } from "./actions/ReturnFunds";
import { TakeTripPrepayment } from "./actions/TakeTripPrepayment";

export const PaymentActions = ({ action }: { action: BookingPaymentAction }) => {
  const payment = useAppSelector(selectedBookingPaymentSelector);
  const meta = useAppSelector(bookingPaymentsMetaSelector);
  const {
    cost: { total },
  } = useSelectedBooking();

  switch (action) {
    case "take-prepayment":
      //todo: get cardPercentageFee from booking
      return meta ? (
        <TakeTripPrepayment amountPaid={meta.fundsReceived} balance={total - meta.fundsReceived} cardPercentageFee={2.5} />
      ) : null;

    case "process-refund":
      return <ProcessRefund payment={payment as BookingPaymentLog} />;

    case "disburse-funds":
      return <DisburseFunds payment={payment as BookingPaymentLedgerLog} />;

    case "return-funds":
      return <ReturnFunds payment={payment as BookingPaymentLedgerLog} />;
  }
};
