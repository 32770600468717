import { z } from "zod";

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  delete: {
    title: "Are you sure?",
    description: "This will delete this multiplier permanently. You cannot undo this action, do you wish to continue?",
  },
  error: {
    title: "Unable to do this action",
    description: "Unable to do this action because some error occured.",
  },
};

export const transferTypeFormSchema = z.object({
  name: z.string().nonempty("Field is required and can't be empty.").max(128, "Maximum of 128 characters."),
  description: z.string().nonempty("Field is required and can't be empty.").max(128, "Maximum of 128 characters."),
  costMultiplier: z.string().nonempty("Field is required and can't be empty."),
  treatAsSpecialEvent: z.boolean(),
  tripType: z.string().nonempty("Field is required and can't be empty."),
  status: z.boolean(),
  adminOnlyTransferType: z.boolean(),
  automatedDispatchEnabled: z.boolean(),
  transferTypeTaxExempt: z.boolean(),
}).refine((data) => !(data.tripType === "fixed_rate" && !data.adminOnlyTransferType), {
  message: "Fixed transfer types must have admin only flag set (Available under Advanced Settings)",
  path: ["tripType"],
});