import { IconButton, TextInput, Tooltip, Typography } from "@/components/atoms";
import { copyToClipboard, getAssetUrl } from "@/utils";

export type FleetLeader = {
  id: string;
  name: string;
  location: string;
  phone: string;
  email: string;
  avatar: string | null;
};

export interface FleetLeadersGridItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: FleetLeader;
}

export const FleetLeadersGridItem = ({ item }: FleetLeadersGridItemProps) => {
  const { avatar, name, phone, location, email } = item;

  return (
    <div className="min-h-[350px] rounded-lg border border-neutral-mid-gray p-4">
      <figure className="grid place-items-center gap-4 py-4 text-center">
        <img src={getAssetUrl(avatar || "")} alt={name} className="h-[120px] w-[120px] rounded-full object-cover" />
        <figcaption>
          <Typography variant="title">{name}</Typography>
          <Typography className="text-neutral-dark-gray">{location}</Typography>
        </figcaption>
      </figure>
      <div className="grid gap-2">
        <fieldset className="flex ">
          <TextInput
            containerClass="w-full [&>div]:h-full"
            className="h-[38px] truncate border-none bg-neutral-gray pr-[38px] text-neutral-dark-gray"
            value={phone}
            iconName="Call"
            iconClassName="text-neutral-black"
            size="sm"
            readOnly
          />
          <Tooltip content="Copy Phone Number" placement="bottom">
            <IconButton
              onClick={() => copyToClipboard(phone)}
              iconName="Copy"
              size="sm"
              className="z-10 -ml-[38px] inline-block scale-[85%] px-2.5"
            />
          </Tooltip>
        </fieldset>

        <fieldset className="flex">
          <TextInput
            containerClass="w-full [&>div]:h-full"
            className="h-[38px] truncate border-none bg-neutral-gray pr-[38px] text-neutral-dark-gray"
            value={email}
            iconName="Sms"
            iconClassName="text-neutral-black"
            size="sm"
            readOnly
          />
          <Tooltip content="Copy Email Address" placement="bottom">
            <IconButton onClick={() => copyToClipboard(email)} iconName="Copy" size="sm" className="z-10 -ml-[38px] scale-[85%] px-2.5" />
          </Tooltip>
        </fieldset>
      </div>
    </div>
  );
};
