import { circle } from "@turf/turf";
import { FillLayer, Layer, LineLayer, Source } from "react-map-gl";

export interface LocationPointProps {
  isHovered: boolean;
  id: string;
  type: "on-route" | "on-trip";
  point: [number, number];
  dateTime: string;
  distance: number;
  time: number;
}

export const LocationPoint = ({ isHovered, id, type, point, time }: LocationPointProps) => {
  const sourceId = `${id}-source`;
  const outlineId = `${id}-outline`;
  const fillId = `${id}-fill`;

  const isStop = time > 5 * 60;
  const normalCircle = circle(point, (isStop ? 120 : 40) / 1000);
  const hoverCircle = circle(point, 180 / 1000);
  const pointColor = type === "on-trip" ? "#5B9DFF" : "#6FC062";

  const outlineLayer: LineLayer = {
    id: outlineId,
    type: "line",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    source: sourceId,
  };

  const fillLayer: FillLayer = {
    id: fillId,
    type: "fill",
    source: sourceId,
  };

  return (
    <Source id={sourceId} type="geojson" data={isHovered ? hoverCircle : normalCircle}>
      <Layer
        paint={{
          "line-width": 2,
          "line-color": isStop ? "#FACA3A" : pointColor,
          "line-opacity": isStop ? 1 : 0.8,
        }}
        {...outlineLayer}
      />
      <Layer paint={{ "fill-color": isStop ? "#FACA3A" : pointColor, "fill-opacity": isStop ? 0.9 : 0.35 }} {...fillLayer} />
    </Source>
  );
};
