import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton, BooleanDisplay, Divider } from "@/components/atoms";
import { ConfigDetail, DisclosurePanel } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetBillingSettingsQuery } from "@/redux/apis/config/admin/billingSettings";
import { useAppSelector } from "@/redux/hooks";
import { billingSettingsSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { BillingSettingsEdit } from "./BillingSettingsEdit";

export const BillingSettings = () => {
  const { isFetching } = useGetBillingSettingsQuery();
  const { errors, details } = useAppSelector(billingSettingsSelector);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  const renderRateDisplay = (rate: string) => {
    return rate ? `${rate}%` : null;
  };

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <Button className="ml-auto animate-enter" startIcon="Edit2" onClick={() => navigate("edit")}>
            Edit
          </Button>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Billing Settings
            </Typography>
            <Typography className="text-neutral-dark-gray">Set the Billing Settings for this site.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {renderErrors(errors)}
        <ConfigDetail title="Pass on Credit Card Fees to Client" description="When enabled, fees will be passed onto the client">
          <BooleanDisplay status={details.enabledPassOnCreditCardFees} />
        </ConfigDetail>
        <ConfigDetail title="Service Fee %" description="Apply a service fee on the entire trip cost for every booking">
          <Typography variant="paragraph" className="leading-8">
            {renderRateDisplay(details.defaultServiceFeeRate)}
          </Typography>
        </ConfigDetail>
        <ConfigDetail
          title="Default Operator Commission Rate (e.g. 30%)"
          description="% commission that operator wants to keep by default."
        >
          <Typography variant="paragraph" className="leading-8">
            {renderRateDisplay(details.defaultOperatorCommissionRate)}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Operator pays own drivers" description="Does operator want to pay their drivers?">
          <BooleanDisplay status={details.enabledOperatorPaysOwnDrivers} />
        </ConfigDetail>
        <ConfigDetail title="Send price information in guest emails" description="In all booking confirmations to guest passengers, include pricing">
          <BooleanDisplay status={details.enabledPriceInGuestEmail} />
        </ConfigDetail>
        <Divider className="my-6" />
        <DisclosurePanel title="Advanced Settings" className="flex flex-col">
          <ConfigDetail title="Close Job Auto-Approve" description="Specify a threshold for extras. Input an amount to auto-approve extras below this value. '0' auto-approves all. Default is '0.01', sending all extras for approval." hideDivider>
            <Typography variant="paragraph">{details.closeJobAutoApproveValue}</Typography>
          </ConfigDetail>
        </DisclosurePanel>
      </Panel>
    </>
  );
};

BillingSettings.Edit = BillingSettingsEdit;
