import { Icon, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { NetworkItem } from "@/redux/slices/network/types";
import { getAssetUrl } from "@/utils";
import { format } from "date-fns";
import { TableActionsDropdown } from "./actions/TableActionsDropdown";
import { PrivateNetworkHistoryTableData, PrivateNetworkTableData } from "./types";

export const generateNetworkColumns = () => {
  const columns: DataTableColumnDef<PrivateNetworkTableData>[] = [
    { accessorKey: "operator", header: "Operator", cell: (props) => props.row.original.operator, enableSorting: false },
    { accessorKey: "status", header: "Status", id: "status", cell: (props) => props.row.original.status },
    {
      accessorKey: "linkedAt",
      header: "Linked at",
      id: "updated",
      cell: (props) => props.row.original.linkedAt,
    },
    {
      accessorKey: "offloadFeeExemption",
      header: "Offload Fee Exemption",
      cell: (props) => props.row.original.offloadFeeExemption,
      enableSorting: false,
    },
    {
      accessorKey: "keyContact",
      header: "Key Contact",
      cell: (props) => props.row.original.keyContact,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateNetworkData = (
  items: NetworkItem[],
  dateFormat: string,
  acceptInvitation: (id: string) => void,
  declineInvitation: (id: string) => void,
  toggleOffloadFeeExemption: (id: string, active: boolean) => void,
  setPendingDisconnectRelationship: (item: NetworkItem) => void,
) => {
  const data = items.map(
    (i) =>
      ({
        id: i.id,
        operator: (
          <div className="flex flex-row gap-2.5">
            {i.operator.assetsCdn.emblem ? (
              <img src={getAssetUrl(i.operator.assetsCdn.emblem)} className="h-6 w-6" />
            ) : (
              <div className="h-6 w-6 rounded-full bg-neutral-mid-gray" />
            )}
            <Typography variant="paragraph">{i.operator.name}</Typography>
          </div>
        ),
        status: renderStatus(i.status),
        linkedAt: format(new Date(i.updated), dateFormat),
        offloadFeeExemption: (i.paysCommission === undefined ? "-" : i.paysCommission ? "No" : "Yes"),
        keyContact: (
          <div className="flex flex-col">
            <Typography variant="paragraph">{i.operator.contact.email}</Typography>
            <Typography variant="small" className="text-neutral-dark-gray">
              {i.operator.contact.phone}
            </Typography>
          </div>
        ),
        action: (
          <TableActionsDropdown
            item={i}
            acceptInvitation={acceptInvitation}
            declineInvitation={declineInvitation}
            setPendingDisconnectRelationship={setPendingDisconnectRelationship}
            toggleOffloadFeeExemption={toggleOffloadFeeExemption}
          />
        ),
      } as PrivateNetworkTableData)
  );

  return data;
};

export const generateNetworkHistoryColumns = () => {
  const columns: DataTableColumnDef<PrivateNetworkHistoryTableData>[] = [
    {
      accessorKey: "dateOfRequest",
      header: "Date of Request",
      cell: (props) => props.row.original.dateOfRequest,
      enableSorting: false,
    },
    {
      accessorKey: "timeOfRequest",
      header: "Time of Request",
      cell: (props) => props.row.original.timeOfRequest,
      enableSorting: false,
    },
    { accessorKey: "operator", header: "Operator", cell: (props) => props.row.original.operator, enableSorting: false },
    { accessorKey: "sender", header: "Sender", cell: (props) => props.row.original.sender, enableSorting: false },
    { accessorKey: "field", header: "Field", cell: (props) => props.row.original.field, enableSorting: false },
    { accessorKey: "value", header: "Value", cell: (props) => props.row.original.value, enableSorting: false },
  ];

  return columns;
};

const renderStatus = (status: string) => {
  switch (status) {
    case "pending":
      return (
        <div className="flex flex-row items-center gap-2">
          <Icon name="InfoCircle" variant="Bold" size="md" className="text-neutral-dark-gray" />
          <Typography variant="paragraph">Invitation Sent</Typography>
        </div>
      );
    case "rejected":
      return (
        <div className="flex flex-row items-center gap-2">
          <Icon name="CloseCircle" variant="Bold" size="md" className="text-danger" />
          <Typography variant="paragraph">Rejected</Typography>
        </div>
      );
    case "disconnected":
      return (
        <div className="flex flex-row items-center gap-2">
          <Icon name="CloseCircle" variant="Bold" size="md" className="text-danger" />
          <Typography variant="paragraph">Disconnected</Typography>
        </div>
      );
    case "active":
      return (
        <div className="flex flex-row items-center gap-2">
          <Icon name="TickCircle" variant="Bold" size="md" className="text-success" />
          <Typography variant="paragraph">Connected</Typography>
        </div>
      );
    default:
      return <></>;
  }
};