import { format, parseISO } from "date-fns";
import { Icon, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { DebitsHistoryItem, DepositsHistoryItem } from "@/redux/slices/payment/types";
import { BankDetailsDebitsHistoryTableData, BankDetailsDepositsHistoryTableData } from "./types";

export const renderDetail = (detail: string) => detail || "-";

export const generateBankDetailsDebitsHistoryColumns = () => {
  const columns: DataTableColumnDef<BankDetailsDebitsHistoryTableData>[] = [
    {
      accessorKey: "date",
      header: "Date",
      cell: (props) => props.row.original.date,
      enableSorting: false,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
    },
    {
      accessorKey: "modifier",
      header: "Modifier",
      cell: (props) => props.row.original.modifier,
      enableSorting: false,
    },
    {
      accessorKey: "event",
      header: "Event",
      cell: (props) => props.row.original.event,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateBankDetailsDebitsHistoryData = (items: DebitsHistoryItem[], dateFormat: string, timeFormat: string) => {
  const data = items.map((i) => {
    return {
      date: format(parseISO(i.dateTime), dateFormat),
      time: format(parseISO(i.dateTime), timeFormat),
      modifier: `${i.firstName} ${i.lastName}`,
      event: "Updated Bank Details - Debit",
    } as BankDetailsDebitsHistoryTableData;
  });

  return data;
};

export const generateBankDetailsDepositsHistoryColumns = () => {
  const columns: DataTableColumnDef<BankDetailsDepositsHistoryTableData>[] = [
    {
      accessorKey: "date",
      header: "Date",
      cell: (props) => props.row.original.date,
      enableSorting: false,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
    },
    {
      accessorKey: "modifier",
      header: "Modifier",
      cell: (props) => props.row.original.modifier,
      enableSorting: false,
    },
    {
      accessorKey: "event",
      header: "Event",
      cell: (props) => props.row.original.event,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateBankDetailsDepositsHistoryData = (items: DepositsHistoryItem[], dateFormat: string, timeFormat: string) => {
  const data = items.map((i) => {
    return {
      date: format(parseISO(i.dateTime), dateFormat),
      time: format(parseISO(i.dateTime), timeFormat),
      modifier: `${i.firstName} ${i.lastName}`,
      event: "Updated Bank Details - Deposit",
    } as BankDetailsDepositsHistoryTableData;
  });

  return data;
};

export const renderDetailsStatus = (isBlankDetails: boolean, mode?: string) => {
  if (isBlankDetails && mode !== "edit") {
    return (
      <div className="mb-2 flex flex-row items-center gap-x-2 text-warning-dark">
        <Icon name="Danger" size="sm" variant="Bold" />
        <Typography variant="action">Blank Details</Typography>
      </div>
    );
  }
};
