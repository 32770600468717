import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ErrorMessage,
  Loading,
  Modal,
  Panel,
  PhoneNumberInput,
  TextInput,
  Toggle,
  Typography,
  validatePhoneNumberInput,
} from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { getDirtyValues } from "@/helpers/formHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePrompt } from "@/hooks";
import { useUpdateEmailNotificationsMutation } from "@/redux/apis/config/admin/emailNotifications";
import { EmailNotifications } from "@/redux/slices/admin/types";
import { addToast } from "@/utils";
import { PageHeader } from "../PageHeader/PageHeader";
import { formSchema, modals } from "./fixtures";
import { FormData } from "./types";

interface EmailNotificationsEditPanelProps {
  details: EmailNotifications;
}

export const EmailNotificationsEditPanel = ({ details }: EmailNotificationsEditPanelProps) => {
  const [updateEmailNotifications, { isLoading }] = useUpdateEmailNotificationsMutation();
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isDirty, dirtyFields, isSubmitted, errors },
  } = useForm<FormData>({
    defaultValues: details,
    resolver: zodResolver(formSchema),
  });
  const values = watch();
  const [activeModal, setActiveModal] = useState<"exit" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as EmailNotifications;
    setActiveModal(null);

    updateEmailNotifications({
      phone_driver_hotline: validatePhoneNumberInput(dirtyValues.phoneDriverHotline),
      email_driver_hotline: dirtyValues.emailDriverHotline,
      enabled_bcc_all_emails: dirtyValues.enabledBccAllEmails,
      email_address_bcc_all: data.enabledBccAllEmails ? dirtyValues.emailAddressBccAll : undefined,
      enabled_booking_notifications: dirtyValues.enabledBookingNotifications,
      email_booking_notifications: data.enabledBookingNotifications ? dirtyValues.emailBookingNotifications : undefined,
      enabled_driver_notifications: dirtyValues.enabledDriverNotifications,
      email_driver_notifications: data.enabledDriverNotifications ? dirtyValues.emailDriverNotifications : undefined,
      enabled_low_job_rating_notifications: dirtyValues.enabledLowJobRatingNotifications,
      email_low_job_notifications: data.enabledLowJobRatingNotifications ? dirtyValues.emailLowJobNotifications : undefined,
      enabled_member_registration_notifications: dirtyValues.enabledMemberRegistrationNotifications,
      email_member_registration_notifications: data.enabledMemberRegistrationNotifications
        ? dirtyValues.emailMemberRegistrationNotifications
        : undefined,
      enabled_quote_notifications: dirtyValues.enabledQuoteNotifications,
      email_quote_notifications: data.enabledQuoteNotifications
        ? dirtyValues.emailQuoteNotifications
        : undefined,
      enabled_failed_payment_notifications: dirtyValues.enabledFailedPaymentNotifications,
      email_failed_payment_notifications: data.enabledFailedPaymentNotifications ? dirtyValues.emailFailedPaymentNotifications : undefined,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Notifications");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="ml-auto flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Email Notifications
              </Typography>
              <Typography className="text-neutral-dark-gray">Set your Notifications for the site.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
          <ConfigDetail title="Phone Driver Hotline" description="Phone number for when drivers need urgent support.">
            <div className="w-3/5">
              <PhoneNumberInput control={control} name="phoneDriverHotline" />
            </div>
          </ConfigDetail>
          <ConfigDetail title="Email - Driver Hotline" description="Email address for non-urgent support requests.">
            <div className="flex flex-col">
              <TextInput className="w-3/5" maxLength={254} autoComplete="email" {...register("emailDriverHotline")} />
              <ErrorMessage errors={errors} name="emailDriverHotline" />
            </div>
          </ConfigDetail>
          <ConfigDetail title="All Emails - BCC all emails" description="BCC a copy of all emails.">
            <Controller
              name="enabledBccAllEmails"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Email Address - For Notifications"
            description="BCC email address for all email notifications."
            indentPadding="pl-2"
          >
            <div className="flex flex-col">
              <TextInput
                className="w-3/5"
                maxLength={254}
                autoComplete="email"
                {...register("emailAddressBccAll")}
                disabled={!values.enabledBccAllEmails}
              />
              <ErrorMessage errors={errors} name="emailAddressBccAll" />
            </div>
          </ConfigDetail>
          <ConfigDetail title="Email - Booking Notifications" description="BCC on all booking notification emails">
            <Controller
              name="enabledBookingNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Email Address - For Notifications"
            description="BCC email address for all booking created, edited or cancelled"
            indentPadding="pl-2"
          >
            <div className="flex flex-col">
              <TextInput
                className="w-3/5"
                maxLength={254}
                autoComplete="email"
                {...register("emailBookingNotifications")}
                disabled={!values.enabledBookingNotifications}
              />
              <ErrorMessage errors={errors} name="emailBookingNotifications" />
            </div>
          </ConfigDetail>
          <ConfigDetail title="Email - Driver Notifications" description="Receive an email notification for driver related activities">
            <Controller
              name="enabledDriverNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Email Address - For Notifications"
            description="BCC email address for all driver registration or 100% completion notifications."
            indentPadding="pl-2"
          >
            <div className="flex flex-col">
              <TextInput
                className="w-3/5"
                maxLength={254}
                autoComplete="email"
                {...register("emailDriverNotifications")}
                disabled={!values.enabledDriverNotifications}
              />
              <ErrorMessage errors={errors} name="emailDriverNotifications" />
            </div>
          </ConfigDetail>
          <ConfigDetail
            title="Low Job Rating Notifications Enabled"
            description="Notifications for when a low job rating is received after a booking has been completed."
          >
            <Controller
              name="enabledLowJobRatingNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Email - Low Job Notifications"
            description="Email address to send low job notifications to."
            indentPadding="pl-2"
          >
            <div className="flex flex-col">
              <TextInput
                className="w-3/5"
                maxLength={254}
                autoComplete="email"
                {...register("emailLowJobNotifications")}
                disabled={!values.enabledLowJobRatingNotifications}
              />
              <ErrorMessage errors={errors} name="emailLowJobNotifications" />
            </div>
          </ConfigDetail>

          <ConfigDetail title="Email - Member Registration Notifications" description="Receive an email upon each new member registration">
            <Controller
              name="enabledMemberRegistrationNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Email Address - For Notifications"
            description="BCC email address used for all passenger registrations."
            indentPadding="pl-2"
          >
            <div className="flex flex-col">
              <TextInput
                className="w-3/5"
                maxLength={254}
                autoComplete="email"
                {...register("emailMemberRegistrationNotifications")}
                disabled={!values.enabledMemberRegistrationNotifications}
              />
              <ErrorMessage errors={errors} name="emailMemberRegistrationNotifications" />
            </div>
          </ConfigDetail>

          <ConfigDetail title="Email - Instant Quote Notifications" description="Receive a notification when your instant quote widget is used to send an email">
            <Controller
              name="enabledQuoteNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Email Address - For Quote Notifications"
            description="BCC email address used for all quote notifications"
            indentPadding="pl-2"
          >
            <div className="flex flex-col">
              <TextInput
                className="w-3/5"
                maxLength={254}
                autoComplete="email"
                {...register("emailQuoteNotifications")}
                disabled={!values.enabledQuoteNotifications}
              />
              <ErrorMessage errors={errors} name="emailQuoteNotifications" />
            </div>
          </ConfigDetail>

          <ConfigDetail title="Failed Payment Notifications" description="Email address to BCC all failed payment notifications.">
            <Controller
              name="enabledFailedPaymentNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Email Address - For Failed Payment Notifications"
            description="BCC email address used for all failed payment notifications"
            indentPadding="pl-2"
          >
            <div className="flex flex-col">
              <TextInput
                className="w-3/5"
                maxLength={254}
                autoComplete="email"
                {...register("emailFailedPaymentNotifications")}
                disabled={!values.enabledFailedPaymentNotifications}
              />
              <ErrorMessage errors={errors} name="emailFailedPaymentNotifications" />
            </div>
          </ConfigDetail>
        </div>
      </Panel>
    </form>
  );
};
