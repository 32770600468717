import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useGetVehicleFormOptionsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  class: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const ClassFilter = () => {
  const {
    filters: { class: vehicleClass },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!vehicleClass) return undefined;

    return vehicleClass.filter(Boolean).join(", ");
  }, [vehicleClass]);

  const onClose = () => setFilter("class", undefined);

  return (
    <FilterPopover name="class" value={value} label="Vehicle Class" onClose={onClose}>
      <ClassFilterForm />
    </FilterPopover>
  );
};

interface ClassFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const ClassFilterDrawer = ({ open, onOpenChange }: ClassFilterDrawerProps) => {
  const {
    filters: { class: vehicleClass },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!vehicleClass) return undefined;

    return vehicleClass.filter(Boolean).join(", ");
  }, [vehicleClass]);

  const onClose = () => setFilter("class", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Vehicle Class" onClose={onClose}>
      <ClassFilterForm />
    </FilterDrawer>
  );
};

const ClassFilterForm = () => {
  const { setFilter, filters } = useVehicleFilters();
  const { isFetching, data: options } = useGetVehicleFormOptionsQuery();

  const classes = useMemo(() => {
    if (isFetching || !options) return [];

    return options.class.map((vehicleClass) => ({
      name: vehicleClass,
      value: vehicleClass,
    }));
  }, [isFetching, options]);

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { class: (filters.class as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "class",
          classes.map((vehicleClass) => vehicleClass.value)
        );
        setValue(
          "class",
          classes.map((vehicleClass) => vehicleClass.value)
        );
      } else {
        setFilter("class", null);
        setValue("class", []);
      }
    },
    [classes, setFilter, setValue]
  );

  const isAllClassesSelected = useMemo(() => {
    if (!filters.class) return false;
    return filters.class.length === classes.length;
  }, [classes.length, filters.class]);

  return (
    <div className="contents">
      <Typography className="mb-4">Vehicle Class</Typography>
      <CheckboxInput label="All Vehicle Classes" onChange={handleCheckAll} variant="check" checked={isAllClassesSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="class"
          render={({ field }) => (
            <>
              {classes.map((vehicleClass) => (
                <CheckboxInput
                  key={vehicleClass.value}
                  className="capitalize"
                  label={vehicleClass.name.toLowerCase()}
                  value={vehicleClass.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newClass = [...field.value, e.target.value];
                      field.onChange(newClass);
                      setFilter("class", newClass.length > 0 ? newClass : null);
                    } else {
                      const newClass = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newClass);
                      setFilter("class", newClass.length > 0 ? newClass : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(vehicleClass.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
