import { Transition as HUITransition } from "@headlessui/react";
import React from "react";

export const Transition = (props: React.ComponentProps<typeof HUITransition>) => {
  const {
    show,
    enter = "transition-opacity ease-linear duration-300",
    enterFrom = "opacity-0 ",
    enterTo = "opacity-100 ",
    leave = "transition-opacity ease-linear duration-300",
    leaveFrom = "opacity-100 ",
    leaveTo = "opacity-0 ",
    children,
  } = props;

  return (
    <HUITransition show={show} enter={enter} enterFrom={enterFrom} enterTo={enterTo} leave={leave} leaveFrom={leaveFrom} leaveTo={leaveTo}>
      {children}
    </HUITransition>
  );
};
