import { useDebouncedEffect } from "@react-hookz/web";
import { useState } from "react";
import { Autocomplete, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { useLazySearchClientQuery } from "@/redux/apis/typeahead/typeaheadApi";

type SearchOption = {
  id: string;
  name: string;
  email: string;
  phone: string;
};

export const HeaderSearch = () => {
  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState<SearchOption | null>(null);
  const [searchClient, { isFetching, data }] = useLazySearchClientQuery();

  useDebouncedEffect(
    () => {
      if (query.length > 2) searchClient({ q: query });
    },
    [query, searchClient],
    500
  );

  const handleSelect = (option: SearchOption | null) => {
    setSelected(option);

    if (option) {
      const redirectUrl = `${getPhpHostUrl()}/o/context/switch?redirection=/passenger/${option.id}/booking/history`;
      window.location.href = redirectUrl;
    }
  };

  const options = data?.map(
    (i) =>
      ({
        id: i.id,
        name: i.name,
        email: i.email,
        phone: i.phone,
      } as SearchOption)
  );

  return (
    <div className="flex flex-1 flex-col justify-center">
      <Autocomplete
        isLoading={isFetching}
        placeholder="Search client"
        options={options}
        onQueryChange={setQuery}
        value={selected}
        size="sm"
        onValueChange={handleSelect}
        className="w-full max-w-full sm:w-[320px] [&_input]:bg-neutral-gray"
      >
        <Autocomplete.Options className="absolute z-10 mt-2 max-h-72 w-[90vw] sm:w-full overflow-y-auto overflow-x-hidden rounded-md border bg-white text-base shadow-lg empty:hidden">
          {options?.map((i) => (
            <Autocomplete.Option key={i.id} value={i} className="flex cursor-pointer items-center px-4 py-2 hover:bg-primary-light">
              <div className="flex flex-row w-full gap-x-3">
                <div className="flex-shrink-0 mt-1">
                  <img
                    className="h-[24px] w-[24px] rounded-full"
                    src={`https://via.placeholder.com/120x120/cccccc/969696?text=${i.name[0].toLocaleUpperCase()}`}
                    alt={i.name}
                  />
                </div>
                <div className="flex flex-1 flex-col overflow-hidden">
                  <Typography className="text-neutral-black">{i.name}</Typography>
                  <Typography className="text-neutral-dark-gray truncate -mt-1" variant="small">
                    {i.email}
                  </Typography>
                  <Typography className="text-neutral-dark-gray -mt-1.5" variant="small">
                    {i.phone}
                  </Typography>
                </div>
              </div>
            </Autocomplete.Option>
          ))}
        </Autocomplete.Options>
      </Autocomplete>
    </div>
  );
};
