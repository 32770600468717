import { format } from "date-fns";
import { DataTable, DataTableColumnDef } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { BookingPaymentBreakdownLog } from "@/redux/slices/booking/types";
import { capitalize, formatCurrency } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { PaymentStatus } from "../PaymentStatus";
import { BookingPaymentBreakdownData } from "../types";

export const paymentBreakdownColumns: DataTableColumnDef<BookingPaymentBreakdownData>[] = [
  {
    header: "Date",
    accessorKey: "date",
    size: 120,
    enableSorting: false,
  },
  {
    header: "Amount",
    accessorKey: "amount",
    size: 120,
    enableSorting: false,
  },
  {
    header: "Payment Transfer Type",
    accessorKey: "transferType",
    enableSorting: false,
  },
  {
    header: "Category",
    accessorKey: "category",
    size: 120,
    enableSorting: false,
  },
  {
    header: "Status",
    cell: (props) => props.row.original.status,
  },
];

export const PaymentBreakdownTable = ({ items }: { items: BookingPaymentBreakdownLog[] }) => {
  const { dateFormat } = useProfile();
  const {
    cost: { currency },
  } = useSelectedBooking();

  const data: BookingPaymentBreakdownData[] = items.map((payment) => ({
    id: payment.id,
    date: format(new Date(payment.createdAt), dateFormat),
    amount: formatCurrency(payment.amount, 2, currency),
    transferType: payment.transferType.substring(payment.transferType.indexOf("-") + 1).trim(),
    category: capitalize(payment.category.substring(0, payment.category.indexOf("_"))),
    status: <PaymentStatus status={payment.status} />,
  }));

  return <DataTable data={data} columns={paymentBreakdownColumns} className="[&_table]:table-auto overflow-x-auto w-full mt-5" />;
};
