import { useEffect } from "react";

export type Branding = {
  primary: string;
  dark: string;
  light?: string;
};

const DEFAULT_BRANDING = {
  dark: "#370A47",
  primary: "#73547E",
  light: "#E7E3ED",
};

export const useBranding = (branding?: Branding) => {
  useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty("--color-primary", branding ? branding.primary : DEFAULT_BRANDING.primary);
    root.style.setProperty("--color-primary-dark", branding ? branding.dark : DEFAULT_BRANDING.dark);
    root.style.setProperty("--color-primary-light", branding?.light ? branding.light : DEFAULT_BRANDING.light);
  }, [branding]);
};
