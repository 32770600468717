import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";

interface FixedPricingEmptyStateProps {
  onAddPricingProfile: () => void;
}

export const FixedPricingEmptyState = ({ onAddPricingProfile }: FixedPricingEmptyStateProps) => {
  return (
    <EmptyState
      title="Fixed Pricing Trip Cost"
      description="Establish customized pricing profiles for seamless assignment to your valued customers."
      hideImage
    >
      <Button startIcon="Add" onClick={onAddPricingProfile}>
        Add Pricing Profile
      </Button>
    </EmptyState>
  );
};
