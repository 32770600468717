import { format, isEqual } from "date-fns";
import { useEffect, useState } from "react";
import { Button, DateInput, Dropzone, Icon, Spinner, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useEditAccreditationMutation } from "@/redux/apis/accreditation/accreditationApi";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { AccreditationItem } from "@/redux/slices/accreditation/types";
import { addToast } from "@/utils/addToast";
import { RegistrationCertificate } from "../contents/RegistrationCertificate";
import { useAppSelector } from "@/redux/hooks";
import { defaultCountrySelector } from "@/redux/slices/operator/selectors";

interface EditAccreditationFormProps {
  item?: AccreditationItem;
  onClose: () => void;
}

export const EditAccreditationForm = ({ item, onClose }: EditAccreditationFormProps) => {
  const { dateFormat } = useProfile();
  const country = useAppSelector(defaultCountrySelector);
  const { isFetching } = useGetCountryStatesQuery(country);
  const [editAccreditation, { isLoading }] = useEditAccreditationMutation();
  const [regCert, setRegCert] = useState<File | null>();
  const [regCertName, setRegCertName] = useState<string | null>(null);
  const [expiryDate, setExpiryDate] = useState<Date | null>(new Date());
  const [regNo, setRegNo] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (item) {
      const { expiryDate, regNo, regCertName } = item;
      if (expiryDate) setExpiryDate(new Date(expiryDate));
      if (regNo) setRegNo(regNo);
      if (regCertName) setRegCertName(regCertName);
    }
  }, [item]);

  useEffect(() => {
    if (item)
      setIsDirty(
        regCertName !== item.regCertName || (expiryDate && !isEqual(expiryDate, new Date(item.expiryDate))) || regNo !== item.regNo
      );
  }, [item, regCertName, expiryDate, regNo]);

  const handleSetRegCert = (file: File | null) => {
    const fileName = file ? file.name : null;
    setRegCert(file);
    setRegCertName(fileName);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = item && expiryDate && regNo;
    if (isValid && !(item.requiresCertificate && !regCertName)) {
      const formData = new FormData();
      formData.append("document_identifier", regNo);
      if (expiryDate && !isEqual(expiryDate, new Date(item.expiryDate)))
        formData.append("document_expiry", format(expiryDate, "yyyy-MM-dd"));
      if (regCert) formData.append("document", regCert);
      editAccreditation({ id: item.id, body: formData })
        .unwrap()
        .then(() => {
          addToast("success", "Successfully updated accreditation");
          onClose();
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else {
      addToast("danger", "All fields are required.");
    }
  };

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="my-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">{`Update Accreditation${
              item?.stateAbbrev ? ` (${item.stateAbbrev})` : ""
            }`}</Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
      <form id="edit-accreditation-form" className="relative flex min-h-[265px] w-[550px] flex-col" onSubmit={onSubmit}>
        {(isFetching || isLoading) && (
          <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white/90 pt-2">
            <Spinner className="h-8 w-8" />
          </div>
        )}
        <div className="block flex-1">
          <div className="mt-2">
            <Typography variant="paragraph">Registration Certificate</Typography>
            {regCertName ? (
              <div className="mt-1 flex">
                <RegistrationCertificate name={regCertName} className="rounded-lg bg-neutral-surface-gray p-3" />
                <button
                  className="ml-[18px] mr-3 flex items-center bg-transparent text-base text-danger hover:text-danger-dark"
                  onClick={() => handleSetRegCert(null)}
                >
                  <Icon name="Trash" size="sm" className="mr-2" aria-hidden="true" />
                  <Typography variant="paragraph">Remove</Typography>
                </button>
              </div>
            ) : (
              <Dropzone
                accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "application/pdf": [".pdf"] }}
                maxSize={20000000}
                upload={(file) => handleSetRegCert(file)}
                detail={
                  <div className="flex h-full flex-col items-center justify-center gap-y-4">
                    <Icon name="FolderMinus" variant="Bold" size="xxl" aria-hidden="true" />
                    <div className="flex flex-col items-center justify-center">
                      <Typography variant="action">Drag & Drop your files here or choose file</Typography>
                      <Typography variant="paragraph">20 MB max file size</Typography>
                    </div>
                  </div>
                }
                className="mt-1 h-[200px]"
              />
            )}
          </div>
          <div className="mt-6">
            <Typography variant="paragraph">Expiry / Renewal Date</Typography>
            <DateInput className="mt-1" selected={expiryDate} onChange={(date) => setExpiryDate(date)} dateFormat={dateFormat} />
          </div>
          <div className="mt-6">
            <Typography variant="paragraph">Registration Number</Typography>
            <TextInput
              className="mt-1"
              placeholder="0000000000"
              type="number"
              min="0"
              max="9999999999"
              required
              value={regNo}
              onChange={(e) => setRegNo(e.target.value)}
            />
            <Typography variant="small" className="text-neutral-dark-gray">
              Hire Car Service Accreditation number
            </Typography>
          </div>
        </div>
        <div className="mt-6 flex">
          <div className="flex w-full flex-col gap-2">
            <Button variant="primary" type="submit" form="edit-accreditation-form" size="lg" disabled={!isDirty}>
              Update
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
