import { useCallback, useMemo } from "react";
import { useAppAbility } from "@/casl";
import { Button, Divider, Icon, IconButton, LinkButton, Popover, Spinner, Typography } from "@/components/atoms";
import { getOldBookingUrl } from "@/helpers/bookingHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useCommenceJobMutation, useConfirmDriverMutation, useMarkDriverOnWayMutation } from "@/redux/apis/booking/endpoints/jobStatus";
import { useAppDispatch } from "@/redux/hooks";
import { setAction } from "@/redux/slices/booking/bookingSlice";
import { BookingAction } from "@/redux/slices/booking/types";
import { addToast, clsx } from "@/utils";
import { BookingNavButton } from "../../../common/BookingNavButton/BookingNavButton";
import { useIsOperatorOwnJob } from "../../../hooks/useIsOperatorOwnJob";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { addBookingNotification } from "../../../utils";

export const ActionsBar = () => {
  return (
    <div className="flex items-center gap-1 !bg-transparent px-2 hover:bg-primary-light group-[.compact-view]:p-1 max-xl:justify-end xl:group-[.compact-view]:p-2">
      <DriverActionsPopover />
      <ActionsPopover />
    </div>
  );
};

const DriverActionsPopover = () => {
  const booking = useSelectedBooking();
  const isOwnJob = useIsOperatorOwnJob();

  const {
    id: bookingId,
    status,
    account,
    metadata: { isOffloaded },
  } = booking;

  const [confirmDriver, { isLoading: isConfirmingDriver }] = useConfirmDriverMutation();
  const [markDriverOnWay, { isLoading: isMarkingDriverOnWay }] = useMarkDriverOnWayMutation();
  const [startTrip, { isLoading: isCommencingJob }] = useCommenceJobMutation();

  const handleConfirmDriver = useCallback(() => {
    if (!booking.driver) throw Error("Cannot confirm driver without driver data");

    confirmDriver({ bookingId, driverId: booking.driver.id })
      .unwrap()
      .then(() => {
        addBookingNotification(`Job ${bookingId} updated`, "Driver confirmed", "driver_allocated_not_confirmed");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  }, [booking.driver, bookingId, confirmDriver]);

  const handleDriverOnTheWay = useCallback(() => {
    markDriverOnWay({ bookingId })
      .unwrap()
      .then(() => {
        addBookingNotification(`Job ${bookingId} updated`, "Driver is on the way to pickup", "driver_allocated_confirmed");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  }, [bookingId, markDriverOnWay]);

  const handleDriverStartedTrip = useCallback(() => {
    startTrip({ bookingId })
      .unwrap()
      .then(() => {
        addBookingNotification(`Job ${bookingId} updated`, "Driver has started the trip", "on_way_to_job");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  }, [bookingId, startTrip]);

  const [handleClick, isLoading, buttonContent] = useMemo(() => {
    switch (status) {
      case "driver_allocated_not_confirmed":
        return [handleConfirmDriver, isConfirmingDriver, "Confirm driver"];
      case "driver_allocated_confirmed":
        return [handleDriverOnTheWay, isMarkingDriverOnWay, "Confirm driver on the way to pickup"];
      case "on_way_to_job":
        return [handleDriverStartedTrip, isCommencingJob, "Confirm driver has started trip"];
      default:
        return [() => {}, false, ""];
    }
  }, [
    handleConfirmDriver,
    handleDriverOnTheWay,
    handleDriverStartedTrip,
    isCommencingJob,
    isConfirmingDriver,
    isMarkingDriverOnWay,
    status,
  ]);

  const handleOpenJob = () => {
    if (!account) return;
    window.open(getOldBookingUrl(account?.id, bookingId, "").replace("/admin/", ""), "_blank");
  };

  return (
    <>
      <div className="hidden flex-1 xl:block">
        {(!isOffloaded || isOwnJob) && buttonContent && (
          <Button
            onClick={handleClick}
            disabled={isLoading}
            size="sm"
            variant="info"
            className="h-[34px] animate-ripple items-center outline-info [&>.typography]:flex [&>.typography]:items-center"
          >
            {isLoading ? <Spinner className="mr-2" variant="light" /> : <Icon name="TickCircle" size="sm" className="mr-2" />}
            {buttonContent}
          </Button>
        )}
      </div>
      {(!isOffloaded || isOwnJob) && buttonContent && (
        <Popover position="bottom-end" offset={5}>
          <IconButton
            variant="secondary"
            iconName="More"
            className="h-[34px] !bg-transparent xl:hidden [&>svg]:fill-black"
            size="sm"
            iconSize="sm"
          />
          <div className="flex w-[250px] flex-col rounded-lg bg-white pb-3 pt-3 shadow-dropdown">
            <BookingNavButton icon="TickCircle" onClick={handleClick} disabled={isLoading}>
              {buttonContent}
            </BookingNavButton>
            {account && (
              <BookingNavButton icon="ExportSquare" onClick={handleOpenJob}>
                Open Booking
              </BookingNavButton>
            )}
          </div>
        </Popover>
      )}
    </>
  );
};

const ActionsPopover = () => {
  const ability = useAppAbility();
  const dispatch = useAppDispatch();

  const {
    id: bookingId,
    status,
    metadata,
    account,
    metadata: { isOffloaded },
  } = useSelectedBooking();

  const isOwnJob = useIsOperatorOwnJob();

  const setBookingAction = (action: BookingAction) => dispatch(setAction(action));
  const renderNavButtons = () => {
    switch (status) {
      case "closed":
      case "cancelled":
        return (
          <>
            {metadata.isAdjustInvoiceAllowed && ability.can("manage", "faProvider") && (
              <BookingNavButton
                customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
                onClick={() => setBookingAction("invoice-adjustments")}
              >
                Invoice Adjustments
              </BookingNavButton>
            )}
            {metadata.isReopenAllowed && (
              <BookingNavButton
                customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
                onClick={() => setBookingAction("reopen-job")}
              >
                Reopen Job
              </BookingNavButton>
            )}
            {(!isOffloaded || isOwnJob) && (
              <BookingNavButton
                customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
                onClick={() => setBookingAction("view-closing-extras-notes")}
              >
                View Closing Extras and Notes
              </BookingNavButton>
            )}
          </>
        );
      case "commenced":
      case "complete_awaiting_review":
        return (
          <>
            <BookingNavButton
              customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
              onClick={() => setBookingAction("close-job-no-cost")}
            >
              Close Job at No Cost
            </BookingNavButton>
            <BookingNavButton
              customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
              onClick={() => setBookingAction("close-job-completed")}
            >
              Close Job
            </BookingNavButton>
            <BookingNavButton
              customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
              onClick={() => setBookingAction("allocate-new-driver")}
            >
              Driver No Show
            </BookingNavButton>
          </>
        );

      default:
        return (
          <>
            {metadata.isCancelAllowed && (
              <BookingNavButton
                customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
                onClick={() => setBookingAction("close-job-customer-cancel")}
              >
                Cancelled By Customer
              </BookingNavButton>
            )}
            <>
              <BookingNavButton
                customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
                onClick={() => setBookingAction("close-job-no-cost")}
              >
                Close at No Cost
              </BookingNavButton>

              <BookingNavButton
                customIcon={<i className="inline-block h-1.5 w-1.5 rounded-full bg-primary"></i>}
                onClick={() => setBookingAction("close-job-no-driver")}
              >
                <Typography>No Driver Available</Typography>
              </BookingNavButton>
            </>
          </>
        );
    }
  };

  const isRecallEnabled =
    (!isOffloaded || isOwnJob) &&
    (metadata.isOffloadToPrivate || metadata.isOffloadToPublic) &&
    !["complete_awaiting_review", "closed"].includes(status);
  const isCustomerCancelEnabled = !["commenced", "complete_awaiting_review", "closed", "cancelled"].includes(status);
  const isOffloadEnabled = (!isOffloaded || isOwnJob) && !["commenced", "complete_awaiting_review", "closed", "cancelled"].includes(status);

  return (
    <div className="flex items-center space-x-2">
      {account && (
        <LinkButton
          to={getOldBookingUrl(account.id, bookingId, "").replace("/admin/", "")}
          target="_blank"
          size="sm"
          variant="secondary"
          className="hidden !bg-transparent xl:flex"
          startIcon="ExportSquare"
        >
          Open Booking
        </LinkButton>
      )}
      <Popover placement="bottom-end">
        <Button variant="primary" startIcon="Edit2" className="h-[34px] sm:p-3" size="sm">
          Actions
        </Button>
        <div
          className={clsx("flex w-[270px] flex-col-reverse rounded-lg bg-white pb-3 pt-6 shadow-dropdown", {
            "flex-col": isCustomerCancelEnabled,
          })}
        >
          {(!isOffloaded || isOwnJob) && (
            <nav className="flex flex-col">
              <Typography variant="action" className="px-4 leading-tight text-neutral-dark-gray">
                Closing Actions
              </Typography>
              <div className="mt-3 flex list-none flex-col">{renderNavButtons()}</div>
            </nav>
          )}
          <Divider className="my-4" />
          <nav className="flex flex-col">
            <Typography variant="action" className="px-4 leading-tight text-neutral-dark-gray">
              More Actions
            </Typography>
            <div className="mt-3 flex list-none flex-col">
              {(!isOffloaded || isOwnJob) && (
                <BookingNavButton icon="DocumentText" onClick={() => setBookingAction("job-attachments")}>
                  Attachments
                </BookingNavButton>
              )}
              {(!isOffloaded || isOwnJob) && (
                <BookingNavButton icon="Archive" onClick={() => setBookingAction("forward-confirmation")}>
                  Forward Booking Confirmation
                </BookingNavButton>
              )}
              <BookingNavButton icon="Clock" onClick={() => setBookingAction("job-history")}>
                Job History
              </BookingNavButton>
              {(!isOffloaded || isOwnJob) && (
                <BookingNavButton icon="MessageNotif" onClick={() => setBookingAction("message-log")}>
                  Job Messaging Log
                </BookingNavButton>
              )}
              {isOffloadEnabled && (
                <BookingNavButton disabled={!isOffloadEnabled} icon="Global" onClick={() => setBookingAction("offload-to-network")}>
                  Offload Job to Your Networks
                </BookingNavButton>
              )}
              {(!isOffloaded || isOwnJob) && (
                <BookingNavButton icon="DollarCircle" onClick={() => setBookingAction("payment-logs")}>
                  Payment Logs
                </BookingNavButton>
              )}
              {metadata.isReassignClientAllowed && (
                <BookingNavButton icon="User" onClick={() => setBookingAction("reassign-client")} className="[&>.typography]:-mr-1">
                  Reassign Job to Another Client
                </BookingNavButton>
              )}
              {isRecallEnabled && (
                <BookingNavButton icon="Refresh" onClick={() => setBookingAction("recall-job")} className="[&>.typography]:-mr-1">
                  Recall Job
                </BookingNavButton>
              )}
              <BookingNavButton icon="Routing" onClick={() => setBookingAction("trip-activity")} className="[&>.typography]:-mr-1">
                Trip Driver Activity
              </BookingNavButton>
            </div>
          </nav>
        </div>
      </Popover>
    </div>
  );
};
