import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { accountApi } from "@/redux/apis";
import { Account, AccountState } from "./types";

const initialState: AccountState = {
  accounts: [],
  activeAccount: {} as Account,
  setupFinished: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setActiveAccount: (state, action: PayloadAction<Account>) => {
      state.activeAccount = action.payload;
    },
    setSetupFinished: (state, action: PayloadAction<boolean>) => {
      state.setupFinished = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(accountApi.endpoints.getAccounts.matchFulfilled, (state, { payload }) => {
      state.accounts = payload.data.accounts;
    });
  },
});

export default accountSlice.reducer;
export const { setActiveAccount, setSetupFinished } = accountSlice.actions;
