import { format } from "date-fns";
import { memo, useCallback } from "react";
import { IconButton, Typography } from "@/components/atoms";
import { useBookingTripMap } from "../hooks/useBookingTripMap";
import { Popup } from "./common/Popup";
import { OverrideTripForm } from "./forms/OverrideTripForm";

export const BookingTripOverridePopup = memo(() => {
  const {
    tripLog: { job },
    states: { activeTripPoint: tripPoint },
    functions: { setActiveTripPoint },
  } = useBookingTripMap();

  const onClose = useCallback(() => setActiveTripPoint(undefined), [setActiveTripPoint]);

  if (!tripPoint) return null;

  const { location, dateTime, distance, time } = tripPoint;
  const isStop = time > 5 * 60;

  return (
    <Popup type="stop" location={location} onClose={onClose} anchor="bottom" maxWidth="300px">
      <Typography variant="action">{isStop ? "Potential Stop" : "Trip Point"}</Typography>
      <IconButton variant="custom" iconName="close" isCustomIcon onClick={onClose} className="absolute right-1 top-2 text-neutral-black" />
      <ol className="grid">
        <li className="flex justify-between gap-2">
          <Typography className="text-neutral-dark-gray">Location time</Typography>
          <Typography>{format(new Date(dateTime), "hh:mm:ss a")}</Typography>
        </li>
        <li className="flex justify-between  gap-2">
          <Typography className="text-neutral-dark-gray">Time spent</Typography>
          <Typography>{new Date(time * 1000).toISOString().substring(11, 19)}</Typography>
        </li>
        <li className="flex justify-between gap-2">
          <Typography className="text-neutral-dark-gray">Distance Traveled</Typography>
          <Typography>{distance}m</Typography>
        </li>
      </ol>
      {job.isLocationEditable && (
        <OverrideTripForm bookingId={job.id.toString()} timezone={job.timezone} dateTime={dateTime} onClose={onClose} />
      )}
    </Popup>
  );
});

BookingTripOverridePopup.displayName = "MapboxOverridePopup";
