import { useFormContext } from "react-hook-form";
import { CheckboxInput, DropdownSelect, Loading, Typography } from "@/components/atoms";
import { ExternalSuppliersFilters } from "../types";
import { useOperator } from "@/hooks";
import { useLazyGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useEffect, useState } from "react";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { addToast, getCountryName } from "@/utils";

export const StateFilter = () => {
  const { bookableCountryList, country: defaultCountry } = useOperator();
  const [getCountryStates, { isFetching }] = useLazyGetCountryStatesQuery();
  const [country, setCountry] = useState(defaultCountry);
  const [options, setOptions] = useState<Array<{ name: string; value: string }>>([]);

  const { watch, setValue } = useFormContext<ExternalSuppliersFilters>();
  const selected = watch("addressState");

  useEffect(() => {
    getCountryStates(country)
      .unwrap()
      .then(data => setOptions(data.data.states.map(state => ({ name: state.abbreviation, value: state.id }))))
      .catch((e) => {
        getErrorMessages(e).forEach((m) => {
          if (m === "Not found") addToast("danger", "No states found.");
          else addToast("danger", m);
        });
        setOptions([]);
        setValue("addressState", []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const getCountryOptions = () => {
    const options: Array<{ name: string; value: string }> = [];
    bookableCountryList.forEach(countryCode => {
      const country = getCountryName(countryCode);
      if (country) options.push({ name: country, value: countryCode });
    })

    return options;
  };

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) setValue("addressState", [...(selected || []), target.value]);
    else
      setValue(
        "addressState",
        (selected || []).filter((value) => value !== target.value)
      );
  };

  const handleSelectAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    target.checked
      ? setValue(
          "addressState",
          options.map((i) => i.value)
        )
      : setValue("addressState", []);
  };

  const isAllSelected = selected && selected.length > 0 && selected.length === options.length;

  return (
    <div className="relative rounded bg-neutral-surface-gray p-4">
      {isFetching && <Loading />}
      <Typography className="mb-3">State</Typography>
      <div className="grid gap-4">
        <div className="flex flex-row items-center gap-4">
          {bookableCountryList.length > 1 && <DropdownSelect className="flex-1" options={getCountryOptions()} value={country} onChange={setCountry} placeholder="Select Country" />}
          <CheckboxInput id="stateSelectAll" label="Select all" onChange={handleSelectAll} checked={isAllSelected} />
        </div>
        <div className="grid grid-cols-4 gap-4">
          {options.map((i) => (
            <CheckboxInput
              onChange={handleChange}
              key={i.value}
              id={i.value}
              label={i.name}
              value={i.value}
              checked={selected?.includes(i.value)}
              variant="check"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
