import { flexRender } from "@tanstack/react-table";
import { clsx } from "@/utils";
import { DataTableCell as Cell } from "../types";

export interface DataTableCellProps<T extends object> extends React.ComponentProps<"div"> {
  cell: Cell<T, unknown>;
}

export const DataTableCell = <T extends object>({ cell, className, ...props }: DataTableCellProps<T>) => {
  return (
    <div
      key={cell.id}
      className={clsx("td flex items-center overflow-hidden overflow-ellipsis", className)}
      style={{ width: cell.column.getSize() }}
      {...props}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  );
};
