import { createApi } from "@reduxjs/toolkit/dist/query/react";
import camelcaseKeys, { CamelCaseKeys } from "camelcase-keys";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { GetVehicleCategoriesResponse, VehicleCategory } from "@/redux/slices/vehicle/types";
import { createSearchParams } from "@/utils";
import { transformGetVehicleCategoriesResponse } from "./helpers";
import {
  AddVehicleCategoryParams,
  GetVehicleCategoriesFormOptionsRawResponse,
  GetVehicleCategoriesParams,
  RawVehicleCategory,
  ToggleVehicleStatusParams,
} from "./types";

export const vehicleCategoryApi = createApi({
  baseQuery: baseQueryWithProvider("vehicle/category"),
  reducerPath: "vehicleCategoryApi",
  endpoints: (builder) => ({
    getVehicleCategory: builder.query<VehicleCategory, string>({
      query: (id) => `/${id}`,
      transformResponse: (res: RawVehicleCategory) => camelcaseKeys(res, { deep: true }),
      providesTags: (_, __, id) => [{ type: "VehicleCategory", id }],
    }),
    getVehicleCategories: builder.query<GetVehicleCategoriesResponse, GetVehicleCategoriesParams>({
      query: (params) => ({
        url: `?${createSearchParams(params).toString()}`,
      }),
      providesTags: (result) => (result ? result.categories.map(({ uuid }) => ({ type: "VehicleCategory", uuid })) : ["VehicleCategory"]),
      transformResponse: transformGetVehicleCategoriesResponse,
    }),
    reorderVehicleCategories: builder.mutation<Record<string, number>, Record<string, number>>({
      query: (body) => ({
        url: "/reorder",
        method: "POST",
        body,
      }),
    }),
    toggleVehicleCategory: builder.mutation<VehicleCategory, ToggleVehicleStatusParams>({
      query: ({ id, active }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: { is_active: active },
      }),
    }),
    deleteVehicleCategory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
    getVehicleCategoriesFormOptions: builder.query<CamelCaseKeys<GetVehicleCategoriesFormOptionsRawResponse, true>, void>({
      query: () => "/options",
      transformResponse: (res: GetVehicleCategoriesFormOptionsRawResponse) => camelcaseKeys(res),
    }),
    addVehicleCategory: builder.mutation<VehicleCategory, AddVehicleCategoryParams>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      transformResponse: (res: RawVehicleCategory) => camelcaseKeys(res, { deep: true }),
      invalidatesTags: ["VehicleCategory"],
    }),
    updateVehicleCategory: builder.mutation<VehicleCategory, { id: string } & Partial<AddVehicleCategoryParams>>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PUT",
        body,
      }),
      transformResponse: (res: RawVehicleCategory) => camelcaseKeys(res, { deep: true }),
      invalidatesTags: ["VehicleCategory"],
    }),
  }),
  tagTypes: ["VehicleCategory"],
});

export const {
  useGetVehicleCategoryQuery,
  useGetVehicleCategoriesQuery,
  useReorderVehicleCategoriesMutation,
  useToggleVehicleCategoryMutation,
  useDeleteVehicleCategoryMutation,
  useGetVehicleCategoriesFormOptionsQuery,
  useAddVehicleCategoryMutation,
  useUpdateVehicleCategoryMutation,
} = vehicleCategoryApi;
