import { BookingAttachment, BookingHistoryActivity } from "@/redux/slices/booking/types";
import { SearchParams } from "@/redux/types";
import { createSearchParams } from "@/utils";
import { bookingApi } from "../bookingApi";
import {
  transformBookingAttachment,
  transformBookingAttachments,
  transformBookingHistoryActivity,
  transformBookingHistoryLogs,
  transformBookingMessages,
} from "../helpers";
import { GetBookingHistoryLogsResponse, GetBookingMessagesResponse, OffloadJobToNetworkParams } from "../types";

export const actionEndpoints = bookingApi.enhanceEndpoints({ addTagTypes: ["BookingAttachment"] }).injectEndpoints({
  endpoints: (builder) => ({
    reassignToNewClient: builder.mutation<void, { bookingId: string; accountId: string }>({
      query: ({ bookingId, accountId }) => ({
        url: `/${bookingId}/actions/change-owner`,
        method: "POST",
        body: {
          account_uuid: accountId,
        },
      }),
      invalidatesTags: (_, __, arg) => [{ type: "Booking", id: arg.bookingId }],
    }),
    forwardBookingConfirmation: builder.mutation<void, { bookingId: string; email: string }>({
      query: ({ bookingId, email }) => ({
        url: `/${bookingId}/actions/forward-booking-confirmation`,
        method: "POST",
        body: {
          email_address: email,
        },
      }),
    }),
    offloadJobToNetwork: builder.mutation<void, OffloadJobToNetworkParams>({
      query: ({ bookingId, driverPercentage, offloadToPrivate, offloadToPublic }) => ({
        url: `/${bookingId}/actions/offload`,
        method: "POST",
        body: {
          driver_percentage: driverPercentage,
          private_network: Number(offloadToPrivate),
          public_network: Number(offloadToPublic),
        },
      }),
      invalidatesTags: (_, error, arg) => {
        if (error) return [];
        return [{ type: "Booking", id: arg.bookingId }, { type: "BookingDetails" }];
      },
    }),
    recallJob: builder.mutation<void, { bookingId: string; reason: string }>({
      query: ({ bookingId, reason }) => ({
        url: `/${bookingId}/actions/recall`,
        method: "POST",
        body: {
          reason,
        },
      }),
      invalidatesTags: (_, __, arg) => [{ type: "Booking", id: arg.bookingId }],
    }),
    getJobAttachment: builder.query<BookingAttachment, { bookingId: string; documentId: string }>({
      query: ({ bookingId, documentId }) => ({
        url: `/${bookingId}/document/${documentId}`,
        method: "GET",
      }),
      transformResponse: transformBookingAttachment,
    }),
    getJobAttachments: builder.query<BookingAttachment[], string>({
      query: (bookingId) => ({
        url: `/${bookingId}/document`,
        method: "GET",
      }),
      transformResponse: transformBookingAttachments,
      providesTags: ["BookingAttachment"],
    }),
    addJobAttachment: builder.mutation<void, { bookingId: string; body: FormData }>({
      query: ({ bookingId, body }) => ({
        url: `/${bookingId}/document`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["BookingAttachment"],
    }),
    removeJobAttachment: builder.mutation<void, { bookingId: string; documentId: string }>({
      query: ({ bookingId, documentId }) => ({
        url: `/${bookingId}/document/${documentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BookingAttachment"],
    }),
    getBookingHistoryActivity: builder.query<BookingHistoryActivity, string>({
      query: (id) => `/${id}/history/activity`,
      transformResponse: transformBookingHistoryActivity,
    }),
    getBookingHistoryLogs: builder.query<GetBookingHistoryLogsResponse, { id: string } & SearchParams>({
      query: ({ id, ...params }) => `/${id}/history/logs?${createSearchParams(params)}`,
      transformResponse: transformBookingHistoryLogs,
    }),
    getBookingMessages: builder.query<GetBookingMessagesResponse, string>({
      query: (id) => `/${id}/message/log`,
      transformResponse: transformBookingMessages,
    }),
    overrideTripTimestamp: builder.mutation<void, { bookingId: string; payload: Record<string, string> }>({
      query: ({ bookingId, payload }) => ({
        url: `/${bookingId}/actions/change-timestamp`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["BookingTripLog"],
    }),
  }),
});

export const {
  useReassignToNewClientMutation,
  useForwardBookingConfirmationMutation,
  useOffloadJobToNetworkMutation,
  useRecallJobMutation,
  useAddJobAttachmentMutation,
  useLazyGetJobAttachmentsQuery,
  useRemoveJobAttachmentMutation,
  useLazyGetJobAttachmentQuery,
  useLazyGetBookingHistoryActivityQuery,
  useLazyGetBookingHistoryLogsQuery,
  useGetBookingHistoryLogsQuery,
  useLazyGetBookingMessagesQuery,
  useOverrideTripTimestampMutation,
} = actionEndpoints;
