import { ErrorPanel, Skeleton } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetOffloadDriversQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { offloadDriversSelector } from "@/redux/slices/driver/selectors";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { useState } from "react";
import { BlockedOffloadDriversModals } from "./contents/BlockedOffloadDriversModals";
import { useGetOffloadDriversParams } from "./hooks/useGetOffloadDriversParams";
import { BlockedOffloadDriversTable } from "./tables/BlockedOffloadDriversTable";
import { BlockedOffloadDriversStep } from "./types";

export const BlockedOffloadDriversPanel = () => {
  const { params } = useGetOffloadDriversParams();
  const { isFetching, error } = useGetOffloadDriversQuery(params, { refetchOnMountOrArgChange: true });
  const drivers = useAppSelector(offloadDriversSelector);
  const [selectedDriver, setSelectedDriver] = useState<OffloadDriver | undefined>(undefined);
  const [step, setStep] = useState<BlockedOffloadDriversStep>(undefined);

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;
  
  return (
    <>
      <BlockedOffloadDriversTable items={drivers} setDriver={setSelectedDriver} setStep={setStep} />
      <BlockedOffloadDriversTable.Pagination />
      <BlockedOffloadDriversModals step={step} setStep={setStep} driver={selectedDriver} setDriver={setSelectedDriver} />
    </>
  );
};