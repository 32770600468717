import { createSlice } from "@reduxjs/toolkit";
import { qrApi } from "@/redux/apis";
import { QRState } from "./types";

const initialState: QRState = {
  templates: [],
};

export const qrSlice = createSlice({
  name: "qrTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(qrApi.endpoints.getQrTemplates.matchFulfilled, (state, { payload }) => {
      state.templates = payload.data.templates;
    });
  },
});

export default qrSlice.reducer;
