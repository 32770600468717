import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AddOnTextInput, Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateDailyHireMinimumMutation } from "@/redux/apis/config/pricing/dailyHire";
import { addToast, clsx } from "@/utils";
import { DailyHireMinimumFormData } from "./types";

const onError = (messages: string[]) => {
  messages.forEach((m) => addToast("danger", m));
};

export interface DailyHireMinimumEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  dailyHireMinimum: number;
}

export const DailyHireMinimumEditor = ({ dailyHireMinimum, className, ...props }: DailyHireMinimumEditorProps) => {
  const navigate = useNavigate();
  const [updateDailyHireMinimum, { isLoading }] = useUpdateDailyHireMinimumMutation();
  const { register, handleSubmit } = useForm<DailyHireMinimumFormData>({
    defaultValues: { dailyHireMinimum },
  });

  const onSubmit = handleSubmit((data) => {
    updateDailyHireMinimum({ minimum_booking_period_minutes: data.dailyHireMinimum * 1440 })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully updated minimum daily booking.");
        navigate(-1);
      })
      .catch((e) => onError(getErrorMessages(e)));
  });

  return (
    <div className={clsx("relative", className)}>
      {isLoading && <Loading />}
      <div className="flex flex-col">
        <Typography variant="h3" className="leading-8">
          Daily Hire - Minimum booking time
        </Typography>
        <Typography className="text-neutral-dark-gray">Set the minimum daily hire that you allow customers to book.</Typography>
      </div>
      <div className="border-1 relative mt-5 w-[300px] rounded-lg border border-neutral-mid-gray bg-white p-4" {...props}>
        <Typography variant="action">Minimum daily booking</Typography>
        <form className="mt-2 flex items-center gap-4" onSubmit={onSubmit}>
          <AddOnTextInput
            type="number"
            endAddOn="Days"
            className="[&>input]:text-right"
            {...register("dailyHireMinimum")}
            required
            step="1"
            min="1"
            max="23"
          />
          <Button type="submit" className="py-1.5">
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
