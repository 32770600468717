import { formatInTimeZone } from "date-fns-tz";
import { Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { BookingHistoryLog } from "@/redux/slices/booking/types";
import { bookingHistoryColumns } from "../fixtures";
import {
  getBookingHistoryActionText,
  getBookingHistoryEventText,
  getBookingHistoryIcon,
  isBookingHistoryEventSystemType,
} from "../helpers";
import { BookingHistoryItemData } from "../types";

export const JobHistoryTable = ({ logs }: { logs: BookingHistoryLog[] }) => {
  const { dateFormat, fullTimeFormat } = useProfile();

  const data: BookingHistoryItemData[] = logs.map((i) => ({
    id: i.id,
    dateTime: (
      <div className="grid">
        <span className="leading-snug">{formatInTimeZone(new Date(i.createdAt), i.timezone, dateFormat)}</span>
        <span className="leading-snug text-neutral-dark-gray">{formatInTimeZone(new Date(i.createdAt), i.timezone, fullTimeFormat)}</span>
      </div>
    ),
    modifier:
      i.event.type !== "notification" && i.modifier ? (
        <span className="leading-none">{i.modifier}</span>
      ) : (
        <span className="leading-none text-neutral-dark-gray">System</span>
      ),
    event: (
      <div className="flex items-center gap-2">
        {getBookingHistoryIcon(i.event.name.toLowerCase() || i.event.type.toLowerCase())}
        <Typography
          variant="small"
          className="line-clamp-2 leading-snug"
          title={
            isBookingHistoryEventSystemType(i.event.name)
              ? `${i.event.name} Type: ${i.field?.name}`
              : getBookingHistoryEventText(i.event.type, i.event.name, i.field?.name)
          }
        >
          {isBookingHistoryEventSystemType(i.event.name) ? (
            <>
              <span className="inline capitalize text-neutral-dark-gray">{i.event.name} Type: </span>
              {i.field ? <span>{i.field.name}</span> : null}
            </>
          ) : (
            getBookingHistoryEventText(i.event.type, i.event.name, i.field?.name)
          )}
        </Typography>
      </div>
    ),
    action: i.field ? getBookingHistoryActionText(i.field.name, i.field.from, i.field.to) : null,
  }));

  return (
    <DataTable
      data={data}
      columns={bookingHistoryColumns}
      className="mt-5 [&>table]:table-fixed [&>table_td]:text-sm max-sm:[&>table_td]:max-w-[100px]"
      emptyState={<EmptyState />}
    />
  );
};

const EmptyState = () => {
  return (
    <div className="m-5 place-items-center rounded-lg border border-neutral-mid-gray p-5">
      <Typography>There are currently no history for this booking.</Typography>
    </div>
  );
};
