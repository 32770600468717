import { Card, CheckboxInput, IconButton, SearchInput, Typography } from "@/components/atoms";
import { FILTERS } from "./fixtures";
import { UseJobHistorySearchFilters } from "./useJobHistorySearchFilters";

export const JobHistorySearchFilters = ({ searchFiltersContext }: { searchFiltersContext: UseJobHistorySearchFilters }) => {
  const { mode, setMode, search, setSearch, filters, setFilters } = searchFiltersContext;

  return (
    <section className="mt-5 space-y-3">
      <div className="flex items-center gap-3">
        <IconButton
          size="sm"
          iconName="SearchNormal1"
          variant={mode === "search" ? "primary" : "secondary"}
          onClick={() => (mode !== "search" ? setMode("search") : setMode("default"))}
        />
        <IconButton
          size="sm"
          iconName="Sort"
          variant={mode === "filter" ? "primary" : "secondary"}
          onClick={() => (mode !== "filter" ? setMode("filter") : setMode("default"))}
        />
      </div>
      {mode === "search" ? (
        <Card className="grid space-y-4">
          <Typography variant="action">Search Modifier</Typography>
          <SearchInput size="md" value={search} onSearch={(e) => setSearch(e ?? "")} debounceTime={0} />
        </Card>
      ) : mode === "filter" ? (
        <Card className="grid space-y-4">
          <Typography variant="action">Filter Events by</Typography>
          <CheckboxInput
            label="Select all"
            checked={filters.length === Object.keys(FILTERS).length}
            onChange={(e) => setFilters(e.target.checked ? Object.keys(FILTERS) : [])}
          />
          <div className="flex items-center gap-2.5 max-sm:grid max-sm:grid-cols-3">
            {Object.entries(FILTERS).map(([key, value]) => (
              <CheckboxInput
                key={key}
                variant="check"
                label={value}
                checked={filters.includes(key)}
                onChange={(e) => {
                  setFilters(e.target.checked ? [...filters, key] : filters.filter((i) => i !== key));
                }}
              />
            ))}
          </div>
        </Card>
      ) : null}
    </section>
  );
};
