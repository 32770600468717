import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { isEmpty } from "ramda";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import z from "zod";
import { Autocomplete, Button, Icon, IconButton, Loading, Typography } from "@/components/atoms";
import { useAddAssociatedClientMutation } from "@/redux/apis/company/companyApi";
import { useLazySearchClientQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAssociatedClientAction, setAssociatedClientUnableToAddClients } from "@/redux/slices/company/companySlice";
import { selectedCompanySelector } from "@/redux/slices/company/selectors";
import { UnableToAddClient } from "@/redux/slices/company/types";
import { addToast, clsx } from "@/utils";
import { AssociatedClientOption } from "../types";

interface AssociatedClientsAddFormProps {
  onClose: () => void;
  refetchAssociatedClients: () => void;
}

const formSchema = z.object({
  associatedClients: z.array(
    z.object({
      clientId: z.string(),
      name: z.string(),
      email: z.string(),
      avatar: z.string().nullable(),
    })
  ),
});

type FormData = z.infer<typeof formSchema>;

export const AssociatedClientsAddForm = ({ onClose, refetchAssociatedClients }: AssociatedClientsAddFormProps) => {
  const dispatch = useAppDispatch();
  const [searchAssociatedClient, { isFetching, data }] = useLazySearchClientQuery();
  const [addAssociatedClient] = useAddAssociatedClientMutation();
  const company = useAppSelector(selectedCompanySelector);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "associatedClients",
  });

  const options = data?.map(
    (i) =>
      ({
        id: i.id,
        name: i.name,
        email: i.email,
        isClient: i.company?.id && i.company.id === company?.id,
        avatar: i.avatar,
      } as AssociatedClientOption)
  );

  useDebouncedEffect(
    () => {
      if (query.length > 1) searchAssociatedClient({ q: query });
    },
    [company, query, searchAssociatedClient],
    300
  );

  const onSubmit = handleSubmit(async (data) => {
    if (!company) return;
    setIsLoading(true);

    const { associatedClients } = data;
    const clientsWithErrors: UnableToAddClient[] = [];

    await Promise.all(
      associatedClients.map((client) =>
        addAssociatedClient({ companyId: company.id, associatedClientId: client.clientId })
          .unwrap()
          .catch(() => clientsWithErrors.push({ id: client.clientId, name: client.name }))
      )
    );

    if (clientsWithErrors.length < associatedClients.length) {
      addToast("success", "Successfully Added New Clients");
      refetchAssociatedClients();
    }

    if (isEmpty(clientsWithErrors)) {
      onClose();
    } else {
      dispatch(setAssociatedClientUnableToAddClients(clientsWithErrors));
      dispatch(setAssociatedClientAction("unableToAddClient"));
    }

    setIsLoading(false);
  });

  const appendClient = (client: AssociatedClientOption | null) => {
    if (client && !fields.find((field) => field.clientId === client.id)) {
      append({
        clientId: client.id,
        name: client.name,
        email: client.email,
        avatar: client.avatar,
      });
    }
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Add Clients
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );

  const renderAssociatedClients = () => {
    if (isEmpty(fields)) return;

    return (
      <div className="flex flex-col gap-y-2">
        {fields.map((field, i) => (
          <div key={field.id} className="flex flex-row items-center rounded-lg bg-neutral-surface-gray py-3 pl-4 pr-2">
            <div className="flex flex-1 flex-row items-center gap-x-4">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-light">
                <Icon name="User" size="lg" className="text-primary" />
              </div>
              <div className="flex flex-1 flex-col">
                <Typography variant="paragraph" className="leading-6">
                  {field.name}
                </Typography>
                <Typography variant="small" className="leading-6 text-neutral-dark-gray">
                  {field.email}
                </Typography>
              </div>
            </div>
            <IconButton onClick={() => remove(i)} iconName="Trash" variant="tertiary" className="!text-danger hover:bg-danger-light" />
          </div>
        ))}
      </div>
    );
  };

  const renderButtons = () => (
    <div className="flex flex-col">
      <Button variant="primary" type="submit" form="add-associated-client-form" size="lg" disabled={!isDirty}>
        Add Client
      </Button>
    </div>
  );

  return (
    <>
      {renderHeader()}
      <form id="add-associated-client-form" className="relative flex w-[85vw] flex-col md:w-[550px] lg:w-[550px]" onSubmit={onSubmit}>
        {isLoading && <Loading />}
        <div className="flex flex-1 flex-col">
          <Typography variant="paragraph">Search for clients and add them individually or in bulk.</Typography>
          <Autocomplete
            options={options}
            isLoading={isFetching}
            onValueChange={appendClient}
            onQueryChange={setQuery}
            value={null}
            className="mt-1 w-full"
            placeholder="Search Client"
          >
            <Autocomplete.Options className="absolute z-10 mt-2 w-full overflow-auto rounded-md border bg-white text-base shadow-lg empty:hidden">
              {options?.map((i, idx) => (
                <Autocomplete.Option
                  key={idx}
                  value={i}
                  className={clsx("flex w-full px-4 pb-3 pt-4", { "cursor-pointer hover:bg-primary-light": !i.isClient })}
                  disabled={i.isClient}
                >
                  <div className="flex flex-1 flex-row items-center">
                    <div className="flex flex-1 flex-col">
                      <Typography variant="paragraph" className={clsx("leading-6", { "text-neutral-dark-gray": i.isClient })}>
                        {i.name}
                      </Typography>
                      <Typography variant="small" className="leading-6 text-neutral-dark-gray">
                        {i.email}
                      </Typography>
                    </div>
                    {i.isClient && (
                      <Typography variant="paragraph" className="leading-6 text-neutral-dark-gray">
                        Already a client
                      </Typography>
                    )}
                  </div>
                </Autocomplete.Option>
              ))}
            </Autocomplete.Options>
          </Autocomplete>
          <div className="my-4 h-[312px] overflow-auto">{renderAssociatedClients()}</div>
        </div>
        {renderButtons()}
      </form>
    </>
  );
};
