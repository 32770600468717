import { Button, Icon, Typography, Modal } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { addToast } from "@/utils";

interface TransportProviderCodeViewModalProps {
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  canEdit: boolean;
}

export const TransportProviderCodeViewModal = ({ open, onClose, onEdit, canEdit }: TransportProviderCodeViewModalProps) => {
  const operator = useOperator();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    addToast("success", "Copied to clipboard!");
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Transport Provider Code
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );

  return (
    <Modal open={open} onClose={onClose} className="w-[85vw] md:w-[550px] lg:w-[550px]">
      {renderHeader()}
      <div>
        {operator?.providerCode && (
          <div className="mt-[25px] flex h-[66px] items-center rounded-lg border border-neutral-mid-gray pl-6 pr-2.5">
            <Typography variant="title" className="flex-1 uppercase truncate">
              {operator.providerCode}
            </Typography>
            <Button size="lg" startIcon="Copy" className="flex" onClick={() => copyToClipboard(operator.providerCode.toUpperCase())}>
              Copy
            </Button>
          </div>
        )}
        <div className="mt-6 flex flex-col gap-5 rounded-lg bg-neutral-gray px-5 pb-[22px] pt-6 text-neutral-dark-gray">
          <Typography variant="action">What&apos;s a Transport Provider Code?</Typography>
          <Typography variant="paragraph">
            “A Transport Provider Code is what you can send to the users who wants to join your group. The users would need a Transport
            Provider Code before they could even sign up to RideMinder, so be sure to send them the correct code.”
          </Typography>
        </div>
        {canEdit && (
          <div className="mt-8 flex flex-col items-center gap-4">
            <Button variant="secondary" size="lg" className="w-full" onClick={onEdit}>
              Edit Transport Provider Code
            </Button>
            <Typography variant="action" className="text-danger">
              You can only edit this ONCE
            </Typography>
          </div>
        )}
      </div>
    </Modal>
  );
};