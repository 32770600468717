import { useAppAbility } from "@/casl";
import { isUATorStaging } from "@/helpers/urlHelpers";
import { VehicleCategories } from "./VehicleCategories";
import { VehicleCategoriesLegacy } from "./VehicleCategoriesLegacy";

export const VehicleCategoriesRoot = () => {
  const ability = useAppAbility();

  if (ability.can("manage", "providerVehicleManager") || isUATorStaging || import.meta.env.MODE === "development") {
    return <VehicleCategories />;
  }

  return <VehicleCategoriesLegacy />;
};

const VehicleCategoriesRootAdd = () => {
  const ability = useAppAbility();

  if (ability.can("manage", "providerVehicleManager") || isUATorStaging || import.meta.env.MODE === "development") {
    return <VehicleCategories.Add />;
  }

  return <VehicleCategoriesLegacy.Add />;
};

const VehicleCategoriesRootEdit = () => {
  const ability = useAppAbility();

  if (ability.can("manage", "providerVehicleManager") || isUATorStaging || import.meta.env.MODE === "development") {
    return <VehicleCategories.Edit />;
  }

  return <VehicleCategoriesLegacy.Edit />;
};

VehicleCategoriesRoot.Add = VehicleCategoriesRootAdd;
VehicleCategoriesRoot.Edit = VehicleCategoriesRootEdit;
