import { useEffect, useState } from "react";
import { Button, CardPreview, Loading } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyGetPaymentMethodDetailsQuery, useTriggerManualPaymentMutation } from "@/redux/apis/payInvoice/payInvoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { resetPayByCreditCard, setPayByCreditCardStep } from "@/redux/slices/payInvoice/payInvoiceSlice";
import { payByCreditCardSelector } from "@/redux/slices/payInvoice/selectors";
import { addToast, formatCurrency } from "@/utils";
import { usePayInvoicePageContext } from "../context";
import { ManualPaymentDetails } from "./ManualPaymentDetails";

export const PayByCreditCardStep2 = () => {
  const { params, payInvoice } = usePayInvoicePageContext();
  const dispatch = useAppDispatch();
  const [getPaymentMethodDetails, { isFetching }] = useLazyGetPaymentMethodDetailsQuery();
  const [triggerManualPayment, { isLoading: isLoadingTriggerManualPayment }] = useTriggerManualPaymentMutation();
  const { paymentMethodSetupId, paymentMethodDetails, manualPaymentDetails } = useAppSelector(payByCreditCardSelector);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentMethodSetupId) {
        getPaymentMethodDetails({
          ...params,
          setupId: paymentMethodSetupId,
        })
          .unwrap()
          .then(() => setIsLoading(false))
          .catch((e) => {
            getErrorMessages(e).forEach((m) => addToast("danger", m));
            setIsLoading(false);
          });
      } else addToast("danger", "Something went wrong. Please contact your administrator.");
    }, 8000);

    return () => clearTimeout(timer);
  }, [paymentMethodSetupId, params, getPaymentMethodDetails]);

  const handlePayNow = () => {
    if (paymentMethodDetails) {
      triggerManualPayment({
        ...params,
        paymentMethodId: paymentMethodDetails.id,
      })
        .unwrap()
        .then(() => dispatch(setPayByCreditCardStep(3)))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else addToast("danger", "Something went wrong. Please contact your administrator.");
  };

  const handleStepClick = (step: number) => {
    if (step === 0) dispatch(resetPayByCreditCard());
  };

  return (
    <div className="relative flex h-full w-full">
      <div className="relative flex w-full flex-col">
        {(isLoading || isFetching || isLoadingTriggerManualPayment) && <Loading />}
        <div className="h-full flex-1 overflow-y-auto p-5">
          <StepIndicator currentStep={2} numSteps={2} onStepClick={handleStepClick} className="mb-16 mt-10 justify-center" />
          {paymentMethodDetails && (
            <>
              <CardPreview
                number={paymentMethodDetails.paymentMethod.last4}
                expiryMonth={paymentMethodDetails.paymentMethod.expiryMonth}
                expiryYear={paymentMethodDetails.paymentMethod.expiryYear}
                issuer={paymentMethodDetails.paymentMethod.issuer}
                className="mb-14"
              />
              <ManualPaymentDetails className="flex flex-col px-[22px]" paymentMethodDetails={paymentMethodDetails} />
            </>
          )}
        </div>
        <div className="flex flex-col gap-3 px-4 py-5">
          <Button className="w-full text-center" size="md" onClick={handlePayNow}>
            {manualPaymentDetails
              ? `Pay ${payInvoice.currency} ${formatCurrency(manualPaymentDetails.amounts.total, 2, payInvoice.currency)}`
              : "Pay Now"}
          </Button>
        </div>
      </div>
    </div>
  );
};
