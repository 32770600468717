import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddOnTextInput, Button, ErrorMessage, IconButton, Modal, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBookingMutation } from "@/redux/apis/booking/bookingApi";
import { addNotification, addToast } from "@/utils";
import { useSelectedBooking } from "../hooks/useSelectedBooking";

interface DriverPercentageProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  driverPercentage: z
    .number({
      invalid_type_error: "Invalid number",
    })
    .min(0, { message: "Minimum value of 0" })
    .max(95, { message: "The driver's input has exceeded the commission limit of 95%." }),
  reason: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

export const DriverPercentageEdit = ({ open, onClose }: DriverPercentageProps) => {
  const { id: bookingId, cost, driver } = useSelectedBooking();
  const [updateBooking, { isLoading: isSaving }] = useUpdateBookingMutation();

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      driverPercentage: cost.percentageToDriver,
    },
  });

  const {
    formState: { isDirty, errors },
    register,
    handleSubmit,
  } = form;

  const onSubmit = async (data: FormData) =>
    updateBooking({
      bookingId,
      driver_percentage: data.driverPercentage,
    })
      .then(() => {
        addNotification("success", "Driver Percentage Updated", `Driver payment percentage changed to ${data.driverPercentage}`);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  return (
    <Modal open={open} onClose={onClose} className="w-[550px]">
      <header className="flex">
        <Typography variant="title" className="flex-1">
          Driver %
        </Typography>
        <IconButton iconName="close" iconSize="lg" isCustomIcon variant="custom" onClick={onClose} />
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
        <div className="flex flex-col gap-2">
          <div>
            <AddOnTextInput
              className="w-[110px] self-start"
              endAddOn="%"
              {...register("driverPercentage", { valueAsNumber: true })}
              hasError={!!errors.driverPercentage?.message}
              size="lg"
              type="number"
              step={0.01}
              maxLength={2}
              autoFocus
            />
            <ErrorMessage errors={errors} name="driverPercentage" />
          </div>
          {driver && (
            <div>
              <TextArea
                className="mt-2"
                {...register("reason")}
                hasError={!!errors.reason}
                placeholder="Enter your reason for changing the Driver %"
              />

              <Typography variant="paragraph" className="mb-2 mt-1  text-neutral-dark-gray">
                This message will be forwarded to the designated driver.
              </Typography>
            </div>
          )}
          <Button type="submit" disabled={!isDirty || isSaving} className="w-full" variant="primary" size="lg">
            Update Driver %
          </Button>
        </div>
      </form>
    </Modal>
  );
};
