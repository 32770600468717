import { forwardRef } from "react";
import { clsx } from "@/utils";
import { Icon } from "..";
import { baseClass, getVariantClass, iconBaseClass } from "./themes";
import { IconButtonProps } from "./types";

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((buttonProps, ref) => {
  const {
    variant = "primary",
    size = "md",
    iconName,
    iconVariant,
    iconSize = "sm",
    isCustomIcon = false,
    className,
    type = "button",
    children,
    ...props
  } = buttonProps;

  const variantClass = getVariantClass(variant);

  const sizeClass = {
    "p-4": size === "xl",
    "p-3.5": size === "lg",
    "p-3": size === "md",
    "p-2": size === "sm",
    "p-0": size === "xs" || variant === "custom",
  };

  return (
    <button type={type} className={clsx(baseClass, variantClass, sizeClass, className)} ref={ref} {...props}>
      <Icon name={iconName} variant={iconVariant} size={iconSize} className={iconBaseClass} isCustom={isCustomIcon} aria-hidden="true" />
      {children}
    </button>
  );
});

IconButton.displayName = "IconButton";
