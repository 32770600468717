import { forwardRef } from "react";
import { clsx } from "@/utils";

export type TypographyVariant = "h1" | "h2" | "h3" | "title" | "action" | "paragraph" | "muted" | "small";
export interface TypographyProps extends React.HTMLAttributes<HTMLHeadingElement & HTMLSpanElement> {
  variant?: TypographyVariant;
}

export const Typography = forwardRef<HTMLHeadingElement & HTMLSpanElement, TypographyProps>(
  ({ variant, className, children, ...props }, ref) => {
    const baseClass = "typography";

    switch (variant) {
      case "h1":
        return (
          <h1 className={clsx(baseClass, "text-3xl font-bold max-lg:text-xl max-md:text-2xl", className)} {...props} ref={ref}>
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2 className={clsx(baseClass, "max-lg:text-lg text-2xl font-bold", className)} {...props} ref={ref}>
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3 className={clsx(baseClass, "max-lg:text-lg text-xl font-bold", className)} {...props} ref={ref}>
            {children}
          </h3>
        );
      case "title":
        return (
          <span className={clsx(baseClass, "text-title font-bold max-lg:text-base", className)} {...props} ref={ref}>
            {children}
          </span>
        );
      case "action":
        return (
          <span className={clsx(baseClass, "text-base font-semibold", className)} {...props} ref={ref}>
            {children}
          </span>
        );
      case "muted":
        return (
          <span className={clsx(baseClass, "text-base font-light", className)} {...props} ref={ref}>
            {children}
          </span>
        );
      case "small":
        return (
          <small className={clsx(baseClass, "inline-block text-sm font-semibold", className)} {...props} ref={ref}>
            {children}
          </small>
        );
      default:
        return (
          <p className={clsx(baseClass, "text-base font-medium", className)} {...props} ref={ref}>
            {children}
          </p>
        );
    }
  }
);

Typography.displayName = "Typography";
