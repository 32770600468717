import { useForm } from "react-hook-form";
import { Button, Icon, Loading, RadioInput, Typography } from "@/components/atoms";
import { useUserAccountsContext } from "@/contexts/userAccounts";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useChangeRoleMutation } from "@/redux/apis/userAccount/userAccountApi";
import { UserAccountRole } from "@/redux/slices/userAccount/types";
import { addToast } from "@/utils";

type FormData = {
  role: UserAccountRole;
};

export const UserAccountsRoleForm = () => {
  const { setStep, selectedUserAccount } = useUserAccountsContext();
  const [changeRole, { isLoading }] = useChangeRoleMutation();
  const { handleSubmit, watch, setValue } = useForm<FormData>({
    defaultValues: {
      role: selectedUserAccount?.role,
    },
  });

  const role = watch("role");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("role", e.target.value as UserAccountRole);
  };

  const onSubmit = (data: FormData) => {
    if (!selectedUserAccount) return;
    changeRole({ id: selectedUserAccount.id, role: data.role })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully updated ${selectedUserAccount.name}’s role`);
        setStep(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Change Account Role
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={() => setStep(undefined)} />
    </div>
  );

  if (!selectedUserAccount) return <Loading />;

  return (
    <div className="relative py-4">
      {isLoading && <Loading />}
      {renderHeader()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5 flex flex-col gap-5">
          <RadioInput
            label="Call Center"
            description="Can access customer accounts and edit bookings"
            value="Call Center"
            onChange={handleChange}
            checked={role === "Call Center"}
          />
          <RadioInput
            label="Dispatcher"
            description="Can access all Call Center functionality + Assign drivers and vehicles"
            value="Dispatcher"
            onChange={handleChange}
            checked={role === "Dispatcher"}
          />
          <RadioInput
            label="Manager"
            description="Access all non-config information"
            value="Manager"
            onChange={handleChange}
            checked={role === "Manager"}
          />
          <RadioInput
            label="Admin"
            description="Access all config related information"
            value="Admin"
            onChange={handleChange}
            checked={role === "Admin"}
          />
        </div>
        <Button disabled={!role} variant="primary" type="submit" size="lg" className="mt-5 w-full">
          Update
        </Button>
      </form>
    </div>
  );
};