import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getGoogleApiKey } from "@/helpers/apiHelpers";
import { useAppSelector } from "@/redux/hooks";
import { defaultCountrySelector } from "@/redux/slices/operator/selectors";

export type Place = {
  name?: string;
  description: string;
  place_id: string;
  types?: Array<string>;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
};

export const usePlaces = () => {
  const country = useAppSelector(defaultCountrySelector);
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesAutocompleteService({
    apiKey: getGoogleApiKey(),
  });

  return {
    getPredictions: (input: string) =>
      getPlacePredictions({
        input,
        componentRestrictions: {
          country,
        },
        types: ["establishment"],
      }),
    isLoading: isPlacePredictionsLoading,
    predictions: placePredictions.map((p) => p as Place),
    placesService,
  };
};
