import { Container } from "@/components/atoms";
import { PageHeader, PrivateNetworkHistoryPanel } from "@/components/organisms";

export const PrivateNetworkHistory = () => {
  return (
    <Container className="flex flex-1 flex-col w-full mb-12">
      <PageHeader title="Private Network" skipCrumbs={1} />
      <PrivateNetworkHistoryPanel />
    </Container>
  );
};
