import { Panel, Typography } from "@/components/atoms";
import { AddExternalSupplierForm } from "./forms/AddExternalSupplierForm";

export const ExternalSuppliersAddPanel = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <>
      <Panel {...props}>
        <div className="block">
          <Typography variant="h3" className="leading-8">
            Sign up a New External Supplier
          </Typography>
          <Typography className="text-neutral-dark-gray">Add a new external supplier for RideMinder</Typography>
        </div>
        <AddExternalSupplierForm />
      </Panel>
    </>
  );
};
