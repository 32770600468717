import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { transformExportClients, transformTripReport, transformExportDrivers, transformExportExternalSuppliers } from "./helpers";
import {
  DownloadTripReportParams,
  DownloadTripReportResponse,
  ExportClientsParams,
  ExportClientsResponse,
  ExportDriversParams,
  ExportDriversResponse,
  ExportExternalSuppliersParams,
  ExportExternalSuppliersResponse,
} from "./types";

export const reportingApi = createApi({
  reducerPath: "reportingApi",
  baseQuery: baseQueryWithProvider("reporting"),
  endpoints: (builder) => ({
    downloadTripReport: builder.mutation<DownloadTripReportResponse, DownloadTripReportParams>({
      query: (params) => ({
        url: "/trip",
        method: "GET",
        params,
      }),
      transformResponse: transformTripReport,
    }),
    exportClients: builder.query<ExportClientsResponse, ExportClientsParams>({
      query: (params) => ({
        url: "/client",
        params,
      }),
      transformResponse: transformExportClients,
    }),
    exportDrivers: builder.query<ExportDriversResponse, ExportDriversParams>({
      query: (params) => ({
        url: "/driver",
        params,
      }),
      transformResponse: transformExportDrivers,
    }),
    exportExternalSuppliers: builder.query<ExportExternalSuppliersResponse, ExportExternalSuppliersParams>({
      query: (params) => ({
        url: "/external-supplier",
        params,
      }),
      transformResponse: transformExportExternalSuppliers,
    }),
  }),
});

export const { useDownloadTripReportMutation, useLazyExportClientsQuery, useLazyExportDriversQuery, useLazyExportExternalSuppliersQuery } =
  reportingApi;
