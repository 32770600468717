import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { Userpilot } from "userpilot";
import App from "./App";
import "./index.css";

Sentry.init({
  dsn: "https://348eb05e6a944fba9bf98d9be869a4ef@o1076525.ingest.us.sentry.io/4504800726679552",
  environment: import.meta.env.MODE,
  beforeBreadcrumb: (breadcrumb, hint) => {
    try {
      if (breadcrumb?.category?.startsWith("ui")) {
        breadcrumb.message = `${hint?.event.target.tagName.toLowerCase()}: ${hint?.event.target.innerText}`;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return breadcrumb;
  },
  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

Userpilot.initialize(import.meta.env.VITE_RIDEMINDER_USERPILOT_TOKEN);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);
