import { useMemo } from "react";
import { Loading } from "@/components/atoms";
import { DataTable, DataTableSetSorting, DataTableSorting, NoSearchResults } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRemoveAssociatedClientMutation } from "@/redux/apis/company/companyApi";
import { AssociatedClient } from "@/redux/slices/company/types";
import { addToast, clsx } from "@/utils";
import { generateAssociatedClientsColumns, generateAssociatedClientsData } from "../helpers";

interface AssociatedClientsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: AssociatedClient[];
  companyId: string;
  sorting: DataTableSorting;
  setSorting: DataTableSetSorting;
}

export const AssociatedClientsTable = ({ items, companyId, className, sorting, setSorting, ...props }: AssociatedClientsTableProps) => {
  const [removeAssociatedClient, { isLoading }] = useRemoveAssociatedClientMutation();

  const disassociateClient = (associatedClient: AssociatedClient) => {
    removeAssociatedClient({ companyId, associatedClientId: associatedClient.id })
      .unwrap()
      .then(() => addToast("success", `Successfully disassociated ${associatedClient.name}`))
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => generateAssociatedClientsData(items, disassociateClient), [items]);
  const columns = useMemo(() => generateAssociatedClientsColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      {isLoading && <Loading />}
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} sorting={sorting} setSorting={setSorting} emptyState={<NoSearchResults />} {...props} />
    </div>
  );
};
