import { ComponentProps } from "react";
import { Drawer as VaulDrawer } from "vaul";
import { clsx } from "@/utils";
import { Typography } from "../Typography/Typography";

export type DrawerProps = ComponentProps<typeof VaulDrawer.Root> & {
  title?: string;
  children: React.ReactElement[];
  asNested?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  header?: JSX.Element;
  minHeight?: number;
};

export const Drawer = ({ children, title, header, asNested, open, onOpenChange, minHeight, ...props }: DrawerProps) => {
  const RootComponent = asNested ? VaulDrawer.NestedRoot : VaulDrawer.Root;

  return (
    <RootComponent shouldScaleBackground open={open} onOpenChange={onOpenChange} {...props}>
      <VaulDrawer.Trigger asChild>{children[0]}</VaulDrawer.Trigger>
      <VaulDrawer.Portal>
        <VaulDrawer.Overlay className="fixed inset-0 z-50 bg-neutral-black/50" />
        <VaulDrawer.Content
          className="max-h-auto fixed bottom-0 left-0 right-0 z-50 mt-24 flex max-h-[90%] min-h-[200px] flex-col rounded-t-[10px] bg-white"
          style={{
            minHeight: minHeight ?? 200,
          }}
        >
          <div className={clsx("relative flex-1 overflow-y-auto overflow-x-clip rounded-t-[10px] bg-white p-4", header && "pt-[50px]")}>
            {header && <div className="fixed inset-x-0 top-0 z-10 h-[50px] rounded-t-[10px] bg-white px-4 pb-2 pt-5">{header}</div>}
            <div className="fixed inset-x-0 top-4 z-10 mx-auto h-1 w-12 flex-shrink-0 -translate-y-2 cursor-grab rounded-full bg-gray-300" />
            {title && (
              <VaulDrawer.Title asChild>
                <Typography className="text-center">{title}</Typography>
              </VaulDrawer.Title>
            )}
            {children[1]}
          </div>
        </VaulDrawer.Content>
      </VaulDrawer.Portal>
    </RootComponent>
  );
};

Drawer.Close = VaulDrawer.Close;
