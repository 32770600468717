import { motion, AnimatePresence } from "framer-motion";
import { useEffect } from "react";
import { Alert, Card, Skeleton } from "@/components/atoms";
import { useGetBookingDetailsQuery } from "@/redux/apis/booking/bookingApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInlineEditing, setSelectedBooking } from "@/redux/slices/booking/bookingSlice";
import { bookingsPageStateSelector } from "@/redux/slices/booking/selectors";
import { clsx } from "@/utils";
import { BookingActions } from "../../BookingActions";
import { BookingInlineActions } from "../../BookingInlineActions";
import { ActionsBar } from "./details/ActionsBar";
import { BasicDetails } from "./details/BasicDetails";
import { Cost } from "./details/Cost";
import { Destinations } from "./details/Destinations";
import { DriverVehicle } from "./details/DriverVehicle";
import { FeesExtras } from "./details/FeesExtras";
import { Notes } from "./details/Notes";
import { OperatorDetails } from "./details/OperatorDetails";
import { Passenger } from "./details/Passenger";
import { Preferences } from "./details/Preferences";

export interface BookingTableDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  bookingId: string;
}

export const BookingTableDetails = ({ bookingId }: BookingTableDetailsProps) => {
  const dispatch = useAppDispatch();
  const { isFetching, isError } = useGetBookingDetailsQuery(bookingId, {
    refetchOnMountOrArgChange: true,
    skip: !bookingId,
  });

  useEffect(() => {
    return () => {
      dispatch(setSelectedBooking(undefined));
      dispatch(setInlineEditing(undefined));
    };
  }, [dispatch]);

  if (isError)
    return (
      <div className="p-4">
        <Alert
          className="bg-[#FFFAEC]"
          type="warning"
          message="This job is no longer available. Common reasons include client cancellation, operator recall, or job declined by your driver."
        />
      </div>
    );

  return (
    <AnimatePresence>
      {isFetching ? (
        <motion.div initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }}>
          <div className="grid min-h-[300px] grid-cols-4 gap-3 p-2 2xl:p-4">
            <Card className="col-span-2 bg-white/75 max-sm:col-span-4">
              <Skeleton variant="short" />
            </Card>
            <Card className="col-span-1 bg-white/75 max-sm:hidden">
              <Skeleton variant="short" />
            </Card>
            <Card className="col-span-1 bg-white/75 max-sm:hidden">
              <Skeleton variant="short" />
            </Card>
          </div>
        </motion.div>
      ) : (
        <BookingContents />
      )}
    </AnimatePresence>
  );
};

const BookingContents = () => {
  const { inlineEditing, selectedBooking: booking } = useAppSelector(bookingsPageStateSelector);
  if (!booking) return null;

  return (
    <>
      <ActionsBar />
      <div
        className={clsx(
          "grid min-h-[300px] grid-cols-6 gap-2.5 p-3 group-[.compact-view]:p-1 xl:group-[.compact-view]:p-2.5",
          !!inlineEditing && "pointer-events-none [&>div]:opacity-50 "
        )}
      >
        <div className="col-span-6 block md:col-span-3 xl:contents">
          <div className="col-span-3 block space-y-2.5 xl:col-span-1 [&>div]:rounded-lg [&>div]:bg-white [&>div]:p-3">
            <BasicDetails />
            <Passenger />
            <FeesExtras />
          </div>
          <div className="col-span-3 block space-y-2.5 xl:col-span-2  [&>div]:rounded-lg [&>div]:bg-white [&>div]:p-3">
            <DriverVehicle />
            <Notes />
          </div>
        </div>
        <div className="col-span-6 block md:col-span-3 xl:contents">
          <div className="col-span-3 block space-y-2.5 xl:col-span-1 [&>div]:rounded-lg [&>div]:bg-white [&>div]:p-3">
            <Cost />
            <Preferences />
          </div>
          <div className="col-span-3 block space-y-2.5 !bg-transparent xl:col-span-2  [&>div]:rounded-lg [&>div]:bg-white [&>div]:p-3">
            <Destinations className="max-xl:!mt-2.5 " />
            <OperatorDetails />
          </div>
        </div>
      </div>
      <BookingInlineActions />
      <BookingActions />
    </>
  );
};
