import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Button, Loading, Typography } from "@/components/atoms";
import { Pagination } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetCompanyOptionsQuery, useUpdateCompanyMutation } from "@/redux/apis/company/companyApi";
import { UpdateCompanyParams } from "@/redux/apis/company/types";
import { Company } from "@/redux/slices/company/types";
import { addToast, clsx } from "@/utils";
import { companiesFormSchema } from "../fixtures";
import { CompaniesFormData } from "../types";
import { CompaniesFormStep1 } from "./CompaniesFormStep1";
import { CompaniesFormStep2 } from "./CompaniesFormStep2";

interface CompaniesEditFormProps extends React.HTMLAttributes<HTMLFormElement> {
  company: Company;
}

export const CompaniesEditForm = ({ company, ...props }: CompaniesEditFormProps) => {
  const operator = useOperator();
  const navigate = useNavigate();
  const ability = useAppAbility();
  const [formStep, setFormStep] = useState(1);
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  const { isFetching } = useGetCompanyOptionsQuery();
  const isCompanyLevel = company.invoice.generateOnLevel === "company" || company.invoice.generateOnLevel === "client_no_invoice";
  const methods = useForm<CompaniesFormData>({
    defaultValues: {
      companyName: company.name,
      companyAddress: company.address,
      companyContactNumber: company.phone,
      customReferenceFieldDescription: company.notes.reference,
      businessRegistrationType: company.businessRegistrationType,
      businessRegistrationIdNumber: company.businessRegistrationId,
      notes: company.notes.admin,
      driverNotes: company.notes.driverNotes,
      keyContactName: company.keyContact.name,
      keyContactEmailAddress: company.keyContact.email,
      keyContactContactNumber: company.keyContact.phone,
      enableInvoicing: company.invoice.enabled,
      invoiceGenerateLevel: company.invoice.generateOnLevel || "company",
      invoiceGenerateFrequency: company.invoice.generateOnFrequency || "never",
      generateInvoiceOn: company.invoice.generateOnDate?.toString() || "1",
      paymentTerms: company.invoice.paymentTerms?.toString() || "30",
      billingContacts:
        isCompanyLevel && isEmpty(company.billingContacts)
          ? [{ name: "", emailAddress: "", contactNumber: "" }]
          : company.billingContacts.map((billingContact) => ({
              name: billingContact.name,
              emailAddress: billingContact.email,
              contactNumber: billingContact.phone,
            })),
      pricingAdjustments: company.pricing.adjustments.map((adjustment) => {
        const isPriceIncrease = adjustment.amount > -1;
        const amount = isPriceIncrease ? adjustment.amount : adjustment.amount * -1;
        return {
          isPriceIncrease,
          price: amount,
          displayToClient: adjustment.type === "itemised" ? true : false,
          description: adjustment.description,
        };
      }),
      operatorCommissionOverride: company.pricing.commission.overrideEnabled,
      percentageCommission: company.pricing.commission.percentage || undefined,
      pricingProfile: company.pricing.profileId || "dynamic-pricing",
      allowDriverExtras: company.pricing.allowDriverExtras,
      paidCustomerExtras: !company.pricing.includeClientExtrasInBaseCost,
    },
    resolver: zodResolver(companiesFormSchema),
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitted, errors },
  } = methods;

  usePrompt({ when: isDirty && !isSubmitted, message: "Changes will not be saved. Do you want to proceed?" });

  useEffect(() => {
    if (!isEmpty(errors)) {
      const step1Fields = [
        "companyName",
        "companyAddress",
        "companyContactNumber",
        "customReferenceFieldDescription",
        "businessRegistrationType",
        "businessRegistrationIdNumber",
        "notes",
        "driverNotes",
      ];
      setFormStep(step1Fields.includes(Object.keys(errors)[0]) ? 1 : 2);
    }
  }, [errors]);

  const onSubmit = handleSubmit((data) => {
    const {
      customReferenceFieldDescription,
      notes,
      driverNotes,
      keyContactContactNumber,
      keyContactName,
      keyContactEmailAddress,
      enableInvoicing,
      generateInvoiceOn,
      billingContacts,
      pricingAdjustments,
      operatorCommissionOverride,
      invoiceGenerateFrequency,
    } = data;
    const isGenerateOnDateHidden =
      !invoiceGenerateFrequency || ["never", "no_future_jobs", "twice_monthly"].includes(invoiceGenerateFrequency);
    const companyData: UpdateCompanyParams = {
      uuid: company.id,
      name: data.companyName,
      address: data.companyAddress || undefined,
      phone: data.companyContactNumber || undefined,
      business_registration_type: data.businessRegistrationType,
      business_registration_id: data.businessRegistrationIdNumber,
      notes:
        customReferenceFieldDescription || notes || driverNotes
          ? {
              reference: customReferenceFieldDescription || undefined,
              admin: notes || undefined,
              driver: driverNotes || undefined,
            }
          : undefined,
      key_contact:
        keyContactName || keyContactEmailAddress || keyContactContactNumber
          ? {
              name: keyContactName || undefined,
              email: keyContactEmailAddress || undefined,
              phone: keyContactContactNumber || undefined,
            }
          : undefined,
      invoice:
        ability.can("manage", "providerCompanyInvoicing") && operator.flags.paymentInvoiceConfigured
          ? {
              enabled: enableInvoicing,
              generate_on_level: enableInvoicing ? data.invoiceGenerateLevel : undefined,
              generate_on_frequency: enableInvoicing ? data.invoiceGenerateFrequency : undefined,
              generate_on_date: enableInvoicing && !isGenerateOnDateHidden && generateInvoiceOn ? Number(generateInvoiceOn) : undefined,
              payment_terms: enableInvoicing ? Number(data.paymentTerms) : undefined,
            }
          : undefined,
      billing_contacts:
        enableInvoicing && billingContacts
          ? billingContacts.map((billingContact) => ({
              name: billingContact.name || undefined,
              email: billingContact.emailAddress || "",
              phone: billingContact.contactNumber || undefined,
            }))
          : undefined,
      pricing: {
        adjustments: pricingAdjustments
          ? pricingAdjustments.map((pricingAdjustment) => ({
              amount: pricingAdjustment.isPriceIncrease ? pricingAdjustment.price : pricingAdjustment.price * -1,
              type: pricingAdjustment.displayToClient ? "itemised" : "bundled",
              description: pricingAdjustment.description || undefined,
            }))
          : undefined,
        commission: {
          override_enabled: operatorCommissionOverride,
          percentage: operatorCommissionOverride ? data.percentageCommission : undefined,
        },
        profile_uuid: data.pricingProfile === "dynamic-pricing" ? "" : data.pricingProfile,
        allow_driver_extras: data.allowDriverExtras,
        include_client_extras_in_base_cost: data.pricingProfile !== "dynamic-pricing" ? !data.paidCustomerExtras : false,
      },
    };

    updateCompany(companyData)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully updated company");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="relative" {...props}>
        {(isFetching || isLoading) && <Loading />}
        <div className="flex flex-col sm:flex-row gap-2 mb-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Edit Company
            </Typography>
            <Typography className="text-neutral-dark-gray">Edit {company.name} company details</Typography>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-2">
            <Button variant="secondary" onClick={() => navigate("../")} className="w-full sm:w-fit">
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading} className="w-full sm:w-fit">
              Save
            </Button>
          </div>
        </div>
        <CompaniesFormStep1 className={clsx("animate-enter", { hidden: formStep > 1 })} />
        <CompaniesFormStep2 className={clsx("animate-enter", { hidden: formStep < 2 })} />
        <Pagination
          pageCount={2}
          totalItems={1}
          currentPage={formStep}
          onPageClick={(page) => setFormStep(page)}
          onSizeChange={() => null}
        />
      </form>
    </FormProvider>
  );
};
