import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { RawErrorResponse } from "@/redux/types";

export function isErrorResponse(error: unknown): error is { data: { detail: string } } {
  return (
    typeof error === "object" &&
    error != null &&
    "data" in error &&
    typeof (error as FetchBaseQueryError).data === "object" &&
    (error as FetchBaseQueryError).data != null
  );
}

export const getErrorMessages = (res: RawErrorResponse | FetchBaseQueryError | SerializedError) => {
  if (isErrorResponse(res)) {
    const { messages, detail } = res.data;
    if (messages) return Object.values(messages);
    return [detail];
  }

  return ["Something went wrong. Please contact your administrator."];
};

export const getErrorMessagesObject = (res: RawErrorResponse) => {
  if (!res || !res.data) return { error: "Something went wrong. Please contact your administrator." };
  const { messages, detail } = res.data;
  if (messages) return messages as { [key: string]: string };
  return { error: detail };
};

export const getErrorData = (res: RawErrorResponse) => {
  if (!res || !res.data) return undefined;
  return { error: res.data.error, data: res.data.error_data };
};

export const invalidateOn = <T>({ success = [], error = [] }: { success?: T[]; error?: T[] }) => {
  return (result: unknown) => (result ? success : error);
};
