import { Divider, Icon } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { bookingsPageStateSelector } from "@/redux/slices/booking/selectors";
import { clsx } from "@/utils";
import { useIsOperatorOwnJob } from "../../../hooks/useIsOperatorOwnJob";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { AdminNotes } from "./AdminNotes";
import { ClientNotes } from "./ClientNotes";
import { DashboardNotes } from "./DashboardNotes";
import { ExternalSupplierNotes } from "./ExternalSupplierNotes";
import { OperatorToDriverNotes } from "./OperatorToDriverNotes";

export const Notes = () => {
  const {
    driver,
    metadata: { isOffloaded },
  } = useSelectedBooking();
  const isOwnJob = useIsOperatorOwnJob();
  const { inlineEditing } = useAppSelector(bookingsPageStateSelector);
  const {
    flags: { dashboardNotesEnabled },
  } = useOperator();

  const className = clsx("relative", {
    "!opacity-100 !pointer-events-auto":
      inlineEditing && ["dashboard-notes", "admin-notes", "driver-notes", "client-notes"].includes(inlineEditing),
  });

  return (
    <div className={className}>
      <Icon name="Stickynote" variant="Bold" size="lg" className="absolute -inset-1.5 z-10 rounded-full bg-primary p-1 text-white" />
      <ClientNotes />

      <Divider className="my-2.5" />
      <OperatorToDriverNotes />
      {dashboardNotesEnabled && (
        <>
          <Divider className="my-2.5" />
          <DashboardNotes />
        </>
      )}
      {driver && driver.type !== "driver" && (
        <>
          <Divider className="my-2.5" />
          <ExternalSupplierNotes />
        </>
      )}
      {(!isOffloaded || isOwnJob) && (
        <>
          <Divider className="my-2.5" />
          <AdminNotes />
        </>
      )}
    </div>
  );
};
