import { Divider, Typography } from "@/components/atoms";

interface ProfileDetailProps extends React.PropsWithChildren {
  label: string | JSX.Element;
  description?: string;
  icon?: JSX.Element;
}

export const ProfileDetail = ({ label, description = "", icon, children, ...props }: ProfileDetailProps) => {
  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-4 w-full gap-x-8" {...props}>
        <div className="col-span-4 sm:col-span-1 flex flex-col gap-y-1.5">
          <div className="flex flex-row gap-x-3">
            {typeof label === "string" ? <Typography variant="paragraph">{label}</Typography> : <label.type {...label.props} />}
            {icon}
          </div>
          {description && (
            <Typography variant="small" className="text-neutral-dark-gray">
              {description}
            </Typography>
          )}
        </div>
        <div className="col-span-4 sm:col-span-3 flex flex-col">
          <div className="flex-1">{children}</div>
        </div>
      </div>
      <div className="grid grid-cols-4 w-full mt-6">
        <div className="col-span-4 sm:col-span-3 sm:col-end-5 flex flex-col">
          <Divider className="w-full self-end" />
        </div>
      </div>
    </div>
  );
};
