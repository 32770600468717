import { Button, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface BillingContactRemoveProps extends React.HTMLAttributes<HTMLDivElement> {
  onRemove: () => void;
  onClose: () => void;
  name?: string;
}

export const BillingContactRemove = ({ onRemove, onClose, name, className, ...props }: BillingContactRemoveProps) => {
  return (
    <div className={clsx("relative", className)} {...props}>
      <div className="max-w-lg">
        <div className="flex flex-col">
          <Typography variant="title">Remove Billing Contact</Typography>
          <div className="mt-3">
            {name ? (
              <Typography variant="paragraph">Are you sure you want to remove {name} from Billing Contacts?</Typography>
            ) : (
              <Typography variant="paragraph">Are you sure you want to remove this Billing Contact?</Typography>
            )}
          </div>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="secondary" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button variant="primary" onClick={onRemove} size="md" className="bg-danger hover:bg-danger-dark">
          Remove Contact
        </Button>
      </div>
    </div>
  );
};
