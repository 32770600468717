import { BookingPaymentBreakdownLog, BookingPaymentLedgerLog, BookingPaymentLog, BookingPaymentMeta } from "@/redux/slices/booking/types";
import { bookingApi } from "../bookingApi";
import {
  transformBookingLedger,
  transformBookingPayments,
  transformBookingPaymentBreakdown,
  transformBookingPaymentsMeta,
} from "../helpers";

export const paymentEndpoints = bookingApi
  .enhanceEndpoints({ addTagTypes: ["BookingPaymentMeta", "BookingPayment", "BookingPayout", "BookingLedger"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBookingPaymentsMeta: builder.query<BookingPaymentMeta, string>({
        query: (bookingId) => ({
          url: `/${bookingId}/payment-log`,
          method: "GET",
        }),
        providesTags: ["BookingPaymentMeta"],
        transformResponse: transformBookingPaymentsMeta,
      }),
      getBookingPayments: builder.query<BookingPaymentLog[], string>({
        query: (bookingId) => ({
          url: `/${bookingId}/payment`,
          method: "GET",
        }),
        providesTags: ["BookingPayment"],
        transformResponse: transformBookingPayments,
      }),
      getBookingPaymentBreakdown: builder.query<BookingPaymentBreakdownLog[], string>({
        query: (bookingId) => ({
          url: `/${bookingId}/payment-log`,
          method: "GET",
        }),
        providesTags: ["BookingPayout"],
        transformResponse: transformBookingPaymentBreakdown,
      }),
      getBookingLedger: builder.query<BookingPaymentLedgerLog[], string>({
        query: (bookingId) => ({
          url: `/${bookingId}/payment-ledger`,
          method: "GET",
        }),
        providesTags: ["BookingLedger"],
        transformResponse: transformBookingLedger,
      }),
      processRefund: builder.mutation<void, { bookingId: string; paymentId: string; amount: number; reason: string }>({
        query: ({ bookingId, paymentId, ...body }) => ({
          url: `/${bookingId}/payment/${paymentId}/refund`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["BookingPayment"],
      }),
      takeTripPrepayment: builder.mutation<void, { bookingId: string; amount: number }>({
        query: ({ bookingId, ...body }) => ({
          url: `/${bookingId}/payment`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["BookingPayment", "BookingPayout"],
      }),
      disburseFunds: builder.mutation<void, { bookingId: string; paymentId: string; amount: number; direct_to: string }>({
        query: ({ bookingId, paymentId, ...body }) => ({
          url: `/${bookingId}/payment-ledger/${paymentId}/disburse`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["BookingPayment", "BookingPayout", "BookingLedger"],
      }),
      returnFunds: builder.mutation<void, { bookingId: string; paymentId: string; amount: number; reason: string; direct_to: string }>({
        query: ({ bookingId, paymentId, ...body }) => ({
          url: `/${bookingId}/payment-ledger/${paymentId}/return`,
          method: "POST",
          body,
        }),
        invalidatesTags: ["BookingPayment", "BookingPayout", "BookingLedger"],
      }),
    }),
  });

export const {
  useGetBookingPaymentsQuery,
  useGetBookingPaymentBreakdownQuery,
  useGetBookingLedgerQuery,
  useTakeTripPrepaymentMutation,
  useProcessRefundMutation,
  useGetBookingPaymentsMetaQuery,
  useReturnFundsMutation,
  useDisburseFundsMutation,
} = paymentEndpoints;
