import { useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { invoicingOptionsSelector } from "@/redux/slices/payment/selectors";
import { UninvoicedJobsFilterFormData } from "../types";

export const AutoInvoiceGenerationFilter = () => {
  const { generateOnFrequency } = useAppSelector(invoicingOptionsSelector);
  const { watch, setValue } = useFormContext<UninvoicedJobsFilterFormData>();
  const selected = watch("generateOnFrequency");

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) setValue("generateOnFrequency", [...(selected || []), target.value]);
    else
      setValue(
        "generateOnFrequency",
        (selected || []).filter((value) => value !== target.value)
      );
  };

  const handleSelectAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    target.checked
      ? setValue(
          "generateOnFrequency",
          generateOnFrequency.map((i) => i.value)
        )
      : setValue("generateOnFrequency", []);
  };

  const isAllSelected = selected ? selected.length > 0 && selected.length === generateOnFrequency.length : false;

  return (
    <div className="flex flex-col rounded bg-neutral-surface-gray p-4">
      <Typography variant="action" className="mb-3">
        Auto Invoice Generation
      </Typography>
      <div className="grid gap-y-4">
        <CheckboxInput id="autoInvoiceGenerationSelectAll" label="Select all" onChange={handleSelectAll} checked={isAllSelected} />
        {generateOnFrequency.map((i) => (
          <CheckboxInput
            onChange={handleChange}
            key={i.value}
            id={i.value}
            label={i.name}
            value={i.value}
            checked={selected ? selected.includes(i.value) : false}
            variant="check"
          />
        ))}
      </div>
    </div>
  );
};
