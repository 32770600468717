import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { accreditationApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { AccreditationAction, AccreditationItem, AccreditationState } from "./types";

const initialState: AccreditationState = {
  items: [],
  page: pageState,
  history: {
    items: [],
    page: pageState,
  },
  pageState: {
    selectedAccreditation: undefined,
    action: undefined,
  },
};

export const accreditationSlice = createSlice({
  name: "accreditation",
  initialState,
  reducers: {
    setAction: (state, { payload }: PayloadAction<AccreditationAction | undefined>) => {
      state.pageState.action = payload;
    },
    setSelectedAccreditation: (state, { payload }: PayloadAction<AccreditationItem | undefined>) => {
      state.pageState.selectedAccreditation = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(accreditationApi.endpoints.getAccreditations.matchFulfilled, (state, { payload }) => {
        const { items, page } = payload.data;
        state.items = items;
        state.page = page;
      })
      .addMatcher(accreditationApi.endpoints.getAccreditationHistory.matchFulfilled, (state, { payload }) => {
        const { items, page } = payload.data;
        state.history.items = items;
        state.history.page = page;
      });
  },
});

export const { setAction, setSelectedAccreditation } = accreditationSlice.actions;
export default accreditationSlice.reducer;
