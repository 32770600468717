import { Alert, Coupon, Divider, Panel, Typography, Skeleton } from "@/components/atoms";
import { PageHeader, SendToPrinterForm } from "@/components/organisms";
import { useGetQrTemplatesQuery } from "@/redux/apis/qr/qrApi";

export const SendToPrinter = () => {
  const { isFetching } = useGetQrTemplatesQuery();

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <div className="flex flex-col-reverse gap-8 lg:flex-row">
        <Panel className="flex-1">
          <div className="block">
            <Typography variant="h3" className="block leading-8">
              Select the QR templates that you want to Print out
            </Typography>
            <Typography className="text-neutral-dark-gray">
              Send your desired QR code templates that you want your QR code to be placed onto.
            </Typography>
          </div>
          <div className="mt-4 block">
            <Alert type="info" message="Postage is $18 and turn around time is 4 working days." />
            <SendToPrinterForm />
          </div>
        </Panel>
        <div className="w-full lg:w-[365px]">
          <Typography variant="title">Discount Promos</Typography>
          <div className="mt-4 grid gap-4">
            <Coupon type="gold" discount={25} minimumPurchase={100} className="w-[363px]" />
            <Coupon type="silver" discount={20} minimumPurchase={50} className="w-[363px]" />
            <Coupon type="bronze" discount={10} minimumPurchase={25} className="w-[363px]" />
          </div>
          <Divider className="my-8" />
          <Typography variant="title">About QR Stickers</Typography>
          <ul className="mt-4 list-disc space-y-4 pl-4">
            <li>QR Stickers will last for 3 years (as long as they are not placed on the bonnet or the roof of the car).</li>
            <li>
              If you remove the QR Stickers within 12 months of putting them on, they will not leave a mark on the surface they were on (any
              more than 12 months, you might need to wipe off some glue residue).
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
