import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Divider, PDFCheckboxInput, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { qrTemplatesSelector } from "@/redux/slices/qr/selectors";
import { QRTemplate } from "@/redux/slices/qr/types";
import { PDFPreviewModal } from "./PDFPreview";
import { SendToPrinterContext } from "./context";

type FormData = {
  templates: string[];
};

export const Step1 = () => {
  const { step, setStep, setSelectedTemplates } = useContext(SendToPrinterContext);
  const { register, handleSubmit, watch, reset } = useForm<FormData>();
  const [previewTemplate, setPreviewTemplate] = useState<QRTemplate | undefined>(undefined);
  const qrTemplates = useAppSelector(qrTemplatesSelector);
  const templates = watch("templates");

  useEffect(() => {
    if (step > 2) reset();
  }, [reset, step]);

  const onSubmit = (data: FormData) => {
    if (data.templates.length < 1) return;

    setSelectedTemplates(data.templates);
    setStep(2);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6 grid grid-cols-4 gap-4 2xl:grid-cols-6">
          {qrTemplates.map((i) => (
            <PDFCheckboxInput
              onPreviewClick={() => setPreviewTemplate(qrTemplates?.find((t) => t.slug === i.slug))}
              key={i.slug}
              label={i.name}
              value={i.slug}
              img={i.previewUrl}
              price={i.price}
              checked={templates ? templates.includes(i.slug) : false}
              {...register("templates")}
            />
          ))}
        </div>
        <Divider className="mt-6" />
        <div className="flex flex-1 justify-center px-4 pb-4 pt-8">
          <div className="flex max-w-[200px] flex-col gap-2 text-center">
            <Typography variant="h1">{templates?.length || 0}</Typography>
            <Typography variant="action">QR Templates Selected</Typography>
            <Button variant="primary" type="submit" className="px-6" disabled={!templates || templates.length < 1} startIcon="ShoppingCart">
              View Cart
            </Button>
          </div>
        </div>
      </form>
      <PDFPreviewModal open={!!previewTemplate} template={previewTemplate} onClose={() => setPreviewTemplate(undefined)} />
    </>
  );
};
