import { createPortal } from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useGTM } from "gtm-react-hook";
import { AbilityProvider, ability } from "./casl";
import { store } from "./redux/store";
import { routes } from "./routes";
import { useEffect } from "react";

const basename = import.meta.env.VITE_RIDEMINDER_BASE_NAME || "";

export default function App() {
  const router = createBrowserRouter(routes, { basename: `/${basename}` });
  const { runGTM } = useGTM();

  useEffect(() => {
    const rideminderGTM = import.meta.env.VITE_RIDEMINDER_GOOGLE_TAG_MANAGER_ID;
    const operatorGTM = window.rm?.config.site.ga_property;
    runGTM({ tagId: rideminderGTM });
    if (operatorGTM) runGTM({ tagId: operatorGTM });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HelmetProvider>
        <Provider store={store}>
          <AbilityProvider value={ability}>
            <RouterProvider router={router} />
          </AbilityProvider>
        </Provider>
      </HelmetProvider>
      {createPortal(<Toaster />, document.body)}
    </>
  );
}
