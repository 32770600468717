import { ScrollRestoration } from "react-router-dom";
import { ConfigProvider, Head, LayoutProvider, PublicContent } from "@/components/organisms";

export const Public = () => {
  return (
    <ConfigProvider>
      <LayoutProvider>
        <Head />
        <ScrollRestoration />
        <PublicContent />
      </LayoutProvider>
    </ConfigProvider>
  );
};
