import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Modal, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { AddVehicleCategoryForm, EditVehicleCategoryForm, PageHeader, VehicleCategoriesTableEditor } from "@/components/organisms";
import { usePagination } from "@/hooks/";
import { useGetVehicleCategoriesQuery } from "@/redux/apis/config/vehicle/vehicleCategories";
import { useGetVehicleListQuery } from "@/redux/apis/config/vehicle/vehicleCategories/vehicleCategoriesApi";
import { useAppSelector } from "@/redux/hooks";
import { vehicleCategoriesPageSelector, vehicleCategoriesSelector } from "@/redux/slices/pricing/selectors";
import { VehicleCategory } from "@/redux/slices/pricing/types";
import { addNotification } from "@/utils";
import { addToast } from "@/utils/addToast";

export const VehicleCategoriesEditLegacy = () => {
  const navigate = useNavigate();
  const items = useAppSelector(vehicleCategoriesSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const { current, count, total, size } = useAppSelector(vehicleCategoriesPageSelector);
  const { isFetching: isFetchingVehicleList } = useGetVehicleListQuery();
  const { isFetching: isFetchingVehicleCategories } = useGetVehicleCategoriesQuery(
    { page, page_size: pageSize },
    { refetchOnMountOrArgChange: true }
  );
  const [step, setStep] = useState<"add" | "edit" | undefined>(undefined);
  const [editItem, setEditItem] = useState<VehicleCategory | undefined>(undefined);

  useEffect(() => {
    setStep(editItem ? "edit" : undefined);
  }, [editItem]);

  return (
    <>
      {isFetchingVehicleCategories || isFetchingVehicleList ? (
        <Skeleton />
      ) : (
        <>
          <PageHeader.Actions />
          <Panel>
            <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
              <div className="flex-1">
                <Typography variant="h3" className="leading-8">
                  Vehicle Categories (Multiplier)
                </Typography>
                <Typography className="text-neutral-dark-gray">Configure the Vehicle Categories Multiplier for each trip.</Typography>
              </div>
              <div className="flex items-center gap-4">
                <Button onClick={() => setStep("add")} variant="tertiary" startIcon="Add">
                  Add New
                </Button>
                <Button onClick={() => navigate("../")}>Done</Button>
              </div>
            </div>
            {isEmpty(items) ? (
              <EmptyState
                title="Vehicle Categories"
                description="It looks like you haven't added any vehicle categories yet. Begin by adding your first vehicle category to get started"
              >
                <Button onClick={() => setStep("add")} startIcon="Add" variant="tertiary">
                  Add New
                </Button>
              </EmptyState>
            ) : (
              <VehicleCategoriesTableEditor className="mt-4" items={items} onEditItem={(item: VehicleCategory) => setEditItem(item)} />
            )}
            <Modal open={step === "add"} title="Add Vehicle Category">
              <AddVehicleCategoryForm
                onClose={() => setStep(undefined)}
                onSuccess={() => {
                  addToast("success", "Successfully added new vehicle category.");
                  setStep(undefined);
                }}
              />
            </Modal>
            {editItem && (
              <Modal open={step === "edit"} title="Edit Vehicle Category">
                <EditVehicleCategoryForm
                  item={editItem}
                  onClose={() => setEditItem(undefined)}
                  onSuccess={() => {
                    addNotification("info", `Updated ${editItem?.name}`, `Successfully updated.`);
                    setEditItem(undefined);
                  }}
                />
              </Modal>
            )}
          </Panel>
        </>
      )}
      <Pagination
        totalItems={total}
        pageCount={count}
        currentPage={current}
        onPageClick={handlePageClick}
        pageSize={size}
        onSizeChange={handleSizeChange}
      />
    </>
  );
};
