import { useFormContext } from "react-hook-form";
import { CheckboxInput, ErrorMessage } from "@/components/atoms";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";

export const AllDayField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();
  const values = watch();

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      setValue("timeStart", "00:00:00", { shouldDirty: true });
      setValue("timeEnd", "23:59:59", { shouldDirty: true });
    }

    setValue("allDay", target.checked, { shouldDirty: true });
  };

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <CheckboxInput label="All Day" onChange={handleChange} checked={values.allDay} />
      <ErrorMessage errors={errors} name="allDay" />
    </div>
  );
};
