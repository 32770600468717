import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddOnTextInput, Badge, Button, Divider, ErrorMessage, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useTakeTripPrepaymentMutation } from "@/redux/apis/booking/endpoints/payment";
import { useAppDispatch } from "@/redux/hooks";
import { setPaymentAction } from "@/redux/slices/booking/bookingSlice";
import { addToast, formatCurrency, getCurrencySymbol } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

const schema = z.object({
  amount: z.coerce.number({ invalid_type_error: "This field is required." }).min(1),
});

type FormData = z.infer<typeof schema>;

interface TakeTripPrepaymentProps {
  amountPaid: number;
  balance: number;
  cardPercentageFee?: number;
}

export const TakeTripPrepayment = ({ amountPaid, balance }: TakeTripPrepaymentProps) => {
  const dispatch = useAppDispatch();
  const {
    id: bookingId,
    cost: { currency },
  } = useSelectedBooking();
  const [takePrepayment, { isLoading }] = useTakeTripPrepaymentMutation();

  const {
    register,
    formState: { isDirty, isSubmitting, errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: 0,
    },
  });

  const onSubmit = async (data: FormData) =>
    takePrepayment({
      bookingId,
      amount: data.amount * 100,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Pre-payment collected successfully");
        dispatch(setPaymentAction(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  const handleClose = () => dispatch(setPaymentAction(undefined));

  return (
    <section className="relative flex h-full flex-col">
      {isLoading && <Loading />}
      <header className="m-5">
        <Typography variant="title">Take Trip Pre-Payment</Typography>
      </header>
      <div className="m-5 mt-6 flex-1 ">
        <form id="take-prepayment-form" onSubmit={handleSubmit(onSubmit)} className="flex bg-neutral-surface-gray ">
          <fieldset className="w-3/5 border-r border-neutral-mid-gray p-5">
            <Typography className="block leading-loose">Amount to Collect</Typography>
            <div className="block ">
              <AddOnTextInput
                className="w-[150px]"
                startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
                {...register("amount", { valueAsNumber: true })}
                type="number"
                step="0.01"
              />
              <ErrorMessage className="leading-loose" errors={errors} name="amount" />
              <Badge
                className="mt-3 grow-0"
                type="info"
                message={`Already Collected: ${formatCurrency(amountPaid, 2, currency, { appendCurrency: true })}`}
              />
            </div>
          </fieldset>
          <fieldset className=" grid w-2/5 items-center justify-end ">
            <div className="p-5 text-right">
              <Typography variant="action">Remaining Balance</Typography>
              <Typography className="text-[32px] font-bold">{formatCurrency(balance, 2, currency, { appendCurrency: true })}</Typography>
            </div>
          </fieldset>
        </form>
      </div>

      <div className="flex flex-col ">
        <Divider className="-mx-5" />
        <div className="flex justify-end p-5">
          <Button variant="secondary" onClick={handleClose} className="mr-4" size="lg">
            Cancel
          </Button>
          <Button form="take-prepayment-form" type="submit" disabled={isSubmitting || !isDirty} size="lg">
            Collect Pre-Payment
          </Button>
        </div>
      </div>
    </section>
  );
};
