import { formatInTimeZone } from "date-fns-tz";
import { useCallback, useMemo } from "react";
import { Alert, Card, Divider, Icon, Modal, Tooltip, Typography } from "@/components/atoms";
import { BookingFlight, BookingFlightStatus } from "@/redux/slices/booking/types";
import { clsx, getAssetUrl } from "@/utils";
import { BookingFlightStatusCode } from "../../../common/BookingFlightStatusCode/BookingFlightStatusCode";

interface FlightTrackingDetailsModalProps {
  flight: BookingFlight;
  timezone: string;
  open: boolean;
  onClose: () => void;
}

export const FlightTrackingDetailsModal = ({ flight, timezone, open, onClose }: FlightTrackingDetailsModalProps) => {
  const { number, status, delay, codeShare, operatingAs, arrival, airline } = flight;

  const renderFlightStatusCode = () => {
    const activeStatusList = ["scheduled", "en-route"];
    if (typeof delay === "number" && activeStatusList.includes(status) && delay !== 0) {
      return (
        <Tooltip content={`Running ${Math.abs(delay)} minutes ${delay > 0 ? "late" : "early"}`} placement="bottom">
          <BookingFlightStatusCode status={status} delay={delay} className="py-1 group-[.has-landed]:hidden" />
        </Tooltip>
      );
    }

    return <BookingFlightStatusCode status={status} className="py-1 group-[.has-landed]:hidden" />;
  };

  const renderAlerts = () => {
    switch (status) {
      case "not_found":
        return <Alert type="info" message="Flight details cannot be verified" />;

      case "unknown":
        return <Alert type="info" message="Flight details will be available 24 hours before the flight." />;
      default:
        return null;
    }
  };

  const statusClasses = {
    "is-cancelled": status === "cancelled",
    "has-data": status !== "not_found" && status !== "unknown" && status !== "early",
    "has-landed": status === "landed",
  };

  return (
    <Modal open={open} onClose={onClose} className={clsx("group w-[85vw] space-y-3 !px-4 md:w-[550px] lg:w-[700px]", statusClasses)}>
      <header className="flex justify-between">
        <Typography variant="title">Flight Details</Typography>
        <Icon name="close" isCustom className="flex cursor-pointer" onClick={onClose} />
      </header>
      <Card className="grid">
        {airline?.avatar ? (
          <div className="flex w-full justify-center">
            <img
              src={getAssetUrl(airline?.avatar, { height: 48, width: 250, fit: "cover" })}
              alt={airline.name}
              className="h-[24px] w-[125px]"
            />
          </div>
        ) : (
          <Typography variant="title" className="text-center empty:hidden">
            {airline?.name || ""}
          </Typography>
        )}
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-1">
            <Icon name="Airplane" size={24} variant="Bold" />
            <div className="grid">
              <Typography variant="action" className="block leading-tight">
                {number || "-"}
              </Typography>
              <Typography variant="small" className="empty:hidden">
                {codeShare
                  ? [operatingAs, ...codeShare]
                      .filter((code) => code !== number)
                      .filter(Boolean)
                      .join(", ")
                  : ""}
              </Typography>
            </div>
          </div>
          {renderFlightStatusCode()}
        </div>
      </Card>
      {renderAlerts()}
      <ArrivalDetails timezone={timezone} arrival={arrival} />
      <Divider className="my-3" />
      <MoreDetails status={status} hasArrived={!!arrival.actual} hasGated={!!arrival.gateActual} />
    </Modal>
  );
};

const ArrivalDetails = ({ arrival, timezone }: { arrival: BookingFlight["arrival"]; timezone: string }) => {
  const {
    airport,
    actual: actualArrival,
    estimated: estimatedArrival,
    scheduled: scheduledArrival,
    terminal,
    gateEstimated,
    gateActual,
  } = arrival;

  const landingDetails = useMemo(() => {
    if (actualArrival) {
      return [
        "Actual Landing Time",
        formatInTimeZone(new Date(actualArrival), timezone, "h:mm a"),
        formatInTimeZone(new Date(actualArrival), timezone, "dd MMMM yyyy"),
      ];
    }
    if (estimatedArrival) {
      return [
        "Est. Landing Time",
        formatInTimeZone(new Date(estimatedArrival), timezone, "h:mm a"),
        formatInTimeZone(new Date(estimatedArrival), timezone, "dd MMMM yyyy"),
      ];
    }
    return ["Landing Time", "-", ""];
  }, [actualArrival, estimatedArrival, timezone]);

  const gateDetails = useMemo(() => {
    if (gateActual) {
      return [
        "Actual Gate Time",
        formatInTimeZone(new Date(gateActual), timezone, "h:mm a"),
        formatInTimeZone(new Date(gateActual), timezone, "dd MMMM yyyy"),
      ];
    }
    if (gateEstimated) {
      return [
        "Est. Gate Time",
        formatInTimeZone(new Date(gateEstimated), timezone, "h:mm a"),
        formatInTimeZone(new Date(gateEstimated), timezone, "dd MMMM yyyy"),
      ];
    }
    return ["Gate Time", "-", ""];
  }, [gateActual, gateEstimated, timezone]);

  return (
    <div className="!mt-6">
      <Typography variant="title">Arrival</Typography>
      <Typography>{airport?.name || "-"}</Typography>
      <Divider className="my-3" />
      <div className="grid grid-cols-1 gap-x-2.5 gap-y-2.5 sm:grid-cols-[190px_1fr_1fr]">
        <div className="bg-neutral-surface-gray p-3">
          <Typography variant="action">Scheduled Gate Time</Typography>
          <Typography className="leading-relaxed group-[.is-cancelled]:line-through">
            {scheduledArrival ? formatInTimeZone(new Date(scheduledArrival), timezone, "h:mm a") : "-"}
          </Typography>
          {scheduledArrival && (
            <Typography className="text-neutral-dark-gray group-[.is-cancelled]:line-through">
              {formatInTimeZone(new Date(scheduledArrival), timezone, "dd MMMM yyyy")}{" "}
            </Typography>
          )}
        </div>
        <div className="bg-neutral-surface-gray p-3">
          <Typography variant="action">{landingDetails[0]}</Typography>
          <Typography className="leading-relaxed group-[.is-cancelled]:line-through">{landingDetails[1]}</Typography>
          <Typography className="text-neutral-dark-gray empty:hidden group-[.is-cancelled]:line-through">{landingDetails[2]}</Typography>
        </div>
        <div className="bg-neutral-surface-gray p-3">
          <Typography variant="action">Terminal</Typography>
          <Typography className="leading-relaxed">{terminal || "-"}</Typography>
        </div>
        <div className="bg-neutral-surface-gray p-3">
          <Typography variant="action">{gateDetails[0]}</Typography>
          <Typography className="leading-relaxed group-[.is-cancelled]:line-through">{gateDetails[1]}</Typography>
          <Typography className="text-neutral-dark-gray empty:hidden group-[.is-cancelled]:line-through">{gateDetails[2]}</Typography>
        </div>
      </div>
    </div>
  );
};

const MoreDetails = ({ status, hasArrived, hasGated }: { status: BookingFlightStatus; hasArrived: boolean; hasGated: boolean }) => {
  const renderDepartureStatusIcon = useCallback(() => {
    switch (status) {
      case "diverted":
      case "en-route":
      case "early":
      case "landed":
        return <Icon name="TickCircle" size={24} variant="Bold" className="text-success" />;

      case "cancelled":
        return <Icon name="CloseCircle" size={24} variant="Bold" className="text-danger" />;

      default:
        return <Icon name="MinusCircle" size={24} variant="Bold" className="text-neutral-dark-gray" />;
    }
  }, [status]);

  const renderLandedStatusIcon = useCallback(() => {
    switch (status) {
      case "landed":
        return <Icon name="TickCircle" size={24} variant="Bold" className="text-success" />;

      case "cancelled":
        return <Icon name="CloseCircle" size={24} variant="Bold" className="text-danger" />;

      default:
        return <Icon name="MinusCircle" size={24} variant="Bold" className="text-neutral-dark-gray" />;
    }
  }, [status]);

  const renderGatedStatus = useCallback(() => {
    if (hasGated) {
      return <Icon name="TickCircle" size={24} variant="Bold" className="text-success" />;
    }

    return <Icon name="MinusCircle" size={24} variant="Bold" className="text-neutral-dark-gray" />;
  }, [hasGated]);

  return (
    <div className="grid gap-2">
      <Typography className="flex gap-2" variant="action">
        {renderDepartureStatusIcon()}
        Departed
      </Typography>
      <Typography className="flex gap-2" variant="action">
        {renderLandedStatusIcon()}
        Landed
      </Typography>
      {status === "landed" && hasArrived && (
        <Typography className="flex gap-2" variant="action">
          {renderGatedStatus()}
          Arrived / Gated
        </Typography>
      )}
    </div>
  );
};
