import { clsx } from "@/utils";

export interface CompanyDisclosureSkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "full" | "item";
}

export const CompanyDisclosureSkeleton = ({ variant = "full", className, ...props }: CompanyDisclosureSkeletonProps) => {
  const renderSkeletonItem = () => {
    return (
      <div className="flex w-full flex-row items-center justify-between gap-x-1 rounded-lg bg-gray-200 px-5 py-2.5">
        <div className="flex flex-row items-center">
          <div className="flex h-6 w-6 flex-shrink-0 rounded-2xl bg-gray-300" />
          <div className="ml-5 flex h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" />
          <div className="ml-4 h-6 w-24 rounded-2xl bg-gray-300" />
        </div>
        <div className="flex items-center gap-x-10 ">
          <div className="h-9 w-20 rounded-md bg-gray-300" />
          <div className="h-9 w-20 rounded-md bg-gray-300" />
          <div className="h-9 w-20 rounded-md bg-gray-300" />
        </div>
      </div>
    );
  };

  const skeletonItems = Array.from({ length: 4 }, (_, x: number) => <div key={x}>{renderSkeletonItem()}</div>);

  return (
    <div className={clsx("animate-pulse", className)} {...props}>
      {variant === "item" ? renderSkeletonItem() : skeletonItems}
    </div>
  );
};
