import { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Button, Icon, Loading, Panel, PasswordInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useSiteConfig } from "@/hooks";
import { useUpdatePasswordMutation } from "@/redux/apis/profile/profileApi";
import { addToast } from "@/utils";

type FormData = {
  newPassword: string;
  confirmPassword: string;
};

export const ProfileSecureAccountForm = () => {
  const { baseUrl } = useSiteConfig();
  const [updatePassword, { isLoading, isSuccess }] = useUpdatePasswordMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm<FormData>();
  const [errors, setErrors] = useState<string[]>([]);

  const onSubmit = handleSubmit((data) => {
    const { newPassword, confirmPassword } = data;

    setErrors([]);
    if (newPassword !== confirmPassword) {
      setErrors(["Passwords don't match. Please try again."]);
      return;
    }

    updatePassword({ password: newPassword })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated your Password");
        reset();
        setTimeout(() => window.location.href = baseUrl, 1250);
      })
      .catch((e) => setErrors(getErrorMessages(e)));
  });

  return (
    <Panel>
      <div className="w-full">
        <div className="px-6 text-center">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center rounded-lg bg-primary-light p-3 mb-4">
              <Icon
                name="Refresh2"
                size={36}
                className="text-primary"
              />
            </div>
          </div>
          <Typography variant="h1">Secure Your Account</Typography>
          <Typography variant="paragraph" className="mt-1 text-neutral-dark-gray">
            For your added security, we require a new password to be set before you can complete the login process.
          </Typography>
        </div>

        {errors.length > 0 && (
          <div className="my-4">
            {errors.map((e, i) => (
              <Alert key={i} type="danger" message={e} className="my-4" />
            ))}
          </div>
        )}

        <form className="mt-4 w-full" onSubmit={onSubmit}>
          <div className="space-y-4 rounded-md shadow-sm">
            <div>
              <label htmlFor="pnewPasswordassword" className="mb-1 block">
                <Typography>Enter new password</Typography>
              </label>
              <PasswordInput
                required
                placeholder="Enter password"
                minLength={8}
                hasError={!!formErrors.newPassword}
                {...register("newPassword", { required: true })}
              />
              <Typography variant="small" className="text-neutral-dark-gray">
                Note: minimum of 8 characters
              </Typography>
            </div>
            <div>
              <label htmlFor="confirmPassword" className="mb-1 block">
                <Typography>Confirm new password</Typography>
              </label>
              <PasswordInput
                required
                placeholder="Enter password"
                minLength={8}
                hasError={!!formErrors.confirmPassword}
                {...register("confirmPassword", { required: true })}
              />
            </div>
          </div>

          <div className="relative mt-8">
            {(isLoading || isSuccess) && <Loading className="!bg-transparent" />}
            <Button type="submit" className="w-full" size="lg" disabled={isLoading || isSuccess}>
              Update Password
            </Button>
          </div>
        </form>
      </div>
    </Panel>
  );
};
