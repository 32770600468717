import { useAppAbility } from "@/casl";
import { Typography, Divider, Button, Loading } from "@/components/atoms";
import {
  useGetBookingLedgerQuery,
  useGetBookingPaymentBreakdownQuery,
  useGetBookingPaymentsMetaQuery,
  useGetBookingPaymentsQuery,
} from "@/redux/apis/booking/endpoints/payment";
import { useAppDispatch } from "@/redux/hooks";
import { setPaymentAction } from "@/redux/slices/booking/bookingSlice";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { EmptyBreakdown } from "./emptyStates/EmptyBreakdown";
import { EmptyLedger } from "./emptyStates/EmptyLedger";
import { PaymentBreakdownTable } from "./tables/PaymentBreakdownTable";
import { PaymentLedgerTable } from "./tables/PaymentLedgerTable";
import { PaymentLogsTable } from "./tables/PaymentLogsTable";

export const PaymentLogs = () => {
  const ability = useAppAbility();
  const dispatch = useAppDispatch();
  const { id: bookingId } = useSelectedBooking();
  const { isFetching: isLoadingPayments, data: payments } = useGetBookingPaymentsQuery(bookingId);
  const { isFetching: isLoadingBreakdown, data: breakdown } = useGetBookingPaymentBreakdownQuery(bookingId);
  const { isFetching: isLoadingLedger, data: ledger } = useGetBookingLedgerQuery(bookingId);
  const { isFetching: isLoadingMeta, data: meta } = useGetBookingPaymentsMetaQuery(bookingId);

  const isLoading = isLoadingPayments || isLoadingBreakdown || isLoadingLedger || isLoadingMeta;

  return (
    <section className="flex flex-col h-full w-[90vw] sm:w-full">
      <div className="flex-1 w-full p-5">
        {isLoading && <Loading />}
        {payments?.length ? <PaymentLogsTable logs={payments} /> : null}
        <Typography variant="title" className="mt-7 block">
          Breakdown
        </Typography>
        {breakdown?.length ? <PaymentBreakdownTable items={breakdown} /> : <EmptyBreakdown />}
        {ability.can("manage", "faProvider") && (
          <>
            <Typography variant="title" className="mt-7 block">
              Ledger (RM Admin)
            </Typography>
            {ledger?.length ? <PaymentLedgerTable items={ledger} /> : <EmptyLedger />}
          </>
        )}
      </div>
      {meta?.isPrepaymentAvailable && (
        <div className="flex flex-col p-5">
          <Divider className="-mx-5" />
          <Button
            onClick={() => dispatch(setPaymentAction({ action: "take-prepayment" }))}
            className="mt-5 self-end"
            variant="primary"
            size="lg"
          >
            Take Trip Pre-Payment
          </Button>
        </div>
      )}
    </section>
  );
};
