import { Typography } from "@/components/atoms";
import { getAssetUrl, parseHtml } from "@/utils";
import { useBookingOfferPageContext } from "../context";

export const BookingNotes = () => {
  const { bookingOffer } = useBookingOfferPageContext();
  const {
    supplier: { name, avatar },
    notes: { driver: driverNotes },
  } = bookingOffer;

  return (
    <div className="flex flex-col gap-3 rounded-lg bg-white p-4 shadow">
      <div className="flex bg-neutral-surface-gray p-[10px]">
        <div className="flex flex-1 gap-2">
          <img src={getAssetUrl(avatar)} alt="profile" className="h-6 w-6 rounded-full" />
          <Typography variant="action">{name}</Typography>
        </div>
      </div>

      <Typography className="text-neutral-dark-gray">Booking Notes</Typography>
      <Typography className="text-neutral-dark-gray">{driverNotes ? parseHtml(driverNotes) : "No notes available"}</Typography>
    </div>
  );
};
