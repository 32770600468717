import { format } from "date-fns";
import { useState } from "react";
import { Button, DateInput, Dropzone, Icon, Spinner, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useAddAccreditationMutation } from "@/redux/apis/accreditation/accreditationApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAction } from "@/redux/slices/accreditation/accreditationSlice";
import { accreditationsSelector } from "@/redux/slices/accreditation/selectors";
import { countryStatesSelector } from "@/redux/slices/resource/selectors";
import { CountryState } from "@/redux/slices/resource/types";
import { clsx } from "@/utils";
import { addToast } from "@/utils/addToast";
import { StateCard } from "../..";
import { RegistrationCertificate } from "../contents/RegistrationCertificate";

interface AddAccreditationFormProps {
  onClose: () => void;
}

export const AddAccreditationForm = ({ onClose }: AddAccreditationFormProps) => {
  const { dateFormat } = useProfile();
  const dispatch = useAppDispatch();
  const [addAccreditation, { isLoading }] = useAddAccreditationMutation();
  const accreditationItems = useAppSelector(accreditationsSelector);
  const stateItems = useAppSelector(countryStatesSelector);
  const [step, setStep] = useState<0 | 1>(0);
  const [stateId, setStateId] = useState("");
  const [stateName, setStateName] = useState("");
  const [regCert, setRegCert] = useState<File | null>();
  const [expiryDate, setExpiryDate] = useState<Date | null>(new Date());
  const [regNo, setRegNo] = useState("");

  const handleSetState = ({ id, name }: CountryState) => {
    setStateId(id);
    setStateName(name);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = stateId && expiryDate && regNo;
    if (isValid) {
      const formData = new FormData();
      formData.append("state_uuid", stateId);
      formData.append("document_identifier", regNo);
      formData.append("document_expiry", format(expiryDate, "yyyy-MM-dd"));
      if (regCert) formData.append("document", regCert);
      addAccreditation(formData)
        .unwrap()
        .then(() => {
          addToast("success", "Successfully added new accreditation");
          dispatch(setAction(undefined));
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else {
      addToast("danger", "All fields are required.");
    }
  };

  const renderButtons = () => {
    if (step < 1) {
      return (
        <div className="mt-6 flex justify-end">
          <Button variant="primary" onClick={() => setStep(1)} disabled={!stateId} size="lg">
            Next
          </Button>
        </div>
      );
    } else if (step > 0) {
      return (
        <div className="mt-6 flex">
          <div className="flex w-full flex-col">
            <Button variant="primary" type="submit" form="add-accreditation-form" size="lg">
              Add Accreditation
            </Button>
          </div>
        </div>
      );
    }
  };

  const renderModalHeader = () => {
    if (step < 1) {
      return (
        <div className="sm:flex sm:items-start">
          <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
            <div className="flex flex-row items-center">
              <Typography variant="title" className="flex-1">
                Add New State
              </Typography>
              <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
            </div>
            <div className="mt-3">
              <Typography className="text-neutral-dark-gray">Add another state that you’re authorize in.</Typography>
            </div>
          </div>
        </div>
      );
    } else if (step > 0) {
      return (
        <div className="sm:flex sm:items-start">
          <div className="my-3 w-full text-center sm:mt-0 sm:text-left">
            <div className="flex flex-row items-center">
              <Typography variant="title" className="flex-1">{`Add New State (${stateName})`}</Typography>
              <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {renderModalHeader()}
      <form id="add-accreditation-form" className="relative flex min-h-[265px] w-[550px] max-w-full flex-col" onSubmit={onSubmit}>
        {isLoading && (
          <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white/90 pt-2">
            <Spinner className="h-8 w-8" />
          </div>
        )}
        <div className={`flex-1 ${step < 1 ? "block" : "hidden"}`}>
          <ul role="list" className="mt-5 grid grid-cols-2 gap-x-6 gap-y-6">
            {stateItems.map((stateItem) => {
              if (accreditationItems.find((accreditationItem) => accreditationItem.stateId === stateItem.id)) return;
              return (
                <li key={stateItem.id}>
                  <StateCard
                    image={stateItem.image}
                    name={stateItem.name}
                    onClick={() => handleSetState(stateItem)}
                    className={clsx("relative cursor-pointer overflow-hidden rounded-lg border", {
                      "border-primary": stateId === stateItem.id,
                      "opacity-50": stateId && stateId !== stateItem.id,
                    })}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className={`flex-1 ${step > 0 ? "block" : "hidden"}`}>
          <div className="mt-2">
            <Typography variant="paragraph">Registration Certificate</Typography>
            {regCert ? (
              <div className="mt-1 flex">
                <RegistrationCertificate name={regCert.name} className="rounded-lg bg-neutral-surface-gray p-3" />
                <button
                  className="ml-[18px] mr-3 flex items-center bg-transparent text-base text-danger hover:text-danger-dark"
                  onClick={() => setRegCert(null)}
                >
                  <Icon name="Trash" size="sm" className="mr-2" aria-hidden="true" />
                  <Typography variant="paragraph">Remove</Typography>
                </button>
              </div>
            ) : (
              <Dropzone
                accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "application/pdf": [".pdf"] }}
                maxSize={20000000}
                upload={(file) => setRegCert(file)}
                detail={
                  <div className="flex h-full flex-col items-center justify-center gap-y-4">
                    <Icon name="FolderMinus" variant="Bold" size="xxl" aria-hidden="true" />
                    <div className="flex flex-col items-center justify-center">
                      <Typography variant="action">Drag & Drop your files here or choose file</Typography>
                      <Typography variant="paragraph">20 MB max file size</Typography>
                    </div>
                  </div>
                }
                className="mt-1 h-[200px]"
              />
            )}
          </div>
          <div className="mt-6">
            <Typography variant="paragraph">Expiry / Renewal Date</Typography>
            <DateInput className="mt-1" selected={expiryDate} onChange={(date) => setExpiryDate(date)} dateFormat={dateFormat} />
          </div>
          <div className="mt-6">
            <Typography variant="paragraph">Registration Number</Typography>
            <TextInput
              className="mt-1"
              placeholder="0000000000"
              type="number"
              min="0"
              max="9999999999"
              required
              value={regNo}
              onChange={(e) => setRegNo(e.target.value)}
            />
            <Typography variant="small" className="text-neutral-dark-gray">
              Hire Car Service Accreditation number
            </Typography>
          </div>
        </div>
        {renderButtons()}
      </form>
    </>
  );
};
