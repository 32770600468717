import {
  GetNetworkHistoryRawResponse,
  GetNetworkHistoryResponse,
  GetNetworkItemRawResponse,
  GetNetworkItemResponse,
  GetNetworkRawResponse,
  GetNetworkResponse,
} from "./types";

export const transformNetwork = (res: GetNetworkRawResponse) => {
  const {
    _embedded: { network: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetNetworkResponse = {
    data: {
      items: items.map((i) => {
        const { operator, metadata } = i;
        return {
          id: i.uuid,
          operator: {
            id: operator.uuid,
            name: operator.name,
            contact: operator.contact,
            assets: operator.assets,
            assetsCdn: operator.assets_cdn,
          },
          status: i.status,
          paysCommission: i.pays_commission,
          created: i.created,
          updated: i.updated,
          ownsRelationship: metadata.owns_relationship,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};

export const transformNetworkHistory = (res: GetNetworkHistoryRawResponse) => {
  const {
    _embedded: { network: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetNetworkHistoryResponse = {
    data: {
      items: items.map((i) => {
        const { created_at, operator, account, field, to } = i;

        return {
          created: created_at,
          operator: operator.name,
          account: account.name,
          field,
          value: to,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};

export const transformNetworkItem = (res: GetNetworkItemRawResponse) => {
  const { operator, metadata, _links } = res;

  const response: GetNetworkItemResponse = {
    data: {
      id: res.uuid,
      operator: {
        id: operator.uuid,
        name: operator.name,
        contact: operator.contact,
        assets: operator.assets,
        assetsCdn: operator.assets_cdn,
      },
      status: res.status,
      paysCommission: res.pays_commission,
      created: res.created,
      updated: res.updated,
      ownsRelationship: metadata.owns_relationship,
    },
    links: _links,
  };

  return response;
};
