import { Link, To } from "react-router-dom";
import { Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export type BreadcrumbsPage = {
  name: string;
  to: To;
  current?: boolean;
};

export interface BreadcrumbsProps extends React.HTMLAttributes<HTMLElement> {
  pages: BreadcrumbsPage[];
}

export const Breadcrumbs = ({ pages, className }: BreadcrumbsProps) => {
  return (
    <nav className={clsx("flex truncate", className)} aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-2 ">
        {pages.map((page, i) => (
          <li key={`${page.name}-${i}`} className="whitespace-nowrap">
            <div className="flex items-center">
              {i > 0 && <span className=" text-sm  text-neutral-dark-gray">/</span>}
              <Link
                to={page.to}
                className="ml-2  text-sm text-neutral-dark-gray hover:text-neutral-black"
                aria-current={page.current ? "page" : undefined}
              >
                <Typography variant="action">{page.name}</Typography>
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
