import { createSlice } from "@reduxjs/toolkit";
import { reportApi } from "@/redux/apis";
import { TripReportingState } from "./types";

const initialState: TripReportingState = {
  tripReport: undefined,
};

export const tripReportingSlice = createSlice({
  name: "tripReporting",
  initialState,
  reducers: {
    clearTripReport: (state) => {
      state.tripReport = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(reportApi.endpoints.getReport.matchFulfilled, (state, { payload }) => {
      state.tripReport = payload.tripReport;
    });
  },
});

export const { clearTripReport } = tripReportingSlice.actions;
export default tripReportingSlice.reducer;
