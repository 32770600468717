import { useState } from "react";
import { Alert, Divider, Icon, IconButton, SidePanel, Skeleton, Toggle, Typography } from "@/components/atoms";
import { useInfiniteScroll } from "@/hooks";
import { useGetInvoiceSettingsHistoryQuery, useLazyGetInvoiceSettingsHistoryQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppSelector } from "@/redux/hooks";
import { invoicingSettingsHistoryPageSelector, invoicingSettingsSelector } from "@/redux/slices/payment/selectors";
import { InvoiceSetupForm } from "./forms/InvoiceSetupForm";
import { InvoiceSettingLogsTable } from "./tables";

interface InvoiceSetupPanelProps {
  open: boolean;
  onClose: () => void;
}

export const InvoiceSetupPanel = ({ open, onClose }: InvoiceSetupPanelProps) => {
  const [scroller, setScroller] = useState<HTMLDivElement | null>(null);
  const [showLogs, setShowLogs] = useState(false);
  const { history } = useAppSelector(invoicingSettingsSelector);
  const { current, count } = useAppSelector(invoicingSettingsHistoryPageSelector);
  const { isFetching } = useGetInvoiceSettingsHistoryQuery({ page: "1" });
  const [getInvoiceSettingsHistory, { isFetching: isFetchingLazy }] = useLazyGetInvoiceSettingsHistoryQuery();

  useInfiniteScroll({
    scroller,
    hasMore: current < count,
    pageStart: current,
    loadMore: (page: string) => getInvoiceSettingsHistory({ page }),
    // Infinite scrolling should only start working when show logs is enabled
    isFetching: isFetching || isFetchingLazy || !showLogs,
  });

  return (
    <SidePanel open={open} onClose={onClose} width={550} focused ref={(el) => setScroller(el)}>
      <div className="flex items-center justify-between px-5 py-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="Setting2" size="lg" />
          <Typography variant="title">Invoice Setup</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <div className="flex flex-col p-5">
        <Alert type="info" message="Establish invoice setup for universal display on all generated invoices." />
        <InvoiceSetupForm className="mt-4" />
        <Divider className="my-8" />
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row items-center">
            <div className="flex flex-1 flex-col">
              <Typography variant="action">Invoice Setting Logs</Typography>
              <Typography variant="paragraph" className="text-neutral-dark-gray">
                View settings change history
              </Typography>
            </div>
            <Toggle size="md" checked={showLogs} onChange={(checked) => setShowLogs(checked)} />
          </div>
          {showLogs &&
            (isFetching ? (
              <Skeleton />
            ) : history.length > 0 ? (
              <div className="flex flex-col">
                <InvoiceSettingLogsTable logs={history} />
                {isFetchingLazy && <Skeleton variant="short" />}
              </div>
            ) : (
              <div className="flex items-center rounded-lg border border-neutral-mid-gray p-6">
                <Typography variant="paragraph">There are currently no invoice history logs available</Typography>
              </div>
            ))}
        </div>
      </div>
    </SidePanel>
  );
};
