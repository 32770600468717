import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "@/redux/baseQuery";
import { RecommendProviderResponse } from "./types";

export const recommendApi = createApi({
  reducerPath: "recommendApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    recommendProvider: builder.mutation<RecommendProviderResponse, string>({
      query: (email) => ({
        url: "/v3/recommend/provider",
        method: "POST",
        body: { email },
      }),
    }),
  }),
});

export const { useRecommendProviderMutation } = recommendApi;
