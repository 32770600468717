import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useLazyGetFixedPricingLocationsQuery } from "@/redux/apis/config/pricing/tripCost";
import { useAppSelector } from "@/redux/hooks";
import { fixedPricingLocationSelector, fixedPricingLocationsSelector } from "@/redux/slices/pricing/selectors";
import { FixedPricingLocation } from "@/redux/slices/pricing/types";
import { useFixedPricingContext } from "./fixedPricing";

interface FixedPricingLocationState {
  pricingLocations: FixedPricingLocation[];
  locationName: string | null;
  isFetching: boolean;
  isRestricted: boolean;
  pricingLocation?: FixedPricingLocation;
}

const FixedPricingLocationContext = createContext<FixedPricingLocationState | null>(null);

export const FixedPricingLocationContextProvider = ({ children }: React.PropsWithChildren) => {
  const { isFetching: isFetchingPricingProfiles, pricingProfile } = useFixedPricingContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const locationName = searchParams.get("locationName");
  const { locationId = "" } = useParams();

  const [getPricingLocations, { isFetching: isFetchingPricingLocations, isSuccess: isSuccessPricingLocations }] =
    useLazyGetFixedPricingLocationsQuery();
  const pricingLocations = useAppSelector(fixedPricingLocationsSelector);
  const pricingLocation = useAppSelector(fixedPricingLocationSelector(locationId));
  const [isRestricted, setIsRestricted] = useState(false);

  useEffect(() => {
    if (!isFetchingPricingProfiles && pricingProfile) getPricingLocations({ id: pricingProfile.id });
  }, [isFetchingPricingProfiles, pricingProfile, getPricingLocations]);

  // breadcrumbs setup
  useEffect(() => {
    if (pricingLocation && !pricingLocation.managed && locationName !== pricingLocation.pickupLocation.name) {
      searchParams.set("locationName", pricingLocation.pickupLocation.name);
      setSearchParams(searchParams);
    }
  }, [pricingLocation, locationName, searchParams, setSearchParams]);

  // restricted check
  useEffect(() => {
    if (isSuccessPricingLocations && (!pricingLocation || pricingLocation.managed)) setIsRestricted(true);
  }, [isSuccessPricingLocations, pricingLocation]);

  return (
    <FixedPricingLocationContext.Provider
      value={{ pricingLocations, isFetching: isFetchingPricingLocations, locationName, isRestricted, pricingLocation }}
    >
      {children}
    </FixedPricingLocationContext.Provider>
  );
};

export const useFixedPricingLocationContext = () => {
  const context = useContext(FixedPricingLocationContext);
  if (!context) throw new Error("useFixedPricingLocationContext must be used within a FixedPricingLocationContextProvider");

  return context;
};
