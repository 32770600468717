import { clsx } from "@/utils";

export type PanelProps = React.HTMLAttributes<HTMLDivElement>;

export const Panel = ({ children, className, ...props }: PanelProps) => {
  return (
    <div className={clsx("w-full rounded-lg bg-white px-5 py-6 shadow", className)} {...props}>
      {children}
    </div>
  );
};
