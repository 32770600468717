import { useFormContext } from "react-hook-form";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";
import { SpecificDateField } from "./SpecificDateField";
import { TimeStartField } from "./TimeStartField";
import { TimeEndField } from "./TimeEndField";
import { AllDayField } from "./AllDayField";
import { DateStartField } from "./DateStartField";
import { DateEndField } from "./DateEndField";
import { RepeatYearlyField } from "./RepeatYearlyField";
import { ErrorMessage } from "@/components/atoms";

export const TimeDateField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();
  const specificDate = watch("specificDate");

  return (
    <div className={clsx("flex flex-col rounded-lg bg-neutral-surface-gray p-5", className)} {...props}>
      <SpecificDateField />
      <div className="flex flex-col gap-y-1">
        <div className="grid grid-cols-2 items-center">
          {specificDate && <DateStartField className="mt-5 mr-1 col-span-1" />}
          <TimeStartField className="mt-5 col-span-1" />
        </div>
        <ErrorMessage errors={errors} name="dateStart" />
        <ErrorMessage errors={errors} name="timeStart" />
      </div>
      <div className="flex flex-col gap-y-1">
        <div className="grid grid-cols-2 items-center">
          {specificDate && <DateEndField className="mt-5 mr-1 col-span-1" />}
          <TimeEndField className="mt-5 col-span-1" />
        </div>
        <ErrorMessage errors={errors} name="dateEnd" />
        <ErrorMessage errors={errors} name="timeEnd" />
      </div>
      <div className="grid grid-cols-2 items-center">
        {specificDate && <RepeatYearlyField className="mt-2 col-span-1" />}
        <AllDayField className="mt-2 col-span-1" />
      </div>
    </div>
  );
};