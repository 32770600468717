import { adminApi } from "../adminApi";
import { transformBusinessDetails, transformUpdateBusinessDetails } from "./helpers";
import { GetBusinessDetailsResponse, UpdateBusinessDetailsParams, UpdateBusinessDetailsResponse } from "./types";

export const businessDetailsApi = adminApi.enhanceEndpoints({ addTagTypes: ["BusinessDetails"] }).injectEndpoints({
  endpoints: (builder) => ({
    getBusinessDetails: builder.query<GetBusinessDetailsResponse, void>({
      query: () => `/business-details`,
      transformResponse: transformBusinessDetails,
      providesTags: ["BusinessDetails"],
    }),
    updateBusinessDetails: builder.mutation<UpdateBusinessDetailsResponse, UpdateBusinessDetailsParams>({
      query: (body) => ({
        url: `/business-details`,
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateBusinessDetails,
      invalidatesTags: ["BusinessDetails"],
    }),
  }),
});

export const { useGetBusinessDetailsQuery, useUpdateBusinessDetailsMutation } = businessDetailsApi;
