import { useState } from "react";
import { Button, IconButton, Spinner, Toggle, Typography, Modal } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import {
  useDeleteTransferTypeMutation,
  useReorderTransferTypeMutation,
  useToggleTransferTypeMutation,
} from "@/redux/apis/config/pricing/transferTypes";
import { useAppDispatch } from "@/redux/hooks";
import { setTransferTypes, toggleTransferType } from "@/redux/slices/pricing/pricingSlice";
import { TransferTypeItem } from "@/redux/slices/pricing/types";
import { addNotification, clsx } from "@/utils";
import { modals } from "./fixtures";
import { generateEditorColumns } from "./helpers";
import { TransferTypeTableData } from "./types";

interface TransferTypesTableEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  items: TransferTypeItem[];
  onEditItem: (item: TransferTypeItem) => void;
}

export const TransferTypesTableEditor = ({ items, onEditItem, className, ...props }: TransferTypesTableEditorProps) => {
  const dispatch = useAppDispatch();
  const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null);
  const [toggle, { isLoading: toggleLoading }] = useToggleTransferTypeMutation();
  const [remove, { isLoading: removeLoading }] = useDeleteTransferTypeMutation();
  const [reorder, { isLoading: reorderLoading }] = useReorderTransferTypeMutation();
  const [activeModal, setActiveModal] = useState<"error" | "exit" | "delete" | null>(null);

  const data: TransferTypeTableData[] = items.map(
    (i) =>
      ({
        id: i.id,
        name: <Typography className="leading-6">{i.name}</Typography>,
        description: i.description,
        costIncrease: `${i.multiplier > 0 ? "+" : ""}${i.multiplier}%`,
        active: <Toggle size="sm" checked={i.isActive} onChange={(checked) => toggleItemStatus(i.id, checked)} className="mt-0" />,
        actions: (
          <span className="flex gap-2">
            <IconButton
              iconName="Edit2"
              variant="tertiary"
              onClick={() => onEditItem(i)}
              className="border-none bg-transparent p-1 !text-info hover:bg-info-light"
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              onClick={() => {
                setPendingDeleteId(i.id);
                setActiveModal("delete");
              }}
              className="border-none bg-transparent p-1 !text-danger hover:bg-danger-light"
            />
          </span>
        ),
      } as TransferTypeTableData)
  );

  const columns = generateEditorColumns(data);

  const toggleItemStatus = (id: string, isActive: boolean) => {
    const item = items.find((i) => i.id === id);
    if (!item) return;

    dispatch(toggleTransferType({ id, isActive }));

    toggle({ uuid: id, is_active: isActive })
      .unwrap()
      .then(() => {
        addNotification("info", `Changed active status: ${isActive ? "On" : "Off"}`, `Changed active status for "${item.name}".`);
      })
      .catch(() => setActiveModal("error"));
  };

  const onReorder = (sortedItems: TransferTypeTableData[]) => {
    const mapping: { [key: string]: number } = {};

    sortedItems.forEach((item, index) => {
      const newOrder = items[index].order;
      mapping[item.id] = newOrder;
    });

    const newItems = items.map((item) => ({ ...item, order: mapping[item.id] })).sort((a, b) => (a.order - b.order));

    dispatch(setTransferTypes(newItems));

    reorder(mapping)
      .unwrap()
      .then(() => {
        addNotification("info", `Updated Order`, "Successfully updated transfer types order.");
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const handleDeleteRow = () => {
    setActiveModal(null);
    const item = items.find((i) => i.id === pendingDeleteId);
    if (!item) return;

    remove(item.id)
      .unwrap()
      .then(() => {
        addNotification("info", `Deleted 1 row`, `Deleted "${item.name}"`);
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteRow} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  return (
    <div className="relative">
      {activeModal && (
        <Modal
          open={true}
          title={modals[activeModal].title}
          description={modals[activeModal].description}
          onClose={() => setActiveModal(null)}
          renderButtons={renderModalButtons}
        />
      )}
      {(toggleLoading || removeLoading || reorderLoading) && (
        <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white/90">
          <Spinner className="h-8 w-8" />
        </div>
      )}
      <DataTable
        columns={columns}
        data={data}
        className={clsx("[&_table]:table-auto overflow-x-auto w-full", className)}
        onReorder={onReorder}
        {...props}
      />
    </div>
  );
};
