import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { Loading } from "@/components/atoms";
import { getStripePublishableKey } from "@/helpers/apiHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetPaymentMethodSecretMutation } from "@/redux/apis/payInvoice/payInvoiceApi";
import { useAppSelector } from "@/redux/hooks";
import { payByCreditCardSelector } from "@/redux/slices/payInvoice/selectors";
import { addToast } from "@/utils";
import { usePayInvoicePageContext } from "../context";
import { PayByCreditCardForm } from "./PayByCreditCardForm";

export const PayByCreditCardStep1 = () => {
  const { params } = usePayInvoicePageContext();
  const stripePublishableKey = getStripePublishableKey();
  const stripePromise = loadStripe(stripePublishableKey);
  const [getPaymentMethodSecret, { isLoading }] = useGetPaymentMethodSecretMutation();
  const { paymentMethodSecret } = useAppSelector(payByCreditCardSelector);

  useEffect(() => {
    getPaymentMethodSecret(params)
      .unwrap()
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex h-full w-full">
      {isLoading && <Loading />}
      {stripePromise && paymentMethodSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret: paymentMethodSecret }}>
          <PayByCreditCardForm />
        </Elements>
      )}
    </div>
  );
};
