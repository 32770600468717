import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Blockquote, Button, Card, Divider, ErrorMessage, Icon, TextArea, Tooltip, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useCloseJobNoShowMutation } from "@/redux/apis/booking/endpoints/closeJob";
import { BookingDetails } from "@/redux/slices/booking/types";
import { addToast, getAssetUrl } from "@/utils";
import { useSelectedBookingId } from "../../../hooks/useSelectedBookingId";

const schema = z.object({
  notesToDriver: z.string().min(1, { message: "This field is required" }),
  notesToCustomer: z.string().min(1, { message: "This field is required" }),
});

type FormData = z.infer<typeof schema>;

export const CloseNoShow = ({ booking, onClose }: { booking: BookingDetails; onClose: () => void }) => {
  const { id: bookingId, driver, closeMeta, status } = booking;
  const [_, setSelectedBookingId] = useSelectedBookingId();
  const [closeJobNoShow, { isLoading: isClosingJob }] = useCloseJobNoShowMutation();

  const formValues = useMemo(() => {
    if (!closeMeta || !["closed", "complete_awaiting_review"].includes(status)) return undefined;

    const { clientNotes, providerNotes } = closeMeta;

    return {
      notesToCustomer: clientNotes,
      notesToDriver: providerNotes,
    } as Partial<FormData>;
  }, [closeMeta, status]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: formValues,
  });

  const onSubmit = async (data: FormData) =>
    closeJobNoShow({ bookingId, clientNotes: data.notesToCustomer, driverNotes: data.notesToDriver })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Closed Job J${bookingId} as No Show`);
        setSelectedBookingId(undefined);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5 flex flex-1 flex-col">
      <div className="flex-1 space-y-5">
        <Card as="fieldset">
          <Typography className="mb-1 flex items-center gap-1">
            Notes to Driver
            <Tooltip content="These notes will be sent to the driver upon close" placement="right">
              <Icon name="InfoCircle" variant="Bold" size="md" className="rotate-180 text-neutral-dark-gray" />
            </Tooltip>
          </Typography>
          <TextArea placeholder="Enter Notes" rows={3} {...register("notesToDriver")} />
          <ErrorMessage errors={errors} name="notesToDriver" className="mt-2" />
        </Card>
        <Card as="fieldset">
          <Typography className="mb-1 flex items-center gap-2">
            Notes to Customer
            <Tooltip
              content="We recommend leaving notes explaining why this job is being closed with a charge. These notes will be displayed to the customer."
              placement="right"
              maxWidth={320}
            >
              <i className="inline-flex">
                <Icon name="InfoCircle" variant="Bold" size="md" className="inline rotate-180 text-neutral-dark-gray" />
              </i>
            </Tooltip>
          </Typography>
          <TextArea placeholder="Enter Notes" rows={3} {...register("notesToCustomer")} />
          <ErrorMessage errors={errors} name="notesToCustomer" className="mt-2" />
        </Card>
        {closeMeta?.driverNotes && (
          <Blockquote avatarURL={getAssetUrl(driver?.avatar)} heading={driver!.name} subheading="Driver Notes">
            {closeMeta?.driverNotes}
          </Blockquote>
        )}
      </div>
      <div className="w-full">
        <Divider className="-mx-5" />
        <Button type="submit" disabled={isClosingJob} className="mt-5 w-full" variant="primary" size="lg">
          Approve For Payment
        </Button>
      </div>
    </form>
  );
};
