import { isEmpty } from "ramda";
import { useState } from "react";
import { FieldErrors, useFieldArray, useFormContext } from "react-hook-form";
import { Button, ErrorMessage, Icon, IconButton, Modal, PhoneNumberInput, TextInput, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { BillingContactRemove } from "../contents/BillingContactRemove";
import { CompaniesFormData } from "../types";

export const BillingContactForm = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const [selectedBillingContact, setSelectedBillingContact] = useState<{ index: number; name?: string } | null>(null);
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "billingContacts",
  });
  const watchBillingContacts = watch("billingContacts");
  const watchInvoiceGenerateLevel = watch("invoiceGenerateLevel");
  const isCompanyLevel = watchInvoiceGenerateLevel === "company" || watchInvoiceGenerateLevel === "client_no_invoice";

  const handleCloseModal = () => setSelectedBillingContact(null);

  const handleRemoveBillingContact = () => {
    remove(selectedBillingContact?.index);
    handleCloseModal();
  };

  const renderBillingContacts = () => {
    return fields.map((field, i) => {
      return (
        <div key={field.id} className="flex flex-row items-center gap-x-2.5">
          <div className="grid grid-cols-2 lg:grid-cols-3 w-full gap-2.5 lg:gap-5">
            <div className="col-span-full sm:col-span-1 flex flex-col gap-1">
              <Typography>Name</Typography>
              <TextInput
                className="w-full"
                type="text"
                hasError={!!errors.billingContacts?.[i]?.name}
                placeholder="Enter Name"
                maxLength={100}
                {...register(`billingContacts.${i}.name`)}
              />
              {errors.billingContacts?.[i] && <ErrorMessage errors={errors.billingContacts[i] as FieldErrors} name="name" />}
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-col gap-1">
              <Typography>
                Email Address <span className="text-danger">*</span>
              </Typography>
              <TextInput
                className="w-full"
                hasError={!!errors.billingContacts?.[i]?.emailAddress}
                placeholder="Enter Email Address"
                maxLength={100}
                {...register(`billingContacts.${i}.emailAddress`)}
              />
              {errors.billingContacts?.[i] && <ErrorMessage errors={errors.billingContacts[i] as FieldErrors} name="emailAddress" />}
            </div>
            <div className="col-span-full sm:col-span-1 flex flex-row gap-x-1">
              <div className="flex flex-col gap-1 w-10/12">
                <Typography>Contact Number</Typography>
                <PhoneNumberInput
                  control={control}
                  name={`billingContacts.${i}.contactNumber`}
                  hasError={!!errors.billingContacts?.[i]?.contactNumber}
                />
                {errors.billingContacts?.[i] && <ErrorMessage errors={errors.billingContacts[i] as FieldErrors} name="contactNumber" />}
              </div>
              <div
                className={clsx("flex flex-col gap-1", {
                  hidden: isCompanyLevel && fields.length < 2,
                })}
              >
                <Typography className="text-transparent">T</Typography>
                <IconButton
                  onClick={() => setSelectedBillingContact({ index: i, name: watchBillingContacts?.[i].name })}
                  iconName="Trash"
                  variant="tertiary"
                  className={"!text-danger hover:bg-danger-light"}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={clsx("flex flex-col gap-2.5 lg:gap-5", className)} {...props}>
      <div className="flex flex-col gap-y-1">
        <div className="flex flex-row items-center gap-x-2">
          <Typography variant="action">Billing Contact</Typography>
          <Tooltip
            content="The designated billing contact will serve as the recipient for our invoice and receipt emails."
            placement="right"
            maxWidth={459}
          >
            <span>
              <Icon name="InfoCircle" variant="Bold" size="lg" className="text-neutral-dark-gray" />
            </span>
          </Tooltip>
        </div>
      </div>
      {!isEmpty(fields) && <div className="flex flex-col gap-y-2.5 lg:gap-y-5">{renderBillingContacts()}</div>}
      <div className="flex flex-col items-center">
        <Button
          startIcon="Add"
          variant="tertiary"
          size="lg"
          className="w-full"
          iconClassName="flex-shrink-0"
          onClick={() => append({ name: "", emailAddress: "", contactNumber: "" })}
        >
          Add Account Contact
        </Button>
      </div>
      <Modal open={!!selectedBillingContact} onClose={handleCloseModal}>
        {selectedBillingContact && (
          <BillingContactRemove onRemove={handleRemoveBillingContact} onClose={handleCloseModal} name={selectedBillingContact.name} />
        )}
      </Modal>
    </div>
  );
};
