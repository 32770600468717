import { Link } from "react-router-dom";
import { Icon, Typography } from "@/components/atoms";
import { useLayout, useOperator } from "@/hooks";
import { clsx } from "@/utils";

export const PaymentConfigurationBanner = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { flags: { paymentConfigured }, roles } = useOperator();
  const { layout: { sidebarOpen } } = useLayout();
  const isAdmin = roles.includes("Fa_Access") || roles.includes("Owner") || roles.includes("Admin");

  return (
    <div
      className={clsx(
        "relative top-0 z-40 min-h-16 flex flex-col items-center justify-center gap-x-5 bg-danger text-white lg:min-h-10 lg:flex-row sm:pl-[100px] lg:pl-[120px]",
        {
          "hidden": !isAdmin || paymentConfigured === undefined || paymentConfigured === true,
          "sm:pl-[200px] lg:pl-[270px]": sidebarOpen,
        },
        className
      )}
      {...props}
    >
      <div className="mx-2 flex flex-row items-center gap-x-2">
        <Icon name="Danger" variant="Bold" size="xs" className="flex-shrink-0" />
        <Typography variant="paragraph">
          You are not currently configured to receive payments. Complete setup to start receiving bookings.
        </Typography>
      </div>
      <Link to="/payments/payment-configuration" className="flex flex-row items-center gap-x-2.5">
        <Typography variant="paragraph" className="underline">
          Go to Payment Configuration
        </Typography>
        <Icon name="ArrowRight" size="lg" className="flex-shrink-0" />
      </Link>
    </div>
  );
};
