import { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Button, Icon, Panel, PasswordInput, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLayout } from "@/hooks";
import { useUpdatePasswordMutation } from "@/redux/apis/profile/profileApi";
import { addToast } from "@/utils";
import { useNavigate } from "react-router-dom";

type FormData = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const ProfilePasswordForm = () => {
  const navigate = useNavigate();
  const { setFullScreen } = useLayout();
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = useForm<FormData>();
  const [errors, setErrors] = useState<string[]>([]);

  const onSubmit = handleSubmit((data) => {
    setErrors([]);

    const { newPassword, confirmPassword, currentPassword } = data;

    if (newPassword !== confirmPassword) {
      setErrors(["Passwords don't match. Please try again."]);
      return;
    }

    updatePassword({ current_password: currentPassword, password: newPassword })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated your Password");
        reset();
        setFullScreen(false);
        navigate("../../profile");
      })
      .catch((e) => setErrors(getErrorMessages(e)));
  });

  return (
    <Panel>
      <div className="w-full">
        <div className="px-6 text-center">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center rounded-lg bg-primary-light p-3 mb-4">
              <Icon
                name="Lock1"
                variant="Bold"
                size={36}
                className="text-primary"
              />
            </div>
          </div>
          <Typography variant="h1">Change Password</Typography>
          <Typography variant="paragraph" className="mt-1 text-neutral-dark-gray">
            In order to change to a new password, please enter your current password.
          </Typography>
        </div>

        {errors.length > 0 && (
          <div className="my-4">
            {errors.map((e, i) => (
              <Alert key={i} type="danger" message={e} className="my-4" />
            ))}
          </div>
        )}

        <form className="mt-4 w-full" onSubmit={onSubmit}>
          <div className="space-y-4 rounded-md shadow-sm">
            <div>
              <label htmlFor="currentPassword" className="mb-1 block">
                <Typography>Current Password</Typography>
              </label>
              <PasswordInput
                required
                placeholder="Enter current password"
                autoFocus
                minLength={8}
                hasError={!!formErrors.currentPassword}
                {...register("currentPassword", { required: true })}
              />
            </div>
            <div>
              <label htmlFor="pnewPasswordassword" className="mb-1 block">
                <Typography>Enter new password</Typography>
              </label>
              <PasswordInput
                required
                placeholder="Enter new password"
                minLength={8}
                hasError={!!formErrors.newPassword}
                {...register("newPassword", { required: true })}
              />
              <Typography variant="small" className="text-neutral-dark-gray">
                Note: minimum of 8 characters
              </Typography>
            </div>
            <div>
              <label htmlFor="confirmPassword" className="mb-1 block">
                <Typography>Confirm new password</Typography>
              </label>
              <PasswordInput
                required
                placeholder="Enter password"
                minLength={8}
                hasError={!!formErrors.confirmPassword}
                {...register("confirmPassword", { required: true })}
              />
            </div>
          </div>

          <div className="mt-8">
            {isLoading ? (
              <Button type="submit" disabled className="w-full">
                <div className="flex items-center justify-center ">
                  <Spinner variant="light" />
                  Processing...
                </div>
              </Button>
            ) : (
              <Button type="submit" className="w-full" size="lg">
                Change Password
              </Button>
            )}
          </div>
        </form>
      </div>
    </Panel>
  );
};
