import { Dropzone, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddAvatarMutation } from "@/redux/apis/company/companyApi";
import { addToast } from "@/utils/addToast";
import { useState } from "react";

export interface CompanyAvatarUploadModalProps {
  companyId: string;
  open: boolean;
  onClose: () => void;
}

export const CompanyAvatarUploadModal = ({ companyId, open, onClose }: CompanyAvatarUploadModalProps) => {
  const [addAvatar] = useAddAvatarMutation();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = (file: File) => {
    setIsLoading(true);
    
    const formData = new FormData();
    formData.append("avatar", file);
    addAvatar({ id: companyId, body: formData })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully updated company logo");
        setTimeout(() => window.location.reload(), 1250);
      })
      .catch((e) => {
        setIsLoading(false);
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  const handleOnClose = () => {
    if (!isLoading) onClose();
  };

  return (
    <Modal open={open} onClose={handleOnClose} title="Upload Company Logo">
      <div className="relative flex w-[550px] max-w-full flex-col">
        {isLoading && <Loading />}
        <button type="button" className="-mt-8 ml-auto rounded-md text-black" onClick={handleOnClose}>
          <Icon name="close" isCustom size="lg" aria-hidden="true" />
        </button>
        <div className="mt-2">
          <Dropzone
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpeg", ".jpg"],
            }}
            maxSize={20000000}
            upload={handleUpload}
            className="mt-1 h-[350px]"
            detail={
              <div className="flex h-full flex-col  items-center justify-center gap-y-4">
                <Icon name="Gallery" variant="Bold" size="xxl" aria-hidden="true" />
                <div className="flex flex-col items-center justify-center">
                  <Typography variant="action">
                    Drag & Drop your photo here or <span className="text-info underline">choose file</span>
                  </Typography>
                  <Typography variant="paragraph">20 MB max file size</Typography>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </Modal>
  );
};
