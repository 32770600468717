import { Button, Icon, IconButton, Tooltip, Typography } from "@/components/atoms";
import { useAppDispatch } from "@/redux/hooks";
import { setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { clsx } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const ClientNotes = () => {
  const dispatch = useAppDispatch();

  const {
    status,
    notes: { driver: clientNotes },
  } = useSelectedBooking();

  const handleEditMode = () => {
    dispatch(setInlineEditing("client-notes"));
  };

  const isEditable = status !== "closed" && status !== "cancelled" && status !== "complete_awaiting_review";

  return (
    <div
      {...(isEditable && { onClick: handleEditMode })}
      className={clsx(
        "items-center gap-3 space-y-2 rounded-md",
        isEditable && "!-mb-3 -mr-4 -translate-x-2 -translate-y-1.5 cursor-pointer px-2 py-1.5 hover:bg-neutral-gray"
      )}
    >
      <div className="flex items-center gap-3">
        <Typography variant="small" className="flex flex-1 items-center text-neutral-black/70">
          Client Notes to Driver
          <Tooltip content="Notes from the client to the driver" placement="right">
            <Icon name="InfoCircle" variant="Bold" className=" ml-1 inline-block rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </Typography>
        {clientNotes && isEditable && (
          <IconButton
            onClick={handleEditMode}
            iconName="Edit2"
            variant="custom"
            iconSize="sm"
            className=" text-info hover:bg-transparent"
          />
        )}
      </div>

      <Typography variant="small" className="line-clamp-5 block max-h-[75px] flex-1 break-words">
        {!clientNotes && isEditable ? (
          <Button
            onClick={handleEditMode}
            startIcon="Add"
            variant="tertiary"
            size="sm"
            className="-ml-3 h-auto py-0 text-info hover:bg-transparent [&>span]:text-sm"
          >
            Add notes
          </Button>
        ) : (
          clientNotes || "-"
        )}
      </Typography>
    </div>
  );
};
