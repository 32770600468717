import { useEffect } from "react";
import { Container } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, ProfileSecureAccountForm } from "@/components/organisms";
import { useLayout, useProfile } from "@/hooks";
import { Restricted } from "../Restricted/Restricted";

export const ProfileSecureAccount = () => {
  const { setFullScreen } = useLayout();
  const { passwordChangeRequired } = useProfile();

  useEffect(() => {
    setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-row min-h-screen overflow-hidden">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        {passwordChangeRequired ? (
          <Container className="flex flex-1 flex-col w-full max-w-[1040px] gap-5 pt-3 md:pt-[30px] pb-8 md:pb-[100px] px-5">
            <div className="self-center w-full max-w-[440px]">
              <ProfileSecureAccountForm />
            </div>
          </Container>
        ) : <Restricted />}
        <GeneralFooter />
      </div>
    </div>
  );
};
