import { format, parseISO } from "date-fns";
import { isEmpty } from "ramda";
import { CheckboxInput, DropdownMenu, IconButton } from "@/components/atoms";
import { Icon, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import {
  ClientJob,
  Invoice,
  InvoiceHistory,
  InvoicePayment,
  InvoiceSettingsHistory,
  InvoicingModalAction,
  InvoicingSidePanelAction,
  Job,
  UninvoicedJobsCompany,
} from "@/redux/slices/payment/types";
import { clsx, formatCurrency } from "@/utils";
import {
  InvoiceHistoryTableData,
  InvoiceSettingsHistoryTableData,
  InvoicesTableData,
  PaymentHistoryTableData,
  JobsTableData,
  ClientJobsTableData,
} from "./types";

export const generateInvoicesColumns = () => {
  const columns: DataTableColumnDef<InvoicesTableData>[] = [
    {
      accessorKey: "notes",
      header: "",
      cell: (props) => props.row.original.notes,
      size: 75,
      enableSorting: false,
    },
    {
      accessorKey: "dueDate",
      header: "Due Date",
      id: "due_date",
      cell: (props) => props.row.original.dueDate,
    },
    {
      accessorKey: "invoiceNumber",
      header: "Invoice no.",
      cell: (props) => props.row.original.invoiceNumber,
      enableSorting: false,
    },
    {
      accessorKey: "issueDate",
      header: "Issue Date",
      cell: (props) => props.row.original.issueDate,
      enableSorting: false,
    },
    {
      accessorKey: "totalAmount",
      header: "Total Amount",
      cell: (props) => props.row.original.totalAmount,
      enableSorting: false,
    },
    {
      accessorKey: "amountDue",
      header: "Amount Due",
      id: "amount_due",
      cell: (props) => props.row.original.amountDue,
    },
    {
      accessorKey: "chd",
      header: () => (
        <div className="flex flex-row items-center gap-x-1.5">
          <Typography variant="small">CHD</Typography>
          <Tooltip
            content={
              <Typography variant="action" className="mb-2">
                Client Held Days: The calculation involves
                <br />
                determining the number of days between the
                <br />
                invoice creation date and the manual marking
                <br />
                of the invoice as “Paid”
              </Typography>
            }
          >
            <span className="ml-1">
              <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
            </span>
          </Tooltip>
        </div>
      ),
      id: "client_held_days",
      cell: (props) => props.row.original.chd,
    },
    {
      accessorKey: "status",
      header: "Status",
      id: "status",
      cell: (props) => props.row.original.status,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateInvoicesData = (
  items: Invoice[],
  dateFormat: string,
  handleInvoiceModalAction: (invoice: Invoice, action: InvoicingModalAction) => void,
  handleInvoiceSidePanelAction: (invoice: Invoice, action: InvoicingSidePanelAction) => void,
  handleIssueInvoiceAction: (invoice: Invoice) => void,
  handleResendInvoiceAction: (invoice: Invoice) => void,
  handleDownloadAction: (invoice: Invoice, document: string) => void
) => {
  const data = items.map((i) => {
    return {
      notes: (
        <Tooltip content="View / Add Internal Notes" placement="right">
          <span className="flex items-center justify-center">
            <IconButton
              iconName="DocumentText"
              variant="tertiary"
              className="!bg-info-light !p-[5px] text-info"
              onClick={() => handleInvoiceSidePanelAction(i, "invoiceNotes")}
            />
          </span>
        </Tooltip>
      ),
      dueDate:
        i.status === "past_due" ? (
          <Tooltip content="Overdue" placement="bottom">
            <span>
              <Typography variant="paragraph" className="text-danger">
                {i.dueDate ? format(parseISO(i.dueDate), dateFormat) : "-"}
              </Typography>
            </span>
          </Tooltip>
        ) : (
          <Typography variant="paragraph">{i.dueDate ? format(parseISO(i.dueDate), dateFormat) : "-"}</Typography>
        ),
      invoiceNumber: i.identifier,
      issueDate: i.issueDate ? format(parseISO(i.issueDate), dateFormat) : "-",
      totalAmount: formatCurrency(i.amount, 2, i.currency),
      amountDue: formatCurrency(i.amountDue, 2, i.currency),
      chd: i.clientHeldDays ? `${i.clientHeldDays} Day${i.clientHeldDays > 1 ? "s" : ""}` : "-",
      status: renderStatus(i.status),
      action: (
        <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
          {i.metadata.isMarkPaidAvailable && (
            <DropdownMenu.Item onClick={() => handleInvoiceModalAction(i, "markAsPaid")} className="w-[240px]">
              <Icon name="check" isCustom size="sm" className="mr-2.5 text-success" />
              <Typography>Mark as Paid</Typography>
            </DropdownMenu.Item>
          )}
          {renderPayByCreditCardAction(i, handleInvoiceSidePanelAction)}
          {i.status === "draft" && (
            <DropdownMenu.Item onClick={() => handleIssueInvoiceAction(i)} className="w-[240px]">
              <Icon name="Send2" size="sm" className="mr-2.5" />
              <Typography>Issue Invoice</Typography>
            </DropdownMenu.Item>
          )}
          <DropdownMenu.Item onClick={() => handleInvoiceSidePanelAction(i, "invoiceLogs")} className="w-[240px]">
            <Icon name="ReceiptText" size="sm" className="mr-2.5" />
            <Typography>Payment Logs</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => handleDownloadAction(i, "pdf")} className="w-[240px]">
            <Icon name="ImportCurve" size="sm" className="mr-2.5" />
            <Typography>Download Invoice</Typography>
          </DropdownMenu.Item>
          {i.documents.csv && (
            <DropdownMenu.Item onClick={() => handleDownloadAction(i, "csv")} className="w-[240px]">
              <Icon name="ImportCurve" size="sm" className="mr-2.5" />
              <Typography>Download CSV</Typography>
            </DropdownMenu.Item>
          )}
          {i.metadata.isCancelAvailable && (
            <DropdownMenu.Item onClick={() => handleInvoiceModalAction(i, "cancelInvoice")} className="w-[240px]">
              <Icon name="Trash" size="sm" className="mr-2.5 text-danger" />
              <Typography>Cancel Invoice</Typography>
            </DropdownMenu.Item>
          )}
          {i.metadata.isWriteOffAvailable && (
            <DropdownMenu.Item onClick={() => handleInvoiceModalAction(i, "writeOff")} className="w-[240px]">
              <Icon name="close" isCustom size="sm" className="mr-2.5 text-danger" />
              <Typography>Close Invoice as Write-Off</Typography>
            </DropdownMenu.Item>
          )}
          {renderResendInvoiceAction(i, handleResendInvoiceAction)}
        </DropdownMenu>
      ),
    } as InvoicesTableData;
  });

  return data;
};

export const renderPayByCreditCardAction = (
  invoice: Invoice,
  handleInvoiceSidePanelAction: (invoice: Invoice, action: InvoicingSidePanelAction) => void
) => {
  const acceptedStatuses = ["issued", "partially_paid", "past_due"];

  if (invoice.metadata.isPayByCreditCardAvailable && acceptedStatuses.includes(invoice.status)) {
    return (
      <DropdownMenu.Item className="w-[240px]" onClick={() => handleInvoiceSidePanelAction(invoice, "payByCreditCard")}>
        <Icon name="Card" size="sm" className="mr-2.5" />
        <Typography>Pay by Credit Card</Typography>
      </DropdownMenu.Item>
    );
  }
};

export const renderResendInvoiceAction = (invoice: Invoice, handle: (invoice: Invoice) => void) => {
  const acceptedStatuses = ["paid", "issued", "partially_paid", "past_due"];
  if (acceptedStatuses.includes(invoice.status)) {
    return (
      <DropdownMenu.Item onClick={() => handle(invoice)} className="w-[240px]">
        <Icon name="Refresh2" size="sm" className="mr-2.5" />
        <Typography>Resend Invoice</Typography>
      </DropdownMenu.Item>
    );
  }
};

export const renderStatus = (status: string) => {
  if (status) {
    return (
      <Typography variant="paragraph" className="flex flex-row items-center gap-x-2 truncate capitalize">
        {renderStatusIcon(status)} {status.replaceAll("_", " ").toLowerCase()}
      </Typography>
    );
  }
};

export const renderStatusIcon = (statusVal: string) => {
  const status = statusVal.toUpperCase();
  const iconColorClass = {
    "bg-success": status === "PAID" || status === "SUCCESSFUL",
    "bg-warning": status === "REFUNDED",
    "bg-warning-dark": status === "PARTIALLY_REFUNDED",
    "bg-danger": status === "FAILED" || status === "CANCELLED" || status === "ERROR",
    "bg-danger opacity-60": status === "CANCELLED",
    "bg-info": status === "PROCESSING" || status === "IN_TRANSIT",
  };

  return <span className={clsx("h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray", iconColorClass)} />;
};

export const generatePaymentHistoryColumns = () => {
  const columns: DataTableColumnDef<PaymentHistoryTableData>[] = [
    {
      accessorKey: "dateAndTime",
      header: "Date and Time",
      cell: (props) => props.row.original.dateAndTime,
      enableSorting: false,
    },
    {
      accessorKey: "amountPaid",
      header: "Amount Paid",
      cell: (props) => props.row.original.amountPaid,
      enableSorting: false,
    },
    {
      accessorKey: "dateReceived",
      header: "Date Received",
      cell: (props) => props.row.original.dateReceived,
      enableSorting: false,
    },
    {
      accessorKey: "markedAsPaidBy",
      header: "Marked as Paid By",
      cell: (props) => props.row.original.markedAsPaidBy,
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "",
      cell: (props) => props.row.original.action,
      size: 50,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generatePaymentHistoryData = (
  items: InvoicePayment[],
  dateFormat: string,
  timeFormat: string,
  currency: string,
  handleUndoPayment: (payment: InvoicePayment) => void,
  handleDownloadReceipt: (payment: InvoicePayment) => void
) => {
  const data = items.map((i) => ({
    dateAndTime: (
      <div className="flex flex-col">
        <Typography variant="paragraph">{i.createdAt ? format(parseISO(i.createdAt), dateFormat) : "-"}</Typography>
        {i.createdAt && (
          <Typography variant="small" className="text-neutral-dark-gray">
            {format(parseISO(i.createdAt), timeFormat)}
          </Typography>
        )}
      </div>
    ),
    amountPaid: (
      <div className="flex flex-col">
        <Typography variant="paragraph">{formatCurrency(i.amountPaid, 2, currency)}</Typography>
        <Typography variant="small" className="text-neutral-dark-gray">
          {i.type === "credit_card" ? "CC payment" : "Manual payment"}
        </Typography>
      </div>
    ),
    dateReceived: i.receivedAt ? format(parseISO(i.receivedAt), dateFormat) : "-",
    markedAsPaidBy: i.receivedByName ? i.receivedByName : "Guest",
    status: (
      <div className="flex flex-col">
        {renderStatus(i.status)}
        <Typography variant="small" className="text-neutral-dark-gray">
          {i.comment}
        </Typography>
      </div>
    ),
    action:
      i.status === "PAID" &&
      (i.type === "credit_card" ? (
        <div className="flex items-center justify-center">
          <Tooltip content="Download Receipt" placement="bottom">
            <span>
              <Icon name="ImportCurve" size="sm" className="cursor-pointer text-info" onClick={() => handleDownloadReceipt(i)} />
            </span>
          </Tooltip>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <Tooltip content="Undo Payment" placement="bottom">
            <span>
              <Icon name="Refresh2" size="sm" className="cursor-pointer text-info" onClick={() => handleUndoPayment(i)} />
            </span>
          </Tooltip>
        </div>
      )),
  } as PaymentHistoryTableData));

  return data;
};

export const generateInvoiceHistoryColumns = () => {
  const columns: DataTableColumnDef<InvoiceHistoryTableData>[] = [
    {
      accessorKey: "date",
      header: "Date",
      cell: (props) => props.row.original.date,
      enableSorting: false,
      size: 100,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
      size: 100,
    },
    {
      accessorKey: "modifier",
      header: "Modifier",
      cell: (props) => props.row.original.modifier,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "event",
      header: "Event",
      cell: (props) => props.row.original.event,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateInvoiceHistoryData = (items: InvoiceHistory[], dateFormat: string, timeFormat: string, currency: string) => {
  const data = items.map((i) => {
    return {
      date: i.createdAt ? format(parseISO(i.createdAt), dateFormat) : "-",
      time: i.createdAt ? format(parseISO(i.createdAt), timeFormat) : "-",
      modifier: i.accountName,
      event: renderInvoiceHistoryEvent(i.field, i.to, currency),
    } as InvoiceHistoryTableData;
  });

  return data;
};

export const renderInvoiceHistoryEvent = (event: string, value: string, currency: string) => {
  switch (event) {
    case "invoice.create":
      return "Created invoice";
    case "invoice.emailed":
      return `Invoice emailed to ${value}`;
    case "payment.receive":
      return `Applied payment of ${formatCurrency(Number(value), 2, currency)}`;
    case "payment.cancel":
      return `Cancelled payment of ${formatCurrency(Number(value), 2, currency)}`;
    case "invoice.receipt.emailed":
      return `Invoice Payment Receipt emailed to ${value}`;
    case "issue": 
      return "Invoice Issued";
    case "invoice.update": 
      if (value === "cancelled") return "Invoice Cancelled";
      else if (value === "written_off") return "Invoice Written Off";
      else if (value === "issued") return "Invoice Issued";
      break;
    default:
      return "";
  }
};

export const generateInvoiceSettingLogsColumns = () => {
  const columns: DataTableColumnDef<InvoiceSettingsHistoryTableData>[] = [
    {
      accessorKey: "date",
      header: "Date",
      cell: (props) => props.row.original.date,
      enableSorting: false,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
    },
    {
      accessorKey: "modifier",
      header: "Modifier",
      cell: (props) => props.row.original.modifier,
      enableSorting: false,
    },
    {
      accessorKey: "event",
      header: "Event",
      cell: (props) => props.row.original.event,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateInvoiceSettingLogsData = (items: InvoiceSettingsHistory[], dateFormat: string, timeFormat: string) => {
  const data = items.map((i) => {
    return {
      date: i.createdAt ? format(parseISO(i.createdAt), dateFormat) : "-",
      time: i.createdAt ? format(parseISO(i.createdAt), timeFormat) : "-",
      modifier: i.accountName,
      event: renderInvoiceSettingLogsEvent(i.field),
    } as InvoiceSettingsHistoryTableData;
  });

  return data;
};

export const renderInvoiceSettingLogsEvent = (event: string) => {
  switch (event) {
    case "account_name":
      return "Updated Account Name";
    case "account_number":
      return "Updated Account Number";
    case "account_routing_number":
      return "Updated Routing Number";
    default:
      return "";
  }
};

// export const getDateFilterValue = (start: string | null, end?: string | null) => {
//   const dateValue = [];
//   if (start) dateValue.push(new Date(start));
//   if (end) dateValue.push(new Date(end));

//   return isEmpty(dateValue) ? null : dateValue;
// };

export const generateClientJobsColumns = () => {
  const columns: DataTableColumnDef<ClientJobsTableData>[] = [
    {
      accessorKey: "clientName",
      header: "Client Name",
      id: "client_name",
      cell: (props) => props.row.original.clientName,
    },
    {
      accessorKey: "totalJob",
      header: () => (
        <div className="text-right">
          <Typography variant="small">Total Job</Typography>
        </div>
      ),
      cell: (props) => props.row.original.totalJob,
      size: 100,
      enableSorting: false,
    },
    {
      accessorKey: "totalAmount",
      header: () => (
        <div className="text-right">
          <Typography variant="small">Total Amount</Typography>
        </div>
      ),
      cell: (props) => props.row.original.totalAmount,
      size: 100,
      enableSorting: false,
    },
    {
      header: "",
      id: "arrow",
      cell: (props) => {
        return (
          <div className="flex flex-shrink-0 items-center justify-center">
            <Icon name={props.row.getIsExpanded() ? "ArrowUp2" : "ArrowDown2"} size="sm" />
          </div>
        );
      },
      size: 10,
    },
  ];

  return columns;
};

export const generateClientJobsData = (items: ClientJob[], currency: string) => {
  const data = items.map((i) => {
    return {
      clientName: i.name,
      totalJob: (
        <Typography variant="paragraph" className="text-right">
          {i.count.toString()}
        </Typography>
      ),
      totalAmount: (
        <Typography variant="paragraph" className="text-right">
          {formatCurrency(i.cost, 2, currency)}
        </Typography>
      ),
    } as ClientJobsTableData;
  });

  return data;
};

export const generateJobsColumns = (
  items: Job[],
  selectedJobs: Array<{ id: number; cost: number }>,
  handleSelectAllChange: (checked: boolean) => void
) => {
  const columns: DataTableColumnDef<JobsTableData>[] = [
    {
      accessorKey: "checkbox",
      header: () => (
        <div className="flex items-center">
          <CheckboxInput
            label=""
            onChange={({ target }) => handleSelectAllChange(target.checked)}
            checked={!isEmpty(items) && selectedJobs.length === items.length}
            disabled={isEmpty(items)}
            variant="check"
          />
        </div>
      ),
      cell: (props) => props.row.original.checkbox,
      size: 10,
      enableSorting: false,
    },
    {
      accessorKey: "pickupDate",
      header: "Pickup Date",
      id: "pickup_date",
      cell: (props) => props.row.original.pickupDate,
    },
    {
      accessorKey: "pickupTime",
      header: "Pickup Time",
      cell: (props) => props.row.original.pickupTime,
      enableSorting: false,
    },
    {
      accessorKey: "jobId",
      header: "Job ID",
      id: "job_id",
      cell: (props) => props.row.original.jobId,
    },
    {
      accessorKey: "costCenter",
      header: "Cost Center",
      id: "cost_center",
      cell: (props) => props.row.original.costCenter,
    },
    {
      accessorKey: "clientName",
      header: "Client Name",
      id: "client_name",
      cell: (props) => props.row.original.clientName,
    },
    {
      accessorKey: "referenceNumber",
      header: "Reference Number",
      id: "reference_number",
      cell: (props) => props.row.original.referenceNumber,
    },
    {
      accessorKey: "totalDue",
      header: "Total Due",
      id: "total",
      cell: (props) => props.row.original.totalDue,
    },
  ];

  return columns;
};

export const generateJobsData = (
  items: Job[],
  dateFormat: string,
  timeFormat: string,
  currency: string,
  selectedJobs: Array<{ id: number; cost: number }>,
  handleCheckboxChange: (checked: boolean, value: { id: number; cost: number }) => void
) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map((i) => {
    return {
      checkbox: (
        <div className="flex items-center">
          <CheckboxInput
            label=""
            value={i.id}
            checked={selectedJobs.some((job) => job.id === i.id)}
            onChange={({ target }) => handleCheckboxChange(target.checked, { id: i.id, cost: i.cost })}
            variant="check"
          />
        </div>
      ),
      pickupDate: i.pickupDate ? format(parseISO(i.pickupDate), dateFormat) : "-",
      pickupTime: i.pickupDate ? format(parseISO(i.pickupDate), timeFormat) : "-",
      jobId: (
        <a
          href={`${phpHostUrl}/passenger/${i.clientAccountId}/booking/view/${i.id}`}
          className="hover:text-info"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="paragraph">{i.id.toString()}</Typography>
        </a>
      ),
      costCenter: i.clientCostCentre ? i.clientCostCentre : "-",
      clientName: i.clientName,
      referenceNumber: i.referenceNumber ? i.referenceNumber : "-",
      totalDue: `${currency} ${formatCurrency(i.cost, 2, currency)}`,
    } as JobsTableData;
  });

  return data;
};

export const getDefaultEmails = (company: UninvoicedJobsCompany, clientEmail?: string) => {
  const emails = new Set<string>();

  for (const billingContact of company.billingContacts) {
    emails.add(billingContact.email);
  }

  if (company.invoice.generateOnLevel === "client" && clientEmail) emails.add(clientEmail);

  return [...emails];
};
