import { useState } from "react";
import { IconButton, SidePanel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useInfiniteScroll } from "@/hooks";
import { useGetDebitUpcomingQuery, useLazyGetDebitUpcomingQuery } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { debitUpcomingSelector } from "@/redux/slices/payment/selectors";
import { CurrentPendingDebitAmountTable } from "./CurrentPendingDebitAmountTable";

interface CurrentPendingDebitAmountPanelProps {
  open: boolean;
  onClose: () => void;
}

export const CurrentPendingDebitAmountPanel = ({ open, onClose }: CurrentPendingDebitAmountPanelProps) => {
  const [scroller, setScroller] = useState<HTMLDivElement | null>(null);
  const {
    items,
    page: { current, count },
  } = useAppSelector(debitUpcomingSelector);
  const { isFetching } = useGetDebitUpcomingQuery({ page: "1" });
  const [getDebitUpcoming, { isFetching: isFetchingLazy }] = useLazyGetDebitUpcomingQuery();

  useInfiniteScroll({
    scroller,
    hasMore: current < count,
    pageStart: current,
    loadMore: (page: string) => getDebitUpcoming({ page }),
    isFetching: isFetching || isFetchingLazy,
  });

  return (
    <SidePanel open={open} onClose={onClose} width={800} focused ref={(el) => setScroller(el)}>
      <div className="flex items-center justify-between px-5 py-5">
        <div className="flex flex-row items-center">
          <Typography variant="title">Current Pending Debit Amount</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <div className="flex flex-col p-5">
        {isFetching ? (
          <Skeleton />
        ) : items.length > 0 ? (
          <div className="flex flex-col">
            <CurrentPendingDebitAmountTable items={items} />
            {isFetchingLazy && <Skeleton variant="short" />}
          </div>
        ) : (
          <EmptyState title="Current Pending Debit Amount" description="There are currently no current pending debit amount available" />
        )}
      </div>
    </SidePanel>
  );
};
