import { isEmpty } from "ramda";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { PageHeader, VehicleCategoriesTable } from "@/components/organisms";
import { usePagination } from "@/hooks/usePagination";
import { useGetVehicleCategoriesQuery } from "@/redux/apis/config/vehicle/vehicleCategories";
import { useAppSelector } from "@/redux/hooks";
import { vehicleCategoriesPageSelector, vehicleCategoriesSelector } from "@/redux/slices/pricing/selectors";
import { VehicleCategoriesEditLegacy } from "./VehicleCategoriesEditLegacy";

export const VehicleCategoriesLegacy = () => {
  const navigate = useNavigate();
  const items = useAppSelector(vehicleCategoriesSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const { current, count, total, size } = useAppSelector(vehicleCategoriesPageSelector);
  const { isFetching } = useGetVehicleCategoriesQuery({ page, page_size: pageSize }, { refetchOnMountOrArgChange: true });

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <>
          <PageHeader.Actions />
          <Panel>
            <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
              <div className="flex-1">
                <Typography variant="h3" className="leading-8">
                  Vehicle Categories (Multiplier)
                </Typography>
                <Typography className="text-neutral-dark-gray">Configure the Vehicle Categories Multiplier for each trip.</Typography>
              </div>
              <div className="flex items-center">
                <Button startIcon="Edit2" onClick={() => navigate("edit")}>
                  Edit
                </Button>
              </div>
            </div>
            {isEmpty(items) ? (
              <EmptyState title="Vehicle Categories" description="There are currently no vehicle categories available" />
            ) : (
              <VehicleCategoriesTable className="mt-4" items={items} />
            )}
          </Panel>
          <Pagination
            onPageClick={handlePageClick}
            onSizeChange={handleSizeChange}
            currentPage={current}
            pageSize={size}
            totalItems={total}
            pageCount={count}
          />
        </>
      )}
    </>
  );
};

// eslint-disable-next-line react/display-name
VehicleCategoriesLegacy.Add = () => null;
VehicleCategoriesLegacy.Edit = VehicleCategoriesEditLegacy;
