import { Icon, Typography } from "@/components/atoms";
import { useBookingOfferPageContext } from "../context";

export const Where = () => {
  const { bookingOffer } = useBookingOfferPageContext();
  const { stops } = bookingOffer;
  const pickup = stops[0];
  const midStops = stops.slice(1, stops.length - 1);
  const dropoff = stops[stops.length - 1];

  return (
    <div className="mt-6">
      <Typography variant="action" className="block w-full rounded-lg bg-neutral-surface-gray px-[10px] py-[6px]">
        Where
      </Typography>
      <div className="mt-4 flex gap-4">
        <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
          <Icon name="LocationDiscover" size={24} className=" text-neutral-dark-gray" />
          <Typography className=" text-neutral-dark-gray">Pickup</Typography>
        </div>
        <div className="flex gap-3">
          <Typography>{pickup.address}</Typography>
        </div>
      </div>
      {midStops.map((stop, idx) => (
        <div className="mt-4 flex gap-4" key={idx}>
          <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
            <Icon name="LocationDiscover" size={24} className=" text-neutral-dark-gray" />
            <Typography className=" text-neutral-dark-gray">Additional Stop</Typography>
          </div>
          <div className="flex gap-3">
            <Typography>{stop.address}</Typography>
          </div>
        </div>
      ))}
      <div className="mt-4 flex gap-4">
        <div className="flex w-[120px] shrink-0  items-center gap-2 md:w-[200px]">
          <Icon name="Location" size={24} className=" text-neutral-dark-gray" />
          <Typography className=" text-neutral-dark-gray">Drop off</Typography>
        </div>
        <div className="flex gap-3">
          <Typography>{dropoff.address}</Typography>
        </div>
      </div>
    </div>
  );
};
