import { FixedPricingContextProvider, FixedPricingPanel } from "@/components/organisms";
import { PricingProfile } from "./PricingProfile";

export const FixedPricing = () => {
  return (
    <FixedPricingContextProvider>
      <FixedPricingPanel />
    </FixedPricingContextProvider>
  );
};

FixedPricing.PricingProfile = PricingProfile;
