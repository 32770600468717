import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Typography } from "@/components/atoms";
import { DisclosurePanel } from "@/components/molecules";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { capitalize, clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../form";
import { VehicleCategoryOffloadModelsField } from "./VehicleCategoryOffloadModelsField/VehicleCategoryOffloadModelsField";
import { VehicleCategoryDispatchToleranceField } from "./fields/VehicleCategoryDispatchToleranceField";
import { VehicleCategoryEnableCustomOffloadField } from "./fields/VehicleCategoryEnableCustomOffloadField";
import { VehicleCategoryEnableSpecificModels } from "./fields/VehicleCategoryEnableSpecificModels";
import { VehicleCategoryOffloadColorsField } from "./fields/VehicleCategoryOffloadColorsField";
import { VehicleCategoryOffloadEngineTypesField } from "./fields/VehicleCategoryOffloadEngineTypesField";
import { VehicleCategoryOffloadMaxAgeField } from "./fields/VehicleCategoryOffloadMaxAgeField";
import { VehicleCategoryOffloadMinRatingField } from "./fields/VehicleCategoryOffloadMinRatingField";

export const AddVehicleCategoryDispatchSettings = () => {
  const { watch } = useFormContext<AddVehicleCategoryFormData>();
  const vehicleClass = watch("class");
  const vehicleType = watch("type");

  const label = useMemo(() => {
    if (vehicleClass && vehicleType) {
      return `${capitalize(watch("class").toLowerCase())} ${getVehicleTypeLabel(watch("type"))}`;
    }

    return "";
  }, [vehicleClass, vehicleType, watch]);

  const enableCustomOffload = watch("enableCustomOffload");

  return (
    <DisclosurePanel title="Custom Network Dispatch Settings" className="col-span-2 flex flex-col">
      <VehicleCategoryEnableCustomOffloadField />

      <div className={clsx("mt-6 w-full space-y-6", !enableCustomOffload && "pointer-events-none opacity-50")}>
        <VehicleCategoryDispatchToleranceField />
        <div className="grid">
          <Typography variant="action">For Network Dispatching of {label}</Typography>
          <Typography className=" text-neutral-dark-gray">Optional filters for a more customized network vehicle dispatching</Typography>
        </div>

        <VehicleCategoryOffloadEngineTypesField />
        <VehicleCategoryOffloadMaxAgeField />
        <VehicleCategoryOffloadMinRatingField />
        <VehicleCategoryOffloadColorsField />
        <VehicleCategoryEnableSpecificModels />
        <VehicleCategoryOffloadModelsField />
      </div>
    </DisclosurePanel>
  );
};
