import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BookingViewCompact } from "@/assets/images/config/booking-compact.svg";
import { ReactComponent as BookingViewDefault } from "@/assets/images/config/booking-default.svg";
import { Button, Loading, Panel, Toggle, Typography, Modal, Skeleton, RadioInput, TextInput, ErrorMessage } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetDisplayOptionsQuery, useUpdateDisplayOptionsMutation } from "@/redux/apis/config/admin/displayOptions";
import { useAppSelector } from "@/redux/hooks";
import { displayOptionsSelector } from "@/redux/slices/admin/selectors";
import { DisplayOptions } from "@/redux/slices/admin/types";
import { clsx } from "@/utils";
import { addToast } from "@/utils/addToast";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save Display Options",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

const formSchema = z.object({
  bookingMaxNumberOfStops: z.coerce.number().min(0).max(12).step(1, "Field must be a whole number."),
  enabledBookingHistoryDisplayOperatorIdentifier: z.boolean(),
  enabledBookingHistoryUseOptimizedWideDisplay: z.boolean(),
  enabledBookingManagerDashboardNoteFunctionality: z.boolean(),
});

type FormData = z.infer<typeof formSchema>;

export const DisplayOptionsEdit = () => {
  const { isFetching } = useGetDisplayOptionsQuery();
  const { details } = useAppSelector(displayOptionsSelector);
  const [updateDisplayOptions, { isLoading }] = useUpdateDisplayOptionsMutation();
  const defaultValues = useMemo(
    () => ({
      bookingMaxNumberOfStops: Number(details.bookingMaxNumberOfStops),
      enabledBookingHistoryDisplayOperatorIdentifier: details.enabledBookingHistoryDisplayOperatorIdentifier,
      enabledBookingHistoryUseOptimizedWideDisplay: details.enabledBookingHistoryUseOptimizedWideDisplay,
      enabledBookingManagerDashboardNoteFunctionality: details.enabledBookingManagerDashboardNoteFunctionality,
    }),
    [details]
  );
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, dirtyFields, isSubmitted, errors },
  } = useForm<FormData>({ 
    defaultValues,
    resolver: zodResolver(formSchema),
  });
  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as DisplayOptions;
    const {
      bookingMaxNumberOfStops,
      enabledBookingHistoryDisplayOperatorIdentifier,
      enabledBookingHistoryUseOptimizedWideDisplay,
      enabledBookingManagerDashboardNoteFunctionality,
    } = { ...dirtyValues };

    setActiveModal(null);
    updateDisplayOptions({
      booking_max_number_of_stops: bookingMaxNumberOfStops,
      enabled_booking_history_display_operator_identifier: enabledBookingHistoryDisplayOperatorIdentifier,
      enabled_booking_history_use_optimized_wide_display: enabledBookingHistoryUseOptimizedWideDisplay,
      enabled_booking_manager_dashboard_note_functionality: enabledBookingManagerDashboardNoteFunctionality,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Display Options");
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="ml-auto flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Display Options
              </Typography>
              <Typography className="text-neutral-dark-gray">Display the options that you wanted to see.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
          
          <ConfigDetail title="Booking Sheet" />
          <ConfigDetail
            title="Maximum Number of Stops"
            description="Configure the maximum number of additional stops a passenger can add to a booking"
            indentPadding="pl-2"
          >
            <div className="flex flex-row items-center gap-x-2">
              <TextInput
                type="number"
                className="w-[60px]"
                step="any"
                hasError={!!errors.bookingMaxNumberOfStops}
                {...register("bookingMaxNumberOfStops")}
              />
              <ErrorMessage errors={errors} name="bookingMaxNumberOfStops" />
            </div>
          </ConfigDetail>

          <ConfigDetail title="Operator Identifier" />
          <ConfigDetail
            title="Display 'Operator Identifier' column"
            description="Allows adding and display of an operator only identifier for each passenger account."
            indentPadding="pl-2"
          >
            <Controller
              name="enabledBookingHistoryDisplayOperatorIdentifier"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>

          <ConfigDetail title="General Booking Display" />
          <ConfigDetail
            title="Booking Manager Display"
            description="Customize the view density of all booking entries."
            indentPadding="pl-2"
          >
            <Controller
              name="enabledBookingHistoryUseOptimizedWideDisplay"
              control={control}
              render={({ field }) => (
                <div className="group flex flex-col gap-4">
                  <RadioInput
                    label="Default"
                    value="compact"
                    name="enabledBookingHistoryUseOptimizedWideDisplay"
                    checked={!field.value}
                    onChange={() => field.onChange(false)}
                  />
                  <BookingViewDefault className={clsx(field.value?.toString() === "default" && "ring-1 ring-primary-dark")} />
                  <Typography className="text-neutral-dark-gray">
                    Standard view with enhanced row height for records spanning up to two lines, ensuring clear readability and detail
                    visibility.
                  </Typography>
                </div>
              )}
            />
            <Controller
              name="enabledBookingHistoryUseOptimizedWideDisplay"
              control={control}
              render={({ field }) => (
                <div className="group mt-8 flex flex-col gap-4">
                  <RadioInput
                    label="Compact"
                    value="compact"
                    name="enabledBookingHistoryUseOptimizedWideDisplay"
                    checked={field.value}
                    onChange={() => field.onChange(true)}
                  />
                  <BookingViewCompact className={clsx(field.value?.toString() === "compact" && "ring-1 ring-primary-dark")} />
                  <Typography className="text-neutral-dark-gray">
                    Compressed layout for a more streamlined view, aligning all records in a single line for a concise, space-efficient
                    overview.
                  </Typography>
                </div>
              )}
            />
          </ConfigDetail>

          <ConfigDetail title="Dashboard Notes" />
          <ConfigDetail
            title="Enable 'Dashboard Note' field on booking and display on booking dashboard"
            description="Allows adding a unique note to each booking and displaying on the booking admin view."
            indentPadding="pl-2"
          >
            <Controller
              name="enabledBookingManagerDashboardNoteFunctionality"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
        </div>
      </Panel>
    </form>
  );
};
