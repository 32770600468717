import { DPCalendar as CalendarType, useCalendars } from "@rehookify/datepicker";
import { clsx } from "@/utils";
import { Typography } from "../Typography/Typography";
import { Day } from "./Day";
import { useDateRangePicker } from "./context";
import "./style.css";

export interface DatePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  prevButton?: JSX.Element;
  nextButton?: JSX.Element;
  calendar: CalendarType;
}

export const DatePicker = ({ prevButton, nextButton, calendar, className, ...props }: DatePickerProps) => {
  const { dpState } = useDateRangePicker();
  const { weekDays } = useCalendars(dpState);
  const { days, month, year } = calendar;

  return (
    <div className={clsx("max-w-full max-md:w-[375px]", className)} {...props}>
      <div className="flex h-10 w-full items-center border-b-neutral-mid-gray text-center md:w-[250px] md:border-b xl:mb-2 xl:h-12 xl:w-[300px]">
        {prevButton || <div className="h-8 w-8 max-md:hidden" />}
        <Typography variant="action" className="flex-1 !text-base max-md:px-5 max-md:pt-2 max-md:text-left">
          {month} {year}
        </Typography>
        {nextButton || <div className="h-8 w-8 max-md:hidden" />}
      </div>
      <div className="p-2">
        <div className="mb-2 grid h-8 grid-cols-7 items-center gap-y-2">
          {weekDays.map((d) => (
            <Typography key={d} className="text-center ">
              {d}
            </Typography>
          ))}
        </div>
        <div className="grid grid-cols-7 justify-center gap-y-2">
          {days.map((d) => (
            <Day key={d.$date.toString()} day={d} />
          ))}
        </div>
      </div>
    </div>
  );
};
