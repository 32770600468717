import { useNavigate } from "react-router-dom";
import { Button, Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setFixedPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { fixedPricingPageStateSelector } from "@/redux/slices/pricing/selectors";
import { AddNoteForm } from "../AddNoteForm";
import { RangeDelete } from "./contents/RangeDelete";
import { modals } from "./fixtures";

interface FixedPricingEditorModalsProps {
  selectedRange: number | null;
  selectedRangeNote: string;
  onDeleteRow: () => void;
  onDeleteFixedPricing: () => void;
  onAddNote: (note: string) => void;
}

export const FixedPricingEditorModals = ({
  selectedRange,
  selectedRangeNote,
  onDeleteRow,
  onDeleteFixedPricing,
  onAddNote,
}: FixedPricingEditorModalsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { action } = useAppSelector(fixedPricingPageStateSelector);

  const onClose = () => dispatch(setFixedPricingAction(undefined));

  return (
    <>
      <Modal
        className="w-[85vw] md:w-[510px] lg:w-[510px]"
        open={action === "exit"}
        title={modals["exit"].title}
        description={modals["exit"].description}
        onClose={onClose}
        renderButtons={() => (
          <>
            <Button variant="secondary" size="md" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                onClose();
                navigate("../");
              }}
            >
              Discard Changes
            </Button>
          </>
        )}
      />
      {selectedRange !== null && (
        <Modal className="w-[85vw] md:w-[510px] lg:w-[510px]" open={action === "deleteRange"} onClose={onClose}>
          <RangeDelete range={selectedRange} onDeleteRow={onDeleteRow} onClose={onClose} />
        </Modal>
      )}
      <Modal
        className="w-[85vw] md:w-[510px] lg:w-[510px]"
        open={action === "deleteFixedPricing"}
        title={modals["deleteFixedPricing"].title}
        description={modals["deleteFixedPricing"].description}
        onClose={onClose}
        renderButtons={() => (
          <>
            <Button variant="secondary" size="md" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" size="md" onClick={onDeleteFixedPricing} className="bg-danger hover:bg-danger-dark">
              Delete Fixed Pricing
            </Button>
          </>
        )}
      />
      <Modal className="w-[85vw] md:w-[510px] lg:w-[510px]" open={action === "addNote"} onClose={onClose}>
        <AddNoteForm selectedRange={selectedRange} selectedRangeNote={selectedRangeNote} onAddNote={onAddNote} onClose={onClose} />
      </Modal>
    </>
  );
};
