import { useFormContext } from "react-hook-form";
import { CheckboxInput, ErrorMessage } from "@/components/atoms";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";

export const RepeatYearlyField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <CheckboxInput label="Repeat Yearly" {...register("repeatYearly")} />
      <ErrorMessage errors={errors} name="repeatYearly" />
    </div>
  );
};
