import { Controller, useFormContext } from "react-hook-form";
import { DateInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";

export const DateStartField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="action">Date Start</Typography>
      <Controller
        name="dateStart"
        control={control}
        render={({ field }) => (
          <div className="flex flex-row items-center gap-x-1">
            <DateInput
              className="w-full h-[46px]"
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              placeholderText="Select a date"
              hasError={!!errors.dateStart}
            />
            <Typography variant="action" className="text-neutral-dark-gray">-</Typography>
          </div>
        )}
      />
    </div>
  );
};
