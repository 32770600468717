import { ComponentProps } from "react";
import { Popup as MapboxPopup } from "react-map-gl";
import { IconButton } from "@/components/atoms";
import { BookingMarker } from "../../types";

export interface PopupProps extends Omit<ComponentProps<typeof MapboxPopup>, "longitude" | "latitude"> {
  type: BookingMarker["type"];
  location: [number, number];
  onClose: () => void;
}

const styles =
  "[&>.mapboxgl-popup-content]:p-2 [&>.mapboxgl-popup-content]:pr-4[&>.mapboxgl-popup-content]:rounded-lg  [&>.mapboxgl-popup-content]:text-neutral-black [&>.mapboxgl-popup-content]:border  [&>.mapboxgl-popup-content]:shadow-md [&>.mapboxgl-popup-tip]:-translate-y-[1px] [&>.mapboxgl-popup-tip]:border-30";

export const Popup = ({ location, onClose, children, ...props }: PopupProps) => {
  return (
    <MapboxPopup
      className={styles}
      longitude={location[0]}
      latitude={location[1]}
      anchor="left"
      onClose={onClose}
      closeOnClick={false}
      closeButton={false}
      {...props}
    >
      <IconButton variant="custom" iconName="close" isCustomIcon onClick={onClose} className="absolute right-1 top-2  text-neutral-black" />
      {children}
    </MapboxPopup>
  );
};

Popup.displayName = "MapboxPopup";
