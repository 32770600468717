import { Helmet } from "react-helmet-async";
import { useSiteConfig } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";

export interface HeadProps {
  title?: string;
  favicon?: string;
}

export const Head = ({ title: headTitle, ...props }: HeadProps) => {
  const activeOperator = useAppSelector(activeOperatorSelector);
  const config = useSiteConfig();

  const favicon = props.favicon || activeOperator?.images.emblem || config.images.emblem || "/assets/favicon.svg";
  const title = headTitle || activeOperator?.name || config.name;

  return (
    <Helmet title={title || activeOperator?.name || config.name}>
      {favicon && <link rel="icon" type="image/png" href={favicon} sizes="16x16" />}
    </Helmet>
  );
};
