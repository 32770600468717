import NetworkAuMap from "@/assets/images/network/au-map.svg";
import { Icon, Modal, Typography } from "@/components/atoms";

interface PrivateNetworkHelpModalProps {
  open: boolean;
  onClose: () => void;
}

export const PrivateNetworkHelpModal = ({ open, onClose }: PrivateNetworkHelpModalProps) => {
  return (
    <Modal open={open} onClose={onClose} className="w-[85vw] md:w-[550px] lg:w-[550px]">
      <div className="flex flex-col items-center">
        <button type="button" className="ml-auto rounded-md text-black" onClick={onClose}>
          <span className="sr-only">Close</span>
          <Icon name="close" isCustom size="lg" aria-hidden="true" />
        </button>
        <img src={NetworkAuMap} className="mt-3.5 h-[164px] w-[180px]" />
        <div className="mb-[8.75px] mt-[30px] flex flex-col items-center justify-center gap-4 text-center">
          <Typography variant="h3">What is a Private Network?</Typography>
          <Typography variant="paragraph">
            The private network is your own, curated list of operators that you work with regularly. You can think of these operators as those trusted drivers you know are of the quality and standard you want to represent your own company. We recommend this collection of drivers to become your first point of call when you need to offload work.
          </Typography>
        </div>
      </div>
    </Modal>
  );
};
