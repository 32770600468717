import { Typography } from "@/components/atoms";

export const PaymentStatusTooltip = () => {
  return (
    <ul className="m-0 list-disc space-y-1 px-3">
      <li>
        <Typography>Invoiced</Typography>
      </li>
      <li>
        <Typography>Paid</Typography>
      </li>
      <li>
        <Typography>Partially Paid</Typography>
      </li>
      <li>
        <Typography>Unpaid</Typography>
      </li>
      <li>
        <Typography>Pre-paid</Typography>
      </li>
      <li>
        <Typography>Partially Pre-paid</Typography>
      </li>
      <li>
        <Typography>Refunded</Typography>
      </li>
      <li>
        <Typography>No Charge</Typography>
      </li>
    </ul>
  );
};
