import { useForm } from "react-hook-form";
import { Button, Icon, Loading, RadioInput, TextInput, Typography } from "@/components/atoms";
import { useUserAccountsContext } from "@/contexts/userAccounts";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useInviteUserMutation } from "@/redux/apis/userAccount/userAccountApi";
import { UserAccountRole } from "@/redux/slices/userAccount/types";
import { addToast } from "@/utils";

type FormData = {
  email: string;
  role: UserAccountRole;
};

export const UserAccountsInviteForm = () => {
  const { setStep } = useUserAccountsContext();
  const [sendInvite, { isLoading }] = useInviteUserMutation();
  const { register, handleSubmit, setValue, watch } = useForm<FormData>({
    defaultValues: {
      role: "Call Center",
    },
  });

  const role = watch("role");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("role", e.target.value as UserAccountRole);
  };

  const onSubmit = (data: FormData) => {
    sendInvite(data)
      .unwrap()
      .then(() => {
        addToast("success", `Successfully sent invite to “${data.email}”`);
        setStep(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const renderHeader = () => (
    <div className="flex flex-col">
    <Icon name="close" isCustom className="flex cursor-pointer self-end" onClick={() => setStep(undefined)} />
      <div className="flex flex-col text-center gap-y-2">
        <Typography variant="h1">
          Invite Team Member
        </Typography>
        <Typography>Let your team member join and have access on the RideMinder Web app.</Typography>
      </div>
    </div>
  );

  return (
    <div className="relative">
      {isLoading && <Loading />}
      {renderHeader()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput className="mt-5" placeholder="Enter Email" {...register("email", { required: true })} required />
        <div className="mt-5 flex flex-col gap-5">
          <RadioInput
            label="Call Center"
            description="Can access customer accounts and edit bookings"
            value="Call Center"
            onChange={handleChange}
            checked={role === "Call Center"}
          />
          <RadioInput
            label="Dispatcher"
            description="Can access all Call Center functionality + Assign drivers and vehicles"
            value="Dispatcher"
            onChange={handleChange}
            checked={role === "Dispatcher"}
          />
          <RadioInput
            label="Manager"
            description="Access all non-config information"
            value="Manager"
            onChange={handleChange}
            checked={role === "Manager"}
          />
          <RadioInput
            label="Admin"
            description="Access all config related information"
            value="Admin"
            onChange={handleChange}
            checked={role === "Admin"}
          />
        </div>
        <Button variant="primary" type="submit" size="lg" className="mt-5 w-full">
          Invite Team Member
        </Button>
      </form>
    </div>
  );
};