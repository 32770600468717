import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryOffloadMaxAgeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="paragraph" className="flex items-center gap-2">
        Maximum Vehicle Age
      </Typography>
      <AddOnTextInput
        className="[&>div]:w-[60px]"
        type="number"
        placeholder="0"
        endAddOn="Year/s"
        step="1"
        hasError={!!errors.offloadMaxVehicleAge}
        {...register("offloadMaxVehicleAge", {
          valueAsNumber: true,
        })}
      />
      <ErrorMessage errors={errors} name="offloadMaxVehicleAge" />
    </div>
  );
};
