import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton, BooleanDisplay } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetDisplayOptionsQuery } from "@/redux/apis/config/admin/displayOptions";
import { useAppSelector } from "@/redux/hooks";
import { displayOptionsSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { DisplayOptionsEdit } from "./DisplayOptionsEdit";

export const DisplayOptions = () => {
  const { isFetching } = useGetDisplayOptionsQuery();
  const { errors, details } = useAppSelector(displayOptionsSelector);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <Button className="ml-auto animate-enter" startIcon="Edit2" onClick={() => navigate("edit")}>
            Edit
          </Button>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Display Options
            </Typography>
            <Typography className="text-neutral-dark-gray">Display the options that you wanted to see.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {renderErrors(errors)}
        <ConfigDetail title="Booking Sheet" />
        <ConfigDetail
          title="Maximum Number of Stops"
          description="Configure the maximum number of additional stops a passenger can add to a booking"
          indentPadding="pl-2"
        >
          <Typography variant="paragraph">{details.bookingMaxNumberOfStops}</Typography>
        </ConfigDetail>

        <ConfigDetail title="Operator Identifier" />
        <ConfigDetail
          title="Display 'Operator Identifier' column"
          description="Allows adding and display of an operator only identifier for each passenger account."
          indentPadding="pl-2"
        >
          <BooleanDisplay status={details.enabledBookingHistoryDisplayOperatorIdentifier}>
            <Typography variant="paragraph">{details.enabledBookingHistoryDisplayOperatorIdentifier ? "Yes" : "No"}</Typography>
          </BooleanDisplay>
        </ConfigDetail>

        <ConfigDetail title="General Booking Display" />
        <ConfigDetail title="Booking Manager Display" description="Customize the view density of all booking entries." indentPadding="pl-2">
          <Typography>
            {!details.enabledBookingHistoryUseOptimizedWideDisplay
              ? "Default - Standard view with enhanced row height for records spanning up to two lines, ensuring clear readability and detail visibility."
              : "Compact View - Compressed layout for a more streamlined view, aligning all records in a single line for a concise, space-efficient overview."}
          </Typography>
        </ConfigDetail>

        <ConfigDetail title="Dashboard Notes" />
        <ConfigDetail
          title="Enable 'Dashboard Note' field on booking and display on booking dashboard"
          description="Allows adding a unique note to each booking and displaying on the booking admin view."
          indentPadding="pl-2"
        >
          <BooleanDisplay status={details.enabledBookingManagerDashboardNoteFunctionality}>
            <Typography variant="paragraph">{details.enabledBookingManagerDashboardNoteFunctionality ? "Yes" : "No"}</Typography>
          </BooleanDisplay>
        </ConfigDetail>
      </Panel>
    </>
  );
};

DisplayOptions.Edit = DisplayOptionsEdit;
