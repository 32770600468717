import { GetTimeOfDayMultipliersResponse, GetTimeOfDayRawMultipliersResponse } from "./types";

export const transformTimeOfDayMultipliers = (res: GetTimeOfDayRawMultipliersResponse) => {
  const {
    _embedded: { time_of_day_multiplier: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetTimeOfDayMultipliersResponse = {
    data: {
      items: items.map((i) => ({
        id: i.uuid,
        type: i.type,
        name: i.name,
        active: i.is_active,
        isSpecialEvent: i.is_special_event,
        isRecurring: i.is_recurring,
        description: i.description,
        multiplier: i.multiplier,
        startTime: i.start_time,
        endTime: i.end_time,
        order: i.order_score,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};