import { DataTableRowProps } from "../types";
import { DataTableCell } from "./DataTableCell";

export const DataTableRow = <T extends object>({ row, children, ...props }: DataTableRowProps<T>) => {
  return (
    <>
      <tr {...props}>
        {row.getVisibleCells().map((cell) => (
          <DataTableCell key={cell.id} cell={cell} className="px-4 py-2 align-middle text-base font-medium text-neutral-black max-lg:p-2" />
        ))}
      </tr>
      {children && (
        <tr className="subcomponent border-none">
          <td colSpan={row.getVisibleCells().length} className="p-0">
            {children}
          </td>
        </tr>
      )}
    </>
  );
};
