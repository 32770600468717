import { DailyHireInterval } from "@/redux/slices/pricing/types";
import { getCurrencySymbol } from "@/utils";
import { GetDailyHireMinimumRawResponse, GetDailyHireMinimumResponse, GetDailyHireRawResponse, GetDailyHireResponse } from "./types";

export const transformDailyHire = (res: GetDailyHireRawResponse) => {
  const { _links, currency, intervals: rawIntervals, base_interval } = res;

  const intervals: DailyHireInterval[] = rawIntervals.map((i) => ({
    minimumRange: i.minimum_range,
    rate: i.rate / 100,
  }));

  const currencySymbol = getCurrencySymbol(currency) ?? "";

  const response: GetDailyHireResponse = {
    data: {
      pricing: {
        currency,
        currencySymbol,
        basePrice: {
          minimumRange: base_interval.minimum_range,
          rate: base_interval.rate / 100,
        },
        intervals,
      },
    },
    links: _links,
  };

  return response;
};

export const transformDailyHireMinimum = (res: GetDailyHireMinimumRawResponse) => {
  const { _links, minimum_booking_period_minutes } = res;

  const response: GetDailyHireMinimumResponse = {
    data: {
      minimumBookingPeriodMinutes: minimum_booking_period_minutes / 1440,
    },
    links: _links,
  };

  return response;
};
