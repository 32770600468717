import { RatingFilter, RatingFilterDrawer } from "./filters/RatingFilter";
import { StateFilter, StateFilterDrawer } from "./filters/StateFilter";
import { StatusFilter, StatusFilterDrawer } from "./filters/StatusFilter";
import { TierFilter, TierFilterDrawer } from "./filters/TierFilter";

export const getFilterPopover = (key: string) => {
  switch (key) {
    case "rating":
      return <RatingFilter key={key} />;
    case "state":
      return <StateFilter key={key} />;
    case "tier":
      return <TierFilter key={key} />;
    case "status":
      return <StatusFilter key={key} />;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};

export const getFilterDrawer = (key: string, asJSX = false) => {
  switch (key) {
    case "rating":
      return asJSX ? <RatingFilterDrawer key={key} /> : RatingFilterDrawer;
    case "state":
      return asJSX ? <StateFilterDrawer key={key} /> : StateFilterDrawer;
    case "tier":
      return asJSX ? <TierFilterDrawer key={key} /> : TierFilterDrawer;
    case "status":
      return asJSX ? <StatusFilterDrawer key={key} /> : StatusFilterDrawer;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};
