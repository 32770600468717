import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import {
  AddOnTextInput,
  Button,
  Icon,
  Loading,
  Panel,
  PhoneNumberInput,
  TextArea,
  TextInput,
  Typography,
  validatePhoneNumberInput,
  Modal,
  Skeleton,
} from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetBusinessDetailsQuery, useUpdateBusinessDetailsMutation } from "@/redux/apis/config/admin/businessDetails";
import { useAppSelector } from "@/redux/hooks";
import { businessDetailsSelector } from "@/redux/slices/admin/selectors";
import { BusinessDetails } from "@/redux/slices/admin/types";
import { addToast } from "@/utils/addToast";

const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save Business Details",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

type FormData = BusinessDetails;

export const BusinessDetailsEdit = () => {
  const { isFetching } = useGetBusinessDetailsQuery();
  const { details } = useAppSelector(businessDetailsSelector);
  const [updateBusinessDetails, { isLoading }] = useUpdateBusinessDetailsMutation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, dirtyFields, isSubmitted },
  } = useForm<FormData>({ defaultValues: details });
  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  useEffect(() => {
    reset(details);
  }, [details, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as BusinessDetails;
    const {
      businessName,
      businessTagline,
      businessAddress,
      businessRegistrationType,
      businessRegistrationId,
      businessPhoneNumberLocal,
      businessPhoneNumberInternational,
      businessSupportEmail,
    } = { ...dirtyValues };
    setActiveModal(null);
    updateBusinessDetails({
      business_name: businessName,
      business_tagline: businessTagline,
      business_address: businessAddress,
      business_registration_type: businessRegistrationType,
      business_registration_id: businessRegistrationId,
      business_phone_number_local: businessPhoneNumberLocal,
      business_phone_number_international: validatePhoneNumberInput(businessPhoneNumberInternational),
      business_support_email: businessSupportEmail,
    })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Updated Business Details`);
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="ml-auto flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Business Details
              </Typography>
              <Typography className="text-neutral-dark-gray">Update your Business details here.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
          <ConfigDetail title="Business Name" description="This is the name customers will see.">
            <TextInput className="w-3/5" maxLength={45} {...register("businessName")} />
          </ConfigDetail>
          <ConfigDetail title="Business Tagline" description="A quick snapshot about your business.">
            <TextInput className="w-3/5" maxLength={32} {...register("businessTagline")} />
          </ConfigDetail>
          <ConfigDetail title="Business Address" description="Where is you business located.">
            <TextArea className="w-3/5" maxLength={128} {...register("businessAddress")} />
          </ConfigDetail>
          <ConfigDetail title="Business Registration" description="State your Business Registration Type and Registration ID.">
            <AddOnTextInput className="mb-4 w-3/5" startAddOn="Type" maxLength={4} {...register("businessRegistrationType")} />
            <AddOnTextInput className="w-3/5" startAddOn="ID" maxLength={16} {...register("businessRegistrationId")} />
          </ConfigDetail>
          <ConfigDetail title="Business Phone Number" description="Local and International contact number of you Business.">
            <div className="mb-4 w-3/5">
              <Typography variant="paragraph" className="leading-8">
                Local:
              </Typography>
              <div className="flex w-full">
                <div className="flex items-center rounded-l-lg border border-r-0 border-neutral-mid-gray bg-neutral-gray px-[18.5px]">
                  <Icon name="Call" size="md" className="text-neutral-dark-gray" />
                </div>
                <input
                  className="h-12 w-full rounded-r-lg border border-neutral-mid-gray px-4 text-base text-neutral-black placeholder-neutral-dark-gray focus:border-primary focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-surface-gray"
                  type="tel"
                  maxLength={15}
                  pattern="[\+\d\s]{0,15}"
                  required
                  {...register("businessPhoneNumberLocal")}
                />
              </div>
            </div>
            <div className="w-3/5">
              <PhoneNumberInput control={control} name="businessPhoneNumberInternational" label="International:" />
            </div>
          </ConfigDetail>
          <ConfigDetail
            title="Support Email Address"
            description="AGeneral support email address to use in email footers, invoices, private network notifications and more."
          >
            <TextInput className="w-3/5" maxLength={100} {...register("businessSupportEmail")} />
          </ConfigDetail>
        </div>
      </Panel>
    </form>
  );
};
