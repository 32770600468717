import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { Icon, IconButton, Typography, SidePanel } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import {
  useGetNotificationsQuery,
  useLazyGetNotificationsQuery,
  useMarkReadNotificationsMutation,
} from "@/redux/apis/notification/notificationApi";
import { useAppSelector } from "@/redux/hooks";
import { earlierNotificationsSelector, newNotificationsSelector, notificationsPageSelector } from "@/redux/slices/notification/selectors";
import { addToast } from "@/utils";
import { NotificationItem, NotificationsSkeleton } from "./";

export interface NotificationsSidePanelProps {
  open: boolean;
  onClose: () => void;
}

export const NotificationsSidePanel = ({ open, onClose }: NotificationsSidePanelProps) => {
  const [scroller, setScroller] = useState<HTMLDivElement | null>(null);
  const { count, current } = useAppSelector(notificationsPageSelector);
  const { isFetching } = useGetNotificationsQuery("1");
  const [getNotifications, { isFetching: isFetchingLazy }] = useLazyGetNotificationsQuery();
  const [markReadNotifications] = useMarkReadNotificationsMutation();
  const newNotifications = useAppSelector(newNotificationsSelector);
  const earlierNotifications = useAppSelector(earlierNotificationsSelector);
  const [hasOpened, setHasOpened] = useState(false);

  useInfiniteScroll({
    scroller,
    hasMore: current < count,
    pageStart: current,
    loadMore: getNotifications,
    isFetching: isFetching || isFetchingLazy,
  });

  useEffect(() => {
    if (open) setHasOpened(true);
  }, [open]);

  useEffect(() => {
    if (!open && hasOpened) {
      markReadNotifications()
        .unwrap()
        .then(() => getNotifications("1"))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  }, [open, hasOpened, markReadNotifications, getNotifications]);

  return (
    <SidePanel open={open} onClose={onClose} focused ref={(el) => setScroller(el)}>
      <div className="flex items-center justify-between p-5">
        <div className="flex flex-row items-center gap-2">
          <div className="relative">
            <Icon name="Notification" variant="Bold" size="lg" />
            {!isEmpty(newNotifications) && (
              <svg className="absolute bottom-3.5 left-3.5 h-3 w-3 text-danger" fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} />
              </svg>
            )}
          </div>
          <Typography variant="title">Notifications</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <div>
        {isFetching ? (
          <NotificationsSkeleton variant="full" />
        ) : (
          <div className="flex flex-col">
            {isEmpty(newNotifications) && isEmpty(earlierNotifications) ? (
              <EmptyState title="No Notifications" description="Your notifications are currently empty" />
            ) : (
              <>
                {!isEmpty(newNotifications) && (
                  <div>
                    <div className="px-5 pb-2.5 pt-5">
                      <Typography variant="title">New</Typography>
                    </div>
                    {newNotifications.map((notification) => (
                      <NotificationItem key={notification.id} notification={notification} onClose={onClose} isNew />
                    ))}
                  </div>
                )}
                {!isEmpty(earlierNotifications) && (
                  <div>
                    <div className="px-5 pb-2.5 pt-5">
                      <Typography variant="title">Earlier</Typography>
                    </div>
                    {earlierNotifications.map((notification) => (
                      <NotificationItem key={notification.id} notification={notification} onClose={onClose} />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {isFetchingLazy && <NotificationsSkeleton variant="item" />}
      </div>
    </SidePanel>
  );
};
