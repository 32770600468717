import { Container, Typography } from "@/components/atoms";

export interface ErrorPanelProps {
  messages: string[];
}

export const ErrorPanel = ({ messages }: ErrorPanelProps) => {
  return (
    <Container>
      <div className="flex min-h-full flex-col py-40">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              {messages.map((m, i) => (
                <Typography key={i} variant="h2">
                  {m}
                </Typography>
              ))}
              <p className="mt-2 text-base text-gray-500">An error has occured.</p>
            </div>
          </div>
        </main>
      </div>
    </Container>
  );
};
