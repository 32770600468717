import { differenceInDays, format } from "date-fns";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
import { Icon, Typography } from "@/components/atoms";

export const renderCreatedDate = (date: string) => {
  const createdDate = new Date(date);
  return (
    <Typography variant="muted" className="mt-2">
      {differenceInDays(createdDate, new Date()) < -5
        ? format(new Date(createdDate), "dd MMMM yyyy")
        : distanceInWordsToNow(createdDate, { addSuffix: true })}
    </Typography>
  );
};

export const renderNotificationIcon = (category: string, action: string) => {
  switch (category) {
    case "error":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-danger-light">
          <Icon name="InfoCircle" size="sm" className="text-danger" />
        </div>
      );
    case "warning":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-warning-light">
          <Icon name="Danger" size="sm" className="text-warning" />
        </div>
      );
    case "system":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
          <Icon name="rm-logo-emblem" size="sm" className="text-warning" isCustom />
        </div>
      );
    case "job":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
          <Icon name="ArchiveBook" size="sm" className="text-primary" />
        </div>
      );
    case "vehicle":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
          <Icon name="Car" size="sm" className="text-primary" />
        </div>
      );
    case "driver":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
          <Icon name="UserSquare" size="sm" className="text-primary" />
        </div>
      );
    case "client":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
          <Icon name="Profile2User" size="sm" className="text-primary" />
        </div>
      );
    case "company":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
          <Icon name="Buildings" size="sm" className="text-primary" />
        </div>
      );
    case "account":
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
          <Icon name="User" size="sm" className="text-primary" />
        </div>
      );
    case "provider":
      switch (action) {
        case "provider.accreditation":
          return (
            <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
              <Icon name="LikeTag" size="sm" className="text-primary" />
            </div>
          );
        case "provider.private-network":
          return (
            <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
              <Icon name="Global" size="sm" className="text-primary" />
            </div>
          );
        case "provider.reporting.trip":
          return (
            <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
              <Icon name="DocumentText" size="sm" className="text-primary" />
            </div>
          );
        case "provider.rating":
          return (
            <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
              <Icon name="Star1" size="sm" className="text-primary" />
            </div>
          );
        case "provider.settings":
          return (
            <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-primary-light">
              <Icon name="Setting2" size="sm" className="text-primary" />
            </div>
          );
        default:
          return (
            <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-info-light">
              <Icon name="InfoCircle" size="sm" className="rotate-180 text-info" />
            </div>
          );
      }
    default:
      return (
        <div className="relative flex h-[42px] w-[42px] items-center justify-center rounded-md bg-info-light">
          <Icon name="InfoCircle" size="sm" className="rotate-180 text-info" />
        </div>
      );
  }
};

export const renderNotificationItemSkeleton = () => {
  return (
    <div className="px-5">
      <div className="flex flex-row gap-4 pb-4 pt-6">
        <div className="flex">
          <div className="relative flex h-[42px] w-[42px] rounded-md bg-gray-200" />
        </div>
        <div className="flex w-full flex-col">
          <div className="flex flex-row items-center gap-1.5">
            <div className="mr-3 h-4 w-44 rounded-2xl bg-gray-200" />
          </div>
          <div className="mt-3 flex flex-col gap-2">
            <div className="h-4 rounded-2xl bg-gray-100" />
            <div className="h-4 rounded-2xl bg-gray-100" />
            <div className="h-4 rounded-2xl bg-gray-100" />
          </div>
          <div className="mt-2 h-4 w-32 rounded-2xl bg-gray-200" />
        </div>
      </div>
      <div className="border border-gray-200 px-2" />
    </div>
  );
};
