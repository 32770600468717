import { useDebouncedCallback } from "@react-hookz/web";
import { forwardRef, useEffect, useState } from "react";
import { clsx } from "@/utils";
import { IconButton } from "../Button/IconButton";
import { Icon } from "../Icon/Icon";
import { Spinner } from "../Spinner/Spinner";
import { TextInput, TextInputProps } from "../TextInput/TextInput";

export interface SearchInputProps extends TextInputProps {
  isLoading?: boolean;
  search?: string;
  onSearch: (value?: string) => void;
  onClear?: () => void;
  debounceTime?: number;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ isLoading = false, search, onSearch, onClear, placeholder = "Search", className, debounceTime = 300, ...props }, ref) => {
    const [inputValue, setInputValue] = useState(search || "");
    const debouncedOnSearch = useDebouncedCallback(onSearch, [], debounceTime);

    // Skips/ignores the first instance of empty inputValue in useEffect
    const [isFirstSearch, setIsFirstSearch] = useState(true);

    useEffect(() => {
      if (isFirstSearch) setIsFirstSearch(false);
      else {
        if (inputValue.trim() === "") {
          debouncedOnSearch(undefined);
          setIsFirstSearch(true);
        } else debouncedOnSearch(inputValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    return (
      <div className={clsx("relative inline-block", className)}>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          {isLoading ? (
            <Spinner className="text-neutral-dark-gray" />
          ) : (
            <Icon name="SearchNormal1" className="text-neutral-dark-gray" size="sm" />
          )}
        </div>
        <TextInput
          value={inputValue || ""}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={placeholder}
          className="m-0 pl-9 pr-3 lg:min-w-[320px]"
          ref={ref}
          {...props}
        />
        {(inputValue || onClear) && (
          <IconButton
            iconName="close"
            isCustomIcon
            className="text-neural-black absolute right-2 top-[50%] h-[24px] w-[24px] -translate-y-[50%] rounded-full border-none bg-transparent bg-white p-0 hover:bg-neutral-mid-gray"
            onClick={() => {
              setInputValue("");
              onClear?.();
            }}
          />
        )}
      </div>
    );
  }
);

SearchInput.displayName = "SearchInput";
