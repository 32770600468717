import { useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { usePagination, useProfile, useSorting } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { payoutRecordsSelector } from "@/redux/slices/payment/selectors";
import { PayoutRecordsItem } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generatePayoutColumns, generatePayoutData } from "./helpers";

interface PayoutTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: PayoutRecordsItem[];
}

export const PayoutTable = ({ items, className, ...props }: PayoutTableProps) => {
  const { dateFormat, timeFormat } = useProfile();
  const { sorting, setSorting } = useSorting();

  const data = useMemo(
    () => generatePayoutData(items, dateFormat, timeFormat),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generatePayoutColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable columns={columns} data={data} sorting={sorting} setSorting={setSorting} {...props} />
    </div>
  );
};

const PayoutTablePagination = () => {
  const {
    page: { current, count, total, size },
  } = useAppSelector(payoutRecordsSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

PayoutTable.Pagination = PayoutTablePagination;
