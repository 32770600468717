import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, Panel, PhoneNumberDetail, Typography, Skeleton, BooleanDisplay } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetSMSVoiceNotificationsQuery } from "@/redux/apis/config/admin/smsVoiceNotifications";
import { useAppSelector } from "@/redux/hooks";
import { smsVoiceNotificationsSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { SMSVoiceNotificationsEdit } from "./SMSVoiceNotificationsEdit";

export const SMSVoiceNotifications = () => {
  const { isFetching } = useGetSMSVoiceNotificationsQuery();
  const { errors, details } = useAppSelector(smsVoiceNotificationsSelector);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <Button className="ml-auto animate-enter" startIcon="Edit2" onClick={() => navigate("edit")}>
            Edit
          </Button>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              SMS / Voice Notifications
            </Typography>
            <Typography className="text-neutral-dark-gray">Set your SMS / Voice Notification settings.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {renderErrors(errors)}
        <ConfigDetail
          title="Unallocated & close to pickup notifications enabled"
          description="Trigger an automated call when a job is unallocated and close to pickup."
        >
          <BooleanDisplay status={details.enabledUnallocatedClosePickupNotifications} />
        </ConfigDetail>
        <ConfigDetail
          title="Phone Number to call when unallocated & close to pickup"
          description="Phone number to use."
          indentPadding="pl-2"
        >
          <PhoneNumberDetail number={details.unallocatedClosePickupPhoneNumber} />
        </ConfigDetail>

        <ConfigDetail
          title="Driver not on way notifications enabled"
          description="Trigger an automated call when driver is not on way to job."
        >
          <BooleanDisplay status={details.enabledDriverNotOnWayNotifications} />
        </ConfigDetail>
        <ConfigDetail title="Phone number to call when driver not on way to pickup" description="Phone number to use." indentPadding="pl-2">
          <PhoneNumberDetail number={details.driverNotOnWayPhoneNumber} />
        </ConfigDetail>
        <ConfigDetail
          title="Email address to email when driver not on way to pickup"
          description="Additional email address which contains extra driver details."
          indentPadding="pl-2"
        >
          <Typography variant="paragraph" className="leading-8">
            {details.driverNotOnWayEmailAddress}
          </Typography>
        </ConfigDetail>

        <ConfigDetail
          title="SMS Customer Reminder & OpChange Notifications enabled"
          description="Send SMS reminders for upcoming jobs and job changes to customers."
        >
          <BooleanDisplay status={details.enabledSmsCustomerReminderAndOpchangeNotifications} />
        </ConfigDetail>
      </Panel>
    </>
  );
};

SMSVoiceNotifications.Edit = SMSVoiceNotificationsEdit;
