import { useGetAssetsQuery } from "@/redux/apis/config/asset";
import { Icon, Panel, Skeleton, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { useMemo } from "react";
import { operatorBrandingSelector } from "@/redux/slices/brandingAndAcquisition/selectors";
import { clsx } from "@/utils";
import { AssetUpload } from "@/components/organisms";

export const OperatorBrandingPanel = () => {
  const { isFetching } = useGetAssetsQuery();
  const { assets } = useAppSelector(operatorBrandingSelector);
  const images = useMemo(() => {
    const res = assets.reduce((prev, curr) => {
      const { path, type } = curr;
      prev[type] = path;
      return prev;
    }, {} as Record<string, string>);
    return res;
  }, [assets]);

  if (isFetching) return <Skeleton />;

  return (
    <Panel className="flex flex-1 flex-col">
      <Typography variant="h3">Operator Branding</Typography>
      <div className="flex flex-col lg:flex-row gap-y-10 gap-x-[72px] mt-5">
        <div className="flex flex-col max-w-[405px]">
          <div className="flex flex-row items-center gap-x-1.5">
            <Typography variant="action" className={clsx({ "text-danger": !images.logo })}>Company Logo</Typography>
            {!images.logo && <Icon name="InfoCircle" variant="Bold" className="text-danger" />}
          </div>
          <Typography variant="paragraph" className="text-neutral-dark-gray mt-2">Ensure your logo looks its best in communications and invoices. Recommended size: 150x50 px.</Typography>
          <Typography variant="small" className="text-neutral-dark-gray mt-1">PNG & JPG supported</Typography>
          <AssetUpload path={images.logo} type="logo" className="mt-4" />
        </div>
        <div className="flex flex-col max-w-[405px]">
          <div className="flex flex-row items-center gap-x-1.5">
            <Typography variant="action" className={clsx({ "text-danger": !images.emblem })}>Emblem</Typography>
            {!images.emblem && <Icon name="InfoCircle" variant="Bold" className="text-danger" />}
          </div>
          <Typography variant="paragraph" className="text-neutral-dark-gray mt-2">Display your emblem clearly across the platform. Recommended size: 256x256 px.</Typography>
          <Typography variant="small" className="text-neutral-dark-gray mt-1">PNG & JPG supported</Typography>
          <AssetUpload path={images.emblem} type="emblem" className="mt-4" />
        </div>
      </div>
    </Panel>
  );
};
