import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { DropdownSelect, DropdownSelectOption, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  year: z.string(),
});

type FormData = z.infer<typeof schema>;

export const YearFilter = () => {
  const {
    filters: { minYear },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => minYear, [minYear]);

  const onClose = () => setFilter("minYear", undefined);

  return (
    <FilterPopover className="overflow-visible" name="minYear" value={value?.toString()} label="Year" onClose={onClose}>
      <YearFilterForm />
    </FilterPopover>
  );
};

interface YearFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const YearFilterDrawer = ({ open, onOpenChange }: YearFilterDrawerProps) => {
  const {
    filters: { minYear },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => minYear, [minYear]);

  const onClose = () => setFilter("minYear", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value?.toString()} label="Year" onClose={onClose}>
      <YearFilterForm />
    </FilterDrawer>
  );
};

const YearFilterForm = () => {
  const close = useClose();
  const { setFilter, filters } = useVehicleFilters();

  const {
    control,
    watch,
    formState: { errors: formErrors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { year: filters.minYear ? filters.minYear.toString() : "" },
  });

  const value = watch("year");

  useDebouncedEffect(
    () => {
      setFilter("minYear", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Select Year</Typography>

      <Controller
        control={control}
        name="year"
        render={({ field }) => (
          <DropdownSelect
            size="md"
            isNullable
            placeholder="Select Year"
            options={Array.from({ length: new Date().getFullYear() - 1999 }, (_, i) => ({
              name: (2000 + i).toString(),
              value: (2000 + i).toString(),
            }))}
            value={field.value}
            onChange={(value: DropdownSelectOption["value"]) => {
              field.onChange(value);
              setFilter("minYear", value);
            }}
            hasError={!!formErrors.year}
          />
        )}
      />
    </form>
  );
};
