import { useMemo } from "react";
import { Skeleton } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { useGetInvoiceHistoryQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppSelector } from "@/redux/hooks";
import { invoicingInvoicesSelector } from "@/redux/slices/payment/selectors";
import { Invoice } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generateInvoiceHistoryColumns, generateInvoiceHistoryData } from "../helpers";

interface InvoiceHistoryTableProps extends React.HTMLAttributes<HTMLDivElement> {
  invoice: Invoice;
}

export const InvoiceHistoryTable = ({ invoice, className, ...props }: InvoiceHistoryTableProps) => {
  const { dateFormat, timeFormat } = useProfile();
  const { isFetching } = useGetInvoiceHistoryQuery({ id: invoice.id }, { refetchOnMountOrArgChange: true });
  const { invoiceHistory } = useAppSelector(invoicingInvoicesSelector);

  const data = useMemo(
    () => generateInvoiceHistoryData(invoiceHistory, dateFormat, timeFormat, invoice.currency),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoiceHistory]
  );
  const columns = useMemo(() => generateInvoiceHistoryColumns(), []);

  if (isFetching) return <Skeleton />;

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};
