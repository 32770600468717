import { useNavigate } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";

export const CompaniesEmptyState = () => {
  const ability = useAppAbility();
  const navigate = useNavigate();

  return (
    <EmptyState
      title="Company Manager"
      description="It looks like you haven't added any companies yet. Begin by adding your first company to get started"
    >
      {ability.can("manage", "providerCompany") && (
        <Button onClick={() => navigate("add")} startIcon="Add">
          New Company
        </Button>
      )}
    </EmptyState>
  );
};
