import {
  GetFleetDriversRawResponse,
  GetFleetDriversResponse,
  GetFleetLeadersResponse,
  GetFleetOptionsRawResponse,
  GetFleetOptionsResponse,
  GetOffloadDriversRawResponse,
  GetOffloadDriversResponse,
  GetOffloadOptionsRawResponse,
  GetOffloadOptionsResponse,
} from "./types";

const toNameValuePair = (obj: Record<string, string>) =>
  Object.entries(obj)
    .map(([key, label]) => ({ name: label, value: key }))
    .sort((a, b) => a.name.toString().localeCompare(b.name));

export const transformFleetDrivers = (res: GetFleetDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetFleetDriversResponse = {
    data: {
      drivers: drivers.map((i) => {
        const { vehicle_type, vehicle, provider, metadata } = i;
        return {
          id: i.uuid,
          name: i.name,
          email: i.email,
          phone: i.phone,
          state: i.state[0],
          tier: i.tier,
          vehicleType: {
            id: vehicle_type.uuid,
            name: vehicle_type.name,
          },
          vehicle: {
            id: vehicle.uuid,
            registrationNumber: vehicle.registration_number,
            color: vehicle.color,
            year: vehicle.year,
            brand: vehicle.brand,
            avatar: vehicle.avatar,
          },
          status: i.status,
          provider: {
            providerKey: provider.provider_key,
            id: provider.uuid,
            name: provider.name,
            phoneNumber: provider.phone_number,
            appDeepLink: provider.app_deep_link,
            assets: provider.assets,
          },
          isFleetLeader: i.is_fleet_leader,
          avatar: i.avatar,
          rating: metadata.rating,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformFleetLeaders = (res: GetFleetDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetFleetLeadersResponse = {
    data: {
      items: drivers.map((i) => {
        const { vehicle_type, vehicle, provider, metadata } = i;
        return {
          id: i.uuid,
          name: i.name,
          email: i.email,
          phone: i.phone,
          state: i.state[0],
          tier: i.tier,
          vehicleType: {
            id: vehicle_type.uuid,
            name: vehicle_type.name,
          },
          vehicle: {
            id: vehicle.uuid,
            registrationNumber: vehicle.registration_number,
            color: vehicle.color,
            year: vehicle.year,
            brand: vehicle.brand,
            avatar: vehicle.avatar,
          },
          status: i.status,
          provider: {
            providerKey: provider.provider_key,
            id: provider.uuid,
            name: provider.name,
            phoneNumber: provider.phone_number,
            appDeepLink: provider.app_deep_link,
            assets: provider.assets,
          },
          isFleetLeader: i.is_fleet_leader,
          avatar: i.avatar,
          rating: metadata.rating,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformOffloadDrivers = (res: GetOffloadDriversRawResponse) => {
  const {
    _embedded: { driver: drivers },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetOffloadDriversResponse = {
    data: {
      drivers: drivers.map((i) => {
        const { vehicle_type, vehicle, provider, block, metadata } = i;
        return {
          id: i.uuid,
          name: i.name,
          state: i.state[0],
          vehicleType: {
            id: vehicle_type.uuid,
            name: vehicle_type.name,
          },
          vehicle: {
            id: vehicle.uuid,
            registrationNumber: vehicle.registration_number,
            color: vehicle.color,
            year: vehicle.year,
            brand: vehicle.brand,
            avatar: vehicle.avatar,
          },
          provider: {
            providerKey: provider.provider_key,
            id: provider.uuid,
            name: provider.name,
            phoneNumber: provider.phone_number,
            appDeepLink: provider.app_deep_link,
            assets: provider.assets,
          },
          avatar: i.avatar,
          block: {
            isActive: block.is_active,
            reason: block.reason,
          },
          tripCount: metadata.trip_count,
          rating: metadata.rating,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformFleetOptions = (res: GetFleetOptionsRawResponse) => {
  const response: GetFleetOptionsResponse = {
    data: {
      options: {
        isFleetLeader: toNameValuePair(res.is_fleet_leader),
        state: toNameValuePair(res.state),
        accountStatus: toNameValuePair(res.account_status),
        tier: toNameValuePair(res.tier),
        network: toNameValuePair(res.network),
        vehicleBrand: toNameValuePair(res.vehicle_brand),
        vehicleColor: toNameValuePair(res.vehicle_color),
        vehicleType: toNameValuePair(res.vehicle_type),
        year: res.year
      },
    },
  };

  return response;
};

export const transformOffloadOptions = (res: GetOffloadOptionsRawResponse) => {
  const response: GetOffloadOptionsResponse = {
    data: {
      options: {
        state: toNameValuePair(res.state),
        isBlocked: toNameValuePair(res.is_blocked),
        vehicleBrand: toNameValuePair(res.vehicle_brand),
        vehicleColor: toNameValuePair(res.vehicle_color),
        vehicleType: toNameValuePair(res.vehicle_type),
        year: res.year
      },
    },
  };

  return response;
};
