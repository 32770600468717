import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetUnscheduledStopsQuery } from "@/redux/apis/config/pricing/unscheduledStops";
import { useAppSelector } from "@/redux/hooks";
import { unscheduledStopsSelector } from "@/redux/slices/pricing/selectors";
import { UnscheduledStopsEdit } from "./UnscheduledStopsEdit";
import { formatCurrency } from "@/utils";

export const UnscheduledStops = () => {
  const { details } = useAppSelector(unscheduledStopsSelector);
  const { isFetching } = useGetUnscheduledStopsQuery();
  const navigate = useNavigate();

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Unscheduled Stops
            </Typography>
            <Typography className="text-neutral-dark-gray">Set the pricing for every unscheduled stops</Typography>
          </div>
          <div className="ml-auto">
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        <ConfigDetail title="Unscheduled Stop Fee" description="Fee for every unscheduled stops each trip.">
          <Typography variant="paragraph" className="leading-8">
            {`${formatCurrency(details.unscheduledStopsFee, 2, details.currency)}`}
          </Typography>
        </ConfigDetail>
      </Panel>
    </>
  );
};

UnscheduledStops.Edit = UnscheduledStopsEdit;
