import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { bookingApi } from "@/redux/apis";
import { actionEndpoints } from "@/redux/apis/booking/endpoints/actions";
import { closeEndpoints } from "@/redux/apis/booking/endpoints/closeJob";
import { statusEndpoints } from "@/redux/apis/booking/endpoints/jobStatus";
import { paymentEndpoints } from "@/redux/apis/booking/endpoints/payment";
import { transformBookingDetails } from "@/redux/apis/booking/helpers";
import { Page, pageState } from "@/redux/types";
import {
  Booking,
  BookingAction,
  BookingDetails,
  BookingInlineEditing,
  BookingPaymentAction,
  BookingPaymentLedgerLog,
  BookingPaymentLog,
  BookingState,
} from "./types";

const initialState: BookingState = {
  pageState: {
    inlineEditing: undefined,
    selectedBooking: undefined,
    actionsState: {
      action: undefined,
      payments: {
        meta: undefined,
        action: undefined,
        logs: undefined,
        selectedPayment: undefined,
      },
      history: {
        logs: [],
        page: pageState,
      },
      driver: {
        recommendedCommissionRate: undefined,
      },
    },
  },
  bookings: [],
  bookingRequestId: undefined,
  bookingSummary: undefined,
  bookingSummaryRequestId: undefined,
  options: {
    assignedVehicles: [],
    transferTypes: [],
    vehicleTypes: [],
    states: [],
    statuses: [],
  },
  page: {
    current: 1,
    count: 1,
    size: 12,
    total: 0,
  },
};

const anyOfStatusEndpointsFulfilled = isAnyOf(
  ...[
    statusEndpoints.endpoints.confirmDriver.matchFulfilled,
    statusEndpoints.endpoints.markDriverOnWay.matchFulfilled,
    statusEndpoints.endpoints.reopenBooking.matchFulfilled,
    statusEndpoints.endpoints.commenceJob.matchFulfilled,
    statusEndpoints.endpoints.cancelBooking.matchFulfilled,
    closeEndpoints.endpoints.closeJobNoCost.matchFulfilled,
    closeEndpoints.endpoints.closeJobNoShow.matchFulfilled,
    closeEndpoints.endpoints.closeJobNoExtras.matchFulfilled,
    closeEndpoints.endpoints.closeJobWithExtras.matchFulfilled,
    closeEndpoints.endpoints.closeJobNoDriver.matchFulfilled,
  ]
);

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setBookings: (state, { payload }: PayloadAction<{ bookings: Booking[]; page: Page }>) => {
      state.bookings = payload.bookings;
      state.page = payload.page;
    },
    setBooking: (state, { payload }: PayloadAction<Booking>) => {
      const index = state.bookings.findIndex((booking) => booking.id === payload.id);
      state.bookings[index] = payload;
    },
    setSelectedBooking: (state, { payload }: PayloadAction<BookingDetails | undefined>) => {
      state.pageState.selectedBooking = payload;
    },
    setInlineEditing: (state, { payload }: PayloadAction<BookingInlineEditing | undefined>) => {
      state.pageState.inlineEditing = payload;
    },
    setAction: (state, { payload }: PayloadAction<BookingAction | undefined>) => {
      state.pageState.actionsState.action = payload;
    },
    setSelectedPayment: (state, { payload }: PayloadAction<BookingPaymentLog | undefined>) => {
      state.pageState.actionsState.payments.selectedPayment = payload;
    },
    setPaymentAction: (
      state,
      { payload }: PayloadAction<{ action: BookingPaymentAction; log?: BookingPaymentLog | BookingPaymentLedgerLog } | undefined>
    ) => {
      if (!payload) {
        state.pageState.actionsState.payments.action = payload;
      } else {
        state.pageState.actionsState.payments.action = payload.action;
        state.pageState.actionsState.payments.selectedPayment = payload.log;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(bookingApi.endpoints.getBookings.matchPending, (state, { meta: { requestId } }) => {
      // Handles aborting the request when new request in succession is made
      state.bookingRequestId = requestId;
    });
    builder.addMatcher(bookingApi.endpoints.getBookings.matchRejected, (state, { meta: { requestId } }) => {
      if (state.bookingRequestId === requestId) {
        state.bookingRequestId = undefined;
      }
    });
    builder.addMatcher(bookingApi.endpoints.getBookings.matchFulfilled, (state, { payload, meta: { requestId } }) => {
      if (state.bookingRequestId === requestId) {
        const { bookings } = payload.data;
        state.bookings = bookings;
        state.page = payload.data.page;
        state.bookingRequestId = undefined;
      }
    });
    builder.addMatcher(bookingApi.endpoints.getBookingsSummary.matchPending, (state, { meta: { requestId } }) => {
      state.bookingSummaryRequestId = requestId;
    });
    builder.addMatcher(bookingApi.endpoints.getBookingsSummary.matchRejected, (state, { meta: { requestId } }) => {
      if (state.bookingSummaryRequestId === requestId) {
        state.bookingSummaryRequestId = undefined;
      }
    });
    builder.addMatcher(bookingApi.endpoints.getBookingsSummary.matchFulfilled, (state, { payload, meta: { requestId } }) => {
      if (state.bookingSummaryRequestId === requestId) {
        state.bookingSummary = payload.summary;
        state.bookingSummaryRequestId = undefined;
      }
    });
    builder.addMatcher(bookingApi.endpoints.getBookingFormOptions.matchFulfilled, (state, { payload }) => {
      state.options = payload;
    });
    builder.addMatcher(bookingApi.endpoints.updateBooking.matchFulfilled, (state, { payload }) => {
      const transformedBooking = transformBookingDetails(payload);
      state.pageState.selectedBooking = transformedBooking;

      const index = state.bookings.findIndex((booking) => booking.id === payload.uuid);
      state.bookings[index] = transformedBooking;
    });
    builder.addMatcher(bookingApi.endpoints.getBookingDetails.matchFulfilled, (state, { payload }) => {
      const transformedBookingDetails = transformBookingDetails(payload);
      state.pageState.selectedBooking = transformedBookingDetails;

      const index = state.bookings.findIndex((booking) => booking.id === payload.uuid);
      state.bookings[index] = transformedBookingDetails;
    });
    builder.addMatcher(paymentEndpoints.endpoints.getBookingPaymentsMeta.matchFulfilled, (state, { payload }) => {
      state.pageState.actionsState.payments.meta = payload;
    });
    builder.addMatcher(anyOfStatusEndpointsFulfilled, (state, { payload }) => {
      const transformedBooking = transformBookingDetails(payload);
      state.pageState.selectedBooking = transformedBooking;

      const index = state.bookings.findIndex((booking) => booking.id === payload.uuid);
      state.bookings[index] = transformedBooking;
    });
    builder.addMatcher(actionEndpoints.endpoints.getBookingHistoryLogs.matchFulfilled, (state, { payload }) => {
      const { items, page } = payload.data;
      state.pageState.actionsState.history.logs = page.current > 1 ? [...state.pageState.actionsState.history.logs, ...items] : items;
      state.pageState.actionsState.history.page = page;
    });
  },
});

export const { setBookings, setBooking, setSelectedBooking, setInlineEditing, setAction, setPaymentAction } = bookingSlice.actions;
export default bookingSlice.reducer;
