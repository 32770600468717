import { ErrorPanel, Skeleton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { BookingTracker } from "./BookingTracker";
import { BookingsTableV2 } from "./BookingsTableV2";
import { useBookings } from "./hooks/useBookings";
import { useGetBookingsParams } from "./hooks/useGetBookingsParams";

export const BookingsPanel = () => {
  const {
    flags: { useOptimizedLayout },
  } = useOperator();
  const params = useGetBookingsParams();
  const { isLoading, bookings, error } = useBookings();

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;

  return (
    <>
      <div className="mt-2 overflow-auto p-1 lg:max-h-[calc(100vh-250px)]">
        {bookings.length < 1 && !params.search && !isLoading ? (
          <EmptyState title="Bookings" description="There are no bookings matching your selected date and filter criteria at the moment." />
        ) : isLoading ? (
          <Skeleton />
        ) : (
          <>
            <BookingsTableV2 items={bookings} compactView={useOptimizedLayout} />
            <BookingTracker />
          </>
        )}
      </div>
      {!isLoading && <BookingsTableV2.Pagination />}
    </>
  );
};
