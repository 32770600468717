import { AuBankAccountElementProps } from "@stripe/react-stripe-js";
import { z } from "zod";

export const auBankAccountOptions: AuBankAccountElementProps["options"] = {
  classes: {
    base: "flex flex-col p-[10px] rounded-lg bg-white text-neutral-black border border-neutral-mid-gray placeholder-neutral-dark-gray",
    focus: "border-primary shadow-input ring-0",
    invalid: "!border-danger",
  },
  disabled: false,
  hideIcon: false,
  iconStyle: "default",
};

export const depositsFormSchema = z
  .object({
    bsb: z.string().regex(/^\d{3}-?\d{3}$/, "Invalid BSB"),
    accountNumber: z
      .string()
      .nonempty("Field is required and can't be empty.")
      .min(4, "Minimum of 9 characters")
      .max(9, "Maximum of 9 characters"),
    confirmAccountNumber: z
      .string()
      .nonempty("Field is required and can't be empty.")
      .min(4, "Minimum of 9 characters")
      .max(9, "Maximum of 9 characters"),
  })
  .refine((data) => data.accountNumber === data.confirmAccountNumber, {
    message: "Account numbers don't match. Please try again.",
    path: ["confirmAccountNumber"],
  });
