import { adminApi } from "../adminApi";
import { transformGetEmailNotifications, transformUpdateEmailNotifications } from "./helpers";
import { GetEmailNotificationsResponse, UpdateEmailNotificationsParams, UpdateEmailNotificationsResponse } from "./types";

export const emailNotificationsApi = adminApi.enhanceEndpoints({ addTagTypes: ["EmailNotifications"] }).injectEndpoints({
  endpoints: (builder) => ({
    getEmailNotifications: builder.query<GetEmailNotificationsResponse, void>({
      query: () => `/notifications`,
      transformResponse: transformGetEmailNotifications,
      providesTags: ["EmailNotifications"],
    }),
    updateEmailNotifications: builder.mutation<UpdateEmailNotificationsResponse, UpdateEmailNotificationsParams>({
      query: (body) => ({
        url: `/notifications`,
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateEmailNotifications,
      invalidatesTags: ["EmailNotifications"],
    }),
  }),
});

export const { useGetEmailNotificationsQuery, useUpdateEmailNotificationsMutation } = emailNotificationsApi;
