import { Alert, Button, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useEffect, useState } from "react";
import { useUpdateGoogleTagManagerConfigurationMutation } from "@/redux/apis/addOn/addOnApi";
import { addOnItemSelector } from "@/redux/slices/addOn/selectors";
import { renderAddOnCost, renderAddOnIcon, renderPostFreeTrialInfo, renderPreFreeTrialInfo } from "../helpers";
import { useProfile } from "@/hooks";
import { GoogleTagManagerDetails } from "./GoogleTagManagerDetails";
import { GoogleTagManagerEdit } from "./GoogleTagManagerEdit";
import { addOnsModalDescription } from "../fixtures";
import { FormProvider, useForm } from "react-hook-form";
import { GoogleTagManagerConfiguration } from "@/redux/slices/addOn/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { formSchema } from "./fixtures";
import { AddOnDisableModal } from "../modals/AddOnDisableModal";
import { AddOnSaveModal } from "../modals/AddOnSaveModal";
import { isEmpty } from "ramda";
import { useToggleAddOn } from "../hooks/useToggleAddOn";
import { RawErrorResponse } from "@/redux/types";

interface GoogleTagManagerModalProps {
  open: boolean;
  onClose: () => void;
  isAdmin: boolean;
}

export const GoogleTagManagerModal = ({ open, onClose, isAdmin }: GoogleTagManagerModalProps) => {
  const { dateFormat } = useProfile();
  const item = useAppSelector(addOnItemSelector("enterprise-gtm"));
  const configuration = item?.configuration as GoogleTagManagerConfiguration | undefined;
  const [updateGoogleTagManagerConfiguration, { isLoading: isLoadingUpdate }] = useUpdateGoogleTagManagerConfigurationMutation();

  const [modal, setModal] = useState<"save" | "disable" | "disableError" | undefined>(undefined);
  const [action, setAction] = useState<"edit" | undefined>(undefined);

  const methods = useForm<GoogleTagManagerConfiguration>({
    resolver: zodResolver(formSchema),
    defaultValues: configuration,
  });
  const { reset, handleSubmit, formState: { isDirty, errors } } = methods;

  const { toggleAddOn, isLoading } = useToggleAddOn({ 
    onEnableSuccess: () => addToast("success", "Google Tag Manager has been successfully enabled."), 
    onDisableSuccess: () => {
      addToast("info", "Google Tag Manager has been disabled.");
      onClose(); 
    }, 
    onEnableError: (e: RawErrorResponse) => getErrorMessages(e).forEach((message) => addToast("danger", message)),
    onDisableError: () => setModal("disableError"),
    shouldUpdateOperator: true,
  });

  useEffect(() => {
    reset(configuration);
  }, [configuration, reset]);

  useEffect(() => {
    if (!isEmpty(errors)) setModal(undefined);
  }, [errors]);

  const handleClose = () => {
    if (isDirty) setModal("save");
    else onClose();
  };

  const onSave = handleSubmit((data) => {
    const { property } = data;

    updateGoogleTagManagerConfiguration({ property })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Google Tag Manager");
        setAction(undefined);
        setModal(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  });

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Add-ons
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleClose} />
    </div>
  );

  const renderButton = () => {
    if (item && item.available) {
      if (isAdmin) {
        if (item.enabled) return <Button variant="secondary" size="lg" startIcon="MinusCircle" iconVariant="Bold" iconClassName="text-danger" className="w-full mt-8" onClick={() => setModal("disable")}>Disable Google Tag Manager</Button>;
        else return (
          <div className="relative mt-8">
            {isLoading && <Loading />}
            <Button variant="primary" size="lg" className="w-full" onClick={() => toggleAddOn("enterprise-gtm", true)}>Enable Google Tag Manager</Button>
          </div>
        );
      } else return <Alert type="info" message={`Request access from your admin for Google Tag Manager.`} className="w-full mt-4" />;
    }
  };

  const renderModals = () => (item && (
    <>
      <AddOnSaveModal open={modal === "save"} onClose={() => setModal(undefined)} onSave={onSave} isLoading={isLoadingUpdate} />
      <AddOnDisableModal id="enterprise-gtm" name={item.name} open={modal === "disable"} onClose={() => setModal(undefined)} onDisable={() => toggleAddOn("enterprise-gtm", false)} isLoading={isLoading} />
    </>
  ));
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="mt-3">
        {renderPreFreeTrialInfo(item)}
        <div className="flex flex-col md:flex-row md:items-center bg-neutral-surface-gray rounded-lg gap-y-2.5 py-2.5 pl-2.5 pr-5 mt-3">
          <div className="flex flex-1 items-center flex-row gap-x-4">
            <div className="flex items-center justify-center bg-primary-dark rounded-md p-2.5">
              {renderAddOnIcon("enterprise-gtm")}
            </div>
            <Typography variant="h3">Google Tag Manager</Typography>
          </div>
          <div className="flex flex-col items-start sm:items-end">
            {renderAddOnCost(item)}
          </div>
        </div> 
        {item?.enabled && (
          action === "edit" ? (
            <FormProvider {...methods}>
              <GoogleTagManagerEdit onSubmit={onSave} onClose={() => setAction(undefined)} isLoading={isLoadingUpdate} />
            </FormProvider>
          ) : <GoogleTagManagerDetails onEdit={() => setAction("edit")} configuration={configuration} />
        )}
        <Typography variant="paragraph" className="mt-4">{addOnsModalDescription["enterprise-gtm"]}</Typography>
        {renderPostFreeTrialInfo(dateFormat, item)}
        {renderButton()}
      </div>
      {renderModals()}
    </Modal>
  );
};
