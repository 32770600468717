import { Button, Icon, IconButton, Tooltip, Typography } from "@/components/atoms";
import { useAppDispatch } from "@/redux/hooks";
import { setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const AdminNotes = () => {
  const dispatch = useAppDispatch();

  const {
    notes: { admin: adminNotes },
  } = useSelectedBooking();

  const handleEditMode = () => {
    dispatch(setInlineEditing("admin-notes"));
  };

  return (
    <div
      onClick={handleEditMode}
      className="!-mb-3 -mr-4 -translate-x-2 -translate-y-1.5 cursor-pointer items-center gap-3 space-y-2 rounded-md px-2 py-1.5 hover:bg-neutral-gray"
    >
      <div className="flex items-center gap-3">
        <Typography variant="small" className="flex flex-1 items-center text-neutral-black/70">
          Admin Notes
          <Tooltip content="Internal administrative notes visible to operators only" placement="right">
            <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </Typography>

        {adminNotes && (
          <IconButton
            onClick={handleEditMode}
            iconName="Edit2"
            variant="custom"
            iconSize="sm"
            className=" text-info hover:bg-transparent"
          />
        )}
      </div>

      <Typography variant="small" className="line-clamp-5 block max-h-[75px] flex-1 break-words">
        {!adminNotes ? (
          <Button
            onClick={handleEditMode}
            startIcon="Add"
            variant="tertiary"
            size="sm"
            className="-ml-3 h-auto py-0 text-info hover:bg-transparent [&>span]:text-sm"
          >
            Add notes
          </Button>
        ) : (
          adminNotes ?? "-"
        )}
      </Typography>
    </div>
  );
};
