import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setCreditCardPaymentsSidePanelAction } from "@/redux/slices/payment/paymentSlice";
import { creditCardPaymentsPageStateSelector } from "@/redux/slices/payment/selectors";
import { Payments } from "../../Bookings/actions/Payments/Payments";
import { TakeManualPaymentPanel } from "./TakeManualPaymentPanel";

export const CreditCardPaymentsSidePanels = () => {
  const dispatch = useAppDispatch();
  const { sidePanelAction, selectedPayment } = useAppSelector(creditCardPaymentsPageStateSelector);

  const onClose = () => dispatch(setCreditCardPaymentsSidePanelAction(undefined));

  return (
    <>
      <Payments open={sidePanelAction === "paymentLogs"} onClose={onClose} bookingId={selectedPayment?.jobId} />
      <TakeManualPaymentPanel open={sidePanelAction === "takeManualPayment"} onClose={onClose} />
    </>
  );
};
