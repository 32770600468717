import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddTransferTypeFormData } from "../../form";

export const TransferTypeIsTaxExemptField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddTransferTypeFormData>();

  return (
    <div className={clsx("flex w-full items-center gap-x-1.5", className)} {...props}>
      <div className="flex flex-1 flex-col  ">
        <Typography variant="paragraph">Transfer Type Tax Exempt</Typography>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          Is this transfer type Tax exempt? If enabled, Tax will not be applied to job invoice
        </Typography>
      </div>
      <Controller
        name="isTaxExempt"
        control={control}
        render={({ field }) => (
          <div className="flex items-center gap-4">
            <Toggle
              checked={field.value}
              onChange={(checked) => {
                field.onChange(checked);
              }}
            />
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="isTaxExempt" />
    </div>
  );
};
