import { FixedPricingContextProvider, FixedPricingStateContextProvider, StateBasedFixedPricingPanel } from "@/components/organisms";

export const StateBased = () => {
  return (
    <FixedPricingContextProvider>
      <FixedPricingStateContextProvider>
        <StateBasedFixedPricingPanel />
      </FixedPricingStateContextProvider>
    </FixedPricingContextProvider>
  );
};
