import {
  GetBusinessDetailsRawResponse,
  GetBusinessDetailsResponse,
  UpdateBusinessDetailsRawResponse,
  UpdateBusinessDetailsResponse,
} from "./types";

export const transformBusinessDetails = (res: GetBusinessDetailsRawResponse) => {
  const { _links, ...businessDetails } = res;
  const response: GetBusinessDetailsResponse = {
    data: {
      details: {
        businessName: businessDetails.business_name,
        businessTagline: businessDetails.business_tagline,
        businessAddress: businessDetails.business_address,
        businessRegistrationType: businessDetails.business_registration_type,
        businessRegistrationId: businessDetails.business_registration_id,
        businessPhoneNumberLocal: businessDetails.business_phone_number_local,
        businessPhoneNumberInternational: businessDetails.business_phone_number_international,
        businessSupportEmail: businessDetails.business_support_email,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateBusinessDetails = (res: UpdateBusinessDetailsRawResponse) => {
  return {
    links: res._links,
  } as UpdateBusinessDetailsResponse;
};
