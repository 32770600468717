import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import parsePhoneNumber from "libphonenumber-js";
import { Typography } from "@/components/atoms";

export interface PhoneNumberDetailProps {
  number: string;
  label?: string;
}

export const PhoneNumberDetail = (phoneNumberDetailProps: PhoneNumberDetailProps) => {
  const { number, label } = phoneNumberDetailProps;
  const countryCode = parsePhoneNumber(number)?.country;
  const countryFlag = countryCode && hasFlag(countryCode) ? getUnicodeFlagIcon(countryCode) : null;

  return (
    <Typography variant="paragraph" className="leading-8">
      {label} {countryFlag} {number}
    </Typography>
  );
};

PhoneNumberDetail.displayName = "PhoneNumberDetail";
