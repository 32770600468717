import { BookingTripLog } from "@/redux/slices/booking/types";
import { BookingTripViewer } from "./components/BookingTripViewer";
import { BookingTripMapProvider } from "./hooks/useBookingTripMap";
import { BookingMapState, BookingMarkerState } from "./types";

export interface BookingTripMapProps {
  tripLog: BookingTripLog;
  markerState: BookingMarkerState;
  mapState: BookingMapState;
  onToggle: (markerState: BookingMarkerState) => void;
  readOnly?: boolean;
  mode?: "admin" | "guest";
}

export const BookingTripMap = ({ mode = "guest", tripLog, markerState, mapState, onToggle, readOnly = false }: BookingTripMapProps) => {
  return (
    <BookingTripMapProvider
      mode={mode}
      tripLog={tripLog}
      markerState={markerState}
      mapState={mapState}
      onToggle={onToggle}
      readOnly={readOnly}
    >
      <BookingTripViewer />
    </BookingTripMapProvider>
  );
};
