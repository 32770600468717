import cx from "classnames";
import { renderNotificationItemSkeleton } from "./helpers";

export interface NotificationsSkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "full" | "item";
}

export const NotificationsSkeleton = ({ variant = "full", className, ...props }: NotificationsSkeletonProps) => {
  const fullSkeletonItems = Array.from({ length: 4 }, (_, x: number) => <div key={x}>{renderNotificationItemSkeleton()}</div>);

  return (
    <div className={cx("animate-pulse", className)} {...props}>
      {variant === "item" ? (
        renderNotificationItemSkeleton()
      ) : (
        <>
          <div className="flex flex-col">
            <div className="px-5 pb-2.5 pt-5">
              <div className="mr-3 h-5 w-20 rounded-2xl bg-gray-300" />
            </div>
          </div>
          {fullSkeletonItems}
        </>
      )}
    </div>
  );
};
