import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Icon, Tooltip, Typography } from "@/components/atoms";
import { useGetVehicleCategoriesFormOptionsQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { capitalize, clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryDispatchToleranceField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isFetching, data } = useGetVehicleCategoriesFormOptionsQuery();
  const {
    control,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  const options = ["STRICT", "MODERATE", "OPEN"].map((option) => ({
    name: `${capitalize(option.toLowerCase())} Tolerance`,
    value: option,
  }));
  if (isFetching || !data) return null;

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph">Dispatch Tolerance</Typography>
        <Tooltip
          maxWidth={747}
          content={
            <div className="flex flex-col">
              <Typography variant="paragraph" className="mb-2">
                • Strict Tolerance: Vehicle category, engine type, and color must match exactly
              </Typography>
              <Typography variant="paragraph" className="mb-2">
                • Moderate Tolerance: Only vehicle class and engine type must match exactly
              </Typography>
              <Typography variant="paragraph" className="mb-2">
                • Open Tolerance: Any vehicle category with the same capacity, any engine type and color
              </Typography>
            </div>
          }
          placement="right"
        >
          <span>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="md" />
          </span>
        </Tooltip>
      </div>
      <Controller
        control={control}
        name="offloadDispatchTolerance"
        render={({ field }) => (
          <DropdownSelect
            className="w-[350px] max-w-full"
            placeholder="Select Dispatch Tolerance"
            options={options}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            hasError={!!errors.class}
            isNullable
          />
        )}
      />
      <ErrorMessage errors={errors} name="offloadDispatchTolerance" />
    </div>
  );
};
