import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";

export const StatusField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="action">Status</Typography>
      <Controller
        name="status"
        control={control}
        render={({ field }) => (
          <div className="flex flex-row items-center gap-x-4">
            <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
            <Typography className="inline">{field.value ? "Active" : "Inactive"}</Typography>
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="status" />
    </div>
  );
};
