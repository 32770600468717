import { DataTableColumnDef } from "@/components/molecules";
import { z } from "zod";
import { ArchivedDriversTableData, BlockedOffloadDriversTableData, OffloadDriversTableData } from "./types";

export const offloadDriversColumns: DataTableColumnDef<OffloadDriversTableData>[] = [
  { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 300 },
  {
    accessorKey: "state",
    header: "State",
    cell: (props) => props.row.original.state,
    id: "state"
  },
  {
    accessorKey: "rating",
    header: "Rating",
    cell: (props) => props.row.original.rating,
    id: "rating"
  },
  {
    accessorKey: "totalJobs",
    header: "Total Jobs",
    cell: (props) => props.row.original.totalJobs,
    enableSorting: false,
  },
  {
    accessorKey: "operator",
    header: "Operator",
    cell: (props) => props.row.original.operator,
    enableSorting: false,
    size: 250
  },
  {
    accessorKey: "operatorContact",
    header: "Operator Contact No.",
    cell: (props) => props.row.original.operatorContact,
    enableSorting: false,
    size: 250
  },
  {
    accessorKey: "action",
    header: "Action",
    cell: (props) => props.row.original.action,
    size: 75,
    enableSorting: false,
  },
];

export const blockedOffloadDriversColumns: DataTableColumnDef<BlockedOffloadDriversTableData>[] = [
  { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 325 },
  {
    accessorKey: "state",
    header: "State",
    cell: (props) => props.row.original.state,
    id: "state"
  },
  {
    accessorKey: "rating",
    header: "Rating",
    cell: (props) => props.row.original.rating,
    id: "rating"
  },
  {
    accessorKey: "totalJobs",
    header: "Total Jobs",
    cell: (props) => props.row.original.totalJobs,
    enableSorting: false,
  },
  {
    accessorKey: "operator",
    header: "Operator",
    cell: (props) => props.row.original.operator,
    enableSorting: false,
    size: 250
  },
  {
    accessorKey: "operatorContact",
    header: "Operator Contact No.",
    cell: (props) => props.row.original.operatorContact,
    enableSorting: false,
    size: 250
  },
  {
    accessorKey: "reasonForBlocking",
    header: "Reason for Blocking",
    cell: (props) => props.row.original.reasonForBlocking,
    enableSorting: false,
    size: 250
  },
];

export const archivedDriversColumns: DataTableColumnDef<ArchivedDriversTableData>[] = [
  { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 300 },
    {
      accessorKey: "email",
      header: "Email",
      cell: (props) => props.row.original.email,
      id: "email",
      size: 370,
    },
    {
      accessorKey: "mobile",
      header: "Mobile",
      cell: (props) => props.row.original.mobile,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250
    },
    // {
    //   accessorKey: "actionDate",
    //   header: "Action Date",
    //   cell: (props) => props.row.original.actionDate,
    //   enableSorting: false,
    //   size: 200
    // },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
];

export const blockDriverFormSchema = z.object({
  reason: z.string().optional(),
});

export const myDriversFilterFormSchema = z.object({
  isFleetLeader: z.boolean(),
  state: z.array(z.string()),
  tier: z.array(z.string()),
  accountStatus: z.array(z.string()),
  driverRating: z.array(z.string()),
  vehicleType: z.array(z.string()),
  vehicleBrand: z.array(z.string()),
  vehicleYearFrom: z.string(),
  vehicleYearTo: z.string(),
  vehicleColor: z.array(z.string()),
});

export const offloadFilterFormSchema = z.object({
  state: z.array(z.string()),
  driverRating: z.array(z.string()),
  vehicleBrand: z.array(z.string()),
  vehicleYearFrom: z.string(),
  vehicleYearTo: z.string(),
  vehicleColor: z.array(z.string()),
});

export const defautFleetFilters = {
  isFleetLeader: false,
  state: [],
  tier: [],
  accountStatus: [],
  driverRating: [],
  vehicleType: [],
  vehicleBrand: [],
  vehicleYearFrom: "",
  vehicleYearTo: "",
  vehicleColor: [],
};

export const defaultOffloadFilters = {
  state: [],
  driverRating: [],
  vehicleBrand: [],
  vehicleYearFrom: "",
  vehicleYearTo: "",
  vehicleColor: [],
};
