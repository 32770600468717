import { useAppSelector } from "@/redux/hooks";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";
import { Operator } from "@/redux/slices/operator/types";

export const useOperator = (): Operator => {
  const activeOperator = useAppSelector(activeOperatorSelector);
  if (!activeOperator) throw new Error("No active operator found");

  return activeOperator;
};
