import { useMemo } from "react";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { InvoiceSettingsHistory } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generateInvoiceSettingLogsData, generateInvoiceSettingLogsColumns } from "../helpers";

interface InvoiceSettingLogsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  logs: InvoiceSettingsHistory[];
}

export const InvoiceSettingLogsTable = ({ logs, className, ...props }: InvoiceSettingLogsTableProps) => {
  const { dateFormat, timeFormat } = useProfile();
  const data = generateInvoiceSettingLogsData(logs, timeFormat, dateFormat);
  const columns = useMemo(() => generateInvoiceSettingLogsColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};
