import { StringParam, useQueryParams, withDefault } from "use-query-params";

const DEFAULT_STATUS = "paid";

export const usePaymentsStatus = () => {
  const [selectedStatus, setSelectedStatus] = useQueryParams({
    status: withDefault(StringParam, DEFAULT_STATUS),
  });

  return [selectedStatus, setSelectedStatus] as const;
};
