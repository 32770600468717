import { LngLatBounds } from "mapbox-gl";
import { createContext, RefObject, useCallback, useContext, useMemo, useRef, useState } from "react";
import { MapRef } from "react-map-gl";
import { BookingTripLog } from "@/redux/slices/booking/types";
import { getAllLocationBounds, getCommencedBounds, getDriverOnWayBounds } from "../helpers";
import { BookingMapState, BookingMarker, BookingMarkerState, BookingTripPoint } from "../types";

interface BookingTripMapContext {
  mode: "admin" | "guest";
  tripLog: BookingTripLog;
  states: {
    markerState: BookingMarkerState;
    activeTripPoint?: BookingTripPoint;
  };
  map: {
    ref: RefObject<MapRef>;
    tripPoints: BookingTripPoint[];
    markers: BookingMarker[];
    polylines: {
      onRoute: string | null;
      onTrip: string | null;
      preTrip: string | null;
      driverOnWay: string | null;
      commenced: string | null;
    };
  };
  functions: {
    setActiveTripPoint: (tripPoint?: BookingTripPoint) => void;
    flyToMarker: (markerId: string) => void;
    toggleMarker: (markerState: BookingMarkerState) => void;
    setMapBounds: (scope: "all" | "driver-on-way" | "commenced") => void;
  };
}

interface BookingTripMapProviderProps extends React.PropsWithChildren {
  tripLog: BookingTripLog;
  markerState: BookingMarkerState;
  mapState: BookingMapState;
  onToggle: (markerState: BookingMarkerState) => void;
  readOnly?: boolean;
  mode: "admin" | "guest";
}

const BookingTripMapContext = createContext<BookingTripMapContext | null>(null);

export const BookingTripMapProvider = ({
  mode,
  tripLog,
  markerState,
  mapState,
  onToggle,
  children,
  readOnly = false,
}: BookingTripMapProviderProps) => {
  const mapRef = useRef<MapRef>(null);

  const tripPoints = useMemo(() => mapState.tripPoints, [mapState.tripPoints]);
  const polylines = useMemo(() => mapState.polylines, [mapState.polylines]);
  const markers = useMemo(() => mapState.markers, [mapState.markers]);

  const markerStateMemo = useMemo(() => markerState, [markerState]);
  const [activeTripPoint, setActiveTripPoint] = useState<BookingTripPoint>();

  const flyToMarker = useCallback(
    (markerId: string) => {
      mapRef.current?.getMap().flyTo({
        center: markers.find((marker) => marker.id === markerId)?.location,
        zoom: 13,
      });
    },
    [markers]
  );

  const toggleMarker = useCallback(
    (newState: BookingMarkerState) => {
      if (readOnly) return;
      onToggle(newState);
    },
    [onToggle, readOnly]
  );

  const setMapBounds = useCallback(
    (scope: string) => {
      if (!mapRef.current) return;

      let bounds = new LngLatBounds();

      switch (scope) {
        case "driver-on-way":
          bounds = getDriverOnWayBounds(tripLog);
          break;

        case "commenced":
          bounds = getCommencedBounds(tripLog);
          break;

        default:
          bounds = getAllLocationBounds(tripLog);
      }

      if (bounds.isEmpty()) {
        bounds = getAllLocationBounds(tripLog);
      }

      const mapHeight = mapRef.current.getMap().getCanvas().height;
      const padding = mapHeight > 240 ? 120 : mapHeight / 6;

      mapRef.current.fitBounds(bounds, {
        padding,
        linear: true,
      });
    },
    [tripLog]
  );

  const mapStateWithRef = useMemo(() => {
    return {
      tripPoints,
      polylines,
      markers,
      ref: mapRef,
    };
  }, [markers, polylines, tripPoints]);

  return (
    <BookingTripMapContext.Provider
      value={{
        mode,
        tripLog,
        states: {
          markerState: markerStateMemo,
          activeTripPoint,
        },
        map: mapStateWithRef,
        functions: {
          flyToMarker,
          toggleMarker,
          setActiveTripPoint,
          setMapBounds,
        },
      }}
    >
      {children}
    </BookingTripMapContext.Provider>
  );
};

export const useBookingTripMap = () => {
  const context = useContext(BookingTripMapContext);

  if (!context) {
    throw new Error("useBookingTripMap must be used within a BookingTripMapProvider");
  }

  return context;
};
