import { FixedPricingContextProvider, FixedPricingStateContextProvider, FixedPricingStatePanel } from "@/components/organisms";
import { StateBased } from "./StateBased";

export const PricingState = () => {
  return (
    <FixedPricingContextProvider>
      <FixedPricingStateContextProvider>
        <FixedPricingStatePanel />
      </FixedPricingStateContextProvider>
    </FixedPricingContextProvider>
  );
};

PricingState.StateBased = StateBased;
