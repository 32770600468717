import { pricingApi } from "../pricingApi";
import { transformDailyHire, transformDailyHireMinimum } from "./helpers";
import { GetDailyHireMinimumResponse, GetDailyHireResponse, UpdateDailyHireMinimumParams, UpdateDailyHireParams } from "./types";

export const dailyHireApi = pricingApi.enhanceEndpoints({ addTagTypes: ["DailyHire", "DailyHireMinimum"] }).injectEndpoints({
  endpoints: (builder) => ({
    getDailyHire: builder.query<GetDailyHireResponse, void>({
      query: () => "/pricing/periodic/daily",
      transformResponse: transformDailyHire,
      providesTags: ["DailyHire"],
    }),
    updateDailyHire: builder.mutation<void, UpdateDailyHireParams>({
      query: (body) => ({
        url: "/pricing/periodic/daily",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DailyHire"],
    }),
    getDailyHireMinimum: builder.query<GetDailyHireMinimumResponse, void>({
      query: () => "/pricing/periodic/daily/minimum",
      transformResponse: transformDailyHireMinimum,
      providesTags: ["DailyHireMinimum"],
    }),
    updateDailyHireMinimum: builder.mutation<void, UpdateDailyHireMinimumParams>({
      query: (body) => ({
        url: "/pricing/periodic/daily/minimum",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DailyHireMinimum"],
    }),
  }),
});

export const { useGetDailyHireQuery, useUpdateDailyHireMutation, useGetDailyHireMinimumQuery, useUpdateDailyHireMinimumMutation } =
  dailyHireApi;
