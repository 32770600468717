import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useReactivateVehicleMutation, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { useAppDispatch } from "@/redux/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast, clsx } from "@/utils";

interface ReactivateVehicleModalProps extends React.HTMLAttributes<HTMLDivElement> {
  pendingReactivate?: Vehicle;
  setPendingReactivate: (vehicle?: Vehicle) => void;
}

export const ReactivateVehicleModal = ({ pendingReactivate, setPendingReactivate, className, ...props }: ReactivateVehicleModalProps) => {
  const dispatch = useAppDispatch();
  const [archiveVehicle, { isLoading }] = useReactivateVehicleMutation();

  const handleReactivate = () => {
    if (!pendingReactivate) return;

    archiveVehicle(pendingReactivate.uuid)
      .unwrap()
      .then(() => {
        addToast("info", `Vehicle ${pendingReactivate.registrationNumber} has been added back to My Vehicles tab`);
        setPendingReactivate(undefined);
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal open={!!pendingReactivate} onClose={() => setPendingReactivate(undefined)} className="max-w-[514px] !p-5">
      <div className={clsx("relative", className)} {...props}>
        {isLoading && <Loading />}
        <div className="max-w-lg sm:flex sm:items-start ">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <Typography variant="title">Reactivate Vehicle</Typography>
            <div className="mt-3 ">
              <Typography variant="paragraph">
                Are you sure you want to reactivate <span className="text-info">{pendingReactivate?.registrationNumber}</span> ? This would
                bring it back to the My Vehicles tab.
              </Typography>
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button variant="secondary" onClick={() => setPendingReactivate(undefined)} className="px-8">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleReactivate}>
            Reactivate Vehicle
          </Button>
        </div>
      </div>
    </Modal>
  );
};
