import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Button,
  Loading,
  Panel,
  Recaptcha,
  Typography,
} from "@/components/atoms";
import { getErrorMessages, getErrorMessagesObject } from "@/helpers/reduxHelpers";
import { useOperator, useSiteConfig } from "@/hooks";
import { useLoginMutation, useRegisterClientMutation } from "@/redux/apis/auth/authApi";
import { useSignUpContext } from "../context";
import { OperatorEmblem } from "../contents";
import { SignUpClientFormData, signUpFormDataKey } from "./types";
import { signUpClientFormSchema } from "./schemas";
import { ConfirmPasswordField, ContactNumberField, EmailField, FirstNameField, HowDidYouHearAboutUsField, JobTitleField, LastNameField, PasswordField, CompanyField } from "./fields";
import { addToast } from "@/utils";
import { useNavigate } from "react-router-dom";
import { getPhpHostUrl } from "@/helpers/apiHelpers";

export const SignUpFormClient = () => {
  const navigate = useNavigate();
  const { apiUrl } = useSiteConfig();
  const {
    name,
    images: { emblem },
    providerCode,
    singleTenant,
    urls: { privacy },
    flags: { isSimpleSignupEnabled },
    availableAccountTypesOnRegister,
  } = useOperator();
  const { setStep, userTypeData } = useSignUpContext();
  const [registerClient, { isLoading: isLoadingRegister }] = useRegisterClientMutation();
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [token, setToken] = useState<null | string>(null);
  const methods = useForm<SignUpClientFormData>({ resolver: zodResolver(signUpClientFormSchema) });
  const {
    handleSubmit,
    setError,
    formState: { isDirty },
  } = methods;
  const userType = userTypeData?.type;
  const isLoading = isLoadingRegister || isLoadingLogin || isLoggingIn;

  const emailPhpRef = useRef<HTMLInputElement>(null);
  const passwordPhpRef = useRef<HTMLInputElement>(null);
  const submitPhpRef = useRef<HTMLInputElement>(null);

  const onRecaptchaChange = (token: string | null) => setToken(token);

  const onBackClick = () => {
    if (singleTenant) {
      if (availableAccountTypesOnRegister.length > 1) setStep("user-select");
      else navigate(`/login/${providerCode}`);
    } else setStep("provider-select");
  };

  const onSubmit = (data: SignUpClientFormData) => {
    if (!data) return;

    return registerClient({
      apiUrl,
      first_name: data.firstName,
      last_name: data.lastName,
      company_name: data.company,
      job_title: data.jobTitle,
      email: data.email,
      mobile: data.contactNumber,
      password: data.password.password,
      hear_about_us: data.howDidYouHearAboutUs,
      provider_code: providerCode,
      type: (userType === "travel_manager" || userType === "executive_assistant") ? "executive_assistant" : "passenger",
      registered_device: "browser",
    })
      .unwrap()
      .then(() => {
        setIsLoggingIn(true);
        login({ username: data.email, password: data.password.password })
          .unwrap()
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (import.meta.env.MODE === "production") loginPhp(data.email, data.password.password);
            else {
              if (isSimpleSignupEnabled) navigate("/profile/payment-method-setup");
              else navigate("/profile/signup-success?type=client");
            }
          }).catch(() => {
            setIsLoggingIn(false);
            addToast("danger", "Something went wrong. Please contact your administrator.");
          })
      })
      .catch((e) => {
        const errors = getErrorMessagesObject(e);
        for (const [key, value] of Object.entries(errors)) {
          if (signUpFormDataKey[key]) setError(signUpFormDataKey[key] as keyof SignUpClientFormData, { type: "custom", message: value });
        }
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  const loginPhp = (emailAddress: string, password: string) => {
    const emailInputElement = emailPhpRef.current;
    const passwordInputElement = passwordPhpRef.current;
    const submitElement = submitPhpRef.current;

    if (emailInputElement && passwordInputElement && submitElement) {
      emailInputElement.value = emailAddress;
      passwordInputElement.value = password;

      submitElement.click();
    }
  };

  const renderButtons = () => (
    <div className="flex flex-col md:flex-row md:justify-end gap-4 mt-6">
      <div className="hidden md:block">
        <Button
          variant="secondary"
          startIcon="ArrowLeft"
          size="lg"
          onClick={onBackClick}
        >
          Back
        </Button>
      </div>
      <Button
        variant="primary"
        type="submit"
        endIcon="ArrowRight"
        size="lg"
        className="relative w-full md:w-auto"
        disabled={!isDirty || isLoading || !token}
      >
        {isLoading && <Loading className="!bg-transparent" />}
        Sign up
      </Button>
      <Button
        variant="secondary"
        startIcon="ArrowLeft"
        size="lg"
        onClick={onBackClick}
        className="w-full md:hidden"
      >
        Back
      </Button>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <form className="w-full max-w-[680px]" onSubmit={handleSubmit(onSubmit)}>
        <Panel>
          <div className="flex flex-row mb-7 items-center">
            <div className="flex flex-1 flex-col gap-y-1.5">
              <Typography variant="h1">{`Sign up with ${name}`}</Typography>
              <Typography variant="paragraph">{`Become a client of ${name}`}</Typography>
            </div>
            <OperatorEmblem name={name} emblem={emblem} size="sm" />
          </div>
          <div className="grid animate-enter grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
            <FirstNameField className="col-span-2 lg:col-span-1" />
            <LastNameField className="col-span-2 lg:col-span-1" />
            {!isSimpleSignupEnabled && (
              <>
                <CompanyField className="col-span-2 lg:col-span-1" />
                <JobTitleField className="col-span-2 lg:col-span-1" />
              </>
            )}
            <EmailField className="col-span-2 lg:col-span-1" />
            <ContactNumberField className="col-span-2 lg:col-span-1" />
            <PasswordField className="col-span-2 lg:col-span-1" />
            <ConfirmPasswordField className="col-span-2 lg:col-span-1" />
            {!isSimpleSignupEnabled && <HowDidYouHearAboutUsField className="col-span-2" />}
            <Recaptcha onChange={onRecaptchaChange} className="col-span-2" />
          </div>
          <Typography variant="paragraph" className="mt-4 px-8 md:px-[65px] text-neutral-dark-gray text-center">
            By clicking sign-up you agree to the Terms & Conditions and acknowledge you have read our{" "}
            <a href={privacy} target="_blank" className="inline" rel="noreferrer">
              Privacy Policy
            </a>
          </Typography>
        </Panel>
        {renderButtons()}
      </form>
      <form 
        id="phpForm" 
        method="post" 
        action={`${getPhpHostUrl()}/login?referrer=dashboard&redirect=/dashboard/profile${ isSimpleSignupEnabled ? "/payment-method-setup" : "/signup-success?type=client" }`}
      >
        <input type="hidden" name="email" ref={emailPhpRef} />
        <input type="hidden" name="password" ref={passwordPhpRef} />
        <input className="hidden" type="submit" ref={submitPhpRef} />
      </form>
    </FormProvider>
  );
};
