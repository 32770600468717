import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddOnTextInput, Button, ErrorMessage, IconButton, Loading, RadioInput, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useReturnFundsMutation } from "@/redux/apis/booking/endpoints/payment";
import { useAppDispatch } from "@/redux/hooks";
import { setPaymentAction } from "@/redux/slices/booking/bookingSlice";
import { BookingPaymentLedgerLog } from "@/redux/slices/booking/types";
import { addToast, getCurrencySymbol } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

const schema = z.object({
  category: z.string().nonempty(),
  amount: z.coerce.number({ invalid_type_error: "This field is required." }).min(1),
  reason: z.string().nonempty(),
});

type FormData = z.infer<typeof schema>;

interface ReturnFundsProps {
  payment: BookingPaymentLedgerLog;
}

export const ReturnFunds = ({ payment }: ReturnFundsProps) => {
  const dispatch = useAppDispatch();

  const {
    id: bookingId,
    cost: { currency },
  } = useSelectedBooking();

  const [returnFunds, { isLoading }] = useReturnFundsMutation();
  const paymentAmount = Math.abs(payment.amount) / 100;

  const {
    register,
    formState: { isDirty, isSubmitting, errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: paymentAmount,
      reason: "",
    },
  });

  const onSubmit = async (data: FormData) =>
    returnFunds({
      bookingId,
      paymentId: payment.id,
      amount: data.amount * 100,
      reason: data.reason,
      direct_to: data.category,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Funds returned successfully");
        dispatch(setPaymentAction(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  const handleClose = () => dispatch(setPaymentAction(undefined));

  return (
    <section onSubmit={handleSubmit(onSubmit)} className="relative m-5 bg-neutral-surface-gray p-5">
      {isLoading && <Loading />}
      <header>
        <Typography variant="title">Return Funds</Typography>
        <Typography variant="paragraph" className="mt-2 text-neutral-dark-gray">
          Please exercise caution when using this page to return funds to the operator, if necessary.
        </Typography>
        <IconButton onClick={handleClose} iconName="close" variant="custom" isCustomIcon iconSize="lg" className="absolute right-5 top-5" />
      </header>
      <form className="mt-6 space-y-6 ">
        <fieldset className="flex gap-8">
          <RadioInput label="Driver" value="driver" {...register("category")} disabled={payment.category === "driver"} />
          <RadioInput label="Provider" value="provider" {...register("category")} disabled={payment.category === "provider"} />
          <RadioInput label="Platform" value="platform" {...register("category")} disabled={payment.category === "platform"} />
        </fieldset>
        <fieldset className="w-1/2">
          <Typography className="block leading-loose">Enter Return Amount</Typography>
          <div className="flex items-center gap-3">
            <AddOnTextInput
              startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
              {...register("amount", { valueAsNumber: true })}
              type="number"
              max={paymentAmount}
              step="0.01"
              autoFocus
            />
            <ErrorMessage className="leading-loose" errors={errors} name="amount" />
            <Typography variant="action" className="shrink-0 text-neutral-dark-gray">
              {getCurrencySymbol(currency)}
              {paymentAmount} Available
            </Typography>
          </div>
        </fieldset>
        <fieldset className="w-full">
          <Typography className="block leading-loose">Reason for Return</Typography>
          <TextInput placeholder="Enter reason for return" {...register("reason")} />
          <ErrorMessage className="leading-loose" errors={errors} name="reason" />
        </fieldset>
        <div className="flex justify-end">
          <Button variant="secondary" onClick={handleClose} className="mr-4" size="lg">
            Cancel Return
          </Button>
          <Button type="submit" disabled={isSubmitting || !isDirty} size="lg">
            Process Return
          </Button>
        </div>
      </form>
    </section>
  );
};
