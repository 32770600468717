import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { ExternalSupplier } from "@/redux/slices/externalSupplier/types";
import { getAssetUrl } from "@/utils";
import { accountTypes, tableColumns } from "./fixtures";
import { ExternalSuppliersTableData } from "./types";

export const generateTableColumns = () => {
  const columns: DataTableColumnDef<ExternalSuppliersTableData>[] = tableColumns.map((i) => ({
    accessorKey: i.id,
    header: i.header,
    cell: ({ row }) => row.original[i.id],
    ...(i.size && { size: i.size }),
    enableSorting: !i.isSortDisabled,
  }));

  return columns;
};

export const generateTableData = (items: ExternalSupplier[], onUpdateClick: (id: string) => void) => {
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex cursor-pointer items-center gap-2.5 hover:text-info" onClick={() => onUpdateClick(i.id)}>
            {renderAvatar(i.avatar, i.name)}
            <div className="flex flex-col">
              <Typography variant="paragraph">{i.name}</Typography>
            </div>
          </div>
        ),
        type: accountTypes[i.type],
        email: i.email,
        phone: i.phone,
        state: i.state,
        rating: (
          <Typography className="flex items-center">
            {i.rating} <Icon name="Star1" className="ml-2 inline fill-warning text-warning" variant="TwoTone" />
          </Typography>
        ),
        completion: `${i.profileCompletion * 100}%`,
        status: renderStatus(i.status),
        action: renderActions(i, onUpdateClick),
      } as ExternalSuppliersTableData)
  );

  return data;
};

const renderAvatar = (avatar: string, name: string) => (
  <div className="flex flex-shrink-0">
    {avatar ? (
      <img className="h-6 w-6 rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
    ) : (
      <div className="h-6 w-6 rounded-full bg-neutral-mid-gray" />
    )}
  </div>
);

export const renderStatus = (status: string) => {
  switch (status) {
    case "Pending Qualification":
    case "Application in Progress":
    case "Pending Approval":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="flex-shrink-0 h-2 w-2 rounded-full bg-neutral-dark-gray" /> {status}
        </Typography>
      );
    case "Approved":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="flex-shrink-0 h-2 w-2 rounded-full bg-success" /> {status}
        </Typography>
      );
    default:
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="flex-shrink-0 h-2 w-2 rounded-full bg-danger" /> {status}
        </Typography>
      );
  }
};

const renderActions = (supplier: ExternalSupplier, onUpdateClick: (id: string) => void) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onUpdateClick(supplier.id)}>
        <Icon name="Edit2" className="mr-2.5 text-neutral-black" size="sm" />
        <Typography className="text-neutral-black">Update Supplier</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};
