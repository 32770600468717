import { Link } from "react-router-dom";
import DownloadAppStore from "@/assets/images/download-appstore.png";
import DownloadGooglePlay from "@/assets/images/download-googleplay.png";
import { LinkButton, Typography } from "@/components/atoms";
import { useBookingDriverActivityContext } from "../context";

export const GuestLogin = () => {
  const {
    tripLog: {
      provider: { name, appDeepLink },
    },
  } = useBookingDriverActivityContext();

  return (
    <div className="space-y-4">
      <LinkButton to="/login" variant="primary" className="w-full" size="lg">
        Login to your account
      </LinkButton>

      <Typography variant="action" className="block">
        Get the {name} app for FREE!
      </Typography>
      <div className="flex gap-4">
        <Link to={appDeepLink} target="_blank">
          <img src={DownloadAppStore} className="w-[120px]" alt="Download on the App Store" />
        </Link>
        <Link to={appDeepLink} target="_blank">
          <img src={DownloadGooglePlay} className="w-[135px]" alt="Get it on Google Play" />
        </Link>
      </div>
    </div>
  );
};
