import { useNavigate } from "react-router-dom";
import { Button, Icon, Tooltip, Typography } from "@/components/atoms";
import { DataTable, DataTableColumnDef } from "@/components/molecules";
import { StandardCancelFeeInterval } from "@/redux/slices/pricing/types";
import { StandardCancelFeesTableData } from "./types";

export interface StandardCancelFeesTableProps {
  intervals: StandardCancelFeeInterval[];
}

export const StandardCancelFeesTable = ({ intervals }: StandardCancelFeesTableProps) => {
  const navigate = useNavigate();

  const data = intervals.map((i, idx) => {
    return {
      cancellationHours: `${idx < 1 ? "0" : intervals[idx - 1].rangeTo} - ${i.rangeTo} hours`,
      cancellationRate: `${i.rate}%`,
    } as StandardCancelFeesTableData;
  });

  const columns: DataTableColumnDef<StandardCancelFeesTableData>[] = [
    {
      accessorKey: "cancellationHours",
      header: () => (
        <div className="flex flex-row items-center gap-2">
          <Typography>Cancellation Hours</Typography>
          <Tooltip content="Number of hours before pickup time" placement="right" maxWidth={400}>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "cancellationRate",
      header: () => (
        <div className="flex flex-row items-center gap-2">
          <Typography>Cancellation Rate (%)</Typography>
          <Tooltip
            content="Percentage of the total trip cost that would be charge to your customer for a cancellation in this time window"
            placement="right"
            maxWidth={400}
          >
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </div>
      ),
      enableSorting: false,
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-3 mb-4 sm:flex-row sm:items-center">
        <div className="flex-1">
          <Typography variant="h3" className="leading-8">
            Standard Cancellation Fees
          </Typography>
          <Typography className="text-neutral-dark-gray">Set the cancellation fees for your business.</Typography>
        </div>
        <div className="flex-shrink-0">
          <Button startIcon="Edit2" onClick={() => navigate(`edit/standard-fees`)} className="bg-primary px-6 hover:bg-primary-dark">
            Edit
          </Button>
        </div>
      </div>
      <DataTable columns={columns} data={data} className="[&_table]:table-auto overflow-x-auto w-full" />
    </>
  );
};
