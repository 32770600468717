import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Loading, Panel, Recaptcha, Typography } from "@/components/atoms";
import { getErrorMessages, getErrorMessagesObject } from "@/helpers/reduxHelpers";
import { useOperator, useSiteConfig } from "@/hooks";
import { useLoginMutation, useRegisterUserMutation } from "@/redux/apis/auth/authApi";
import { addToast } from "@/utils";
import { signUpFormDataKey, SignUpInviteFormData } from "./types";
import { zodResolver } from "@hookform/resolvers/zod";
import { signUpInviteFormSchema } from "./schemas";
import { OperatorEmblem } from "../contents";
import { ConfirmPasswordField, ContactNumberField, EmailField, FirstNameField, LastNameField, PasswordField } from "./fields";
import { getPhpHostUrl } from "@/helpers/apiHelpers";

interface SignUpFormInviteProps {
  email: string;
  inviteToken: string;
}

export const SignUpFormInvite = ({ email, inviteToken }: SignUpFormInviteProps) => {
  const navigate = useNavigate();
  const { apiUrl } = useSiteConfig();
  const {
    name,
    images: { emblem },
    providerCode,
    urls: { privacy },
  } = useOperator();
  const [registerUser, { isLoading: isLoadingRegister }] = useRegisterUserMutation();
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [token, setToken] = useState<null | string>(null);
  const methods = useForm<SignUpInviteFormData>({ resolver: zodResolver(signUpInviteFormSchema), defaultValues: { email } });
  const {
    handleSubmit,
    setError,
    formState: { isDirty },
  } = methods;
  const isLoading = isLoadingRegister || isLoadingLogin || isLoggingIn;

  const emailPhpRef = useRef<HTMLInputElement>(null);
  const passwordPhpRef = useRef<HTMLInputElement>(null);
  const submitPhpRef = useRef<HTMLInputElement>(null);

  const onRecaptchaChange = (token: string | null) => setToken(token);

  const onSubmit = (data: SignUpInviteFormData) => {
    if (!data) return;

    registerUser({
      apiUrl,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      mobile: data.contactNumber,
      password: data.password.password,
      provider_code: providerCode,
      registered_device: "browser",
      inviteToken: inviteToken,
    })
      .unwrap()
      .then(() => {
        setIsLoggingIn(true);
        login({ username: data.email, password: data.password.password })
          .unwrap()
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (import.meta.env.MODE === "production") loginPhp(data.email, data.password.password);
            else navigate("/profile/signup-success?type=invite");
          }).catch(() => {
            setIsLoggingIn(false);
            addToast("danger", "Something went wrong. Please contact your administrator.");
          })
      })
      .catch((e) => {
        const errors = getErrorMessagesObject(e);
        for (const [key, value] of Object.entries(errors)) {
          if (signUpFormDataKey[key]) setError(signUpFormDataKey[key] as keyof SignUpInviteFormData, { type: "custom", message: value });
        }
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  const loginPhp = (emailAddress: string, password: string) => {
    const emailInputElement = emailPhpRef.current;
    const passwordInputElement = passwordPhpRef.current;
    const submitElement = submitPhpRef.current;

    if (emailInputElement && passwordInputElement && submitElement) {
      emailInputElement.value = emailAddress;
      passwordInputElement.value = password;

      submitElement.click();
    }
  };

  const renderButtons = () => (
    <div className="flex flex-col md:flex-row md:justify-end gap-4 mt-6">
      <Button
        variant="primary"
        type="submit"
        endIcon="ArrowRight"
        size="lg"
        className="relative w-full md:w-auto"
        disabled={!isDirty || isLoading || !token}
      >
        {isLoading && <Loading className="!bg-transparent" />}
        Sign up
      </Button>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <form className="w-full max-w-[680px]" onSubmit={handleSubmit(onSubmit)}>
        <Panel>
          <div className="flex flex-row mb-7 items-center">
            <div className="flex flex-1 flex-col gap-y-1.5">
              <Typography variant="h1">{`Sign up with ${name}`}</Typography>
              <Typography variant="paragraph">{`You have been invited to join ${name}. Fill up the necessary information to join.`}</Typography>
            </div>
            <OperatorEmblem name={name} emblem={emblem} size="sm" />
          </div>
          <div className="grid animate-enter grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
            <FirstNameField className="col-span-2 lg:col-span-1" />
            <LastNameField className="col-span-2 lg:col-span-1" />
            <EmailField disabled className="col-span-2 lg:col-span-1" />
            <ContactNumberField className="col-span-2 lg:col-span-1" />
            <PasswordField className="col-span-2 lg:col-span-1" />
            <ConfirmPasswordField className="col-span-2 lg:col-span-1" />
            <Recaptcha onChange={onRecaptchaChange} className="col-span-2" />
          </div>
          <Typography variant="paragraph" className="mt-4 px-8 md:px-[65px] text-neutral-dark-gray text-center">
            By clicking sign-up you agree to the Terms & Conditions and acknowledge you have read our{" "}
            <a href={privacy} target="_blank" className="inline" rel="noreferrer">
              Privacy Policy
            </a>
          </Typography>
        </Panel>
        {renderButtons()}
      </form>
      <form id="phpForm" method="post" action={`${getPhpHostUrl()}/login?referrer=dashboard&redirect=/dashboard/profile/signup-success?type=invite`}>
        <input type="hidden" name="email" ref={emailPhpRef} />
        <input type="hidden" name="password" ref={passwordPhpRef} />
        <input className="hidden" type="submit" ref={submitPhpRef} />
      </form>
    </FormProvider>
  );
};
