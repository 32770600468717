import { usePagination, useSorting } from "@/hooks";
import { GetDebitHistoryParams } from "@/redux/apis/payment/record/types";

export const useGetDebitHistoryParams = () => {
  const { page, pageSize } = usePagination();
  const { sortKey, direction } = useSorting();

  const params: GetDebitHistoryParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
  };

  return { params, page, pageSize, sort: sortKey, direction };
};
