import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Icon, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddTransferTypeMutation } from "@/redux/apis/config/pricing/transferTypes";
import { addToast } from "@/utils";
import { TransferTypeForm } from "./TransferTypeForm";
import { transferTypeFormSchema } from "./fixtures";
import { TransferTypeFormData } from "./types";

interface AddTransferTypeFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export const AddTransferTypeForm = ({ onClose, onSuccess, ...props }: AddTransferTypeFormProps) => {
  const [addTransferType, { isLoading }] = useAddTransferTypeMutation();
  const methods = useForm<TransferTypeFormData>({
    defaultValues: {
      name: "",
      description: "",
      costMultiplier: "",
      treatAsSpecialEvent: false,
      tripType: "hourly_rate",
      status: true,
      adminOnlyTransferType: false,
      automatedDispatchEnabled: true,
      transferTypeTaxExempt: false,
    },
    resolver: zodResolver(transferTypeFormSchema),
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    addTransferType({
      name: data.name,
      is_active: data.status,
      is_special_event: data.treatAsSpecialEvent,
      is_admin_only: data.adminOnlyTransferType,
      is_dispatch_enabled: data.automatedDispatchEnabled,
      description: data.description,
      multiplier: Number(data.costMultiplier),
      type: data.tripType,
      tax_status: data.transferTypeTaxExempt ? "exempt" : "liable",
      vehicle_categories: [],
    })
      .unwrap()
      .then(onSuccess)
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const renderModalHeader = () => {
    return (
      <div className="sm:flex sm:items-start">
        <div className="my-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Add Transfer Type
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
      {renderModalHeader()}
      <form id="add-transfer-type-form" className="relative flex flex-col" onSubmit={onSubmit} {...props}>
        {isLoading && <Loading />}
        <TransferTypeForm className="mt-2 flex-1" />
        <div className="mt-[42px]">
          <div className="flex justify-end gap-4">
            <Button variant="secondary" onClick={onClose} className="px-8">
              Back
            </Button>
            <Button variant="primary" type="submit" form="add-transfer-type-form" className="px-8" disabled={!isDirty}>
              Add Transfer Type
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
