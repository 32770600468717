import { useMemo, useState } from "react";
import { Button, Modal, Loading } from "@/components/atoms";
import { DataTable, Pagination } from "@/components/molecules";
import { usePagination, useSorting } from "@/hooks";
import {
  useDeleteVehicleCategoryMutation,
  useReorderVehicleCategoriesMutation,
  useToggleVehicleCategoryMutation,
} from "@/redux/apis/config/vehicle/vehicleCategories/vehicleCategoriesApi";
import { useAppDispatch } from "@/redux/hooks";
import { setVehicleCategories, toggleVehicleCategory } from "@/redux/slices/pricing/pricingSlice";
import { VehicleCategory } from "@/redux/slices/pricing/types";
import { Page } from "@/redux/types";
import { addNotification, clsx } from "@/utils";
import { modals } from "./fixtures";
import { generateEditorColumns, generateTableEditorData } from "./helpers";
import { VehicleCategoriesTableData } from "./types";

interface VehicleCategoriesTableEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  items: VehicleCategory[];
  onEditItem: (item: VehicleCategory) => void;
}

export const VehicleCategoriesTableEditor = ({ items, onEditItem, className, ...props }: VehicleCategoriesTableEditorProps) => {
  const dispatch = useAppDispatch();
  const { sorting, setSorting } = useSorting();
  const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null);
  const [toggle, { isLoading: toggleLoading }] = useToggleVehicleCategoryMutation();
  const [remove, { isLoading: removeLoading }] = useDeleteVehicleCategoryMutation();
  const [reorder, { isLoading: reorderLoading }] = useReorderVehicleCategoriesMutation();
  const [activeModal, setActiveModal] = useState<"error" | "exit" | "delete" | null>(null);
  const isLoading = toggleLoading || removeLoading || reorderLoading;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleItemStatus = (id: string, active: boolean) => {
    const item = items.find((i) => i.id === id);
    if (!item) return;

    dispatch(toggleVehicleCategory({ id, active }));

    toggle({ uuid: id, is_active: active })
      .unwrap()
      .then(() => {
        addNotification("info", `Changed active status: ${active ? "On" : "Off"}`, `Changed active status for "${item.name}".`);
      })
      .catch(() => setActiveModal("error"));
  };

  const onReorder = (sortedItems: VehicleCategoriesTableData[]) => {
    const mapping: { [key: string]: number } = {};

    sortedItems.forEach((item, index) => {
      const newOrder = items[index].order;
      mapping[item.id] = newOrder;
    });

    const newItems = items.map((item) => ({ ...item, order: mapping[item.id] })).sort((a, b) => a.order - b.order);

    dispatch(setVehicleCategories(newItems));

    reorder(mapping)
      .unwrap()
      .then(() => {
        addNotification("info", "Updated Order", "Successfully updated vehicle category order.");
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const onDeleteItem = (item: VehicleCategory) => {
    setPendingDeleteId(item.id);
    setActiveModal("delete");
  };

  const handleDeleteItem = () => {
    setActiveModal(null);
    const item = items.find((i) => i.id === pendingDeleteId);
    if (!item) return;

    remove(item.id)
      .unwrap()
      .then(() => {
        addNotification("info", `Deleted 1 row`, `Deleted "${item.name}"`);
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteItem} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  const data = useMemo(
    () => generateTableEditorData(items, toggleItemStatus, onEditItem, onDeleteItem),
    [items, onEditItem, toggleItemStatus]
  );
  const columns = useMemo(() => generateEditorColumns(data), [data]);

  return (
    <div className="relative">
      {activeModal && (
        <Modal
          open={true}
          title={modals[activeModal].title}
          description={modals[activeModal].description}
          onClose={() => setActiveModal(null)}
          renderButtons={renderModalButtons}
        />
      )}
      {isLoading && <Loading className="rounded-lg" />}
      <DataTable
        columns={columns}
        data={data}
        className={clsx("w-full overflow-x-auto [&_table]:table-auto", className)}
        onReorder={onReorder}
        sorting={sorting}
        setSorting={setSorting}
        {...props}
      />
    </div>
  );
};

const VehicleCategoriesTableEditorPagination = ({ page }: { page: Page }) => {
  const { current, size, total, count } = page;
  const { handlePageClick, handleSizeChange } = usePagination("config-vehicle-categories", 30);

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

VehicleCategoriesTableEditor.Pagination = VehicleCategoriesTableEditorPagination;
