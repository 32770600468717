import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { Filter } from "@/redux/types";
import { ClientsFilterFormData } from "../types";

interface AccountTypeFilterProps {
  options: Filter;
}

export const AccountTypeFilter = ({ options }: AccountTypeFilterProps) => {
  const { control, setValue, watch } = useFormContext<ClientsFilterFormData>();
  const watchAccountType = watch("accountType");
  const filterItems = Object.entries(options).map(([key, value]) => ({
    id: key,
    value: key,
    label: value,
  }));

  const renderSelectAll = () => {
    const watchAccountTypeLength = watchAccountType.length;
    return (
      <CheckboxInput
        id="accountTypeSelectAll"
        label="Select all"
        onChange={({ target }) =>
          target.checked
            ? setValue(
                "accountType",
                filterItems.map((i) => i.value)
              )
            : setValue("accountType", [])
        }
        checked={watchAccountTypeLength > 0 && watchAccountTypeLength === filterItems.length}
      />
    );
  };

  return (
    <div className="rounded-lg bg-neutral-surface-gray p-5">
      <Typography variant="paragraph">Account Type</Typography>
      <Controller
        control={control}
        name="accountType"
        render={({ field }) => (
          <div className="mt-[18px] flex flex-col gap-6">
            {renderSelectAll()}
            {filterItems.map((i) => (
              <CheckboxInput
                key={i.id}
                label={i.label}
                value={i.value}
                checked={field.value.includes(i.value)}
                onChange={({ target }) => {
                  if (target.checked) {
                    field.onChange([...field.value, target.value]);
                  } else {
                    field.onChange(field.value.filter((value) => value !== target.value));
                  }
                }}
                variant="check"
              />
            ))}
          </div>
        )}
      />
    </div>
  );
};
