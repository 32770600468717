import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TransferTypeFormData } from "../types";

export const AdminOnlyTransferTypeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TransferTypeFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="action">Admin Only Transfer Type</Typography>
      <Typography variant="small" className="leading-6 text-neutral-dark-gray">
        If enabled, only admins can use this transfer type. Clients must contact you to modify or cancel a job with this transfer type.
      </Typography>
      <Controller
        name="adminOnlyTransferType"
        control={control}
        render={({ field }) => (
          <div className="flex flex-row items-center gap-x-4">
            <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
            <Typography className="inline">{field.value ? "Enabled" : "Disabled"}</Typography>
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="adminOnlyTransferType" />
    </div>
  );
};
