import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Typography, Modal } from "@/components/atoms";
import { SendToPrinterContext } from "./context";

export const Step3 = () => {
  const navigate = useNavigate();
  const { step, setStep } = useContext(SendToPrinterContext);

  const onClick = () => {
    navigate("../");
  };

  return (
    <Modal open={step > 2} title="" onClose={() => setStep(1)}>
      <div className="flex max-w-[515px] flex-col items-center px-4 text-center">
        <Typography variant="title" className="mb-4">
          Way to go!
        </Typography>
        <Typography className="mb-4 text-neutral-dark-gray">
          Your Email has been sent to our Printer Supplier. Kindly check your inbox or spam folders from time to time.
        </Typography>
        <Icon name="envelope-approved" isCustom size={175} className="mb-8 inline-flex" />
        <Button variant="primary" className="w-full" onClick={onClick}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
