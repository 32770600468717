import { IconButton, Modal, ModalProps } from "@/components/atoms";
import { getApiHostUrl } from "@/helpers/apiHelpers";
import { useOperator } from "@/hooks";
import { QRTemplate } from "@/redux/slices/qr/types";

export interface PDFPreviewModalProps extends Omit<ModalProps, "title"> {
  template?: QRTemplate;
}

export const PDFPreviewModal = ({ open, template, onClose }: PDFPreviewModalProps) => {
  const { id: operatorId } = useOperator();

  const previewUrl = `${getApiHostUrl()}/v3/provider/${operatorId}/assets-printed-qr/${template?.slug}/preview`;

  return (
    <Modal open={open} title={template?.name || ""} onClose={onClose} className="relative">
      <IconButton
        iconName="close"
        isCustomIcon
        onClick={onClose}
        data-testid="panel-close-button"
        variant="tertiary"
        className="absolute right-0 top-0 m-4"
      />
      <div className="max-w-screen-sm p-16">{template && <img src={previewUrl} className="w-full" />}</div>
    </Modal>
  );
};
