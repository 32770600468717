import { Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface BreakdownProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode[];
}

export const Breakdown = ({ className, children, ...props }: BreakdownProps) => {
  return (
    <div className={clsx("space-y-4", className)} {...props}>
      <ul role="list" className="space-y-2 text-neutral-black">
        {children}
      </ul>
    </div>
  );
};

interface ItemProps extends React.HTMLAttributes<HTMLLIElement> {
  label: string;
  value: string;
}

const Item = ({ label, value, className }: ItemProps) => {
  return (
    <li className={clsx("flex items-center justify-between", className)}>
      <Typography variant="paragraph">{label}</Typography>
      <span className="mx-2 mt-1 flex-1  border-dotted border-neutral-dark-gray"></span>
      <Typography variant="action">{value}</Typography>
    </li>
  );
};

Breakdown.Item = Item;
