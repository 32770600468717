import { driverErrorTooltips, driverWarningTooltips } from "../fixtures/driver";

export const getDriverErrorTooltip = (error: { key: string; value: string }) => {
  return driverErrorTooltips[error.key as keyof typeof driverErrorTooltips][
    error.value as keyof (typeof driverErrorTooltips)[keyof typeof driverErrorTooltips]
  ];
};

export const getDriverWarningTooltip = (error: string) => {
  return driverWarningTooltips[error as keyof typeof driverWarningTooltips];
};
