import { createApi } from "@reduxjs/toolkit/dist/query/react";
import camelcaseKeys from "camelcase-keys";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { SubmitFeedbackParams, SubmitFeedbackRawResponse } from "./types";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: baseQueryWithoutProvider("content"),
  endpoints: (builder) => ({
    submitFeedback: builder.mutation({
      query: (body: SubmitFeedbackParams) => ({
        url: "/feedback",
        method: "POST",
        body,
      }),
      transformResponse: (response: SubmitFeedbackRawResponse) => camelcaseKeys(response, { deep: true }),
    }),
  }),
});

export const { useSubmitFeedbackMutation } = contentApi;
