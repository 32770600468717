import { formatInTimeZone } from "date-fns-tz";
import { Icon, Tooltip, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { BookingFlightNumber } from "../../../common/BookingFlightNumber/BookingFlightNumber";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const BasicDetails = () => {
  const profile = useProfile();
  const { id: bookingId, dates, stops, referenceExternal } = useSelectedBooking();
  const flight = stops.find((stop) => stop.flight)?.flight;

  return (
    <div className="relative space-y-3">
      <Icon name="ArchiveBook" variant="Bold" size="lg" className="absolute -inset-1.5 rounded-full bg-primary p-1 text-white" />
      <div className="!mt-0 flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="min-w-[50px] text-neutral-black/70">
          Job ID:
        </Typography>
        <Typography variant="small" className="col-span-3">
          J{bookingId}
        </Typography>
      </div>
      <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="min-w-[50px] text-neutral-black/70">
          Date:
        </Typography>
        <Typography variant="small">{formatInTimeZone(new Date(dates.pickup), dates.timezone, profile.dateFormat)}</Typography>
      </div>
      <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="min-w-[50px] text-neutral-black/70">
          Time:
        </Typography>
        <div className="flex flex-wrap gap-1">
          <Typography variant="small">{formatInTimeZone(new Date(dates.pickup), dates.timezone, profile.timeFormat)}</Typography>
          {profile.timezone.name !== dates.timezone && (
            <Typography variant="small" className="whitespace-nowrap leading-snug text-neutral-dark-gray">
              ({dates.timezone.split("/")[1]})
              <Tooltip content={formatInTimeZone(dates.pickup, profile.timezone.name, `${profile.timeFormat} zzzz`)} placement="bottom">
                <Icon name="InfoCircle" variant="Bold" size="md" className="ml-1 inline -translate-y-[1px] rotate-180" />
              </Tooltip>
            </Typography>
          )}
        </div>
      </div>
      {flight && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="min-w-[50px] text-neutral-black/70">
            Flight:
          </Typography>
          <Typography variant="small" className="self-start">
            <BookingFlightNumber className="-translate-y-0.5" status={flight.status} number={flight.number} delay={flight.delay} />
          </Typography>
        </div>
      )}
      {referenceExternal && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="min-w-[50px] text-neutral-black/70">
            Ref:
          </Typography>
          <Typography variant="small">{referenceExternal}</Typography>
        </div>
      )}
    </div>
  );
};
