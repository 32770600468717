import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Stars, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryOffloadMinRatingField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="paragraph" className="flex items-center gap-2">
        Minimum Rating Allowed
      </Typography>
      <Controller
        name="offloadMinimumRating"
        control={control}
        render={({ field }) => (
          <Stars className="-mt-3" color1="#D1D2D5" size={32} value={field.value} onChange={(newRating) => field.onChange(newRating)} />
        )}
      />

      <ErrorMessage errors={errors} name="offloadMinimumRating" />
    </div>
  );
};
