import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl } from "@/utils";
import { ArchivedVehicleTableData } from "./types";

export const generateArchivedVehicleTableData = (
  operatorId: string,
  vehicles: Vehicle[],
  onArchiveClick: (vehicle: Vehicle) => void
): ArchivedVehicleTableData[] => {
  const data = vehicles.map(
    (vehicle) =>
      ({
        numberPlate: renderNumberPlate(vehicle, operatorId),
        nickname: vehicle.identifier,
        make: (
          <div className="flex items-center gap-2">
            <div className="flex h-6 w-6 items-center justify-center rounded bg-neutral-gray">
              <img
                src={getAssetUrl(vehicle.model.avatar, {
                  width: 36,
                  height: 36,
                  fit: "contain",
                  background: "transparent",
                })}
                alt={vehicle.model.make}
                className="h-[18px] w-[18px]"
              />
            </div>

            {vehicle.model.make}
          </div>
        ),
        model: vehicle.model.model,
        year: vehicle.year?.toString() ?? "-",
        actions: renderActions(vehicle, operatorId, onArchiveClick),
      } as ArchivedVehicleTableData)
  );

  return data;
};

const renderNumberPlate = (vehicle: Vehicle, operatorId: string) => {
  const link = `/operator/${operatorId}/vehicles/archived-vehicles/${vehicle.uuid}`;

  return (
    <Link to={link} className="flex items-center gap-1 hover:text-info">
      {vehicle.registrationNumber}
    </Link>
  );
};

const renderActions = (vehicle: Vehicle, operatorId: string, onReactivateClick: (client: Vehicle) => void) => {
  const link = `/operator/${operatorId}/vehicles/archived-vehicles/${vehicle.uuid}`;

  return (
    <>
      <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
        <Link to={link}>
          <DropdownMenu.Item>
            <Icon name="ArrowRight" className="mr-2.5 text-primary-dark" size="sm" />
            <Typography className="text-primary-dark">View Vehicle</Typography>
          </DropdownMenu.Item>
        </Link>
        {vehicle.metadata.isArchived && (
          <DropdownMenu.Item onClick={() => onReactivateClick(vehicle)}>
            <Icon name="Refresh2" className="mr-2.5 " size="sm" />
            <Typography className="text-primary-dark">Reactivate Vehicle</Typography>
          </DropdownMenu.Item>
        )}
      </DropdownMenu>
    </>
  );
};
