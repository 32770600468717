import { Loading } from "@/components/atoms";
import { useLegacySiteConfig } from "./hooks/useLegacySiteConfig";
import { useRMConfig } from "./hooks/useRMConfig";

const hasRMConfig = window.rm && window.rm.config;

export const ConfigProvider = ({ children }: React.PropsWithChildren) => {
  if (hasRMConfig) return <RMConfigProvider>{children}</RMConfigProvider>;
  return <LegacyConfigProvider>{children}</LegacyConfigProvider>;
};

const LegacyConfigProvider = ({ children }: React.PropsWithChildren) => {
  const { isReady } = useLegacySiteConfig();

  return isReady ? <>{children}</> : <Loading />;
};

const RMConfigProvider = ({ children }: React.PropsWithChildren) => {
  const { isReady } = useRMConfig();

  return isReady ? <>{children}</> : <Loading />;
};
