import { ability } from "@/casl";
import { defineAbilities } from "@/casl/ability";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useToggleAddOnMutation } from "@/redux/apis/addOn/addOnApi";
import { useLazyGetOperatorQuery } from "@/redux/apis/operator/operatorApi";
import { useAppDispatch } from "@/redux/hooks";
import { updateOperator } from "@/redux/slices/operator/operatorSlice";
import { RawErrorResponse } from "@/redux/types";
import { addToast } from "@/utils";

type ToggleAddOnParams = {
  onEnableSuccess: () => void;
  onDisableSuccess: () => void;
  onEnableError: (e: RawErrorResponse) => void;
  onDisableError: (e: RawErrorResponse) => void;
  shouldUpdateOperator: boolean;
};

export const useToggleAddOn = ({ onEnableSuccess, onDisableSuccess, onEnableError, onDisableError, shouldUpdateOperator }: ToggleAddOnParams) => {
  const { id: operatorId } = useOperator();
  const dispatch = useAppDispatch();
  const [toggle, { isLoading: isLoadingToggle }] = useToggleAddOnMutation();
  const [getOperator, { isFetching: isFetchingOperator }] = useLazyGetOperatorQuery();
  const isLoading = isLoadingToggle || isFetchingOperator;

  const toggleAddOn = (id: string, enabled: boolean) => {
    const handleOnSuccess = () => enabled ? onEnableSuccess() : onDisableSuccess();

    toggle({ id, enabled })
      .unwrap()
      .then(() => {
        if (shouldUpdateOperator) {
          getOperator(operatorId).unwrap().then((res) => {
            const { data } = res;
            dispatch(updateOperator(data));
            ability.update(defineAbilities(data.permissions));
            handleOnSuccess();
          }).catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
        } else handleOnSuccess();
      })
      .catch((e) => enabled ? onEnableError(e) : onDisableError(e));
  };

  return { toggleAddOn, isLoading };
};
