import { Ability } from "@casl/ability";
import { createContextualCan, useAbility } from "@casl/react";
import { createContext } from "react";
import { AppAbility, AppAction, AppSubject } from "./types";

const AbilityContext = createContext<typeof ability>({} as AppAbility);

export const ability = new Ability<[AppAction, AppSubject]>();
export const AbilityProvider = AbilityContext.Provider;
export const Can = createContextualCan(AbilityContext.Consumer);
export const useAppAbility = () => useAbility(AbilityContext);
