import { pricingApi } from "../pricingApi";
import { transformUnscheduledStops } from "./helpers";
import { GetUnscheduledStopsResponse, UpdateUnscheduledStopsResponse, UpdateUnscheduledStopsParams } from "./types";

export const unscheduledStopsApi = pricingApi.enhanceEndpoints({ addTagTypes: ["UnscheduledStops"] }).injectEndpoints({
  endpoints: (builder) => ({
    getUnscheduledStops: builder.query<GetUnscheduledStopsResponse, void>({
      query: () => `/pricing/fees`,
      transformResponse: transformUnscheduledStops,
      providesTags: ["UnscheduledStops"],
    }),
    updateUnscheduledStops: builder.mutation<UpdateUnscheduledStopsResponse, UpdateUnscheduledStopsParams>({
      query: (body) => ({
        url: `/pricing/fees`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UnscheduledStops"],
    }),
  }),
});

export const { useGetUnscheduledStopsQuery, useUpdateUnscheduledStopsMutation, useLazyGetUnscheduledStopsQuery } = unscheduledStopsApi;
