import { forwardRef, useState } from "react";
import { TextInput, TextInputProps } from "../";

export type PasswordInputProps = Omit<TextInputProps, "variant" | "type" | "onIconClick">;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextInput
      type={showPassword ? "text" : "password"}
      iconName={showPassword ? "Eye" : "EyeSlash"}
      iconPosition="end"
      onIconClick={() => setShowPassword(!showPassword)}
      ref={ref}
      {...props}
    />
  );
});

PasswordInput.displayName = "PasswordInput";
