import BookingRejected from "@/assets/images/booking/envelope-declined.svg";
import { Container, Typography } from "@/components/atoms";
import { GeneralFooterOld, GeneralHeaderOld } from "@/components/organisms";
import { useBranding, useSiteConfig } from "@/hooks";

export const BookingOfferRejected = () => {
  const { branding, name } = useSiteConfig();
  useBranding(branding);

  return (
    <div className="bg-transparent">
      <GeneralHeaderOld />
      <Container className="relative z-10 flex h-screen flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-6 p-4">
          <img src={BookingRejected} className="-mt-12 h-[256px] w-[256px] " />
          <Typography variant="h2">Booking Offer Declined: Thank You for Your Interest!</Typography>
          <Typography className="w-[768px] max-w-full text-center">
            Thank you, you have now declined this booking offer and we have notified {name}. We look forward to working with you in the
            future.
          </Typography>
        </div>
      </Container>
      <GeneralFooterOld className="z-20" />
    </div>
  );
};
