import { isEmpty } from "ramda";
import { useGetCloseOptionsQuery } from "@/redux/apis/booking/endpoints/closeJob";
import { GetCloseJobOptionsParams } from "@/redux/apis/booking/types";

type BookingFees =
  | {
      additionalWaitTimeFee: number;
      additionalRunTimeFee: number;
      unscheduledStopsFee?: number;
      isLoading: true;
    }
  | {
      additionalWaitTimeFee: number;
      additionalRunTimeFee: number;
      unscheduledStopsFee: number;
      isLoading: false;
    };

export const useBookingFees = (bookingId: string, extras: GetCloseJobOptionsParams): BookingFees => {
  const { data: fees, isFetching } = useGetCloseOptionsQuery({ bookingId, ...extras }, { skip: isEmpty(extras) });

  return {
    isLoading: isFetching,
    additionalWaitTimeFee: fees?.wait_time,
    additionalRunTimeFee: fees?.additional_run_time,
    unscheduledStopsFee: fees?.unscheduled_stops,
  } as BookingFees;
};
