import { zodResolver } from "@hookform/resolvers/zod";
import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Loading, Panel, Typography } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getErrorMessages, getErrorMessagesObject } from "@/helpers/reduxHelpers";
import { useLazyGetProviderQuery, useLoginMutation, useRegisterOperatorMutation } from "@/redux/apis/auth/authApi";
import { useAppDispatch } from "@/redux/hooks";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";
import { addToast, clsx } from "@/utils";
import { useSignUpContext } from "../context";
import { signUpOperatorFormSchema } from "./schemas";
import { signUpFormDataKey, SignUpOperatorFormData } from "./types";
import { SignUpFormOperatorStep1, SignUpFormOperatorStep2, SignUpFormOperatorStep3, SignUpFormOperatorStep4, SignUpFormOperatorStep5 } from "../contents";
import { isEmpty } from "ramda";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOperator } from "@/hooks";
import { getPhpHostUrl } from "@/helpers/apiHelpers";

export const SignUpFormOperator = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { name, availableAccountTypesOnRegister, providerCode } = useOperator();
  const paramsUserType = params.get("type");
  const dispatch = useAppDispatch();
  const { setStep, formStep, setFormStep } = useSignUpContext();
  const [registerOperator, { isLoading: isLoadingRegister }] = useRegisterOperatorMutation();
  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [getProvider, { isLoading: isFetchingProvider }] = useLazyGetProviderQuery();
  const [token, setToken] = useState<null | string>(null);
  const methods = useForm<SignUpOperatorFormData>({
    resolver: zodResolver(signUpOperatorFormSchema),
    defaultValues: { statesOfOperation: [] },
  });
  const {
    handleSubmit,
    trigger,
    watch,
    setError,
    formState: { isDirty },
  } = methods;
  const values = watch();
  const isLoading = isLoadingRegister || isLoadingLogin || isLoggingIn;

  const emailPhpRef = useRef<HTMLInputElement>(null);
  const passwordPhpRef = useRef<HTMLInputElement>(null);
  const submitPhpRef = useRef<HTMLInputElement>(null);

  const onBackClick = () => {
    if (formStep < 2) {
      if (availableAccountTypesOnRegister.length > 1) setStep("user-select");
      else navigate(`/login/${providerCode}`);
    } else setFormStep(formStep - 1);
  };

  const onNextClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let valid = false;

    switch (formStep) {
      case 1:
        valid = await trigger(["statesOfOperation"], {
          shouldFocus: true,
        });
        break;
      case 2:
        valid = await trigger(["firstName", "lastName", "email", "contactNumber", "password", "howDidYouHearAboutUs"], { shouldFocus: true });
        break;
      case 3:
        valid = await trigger(["businessType", "businessRegistration", "businessOfficialName", "businessTradingName", "websiteAddress", "businessTagline", "businessAddress"], { shouldFocus: true });
        break;
      case 4:
        valid = await trigger(["yearsInOperation", "totalAnnualTurnover", "numberOfAnticipatedAnnualBookings", "averageTripCost", "numberOfIndividualTravelers", "numberOfVehiclesYouOwnAndOperate", "numberOfInternalDrivers", "numberOfPartnerDrivers", "officeHandlingOperations"], { shouldFocus: true });
        break;
      default:
        break;
    }

    if (valid) setFormStep(formStep + 1);
  };

  const onSubmit = async (data: SignUpOperatorFormData) => {
    if (data && token) {
      registerOperator({
        states_of_operation: data.statesOfOperation.map((i) => ({ uuid: i })),
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        mobile: data.contactNumber,
        password: data.password.password,
        hear_about_us: data.howDidYouHearAboutUs,
        business_type: data.businessType,
        business_registration_type: data.businessRegistration.businessRegistrationType,
        business_registration_id: data.businessRegistration.businessRegistrationIdNumber,
        business_official_name: data.businessOfficialName,
        business_name: data.businessTradingName,
        website_address: data.websiteAddress || "",
        business_tagline: data.businessTagline || "",
        business_address: data.businessAddress,
        years_in_operation: data.yearsInOperation.toString(),
        total_annual_turnover: data.totalAnnualTurnover.toString(),
        number_anticipated_annual_bookings: data.numberOfAnticipatedAnnualBookings.toString(),
        average_trip_cost: data.averageTripCost.toString(),
        number_individual_travellers: data.numberOfIndividualTravelers.toString(),
        number_vehicles_owned: data.numberOfVehiclesYouOwnAndOperate.toString(),
        number_internal_drivers: data.numberOfInternalDrivers.toString(),
        number_partner_drivers: data.numberOfPartnerDrivers.toString(),
        office_handling_operations: data.officeHandlingOperations,
        platform_goals: data.platformGoals,
        platform_fit: data.platformFit,
      })
        .unwrap()
        .then(res => {
          getProvider(res.provider_uuid)
            .unwrap()
            .then((operator) => {
              setIsLoggingIn(true);
              dispatch(setActiveOperator(operator));
              login({ username: data.email, password: data.password.password })
                .unwrap()
                .then(() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  if (import.meta.env.MODE === "production") loginPhp(data.email, data.password.password);
                  else navigate("/profile/signup-success?type=operator");
                }).catch(() => {
                  setIsLoggingIn(false);
                  addToast("danger", "Something went wrong. Please contact your administrator.");
                })
            });
        })
        .catch((e) => {
          const errors = getErrorMessagesObject(e);
          for (const [key, value] of Object.entries(errors)) {
            if (signUpFormDataKey[key]) setError(signUpFormDataKey[key] as keyof SignUpOperatorFormData, { type: "custom", message: value });
          }
          getErrorMessages(e).forEach((m) => addToast("danger", m))
        });
    }
  };

  const loginPhp = (emailAddress: string, password: string) => {
    const emailInputElement = emailPhpRef.current;
    const passwordInputElement = passwordPhpRef.current;
    const submitElement = submitPhpRef.current;

    if (emailInputElement && passwordInputElement && submitElement) {
      emailInputElement.value = emailAddress;
      passwordInputElement.value = password;

      submitElement.click();
    }
  };

  const renderButtons = () => {
    const hideBackButton = formStep === 1 && !!paramsUserType;
    return (
      <div className="flex flex-col md:flex-row md:justify-end gap-4 mt-6">
        <div className={clsx("hidden md:block", { "!hidden": hideBackButton })}>
          <Button
            variant="secondary"
            startIcon="ArrowLeft"
            size="lg"
            onClick={onBackClick}
          >
            Back
          </Button>
        </div>
        {formStep < 5 ? (
          <Button
            variant="primary"
            type="submit"
            endIcon="ArrowRight"
            size="lg"
            className="w-full md:w-auto"
            disabled={!isDirty || isEmpty(values.statesOfOperation)}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="primary"
            type="submit"
            endIcon="ArrowRight"
            className="relative w-full md:w-auto"
            disabled={!isDirty || isLoading || isFetchingProvider || !token}
            size="lg"
          >
            {(isLoading || isFetchingProvider) && <Loading className="!bg-transparent" />}
            Sign up
          </Button>
        )}
        <Button
          variant="secondary"
          startIcon="ArrowLeft"
          size="lg"
          onClick={onBackClick}
          className={clsx("w-full md:hidden", { "!hidden": hideBackButton })}
        >
          Back
        </Button>
      </div>
    );
  };

  return (
    <FormProvider {...methods}>
      <form className="w-full max-w-[680px]" onSubmit={formStep < 5 ? onNextClick : handleSubmit(onSubmit)}>
        <Panel>
          <div className="flex flex-col mb-7 gap-y-1.5">
            <Typography variant="h1">Sign up</Typography>
            <Typography variant="paragraph">{`Become an Operator on ${name}`}</Typography>
          </div>
          {formStep > 1 && <StepIndicator currentStep={formStep - 1} numSteps={4} className="my-8 pointer-events-none" />}
          <SignUpFormOperatorStep1 className={clsx({ hidden: formStep !== 1 })} />
          <SignUpFormOperatorStep2 className={clsx({ hidden: formStep !== 2 })} />
          <SignUpFormOperatorStep3 className={clsx({ hidden: formStep !== 3 })} />
          <SignUpFormOperatorStep4 className={clsx({ hidden: formStep !== 4 })} />
          <SignUpFormOperatorStep5 onRecaptchaChange={(token) => setToken(token)} className={clsx({ hidden: formStep !== 5 })} />
        </Panel>
        {renderButtons()}
      </form>
      <form id="phpForm" method="post" action={`${getPhpHostUrl()}/login?referrer=dashboard&redirect=/dashboard/profile/signup-success?type=operator`}>
        <input type="hidden" name="email" ref={emailPhpRef} />
        <input type="hidden" name="password" ref={passwordPhpRef} />
        <input className="hidden" type="submit" ref={submitPhpRef} />
      </form>
    </FormProvider>
  );
};
