import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { BookingOffer } from "@/redux/slices/bookingOffer/types";
import { transformBookingOffer, transformBookingOfferDocuments } from "./helpers";
import { GetBookingOfferDocumentsResponse, GetBookingOfferParams, RawBookingOfferDocument, UpdateBookingOfferParams } from "./types";

export const bookingOfferApi = createApi({
  reducerPath: "bookingOfferApi",
  baseQuery: baseQueryWithoutProvider("external-supplier"),
  endpoints: (builder) => ({
    getBookingOffer: builder.query<BookingOffer, GetBookingOfferParams>({
      query: ({ providerId, accountId, jobId, jobKey }) => `/${providerId}/${accountId}/job/${jobId}/${jobKey}`,
      transformResponse: transformBookingOffer,
      providesTags: ["BookingOffer"],
    }),
    updateBookingOffer: builder.mutation<BookingOffer, GetBookingOfferParams & UpdateBookingOfferParams>({
      query: ({ providerId, accountId, jobId, jobKey, ...body }) => ({
        url: `/${providerId}/${accountId}/job/${jobId}/${jobKey}`,
        method: "PATCH",
        body,
      }),
      transformResponse: transformBookingOffer,
      invalidatesTags: ["BookingOffer"],
    }),
    acceptBookingOffer: builder.mutation<void, GetBookingOfferParams>({
      query: ({ providerId, accountId, jobId, jobKey }) => ({
        url: `/${providerId}/${accountId}/job/${jobId}/${jobKey}/action`,
        method: "PUT",
        body: { action: "accept" },
      }),
      invalidatesTags: ["BookingOffer"],
    }),
    rejectBookingOffer: builder.mutation<void, GetBookingOfferParams>({
      query: ({ providerId, accountId, jobId, jobKey }) => ({
        url: `/${providerId}/${accountId}/job/${jobId}/${jobKey}/action`,
        method: "PUT",
        body: { action: "reject" },
      }),
      invalidatesTags: ["BookingOffer"],
    }),
    getBookingOfferDocument: builder.query<RawBookingOfferDocument, GetBookingOfferParams & { documentId: string }>({
      query: ({ providerId, accountId, jobId, jobKey, documentId }) => ({
        url: `/${providerId}/${accountId}/job/${jobId}/${jobKey}/document/${documentId}`,
      }),
    }),
    getBookingOfferDocuments: builder.query<GetBookingOfferDocumentsResponse, GetBookingOfferParams>({
      query: ({ providerId, accountId, jobId, jobKey }) => ({
        url: `/${providerId}/${accountId}/job/${jobId}/${jobKey}/document`,
        method: "GET",
      }),
      transformResponse: transformBookingOfferDocuments,
      providesTags: ["BookingOfferDocuments"],
    }),
    addBookingOfferDocument: builder.mutation<void, GetBookingOfferParams & { body: FormData }>({
      query: ({ providerId, accountId, jobId, jobKey, body }) => ({
        url: `/${providerId}/${accountId}/job/${jobId}/${jobKey}/document`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["BookingOfferDocuments"],
    }),
    removeBookingOfferDocument: builder.mutation<void, GetBookingOfferParams & { documentId: string }>({
      query: ({ providerId, accountId, jobId, jobKey, documentId }) => ({
        url: `/${providerId}/${accountId}/job/${jobId}/${jobKey}/document/${documentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BookingOfferDocuments"],
    }),
  }),
  tagTypes: ["BookingOffer", "BookingOfferDocuments"],
});

export const {
  useGetBookingOfferQuery,
  useLazyGetBookingOfferQuery,
  useUpdateBookingOfferMutation,
  useAcceptBookingOfferMutation,
  useRejectBookingOfferMutation,
  useGetBookingOfferDocumentsQuery,
  useLazyGetBookingOfferDocumentsQuery,
  useRemoveBookingOfferDocumentMutation,
  useAddBookingOfferDocumentMutation,
  useLazyGetBookingOfferDocumentQuery,
} = bookingOfferApi;
