import { createSlice } from "@reduxjs/toolkit";
import { profileApi } from "@/redux/apis";
import { Profile, ProfileState } from "./types";

const initialState: ProfileState = {
  profile: {} as Profile,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, { payload }) => {
      state.profile = payload;
    });
  },
});

export default profileSlice.reducer;
