import { Fragment} from "react";
import { CustomPicker, CustomPickerProps, SketchPicker } from "react-color";
import { Typography } from "..";
import { Popover, Transition } from "@headlessui/react";

interface ColorPickerProps extends CustomPickerProps<ColorPickerProps> {
  hexCode: string;
}

const ColorPicker = ({ hexCode, onChange }: ColorPickerProps) => {
  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button as="div" className="flex flex-row cursor-pointer">
            <div className="flex items-center justify-center border border-neutral-mid-gray bg-neutral-gray rounded-l-lg border-r-0 p-2.5">
              <div className="w-6 h-6 rounded" style={{backgroundColor: hexCode}} />
            </div>
            <div className="flex items-center border border-neutral-mid-gray bg-white rounded-r-lg w-36 px-4 py-2.5">
              <Typography variant="paragraph">{hexCode}</Typography>
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 mt-3 lg:translate-x-[200px] lg:translate-y-[-200px] transform sm:px-0">
              <SketchPicker color={hexCode} onChange={onChange} disableAlpha />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default CustomPicker(ColorPicker);