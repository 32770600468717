import { isEmpty } from "ramda";
import { useState } from "react";
import { Panel, Typography, Skeleton, Icon, SearchInput, IconButton, DropdownMenu } from "@/components/atoms";
import { EmptyState, NoSearchResults } from "@/components/molecules";
import { useAppSelector } from "@/redux/hooks";
import { useGetAddOnsQuery, useGetPremiumAddOnsQuery } from "@/redux/apis/addOn/addOnApi";
import { addOnsSelector, premiumAddOnsSelector } from "@/redux/slices/addOn/selectors";
import { AddOnItem } from "./AddOnItem";
import { AddOnsModals } from "./AddOnsModals";
import { addOnsDescription } from "./fixtures";
import { RecommendedPanel } from "./RecommendedPanel";
import { useGetAddOnsParams } from "./hooks/useGetAddOnsParams";

export const AddOnsPanel = () => {
  const { params, search, setSearch, setSort } = useGetAddOnsParams();
  const { isFetching: isFetchingAddOns } = useGetAddOnsQuery(params, { refetchOnMountOrArgChange: true });
  const isSearchActive = Boolean(search?.length);

  const { isFetching: isFetchingPremiumAddOns } = useGetPremiumAddOnsQuery();
  const premiumAddOns = useAppSelector(premiumAddOnsSelector);
  const addOns = useAppSelector(addOnsSelector);
  const [selectedAddOn, setSelectedAddOn] = useState<string| undefined>(undefined);

  return (
    <div className="flex flex-col gap-y-5">
      <RecommendedPanel setSelectedAddOn={setSelectedAddOn} />
      <Panel>
        <div className="flex flex-row items-center gap-x-1.5">
          <Typography variant="h3">Premium Add Ons</Typography>
          <Icon isCustom name="diamond" size="lg" className="text-warning-light" />
        </div>
        {isFetchingPremiumAddOns ? <Skeleton /> : (
          isEmpty(premiumAddOns) ? <EmptyState title="Premium Add Ons" description="There are currently no premium add ons available" /> : (
            <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-2.5 mt-4">
              {premiumAddOns.map(addOn => (
                <AddOnItem 
                  key={addOn.identifier}
                  item={addOn}
                  title={addOn.name}
                  description={addOnsDescription[addOn.identifier]}
                  onClick={() => setSelectedAddOn(addOn.identifier)}
                />
              ))}
            </div>
          )
        )}
      </Panel>
      <Panel>
        <div className="flex flex-col sm:flex-row gap-2">
          <div className="flex flex-1 flex-row items-center sm:flex-col sm:items-start">
            <Typography variant="h3" className="flex-1">Included Add Ons</Typography>
            {addOns.length > 0 && <Typography variant="action" className="text-neutral-dark-gray">{addOns.length} Add Ons Active</Typography>}
          </div>
          <div className="flex flex-row items-center self-start w-full sm:w-fit gap-4">
            <SearchInput
              isLoading={isSearchActive && isFetchingAddOns}
              search={search}
              onSearch={setSearch}
              placeholder="Search Add Ons"
              size="md"
              className="w-full lg:w-fit"
            />
            <DropdownMenu button={<IconButton iconName="ArrangeVertical" size="sm" variant="secondary" />} position="bottom-end">
              <DropdownMenu.Item className="flex flex-row items-center gap-2.5" onClick={() => setSort("az")}>
                <Icon name="ArrangeVertical" size="sm" />
                <Typography variant="paragraph">Sort Alphabetically</Typography>
              </DropdownMenu.Item>
              <DropdownMenu.Item className="flex flex-row items-center gap-2.5" onClick={() => setSort("status")}>
                <Icon name="ArrangeVertical" size="sm" />
                <Typography variant="paragraph">Sort by Status</Typography>
              </DropdownMenu.Item>
            </DropdownMenu>
          </div>
        </div>
        {isFetchingAddOns ? <Skeleton /> : (
          isEmpty(addOns) ? (
            isSearchActive ? <NoSearchResults /> : <EmptyState title="Included Add Ons" description="There are currently no included add ons available" />
          ) : (
            <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-2.5 mt-4">
              {addOns.map(addOn => (
                <AddOnItem 
                  key={addOn.identifier}
                  item={addOn}
                  title={addOn.name}
                  description={addOnsDescription[addOn.identifier]}
                  onClick={() => setSelectedAddOn(addOn.identifier)}
                />
              ))}
            </div>
          )
        )}
      </Panel>
      {selectedAddOn && <AddOnsModals identifier={selectedAddOn} onClose={() => setSelectedAddOn(undefined)} />}
    </div>
  );
};