import { clsx } from "@/utils";
import { Divider } from "../Divider/Divider";
import { Typography } from "../Typography/Typography";

export interface BlockquoteProps extends React.ComponentProps<"blockquote"> {
  heading: string;
  subheading: string;
  avatarURL: string;
  alt?: string;
}

export const Blockquote = ({ className, avatarURL, alt = "author", children, heading, subheading, ...props }: BlockquoteProps) => {
  const baseClass = "rounded-lg bg-neutral-surface-gray p-5 relative";

  return (
    <blockquote className={clsx(baseClass, className)} {...props}>
      <span className="absolute left-2 top-1 text-3xl font-bold">“</span>
      <figure className="flex gap-4 pt-2">
        <img src={avatarURL} alt={alt} className="h-16 w-16 rounded-full" />
        <figcaption className="flex-1 self-end">
          <Typography variant="action" className="lg:leading-tight">
            {heading}
          </Typography>
          <Typography className="leading-tight text-neutral-dark-gray">{subheading}</Typography>
          <Divider className="my-1" />
        </figcaption>
      </figure>
      <Typography className="mt-3">{children}</Typography>
    </blockquote>
  );
};
