import { useMemo } from "react";
import { DataTable } from "@/components/molecules";
import { VehicleCategory } from "@/redux/slices/pricing/types";
import { clsx } from "@/utils";
import { generateColumns, generateTableData } from "./helpers";

interface VehicleCategoriesTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: VehicleCategory[];
}

export const VehicleCategoriesTable = ({ items, className, ...props }: VehicleCategoriesTableProps) => {
  const data = useMemo(() => generateTableData(items), [items]);
  const columns = useMemo(() => generateColumns(data), [data]);

  return <DataTable className={clsx("w-full overflow-x-auto [&_table]:table-auto", className)} columns={columns} data={data} {...props} />;
};
