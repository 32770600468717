import { DataTableColumnDef } from "@/components/molecules";
import { VehicleHistoryLogData } from "./types";

export const vehicleHistoryColumns: DataTableColumnDef<VehicleHistoryLogData>[] = [
  {
    header: "Date and Time",
    cell: (props) => props.row.original.dateTime,
    size: 120,
  },
  {
    header: "Modifier",
    cell: (props) => props.row.original.modifier,
    size: 120,
  },
  {
    header: "Event",
    cell: (props) => props.row.original.event,
    size: 200,
  },
  {
    header: "Action",
    cell: (props) => props.row.original.action,
  },
];
