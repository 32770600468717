import { Button, Icon, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { associatedClientsPageStateSelector } from "@/redux/slices/company/selectors";
import { UnableToAddClient as Client } from "@/redux/slices/company/types";
import { clsx } from "@/utils";
import { getManageAccountPath } from "../../Clients/helpers";

export interface UnableToAddClientProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
}

export const UnableToAddClient = ({ onClose, className, ...props }: UnableToAddClientProps) => {
  const { unableToAddClients } = useAppSelector(associatedClientsPageStateSelector);

  const renderClientName = (client: Client) => {
    if (!client) return;

    return (
      <a href={getManageAccountPath(client.id)} target="_blank" rel="noreferrer">
        <span className="text-info">{client.name}</span>
      </a>
    );
  };

  const renderSingleClient = () => (
    <>
      <div className="max-w-lg">
        <div className="flex flex-col">
          <Typography variant="title">Unable to Add Client</Typography>
          <div className="mt-3">
            <Typography variant="paragraph">
              It looks like the client {renderClientName(unableToAddClients[0])} that you&apos;re trying to add is already associated with
              another company. Kindly double check the user&apos;s company information in their profile
            </Typography>
          </div>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        {unableToAddClients[0] && (
          <a href={getManageAccountPath(unableToAddClients[0].id)}>
            <Button variant="secondary" size="md">
              Go to User&apos;s Profile
            </Button>
          </a>
        )}
        <Button variant="primary" onClick={onClose} size="md" className="bg-danger hover:bg-danger-dark">
          Cancel
        </Button>
      </div>
    </>
  );

  const renderMultipleClients = () => (
    <>
      <div className="max-w-lg">
        <div className="flex flex-col">
          <Typography variant="title">Unable to Add Some Client</Typography>
          <div className="mt-3 flex flex-col">
            <Typography variant="paragraph">
              Uh-oh! The clients you&apos;re adding are already linked to another company. Please verify their information in their profiles
            </Typography>
            <div className="mt-2 flex flex-col gap-y-2">
              {unableToAddClients.map((client) => (
                <div key={client.id} className="flex flex-row items-center gap-x-3">
                  <Typography variant="paragraph">{`• ${client.name}`}</Typography>
                  <a
                    href={getManageAccountPath(client.id)}
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-row items-center gap-x-1.5 text-info"
                  >
                    <Typography variant="paragraph">Visit profile</Typography>
                    <Icon name="ExportSquare" size="sm" />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="primary" onClick={onClose} size="md" className="bg-danger hover:bg-danger-dark">
          Back
        </Button>
      </div>
    </>
  );

  return (
    <div className={clsx("relative", className)} {...props}>
      {unableToAddClients.length > 1 ? renderMultipleClients() : renderSingleClient()}
    </div>
  );
};
