import { Alert, Panel, Typography, Skeleton } from "@/components/atoms";
import { PricingStateList } from "../PricingStateList";
import { useDynamicPricingStateContext } from "../contexts";

export const DynamicPricingStatePanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isFetching, pricingStates } = useDynamicPricingStateContext();

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <Panel className={className} {...props}>
          <Typography variant="h3" className="leading-8">
            State Based Dynamic Pricing
          </Typography>
          <Typography className="text-neutral-dark-gray">
            Configure your preferred method of charging customers per trip using dynamic pricing.
          </Typography>
          <Alert
            type="info"
            message="Custom pricing overwrites Universal Pricing. Any state that does not have custom pricing will revert back to Universal Pricing"
            className="mt-3"
          />
          <PricingStateList pricingStates={pricingStates} actionLabel="Replace Custom Pricing" />
          <Typography className="mt-6 text-neutral-dark-gray">
            If you wish to customise the base trip cost in a particular location click to construct your custom pricing in each State.
          </Typography>
        </Panel>
      )}
    </>
  );
};
