import { NetworkItem } from "@/redux/slices/network/types";
import { DisconnectRelationshipModal } from "./modals/DisconnectRelationshipModal";
import { InviteNetworkCodeModal } from "./modals/InviteNetworkCodeModal";
import { InviteNetworkEmailModal } from "./modals/InviteNetworkEmailModal";
import { PrivateNetworkStep } from "./types";
import { PrivateNetworkHelpModal } from "./modals/PrivateNetworkHelpModal";

interface PrivateNetworkModalsProps {
  step: PrivateNetworkStep;
  setStep: (step: PrivateNetworkStep) => void;
  setPendingDisconnectRelationship: (network?: NetworkItem) => void;
  pendingDisconnectRelationship?: NetworkItem;
}

export const PrivateNetworkModals = ({
  pendingDisconnectRelationship,
  setPendingDisconnectRelationship,
  step,
  setStep,
}: PrivateNetworkModalsProps) => {
  return (
    <>
      <InviteNetworkCodeModal open={step === "inviteCode"} onClose={() => setStep(undefined)} setStep={setStep}  />
      <InviteNetworkEmailModal open={step === "inviteEmail"} onClose={() => setStep(undefined)} />
      <PrivateNetworkHelpModal open={step === "help"} onClose={() => setStep(undefined)} />
      <DisconnectRelationshipModal
        pendingDisconnectRelationship={pendingDisconnectRelationship} 
        setPendingDisconnectRelationship={setPendingDisconnectRelationship} 
      />
    </>
  );
};
