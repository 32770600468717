import { GetReportRawResponse, GetReportResponse } from "./types";

export const transformReport = (res: GetReportRawResponse) => {
  const response: GetReportResponse = {
    tripReport: {
      id: res.uuid,
      status: res.status,
      filename: res.filename,
    }
  };

  return response;
};
