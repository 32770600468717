import { useForm } from "react-hook-form";
import { Button, Icon, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useEditPricingProfileMutation } from "@/redux/apis/config/pricing/tripCost";
import { PricingProfile } from "@/redux/slices/pricing/types";
import { addToast } from "@/utils";

interface EditPricingProfileFormProps {
  item: PricingProfile;
  onClose: () => void;
  onDelete: () => void;
}

export const EditPricingProfileForm = ({ item, onClose, onDelete, ...props }: EditPricingProfileFormProps) => {
  const [editPricingProfile, { isLoading }] = useEditPricingProfileMutation();

  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<{ name: string }>({
    defaultValues: { name: item.name },
  });

  const onSubmit = handleSubmit((data) => {
    editPricingProfile({ id: item.id, name: data.name })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Pricing Profile");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <>
      <div className="flex items-center">
        <div>
          <Typography variant="title">Edit Fixed Pricing Profile</Typography>
        </div>
        <button type="button" className="ml-auto rounded-md text-black" onClick={onClose}>
          <span className="sr-only">Close</span>
          <Icon name="close" isCustom size="lg" aria-hidden="true" />
        </button>
      </div>
      <form id="edit-pricing-profile-form" className="relative" onSubmit={onSubmit} {...props}>
        {isLoading && <Loading />}
        <div className="mt-3 flex flex-1 flex-col gap-y-8">
          <div>
            <Typography variant="paragraph">Fixed Pricing Profile Name</Typography>
            <TextInput placeholder="Enter Pricing Profile Name" maxLength={65} required {...register("name")} />
          </div>
          <div className="flex flex-col gap-y-2">
            <Button variant="primary" type="submit" form="edit-pricing-profile-form" size="lg" className="w-full" disabled={!isDirty}>
              Save Profile
            </Button>
            <Button variant="tertiary" size="md" className="w-full text-danger" onClick={onDelete}>
              Delete Profile
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
