import { Skeleton, ErrorPanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetFleetDriversQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { fleetDriversSelector } from "@/redux/slices/driver/selectors";
import { ArchivedDriversTable } from "./tables/ArchivedDriversTable";
import { useGetArchivedDriversParams } from "./hooks/useGetArchivedDriversParams";

export const ArchivedDriversPanel = () => {
  const { params } = useGetArchivedDriversParams();
  const { isFetching, error } = useGetFleetDriversQuery(params, { refetchOnMountOrArgChange: true });
  const drivers = useAppSelector(fleetDriversSelector);

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;
  
  return (
    <>
      <ArchivedDriversTable items={drivers} />
      <ArchivedDriversTable.Pagination />
    </>
  );
};