import { useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { usePagination, useProfile, useSorting } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { debitRecordsSelector } from "@/redux/slices/payment/selectors";
import { DebitRecordsItem } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generateDebitRecordsColumns, generateDebitRecordsData } from "./helpers";

interface DebitRecordsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: DebitRecordsItem[];
}

export const DebitRecordsTable = ({ items, className, ...props }: DebitRecordsTableProps) => {
  const { dateFormat, timeFormat } = useProfile();
  const { sorting, setSorting } = useSorting();

  const data = useMemo(
    () => generateDebitRecordsData(items, dateFormat, timeFormat),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateDebitRecordsColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable columns={columns} data={data} sorting={sorting} setSorting={setSorting} {...props} />
    </div>
  );
};

const DebitRecordsTablePagination = () => {
  const {
    page: { current, count, total, size },
  } = useAppSelector(debitRecordsSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      className="[&_table]:table-auto overflow-x-auto w-full"
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

DebitRecordsTable.Pagination = DebitRecordsTablePagination;
