import { isEmpty } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { Button, Loading } from "@/components/atoms";
import { DataTable, EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile, useSortingState } from "@/hooks";
import { useLazyGetInvoiceQuery, useLazyGetInvoicesQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInvoices, setInvoicingModalAction, setInvoicingSidePanelAction, setSelectedInvoice } from "@/redux/slices/payment/paymentSlice";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { Invoice, InvoicesCompany, InvoicingModalAction, InvoicingSidePanelAction } from "@/redux/slices/payment/types";
import { addToast, clsx, downloadFile } from "@/utils";
import { IssueInvoiceModal, ResendInvoiceModal } from "../contents";
import { generateInvoicesColumns, generateInvoicesData } from "../helpers";

interface InvoicesTableProps extends React.HTMLAttributes<HTMLDivElement> {
  company: InvoicesCompany;
  companyIndex: number;
  items: Invoice[];
}

type Step = "issueInvoice" | "resendInvoice" | undefined;

export const InvoicesTable = ({ company, companyIndex, items, className, ...props }: InvoicesTableProps) => {
  const { dateFormat } = useProfile();
  const dispatch = useAppDispatch();
  const { sorting, setSorting, sortKey, direction } = useSortingState();
  const [getInvoices, { isFetching: isFetchingGetInvoices }] = useLazyGetInvoicesQuery();
  const [getInvoice, { isFetching: isFetchingGetInvoice }] = useLazyGetInvoiceQuery();
  const { selectedInvoice: invoice } = useAppSelector(invoicingPageStateSelector);
  const [step, setStep] = useState<Step>(undefined);
  const { id, invoicesPage } = company;
  const { current, count } = invoicesPage;
  const isFetching = isFetchingGetInvoices || isFetchingGetInvoice;

  useEffect(() => {
    getInvoices({ company_uuid: id, sort: sortKey, direction, page: "1" })
      .unwrap()
      .then(({ data }) => {
        dispatch(setInvoices({ companyIndex, invoices: data.invoices, invoicesPage: data.invoicesPage }));
      });
  }, [getInvoices, id, sortKey, direction, dispatch, companyIndex]);

  const loadMoreInvoices = () => {
    getInvoices({ company_uuid: id, sort: sortKey, direction, page: (current + 1).toString() })
      .unwrap()
      .then(({ data }) => {
        dispatch(setInvoices({ companyIndex, invoices: [...items, ...data.invoices], invoicesPage: data.invoicesPage }));
      });
  };

  const handleInvoicingModalAction = (invoice: Invoice, action: InvoicingModalAction) => {
    dispatch(setSelectedInvoice(invoice));
    dispatch(setInvoicingModalAction(action));
  };

  const handleInvoicingSidePanelAction = (invoice: Invoice, action: InvoicingSidePanelAction) => {
    dispatch(setSelectedInvoice(invoice));
    dispatch(setInvoicingSidePanelAction(action));
  };

  const handleIssueInvoiceAction = (invoice: Invoice) => {
    dispatch(setSelectedInvoice(invoice));
    setStep("issueInvoice");
  };

  const handleResendInvoiceAction = (invoice: Invoice) => {
    dispatch(setSelectedInvoice(invoice));
    setStep("resendInvoice");
  };

  const handleDownloadAction = (invoice: Invoice, document: string) => {
    getInvoice({ id: invoice.id })
      .unwrap()
      .then((res) => {
        switch (document) {
          case "pdf":
            downloadFile(res.data.documents.pdf, "invoice");
            break;
          case "csv":
            downloadFile(res.data.documents.csv, "invoice");
            break;
        }
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderLoadMoreButton = () => {
    const hasMore = current < count;
    if (hasMore) {
      return (
        <div className="flex items-center justify-center">
          <Button size="sm" variant="tertiary" onClick={loadMoreInvoices} className="!text-info hover:bg-info-light">
            Load more
          </Button>
        </div>
      );
    }
  };

  const data = useMemo(
    () =>
      generateInvoicesData(
        items,
        dateFormat,
        handleInvoicingModalAction,
        handleInvoicingSidePanelAction,
        handleIssueInvoiceAction,
        handleResendInvoiceAction,
        handleDownloadAction
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateInvoicesColumns(), []);

  return (
    <div className={clsx("relative flex w-full flex-col", className)}>
      {isFetching && <Loading />}
      {isEmpty(data) ? (
        <EmptyState title="Invoices" description="There are currently no invoices available" />
      ) : (
        <div className="flex flex-col gap-y-3">
          <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} sorting={sorting} setSorting={setSorting} {...props} />
          {renderLoadMoreButton()}
        </div>
      )}
      {invoice && <IssueInvoiceModal open={step === "issueInvoice"} invoice={invoice} onClose={() => setStep(undefined)} />}
      {invoice && <ResendInvoiceModal open={step === "resendInvoice"} invoice={invoice} onClose={() => setStep(undefined)} />}
    </div>
  );
};
