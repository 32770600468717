import { pricingApi } from "../pricingApi";
import { transformHourlyHire, transformHourlyHireMinimum } from "./helpers";
import { GetHourlyHireMinimumResponse, GetHourlyHireResponse, UpdateHourlyHireMinimumParams, UpdateHourlyHireParams } from "./types";

export const hourlyHireApi = pricingApi.enhanceEndpoints({ addTagTypes: ["HourlyHire", "HourlyHireMinimum"] }).injectEndpoints({
  endpoints: (builder) => ({
    getHourlyHire: builder.query<GetHourlyHireResponse, void>({
      query: () => "/pricing/periodic/hourly",
      transformResponse: transformHourlyHire,
      providesTags: ["HourlyHire"],
    }),
    updateHourlyHire: builder.mutation<void, UpdateHourlyHireParams>({
      query: (body) => ({
        url: "/pricing/periodic/hourly",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["HourlyHire"],
    }),
    getHourlyHireMinimum: builder.query<GetHourlyHireMinimumResponse, void>({
      query: () => "/pricing/periodic/hourly/minimum",
      transformResponse: transformHourlyHireMinimum,
      providesTags: ["HourlyHireMinimum"],
    }),
    updateHourlyHireMinimum: builder.mutation<void, UpdateHourlyHireMinimumParams>({
      query: (body) => ({
        url: "/pricing/periodic/hourly/minimum",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["HourlyHireMinimum"],
    }),
  }),
});

export const { useGetHourlyHireQuery, useUpdateHourlyHireMutation, useGetHourlyHireMinimumQuery, useUpdateHourlyHireMinimumMutation } =
  hourlyHireApi;
