import { Disclosure, Transition } from "@headlessui/react";
import { Icon, Typography } from "@/components/atoms";
import { InvoicesCompany, UninvoicedJobsCompany } from "@/redux/slices/payment/types";
import { clsx, formatCurrency, getInitials, getOrdinalNumber } from "@/utils";

type Variant = "uninvoiced-jobs" | "invoices";

interface CompanyDisclosureProps extends React.HTMLAttributes<HTMLDivElement> {
  company: UninvoicedJobsCompany | InvoicesCompany;
  variant: Variant;
  currency: string;
  order?: number;
}

export const CompanyDisclosure = ({ company, variant, currency, order = 1, children, className, ...props }: CompanyDisclosureProps) => {
  const { name } = company;

  const renderAutoGenerateDate = (frequency: string | null, date: number | null) => {
    switch (frequency) {
      case "never":
        return (
          <Typography variant="action" className="flex flex-row items-center gap-x-1.5">
            <span className="h-2 w-2 rounded-full bg-danger" /> Never
          </Typography>
        );
      case "monthly":
        if (date) {
          return (
            <Typography variant="action" className="flex flex-row items-center gap-x-1.5">
              <span className="h-2 w-2 rounded-full bg-info" /> {`Every ${getOrdinalNumber(date)} day /month`}
            </Typography>
          );
        }
        break;
      case "weekly":
        if (date) {
          return (
            <Typography variant="action" className="flex flex-row items-center gap-x-1.5">
              <span className="h-2 w-2 rounded-full bg-info" /> {`Every ${getOrdinalNumber(date)} day /week`}
            </Typography>
          );
        }
        break;
      case "no_future_jobs":
        return (
          <Typography variant="action" className="flex flex-row items-center gap-x-1.5">
            <span className="h-2 w-2 rounded-full bg-info" /> When no future jobs w/in 3 months
          </Typography>
        );
      case "twice_monthly":
        return (
          <Typography variant="action" className="flex flex-row items-center gap-x-1.5">
            <span className="h-2 w-2 rounded-full bg-info" /> Twice Monthly (1st and 15th)
          </Typography>
        );
      default:
        return;
    }
  };

  const renderDetails = () => {
    switch (variant) {
      case "uninvoiced-jobs": {
        const { invoice, metadata } = company as UninvoicedJobsCompany;

        return (
          <div className="flex flex-row items-center gap-x-10 text-right">
            <div className="flex flex-col">
              <div className="flex justify-end">{renderAutoGenerateDate(invoice.generateOnFrequency, invoice.generateOnDate)}</div>
              <Typography variant="small" className="text-neutral-dark-gray">
                Auto Generate Date
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography variant="action">{metadata.unpaidJobCount}</Typography>
              <Typography variant="small" className="text-neutral-dark-gray">
                Total Jobs
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography variant="action">{metadata.unpaidJobValue && formatCurrency(metadata.unpaidJobValue, 2, currency)}</Typography>
              <Typography variant="small" className="text-neutral-dark-gray">
                Total Amount
              </Typography>
            </div>
          </div>
        );
      }
      case "invoices": {
        const { metadata } = company as InvoicesCompany;

        return (
          <div className="flex flex-row items-center gap-x-10 text-right">
            <div className="flex flex-col">
              <Typography variant="action">{metadata.unpaidInvoiceCount}</Typography>
              <Typography variant="small" className="text-neutral-dark-gray">
                Total Unpaid
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography variant="action">{metadata.overdueInvoiceCount}</Typography>
              <Typography variant="small" className="text-neutral-dark-gray">
                Overdue Invoice
              </Typography>
            </div>
            <div className="flex flex-col">
              <Typography variant="action">{formatCurrency(metadata.unpaidInvoiceValue, 2, currency)}</Typography>
              <Typography variant="small" className="text-neutral-dark-gray">
                Total Amount Due
              </Typography>
            </div>
          </div>
        );
      }
      default:
        return;
    }
  };

  return (
    <Disclosure as="div" className={clsx("rounded-lg bg-white", className)} {...props}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full flex-row items-center justify-between gap-x-1 px-5 py-2.5 outline-none">
            <div className="flex flex-row items-center">
              <Icon name={open ? "ArrowDown2" : "ArrowRight2"} variant="Bold" size="lg" className="flex-shrink-0" />
              <div
                className={clsx("ml-5 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full text-white", {
                  "bg-[#3498DB]": order % 1 === 0,
                  "bg-[#E74C3C]": order % 2 === 0,
                  "bg-[#2ECC71]": order % 3 === 0,
                  "bg-[#F39C12]": order % 4 === 0,
                  "bg-[#9B59B6]": order % 5 === 0,
                })}
              >
                <Typography variant="action">{getInitials(name)}</Typography>
              </div>
              <Typography variant="action" className="ml-4">
                {name}
              </Typography>
            </div>
            {renderDetails()}
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition transition-[max-height] duration-100 ease-in"
            enterFrom="transform max-h-0"
            enterTo="transform max-h-auto"
            leave="transition transition-[max-height] duration-100 ease-out"
            leaveFrom="transform max-h-auto"
            leaveTo="transform max-h-0"
          >
            <Disclosure.Panel>{open && children}</Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
