import { getCurrencySymbol } from "./getCurrencySymbol";

type FormatCurrencyOptions = {
  skipDecimalConversion?: boolean;
  appendCurrency?: boolean;
  prependCurrencySymbol?: boolean;
  minimumFractionDigits?: number;
  noDecimalIfZero?: boolean;
};

export const formatCurrency = (value: number, decimals: number, currency: string, options?: FormatCurrencyOptions) => {
  const { skipDecimalConversion = false, appendCurrency = false, prependCurrencySymbol = true, noDecimalIfZero = true } = options ?? {};
  const polarity = value < 0 ? "-" : "";

  const numberValue = skipDecimalConversion ? Math.abs(value) : Math.abs(value) / 100;

  let textValue = "";

  if (noDecimalIfZero && decimals === 0) {
    textValue = numberValue.toFixed(0);
  } else {
    textValue = numberValue.toFixed(decimals);
  }

  return `${polarity}${prependCurrencySymbol ? getCurrencySymbol(currency) : ""}${textValue}${appendCurrency ? ` ${currency}` : ""}`;
};
