import { flatten, isEmpty } from "ramda";
import { EmptyState, NoFilterResults, NoSearchResults } from "@/components/molecules";
import { useInfiniteScroll } from "@/hooks";
import { useGetUninvoicedJobsCompaniesQuery, useLazyGetUninvoicedJobsCompaniesQuery } from "@/redux/apis/company/companyApi";
import { useAppSelector } from "@/redux/hooks";
import {
  invoicingOptionsSelector,
  invoicingUninvoicedJobsPageSelector,
  invoicingUninvoicedJobsSelector,
} from "@/redux/slices/payment/selectors";
import { CompanyDisclosure } from "./contents";
import { CompanyDisclosureSkeleton } from "./contents/CompanyDisclosureSkeleton";
import { useGetUninvoicedJobsCompaniesParams } from "./hooks";
import { ClientJobsTable, JobsTable } from "./tables";

export const UninvoicedJobsPanel = () => {
  const { params, search, filters } = useGetUninvoicedJobsCompaniesParams();
  const { isFetching } = useGetUninvoicedJobsCompaniesQuery({ page: "1", ...params }, { refetchOnMountOrArgChange: true });
  const [getUninvoicedJobsCompanies, { isFetching: isFetchingLazy }] = useLazyGetUninvoicedJobsCompaniesQuery();
  const { companies } = useAppSelector(invoicingUninvoicedJobsSelector);
  const { count, current } = useAppSelector(invoicingUninvoicedJobsPageSelector);
  const { currency } = useAppSelector(invoicingOptionsSelector);
  const containerClassName = "flex flex-1 flex-col gap-y-3";

  useInfiniteScroll({
    scroller: window,
    hasMore: current < count,
    pageStart: current,
    loadMore: (page: string) => getUninvoicedJobsCompanies({ page, ...params }),
    isFetching: isFetching || isFetchingLazy,
  });

  const renderEmptyState = () => {
    const isSearchActive = Boolean(search?.length);
    const isFilterActive = flatten(Object.values(filters).filter(Boolean)).length > 0;

    if (isSearchActive) return <NoSearchResults />;
    if (isFilterActive) return <NoFilterResults />;

    return <EmptyState title="Companies" description="There are currently no companies available" />;
  };

  if (isFetching) return <CompanyDisclosureSkeleton className={containerClassName} />;

  return (
    <div className={containerClassName}>
      {isEmpty(companies)
        ? renderEmptyState()
        : companies.map((company, i) => (
            <CompanyDisclosure key={company.id} company={company} variant="uninvoiced-jobs" currency={currency[0]?.value} order={i + 1}>
              {company.invoice.generateOnLevel === "client" || company.invoice.generateOnLevel === "client_no_invoice" ? (
                <ClientJobsTable companyIndex={i} company={company} items={company.clientJobs} className="px-5 pb-6 pt-3" />
              ) : (
                <JobsTable company={company} companyIndex={i} items={company.jobs} className="px-5 pb-6 pt-3" />
              )}
            </CompanyDisclosure>
          ))}
      {isFetchingLazy && <CompanyDisclosureSkeleton variant="item" />}
    </div>
  );
};
