import {
  GetWebsiteDetailsRawResponse,
  GetWebsiteDetailsResponse,
  UpdateWebsiteDetailsRawResponse,
  UpdateWebsiteDetailsResponse,
} from "./types";

export const transformWebsiteDetails = (res: GetWebsiteDetailsRawResponse) => {
  const { _links, ...websiteDetails } = res;
  const response: GetWebsiteDetailsResponse = {
    data: {
      details: {
        brochureUrl: websiteDetails.brochure_url,
        termsAndConditionsUrl: websiteDetails.terms_and_conditions_url,
        meetingPointsUrl: websiteDetails.meeting_points_url,
        priceGuideUrl: websiteDetails.price_guide_url,
        findYourDriverUrl: websiteDetails.find_your_driver_url,
        googleReviewsUrl: websiteDetails.google_reviews_url,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateWebsiteDetails = (res: UpdateWebsiteDetailsRawResponse) => {
  return {
    links: res._links,
  } as UpdateWebsiteDetailsResponse;
};
