import { Button, IconButton, Panel, SearchInput, Skeleton, Typography } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { useSearch, useSorting } from "@/hooks";
import { usePagination } from "@/hooks/usePagination";
import { useGetAssociatedClientsQuery } from "@/redux/apis/company/companyApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAssociatedClientAction } from "@/redux/slices/company/companySlice";
import { associatedClientsPageSelector, associatedClientsSelector } from "@/redux/slices/company/selectors";
import { AssociatedClientsModals } from "./AssociatedClientsModals";
import { AssociatedClientsTable } from "./tables/AssociatedClientsTable";

interface AssociatedClientsPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  companyId: string;
}

export const AssociatedClientsPanel = ({ companyId, className, ...props }: AssociatedClientsPanelProps) => {
  const dispatch = useAppDispatch();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const { page, pageSize, handlePageClick, handleSizeChange, clearPagination } = usePagination();
  const [search, setSearch] = useSearch();
  const associateClients = useAppSelector(associatedClientsSelector);
  const { current, count, total, size } = useAppSelector(associatedClientsPageSelector);
  const { isFetching, refetch } = useGetAssociatedClientsQuery(
    { companyId, page, page_size: pageSize, sort: sortKey, direction, name: search ?? undefined },
    { refetchOnMountOrArgChange: true }
  );

  const handleSearch = (value?: string) => {
    clearPagination();
    setSearch(value);
  };

  const renderHeaderButton = () => (
    <>
      {/* Desktop & Tablet View */}
      <div className="hidden sm:flex">
        <Button size="md" startIcon="AddCircle" iconVariant="Bold" onClick={() => dispatch(setAssociatedClientAction("addClients"))}>
          Add Client
        </Button>
      </div>
      {/* Mobile View */}
      <div className="flex sm:hidden">
        <IconButton size="md" iconName="AddCircle" iconVariant="Bold" onClick={() => dispatch(setAssociatedClientAction("addClients"))} />
      </div>
    </>
  );

  const isClientsEmpty = associateClients.length < 1 && !search && !isFetching;

  return (
    <div className={className} {...props}>
      <Panel>
        <div className="flex flex-row items-center">
          <div className="flex flex-1 items-center">
            <Typography variant="h3" className="leading-8">
              Associated Client
            </Typography>
          </div>
          {renderHeaderButton()}
        </div>
        {isClientsEmpty ? (
          <EmptyState title="Associated Clients" description="There are currently no associated clients available" />
        ) : (
          <>
            <SearchInput
              isLoading={isFetching}
              className="mt-3.5 w-full sm:max-w-sm"
              search={search}
              onSearch={handleSearch}
              placeholder="Search Associated Clients"
              size="md"
            />
            {isFetching ? (
              <Skeleton variant="short" className="mt-4" />
            ) : (
              <AssociatedClientsTable
                items={associateClients}
                companyId={companyId}
                sorting={sorting}
                setSorting={setSorting}
                className="mt-4"
              />
            )}
          </>
        )}
      </Panel>
      <AssociatedClientsModals refetchAssociatedClients={() => refetch()} />
      {!isFetching && (
        <Pagination
          onPageClick={handlePageClick}
          onSizeChange={handleSizeChange}
          currentPage={current}
          pageSize={size}
          totalItems={total}
          pageCount={count}
        />
      )}
    </div>
  );
};
