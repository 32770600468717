import { useParams } from "react-router-dom";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetFleetDriversParams } from "@/redux/apis/driver/types";

export const useGetFleetLeadersParams = () => {
  const { page, pageSize } = usePagination();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const [search, setSearch] = useSearch();
  const { networkOperatorId: operatorId } = useParams<{ networkOperatorId: string }>();

  if (!operatorId) throw Error("Operator must be defined to use useGetFleetLeadersParams hook");

  const params: GetFleetDriversParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    search,
    provider_uuid: operatorId ?? undefined,
    is_fleet_leader: 1,
  };

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    search,
    setSearch,
  };
};
