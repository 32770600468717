import { ButtonVariant } from "./types";

export const baseClass = "text-base rounded-md items-center inline-flex gap-2 justify-center disabled:!opacity-70 whitespace-nowrap";

export const iconBaseClass = "self-center";

export const getVariantClass = (variant: ButtonVariant) => {
  switch (variant) {
    case "secondary":
      return "bg-white text-gray-text border enabled:border-neutral-dark-gray hover:bg-neutral-gray disabled:!bg-primary-light disabled:!text-white";
    case "tertiary":
      return "bg-transparent hover:bg-primary-light hover:text-primary-dark disabled:!bg-neutral-gray disabled:!text-neutral-dark-gray";
    case "info":
      return "bg-info text-white hover:bg-info/70 disabled:!bg-info-light";
    case "primary":
      return "bg-primary text-white border border-primary hover:bg-primary-dark disabled:border-primary-light disabled:!bg-primary-light";
    default:
      return "bg-transparent hover:bg-transparent hover:opacity-70 disabled:!bg-neutral-gray disabled:!text-neutral-dark-gray";
  }
};
