import { Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { FixedBaseInterval, FixedIntervals } from "@/redux/slices/pricing/types";
import { addToast, getCostDisplay, getCurrencySymbol } from "@/utils";
import { FixedPricingTableData } from "./types";

export const generateFixedPricingData = (
  baseInterval: FixedBaseInterval,
  intervals: FixedIntervals,
  currency: string,
  distanceUnit: string
) => {
  const currencySymbol = getCurrencySymbol(currency) || "";
  const jointIntervals = baseInterval ? [baseInterval, ...intervals] : [];
  const data = jointIntervals.map(
    (interval, i) =>
      ({
        range: (
          <div className="flex flex-col">
            <Typography variant="paragraph">{`Range ${i + 1}`}</Typography>
            {interval.note && (
              <Typography variant="small" className="text-neutral-dark-gray">
                {interval.note}
              </Typography>
            )}
          </div>
        ),
        distance: `${i > 0 ? jointIntervals[i - 1].maximumRange : 0} - ${interval.maximumRange} ${distanceUnit}`,
        baseTripCost: getCostDisplay(interval.intervalCost, currencySymbol),
        managementFee: getManagementFee(interval.managementCost, interval.managementCostUnit, currencySymbol),
        totalTripCost: getTotalTripCost(interval.intervalCost, interval.managementCost, interval.managementCostUnit, currencySymbol),
      } as FixedPricingTableData)
  );
  return data;
};

export const generateFixedPricingColumns = (distanceUnit: string) => {
  const columns: DataTableColumnDef<FixedPricingTableData>[] = [
    {
      accessorKey: "range",
      header: "Range",
      cell: (props) => props.row.original.range,
      enableSorting: false,
    },
    {
      accessorKey: "distance",
      header: `Distance (${distanceUnit})`,
      cell: (props) => props.row.original.distance,
      enableSorting: false,
    },
    {
      accessorKey: "baseTripCost",
      header: "Base Trip Cost",
      cell: (props) => props.row.original.baseTripCost,
      enableSorting: false,
    },
    {
      accessorKey: "managementFee",
      header: "Management Fee",
      cell: (props) => props.row.original.managementFee,
      enableSorting: false,
    },
    {
      accessorKey: "totalTripCost",
      header: "Total Trip Cost",
      cell: (props) => props.row.original.totalTripCost,
      enableSorting: false,
    },
  ];

  return columns;
};

export const getManagementFee = (cost: number, unit: string, currencySymbol: string) =>
  unit === "percentage" ? `${cost}%` : getCostDisplay(cost, currencySymbol);

export const getTotalTripCost = (baseTripCost: number, managementFee: number, unit: string, currencySymbol: string) =>
  unit === "percentage"
    ? getCostDisplay(baseTripCost + baseTripCost * (managementFee / 100), currencySymbol)
    : getCostDisplay(baseTripCost + managementFee, currencySymbol);

export const getManagementCostUnitSymbol = (unit: string, currencySymbol: string) => (unit === "percentage" ? "%" : currencySymbol);

export const validateRanges = (ranges: number[]): boolean => {
  let rangeError = 0;
  const isIncremental = ranges.slice(1).every((item, i) => {
    const condition = ranges[i] < item;
    if (!condition) rangeError = i;

    return condition;
  });

  if (!isIncremental) {
    addToast("danger", `Unable to save changes. Range ${rangeError + 1} must be incremental over the previous range.`);
    return false;
  }

  return true;
};
