import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import StripeLogo from "@/assets/stripe-logo.svg";
import { Button, Icon, Loading, Panel, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePage } from "@/hooks";
import { useConnectStripeMutation } from "@/redux/apis/payment/stripe/stripeApi";
import { useAppSelector } from "@/redux/hooks";
import { stripeSelector } from "@/redux/slices/payment/selectors";
import { addToast, clsx } from "@/utils";
import { Userpilot } from "userpilot";

export const StripeConfigurationPanel = () => {
  const [searchParams, _] = useSearchParams();
  const refresh = searchParams.get("refresh");
  const { mode } = useParams();
  const { pathname } = usePage();
  const url = `${getPhpHostUrl()}/dashboard${pathname}`;
  const [connectStripe, { isLoading }] = useConnectStripeMutation();
  const {
    config: { status, path, openRequirements },
  } = useAppSelector(stripeSelector);
  const isPendingDepositsOnly = openRequirements.includes("external_account") && openRequirements.length < 2;

  useEffect(() => {
    if (refresh && Number(refresh)) addToast("danger", "Connect Stripe link expired. Please try again.");
  }, [refresh]);

  useEffect(() => {
    connectStripe({
      refresh_url: `${url}?refresh=1`,
      redirect_url: url,
    })
      .unwrap()
      .then((res) => { 
        if (res.data.config.status === "connected") Userpilot.track("Stripe Connected");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStatus = () => {
    switch (status) {
      case "connected":
        return (
          <div className="mb-2 flex flex-row items-center gap-x-2 text-success">
            <Icon name="TickCircle" size="sm" variant="Bold" />
            <Typography variant="action">Connected</Typography>
          </div>
        );
      case "in_progress":
        return (
          <div className="mb-2 flex flex-row items-center gap-x-2 text-warning-dark">
            <Icon name="Danger" size="sm" variant="Bold" />
            <Typography variant="action">
              {isPendingDepositsOnly ? "Pending completion of “Bank Details - Deposits”" : "Details Pending"}
            </Typography>
          </div>
        );
      case "disconnected":
        return (
          <div className="mb-2 flex flex-row items-center gap-x-2 text-danger">
            <Icon name="CloseCircle" size="sm" variant="Bold" />
            <Typography variant="action">Disconnected</Typography>
          </div>
        );
      default:
        return;
    }
  };

  const renderButton = () => {
    if (isLoading) return;

    switch (status) {
      case "disconnected":
        return (
          <a href={path} className="mt-4 flex">
            <Button size="md" variant="primary" startIcon="Link2">
              Connect Stripe
            </Button>
          </a>
        );
      case "in_progress":
        if (!isPendingDepositsOnly) {
          return (
            <a href={path} className="mt-4 flex">
              <Button size="md" variant="primary" startIcon="Link2">
                Continue Stripe Setup
              </Button>
            </a>
          );
        }
        break;
      default:
        return;
    }
  };

  return (
    <Panel className={clsx({ "pointer-events-none opacity-50": mode === "edit" })}>
      <Typography variant="h3">Stripe Configuration</Typography>
      <div className="relative mt-6 flex flex-col gap-[15px] lg:flex-row lg:gap-[30px]">
        {isLoading && <Loading />}
        <div
          className={clsx("flex flex-col items-center justify-center rounded-lg bg-neutral-surface-gray p-[30px] lg:w-[573px]", {
            "border border-success": status === "connected",
            "border border-danger": status === "disconnected",
            "border border-warning-dark": status === "in_progress",
            "border border-transparent": isLoading,
          })}
        >
          <img src={StripeLogo} alt="Stripe" />
          <div className="mt-1.5 flex flex-row gap-x-5">
            <Icon name="mastercard" size={36} isCustom />
            <Icon name="visa" size={36} isCustom />
            <Icon name="amex" size={36} isCustom />
            <Icon name="jcb" size={36} isCustom />
            <Icon name="union-pay" size={36} isCustom />
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          {renderStatus()}
          <Typography variant="paragraph">
            Integrating your platform with Stripe streamlines transactions, enhances customer satisfaction, strengthens security, and
            ensures compliance with documents like your ABN, ACN, Business Phone Number, and Government ID&apos;s.
          </Typography>
          {renderButton()}
        </div>
      </div>
    </Panel>
  );
};
