import { UseFormRegister } from "react-hook-form";
import { TimeFrame } from "@/redux/slices/admin/types";
import { Typography } from "..";
import { clsx } from "@/utils";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TimeFrameInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  timeName: string;
  timeFrameName: string;
  register: UseFormRegister<any>;
  options: TimeFrame[];
  error?: string;
}

export const TimeFrameInput = (timeFrameInputProps: TimeFrameInputProps) => {
  const { timeName, timeFrameName, register, options, error, className, disabled, ...props } = timeFrameInputProps;

  return (
    <div className="flex flex-row items-center gap-3">
      <div className={clsx("flex focus-within:z-10", className)}>
        <Typography
          className="w-[90px] whitespace-nowrap rounded-l-lg border border-r-0 border-neutral-mid-gray bg-neutral-gray text-neutral-dark-gray"
          variant="paragraph"
        >
          <select
            className="h-full w-fit rounded-l-lg border-transparent bg-transparent cursor-pointer focus:border-transparent focus:ring-0"
            disabled={disabled}
            {...register(timeFrameName)}
          >
            {options.includes("minute") && <option value="minute">Min</option>}
            {options.includes("hour") && <option value="hour">Hour</option>}
            {options.includes("day") && <option value="day">Day</option>}
          </select>
        </Typography>
        <input
          type="number"
          className={clsx(
            "block h-12 w-full rounded-r-lg px-4 text-base placeholder-neutral-dark-gray focus:border-primary focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-surface-gray",
            error ? "border-danger text-danger" : "border-neutral-mid-gray text-neutral-black"
          )}
          disabled={disabled}
          step="1"
          {...register(timeName)}
          {...props}
        />
      </div>
      {error && (
        <Typography variant="paragraph" className="text-danger">
          {error}
        </Typography>
      )}
    </div>
  );
};

TimeFrameInput.displayName = "TimeFrameInput";
