import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TransferTypeFormData } from "../types";

export const CostMultiplierField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TransferTypeFormData>();

  return (
    <div className={clsx("grid grid-cols-2 gap-4", className)} {...props}>
      <div className="flex flex-col gap-y-2">
        <Typography variant="action">Cost Multiplier (%)</Typography>
        <AddOnTextInput
          className="w-full"
          endAddOn="%"
          placeholder="0"
          type="number"
          step="0.01"
          min="-99"
          max="9999"
          hasError={!!errors.costMultiplier}
          {...register("costMultiplier")}
        />
        <ErrorMessage errors={errors} name="costMultiplier" />
      </div>
    </div>
  );
};
