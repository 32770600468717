import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Loading,
  Panel,
  PhoneNumberInput,
  TextInput,
  Toggle,
  Typography,
  validatePhoneNumberInput,
  Modal,
  Skeleton,
} from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetSMSVoiceNotificationsQuery, useUpdateSMSVoiceNotificationsMutation } from "@/redux/apis/config/admin/smsVoiceNotifications";
import { useAppSelector } from "@/redux/hooks";
import { smsVoiceNotificationsSelector } from "@/redux/slices/admin/selectors";
import { SMSVoiceNotifications } from "@/redux/slices/admin/types";
import { addToast } from "@/utils/addToast";

const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save SMS / Voice Notifications",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

type FormData = SMSVoiceNotifications;

export const SMSVoiceNotificationsEdit = () => {
  const { isFetching } = useGetSMSVoiceNotificationsQuery();
  const { details } = useAppSelector(smsVoiceNotificationsSelector);
  const [updateSMSVoiceNotifications, { isLoading }] = useUpdateSMSVoiceNotificationsMutation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { isDirty, dirtyFields, isSubmitted },
  } = useForm<FormData>({ defaultValues: details });
  const watchEnabledUnallocatedClosePickupNotifications = watch("enabledUnallocatedClosePickupNotifications", false);
  const watchEnabledDriverNotOnWayNotifications = watch("enabledDriverNotOnWayNotifications", false);
  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  useEffect(() => {
    reset(details);
  }, [details, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as SMSVoiceNotifications;
    const {
      enabledUnallocatedClosePickupNotifications,
      unallocatedClosePickupPhoneNumber,
      enabledDriverNotOnWayNotifications,
      driverNotOnWayPhoneNumber,
      driverNotOnWayEmailAddress,
      enabledSmsCustomerReminderAndOpchangeNotifications,
    } = { ...dirtyValues };

    setActiveModal(null);
    updateSMSVoiceNotifications({
      enabled_unallocated_close_pickup_notifications: enabledUnallocatedClosePickupNotifications,
      unallocated_close_pickup_phone_number: validatePhoneNumberInput(unallocatedClosePickupPhoneNumber),
      enabled_driver_not_on_way_notifications: enabledDriverNotOnWayNotifications,
      driver_not_on_way_phone_number: validatePhoneNumberInput(driverNotOnWayPhoneNumber),
      driver_not_on_way_email_address: driverNotOnWayEmailAddress,
      enabled_sms_customer_reminder_and_opchange_notifications: enabledSmsCustomerReminderAndOpchangeNotifications,
    })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Updated SMS / Voice Notifications`);
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="ml-auto flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                SMS / Voice Notifications
              </Typography>
              <Typography className="text-neutral-dark-gray">Set your SMS / Voice Notification settings.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
          <ConfigDetail
            title="Unallocated & close to pickup notifications enabled"
            description="Trigger an automated call when a job is unallocated and close to pickup."
          >
            <Controller
              name="enabledUnallocatedClosePickupNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Phone Number to call when unallocated & close to pickup"
            description="Phone number to use."
            indentPadding="pl-2"
          >
            <div className="w-3/5">
              <PhoneNumberInput
                control={control}
                name="unallocatedClosePickupPhoneNumber"
                disabled={!watchEnabledUnallocatedClosePickupNotifications}
              />
            </div>
          </ConfigDetail>

          <ConfigDetail
            title="Driver not on way notifications enabled"
            description="Trigger an automated call when driver is not on way to job."
          >
            <Controller
              name="enabledDriverNotOnWayNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
          <ConfigDetail
            title="Phone number to call when driver not on way to pickup"
            description="Phone number to use."
            indentPadding="pl-2"
          >
            <div className="w-3/5">
              <PhoneNumberInput control={control} name="driverNotOnWayPhoneNumber" disabled={!watchEnabledDriverNotOnWayNotifications} />
            </div>
          </ConfigDetail>
          <ConfigDetail
            title="Email address to email when driver not on way to pickup"
            description="Additional email address which contains extra driver details."
            indentPadding="pl-2"
          >
            <TextInput
              className="w-3/5"
              maxLength={254}
              type="email"
              autoComplete="email"
              {...register("driverNotOnWayEmailAddress")}
              disabled={!watchEnabledDriverNotOnWayNotifications}
            />
          </ConfigDetail>

          <ConfigDetail
            title="SMS Customer Reminder & OpChange Notifications enabled"
            description="Send SMS reminders for upcoming jobs and job changes to customers."
          >
            <Controller
              name="enabledSmsCustomerReminderAndOpchangeNotifications"
              control={control}
              render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
            />
          </ConfigDetail>
        </div>
      </Panel>
    </form>
  );
};
