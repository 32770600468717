import { createContext, useContext, useState } from "react";
import { UserAccount } from "@/redux/slices/userAccount/types";

type UserAccountStep = "add" | "edit" | "delete" | undefined;

interface UserAccountsState {
  selectedUserAccount?: UserAccount;
  setSelectedUserAccount: React.Dispatch<React.SetStateAction<UserAccount | undefined>>;
  step: UserAccountStep;
  setStep: React.Dispatch<React.SetStateAction<UserAccountStep>>;
}

const UserAccountsContext = createContext<UserAccountsState | null>(null);

export const UserAccountsContextProvider = ({ children }: React.PropsWithChildren) => {
  const [selectedUserAccount, setSelectedUserAccount] = useState<UserAccount>();
  const [step, setStep] = useState<UserAccountStep>();

  return (
    <UserAccountsContext.Provider value={{ selectedUserAccount, setSelectedUserAccount, step, setStep }}>
      {children}
    </UserAccountsContext.Provider>
  );
};

export const useUserAccountsContext = () => {
  const context = useContext(UserAccountsContext);
  if (!context) throw new Error("useUserAccountsContext must be used within a UserAccountsContextProvider");

  return context;
};
