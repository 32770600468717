import { endOfDay, startOfDay } from "date-fns";
import { DateParam, useQueryParams, withDefault } from "use-query-params";

const DEFAULT_DATES = [startOfDay(new Date()), endOfDay(new Date())];

export const useBookingDates = () => {
  const [selectedDates, setSelectedDates] = useQueryParams({
    dateFrom: withDefault(DateParam, DEFAULT_DATES[0]),
    dateTo: withDefault(DateParam, DEFAULT_DATES[1]),
  });

  return [selectedDates, setSelectedDates] as const;
};
