import { useEffect, useState } from "react";
import { Button, CardPreview, Divider, Loading } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { companyApi, useLazyGetCompanyPaymentMethodDetailsQuery } from "@/redux/apis/company/companyApi";
import { useTriggerInvoiceManualPaymentMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setPayByCreditCardStep } from "@/redux/slices/payment/paymentSlice";
import { invoicingInvoicesPayByCreditCardSelector } from "@/redux/slices/payment/selectors";
import { Invoice } from "@/redux/slices/payment/types";
import { addToast, formatCurrency } from "@/utils";
import { ManualPaymentDetails } from "./ManualPaymentDetails";

interface PayByCreditCardStep2Props {
  invoice: Invoice;
}

export const PayByCreditCardStep2 = ({ invoice }: PayByCreditCardStep2Props) => {
  const dispatch = useAppDispatch();
  const [getCompanyPaymentMethodDetails, { isFetching }] = useLazyGetCompanyPaymentMethodDetailsQuery();
  const [triggerInvoiceManualPayment, { isLoading: isLoadingTriggerInvoiceManualPayment }] = useTriggerInvoiceManualPaymentMutation();
  const { paymentMethodSetupId, paymentMethodDetails, manualPaymentDetails } = useAppSelector(invoicingInvoicesPayByCreditCardSelector);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentMethodSetupId) {
        getCompanyPaymentMethodDetails({
          id: invoice.company.id,
          setupId: paymentMethodSetupId,
        })
          .unwrap()
          .then(() => setIsLoading(false))
          .catch((e) => {
            getErrorMessages(e).forEach((m) => addToast("danger", m));
            setIsLoading(false);
          });
      } else addToast("danger", "Something went wrong. Please contact your administrator.");
    }, 8000);

    return () => clearTimeout(timer);
  }, [paymentMethodSetupId, invoice, getCompanyPaymentMethodDetails]);

  const handlePayNow = () => {
    if (paymentMethodDetails) {
      triggerInvoiceManualPayment({
        id: invoice.id,
        paymentMethodId: paymentMethodDetails.id,
      })
        .unwrap()
        .then(() => {
          dispatch(setPayByCreditCardStep(3));
          dispatch(companyApi.util.invalidateTags(["InvoicesCompanies"]));
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else addToast("danger", "Something went wrong. Please contact your administrator.");
  };

  return (
    <div className="relative flex w-full">
      <div className="relative flex h-full w-full flex-col">
        {(isLoading || isFetching || isLoadingTriggerInvoiceManualPayment) && <Loading />}
        <div className="h-full flex-1 overflow-y-auto p-5">
          <StepIndicator currentStep={2} numSteps={2} className="mb-16 mt-10 justify-center" />
          {paymentMethodDetails && (
            <>
              <CardPreview
                number={paymentMethodDetails.paymentMethod.last4}
                expiryMonth={paymentMethodDetails.paymentMethod.expiryMonth}
                expiryYear={paymentMethodDetails.paymentMethod.expiryYear}
                issuer={paymentMethodDetails.paymentMethod.issuer}
                className="mb-14"
              />
              <ManualPaymentDetails className="flex flex-col px-[22px]" invoice={invoice} paymentMethodDetails={paymentMethodDetails} />
            </>
          )}
        </div>
        <div>
          <Divider />
          <div className="flex flex-col gap-3 px-4 py-5">
            <Button className="w-full text-center" size="lg" onClick={handlePayNow}>
              {manualPaymentDetails
                ? `Pay ${invoice.currency} ${formatCurrency(manualPaymentDetails.amounts.total, 2, invoice.currency)}`
                : "Pay Now"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
