import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAppHostname } from "@/helpers/apiHelpers";
import { useProviderCode } from "@/hooks";
import { useLazyVerifyProviderQuery } from "@/redux/apis/auth/authApi";
import { useLazyGetSiteInfoQuery } from "@/redux/apis/resource/resourceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setConfig, setSiteInfo } from "@/redux/slices/config/configSlice";
import { configSelector } from "@/redux/slices/config/selectors";
import { SiteConfig, SiteInfo } from "@/redux/slices/config/types";
import { Operator } from "@/redux/slices/operator/types";
import { transformLegacySiteConfig } from "../helpers";

export type LegacySiteConfig = {
  siteInfo: SiteInfo;
  operator: Operator;
};

type UseLegacySiteConfig =
  | {
      isReady: false;
      config: undefined;
    }
  | {
      isReady: true;
      config: SiteConfig;
    };

const hostname = getAppHostname();

export const useLegacySiteConfig = (): UseLegacySiteConfig => {
  const dispatch = useAppDispatch();
  const config = useAppSelector(configSelector);

  const [getSiteInfo] = useLazyGetSiteInfoQuery();
  const [verify] = useLazyVerifyProviderQuery();

  const [params] = useSearchParams();
  const type = params.get("type");
  const providerCode = useProviderCode();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Using legacy site config ...");
    getSiteInfo(hostname)
      .unwrap()
      .then((siteInfo) => {
        verify({ providerCode: providerCode || siteInfo.key, apiUrl: siteInfo.apiUrl, forDriver: type === "driver" })
          .unwrap()
          .then((operator) => {
            dispatch(setSiteInfo(siteInfo));
            dispatch(setConfig(transformLegacySiteConfig({ siteInfo, operator })));
          });
      })
      .catch((e) => {
        console.error("Unable to configure provider", {
          hostname,
          error: e,
        });
        navigate("/404");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!config) return { isReady: false, config: undefined };
  return { isReady: true, config };
};
