import { z } from "zod";

export const vehicleCategoryFormSchema = z.object({
  vehicleCategoryName: z.string().nonempty("Field is required and can't be empty.").max(32, "Maximum of 32 characters."),
  numberOfPassengers: z.coerce.number().positive().max(999).step(1, "Field must be a whole number."),
  numberOfBags: z.coerce.number().nonnegative().max(999).step(1, "Field must be a whole number."),
  vehicleCategoryMultiplier: z.coerce.number().min(-99).max(99999).step(1, "Field must be a whole number."),
  waitingTimeCost: z.coerce.number().nonnegative().max(99999).step(0.01, "Field must be a maximum of 2 decimal places."),
  globalVehicle: z.string(),
  vehicleCollectJobs: z.array(z.string()).optional(),
  enabledCustomCommissionRate: z.boolean(),
  customCommissionRate: z.coerce.number().nonnegative().max(100).step(1, "Field must be a whole number."),
});

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  delete: {
    title: "Are you sure?",
    description: "This will delete this vehicle category permanently. You cannot undo this action, do you wish to continue?",
  },
  error: {
    title: "Unable to do this action",
    description: "Unable to remove. Vehicle type is attached to vehicles. Please remove relationships and retry",
  },
};
