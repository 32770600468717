import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Typography } from "@/components/atoms";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryCommissionField = () => {
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  const isCommissionEnabled = watch("enableCustomCommission");

  return (
    <div className="flex w-full flex-col gap-y-1">
      <AddOnTextInput
        type="number"
        className="[&_input]:w-[60px]"
        endAddOn="%"
        placeholder="0"
        step="0.01"
        defaultValue={0}
        min={0}
        max={100}
        {...register("commissionRate", { valueAsNumber: true })}
        disabled={!isCommissionEnabled}
        hasError={!!errors.commissionRate}
      />
      <Typography variant="small" className="text-neutral-dark-gray">
        Enter Custom Driver Commission for this Vehicle Category
      </Typography>
      <ErrorMessage errors={errors} name="commissionRate" />
    </div>
  );
};
