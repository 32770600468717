import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpOperatorFormData } from "../types";

export const BusinessOfficialNameField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Business Official Name</Typography>
      <TextInput
        className="w-full"
        placeholder="Enter Company Official Name"
        maxLength={45}
        hasError={!!errors.businessOfficialName}
        {...register("businessOfficialName")}
      />
      <ErrorMessage errors={errors} name="businessOfficialName" />
    </div>
  );
};
