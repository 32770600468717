import { Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setCompanyAction } from "@/redux/slices/company/companySlice";
import { companyActionSelector } from "@/redux/slices/company/selectors";
import { CompaniesRemove } from "./contents/CompaniesRemove";

export const CompaniesModals = () => {
  const action = useAppSelector(companyActionSelector);
  const dispatch = useAppDispatch();

  const onClose = () => dispatch(setCompanyAction(undefined));

  return (
    <>
      <Modal open={action === "removeCompany"} onClose={onClose}>
        <CompaniesRemove onClose={onClose} />
      </Modal>
    </>
  );
};
