import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { Icon, Typography, Modal, Button, MultiEmailInput, Loading, Alert } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useGeneratePreviewMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { invoicingOptionsSelector, previewInvoiceSelector } from "@/redux/slices/payment/selectors";
import { addToast, formatCurrency } from "@/utils";
import { GenerateInvoiceFormData } from "../types";
import { useEffect, useState } from "react";
import { useLazyGetInvoicePreviewQuery } from "@/redux/apis/document/documentApi";
import { clearPreviewInvoice } from "@/redux/slices/payment/paymentSlice";

interface GenerateInvoiceModalProps {
  open: boolean;
  companyId: string;
  companyName: string;
  isSubmitting: boolean;
  onClose: () => void;
  onSend: () => void;
  onSave: () => void;
}

export const GenerateInvoiceModal = ({
  open,
  companyId,
  companyName,
  isSubmitting,
  onClose,
  onSend,
  onSave,
}: GenerateInvoiceModalProps) => {
  const { id: operatorId } = useOperator();
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector(invoicingOptionsSelector);
  const { watch, setValue } = useFormContext<GenerateInvoiceFormData>();
  const watchJobs = watch("jobs");
  const watchEmails = watch("emails");
  const currencyValue = currency[0]?.value;

  const [generatePreview, { data: generatePreviewData }] = useGeneratePreviewMutation();
  const [getInvoicePreview, { isFetching }] = useLazyGetInvoicePreviewQuery();
  const previewInvoice = useAppSelector(previewInvoiceSelector);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (generatePreviewData && !isFetching) {
      if (previewInvoice?.status !== "SUCCESS") {
        setTimeout(() => {
          getInvoicePreview({
            uuid: generatePreviewData.uuid,
            owner_uuid: operatorId,
          })
          .unwrap()
          .catch((e) => {
            getErrorMessages(e).forEach((m) => addToast("danger", m));
            setIsLoading(false);
          });
        }, 5000);
      } else {
        window.open(previewInvoice.filename, "_blank");
        setIsLoading(false);
      };
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatePreviewData, isFetching])

  const handlePreviewInvoice = () => {
    setIsLoading(true);
    dispatch(clearPreviewInvoice());

    const generatePreviewData = {
      company_uuid: companyId,
      jobs: watchJobs.map((job) => job.id),
    };

    generatePreview(generatePreviewData)
      .unwrap()
      .catch((e) => {
        if (e.status === "FETCH_ERROR" || Number(e.status.toString()[0]) === 5) addToast("danger", "Preview Unavailable - Please try again later");
        else getErrorMessages(e).forEach((m) => addToast("danger", m));
        setIsLoading(false);
      });
  };

  const getTotalJobCost = () => {
    let totalCost = 0;
    for (const job of watchJobs) {
      totalCost += job.cost;
    }

    return totalCost;
  };

  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Generate Invoice
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
        </div>
      </div>
    </div>
  );

  const renderButtons = () => (
    <div className="mt-2.5 flex flex-col gap-y-4">
      <Button size="lg" variant="primary" onClick={onSend}>
        Send now to Email(s) listed
      </Button>
      <Button size="lg" variant="secondary" onClick={onSave}>
        Save as Draft
      </Button>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!(isLoading || isSubmitting)) onClose();
      }}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {(isLoading || isSubmitting) && <Loading />}
      {renderHeader()}
      <div className="flex flex-col gap-y-6 py-3">
        <div className="flex flex-col gap-y-3">
          <Typography variant="paragraph">
            Generate an invoice for{" "}
            <Link to={`/operator/${operatorId}/companies/${companyId}`} target="_blank" className="text-info">
              {companyName}
            </Link>{" "}
            with {watchJobs.length} selected jobs totaling {`${currencyValue} ${formatCurrency(getTotalJobCost(), 2, currencyValue)}`}. Save
            as draft or send now. After generation or saving as a draft, you can view the invoice in the &apos;Invoices&apos; tab.
          </Typography>
          <Alert type="info" message="Once you've generated an invoice it cannot be edited. To change the invoice, it must be cancelled and re-issued" />
        </div>
        <div className="flex items-center justify-center">
          <Button variant="tertiary" startIcon="SearchNormal1" size="sm" onClick={handlePreviewInvoice}>
            Preview Invoice
          </Button>
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Send Email(s) to</Typography>
          <MultiEmailInput emails={watchEmails} onChange={(emails) => setValue("emails", emails)} />
        </div>
      </div>
      {renderButtons()}
    </Modal>
  );
};
