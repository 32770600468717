import qs from "query-string";
import { Outlet, RouteObject } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { NotFound, RouteError } from "@/components/pages";
import { Public } from "@/containers";
import { authRoutes } from "./auth";
import { privateRoutes } from "./private";
import { publicRoutes } from "./public";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: qs.parse,
          objectToSearchString: qs.stringify,
        }}
      >
        <Outlet />
      </QueryParamProvider>
    ),
    children: [
      publicRoutes,
      privateRoutes,
      {
        path: "/",
        element: <Public />,
        errorElement: <RouteError />,
        children: [
          ...authRoutes,
          {
            path: "404",
            element: <NotFound />,
          },
          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
    ],
  },
];
