import { useFormContext } from "react-hook-form";
import { ErrorMessage, RadioInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TransferTypeFormData } from "../types";

export const TripTypeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TransferTypeFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="action">Trip Type</Typography>
      <div className="flex flex-col sm:flex-row gap-x-6 gap-y-4">
        <div className="flex flex-row gap-6">
          <RadioInput label="Hourly" value="hourly_rate" {...register("tripType")} />
          <RadioInput label="Daily" value="daily_rate" {...register("tripType")} />
        </div>
        <div className="flex flex-row gap-6">
          <RadioInput label="Standard" value="journey_rate" {...register("tripType")} />
          <RadioInput label="Fixed Price" value="fixed_rate" {...register("tripType")} />
        </div>
      </div>
      <ErrorMessage errors={errors} name="tripType" />
    </div>
  );
};
