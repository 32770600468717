import z from "zod";

export const clientsAddFormSchema = z
  .object({
    firstName: z
      .string()
      .nonempty("Field is required and can't be empty.")
      .min(2, "Minimum of 2 characters.")
      .max(45, "Maximum of 45 characters."),
    lastName: z
      .string()
      .nonempty("Field is required and can't be empty.")
      .min(2, "Minimum of 2 characters.")
      .max(45, "Maximum of 45 characters."),
    company: z.string().optional(),
    email: z
      .string()
      .nonempty("Field is required and can't be empty.")
      .min(2, "Minimum of 2 characters.")
      .max(100, "Maximum of 100 characters."),
    contactNumber: z
      .string()
      .nullable()
      .refine((val) => val && val.length > 0, { message: "Field is required and can't be empty." }),
    password: z
      .string()
      .nonempty("Field is required and can't be empty.")
      .min(6, "Minimum of 6 characters.")
      .max(65, "Maximum of 65 characters."),
    confirmPassword: z
      .string()
      .nonempty("Field is required and can't be empty.")
      .min(6, "Minimum of 6 characters.")
      .max(65, "Maximum of 65 characters."),
    sendLoginInstructions: z.boolean(),
    howDidTheClientHearAboutUs: z.string().optional(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match. Please try again.",
    path: ["confirmPassword"],
  });

export const importFields = [
  {
    label: "First Name",
    key: "firstName",
    fieldType: {
      type: "input",
    },
    example: "John",
    validations: [
      {
        rule: "required",
        errorMessage: "First Name is required",
        level: "error",
      },
      {
        rule: "regex",
        value: /^.{0,45}$/,
        errorMessage: "Maximum of 45 characters",
        level: "error",
      },
    ],
  },
  {
    label: "Last Name",
    key: "lastName",
    fieldType: {
      type: "input",
    },
    example: "Doe",
    validations: [
      {
        rule: "required",
        errorMessage: "Last Name is required",
        level: "error",
      },
      {
        rule: "regex",
        value: /^.{0,45}$/,
        errorMessage: "Maximum of 45 characters",
        level: "error",
      },
    ],
  },
  {
    label: "Email",
    key: "email",
    fieldType: {
      type: "input",
    },
    example: "john.doe@gmail.com",
    validations: [
      {
        rule: "required",
        errorMessage: "Email is required",
        level: "error",
      },
      {
        rule: "regex",
        value: /^.{0,100}$/,
        errorMessage: "Minimum of 2 and maximum of 100 characters",
        level: "error",
      },
      {
        rule: "regex",
        /* eslint-disable no-useless-escape */
        value: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        errorMessage: "Email is invalid",
        level: "error",
      },
    ],
  },
  {
    label: "Contact Number",
    key: "contactNumber",
    fieldType: {
      type: "input",
    },
    example: "+61*********",
    validations: [
      {
        rule: "required",
        errorMessage: "Contact Number is required",
        level: "error",
      },
      {
        rule: "regex",
        value: /^.{0,45}$/,
        errorMessage: "Maximum of 45 characters",
        level: "error",
      },
    ],
  },
  {
    label: "Company Name",
    key: "companyName",
    fieldType: {
      type: "input",
    },
    example: "RideMinder",
    validations: [
      {
        rule: "regex",
        value: /^.{0,100}$/,
        errorMessage: "Maximum of 100 characters",
        level: "error",
      },
    ],
  },
  {
    label: "Unique Identifier",
    key: "uniqueIdentifier",
    fieldType: {
      type: "input",
    },
    example: "CLI001",
    validations: [
      {
        rule: "regex",
        value: /^.{0,64}$/,
        errorMessage: "Maximum of 64 characters",
        level: "error",
      },
    ],
  },
  {
    label: "Account Type",
    key: "accountType",
    fieldType: {
      type: "input",
    },
    example: "Passenger",
  },
] as const;