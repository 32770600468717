import { Field, Input } from "@headlessui/react";
import { forwardRef } from "react";
import { clsx } from "@/utils";
import { Typography } from "..";
import { AddOnTextInputProps } from "./types";

export const AddOnTextInput = forwardRef<HTMLInputElement, AddOnTextInputProps>((addOnTextInputProps, ref) => {
  const { type = "text", size = "lg", hasError, startAddOn, endAddOn, className, ...props } = addOnTextInputProps;

  const errorClass = {
    "!border-danger": hasError,
  };

  const sizeClass = {
    "h-[32px] text-sm": size === "sm",
    "h-[38px] text-base": size === "md",
    "h-[46px] text-base": size === "lg",
  };

  const borderClass = {
    "rounded-r-lg": startAddOn && !endAddOn,
    "rounded-l-lg": endAddOn && !startAddOn,
  };

  const addonBaseClass =
    "w-fit inline-flex items-center border border-neutral-mid-gray bg-neutral-gray text-neutral-dark-gray whitespace-nowrap";

  const baseClass =
    "block w-full border-neutral-mid-gray px-4 text-base text-neutral-black placeholder-neutral-dark-gray focus:border-primary focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-surface-gray disabled:text-neutral-dark-gray";

  return (
    <div className={clsx("flex focus-within:z-10", className)}>
      {startAddOn ? (
        <Typography className={clsx(addonBaseClass, "rounded-l-lg border-r-0 pl-4 pr-2.5")} variant="paragraph" data-testid="start-add-on">
          {startAddOn}
        </Typography>
      ) : null}

      <Field>
        <Input type={type} ref={ref} className={clsx(baseClass, borderClass, sizeClass, errorClass)} {...props} />
      </Field>
      {endAddOn ? (
        <Typography className={clsx(addonBaseClass, "rounded-r-lg border-l-0 pl-2.5 pr-4")} variant="paragraph" data-testid="end-add-on">
          {endAddOn}
        </Typography>
      ) : null}
    </div>
  );
});

AddOnTextInput.displayName = "AddOnTextInput";
