import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, DropdownSelectOption, Typography } from "@/components/atoms";
import { SearchCompanyResponse } from "@/redux/apis/typeahead/types";
import { ClientsFilterFormData } from "../types";

interface SelectCompanyFilterProps {
  setQuery: (value: string) => void;
  isFetching: boolean;
  companies?: SearchCompanyResponse;
}

export const SelectCompanyFilter = ({ companies, setQuery, isFetching }: SelectCompanyFilterProps) => {
  const { control } = useFormContext<ClientsFilterFormData>();
  const options: DropdownSelectOption[] = companies
    ? companies.map((i) => ({
        value: i.id,
        name: i.name,
      }))
    : [];

  return (
    <div className="rounded-lg bg-neutral-surface-gray p-5">
      <Typography variant="paragraph">Select Company</Typography>
      <Controller
        control={control}
        name="company"
        render={({ field }) => (
          <Autocomplete
            placeholder="Select Company Name"
            options={options}
            isLoading={isFetching}
            value={options.find((i) => i.value === field.value) ?? null}
            onValueChange={(option) => field.onChange(option?.value ?? "")}
            onQueryChange={setQuery}
            size="md"
          />
        )}
      />
    </div>
  );
};
