import { createContext, useContext, useEffect, useState } from "react";
import { useLazyGetNationwideFixedPricingQuery } from "@/redux/apis/config/pricing/tripCost";
import { useAppSelector } from "@/redux/hooks";
import { nationwideFixedPricingSelector } from "@/redux/slices/pricing/selectors";
import { NationwideFixedPricing } from "@/redux/slices/pricing/types";
import { useFixedPricingContext } from "./fixedPricing";

interface FixedNationwidePricingState {
  nationwidePricing: NationwideFixedPricing;
  isFetching: boolean;
  isRestricted: boolean;
}

const FixedNationwidePricingContext = createContext<FixedNationwidePricingState | null>(null);

export const FixedNationwidePricingContextProvider = ({ children }: React.PropsWithChildren) => {
  const { isFetching: isFetchingPricingProfiles, pricingProfile } = useFixedPricingContext();

  const [getNationwidePricing, { isFetching: isFetchingNationwidePricing, isSuccess: isSuccessNationwidePricing }] =
    useLazyGetNationwideFixedPricingQuery();
  const nationwidePricing = useAppSelector(nationwideFixedPricingSelector);
  const [isRestricted, setIsRestricted] = useState(false);

  useEffect(() => {
    if (!isFetchingPricingProfiles && pricingProfile) getNationwidePricing({ id: pricingProfile.id });
  }, [isFetchingPricingProfiles, pricingProfile, getNationwidePricing]);

  // restricted check
  useEffect(() => {
    if (isSuccessNationwidePricing && (!nationwidePricing || nationwidePricing.managed)) setIsRestricted(true);
  }, [isSuccessNationwidePricing, nationwidePricing]);

  return (
    <FixedNationwidePricingContext.Provider value={{ isFetching: isFetchingNationwidePricing, nationwidePricing, isRestricted }}>
      {children}
    </FixedNationwidePricingContext.Provider>
  );
};

export const useFixedNationwidePricingContext = () => {
  const context = useContext(FixedNationwidePricingContext);
  if (!context) throw new Error("useFixedNationwidePricingContext must be used within a FixedNationwidePricingContextProvider");

  return context;
};
