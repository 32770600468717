import { useOperator } from "@/hooks";
import { useSelectedBooking } from "./useSelectedBooking";

export const useIsOperatorOwnJob = () => {
  const {
    operator: { providerKey },
  } = useSelectedBooking();
  const { providerCode } = useOperator();

  return providerKey.toLowerCase() === providerCode.toLowerCase();
};
