import { EmptyState } from "@/components/molecules";

export const ArchivedDriversEmptyState = ({ hasFilters }: { hasFilters?: boolean; }) => {
  return (
    <EmptyState 
      title="Archived Drivers" 
      description={hasFilters ? "There are no drivers matching your filter criteria at the moment." : "It looks like you don't have any archive drivers. Here's where you'll find the list of your archived drivers."} 
    />
  );
};
