import { flexRender } from "@tanstack/react-table";
import { DataTableCell as Cell } from "../types";

export interface DataTableCellProps<T extends object> extends React.ComponentProps<"td"> {
  cell: Cell<T, unknown>;
}

export const DataTableCell = <T extends object>({ cell, className, ...props }: DataTableCellProps<T>) => {
  return (
    <td
      key={cell.id}
      className={className}
      style={{ width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? "auto" : cell.column.getSize() }}
      {...props}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};
