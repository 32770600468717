import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";
import {
  Button,
  Divider,
  ErrorMessage,
  Icon,
  IconButton,
  Loading,
  RangeSlider,
  Toggle,
  Typography,
  SidePanel,
  Toast,
  Tooltip,
  AddOnTextInput,
} from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOffloadJobToNetworkMutation } from "@/redux/apis/booking/endpoints/actions";
import { useLazyGetDispatchSettingsQuery } from "@/redux/apis/config/admin/dispatchSettings";
import { dispatchSettingsSelector } from "@/redux/slices/admin/selectors";
import { addNotification, addToast, formatCurrency } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

interface OffloadJobToNetworkProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  driverPercentage: z.coerce.number().min(0).max(100),
  offloadToPrivate: z.boolean(),
  offloadToPublic: z.boolean(),
});

type FormData = z.infer<typeof schema>;

export const OffloadJobToNetwork = ({ open, onClose }: OffloadJobToNetworkProps) => {
  const {
    id: bookingId,
    cost: { currency, percentageToDriver, total, offloadCalculation },
    metadata: { isOffloadToPublic, isOffloadToPrivate },
    account,
    dates: { pickup },
  } = useSelectedBooking();
  const { details } = useSelector(dispatchSettingsSelector);
  const [getDispatchSettings, { isFetching }] = useLazyGetDispatchSettingsQuery();
  const [offloadJobToNetwork, { isLoading: isOffloading }] = useOffloadJobToNetworkMutation();
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      driverPercentage: percentageToDriver,
      offloadToPrivate: isOffloadToPrivate,
      offloadToPublic: isOffloadToPublic,
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (open) getDispatchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = form;

  useEffect(() => {
    reset({
      driverPercentage: percentageToDriver,
      offloadToPrivate: isOffloadToPrivate,
      offloadToPublic: isOffloadToPublic,
    });
  }, [details, isOffloadToPrivate, isOffloadToPublic, percentageToDriver, reset]);

  const onSubmit = async (data: FormData) => {
    await offloadJobToNetwork({
      bookingId,
      ...data,
    })
      .unwrap()
      .then(() => {
        addNotification("success", "Offload Job to Network", "Successfully offloaded job to networks");
        reset();
        onClose();
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
      });
  };

  if (!offloadCalculation || !account) return null;

  const driverPercentage = form.watch("driverPercentage");
  const potentialPayment = total * (driverPercentage / 100);
  const offloadFee =
    offloadCalculation.type === "percentage"
      ? potentialPayment * (offloadCalculation.value / 100)
      : potentialPayment * (offloadCalculation.value / 100);
  const driverFee = potentialPayment - offloadFee;

  const isNeverOffload = account.flags.dispatchRule === "never";
  const isPastPickupTime = new Date(pickup) < new Date();

  return (
    <SidePanel open={open} onClose={onClose} width={550} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="ArchiveBook" size="lg" />
          <Typography variant="title">Offload Job to Your Networks</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <form onSubmit={handleSubmit(onSubmit)} className="relative flex h-full flex-col">
        {isFetching && <Loading />}
        {isPastPickupTime && (
          <Toast
            visible
            className="mx-4 mt-4 shadow-none [&>.typography]:font-medium [&>.typography]:leading-tight"
            type="danger"
            message="The pickup time for this job has passed and offload options are no longer available"
            customIcon={<Icon name="InfoCircle" variant="Bold" size="lg" className="shrink-0" />}
          />
        )}
        {isNeverOffload && (
          <Toast
            className="mx-4 mt-4 shadow-none [&>.typography]:font-medium [&>.typography]:leading-tight"
            type="danger"
            visible
            message="This client has been flagged to ‘never offload’. Please be aware that by offloading you will be overriding this client request"
            customIcon={<Icon name="InfoCircle" variant="Bold" size="lg" className="shrink-0" />}
          />
        )}
        <div className="flex-1 space-y-4 p-4">
          <div className="bg-neutral-surface-gray p-5">
            <Typography variant="paragraph" className={isNeverOffload ? "text-neutral-dark-gray" : ""}>
              By Offloading Job <span className="text-primary">{bookingId}</span>, you accept that our dispatch engine will try it’s best to
              cover the job. We do not guarantee a job will be covered.
            </Typography>
            <div className="mt-5 flex flex-col">
              <Typography variant="action">Driver Percentage</Typography>
              <Typography variant="paragraph" className="text-neutral-dark-gray">
                Slide or enter a driver percentage fee
              </Typography>

              <div className="flex items-center gap-3">
                <Controller
                  name="driverPercentage"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <div className="flex w-full items-center gap-5">
                      <RangeSlider
                        value={value}
                        onChange={(val) => {
                          onChange(Number(val));
                        }}
                        min={0}
                        max={95}
                        className="flex-1"
                      />
                      <AddOnTextInput
                        endAddOn="%"
                        value={value}
                        onChange={(e) => onChange(Number(e.target.value))}
                        type="number"
                        className="w-[90px]"
                      />
                    </div>
                  )}
                />
              </div>
              <ErrorMessage errors={formErrors} name="driverPercentage" />
            </div>
          </div>
          <div className="space-y-4 bg-neutral-surface-gray p-5">
            <Controller
              name="offloadToPrivate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-3">
                  <Toggle checked={value} onChange={onChange} />
                  <Typography>Offload to the Private Network</Typography>
                </div>
              )}
            />
            <Controller
              name="offloadToPublic"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-3">
                  <Toggle checked={value} onChange={onChange} />
                  <Typography>Offload to the Public Network</Typography>
                </div>
              )}
            />
          </div>
          <div className="bg-neutral-surface-gray p-5">
            <div className="flex justify-between">
              <Typography>Estimated Driver Fee:</Typography>
              <Typography variant="action">{formatCurrency(driverFee, 2, currency)}</Typography>
            </div>
          </div>
        </div>

        <div className="p-5">
          <Divider className="-mx-5" />

          <Tooltip
            disabled={!isNeverOffload || isPastPickupTime}
            maxWidth={400}
            content="This client is set to never offload. By continuing you will be overriding this client request"
          >
            <Button
              type="submit"
              disabled={isOffloading || isPastPickupTime}
              className="mt-5 flex w-full items-center gap-2 [&>svg]:text-warning"
              variant="primary"
              startIcon={isNeverOffload ? "Danger" : undefined}
              iconVariant="Bold"
              size="lg"
            >
              <span>Offload Job</span>
            </Button>
          </Tooltip>
        </div>
      </form>
    </SidePanel>
  );
};
