import { useParams } from "react-router-dom";
import { Panel, Skeleton } from "@/components/atoms";
import { Restricted } from "@/components/pages";
import { useFixedPricingContext, useFixedPricingStateContext } from "../contexts";
import { StateBasedFixedPricingEditor } from "./StateBasedFixedPricingEditor";
import { StateBasedFixedPricingTable } from "./tables/StateBasedFixedPricingTable";

export const StateBasedFixedPricingPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { mode } = useParams();
  const { isFetching: isFetchingPricingProfiles, pricingProfile, isRestricted: isRestrictedPricingProfile } = useFixedPricingContext();
  const { isFetching: isFetchingPricingStates, pricingState, isRestricted: isRestrictedPricingState } = useFixedPricingStateContext();
  const isFetching = isFetchingPricingProfiles || isFetchingPricingStates || !pricingProfile || !pricingState;
  const isRestricted = isRestrictedPricingProfile || isRestrictedPricingState;

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <Panel className={className} {...props}>
          {mode === "edit" ? (
            <StateBasedFixedPricingEditor pricing={pricingState} pricingProfile={pricingProfile} />
          ) : (
            <StateBasedFixedPricingTable pricing={pricingState} />
          )}
        </Panel>
      )}
    </>
  );
};
