import { Controller, useFormContext } from "react-hook-form";
import { DateInput, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpDriverFormData } from "../types";

export const DateOfBirthField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SignUpDriverFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Date of Birth</Typography>
      <Controller
        name="dateOfBirth"
        control={control}
        render={({ field }) => (
          <DateInput
            className="w-full"
            onChange={(date) => field.onChange(date)}
            selected={field.value}
            placeholderText="-- / -- / ----"
            hasError={!!errors.dateOfBirth}
          />
        )}
      />
      <ErrorMessage errors={errors} name="dateOfBirth" />
    </div>
  );
};
