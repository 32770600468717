import { useMemo } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetOffloadDriversParams } from "@/redux/apis/driver/types";
import { useDriversFilters } from "../features/Filters/useDriversFilters";

export const useGetOffloadDriversParams = () => {
  const { page, pageSize } = usePagination();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const [search, setSearch] = useSearch();
  const [showBlocked, setShowBlocked] = useQueryParam("blocked", BooleanParam);
  const { params: filterParams } = useDriversFilters();

  const params: GetOffloadDriversParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      sort: sortKey,
      direction,
      search,
      is_blocked: typeof showBlocked === "boolean" ? Number(showBlocked) : undefined,
      ...filterParams,
    }),
    [page, pageSize, sortKey, direction, search, showBlocked, filterParams]
  );

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    search,
    setSearch,
    showBlocked: showBlocked ?? undefined,
    setShowBlocked,
    filters: filterParams,
  };
};
