import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddTransferTypeFormData } from "../../form";

export const TransferTypeIsDispatchEnabledField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddTransferTypeFormData>();

  return (
    <div className={clsx("flex w-full items-center gap-x-1.5", className)} {...props}>
      <div className="flex flex-1 flex-col">
        <Typography variant="paragraph">Automated Dispatch Enabled</Typography>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          Disabling this option ensures that the job will never be dispatched to your drivers, requires manual driver allocation at all
          times.
        </Typography>
      </div>
      <Controller
        name="isDispatchEnabled"
        control={control}
        render={({ field }) => (
          <div className="flex items-center gap-4">
            <Toggle
              checked={field.value}
              onChange={(checked) => {
                field.onChange(checked);
              }}
            />
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="isDispatchEnabled" />
    </div>
  );
};
