import { Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { GeneralLogo } from "./GeneralLogo";
import RMLogo from "@/assets/images/rm-logo-emblem.svg";
import { useAppSelector } from "@/redux/hooks";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";

export const GeneralHeaderOld = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const operator = useAppSelector(activeOperatorSelector);

  return (
    <header className={clsx("top-0 w-full items-center p-3 sm:p-5 md:fixed", className)} {...props}>
      <div className="flex">
        <GeneralLogo />
        <div className={clsx("hidden items-center gap-2 sm:flex", { hidden: !operator?.singleTenant })}>
          <Typography className="text-neutral-dark-gray">Powered by </Typography>
          <img className="flex h-8 max-h-[32px] object-contain" src={RMLogo} alt="Rideminder" />
        </div>
      </div>
    </header>
  );
};
