import { saveAs } from "file-saver";
import { useCallback } from "react";
import { Button, DropdownMenu, Icon, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyExportExternalSuppliersQuery } from "@/redux/apis/reporting/reportingApi";
import { addToast } from "@/utils";

export const ExportExternalSuppliers = () => {
  const [exportData, { isFetching: isExporting }] = useLazyExportExternalSuppliersQuery();

  const exportSuppliers = useCallback(
    (type: "full" | "active") => {
      exportData({ report_type: type })
        .unwrap()
        .then((res) => saveAs(res.data.path, "external-suppliers"))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    },
    [exportData]
  );

  return (
    <DropdownMenu
      button={
        <DropdownMenu.Button as={Button} variant="secondary" endIcon="arrow-down" isCustomIcon className="inline-flex w-full">
          Export
        </DropdownMenu.Button>
      }
      isCustomButton
      disabled={isExporting}
      position="bottom-start"
    >
      <DropdownMenu.Item onClick={() => exportSuppliers("full")} className="w-[280px]">
        <Icon name="ExportSquare" className="mr-2.5 text-neutral-black" size="sm" />
        <Typography className="text-neutral-dark">Export (Full)</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => exportSuppliers("active")} className="w-[280px]">
        <Icon name="ExportSquare" className="text-neutral-dark mr-2.5" size="sm" />
        <Typography className="text-neutral-dark">Export (Approved)</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};
