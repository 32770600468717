import { OffloadDriver } from "@/redux/slices/driver/types";
import { BlockDriverModal } from "../modals/BlockDriverModal";
import { OffloadDriversStep } from "../types";

interface OffloadDriversModalsProps {
  step: OffloadDriversStep;
  setStep: (step: OffloadDriversStep) => void;
  driver?: OffloadDriver;
  setDriver: (driver?: OffloadDriver) => void;
}

export const OffloadDriversModals = ({ step, setStep, driver, setDriver }: OffloadDriversModalsProps) => {
  const onClose = () => {
    setStep(undefined);
    setDriver(undefined);
  };

  return (
    <>
      <BlockDriverModal open={step === "block"} onClose={onClose} driver={driver} />
    </>
  );
};
