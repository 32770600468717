import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { clsx } from "@/utils";
import { Icon, IconButton, Spinner } from "..";

export interface AutocompleteProps<T extends { name: string }> extends React.ComponentProps<typeof Combobox> {
  onQueryChange?: (value: string) => void;
  onValueChange: (option: T | null) => void;
  options?: Array<T>;
  value: T | null;
  size?: "sm" | "md" | "lg";
  placeholder?: string;
  hasError?: boolean;
  className?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  focused?: boolean;
}

export const Autocomplete = <T extends { name: string }>({
  options,
  value,
  onQueryChange,
  onValueChange,
  size = "lg",
  disabled,
  placeholder = "Search",
  hasError,
  children,
  isLoading = false,
  focused = false,
  className,
}: AutocompleteProps<T>) => {
  const sizeClass = {
    "h-[32px] text-sm": size === "sm",
    "h-[38px] text-base": size === "md",
    "h-[46px] text-base": size === "lg",
  };

  const errorClass = {
    "border-danger": hasError,
  };

  const handleBlur = () => {
    if (value && onQueryChange) onQueryChange(value?.name ?? "");
  };

  const handleClear = () => {
    onValueChange(null);
    if (onQueryChange) onQueryChange("");
  };

  const renderChildren = () => {
    if (children) return children;

    return (
      <ComboboxOptions
        className={clsx(
          "autocomplete-options absolute z-10 mt-2 max-h-72 w-full overflow-auto rounded-md border bg-white text-base shadow-lg empty:hidden",
          {
            hidden: isLoading,
          }
        )}
      >
        {options?.map((option, i) => (
          <ComboboxOption
            key={i}
            value={option}
            className={({ selected }) =>
              clsx(
                "autocomplete-option relative cursor-pointer select-none py-3 pl-3 pr-9",
                selected ? "bg-primary-light" : "text-neutral-black"
              )
            }
          >
            <div className="flex items-center">
              <span className={clsx("ml-3 block truncate text-base font-medium")}>{option.name}</span>
            </div>
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    );
  };

  return (
    <Combobox as="div" className={clsx("relative", className)} value={value} onChange={onValueChange}>
      <div className="relative">
        <ComboboxInput
          className={clsx(
            "autocomplete-input w-full rounded-md border-neutral-mid-gray py-2 pl-10 pr-3 ring-0 placeholder:text-neutral-dark-gray focus:border-primary focus:shadow-input focus:!outline-none focus:ring-0",
            sizeClass,
            errorClass
          )}
          {...(onQueryChange && { onChange: (event) => onQueryChange(event.target.value) })}
          onBlur={handleBlur}
          displayValue={(option: T) => option?.name}
          placeholder={placeholder}
          readOnly={disabled}
          autoFocus={focused}
        />
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          {isLoading ? (
            <Spinner className="text-neutral-dark-gray" />
          ) : (
            <Icon name="SearchNormal1" className="text-neutral-dark-gray" size="sm" />
          )}
        </div>
        {value && (
          <IconButton
            onClick={handleClear}
            iconName="close"
            isCustomIcon
            className="absolute inset-y-0 right-0 p-4 text-black"
            variant="custom"
            size="sm"
          />
        )}
      </div>
      {renderChildren()}
    </Combobox>
  );
};

Autocomplete.Options = ComboboxOptions;
Autocomplete.Option = ComboboxOption;
