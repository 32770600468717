import { isEmpty } from "ramda";
import { useCallback, useEffect, useMemo } from "react";
import { Button, Loading } from "@/components/atoms";
import { DataTable, DataTableRow, DataTableRowProps, EmptyState } from "@/components/molecules";
import { useSortingState } from "@/hooks";
import { useLazyGetClientJobsQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setClientJobs } from "@/redux/slices/payment/paymentSlice";
import { invoicingOptionsSelector } from "@/redux/slices/payment/selectors";
import { ClientJob, UninvoicedJobsCompany } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generateClientJobsColumns, generateClientJobsData } from "../helpers";
import { ClientJobsTableData } from "../types";
import { JobsTable } from "./JobsTable";

interface ClientJobsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  companyIndex: number;
  company: UninvoicedJobsCompany;
  items: ClientJob[];
}

export const ClientJobsTable = ({ companyIndex, company, items, className, ...props }: ClientJobsTableProps) => {
  const dispatch = useAppDispatch();
  const { sorting, setSorting, sortKey, direction } = useSortingState();
  const { currency } = useAppSelector(invoicingOptionsSelector);
  const [getClientJobs, { isFetching }] = useLazyGetClientJobsQuery();
  const { id, clientJobsPage } = company;
  const { current, count } = clientJobsPage;

  useEffect(() => {
    getClientJobs({ companyId: id, sort: sortKey, direction, page: "1" })
      .unwrap()
      .then(({ data }) => {
        dispatch(setClientJobs({ companyIndex, clientJobs: data.clientJobs, clientJobsPage: data.clientJobsPage }));
      });
  }, [getClientJobs, id, sortKey, direction, dispatch, companyIndex]);

  const loadMoreClientJobs = () => {
    getClientJobs({ companyId: id, sort: sortKey, direction, page: (current + 1).toString() })
      .unwrap()
      .then(({ data }) => {
        dispatch(setClientJobs({ companyIndex, clientJobs: [...items, ...data.clientJobs], clientJobsPage: data.clientJobsPage }));
      });
  };

  const getRowProps = useCallback((row: DataTableRow<ClientJobsTableData>) => {
    const handleRowClick = (row: DataTableRow<ClientJobsTableData>) => {
      row.toggleExpanded(!row.getIsExpanded());
    };

    const rowProps: Partial<DataTableRowProps<ClientJobsTableData>> = {
      className: clsx("cursor-pointer", {
        "!bg-neutral-gray": row.getIsExpanded(),
      }),
      onClick: () => handleRowClick(row),
    };

    return rowProps;
  }, []);

  const renderLoadMoreButton = () => {
    const hasMore = current < count;
    if (hasMore) {
      return (
        <div className="flex items-center justify-center">
          <Button size="sm" variant="tertiary" onClick={loadMoreClientJobs} className="!text-info hover:bg-info-light">
            Load more
          </Button>
        </div>
      );
    }
  };

  const data = useMemo(
    () => generateClientJobsData(items, currency[0]?.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateClientJobsColumns(), []);

  return (
    <div className={clsx("relative flex w-full flex-col gap-y-3", className)}>
      {isFetching && <Loading className="rounded-lg" />}
      {isEmpty(data) ? (
        <EmptyState title="Closed Jobs" description="There are currently no closed jobs available" />
      ) : (
        <div className="flex flex-col gap-y-3">
          <DataTable
            className="[&_table]:table-auto overflow-x-auto w-full"
            columns={columns}
            data={data}
            canExpand
            renderSubComponent={(row) => (
              <div className="flex bg-neutral-gray px-5 pb-5 pt-1.5">
                <JobsTable
                  company={company}
                  companyIndex={companyIndex}
                  items={items[row.index].jobs}
                  clientEmail={items[row.index].email}
                  clientLevelSorting={sorting}
                  clientLevelSetSorting={setSorting}
                />
              </div>
            )}
            getRowProps={getRowProps}
            {...props}
          />
          {renderLoadMoreButton()}
        </div>
      )}
    </div>
  );
};
