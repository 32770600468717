import { ComponentPropsWithoutRef } from "react";
import { DropdownSelect, Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
const DEFAULT_SIZES = [12, 25, 50, 100];

const range = (from: number, to: number, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export interface PaginationProps extends ComponentPropsWithoutRef<"nav"> {
  className?: string;
  totalItems: number;
  pageCount: number;
  currentPage: number;
  pageSize?: number;
  pageSizes?: number[];
  onPageClick: (page: number) => void;
  onSizeChange: (size: number) => void;
}

/** reference: https://codesandbox.io/s/wwq489j46l */
export const Pagination = (props: PaginationProps) => {
  const { className, totalItems, pageCount, currentPage, onPageClick, pageSize, pageSizes, onSizeChange } = props;
  const pageNeighbours = Math.max(0, Math.min(1, 2));

  const getPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (pageCount > totalBlocks) {
      let pages = [];

      const leftBound = currentPage - pageNeighbours;
      const rightBound = currentPage + pageNeighbours;
      const beforeLastPage = pageCount - 1;

      const startPage = leftBound > 2 ? leftBound : 2;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      pages = range(startPage, endPage);

      const pagesCount = pages.length;
      const singleSpillOffset = totalNumbers - pagesCount - 1;

      const leftSpill = startPage > 2;
      const rightSpill = endPage < beforeLastPage;

      const leftSpillPage = LEFT_PAGE;
      const rightSpillPage = RIGHT_PAGE;

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1);
        pages = [leftSpillPage, ...extraPages, ...pages];
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset);
        pages = [...pages, ...extraPages, rightSpillPage];
      } else if (leftSpill && rightSpill) {
        pages = [leftSpillPage, ...pages, rightSpillPage];
      }

      return [1, ...pages, pageCount];
    }

    return range(1, pageCount);
  };

  const renderPreviousPageButton = () => {
    const page = currentPage - 1;
    const disabled = page < 1;
    return (
      <li>
        <div
          className={clsx("flex h-[30px] w-[30px] items-center justify-center rounded-lg md:h-[35px] md:w-[35px] xl:h-[45px] xl:w-[45px]", {
            "cursor-pointer text-neutral-black": !disabled,
            " cursor-not-allowed text-neutral-mid-gray": disabled,
          })}
          {...(!disabled && { onClick: () => onPageClick(page) })}
        >
          <Icon name="ArrowLeft2" />
        </div>
      </li>
    );
  };

  const renderNextPageButton = () => {
    const page = currentPage + 1;
    const disabled = page > pageCount;

    return (
      <li>
        <div
          className={clsx("flex h-[30px] w-[30px] items-center justify-center rounded-lg md:h-[35px] md:w-[35px] xl:h-[45px] xl:w-[45px]", {
            "cursor-pointer text-neutral-black": !disabled,
            " cursor-not-allowed text-neutral-mid-gray": disabled,
          })}
          {...(!disabled && { onClick: () => onPageClick(page) })}
        >
          <Icon name="ArrowRight2" />
        </div>
      </li>
    );
  };

  const renderPageSizeSelect = () => {
    if (!pageSize) return null;

    return (
      <div className="flex items-center space-x-[19px] max-md:justify-center">
        <Typography variant="action">View items by:</Typography>
        <div className="w-24">
          <DropdownSelect
            options={(pageSizes || DEFAULT_SIZES).map((i) => i.toString())}
            value={pageSize.toString()}
            onChange={(value) => onSizeChange(Number(value))}
            position="top"
          />
        </div>
      </div>
    );
  };

  if (!totalItems) return null;

  const pages = getPageNumbers();

  return (
    <nav className={clsx("mt-6 flex flex-col gap-3 md:flex-row md:items-center", className)}>
      {renderPageSizeSelect()}

      {pageCount > 1 && (
        <div className={clsx("flex flex-1 justify-center", { "md:justify-end": pageSize })}>
          <ul className="flex gap-2">
            {renderPreviousPageButton()}

            {pages.map((page, index) => {
              if (page === LEFT_PAGE || page === RIGHT_PAGE) {
                return (
                  <li key={index}>
                    <div className={clsx("flex h-[30px] w-[18px] justify-center md:h-[35px] xl:h-[45px]")}>
                      <Typography className="mt-auto" variant="action">
                        ...
                      </Typography>
                    </div>
                  </li>
                );
              }

              return (
                <li key={index}>
                  <div
                    className={clsx(
                      "flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-lg md:h-[35px] md:w-[35px] xl:h-[45px] xl:w-[45px]",
                      {
                        "bg-primary text-white": currentPage === page,
                        "bg-white text-neutral-black hover:bg-neutral-gray": currentPage !== page,
                      }
                    )}
                    onClick={() => onPageClick(page as number)}
                  >
                    <Typography variant="action">{page}</Typography>
                  </div>
                </li>
              );
            })}
            {renderNextPageButton()}
          </ul>
        </div>
      )}
    </nav>
  );
};
