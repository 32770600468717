import { useLocalStorageValue } from "@react-hookz/web";
import { createContext, useMemo, useState } from "react";

export type Layout = {
  layout: { sidebarOpen: boolean; fullScreen: boolean };
  setSidebarOpen: (sidebarOpen: boolean) => void;
  setFullScreen: (fullScreen: boolean) => void;
};

export const LayoutContext = createContext<Layout | null>(null);

export const LayoutProvider = ({ children }: React.PropsWithChildren) => {
  const [fullScreen, setFullScreen] = useState(false);
  const { value: sidebarOpen = true, set: setSidebarOpen } = useLocalStorageValue<boolean>("sidebarOpen", {
    defaultValue: true,
  });

  const layout = useMemo(() => ({ fullScreen, sidebarOpen }), [fullScreen, sidebarOpen]);

  return <LayoutContext.Provider value={{ layout, setFullScreen, setSidebarOpen }}>{children}</LayoutContext.Provider>;
};
