import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "@/redux/baseQuery";
import { transformAccounts } from "./helpers";
import { GetAccountsResponse } from "./types";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getAccounts: builder.query<GetAccountsResponse, void>({
      query: () => "/v3/account-management",
      transformResponse: transformAccounts,
      providesTags: ["Account"],
    }),
  }),
  tagTypes: ["Account"],
});

export const { useLazyGetAccountsQuery } = accountApi;
