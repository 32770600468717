import { useState } from "react";
import { Loading, Skeleton, Typography } from "@/components/atoms";
import { useGetBookingTripLogsQuery } from "@/redux/apis/booking/bookingApi";
import { BookingMarkerState } from "../../../../BookingTripMap/types";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { TripBookingDetails, TripMapWrapper, TripMarkerDetails } from "../../TripActivity/TripActivity";

export const ViewTripDriverActivity = ({ open }: { open: boolean }) => {
  const { id: bookingId } = useSelectedBooking();
  const { data: bookingTrip } = useGetBookingTripLogsQuery(bookingId, { skip: !open });
  const [markerState, setMarkerState] = useState<BookingMarkerState>({});

  return (
    <div className="flex-1 space-y-6">
      <Typography variant="title">Trip Summary</Typography>
      {bookingTrip ? (
        <TripMapWrapper bookingTrip={bookingTrip} markerState={markerState} setMarkerState={setMarkerState} readOnly height={425} />
      ) : (
        <Loading />
      )}
      {bookingTrip ? (
        <TripBookingDetails
          className="[&>.typography]:hidden [&>div>button:first-child]:pb-0 [&>div]:grid-cols-1 [&>div]:gap-0"
          bookingTrip={bookingTrip}
          markerState={markerState}
          setMarkerState={setMarkerState}
          readOnly
        />
      ) : (
        <Skeleton />
      )}
      {bookingTrip ? (
        <TripMarkerDetails
          className="[&>.typography]:hidden [&>div]:grid-cols-2 [&>div]:gap-0 [&>div]:bg-neutral-surface-gray"
          bookingTrip={bookingTrip}
          markerState={markerState}
          setMarkerState={setMarkerState}
          readOnly
        />
      ) : (
        <Skeleton />
      )}
    </div>
  );
};
