import { useFormContext } from "react-hook-form";
import { ErrorMessage, RadioInput, Typography } from "@/components/atoms";
import { ExportReportFormData } from "../types";

export const ReportTypeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ExportReportFormData>();

  return (
    <div className={className} {...props}>
      <Typography variant="action">Report Type</Typography>
      <div className="mt-4 flex flex-col gap-y-6">
        <RadioInput label="Standard Trip Report" value="standard" {...register("reportType")} />
        <RadioInput label="Enhanced Trip Report" value="enhanced" {...register("reportType")} />
      </div>
      <ErrorMessage errors={errors} name="reportType" />
    </div>
  );
};
