import {
  GetCreditCardPaymentsRawResponse,
  GetCreditCardPaymentsResponse,
  GetCreditCardPaymentsSummaryRawResponse,
  GetCreditCardPaymentsSummaryResponse,
  GetDebitHistoryItemRawResponse,
  GetDebitHistoryItemResponse,
  GetDebitHistoryRawResponse,
  GetDebitHistoryResponse,
  GetDebitRecordsRawResponse,
  GetDebitRecordsResponse,
  GetDebitUpcomingRawResponse,
  GetDebitUpcomingResponse,
  GetDebitUpcomingSummaryRawResponse,
  GetDebitUpcomingSummaryResponse,
  GetManualPaymentDetailsRawResponse,
  GetManualPaymentDetailsResponse,
  GetPaymentMethodDetailsRawResponse,
  GetPaymentMethodDetailsResponse,
  GetPaymentMethodSecretRawResponse,
  GetPaymentMethodSecretResponse,
  GetPayoutHistoryItemRawResponse,
  GetPayoutHistoryItemResponse,
  GetPayoutHistoryRawResponse,
  GetPayoutHistoryResponse,
  GetPayoutRecordsRawResponse,
  GetPayoutRecordsResponse,
} from "./types";

export const transformDebitHistory = (res: GetDebitHistoryRawResponse) => {
  const {
    _embedded: { debit: items },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetDebitHistoryResponse = {
    data: {
      items: items.map((i) => ({
        id: i.uuid,
        creationDate: i.creation_date,
        payableDate: i.payable_date,
        amount: i.amount,
        currency: i.currency,
        description: i.description,
        status: i.status,
        documents: i.documents,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformDebitHistoryItem = (res: GetDebitHistoryItemRawResponse) => {
  const response: GetDebitHistoryItemResponse = {
    data: {
      id: res.uuid,
      creationDate: res.creation_date,
      payableDate: res.payable_date,
      amount: res.amount,
      currency: res.currency,
      description: res.description,
      status: res.status,
      documents: res.documents,
    },
  };

  return response;
};

export const transformDebitUpcoming = (res: GetDebitUpcomingRawResponse) => {
  const {
    _embedded: { debit: items },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetDebitUpcomingResponse = {
    data: {
      items: items.map((i) => ({
        identifier: i.identifier,
        dateTime: i.date_time,
        status: i.status,
        amount: i.amount,
        type: i.type,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformDebitUpcomingSummary = (res: GetDebitUpcomingSummaryRawResponse) => {
  const response: GetDebitUpcomingSummaryResponse = {
    data: {
      amount: res.amount,
      currency: res.currency,
    },
  };

  return response;
};

export const transformDebitRecords = (res: GetDebitRecordsRawResponse) => {
  const {
    _embedded: { item: items },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetDebitRecordsResponse = {
    data: {
      items: items.map((i) => ({
        identifier: i.identifier,
        dateTime: i.date_time,
        description: i.description,
        status: i.status,
        amount: i.amount,
        currency: i.currency,
        accountId: i.account ? i.account.uuid : null,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformPayoutHistory = (res: GetPayoutHistoryRawResponse) => {
  const {
    _embedded: { payout: items },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetPayoutHistoryResponse = {
    data: {
      items: items.map((i) => ({
        id: i.uuid,
        arrivalDate: i.arrival_date,
        amount: i.amount,
        currency: i.currency,
        description: i.description,
        status: i.status,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformPayoutHistoryItem = (res: GetPayoutHistoryItemRawResponse) => {
  const response: GetPayoutHistoryItemResponse = {
    data: {
      id: res.uuid,
      arrivalDate: res.arrival_date,
      amount: res.amount,
      currency: res.currency,
      description: res.description,
      status: res.status,
    },
  };

  return response;
};

export const transformPayoutRecords = (res: GetPayoutRecordsRawResponse) => {
  const {
    _embedded: { item: items },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetPayoutRecordsResponse = {
    data: {
      items: items.map((i) => ({
        identifier: i.identifier,
        description: i.description,
        dateTime: i.date_time,
        status: i.status,
        amount: i.amount,
        currency: i.currency,
        accountId: i.account ? i.account.uuid : null,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformCreditCardPayments = (res: GetCreditCardPaymentsRawResponse) => {
  const {
    _embedded: { records: items },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetCreditCardPaymentsResponse = {
    data: {
      items: items.map((i) => ({
        id: i.uuid,
        dateTime: i.date_time,
        jobId: i.job_id,
        account: {
          id: i.account.uuid,
          firstName: i.account.first_name,
          lastName: i.account.last_name,
          email: i.account.email,
          avatar: i.account.avatar,
        },
        amount: i.amount,
        status: i.status,
        statusReason: i.status_reason,
        description: i.description,
        currency: i.currency,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformCreditCardPaymentsSummary = (res: GetCreditCardPaymentsSummaryRawResponse) => {
  const response: GetCreditCardPaymentsSummaryResponse = {
    data: {
      paid: res.summary.paid,
      failed: res.summary.failed,
      ready: res.summary.ready,
      refund: res.summary.refund,
      currency: res.currency,
    },
  };

  return response;
};

export const transformPaymentMethodSecret = (res: GetPaymentMethodSecretRawResponse) => {
  const response: GetPaymentMethodSecretResponse = {
    data: {
      paymentMethodSecret: res.intent_secret,
    },
  };

  return response;
};

export const transformPaymentMethodDetails = (res: GetPaymentMethodDetailsRawResponse) => {
  const response: GetPaymentMethodDetailsResponse = {
    data: {
      id: res.uuid,
      type: res.type,
      method: res.method,
      paymentMethod: res.payment_method,
    },
  };

  return response;
};

export const transformManualPaymentDetails = (res: GetManualPaymentDetailsRawResponse) => {
  const response: GetManualPaymentDetailsResponse = {
    data: {
      id: res.uuid,
      status: res.status,
      amounts: {
        subTotal: res.amounts.sub_total,
        fees: res.amounts.fees,
        total: res.amounts.total,
      },
    },
  };

  return response;
};
