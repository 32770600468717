import { Icon, IconButton, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface IntervalRowProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  note: string;
  renderRange: () => JSX.Element;
  renderCost: () => JSX.Element;
  renderErrors: () => JSX.Element | null;
  onAddNote: () => void;
  onDelete?: () => void;
}

export const IntervalRow = ({
  label,
  note,
  renderRange,
  renderCost,
  renderErrors,
  onAddNote,
  onDelete,
  className,
  ...props
}: IntervalRowProps) => {
  return (
    <div className={clsx("flex flex-shrink-0 flex-row gap-x-2.5", className)} {...props}>
      <div
        className="group flex w-[167px] min-w-[167px] cursor-pointer flex-col justify-center rounded-lg bg-neutral-gray px-5 py-4 hover:ring-1 hover:ring-info"
        onClick={onAddNote}
      >
        <div className="flex flex-row items-center gap-x-1.5">
          <Typography variant="action">{label}</Typography>
          <Icon name="Edit2" size="sm" className="hidden text-info group-hover:block" />
        </div>
        <Typography variant="small" className="overflow-hidden text-ellipsis text-neutral-dark-gray">
          {note}
        </Typography>
      </div>
      <div className="flex flex-1 flex-col gap-y-2 rounded-lg bg-neutral-surface-gray px-5 py-2.5">
        <div className="flex flex-col gap-x-8 gap-y-2 md:flex-row lg:flex-row">
          <div className="flex">{renderRange()}</div>
          <div className="flex">{renderCost()}</div>
        </div>
        {renderErrors()}
      </div>
      <div className="flex w-[34px] items-center">
        {onDelete && (
          <IconButton onClick={onDelete} iconName="Trash" variant="tertiary" className="!text-danger hover:bg-danger-light" size="sm" />
        )}
      </div>
    </div>
  );
};
