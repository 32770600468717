import { Badge, Container, Typography } from "@/components/atoms";
import { getAssetUrl } from "@/utils";
import { Head } from "../Head/Head";
import { useBookingDriverActivityContext } from "./context";
import { BookingInformation } from "./widgets/BookingInformation";
import { BookingTripMapContainer } from "./widgets/BookingTripMapContainer";
import { GuestLogin } from "./widgets/GuestLogin";

export const GuestBookingDriverActivity = () => {
  return (
    <div className="flex min-h-screen flex-col ">
      <Header />
      <div className="flex-1 py-6 max-xl:px-3">
        <Container className="grid h-[calc(100vh-140px)] grid-rows-[auto_1ft_auto] gap-4 lg:grid-cols-[295px_minmax(500px,_1fr)]">
          <Sidebar />
          <BookingTripMapContainer />
        </Container>
      </div>
    </div>
  );
};

const Header = () => {
  const {
    tripLog: {
      provider: {
        name,
        assets: { emblem },
      },
    },
  } = useBookingDriverActivityContext();

  const icon = getAssetUrl(emblem, { height: 48, width: 48, fit: "cover" });

  return (
    <>
      <Head title={name} favicon={icon} />
      <header className="sticky top-0 z-10  w-full border-b border-neutral-mid-gray bg-white p-3.5 sm:p-7">
        <Container className="flex justify-between gap-2">
          <div className="flex items-center gap-2">
            <img className="flex h-8 max-h-[32px] object-contain" src={icon} alt={name} />
            <Typography variant="h3" className="line-clamp-1 overflow-ellipsis">
              {name}
            </Typography>
          </div>
          <div className="flex items-center">
            <TripStatus />
          </div>
        </Container>
      </header>
    </>
  );
};

const TripStatus = () => {
  const { tripLog } = useBookingDriverActivityContext();

  switch (tripLog.job.status) {
    case "awaiting_driver_allocation":
    case "driver_allocated_confirmed":
    case "driver_allocated_not_confirmed":
      return <Badge type="info" message="Trip hasn't started" />;

    case "commenced":
    case "on_way_to_job":
      return <Badge type="danger" message="Live" />;

    case "complete_awaiting_review":
    case "closed":
      return <Badge type="success" message="Completed" />;
    default:
      return null;
  }
};

const Sidebar = () => {
  return (
    <div className="space-y-4">
      <BookingInformation />
      <GuestLogin />
    </div>
  );
};
