import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useLazyGetFixedPricingStatesQuery } from "@/redux/apis/config/pricing/tripCost";
import { useAppSelector } from "@/redux/hooks";
import { fixedPricingStateSelector, fixedPricingStatesSelector } from "@/redux/slices/pricing/selectors";
import { FixedPricingState } from "@/redux/slices/pricing/types";
import { useFixedPricingContext } from "./fixedPricing";

interface FixedPricingStateState {
  pricingStates: FixedPricingState[];
  isFetching: boolean;
  stateName: string | null;
  isRestricted: boolean;
  pricingState?: FixedPricingState;
}

const FixedPricingStateContext = createContext<FixedPricingStateState | null>(null);

export const FixedPricingStateContextProvider = ({ children }: React.PropsWithChildren) => {
  const { isFetching: isFetchingPricingProfiles, pricingProfile } = useFixedPricingContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const stateName = searchParams.get("stateName");
  const { slug = "" } = useParams();

  const [getPricingStates, { isFetching: isFetchingPricingStates, isSuccess: isSuccessPricingStates }] =
    useLazyGetFixedPricingStatesQuery();
  const pricingStates = useAppSelector(fixedPricingStatesSelector);
  const pricingState = useAppSelector(fixedPricingStateSelector(slug));
  const [isRestricted, setIsRestricted] = useState(false);

  useEffect(() => {
    if (!isFetchingPricingProfiles && pricingProfile) getPricingStates({ id: pricingProfile.id });
  }, [isFetchingPricingProfiles, pricingProfile, getPricingStates]);

  // breadcrumbs setup
  useEffect(() => {
    if (pricingState && !pricingState.managed && stateName !== pricingState.state.toUpperCase()) {
      searchParams.set("stateName", pricingState.state.toUpperCase());
      setSearchParams(searchParams);
    }
  }, [pricingState, stateName, searchParams, setSearchParams]);

  // restricted check
  useEffect(() => {
    if (isSuccessPricingStates && (!pricingState || pricingState.managed)) setIsRestricted(true);
  }, [isSuccessPricingStates, pricingState]);

  return (
    <FixedPricingStateContext.Provider
      value={{ pricingStates, isFetching: isFetchingPricingStates, stateName, isRestricted, pricingState }}
    >
      {children}
    </FixedPricingStateContext.Provider>
  );
};

export const useFixedPricingStateContext = () => {
  const context = useContext(FixedPricingStateContext);
  if (!context) throw new Error("useFixedPricingStateContext must be used within a FixedPricingStateContextProvider");

  return context;
};
