import "mapbox-gl/dist/mapbox-gl.css";
import { memo, useEffect } from "react";
import { useBookingTripMap } from "../hooks/useBookingTripMap";
import { Marker } from "./common/Marker";

export const BookingTripMarkers = memo(() => {
  const {
    states: { markerState },
    map: { markers },
    functions: { flyToMarker, toggleMarker },
  } = useBookingTripMap();

  useEffect(() => {
    const activeMarker = Object.keys(markerState).find((key) => markerState[key]);
    if (activeMarker) {
      flyToMarker(activeMarker);
    }
  }, [flyToMarker, markerState]);

  // useEffect(() => {
  //   if (driverCurrentLocationData && isInProgress) {
  //     mapRef?.current?.getMap().on("load", () => {
  //       // toggleMarker({
  //       //   driver: true,
  //       // });
  //       flyToMarker("driver");
  //     });
  //   }
  // }, [driverCurrentLocationData, flyToMarker, isInProgress, mapRef, toggleMarker]);

  return (
    <>
      {markers.map((marker, idx) => {
        return <Marker key={idx} marker={marker} isActive={markerState[marker.id]} onToggle={toggleMarker} />;
      })}
    </>
  );
});

BookingTripMarkers.displayName = "BookingTripMarkers";
