import { useEffect, useState } from "react";
import { Avatar, Button, SearchInput, Typography } from "@/components/atoms";
import { useLayout, useProfile } from "@/hooks";
import { getAssetUrl } from "@/utils";
import { operatorsSelector } from "@/redux/slices/operator/selectors";
import { useAppDispatch, useAppResetApi, useAppSelector } from "@/redux/hooks";
import { logOut } from "@/redux/slices/auth/authSlice";
import { Operator } from "@/redux/slices/operator/types";
import { OperatorList } from "@/components/organisms";
import { isEmpty } from "ramda";

export const Operators = () => {
  const dispatch = useAppDispatch();
  const { setFullScreen } = useLayout();
  const { avatar, displayName } = useProfile();
  const resetApi = useAppResetApi();
  const operators = useAppSelector(operatorsSelector);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchResult, setSearchResult] = useState<Operator[]>([]);
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    setFullScreen(true);
    return () => {
      setFullScreen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    dispatch(logOut());
    resetApi();
  };

  const handleSearch = (value?: string) => {
    if (value) {
      setSearchResult(operators.filter((operator) => operator.name.toLowerCase().includes(value.toLowerCase()))); 
    } else setIsSearchActive(false);
    
    setSearch(value);
  };

  return (
    <div className="flex flex-col items-center w-full min-h-screen pt-[100px] pb-[30px] px-5 bg-[#370a47]">
      <div className="flex flex-col items-center justify-center mb-[72px]">
        <Avatar src={getAssetUrl(avatar)} alt={displayName} size="sm" className="bg-white" />
        <div className="flex flex-col items-center text-white mt-2">
          <Typography variant="title">Hello, {displayName}</Typography>
          <Typography variant="action" className="opacity-50">Administrator</Typography>
        </div>
        <Button 
          variant="secondary"
          size="sm"
          startIcon="LogoutCurve" 
          iconClassName="rotate-180" 
          className="bg-[#370a47] text-white mt-7 hover:bg-[#73547e]"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
      {isSearchActive ? (
        <div className="flex flex-col items-center justify-center text-white">
          <Typography variant="title">Search Operator</Typography>
          <SearchInput 
            placeholder="Search Operator" 
            onSearch={handleSearch}
            search={search}
            className="w-[320px] sm:w-[490px] mt-6 [&_input]:border-[#370a47] [&_input]:text-white [&_input]:bg-[#73547E]" 
            autoFocus
          />
          {!isEmpty(searchResult) && (
            <div className="flex flex-col items-center justify-center gap-3 mt-5">
              <Typography variant="paragraph">Results:</Typography>
              <OperatorList operators={searchResult} />
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center text-white">
          <Typography variant="title">Select Operator</Typography>
          <OperatorList operators={operators} />
          <Button 
            variant="tertiary"
            size="sm"
            startIcon="SearchNormal1" 
            className="bg-[#370a47] !text-white mt-12 hover:bg-[#73547e]"
            onClick={() => setIsSearchActive(true)}
          >
            Search Operator
          </Button>
        </div>
      )}
      <div className="flex flex-1 items-end mt-[60px]">
        <Typography className="text-center text-white opacity-50" variant="small">
          © Copyright {new Date().getFullYear()} - Rideminder Australia Pty Ltd  |  Powered by RideMinder
        </Typography>
      </div>
    </div>
  );
};
