import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { DropdownSelectOption, RadioInput, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { VehicleFilters } from "../types";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  isRegistrationVerified: z.string(),
});

type FormData = z.infer<typeof schema>;

const statusOptions: DropdownSelectOption[] = [
  {
    name: "All",
    value: "all",
  },
  {
    name: "Verified",
    value: "true",
  },
  {
    name: "Unverified",
    value: "false",
  },
];

const getValue = (status: VehicleFilters["isRegistrationVerified"]) => {
  if (!status) return status;
  return statusOptions.find((s) => s.value === status)?.name;
};

export const RegistrationFilter = () => {
  const {
    filters: { isRegistrationVerified },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => getValue(isRegistrationVerified), [isRegistrationVerified]);

  const onClose = () => setFilter("isRegistrationVerified", undefined);

  return (
    <FilterPopover name="isRegistrationVerified" value={value} label="Status" onClose={onClose}>
      <RegistrationFilterForm />
    </FilterPopover>
  );
};

interface RegistrationFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const RegistrationFilterDrawer = ({ open, onOpenChange }: RegistrationFilterDrawerProps) => {
  const {
    filters: { isRegistrationVerified },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => getValue(isRegistrationVerified), [isRegistrationVerified]);

  const onClose = () => setFilter("isRegistrationVerified", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Status" onClose={onClose}>
      <RegistrationFilterForm />
    </FilterDrawer>
  );
};

const RegistrationFilterForm = () => {
  const { setFilter, filters } = useVehicleFilters();

  const { control } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { isRegistrationVerified: filters.isRegistrationVerified ? filters.isRegistrationVerified : undefined },
  });

  return (
    <div className="contents">
      <Typography className="mb-4">Status</Typography>

      <div className="flex flex-col gap-2">
        <Controller
          name="isRegistrationVerified"
          control={control}
          render={({ field }) => (
            <>
              {statusOptions.map((i) => (
                <RadioInput
                  key={i.name}
                  label={i.name}
                  value={i.value}
                  onChange={({ target }) => {
                    field.onChange(target.value);
                    setFilter("isRegistrationVerified", target.value);
                  }}
                  checked={field.value === i.value}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
