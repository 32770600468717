import { isUATorStaging } from "@/helpers/urlHelpers";
import { TransferTypes } from "./TransferTypes";
import { TransferTypesLegacy } from "./TransferTypesLegacy";

export const TransferTypesRoot = () => {
  if (isUATorStaging || import.meta.env.MODE === "development") {
    return <TransferTypes />;
  }

  return <TransferTypesLegacy />;
};

const TransferTypesRootAdd = () => {
  if (isUATorStaging || import.meta.env.MODE === "development") {
    return <TransferTypes.Add />;
  }

  return <TransferTypesLegacy.Add />;
};

const TransferTypesRootEdit = () => {
  if (isUATorStaging || import.meta.env.MODE === "development") {
    return <TransferTypes.Edit />;
  }

  return <TransferTypesLegacy.Edit />;
};

TransferTypesRoot.Add = TransferTypesRootAdd;
TransferTypesRoot.Edit = TransferTypesRootEdit;
