import { reverse } from "ramda";
import { useFormContext } from "react-hook-form";
import { CheckboxInput, Icon, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersFormOptionsSelector } from "@/redux/slices/externalSupplier/selectors";
import { ExternalSuppliersFilters } from "../types";

export const RatingFilter = () => {
  const { ratings } = useAppSelector(externalSuppliersFormOptionsSelector);
  const { watch, setValue } = useFormContext<ExternalSuppliersFilters>();
  const selected = watch("rating");

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) setValue("rating", [...(selected || []), target.value]);
    else
      setValue(
        "rating",
        (selected || []).filter((value) => value !== target.value)
      );
  };

  const handleSelectAll = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    target.checked
      ? setValue(
          "rating",
          ratings.map((i) => i.value)
        )
      : setValue("rating", []);
  };

  const isAllSelected = selected && selected.length > 0 && selected.length === ratings.length;

  return (
    <div className="rounded bg-neutral-surface-gray p-4">
      <Typography className="mb-3">Supplier Rating</Typography>
      <div className="grid gap-4">
        <CheckboxInput id="ratingSelectAll" label="Select all" onChange={handleSelectAll} checked={isAllSelected} />
        {reverse(ratings).map((i) => (
          <CheckboxInput
            onChange={handleChange}
            key={i.value}
            id={i.value}
            label={i.name.replace("Stars", "").replace("Star", "")}
            className="[&_p]:inline-flex [&_p]:flex-row-reverse"
            startIcon={<Icon name="Star1" className="fill-warning text-warning" variant="TwoTone" />}
            value={i.value}
            checked={selected?.includes(i.value)}
            variant="check"
          />
        ))}
      </div>
    </div>
  );
};
