import { useCallback } from "react";
import { DropdownMenu, Icon, Loading, Typography } from "@/components/atoms";
import { useLazyRequestVehicleDocumentQuery } from "@/redux/apis/document/documentApi";
import { useRemoveVehicleDocumentMutation, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { useAppDispatch } from "@/redux/hooks";
import { VehicleDocument } from "@/redux/slices/vehicle/types";
import { addToast, downloadFile } from "@/utils";

interface VehicleDocumentCardProps {
  vehicleId: string;
  document: VehicleDocument;
}
export const VehicleDocumentCard = ({ vehicleId, document }: VehicleDocumentCardProps) => {
  const dispatch = useAppDispatch();
  const fileExtension = document.filename.split(".").pop() ?? "pdf";
  const [removeDocument, { isLoading: isRemoving }] = useRemoveVehicleDocumentMutation();
  const [requestDocument, { isLoading }] = useLazyRequestVehicleDocumentQuery();

  const handleRemoveDocument = useCallback(() => {
    removeDocument({
      documentId: document.uuid,
      vehicleId,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Document removed successfully");
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
      })
      .catch(() => {
        addToast("danger", "Failed to remove document");
      });
  }, [dispatch, document.uuid, removeDocument, vehicleId]);

  const handleClick = () => {
    requestDocument({
      id: document.uuid,
      fileType: "pdf", //todo: get file type from document
      vehicleId,
    })
      .unwrap()
      .then((res) => {
        downloadFile(res.filename, document.uuid);
      });
  };

  const renderIcon = () => {
    switch (fileExtension) {
      case "pdf":
        return <Icon name="file-pdf" size="sm" isCustom />;
      case "doc":
      case "docx":
        return <Icon name="google-doc" size="sm" isCustom />;
      case "jpg":
      case "jpeg":
        return <Icon name="file-jpg" size="sm" isCustom />;
      case "png":
        return <Icon name="file-png" size="sm" isCustom />;
      default:
        return <Icon name="file" size="sm" isCustom />;
    }
  };

  const renderFileType = () => {
    switch (fileExtension) {
      case "pdf":
        return "PDF";
      case "doc":
      case "docx":
        return "Google Docs";
      case "png":
      case "jpg":
      case "jpeg":
        return "Image";
      default:
        return "File";
    }
  };

  return (
    <div className="relative inline-flex w-[170px] flex-col rounded-lg bg-white px-5 py-2 shadow">
      <DropdownMenu
        button={<Icon name="options" isCustom size="sm" />}
        position="bottom-start"
        className="relative z-10 inline-flex translate-x-2 self-end"
        disabled={isLoading}
      >
        <DropdownMenu.Item onClick={handleRemoveDocument} disabled={isRemoving}>
          <Icon name="Trash" className="mr-2.5 text-danger" size="sm" />
          <Typography variant="paragraph" className="text-danger">
            Remove
          </Typography>
        </DropdownMenu.Item>
      </DropdownMenu>

      {isRemoving && <Loading />}

      <div className="flex flex-col place-items-center gap-4">
        <span className="relative mt-2 flex h-14 w-14 cursor-pointer items-center justify-center hover:opacity-75" onClick={handleClick}>
          <Icon name="document" isCustom size={96} className="absolute h-full w-full" />
          <span className="z-10">{renderIcon()}</span>
        </span>
        <Typography
          variant="paragraph"
          className="line-clamp-2 min-h-[42px] cursor-pointer text-center leading-normal hover:opacity-75"
          onClick={handleClick}
        >
          {document.filename}
        </Typography>
        <Typography variant="small" className="w-full border-t border-neutral-mid-gray pt-2 text-center text-neutral-dark-gray">
          {renderFileType()}
        </Typography>
      </div>
    </div>
  );
};
