import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import { LinkButtonProps } from "./types";

export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>((buttonProps, ref) => {
  const { to, target, children, relative = "path", ...props } = buttonProps;

  return (
    <Link to={to} target={target} relative={relative}>
      <Button ref={ref} {...props}>
        {children}
      </Button>
    </Link>
  );
});

LinkButton.displayName = "LinkButton";
