import { useLocalStorageValue } from "@react-hookz/web";
import { flexRender } from "@tanstack/react-table";
import { equals, isEmpty } from "ramda";
import { useEffect } from "react";
import { Icon } from "@/components/atoms";
import { clsx } from "@/utils";
import { useDataTableId } from "../hooks/useDataTableId";
import { DataTableHeader as Header } from "../types";
import { DataTableResizeHandler } from "./DataTableResizeHandler";

export interface DataTableHeaderProps<T extends object> extends React.ComponentProps<"div"> {
  headers: Header<T, unknown>[];
}

export const DataTableHeader = <T extends object>({ className, headers, ...props }: DataTableHeaderProps<T>) => {
  const tableId = useDataTableId();
  const { value: columnSizing, set: setColumnSizing } = useLocalStorageValue(tableId);

  const currentColumnSizing = headers.reduce((acc, header) => {
    acc[header.id] = header.getSize();
    return acc;
  }, {} as Record<string, number>);

  useEffect(() => {
    if (!currentColumnSizing || isEmpty(currentColumnSizing) || equals(columnSizing, currentColumnSizing)) return;

    setColumnSizing(currentColumnSizing);
  }, [columnSizing, currentColumnSizing, headers, setColumnSizing]);

  return (
    <div
      className={clsx("thead sticky -top-1 z-20 flex overflow-hidden rounded-tl-lg rounded-tr-lg bg-neutral-surface-gray", className)}
      {...props}
    >
      {headers.map((header) => (
        <div
          key={header.id}
          className={clsx(
            "th group relative whitespace-nowrap !bg-neutral-surface-gray px-4 py-3 text-left text-sm font-semibold text-neutral-black max-lg:p-2"
          )}
          style={{ width: header.getSize() }}
        >
          {header.isPlaceholder ? null : (
            <div
              className={clsx({
                "flex cursor-pointer select-none place-items-center gap-1 group-first:pl-2 ": header.column.getCanSort(),
              })}
              onClick={header.column.getToggleSortingHandler()}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getCanSort() &&
                ({
                  asc: <Icon name="ArrowDown2" variant="Bold" className="inline h-4 w-4 flex-shrink-0" />,
                  desc: <Icon name="ArrowUp2" variant="Bold" className="inline h-4 w-4 flex-shrink-0" />,
                }[header.column.getIsSorted() as string] ?? <Icon name="double-arrow" isCustom className="inline h-4 w-4 flex-shrink-0" />)}
            </div>
          )}
          <DataTableResizeHandler header={header} />
        </div>
      ))}
    </div>
  );
};
