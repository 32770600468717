export const addOnsIcon: { [key: string]: string } = {
  "flight-tracking": "Airplane",
  "company-manager": "Building",
  "sms-job-rating": "Sms",
  "invoicing": "Receipt2",
  "offloading": "ArrangeVertical",
  "private-network": "Global",
  "rating-manager": "Star1",
  "trip-reporting": "DocumentText",
  "enterprise-gtm": "Google",
};

export const addOnsDescription: { [key: string]: string } = {
  "flight-tracking": "Keep your plans on track with live flight updates for your clients & drivers.",
  "sms-job-rating": "Request end-of-trip ratings from your customers via SMS",
  "company-manager": "Organize clients into groups with the Company Manager, enabling customized pricing and streamlined invoicing for efficient management.",
  "invoicing": "Automate your billing process with Invoicing, designed to generate and send invoices automatically for your non-credit card customers.",
  "offloading": "Automate your dispatching with Offloading, effortlessly transferring excess bookings to trusted network partners for optimized job distribution.",
  "private-network": "Expand your operational capabilities with the Private Network, connecting you to a broader ecosystem of service providers.",
  "rating-manager": "Monitor and improve service quality with the Rating Manager, which facilitates detailed feedback and ratings for bookings.",
  "trip-reporting": "Access essential trip data and analytics to optimize your operations and enhance decision-making.",
  "enterprise-gtm": "Enable Google Tag Manager to inject custom JavaScript into the page. Useful if you wish to track via your own Google Analytics etc.",
};

export const addOnsModalDescription: { [key: string]: string } = {
  "company-manager": "Streamline your business operations with the Company Manager, which allows you to organize clients into groups, apply custom pricing strategies, and manage invoicing efficiently—all from one central location.",
  "sms-job-rating": "Trigger a SMS per trip to your clients to request a review of the service they received. Helping you gather valuable feedback and actionable items to improve the quality of your service. Clients have the ability to opt-out in their profiles if they wish.",
  "invoicing": "Enable customers to book services on invoicing, in place of a credit card. RideMinder will directly deduct applicable driver payments and fees from your linked bank account. Clients settle invoices via bank draft or credit card, based on your payment terms.",
  "offloading": "Enhance operational flexibility with Offloading, enabling you to manage and redistribute excess bookings to trusted network partners seamlessly, optimizing your resource allocation and customer service.",
  "private-network": "Expand your service capabilities and build stronger business relationships by connecting with other operators through the Private Network. This feature facilitates secure collaborations and job sharing among trusted partners.",
  "rating-manager": "Maintain high service standards with the Rating Manager, where you can track and analyze driver and vehicle performance through detailed feedback and ratings, helping you make informed decisions to improve quality.",
  "trip-reporting": "Unlock detailed trip data with our Trip Reporting feature. Gain access to granular information including trip durations, distances, and earnings, which you can use to conduct thorough analyses and tailor your operations for enhanced efficiency.",
  "enterprise-gtm": "Google Tag Manager allows you to collate all of your JavaScript in one place. We allow you to inject custom JS into every page to enable functionality such as custom tracking or user analytics.",
};

export const addOnsDisableMessage: { [key: string]: string } = {
  "flight-tracking": "If you disable flight tracking, you will no longer have access to essential real-time flight information.",
  "sms-job-rating": "Are you sure you want to disable SMS Trip Ratings? Your clients will no longer receive prompts to rate their trip via SMS.",
  "company-manager": "Are you sure you want to disable the Company Manager? You'll lose the ability to organize clients into groups and customize pricing efficiently.",
  "invoicing": "Do you really want to disable the invoicing module? Confirm to proceed with deactivation.",
  "offloading": "Are you sure you want to disable Offloading? You'll lose the capability to redistribute excess bookings seamlessly to network partners.",
  "private-network": "Are you sure you want to disable the Private Network? You'll lose access to secure collaborations and job sharing with trusted industry partners.\n\nWARNING: This will disconnect any active private network connections (if any).",
  "rating-manager": "Are you sure you want to disable the Rating Manager? You'll lose valuable insights from detailed driver and vehicle performance feedback.",
  "trip-reporting": "Are you sure you want to disable Trip Reporting? You'll lose access to comprehensive raw data essential for analyzing and enhancing your operations.",
  "enterprise-gtm": "Are you sure you want to disable Google Tag Manager support?",
};

export const addOnsLinks: { [key: string]: string } = {
  "company-manager": "companies",
  "private-network": "private-network",
  "rating-manager": "rating-manager",
  "trip-reporting": "trip-reporting",
};

// an array of addon/feature types which have a unique modal design
export const independentModals = ["flight-tracking", "invoicing", "offloading", "enterprise-gtm"];

export const addOnsDisableErrorMessage: { [key: string]: string } = {
  "invoicing": "This add-on cannot be disabled because there are open jobs with invoicing enabled. Please close these jobs before attempting to disable the add-on.",
  "company-manager": "This add-on cannot be disabled because there are active companies present. Please delete these before attempting to disable the add-on.",
};
