import { UserAccount } from "@/redux/slices/userAccount/types";
import { GetUserAccountsRawResponse, GetUserAccountsResponse } from "./types";

export const transformUserAccounts = (res: GetUserAccountsRawResponse) => {
  const {
    _embedded: { accounts },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetUserAccountsResponse = {
    data: {
      accounts: accounts.map(
        (i) =>
          ({
            id: i.uuid,
            name: i.name,
            status: i.status,
            phone: i.phone,
            email: i.email,
            role: i.role,
          } as UserAccount)
      ),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};
