import { createSearchParams } from "@/utils";
import { configVehicleApi } from "../configVehicleApi";
import { transformVehicleList, transformVehicleCategories } from "./helpers";
import {
  GetVehicleListResponse,
  GetVehicleCategoriesParams,
  GetVehicleCategoriesResponse,
  ReorderVehicleCategoriesResponse,
  AddVehicleCategoryResponse,
  AddVehicleCategoryParams,
  EditVehicleCategoryResponse,
  EditVehicleCategoryParams,
  DeleteVehicleCategoryResponse,
  ToggleVehicleCategoryResponse,
  ToggleVehicleCategoryParams,
  ReorderVehicleCategoriesParams,
} from "./types";

export const vehicleCategoriesApi = configVehicleApi
  .enhanceEndpoints({ addTagTypes: ["VehicleCategories", "VehicleList"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVehicleCategories: builder.query<GetVehicleCategoriesResponse, GetVehicleCategoriesParams>({
        query: (params) => `/type?${createSearchParams(params).toString()}`,
        providesTags: ["VehicleCategories"],
        transformResponse: transformVehicleCategories,
      }),
      addVehicleCategory: builder.mutation<AddVehicleCategoryResponse, AddVehicleCategoryParams>({
        query: (body) => ({
          url: "/type",
          method: "POST",
          body,
        }),
        invalidatesTags: ["VehicleCategories", "VehicleList"],
      }),
      editVehicleCategory: builder.mutation<EditVehicleCategoryResponse, EditVehicleCategoryParams>({
        query: ({ uuid, ...put }) => ({
          url: `/type/${uuid}`,
          method: "PUT",
          body: put,
        }),
        invalidatesTags: ["VehicleCategories", "VehicleList"],
      }),
      deleteVehicleCategory: builder.mutation<DeleteVehicleCategoryResponse, string>({
        query: (uuid) => ({
          url: `/type/${uuid}`,
          method: "DELETE",
        }),
        invalidatesTags: ["VehicleCategories", "VehicleList"],
      }),
      toggleVehicleCategory: builder.mutation<ToggleVehicleCategoryResponse, ToggleVehicleCategoryParams>({
        query: ({ uuid, ...body }) => ({
          url: `/type/${uuid}`,
          method: "PATCH",
          body,
        }),
      }),
      reorderVehicleCategories: builder.mutation<ReorderVehicleCategoriesResponse, ReorderVehicleCategoriesParams>({
        query: (body) => ({
          url: "/type/reorder",
          method: "POST",
          body,
        }),
      }),
      getVehicleList: builder.query<GetVehicleListResponse, void>({
        query: () => "/type/list",
        providesTags: ["VehicleList"],
        transformResponse: transformVehicleList,
      }),
    }),
  });

export const {
  useGetVehicleCategoriesQuery,
  useAddVehicleCategoryMutation,
  useToggleVehicleCategoryMutation,
  useDeleteVehicleCategoryMutation,
  useEditVehicleCategoryMutation,
  useReorderVehicleCategoriesMutation,
  useGetVehicleListQuery,
} = vehicleCategoriesApi;
