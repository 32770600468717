import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { transformReport } from "./helpers";
import { CreateReportParams, CreateReportResponse, GetReportResponse } from "./types";

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: baseQueryWithoutProvider("files/report"),
  endpoints: (builder) => ({
    createReport: builder.mutation<CreateReportResponse, CreateReportParams>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
    getReport: builder.query<GetReportResponse, string>({
      query: (id) => `/${id}`,
      transformResponse: transformReport,
    }),
  }),
});

export const { useCreateReportMutation, useLazyGetReportQuery } = reportApi;