import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx, getCurrencySymbol } from "@/utils";
import { SignUpOperatorFormData } from "../types";
import { useOperator } from "@/hooks";

export const TotalAnnualTurnoverField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { currency } = useOperator();
  const {
    register,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();

  return (
    <div className={clsx("flex flex-row items-center gap-x-2", className)} {...props}>
      <div className="flex flex-col w-full">
        <Typography variant="paragraph">Total Annual Turnover</Typography>
        <Typography variant="small" className="text-neutral-dark-gray">Last Financial Year. Enter amount in AUD ($).</Typography>
        <ErrorMessage errors={errors} name="totalAnnualTurnover" />
      </div>
      <TextInput
        className="flex-1 w-[72px]"
        type="number"
        placeholder={`${getCurrencySymbol(currency)}0`}
        step="any"
        hasError={!!errors.totalAnnualTurnover}
        {...register("totalAnnualTurnover")}
      />
    </div>
  );
};
