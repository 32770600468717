import { isEmpty } from "ramda";
import { Loading, Panel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useGetDebitHistoryQuery, useGetDebitUpcomingSummaryQuery } from "@/redux/apis/payment/record/recordApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setDebitHistorySidePanelAction } from "@/redux/slices/payment/paymentSlice";
import { debitHistoryPageStateSelector, debitHistorySelector, debitUpcomingSelector } from "@/redux/slices/payment/selectors";
import { formatCurrency } from "@/utils";
import { CurrentPendingDebitAmountPanel } from "./CurrentPendingDebitAmountPanel";
import { DebitHistoryTable } from "./DebitHistoryTable";
import { useGetDebitHistoryParams } from "./hooks/useGetDebitHistoryParams";

export const DebitHistoryPanel = () => {
  const dispatch = useAppDispatch();
  const { params } = useGetDebitHistoryParams();
  const { isFetching: isFetchingDebitHistory } = useGetDebitHistoryQuery(params, { refetchOnMountOrArgChange: true });
  const { isFetching: isFetchingDebitUpcoming } = useGetDebitUpcomingSummaryQuery();
  const { items } = useAppSelector(debitHistorySelector);
  const { summary } = useAppSelector(debitUpcomingSelector);
  const { sidePanelAction } = useAppSelector(debitHistoryPageStateSelector);
  const isDebitHistoryEmpty = isEmpty(items) && !isFetchingDebitHistory;

  const renderCurrentPendingDebitAmount = () => (
    <Typography variant="paragraph" className="text-info">
      {summary.currency} {formatCurrency(summary.amount, 2, summary.currency)}
    </Typography>
  );

  return (
    <>
      <Panel>
        <header className="flex">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Debit History
            </Typography>
            <Typography className="text-neutral-dark-gray">Here is a list of all debits to your nominated bank account</Typography>
          </div>
          <div
            className="relative flex min-w-[223px] cursor-pointer flex-col rounded-lg bg-neutral-surface-gray p-2.5"
            onClick={() => dispatch(setDebitHistorySidePanelAction("currentPendingDebitAmount"))}
          >
            {isFetchingDebitUpcoming && <Loading className="rounded-lg" />}
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Current Pending Debit Amount
            </Typography>
            {renderCurrentPendingDebitAmount()}
          </div>
        </header>
        {isDebitHistoryEmpty ? (
          <EmptyState title="Debit History" description="There are currently no debit history available" />
        ) : isFetchingDebitHistory ? (
          <Skeleton />
        ) : (
          <DebitHistoryTable items={items} className="mt-4" />
        )}
      </Panel>
      {!isFetchingDebitHistory && <DebitHistoryTable.Pagination />}
      <CurrentPendingDebitAmountPanel
        open={sidePanelAction === "currentPendingDebitAmount"}
        onClose={() => dispatch(setDebitHistorySidePanelAction(undefined))}
      />
    </>
  );
};
