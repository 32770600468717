import cx from "classnames";
import { useNavigate } from "react-router-dom";
import { Divider, Typography } from "@/components/atoms";
import { Notification } from "@/redux/slices/notification/types";
import { renderCreatedDate, renderNotificationIcon } from "./helpers";

export interface NotificationItemProps {
  notification: Notification;
  isNew?: boolean;
  onClose: () => void;
}

export const NotificationItem = ({ notification, isNew, onClose }: NotificationItemProps) => {
  const navigate = useNavigate();

  const handleNotificationClick = (category: string, action: string) => {
    switch (category) {
      case "provider":
        switch (action) {
          case "provider.accreditation":
            onClose();
            navigate("/accreditations");
            break;
          case "provider.private-network":
            onClose();
            navigate("/private-network");
            break;
          case "provider.reporting.trip":
            onClose();
            navigate("/trip-reporting");
            break;
          case "provider.rating":
            onClose();
            navigate("/trip-reporting");
            break;
          case "provider.settings":
            onClose();
            navigate("/config");
            break;
          default:
        }
        break;
      default:
    }
  };

  return (
    <div
      className={cx("cursor-pointer px-5", {
        "bg-neutral-surface-gray": isNew,
      })}
      onClick={() => handleNotificationClick(notification.category, notification.action)}
    >
      <div className="flex flex-row gap-4 pb-4 pt-6">
        <div className="flex">{renderNotificationIcon(notification.category, notification.action)}</div>
        <div className="flex w-full flex-col">
          <div className="flex flex-row items-center gap-1.5">
            <Typography variant="action">{notification.subject}</Typography>
            {isNew && (
              <svg className="h-3 w-3 text-danger" fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} />
              </svg>
            )}
          </div>
          <Typography variant="paragraph" className="mt-3 text-neutral-dark-gray">
            {notification.message}
          </Typography>
          {renderCreatedDate(notification.created)}
        </div>
      </div>
      <Divider />
    </div>
  );
};
