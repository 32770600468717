import { useEffect, useState } from "react";
import { Container, Loading } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, PaymentMethodSetupPanel } from "@/components/organisms";
import { useLayout } from "@/hooks";
import { getStripePublishableKey } from "@/helpers/apiHelpers";
import { loadStripe } from "@stripe/stripe-js";
import { useGetPaymentProviderKeysQuery } from "@/redux/apis/paymentMethod/paymentMethodApi";
import { Elements } from "@stripe/react-stripe-js";

export const ProfilePaymentMethodSetup = () => {
  const { setFullScreen } = useLayout();
  const stripePublishableKey = getStripePublishableKey();
  const stripePromise = loadStripe(stripePublishableKey);
  const { isFetching, data } = useGetPaymentProviderKeysQuery();
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);

  useEffect(() => {
    setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.intent_secret) setClientSecret(data.intent_secret);
  }, [data]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader action="skip" />
        <Container className="relative flex flex-1 justify-center w-full max-w-[1040px] px-5 pt-[100px] pb-[234px]">
          {isFetching && <Loading className="!bg-transparent" />}
          {stripePromise && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentMethodSetupPanel />
            </Elements>
          )}
        </Container>
        <GeneralFooter />
      </div>
    </div>
  );
};
