import { saveAs } from "file-saver";
import { Button, DropdownMenu, Icon, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyExportClientsQuery } from "@/redux/apis/reporting/reportingApi";
import { addToast } from "@/utils";

export const ExportClients = () => {
  const [exportClients, { isFetching: isFetchingExport }] = useLazyExportClientsQuery();

  const handleExportClients = (type: string) => {
    exportClients({ report_type: type })
      .unwrap()
      .then((res) => saveAs(res.data.path, "clients"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <DropdownMenu
      button={
        isFetchingExport ? (
          <DropdownMenu.Button as={Button} variant="secondary" className="inline-flex w-full" disabled>
            <div className="flex items-center justify-center">
              <Spinner variant="light" />
              Exporting...
            </div>
          </DropdownMenu.Button>
        ) : (
          <DropdownMenu.Button as={Button} variant="secondary" endIcon="arrow-down" isCustomIcon className="inline-flex w-full">
            Export
          </DropdownMenu.Button>
        )
      }
      isCustomButton
      position="bottom-start"
      disabled={isFetchingExport}
    >
      <DropdownMenu.Item onClick={() => handleExportClients("active")}>
        <Icon name="ExportSquare" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">Active Client Export</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => handleExportClients("full")}>
        <Icon name="ExportSquare" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">Full Export</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};
