import { Divider, Icon, Modal, Stars, Typography } from "@/components/atoms";
import { RatingItem } from "@/redux/slices/rating/types";
import { RatingLink } from "../RatingLink";

interface ViewRatingModalProps {
  open: boolean;
  onClose: () => void;
  item?: RatingItem;
}

export const ViewRatingModal = ({ open, onClose, item }: ViewRatingModalProps) => {
  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Driver Rating
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );

  const renderInformation = () => (
    item ? (
      <div className="rounded-lg bg-neutral-surface-gray p-5">
        <div className="flex flex-col justify-between sm:flex-row gap-2 sm:gap-10">
          <div className="flex flex-col">
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Job ID
            </Typography>
            <RatingLink name={item.job.id} path={item.job.path} hasIcon />
          </div>
          <div className="flex flex-col">
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Customer
            </Typography>
            <Typography variant="paragraph">{item.passenger.name}</Typography>
          </div>
          <div className="flex flex-col">
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Driver
            </Typography>
            <RatingLink name={item.driver.name} path={item.driver.path} hasIcon />
          </div>
        </div>
      </div>
    ) : <div className="h-[90px] rounded-lg bg-neutral-surface-gray p-5" />
  );

  const renderAverageRating = () => (
    <div className="flex flex-row items-center rounded-lg bg-neutral-surface-gray p-5 mt-3.5 gap-2">
      <Typography variant="paragraph" className="flex-1">Trip Average Rating</Typography>
      {item && (
        <div className="flex flex-row items-center gap-x-1.5">
          <Typography variant="title">{item.rating}</Typography>
          <Stars value={1} count={1} edit={false} size={24} color1="transparent" />
        </div>
      )}
    </div>
  );

  const renderNotes = () => (
    item?.comments && (
      <>
        <Divider className="my-6" />
        <div className="flex flex-col gap-1.5">
          <Typography variant="paragraph" className="text-neutral-dark-gray">Notes from client</Typography>
          <Typography variant="paragraph">{item.comments}</Typography>
        </div>
      </>
    )
  );

  const renderRating = (rating?: number | null) => <Stars value={rating ? rating : 0} count={5} edit={false} size={24} color1="#D1D2D5" />;

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="mt-3">
        {renderInformation()}
        {renderAverageRating()}
        <div className="flex flex-col gap-3 mt-4">
          <Typography variant="action">Rating Breakdown</Typography>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <Typography variant="paragraph" className="flex-1">Vehicle</Typography>
            {renderRating(item?.ratings.vehicle)}
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <Typography variant="paragraph" className="flex-1">Driver</Typography>
            {renderRating(item?.ratings.driver)}
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <Typography variant="paragraph" className="flex-1">Professionalism</Typography>
            {renderRating(item?.ratings.professionalism)}
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <Typography variant="paragraph" className="flex-1">Overall Service</Typography>
            {renderRating(item?.ratings.overallService)}
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <Typography variant="paragraph" className="flex-1">Booking Experience</Typography>
            {renderRating(item?.ratings.bookingExperience)}
          </div>
        </div>
        {renderNotes()}
      </div>
    </Modal>
  );
};
