import { Panel, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { ClientsAddForm } from "./ClientsAddForm";

export const ClientsAddPanel = () => {
  const operator = useOperator();

  return (
    <Panel>
      <div className="block">
        <Typography variant="h3" className="leading-8">
          Sign up a New Client
        </Typography>
        <Typography className="text-neutral-dark-gray">Add a new {operator.name} client</Typography>
      </div>
      <ClientsAddForm />
    </Panel>
  );
};
