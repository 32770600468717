import { Loading, PDFViewer } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { payByCreditCardSelector } from "@/redux/slices/payInvoice/selectors";
import { GeneralHeader } from "../General";
import { PayByCreditCardStep1 } from "./contents/PayByCreditCardStep1";
import { PayByCreditCardStep2 } from "./contents/PayByCreditCardStep2";
import { PayByCreditCardStep3 } from "./contents/PayByCreditCardStep3";
import { usePayInvoicePageContext } from "./context";

export const PayInvoicePanel = () => {
  const { payInvoice } = usePayInvoicePageContext();
  const { paymentStep } = useAppSelector(payByCreditCardSelector);

  const renderSteps = () => {
    if (payInvoice) {
      switch (paymentStep) {
        case 2:
          return <PayByCreditCardStep2 />;
        case 3:
          return <PayByCreditCardStep3 />;
        default:
          return <PayByCreditCardStep1 />;
      }
    }
  };

  if (!payInvoice) return <Loading />;

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        <div className="grid w-full grid-cols-3 gap-4">
          <div className="col-span-3 hidden lg:col-span-2 lg:block">
            <PDFViewer src={payInvoice.documents.pdf} className="h-full min-h-[70vh] w-full" />
          </div>
          <div className="col-span-3 h-full min-h-[70vh] lg:col-span-1">{renderSteps()}</div>
        </div>
      </div>
    </div>
  );
};
