import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { PageHeader, ProfileDetailsEdit, ProfileHeaderEdit } from "@/components/organisms";
import { profileSchema } from "@/components/organisms/Profile/fixtures";
import { ProfileFormData } from "@/components/organisms/Profile/types";
import { useAppSelector } from "@/redux/hooks";
import { profileSelector } from "@/redux/slices/profile/selectors";

export const ProfileEdit = () => {
  const profile = useAppSelector(profileSelector);
  const methods = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      ...profile,
      timezone: profile.timezone.name,
    },
  });

  return (
    <>
      <PageHeader skipCrumbs={1} title="Profile" />

      <FormProvider {...methods}>
        <div className="relative mb-4 mt-2 min-h-[800px] w-full overflow-hidden rounded-lg shadow-lg">
          <ProfileHeaderEdit />
          <ProfileDetailsEdit />
        </div>
      </FormProvider>
    </>
  );
};
