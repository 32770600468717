import { isEmpty } from "ramda";
import { Icon, IconButton, SidePanel, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useAppSelector } from "@/redux/hooks";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { InvoiceHistoryTable, PaymentHistoryTable } from "./tables";

interface InvoiceLogsPanelProps {
  open: boolean;
  onClose: () => void;
}

export const InvoiceLogsPanel = ({ open, onClose }: InvoiceLogsPanelProps) => {
  const { modalAction, selectedInvoice: invoice } = useAppSelector(invoicingPageStateSelector);

  return (
    <SidePanel open={open} onClose={() => (!modalAction ? onClose() : null)} width={800} focused>
      <div className="flex items-center justify-between px-5 py-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="ReceiptText" size="lg" />
          <Typography variant="title">Invoice #{invoice?.identifier}</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <>
        {invoice && (
          <div className="flex flex-col gap-y-6 px-5 py-6">
            <div className="flex flex-col gap-y-3">
              <Typography variant="title">Payment History</Typography>
              <PaymentHistoryTable invoice={invoice} />
            </div>
            <div className="flex flex-col gap-y-3">
              <Typography variant="title">Invoice History</Typography>
              {isEmpty(invoice) ? (
                <EmptyState title="Your Invoice History" description="There are currently no records available for your invoice history" />
              ) : (
                <InvoiceHistoryTable invoice={invoice} />
              )}
            </div>
          </div>
        )}
      </>
    </SidePanel>
  );
};
