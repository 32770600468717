import {
  DownloadTripReportRawResponse,
  DownloadTripReportResponse,
  ExportClientsRawResponse,
  ExportClientsResponse,
  ExportDriversRawResponse,
  ExportDriversResponse,
} from "./types";

export const transformTripReport = (res: DownloadTripReportRawResponse) => {
  const { type, path, _links } = res;

  const response: DownloadTripReportResponse = {
    data: { type, path },
    links: _links,
  };

  return response;
};

export const transformExportClients = (res: ExportClientsRawResponse) => {
  const { type, path, _links } = res;

  const response: ExportClientsResponse = {
    data: { type, path },
    links: _links,
  };

  return response;
};

export const transformExportDrivers = (res: ExportDriversRawResponse) => {
  const { type, path, _links } = res;

  const response: ExportDriversResponse = {
    data: { type, path },
    links: _links,
  };

  return response;
};

export const transformExportExternalSuppliers = (res: ExportDriversRawResponse) => {
  const { type, path, _links } = res;

  const response: ExportDriversResponse = {
    data: { type, path },
    links: _links,
  };

  return response;
};
