import { Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAction } from "@/redux/slices/accreditation/accreditationSlice";
import { accreditationsPageStateSelector } from "@/redux/slices/accreditation/selectors";
import { StateRemove } from "./contents/StateRemove";
import { AddAccreditationForm } from "./forms/AddAccreditationForm";
import { EditAccreditationForm } from "./forms/EditAccreditationForm";

export const AccreditationsModals = () => {
  const dispatch = useAppDispatch();
  const { action, selectedAccreditation } = useAppSelector(accreditationsPageStateSelector);

  const onClose = () => dispatch(setAction(undefined));

  return (
    <>
      <Modal open={action === "add"} onClose={onClose}>
        <AddAccreditationForm onClose={onClose} />
      </Modal>
      <Modal open={action === "update"} onClose={onClose}>
        <EditAccreditationForm item={selectedAccreditation} onClose={onClose} />
      </Modal>
      {selectedAccreditation && (
        <Modal open={action === "remove"} onClose={onClose}>
          <StateRemove item={selectedAccreditation} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};
