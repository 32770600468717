import DatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createPortal } from "react-dom";
import { clsx } from "@/utils";
import { Icon, Typography } from "..";
import "./style.css";

export interface DateInputProps extends ReactDatePickerProps {
  hasError?: boolean;
}
export const DateInput = (dateInputProps: DateInputProps) => {
  const {
    dateFormat = "dd MMM yyyy",
    placeholderText = "DD MM YYYY",
    hasError = false,
    className,
    showMonthYearPicker,
    ...props
  } = dateInputProps;

  const inputClassName = clsx(
    "block w-full rounded-lg border-neutral-mid-gray pl-10 pr-4 text-neutral-black placeholder-neutral-dark-gray focus:border-primary focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-surface-gray",
    className,
    {
      "!border-danger": hasError,
    }
  );

  return (
    <div className="relative">
      <div className={clsx("absolute inset-y-0 left-0  flex items-center pl-3 ")}>
        <Icon className="z-10 text-neutral-dark-gray" name="Calendar" size="sm" aria-hidden="true" />
      </div>
      <DatePicker
        showPopperArrow={false}
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        className={inputClassName}
        renderCustomHeader={getHeader(showMonthYearPicker)}
        showMonthYearPicker={showMonthYearPicker}
        popperContainer={({ children }) => createPortal(children, document.body)}
        {...props}
      />
    </div>
  );
};

const getHeader = (monthYear = false) => {
  // eslint-disable-next-line react/display-name
  return ({
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    decreaseMonth,
    decreaseYear,
    monthDate,
    increaseMonth,
    increaseYear,
  }: ReactDatePickerCustomHeaderProps) => {
    return (
      <div className="flex items-center justify-between pb-2">
        <button
          type="button"
          className="flex-shrink-0 rounded bg-neutral-surface-gray p-2.5 text-center "
          onClick={monthYear ? decreaseYear : decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <Icon name="ArrowLeft2" size="lg" aria-hidden="true" className="mr-0 block" />
        </button>
        <Typography variant="action">
          {monthDate.toLocaleDateString("en-US", monthYear ? { year: "numeric" } : { month: "long", year: "numeric" })}
        </Typography>
        <button
          type="button"
          className="flex-shrink-0 rounded bg-neutral-surface-gray p-2.5 text-center"
          onClick={monthYear ? increaseYear : increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <Icon name="ArrowRight2" size="lg" aria-hidden="true" className="mr-0 block" />
        </button>
      </div>
    );
  };
};

DateInput.displayName = "DateInput";
