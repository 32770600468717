import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { transformQrTemplates } from "./helpers";
import { GetQrTemplatesResponse, RequestPrintQrParams } from "./types";

export const qrApi = createApi({
  reducerPath: "qrApi",
  baseQuery: baseQueryWithProvider(),
  endpoints: (builder) => ({
    getQrTemplates: builder.query<GetQrTemplatesResponse, void>({
      query: () => `assets-printed-qr`,
      transformResponse: transformQrTemplates,
      providesTags: ["QRTemplates"],
    }),
    requestPrintQr: builder.mutation<void, RequestPrintQrParams>({
      query: (body) => ({
        url: `assets-printed-qr`,
        method: "POST",
        body,
      }),
    }),
  }),
  tagTypes: ["QRTemplates"],
});

export const { useGetQrTemplatesQuery, useRequestPrintQrMutation } = qrApi;
