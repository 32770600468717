import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton, ErrorPanel } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetVehicleTransferTypesQuery } from "@/redux/apis/config/pricing/transferTypes";
import { TransferTypesTableEditorV2 } from "./TransferTypesTableEditorV2";
import { useGetTransferTypesParams } from "./hooks/useGetTransferTypesParams";

export const TransferTypesPanel = () => {
  const navigate = useNavigate();
  const { params } = useGetTransferTypesParams();
  const { data: response, isFetching, error } = useGetVehicleTransferTypesQuery(params, { refetchOnMountOrArgChange: true });

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  return (
    <>
      <Panel className="mt-4">
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Transfer Types
            </Typography>
            <Typography className="text-neutral-dark-gray">Set up and customize transfer types for various transportation needs</Typography>
          </div>
          <div className="flex items-center">
            <Button onClick={() => navigate("add")} variant="tertiary" startIcon="Add">
              Add Transfer Type
            </Button>
          </div>
        </div>
        {response && response.transferTypes.length > 0 ? (
          <>
            <TransferTypesTableEditorV2 className="mt-4" items={response.transferTypes} />
            <TransferTypesTableEditorV2.Pagination page={response.page} />
          </>
        ) : (
          <EmptyState
            title="Transfer Types"
            description="It looks like you haven't added any transfer type yet. Begin by adding your first transfer type to get started"
          >
            <Button onClick={() => navigate("add")} startIcon="Add" variant="tertiary">
              Add Transfer Type
            </Button>
          </EmptyState>
        )}
      </Panel>
    </>
  );
};
