import { Outlet } from "react-router-dom";
import { useOperator, useProfile } from "@/hooks";

export const RequireOperatorAccount = () => {
  const operator = useOperator();
  const profile = useProfile();

  if (!operator) throw new Error("Operator not set");
  if (!profile) throw new Error("Account not set");

  return <Outlet />;
};
