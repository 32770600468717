import { createSlice } from "@reduxjs/toolkit";
import { externalSupplierApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { ExternalSupplierState } from "./types";

const initialState: ExternalSupplierState = {
  suppliers: [],
  options: {
    types: [],
    states: [],
    statuses: [],
    ratings: [],
  },
  page: pageState,
};

export const externalSupplierSlice = createSlice({
  name: "externalSupplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(externalSupplierApi.endpoints.getExternalSuppliers.matchFulfilled, (state, { payload }) => {
      const { suppliers, page } = payload.data;
      state.suppliers = suppliers;
      state.page = page;
    });
    builder.addMatcher(externalSupplierApi.endpoints.getExternalSuppliersFormOptions.matchFulfilled, (state, { payload }) => {
      state.options = payload;
    });
  },
});

export default externalSupplierSlice.reducer;
