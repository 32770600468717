import { Controller, useForm } from "react-hook-form";
import {
  AddOnTextInput,
  Alert,
  Button,
  Icon,
  IconDropdown,
  Loading,
  RadioInput,
  TextInput,
  Toggle,
  Tooltip,
  Typography,
} from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddCostExtraMutation } from "@/redux/apis/config/pricing/costExtras";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { iconsOptions } from "./fixtures";
import { useOperator } from "@/hooks";
import { getCurrencySymbol } from "@/utils";

interface AddExtrasFormProps {
  onClose: () => void;
  onSuccess: () => void;
  onError: (messages: string[]) => void;
}

export const AddExtrasForm = ({ onClose, onSuccess, onError, ...props }: AddExtrasFormProps) => {
  const { currency } = useOperator();
  const [addExtra, { isLoading }] = useAddCostExtraMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors: formErrors },
  } = useForm<CostExtrasItem>({
    defaultValues: {
      type: "extra",
      active: true,
    },
  });

  const onSubmit = handleSubmit((data) => {
    addExtra({
      name: data.name,
      description: data.description,
      cost: data.price * 100,
      icon: data.icon,
      is_active: data.active,
      type: data.type,
    })
      .unwrap()
      .then(onSuccess)
      .catch((e) => onError(getErrorMessages(e)));
  });

  return (
    <form id="add-extras-form" className="relative min-h-[515px] w-[515px] max-w-full space-y-5" onSubmit={onSubmit} {...props}>
      {isLoading && <Loading />}
      <div className="mt-2">
        <div className="flex flex-row items-center gap-2">
          <Typography variant="action">Select a Category icon for the Fixed Cost Extra</Typography>
          <Tooltip content="This icon will be shown on the mobile app as a visual guide" placement="bottom" maxWidth={250}>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </div>
        <Controller
          control={control}
          name="icon"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <>
              <IconDropdown
                options={iconsOptions}
                value={value}
                hasError={!!formErrors.icon}
                onChange={onChange}
                placeholder="Select a Category"
              />
              {formErrors.icon && <Alert className="mt-2 !py-2" type="danger" message="Please select an icon" />}
            </>
          )}
        />
      </div>
      <div>
        <Typography variant="action">Name</Typography>
        <TextInput {...register("name", { required: true })} hasError={!!formErrors.name} placeholder="Enter Name" maxLength={128} />
        {formErrors.name && <Alert className="mt-2 !py-2" type="danger" message="Please enter a name" />}
      </div>
      <div>
        <Typography variant="action">Description</Typography>
        <TextInput
          {...register("description", { required: true })}
          hasError={!!formErrors.description}
          placeholder="Enter Description for the Cost Extras"
          maxLength={128}
        />
        {formErrors.description && <Alert className="mt-2 !py-2" type="danger" message="Please enter a description" />}
      </div>
      <div>
        <Typography variant="action">Rate</Typography>
        <AddOnTextInput
          {...register("price", { required: true, valueAsNumber: true })}
          hasError={!!formErrors.price}
          startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
          type="number"
          placeholder="0"
          step="0.01"
          min="-50000"
          max="50000"
          className="sm:max-w-[200px]"
        />
        {formErrors.price && <Alert className="mt-2 !py-2" type="danger" message="Please enter a price" />}
      </div>
      <div>
        <div className="flex flex-row items-center gap-2">
          <Typography variant="action">Type</Typography>
          <Tooltip
            content={
              <Typography variant="action" className="mb-2">
                Extra: An option selected by the passenger from a <br />
                drop down menu in the Booking Sheet (e.g. Baby Seat) <br />
                Fee: An administrative extra added by your <br /> internal staff (e.g. Booking Fee)
              </Typography>
            }
            placement="right"
          >
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </div>
        <div className="mt-2 flex gap-6">
          <RadioInput {...register("type", { required: true })} label="Extra" value="extra" />
          <RadioInput {...register("type", { required: true })} label="Fee" value="fee" />
        </div>
      </div>
      <div>
        <Typography variant="action" className="block">
          Status
        </Typography>
        <div>
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <div className="mt-1 flex items-center gap-4">
                <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
                <Typography className="inline">{field.value ? "Active" : "Inactive"}</Typography>
              </div>
            )}
          />
        </div>
      </div>
      <div className="mt-6 flex justify-end gap-4">
        <Button variant="secondary" onClick={onClose} className="px-8">
          Cancel
        </Button>
        <Button variant="primary" disabled={!isDirty} type="submit" form="add-extras-form" className="px-8">
          Add Fixed Cost Extra
        </Button>
      </div>
    </form>
  );
};
