import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";

interface CardPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  number: string;
  expiryMonth: number;
  expiryYear: number;
  issuer: string;
}

export const CardPreview = (cardPreviewProps: CardPreviewProps) => {
  const { number, expiryMonth, expiryYear, issuer, className, ...props } = cardPreviewProps;

  return (
    <div className={className} {...props}>
      <Cards
        number={number}
        expiry={`${expiryMonth.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}${expiryYear}`}
        issuer={issuer}
        cvc=""
        name=""
        focused="number"
        placeholders={{ name: "" }}
        preview
      />
    </div>
  );
};
