import { Icon, IconButton, SidePanel, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { AddInvoiceNoteForm } from "./AddInvoiceNoteForm";
import { InvoiceNotes } from "./contents";

interface InvoiceNotesPanelProps {
  open: boolean;
  onClose: () => void;
}

export const InvoiceNotesPanel = ({ open, onClose }: InvoiceNotesPanelProps) => {
  const { modalAction, selectedInvoice: invoice } = useAppSelector(invoicingPageStateSelector);

  return (
    <SidePanel open={open} onClose={() => (!modalAction ? onClose() : null)} width={428} focused>
      <div className="flex items-center justify-between px-5 py-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="DocumentText" size="lg" />
          <Typography variant="title">Notes for {invoice?.identifier}</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <>
        {invoice && (
          <div className="flex flex-col p-5">
            <AddInvoiceNoteForm invoice={invoice} />
            <InvoiceNotes invoice={invoice} className="mt-4" />
          </div>
        )}
      </>
    </SidePanel>
  );
};
