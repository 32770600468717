import { ArchivedVehiclesPanel } from "@/components/organisms";
import { ViewVehicle } from "./subpages/ViewVehicle";

export const ArchivedVehicles = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      <ArchivedVehiclesPanel />
    </div>
  );
};

ArchivedVehicles.ViewVehicle = ViewVehicle;
