import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { Button, DateInput, ErrorMessage, Icon, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessagesObject } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { companyApi } from "@/redux/apis/company/companyApi";
import { useMarkAsPaidInvoiceMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { addNotification, clsx, getCurrencySymbol } from "@/utils";
import { markAsPaidFormSchema } from "../fixtures";
import { MarkAsPaidFormData } from "../types";

interface MarkAsPaidProps {
  onClose: () => void;
}

export const MarkAsPaid = ({ onClose }: MarkAsPaidProps) => {
  const dispatch = useAppDispatch();
  const { dateFormat } = useProfile();
  const { selectedInvoice: invoice } = useAppSelector(invoicingPageStateSelector);
  const [markAsPaidInvoice, { isLoading }] = useMarkAsPaidInvoiceMutation();
  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
    setError,
  } = useForm<MarkAsPaidFormData>({
    resolver: zodResolver(markAsPaidFormSchema),
    defaultValues: {
      amountPaid: invoice ? invoice.amountDue / 100 : 0,
      dateReceived: new Date(),
    },
  });
  const watchAmountPaid = watch("amountPaid");
  const currency = invoice?.currency;
  const currencySymbol = currency ? getCurrencySymbol(currency) : "";
  const onSubmit = handleSubmit((data) => {
    if (!invoice) return;
    markAsPaidInvoice({
      id: invoice.id,
      amount: data.amountPaid * 100,
      received_date: format(data.dateReceived, "yyyy-MM-dd"),
    })
      .unwrap()
      .then(() => {
        addNotification("info", `Invoice ${invoice.identifier}`, "Invoice has been applied");
        onClose();
        dispatch(companyApi.util.invalidateTags(["InvoicesCompanies"]));
      })
      .catch((e) => {
        const messages = getErrorMessagesObject(e);
        const amountPaidErrorMessage = messages?.amount;
        const dateReceivedErrorMessage = messages?.received_date;
        if (amountPaidErrorMessage) setError("amountPaid", { type: "custom", message: amountPaidErrorMessage });
        if (dateReceivedErrorMessage) setError("dateReceived", { type: "custom", message: dateReceivedErrorMessage });
      });
  });
  const renderModalHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Mark as Paid
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );
  const renderButton = () => (
    <div className="mt-8 flex">
      <Button type="submit" size="lg" disabled={isLoading} className="relative w-full">
        {isLoading && <Loading />}
        Mark as Paid
      </Button>
    </div>
  );
  return (
    <>
      {renderModalHeader()}
      <div>
        <form className="mt-6 flex flex-col" onSubmit={onSubmit}>
          <div className="flex flex-col items-center justify-center">
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Enter Amount Paid
            </Typography>
            <div className="mt-4 flex flex-row items-center">
              <Typography variant="h1" className={clsx({ "text-danger": !!errors.amountPaid })}>
                {currencySymbol}
              </Typography>
              <TextInput
                type="number"
                className={clsx("border-none p-0 text-3xl font-bold focus:border-none focus:shadow-none", {
                  "text-danger": !!errors.amountPaid,
                })}
                style={{
                  width: watchAmountPaid ? Math.min(Math.max(watchAmountPaid.toString().length, 2), 10) + "ch" : "10px",
                  fontFamily: "Inter",
                }}
                {...register("amountPaid")}
                autoFocus
                step="any"
              />
              <Typography variant="title" className="ml-2 text-neutral-dark-gray">
                {invoice?.currency}
              </Typography>
            </div>
            <ErrorMessage errors={errors} name="amountPaid" />
          </div>
          <div className="mt-4 flex flex-col">
            <Typography variant="paragraph">Date Received</Typography>
            <Controller
              name="dateReceived"
              control={control}
              render={({ field }) => (
                <DateInput
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  placeholderText="Select Date"
                  hasError={!!errors.dateReceived}
                  dateFormat={dateFormat}
                />
              )}
            />
            <ErrorMessage errors={errors} name="dateReceived" />
          </div>
          {renderButton()}
        </form>
      </div>
    </>
  );
};
