import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  format,
  isThisMonth,
  isThisWeek,
  isThisYear,
  isToday,
  isTomorrow,
  isYesterday,
  lastDayOfMonth,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns";
import { forwardRef, useMemo } from "react";
import { clsx } from "@/utils";
import { IconButton } from "../Button/IconButton";
import { Icon } from "../Icon/Icon";
import { useDateRangePicker } from "./context";

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  hasError?: boolean;
  size?: "sm" | "md" | "lg";
  dateFormat?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ hasError = false, size = "lg", className, dateFormat = "dd/MM/yyyy", ...props }, ref) => {
    const { dates, preset, onDatesChange, open } = useDateRangePicker();
    const displayValue = useMemo(() => {
      if (!dates) return "";

      switch (preset) {
        case "today":
        case "tomorrow": {
          if (isToday(dates[0]) && isToday(dates[1])) return "Today";
          else if (isYesterday(dates[0]) && isYesterday(dates[1])) return "Yesterday";
          else if (isTomorrow(dates[0]) && isTomorrow(dates[1])) return "Tomorrow";
          else return format(dates[0], "EEE, MMM d");
        }
        case "thisWeek": {
          if (isThisWeek(dates[0]) && isThisWeek(dates[1])) return "This Week";
          else if (isThisWeek(addWeeks(dates[0], 1)) && isThisWeek(addWeeks(dates[1], 1))) return "Last Week";
          else return `${format(dates[0], "MMM d")} - ${format(dates[1], "MMM d")}`;
        }
        case "thisMonth": {
          if (isThisMonth(dates[0]) && isThisMonth(dates[1])) return "This Month";
          else if (isThisMonth(addMonths(dates[0], 1)) && isThisMonth(addMonths(dates[1], 1))) return "Last Month";
          else return `${format(dates[0], "MMM d")} - ${format(dates[1], "MMM d")}`;
        }
        case "thisYear": {
          if (isThisYear(dates[0]) && isThisYear(dates[1])) return "This Year";
          else if (isThisYear(addYears(dates[0], 1)) && isThisYear(addYears(dates[1], 1))) return "Last Year";
          else return format(dates[0], "yyyy");
        }
        default:
          return `${dates[0] ? format(dates[0], dateFormat) : ""}${dates[1] ? ` - ${format(dates[1], dateFormat)}` : ""}`;
      }
    }, [preset, dates, dateFormat]);

    const handlePrevClick = () => {
      if (!dates) return;

      switch (preset) {
        case "thisWeek": {
          const startOfWeek = subWeeks(dates[0], 1);
          const endOfWeek = subWeeks(dates[1], 1);
          onDatesChange([startOfWeek, endOfWeek]);
          return;
        }
        case "thisMonth": {
          const startOfMonth = subMonths(dates[0], 1);
          const endOfMonth = lastDayOfMonth(subMonths(dates[1], 1));
          onDatesChange([startOfMonth, endOfMonth]);
          return;
        }
        case "thisYear": {
          const startOfYear = subYears(dates[0], 1);
          const endOfYear = subYears(dates[1], 1);
          onDatesChange([startOfYear, endOfYear]);
          return;
        }
        default: {
          const start = subDays(dates[0], 1);
          const end = subDays(dates[1], 1);
          onDatesChange([start, end]);
        }
      }
    };

    const handleNextClick = () => {
      if (!dates) return;

      switch (preset) {
        case "thisWeek": {
          const startOfWeek = addWeeks(dates[0], 1);
          const endOfWeek = addWeeks(dates[1], 1);
          onDatesChange([startOfWeek, endOfWeek]);
          return;
        }
        case "thisMonth": {
          const startOfMonth = addMonths(dates[0], 1);
          const endOfMonth = lastDayOfMonth(addMonths(dates[1], 1));
          onDatesChange([startOfMonth, endOfMonth]);
          return;
        }
        case "thisYear": {
          const startOfYear = addYears(dates[0], 1);
          const endOfYear = addYears(dates[1], 1);
          onDatesChange([startOfYear, endOfYear]);
          return;
        }
        default: {
          const start = addDays(dates[0], 1);
          const end = addDays(dates[1], 1);
          onDatesChange([start, end]);
        }
      }
    };

    const borderClass = {
      "ring-neutral-mid-gray": !open,
      "ring-primary": open,
    };

    const errorClass = {
      "ring-1 ring-danger focus:ring-danger": hasError,
    };

    const sizeClass = {
      "h-[32px]": size === "sm",
      "h-[38px]": size === "md",
      "h-[46px]": size === "lg",
    };

    return (
      <div className={clsx("flex h-[46px] overflow-hidden rounded-lg bg-white ring-1 ", sizeClass, borderClass)}>
        <div className="ml-2 flex h-full w-6 items-center justify-center">
          <Icon name="Calendar" size="sm" className="text-neutral-dark-gray" />
        </div>
        <input
          type="text"
          ref={ref}
          className={clsx(
            "h-full w-full flex-1 rounded-lg border-none px-1 py-2 text-neutral-black focus:border-primary focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-surface-gray max-xl:text-base",
            errorClass,
            className
          )}
          readOnly
          value={displayValue}
          {...props}
        />
        <div className="flex shrink-0">
          <IconButton
            iconName="ArrowLeft2"
            variant="tertiary"
            className={clsx("h-full w-[40px] rounded-none border-l hover:bg-primary-light", borderClass)}
            onClick={handlePrevClick}
          />
          <IconButton
            iconName="ArrowRight2"
            variant="tertiary"
            className={clsx("h-full w-[40px] rounded-none border-l  hover:bg-primary-light", borderClass)}
            onClick={handleNextClick}
          />
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";
