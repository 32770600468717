import { useState } from "react";
import { QRTemplate } from "@/redux/slices/qr/types";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { SendToPrinterContext, SendToPrinterStep } from "./context";

export const SendToPrinterForm = () => {
  const [step, setStep] = useState<SendToPrinterStep>(1);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<QRTemplate | undefined>(undefined);

  return (
    <SendToPrinterContext.Provider
      value={{ step, setStep, selectedTemplates, setSelectedTemplates, selectedTemplate, setSelectedTemplate }}
    >
      <Step1 />
      <Step2 />
      <Step3 />
    </SendToPrinterContext.Provider>
  );
};
