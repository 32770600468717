import { clsx } from "@/utils";

export interface SpinnerProps {
  variant?: "light" | "dark";
  className?: string;
}
export const Spinner = ({ variant = "dark", className, ...props }: SpinnerProps) => {
  return (
    <div
      className={clsx(
        "mr-2 h-4 w-4 animate-spin rounded-full border-b-2",
        {
          "border-white": variant === "light",
          "border-primary": variant === "dark",
        },
        className
      )}
      data-testid="spinner"
      {...props}
    ></div>
  );
};
