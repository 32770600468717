import { FixedNationwidePricingContextProvider, FixedPricingContextProvider, NationwidePanel } from "@/components/organisms";

export const Nationwide = () => {
  return (
    <FixedPricingContextProvider>
      <FixedNationwidePricingContextProvider>
        <NationwidePanel />
      </FixedNationwidePricingContextProvider>
    </FixedPricingContextProvider>
  );
};
