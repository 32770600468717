import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Icon, Toggle, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryEnableCustomCommissionField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  return (
    <div className={clsx("flex w-full flex-col gap-y-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph"> Custom Driver Commission</Typography>
        <Tooltip content="When active, this will be the default driver payment when dispatching jobs" placement="right">
          <span>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </span>
        </Tooltip>
      </div>
      <Controller
        name="enableCustomCommission"
        control={control}
        render={({ field }) => (
          <div className="mt-1 flex items-center gap-4">
            <Toggle
              checked={field.value}
              onChange={(checked) => {
                field.onChange(checked);
                if (!checked) setValue("commissionRate", 0, { shouldDirty: true });
              }}
            />
            <Typography className="inline">{field.value ? "Enabled" : "Disabled"}</Typography>
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="enableCustomCommission" />
    </div>
  );
};
