import { hasFlag } from "country-flag-icons";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";

countries.registerLocale(english);

export const getTimezoneFlag = (timezone: string) => {
  const countryName = timezone.split("/")[0];
  const code = countries.getAlpha2Code(countryName, "en") || "au";
  if (hasFlag(code)) return getUnicodeFlagIcon(code);
};
