import { Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { DynamicBaseInterval, DynamicInterval, DynamicIntervals } from "@/redux/slices/pricing/types";
import { addToast, getCostDisplay, getCurrencySymbol } from "@/utils";
import { DynamicPricingFormData, DynamicPricingTableData, PriceRange } from "./types";

export const getPriceRanges = (data: DynamicPricingFormData): PriceRange[] => {
  const { intervals, baseInterval } = data;
  const baseIntervalCost = { min: 0, max: Number(baseInterval.cost) };

  return intervals.reduce(
    (acc, curr, i) => {
      return [
        ...acc,
        {
          min: acc[i].max,
          max:
            i < intervals.length - 1
              ? acc[i].max + (Number(intervals[i + 1].minimumRange) - Number(curr.minimumRange)) * Number(curr.rate)
              : 0,
        },
      ];
    },
    [baseIntervalCost]
  );
};

export const generateDynamicPricingData = (
  baseInterval: DynamicBaseInterval,
  intervals: DynamicIntervals,
  currency: string,
  distanceUnit: string
) => {
  const currencySymbol = getCurrencySymbol(currency) || "";
  const priceRanges = baseInterval ? getPriceRanges({ baseInterval, intervals }) : [];
  const jointIntervals = baseInterval ? [baseInterval, ...intervals] : [];
  const data = jointIntervals.map((interval, i) => {
    const maximumRange = i < intervals.length ? intervals[i].minimumRange : null;
    return {
      intervals: (
        <div className="flex flex-col">
          <Typography variant="paragraph">{i > 0 ? `Interval ${i}` : "Minimum Trip Cost"}</Typography>
          {interval.note && (
            <Typography variant="small" className="text-neutral-dark-gray">
              {interval.note}
            </Typography>
          )}
        </div>
      ),
      range: `${interval.minimumRange} - ${maximumRange || "∞"} ${distanceUnit}`,
      rate: i > 0 ? getCostDisplay((interval as DynamicInterval).rate, currencySymbol) : "-",
      intervalPriceRange:
        i > 0
          ? `${getCostDisplay(priceRanges[i].min, currencySymbol)} - ${
              priceRanges[i].max > 0 ? getCostDisplay(priceRanges[i].max, "") : "∞"
            }`
          : `${getCostDisplay((interval as DynamicBaseInterval)?.cost || 0, currencySymbol)} (Fixed)`,
    } as DynamicPricingTableData;
  });
  return data;
};

export const generateDynamicPricingColumns = (distanceUnit: string) => {
  const columns: DataTableColumnDef<DynamicPricingTableData>[] = [
    {
      accessorKey: "intervals",
      header: "Intervals",
      cell: (props) => props.row.original.intervals,
      enableSorting: false,
    },
    {
      accessorKey: "range",
      header: `Range (${distanceUnit})`,
      cell: (props) => props.row.original.range,
      enableSorting: false,
    },
    {
      accessorKey: "rate",
      header: `Rate /${distanceUnit}`,
      cell: (props) => props.row.original.rate,
      enableSorting: false,
    },
    {
      accessorKey: "intervalPriceRange",
      header: "Interval Price Range",
      cell: (props) => props.row.original.intervalPriceRange,
      enableSorting: false,
    },
  ];

  return columns;
};

export const validateIntervals = (intervals: number[]): boolean => {
  let intervalError = 0;
  const isIncremental = intervals.slice(1).every((item, i) => {
    const condition = intervals[i] < item;
    if (!condition) intervalError = i;

    return condition;
  });

  if (!isIncremental) {
    addToast(
      "danger",
      `Unable to save changes. ${
        intervalError > 0 ? `Interval ${intervalError}` : "Minimum Cost"
      } must be incremental over the previous range.`
    );
    return false;
  }

  return true;
};
