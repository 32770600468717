import "mapbox-gl/dist/mapbox-gl.css";
import { memo } from "react";
import { Icon, Toast } from "@/components/atoms";
import { useBookingTripMap } from "../hooks/useBookingTripMap";
import { Polyline } from "./common/Polyline";

export const BookingTripPolylines = memo(() => {
  const {
    mode,
    tripLog: {
      job: { status },
    },
    map: { ref: mapRef, polylines },
  } = useBookingTripMap();

  const renderAdminRoutes = () => {
    if (mode !== "admin") return null;
    return (
      <>
        {polylines.onRoute && <Polyline type="on-route" encodedPath={polylines.onRoute} />}
        {polylines.onTrip && <Polyline type="on-trip" encodedPath={polylines.onTrip} />}
      </>
    );
  };

  switch (status) {
    case "on_way_to_job":
      return polylines.driverOnWay ? (
        <>
          {renderAdminRoutes()}
          <Polyline type="on-route" encodedPath={polylines.driverOnWay} />
        </>
      ) : mapRef.current?.loaded ? (
        <NotAvailable />
      ) : null;

    case "commenced":
      return polylines.commenced ? (
        <Polyline type="on-route" encodedPath={polylines.commenced} />
      ) : mapRef.current?.loaded ? (
        <NotAvailable />
      ) : null;

    case "awaiting_driver_allocation":
    case "driver_allocated_confirmed":
    case "driver_allocated_not_confirmed":
      return polylines.preTrip ? (
        <Polyline type="on-route" encodedPath={polylines.preTrip} />
      ) : mapRef.current?.loaded ? (
        <NotAvailable />
      ) : null;

    default:
      return (
        <>
          {polylines.onRoute && <Polyline type="on-route" encodedPath={polylines.onRoute} />}
          {polylines.onTrip && <Polyline type="on-trip" encodedPath={polylines.onTrip} />}
        </>
      );
  }
});

BookingTripPolylines.displayName = "BookingTripPolylines";

const NotAvailable = () => {
  const {
    tripLog: {
      job: { status },
    },
  } = useBookingTripMap();

  const message =
    status === "on_way_to_job"
      ? "Tracking is currently unavailable for this trip. Please keep an eye out for your driver"
      : "Tracking is currently unavailable for this trip";

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-neutral-black bg-opacity-70">
      <Toast
        visible
        className="shadow-none"
        customIcon={<Icon name="InfoCircle" size="lg" className="pointer-events-none text-danger" variant="Bold" />}
        type="danger"
        message={message}
      />
    </div>
  );
};
