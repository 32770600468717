import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useUserAccountsContext } from "@/contexts/userAccounts";

export const UserAccountsEmptyState = () => {
  const { setStep } = useUserAccountsContext();

  return (
    <EmptyState
      title="Staff Directory"
      description="It looks like you haven't added any members yet. Begin by inviting your first member to get started."
    >
      <Button onClick={() => setStep("add")} startIcon="Add">
        Invite New
      </Button>
    </EmptyState>
  );
};
