import { clsx } from "@/utils";
import { BusinessAddressField, BusinessOfficialNameField, BusinessRegistrationIdNumberField, BusinessRegistrationTypeField, BusinessTaglineField, BusinessTradingNameField, BusinessTypeField, WebsiteAddressField } from "../forms/fields";

export const SignUpFormOperatorStep3 = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx("animate-enter grid grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", className)} {...props}>
      <BusinessTypeField className="col-span-2" />
      <div className="col-span-2 grid grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
        <BusinessRegistrationTypeField className="col-span-2 lg:col-span-1" />
        <BusinessRegistrationIdNumberField className="col-span-2 lg:col-span-1" />
      </div>
      <BusinessOfficialNameField className="col-span-2 lg:col-span-1" />
      <BusinessTradingNameField className="col-span-2 lg:col-span-1" />
      <WebsiteAddressField className="col-span-2 lg:col-span-1" />
      <BusinessTaglineField className="col-span-2 lg:col-span-1" />
      <BusinessAddressField className="col-span-2" />
    </div>
  );
};
