import { z } from "zod";

const baseIntervalFormSchema = z.object({
  minimumRange: z.coerce.number(),
  maximumRange: z.coerce.number().min(0).max(99999).step(1, "Field must be a whole number."),
  cost: z.coerce.number().min(0).max(999999999).step(0.01, "Field must be a maximum of 2 decimal places."),
  note: z.string(),
});

const intervalsFormSchema = z.object({
  minimumRange: z.coerce.number().min(0).max(99999).step(1, "Field must be a whole number."),
  rate: z.coerce.number().min(0).max(999999999).step(0.01, "Field must be a maximum of 2 decimal places."),
  note: z.string(),
});

export const dynamicPricingFormSchema = z.object({
  baseInterval: baseIntervalFormSchema,
  intervals: z.array(intervalsFormSchema),
});

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  delete: {
    title: "Are you sure?",
    description: "This will delete the interval. You cannot undue this action, do you wish\nto continue?",
  },
  resetTripCosts: {
    title: "Reset Trip Costs",
    description:
      'By clicking "Continue," all the custom pricing for your trips will be reset\nto RideMinder\'s universal pricing. Please note that this action is\nirreversible. Are you sure you want to proceed?',
  },
};
