import { useState } from "react";
import { FILTERS } from "./fixtures";

export interface UseJobHistorySearchFilters {
  mode: "default" | "search" | "filter";
  setMode: (value: "default" | "search" | "filter") => void;
  search: string;
  setSearch: (value: string) => void;
  filters: string[];
  setFilters: (value: string[]) => void;
}

export const useJobHistorySearchFilters = () => {
  const [mode, setMode] = useState<"default" | "search" | "filter">("default");
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<string[]>(Object.keys(FILTERS));

  return {
    mode,
    setMode,
    search,
    setSearch,
    filters,
    setFilters,
  } satisfies UseJobHistorySearchFilters;
};
