import { useFormContext } from "react-hook-form";
import { CheckboxInput, DropdownCheckbox, ErrorMessage, Typography } from "@/components/atoms";
import { VehicleCategory, VehicleCategoryList } from "@/redux/slices/pricing/types";
import { clsx } from "@/utils";
import { VehicleCategoryFormData } from "../../types";

interface VehicleCollectJobsFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  items: VehicleCategoryList;
  excludeItem?: VehicleCategory;
}

export const VehicleCollectJobsField = ({ excludeItem, items, className, ...props }: VehicleCollectJobsFieldProps) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<VehicleCategoryFormData>();
  const watchVehicleCollectJobs = watch("vehicleCollectJobs");

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Vehicles that this vehicle can collect jobs on behalf of:</Typography>
      <DropdownCheckbox
        className="w-full"
        placeholder="Select Vehicle"
        selectedCount={watchVehicleCollectJobs?.length}
        hasError={!!errors.vehicleCollectJobs}
      >
        {items
          .filter((i) => i.id !== excludeItem?.id)
          .map((i) => (
            <CheckboxInput key={i.id} label={i.name} value={i.id} {...register("vehicleCollectJobs")} />
          ))}
      </DropdownCheckbox>
      <ErrorMessage errors={errors} name="vehicleCollectJobs" />
    </div>
  );
};
