import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Icon, Panel, PasswordInput, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useResetPasswordMutation } from "@/redux/apis/auth/authApi";
import { addToast } from "@/utils";

type FormData = {
  password: string;
  confirmPassword: string;
};

export const ResetPasswordForm = ({ token }: { token: string }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const { register, handleSubmit, reset } = useForm<FormData>();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const onSubmit = handleSubmit((data) => {
    setErrors([]);

    const { password, confirmPassword } = data;
    if (password !== confirmPassword) {
      setErrors(["Passwords don't match. Please try again."]);
      return;
    }

    resetPassword({ token, password })
      .unwrap()
      .then(() => {
        addToast("success", "Password changed! You can login now.");
        reset();
        navigate("/login");
      })
      .catch((e) => setErrors(getErrorMessages(e)));
  });

  return (
    <Panel>
      <div className="w-full">
        <div className="px-12 text-center">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center rounded-lg bg-primary-light p-3 mb-4">
              <Icon
                name="Refresh2"
                size={36}
                className="text-primary"
              />
            </div>
          </div>
          <Typography variant="h1">Reset Password</Typography>
          <Typography variant="paragraph" className="mt-1 text-neutral-dark-gray">
            To reset your password please enter your new password below
          </Typography>
        </div>

        {errors.length > 0 && (
          <div className="my-4">
            {errors.map((e, i) => (
              <Alert key={i} type="danger" message={e} className="my-4" />
            ))}
          </div>
        )}

        <form className="mt-4 w-full" onSubmit={onSubmit}>
          <div className="space-y-4 rounded-md shadow-sm">
            <div>
              <label htmlFor="password" className="mb-1 block">
                <Typography>Enter new password</Typography>
              </label>
              <PasswordInput required placeholder="Enter password" autoFocus minLength={8} {...register("password", { required: true })} />
              <Typography variant="small" className="text-neutral-dark-gray">
                Note: minimum of 8 characters
              </Typography>
            </div>
            <div>
              <label htmlFor="confirmPassword" className="mb-1 block">
                <Typography>Confirm new password</Typography>
              </label>
              <PasswordInput required placeholder="Enter password" minLength={8} {...register("confirmPassword", { required: true })} />
            </div>
          </div>

          <div className="mt-8">
            {isLoading ? (
              <Button type="submit" disabled size="lg" className="w-full">
                <div className="flex items-center justify-center ">
                  <Spinner variant="light" />
                  Processing...
                </div>
              </Button>
            ) : (
              <Button type="submit" size="lg" className="w-full">
                Reset Password
              </Button>
            )}
          </div>
        </form>
      </div>
    </Panel>
  );
};
