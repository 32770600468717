import { useParams } from "react-router-dom";
import { Panel, Skeleton } from "@/components/atoms";
import { Restricted } from "@/components/pages";
import { useDynamicPricingStateContext } from "../contexts";
import { CostPreviewPanel } from "./CostPreviewPanel";
import { StateBasedDynamicPricingEditor } from "./StateBasedDynamicPricingEditor";
import { StateBasedDynamicPricingTable } from "./StateBasedDynamicPricingTable";

export const StateBasedDynamicPricingPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { mode } = useParams();
  const { isFetching: isFetchingPricingStates, pricingState, isRestricted } = useDynamicPricingStateContext();
  const isFetching = isFetchingPricingStates || !pricingState;

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="flex flex-1 flex-col items-start gap-5 lg:flex-row">
          <Panel className={className} {...props}>
            {mode === "edit" ? (
              <StateBasedDynamicPricingEditor pricing={pricingState} />
            ) : (
              <StateBasedDynamicPricingTable pricing={pricingState} />
            )}
          </Panel>
          <CostPreviewPanel />
        </div>
      )}
    </>
  );
};
