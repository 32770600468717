import { ExternalSuppliersAddPanel, PageHeader } from "@/components/organisms";

export const ExternalSuppliersAdd = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader title="External Suppliers" />
      <PageHeader.Actions />
      <ExternalSuppliersAddPanel />
    </div>
  );
};
