import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { Option } from "@/redux/types";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

interface InvoiceGenerateFrequencyFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option;
}

export const InvoiceGenerateFrequencyField = ({ options, className, ...props }: InvoiceGenerateFrequencyFieldProps) => {
  const {
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <Typography>When to Generate Invoice</Typography>
      <Controller
        control={control}
        name="invoiceGenerateFrequency"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Monthly Date"
            options={Object.entries(options).map(([key, value]) => ({ name: value, value: key }))}
            value={field.value?.toString()}
            onChange={(value: string) => {
              field.onChange(value);
              if (["never", "no_future_jobs", "twice_monthly"].includes(value)) {
                setValue("generateInvoiceOn", "");
              } else {
                resetField("generateInvoiceOn");
              }
            }}
            hasError={!!errors.invoiceGenerateFrequency}
          />
        )}
      />
      <ErrorMessage errors={errors} name="invoiceGenerateFrequency" />
    </div>
  );
};
