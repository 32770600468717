import { ArrayParam, useQueryParams } from "use-query-params";
import { useSearch } from "@/hooks";
import { GetUninvoicedJobsCompaniesParams } from "@/redux/apis/company/types";
import { UninvoicedJobsFilterFormData } from "../types";

const FiltersParam = {
  generateOnFrequency: ArrayParam,
};

export const useGetUninvoicedJobsCompaniesParams = () => {
  const [search, setSearch] = useSearch();
  const [filters, setFilters] = useQueryParams(FiltersParam);

  const params: GetUninvoicedJobsCompaniesParams = {
    search: search ?? undefined,
    generate_on_frequency: filters.generateOnFrequency ? (filters.generateOnFrequency.filter(Boolean) as string[]) : undefined,
  };

  return {
    params,
    search: params.search,
    setSearch,
    filters: {
      generateOnFrequency: params.generate_on_frequency,
    } as UninvoicedJobsFilterFormData,
    setFilters,
  };
};
