import { FixedPricingContextProvider, FixedPricingLocationContextProvider, LocationBasedPanel } from "@/components/organisms";

export const LocationBased = () => {
  return (
    <FixedPricingContextProvider>
      <FixedPricingLocationContextProvider>
        <LocationBasedPanel />
      </FixedPricingLocationContextProvider>
    </FixedPricingContextProvider>
  );
};
