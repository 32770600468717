import { GetWaitingTimesRawResponse, GetWaitingTimesResponse, UpdateWaitingTimesRawResponse, UpdateWaitingTimesResponse } from "./types";

export const transformWaitingTimes = (res: GetWaitingTimesRawResponse) => {
  const { _links, ...waitingTimes } = res;
  const response: GetWaitingTimesResponse = {
    data: {
      details: {
        internationalAirport: waitingTimes.international_airport,
        domesticAirport: waitingTimes.domestic_airport,
        standard: waitingTimes.standard,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateWaitingTimes = (res: UpdateWaitingTimesRawResponse) => {
  return {
    links: res._links,
  } as UpdateWaitingTimesResponse;
};
