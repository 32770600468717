import { Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { companyApi } from "@/redux/apis/company/companyApi";
import { useUndoPaymentMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInvoicingSidePanelAction } from "@/redux/slices/payment/paymentSlice";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { addToast } from "@/utils";

interface UndoPaymentProps {
  onClose: () => void;
}

export const UndoPayment = ({ onClose }: UndoPaymentProps) => {
  const dispatch = useAppDispatch();
  const { selectedInvoice: invoice, selectedPayment: payment } = useAppSelector(invoicingPageStateSelector);
  const [undoPayment, { isLoading }] = useUndoPaymentMutation();
  const description =
    "Are you sure you want to revert the 'Paid' invoice status to 'Issued'? This will reset the amount paid and date received fields to blank, and this action is irreversible.";

  const handleUndoPayment = () => {
    if (!invoice || !payment) return;
    undoPayment({ invoiceId: invoice.id, paymentId: payment.id })
      .unwrap()
      .then(() => {
        onClose();
        dispatch(setInvoicingSidePanelAction(undefined));
        dispatch(companyApi.util.invalidateTags(["InvoicesCompanies"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <div className="relative">
      {isLoading && <Loading />}
      <div className="max-w-lg">
        <div className="flex flex-col gap-y-3">
          <Typography variant="title">Undo Payment</Typography>
          <Typography variant="paragraph">{description}</Typography>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="secondary" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleUndoPayment} size="md" className="bg-danger hover:bg-danger-dark">
          Undo Payment
        </Button>
      </div>
    </div>
  );
};
