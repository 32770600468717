import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { DropdownCheckbox, DropdownSelect, ErrorMessage, Icon, TextInput, Tooltip, Typography } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { EditVehicleFormData } from "../form";
import { VehicleAdvancedSettings } from "./VehicleAdvancedSettings";
import { VehicleCategoryModal } from "./VehicleCategoryModal";
import { VehicleDocuments } from "./VehicleDocuments";

export const VehicleDetails = ({ vehicle }: { vehicle: Vehicle }) => {
  const actionRef = useRef<boolean | null>(null);
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");
  const [openCategories, setOpenCategories] = useState(false);

  useEffect(() => {
    if (!actionRef.current && action === "vehicle-category") {
      setOpenCategories(true);
      actionRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<EditVehicleFormData>();

  const categories = watch("details.categories");
  const maxCapacity = vehicle.model.maxCapacity;

  return (
    <div className="grid grid-cols-2 gap-5 gap-y-6 ">
      <label htmlFor="details.categories" className="col-span-2 md:col-span-1">
        <Typography className="relative flex items-center gap-2 leading-[2rem]">
          Vehicle Category
          <Tooltip content="Select the category of the vehicle" placement="top">
            <Icon name="InfoCircle" variant="Bold" size="md" className="relative z-10 rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </Typography>
        <DropdownCheckbox
          onClick={() => setOpenCategories(true)}
          className="w-full [&_.dropdown-panel]:z-20"
          placeholder="Select Vehicle Category"
          selectedCount={categories?.length}
          getSelectedLabel={(selected) => (selected ? `${selected} Vehicle Categories Selected` : "")}
          hasError={!!errors.details?.categories}
        />
        {errors.details?.categories?.message && (
          <ErrorMessage className="mt-2 text-sm" name="details" errors={errors} message={errors.details?.categories?.message} />
        )}
        <VehicleCategoryModal open={openCategories} onClose={() => setOpenCategories(false)} defaultCategories={categories} />
      </label>
      <label htmlFor="details.capacity" className="col-span-2 md:col-span-1">
        <Typography className="leading-[2rem]">Capacity</Typography>
        <Controller
          control={control}
          name="details.capacity"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              defaultValue="0"
              placeholder="Select Maximum Capacity"
              options={Array.from({ length: maxCapacity }, (_, i) => (i + 1).toString())}
              value={field.value?.toString()}
              onChange={(value: string) => {
                field.onChange(+value);
              }}
            />
          )}
        />
      </label>
      <label htmlFor="details.bags" className="col-span-2 md:col-span-1">
        <Typography className="leading-[2rem]">No. of Bags</Typography>
        <Controller
          control={control}
          name="details.bags"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              defaultValue="0"
              placeholder="Select Maximum Number of Bags"
              options={Array.from({ length: 100 }, (_, i) => i.toString())}
              value={field.value?.toString()}
              onChange={(value: string) => {
                field.onChange(+value);
              }}
            />
          )}
        />
      </label>
      <label htmlFor="nickname" className="col-span-2 md:col-span-1">
        <Typography className="leading-[2rem]">Vehicle Nickname (Optional)</Typography>
        <TextInput
          {...register("nickname")}
          id="nickname"
          className="[&>div]:w-full [&_input]:w-full [&_input]:text-right"
          hasError={!!errors.nickname}
        />
      </label>
      <VehicleDocuments />
      <VehicleAdvancedSettings />
    </div>
  );
};
