import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { accessControlApi } from "@/redux/apis/config/admin/accessControl";
import { billingSettingsApi } from "@/redux/apis/config/admin/billingSettings";
import { businessDetailsApi } from "@/redux/apis/config/admin/businessDetails";
import { dispatchSettingsApi } from "@/redux/apis/config/admin/dispatchSettings";
import { displayOptionsApi } from "@/redux/apis/config/admin/displayOptions";
import { emailNotificationsApi } from "@/redux/apis/config/admin/emailNotifications";
import { smsVoiceNotificationsApi } from "@/redux/apis/config/admin/smsVoiceNotifications";
import { waitingTimesApi } from "@/redux/apis/config/admin/waitingTimes";
import { websiteDetailsApi } from "@/redux/apis/config/admin/websiteDetails";
import { ErrorResponse } from "@/redux/types";
import { AdminConfigState } from "./types";

export const adminReducers = (builder: ActionReducerMapBuilder<AdminConfigState>) => {
  builder
    .addMatcher(businessDetailsApi.endpoints.getBusinessDetails.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.businessDetails.details = details;
    })
    .addMatcher(businessDetailsApi.endpoints.getBusinessDetails.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.businessDetails.errors = [...state.businessDetails.errors, detail];
    })
    .addMatcher(websiteDetailsApi.endpoints.getWebsiteDetails.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.websiteDetails.details = details;
    })
    .addMatcher(websiteDetailsApi.endpoints.getWebsiteDetails.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.websiteDetails.errors = [...state.websiteDetails.errors, detail];
    })
    .addMatcher(emailNotificationsApi.endpoints.getEmailNotifications.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.emailNotifications.details = details;
    })
    .addMatcher(smsVoiceNotificationsApi.endpoints.getSMSVoiceNotifications.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.smsVoiceNotifications.details = details;
    })
    .addMatcher(smsVoiceNotificationsApi.endpoints.getSMSVoiceNotifications.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.smsVoiceNotifications.errors = [...state.smsVoiceNotifications.errors, detail];
    })
    .addMatcher(billingSettingsApi.endpoints.getBillingSettings.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.billingSettings.details = details;
    })
    .addMatcher(billingSettingsApi.endpoints.getBillingSettings.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.billingSettings.errors = [...state.billingSettings.errors, detail];
    })
    .addMatcher(accessControlApi.endpoints.getAccessControl.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.accessControl.details = details;
    })
    .addMatcher(accessControlApi.endpoints.getAccessControl.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.accessControl.errors = [...state.accessControl.errors, detail];
    })
    .addMatcher(dispatchSettingsApi.endpoints.getDispatchSettings.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.dispatchSettings.details = details;
    })
    .addMatcher(dispatchSettingsApi.endpoints.getDispatchSettings.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.dispatchSettings.errors = [...state.dispatchSettings.errors, detail];
    })
    .addMatcher(waitingTimesApi.endpoints.getWaitingTimes.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.waitingTimes.details = details;
    })
    .addMatcher(waitingTimesApi.endpoints.getWaitingTimes.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.waitingTimes.errors = [...state.waitingTimes.errors, detail];
    })
    .addMatcher(displayOptionsApi.endpoints.getDisplayOptions.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.displayOptions.details = details;
    })
    .addMatcher(displayOptionsApi.endpoints.getDisplayOptions.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.displayOptions.errors = [...state.displayOptions.errors, detail];
    });
};
