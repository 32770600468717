import { invalidateOn } from "@/helpers/reduxHelpers";
import { createSearchParams } from "@/utils";
import { paymentApi } from "../paymentApi";
import {
  transformDebitHistory,
  transformDebitHistoryItem,
  transformDebitUpcomingSummary,
  transformDebitRecords,
  transformDebitUpcoming,
  transformPayoutHistory,
  transformPayoutHistoryItem,
  transformPayoutRecords,
  transformCreditCardPayments,
  transformCreditCardPaymentsSummary,
  transformPaymentMethodSecret,
  transformPaymentMethodDetails,
  transformManualPaymentDetails,
} from "./helpers";
import {
  GetDebitHistoryResponse,
  GetDebitHistoryParams,
  GetDebitHistoryItemResponse,
  GetDebitHistoryItemParams,
  GetDebitUpcomingSummaryResponse,
  GetDebitRecordsResponse,
  GetDebitRecordsParams,
  GetDebitUpcomingResponse,
  GetDebitUpcomingParams,
  GetPayoutHistoryResponse,
  GetPayoutHistoryParams,
  GetPayoutHistoryItemResponse,
  GetPayoutHistoryItemParams,
  GetPayoutRecordsResponse,
  GetPayoutRecordsParams,
  GetCreditCardPaymentsResponse,
  GetCreditCardPaymentsParams,
  GetCreditCardPaymentsSummaryResponse,
  GetCreditCardPaymentsSummaryParams,
  TriggerPaymentResponse,
  TriggerPaymentParams,
  GetPaymentMethodSecretResponse,
  GetPaymentMethodSecretParams,
  GetPaymentMethodDetailsResponse,
  GetPaymentMethodDetailsParams,
  GetManualPaymentDetailsResponse,
  GetManualPaymentDetailsParams,
  TriggerManualPaymentResponse,
  TriggerManualPaymentParams,
} from "./types";

export const recordApi = paymentApi
  .enhanceEndpoints({
    addTagTypes: ["CreditCardPayments", "CreditCardPaymentsSummary"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDebitHistory: builder.query<GetDebitHistoryResponse, GetDebitHistoryParams>({
        query: (params) => `/record/debit?${createSearchParams(params).toString()}`,
        transformResponse: transformDebitHistory,
      }),
      getDebitHistoryItem: builder.query<GetDebitHistoryItemResponse, GetDebitHistoryItemParams>({
        query: ({ id }) => `/record/debit/${id}`,
        transformResponse: transformDebitHistoryItem,
      }),
      getDebitUpcoming: builder.query<GetDebitUpcomingResponse, GetDebitUpcomingParams>({
        query: (params) => `/record/debit-upcoming?${createSearchParams(params).toString()}`,
        transformResponse: transformDebitUpcoming,
      }),
      getDebitUpcomingSummary: builder.query<GetDebitUpcomingSummaryResponse, void>({
        query: () => "/record/debit-upcoming?summary=true",
        transformResponse: transformDebitUpcomingSummary,
      }),
      getDebitRecords: builder.query<GetDebitRecordsResponse, GetDebitRecordsParams>({
        query: ({ id, ...params }) => `/record/debit/${id}/items?${createSearchParams(params).toString()}`,
        transformResponse: transformDebitRecords,
      }),
      getPayoutHistory: builder.query<GetPayoutHistoryResponse, GetPayoutHistoryParams>({
        query: (params) => `/record/payout?${createSearchParams(params).toString()}`,
        transformResponse: transformPayoutHistory,
      }),
      getPayoutHistoryItem: builder.query<GetPayoutHistoryItemResponse, GetPayoutHistoryItemParams>({
        query: ({ id }) => `/record/payout/${id}`,
        transformResponse: transformPayoutHistoryItem,
      }),
      getPayoutRecords: builder.query<GetPayoutRecordsResponse, GetPayoutRecordsParams>({
        query: ({ id, ...params }) => `/record/payout/${id}/items?${createSearchParams(params).toString()}`,
        transformResponse: transformPayoutRecords,
      }),
      getCreditCardPayments: builder.query<GetCreditCardPaymentsResponse, GetCreditCardPaymentsParams>({
        query: (params) => {
          const provideDateParams = params.status === "paid" || params.status === "refund";
          /* eslint-disable @typescript-eslint/no-unused-vars */
          const { from_date, to_date, ...filteredParams } = params;
          return `/record/credit-card?${createSearchParams(provideDateParams ? params : filteredParams).toString()}`;
        },
        transformResponse: transformCreditCardPayments,
        providesTags: ["CreditCardPayments"],
      }),
      getCreditCardPaymentsSummary: builder.query<GetCreditCardPaymentsSummaryResponse, GetCreditCardPaymentsSummaryParams>({
        query: (params) => `/record/credit-card?summary=true&${createSearchParams(params).toString()}`,
        transformResponse: transformCreditCardPaymentsSummary,
        providesTags: ["CreditCardPaymentsSummary"],
      }),
      triggerPayment: builder.mutation<TriggerPaymentResponse, TriggerPaymentParams>({
        query: ({ id }) => ({
          url: `/record/credit-card/${id}/trigger-payment`,
          method: "POST",
        }),
        invalidatesTags: invalidateOn({ success: ["CreditCardPayments", "CreditCardPaymentsSummary"] }),
      }),
      getPaymentMethodSecret: builder.mutation<GetPaymentMethodSecretResponse, GetPaymentMethodSecretParams>({
        query: ({ id }) => ({
          url: `/record/credit-card/${id}/payment-method`,
          method: "POST",
        }),
        transformResponse: transformPaymentMethodSecret,
      }),
      getPaymentMethodDetails: builder.query<GetPaymentMethodDetailsResponse, GetPaymentMethodDetailsParams>({
        query: ({ id, setupId }) => `/record/credit-card/${id}/payment-method/${setupId}`,
        transformResponse: transformPaymentMethodDetails,
      }),
      getManualPaymentDetails: builder.mutation<GetManualPaymentDetailsResponse, GetManualPaymentDetailsParams>({
        query: ({ id, paymentMethodId }) => ({
          url: `/record/credit-card/${id}/manual-payment`,
          method: "POST",
          body: {
            dry_run: true,
            payment_method_uuid: paymentMethodId,
          },
        }),
        transformResponse: transformManualPaymentDetails,
      }),
      triggerManualPayment: builder.mutation<TriggerManualPaymentResponse, TriggerManualPaymentParams>({
        query: ({ id, paymentMethodId }) => ({
          url: `/record/credit-card/${id}/manual-payment`,
          method: "POST",
          body: {
            dry_run: false,
            payment_method_uuid: paymentMethodId,
          },
        }),
        transformResponse: transformManualPaymentDetails,
        invalidatesTags: invalidateOn({ success: ["CreditCardPayments", "CreditCardPaymentsSummary"] }),
      }),
    }),
  });

export const {
  useGetDebitHistoryQuery,
  useLazyGetDebitHistoryItemQuery,
  useGetDebitUpcomingSummaryQuery,
  useGetDebitRecordsQuery,
  useGetDebitUpcomingQuery,
  useLazyGetDebitUpcomingQuery,
  useGetPayoutHistoryQuery,
  useLazyGetPayoutHistoryItemQuery,
  useGetPayoutRecordsQuery,
  useGetCreditCardPaymentsQuery,
  useLazyGetCreditCardPaymentsSummaryQuery,
  useTriggerPaymentMutation,
  useGetPaymentMethodSecretMutation,
  useLazyGetPaymentMethodDetailsQuery,
  useGetManualPaymentDetailsMutation,
  useTriggerManualPaymentMutation,
} = recordApi;
