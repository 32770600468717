import { Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface NoSearchResultsProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  icon?: "search" | React.ReactElement;
}

export const NoSearchResults = ({
  className,
  title = "No results found",
  description = "No results found for your search. Please try a different search term or explore other options",
  icon = "search",
  children,
  ...props
}: NoSearchResultsProps) => {
  const renderIcon = () => {
    if (icon === "search") return <Icon name="SearchNormal1" size="lg" className="mb-3" />;

    return <icon.type {...icon.props} className="mb-3" />;
  };

  return (
    <section className={clsx("flex flex-col place-items-center p-8 lg:p-16", className)} {...props}>
      {renderIcon()}
      <div className="text-center">
        <Typography variant="action" className="mb-1 block">
          {title}
        </Typography>
        <Typography className="max-w-[370px] text-neutral-dark-gray ">{description}</Typography>
      </div>
      <div className="mt-5 flex items-center gap-4">{children}</div>
    </section>
  );
};
