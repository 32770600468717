import { Typography } from "@/components/atoms";
import { clsx, getInitials } from "@/utils";

export interface OperatorEmblemProps {
  name: string;
  emblem?: string;
  size?: "sm" | "md";
}

export const OperatorEmblem = ({ name, emblem, size = "md" }: OperatorEmblemProps) => {
  const sizeFigureClassName = { 
    "h-[124px] w-[124px]": size === "md",
    "h-[62px] w-[62px]": size === "sm"
  };
  const sizeImageClassName = { 
    "max-h-[114px] max-w-[114px]": size === "md",
    "max-h-[57px] max-w-[57px]": size === "sm",
  };

  return (
    <figure className={clsx("flex items-center justify-center overflow-hidden rounded-full ring ring-offset-4 ring-primary-dark", sizeFigureClassName)}>
      {emblem ? <img className={clsx(sizeImageClassName)} src={emblem} alt={name} /> : (
        <div className={clsx(sizeImageClassName)}>
          <Typography variant="h1">{getInitials(name)}</Typography>
        </div>
      )}
    </figure> 
  );
};