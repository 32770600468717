import { Icon } from "@/components/atoms";
import { BookingNavButton } from "../../../common/BookingNavButton/BookingNavButton";

interface FilterNavButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  onClick: () => void;
  isActive: boolean;
  children: React.ReactNode;
}
export const FilterNavButton = ({ onClick, isActive, children, ...props }: FilterNavButtonProps) => {
  return (
    <BookingNavButton
      onClick={onClick}
      iconPosition="right"
      customIcon={isActive ? <Icon isCustom name="check" size="sm" className="text-success" /> : undefined}
      {...props}
    >
      {children}
    </BookingNavButton>
  );
};
