import { useState } from "react";
import { Button, Dropzone, Icon, Typography, Modal, Loading } from "@/components/atoms";
import { useDeleteAssetMutation, useUploadAssetMutation } from "@/redux/apis/config/asset";
import { addNotification, addToast, clsx } from "@/utils";
import { modals } from "./fixtures";
import { getAssetName } from "./helpers";
import { Userpilot } from "userpilot";

export interface AssetUploadProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  path?: string;
}

export const AssetUpload = ({ type, path, className, ...props }: AssetUploadProps) => {
  const assetName = getAssetName(path);
  const [deleteAsset] = useDeleteAssetMutation();
  const [uploadAsset] = useUploadAssetMutation();
  const [activeModal, setActiveModal] = useState<"delete" | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadAsset = (file: File) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append(type, file);
    uploadAsset(formData)
      .unwrap()
      .then(() => {
        if (type === "emblem") Userpilot.track("Emblem Uploaded");
        else if (type === "logo") Userpilot.track("Logo uploaded");
        
        addToast("success", "Successfully uploaded asset.");
        setTimeout(() => window.location.reload(), 1250);
      })
      .catch((e) => {
        const message = e.data?.messages?.[type];
        addToast("danger", message ? message : "Something went wrong.");
        setIsLoading(false);
      });
  };

  const handleDeleteAsset = () => {
    setIsLoading(true);

    setActiveModal(null);
    deleteAsset(type)
      .unwrap()
      .then(() => {
        addNotification("info", "Deleted 1 asset", `Deleted "${assetName}"`);
        setTimeout(() => window.location.reload(), 1250);
      })
      .catch((e) => {
        const message = e.data?.messages?.[type];
        addToast("danger", message ? message : "Something went wrong.");
        setIsLoading(false);
      });
  };

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteAsset} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  return (
    <div className={clsx("relative flex", className)} {...props}>
      {renderModal()}
      {isLoading && <Loading />}
      <div className="flex flex-col w-[405px]">
        {path ? (
          <>
            <div className="flex h-[137px] items-center  justify-center rounded-lg border border-neutral-mid-gray">
              <img src={path} alt={assetName} className="h-2/3 w-2/3 object-contain" />
            </div>
            <div className="flex justify-end">
              <button
                className="my-1 flex items-center bg-transparent text-base text-danger hover:text-danger-dark"
                onClick={() => setActiveModal("delete")}
              >
                <Icon name="Trash" size="sm" className="mr-2" aria-hidden="true" />
                <Typography variant="small">Remove</Typography>
              </button>
            </div>
          </>
        ) : (
          <Dropzone
            accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"] }}
            maxSize={90500000}
            upload={handleUploadAsset}
            detail={
              <div className="flex h-full place-items-center justify-center gap-x-4 px-4">
                <Icon name="Gallery" size="xxl" aria-hidden="true" />
                <div>
                  <Typography variant="action">Select a PNG or JPG file to upload</Typography>
                  <Typography variant="paragraph">or drag and drop it here</Typography>
                </div>
              </div>
            }
            className="h-[137px]"
          />
        )}
      </div>
    </div>
  );
};
