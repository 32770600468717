import { useEffect } from "react";
import { useNavigate, useOutlet } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { AppAction, AppSubject } from "@/casl/types";
import { Tabs } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { CreditCardPayments } from "./CreditCardPayments";
import { DebitHistory } from "./DebitHistory";
import { Invoicing } from "./Invoicing";
import { PaymentConfiguration } from "./PaymentConfiguration";
import { PayoutHistory } from "./PayoutHistory";

type Tab = {
  name: string;
  to: string;
  permission?: [AppAction, AppSubject];
  isPermissionLocked?: boolean;
};

const pageTabs: Tab[] = [
  {
    name: "Credit Card Payments",
    to: "credit-card-payments",
    permission: ["view", "providerPayments"],
  },
  {
    name: "Invoicing",
    to: "invoicing",
    permission: ["manage", "providerPaymentsInvoice"],
  },
  {
    name: "Payout History",
    to: "payout-history",
    permission: ["view", "providerPayments"],
  },
  {
    name: "Debit History",
    to: "debit-history",
    permission: ["view", "providerPayments"],
  },
  {
    name: "Payment Configuration",
    to: "payment-configuration",
    permission: ["manage", "providerPaymentsConfig"],
    isPermissionLocked: true,
  },
];

export const Payments = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const ability = useAppAbility();

  useEffect(() => {
    if (!outlet) navigate("../");
  }, [outlet, navigate]);

  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader skipCrumbs={1} title="Payments" />
      <Tabs className="mb-5 border-b !border-neutral-dark-gray">
        {pageTabs.map((t, i) => {
          const { permission, to, name, isPermissionLocked } = t;
          if (permission) {
            if (ability.can(permission[0], permission[1])) {
              return <Tabs.Tab key={i} to={to} name={name} />;
            } else {
              if (isPermissionLocked) return <Tabs.Tab key={i} to={to} name={name} isLocked={true} />;
            }
          } else {
            return <Tabs.Tab key={i} to={to} name={name} />;
          }
        })}
      </Tabs>
      {outlet}
    </div>
  );
};

Payments.Invoicing = Invoicing;
Payments.CreditCardPayments = CreditCardPayments;
Payments.PayoutHistory = PayoutHistory;
Payments.DebitHistory = DebitHistory;
Payments.PaymentConfiguration = PaymentConfiguration;
