import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { PricingList } from "@/components/molecules";
import { Restricted } from "@/components/pages";
import { useFixedPricingContext, useFixedPricingLocationContext } from "../contexts";
import { PricingLocationEmptyState } from "./emptyStates/PricingLocationEmptyState";

export const PricingLocationPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const { isFetching: isFetchingPricingProfiles, isRestricted } = useFixedPricingContext();
  const { isFetching: isFetchingPricingLocations, pricingLocations } = useFixedPricingLocationContext();
  const isFetching = isFetchingPricingProfiles || isFetchingPricingLocations;

  const onAddPricingLocation = () => navigate("add-location");

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <Panel className={className} {...props}>
          {pricingLocations.length < 1 ? (
            <PricingLocationEmptyState onAddPricingLocation={onAddPricingLocation} />
          ) : (
            <div className="flex flex-col gap-y-4">
              <div className="flex">
                <div className="flex-1">
                  <Typography variant="h3" className="leading-8">
                    Location Based Fixed Pricing
                  </Typography>
                  <Typography className="text-neutral-dark-gray">
                    Tailor your pricing to match the designated area radius of your choice.
                  </Typography>
                </div>
                <div className="flex items-center justify-center">
                  <Button startIcon="Add" onClick={onAddPricingLocation}>
                    Add Location
                  </Button>
                </div>
              </div>
              <PricingList>
                {pricingLocations.map(({ id, pickupLocation: { name, address }, baseInterval, managed }) => (
                  <PricingList.Item
                    key={id}
                    title={name}
                    address={address}
                    to={id}
                    alert={
                      baseInterval
                        ? undefined
                        : {
                            type: "warning",
                            message: "No trip pricing has been set for this one",
                          }
                    }
                    managed={managed}
                  />
                ))}
              </PricingList>
            </div>
          )}
        </Panel>
      )}
    </>
  );
};
