export const getCurrencySymbol = (currencyCode: string) => {
  try {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol",
    });

    const parts = currencyFormatter.formatToParts(0);

    for (const part of parts) {
      if (part.type === "currency") {
        return part.value;
      }
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};
