import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { authApi } from "@/redux/apis";
import { AuthenticationResponse } from "@/redux/apis/auth/types";
import { AuthState } from "./types";

const initialState: AuthState = { accessToken: "", refreshToken: "", loggedOut: false };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<AuthenticationResponse>) => {
      const { access_token, refresh_token } = action.payload;
      state.accessToken = access_token;
      state.refreshToken = refresh_token;
    },
    setLoggedOut: (state, action) => {
      state.loggedOut = action.payload;
    },
    logOut: () => {
      return {
        ...initialState,
        loggedOut: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.accessToken = payload.access_token;
      state.refreshToken = payload.refresh_token;
    });
  },
});

export const { setAuth, setLoggedOut, logOut } = authSlice.actions;
export default authSlice.reducer;
