import cx from "classnames";
import { Typography } from "@/components/atoms";

export interface IntervalRowProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  renderRange: () => JSX.Element;
  renderRate: () => JSX.Element;
}

export const IntervalRow = ({ label, renderRange, renderRate, className, ...props }: IntervalRowProps) => {
  return (
    <div className={cx("flex", className)} {...props}>
      <div className="flex w-[136px] items-center justify-center rounded-l-lg bg-neutral-gray">
        <Typography variant="action">{label}</Typography>
      </div>
      <div className="flex flex-1 flex-col justify-end gap-4 rounded-r-lg bg-neutral-surface-gray px-5 py-4 md:flex-row lg:flex-row">
        <div className="flex-1">{renderRange()}</div>
        <div className="flex">{renderRate()}</div>
      </div>
    </div>
  );
};
