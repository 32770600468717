import { BooleanDisplay, Button, Loading, Typography } from "@/components/atoms";
import { useLazyGetInvoicingConfigurationQuery } from "@/redux/apis/addOn/addOnApi";
import { InvoicingConfiguration } from "@/redux/slices/addOn/types";
import { useEffect } from "react";

interface InvoicingDetailsProps {
  onEdit: () => void;
  configuration?: InvoicingConfiguration;
}

export const InvoicingDetails = ({ onEdit, configuration }: InvoicingDetailsProps) => {
  const [getInvoicingConfiguration, { isFetching }] = useLazyGetInvoicingConfigurationQuery();

  useEffect(() => {
    getInvoicingConfiguration();
  }, [getInvoicingConfiguration]);

  return (
    <div className="relative flex flex-col w-full bg-neutral-surface-gray rounded-lg p-5 my-4">
      {isFetching && <Loading className="rounded-lg" />}
      <div className="flex flex-row items-center gap-x-[15px]">
        <div className="flex flex-1 flex-col">
          <Typography variant="action">Enable Invoicing Generation</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">Manually or automatically generate invoices from the RM system - leave off if you manage invoices externally</Typography>
        </div>
        <BooleanDisplay status={configuration?.invoiceModuleEnabled}>
          <Typography variant="paragraph">{configuration?.invoiceModuleEnabled ? "Yes" : "No"}</Typography>
        </BooleanDisplay>
      </div>
      <div className="flex flex-row w-full items-center justify-end mt-8">
        <Button variant="primary" size="sm" onClick={onEdit}>Edit Settings</Button>
      </div>
    </div>
  );
};
