import { Button, Container, Icon, IconButton, Skeleton, Typography } from "@/components/atoms";
import { PageHeaderV2 } from "@/components/organisms";
import { useGetNetworkParams } from "@/components/organisms/PrivateNetwork/hooks/useGetNetworkParams";
import { PrivateNetworkModals } from "@/components/organisms/PrivateNetwork/PrivateNetworkModals";
import { PrivateNetworkTable } from "@/components/organisms/PrivateNetwork/tables/PrivateNetworkTable";
import { PrivateNetworkStep } from "@/components/organisms/PrivateNetwork/types";
import { useGetNetworkQuery } from "@/redux/apis/network/networkApi";
import { useAppSelector } from "@/redux/hooks";
import { networkSelector } from "@/redux/slices/network/selectors";
import { NetworkItem } from "@/redux/slices/network/types";
import { isEmpty } from "ramda";
import { useState } from "react";
import { PrivateNetworkFleetLeaders } from "./PrivateNetworkFleetLeaders";
import { PrivateNetworkHistory } from "./PrivateNetworkHistory";

export const PrivateNetwork = () => {
  const { params } = useGetNetworkParams();
  const { isFetching } = useGetNetworkQuery(params, { refetchOnMountOrArgChange: true });
  const items = useAppSelector(networkSelector);

  const [pendingDisconnectRelationship, setPendingDisconnectRelationship] = useState<NetworkItem>();
  const [step, setStep] = useState<PrivateNetworkStep>(undefined);

  const renderHeaderActions = () => {
    if (!isEmpty(items)) {
      return (
        <div className="flex flex-row items-center">
          <div className="hidden sm:flex sm:flex-row sm:gap-x-1">
            <Button variant="primary" size="sm" startIcon="Add" onClick={() => setStep("inviteCode")}>
              Invite New
            </Button>
          </div>
          {/* mobile view */}
          <div className="flex flex-row gap-x-1 sm:hidden">
            <IconButton variant="primary" size="sm" iconName="Add" onClick={() => setStep("inviteCode")} />
          </div>
        </div>
      );
    }
  };

  return (
    <Container className="flex flex-1 flex-col w-full mb-12">
      <PageHeaderV2 
        name={(
          <div className="flex flex-row items-center gap-1.5">
            <Typography variant="h1">
              Private Network
            </Typography>
            <Icon 
              name="InfoCircle" 
              variant="Bold" 
              size="lg" 
              onClick={() => setStep("help")}
              className="rotate-180 text-neutral-dark-gray cursor-pointer"
            />
          </div>
        )} 
        skipCrumbs={1}
      >
        {renderHeaderActions()}
      </PageHeaderV2>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="mt-2">
          <PrivateNetworkTable items={items} setPendingDisconnectRelationship={setPendingDisconnectRelationship} setStep={setStep} />
          <PrivateNetworkTable.Pagination />
        </div>
      )}
      <PrivateNetworkModals
        step={step}
        setStep={setStep}
        setPendingDisconnectRelationship={setPendingDisconnectRelationship}
        pendingDisconnectRelationship={pendingDisconnectRelationship}
      />
    </Container>
  );
};

PrivateNetwork.History = PrivateNetworkHistory;
PrivateNetwork.FleetLeaders = PrivateNetworkFleetLeaders;
