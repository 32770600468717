import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";
import { Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  title?: string;
  description?: string;
  onClose?: () => void;
  renderButtons?: () => JSX.Element;
}

export const Modal = ({
  open,
  title,
  description,
  children,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  renderButtons,
  className,
}: ModalProps) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-mid-gray bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-50 h-screen max-w-[100vw] overflow-y-auto lg:h-auto">
          <div className="flex min-h-full items-center justify-center p-3 text-center  ">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={clsx(
                  "relative max-w-full transform overflow-hidden overflow-y-auto rounded-lg bg-white px-4 py-4 text-left shadow-diffuse transition-all sm:p-6 md:my-8 md:min-w-[515px] lg:px-8 lg:py-10",
                  className
                )}
              >
                {title && (
                  <div className="text-left">
                    <Typography variant="title">{title}</Typography>
                    <div className="mt-3">
                      <Typography className="text-neutral-black">{description}</Typography>
                    </div>
                  </div>
                )}
                {children}
                {renderButtons && <div className="mt-6 flex justify-end gap-4">{renderButtons()}</div>}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
