import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const PercentageCommissionField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();
  const watchOperatorCommissionOverride = watch("operatorCommissionOverride");

  return (
    <div className={clsx("flex flex-col sm:flex-row gap-1", { hidden: !watchOperatorCommissionOverride }, className)} {...props}>
      <div className="flex flex-1 flex-col gap-y-1">
        <Typography variant="paragraph">
          Enter the Percentage Commission <span className="text-danger">*</span>
        </Typography>
        <ErrorMessage errors={errors} name="percentageCommission" />
      </div>
      <div>
        <AddOnTextInput
          endAddOn="%"
          type="number"
          className="w-[100px]"
          step="any"
          min="0"
          max="9999"
          {...register("percentageCommission")}
        />
      </div>
    </div>
  );
};
