import { differenceInCalendarDays, format, parseISO } from "date-fns";
import { Badge, DropdownMenu, Icon, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { AccreditationHistoryItem, AccreditationItem } from "@/redux/slices/accreditation/types";
import { RegistrationCertificate } from "./contents/RegistrationCertificate";
import { AccreditationHistoryTableData, AccreditationsTableData } from "./types";

const renderStatus = (status: string) => {
  switch (status) {
    case "IN_REVIEW":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-neutral-dark-gray" /> In Review
        </Typography>
      );
    case "APPROVED":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-info" /> Approved
        </Typography>
      );
    case "DENIED":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-danger" /> Denied
        </Typography>
      );
    default:
      return <></>;
  }
};

const renderAlert = (item: AccreditationItem) => {
  if (item.requiresCertificate && !item.regCertName) return <Badge type="danger" message="Incomplete" isIconBadge />;
  if (item.expiryDate && differenceInCalendarDays(new Date(), new Date(item.expiryDate)) >= 0)
    return <Badge type="warning" message="Expired" isIconBadge />;
  return null;
};

const renderRegCert = (name: string | null, accreditationId: string, requiresCertificate: boolean) => {
  if (name) {
    return (
      <div className="flex items-center">
        <RegistrationCertificate name={name} accreditationId={accreditationId} />
      </div>
    );
  } else {
    if (requiresCertificate) {
      return (
        <div className="flex items-center text-danger">
          <Icon className="text-inherit mr-2" name="InfoCircle" variant="Bold" size="md" />
          <Typography variant="paragraph">Missing</Typography>
        </div>
      );
    } else {
      return <Typography variant="paragraph">No certificate required</Typography>;
    }
  }
};

const renderHistoryAction = (field: string, value: string) => {
  switch (field) {
    case "documentExpiryDate":
      return (
        <div className="flex flex-row items-center gap-x-1.5">
          <Icon name="Calendar" className="flex-shrink-0 text-primary" />
          <Typography variant="paragraph">Expiry Date Updated</Typography>
        </div>
      );
    case "documentPath":
      return (
        <div className="flex flex-row items-center gap-x-1.5">
          <Icon name="Export" className="flex-shrink-0 text-info" />
          <Typography variant="paragraph">Upload Certificate</Typography>
        </div>
      );
    case "documentIdentifier":
      return (
        <div className="flex flex-row items-center gap-x-1.5">
          <Icon name="ArchiveBook" className="flex-shrink-0 text-primary" />
          <Typography variant="paragraph">Registration no. Updated</Typography>
        </div>
      );
    case "status":
      switch (value) {
        case "IN_REVIEW":
          return (
            <div className="flex flex-row items-center gap-x-1.5">
              <Icon name="InfoCircle" className="flex-shrink-0 rotate-180 text-info" />
              <Typography variant="paragraph">In Review</Typography>
            </div>
          );
        case "APPROVED":
          return (
            <div className="flex flex-row items-center gap-x-1.5">
              <Icon name="check" isCustom className="flex-shrink-0 text-success" />
              <Typography variant="paragraph">Approved</Typography>
            </div>
          );
        case "DENIED":
          return (
            <div className="flex flex-row items-center gap-x-1.5">
              <Icon name="close" isCustom className="flex-shrink-0 text-danger" />
              <Typography variant="paragraph">Denied</Typography>
            </div>
          );
        default:
          return null;
      }
    default:
      return null;
  }
};

export const generateColumns = () => {
  const columns: DataTableColumnDef<AccreditationsTableData>[] = [
    { accessorKey: "state", header: "State", enableSorting: false },
    { accessorKey: "alert", header: "", size: 180, cell: (props) => props.row.original.alert, enableSorting: false },
    { accessorKey: "regNo", header: "Registration no.", enableSorting: false },
    {
      accessorKey: "regCert",
      header: "Registration Certificate",
      cell: (props) => props.row.original.regCert,
      enableSorting: false,
    },
    {
      accessorKey: "expiryDate",
      header: "Expiry / Renewal Date",
      cell: (props) => props.row.original.expiryDate,
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "RideMinder Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateData = (
  items: AccreditationItem[],
  dateFormat: string,
  handleUpdate: (item: AccreditationItem) => void,
  handleViewHistory: (id: string) => void,
  canManage: boolean,
  handleApprove: (id: string) => void,
  handleDeny: (id: string) => void,
  handleRemove: (item: AccreditationItem) => void
) => {
  const data = items.map(
    (i) =>
      ({
        state: i.stateAbbrev,
        alert: renderAlert(i),
        regNo: i.regNo,
        regCert: renderRegCert(i.regCertName, i.id, i.requiresCertificate),
        expiryDate: i.expiryDate && format(parseISO(i.expiryDate), dateFormat),
        status: renderStatus(i.status),
        action: (
          <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
            <DropdownMenu.Item onClick={() => handleUpdate(i)}>
              <Icon name="Edit2" size="sm" className="mr-2.5" />
              <Typography>Update</Typography>
              {!i.regCertName && i.requiresCertificate && <Icon className="ml-2 text-danger" name="InfoCircle" variant="Bold" size="md" />}
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => handleViewHistory(i.id)}>
              <Icon name="Clock" size="sm" className="mr-2.5" />
              <Typography>View History Logs</Typography>
            </DropdownMenu.Item>
            {canManage && (
              <>
                <DropdownMenu.Item onClick={() => handleApprove(i.id)}>
                  <Icon name="TickCircle" variant="Bold" size="sm" className="mr-2.5 text-success" />
                  <Typography>Approve Accreditation</Typography>
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={() => handleDeny(i.id)}>
                  <Icon name="CloseCircle" variant="Bold" size="sm" className="mr-2.5 text-danger" />
                  <Typography>Deny Accreditation</Typography>
                </DropdownMenu.Item>
              </>
            )}
            <DropdownMenu.Item onClick={() => handleRemove(i)}>
              <Icon name="Trash" size="sm" className="mr-2.5 text-danger" />
              <Typography>Remove State</Typography>
            </DropdownMenu.Item>
          </DropdownMenu>
        ),
      } as AccreditationsTableData)
  );

  return data;
};

export const generateHistoryColumns = () => {
  const columns: DataTableColumnDef<AccreditationHistoryTableData>[] = [
    {
      accessorKey: "date",
      header: "Date",
      cell: (props) => props.row.original.date,
      enableSorting: false,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
    },
    { accessorKey: "action", header: "Action", cell: (props) => props.row.original.action, enableSorting: false },
    { accessorKey: "user", header: "User", cell: (props) => props.row.original.user, enableSorting: false },
  ];

  return columns;
};

export const generateHistoryData = (items: AccreditationHistoryItem[], dateFormat: string, timeFormat: string) => {
  const data = items.map(
    (i) =>
      ({
        date: format(parseISO(i.created), dateFormat),
        time: format(parseISO(i.created), timeFormat),
        action: renderHistoryAction(i.field, i.value),
        user: i.account,
      } as AccreditationHistoryTableData)
  );

  return data;
};
