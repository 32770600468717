import cx from "classnames";

export type SkeletonVariant = "short" | "standard";

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: SkeletonVariant;
}

export const Skeleton = ({ variant, className, ...props }: SkeletonProps) => {
  return (
    <div role="status" className={cx("mt-5 w-full animate-pulse", className)} {...props}>
      <div className="flex gap-5">
        <div className="w-1/2">
          <div className="mb-3.5 h-3.5 max-w-[640px] rounded-2xl bg-gray-300"></div>
          <div className="h-3.5  max-w-[540px] rounded-2xl bg-gray-300"></div>
          <div className="mt-4  flex">
            <div className="mr-3 h-3.5 w-20 rounded-2xl bg-gray-200"></div>
            <div className="h-3.5 w-24 rounded-2xl bg-gray-200"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
        <div className="flex w-1/2 flex-col items-end">
          <div className="mb-3.5 h-3.5 w-full max-w-[200px] rounded-2xl bg-gray-300"></div>
          <div className="h-3.5 w-full  max-w-[300px] rounded-2xl bg-gray-300"></div>
        </div>
      </div>
      {variant !== "short" && (
        <div
          role="status"
          className="mt-12 animate-pulse space-y-4 divide-y divide-gray-200 rounded border border-gray-200 p-4 shadow md:p-6 "
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="mb-2.5 h-2.5 w-24  rounded-md bg-gray-300"></div>
              <div className="h-2 w-32 rounded-md bg-gray-200"></div>
            </div>
            <div className="h-2.5 w-12 rounded-md  bg-gray-300"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="mb-2.5 h-2.5 w-24  rounded-md bg-gray-300"></div>
              <div className="h-2 w-32 rounded-md bg-gray-200"></div>
            </div>
            <div className="h-2.5 w-12 rounded-md  bg-gray-300"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="mb-2.5 h-2.5 w-24  rounded-md bg-gray-300"></div>
              <div className="h-2 w-32 rounded-md bg-gray-200"></div>
            </div>
            <div className="h-2.5 w-12 rounded-md  bg-gray-300"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="mb-2.5 h-2.5 w-24  rounded-md bg-gray-300"></div>
              <div className="h-2 w-32 rounded-md bg-gray-200"></div>
            </div>
            <div className="h-2.5 w-12 rounded-md  bg-gray-300"></div>
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="mb-2.5 h-2.5 w-24  rounded-md bg-gray-300"></div>
              <div className="h-2 w-32 rounded-md bg-gray-200"></div>
            </div>
            <div className="h-2.5 w-12 rounded-md  bg-gray-300"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
};
