import { clsx } from "@/utils";

export const CompaniesAttachedSkeleton = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const renderSkeletonItem = () => {
    return (
      <div className="flex flex-row items-center rounded-2xl bg-gray-100 py-5 pl-5 pr-2.5">
        <div className="flex flex-row items-center gap-x-4">
          <div className="h-10 w-10 rounded-full bg-gray-200" />
          <div className="h-6 w-24 rounded-2xl bg-gray-200" />
        </div>
        <div className="flex flex-1 items-center justify-end">
          <div className="h-[34px] w-[153px] rounded-md bg-gray-200" />
        </div>
      </div>
    );
  };

  const skeletonItems = Array.from({ length: 4 }, (_, x: number) => <div key={x}>{renderSkeletonItem()}</div>);

  return (
    <div className={clsx("animate-pulse", className)} {...props}>
      {skeletonItems}
    </div>
  );
};
