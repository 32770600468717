import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Loading, Panel, Typography } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getErrorMessages, getErrorMessagesObject } from "@/helpers/reduxHelpers";
import { useOperator, useSiteConfig } from "@/hooks";
import { useRegisterDriverMutation } from "@/redux/apis/auth/authApi";
import { addToast, clsx } from "@/utils";
import { OperatorEmblem, SignUpFormDriverStep1, SignUpFormDriverStep2 } from "../contents";
import { useSignUpContext } from "../context";
import { signUpDriverFormSchema } from "./schemas";
import { SignUpDriverFormData, signUpFormDataKey } from "./types";

export const SignUpFormDriver = () => {
  const navigate = useNavigate();
  const { apiUrl } = useSiteConfig();
  const {
    name,
    images: { emblem },
    providerCode,
    singleTenant,
    availableAccountTypesOnRegister,
  } = useOperator();
  const { setStep, formStep, setFormStep } = useSignUpContext();
  const [registerDriver, { isLoading }] = useRegisterDriverMutation();
  const [token, setToken] = useState<null | string>();
  const methods = useForm<SignUpDriverFormData>({ resolver: zodResolver(signUpDriverFormSchema) });
  const {
    handleSubmit,
    trigger,
    setError,
    formState: { isDirty },
  } = methods;

  const onBackClick = () => {
    if (formStep < 2) {
      if (singleTenant) {
        if (availableAccountTypesOnRegister.length > 1) setStep("user-select");
        else navigate(`/login/${providerCode}`);
      } else setStep("provider-select");
    } else setFormStep(formStep - 1);
  };

  const onNextClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const valid = await trigger(["firstName", "lastName", "email", "contactNumber", "dateOfBirth", "password"], {
      shouldFocus: true,
    });

    if (valid) setFormStep(formStep + 1);
  };

  const onSubmit = async (data: SignUpDriverFormData) => {
    if (!data) return;

    if (data && token) {
      registerDriver({
        apiUrl,
        provider_code: providerCode,
        registered_device: "browser",
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        mobile: data.contactNumber,
        date_of_birth: format(data.dateOfBirth, "yyyy-MM-dd"),
        password: data.password.password,
        state_registered: data.stateRegistered,
        driver_licence: data.driverLicense,
        vehicle: data.vehicle
          ? {
              model_uuid: data.vehicle.makeAndModel.id,
              color: data.vehicle.color,
              year: data.vehicle.year,
            }
          : undefined,
      })
        .unwrap()
        .then(() => setStep("success"))
        .catch((e) => {
          const errors = getErrorMessagesObject(e);
          for (const [key, value] of Object.entries(errors)) {
            if (signUpFormDataKey[key]) setError(signUpFormDataKey[key] as keyof SignUpDriverFormData, { type: "custom", message: value });
          }
          getErrorMessages(e).forEach((m) => addToast("danger", m));
        });
    }
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (formStep < 2) onNextClick(event);
  };

  const renderButtons = () => (
    <div className="mt-6 flex flex-col gap-4 md:flex-row md:justify-end">
      <div className="hidden md:block">
        <Button variant="secondary" startIcon="ArrowLeft" size="lg" onClick={onBackClick}>
          Back
        </Button>
      </div>
      {formStep < 2 ? (
        <Button variant="primary" type="submit" endIcon="ArrowRight" size="lg" className="w-full md:w-auto" disabled={!isDirty}>
          Next
        </Button>
      ) : (
        <Button
          variant="primary"
          type="submit"
          endIcon="ArrowRight"
          className="relative w-full md:w-auto"
          disabled={!isDirty || isLoading || !token}
          size="lg"
        >
          {isLoading && <Loading className="!bg-transparent" />}
          Sign up
        </Button>
      )}
      <Button variant="secondary" startIcon="ArrowLeft" size="lg" onClick={onBackClick} className="w-full md:hidden">
        Back
      </Button>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <form id="sign-up-driver-form" className="w-full max-w-[680px]" onSubmit={formStep === 2 ? handleSubmit(onSubmit) : handleOnSubmit}>
        <Panel>
          <div className="mb-7 flex flex-row items-center">
            <div className="flex flex-1 flex-col gap-y-1.5">
              <Typography variant="h1">{`Sign up with ${name}`}</Typography>
              <Typography variant="paragraph">{`Become a driver of ${name}`}</Typography>
            </div>
            <OperatorEmblem name={name} emblem={emblem} size="sm" />
          </div>
          <StepIndicator currentStep={formStep} numSteps={2} className="pointer-events-none my-8" />
          <SignUpFormDriverStep1 className={clsx({ hidden: formStep !== 1 })} />
          <SignUpFormDriverStep2 onRecaptchaChange={(token) => setToken(token)} className={clsx({ hidden: formStep !== 2 })} />
        </Panel>
        {renderButtons()}
      </form>
    </FormProvider>
  );
};
