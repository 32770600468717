import { useDebouncedEffect } from "@react-hookz/web";
import { equals } from "ramda";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Divider, Icon, IconButton, Loading, Typography, SidePanel } from "@/components/atoms";
import { usePagination } from "@/hooks/usePagination";
import { useGetClientOptionsQuery } from "@/redux/apis/client/clientApi";
import { useLazySearchCompanyQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { useAppSelector } from "@/redux/hooks";
import { clientOptionsSelector } from "@/redux/slices/client/selectors";
import { Filter } from "@/redux/types";
import { AccountStatusFilter } from "./filters/AccountStatusFilter";
import { AccountTypeFilter } from "./filters/AccountTypeFilter";
import { SearchEmailFilter } from "./filters/SearchEmailFilter";
import { SearchPhoneNumberFilter } from "./filters/SearchPhoneNumberFilter";
import { SelectCompanyFilter } from "./filters/SelectCompanyFilter";
import { ClientsFilterFormData } from "./types";

interface ClientsFilterPanelProps {
  open: boolean;
  onClose: () => void;
  filter: Filter;
  setFilter: (filter: Filter) => void;
}

export const ClientsFilterPanel = (clientsFilterPanelProps: ClientsFilterPanelProps) => {
  const { open, onClose, filter, setFilter } = clientsFilterPanelProps;
  const { clearPagination } = usePagination();

  const { isFetching: isFetchingOptions } = useGetClientOptionsQuery();
  const options = useAppSelector(clientOptionsSelector);

  const [query, setQuery] = useState("");
  const [searchCompany, { isFetching: isFetchingCompanies, data: companies }] = useLazySearchCompanyQuery();

  const defaultValues = {
    company: "",
    email: "",
    phoneNumber: "",
    accountType: [],
    accountStatus: [],
  };
  const methods = useForm<ClientsFilterFormData>({ defaultValues });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useDebouncedEffect(
    () => {
      if (query.length > 2) searchCompany({ q: query });
    },
    [query, searchCompany],
    500
  );

  const handleOnClose = (data?: ClientsFilterFormData) => {
    if (isDirty && data) reset(data);
    onClose();
  };

  const onSubmit = handleSubmit((data) => {
    const { company, email, phoneNumber, accountType, accountStatus } = data;
    const filterData: Filter = {};

    if (company) filterData.company_uuid = company;
    if (email) filterData.email = email;
    if (phoneNumber) filterData.phone = phoneNumber;
    accountType.map((type, i) => (filterData[`account_type[${i.toString()}]`] = type));
    accountStatus.map((status, i) => (filterData[`account_status[${i.toString()}]`] = status));

    if (!equals(filterData, filter)) {
      clearPagination();
      setFilter(filterData);
    }

    handleOnClose(data);
  });

  return (
    <SidePanel open={open} onClose={() => handleOnClose()} focused>
      <div className="flex items-center justify-between p-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="Sort" size="lg" />
          <Typography variant="title">Filter</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={() => handleOnClose()} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="relative flex h-full flex-col" onSubmit={onSubmit}>
          {isFetchingOptions && <Loading />}
          <div className="h-full flex-1 overflow-y-auto">
            <div className="flex flex-col gap-y-4 px-4 py-6">
              <SelectCompanyFilter setQuery={setQuery} companies={companies} isFetching={isFetchingCompanies} />
              <SearchEmailFilter />
              <SearchPhoneNumberFilter />
              <AccountTypeFilter options={options.accountType} />
              <AccountStatusFilter options={options.status} />
            </div>
          </div>
          <div>
            <Divider />
            <div className="flex flex-col gap-3 px-4 py-5">
              <Button type="submit" className="w-full text-center" size="lg">
                Filter Results
              </Button>
              <Button className="w-full text-center" size="lg" variant="secondary" onClick={() => reset(defaultValues)}>
                Reset Filters
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </SidePanel>
  );
};
