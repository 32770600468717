import { pricingApi } from "../pricingApi";
import { transformAsapPricing, transformStandardPricing } from "./helpers";
import { GetAsapPricingResponse, GetStandardPricingResponse, UpdateAsapPricingParams, UpdateStandardPricingParams } from "./types";

export const cancellationFeesApi = pricingApi.enhanceEndpoints({ addTagTypes: ["StandardPricing", "AsapPricing"] }).injectEndpoints({
  endpoints: (builder) => ({
    getAsapPricing: builder.query<GetAsapPricingResponse, void>({
      query: () => "/pricing/asap",
      transformResponse: transformAsapPricing,
      providesTags: ["AsapPricing"],
    }),
    updateAsapPricing: builder.mutation<void, UpdateAsapPricingParams>({
      query: (body) => ({
        url: "/pricing/asap",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["AsapPricing"],
    }),
    getStandardPricing: builder.query<GetStandardPricingResponse, void>({
      query: () => "/pricing/cancellation-fees",
      transformResponse: transformStandardPricing,
      providesTags: ["StandardPricing"],
    }),
    updateStandardPricing: builder.mutation<void, UpdateStandardPricingParams>({
      query: (body) => ({
        url: "/pricing/cancellation-fees",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["StandardPricing"],
    }),
  }),
});

export const { useGetAsapPricingQuery, useUpdateAsapPricingMutation, useGetStandardPricingQuery, useUpdateStandardPricingMutation } =
  cancellationFeesApi;
