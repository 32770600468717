import { Typography, Modal, SearchInput, Loading } from "@/components/atoms";
import { EmptyState, NoSearchResults } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { useLazySearchClientQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { isEmpty } from "ramda";
import { useState } from "react";
import GlassesSearching from "@/assets/images/search/glasses-searching.svg";
import { clsx } from "@/utils";

interface HeaderSearchModalProps {
  open: boolean;
  onClose: () => void;
}

export const HeaderSearchModal = ({ open, onClose }: HeaderSearchModalProps) => {
  const [searchClient, { isFetching, data }] = useLazySearchClientQuery();
  const [isFirstSearch, setIsFirstSearch] = useState(true);

  const handleClose = () => {
    onClose();
    setIsFirstSearch(true);
  };

  const handleSearch = (value?: string) => {
    const query = value ?? "";
    if (isFirstSearch && query) setIsFirstSearch(false);

    searchClient({ q: query, page_size: 12 });
  };

  const handleSelect = (id: string) => {
    const redirectUrl = `${getPhpHostUrl()}/o/context/switch?redirection=/passenger/${id}/booking/history`;
    window.location.href = redirectUrl;
  };

  const renderResults = () => {
    if (isFirstSearch) {
      return (
        <EmptyState title="" description="" image={GlassesSearching} className="-mt-20">
          <div className="text-center -mt-20">
            <Typography variant="action" className="mb-1 block">
              Looking for something?
            </Typography>
            <Typography className="max-w-[400px] text-neutral-dark-gray">Type a keyword and we&apos;ll search through clients</Typography>
          </div>
        </EmptyState>
      );
    } else {
      if (data && !isEmpty(data)) {
        return (
          data.map(client => (
            <div key={client.id} className="flex flex-row w-full gap-x-3 px-5 py-3.5 border-b border-b-neutral-mid-gray cursor-pointer" onClick={() => handleSelect(client.id)}>
              <div className="flex-shrink-0 mt-1">
                <img
                  className="h-[24px] w-[24px] rounded-full"
                  src={`https://via.placeholder.com/120x120/cccccc/969696?text=${client.name[0].toLocaleUpperCase()}`}
                  alt={client.name}
                />
              </div>
              <div className="flex flex-1 flex-col overflow-hidden">
                <Typography className="text-neutral-black">{client.name}</Typography>
                <Typography className="text-neutral-dark-gray truncate" variant="small">
                  {client.email}
                </Typography>
                <Typography className="text-neutral-dark-gray" variant="small">
                  {client.phone}
                </Typography>
              </div>
            </div>
          ))
        );
      } else return (<NoSearchResults />);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className="h-[65vh] w-[85vw] md:w-[550px] lg:w-[550px] !bg-neutral-dark-gray !p-2">
      <div className="flex flex-col h-full gap-1">
        <div className="flex">
          <SearchInput
            isLoading={isFetching}
            onSearch={handleSearch}
            placeholder="Search client"
            className="w-full"
          />
        </div>
        <div className={clsx("relative flex flex-1 flex-col items-center overflow-auto rounded-lg bg-white", { "justify-center": isFirstSearch || !data || isEmpty(data) })}>
          {isFetching && <Loading />}
          {renderResults()}
        </div>
      </div>
    </Modal>
  );
};