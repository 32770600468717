import { flatten } from "ramda";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Skeleton } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useGetExternalSuppliersQuery } from "@/redux/apis/externalSupplier/externalSupplierApi";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersSelector } from "@/redux/slices/externalSupplier/selectors";
import { ExternalSuppliersHeader } from "./ExternalSuppliersHeader";
import { ExternalSuppliersTable } from "./ExternalSuppliersTable";
import { useGetExternalSuppliersParams } from "./hooks/useGetExternalSuppliersParams";

export const ExternalSuppliersPanel = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const externalSuppliers = useAppSelector(externalSuppliersSelector);
  const { params, search, filters, sorting, setSorting } = useGetExternalSuppliersParams();
  const { isFetching } = useGetExternalSuppliersQuery(params, { refetchOnMountOrArgChange: true });

  const isSearchActive = Boolean(search?.length);
  const numActiveFilters = flatten(Object.values(filters).filter(Boolean)).length;

  return (
    <>
      <Panel {...props}>
        <ExternalSuppliersHeader />
        {externalSuppliers.length < 1 && !isSearchActive && numActiveFilters < 1 && !isFetching ? (
          <EmptyState
            title="Manage External Suppliers"
            description="It looks like you haven't added any external suppliers yet. Begin by adding your first external supplier to get started"
          >
            <Button onClick={() => navigate("add")} startIcon="Add">
              Add External Supplier
            </Button>
          </EmptyState>
        ) : (
          <>
            {isFetching ? (
              <Skeleton />
            ) : (
              <ExternalSuppliersTable
                items={externalSuppliers}
                sorting={sorting}
                setSorting={setSorting}
                isSearchActive={isSearchActive}
                className="mt-4"
              />
            )}
          </>
        )}
      </Panel>
      {!isFetching && <ExternalSuppliersTable.Pagination />}
    </>
  );
};
