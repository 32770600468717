import { Button, Modal, Typography } from "@/components/atoms";

interface CompleteAllRequiredFieldsModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose: () => void;
}

export const CompleteAllRequiredFieldsModal = ({ open, onClose, ...props }: CompleteAllRequiredFieldsModalProps) => {
  return (
    <Modal open={open} onClose={onClose} className="max-w-[514px] !p-5">
      <div className="relative" {...props}>
        <div className="max-w-lg sm:flex sm:items-start ">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <Typography variant="title">Complete All Required Fields</Typography>
            <div className="mt-3 ">
              <Typography variant="paragraph">
                All required fields must be filled out to verify the vehicle. Please complete any missing information before proceeding.
              </Typography>
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
