import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { BlockDriverResponse, BlockDriverParams, UnblockDriverResponse, UnblockDriverParams } from "./types";

export const blockApi = createApi({
  reducerPath: "blockApi",
  baseQuery: baseQueryWithProvider("block"),
  endpoints: (builder) => ({
    blockDriver: builder.mutation<BlockDriverResponse, BlockDriverParams>({
      query: ({ id, comment }) => ({
        url: "",
        method: "POST",
        body: {
          uuid: id,
          category: "PROVIDER_TO_ACCOUNT",
          comment,
        },
      }),
    }),
    unblockDriver: builder.mutation<UnblockDriverResponse, UnblockDriverParams>({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useBlockDriverMutation, useUnblockDriverMutation } = blockApi;
