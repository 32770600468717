import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAuth } from "@/redux/baseQuery";
import { SiteInfo } from "@/redux/slices/config/types";
import { transformSiteInfo, transformStates } from "./helpers";
import { GetCountryStatesResponse } from "./types";

export const resourceApi = createApi({
  reducerPath: "resourceApi",
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getCountryStates: builder.query<GetCountryStatesResponse, string>({
      query: (countryCode) => ({
        url: `/v3/country/${countryCode}/state`,
      }),
      transformResponse: transformStates,
    }),
    getSiteInfo: builder.query<SiteInfo, string>({
      query: (domain) => ({
        url: `/v3/domain/${domain}`,
      }),
      transformResponse: transformSiteInfo,
    }),
  }),
  tagTypes: ["Resource"],
});

export const { useGetCountryStatesQuery, useLazyGetCountryStatesQuery, useGetSiteInfoQuery, useLazyGetSiteInfoQuery } = resourceApi;
