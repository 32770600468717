import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAccount } from "@/redux/baseQuery";
import { GetPaymentProviderKeysRawResponse, GetPaymentMethodRawResponse } from "./types";

export const paymentMethodApi = createApi({
  reducerPath: "paymentMethodApi",
  baseQuery: baseQueryWithAccount("payment-method"),
  endpoints: (builder) => ({
    getPaymentProviderKeys: builder.query<GetPaymentProviderKeysRawResponse, void>({
      query: () => "/keys/stripe?label=personal",
    }),
    getPaymentMethod: builder.query<GetPaymentMethodRawResponse, string>({
      query: (id) => `/${id}`,
    }),
  }),
});

export const { useGetPaymentProviderKeysQuery, useLazyGetPaymentMethodQuery } = paymentMethodApi;
