import { FieldErrors, useFormContext } from "react-hook-form";
import { ErrorMessage, PasswordInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpClientFormData, SignUpDriverFormData, SignUpInviteFormData, SignUpOperatorFormData } from "../types";

export const PasswordField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SignUpDriverFormData | SignUpOperatorFormData | SignUpClientFormData | SignUpInviteFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Password</Typography>
      <PasswordInput
        className="w-full"
        hasError={!!errors.password?.password}
        placeholder="Enter Password"
        autoComplete="new-password"
        maxLength={65}
        {...register("password.password")}
      />
      <Typography variant="small" className="text-neutral-dark-gray">
        Note: minimum of 6 characters
      </Typography>
      {errors.password?.password && <ErrorMessage errors={errors.password as FieldErrors} name="password" />}
    </div>
  );
};
