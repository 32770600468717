import { Button, Loading, Typography } from "@/components/atoms";
import { useUserAccountsContext } from "@/contexts/userAccounts";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRemoveAccessMutation } from "@/redux/apis/userAccount/userAccountApi";
import { addToast } from "@/utils";

export const UserAccountsRemoveAccess = () => {
  const { setStep, selectedUserAccount: user } = useUserAccountsContext();
  const [removeAccess, { isLoading }] = useRemoveAccessMutation();

  const description = `Are you sure you want to remove ${user?.name}’s access from RideMinder?`;

  const handleRemoveAccessClick = () => {
    if (!user) return;

    removeAccess(user.id)
      .unwrap()
      .then(() => {
        addToast("success", `Removed access for ${user?.name}`);
        setStep(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <div className="relative">
      {isLoading && <Loading />}
      <div className="max-w-lg sm:flex sm:items-start ">
        <div className="mt-3 text-center sm:mt-0 sm:text-left">
          <Typography variant="title">Remove Access</Typography>
          <div className="mt-3">
            <Typography className="text-neutral-dark-gray">{description}</Typography>
          </div>
        </div>
      </div>
      <div className="mt-6 flex justify-end gap-4">
        <Button variant="secondary" onClick={() => setStep(undefined)} size="sm" className="px-8">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleRemoveAccessClick} size="sm" className="bg-danger px-8 hover:bg-danger-dark">
          Remove Access
        </Button>
      </div>
    </div>
  );
};
