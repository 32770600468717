import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Container } from "@/components/atoms";
import { GeneralFooter, GeneralHeader } from "@/components/organisms";
import { useLayout } from "@/hooks";
import { Restricted } from "../Restricted/Restricted";
import { SignUpSuccessClient, SignUpSuccessInvite, SignUpSuccessOperator } from "@/components/organisms/SignUp/contents";

export const ProfileSignUpSuccess = () => {
  const [params] = useSearchParams();
  const paramsUserType = params.get("type");
  const { setFullScreen } = useLayout();
  const userTypes = ["client", "operator", "invite"];

  useEffect(() => {
    setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSuccess = (userType: string) => {
    switch (userType) {
      case "operator": return <SignUpSuccessOperator />;
      case "invite": return <SignUpSuccessInvite />;
      default: return <SignUpSuccessClient />
    }
  };

  const getAction = () => {
    if (paramsUserType && paramsUserType === "operator") return "contact";
    return undefined;
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader action={getAction()} />
        {!paramsUserType || !userTypes.includes(paramsUserType) ? <Restricted /> : (
          <Container className="flex flex-1 justify-center w-full max-w-[1040px] gap-5 py-8 md:py-[100px] px-5">
            {renderSuccess(paramsUserType)}
          </Container>
        )}
        <GeneralFooter />
      </div>
    </div>
  );
};
