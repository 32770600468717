import { toast } from "react-hot-toast";
import { Badge, Notification, Typography } from "@/components/atoms";
import { BookingStatus } from "@/redux/slices/booking/types";
import { NewBookingNotification } from "./common/UpdatedBookingNotification/UpdatedBookingNotification";
import { statusMapping } from "./fixtures";

export const addBookingNotification = (title: string, description: string, status: BookingStatus) => {
  const renderBadge = (status: BookingStatus) => {
    switch (status) {
      case "awaiting_driver_allocation":
        return <Badge message={statusMapping["awaiting_driver_allocation"].legend} type="danger" size="sm" />;
      case "driver_allocated_not_confirmed":
        return <Badge message={statusMapping["driver_allocated_not_confirmed"].legend} type="warning" size="sm" />;
      case "driver_allocated_confirmed":
        return <Badge message={statusMapping["driver_allocated_confirmed"].legend} type="success" size="sm" />;
      case "on_way_to_job":
        return (
          <Badge type="info" message={statusMapping["on_way_to_job"].legend} className="bg-booking-dow/10 text-booking-dow" size="sm" />
        );

      default:
        return null;
    }
  };

  toast.custom(
    (t) => (
      <Notification type="info" visible={t.visible} title={title} onClose={() => toast.dismiss(t.id)}>
        <Typography className="mt-1 flex items-center gap-2 !leading-normal">
          {renderBadge(status)}
          {description}
        </Typography>
      </Notification>
    ),
    { position: "top-right", duration: 20000 }
  );
};

export const addNewBookingNotification = (message: string, callback: () => void) => {
  toast.custom(
    (t) => (
      <NewBookingNotification
        visible={t.visible}
        onClose={() => {
          callback();
          toast.dismiss(t.id);
        }}
      >
        {message}
      </NewBookingNotification>
    ),
    { position: "top-center", duration: 60000 }
  );
};
