import { isEmpty } from "ramda";
// import { NavigateFunction } from "react-router-dom";
import { Alert } from "@/components/atoms";

// type Pages = {
//   name: string;
//   to: string;
//   current: boolean;
// }[];

export const renderErrors = (errors: string[]) => {
  return !isEmpty(errors) ? (
    <div className="mt-4 space-y-3">
      {errors.map((error, i) => (
        <Alert key={i} type="danger" message={error} className="w-full" />
      ))}
    </div>
  ) : null;
};

// export const navigateToViewPage = (navigate: NavigateFunction, pages: Pages, depth: number) => {
//   navigate(pages[depth - 1].to);
// };
