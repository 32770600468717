import cx from "classnames";
import { forwardRef } from "react";
import { slugify } from "@/utils";
import { Icon } from "../";

export interface PDFCheckboxInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  img: string;
  price: number;
  onPreviewClick: () => void;
}

export const PDFCheckboxInput = forwardRef<HTMLInputElement, PDFCheckboxInputProps>((props, ref) => {
  const { className, img, checked, label, price, onPreviewClick, ...rest } = props;

  return (
    <div
      className={cx("relative inline-flex rounded-lg border align-top transition-colors ease-linear ", className, {
        "border-neutral-surface-gray bg-neutral-surface-gray": !checked,
        "border-primary-dark bg-primary-light": checked,
      })}
    >
      <Icon
        name="Eye"
        variant="Bold"
        size="md"
        className="absolute left-3 top-3 text-neutral-dark-gray hover:opacity-50"
        onClick={onPreviewClick}
      />
      <label
        htmlFor={`pdf-checkbox-${slugify(label)}`}
        className="flex min-h-[144px] min-w-[144px] cursor-pointer flex-col items-center justify-around p-4"
      >
        <div className="mx-[30%]">
          <img src={img} alt={label} className="max-w-full border border-black" />
        </div>
        <input ref={ref} id={`pdf-checkbox-${slugify(label)}`} type="checkbox" className="invisible" {...rest} />
        <span className="select-none text-center text-sm font-medium">{label}</span>
        <span className="select-none text-center text-sm font-medium leading-8 text-success">${price}</span>
      </label>
    </div>
  );
});

PDFCheckboxInput.displayName = "PDFCheckboxInput";
