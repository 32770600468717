import { FieldErrors, useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpOperatorFormData } from "../types";

export const BusinessRegistrationIdNumberField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();
  const businessRegistrationType = watch("businessRegistration.businessRegistrationType");

  return (
    <div className={clsx("flex flex-col gap-1", { hidden: !businessRegistrationType }, className)} {...props}>
      <Typography>{businessRegistrationType} ID Number</Typography>
      <TextInput
        className="w-full"
        placeholder={businessRegistrationType === "ACN" ? "000 000 000" : "00 000 000 000"}
        hasError={!!errors.businessRegistration?.businessRegistrationIdNumber}
        {...register("businessRegistration.businessRegistrationIdNumber")}
      />
      <Typography variant="small" className="text-neutral-dark-gray">
        {`Valid ${businessRegistrationType} format: ${businessRegistrationType === "ACN" ? "9" : "11"} numerical digits only`}
      </Typography>
      {errors.businessRegistration?.businessRegistrationIdNumber && <ErrorMessage errors={errors.businessRegistration as FieldErrors} name="businessRegistrationIdNumber" />}
    </div>
  );
};
