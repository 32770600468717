import type { DraggableAttributes, DraggableSyntheticListeners, UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { createContext, useMemo } from "react";
import type { CSSProperties, PropsWithChildren } from "react";

interface SortableItemProps {
  id: UniqueIdentifier;
}

interface ISortableItemContext {
  attributes: DraggableAttributes;
  listeners: DraggableSyntheticListeners;
  ref: (node: HTMLElement | null) => void;
}

export const SortableItemContext = createContext<ISortableItemContext>({} as ISortableItemContext);

export const SortableItem = ({ children, id }: PropsWithChildren<SortableItemProps>) => {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <li ref={setNodeRef} style={style} className="list-none">
        {children}
      </li>
    </SortableItemContext.Provider>
  );
};
