import { useNavigate } from "react-router-dom";
import { Icon, Map, Typography } from "@/components/atoms";
import { FixedPricingLocation } from "@/redux/slices/pricing/types";

interface CoverageAreaProps extends React.HTMLAttributes<HTMLDivElement> {
  pricing: FixedPricingLocation;
}

export const CoverageArea = ({ pricing, className, ...props }: CoverageAreaProps) => {
  const navigate = useNavigate();
  const { pickupLocation } = pricing;

  return (
    <div className={className} {...props}>
      <div className="flex flex-col items-center">
        <div className="flex w-full flex-row items-center">
          <Typography variant="h3" className="flex flex-1 leading-8">
            Coverage Area
          </Typography>
          <Icon name="Edit2" className="cursor-pointer text-primary" onClick={() => navigate("./edit/coverage-area")} />
        </div>
        <Typography className="text-neutral-dark-gray">
          This represents the coverage area range to which this pricing will be applied.
        </Typography>
      </div>
      <div className="mt-4 flex h-[303px] w-full">
        <Map center={{ lat: pickupLocation.latitude, lng: pickupLocation.longitude }} circleRadius={pickupLocation.radius * 1000} />
      </div>
      <Typography variant="paragraph" className="mt-4">
        {pickupLocation.address}
      </Typography>
      <Typography variant="paragraph" className="mt-4 text-info">{`Within ${pickupLocation.radius} km`}</Typography>
    </div>
  );
};
