import { format, parseISO } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, PDFViewer, Panel, Typography, Skeleton } from "@/components/atoms";
import { PageHeader } from "@/components/organisms";
import { useGetTermsAndConditionsQuery } from "@/redux/apis/config/termsAndConditions";
import { documentsSelector } from "@/redux/slices/documents/selectors";
import { useAppSelector } from "@/redux/hooks";

export const TermsAndConditionsHistory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isFetching } = useGetTermsAndConditionsQuery();
  const { termsAndConditions } = useAppSelector(documentsSelector);
  const pastTerms = termsAndConditions.find(i => i.id === (id || ""));

  const handleBackClick = () => navigate(-1);

  if (isFetching || !pastTerms) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <Alert
        type="info"
        message={
          <span>
            Click{" "}
            <Link to=".." relative="path" className="underline">
              here
            </Link>{" "}
            to view the updated Terms and Conditions
          </span>
        }
        className="mb-3"
      />
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3">
              Terms and Conditions - {format(parseISO(pastTerms.createdAt), "LLLL dd, yyyy")}
            </Typography>
          </div>
          <div className="flex">
            <Button variant="tertiary" className="ml-2 text-info" onClick={handleBackClick}>
              Go to Current T&C
            </Button>
          </div>
        </div>
        <div className="min-h-[500px]">
          <PDFViewer src={pastTerms.url} className="mt-4 min-h-[70vh] w-full" />
        </div>
      </Panel>
    </>
  );
};
