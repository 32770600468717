import { useIntervalEffect } from "@react-hookz/web";
import { useGetVehicleDocumentQuery } from "@/redux/apis/vehicle/vehicleApi";
import { useEditVehicleContext } from "../context";
import { getErrorMessage, getIsManualExpiryDate } from "../helpers";

interface IVehicleDocuments {
  vehicleId: string;
  insuranceId?: string;
  accreditationId?: string;
}

export const useVehicleDocuments = ({ vehicleId, insuranceId, accreditationId }: IVehicleDocuments) => {
  const { documentRetryCount, setDocumentRetryCount } = useEditVehicleContext();
  const {
    data: insuranceDoc,
    isFetching: isLoadingInsurance,
    refetch: refetchInsurance,
  } = useGetVehicleDocumentQuery(
    {
      vehicleId,
      vehicleDocumentId: insuranceId || "",
    },
    {
      skip: !insuranceId,
    }
  );

  const {
    data: accreditationDoc,
    isFetching: isLoadingAccreditation,
    refetch: refetchAccreditation,
  } = useGetVehicleDocumentQuery(
    {
      vehicleId,
      vehicleDocumentId: accreditationId || "",
    },
    {
      skip: !accreditationId,
    }
  );

  useIntervalEffect(() => {
    if (insuranceId && insuranceDoc?.approvalStatus === "PROCESSING" && documentRetryCount.insurance < 10) {
      refetchInsurance();
      setDocumentRetryCount((prev) => ({
        ...prev,
        insurance: prev.insurance + 1,
      }));
    }

    if (accreditationId && accreditationDoc?.approvalStatus === "PROCESSING" && documentRetryCount.accreditation < 10) {
      refetchAccreditation();
      setDocumentRetryCount((prev) => ({
        ...prev,
        accreditation: prev.accreditation + 1,
      }));
    }
  }, 5000);

  return {
    insurance: {
      document: insuranceId ? insuranceDoc : undefined,
      errorMessage: insuranceId && insuranceDoc ? getErrorMessage(insuranceDoc) : "",
      manualExpiryDate: insuranceId && insuranceDoc ? getIsManualExpiryDate(insuranceDoc) : false,
      isLoading: isLoadingInsurance,
    },
    accreditation: {
      document: accreditationId ? accreditationDoc : undefined,
      errorMessage: accreditationId && accreditationDoc ? getErrorMessage(accreditationDoc) : "",
      manualExpiryDate: accreditationId && accreditationDoc ? getIsManualExpiryDate(accreditationDoc) : false,
      isLoading: isLoadingAccreditation,
    },
    isLoading: isLoadingInsurance || isLoadingAccreditation,
    isProcessing: insuranceDoc?.approvalStatus === "PROCESSING" || accreditationDoc?.approvalStatus === "PROCESSING",
  };
};
