import { format } from "date-fns";
import { useCallback } from "react";
import { Button, Icon, IconButton, Typography } from "@/components/atoms";
import { DataTable, DataTableColumnDef, DataTableRow, DataTableRowProps } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { useAppDispatch } from "@/redux/hooks";
import { setPaymentAction } from "@/redux/slices/booking/bookingSlice";
import { BookingPaymentLog } from "@/redux/slices/booking/types";
import { formatCurrency } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { PaymentStatus } from "../PaymentStatus";
import { BookingPaymentData } from "../types";

const bookingPaymentLogsColumns: DataTableColumnDef<BookingPaymentData>[] = [
  {
    header: "",
    id: "arrow",
    cell: (props) =>
      props.row.original.history.length ? (
        props.row.getIsExpanded() ? (
          <IconButton
            onClick={() => props.row.toggleExpanded(false)}
            iconName="ArrowUp2"
            variant="tertiary"
            className="pointer-events-auto -mx-2 p-2"
          />
        ) : (
          <IconButton
            onClick={() => props.row.toggleExpanded(true)}
            iconName="ArrowDown2"
            variant="tertiary"
            className="pointer-events-auto -mx-2 p-2"
          />
        )
      ) : null,
    size: 40,
  },
  {
    header: "Date",
    cell: (props) => props.row.original.dateTime,
    size: 110,
    enableSorting: false,
  },
  {
    header: "Amount",
    cell: (props) => props.row.original.amount,
    size: 150,
    enableSorting: false,
  },
  {
    header: "Description",
    accessorKey: "description",
    enableSorting: false,
  },
  {
    header: "Status",
    cell: (props) => props.row.original.status,
    size: 160,
    enableSorting: false,
  },
  {
    header: " ",
    cell: (props) => props.row.original.actions,
    enableSorting: false,
    size: 80,
  },
];

export const PaymentLogsTable = ({ logs }: { logs: BookingPaymentLog[] }) => {
  const dispatch = useAppDispatch();
  const { dateFormat, fullTimeFormat } = useProfile();
  const {
    cost: { currency },
  } = useSelectedBooking();

  const data: BookingPaymentData[] = logs.map((log) => ({
    id: log.id,
    dateTime: (
      <div className="grid">
        <span className="leading-snug">{format(new Date(log.createdAt), dateFormat)}</span>
        <span className="leading-snug text-neutral-dark-gray">{format(new Date(log.createdAt), fullTimeFormat)}</span>
      </div>
    ),
    amount: (
      <div className="grid">
        <span className="leading-snug">{formatCurrency(log.amount, 2, currency)}</span>
        {log.balance && <span className="leading-snug text-neutral-dark-gray">Balance: {formatCurrency(log.balance, 2, currency)}</span>}
      </div>
    ),
    description: log.description,
    status: <PaymentStatus status={log.status} />,
    actions:
      log.isRefundable && log?.balance ? (
        <Button
          onClick={() => dispatch(setPaymentAction({ action: "process-refund", log }))}
          variant="custom"
          startIcon="Refresh2"
          className="!p-0 text-primary [&>.typography]:text-sm [&>.typography]:font-medium"
        >
          Refund
        </Button>
      ) : null,
    history: log.history,
  }));

  const getRowProps = useCallback((row: DataTableRow<BookingPaymentData>) => {
    const toggleRowExpand = (row: DataTableRow<BookingPaymentData>) => {
      row.toggleExpanded(!row.getIsExpanded());
    };

    const rowProps: Partial<DataTableRowProps<BookingPaymentData>> = {
      className: "hover:!bg-transparent",
      onClick: () => toggleRowExpand(row),
    };

    return rowProps;
  }, []);

  return (
    <DataTable
      data={data}
      columns={bookingPaymentLogsColumns}
      className="[&_table]:table-auto overflow-x-auto w-full"
      getRowProps={getRowProps}
      renderSubComponent={(row) => <PaymentLogsTableRowHistory row={row} />}
      canExpand
    />
  );
};

const PaymentLogsTableRowHistory = ({ row }: { row: DataTableRow<BookingPaymentData> }) => {
  const { dateFormat, fullTimeFormat } = useProfile();

  const {
    original: { history },
  } = row;

  return (
    <div className="payment-log-history contents divide-y">
      {history.map((log) => {
        return (
          <div key={log.id} className="flex bg-neutral-gray p-3 py-3 pl-[65px]">
            <div className="flex flex-1 flex-col">
              <Typography variant="small" className="font-medium">
                {log.description}
              </Typography>
              <Typography variant="small" className="flex items-center gap-2 font-medium leading-loose text-neutral-dark-gray">
                {format(new Date(log.createdAt), dateFormat)} | {format(new Date(log.createdAt), fullTimeFormat)}
                {/* 
              TODO: timezone comparison
              <Tooltip content="8:00 PM Sydney">
                <Icon name="InfoCircle" variant="Bold" size="sm" className="-mt-[2px] rotate-180" />
              </Tooltip> 
              */}
                {log.reason && (
                  <>
                    <i className="inline-block h-[6px] w-[6px] rounded-full bg-neutral-dark-gray"></i> {log.reason}
                  </>
                )}
              </Typography>
              {log.paidTo && (
                <Typography variant="small" className="flex items-center gap-1 font-medium text-neutral-dark-gray">
                  <Icon name="Profile" variant="Bold" size="sm" />
                  {log.paidTo}
                </Typography>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
