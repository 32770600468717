import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { companyApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { AssociatedClientAction, Company, CompanyAction, companyOptionsInitialState, CompanyState, UnableToAddClient } from "./types";

const initialState: CompanyState = {
  companies: [],
  associatedClients: [],
  companiesPage: pageState,
  associatedClientsPage: pageState,
  companiesPageState: {
    search: "",
    selectedCompany: undefined,
    action: undefined,
  },
  associatedClientsPageState: {
    unableToAddClients: [],
    action: undefined,
  },
  options: companyOptionsInitialState,
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setSelectedCompany: (state, { payload }: PayloadAction<Company | undefined>) => {
      state.companiesPageState.selectedCompany = payload;
    },
    setCompaniesSearch: (state, { payload }: PayloadAction<string>) => {
      state.companiesPageState.search = payload;
    },
    setCompanyAction: (state, { payload }: PayloadAction<CompanyAction | undefined>) => {
      state.companiesPageState.action = payload;
    },
    setAssociatedClientAction: (state, { payload }: PayloadAction<AssociatedClientAction | undefined>) => {
      state.associatedClientsPageState.action = payload;
    },
    setAssociatedClientUnableToAddClients: (state, { payload }: PayloadAction<UnableToAddClient[]>) => {
      state.associatedClientsPageState.unableToAddClients = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(companyApi.endpoints.getCompanies.matchFulfilled, (state, { payload }) => {
      const { companies, companiesPage } = payload.data;
      state.companies = companies;
      state.companiesPage = companiesPage;
    });
    builder.addMatcher(companyApi.endpoints.getAssociatedClients.matchFulfilled, (state, { payload }) => {
      const { associatedClients, associatedClientsPage } = payload.data;
      state.associatedClients = associatedClients;
      state.associatedClientsPage = associatedClientsPage;
    });
    builder.addMatcher(companyApi.endpoints.getCompanyOptions.matchFulfilled, (state, { payload }) => {
      const { options } = payload.data;
      state.options = options;
    });
  },
});

export const {
  setSelectedCompany,
  setCompaniesSearch,
  setCompanyAction,
  setAssociatedClientAction,
  setAssociatedClientUnableToAddClients,
} = companySlice.actions;
export default companySlice.reducer;
