import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Card, Divider, ErrorMessage, IconButton, TextArea, Typography, SidePanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useCloseJobNoCostMutation } from "@/redux/apis/booking/endpoints/closeJob";
import { addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { useSelectedBookingId } from "../../hooks/useSelectedBookingId";

interface CloseJobNoCostProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  justification: z.string().nonempty({ message: "This field is required" }),
});

type FormData = z.infer<typeof schema>;

export const CloseJobNoCost = ({ open, onClose }: CloseJobNoCostProps) => {
  const booking = useSelectedBooking();
  const [_, setSelectedBookingId] = useSelectedBookingId();
  const [closeJobNoCost, { isLoading: isClosingJob }] = useCloseJobNoCostMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      justification: "",
    },
  });

  const onSubmit = async (data: FormData) => {
    return closeJobNoCost({ bookingId: booking.id, justification: data.justification })
      .unwrap()
      .then(() => {
        addToast("success", "Job closed successfully");
        setSelectedBookingId(undefined);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <SidePanel open={open} onClose={onClose} width={550} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <i className="h-[10px] w-[10px] rounded-full bg-primary"></i>
          <Typography variant="title">Close Job at No Cost ({booking.id})</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
        {/* {isFetching && <Loading />} */}
        <div className="flex-1 p-5">
          <Card>
            <Typography className="mb-2">
              Please leave a note on why this job is being closed with no charge. If a driver is assigned, this message will also be sent to
              the driver.
            </Typography>
            <TextArea {...register("justification")} placeholder="Enter Justification" autoFocus rows={3} />
            <ErrorMessage errors={errors} name="justification" />
          </Card>
        </div>
        <div className="p-5">
          <Divider className="-mx-5" />
          <Button type="submit" disabled={!isDirty || isSubmitting || isClosingJob} className="mt-5 w-full" variant="primary" size="lg">
            Close Job at No Cost
          </Button>
        </div>
      </form>
    </SidePanel>
  );
};
