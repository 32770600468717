import { RootState } from "@/redux/store";

export const bookingsSelector = (state: RootState) => state.booking.bookings;
export const bookingSummarySelector = (state: RootState) => state.booking.bookingSummary;
export const bookingFormOptionsSelector = (state: RootState) => state.booking.options;
export const bookingsPageStateSelector = (state: RootState) => state.booking.pageState;
export const bookingsPageSelector = (state: RootState) => state.booking.page;
export const selectedBookingSelector = (state: RootState) => state.booking.pageState.selectedBooking;
export const bookingActionSelector = (state: RootState) => state.booking.pageState.actionsState.action;
export const bookingPaymentsMetaSelector = (state: RootState) => state.booking.pageState.actionsState.payments.meta;
export const bookingPaymentActionSelector = (state: RootState) => state.booking.pageState.actionsState.payments.action;
export const selectedBookingPaymentSelector = (state: RootState) => state.booking.pageState.actionsState.payments.selectedPayment;
export const bookingPaymentLogsSelector = (state: RootState) => state.booking.pageState.actionsState.payments.logs;
export const recommendedCommissionRateSelector = (state: RootState) =>
  state.booking.pageState.actionsState.driver.recommendedCommissionRate;
export const bookingHistoryLogsSelector = (state: RootState) => state.booking.pageState.actionsState.history.logs;
