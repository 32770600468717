import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { driverApi, networkApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { NetworkState } from "./types";

const initialState: NetworkState = {
  items: [],
  page: pageState,
  history: {
    items: [],
    page: pageState,
  },
  fleetLeader: {
    items: [],
    page: pageState,
  },
};

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    togglePaysCommission: (state, action: PayloadAction<{ id: string; active: boolean }>) => {
      const item = state.items.find((i) => i.id === action.payload.id);
      if (item) item.paysCommission = action.payload.active;
    },
    updateNetworkStatus: (state, action: PayloadAction<{ id: string; status: string }>) => {
      const item = state.items.find((i) => i.id === action.payload.id);
      if (item) item.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(networkApi.endpoints.getNetwork.matchFulfilled, (state, { payload }) => {
        const { items, page } = payload.data;
        state.items = items;
        state.page = page;
      })
      .addMatcher(networkApi.endpoints.getNetworkHistory.matchFulfilled, (state, { payload }) => {
        const { items, page } = payload.data;
        state.history.items = items;
        state.history.page = page;
      })
      .addMatcher(driverApi.endpoints.getFleetLeaders.matchFulfilled, (state, { payload }) => {
        const { items, page } = payload.data;
        state.fleetLeader.items = items;
        state.fleetLeader.page = page;
      });
  },
});

export const { togglePaysCommission, updateNetworkStatus } = networkSlice.actions;
export default networkSlice.reducer;
