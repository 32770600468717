import { invalidateOn } from "@/helpers/reduxHelpers";
import { pricingApi } from "../pricingApi";
import { transformTimeOfDayMultipliers } from "./helpers";
import {
  AddTimeOfDayMultiplierParams,
  EditTimeOfDayMultiplierParams,
  AddTimeOfDayMultiplierResponse,
  DeleteTimeOfDayMultiplierResponse,
  EditTimeOfDayMultiplierResponse,
  GetTimeOfDayMultipliersParams,
  GetTimeOfDayMultipliersResponse,
  RawTimeOfDayMultiplier,
  ReorderTimeOfDayMultipliersResponse,
  ToggleTimeOfDayMultiplierResponse,
} from "./types";

export const timeOfDayApi = pricingApi.enhanceEndpoints({ addTagTypes: ["TimeOfDay"] }).injectEndpoints({
  endpoints: (builder) => ({
    getTimeOfDayMultipliers: builder.query<GetTimeOfDayMultipliersResponse, GetTimeOfDayMultipliersParams>({
      query: ({ page, pageSize }) => {
        let queryString = "/time-of-day-multiplier?";
        if (page) queryString = `${queryString}&page=${page}`;
        if (pageSize) queryString = `${queryString}&page_size=${pageSize}`;
        return queryString;
      },
      providesTags: ["TimeOfDay"],
      transformResponse: transformTimeOfDayMultipliers,
    }),
    addTimeOfDayMultiplier: builder.mutation<AddTimeOfDayMultiplierResponse, AddTimeOfDayMultiplierParams>({
      query: (body) => ({
        url: "/time-of-day-multiplier",
        method: "POST",
        body,
      }),
      invalidatesTags: invalidateOn({ success: ["TimeOfDay"] }),
    }),
    editTimeOfDayMultiplier: builder.mutation<EditTimeOfDayMultiplierResponse, EditTimeOfDayMultiplierParams>({
      query: ({ uuid, ...put }) => ({
        url: `/time-of-day-multiplier/${uuid}`,
        method: "PUT",
        body: put,
      }),
      invalidatesTags: invalidateOn({ success: ["TimeOfDay"] }),
    }),
    deleteTimeOfDayMultiplier: builder.mutation<DeleteTimeOfDayMultiplierResponse, string>({
      query: (uuid) => ({
        url: `/time-of-day-multiplier/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TimeOfDay"],
    }),
    toggleTimeOfDayMultiplier: builder.mutation<
      ToggleTimeOfDayMultiplierResponse,
      Pick<RawTimeOfDayMultiplier, "uuid"> & { is_active: boolean }
    >({
      query: ({ uuid, ...body }) => ({
        url: `/time-of-day-multiplier/${uuid}`,
        method: "PATCH",
        body,
      }),
    }),
    reorderTimeOfDay: builder.mutation<ReorderTimeOfDayMultipliersResponse, Record<string, number>>({
      query: (body) => ({
        url: "/time-of-day-multiplier/reorder",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetTimeOfDayMultipliersQuery,
  useAddTimeOfDayMultiplierMutation,
  useToggleTimeOfDayMultiplierMutation,
  useDeleteTimeOfDayMultiplierMutation,
  useEditTimeOfDayMultiplierMutation,
  useReorderTimeOfDayMutation,
} = timeOfDayApi;
