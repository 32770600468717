import { Listbox, Transition } from "@headlessui/react";
import cx from "classnames";
import { Fragment } from "react";
import { Icon } from "../Icon/Icon";

export type IconDropdownOption = { name: string; icon: string; value: string };

export interface IconDropdownProps {
  options: IconDropdownOption[];
  placeholder?: string;
  value?: string;
  hasError?: boolean;
  onChange: (value: string) => void;
}

export const IconDropdown = ({ options, hasError = false, placeholder = "Select", value, onChange, ...props }: IconDropdownProps) => {
  const { icon, name } = options.find((o) => o.value === value) || {};

  const errorClass = {
    "!border-danger": hasError,
  };

  return (
    <Listbox defaultValue={value} onChange={onChange}>
      {({ open }) => (
        <div className="relative mt-1" {...props}>
          <Listbox.Button
            className={cx(
              "relative min-h-[48px] w-full cursor-pointer rounded-md border border-neutral-mid-gray bg-white py-2 pl-3 pr-10 text-left shadow-sm sm:text-base",
              errorClass
            )}
          >
            <span className="flex items-center">
              {icon && <Icon name={icon} isCustom className="rounded-full bg-neutral-gray p-[8px]" size="xl" />}
              {name ? (
                <span className="ml-3 block truncate font-medium">{name}</span>
              ) : (
                <span className="block truncate font-medium text-neutral-mid-gray">{placeholder}</span>
              )}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <Icon name="arrow-down" isCustom className="h-5 w-5 text-black" />
            </span>
          </Listbox.Button>
          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-2 max-h-72 w-full overflow-auto rounded-md border bg-white text-base shadow-lg sm:text-sm">
              {options.map((option, i) => (
                <Listbox.Option
                  key={i}
                  className={({ active }) =>
                    cx(active ? " bg-primary-light" : "text-gray-900", "relative cursor-pointer select-none py-3 pl-3 pr-9")
                  }
                  value={option.value}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        <Icon name={option.icon} isCustom className="rounded-full bg-neutral-gray p-[8px]" size="xl" />
                        <span className={cx("ml-3 block truncate text-base font-medium")}>{option.name}</span>
                      </div>

                      {selected ? (
                        <span
                          className={cx(active ? "text-white" : "text-primary-dark", "absolute inset-y-0 right-0 flex items-center pr-4")}
                        ></span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
