import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Panel, Skeleton, Typography } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useLazyGetNetworkHistoryQuery, useLazyGetNetworkItemQuery } from "@/redux/apis/network/networkApi";
import { useAppSelector } from "@/redux/hooks";
import { networkHistoryPageSelector, networkHistorySelector } from "@/redux/slices/network/selectors";
import { addToast } from "@/utils";
import { PrivateNetworkHistoryTable } from "./tables/PrivateNetworkHistoryTable";

export const PrivateNetworkHistoryPanel = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const items = useAppSelector(networkHistorySelector);
  const { current, count, total, size } = useAppSelector(networkHistoryPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const [getNetworkItem, { isFetching: isFetchingNetworkItem }] = useLazyGetNetworkItemQuery();
  const [getNetworkHistory, { isFetching: isFetchingNetworkHistory }] = useLazyGetNetworkHistoryQuery();
  const [networkName, setNetworkName] = useState("");

  if (!id) {
    navigate("../");
    throw new Error("Missing Network ID");
  }

  useEffect(() => {
    getNetworkItem(id)
      .unwrap()
      .then(({ data }) => setNetworkName(data.operator.name))
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [id, navigate, getNetworkItem]);

  useEffect(() => {
    getNetworkHistory({ id, page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [id, navigate, page, pageSize, getNetworkHistory]);

  if (isFetchingNetworkItem || isFetchingNetworkHistory) return <Skeleton />;

  return (
    <section className="contents">
      <nav className="pb-4">
        <Button startIcon="ArrowLeft" variant="tertiary" className="!bg-transparent" onClick={() => navigate("../")}>
          Back
        </Button>
      </nav>
      <Panel className="flex flex-col">
        <header>
          <div className="flex flex-row items-center gap-2">
            <Typography variant="h3" className="leading-8">
              {`${networkName ? `${networkName}'s` : ""} History Logs`}
            </Typography>
          </div>
          <Typography className="text-neutral-dark-gray">{`${networkName ?? ""} Private Network History Logs`}</Typography>
        </header>
        {items.length < 1 ? (
          <EmptyState
            title="Your Private Network History"
            description="There are currently no records available for your private network history"
          />
        ) : (
          <PrivateNetworkHistoryTable className="mt-4" items={items} />
        )}
      </Panel>
      <Pagination
        totalItems={total}
        pageCount={count}
        currentPage={current}
        onPageClick={handlePageClick}
        pageSize={size}
        onSizeChange={handleSizeChange}
      />
    </section>
  );
};
