import camelcaseKeys from "camelcase-keys";

const BASE_URL = "https://api.mapbox.com/directions/v5/mapbox/driving-traffic/";
const ACCESS_TOKEN = import.meta.env.VITE_RIDEMINDER_MAPBOX_ACCESS_TOKEN;

type MapboxResponse = {
  routes: Array<{
    weight_name: string;
    weight: number;
    duration: number;
    distance: number;
    legs: Array<{
      via_waypoints: Array<unknown>;
      admins: Array<{
        iso_3166_1_alpha3: string;
        iso_3166_1: string;
      }>;
      weight: number;
      duration: number;
      steps: Array<unknown>;
      distance: number;
      summary: string;
    }>;
    geometry: string;
  }>;
  waypoints: Array<{
    distance: number;
    name: string;
    location: Array<number>;
  }>;
  code: string;
  uuid: string;
};

export const fetchDirections = async (points: number[][]) => {
  try {
    // Construct the URL
    const pointsString = points.map((point) => point.join(",")).join(";");
    const url = `${BASE_URL}${pointsString}?access_token=${ACCESS_TOKEN}`;

    const response = await fetch(url);
    const data = (await response.json()) as MapboxResponse;

    return camelcaseKeys(data);
  } catch (error) {
    console.error("Error fetching directions:", error);
  }
};
