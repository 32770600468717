import { Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { AddOnItem as AddOnItemType } from "@/redux/slices/addOn/types";
import { clsx } from "@/utils";
import { isAfter, isEqual } from "date-fns";
import { renderAddOnBadge, renderAddOnIcon, renderAddOnStatus } from "./helpers";


interface AddOnItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: AddOnItemType;
  title: string;
  description?: string;
}

export const AddOnItem = ({ item, title, description = "", className, ...props }: AddOnItemProps) => {
  const { dateFormat } = useProfile();
  const { identifier, enabled, freeTrialExpiry } = item;
  const expiryDate = new Date(freeTrialExpiry);
  const currentDate = new Date();
  const isFreeTrialValid = Boolean(freeTrialExpiry && (isEqual(expiryDate, currentDate) || isAfter(expiryDate, currentDate)));

  return (
    <div className={clsx("flex flex-col group rounded-lg cursor-pointer p-2.5 gap-y-2 hover:bg-neutral-surface-gray", className)} {...props}>
      <div className="flex flex-row gap-x-2">
        <div className="flex flex-1">
          <div>
            <div className="flex items-center justify-center bg-primary-dark rounded-lg p-3 group-hover:bg-primary">
              {renderAddOnIcon(identifier)}
            </div>
          </div>
          <div className="flex flex-col ml-4">
            <div className="flex flex-row items-center gap-x-2">
              <Typography variant="action" className="group-hover:text-primary">{title}</Typography>
              {renderAddOnBadge(item, isFreeTrialValid)}
            </div>
            <Typography variant="paragraph" className="text-neutral-dark-gray group-hover:text-primary">{description}</Typography>
            {renderAddOnStatus(enabled, isFreeTrialValid, expiryDate, dateFormat)}
          </div>
        </div>
      </div>
    </div>
  );
};
