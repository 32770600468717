import { RootState } from "@/redux/store";

export const invoicingUninvoicedJobsSelector = (state: RootState) => state.payment.invoicing.uninvoicedJobs;
export const invoicingUninvoicedJobsPageSelector = (state: RootState) => state.payment.invoicing.uninvoicedJobs.page;
export const previewInvoiceSelector = (state: RootState) => state.payment.invoicing.uninvoicedJobs.previewInvoice;

export const invoicingInvoicesSelector = (state: RootState) => state.payment.invoicing.invoices;
export const invoicingInvoicesPayByCreditCardSelector = (state: RootState) => state.payment.invoicing.invoices.payByCreditCard;
export const invoicingInvoicesCompaniesPageSelector = (state: RootState) => state.payment.invoicing.invoices.companiesPage;

export const invoicingOptionsSelector = (state: RootState) => state.payment.invoicing.options;
export const invoicingSettingsSelector = (state: RootState) => state.payment.invoicing.settings;
export const invoicingSettingsHistoryPageSelector = (state: RootState) => state.payment.invoicing.settings.historyPage;
export const invoicingPageStateSelector = (state: RootState) => state.payment.invoicing.pageState;

export const creditCardPaymentsSelector = (state: RootState) => state.payment.creditCardPayments.payments;
export const creditCardPaymentsTakeManualPaymentSelector = (state: RootState) => state.payment.creditCardPayments.takeManualPayment;
export const creditCardPaymentsPageStateSelector = (state: RootState) => state.payment.creditCardPayments.pageState;

export const payoutHistorySelector = (state: RootState) => state.payment.payoutHistory.history;
export const payoutRecordsSelector = (state: RootState) => state.payment.payoutHistory.records;

export const debitHistorySelector = (state: RootState) => state.payment.debitHistory.history;
export const debitUpcomingSelector = (state: RootState) => state.payment.debitHistory.upcoming;
export const debitRecordsSelector = (state: RootState) => state.payment.debitHistory.records;
export const debitHistoryPageStateSelector = (state: RootState) => state.payment.debitHistory.pageState;

export const stripeSelector = (state: RootState) => state.payment.stripe;
