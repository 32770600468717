import { format, parseISO } from "date-fns";
import { DropdownMenu, Icon, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { CreditCardPaymentsItem } from "@/redux/slices/payment/types";
import { clsx, formatCurrency } from "@/utils";
import { CreditCardPaymentsTableData } from "./types";

export const generateCreditCardPaymentsColumns = (status: string) => {
  const columns: DataTableColumnDef<CreditCardPaymentsTableData>[] = [
    {
      accessorKey: "date",
      header: "Date",
      id: "date",
      cell: (props) => props.row.original.date,
      size: 200,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
      size: 200,
    },
    {
      accessorKey: "job",
      header: "Job",
      id: "job_id",
      cell: (props) => props.row.original.job,
      size: 200,
    },
    {
      accessorKey: "firstName",
      header: "First Name",
      id: "first_name",
      cell: (props) => props.row.original.firstName,
      size: 200,
    },
    {
      accessorKey: "lastName",
      header: "Last Name",
      id: "last_name",
      cell: (props) => props.row.original.lastName,
      size: 200,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: (props) => props.row.original.amount,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      enableSorting: false,
      size: 100,
    },
  ];

  if (status === "failed") {
    columns.splice(5, 0, {
      accessorKey: "failureReasons",
      header: "Failure Reasons",
      cell: (props) => props.row.original.failureReasons,
      enableSorting: false,
      size: 250,
    });
  } else if (status === "refund") {
    columns.splice(5, 0, {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250,
    });
  }

  return columns;
};

export const generateCreditCardPaymentsData = (
  items: CreditCardPaymentsItem[],
  status: string,
  dateFormat: string,
  timeFormat: string,
  handleViewPaymentLog: (item: CreditCardPaymentsItem) => void,
  handleTriggerPayment: (item: CreditCardPaymentsItem) => void,
  handleTakeManualPayment: (item: CreditCardPaymentsItem) => void
) => {
  const data = items.map(
    (i) =>
      ({
        date: format(parseISO(i.dateTime), dateFormat),
        time: format(parseISO(i.dateTime), timeFormat),
        job: i.jobId,
        firstName: i.account.firstName,
        lastName: i.account.lastName,
        amount: formatCurrency(i.amount, 2, i.currency),
        failureReasons:
          status === "failed" ? (
            <div className="flex">
              <Tooltip content={i.description} placement="bottom">
                <Typography variant="paragraph" className="truncate">
                  {i.description}
                </Typography>
              </Tooltip>
            </div>
          ) : undefined,
        status: status === "refund" ? renderStatus(i.status) : undefined,
        action: renderActions(i, status, handleViewPaymentLog, handleTriggerPayment, handleTakeManualPayment),
      } as CreditCardPaymentsTableData)
  );

  return data;
};

export const renderStatus = (status: string) => {
  if (status) {
    return (
      <Typography variant="paragraph" className="flex flex-row items-center gap-x-2 truncate capitalize">
        {renderStatusIcon(status)} {status.replaceAll("_", " ").toLowerCase()}
      </Typography>
    );
  }
};

export const renderStatusIcon = (statusVal: string) => {
  const status = statusVal.toUpperCase();
  const iconColorClass = {
    "bg-success": status === "PAID" || status === "SUCCESSFUL",
    "bg-warning": status === "REFUNDED",
    "bg-warning-dark": status === "PARTIALLY_REFUNDED",
    "bg-danger": status === "FAILED" || status === "CANCELLED" || status === "ERROR",
    "bg-danger opacity-60": status === "CANCELLED",
    "bg-info": status === "PROCESSING" || status === "IN_TRANSIT",
  };

  return <span className={clsx("h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray", iconColorClass)} />;
};

const renderActions = (
  item: CreditCardPaymentsItem,
  status: string,
  handleViewPaymentLog: (item: CreditCardPaymentsItem) => void,
  handleTriggerPayment: (item: CreditCardPaymentsItem) => void,
  handleTakeManualPayment: (item: CreditCardPaymentsItem) => void
) => {
  const phpHostUrl = getPhpHostUrl();
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      {(status === "ready" || status === "failed") && (
        <>
          <DropdownMenu.Item onClick={() => handleTakeManualPayment(item)}>
            <Icon name="Card" className="mr-2.5" size="sm" />
            <Typography variant="paragraph">Take Manual Payment</Typography>
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => handleTriggerPayment(item)}>
            <Icon name="Flash" className="mr-2.5" size="sm" />
            <Typography variant="paragraph">Trigger Payment</Typography>
          </DropdownMenu.Item>
        </>
      )}
      <a href={`${phpHostUrl}/passenger/${item.account.id}/booking/view/${item.jobId}`} target="_blank" rel="noreferrer">
        <DropdownMenu.Item>
          <Icon name="Archive" className="mr-2.5" size="sm" />
          <Typography variant="paragraph">View Job</Typography>
        </DropdownMenu.Item>
      </a>
      <DropdownMenu.Item onClick={() => handleViewPaymentLog(item)}>
        <Icon name="DollarCircle" className="mr-2.5" size="sm" />
        <Typography variant="paragraph">View Payment Log</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};
