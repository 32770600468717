import { useMemo } from "react";
import { useGetBookingsQuery, useGetBookingsSummaryQuery } from "@/redux/apis/booking/bookingApi";
import { useAppSelector } from "@/redux/hooks";
import { bookingSummarySelector, bookingsPageSelector, bookingsSelector } from "@/redux/slices/booking/selectors";
import { useGetBookingsParams } from "./useGetBookingsParams";

export const useBookings = () => {
  const { params } = useGetBookingsParams();
  const { isFetching, error } = useGetBookingsQuery(params, { refetchOnMountOrArgChange: true });
  const { isFetching: isFetchingSummary } = useGetBookingsSummaryQuery(params, { refetchOnMountOrArgChange: true });
  const summary = useAppSelector(bookingSummarySelector);
  const bookings = useAppSelector(bookingsSelector);
  const page = useAppSelector(bookingsPageSelector);

  const isLoading = useMemo(() => isFetching || isFetchingSummary, [isFetching, isFetchingSummary]);

  return {
    isLoading,
    bookings,
    summary,
    error,
    page,
  };
};
