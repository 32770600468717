import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination } from "@/components/molecules";
import { useUserAccountsContext } from "@/contexts/userAccounts";
import { usePagination } from "@/hooks/usePagination";
import { useGetUserAccountsQuery } from "@/redux/apis/userAccount/userAccountApi";
import { useAppSelector } from "@/redux/hooks";
import { userAccountsPageSelector, userAccountsSelector } from "@/redux/slices/userAccount/selectors";
import { UserAccountsEmptyState } from "./UserAccountsEmptyState";
import { UserAccountsTable } from "./UserAccountsTable";

export const UserAccountsPanel = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const { current, count, total, size } = useAppSelector(userAccountsPageSelector);
  const userAccounts = useAppSelector(userAccountsSelector);
  const { isFetching } = useGetUserAccountsQuery({ page, page_size: pageSize }, { refetchOnMountOrArgChange: true });

  if (isFetching) return <Skeleton />;

  return (
    <>
      <Panel {...props}>
        <Header />
        {userAccounts.length < 1 && !isFetching ? <UserAccountsEmptyState /> : <UserAccountsTable items={userAccounts} className="mt-4" />}
      </Panel>
      {!isFetching && (
        <Pagination
          onPageClick={handlePageClick}
          onSizeChange={handleSizeChange}
          currentPage={current}
          pageSize={size}
          totalItems={total}
          pageCount={count}
        />
      )}
    </>
  );
};

const Header = () => {
  const { setStep } = useUserAccountsContext();
  const userAccounts = useAppSelector(userAccountsSelector);
  return (
    <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
      <div className="flex-1">
        <Typography variant="h3" className="leading-8">
          Staff Directory
        </Typography>
        <Typography className="text-neutral-dark-gray">Manage all the user permissions of Rideminder</Typography>
      </div>
      <div className="flex items-center">
        {userAccounts.length > 0 && (
          <Button onClick={() => setStep("add")} startIcon="Add" className="w-full sm:w-fit">
            Invite New
          </Button>
        )}
      </div>
    </div>
  );
};