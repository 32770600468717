import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, Pagination } from "@/components/molecules";
import { usePagination, useProfile, useSorting } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { payoutHistorySelector } from "@/redux/slices/payment/selectors";
import { PayoutHistoryItem } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generatePayoutHistoryColumns, generatePayoutHistoryData } from "./helpers";

interface PayoutHistoryTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: PayoutHistoryItem[];
}

export const PayoutHistoryTable = ({ items, className, ...props }: PayoutHistoryTableProps) => {
  const navigate = useNavigate();
  const { dateFormat } = useProfile();
  const { sorting, setSorting } = useSorting();

  const handleViewPayoutDetails = (item: PayoutHistoryItem) => navigate(item.id);

  const data = useMemo(
    () => generatePayoutHistoryData(items, dateFormat, handleViewPayoutDetails),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generatePayoutHistoryColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} sorting={sorting} setSorting={setSorting} {...props} />
    </div>
  );
};

const PayoutHistoryTablePagination = () => {
  const {
    page: { current, count, total, size },
  } = useAppSelector(payoutHistorySelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

PayoutHistoryTable.Pagination = PayoutHistoryTablePagination;
