import { useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Alert, Divider, Typography } from "@/components/atoms";
import { DisclosurePanel } from "@/components/molecules";
import { useOperator } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { companyOptionsSelector } from "@/redux/slices/company/selectors";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../types";
import { BillingContactForm } from "./BillingContactForm";
import { PricingAdjustmentsForm } from "./PricingAdjustmentsForm";
import { AllowDriverExtrasField } from "./fields/AllowDriverExtrasField";
import { EnableInvoicingField } from "./fields/EnableInvoicingField";
import { GenerateInvoiceOnField } from "./fields/GenerateInvoiceOnField";
import { InvoiceGenerateFrequencyField } from "./fields/InvoiceGenerateFrequencyField";
import { InvoiceGenerateLevelField } from "./fields/InvoiceGenerateLevelField";
import { KeyContactContactNumberField } from "./fields/KeyContactContactNumberField";
import { KeyContactEmailAddressField } from "./fields/KeyContactEmailAddressField";
import { KeyContactNameField } from "./fields/KeyContactNameField";
import { OperatorCommissionOverrideField } from "./fields/OperatorCommissionOverrideField";
import { PaidCustomerExtrasField } from "./fields/PaidCustomerExtrasField";
import { PaymentTermsField } from "./fields/PaymentTermsField";
import { PercentageCommissionField } from "./fields/PercentageCommissionField";
import { PricingProfileField } from "./fields/PricingProfileField";

export const CompaniesFormStep2 = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const operator = useOperator();
  const ability = useAppAbility();
  const options = useAppSelector(companyOptionsSelector);
  const { watch } = useFormContext<CompaniesFormData>();
  const watchEnableInvoicing = watch("enableInvoicing");
  const watchPricingProfile = watch("pricingProfile");

  return (
    <div className={clsx("flex flex-col", className)} {...props}>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
        <div className="col-span-full">
          <Typography variant="title">Key Contact</Typography>
        </div>
        <KeyContactNameField className="col-span-full sm:col-span-1" />
        <KeyContactEmailAddressField className="col-span-full sm:col-span-1" />
        <KeyContactContactNumberField className="col-span-full sm:col-span-1" />
      </div>
      <div className="mt-8 grid grid-cols-3 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
        <div className="col-span-full">
          <Typography variant="title">Billing Settings</Typography>
        </div>
      </div>

      {ability.can("manage", "providerCompanyInvoicing") && (
        <div className={clsx("mt-3 lg:mt-6")}>
          {operator.flags.paymentInvoiceConfigured ? (
            <>
              <div className="grid grid-cols-3">
                <EnableInvoicingField className="col-span-3 lg:col-span-1" />
              </div>
              <div
                className={clsx("mt-6 flex flex-col rounded-lg bg-neutral-surface-gray px-5 pb-6 pt-4", {
                  hidden: !watchEnableInvoicing,
                })}
              >
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                  <div className="col-span-full">
                    <Typography variant="action">Invoice Settings</Typography>
                  </div>
                  <InvoiceGenerateLevelField className="col-span-full sm:col-span-1" options={options.invoiceGenerateLevel} />
                  <InvoiceGenerateFrequencyField className="col-span-full sm:col-span-1" options={options.invoiceGenerateFrequency} />
                  <GenerateInvoiceOnField className="col-span-full sm:col-span-1" />
                  <PaymentTermsField className="col-span-full sm:col-span-1" />
                </div>
                <BillingContactForm className="mt-8" />
              </div>
            </>
          ) : (
            <Alert
              type="warning"
              message={
                <span>
                  Invoicing Currently Unavailable. Please have your account owner complete the &quot;Invoice Setup&quot; configuration in{" "}
                  <Link to={`/operator/${operator.id}/payments`} className="underline">
                    Payments
                  </Link>{" "}
                  to enable account invoicing
                </span>
              }
            />
          )}
        </div>
      )}

      <div className="mt-4 flex flex-col">
        <DisclosurePanel title="Pricing Adjustments">
          <div className="flex flex-col gap-y-2 lg:gap-y-4">
            <PricingAdjustmentsForm />
            <Divider />
            <OperatorCommissionOverrideField />
            <PercentageCommissionField />
          </div>
        </DisclosurePanel>
      </div>
      <div className="mt-4 flex flex-col">
        <DisclosurePanel title="Advanced Settings">
          <div className="grid grid-cols-6 gap-x-5 gap-y-4 lg:gap-x-10 lg:gap-y-8">
            <PricingProfileField className="col-span-6 lg:col-span-3" options={options.pricingProfile} />
            <AllowDriverExtrasField className="col-span-6 lg:col-span-1" />
            {watchPricingProfile !== "dynamic-pricing" && <PaidCustomerExtrasField className="col-span-6 lg:col-span-2" />}
          </div>
        </DisclosurePanel>
      </div>
    </div>
  );
};
