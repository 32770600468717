import { Skeleton } from "@/components/atoms";
import { EmailNotificationsEditPanel } from "@/components/organisms/EmailNotifications";
import { useGetEmailNotificationsQuery } from "@/redux/apis/config/admin/emailNotifications";
import { useAppSelector } from "@/redux/hooks";
import { emailNotificationsSelector } from "@/redux/slices/admin/selectors";

export const EmailNotificationsEdit = () => {
  const { isFetching } = useGetEmailNotificationsQuery();
  const { details } = useAppSelector(emailNotificationsSelector);

  if (isFetching) return <Skeleton />;

  return <EmailNotificationsEditPanel details={details} />;
};
