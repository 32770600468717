import { adminApi } from "../adminApi";
import { transformUpdateWaitingTimes, transformWaitingTimes } from "./helpers";
import { GetWaitingTimesResponse, UpdateWaitingTimesParams, UpdateWaitingTimesResponse } from "./types";

export const waitingTimesApi = adminApi.enhanceEndpoints({ addTagTypes: ["WaitingTimes"] }).injectEndpoints({
  endpoints: (builder) => ({
    getWaitingTimes: builder.query<GetWaitingTimesResponse, void>({
      query: () => `/wait-times`,
      transformResponse: transformWaitingTimes,
      providesTags: ["WaitingTimes"],
    }),
    updateWaitingTimes: builder.mutation<UpdateWaitingTimesResponse, UpdateWaitingTimesParams>({
      query: (body) => ({
        url: `/wait-times`,
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateWaitingTimes,
      invalidatesTags: ["WaitingTimes"],
    }),
  }),
});

export const { useGetWaitingTimesQuery, useUpdateWaitingTimesMutation } = waitingTimesApi;
