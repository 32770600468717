import { Outlet } from "react-router-dom";
import { useBranding, useProviderCode, useSiteConfig } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";

export const PublicContent = () => {
  const { branding } = useSiteConfig();
  const providerCode = useProviderCode();
  const operator = useAppSelector((state) => state.operator.activeOperator);
  const useOperatorBranding = Boolean(providerCode) || (operator && operator.singleTenant);
  const siteBranding = useOperatorBranding && operator ? operator.branding : branding;
  useBranding(siteBranding);  

  return (
    <main className="contents">
      <Outlet />
    </main>
  );
};
