import { clsx } from "@/utils";
import { Typography, AlertType, Icon } from "..";

type BadgeType = AlertType | "disabled";

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  type: BadgeType;
  message: string;
  isIconBadge?: boolean;
  size?: "sm" | "md";
}

export const renderBadgeIcon = (type: BadgeType) => {
  switch (type) {
    case "info":
      return <Icon className="text-inherit mr-2 rotate-180 self-center" name="InfoCircle" variant="Bold" size="xs" />;
    case "danger":
      return <Icon className="text-inherit mr-2 self-center" name="InfoCircle" variant="Bold" size="xs" />;
    case "warning":
      return <Icon className="text-inherit mr-2 self-center" name="Danger" variant="Bold" size="xs" />;
    case "success":
      return <Icon className="text-inherit mr-2 self-center" name="TickCircle" variant="Bold" size="xs" />;
    default:
      return null;
  }
};

export const Badge = (badgeProps: BadgeProps) => {
  const { type, message, isIconBadge, className, size = "md", ...props } = badgeProps;

  const typeClass = {
    "bg-info-light text-info": type === "info",
    "bg-danger-light text-danger": type === "danger",
    "bg-warning-light text-warning-dark": type === "warning",
    "bg-success-light text-success": type === "success",
    "bg-neutral-gray text-neutral-dark-gray": type === "disabled",
  };

  const sizeClass = {
    "px-2 py-1 rounded-lg": size === "sm",
    "px-4 py-1 rounded-full": size === "md",
  };

  const fontClass = {
    "!text-sm !leading-none": size === "sm",
    "!text-base": size === "md",
  };

  return (
    <div className={clsx("inline-flex whitespace-nowrap rounded-full", typeClass, sizeClass, className)} data-testid="badge" {...props}>
      {isIconBadge ? renderBadgeIcon(type) : null}
      <Typography className={clsx("self-center", fontClass)} variant="action">
        {message}
      </Typography>
    </div>
  );
};
