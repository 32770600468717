import { forwardRef } from "react";
import { CustomIcon } from "./CustomIcon";
import { IconSax, IconVariant } from "./IconSax";

export type IconSize = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | number;

export const iconSizes: Record<IconSize, number> = {
  xxs: 10,
  xs: 15,
  sm: 18,
  md: 20,
  lg: 24,
  xl: 32,
  xxl: 40,
};

export interface IconProps extends React.ComponentPropsWithRef<"svg"> {
  name: string;
  /** Refer to https://iconsax-react.pages.dev/*/
  size?: IconSize;
  variant?: IconVariant;
  color?: string;
  isCustom?: boolean;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { name, size = "lg", variant, color, isCustom = false, ...rest } = props;

  if (isCustom) return <CustomIcon name={name} size={size} variant={variant} color={color} ref={ref} {...rest} />;

  return <IconSax name={name} size={size} variant={variant} color={color} ref={ref} {...rest} />;
});

Icon.displayName = "Icon";
