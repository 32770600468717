import { format } from "date-fns";
import { useCallback } from "react";
import { Marker as MapboxMarker } from "react-map-gl";
import { MarkerEvent } from "react-map-gl/dist/esm/types";
import { Icon, Typography } from "@/components/atoms";
import { BookingMarker } from "../../types";
import { Popup } from "./Popup";

export interface MarkerProps extends Omit<React.ComponentProps<typeof MapboxMarker>, "latitude" | "longitude"> {
  marker: BookingMarker;
  isActive: boolean;
  onToggle: (activeMarkers: Record<string, boolean>) => void;
}

const getIcon = (type: BookingMarker["type"]) => {
  switch (type) {
    case "pickup":
      return <Icon name="User" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-primary" />;
    case "trip-pickup":
      return <Icon name="UserTick" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-info" />;
    case "destination":
      return <Icon name="Flag" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-primary" />;
    case "dropoff":
      return <Icon name="UserTick" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-info" />;
    case "trip-dropoff":
      return <Icon name="Flag" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-info" />;
    case "en-route":
      return <Icon name="Car" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-success" />;
    case "driver":
      return <Icon name="Car" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-black" />;
    case "stop":
      return <Icon name="Danger" variant="Bold" size="md" className="h-6 w-6 shrink-0 text-warning" />;
    default:
      return null;
  }
};

export const Marker = ({ marker, isActive, onToggle, ...props }: MarkerProps) => {
  const { id, type, dateTime, location, address, title } = marker;

  const handleClick = useCallback(
    (e: MarkerEvent<mapboxgl.Marker, MouseEvent>) => {
      onToggle({
        [id]: !isActive,
      });
      e.originalEvent.stopPropagation();
    },
    [id, isActive, onToggle]
  );

  const renderIcon = () => {
    switch (type) {
      case "dropoff":
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-primary" isCustom />
            <Icon name="Flag" variant="Bold" size="lg" className="relative -translate-y-1 text-white" />
          </>
        );

      case "pickup":
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-primary" isCustom />
            <Icon name="User" variant="Bold" size="lg" className="relative  -translate-y-1 text-white" />
          </>
        );

      case "trip-pickup":
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-info" isCustom />
            <Icon name="UserTick" variant="Bold" size="lg" className="relative  -translate-y-1 text-white" />
          </>
        );

      case "destination":
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-primary" isCustom />
            <Icon name="Flag" variant="Bold" size="lg" className="relative  -translate-y-1 text-white" />
          </>
        );

      case "en-route":
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-success" isCustom />
            <Icon name="Car" variant="Bold" size="lg" className="relative  -translate-y-1 text-white" />
          </>
        );
      case "driver":
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-black" isCustom />
            <Icon name="Car" variant="Bold" size="lg" className="relative  -translate-y-1 text-white" />
          </>
        );

      case "trip-dropoff":
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-info" isCustom />
            <Icon name="Flag" variant="Bold" size="lg" className="relative  -translate-y-1 text-white" />
          </>
        );

      case "stop":
      default:
        return (
          <>
            <Icon name="location" className="absolute inset-0 h-full w-full text-warning" isCustom />
            <Icon name="Danger" variant="Bold" size="lg" className="relative  -translate-y-1 text-white" />
          </>
        );
    }
  };

  return (
    <>
      <MapboxMarker onClick={handleClick} anchor="bottom" longitude={location[0]} latitude={location[1]} {...props}>
        <button className="relative grid h-[55px] w-11 place-items-center !p-0">{renderIcon()}</button>
      </MapboxMarker>
      {isActive && (
        <Popup
          type={type}
          anchor="bottom"
          location={location}
          onClose={() =>
            onToggle({
              [id]: false,
            })
          }
        >
          <div className="flex place-items-center gap-3">
            {getIcon(type)}
            <div className="grid">
              <Typography className="line-clamp-2 leading-5">{dateTime ? format(new Date(dateTime), "hh:mm:ss a") : address}</Typography>
              <Typography className="leading-5 text-neutral-dark-gray">{title}</Typography>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};
