import { useLocalStorageValue } from "@react-hookz/web";
import { Controller, useForm } from "react-hook-form";
import { Button, CheckboxInput, Divider, IconButton, Typography, Modal, SortableList, Tooltip, Icon } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { allColumns } from "../../fixtures";
import { TableColumn } from "../../types";

export interface BookingCustomizeColumnsProps {
  open: boolean;
  onClose: () => void;
}

type FormData = {
  columns: string[];
};

const selectableColumns = allColumns.filter((i) => !i.isFixed);
const defaultColumns = selectableColumns.filter((i) => i.isDefault);

export const BookingCustomizeColumns = ({ open, onClose }: BookingCustomizeColumnsProps) => {
  const { id: operatorId } = useOperator();
  const { set: setColumns, value: savedColumns } = useLocalStorageValue(`${operatorId}:booking-columns`, {
    defaultValue: defaultColumns.map((i) => i.id),
  });

  const columns = savedColumns ? allColumns.filter((i) => savedColumns.includes(i.id)) : defaultColumns;

  const { control, setValue, watch, handleSubmit } = useForm<FormData>({
    defaultValues: { columns: columns.map((i) => i.id) },
  });

  const selectedColumns = watch("columns");
  const listItems = selectedColumns.reduce((acc, id) => {
    const item = selectableColumns.find((i) => i.id === id);
    if (item) acc.push(item);
    return acc;
  }, [] as TableColumn[]);

  const handleSelectAllChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.checked) {
      setValue(
        "columns",
        selectableColumns.map((i) => i.id)
      );
    } else {
      setValue("columns", []);
    }
  };

  const handleSortChange = (items: TableColumn[]) => {
    setValue(
      "columns",
      items.map((i) => i.id)
    );
  };

  const handleResetClick = () => {
    setValue(
      "columns",
      selectableColumns.filter((i) => i.isDefault).map((i) => i.id)
    );
  };

  const onSubmit = () => {
    setColumns(listItems.map((i) => i.id));
    onClose();
  };

  const renderItem = (item: TableColumn) => {
    return (
      <SortableList.Item id={item.id}>
        <div className="flex h-[40px] items-center gap-2 overflow-hidden rounded-lg bg-white p-4 shadow">
          <SortableList.DragHandle />
          <Typography className="flex-1">{item.header}</Typography>
          <IconButton
            onClick={() =>
              setValue(
                "columns",
                selectedColumns.filter((i) => i !== item.id)
              )
            }
            className="border-none p-1"
            iconName="close"
            isCustomIcon
            iconSize="sm"
            variant="secondary"
          />
        </div>
      </SortableList.Item>
    );
  };

  return (
    <Modal open={open} onClose={onClose} className="overflow-hidden lg:px-0 lg:py-8">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center px-5 pb-3">
          <Typography variant="title" className="flex flex-1 items-center gap-1">
            Customize Columns
            <Tooltip
              content="Select the columns you want to display on your booking dashboard and then drag and move them into the order you want them displayed."
              placement="right"
              maxWidth={347}
            >
              <Icon name="InfoCircle" size="md" variant="Bold" className="inline-block rotate-180 text-neutral-dark-gray" />
            </Tooltip>
          </Typography>
          <IconButton className="border-none p-2" iconName="close" isCustomIcon iconSize="md" variant="secondary" onClick={onClose} />
        </div>
        <Divider className="-mx-6" />
        <div className="gap grid w-[700px] grid-cols-2 ">
          <div className="h-[475px] overflow-y-scroll  px-5 py-3">
            <Typography variant="action">Job Information</Typography>
            <div className="mt-2 flex flex-col gap-2 ">
              <CheckboxInput
                label="Select all"
                variant="check"
                onChange={handleSelectAllChange}
                checked={selectedColumns.length === selectableColumns.length}
              />

              <Controller
                control={control}
                name="columns"
                render={({ field }) => (
                  <>
                    {selectableColumns.map((i) => (
                      <div key={i.id} className="flex items-center gap-2">
                        <CheckboxInput
                          id={i.id}
                          value={i.id}
                          label={i.header}
                          checked={field.value.includes(i.id)}
                          variant="check"
                          onChange={({ target }) => {
                            if (target.checked) {
                              field.onChange([...field.value, target.value]);
                            } else {
                              field.onChange(field.value.filter((value) => value !== target.value));
                            }
                          }}
                        />
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
          </div>
          <div className="h-[475px] overflow-scroll bg-neutral-gray p-5">
            <SortableList items={listItems} onChange={handleSortChange} renderItem={renderItem} />
          </div>
        </div>
        <Divider className="-mx-6" />
        <div className="mt-5 flex gap-3  px-5">
          <Button className="w-full text-center" size="lg" variant="secondary" onClick={handleResetClick}>
            Reset Default
          </Button>
          <Button type="submit" className="w-full text-center" size="lg">
            Apply
          </Button>
        </div>
      </form>
    </Modal>
  );
};
