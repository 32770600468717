type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

// https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
export const getDirtyValues = (dirtyFields: UnknownArrayOrObject | boolean, allValues: UnknownArrayOrObject): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  type DirtyFieldsIndex = keyof typeof dirtyFields;
  type AllVauesIndex = keyof typeof dirtyFields;

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, getDirtyValues(dirtyFields[key as DirtyFieldsIndex], allValues[key as AllVauesIndex])])
  );
};
