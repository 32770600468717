import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton, BooleanDisplay } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetAccessControlQuery } from "@/redux/apis/config/admin/accessControl";
import { useAppSelector } from "@/redux/hooks";
import { accessControlSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { AccessControlEdit } from "./AccessControlEdit";

export const AccessControl = () => {
  const { isFetching } = useGetAccessControlQuery();
  const { errors, details } = useAppSelector(accessControlSelector);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <Button className="ml-auto animate-enter" startIcon="Edit2" onClick={() => navigate("edit")}>
            Edit
          </Button>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Access Control
            </Typography>
            <Typography className="text-neutral-dark-gray">Enable Access controls for this site.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {renderErrors(errors)}
        <ConfigDetail title="Driver signup enabled?" description="Allow driver signups?">
          <BooleanDisplay status={details.enabledDriverSignup} />
        </ConfigDetail>
        <ConfigDetail title="Client signup enabled?" description="Allow client signups?">
          <BooleanDisplay status={details.enabledClientSignup} />
        </ConfigDetail>
      </Panel>
    </>
  );
};

AccessControl.Edit = AccessControlEdit;
