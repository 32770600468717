import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TextInput, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  model: z.string(),
});

type FormData = z.infer<typeof schema>;

export const ModelFilter = () => {
  const {
    filters: { model },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => model, [model]);

  const onClose = () => setFilter("model", undefined);

  return (
    <FilterPopover name="model" value={value} label="Model" onClose={onClose}>
      <ModelFilterForm />
    </FilterPopover>
  );
};

interface ModelFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const ModelFilterDrawer = ({ open, onOpenChange }: ModelFilterDrawerProps) => {
  const {
    filters: { model },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => model, [model]);

  const onClose = () => setFilter("model", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Model" onClose={onClose}>
      <ModelFilterForm />
    </FilterDrawer>
  );
};

const ModelFilterForm = () => {
  const close = useClose();
  const { setFilter, filters } = useVehicleFilters();

  const { watch, register } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { model: filters.model ? filters.model : "" },
  });

  const value = watch("model");

  useDebouncedEffect(
    () => {
      setFilter("model", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Search Model</Typography>
      <TextInput {...register("model")} size="md" iconName="SearchNormal1" placeholder="Search Model" />
    </form>
  );
};
