import cx from "classnames";
import { Typography } from "@/components/atoms";

export const ActionList = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cx("mt-5 flow-root", className)} {...props}>
      <ul role="list" className="-my-5 divide-y divide-neutral-mid-gray">
        {children}
      </ul>
    </div>
  );
};

export interface ActionListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  title: string;
  description: string;
  action: JSX.Element;
}

const ActionListItem = ({ id, title, description, action, onClick, ...props }: ActionListItemProps) => {
  return (
    <li key={id} className="cursor-pointer px-5 py-5 hover:bg-neutral-surface-gray" {...props} onClick={onClick}>
      <div className="relative flex items-center">
        <div className="flex-1">
          <Typography variant="action">{title}</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray ">
            {description}
          </Typography>
        </div>
        <div>{action}</div>
      </div>
    </li>
  );
};

ActionList.Item = ActionListItem;
