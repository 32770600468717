import { differenceInWeeks } from "date-fns";

export const isDocumentExpiring = (expiry: string | Date, inWeeks = 6) => {
  const expiryDate = typeof expiry === "string" ? new Date(expiry) : expiry;

  return (
    differenceInWeeks(expiryDate, new Date(), {
      roundingMethod: "ceil",
    }) <= inWeeks &&
    differenceInWeeks(expiryDate, new Date(), {
      roundingMethod: "ceil",
    }) > 0
  );
};
