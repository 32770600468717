import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../form";

export const VehicleCategoryNameField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Name of Vehicle Category</Typography>
      <TextInput
        className="w-full"
        placeholder="Enter Name of Vehicle Category"
        maxLength={32}
        hasError={!!errors.name}
        {...register("name")}
      />
      <Typography variant="small" className="leading-relaxed text-neutral-dark-gray">
        This is what your customers will see at booking
      </Typography>
      <ErrorMessage errors={errors} name="name" />
    </div>
  );
};
