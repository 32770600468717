import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { clsx, getOrdinalNumber } from "@/utils";
import { CompaniesFormData } from "../../types";

export const GenerateInvoiceOnField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();
  const watchInvoiceGenerateFrequency = watch("invoiceGenerateFrequency");
  const isHidden = !watchInvoiceGenerateFrequency || ["never", "no_future_jobs", "twice_monthly"].includes(watchInvoiceGenerateFrequency);

  const generateOptions = () => {
    switch (watchInvoiceGenerateFrequency) {
      case "monthly":
        return [...Array(28).keys()].map((i) => ({
          name: `${getOrdinalNumber(i + 1)} of the month`,
          value: `${i + 1}`,
        }));
      case "weekly":
        return [
          { name: "Monday", value: "1" },
          { name: "Tuesday", value: "2" },
          { name: "Wednesday", value: "3" },
          { name: "Thursday", value: "4" },
          { name: "Friday", value: "5" },
          { name: "Saturday", value: "6" },
          { name: "Sunday", value: "7" },
        ];
      default:
        return [];
    }
  };

  return (
    <div className={clsx("flex flex-col gap-1", { hidden: isHidden }, className)} {...props}>
      <Typography>Generate invoice on</Typography>
      <Controller
        control={control}
        name="generateInvoiceOn"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Payment Terms"
            options={generateOptions()}
            value={field.value?.toString()}
            onChange={(value) => field.onChange(value)}
            hasError={!!errors.generateInvoiceOn}
          />
        )}
      />
      <ErrorMessage errors={errors} name="generateInvoiceOn" />
    </div>
  );
};
