import { useWindowSize } from "@react-hookz/web";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownSelect } from "@/components/atoms";
import { clsx } from "@/utils";
import { Tab, TabProps } from "./Tab";

export interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  tabs?: TabProps[];
}

export const Tabs = ({ children, className, tabs, ...props }: TabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const current = pathSegments.filter(Boolean).pop();

  const { width } = useWindowSize();
  const [selected, setSelected] = useState<string | undefined>(current);

  const onChange = (nav: string) => {
    setSelected(nav);
    navigate(nav);
  };

  const options = useMemo(() => {
    return tabs?.map(({ name, to }) => ({ name, value: to }));
  }, [tabs]);

  if (options && width < 1024)
    return (
      <DropdownSelect
        size="sm"
        options={options}
        onChange={onChange}
        value={selected}
        className={clsx("w-full max-w-[320px]", className)}
      />
    );

  return (
    <nav className={clsx("flex max-w-full overflow-auto border-b border-transparent", className)} aria-label="Tabs" {...props}>
      {tabs ? tabs.map((tab, i) => <Tab key={i} {...tab} />) : children}
    </nav>
  );
};

Tabs.Tab = Tab;
