import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useGetVehicleCategoryQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { VehicleCategory } from "@/redux/slices/vehicle/types";

export interface IEditVehicleCategory {
  vehicleCategory: VehicleCategory;
}
export const EditVehicleCategoryContext = createContext<IEditVehicleCategory | null>(null);

export const EditVehicleCategoryProvider = ({ children }: PropsWithChildren) => {
  const { vehicleCategoryId } = useParams<{ vehicleCategoryId: string }>();

  const { data: vehicleCategory, isFetching } = useGetVehicleCategoryQuery(vehicleCategoryId ?? "", { skip: !vehicleCategoryId });
  if (isFetching || !vehicleCategory) return <Loading />;

  return <EditVehicleCategoryContext.Provider value={{ vehicleCategory }}>{children}</EditVehicleCategoryContext.Provider>;
};

export const useEditVehicleCategoryContext = () => {
  const context = useContext(EditVehicleCategoryContext);
  if (!context) {
    throw new Error("useEditVehicleCategoryContext must be used within an EditVehicleProvider");
  }
  return context;
};
