import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useLocalStorageValue } from "@react-hookz/web";
import { getCoreRowModel, getExpandedRowModel, useReactTable } from "@tanstack/react-table";
import { isEmpty } from "ramda";
import { useMemo } from "react";
import { clsx } from "@/utils";
import { NoSearchResults } from "../EmptyStates/NoSearchResults";
import { useDataTableId } from "./hooks/useDataTableId";
import { DataTableProps } from "./types";
import { DefaultTable } from "./variants/DefaultTable";
import { SortableTable } from "./variants/SortableTable";

export const tableClass = "min-w-full";

export const DataTableV2 = <T extends object>({
  data,
  columns: initialColumns,
  onReorder,
  sorting,
  setSorting,
  canExpand,
  renderSubComponent,
  getRowProps,
  getIsRowExpanded,
  emptyState,
  className,
  ...props
}: DataTableProps<T>) => {
  const tableId = useDataTableId();
  const { value: columnSizing } = useLocalStorageValue<Record<string, number>>(tableId);

  const columns = useMemo(() => {
    if (!columnSizing) return initialColumns;

    return initialColumns.map((column) => {
      const accessor = column.id || column.header?.valueOf().toString().toLowerCase();
      if (!accessor) return column;

      const size = columnSizing[accessor];
      return {
        ...column,
        ...(size && { size }),
      };
    });
  }, [columnSizing, initialColumns]);

  const table = useReactTable({
    data,
    columns,
    ...(sorting && {
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      manualSorting: true,
    }),
    ...(canExpand && {
      getRowCanExpand: () => true,
      getExpandedRowModel: getExpandedRowModel(),
      getIsRowExpanded: getIsRowExpanded,
    }),
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
  });

  const renderTable = () => {
    const restProps = { renderSubComponent, getRowProps };

    switch (true) {
      case isEmpty(data):
        return (
          <DefaultTable table={table}>
            <div className="tr w-full hover:!bg-white max-sm:!block">
              <div className="td w-full">{emptyState ? emptyState : <NoSearchResults />}</div>
            </div>
          </DefaultTable>
        );

      case !!onReorder: {
        const onDragEnd = (e: DragEndEvent) => {
          const { active, over } = e;
          const rows = table.getRowModel().rows;

          if (active.id !== over?.id) {
            const activeIndex = rows.findIndex((i) => i.id === active.id);
            const overIndex = rows.findIndex((i) => i.id === over?.id);
            onReorder!(arrayMove(data, activeIndex, overIndex));
          }
        };
        return <SortableTable table={table} onDragEnd={onDragEnd} {...restProps} />;
      }

      default:
        return <DefaultTable table={table} {...restProps} />;
    }
  };

  return (
    <div
      className={clsx(
        "border-1 rounded-lg ring-1 ring-neutral-mid-gray ring-opacity-50 [&_.tbody_.tr:hover]:bg-neutral-gray [&_#icon]:[&_.tbody_.tr:hover]:bg-neutral-mid-gray",
        className
      )}
      {...props}
    >
      {renderTable()}
    </div>
  );
};
