import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { DataTable, Pagination } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination, useProfile, useSorting } from "@/hooks";
import { useLazyGetDebitHistoryItemQuery } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { debitHistorySelector } from "@/redux/slices/payment/selectors";
import { DebitHistoryItem } from "@/redux/slices/payment/types";
import { addToast, clsx, downloadFile } from "@/utils";
import { generateDebitHistoryColumns, generateDebitHistoryData } from "./helpers";

interface DebitHistoryTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: DebitHistoryItem[];
}

export const DebitHistoryTable = ({ items, className, ...props }: DebitHistoryTableProps) => {
  const navigate = useNavigate();
  const { dateFormat } = useProfile();
  const { sorting, setSorting } = useSorting();
  const [getDebitHistoryItem, { isFetching }] = useLazyGetDebitHistoryItemQuery();

  const handleDownloadAction = (item: DebitHistoryItem, document: string) => {
    getDebitHistoryItem({ id: item.id })
      .unwrap()
      .then((res) => {
        switch (document) {
          case "pdf":
            downloadFile(res.data.documents.pdf, "debit-history");
            break;
          case "csv":
            downloadFile(res.data.documents.csv, "debit-history");
            break;
        }
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleViewDebitDetails = (item: DebitHistoryItem) => navigate(item.id);

  const data = useMemo(
    () => generateDebitHistoryData(items, dateFormat, handleDownloadAction, handleViewDebitDetails),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateDebitHistoryColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      {isFetching && <Loading className="rounded-lg" />}
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} sorting={sorting} setSorting={setSorting} {...props} />
    </div>
  );
};

const DebitHistoryTablePagination = () => {
  const {
    page: { current, count, total, size },
  } = useAppSelector(debitHistorySelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

DebitHistoryTable.Pagination = DebitHistoryTablePagination;
