import { Typography, Loading, Modal, Button } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useTriggerPaymentMutation } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { creditCardPaymentsPageStateSelector } from "@/redux/slices/payment/selectors";
import { addToast } from "@/utils";

interface TriggerPaymentModalProps {
  open: boolean;
  onClose: () => void;
}

export const TriggerPaymentModal = ({ open, onClose }: TriggerPaymentModalProps) => {
  const { selectedPayment } = useAppSelector(creditCardPaymentsPageStateSelector);
  const [triggerPayment, { isLoading }] = useTriggerPaymentMutation();

  const handleTriggerPayment = () => {
    if (!selectedPayment) return;
    triggerPayment({ id: selectedPayment.id })
      .unwrap()
      .then(() => {
        addToast("success", "Payment triggered successfully and is now being processed.");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const handleClose = () => !isLoading && onClose();

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="relative">
        {isLoading && <Loading />}
        <div className="max-w-lg">
          <div className="flex flex-col gap-y-3">
            <Typography variant="title">Trigger Payment</Typography>
            <Typography variant="paragraph">
              Clicking &quot;Trigger Payment&quot; will initiate the payment process for the provided credit card.
            </Typography>
          </div>
        </div>
        <div className="mt-[22px] flex justify-end gap-x-4">
          <Button variant="secondary" onClick={handleClose} size="md">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleTriggerPayment} size="md">
            Trigger Payment
          </Button>
        </div>
      </div>
    </Modal>
  );
};
