import { RootState } from "@/redux/store";

export const companiesSelector = (state: RootState) => state.company.companies;
export const companiesPageSelector = (state: RootState) => state.company.companiesPage;
export const associatedClientsSelector = (state: RootState) => state.company.associatedClients;
export const associatedClientsPageSelector = (state: RootState) => state.company.associatedClientsPage;
export const companiesPageStateSelector = (state: RootState) => state.company.companiesPageState;
export const associatedClientsPageStateSelector = (state: RootState) => state.company.associatedClientsPageState;
export const selectedCompanySelector = (state: RootState) => state.company.companiesPageState.selectedCompany;
export const companyActionSelector = (state: RootState) => state.company.companiesPageState.action;
export const associatedClientActionSelector = (state: RootState) => state.company.associatedClientsPageState.action;
export const companyOptionsSelector = (state: RootState) => state.company.options;
