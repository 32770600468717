import { useCallback, useMemo } from "react";
import { DataTable, DataTableSetSorting, DataTableSorting, Pagination, NoFilterResults, NoSearchResults } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersPageSelector } from "@/redux/slices/externalSupplier/selectors";
import { ExternalSupplier } from "@/redux/slices/externalSupplier/types";
import { clsx } from "@/utils";
import { generateTableColumns, generateTableData } from "./helpers";

interface ExternalSuppliersTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: ExternalSupplier[];
  sorting: DataTableSorting;
  setSorting: DataTableSetSorting;
  isSearchActive: boolean;
}

export const ExternalSuppliersTable = ({
  items,
  sorting,
  setSorting,
  isSearchActive,
  className,
  ...props
}: ExternalSuppliersTableProps) => {
  const onUpdateClick = useCallback((id: string) => window.location.replace(`${getPhpHostUrl()}/driver/${id}/profile/`), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => generateTableData(items, onUpdateClick), [items]);
  const columns = useMemo(() => generateTableColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable
        className="[&_table]:table-auto overflow-x-auto w-full"
        canExpand
        columns={columns}
        data={data}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={isSearchActive ? <NoSearchResults /> : <NoFilterResults title="No External Supplier Found" />}
        {...props}
      />
    </div>
  );
};

const ExternalSuppliersTablePagination = () => {
  const { current, count, total, size } = useAppSelector(externalSuppliersPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

ExternalSuppliersTable.Pagination = ExternalSuppliersTablePagination;