import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Typography } from "@/components/atoms";
import { clsx, getCurrencySymbol } from "@/utils";
import { VehicleCategoryFormData } from "../../types";

interface WaitingTimeCostFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  currency: string;
}

export const WaitingTimeCostField = ({ currency, className, ...props }: WaitingTimeCostFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<VehicleCategoryFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Waiting Time Cost per minute</Typography>
      <AddOnTextInput
        className="w-full"
        type="number"
        placeholder="0"
        step="any"
        startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
        hasError={!!errors.waitingTimeCost}
        {...register("waitingTimeCost")}
      />
      <ErrorMessage errors={errors} name="waitingTimeCost" />
    </div>
  );
};
