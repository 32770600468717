import { Page, pageState } from "@/redux/types";

export type PaymentState = {
  invoicing: InvoicingState;
  creditCardPayments: CreditCardPayments;
  payoutHistory: PayoutHistory;
  debitHistory: DebitHistory;
  stripe: StripeState;
};

export type InvoicingState = {
  settings: InvoiceSettings;
  uninvoicedJobs: UninvoicedJobs;
  invoices: Invoices;
  options: InvoicingOptions;
  pageState: InvoicingPageState;
};

export type InvoiceSettings = {
  details: {
    accountName: string;
    accountNumber: string;
    bsb: string;
    beneficiaryBankName: string;
    beneficiaryBankAddress: string;
    swiftCode: string | null;
    enablePaymentOfInvoicesViaCreditCard: boolean;
    creditCardFeePassThrough: boolean;
  };
  history: Array<InvoiceSettingsHistory>;
  historyPage: Page;
};

export type InvoiceSettingsHistory = {
  createdAt: string;
  field: string;
  to: string;
  accountName: string;
};

export type UninvoicedJobs = {
  companies: Array<UninvoicedJobsCompany>;
  page: Page;
  previewInvoice?: PreviewInvoice;
};

export type PreviewInvoice = {
  id: string;
  fileType: string;
  status: string;
  filename: string;
};

export type UninvoicedJobsCompany = {
  id: string;
  name: string;
  invoice: {
    generateOnDate: number | null;
    generateOnFrequency: string | null;
    generateOnLevel: string | null;
  };
  keyContact: {
    name: string;
    email: string;
    phone: string;
  };
  billingContacts: Array<{
    name: string;
    email: string;
    phone: string;
  }>;
  clientJobs: Array<ClientJob>;
  clientJobsPage: Page;
  jobs: Array<Job>;
  jobsPage: Page;
  metadata: {
    unpaidJobValue: number | null;
    unpaidJobCount: number | null;
  };
};

export type ClientJob = {
  id: string;
  name: string;
  email: string;
  count: number;
  cost: number;
  jobs: Array<Job>;
};

export type Job = {
  id: number;
  companyId: string;
  pickupDate: string;
  clientAccountId: string;
  clientName: string;
  clientCostCentre: string;
  passengerName: string;
  referenceNumber: string;
  cost: number;
};

export type Invoices = {
  companies: Array<InvoicesCompany>;
  companiesPage: Page;
  paymentHistory: Array<InvoicePayment>;
  invoiceHistory: Array<InvoiceHistory>;
  invoiceNotes: Array<InvoiceNote>;
  payByCreditCard: {
    paymentMethodSecret?: string;
    paymentMethodSetupId?: string;
    paymentMethodDetails?: PaymentMethodDetails;
    manualPaymentDetails?: ManualPaymentDetails;
    paymentStep: number;
  };
};

export type InvoicesCompany = {
  id: string;
  name: string;
  invoices: Array<Invoice>;
  invoicesPage: Page;
  metadata: {
    unpaidInvoiceCount: number;
    overdueInvoiceCount: number;
    unpaidInvoiceValue: number;
  };
};

export type Invoice = {
  id: string;
  identifier: string;
  company: {
    id: string;
    name: string;
  };
  jobs: Array<number>;
  emailLog: Array<string>;
  status: string;
  currency: string;
  issueDate: string | null;
  dueDate: string;
  amount: number;
  amountPaid: number;
  amountDue: number;
  clientHeldDays: number | null;
  documents: {
    pdf: string;
    csv: string | null;
  };
  metadata: {
    isWriteOffAvailable: boolean;
    isCancelAvailable: boolean;
    isMarkPaidAvailable: boolean;
    isPayByCreditCardAvailable: boolean;
  };
};

export type InvoicingOptions = {
  currency: Array<{ name: string; value: string }>;
  generateOnFrequency: Array<{ name: string; value: string }>;
};

export type InvoicePayment = {
  id: string;
  status: string;
  comment: string;
  type: string;
  createdAt: string;
  receivedAt: string;
  receivedByName: string;
  amountPaid: number;
  metadata: {
    receipt: string;
  };
};

export type InvoiceHistory = {
  createdAt: string;
  field: string;
  to: string;
  accountName: string;
};

export type InvoiceNote = {
  id: string;
  createdAt: string;
  account: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatar: string | null;
  };
  content: string;
  isDeleted: boolean;
};

export type InvoicingPageState = {
  sidePanelAction?: InvoicingSidePanelAction;
  modalAction?: InvoicingModalAction;
  selectedInvoice?: Invoice;
  selectedPayment?: InvoicePayment;
};

export type InvoicingSidePanelAction = "invoiceSetup" | "uninvoicedJobsFilter" | "invoiceLogs" | "invoiceNotes" | "payByCreditCard";

export type InvoicingModalAction = "markAsPaid" | "writeOff" | "undoPayment" | "cancelInvoice";

export type CreditCardPayments = {
  payments: {
    summary: CreditCardPaymentsSummary;
    items: CreditCardPaymentsItem[];
    page: Page;
  };
  takeManualPayment: {
    paymentMethodSecret?: string;
    paymentMethodSetupId?: string;
    paymentMethodDetails?: PaymentMethodDetails;
    manualPaymentDetails?: ManualPaymentDetails;
    paymentStep: number;
  };
  pageState: CreditCardPaymentsPageState;
};

export type CreditCardPaymentsSummary = {
  paid: number;
  failed: number;
  ready: number;
  refund: number;
  currency: string;
};

export type CreditCardPaymentsItem = {
  id: string;
  dateTime: string;
  jobId: string;
  account: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatar: string | null;
  };
  amount: number;
  status: string;
  statusReason: string;
  description: string;
  currency: string;
};

export type PaymentMethodDetails = {
  id: string;
  type: string;
  method: string;
  paymentMethod: {
    last4: string;
    issuer: string;
    expiryMonth: number;
    expiryYear: number;
    countryCode: string;
    token: string;
  };
};

export type ManualPaymentDetails = {
  id: string;
  status: string;
  amounts: {
    subTotal: number;
    fees: number;
    total: number;
  };
};

export type CreditCardPaymentsPageState = {
  sidePanelAction?: CreditCardPaymentsSidePanelAction;
  modalAction?: CreditCardPaymentsModalAction;
  selectedPayment?: CreditCardPaymentsItem;
};

export type CreditCardPaymentsSidePanelAction = "paymentLogs" | "takeManualPayment";

export type CreditCardPaymentsModalAction = "triggerPayment";

export type PayoutHistory = {
  history: {
    items: PayoutHistoryItem[];
    page: Page;
  };
  records: {
    items: PayoutRecordsItem[];
    page: Page;
  };
};

export type PayoutHistoryItem = {
  id: string;
  arrivalDate: string;
  amount: number;
  currency: string;
  description: string;
  status: string;
};

export type PayoutRecordsItem = {
  identifier: string;
  description: string;
  dateTime: string;
  status: string;
  amount: number;
  currency: string;
  accountId: string | null;
};

// For debit history tab
export type DebitHistory = {
  history: {
    items: DebitHistoryItem[];
    page: Page;
  };
  upcoming: {
    summary: DebitUpcomingSummary;
    items: DebitUpcomingItem[];
    page: Page;
  };
  records: {
    items: DebitRecordsItem[];
    page: Page;
  };
  pageState: DebitHistoryPageState;
};

// For debit history tab
export type DebitHistoryItem = {
  id: string;
  creationDate: string;
  payableDate: string;
  amount: number;
  currency: string;
  description: string;
  status: string;
  documents: {
    pdf: string;
    csv: string;
  };
};

export type DebitUpcomingItem = {
  identifier: string;
  dateTime: string;
  status: string;
  amount: number;
  type: string;
};

export type DebitUpcomingSummary = {
  amount: number;
  currency: string;
};

export type DebitRecordsItem = {
  identifier: string;
  dateTime: string;
  description: string;
  status: string;
  amount: number;
  currency: string;
  accountId: string | null;
};

export type DebitHistoryPageState = {
  sidePanelAction?: DebitHistorySidePanelAction;
};

export type DebitHistorySidePanelAction = "currentPendingDebitAmount";

export type StripeState = {
  config: {
    status: string;
    path: string;
    openRequirements: string[];
  };
  debits: {
    id: string;
    clientSecret: string;
    routingNumber: string;
    accountNumber: string;
    accountName: string;
    bankName: string;
    token: string;
    history: DebitsHistory;
  };
  deposits: {
    id: string;
    routingNumber: string;
    accountNumber: string;
    bankName: string;
    history: DepositsHistory;
  };
};

// For payment configuration tab
export type DebitsHistory = {
  items: DebitsHistoryItem[];
  page: Page;
};

// For payment configuration tab
export type DebitsHistoryItem = {
  id: string;
  dateTime: string;
  firstName: string;
  lastName: string;
  event: string;
};

export type DepositsHistory = {
  items: DepositsHistoryItem[];
  page: Page;
};

export type DepositsHistoryItem = {
  id: string;
  dateTime: string;
  firstName: string;
  lastName: string;
  event: string;
};

export const stripeInitialState = {
  config: {
    status: "",
    path: "",
    openRequirements: [],
  },
  debits: {
    id: "",
    clientSecret: "",
    routingNumber: "",
    accountNumber: "",
    accountName: "",
    bankName: "",
    token: "",
    history: {
      items: [],
      page: pageState,
    },
  },
  deposits: {
    id: "",
    routingNumber: "",
    accountNumber: "",
    bankName: "",
    history: {
      items: [],
      page: pageState,
    },
  },
};
