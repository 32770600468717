import { format } from "date-fns";
import { useEffect } from "react";
import { Icon, IconButton, Typography, SidePanel, Loading } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { useLazyGetBookingMessagesQuery } from "@/redux/apis/booking/endpoints/actions";
import { BookingMessageLog } from "@/redux/slices/booking/types";
import { getAssetUrl } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { bookingMessagesColumns } from "../fixtures";
import { BookingMessageData } from "../types";

interface JobMessagingLogProps {
  open: boolean;
  onClose: () => void;
}

export const JobMessagingLog = ({ open, onClose }: JobMessagingLogProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [getMessages, { isFetching, data: messages }] = useLazyGetBookingMessagesQuery();

  useEffect(() => {
    if (open) getMessages(bookingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <SidePanel open={open} onClose={onClose} width={800} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="MessageNotif" size="lg" />
          <Typography variant="title">Job Messaging Log ({bookingId})</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="flex h-full flex-col  p-5">
        {isFetching ? (
          <Loading />
        ) : !messages || !messages.logs.length ? (
          <div className="flex h-full w-full items-center justify-center">
            <Typography>No messages available yet.</Typography>
          </div>
        ) : (
          <>
            <div className="flex justify-between gap-4">
              <div className="w-full rounded-lg bg-neutral-surface-gray p-5">
                <div className="flex gap-3">
                  <img
                    src={getAssetUrl(messages.driver.avatar, { height: 120, width: 120 })}
                    alt={messages.driver.name}
                    className="h-[56px] w-[56px] rounded-full"
                  />
                  <div className="flex flex-col justify-center">
                    <Typography variant="action">{messages.driver.name}</Typography>
                    <Typography variant="small" className="text-neutral-dark-gray ">
                      Driver
                    </Typography>
                  </div>
                </div>
                <div className="flex gap-6">
                  <div className="mt-3">
                    <Typography variant="small">Masked Number</Typography>
                    <Typography className="text-primary">{messages.driver.maskedPhone || "-"}</Typography>
                  </div>
                  <div className="mt-3">
                    <Typography variant="small">Actual Number</Typography>
                    <Typography className="text-primary">{messages.driver.realPhone}</Typography>
                  </div>
                </div>
              </div>
              <div className="w-full rounded-lg bg-neutral-surface-gray p-5">
                <div className="flex gap-3">
                  <img
                    src={getAssetUrl(messages.client.avatar, { height: 120, width: 120 })}
                    alt={messages.client.name}
                    className="h-[56px] w-[56px] rounded-full"
                  />
                  <div className="flex flex-col justify-center">
                    <Typography variant="action">{messages.client.name}</Typography>
                    <Typography variant="small" className="text-neutral-dark-gray ">
                      Passenger
                    </Typography>
                  </div>
                </div>
                <div className="flex gap-6">
                  <div className="mt-3">
                    <Typography variant="small">Masked Number</Typography>
                    <Typography className="text-primary">{messages.client.maskedPhone || "-"}</Typography>
                  </div>
                  <div className="mt-3">
                    <Typography variant="small">Actual Number</Typography>
                    <Typography className="text-primary">{messages.client.realPhone}</Typography>
                  </div>
                </div>
              </div>
            </div>
            {messages?.logs?.length === 0 ? <BookingMessagesEmptyState /> : <BookingMessagesTable messages={messages.logs} />}
          </>
        )}
      </section>
    </SidePanel>
  );
};

const formatDuration = (durationInSeconds: string) => {
  const durationNumber = Number(durationInSeconds);
  if (!durationNumber) return "0 seconds";

  const hours = Math.floor(durationNumber / 3600);
  const minutes = Math.floor((durationNumber % 3600) / 60);
  const seconds = durationNumber % 60;

  return `${hours ? `${hours} hour` : ""} ${minutes ? `${minutes} minutes` : ""} ${seconds ? `${seconds} seconds` : ""}`;
};

const BookingMessagesTable = ({ messages }: { messages: BookingMessageLog[] }) => {
  const { dateFormat, fullTimeFormat } = useProfile();

  const data: BookingMessageData[] = messages.map((i) => ({
    id: i.id,
    date: format(new Date(i.createdAt), dateFormat),
    time: format(new Date(i.createdAt), fullTimeFormat),
    from: <span className="capitalize">{i.sender}</span>,
    communication:
      i.type === "voice" ? (
        <span className="whitespace-normal text-neutral-dark-gray">Call duration: {formatDuration(i.message)}</span>
      ) : (
        <span className="whitespace-normal">{i.message}</span>
      ),
    type:
      i.type === "voice" ? (
        <Icon name="Call" size="sm" className="h-6 text-primary" data-value={i.type} />
      ) : (
        <Icon name="MessageNotif" size="sm" className="h-6 text-primary" data-value={i.type} />
      ),
  }));

  return <DataTable data={data} columns={bookingMessagesColumns} className="mt-5 [&_td]:align-top" />;
};

const BookingMessagesEmptyState = () => {
  return (
    <div className="mt-5 place-items-center rounded-lg border border-neutral-mid-gray p-5">
      <Typography>
        Currently, there are no job messaging logs. However, as soon as drivers and passengers begin their conversations, you&#39;ll see
        them right here.
      </Typography>
    </div>
  );
};
