import { Container, Typography } from "@/components/atoms";
import { GeneralFooter, GeneralHeader } from "@/components/organisms";
import LinkUnlink from "@/assets/images/job-rating/link-unlink.svg";

export const JobRatingExpired = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        <Container className="flex flex-1 flex-col items-center text-center pt-[60px] pb-[338px]">
          <img src={LinkUnlink} alt="LinkUnlink" className="h-64 w-64" />
          <Typography variant="h2" className="mt-6">Rating Period Expired</Typography>
          <div className="mt-8 break-words w-10/12">
            <Typography variant="paragraph">You&apos;ll no longer be able to rate your booking after 7 days.</Typography>
          </div>
        </Container>
        <GeneralFooter />
      </div>
    </div>
  );
};