import { isEmpty } from "ramda";
import { Panel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { useGetPayoutHistoryQuery } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { payoutHistorySelector } from "@/redux/slices/payment/selectors";
import { PayoutHistoryTable } from "./PayoutHistoryTable";
import { useGetPayoutHistoryParams } from "./hooks/useGetPayoutHistoryParams";

export const PayoutHistoryPanel = () => {
  const { params } = useGetPayoutHistoryParams();
  const { isFetching } = useGetPayoutHistoryQuery(params, { refetchOnMountOrArgChange: true });
  const { items } = useAppSelector(payoutHistorySelector);
  const isPayoutHistoryEmpty = isEmpty(items) && !isFetching;

  return (
    <>
      <Panel>
        <header className="flex">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Payout History
            </Typography>
            <Typography className="text-neutral-dark-gray">Here is a list of all payouts made to your nominated bank account</Typography>
          </div>
        </header>
        {isPayoutHistoryEmpty ? (
          <EmptyState title="Payout History" description="There are currently no payout history available" />
        ) : isFetching ? (
          <Skeleton />
        ) : (
          <PayoutHistoryTable items={items} className="mt-4" />
        )}
      </Panel>
      {!isFetching && <PayoutHistoryTable.Pagination />}
    </>
  );
};
