import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./fixtures";
import { pricingReducers } from "./reducers";
import { CostExtrasItem, DynamicPricingAction, FixedPricingAction, TimeAndDayMultiplier, TransferTypeItem, VehicleCategory } from "./types";

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    setVehicleCategories: (state, action: PayloadAction<VehicleCategory[]>) => {
      state.vehicleCategories.items = action.payload;
    },
    toggleVehicleCategory: (state, action: PayloadAction<{ id: string; active: boolean }>) => {
      const item = state.vehicleCategories.items.find((i) => i.id === action.payload.id);
      if (item) item.active = action.payload.active;
    },
    setTimeAndDayMultipliers: (state, action: PayloadAction<TimeAndDayMultiplier[]>) => {
      state.timeAndDay.items = action.payload;
    },
    setCostExtras: (state, action: PayloadAction<CostExtrasItem[]>) => {
      state.costExtras.items = action.payload;
    },
    toggleTimeAndDayMultiplier: (state, action: PayloadAction<{ id: string; active: boolean }>) => {
      const item = state.timeAndDay.items.find((i) => i.id === action.payload.id);
      if (item) item.active = action.payload.active;
    },
    setTransferTypes: (state, action: PayloadAction<TransferTypeItem[]>) => {
      state.transferTypes.items = action.payload;
    },
    toggleTransferType: (state, action: PayloadAction<{ id: string; isActive: boolean }>) => {
      const item = state.transferTypes.items.find((i) => i.id === action.payload.id);
      if (item) item.isActive = action.payload.isActive;
    },
    setDynamicPricingAction: (state, { payload }: PayloadAction<DynamicPricingAction | undefined>) => {
      state.tripCost.dynamicPricing.pageState.action = payload;
    },
    setFixedPricingAction: (state, { payload }: PayloadAction<FixedPricingAction | undefined>) => {
      state.tripCost.fixedPricing.pageState.action = payload;
    },
  },
  extraReducers: (builder) => {
    pricingReducers(builder);
  },
});

export const {
  setVehicleCategories,
  toggleVehicleCategory,
  setTimeAndDayMultipliers,
  setCostExtras,
  toggleTimeAndDayMultiplier,
  setTransferTypes,
  toggleTransferType,
  setDynamicPricingAction,
  setFixedPricingAction,
} = pricingSlice.actions;
export default pricingSlice.reducer;
