import { useLocation } from "react-router-dom";
import { useOperator } from "@/hooks";
import { generateDataTableId } from "../utils";

export const useDataTableId = () => {
  const { id: operatorId } = useOperator();
  const location = useLocation();

  return generateDataTableId(operatorId, location.pathname.split("/").pop() ?? "");
};
