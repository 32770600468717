import { useFormContext } from "react-hook-form";
import { TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";

export const TimeEndField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();
  const allDay = watch("allDay");

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="action">Time End</Typography>
      <TextInput placeholder="00 : 00" type="time" step={1} hasError={!!errors.timeEnd} disabled={allDay} {...register("timeEnd")} />
    </div>
  );
};
