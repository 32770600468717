import { useFormContext } from "react-hook-form";
import { ErrorMessage, PhoneNumberInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { ClientsAddFormData } from "../types";

export const ContactNumberField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ClientsAddFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Contact Number</Typography>
      <PhoneNumberInput control={control} name="contactNumber" hasError={!!errors.contactNumber} />
      <ErrorMessage errors={errors} name="contactNumber" />
    </div>
  );
};
