import { Icon, Typography } from "@/components/atoms";
import { getAssetUrl } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const OperatorDetails = () => {
  const {
    metadata: { isOffloadToPrivate, isOffloadToPublic, isOffloaded },
  } = useSelectedBooking();

  const showOffloadedTo = isOffloaded || isOffloadToPrivate || isOffloadToPublic;

  return (
    <>
      <OperatorFrom />
      {showOffloadedTo && <OffloadedTo />}
    </>
  );
};

const OffloadedTo = () => {
  const { driver } = useSelectedBooking();

  if (!driver) return null;

  const { operator } = driver;

  return (
    <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
      <Typography variant="small" className="text-neutral-black/70">
        Offloaded To:
      </Typography>
      <Typography variant="small">
        <img
          src={getAssetUrl(operator.assets.emblem, { height: 48, width: 48, fit: "cover" })}
          className="mr-2 inline-block h-6 w-6 rounded-full"
        />
        {operator.name}
      </Typography>
      <Typography variant="small" className="ml-4 text-neutral-black/70">
        Contact Number:
      </Typography>
      <Typography variant="small" className="flex-1">
        <a href={`tel:${operator.phone}`}>
          <Icon name="Call" size="sm" className="mr-1 inline text-info sm:hidden" />
          {operator.phone}
        </a>
      </Typography>
    </div>
  );
};

const OperatorFrom = () => {
  const {
    operator,
    metadata: { isOffloaded },
  } = useSelectedBooking();

  return (
    <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
      <Typography variant="small" className="text-neutral-black/70">
        {isOffloaded ? "Offloaded From:" : "Booking From:"}
      </Typography>
      <Typography variant="small">
        <img
          src={getAssetUrl(operator.assets.emblem, { height: 48, width: 48, fit: "cover" })}
          className="mr-2 inline-block h-5 w-5 rounded-full"
        />
        {operator.name}
      </Typography>
      <Typography variant="small" className="text-neutral-black/70 2xl:ml-4">
        Contact Number:
      </Typography>
      <Typography variant="small" className="flex-1">
        <a href={`tel:${operator.phone}`}>
          <Icon name="Call" size="sm" className="mr-1 inline text-info sm:hidden" />
          {operator.phone}
        </a>
      </Typography>
    </div>
  );
};
