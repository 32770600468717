import { Icon, Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { TransferTypeItem } from "@/redux/slices/pricing/types";
import { clsx } from "@/utils";
import { generateColumns } from "./helpers";
import { TransferTypeTableData } from "./types";

const generateTableData = (items: TransferTypeItem[]) => {
  return items.map(
    (i) =>
      ({
        id: i.id,
        name: <Typography className="leading-6">{i.name}</Typography>,
        description: i.description,
        costIncrease: `${i.multiplier > 0 ? "+" : ""}${i.multiplier}%`,
        active: i.isActive ? (
          <Icon name="TickCircle" variant="Bold" className="text-success" />
        ) : (
          <Icon name="CloseCircle" variant="Bold" className="text-danger" />
        ),
      } as TransferTypeTableData)
  );
};

interface TransferTypesTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: TransferTypeItem[];
}

export const TransferTypesTable = ({ items, className, ...props }: TransferTypesTableProps) => {
  const data = generateTableData(items);
  const columns = generateColumns(data);

  return <DataTable className={clsx("[&_table]:table-auto overflow-x-auto w-full", className)} columns={columns} data={data} {...props} />;
};
