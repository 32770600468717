import { Divider, Icon, IconButton, Tooltip, Typography } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAction, setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { bookingsPageStateSelector } from "@/redux/slices/booking/selectors";
import { clsx, formatCurrency } from "@/utils";
import { paymentMethods, paymentStatuses } from "../../../fixtures";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const Cost = () => {
  const dispatch = useAppDispatch();
  const { paymentStatus, status, cost } = useSelectedBooking();
  const { inlineEditing } = useAppSelector(bookingsPageStateSelector);

  const handleEditMode = () => {
    dispatch(setInlineEditing("driver-percentage"));
  };

  const handlePaymentStatusClick = () => {
    dispatch(setAction("payment-logs"));
  };

  const editMode = inlineEditing === "driver-percentage";
  const isEditable = status !== "closed" && status !== "complete_awaiting_review" && status !== "cancelled";

  const className = clsx("space-y-3 relative", {
    "!opacity-100 !pointer-events-auto": editMode,
  });

  return (
    <div className={className}>
      <Icon name="DollarCircle" variant="Bold" size="lg" className="absolute -inset-1.5 z-10 rounded-full bg-primary p-1 text-white" />
      <div className="!mt-0 flex flex-1 flex-col space-y-3">
        {cost.total > 0 && (
          <>
            <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
              <Typography variant="small" className="text-neutral-black/70 xl:w-[130px]">
                Total Cost:
              </Typography>
              <Typography variant="small" className="flex-1 2xl:text-right">
                {formatCurrency(cost.total, 2, cost.currency)}
              </Typography>
            </div>
            <Divider className="my-4" />
          </>
        )}
        {/* 
          TODO: when api field is available
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="min-w-[130px] text-right text-neutral-black/70">
            Available to driver:
            <Tooltip content="This is the amount the driver will receive after all deductions" placement="right">
              <Icon name="InfoCircle" variant="Bold" className="inline-block translate-x-1 rotate-180 text-neutral-dark-gray" size="sm" />
            </Tooltip>
          </Typography>
          <Typography variant="small" className=" flex-1 text-right">
            {formatCurrency(cost.breakdown.driverPayment)}
          </Typography>
        </div> */}
        {!isNaN(Number(cost.percentageToDriver)) && (
          <div
            {...(isEditable && { onClick: handleEditMode })}
            className={clsx(
              "flex flex-col gap-1.5 rounded-md 2xl:flex-row 2xl:items-center 2xl:gap-3",
              isEditable && "!-mb-3 -mr-4 -translate-x-2 -translate-y-1.5 cursor-pointer px-2 py-1.5 hover:bg-neutral-gray"
            )}
          >
            <Typography variant="small" className="text-neutral-black/70 2xl:min-w-[130px] 2xl:text-right">
              Driver %:
              <Tooltip content="Percentage of the total cost that the driver will receive" placement="right">
                <Icon name="InfoCircle" variant="Bold" className="inline-block translate-x-1 rotate-180 text-neutral-dark-gray" size="sm" />
              </Tooltip>
            </Typography>
            <Typography variant="small" className="flex flex-1 gap-1 2xl:justify-end">
              {isEditable && <IconButton onClick={handleEditMode} iconName="Edit2" variant="custom" iconSize="sm" className=" text-info" />}
              {cost.percentageToDriver}%
            </Typography>
          </div>
        )}
        {!isNaN(Number(cost.percentageToVehicle)) && (
          <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3">
            <Typography variant="small" className="min-w-[130px] text-right text-neutral-black/70">
              Vehicle %:
              <Tooltip content="Percentage of the total cost that the vehicle will receive" placement="right">
                <Icon
                  name="InfoCircle"
                  variant="Bold"
                  className=" inline-block  translate-x-1 rotate-180 text-neutral-dark-gray"
                  size="sm"
                />
              </Tooltip>
            </Typography>
            <Typography variant="small" className=" flex-1 text-right">
              {cost.percentageToVehicle}%
            </Typography>
          </div>
        )}
        <Divider className="my-4" />

        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3">
          <Typography variant="small" className="text-neutral-black/70 2xl:min-w-[130px]">
            Est. Driver Payment:
          </Typography>
          <Typography variant="small" className=" flex-1 2xl:text-right">
            {formatCurrency(cost.breakdown.driverPayment, 2, cost.currency)}
          </Typography>
        </div>

        {/* <div className={clsx("flex 2xl:items-center flex-col gap-1.5 2xl:flex-row 2xl:gap-3", !cost.breakdown.platformFee && "hidden")}>
          <Typography variant="small" className="min-w-[130px] text-neutral-black/70">
            Est. Platform fee:
          </Typography>
          <Typography variant="small" className=" flex-1 xl:text-right">
            {formatCurrency(cost.breakdown.platformFee)}
          </Typography>
        </div> */}

        <div className={clsx("flex flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3", !cost.paymentMethod && "hidden")}>
          <Typography variant="small" className="w-[130px] text-neutral-black/70">
            Payment Method:
          </Typography>
          <Typography variant="small" className="flex flex-1 whitespace-nowrap 2xl:justify-end">
            {paymentMethods[cost.paymentMethod] ?? ""}
          </Typography>
        </div>
        <div
          onClick={handlePaymentStatusClick}
          className={clsx(
            "group/status flex cursor-pointer flex-col gap-1.5 2xl:flex-row 2xl:items-center 2xl:gap-3",
            (!paymentStatus || !paymentStatuses[paymentStatus]) && "hidden"
          )}
        >
          <Typography variant="small" className="w-[130px] text-neutral-black/70 group-hover/status:text-info">
            Payment Status:
          </Typography>
          <Typography variant="small" className="flex flex-1 whitespace-nowrap group-hover/status:text-info 2xl:justify-end">
            {paymentStatus ? paymentStatuses[paymentStatus] : "-"}
          </Typography>
        </div>
      </div>
    </div>
  );
};
