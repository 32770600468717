import { clsx } from "@/utils";

export interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number;
}

export const ProgressBar = ({ value = 0, className, ...props }: ProgressBarProps) => {
  return (
    <div className={clsx("relative h-[7px] w-[300px] overflow-hidden rounded-xl bg-info-light", className)} {...props}>
      <div className="h-full bg-info transition-transform ease-linear" style={{ transform: `translateX(-${100 - value}%)` }} />
    </div>
  );
};
