import { useDebouncedState } from "@react-hookz/web";
import { useEffect, useState } from "react";
import { Autocomplete, Button, Icon, IconButton, Loading, Modal, Tooltip, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBookingMutation } from "@/redux/apis/booking/bookingApi";
import { useLazySearchVehicleQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { useAppDispatch } from "@/redux/hooks";
import { setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { BookingVehicle } from "@/redux/slices/booking/types";
import { addToast, clsx } from "@/utils";
import { getDriverErrorTooltip, getDriverWarningTooltip } from "../helpers/driver";
import { useSelectedBooking } from "../hooks/useSelectedBooking";
import { addBookingNotification } from "../utils";

type VehicleOption = {
  id: string;
  name: string;
  error: { key: string; value: string };
  warning: string;
  brand: string;
  model: string;
  year: string;
  color: string;
  reg: string;
  vehicleType: { id: string; name: string; isGlobal: boolean };
};

interface ChangeVehicleProps {
  open: boolean;
  onClose: () => void;
}

export const ChangeVehicle = ({ open, onClose }: ChangeVehicleProps) => {
  const dispatch = useAppDispatch();
  const [newVehicle, setNewVehicle] = useState<BookingVehicle>();
  const { id: bookingId, status, assignedVehicle: currentVehicle } = useSelectedBooking();
  const [updateBooking, { isLoading: isSaving }] = useUpdateBookingMutation();

  const handleSubmit = () => {
    if (currentVehicle === newVehicle?.id) {
      dispatch(setInlineEditing(undefined));
      return;
    }

    updateBooking({
      bookingId,
      assigned_vehicle: newVehicle?.id ?? null,
    })
      .unwrap()
      .then(() => {
        const message = newVehicle ? `Assigned a vehicle (${newVehicle.brand} - ${newVehicle.model})` : "Removed vehicle";
        addBookingNotification(`Job ${bookingId} updated`, message, status);
        dispatch(setInlineEditing(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal open={open} onClose={onClose} className="w-[550px] lg:px-5">
      {isSaving && <Loading />}
      <header className="flex">
        <Typography variant="title" className="flex-1">
          Edit Assigned Vehicle
        </Typography>
        <IconButton iconName="close" iconSize="lg" isCustomIcon variant="custom" onClick={onClose} />
      </header>
      <SelectVehicle newVehicle={newVehicle} onVehicleChange={setNewVehicle} onSubmit={handleSubmit} />
    </Modal>
  );
};

interface SelectVehicleProps {
  newVehicle?: BookingVehicle;
  onVehicleChange: (vehicle?: BookingVehicle) => void;
  onSubmit: () => void;
}

const SelectVehicle = ({ newVehicle, onVehicleChange, onSubmit }: SelectVehicleProps) => {
  const [mode, setMode] = useState<"search" | "remove">("search");
  const { id: bookingId, assignedVehicle: currentVehicle } = useSelectedBooking();
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleOption | null>(null);
  const [query, setQuery] = useDebouncedState("", 300);
  const [searchVehicle, { isFetching, data }] = useLazySearchVehicleQuery();

  useEffect(() => {
    if (query.length > 1) searchVehicle({ q: query, job_uuid: bookingId });
  }, [bookingId, query, searchVehicle]);

  const handleChange = (vehicle: VehicleOption | null) => {
    setSelectedVehicle(vehicle);
    onVehicleChange(
      vehicle
        ? ({
            id: vehicle.id,
            brand: vehicle.brand,
            model: vehicle.model,
            year: vehicle.year,
            color: vehicle.color,
            reg: vehicle.reg,
            vehicleType: {
              id: vehicle.vehicleType.id,
              name: vehicle.vehicleType.name,
              isGlobal: vehicle.vehicleType.isGlobal,
            },
          } as BookingVehicle)
        : undefined
    );
  };

  const options = data?.map(
    (i) =>
      ({
        id: i.id,
        model: i.model,
        year: i.year,
        color: i.color,
        reg: i.reg,
        brand: i.brand,
        vehicleType: i.vehicleType,
        name: `${i.brand} - ${i.model} ${i.reg ? `(${i.reg})` : ""}`,
        error: i.errors.length > 0 ? i.errors[0] : "",
        warning: i.warnings.length > 0 ? i.warnings[0] : "",
      } as VehicleOption)
  );

  const previewVehicle = mode === "remove" ? undefined : newVehicle || currentVehicle;

  return (
    <>
      {previewVehicle && (
        <div className="flex gap-2">
          <figure className="mt-3 flex w-full gap-3 rounded bg-neutral-gray px-4 py-3">
            <Typography variant="small" className="w-[105px] shrink-0  self-start text-neutral-dark-gray">
              Assigned Vehicle:
            </Typography>
            <Typography variant="small" className="flex-1">
              {previewVehicle
                ? `${previewVehicle.brand} - ${previewVehicle.model} ${previewVehicle.year ? `(${previewVehicle.year})` : ""}`
                : "-"}
            </Typography>
            <IconButton
              onClick={() => {
                setMode("remove");
                onVehicleChange(undefined);
              }}
              iconName="Trash"
              variant="custom"
              iconSize="md"
              className="ml-3  text-danger"
            />
          </figure>
        </div>
      )}
      <Typography variant="paragraph" className="mt-3 leading-loose">
        Search Vehicle
      </Typography>
      <Autocomplete
        isLoading={isFetching}
        onQueryChange={setQuery}
        onValueChange={handleChange}
        value={selectedVehicle}
        options={options}
        nullable={false}
        size="md"
        focused
      >
        <Autocomplete.Options className="mt-2 max-h-72 w-full overflow-auto rounded-md border border-neutral-gray bg-white text-base shadow-lg empty:mt-0">
          {options?.map((i) => (
            <Autocomplete.Option
              key={i.id}
              value={i}
              className={clsx("flex w-full cursor-pointer px-4 py-3 hover:bg-primary-light", {
                "cursor-not-allowed text-neutral-dark-gray active:pointer-events-none": i.error,
              })}
            >
              <div className="flex flex-1 items-center gap-2">
                <Typography variant="small" className="overflow-clip text-ellipsis whitespace-nowrap leading-6">
                  {i.name}
                </Typography>
                {i.warning && (
                  <Tooltip content={getDriverWarningTooltip(i.warning)} placement="left">
                    <Icon name="Danger" variant="Bold" size="sm" className="text-warning" />
                  </Tooltip>
                )}

                <div className="flex flex-1 items-center justify-end gap-3">
                  {i.error && (
                    <Tooltip content={getDriverErrorTooltip(i.error)} placement="top">
                      <Typography variant="small" className="inline-flex shrink-0 gap-2 whitespace-nowrap leading-6 text-neutral-dark-gray">
                        <Icon name="InfoCircle" variant="Bold" size="sm" className="text-danger" /> Unable to Select
                      </Typography>
                    </Tooltip>
                  )}
                  <Typography variant="small" className="shrink-0 text-neutral-black">
                    {i.reg}
                  </Typography>
                </div>
              </div>
            </Autocomplete.Option>
          ))}
        </Autocomplete.Options>
      </Autocomplete>

      <Button onClick={onSubmit} variant="primary" size="lg" className="mt-4 w-full">
        Update Assigned Vehicle
      </Button>
    </>
  );
};
