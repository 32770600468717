import { isEmpty } from "ramda";
import { Icon, Typography } from "@/components/atoms";
import { useBookingOfferPageContext } from "../context";

export const OtherInformation = () => {
  const { bookingOffer, setActiveModal } = useBookingOfferPageContext();
  const {
    stops,
    vehicleType,
    jobNumber,
    extras,
    passenger,
    status,
    supplier: { type },
  } = bookingOffer;
  const flight = stops.find((i) => i.flight)?.flight;

  return (
    <div className="mt-6">
      <Typography variant="action" className="block w-full rounded-lg bg-neutral-surface-gray px-[10px] py-[8px]">
        Other Information
      </Typography>
      {flight && (
        <div className="mt-4 flex gap-4">
          <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
            <Icon name="Airplane" size={24} className=" text-neutral-dark-gray" />
            <Typography className=" text-neutral-dark-gray">Flight Number</Typography>
          </div>
          <div className="flex gap-3">
            <Typography>{flight.number}</Typography>
          </div>
        </div>
      )}
      {type === "driver" && (
        <div className="mt-4 flex gap-4">
          <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
            <Icon name="Car" size={24} className=" text-neutral-dark-gray" />
            <Typography className=" text-neutral-dark-gray">Vehicle Category</Typography>
          </div>
          <div className="flex gap-3">
            <Typography>{vehicleType.name}</Typography>
          </div>
        </div>
      )}
      {/* todo: when api becomes available */}
      {/* <div className="mt-4 flex gap-4">
        <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
          <Icon name="MoneySend" size={24} className=" text-neutral-dark-gray" />
          <Typography className=" text-neutral-dark-gray">Transfer Type</Typography>
        </div>
        <div className="flex gap-3">
          <Typography>{vehicleType.name}</Typography>
        </div>
      </div> */}
      <div className="mt-4 flex gap-4">
        <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
          <Icon name="Archive" size={24} className=" text-neutral-dark-gray" />
          <Typography className=" text-neutral-dark-gray">Job Reference</Typography>
        </div>
        <div className="flex gap-3">
          <Typography>{jobNumber}</Typography>
        </div>
      </div>

      <div className="mt-4 flex gap-4">
        <div className="flex w-[120px]  shrink-0 items-center gap-2 md:w-[200px]">
          <Icon name="EmptyWallet" size={24} className=" text-neutral-dark-gray" />
          <Typography className=" text-neutral-dark-gray">Extras</Typography>
        </div>
        <div className="block space-x-3">
          {!isEmpty(extras) ? (
            extras.map((i, idx) => (
              <div key={idx} className="inline-block">
                <div className="mr-2 inline-block h-2 w-2 rounded-full bg-neutral-dark-gray" />
                <Typography variant="small">{i.name}</Typography>
              </div>
            ))
          ) : (
            <Typography>-</Typography>
          )}
        </div>
      </div>
      {passenger && (
        <>
          <div className="mt-4 flex gap-4">
            <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
              <Icon name="User" size={24} className=" text-neutral-dark-gray" />
              <Typography className=" text-neutral-dark-gray">Guest</Typography>
            </div>
            <div className="flex gap-3">
              {status === "driver_allocated_not_confirmed" ? (
                <Typography className="cursor-pointer text-info" onClick={() => setActiveModal("accept")}>
                  Accept booking to see guest
                </Typography>
              ) : (
                <Typography>{passenger.name}</Typography>
              )}
            </div>
          </div>
          <div className="mt-4 flex gap-4">
            <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
              <Icon name="Profile" size={24} className=" text-neutral-dark-gray" />
              <Typography className=" text-neutral-dark-gray">Pax</Typography>
            </div>
            <div className="flex gap-3">
              <Typography>{passenger.passengerCount}</Typography>
            </div>
          </div>
          <div className="mt-4 flex gap-4">
            <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
              <Icon name="Call" size={24} className=" text-neutral-dark-gray" />
              <Typography className=" text-neutral-dark-gray">Contact Info</Typography>
            </div>
            <div className="flex gap-3">
              {status === "driver_allocated_not_confirmed" ? (
                <Typography className="cursor-pointer text-info" onClick={() => setActiveModal("accept")}>
                  Accept booking to see contact info
                </Typography>
              ) : (
                <Typography>{passenger.phone}</Typography>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
