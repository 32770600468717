import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Typography } from "@/components/atoms";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { useGetVehicleFormOptionsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  type: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const TypeFilter = () => {
  const {
    filters: { type },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!type) return undefined;

    return type.filter(Boolean).join(", ");
  }, [type]);

  const onClose = () => setFilter("type", undefined);

  return (
    <FilterPopover name="type" value={value} label="Vehicle Type" onClose={onClose}>
      <TypeFilterForm />
    </FilterPopover>
  );
};

interface TypeFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const TypeFilterDrawer = ({ open, onOpenChange }: TypeFilterDrawerProps) => {
  const {
    filters: { type },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!type) return undefined;

    return type.filter(Boolean).join(", ");
  }, [type]);

  const onClose = () => setFilter("type", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Vehicle Type" onClose={onClose}>
      <TypeFilterForm />
    </FilterDrawer>
  );
};

const TypeFilterForm = () => {
  const { setFilter, filters } = useVehicleFilters();
  const { isFetching, data: options } = useGetVehicleFormOptionsQuery();

  const types = useMemo(() => {
    if (isFetching || !options) return [];

    return options.type.map((type) => ({
      name: type,
      value: type,
    }));
  }, [isFetching, options]);

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { type: (filters.type as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "type",
          types.map((type) => type.value)
        );
        setValue(
          "type",
          types.map((type) => type.value)
        );
      } else {
        setFilter("type", null);
        setValue("type", []);
      }
    },
    [types, setFilter, setValue]
  );

  const isAllTypesSelected = useMemo(() => {
    if (!filters.type) return false;
    return filters.type.length === types.length;
  }, [types.length, filters.type]);

  return (
    <div className="contents">
      <Typography className="mb-4">Vehicle Type</Typography>
      <CheckboxInput label="All Vehicle Types" onChange={handleCheckAll} variant="check" checked={isAllTypesSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <>
              {types.map((type) => (
                <CheckboxInput
                  key={type.value}
                  className="capitalize"
                  label={getVehicleTypeLabel(type.value)}
                  value={type.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newType = [...field.value, e.target.value];
                      field.onChange(newType);
                      setFilter("type", newType.length > 0 ? newType : null);
                    } else {
                      const newType = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newType);
                      setFilter("type", newType.length > 0 ? newType : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(type.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
