import { Alert } from "@/components/atoms";
import { isEmpty } from "ramda";

export const renderDispatchTime = (time?: string | number, timeFrame?: string) => {
  if (time && timeFrame) {
    return `${time} ${timeFrame}${Number(time) !== 1 ? "s" : ""}`;
  }

  return null;
};

export const renderErrors = (errors: string[]) => {
  return !isEmpty(errors) ? (
    <div className="mt-4 space-y-3">
      {errors.map((error, i) => (
        <Alert key={i} type="danger" message={error} className="w-full" />
      ))}
    </div>
  ) : null;
};