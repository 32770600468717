import { isEmpty } from "ramda";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { ExtrasTable, PageHeader } from "@/components/organisms";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetCostExtrasQuery } from "@/redux/apis/config/pricing/costExtras";
import { useAppSelector } from "@/redux/hooks";
import { costExtrasPageSelector, costExtrasSelector } from "@/redux/slices/pricing/selectors";
import { addToast } from "@/utils";
import { FixedCostExtrasEdit } from "./FixedCostExtrasEdit";

export const FixedCostExtras = () => {
  const navigate = useNavigate();
  const items = useAppSelector(costExtrasSelector);
  const [getCostExtras, { isFetching }] = useLazyGetCostExtrasQuery();
  const { current, count, total, size } = useAppSelector(costExtrasPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();

  useEffect(() => {
    getCostExtras({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getCostExtras]);

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <>
          <PageHeader.Actions />
          <Panel>
            <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
              <div className="flex-1">
                <Typography variant="h3" className="leading-8">
                  Fixed Cost Extras
                </Typography>
                <Typography className="text-neutral-dark-gray">
                  Configure the fixed cost extras your customers can select when making a booking.
                </Typography>
              </div>
              <div className="flex items-center">
                <Button startIcon="Edit2" onClick={() => navigate("edit")}>
                  Edit
                </Button>
              </div>
            </div>
            {isEmpty(items) ? (
              <EmptyState
                title="Fixed Cost Extras"
                description="It looks like you haven't added any fixed cost extras yet. Begin by adding your first fixed cost extra in the edit page to get started"
              />
            ) : (
              <ExtrasTable className="mt-4" items={items} />
            )}
          </Panel>
          <Pagination
            onPageClick={handlePageClick}
            onSizeChange={handleSizeChange}
            currentPage={current}
            pageSize={size}
            totalItems={total}
            pageCount={count}
          />
        </>
      )}
    </>
  );
};

FixedCostExtras.Edit = FixedCostExtrasEdit;
