import { IconButton, Tooltip, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useAppDispatch } from "@/redux/hooks";
import { setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { clsx } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const AssignedVehicle = () => {
  const dispatch = useAppDispatch();

  const {
    flags: { vehicleAssignmentEnabled },
  } = useOperator();

  const {
    assignedVehicle: vehicle,
    metadata: { isVehicleEditAllowed },
  } = useSelectedBooking();

  const handleEditMode = () => {
    dispatch(setInlineEditing("change-vehicle"));
  };

  const enableEditMode = vehicleAssignmentEnabled && isVehicleEditAllowed;

  return (
    <div className="relative space-y-3">
      <div
        {...(enableEditMode && { onClick: handleEditMode })}
        className={clsx(
          "flex flex-col gap-1.5 rounded-md 2xl:flex-row 2xl:gap-3",
          enableEditMode && "!-mb-3 -mr-4 -translate-x-2 -translate-y-1.5 cursor-pointer px-2 py-1.5 hover:bg-neutral-gray"
        )}
      >
        <Typography variant="small" className="w-[105px] shrink-0 self-start text-neutral-black/70">
          Assigned Vehicle:
        </Typography>
        <Typography variant="small" className="flex flex-1 items-center gap-2">
          {vehicle ? `${vehicle.brand} - ${vehicle.model} ${vehicle.reg ? `(${vehicle.reg})` : ""}` : "-"}
          {vehicle && (
            <Tooltip content={`Color: ${vehicle.color}`} placement="right">
              <i className="-my-1 inline-block h-4 w-4 shrink-0 rounded-full border " style={{ backgroundColor: vehicle.color }} />
            </Tooltip>
          )}
          {enableEditMode && (
            <IconButton
              onClick={handleEditMode}
              iconName="Edit2"
              variant="tertiary"
              iconSize="sm"
              className="flex-1 justify-end p-0 text-info hover:bg-transparent"
            />
          )}
        </Typography>
      </div>
    </div>
  );
};
