import { Filter, Page } from "@/redux/types";

export type ClientState = {
  clients: Client[];
  page: Page;
  options: ClientOptions;
};

export type Client = {
  id: string;
  name: string;
  email: string;
  phone: string;
  companyId: string;
  companyName: string;
  accountType: string;
  hearAboutUs: string;
  joined: string;
  status: string;
};

export type ClientOptions = {
  accountType: Filter;
  status: Filter;
};

export type ClientSearchItem = {
  id: string;
  name: string;
  email: string;
  phone: string;
  company: {
    id: string;
    name: string;
  } | null;
  avatar: string | null;
};

export const clientOptionsInitialState = {
  accountType: {},
  status: {},
};
