import { Panel, Typography } from "@/components/atoms";
import { PageList } from "@/components/molecules";
import { HowItWorks } from "@/components/organisms";
import { PricingState } from "./PricingState";

type Subpage = {
  name: string;
  desc: string;
  to: string;
  icon: number;
};

const subpages: Subpage[] = [
  {
    name: "State Based Dynamic Pricing",
    desc: "Set dynamic pricing per state",
    to: "state-based",
    icon: 1,
  },
];

export const DynamicPricing = () => {
  return (
    <div className="flex flex-1 flex-col items-start gap-5 lg:flex-row">
      <Panel className="flex flex-1 flex-col">
        <Typography variant="h3" className="leading-8">
          Dynamic Pricing &#40;Trip Cost&#41;
        </Typography>
        <Typography className="text-neutral-dark-gray">
          Configure your preferred method of charging customers per trip using dynamic pricing.
        </Typography>
        <PageList className="-mx-5 mt-2">
          {subpages.map(({ name, desc, to, icon }) => (
            <PageList.Item key={name} title={name} description={desc} to={to} icon={icon} />
          ))}
        </PageList>
      </Panel>
      <HowItWorks
        description="The pricing hierarchy for Dynamic Pricing:"
        items={[
          "Apply Location Based Pricing if the pickup point falls within the coverage area.",
          "If no Location Based Pricing is applicable, utilize State Based Pricing.",
          "If none of the above options are applicable, fallback to Dynamic Pricing.",
        ]}
      />
    </div>
  );
};

DynamicPricing.PricingState = PricingState;
