import { createContext, useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetDynamicPricingStatesQuery } from "@/redux/apis/config/pricing/tripCost";
import { useAppSelector } from "@/redux/hooks";
import { dynamicPricingStateSelector, dynamicPricingStatesSelector } from "@/redux/slices/pricing/selectors";
import { DynamicPricingState } from "@/redux/slices/pricing/types";

interface DynamicPricingStateState {
  pricingStates: DynamicPricingState[];
  stateName: string | null;
  isFetching: boolean;
  isRestricted: boolean;
  pricingState?: DynamicPricingState;
}

const DynamicPricingStateContext = createContext<DynamicPricingStateState | null>(null);

export const DynamicPricingStateContextProvider = ({ children }: React.PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stateName = searchParams.get("stateName");
  const { slug = "" } = useParams();

  const { isFetching } = useGetDynamicPricingStatesQuery();
  const pricingStates = useAppSelector(dynamicPricingStatesSelector);
  const pricingState = useAppSelector(dynamicPricingStateSelector(slug));
  const [isRestricted, setIsRestricted] = useState(false);

  // breadcrumbs setup
  useEffect(() => {
    if (pricingState && !pricingState.managed && stateName !== pricingState.state.toUpperCase()) {
      searchParams.set("stateName", pricingState.state.toUpperCase());
      setSearchParams(searchParams);
    }
  }, [pricingState, stateName, searchParams, setSearchParams]);

  // restricted check
  useEffect(() => {
    if (!isFetching && (!pricingState || pricingState.managed)) setIsRestricted(true);
  }, [isFetching, pricingState]);

  return (
    <DynamicPricingStateContext.Provider value={{ pricingStates, stateName, isFetching, isRestricted, pricingState }}>
      {children}
    </DynamicPricingStateContext.Provider>
  );
};

export const useDynamicPricingStateContext = () => {
  const context = useContext(DynamicPricingStateContext);
  if (!context) throw new Error("useDynamicPricingStateContext must be used within a DynamicPricingStateContextProvider");

  return context;
};
