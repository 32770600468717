import { z } from "zod";

export const addVehicleCategoryFormSchema = z
  .object({
    name: z.string().min(1, "Field is required and can't be empty.").max(32, "Maximum of 32 characters."),
    class: z.string().min(1, "Field is required and can't be empty."),
    type: z.string().min(1, "Field is required and can't be empty."),
    capacity: z
      .number({
        message: "Field is required and can't be empty.",
      })
      .min(0)
      .max(999)
      .step(1, "Field must be a whole number."),
    bags: z
      .number({
        message: "Field is required and can't be empty.",
      })
      .min(0)
      .max(999)
      .step(1, "Field must be a whole number."),
    waitTimeCost: z
      .number({
        message: "Field is required and can't be empty.",
      })
      .min(0)
      .max(999)
      .step(0.01, "Field must be a maximum of 2 decimal places."),
    costAdjustment: z
      .number({
        message: "Field is required and can't be empty.",
      })
      .min(-99)
      .max(999)
      .step(0.01, "Field must be a maximum of 2 decimal places."),
    associatedVehicles: z.array(z.string()),

    //advanced settings
    enableCustomCommission: z.boolean(),
    commissionRate: z.number().optional(),

    //offload settings
    enableCustomOffload: z.boolean(),
    offloadDispatchTolerance: z.string().optional(),
    offloadEngineTypes: z.array(z.string()).optional(),
    offloadMaxVehicleAge: z.number().optional(),
    offloadMinimumRating: z.number().optional(),
    offloadVehicleColors: z.array(z.string()).optional(),
    offloadEnableSpecificModels: z.boolean(),
    offloadVehicleModels: z.array(z.string()).optional(),
  })
  .refine(
    (data) => {
      if (data.enableCustomCommission) {
        return data.commissionRate !== undefined && data.commissionRate >= -90 && data.commissionRate <= 90;
      }
      return true;
    },
    {
      message: "Field must be a whole number.",
      path: ["commissionRate"],
    }
  )
  .refine(
    (data) => {
      if (data.enableCustomOffload) {
        return data.offloadDispatchTolerance !== undefined && data.offloadDispatchTolerance.length > 0;
      }
      return true;
    },
    {
      message: "Field is required and can't be empty.",
      path: ["offloadDispatchTolerance"],
    }
  )
  .refine(
    (data) => {
      if (data.enableCustomOffload) {
        return data.offloadEngineTypes !== undefined && data.offloadEngineTypes.length > 0;
      }
      return true;
    },
    {
      message: "Select at least one option",
      path: ["offloadEngineTypes"],
    }
  )
  .refine(
    (data) => {
      if (data.enableCustomOffload) {
        return data.offloadMaxVehicleAge !== undefined && data.offloadMaxVehicleAge >= 1 && data.offloadMaxVehicleAge <= 99;
      }
      return true;
    },
    {
      message: "Must contain at least one number",
      path: ["offloadMaxVehicleAge"],
    }
  )
  .refine(
    (data) => {
      if (data.enableCustomOffload) {
        return data.offloadMinimumRating !== undefined && data.offloadMinimumRating >= 1 && data.offloadMinimumRating <= 5;
      }
      return true;
    },
    {
      message: "Minimum rating is 1 star",
      path: ["offloadMinimumRating"],
    }
  )
  .refine(
    (data) => {
      if (data.enableCustomOffload) {
        return data.offloadVehicleColors !== undefined && data.offloadVehicleColors.length > 0;
      }
      return true;
    },
    {
      message: "Must contain at least one color",
      path: ["offloadVehicleColors"],
    }
  )
  .refine(
    (data) => {
      if (data.enableCustomOffload && data.offloadEnableSpecificModels) {
        return data.offloadVehicleModels !== undefined && data.offloadVehicleModels.length > 0;
      }
      return true;
    },
    {
      message: "Must select at least one vehicle",
      path: ["offloadVehicleModels"],
    }
  );
export type AddVehicleCategoryFormData = z.infer<typeof addVehicleCategoryFormSchema>;
