import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Divider, Icon, IconButton, Loading, Typography, SidePanel } from "@/components/atoms";
import { useGetExternalSuppliersFormOptionsQuery } from "@/redux/apis/externalSupplier/externalSupplierApi";
import { RatingFilter, StateFilter, StatusFilter, TypeFilter } from "./filters";
import { defaultFilters } from "./fixtures";
import { ExternalSuppliersFilters } from "./types";

interface ExternalSuppliersFilterPanelProps {
  open: boolean;
  onClose: () => void;
  filters: ExternalSuppliersFilters;
  setFilters: (filters: ExternalSuppliersFilters) => void;
}

export const ExternalSuppliersFilterPanel = ({ open, onClose, filters, setFilters }: ExternalSuppliersFilterPanelProps) => {
  const { isFetching } = useGetExternalSuppliersFormOptionsQuery();
  const methods = useForm<ExternalSuppliersFilters>({
    defaultValues: filters,
  });
  const { handleSubmit, reset } = methods;
  const handleFormSubmit = handleSubmit((data) => {
    setFilters(data);
    onClose();
  });

  useEffect(() => {
    reset(filters);
  }, [filters, reset]);

  return (
    <SidePanel open={open} onClose={onClose} width={428} focused>
      <div className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="Sort" size="lg" />
          <Typography variant="title">Filter</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="relative flex h-full flex-col" onSubmit={handleFormSubmit}>
          {isFetching && <Loading />}
          <div className="h-full flex-1 overflow-y-auto">
            <div className="flex flex-col gap-y-4 px-4 py-6">
              <TypeFilter />
              <StateFilter />
              <RatingFilter />
              <StatusFilter />
            </div>
          </div>
          <div>
            <Divider />
            <div className="grid gap-3 px-4 py-5">
              <Button type="submit" className="w-full text-center" size="lg">
                Filter Results
              </Button>
              <Button className="w-full text-center" size="lg" variant="secondary" onClick={() => reset(defaultFilters)}>
                Reset Filters
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </SidePanel>
  );
};
