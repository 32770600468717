import { Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setFixedPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { fixedPricingPageStateSelector } from "@/redux/slices/pricing/selectors";
import { PricingProfile } from "@/redux/slices/pricing/types";
import { CompaniesAttached } from "./contents/CompaniesAttached";
import { DeletePricingProfileForm } from "./forms/DeletePricingProfileForm";
import { EditPricingProfileForm } from "./forms/EditPricingProfileForm";

interface PricingProfileModalsProps extends React.HTMLAttributes<HTMLDivElement> {
  pricingProfile: PricingProfile;
}

export const PricingProfileModals = ({ pricingProfile }: PricingProfileModalsProps) => {
  const dispatch = useAppDispatch();
  const { action } = useAppSelector(fixedPricingPageStateSelector);

  const onClose = () => dispatch(setFixedPricingAction(undefined));

  return (
    <>
      <Modal className="w-[85vw] md:w-[510px] lg:w-[510px]" open={action === "editPricingProfile"} onClose={onClose}>
        <EditPricingProfileForm
          item={pricingProfile}
          onClose={onClose}
          onDelete={() => dispatch(setFixedPricingAction("deletePricingProfile"))}
        />
      </Modal>
      <Modal className="w-[85vw] md:w-[510px] lg:w-[510px]" open={action === "deletePricingProfile"} onClose={onClose}>
        <DeletePricingProfileForm item={pricingProfile} onClose={onClose} />
      </Modal>
      <Modal className="w-[85vw] md:w-[510px] lg:w-[510px]" open={action === "attachedCompanies"} onClose={onClose}>
        <CompaniesAttached item={pricingProfile} onClose={onClose} />
      </Modal>
    </>
  );
};
