import { Icon, Modal, Typography } from "@/components/atoms";
import { TimeAndDayMultiplier } from "@/redux/slices/pricing/types";
import { EditTimeAndDayMultiplierForm } from "../forms/EditTimeAndDayMultiplierForm";

interface EditTimeAndDayMultiplierModalProps {
  item?: TimeAndDayMultiplier;
  open: boolean;
  onClose: () => void;
}

export const EditTimeAndDayMultiplierModal = ({ item, open, onClose }: EditTimeAndDayMultiplierModalProps) => {
  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Edit Time & Day
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
        </div>
      </div>
    </div>
  );

  return (
    <Modal open={open} onClose={onClose} className="w-full max-w-[517px]">
      {renderHeader()}
      <EditTimeAndDayMultiplierForm item={item} onClose={onClose} />
    </Modal>
  );
};