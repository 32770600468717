import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, DropdownSelectOption, ErrorMessage, Loading, Typography } from "@/components/atoms";
import { AddExternalSupplierFormData } from "../types";
import { addToast, clsx, getCountryName } from "@/utils";
import { useOperator } from "@/hooks";
import { useLazyGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useEffect, useState } from "react";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { isEmpty } from "ramda";

export const StateField = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<AddExternalSupplierFormData>();
  const { bookableCountryList, country: defaultCountry } = useOperator();
  const [getCountryStates, { isFetching }] = useLazyGetCountryStatesQuery();
  const [country, setCountry] = useState(defaultCountry);
  const [options, setOptions] = useState<Array<DropdownSelectOption>>([]);

  useEffect(() => {
    getCountryStates(country)
      .unwrap()
      .then(data => setOptions(data.data.states.map(state => ({ name: state.name, value: state.id }))))
      .catch((e) => {
        getErrorMessages(e).forEach((m) => {
          if (m === "Not found") addToast("danger", "No states found.");
          else addToast("danger", m);
        });
        setOptions([]);
        setValue("state", "");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const getCountryOptions = () => {
    const options: Array<{ name: string; value: string }> = [];
    bookableCountryList.forEach(countryCode => {
      const country = getCountryName(countryCode);
      if (country) options.push({ name: country, value: countryCode });
    })

    return options;
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      {isFetching && <Loading />}
      {bookableCountryList.length > 1 && (
        <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
          <Typography variant="paragraph">Country</Typography>
          <DropdownSelect
            className="w-full"
            options={getCountryOptions()}
            placeholder="Select Country"
            value={country}
            onChange={(value: string) => setCountry(value)}
          />
        </div>
      )}
      <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
        <Typography variant="paragraph">State</Typography>
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <DropdownSelect
              className="w-full"
              options={options}
              placeholder="Select State"
              value={field.value?.toString()}
              onChange={(value) => field.onChange(value)}
              hasError={!!errors.state}
              disabled={isEmpty(options)}
            />
          )}
        />
        <ErrorMessage errors={errors} name="state" />
      </div>
    </div>
  );
};
