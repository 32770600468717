import { z } from "zod";

const defaultBaseIntervalFormSchema = z.object({
  minimumRange: z.coerce.number(),
  maximumRange: z.coerce.number().min(0).max(99999).step(1, "Field must be a whole number."),
  intervalCost: z.coerce.number().min(0).max(999999999).step(0.01, "Field must be a maximum of 2 decimal places."),
  managementCostUnit: z.string(),
  managementCost: z.coerce.number(),
  note: z.string(),
});

const defaultIntervalsFormSchema = z.object({
  maximumRange: z.coerce.number().min(0).max(99999).step(1, "Field must be a whole number."),
  intervalCost: z.coerce.number().min(0).max(999999999).step(0.01, "Field must be a maximum of 2 decimal places."),
  managementCostUnit: z.string(),
  managementCost: z.coerce.number(),
  note: z.string(),
});

const percentageManagementCostSchema = z.object({
  managementCostUnit: z.literal("percentage"),
  managementCost: z.coerce.number().min(0).max(9999).step(1, "Field must be a whole number."),
});

const fixedManagementCostSchema = z.object({
  managementCostUnit: z.literal("fixed"),
  managementCost: z.coerce.number().min(0).max(999999999).step(0.01, "Field must be a maximum of 2 decimal places."),
});

const managementCostSchemaUnion = z.discriminatedUnion("managementCostUnit", [percentageManagementCostSchema, fixedManagementCostSchema]);

const baseIntervalFormSchema = z.intersection(defaultBaseIntervalFormSchema, managementCostSchemaUnion);

const intervalsFormSchema = z.intersection(defaultIntervalsFormSchema, managementCostSchemaUnion);

export const fixedPricingFormSchema = z.object({
  baseInterval: baseIntervalFormSchema,
  intervals: z.array(intervalsFormSchema),
});

export const locationBasedFormSchema = z.intersection(
  z.object({
    name: z.string().nonempty("Field is required and can't be empty.").max(65),
  }),
  fixedPricingFormSchema
);

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  deleteFixedPricing: {
    title: "Delete Fixed Pricing",
    description:
      'By clicking "Continue" all the custom pricing for your trips will be\ndeleted. Please note that this action is irreversible. Are you\nsure you want to proceed?',
  },
};
