import { FormProvider, useForm } from "react-hook-form";
import { Button, Loading } from "@/components/atoms";
import { useEditTimeOfDayMultiplierMutation } from "@/redux/apis/config/pricing/timeOfDay";
import { TimeAndDayMultiplier } from "@/redux/slices/pricing/types";
import { NameField } from "./fields/NameField";
import { CostMultiplierField } from "./fields/CostMultiplierField";
import { TimeDateField } from "./fields/TimeDateField";
import { TreatAsSpecialEventField } from "./fields/TreatAsSpecialEventField";
import { StatusField } from "./fields/StatusField";
import { TimeAndDayFormData } from "../types";
import { zodResolver } from "@hookform/resolvers/zod";
import { timeAndDayFormSchema } from "../fixtures";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { addToast } from "@/utils";
import { format, parseISO } from "date-fns";

interface EditTimeAndDayMultiplierFormProps {
  item?: TimeAndDayMultiplier;
  onClose: () => void;
}

export const EditTimeAndDayMultiplierForm = ({ item, onClose }: EditTimeAndDayMultiplierFormProps) => {
  const [editTimeOfDayMultiplier, { isLoading }] = useEditTimeOfDayMultiplierMutation();
  const isSpecificDate = item?.type === "date_time_based";
  const methods = useForm<TimeAndDayFormData>({
    defaultValues: {
      name: item?.name,
      costMultiplier: item?.multiplier.toString(),
      specificDate: isSpecificDate,
      timeStart: item?.startTime ? isSpecificDate ? format(parseISO(item.startTime), "HH:mm:ss") : item.startTime : undefined,
      timeEnd: item?.endTime ? isSpecificDate ? format(parseISO(item.endTime), "HH:mm:ss") : item.endTime : undefined,
      dateStart: isSpecificDate && item.startTime ? parseISO(item.startTime) : undefined,
      dateEnd: isSpecificDate && item.endTime ? parseISO(item.endTime) : undefined,
      allDay: false,
      repeatYearly: item?.isRecurring,
      treatAsSpecialEvent: item?.isSpecialEvent,
      status: item?.active,
    },
    resolver: zodResolver(timeAndDayFormSchema),
  });
  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    if (!item) return;

    let start = data.timeStart;
    let end = data.timeEnd;

    if (data.specificDate && data.dateStart && data.dateEnd) {
      const date = format(new Date(), "yyyy-MM-dd");
      const startTime =  new Date(`${date} ${data.timeStart}`);
      const endTime = new Date(`${date} ${data.timeEnd}`);
      const startDate = data.dateStart.setHours(startTime.getHours(), startTime.getMinutes(), startTime.getSeconds());
      const endDate = data.dateEnd.setHours(endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());
      start = format(startDate, "yyyy-MM-dd'T'HH:mm:ss");
      end = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");
    }

    editTimeOfDayMultiplier({
      uuid: item.id,
      name: data.name,
      multiplier: Number(data.costMultiplier),
      type: data.specificDate ? "date_time_based" : "time based",
      start_time: start,
      end_time: end,
      is_recurring: data.specificDate ? data.repeatYearly : false,
      is_special_event: data.treatAsSpecialEvent,
      is_active: data.status,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Time & Day");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <FormProvider {...methods}>
      <form id="add-multiplier-form" className="relative flex min-h-[550px] w-[515px] max-w-full flex-col" onSubmit={onSubmit}>
        {isLoading && <Loading className="z-20" />}
        <div className="flex-1 space-y-5 py-8">
          <NameField />
          <CostMultiplierField />
          <TimeDateField />
          <TreatAsSpecialEventField />
          <StatusField />
        </div>
        <div className="block">
          <div className="flex justify-end  gap-4">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" form="add-multiplier-form" disabled={!isDirty}>
              Update Time & Day
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};