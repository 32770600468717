import { formatRFC3339 } from "date-fns";
import { useState } from "react";
import { Button, DropdownSelect } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOverrideTripTimestampMutation } from "@/redux/apis/booking/endpoints/actions";
import { addToast } from "@/utils";

export interface OverrideTripFormProps {
  bookingId: string;
  timezone: string;
  dateTime: string;
  onClose: () => void;
}

export const OverrideTripForm = ({ bookingId, dateTime, onClose }: OverrideTripFormProps) => {
  const [selected, setSelected] = useState<string>();
  const [overrideTimeStamp, { isLoading }] = useOverrideTripTimestampMutation();

  const handleSelect = (value: string) => {
    setSelected(value);
  };

  const handleClick = () => {
    if (!selected) return;

    const date = new Date(dateTime);

    overrideTimeStamp({
      bookingId,
      payload: {
        type: selected,
        timestamp: formatRFC3339(date),
      },
    })
      .unwrap()
      .then(() => {
        addToast("success", "Trip point override successful");
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
      })
      .finally(onClose);
  };

  return (
    <form className="mt-3 flex gap-3">
      <DropdownSelect
        className="w-[210px] [&>ul]:w-[115%]"
        value={selected}
        onChange={handleSelect}
        placeholder="Override trip point"
        size="sm"
        options={[
          { name: "Passenger Picked Up", value: "on_trip" },
          { name: "Passenger Dropped Off", value: "dropped_off" },
        ]}
      />
      <Button onClick={handleClick} className="shrink-0 px-6" variant="primary" size="sm" disabled={isLoading}>
        Set
      </Button>
    </form>
  );
};
