export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  delete: {
    title: "Are you sure?",
    description: "This will delete the interval permanently. You cannot undo this action, do you wish to continue?",
  },
  error: {
    title: "Unable to Save Hourly Hire",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};
