import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@/components/atoms";
import { DataTable, DataTableColumnDef } from "@/components/molecules";
import { HourlyHireInterval } from "@/redux/slices/pricing/types";
import { HourlyHireTableData } from "./types";

interface HourlyHireTableProps extends React.HTMLAttributes<HTMLDivElement> {
  intervals: HourlyHireInterval[];
  currencySymbol: string;
}

export const HourlyHireTable = ({ intervals, currencySymbol, className, ...props }: HourlyHireTableProps) => {
  const navigate = useNavigate();

  const data = intervals.map(
    (i) =>
      ({
        time: `${i.minimumRange}+ hourly hire`,
        pricePerHour: `${currencySymbol}${i.rate}`,
      } as HourlyHireTableData)
  );

  const columns: DataTableColumnDef<HourlyHireTableData>[] = [
    {
      accessorKey: "time",
      header: "Time",
      size: 350,
      enableSorting: false,
    },
    {
      accessorKey: "pricePerHour",
      header: "Price per hour",
      enableSorting: false,
    },
  ];

  const rowStyles = "  mt-4";

  return (
    <div className={className} {...props}>
      <div className="flex items-center">
        <div className="flex-1">
          <Typography variant="h3" className="leading-8">
            Hourly Hire Fees
          </Typography>
          <Typography className="text-neutral-dark-gray">Set the pricing for the hourly hire bookings</Typography>
        </div>
        <div className="flex-shrink-0">
          <Button startIcon="Edit2" onClick={() => navigate("edit")} className="bg-primary px-6 hover:bg-primary-dark">
            Edit
          </Button>
        </div>
      </div>
      <DataTable columns={columns} data={data} className={rowStyles} />
    </div>
  );
};
