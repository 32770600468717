import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginSlide1 from "@/assets/images/features/login-slide-01.png";
import { Container, Loading, Panel, Typography } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, LoginForm } from "@/components/organisms";
import { useProviderCode, useSiteConfig } from "@/hooks";
import { useLazyVerifyProviderQuery } from "@/redux/apis/auth/authApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setLoggedOut } from "@/redux/slices/auth/authSlice";
import { loggedOutSelector } from "@/redux/slices/auth/selectors";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";

export const Login = () => {
  const dispatch = useAppDispatch();
  const loggedOut = useAppSelector(loggedOutSelector);
  const { domain, apiUrl, images, name } = useSiteConfig();
  const providerCode = useProviderCode();
  const operator = useAppSelector(activeOperatorSelector);
  const [verify] = useLazyVerifyProviderQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedOut) {
      // if the user arrived on this page from logging out, they will be redirected to the main logout page
      // else if they arrived on this page through browsing without access tokens, they will proceed to the login page accordingly
      dispatch(setLoggedOut(false));
      if (import.meta.env.MODE === "production") window.location.replace("/logout");
    }
  }, [dispatch, loggedOut]);

  useEffect(() => {
    if (!operator)
      verify({ providerCode: providerCode || domain, apiUrl })
        .unwrap()
        .then((res) => {
          dispatch(setActiveOperator(res));
        })
        .catch((e) => {
          console.warn("(Login) Error verifying provider:", {
            providerCode: providerCode || domain,
            error: e,
          });
          navigate("/404");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerCode]);

  const renderOperatorLogo = () => {
    if (operator) {
      return <img className="max-h-11 max-w-11 bg-white" src={operator.images.emblem} alt={operator.name} />;
    }
  };

  if (!operator) return <Loading />;

  return (
    <div className="flex min-h-screen flex-col overflow-hidden md:flex-row">
      <div className="relative flex flex-1 flex-col">
        <GeneralHeader action="signUp" />
        <Container className="relative flex w-full max-w-[1040px] flex-1 justify-center gap-5 px-5 py-8 md:py-[100px]">
          <div className="hidden md:block">
            <Panel className="flex flex-col items-center">
              <div className="relative flex h-[339px] w-[395px] items-center justify-center">
                <img src={LoginSlide1} alt="login-slide-1" />
              </div>
              <figure className="absolute mt-24 flex h-16 w-16 items-center justify-center overflow-hidden rounded-full bg-white ring ring-primary-dark">
                {providerCode ? (
                  renderOperatorLogo()
                ) : (
                  <img
                    className="max-h-11 max-w-11 bg-white"
                    src={operator ? operator.images.emblem : images.emblem}
                    alt={operator ? operator.name : name}
                  />
                )}
              </figure>
              <Typography variant="h2" className="-mt-10">
                Where Operators and Their Drivers and Passengers Connect. Simplify Your Journey Today!
              </Typography>
              <Typography variant="paragraph" className="mt-3.5">
                Log in to your operator, driver, or passenger account to manage your travel.
              </Typography>
            </Panel>
          </div>
          <div className="w-full max-w-[440px]">
            <LoginForm />
          </div>
        </Container>
        <GeneralFooter />
      </div>
    </div>
  );
};
