import { ValidateFileType } from "./types";

/**
 * Taken from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
 *
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const getHumanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};

/**
 * Validate against a set of file types using the file's unique signature.
 *
 * If `acceptFileSignatures` is undefined, then we're accepting all file
 *   types (no magic number validation).
 *
 * @param validateFileTypeProps
 */
export const validateFileType = async (validateFileTypeProps: ValidateFileType) => {
  const { acceptFileSignatures = [] } = validateFileTypeProps;
  if (!acceptFileSignatures?.length) {
    return true;
  }

  const { file } = validateFileTypeProps;
  const fileSignature = await new Promise((resolve) => {
    const blob = file as Blob;
    const fileReader = new FileReader();

    fileReader.onloadend = (event) => {
      const arr = new Uint8Array(event.target?.result as ArrayBuffer).subarray(0, 4);
      let header = "";

      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }

      resolve(header);
    };

    fileReader.readAsArrayBuffer(blob);
  });

  return acceptFileSignatures?.includes(`${fileSignature}`);
};
