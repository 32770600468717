import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { format, parseISO } from "date-fns";
import { useState } from "react";
import { Button, Divider, Loading, Typography } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { useOperator, usePage, useProfile } from "@/hooks";
import { useAppDispatch } from "@/redux/hooks";
import { setPayByCreditCardPaymentMethodSetupId, setPayByCreditCardStep } from "@/redux/slices/payment/paymentSlice";
import { Invoice } from "@/redux/slices/payment/types";
import { addToast, formatCurrency } from "@/utils";

interface PayByCreditCardFormProps {
  invoice: Invoice;
}

export const PayByCreditCardForm = ({ invoice }: PayByCreditCardFormProps) => {
  const { currency, amountDue, dueDate, identifier, company } = invoice;
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { dateFormat } = useProfile();
  const operator = useOperator();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { pathname } = usePage();
  const url = `${getPhpHostUrl()}/dashboard${pathname}`;
  const isStripeLoading = !stripe || !elements;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isStripeLoading) return;

    setIsSubmitting(true);
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: url },
      redirect: "if_required",
    });

    if (result.error) {
      addToast("danger", result.error.message || "Something went wrong. Please contact your administrator.");
    } else {
      dispatch(setPayByCreditCardPaymentMethodSetupId(result.setupIntent.id));
      dispatch(setPayByCreditCardStep(2));
    }

    setIsSubmitting(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="relative flex h-full w-full flex-col">
      {(isSubmitting || isStripeLoading) && <Loading />}
      <div className="h-full flex-1 overflow-y-auto p-5">
        <StepIndicator currentStep={1} numSteps={2} className="my-10 justify-center" />
        <div className="mb-8 flex flex-col">
          <Typography variant="h1">{`${currency} ${formatCurrency(amountDue, 2, currency)}`}</Typography>
          <Typography variant="title" className="text-neutral-dark-gray">{`Due ${format(parseISO(dueDate), dateFormat)}`}</Typography>
        </div>
        <div className="mb-8 flex flex-col gap-y-2.5">
          <div className="flex flex-row">
            <Typography variant="action" className="flex-1 text-neutral-dark-gray">
              To
            </Typography>
            <Typography variant="action" className="flex">
              {company.name}
            </Typography>
          </div>
          <div className="flex flex-row">
            <Typography variant="action" className="flex-1 text-neutral-dark-gray">
              From
            </Typography>
            <Typography variant="action" className="flex">
              {operator.name}
            </Typography>
          </div>
          <div className="flex flex-row">
            <Typography variant="action" className="flex-1 text-neutral-dark-gray">
              Invoice
            </Typography>
            <Typography variant="action" className="flex">
              {identifier}
            </Typography>
          </div>
        </div>
        <Divider className="mb-2" />
        <PaymentElement
          options={{
            paymentMethodOrder: ["card", "au_becs_debit"],
            layout: {
              type: "tabs",
              defaultCollapsed: false,
            },
          }}
        />
      </div>
      <div>
        <Divider />
        <div className="flex flex-col gap-3 px-4 py-5">
          <Button type="submit" className="w-full text-center" size="lg">
            Proceed to Payment
          </Button>
        </div>
      </div>
    </form>
  );
};
