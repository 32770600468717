import {
  GetAccessControlRawResponse,
  GetAccessControlResponse,
  UpdateAccessControlRawResponse,
  UpdateAccessControlResponse,
} from "./types";

export const transformAccessControl = (res: GetAccessControlRawResponse) => {
  const { _links, ...accessControl } = res;
  const response: GetAccessControlResponse = {
    data: {
      details: {
        enabledDriverSignup: accessControl.enabled_driver_signup,
        enabledClientSignup: accessControl.enabled_client_signup,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateAccessControl = (res: UpdateAccessControlRawResponse) => {
  return {
    links: res._links,
  } as UpdateAccessControlResponse;
};
