import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Loading } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useApproveAccreditationMutation, useDenyAccreditationMutation } from "@/redux/apis/accreditation/accreditationApi";
import { useAppDispatch } from "@/redux/hooks";
import { setAction, setSelectedAccreditation } from "@/redux/slices/accreditation/accreditationSlice";
import { AccreditationItem } from "@/redux/slices/accreditation/types";
import { addToast, clsx } from "@/utils";
import { generateColumns, generateData } from "../helpers";

interface AccreditationsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: AccreditationItem[];
}

export const AccreditationsTable = ({ items, className, ...props }: AccreditationsTableProps) => {
  const { dateFormat } = useProfile();
  const dispatch = useAppDispatch();
  const ability = useAppAbility();
  const navigate = useNavigate();
  const [approveAccreditation, { isLoading: isLoadingApprove }] = useApproveAccreditationMutation();
  const [denyAccreditation, { isLoading: isLoadingDeny }] = useDenyAccreditationMutation();

  const handleUpdate = (item: AccreditationItem) => {
    dispatch(setSelectedAccreditation(item));
    dispatch(setAction("update"));
  };

  const handleViewHistory = (id: string) => {
    navigate(`${id}/history`);
  };

  const handleApprove = (id: string) => {
    approveAccreditation(id)
      .unwrap()
      .then(() => addToast("success", "Successfully approved accreditation"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleDeny = (id: string) => {
    denyAccreditation(id)
      .unwrap()
      .then(() => addToast("success", "Successfully denied accreditation"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleRemove = (item: AccreditationItem) => {
    dispatch(setSelectedAccreditation(item));
    dispatch(setAction("remove"));
  };

  const data = useMemo(
    () =>
      generateData(
        items,
        dateFormat,
        handleUpdate,
        handleViewHistory,
        ability.can("manage", "faProvider"),
        handleApprove,
        handleDeny,
        handleRemove
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const columns = useMemo(() => generateColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      {(isLoadingApprove || isLoadingDeny) && <Loading />}
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};