import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Button, Loading, Typography } from "@/components/atoms";
import { StepIndicator } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useAddCompanyMutation, useGetCompanyOptionsQuery } from "@/redux/apis/company/companyApi";
import { AddCompanyParams } from "@/redux/apis/company/types";
import { addToast, clsx } from "@/utils";
import { companiesFormSchema } from "../fixtures";
import { CompaniesFormData } from "../types";
import { CompaniesFormStep1 } from "./CompaniesFormStep1";
import { CompaniesFormStep2 } from "./CompaniesFormStep2";

export const CompaniesAddForm = () => {
  const operator = useOperator();
  const navigate = useNavigate();
  const ability = useAppAbility();
  const [formStep, setFormStep] = useState(1);
  const [addCompany, { isLoading }] = useAddCompanyMutation();
  const { isFetching: isFetchingOptions } = useGetCompanyOptionsQuery();
  const methods = useForm<CompaniesFormData>({
    defaultValues: {
      businessRegistrationType: "",
      enableInvoicing: false,
      invoiceGenerateLevel: "company",
      invoiceGenerateFrequency: "never",
      generateInvoiceOn: "1",
      paymentTerms: "30",
      billingContacts: [{ name: "", emailAddress: "", contactNumber: "" }],
      operatorCommissionOverride: false,
      pricingProfile: "dynamic-pricing",
      allowDriverExtras: true,
      paidCustomerExtras: true,
    },
    resolver: zodResolver(companiesFormSchema),
  });
  const {
    handleSubmit,
    trigger,
    formState: { isDirty },
  } = methods;

  const handleBackClick = () => {
    setFormStep(formStep - 1);
  };

  const handleCancelClick = () => {
    navigate("../");
  };

  const onNextClick = async () => {
    const valid = await trigger(
      [
        "companyName",
        "companyAddress",
        "companyContactNumber",
        "customReferenceFieldDescription",
        "businessRegistrationType",
        "businessRegistrationIdNumber",
        "notes",
        "driverNotes",
      ],
      {
        shouldFocus: true,
      }
    );

    if (!valid) return;

    setFormStep(formStep + 1);
  };

  const onSubmit = handleSubmit((data) => {
    const {
      customReferenceFieldDescription,
      notes,
      driverNotes,
      keyContactContactNumber,
      keyContactName,
      keyContactEmailAddress,
      enableInvoicing,
      generateInvoiceOn,
      billingContacts,
      pricingAdjustments,
      operatorCommissionOverride,
      invoiceGenerateFrequency,
    } = data;
    const isGenerateOnDateHidden =
      !invoiceGenerateFrequency || ["never", "no_future_jobs", "twice_monthly"].includes(invoiceGenerateFrequency);
    const companyData: AddCompanyParams = {
      name: data.companyName,
      address: data.companyAddress || undefined,
      phone: data.companyContactNumber || undefined,
      business_registration_type: data.businessRegistrationType,
      business_registration_id: data.businessRegistrationIdNumber,
      notes:
        customReferenceFieldDescription || notes || driverNotes
          ? {
              reference: customReferenceFieldDescription || undefined,
              admin: notes || undefined,
              driver: driverNotes || undefined,
            }
          : undefined,
      key_contact:
        keyContactName || keyContactEmailAddress || keyContactContactNumber
          ? {
              name: keyContactName || undefined,
              email: keyContactEmailAddress || undefined,
              phone: keyContactContactNumber || undefined,
            }
          : undefined,
      invoice:
        ability.can("manage", "providerCompanyInvoicing") && operator.flags.paymentInvoiceConfigured
          ? {
              enabled: enableInvoicing,
              generate_on_level: enableInvoicing ? data.invoiceGenerateLevel : undefined,
              generate_on_frequency: enableInvoicing ? data.invoiceGenerateFrequency : undefined,
              generate_on_date: enableInvoicing && !isGenerateOnDateHidden && generateInvoiceOn ? Number(generateInvoiceOn) : undefined,
              payment_terms: enableInvoicing ? Number(data.paymentTerms) : undefined,
            }
          : undefined,
      billing_contacts:
        enableInvoicing && billingContacts
          ? billingContacts.map((billingContact) => ({
              name: billingContact.name || undefined,
              email: billingContact.emailAddress || "",
              phone: billingContact.contactNumber || undefined,
            }))
          : undefined,
      pricing: {
        adjustments: pricingAdjustments
          ? pricingAdjustments.map((pricingAdjustment) => ({
              amount: pricingAdjustment.isPriceIncrease ? pricingAdjustment.price : pricingAdjustment.price * -1,
              type: pricingAdjustment.displayToClient ? "itemised" : "bundled",
              description: pricingAdjustment.description || undefined,
            }))
          : undefined,
        commission: {
          override_enabled: operatorCommissionOverride,
          percentage: operatorCommissionOverride ? Number(data.percentageCommission) : undefined,
        },
        profile_uuid: data.pricingProfile === "dynamic-pricing" ? "" : data.pricingProfile,
        allow_driver_extras: data.allowDriverExtras,
        include_client_extras_in_base_cost: data.pricingProfile !== "dynamic-pricing" ? !data.paidCustomerExtras : false,
      },
    };

    addCompany(companyData)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully added new company");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="relative">
        {isFetchingOptions && <Loading />}
        <div className="block">
          <Typography variant="h3" className="leading-8">
            Add New Company
          </Typography>
          <Typography className="text-neutral-dark-gray">Fill up the company information</Typography>
        </div>
        <StepIndicator currentStep={formStep} numSteps={2} className="my-8 justify-center" />
        <CompaniesFormStep1 className={clsx("animate-enter", { hidden: formStep > 1 })} />
        <CompaniesFormStep2 className={clsx("animate-enter", { hidden: formStep < 2 })} />
        <div className="flex flex-col sm:flex-row justify-end gap-2 mb-4 mt-10">
          <Button variant="secondary" startIcon="ArrowLeft" className={clsx({ hidden: formStep < 2 })} onClick={handleBackClick} size="lg">
            Back
          </Button>
          <Button variant="secondary" className={clsx({ hidden: formStep > 1 })} onClick={handleCancelClick} size="lg">
            Cancel
          </Button>
          <div className="relative">
            {isLoading && <Loading />}
            <Button variant="primary" type="submit" className={clsx("w-full", { hidden: formStep < 2 })} disabled={!isDirty} size="lg">
              Add Company
            </Button>
          </div>
          <Button
            variant="primary"
            endIcon="ArrowRight"
            className={clsx({ hidden: formStep > 1 })}
            disabled={!isDirty}
            onClick={onNextClick}
            size="lg"
          >
            Next
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
