import { ImageSelectItem, Panel, Typography } from "@/components/atoms";
import { useOperator, useSiteConfig } from "@/hooks";
import { useSignUpContext } from "../context";
import { userTypeImage, userTypes } from "../fixtures";
import { UserType, UserTypeData } from "../types";

export const UserSelectForm = () => {
  const { domain } = useSiteConfig();
  const {
    name,
    singleTenant,
    registerLinks: { driver },
    domainDriver,
    availableAccountTypesOnRegister,
  } = useOperator();
  const { setStep, setUserTypeData } = useSignUpContext();
  const options = singleTenant ? availableAccountTypesOnRegister.map(type => ( userTypes[type as UserType] )) : Object.keys(userTypes).map(type => ( userTypes[type as UserType] ));

  const handleSelectUserType = (userTypeData: UserTypeData) => {
    setUserTypeData(userTypeData);
    switch (userTypeData.type) {
      case "operator":
        setStep("signup");
        break;
      case "driver":
        if (domainDriver === domain && !singleTenant) setStep("provider-select");
        else if (domainDriver === domain && singleTenant) setStep("signup");
        else window.location.replace(driver);
        break;
      case "executive_assistant":
      case "passenger":
      case "travel_manager":
        if (!singleTenant) setStep("provider-select");
        else setStep("signup");
        break;
      default:
        setStep("provider-select");
    }
  };

  return (
    <div>
      <Panel>
        <div className="flex flex-col gap-y-1.5 mb-7">
          <Typography variant="h1">Sign up</Typography>
        </div>
        <div className="flex flex-col gap-y-4">
          <Typography variant="title">{`How are you primarily using ${name}?`}</Typography>
          <div className="grid grid-cols-2 gap-2.5 md:grid-cols-3 lg:grid-cols-5 md:gap-5">
            {options.map((o) => (
              <ImageSelectItem
                key={o.type}
                src={userTypeImage[o.type]}
                label={o.name}
                onClick={() => handleSelectUserType(o)}
              />
            ))}
          </div>
        </div>
      </Panel>
    </div>
  );
};