import { Container } from "@/components/atoms";
import { CompaniesAddPanel, PageHeader, PageHeaderV2 } from "@/components/organisms";

export const CompaniesAdd = () => {
  return (
    <Container className="flex flex-1 flex-col w-full mb-12">
      <PageHeaderV2 name="Companies" skipCrumbs={1} />
      <PageHeader.Actions />
      <CompaniesAddPanel />
    </Container>
  );
};
