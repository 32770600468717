import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Loading } from "@/components/atoms";
import { DisclosurePanel } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useEditVehicleCategoryMutation } from "@/redux/apis/config/vehicle/vehicleCategories";
import { useAppSelector } from "@/redux/hooks";
import { inheritFromSelector, offloadToSelector } from "@/redux/slices/pricing/selectors";
import { VehicleCategory } from "@/redux/slices/pricing/types";
import { clsx } from "@/utils";
import { addToast } from "@/utils/addToast";
import { vehicleCategoryFormSchema } from "../fixtures";
import { VehicleCategoryFormData } from "../types";
import { CustomCommissionRateField } from "./fields/CustomCommissionRateField";
import { EnabledCustomCommissionRateField } from "./fields/EnabledCustomCommissionRateField";
import { GlobalVehicleField } from "./fields/GlobalVehicleField";
import { NumberOfBagsField } from "./fields/NumberOfBagsField";
import { NumberOfPassengersField } from "./fields/NumberOfPassengersField";
import { VehicleCategoryMultiplierField } from "./fields/VehicleCategoryMultiplierField";
import { VehicleCategoryNameField } from "./fields/VehicleCategoryNameField";
import { VehicleCollectJobsField } from "./fields/VehicleCollectJobsField";
import { WaitingTimeCostField } from "./fields/WaitingTimeCostField";

interface EditVehicleCategoryFormProps {
  item: VehicleCategory;
  onClose: () => void;
  onSuccess: () => void;
}

export const EditVehicleCategoryForm = ({ item, onClose, onSuccess, ...props }: EditVehicleCategoryFormProps) => {
  const offloadItems = useAppSelector(offloadToSelector);
  const inheritItems = useAppSelector(inheritFromSelector);
  const [editVehicleCategory, { isLoading }] = useEditVehicleCategoryMutation();
  const [formStep, setFormStep] = useState(1);
  const methods = useForm<VehicleCategoryFormData>({
    defaultValues: {
      vehicleCategoryName: item.name,
      numberOfPassengers: item.passenger,
      numberOfBags: item.bags,
      vehicleCategoryMultiplier: item.costIncrease,
      waitingTimeCost: item.waitTimeCost,
      globalVehicle: item.mappedGlobalVehicle || "",
      vehicleCollectJobs: item.canCollectFromVehicles,
      enabledCustomCommissionRate: item.commissionRateEnabled,
      customCommissionRate: item.commissionRate,
    },
    resolver: zodResolver(vehicleCategoryFormSchema),
  });
  const {
    trigger,
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const onNextClick = async () => {
    const valid = await trigger([
      "vehicleCategoryName",
      "numberOfPassengers",
      "numberOfBags",
      "vehicleCategoryMultiplier",
      "waitingTimeCost",
    ]);
    if (valid) setFormStep(formStep + 1);
  };

  const onSubmit = handleSubmit((data) => {
    editVehicleCategory({
      uuid: item.id,
      name: data.vehicleCategoryName,
      attributes: {
        passengers: data.numberOfPassengers,
        bags: data.numberOfBags,
      },
      per_distance_unit_multiplier: data.vehicleCategoryMultiplier,
      wait_time_cost: data.waitingTimeCost * 100,
      offload_to_global_vehicle_type: data.globalVehicle,
      inherit_from_vehicle_types: data.vehicleCollectJobs || [],
      custom_commission: {
        enabled: data.enabledCustomCommissionRate,
        rate: data.customCommissionRate,
      },
      is_active: true,
    })
      .unwrap()
      .then(onSuccess)
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  const renderButtons = () => (
    <div className="flex justify-end">
      <div className={clsx("flex flex-row gap-x-4", { hidden: formStep > 1 })}>
        <Button variant="secondary" size="md" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" size="md" onClick={onNextClick}>
          Next
        </Button>
      </div>
      <div className={clsx("flex flex-row gap-x-4", { hidden: formStep < 2 })}>
        <Button variant="secondary" size="md" onClick={() => setFormStep(1)}>
          Back
        </Button>
        <Button type="submit" form="edit-vehicle-category-form" variant="primary" size="md" disabled={!isDirty}>
          Update Vehicle Category
        </Button>
      </div>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <form
        id="edit-vehicle-category-form"
        className="relative flex min-h-[620px] w-[515px] max-w-full flex-col"
        onSubmit={onSubmit}
        {...props}
      >
        {isLoading && <Loading />}
        <div className="flex flex-1 flex-col">
          <div
            className={clsx("grid w-full grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", {
              hidden: formStep > 1,
            })}
          >
            <VehicleCategoryNameField className="col-span-2" />
            <NumberOfPassengersField className="col-span-2" />
            <NumberOfBagsField className="col-span-1" />
            <VehicleCategoryMultiplierField className="col-span-1" />
            <WaitingTimeCostField currency={item.currency} className="col-span-1" />
          </div>
          <div className={clsx("flex w-full flex-col gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", { hidden: formStep < 2 })}>
            {offloadItems && <GlobalVehicleField items={offloadItems} />}
            {inheritItems && <VehicleCollectJobsField items={inheritItems} excludeItem={item} />}
            <DisclosurePanel title="Advanced Settings">
              <div className=" grid grid-cols-2 gap-y-2 lg:gap-y-4">
                <EnabledCustomCommissionRateField className="col-span-2" />
                <CustomCommissionRateField className="col-span-1" />
              </div>
            </DisclosurePanel>
          </div>
        </div>
        {renderButtons()}
      </form>
    </FormProvider>
  );
};
