import { z } from "zod";
import { ExternalSuppliersFilters, ExternalSuppliersTableColumn } from "./types";

export const tableColumns: ExternalSuppliersTableColumn[] = [
  {
    id: "name",
    header: "External Supplier",
    isJSX: true,
    size: undefined,
    isHidden: true,
  },
  {
    id: "type",
    header: "Type",
    isSortDisabled: true,
  },
  {
    id: "email",
    header: "Email",
  },
  {
    id: "phone",
    header: "Phone",
  },
  {
    id: "state",
    header: "State",
    sortId: "address_state",
  },
  {
    id: "rating",
    header: "Rating",
    isJSX: true,
  },
  {
    id: "completion",
    header: "Completion",
  },
  {
    id: "status",
    header: "Status",
    isJSX: true,
    isSortDisabled: true,
  },
  {
    id: "action",
    header: "Action",
    isJSX: true,
    isSortDisabled: true,
  },
];

export const accountTypes: Record<string, string> = {
  external_driver: "Driver",
  external_hotel: "Hotel",
  external_medical: "Medical",
};

export const defaultFilters: ExternalSuppliersFilters = {
  type: undefined,
  addressState: undefined,
  rating: undefined,
  accountStatus: undefined,
};

export const addExternalSupplierFormSchema = z.object({
  name: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .min(2, "Minimum of 2 characters.")
    .max(100, "Maximum of 100 characters"),
  type: z.string().nonempty("Field is required and can't be empty.").max(255, "Maximum of 255 characters"),
  state: z.string().nonempty("Field is required and can't be empty."),
  email: z
    .string()
    .nonempty("Field is required and can't be empty.")
    .email("This is not a valid email.")
    .min(2, "Minimum of 2 characters."),
  keyContactName: z.string().nonempty("Field is required and can't be empty.").max(50, "Maximum of 50 characters"),
  contactNumber: z
    .string({ description: "Please enter a valid phone number" })
    .nonempty("Field is required and can't be empty.")
    .max(14, "Maximum of 14 characters"),
});
