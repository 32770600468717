import { Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInvoicingModalAction } from "@/redux/slices/payment/paymentSlice";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { CancelInvoice, MarkAsPaid, UndoPayment, WriteOff } from "./contents";

export const InvoicingModals = () => {
  const dispatch = useAppDispatch();
  const { modalAction } = useAppSelector(invoicingPageStateSelector);

  const onClose = () => dispatch(setInvoicingModalAction(undefined));

  return (
    <>
      <Modal open={modalAction === "markAsPaid"} onClose={onClose} className="w-[85vw] md:w-[550px] lg:w-[550px]">
        <MarkAsPaid onClose={onClose} />
      </Modal>
      <Modal open={modalAction === "writeOff"} onClose={onClose} className="w-[85vw] md:w-[510px] lg:w-[510px]">
        <WriteOff onClose={onClose} />
      </Modal>
      <Modal open={modalAction === "undoPayment"} onClose={onClose} className="w-[85vw] md:w-[510px] lg:w-[510px]">
        <UndoPayment onClose={onClose} />
      </Modal>
      <Modal open={modalAction === "cancelInvoice"} onClose={onClose} className="w-[85vw] md:w-[510px] lg:w-[510px]">
        <CancelInvoice onClose={onClose} />
      </Modal>
    </>
  );
};
