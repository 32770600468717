import { zodResolver } from "@hookform/resolvers/zod";
import { format, startOfMonth } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Divider, Icon, Typography, SidePanel, Loading } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { CountryState } from "@/redux/slices/resource/types";
import { addToast } from "@/utils";
import { AssignedCompanyNameField } from "./fields/AssignedCompanyNameField";
import { DateField } from "./fields/DateField";
import { DriverExternalSupplierField } from "./fields/DriverExternalSupplierField";
import { ReportTypeField } from "./fields/ReportTypeField";
import { exportReportFormSchema } from "./fixtures";
import { ExportReportFormData } from "./types";
import { useCreateReportMutation, useLazyGetReportQuery } from "@/redux/apis/report/reportApi";
import { useEffect, useState } from "react";
import { useOperator } from "@/hooks";
import { CreateReportParams } from "@/redux/apis/report/types";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { tripReportSelector } from "@/redux/slices/tripReporting/selectors";
import { clearTripReport } from "@/redux/slices/tripReporting/tripReportingSlice";

export interface ExportReportPanelProps {
  open: boolean;
  onClose: () => void;
  stateItems: CountryState[];
  isExportAll: boolean;
}

export const ExportReportPanel = (exportReportPanelProps: ExportReportPanelProps) => {
  const { open, onClose, stateItems, isExportAll = false } = exportReportPanelProps;
  const dispatch = useAppDispatch();
  const { id } = useOperator();
  
  const [createReport, { data: createReportData }] = useCreateReportMutation();
  const [getReport, { isFetching }] = useLazyGetReportQuery();
  const [isLoading, setIsLoading] = useState(false);
  const tripReport = useAppSelector(tripReportSelector);

  const methods = useForm<ExportReportFormData>({
    resolver: zodResolver(exportReportFormSchema),
    defaultValues: {
      date: [startOfMonth(new Date()), new Date()],
      assignedCompanyName: "",
      driverExternalSupplier: "",
      reportType: "standard",
    },
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (createReportData && !isFetching) {
      if (tripReport?.status !== "completed") {
        setTimeout(() => {
          getReport(createReportData.uuid)
          .unwrap()
          .catch((e) => {
            getErrorMessages(e).forEach((m) => addToast("danger", m));
            setIsLoading(false);
          });
        }, 5000);
      } else {
        window.open(tripReport.filename, "_blank");
        setIsLoading(false);
      };
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createReportData, isFetching])

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);
    dispatch(clearTripReport());

    const params: CreateReportParams = {
      type: "provider_reporting_Trip",
      params: {
        start_date: format(data.date[0], "yyyy-MM-dd"),
        end_date: format(data.date[1], "yyyy-MM-dd"),
        type: data.reportType,
        owner_uuid: id,
        state: [],
        company: data.assignedCompanyName || undefined,
        supplier_uuid: data.driverExternalSupplier || undefined,
      },
    };

    stateItems.map((stateItem, i) => {
      params.params.state[i] = stateItem.id;
    });

    createReport(params)
      .unwrap()
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
        setIsLoading(false);
      });
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  return (
    <SidePanel
      open={open}
      onClose={handleOnClose}
      title="Select Date"
      description="Enter the month and year that you want to export."
      focused
    >
      <FormProvider {...methods}>
        <form className="relative flex  h-full flex-col" onSubmit={onSubmit}>
          {isLoading && <Loading className="z-40" />}
          <div className="h-full flex-1 overflow-y-auto">
            <div className="flex flex-col gap-y-4 px-4 py-6">
              <div className="rounded-lg bg-neutral-surface-gray p-5">
                {isExportAll ? (
                  <Typography variant="action" className="text-neutral-dark-gray">
                    Exporting All States...
                  </Typography>
                ) : (
                  <ul role="list" className="grid grid-cols-3 gap-x-5 gap-y-5">
                    {stateItems.map((stateItem) => (
                      <li key={stateItem.id} className="flex flex-col items-center justify-center space-y-4">
                        <div className="relative flex h-[100px] w-[100px]">
                          <img src={stateItem.image} alt={stateItem.abbreviation} className="rounded-full object-cover" />
                          <Icon
                            name="TickCircle"
                            variant="Bold"
                            size="md"
                            className="absolute bottom-[79px] left-[79px] z-30 rounded-full text-success"
                          />
                          <div className="absolute bottom-[81px] left-[81px] z-20 h-4 w-4 rounded-full bg-white" />
                          <div className="absolute bottom-[79px] left-[79px] z-10 h-5 w-5 rounded-full bg-success" />
                        </div>
                        <Typography variant="action">{stateItem.abbreviation}</Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <DateField className="rounded-lg bg-neutral-surface-gray p-5" />
              <AssignedCompanyNameField className="rounded-lg bg-neutral-surface-gray p-5" />
              <DriverExternalSupplierField className="rounded-lg bg-neutral-surface-gray p-5" />
              <ReportTypeField className="rounded-lg bg-neutral-surface-gray p-5" />
            </div>
          </div>
          <div>
            <Divider />
            <div className="px-4 py-5">
              <Button type="submit" className="w-full text-center" startIcon="ImportCurve" size="lg">
                Download Report
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </SidePanel>
  );
};
