import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextArea, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const DriverNotesField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography>Driver Notes</Typography>
      <TextArea
        className="w-full"
        placeholder="Enter Driver Notes"
        hasError={!!errors.driverNotes}
        maxLength={1024}
        {...register("driverNotes")}
      />
      <ErrorMessage errors={errors} name="driverNotes" />
    </div>
  );
};
