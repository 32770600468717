import { Typography } from "@/components/atoms";
import { useSiteConfig } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";
import { useParams } from "react-router-dom";

export const GeneralLogo = () => {
  const config = useSiteConfig();
  const operator = useAppSelector(activeOperatorSelector);
  const { providerCode } = useParams();

  const renderOperatorLogo = () => {
    if (operator) {
      return (
        <>
          <img
            className="flex h-8 max-h-[32px] object-contain"
            src={operator.images.emblem}
            alt={operator.name}
          />
          <Typography variant="h3">{operator.name}</Typography>
        </>
      );
    }
  };

  return (
    <figure className="flex flex-1 items-center gap-2">
      {providerCode ? renderOperatorLogo() : (
        <>
          <img
            className="flex h-8 max-h-[32px] object-contain"
            src={operator ? operator.images.emblem : config.images.emblem}
            alt={operator ? operator.name : config.name}
          />
          <Typography variant="h3">{operator ? operator.name : config.name}</Typography>
        </>
      )}
    </figure>
  );
};
