import { GetUnscheduledStopsRawResponse, GetUnscheduledStopsResponse } from "./types";

export const transformUnscheduledStops = (res: GetUnscheduledStopsRawResponse) => {
  const { _links, unscheduled_stop, country_code, currency } = res;
  const response: GetUnscheduledStopsResponse = {
    data: {
      details: {
        unscheduledStopsFee: unscheduled_stop,
        countryCode: country_code,
        currency,
      },
    },
    links: _links,
  };

  return response;
};
