import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DropdownSelect, Loading, PhoneNumberInput, TextInput } from "@/components/atoms";
import { ProfileDetail as Detail } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetProfileOptionsQuery, useUpdateProfileMutation } from "@/redux/apis/profile/profileApi";
import { addToast } from "@/utils";
import { ProfileFormData } from "./types";

export const ProfileDetailsEdit = () => {
  const navigate = useNavigate();
  const [updateProfile, { isLoading: isUpdating }] = useUpdateProfileMutation();
  const { isFetching, data: options } = useGetProfileOptionsQuery();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useFormContext<ProfileFormData>();

  const onSubmit = handleSubmit(async (data) => {
    await updateProfile({
      title: data.title,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      mobile: data.mobile,
      notification_email: data.notificationEmail,
      timezone: data.timezone,
      date_format: data.dateFormat,
      time_format: data.timeFormat,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully updated profile");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  if (isFetching || !options) return <Loading className="!flex w-full" />;

  const { titles, timezones, dateFormats } = options;

  return (
    <form onSubmit={onSubmit} id="editProfile" className="flex flex-col gap-8 overflow-auto bg-white py-8 pl-8 pr-4 lg:pl-14">
      {isUpdating && <Loading />}
      <Detail label="Title">
        <Controller
          control={control}
          name="title"
          rules={{ required: true }}
          render={({ field }) => (
            <DropdownSelect
              className="w-[150px]"
              placeholder="Select title"
              options={titles}
              value={field.value?.toString()}
              onChange={(value) => field.onChange(value)}
              hasError={!!formErrors.title}
            />
          )}
        />
      </Detail>
      <Detail label="First Name">
        <TextInput
          className="!w-[300px]"
          placeholder="Enter your First Name"
          {...register("firstName", { required: true })}
          hasError={!!formErrors.firstName}
        />
      </Detail>
      <Detail label="Last Name">
        <TextInput
          className="!w-[300px]"
          placeholder="Enter your Last Name"
          hasError={!!formErrors.lastName}
          {...register("lastName", { required: true })}
        />
      </Detail>
      <Detail label="Mobile Number">
        <PhoneNumberInput
          className="!w-[300px]"
          control={control}
          name="mobile"
          rules={{ required: true }}
          hasError={!!formErrors.mobile}
        />
      </Detail>
      <Detail label="Login Details" description="This will be your username login details (Email).">
        <TextInput
          className="!w-[300px]"
          placeholder="Enter your Login Details"
          hasError={!!formErrors.email}
          {...register("email", { required: true })}
        />
      </Detail>
      <Detail label="Notification Email" description="This email will be used for all booking notifications.">
        <TextInput
          className="!w-[300px]"
          placeholder="Enter your Login Details"
          hasError={!!formErrors.notificationEmail}
          {...register("notificationEmail", { required: true })}
        />
      </Detail>
      <Detail label="Timezone">
        <Controller
          control={control}
          name="timezone"
          rules={{ required: true }}
          render={({ field }) => (
            <DropdownSelect
              className="w-[300px]"
              placeholder="Select timezone"
              options={timezones}
              value={field.value?.toString()}
              onChange={(value) => field.onChange(value)}
              hasError={!!formErrors.timezone}
            />
          )}
        />
      </Detail>
      <Detail label="Date Format">
        <Controller
          control={control}
          name="dateFormat"
          rules={{ required: true }}
          render={({ field }) => (
            <DropdownSelect
              className="w-[300px]"
              placeholder="Select date format"
              options={dateFormats}
              value={field.value}
              onChange={(value) => field.onChange(value)}
              hasError={!!formErrors.dateFormat}
            />
          )}
        />
      </Detail>
      <Detail label="Time Format">
        <Controller
          control={control}
          name="timeFormat"
          rules={{ required: true }}
          render={({ field }) => (
            <DropdownSelect
              className="w-[300px]"
              placeholder="Select time format"
              options={[
                { name: "12-Hour Format", value: "12H" },
                { name: "24-Hour Format", value: "24H" },
              ]}
              value={field.value}
              onChange={(value) => field.onChange(value)}
              hasError={!!formErrors.timeFormat}
            />
          )}
        />
      </Detail>
    </form>
  );
};
