// import { useLocation } from "react-router-dom";
import { Alert, Panel, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CostEstimateForm } from "./CostEstimateForm";

export const CostPreviewPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  // const { pathname } = useLocation();
  // const { intervals } = location;
  // const disabled = intervals.length < 1 || pathname.includes("edit");
  const disabled = true; //todo: api pending

  return (
    <Panel className={clsx("relative min-h-[645px] w-[380px] flex-shrink-0", className)} {...props}>
      {disabled && <div className="absolute left-0 top-0 z-10 h-full w-full rounded-lg bg-white/90"></div>}
      <Typography variant="h3" className="flex-1 leading-8">
        Trip Cost - Test Preview
      </Typography>
      <Alert className="mt-4" type="info" message="Test and Preview the estimate of your pricing based on your configuration" />
      <div className="divide-y divide-neutral-dark-gray">
        <CostEstimateForm name="Trip 1" />
        <CostEstimateForm name="Trip 2" />
      </div>
    </Panel>
  );
};
