import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const OperatorCommissionOverrideField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col sm:flex-row gap-1 gap-y-2", className)} {...props}>
      <div className="flex flex-1 flex-col gap-y-1">
        <Typography variant="paragraph">Operator Commission Override %</Typography>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          Use this setting to adjust your retained commission and therefore the default driver payments for all jobs related to this
          company. This is useful when you have set a company pricing adjustment and may want to vary the driver payment up or down
          accordingly.
        </Typography>
        <ErrorMessage errors={errors} name="operatorCommissionOverride" />
      </div>
      <Controller
        name="operatorCommissionOverride"
        control={control}
        render={({ field }) => (
          <div className="flex items-center">
            <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />
          </div>
        )}
      />
    </div>
  );
};
