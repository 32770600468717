import { useParams } from "react-router-dom";
import { Panel, Skeleton } from "@/components/atoms";
import { DailyHireEditor, DailyHireMinimum, DailyHireMinimumEditor, DailyHireTable } from "@/components/organisms";
import { useGetDailyHireMinimumQuery, useGetDailyHireQuery } from "@/redux/apis/config/pricing/dailyHire";
import { useAppSelector } from "@/redux/hooks";
import { dailyHireMinimumSelector, dailyHireSelector } from "@/redux/slices/pricing/selectors";
import { clsx } from "@/utils";

export const DailyHire = () => {
  const { isFetching: isFetchingDailyHire } = useGetDailyHireQuery();
  const { isFetching: isFetchingDailyHireMinimum } = useGetDailyHireMinimumQuery();
  const { basePrice, intervals, currency, currencySymbol } = useAppSelector(dailyHireSelector);
  const dailyHireMinimum = useAppSelector(dailyHireMinimumSelector);
  const { mode, type } = useParams();

  return (
    <div className="space-y-6">
      <Panel>
        {isFetchingDailyHireMinimum ? (
          <Skeleton variant="short" />
        ) : (
          <>
            {mode === "edit" && type === "minimum" ? (
              <DailyHireMinimumEditor dailyHireMinimum={dailyHireMinimum} />
            ) : (
              <DailyHireMinimum
                className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type !== "minimum" })}
                dailyHireMinimum={dailyHireMinimum}
              />
            )}
          </>
        )}
      </Panel>
      <Panel>
        {isFetchingDailyHire ? (
          <Skeleton />
        ) : (
          <>
            {mode === "edit" && type !== "minimum" ? (
              <DailyHireEditor basePrice={basePrice} intervals={intervals} currency={currency} currencySymbol={currencySymbol} />
            ) : (
              <DailyHireTable
                className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type === "minimum" })}
                intervals={[basePrice, ...intervals]}
                currencySymbol={currencySymbol}
              />
            )}
          </>
        )}
      </Panel>
    </div>
  );
};
