import { useDeepCompareEffect } from "@react-hookz/web";
import { MapLayerMouseEvent } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useCallback, useMemo, useState } from "react";
import Map, { NavigationControl, ViewState } from "react-map-gl";
import { useBookingTripMap } from "../hooks/useBookingTripMap";
import { BookingTripMarkers } from "./BookingTripMarkers";
import { BookingTripOverridePopup } from "./BookingTripOverridePopup";
import { BookingTripPoints } from "./BookingTripPoints";
import { BookingTripPolylines } from "./BookingTripPolylines";

const ACCESS_TOKEN = import.meta.env.VITE_RIDEMINDER_MAPBOX_ACCESS_TOKEN;
const MAP_STYLE = "mapbox://styles/rideminder/clq7mqg8s001d01ppczpf3bqn";

export const BookingTripViewer = () => {
  const {
    mode,
    tripLog,
    map: { ref: mapRef, tripPoints },
    functions: { setMapBounds, setActiveTripPoint },
  } = useBookingTripMap();

  const recenterMap = useCallback(() => {
    switch (tripLog.job.status) {
      case "on_way_to_job":
        setMapBounds("driver-on-way");
        break;

      case "commenced":
        setMapBounds("commenced");
        break;

      case "awaiting_driver_allocation":
      case "driver_allocated_confirmed":
      case "driver_allocated_not_confirmed":
      default:
        setMapBounds("all");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    recenterMap();
  }, [tripLog]);

  const [viewState] = useState<ViewState | undefined>(undefined);
  const layerIds = useMemo(() => tripPoints.map((_, idx) => `location-point-${idx}-fill`), [tripPoints]);

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const features = event.features;

      if (features && features.length > 0 && tripPoints.length > 0) {
        const feature = features[0];
        const layerIndex = feature.layer.id.split("-")[2];
        const tripPoint = tripPoints[Number(layerIndex)];

        if (layerIndex) setActiveTripPoint(tripPoint);
      }
    },
    [setActiveTripPoint, tripPoints]
  );

  return (
    <Map
      mapStyle={MAP_STYLE}
      mapboxAccessToken={ACCESS_TOKEN}
      onLoad={recenterMap}
      ref={mapRef}
      onClick={handleMapClick}
      interactiveLayerIds={layerIds}
      initialViewState={{
        latitude: -32.60410130519325,
        longitude: 146.2514176872145,
        zoom: 5,
      }}
      {...viewState}
      cooperativeGestures={mode === "guest"}
    >
      <NavigationControl />
      <BookingTripMarkers />
      <BookingTripPolylines />
      {mode !== "guest" && (
        <>
          <BookingTripPoints />
          <BookingTripOverridePopup />
        </>
      )}
    </Map>
  );
};
