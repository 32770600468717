import { z } from "zod";

const detailsFields = z.object({
  categories: z.array(z.string()).min(1, "Please select at least one category"),
  capacity: z.number(),
  bags: z.number(),
});

const otherFields = {
  avatar: z.string().nullable(),
  vehicleOwnerFee: z.number().min(0).max(100),
  nickname: z.string().optional(),
};

const documentFields = {
  insuranceDoc: z.string().nullable(),
  // .string({
  //   message: "Insurance document is required",
  // })
  // .min(1, "Insurance document is required"),
  insuranceExpiry: z.date().nullable(),
  insuranceAutoverified: z.boolean().optional(),
  insuranceExpiryConfirm: z.boolean().optional(),
  accreditationDoc: z.string().nullable(),
  accreditationExpiry: z.date().nullable(),
  accreditationAutoverified: z.boolean().optional(),
  accreditationExpiryConfirm: z.boolean().optional(),
};

export const schema = z.object({
  ...otherFields,
  details: detailsFields,
  documents: z
    .object({
      ...documentFields,
    })
    .refine(
      (data) => {
        if (data.insuranceDoc) return data.insuranceAutoverified ? true : !!data.insuranceExpiry && data.insuranceExpiryConfirm;
        return true;
      },
      {
        message: "Please confirm insurance expiry date",
        path: ["insuranceExpiry"],
      }
    )
    .refine(
      (data) => {
        if (data.accreditationDoc)
          return data.accreditationAutoverified ? true : !!data.accreditationExpiry && data.accreditationExpiryConfirm;
        return true;
      },
      {
        message: "Please confirm accreditation expiry date",
        path: ["accreditationExpiry"],
      }
    ),
});

export type EditVehicleFormData = z.infer<typeof schema>;
