import { useNavigate, useParams } from "react-router-dom";
import { Button, Loading, Panel, Typography } from "@/components/atoms";
import { useGetDepositsDetailsQuery } from "@/redux/apis/payment/stripe/stripeApi";
import { useAppSelector } from "@/redux/hooks";
import { stripeSelector } from "@/redux/slices/payment/selectors";
import { clsx } from "@/utils";
import { DepositsDetails } from "./contents/DepositsDetails";
import { DepositsEditor } from "./contents/DepositsEditor";
import { renderDetailsStatus } from "./helpers";

export const BankDetailsDepositsPanel = () => {
  const { mode, type } = useParams();
  const navigate = useNavigate();
  const { isFetching } = useGetDepositsDetailsQuery();
  const { deposits } = useAppSelector(stripeSelector);
  const isBlankDetails = Boolean(!deposits.id);

  return (
    <Panel className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type !== "deposit" })}>
      <Typography variant="h3">Bank Details - Deposits</Typography>
      <div className="relative mt-6 flex flex-col gap-[15px] lg:flex-row lg:gap-[30px]">
        {isFetching && <Loading />}
        {mode === "edit" && type === "deposit" ? (
          <DepositsEditor />
        ) : (
          <DepositsDetails className={clsx({ "border border-warning-dark": isBlankDetails })} deposits={deposits} />
        )}
        <div className="flex flex-1 flex-col">
          {renderDetailsStatus(isBlankDetails, mode)}
          <Typography variant="paragraph">
            Note: We will request funds daily to settle any outstanding balance you have on your account. Please ensure there are sufficient
            funds daily otherwise fees may apply and loss of service may occur.
          </Typography>
          {!(mode === "edit" && type === "deposit") && (
            <div className="mt-4 flex flex-row gap-x-4">
              <Button variant="primary" onClick={() => navigate("./deposit/edit")}>
                Edit
              </Button>
              <Button variant="secondary" onClick={() => navigate("./deposit/history")}>
                View History Logs
              </Button>
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
};
