import { useSignUpContext } from "./context";
import { SignUpSuccessDriver } from "./contents";
import { NotFound } from "@/components/pages";

export const SignUpSuccess = () => {
  const { userTypeData } = useSignUpContext();

  if (!userTypeData) throw new Error("userTypeData is undefined");

  switch (userTypeData.type) {
    case "driver":
      return <SignUpSuccessDriver />;
    default:
      return <NotFound />;
  }
};
