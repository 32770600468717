import { DndContext, DragEndEvent, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { tableClass } from "../DataTable";
import { DataTableHeader } from "../components/DataTableHeader";
import { DraggableRow } from "../components/DraggableRow";
import { DataTableGetRowProps, DataTableRenderSubComponent, DataTableTable } from "../types";

export interface SortableTableProps<T extends object> {
  table: DataTableTable<T>;
  children?: React.ReactNode;
  getRowProps?: DataTableGetRowProps<T>;
  renderSubComponent?: DataTableRenderSubComponent<T>;
  onDragEnd: (e: DragEndEvent) => void;
}

export const SortableTable = <T extends object>({ table, getRowProps, renderSubComponent, onDragEnd, children }: SortableTableProps<T>) => {
  const rows = table.getRowModel().rows;
  const headers = table.getHeaderGroups()[0].headers;

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <table className={tableClass}>
        <DataTableHeader headers={headers} />
        <tbody className="divide-y divide-neutral-mid-gray divide-opacity-50">
          <SortableContext items={rows} strategy={verticalListSortingStrategy}>
            {rows.map((row) => {
              const rowProps = getRowProps ? getRowProps(row, table) : {};
              return row.getCanExpand() ? (
                <DraggableRow key={row.id} row={row} {...rowProps}>
                  {renderSubComponent && row.getIsExpanded() && renderSubComponent(row)}
                </DraggableRow>
              ) : (
                <DraggableRow key={row.id} row={row} {...rowProps} />
              );
            })}
            {children}
          </SortableContext>
        </tbody>
      </table>
    </DndContext>
  );
};
