import { Icon, Tooltip } from "@/components/atoms";
import { BookingPaymentLogStatus } from "@/redux/slices/booking/types";

interface PaymentStatusProps {
  status: BookingPaymentLogStatus;
}

export const PaymentStatus = ({ status }: PaymentStatusProps) => {
  switch (status) {
    case "paid":
      return (
        <span className="flex items-center gap-2">
          <i className="inline-block h-[8px] w-[8px] shrink-0 rounded-full bg-success"></i> Paid
        </span>
      );
    case "processing":
      return (
        <span className="flex items-center gap-2">
          <i className="inline-block h-[8px] w-[8px] shrink-0 rounded-full bg-info"></i> Partially Paid
        </span>
      );

    case "partially_refunded":
      return (
        <span className="flex items-center gap-2">
          <i className="inline-block h-[8px] w-[8px] shrink-0 rounded-full bg-info"></i> Partially Refunded
        </span>
      );
    case "pending":
      return (
        <div className="-ml-[22px] flex items-center gap-1 ">
          <Tooltip content="This payment is pending to be paid out in our next payment run" placement="top">
            <Icon name="InfoCircle" variant="Bold" size="sm" className="rotate-180 text-neutral-dark-gray" />
          </Tooltip>
          <span className="flex items-center gap-2">
            <i className="inline-block h-[8px] w-[8px] shrink-0 rounded-full bg-warning"></i>
            Pending Payment
          </span>
        </div>
      );
    default:
      return (
        <span className="flex items-center gap-2">
          <i className="inline-block h-[8px] w-[8px] shrink-0 rounded-full bg-danger"></i>
          <span className="capitalize">{status}</span>
        </span>
      );
  }
};
