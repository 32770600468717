import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useMemo } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Panel, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePrompt } from "@/hooks";
import { EditVerifiedVehicleParams } from "@/redux/apis/vehicle/types";
import { useUpdateVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { addToast } from "@/utils";
import { PageHeader } from "../../PageHeader/PageHeader";
import { Sidebar } from "./Sidebar";
import { EditVehicleProvider, useEditVehicleContext } from "./context";
import { VehicleDetails } from "./fields/VehicleDetails";
import { VehicleImage } from "./fields/VehicleImage";
import { EditVehicleFormData, schema } from "./form";

export const EditVehiclePanel = () => {
  return (
    <EditVehicleProvider>
      <PageHeader.Actions />
      <Content />
    </EditVehicleProvider>
  );
};

const Content = () => {
  const { vehicle } = useEditVehicleContext();

  const documents = useMemo(() => {
    const accreditation = vehicle.documents.find((doc) => doc.documentType === "ACCREDITATION");
    const insurance = vehicle.documents.find((doc) => doc.documentType === "INSURANCE");

    return {
      insuranceDoc: insurance?.uuid ?? "",
      insuranceExpiry: insurance?.expiryDate ? new Date(insurance.expiryDate) : null,
      insuranceExpiryConfirm: insurance ? true : false,
      accreditationDoc: accreditation?.uuid ?? null,
      accreditationExpiry: accreditation?.expiryDate ? new Date(accreditation.expiryDate) : null,
      accreditationExpiryConfirm: accreditation ? true : false,
    };
  }, [vehicle]);

  const defaults = useMemo(() => {
    return {
      avatar: vehicle.avatar,
      vehicleOwnerFee: vehicle.maintenanceFee,
      nickname: vehicle.identifier ?? undefined,
      details: {
        bags: vehicle.capacity.bags,
        capacity: vehicle.capacity.pax,
        categories: vehicle.categories.map((category) => category.uuid),
      },
      documents,
    };
  }, [documents, vehicle]);

  const form = useForm<EditVehicleFormData>({
    resolver: zodResolver(schema),
    defaultValues: defaults,
    shouldFocusError: true,
    mode: "all",
  });

  usePrompt({ when: form.formState.isDirty, message: "Changes will not be saved. Do you want to proceed?" });

  return (
    <FormProvider {...form}>
      <div className="flex flex-col items-start gap-3 md:flex-row-reverse">
        <Sidebar />
        <Panel className="flex w-full flex-col gap-5">
          <div className="flex flex-col">
            <Typography variant="h3">Vehicle Details</Typography>
            <Typography className="text-neutral-dark-gray">
              Fill up the last few necessary information to complete your vehicle details
            </Typography>
          </div>
          <EditVehicleForm />
        </Panel>
      </div>
    </FormProvider>
  );
};

const EditVehicleForm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const newVehicle = searchParams.get("newVehicle") === "1";
  const [editVehicle, { isLoading }] = useUpdateVehicleMutation();
  const { vehicle, isDocumentValidating } = useEditVehicleContext();
  const form = useFormContext<EditVehicleFormData>();

  const handleCancel = () => {
    if (newVehicle) navigate("../../");
    else navigate("./../");
  };

  const handleSave = async (data: EditVehicleFormData) => {
    const documents: EditVerifiedVehicleParams["documents"] = [];

    if (data.documents.insuranceDoc) {
      documents.push({
        uuid: data.documents.insuranceDoc,
        expiry_date: data.documents.insuranceExpiry ? format(data.documents.insuranceExpiry, "yyyy-MM-dd") : null,
        document_type: "INSURANCE",
      });
    }

    if (data.documents.accreditationDoc) {
      documents.push({
        uuid: data.documents.accreditationDoc,
        expiry_date: data.documents.accreditationExpiry ? format(data.documents.accreditationExpiry, "yyyy-MM-dd") : null,
        document_type: "ACCREDITATION",
      });
    }

    editVehicle({
      id: vehicle.uuid,
      data: {
        identifier: data.nickname ?? "",
        capacity_pax: data.details.capacity,
        capacity_bags: data.details.bags,
        categories: data.details.categories.map((category) => ({ uuid: category })),
        documents,
        maintenance_fee: data.vehicleOwnerFee,
      } satisfies EditVerifiedVehicleParams,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle successfully updated");
        setSearchParams({});
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  return (
    <form onSubmit={form.handleSubmit(handleSave)} id="edit-vehicle-form" className="flex flex-col gap-5">
      <VehicleImage vehicle={vehicle} />
      <VehicleDetails vehicle={vehicle} />
      <div className="flex justify-end gap-4">
        <Button onClick={handleCancel} variant="secondary" size="lg" disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="lg"
          type="submit"
          form="edit-vehicle-form"
          disabled={isLoading || !form.formState.isDirty || isDocumentValidating}
        >
          {isLoading ? <Spinner /> : "Save Changes"}
        </Button>
      </div>
    </form>
  );
};
