import { useAppAbility } from "@/casl";
import { addToast } from "@/utils";
import { TransportProviderCodeEditModal, TransportProviderCodeViewModal } from "../TransportProviderCode";
import { HeaderSearchModal } from "./HeaderSearchModal";
import { HeaderSteps } from "./types";

interface HeaderModalsProps {
  step: HeaderSteps;
  setStep: (step: HeaderSteps) => void;
};

export const HeaderModals = ({ step, setStep }: HeaderModalsProps) => {
  const ability = useAppAbility();
  const canEditTransportProviderCode = ability.can("manage", "providerConfigKey");
  const showHeaderSearch = ability.can("manage", "providerClient");

  const onClose = () => setStep(undefined);

  return (
    <>
      <TransportProviderCodeViewModal
        open={step === "viewTransportProviderCode"}
        onClose={onClose}
        onEdit={() => setStep("editTransportProviderCode")}
        canEdit={canEditTransportProviderCode}
      />
      {canEditTransportProviderCode && (
        <TransportProviderCodeEditModal
          open={step === "editTransportProviderCode"}
          onClose={onClose}
          onSuccess={() => {
            addToast("success", "Successfully Updated Transport Provider Code");
            setTimeout(() => window.location.reload(), 1250);
          }}
        />
      )}
      {showHeaderSearch && <HeaderSearchModal open={step === "viewHeaderSearchModal"} onClose={onClose} />}
    </>
  );
};