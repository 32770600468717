import { ComponentProps } from "react";
import { DateInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface VehicleDocumentDateInput extends ComponentProps<typeof DateInput> {}

export const VehicleDocumentDateInput = ({ className, ...props }: VehicleDocumentDateInput) => {
  const isExpired = props.selected ? new Date(props.selected) < new Date() : false;

  return (
    <div className="relative block">
      <DateInput className={clsx(className, isExpired && "border-danger")} {...props} />
      {isExpired && (
        <div className="absolute right-0 top-0 flex h-full items-center pr-3 text-danger">
          <Typography variant="paragraph">Expired</Typography>
        </div>
      )}
    </div>
  );
};
