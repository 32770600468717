import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { PrivateNetworkStep } from "./types";

interface PrivateNetworkEmptyStateProps {
  setStep: (step: PrivateNetworkStep) => void;
}

export const PrivateNetworkEmptyState = ({ setStep }: PrivateNetworkEmptyStateProps) => (
  <EmptyState title="Manage Private Network" description="It looks like you haven't added any private network yet. Begin by inviting your first private network to get started">
    <Button variant="primary" size="md" startIcon="Add" onClick={() => setStep("inviteCode")}>Invite New</Button>
  </EmptyState>
);
