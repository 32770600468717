import { Button, Loading, Toggle, Typography } from "@/components/atoms";
import { InvoicingConfiguration } from "@/redux/slices/addOn/types";
import { Controller, useFormContext } from "react-hook-form";

interface InvoicingEditProps {
  onSubmit: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

export const InvoicingEdit = ({ onSubmit, onClose, isLoading }: InvoicingEditProps) => {
  const {
    control,
    formState: { isDirty },
    trigger,
  } = useFormContext<InvoicingConfiguration>();

  const renderButtons = () => (
    <div className="flex flex-row w-full items-center justify-end gap-x-4 mt-8">
      <Button variant="secondary" size="sm" onClick={onClose}>Cancel</Button>
      <Button variant="primary" size="sm" type="submit" disabled={!isDirty || isLoading}>Save Settings</Button>
    </div>
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="relative flex flex-col w-full bg-neutral-surface-gray rounded-lg p-5 my-4 border border-primary pointer-events-auto">
        {isLoading && <Loading className="rounded-lg" />}
        <div className="flex flex-row items-center gap-x-[15px]">
          <div className="flex flex-1 flex-col">
            <Typography variant="action">Enable Invoicing Generation</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray">Manually or automatically generate invoices from the RM system - leave off if you manage invoices externally</Typography>
          </div>
          <Controller
            name="invoiceModuleEnabled"
            control={control}
            render={({ field }) => (
              <Toggle
                checked={field.value}
                onChange={(checked) => {
                  field.onChange(checked);
                  trigger();
                }}
              />
            )}
          />
        </div>
        {renderButtons()}
      </div>
    </form>
  );
};
