import { ArrayParam, useQueryParams } from "use-query-params";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetExternalSuppliersParams } from "@/redux/apis/externalSupplier/types";
import { ExternalSuppliersFilters } from "../types";

const FilterParams = {
  type: ArrayParam,
  accountStatus: ArrayParam,
  addressState: ArrayParam,
  rating: ArrayParam,
};

export const useGetExternalSuppliersParams = () => {
  const { page, pageSize } = usePagination();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const [filters, setFilters] = useQueryParams(FilterParams);
  const [search, setSearch] = useSearch();

  const params: GetExternalSuppliersParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    search,
    type: filters.type ? (filters.type.filter(Boolean) as string[]) : undefined,
    account_status: filters.accountStatus ? (filters.accountStatus.filter(Boolean) as string[]) : undefined,
    address_state: filters.addressState ? (filters.addressState.filter(Boolean) as string[]) : undefined,
    rating: filters.rating ? (filters.rating.filter(Boolean) as string[]) : undefined,
  };

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    filters: {
      type: params.type,
      accountStatus: params.account_status,
      addressState: params.address_state,
      rating: params.rating,
    } as ExternalSuppliersFilters,
    setFilters,
    search,
    setSearch,
  };
};
