import { Icon, Modal, Typography } from "@/components/atoms";
import { AddTimeAndDayMultiplierForm } from "../forms/AddTimeAndDayMultiplierForm";

interface AddTimeAndDayMultiplierModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddTimeAndDayMultiplierModal = ({ open, onClose }: AddTimeAndDayMultiplierModalProps) => {
  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Add Time & Day
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
        </div>
      </div>
    </div>
  );

  return (
    <Modal open={open} onClose={onClose} className="w-full max-w-[517px]">
      {renderHeader()}
      <AddTimeAndDayMultiplierForm onClose={onClose} />
    </Modal>
  );
};
