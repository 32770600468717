import { Icon, IconButton, Toggle, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { TimeAndDayMultiplier } from "@/redux/slices/pricing/types";
import { format, parseISO } from "date-fns";
import { TimeAndDayTableData } from "./types";

export const generateColumns = (data: TimeAndDayTableData[]) => {
  const columns: DataTableColumnDef<TimeAndDayTableData>[] = [
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "time", header: "Time", size: 250, enableSorting: false },
    { accessorKey: "date", header: "Date", size: 250, enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active, size: 100, enableSorting: false },
  ];

  return columns;
};

export const generateEditorColumns = (data: TimeAndDayTableData[]) => {
  const columns: DataTableColumnDef<TimeAndDayTableData>[] = [
    {
      accessorKey: "reorder",
      header: "",
      size: 100,
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "time", header: "Time", size: 250, enableSorting: false },
    { accessorKey: "date", header: "Date", size: 250, enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active, size: 100, enableSorting: false },
    { header: " ", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];

  return columns;
};

export const generateTableData = (items: TimeAndDayMultiplier[], dateFormat: string, timeFormat: string) => {
  const date = format(new Date(), "yyyy-MM-dd");
  return items.map(
    (i) => ({
      id: i.id,
      order: i.order,
      name: (
        <div className="flex flex-row items-center gap-x-1.5">
          <Typography className="leading-6">{i.name}</Typography>
          {i.isRecurring && (
            <Tooltip
              content="Recurring Time & Day"
              placement="right"
            >
              <span>
                <Icon name="Calendar" variant="Bold" className="text-[#FF825B]" />
              </span>
            </Tooltip>
          )}
        </div>
      ),
      time: i.type === "date_time_based" ? `${format(parseISO(i.startTime), timeFormat)} - ${format(parseISO(i.endTime), timeFormat)}` : `${format(parseISO(`${date} ${i.startTime}`), timeFormat)} - ${format(parseISO(`${date} ${i.endTime}`), timeFormat)}`,
      date: i.type === "date_time_based" ? renderDate(i.startTime, i.endTime, i.isRecurring, dateFormat) : "-",
      costIncrease: `${i.multiplier > 0 ? "+" : ""}${i.multiplier}%`,
      active: i.active ? (
        <Icon name="TickCircle" variant="Bold" className="text-success" />
      ) : (
        <Icon name="CloseCircle" variant="Bold" className="text-danger" />
      ),
    } as TimeAndDayTableData)
  );
};

export const generateEditorTableData = (items: TimeAndDayMultiplier[], dateFormat: string, timeFormat: string, toggleItemStatus: (id: string, active: boolean) => void, onEditItem: (item: TimeAndDayMultiplier) => void, onDeleteItem: (item: TimeAndDayMultiplier) => void) => {
  const date = format(new Date(), "yyyy-MM-dd");
  return items.map(
    (i) =>
      ({
        id: i.id,
        order: i.order,
        name: (
          <div className="flex flex-row items-center gap-x-1.5">
            <Typography className="leading-6">{i.name}</Typography>
            {i.isRecurring && (
              <Tooltip
                content="Recurring Time & Day"
                placement="right"
              >
                <span>
                  <Icon name="Calendar" variant="Bold" className="text-[#FF825B]" />
                </span>
              </Tooltip>
            )}
          </div>
        ),
        time: i.type === "date_time_based" ? `${format(parseISO(i.startTime), timeFormat)} - ${format(parseISO(i.endTime), timeFormat)}` : `${format(parseISO(`${date} ${i.startTime}`), timeFormat)} - ${format(parseISO(`${date} ${i.endTime}`), timeFormat)}`,
        date: i.type === "date_time_based" ? renderDate(i.startTime, i.endTime, i.isRecurring, dateFormat) : "-",
        costIncrease: `${i.multiplier > 0 ? "+" : ""}${i.multiplier}%`,
        active: <Toggle size="sm" checked={i.active} onChange={(checked) => toggleItemStatus(i.id, checked)} className="mt-0" />,
        actions: (
          <span className="flex gap-2">
            <IconButton
              iconName="Edit2"
              variant="tertiary"
              onClick={() => onEditItem(i)}
              className="border-none bg-transparent p-1 !text-info hover:bg-info-light"
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              onClick={() => onDeleteItem(i)}
              className="border-none bg-transparent p-1 !text-danger hover:bg-danger-light"
            />
          </span>
        ),
      } as TimeAndDayTableData)
  );
};

const renderDate = (start: string, end: string, isRecurring: boolean, dateFormat: string) => isRecurring ? `${format(parseISO(start), "dd/MM")} - ${format(parseISO(end), "dd/MM")}` : `${format(parseISO(start), dateFormat)} - ${format(parseISO(end), dateFormat)}`;