import { Button, Container, Typography } from "@/components/atoms";
import { GeneralFooter, GeneralHeader } from "@/components/organisms";
import Confetti from "@/assets/images/job-rating/confetti.svg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/redux/hooks";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";

export const JobRatingSuccess = () => {
  const navigate = useNavigate();
  const operator = useAppSelector(activeOperatorSelector);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        <Container className="flex flex-1 flex-col items-center text-center pt-[60px] pb-[338px]">
          <img src={Confetti} alt="Confetti" className="h-64 w-64" />
          <Typography variant="h2" className="mt-6">Thank you for rating this trip</Typography>
          <div className="mt-8 break-words w-3/4">
            <Typography variant="paragraph">Your rating has been successfully submitted. We appreciate your input as it helps us improve our services. Safe travels!</Typography>
          </div>
          <Button variant="primary" size="lg" className="mt-8" onClick={() => navigate(`/login${operator ? `/${operator.providerCode}` : ""}`)}>Book Another Trip</Button>
        </Container>
        <GeneralFooter />
      </div>
    </div>
  );
};