import { Label, Radio, RadioGroup } from "@headlessui/react";
import { Icon, Tooltip } from "@/components/atoms";
import { clsx } from "@/utils";

export interface VehicleColorInputProps extends React.ComponentProps<typeof RadioGroup> {
  children: React.ReactNode;
}
export const VehicleColorInput = ({ className, value, onChange, children, ...props }: VehicleColorInputProps) => {
  return (
    <RadioGroup value={value} className={clsx("flex flex-wrap gap-3", className)} onChange={onChange} {...props}>
      {children}
    </RadioGroup>
  );
};

export interface VehicleColorInputOptionProps extends React.ComponentProps<typeof Radio> {
  isSelected: boolean;
  name: string;
  hex: string;
}

export const VehicleColorInputOption = ({ name, hex, isSelected, className, value, ...props }: VehicleColorInputOptionProps) => {
  return (
    <Tooltip key={name} content={name} placement="top">
      <Radio
        value={value}
        className={clsx(
          "relative inline-block h-14 w-14 cursor-pointer rounded-full p-1.5  focus:outline-none focus:ring-0",
          {
            "border border-black": isSelected,
          },
          className
        )}
        {...props}
      >
        <div className="flex items-center justify-center">
          <Label as="span" className="sr-only">
            {name}
          </Label>
          <span
            aria-hidden="true"
            style={{
              backgroundColor: hex,
            }}
            className="h-11 w-11 flex-shrink-0 rounded-full"
          />
          <Icon isCustom name="check" size="sm" className={clsx("absolute", { hidden: !isSelected })} />
        </div>
      </Radio>
    </Tooltip>
  );
};

VehicleColorInput.Option = VehicleColorInputOption;
VehicleColorInput.displayName = "VehicleColorInput";
