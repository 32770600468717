import { forwardRef } from "react";
import { clsx, getAssetUrl } from "@/utils";

export interface AvatarProps extends React.ComponentPropsWithRef<"div"> {
  alt?: string;
  imgProps?: React.ComponentPropsWithoutRef<"img">;
  size?: "xs" | "sm" | "md" | "lg";
  src?: string;
  variant?: "circle" | "square";
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const { src, alt, imgProps, size = "sm", variant = "circle", children, className, ...rest } = props;

  const fallbackImage = getAssetUrl("infrastructure/images/fallback.jpg", { height: 120, width: 120, fit: "cover" });

  const sizeClass = {
    "h-6 w-6": size === "xs",
    "h-8 w-8": size === "sm",
    "h-12 w-12": size === "md",
    "h-[60px] w-[60px]": size === "lg",
  };

  const variantClass = {
    "rounded-full": variant === "circle",
    "rounded-md": variant === "square",
  };

  return (
    <div
      ref={ref}
      className={clsx(
        "flex shrink-0 items-center justify-center overflow-hidden text-center [&>img]:h-full [&>img]:w-full [&>img]:object-cover",
        sizeClass,
        variantClass,
        className
      )}
      {...rest}
    >
      {children ? children : <img src={src || fallbackImage} alt={alt} {...imgProps} />}
    </div>
  );
});

Avatar.displayName = "Avatar";