import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { Button } from "@/components/atoms";
import { DataTable, DataTableColumnDef } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { setPaymentAction } from "@/redux/slices/booking/bookingSlice";
import { BookingPaymentLedgerLog } from "@/redux/slices/booking/types";
import { capitalize, formatCurrency } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { BookingPaymentLedgerData } from "../types";

const columns: DataTableColumnDef<BookingPaymentLedgerData>[] = [
  {
    header: "Date",
    accessorKey: "date",
    size: 120,
    enableSorting: false,
  },
  {
    header: "Amount",
    accessorKey: "amount",
    size: 80,
    enableSorting: false,
  },
  {
    header: "Fees",
    accessorKey: "fees",
    size: 80,
    enableSorting: false,
  },
  {
    header: "Description",
    accessorKey: "description",
    enableSorting: false,
  },
  {
    header: "Category",
    accessorKey: "category",
    size: 80,
    enableSorting: false,
  },
  {
    header: "Type",
    accessorKey: "type",
    enableSorting: false,
    size: 120,
    cell: (props) => props.row.original.type,
  },
  {
    header: "Action",
    size: 100,
    cell: (props) => props.row.original.actions,
  },
];

export const PaymentLedgerTable = ({ items }: { items: BookingPaymentLedgerLog[] }) => {
  const { dateFormat } = useProfile();
  const {
    cost: { currency },
  } = useSelectedBooking();

  const data: BookingPaymentLedgerData[] = items.map((log) => ({
    id: log.id,
    date: format(new Date(log.createdAt), dateFormat),
    fees: formatCurrency(log.fees, 2, currency),
    amount: formatCurrency(log.amount, 2, currency),
    description: log.description.substring(log.description.indexOf("-") + 1).trim(),
    category: capitalize(log.category),
    type: <span className="capitalize">{log.type.replace("_", " ")}</span>,
    actions: <PaymmentLedgetTableActions log={log} />,
  }));

  return <DataTable data={data} columns={columns} className="[&_table]:table-auto overflow-x-auto w-full mt-5" />;
};

const PaymmentLedgetTableActions = ({ log }: { log: BookingPaymentLedgerLog }) => {
  const dispatch = useDispatch();

  return (
    <div className="contents">
      {log.isReturnable && (
        <Button
          onClick={() => dispatch(setPaymentAction({ action: "return-funds", log }))}
          variant="custom"
          startIcon="Refresh2"
          className="!p-0 text-primary [&>.typography]:text-sm [&>.typography]:font-medium"
        >
          Return
        </Button>
      )}
      {log.isDisburseable && (
        <Button
          onClick={() => dispatch(setPaymentAction({ action: "disburse-funds", log }))}
          variant="custom"
          startIcon="Export"
          className="!p-0 text-primary [&>.typography]:text-sm [&>.typography]:font-medium"
        >
          Disburse
        </Button>
      )}
    </div>
  );
};
