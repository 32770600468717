import { useState } from "react";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetClientsParams } from "@/redux/apis/client/types";

export const useGetClientsParams = () => {
  const { page, pageSize } = usePagination();
  const { sorting, setSorting, sortKey, direction } = useSorting();
  //TODO: useQueryParam for filters
  const [filter, setFilter] = useState({});
  const [search, setSearch] = useSearch();

  const params: GetClientsParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    search,
    ...filter,
  };

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    filter,
    setFilter,
    search,
    setSearch,
  };
};
