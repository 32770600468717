import { HTMLAttributes, forwardRef } from "react";
import { ProgressBar, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface OnboardingProgressProps extends HTMLAttributes<HTMLDivElement> {
  progress: number;
  title?: string;
  desc?: string;
}

export const OnboardingProgress = forwardRef<HTMLDivElement, OnboardingProgressProps>((props, ref) => {
  const { className, progress = 0, title = "Getting Started", desc = "View Onboarding Page", ...rest } = props;

  return (
    <div
      className={clsx(
        "inline-flex w-full cursor-pointer flex-col overflow-hidden rounded-lg border border-info bg-info-light px-2 py-3",
        className
      )}
      {...rest}
      ref={ref}
    >
      <Typography variant="action" className="flex w-full leading-tight text-info">
        <span className="flex-1">{title}</span>
        <span>{progress}%</span>
      </Typography>
      <Typography variant="small" className="leading-loose text-info/70">
        {desc}
      </Typography>
      <ProgressBar value={progress} className="mt-2 w-full" />
    </div>
  );
});

OnboardingProgress.displayName = "OnboardingProgress";
