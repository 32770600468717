import { Alert, Divider, Recaptcha, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { clsx } from "@/utils";
import { DriversLicenseField, StateRegisteredField } from "../forms/fields";

interface SignUpFormDriverStep2Props extends React.HTMLAttributes<HTMLDivElement> {
  onRecaptchaChange: (token: string | null) => void;
}

export const SignUpFormDriverStep2 = ({ onRecaptchaChange, className, ...props }: SignUpFormDriverStep2Props) => {
  const {
    name,
    urls: { privacy },
    flags,
    bookableCountryList,
  } = useOperator();

  return (
    <div className={clsx("relative grid animate-enter grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", className)} {...props}>
      <StateRegisteredField
        className={clsx(
          "col-span-2",
          bookableCountryList.length > 1 ? "grid grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6" : "lg:col-span-1"
        )}
      />
      <DriversLicenseField className="col-span-2 lg:col-span-1" />
      <Divider className="col-span-2" />
      {/* <VehicleField setOpenModal={setOpenModal} className="col-span-2" /> */}
      {flags.driverPaidByPlatform && <Alert type="info" message={`You will be paid directly by ${name}`} className="col-span-2" />}
      <Recaptcha onChange={onRecaptchaChange} className="col-span-2" />
      <Typography variant="paragraph" className="col-span-2 px-8 text-center text-neutral-dark-gray md:px-[65px]">
        By clicking sign-up you agree to the Terms & Conditions and acknowledge you have read our{" "}
        <a href={privacy} target="_blank" className="inline" rel="noreferrer">
          Privacy Policy
        </a>
      </Typography>
    </div>
  );
};
