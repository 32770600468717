import { saveAs } from "file-saver";
import { addToast } from "./addToast";

export const downloadFile = (file: string | Blob | null, name: string) => {
  if (file) {
    try {
      saveAs(file, name);
    } catch (error) {
      addToast("danger", "Failed to download file.");
    }
  } else {
    addToast("danger", "Failed to download file.");
  }
};
