import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Icon, Typography, Modal, Button, MultiEmailInput, Loading } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { companyApi } from "@/redux/apis";
import { useResendInvoiceMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch } from "@/redux/hooks";
import { Invoice } from "@/redux/slices/payment/types";
import { addNotification, addToast } from "@/utils";
import { resendInvoiceFormSchema } from "../fixtures";
import { ResendInvoiceFormData } from "../types";

interface ResendInvoiceModalProps {
  open: boolean;
  invoice: Invoice;
  onClose: () => void;
}

export const ResendInvoiceModal = ({ open, invoice, onClose }: ResendInvoiceModalProps) => {
  const dispatch = useAppDispatch();
  const [resendInvoice, { isLoading }] = useResendInvoiceMutation();
  const { watch, handleSubmit, setValue, reset } = useForm<ResendInvoiceFormData>({
    defaultValues: {
      emails: invoice.emailLog,
    },
    resolver: zodResolver(resendInvoiceFormSchema),
  });
  const watchEmails = watch("emails");

  useEffect(() => reset(), [invoice, reset]);

  const onSubmit = handleSubmit((data) => {
    const resendInvoiceData = {
      id: invoice.id,
      to: data.emails.map((email) => ({ email })),
    };

    resendInvoice(resendInvoiceData)
      .unwrap()
      .then(() => {
        addNotification("info", `Invoice ${invoice.identifier}`, "Invoice has been resent");
        onClose();
        dispatch(companyApi.util.invalidateTags(["InvoicesCompanies"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  });

  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Resend Invoice
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
        </div>
      </div>
    </div>
  );

  const renderButtons = () => (
    <div className="mt-2.5 flex flex-col">
      <Button size="lg" variant="primary" onClick={onSubmit}>
        Resend now to Email(s) listed
      </Button>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!isLoading) onClose();
      }}
      className="relative w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {isLoading && <Loading />}
      {renderHeader()}
      <div className="flex flex-col gap-y-6 py-3">
        <Typography variant="paragraph">Resend the invoice to the email addresses provided below.</Typography>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Send Email(s) to</Typography>
          <MultiEmailInput emails={watchEmails} onChange={(emails) => setValue("emails", emails)} />
        </div>
      </div>
      {renderButtons()}
    </Modal>
  );
};
