import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { UserAccount, UserAccountStatus } from "@/redux/slices/userAccount/types";
import { UserAccountsTableData } from "./types";

export const generateColumns = () => {
  const columns: DataTableColumnDef<UserAccountsTableData>[] = [
    { accessorKey: "name", header: "Name", enableSorting: false },
    { accessorKey: "status", header: "Status", cell: (props) => props.row.original.status, enableSorting: false },
    { accessorKey: "email", header: "Email Address", enableSorting: false },
    { accessorKey: "phone", header: "Phone Number", enableSorting: false },
    { accessorKey: "permission", header: "Permission", enableSorting: false },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateData = (
  items: UserAccount[],
  onChangeClick: (user: UserAccount) => void,
  onRemoveClick: (user: UserAccount) => void,
  onResendClick: (user: UserAccount) => void,
  onCancelClick: (user: UserAccount) => void
) => {
  const data = items.map(
    (i) =>
      ({
        name: i.name || "-",
        status: renderStatus(i.status),
        email: i.email,
        phone: i.phone || "-",
        permission: i.role as string,
        action:
          i.status === "pending"
            ? renderPendingActions(i, onResendClick, onCancelClick)
            : renderDefaultActions(i, onChangeClick, onRemoveClick),
      } as UserAccountsTableData)
  );

  return data;
};

const renderStatus = (status: UserAccountStatus) => {
  switch (status) {
    case "pending":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-neutral-dark-gray" /> Pending
        </Typography>
      );
    case "active":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-success" /> Active
        </Typography>
      );
    case "inactive":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 rounded-full bg-danger" /> Inactive
        </Typography>
      );
    default:
      return <></>;
  }
};

const renderDefaultActions = (
  user: UserAccount,
  onChangeClick: (user: UserAccount) => void,
  onRemoveClick: (user: UserAccount) => void
) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onChangeClick(user)}>
        <Icon name="Edit2" className="mr-2.5 text-primary-dark" size="md" />
        <Typography className="text-primary-dark">Change Account Roles</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => onRemoveClick(user)}>
        <Icon name="close" isCustom className="mr-2.5 text-primary-dark" size="md" />
        <Typography className="text-primary-dark">Remove access</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

const renderPendingActions = (
  user: UserAccount,
  onResendClick: (user: UserAccount) => void,
  onCancelClick: (user: UserAccount) => void
) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onResendClick(user)}>
        <Icon name="Refresh" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">Resend Invitation</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => onCancelClick(user)}>
        <Icon name="close" isCustom className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">Remove Invitation</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};
