import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import z from "zod";
import { Button, Icon, Loading, Map, RangeSlider, Typography, LocationInput, ErrorMessage } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateFixedPricingLocationMutation } from "@/redux/apis/config/pricing/tripCost";
import { FixedPricingLocation, PricingProfile } from "@/redux/slices/pricing/types";
import { addToast } from "@/utils";

interface CoverageAreaEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  pricing: FixedPricingLocation;
  pricingProfile: PricingProfile;
}

const formSchema = z.object({
  referencePoint: z.object({
    id: z.string().nonempty("Field is required and can't be empty."),
    address: z.string().nonempty("Field is required and can't be empty."),
    coordinates: z.object({
      lat: z.number(),
      lng: z.number(),
    }),
  }),
  coverageRange: z.number(),
});

type FormData = z.infer<typeof formSchema>;

export const CoverageAreaEditor = ({ pricing, pricingProfile, className, ...props }: CoverageAreaEditorProps) => {
  const [updateLocation, { isLoading }] = useUpdateFixedPricingLocationMutation();
  const navigate = useNavigate();

  const { pickupLocation } = pricing;
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      referencePoint: {
        id: "reference-point-id",
        address: pickupLocation.address,
        coordinates: {
          lat: pickupLocation.latitude,
          lng: pickupLocation.longitude,
        },
      },
      coverageRange: pickupLocation.radius,
    },
    resolver: zodResolver(formSchema),
  });
  const watchReferencePoint = watch("referencePoint");
  const watchCoverageRange = watch("coverageRange");

  const onSubmit = handleSubmit((data) => {
    updateLocation({
      profileId: pricingProfile.id,
      locationId: pricing.id,
      name: pickupLocation.name,
      pickup_address: data.referencePoint.address,
      pickup_latitude: data.referencePoint.coordinates.lat,
      pickup_longitude: data.referencePoint.coordinates.lng,
      pickup_radius: data.coverageRange,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Coverage Area");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <div className={className} {...props}>
      <form onSubmit={onSubmit} className="relative">
        {isLoading && <Loading />}
        <div className="flex flex-col">
          <Typography variant="h3" className="flex flex-1 leading-8">
            Coverage Area
          </Typography>
          <Typography className="text-neutral-dark-gray">
            This represents the coverage area range to which this pricing will be applied.
          </Typography>
        </div>
        <div className="mt-4 h-[303px]">
          <Map circleRadius={watchCoverageRange * 1000} center={watchReferencePoint?.coordinates} />
        </div>
        <div className="mt-4 flex flex-col">
          <Typography variant="paragraph">Reference point</Typography>
          <Controller
            name="referencePoint"
            control={control}
            render={({ field }) => (
              <LocationInput
                placeholder="Enter Reference Point"
                renderIcon={() => <Icon name="Location" size="sm" className="text-neutral-dark-gray" />}
                onLocationChange={(value) => field.onChange(value)}
                hasError={!!errors.referencePoint}
                defaultValue={pickupLocation.address}
              />
            )}
          />
          <ErrorMessage errors={errors} name="referencePoint" />
        </div>
        <div className="flex flex-col items-center gap-y-4">
          <Controller
            name="coverageRange"
            control={control}
            render={({ field }) => (
              <RangeSlider min={0} max={30} step={0.5} onChange={(value) => field.onChange(value)} value={field.value} />
            )}
          />
          <Typography variant="title">{`Within ${watchCoverageRange} km`}</Typography>
        </div>
        <div className="mt-6 flex flex-row gap-x-4">
          <Button variant="secondary" size="md" onClick={() => navigate("../")} className="w-full">
            Cancel
          </Button>
          <Button variant="primary" size="md" type="submit" className="w-full">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};
