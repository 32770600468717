import { useFormContext } from "react-hook-form";
import { ErrorMessage, Icon, TextInput, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpOperatorFormData } from "../types";

export const BusinessTaglineField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph">Business Tagline</Typography>
        <Tooltip content="A phrase that expresses your company's greater purpose and mission" placement="right">
          <span>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </span>
        </Tooltip>
      </div>
      <TextInput
        className="w-full"
        placeholder="Enter Company Tag Line"
        maxLength={32}
        hasError={!!errors.businessTagline}
        {...register("businessTagline")}
      />
      <Typography variant="small" className="text-neutral-dark-gray">
        Optional
      </Typography>
      <ErrorMessage errors={errors} name="businessTagline" />
    </div>
  );
};
