import { ExternalSuppliersPanel, PageHeader } from "@/components/organisms";
import { ExternalSuppliersAdd } from "./ExternalSuppliersAdd";

export const ExternalSuppliers = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader skipCrumbs={1} title="External Suppliers" />
      <ExternalSuppliersPanel />
    </div>
  );
};

ExternalSuppliers.Add = ExternalSuppliersAdd;
