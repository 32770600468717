import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Spinner, Typography } from "@/components/atoms";
import { useGetVehicleCategoriesQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { AddTransferTypeFormData } from "../form";
import { AssociatedCategoriesTable } from "./AssociatedCategoriesTable";

export const TransferTypeAssociatedCategories = () => {
  const { setValue, watch } = useFormContext<AddTransferTypeFormData>();
  const { isFetching, data } = useGetVehicleCategoriesQuery({ page_size: "999" });

  const categories = useMemo(() => {
    if (!data) return [];
    return data.categories;
  }, [data]);

  const transferTypeName = watch("name");
  const associatedCategories = watch("associatedCategories");

  const onCheckChanged = useCallback(
    (categoryId: string) => {
      if (associatedCategories.includes(categoryId)) {
        setValue(
          "associatedCategories",
          associatedCategories.filter((id) => id !== categoryId),
          {
            shouldDirty: true,
          }
        );
      } else
        setValue("associatedCategories", [...associatedCategories, categoryId], {
          shouldDirty: true,
        });
    },
    [associatedCategories, setValue]
  );

  const onCheckAllChanged = useCallback(
    (checked: boolean) => {
      setValue("associatedCategories", checked ? categories.map((category) => category.uuid) : [], {
        shouldDirty: true,
      });
    },
    [setValue, categories]
  );

  return (
    <div className="col-span-2 mt-5 flex flex-col">
      <header className="mb-3 ">
        <div className="flex w-full items-center">
          <Typography variant="action">Select Vehicle Categories</Typography>
          <Typography className="flex-1 text-right text-primary">
            {associatedCategories.length > 0 ? `${associatedCategories.length} Vehicle Categories Selected` : ""}
          </Typography>
        </div>
        {transferTypeName && (
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            Manage vehicle categories that are applicable for “{transferTypeName}”
          </Typography>
        )}
      </header>
      {isFetching ? (
        <div className="flex h-[400px] w-full flex-col items-center justify-center gap-4 rounded-lg border border-neutral-mid-gray bg-neutral-surface-gray">
          <Spinner className="h-8 w-8 border-neutral-dark-gray" />
          <Typography className="text-neutral-dark-gray">Updating Vehicle Category List</Typography>
        </div>
      ) : (
        <AssociatedCategoriesTable
          items={categories}
          checkedIds={associatedCategories}
          onCheckChanged={onCheckChanged}
          onCheckAllChanged={onCheckAllChanged}
        />
      )}
    </div>
  );
};
