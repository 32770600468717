import { clsx } from "@/utils";
import { ConfirmPasswordField, ContactNumberField, DateOfBirthField, EmailField, FirstNameField, LastNameField, PasswordField } from "../forms/fields";

export const SignUpFormDriverStep1 = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx("animate-enter grid grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", className)} {...props}>
      <FirstNameField className="col-span-2 lg:col-span-1" />
      <LastNameField className="col-span-2 lg:col-span-1" />
      <EmailField className="col-span-2 lg:col-span-1" />
      <ContactNumberField className="col-span-2 lg:col-span-1" />
      <div className="col-span-2 grid grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
        <DateOfBirthField className="col-span-2 lg:col-span-1" />
      </div>
      <PasswordField className="col-span-2 lg:col-span-1" />
      <ConfirmPasswordField className="col-span-2 lg:col-span-1" />
    </div>
  );
};
