import { Button, Modal, Typography } from "@/components/atoms";
import { addOnsDisableErrorMessage } from "../fixtures";

interface AddOnDisableErrorModalProps {
  open: boolean;
  onClose: () => void;
  id?: string;
}

export const AddOnDisableErrorModal = ({ open, onClose, id }: AddOnDisableErrorModalProps) => {  
  return (
    <Modal 
      open={open} 
      onClose={onClose} 
      className="w-[80vw] md:w-[514px] lg:w-[514px]"
    >
      <div className="relative flex flex-col">
        <Typography variant="title">Cannot Disable Add-On</Typography>
        <Typography variant="paragraph" className="mt-3 whitespace-pre-wrap">{id && Object.keys(addOnsDisableErrorMessage).includes(id) ? addOnsDisableErrorMessage[id] : "This add-on cannot be disabled at this time. Please contact RideMinder support for any assistance."}</Typography>
        <div className="flex flex-row items-center justify-end gap-x-4 mt-[22px]">
          <Button variant="secondary" size="md" onClick={onClose}>Back</Button>
        </div>
      </div>
    </Modal>
  );
};
