import { RatingItem } from "@/redux/slices/rating/types";
import { ViewRatingModal } from "./modals/ViewRatingModal";
import { RatingsStep } from "./types";

interface RatingsModalsProps {
  step: RatingsStep;
  setStep: (step: RatingsStep) => void;
  setSelectedRating: (item?: RatingItem) => void;
  item?: RatingItem;
}

export const RatingsModals = ({ step, setStep, setSelectedRating, item }: RatingsModalsProps) => {
  const onClose = () => {
    setStep(undefined);
    setSelectedRating(undefined);
  };

  return (
    <>
      <ViewRatingModal open={step === "view"} onClose={onClose} item={item} />
    </>
  );
};
