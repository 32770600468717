import { useDebouncedEffect } from "@react-hookz/web";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, DropdownSelectOption, ErrorMessage, Typography } from "@/components/atoms";
import { useLazySearchDriverQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { ExportReportFormData } from "../types";

export const DriverExternalSupplierField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const [searchDriver, { isFetching, data: clients }] = useLazySearchDriverQuery();
  const {
    control,
    formState: { errors },
  } = useFormContext<ExportReportFormData>();
  const [query, setQuery] = useState("");

  useDebouncedEffect(
    () => {
      if (query.length > 2) searchDriver({ q: query });
    },
    [query, searchDriver],
    500
  );

  const options: DropdownSelectOption[] = clients
    ? clients.map((i) => ({
        value: i.id,
        name: i.name,
      }))
    : [];

  return (
    <div className={className} {...props}>
      <Typography variant="paragraph">Driver / External Supplier</Typography>
      <Controller
        control={control}
        name="driverExternalSupplier"
        render={({ field }) => (
          <Autocomplete
            placeholder="Select Driver / External Supplier"
            options={options}
            isLoading={isFetching}
            value={options.find((i) => i.value === field.value) ?? null}
            onValueChange={(option) => field.onChange(option?.value ?? "")}
            onQueryChange={setQuery}
            hasError={!!errors.driverExternalSupplier}
            className="max-w-md"
            size="md"
          />
        )}
      />
      <ErrorMessage errors={errors} name="driverExternalSupplier" />
    </div>
  );
};
