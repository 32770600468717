import { Combobox } from "@headlessui/react";
import cx from "classnames";
import { useState } from "react";
import { Coordinates, Typography } from "@/components/atoms";
import { Place, usePlaces } from "@/hooks/usePlaces";

export interface LocationInputProps {
  className?: string;
  placeholder?: string;
  renderIcon: () => JSX.Element;
  onLocationChange: (v: Location | null) => void;
  hasError?: boolean;
  defaultValue?: string;
}

export type Location = {
  id: string;
  address: string;
  coordinates: Coordinates;
};

export const LocationInput = ({
  className,
  placeholder = "Search Location",
  onLocationChange,
  renderIcon,
  hasError = false,
  defaultValue,
}: LocationInputProps) => {
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const { getPredictions, predictions, placesService } = usePlaces();

  const handleOnPlaceChange = (place: Place | null) => {
    setSelectedPlace(place);
    if (place && placesService) {
      placesService.getDetails({ placeId: place.place_id, fields: ["geometry.location"] }, (details) => {
        if (details?.geometry?.location) {
          const coordinates = {
            lat: details.geometry.location.lat(),
            lng: details.geometry.location.lng(),
          };

          onLocationChange({
            id: place.place_id,
            address: place.description,
            coordinates,
          });
        } else {
          onLocationChange(null);
        }
      });
    } else {
      onLocationChange(null);
    }
  };

  return (
    <Combobox as="div" value={selectedPlace} onChange={handleOnPlaceChange} nullable>
      <div className={cx("relative", className)}>
        <Combobox.Input
          className={cx(
            "h-12 w-full min-w-[320px] rounded-lg border-neutral-mid-gray pl-10 text-base text-neutral-black placeholder-neutral-dark-gray focus:border-primary focus:shadow-input focus:ring-0",
            { "border-1 !focus:border-danger !border-danger": hasError }
          )}
          onChange={(event) => getPredictions(event.target.value)}
          displayValue={(p: Place) => (p ? p.description : defaultValue ? defaultValue : "")}
          placeholder={placeholder}
          autoComplete="off"
        />
        <Combobox.Button className="absolute inset-y-0 left-0 flex items-center px-3 focus:outline-none">{renderIcon()}</Combobox.Button>

        {predictions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md  bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {predictions.map((p) => (
              <Combobox.Option
                key={p.place_id}
                value={p}
                className="flex cursor-pointer gap-2 text-ellipsis px-2 py-2 hover:bg-primary-light"
              >
                <div className="flex w-[30px] flex-shrink-0 items-center justify-center">{renderIcon()}</div>
                <Typography>{p.description}</Typography>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
