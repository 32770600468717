import { clsx } from "@/utils";
import { FleetLeader, FleetLeadersGridItem } from "./FleetLeadersGridItem";

export interface FleetLeadersGridProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: Array<FleetLeader>;
  children?: React.ReactNode;
}

export const FleetLeadersGrid = ({ items, className, children, ...props }: FleetLeadersGridProps) => {
  return (
    <div className={clsx("grid grid-cols-[repeat(auto-fit)] sm:grid-cols-[repeat(auto-fit,max(257px))] gap-5", className)} {...props}>
      {children ? (
        <div className="contents">{children}</div>
      ) : (
        items?.map((i) => {
          return <FleetLeadersGridItem key={i.id} item={i} />;
        })
      )}
    </div>
  );
};

FleetLeadersGrid.Item = FleetLeadersGridItem;
