import { DropdownMenu, Icon, Loading, Toggle, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { FleetDriver, OffloadDriver } from "@/redux/slices/driver/types";
import { clsx, getAssetUrl } from "@/utils";
import { ArchivedDriversTableData, BlockedOffloadDriversTableData, MyDriversStep, MyDriversTableData, OffloadDriversTableData } from "./types";

export const generateMyDriversTableColumns = (setStep: (step: MyDriversStep) => void) => {
  const columns: DataTableColumnDef<MyDriversTableData>[] = [
    { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 300 },
    {
      accessorKey: "email",
      header: "Email",
      cell: (props) => props.row.original.email,
      id: "email",
      size: 370,
    },
    {
      accessorKey: "mobile",
      header: "Mobile",
      cell: (props) => props.row.original.mobile,
      enableSorting: false,
      size: 250,
    },
    {
      accessorKey: "rating",
      header: "Rating",
      cell: (props) => props.row.original.rating,
      id: "rating",
    },
    {
      accessorKey: "state",
      header: "State",
      cell: (props) => props.row.original.state,
      enableSorting: false,
    },
    {
      accessorKey: "tier",
      header: "Tier",
      cell: (props) => props.row.original.tier,
      id: "tier",
    },
    {
      accessorKey: "fleetLeader",
      header: () => (
        <div className="flex flex-row items-center gap-1.5">
          <Typography variant="small">Fleet Leader</Typography>
          <Icon 
            isCustom 
            name="help-circle" 
            size="sm"
            className="text-neutral-dark-gray cursor-pointer" 
            onClick={() => setStep("fleet-leader")}
          />
        </div>
      ),
      cell: (props) => props.row.original.fleetLeader,
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 250
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateMyDriversTableData = (
  items: FleetDriver[],
  toggleFleetLeader: (id: string, isFleetLeader: boolean) => void,
  isLoading: boolean,
) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name: (
          <a href={`${phpHostUrl}/driver/${i.id}/profile/`} className="flex flex-row items-center gap-2.5 hover:text-info" target="_blank" rel="noreferrer">
            {renderFleetAvatar(i.avatar, i.name, i.isFleetLeader)}
            <Typography variant="paragraph">{i.name}</Typography>
          </a>
        ),
        email: i.email,
        mobile: i.phone,
        rating: renderRating(i.rating),
        state: i.state ? i.state : "-",
        tier: i.tier || i.tier === 0 ? `${i.tier}` : "-",
        fleetLeader: (
          <div className="flex flex-row items-center gap-1.5">
            {i.isFleetLeader ? <Icon name="TickCircle" variant="Bold" size="sm" className="text-success" /> : <Icon name="CloseCircle" variant="Bold" size="sm" className="text-neutral-dark-gray" />}
            <Typography variant="paragraph">{i.isFleetLeader ? "Yes" : "No"}</Typography>
          </div>
        ),
        status: renderFleetStatus(i.status),
        action: renderMyDriverActions(i.id, i.isFleetLeader, isLoading, toggleFleetLeader),
      } as MyDriversTableData)
  );

  return data;
};

const renderMyDriverActions = (
  driverId: string,
  isFleetLeader: boolean,
  isLoadingToggleFleetLeader: boolean,
  toggleFleetLeader: (id: string, isFleetLeader: boolean) => void
) => {
  const phpHostUrl = getPhpHostUrl();
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <a href={`${phpHostUrl}/driver/${driverId}/profile/`} target="_blank" rel="noreferrer">
        <DropdownMenu.Item>
          <Icon name="UserSquare" size="sm" className="mr-2.5" />
          <Typography variant="paragraph">Manage Driver</Typography>
        </DropdownMenu.Item>
      </a>
      <DropdownMenu.Item className="relative" disabled>
        {isLoadingToggleFleetLeader && <Loading />}
        <Toggle
          className="mr-2.5"
          size="xs"
          checked={isFleetLeader}
          onChange={(checked) => toggleFleetLeader(driverId, checked)}
          disabled={isLoadingToggleFleetLeader}
        />
        <Typography variant="paragraph">Fleet Leader</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export const generateArchivedDriversTableData = (
  items: FleetDriver[]
) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name: (
          <a href={`${phpHostUrl}/driver/${i.id}/profile/`} className="flex flex-row items-center gap-2.5 hover:text-info" target="_blank" rel="noreferrer">
            {renderFleetAvatar(i.avatar, i.name, i.isFleetLeader)}
            <Typography variant="paragraph">{i.name}</Typography>
          </a>
        ),
        email: i.email,
        mobile: i.phone,
        status: renderFleetStatus(i.status),
        // actionDate: "",
        action: renderArchivedDriverActions(i.id),
      } as ArchivedDriversTableData)
  );

  return data;
};

const renderArchivedDriverActions = (driverId: string
) => {
  const phpHostUrl = getPhpHostUrl();
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <a href={`${phpHostUrl}/driver/${driverId}/profile/`} target="_blank" rel="noreferrer">
        <DropdownMenu.Item>
          <Icon name="UserSquare" size="sm" className="mr-2.5" />
          <Typography variant="paragraph">Manage Driver</Typography>
        </DropdownMenu.Item>
      </a>
    </DropdownMenu>
  );
};

const renderFleetAvatar = (avatar: string | null, name: string, isFleetLeader: boolean) => (
  <div className="ml-1">
    {avatar ? (
      <div className={clsx("h-6 w-6 rounded-full", { "ring-2 ring-success ring-offset-2": isFleetLeader })}>
        <img className="rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
      </div>
    ) : (
      <div
        className={clsx("h-6 w-6 rounded-full bg-neutral-mid-gray", {
          "ring-2 ring-success ring-offset-2": isFleetLeader,
        })}
      />
    )}
  </div>
);

const renderFleetStatus = (status: string) => {
  switch (status) {
    case "Pending Qualification":
    case "Application in Progress":
    case "Pending Approval":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray" /> {status}
        </Typography>
      );
    case "Approved":
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-success" /> {status}
        </Typography>
      );
    default:
      return (
        <Typography variant="paragraph" className="flex flex-row items-center gap-2">
          <span className="h-2 w-2 flex-shrink-0 rounded-full bg-danger" /> {status}
        </Typography>
      );
  }
};

export const generateOffloadDriversData = (items: OffloadDriver[], onBlockDriver: (driver: OffloadDriver) => void) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex">
            <a href={`${phpHostUrl}/driver/${i.id}/profile/`} className="flex flex-row items-center gap-2.5 hover:text-info" target="_blank" rel="noreferrer">
              {renderOffloadAvatar(i.avatar, i.name)}
              <Typography variant="paragraph">{i.name}</Typography>
            </a>
          </div>
        ),
        state: i.state,
        rating: renderRating(i.rating),
        totalJobs: i.tripCount || i.tripCount === 0 ? `${i.tripCount}` : "-",
        operator: (
          <div className="flex flex-row items-center gap-2.5">
            {renderOffloadAvatar(i.provider.assets.emblem, i.provider.name)}
            <Typography variant="paragraph">{i.provider.name}</Typography>
          </div>
        ),
        operatorContact: i.provider.phoneNumber,
        action: renderOffloadDriverActions(i, onBlockDriver),
      } as OffloadDriversTableData)
  );

  return data;
};

const renderOffloadDriverActions = (driver: OffloadDriver, onBlockDriver: (driver: OffloadDriver) => void) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onBlockDriver(driver)}>
        <Icon name="ban-bold" size="sm" className="mr-2.5 text-danger" isCustom />
        <Typography variant="paragraph">Block Driver</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export const generateBlockedOffloadDriversData = (items: OffloadDriver[], onViewReason: (driver: OffloadDriver) => void) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex">
            <a href={`${phpHostUrl}/driver/${i.id}/profile/`} className="flex flex-row items-center hover:text-info" target="_blank" rel="noreferrer">
              <Icon isCustom name="ban-bold" size="lg" className="flex-shrink-0 text-danger mr-4" />
              {renderOffloadAvatar(i.avatar, i.name)}
              <Typography variant="paragraph" className="ml-2.5">{i.name}</Typography>
            </a>
          </div>
        ),
        state: i.state,
        rating: renderRating(i.rating),
        totalJobs: i.tripCount || i.tripCount === 0 ? `${i.tripCount}` : "-",
        operator: (
          <div className="flex flex-row items-center gap-2.5">
            {renderOffloadAvatar(i.provider.assets.emblem, i.provider.name)}
            <Typography variant="paragraph">{i.provider.name}</Typography>
          </div>
        ),
        operatorContact: i.provider.phoneNumber,
        reasonForBlocking: <Typography variant="action" className="text-info cursor-pointer" onClick={() => onViewReason(i)}>View Reason</Typography>,
      } as BlockedOffloadDriversTableData)
  );

  return data;
};

const renderOffloadAvatar = (avatar: string | null, name: string) => (
  <div>
    {avatar ? (
      <div className="h-6 w-6 rounded-full">
        <img className="rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
      </div>
    ) : (
      <div className="h-6 w-6 rounded-full bg-neutral-mid-gray" />
    )}
  </div>
);

export const renderRating = (rating?: number | null) => (
  <div className="flex flex-row items-center gap-x-1.5">
    <Typography variant="paragraph">{rating || "-"}</Typography>
    {rating && <Icon name="Star1" className="fill-warning text-warning" />}
  </div>
);
