import { useAppSelector } from "@/redux/hooks";
import { configSelector } from "@/redux/slices/config/selectors";
import { SiteConfig } from "@/redux/slices/config/types";

export const useSiteConfig = (): SiteConfig => {
  const config = useAppSelector(configSelector);

  if (!config) throw new Error("useSiteConfig: config is undefined");
  return config;
};
