import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { createSearchParams } from "@/utils";
import { transformUserAccounts } from "./helpers";
import { GetUserAccountsParams, GetUserAccountsResponse, InviteUserParams } from "./types";

export const userAccountApi = createApi({
  reducerPath: "userAccountApi",
  baseQuery: baseQueryWithProvider("account-directory"),
  endpoints: (builder) => ({
    getUserAccounts: builder.query<GetUserAccountsResponse, GetUserAccountsParams>({
      query: (params) => `?${createSearchParams(params).toString()}`,
      providesTags: ["UserAccounts"],
      transformResponse: transformUserAccounts,
    }),
    inviteUser: builder.mutation<void, InviteUserParams>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["UserAccounts"],
    }),
    resendInvite: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}/resend-invite`,
        method: "POST",
      }),
    }),
    removeAccess: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserAccounts"],
    }),
    changeRole: builder.mutation<void, { id: string; role: string }>({
      query: ({ id, role }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: { role },
      }),
      invalidatesTags: ["UserAccounts"],
    }),
  }),
  tagTypes: ["UserAccounts"],
});

export const {
  useGetUserAccountsQuery,
  useLazyGetUserAccountsQuery,
  useInviteUserMutation,
  useRemoveAccessMutation,
  useChangeRoleMutation,
  useResendInviteMutation,
} = userAccountApi;
