import { Typography, Divider } from "@/components/atoms";
import { useLayout, useMutationObserver, useOperator, useProfile } from "@/hooks";
import { clsx } from "@/utils";
import { SidebarNavItem } from "./SidebarNavItem";
import { getMenuNavigation, getMoreNavigation } from "./helpers";
import { useEffect, useState } from "react";

interface SidebarNavProps {
  ignoreSidebarOpen?: boolean;
}

export const SidebarNav = ({ ignoreSidebarOpen }: SidebarNavProps) => {
  const { id: operatorId, roles } = useOperator();
  const { id: userId } = useProfile();
  const {
    layout: { sidebarOpen: isOpen },
  } = useLayout();
  const sidebarOpen = ignoreSidebarOpen ? true : isOpen;
  const headerClassName = clsx("text-neutral-dark-gray", {
    "text-center": !sidebarOpen,
  });

  const [showHelpNotification, setShowHelpNotification] = useState(false);
  const [newsElement, setNewsElement] = useState<Element | null>(null);
  useMutationObserver({
    element: newsElement, 
    callback: () => {
      const showNotification = newsElement?.getAttribute("unread");
      if (showNotification && !showHelpNotification) setShowHelpNotification(true);
      else if (!showNotification && showHelpNotification) setShowHelpNotification(false);
    }
  });
  useEffect(() => {
    const timer = setTimeout(() => {
      const iframe = document.getElementById("userpilot-resource-centre-frame") as HTMLIFrameElement;
      if (iframe) {
        const navBar = iframe.contentDocument?.getElementById("navigation-bar");
        const news = navBar ? navBar.querySelector("div[tab='news']") : null;
        if (news) setNewsElement(news);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const renderDriverMenu = () => {
    const navItem = {
      name: "My Profile",
      to: `/driver/${userId}/profile/`,
      icon: "User",
    };

    if (roles.includes("Driver")) {
      return (
        <div className="flex flex-col gap-y-3">
          <Typography variant="action" className={headerClassName}>
            Driver Menu
          </Typography>
          <div className="flex flex-col">
            <SidebarNavItem key={navItem.name} item={navItem} sidebarOpen={sidebarOpen} />
          </div>
        </div>
      );
    }
  };

  const renderClientMenu = () => {
    const navItem = {
      name: "My Bookings",
      to: `/passenger/${userId}/booking/history`,
      icon: "ArchiveBook",
    };

    if (roles.includes("Passenger")) {
      return (
        <div className="flex flex-col gap-y-3">
          <Typography variant="action" className={headerClassName}>
            Client Menu
          </Typography>
          <div className="flex flex-col">
            <SidebarNavItem key={navItem.name} item={navItem} sidebarOpen={sidebarOpen} />
          </div>
        </div>
      );
    }
  };

  return (
    <nav className="no-scrollbar flex flex-col overflow-auto border-none px-5">
      {renderDriverMenu()}
      {renderClientMenu()}
      <div className="flex flex-col gap-y-3">
        <Typography variant="action" className={headerClassName}>
          Menu
        </Typography>
        <div className="flex flex-col">
          {getMenuNavigation(operatorId).map((item) => (
            <SidebarNavItem key={item.name} item={item} sidebarOpen={sidebarOpen} />
          ))}
        </div>
      </div>
      <Divider className="my-5" />
      <div className="flex flex-col gap-y-3">
        <Typography variant="action" className={headerClassName}>
          More
        </Typography>
        <div className="flex flex-col">
          {getMoreNavigation().map((item) => (
            <SidebarNavItem key={item.name} item={item} sidebarOpen={sidebarOpen} showNotification={item.name === "Help" && showHelpNotification} />
          ))}
        </div>
      </div>
    </nav>
  );
};
