import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { omit } from "ramda";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { Links } from "@/redux/types";
import { createSearchParams } from "@/utils";
import { transformVehicleMake, transformVehicleModel } from "./helpers";
import {
  SearchVehicleModelResponse,
  SearchVehicleModelParams,
  SearchVehicleMakeResponse,
  GetAllVehicleMakeAndModelsRawResponse,
  GetAllVehicleMakeAndModelsParams,
} from "./types";

export const vehicleGuestApi = createApi({
  reducerPath: "vehicleGuestApi",
  baseQuery: baseQueryWithoutProvider("vehicle"),
  endpoints: (builder) => ({
    searchVehicleModel: builder.query<SearchVehicleModelResponse, SearchVehicleModelParams>({
      query: ({ q, ...params }) => `/model?${createSearchParams({ search: q, ...params }).toString()}`,
      transformResponse: transformVehicleModel,
    }),
    searchVehicleMake: builder.query<SearchVehicleMakeResponse, { q?: string; type?: string; page_size?: number }>({
      query: ({ q, ...params }) => `/make?${createSearchParams({ search: q, ...params }).toString()}`,
      transformResponse: transformVehicleMake,
    }),
    getAllVehicleMakeAndModels: builder.query<GetAllVehicleMakeAndModelsRawResponse, GetAllVehicleMakeAndModelsParams>({
      query: (params) => `/make-model/all?${createSearchParams(params).toString()}`,
      transformResponse: (response: GetAllVehicleMakeAndModelsRawResponse & Links) => omit(["_links"], response),
    }),
  }),
});

export const { useLazySearchVehicleModelQuery, useSearchVehicleModelQuery, useSearchVehicleMakeQuery, useGetAllVehicleMakeAndModelsQuery } =
  vehicleGuestApi;
