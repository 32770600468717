import { clsx } from "@/utils";
import { Typography } from "..";

export interface BooleanDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  status?: boolean;
}

export const BooleanDisplay = ({ status, children, className, ...props }: BooleanDisplayProps) => {
  return (
    <div className={className} {...props}>
      <Typography
        variant="paragraph"
        className={clsx("flex flex-row items-center gap-2 leading-8", status ? "text-success" : "text-neutral-dark-gray")}
      >
        <span className={clsx("h-2.5 w-2.5 rounded-full", status ? "bg-success" : "bg-neutral-dark-gray")} />{" "}
        {children ? children : status ? "Enabled" : "Disabled"}
      </Typography>
    </div>
  );
};
