import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";

export const NameField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="action">Name</Typography>
      <TextInput className="w-full" hasError={!!errors.name} placeholder="Enter Name" maxLength={128} {...register("name")} />
      <ErrorMessage errors={errors} name="name" />
    </div>
  );
};
