import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, DropdownSelect, ErrorMessage, Loading, Typography } from "@/components/atoms";
import { CountryState } from "@/redux/slices/resource/types";
import { addToast, clsx, getCountryName } from "@/utils";
import { SignUpOperatorFormData } from "../types";
import { useOperator } from "@/hooks";
import { useLazyGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useEffect, useState } from "react";
import { getErrorMessages } from "@/helpers/reduxHelpers";

export const StatesOfOperationField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();
  const { bookableCountryList, country: defaultCountry } = useOperator();
  const [getCountryStates, { isFetching }] = useLazyGetCountryStatesQuery();
  const [country, setCountry] = useState(defaultCountry);
  const [options, setOptions] = useState<Array<CountryState>>([]);

  useEffect(() => {
    getCountryStates(country)
      .unwrap()
      .then(data => setOptions(data.data.states))
      .catch((e) => {
        getErrorMessages(e).forEach((m) => {
          if (m === "Not found") addToast("danger", "No states found.");
          else addToast("danger", m);
        });
        setOptions([]);
        setValue("statesOfOperation", []);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const getCountryOptions = () => {
    const options: Array<{ name: string; value: string }> = [];
    bookableCountryList.forEach(countryCode => {
      const country = getCountryName(countryCode);
      if (country) options.push({ name: country, value: countryCode });
    })

    return options;
  };

  return (
    <div className={clsx("relative flex flex-col gap-y-1", className)} {...props}>
      {isFetching && <Loading />}
      <Typography variant="paragraph">Please select the state(s) that you&apos;re authorized</Typography>
      <Typography variant="paragraph" className="text-neutral-dark-gray">You will be asked to fill up the necessary requirements when selecting a state.</Typography>
      {bookableCountryList.length > 1 && (
        <div className="flex flex-col gap-y-1 col-span-2 lg:col-span-1">
          <Typography variant="paragraph">Country of Operation</Typography>
          <DropdownSelect
            className="w-full"
            placeholder="Select Country"
            options={getCountryOptions()}
            value={country}
            onChange={(value: string) => setCountry(value)}
          />
        </div>
      )}
      <div className="mt-4 grid grid-cols-2 gap-5">
        <Controller
          control={control}
          name="statesOfOperation"
          render={({ field }) => (
            <>
              {options.map((i) => (
                <div
                  className="flex items-center rounded-md border border-neutral-mid-gray bg-neutral-surface-gray p-4"
                  key={i.abbreviation}
                >
                  <CheckboxInput
                    className={clsx("text-neutral-dark-gray [&>label]:ml-3", {
                      "text-neutral-black": field.value?.includes(i.id),
                    })}
                    key={i.id}
                    id={i.id}
                    label={i.abbreviation}
                    value={i.id}
                    checked={field.value?.includes(i.id)}
                    variant="check"
                    {...register("statesOfOperation")}
                  />
                </div>
              ))}
            </>
          )}
        />
      </div>
      <ErrorMessage errors={errors} name="statesOfOperation" />
    </div>
  );
};
