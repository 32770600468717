import { useNavigate } from "react-router-dom";
import { Panel, Typography, Skeleton, Button } from "@/components/atoms";
import { EmptyState, Pagination } from "@/components/molecules";
import { usePage, usePagination } from "@/hooks";
import { useGetDepositsHistoryQuery } from "@/redux/apis/payment/stripe/stripeApi";
import { useAppSelector } from "@/redux/hooks";
import { stripeSelector } from "@/redux/slices/payment/selectors";
import { BankDetailsDepositsHistoryTable } from "./tables/BankDetailsDepositsHistoryTable";

export const BankDetailsDepositsHistoryPanel = () => {
  const navigate = useNavigate();
  const { currentTitle } = usePage();
  const {
    deposits: { history },
  } = useAppSelector(stripeSelector);
  const { current, count, total, size } = history.page;
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const { isFetching } = useGetDepositsHistoryQuery({ page, page_size: pageSize }, { refetchOnMountOrArgChange: true });

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="flex flex-col">
          <div className="flex">
            <Button startIcon="ArrowLeft" variant="tertiary" size="sm" onClick={() => navigate("../")}>
              Back
            </Button>
          </div>
          <Panel className="mt-5">
            <div className="flex">
              <div className="flex-1">
                <div className="flex flex-row items-center gap-2">
                  <Typography variant="h3" className="leading-8">
                    {currentTitle}
                  </Typography>
                </div>
                <Typography className="text-neutral-dark-gray">History Logs</Typography>
              </div>
            </div>
            {history.items.length < 1 ? (
              <EmptyState
                title="Bank Details - Deposit Logs"
                description="There are currently no bank details deposit history log available"
              />
            ) : (
              <BankDetailsDepositsHistoryTable className="mt-4" items={history.items} />
            )}
          </Panel>
          <Pagination
            totalItems={total}
            pageCount={count}
            currentPage={current}
            onPageClick={handlePageClick}
            pageSize={size}
            onSizeChange={handleSizeChange}
          />
        </div>
      )}
    </>
  );
};
