import { useWindowSize } from "@react-hookz/web";
import { useState } from "react";
import { Button, SearchInput } from "@/components/atoms";
import { usePagination, useSearch } from "@/hooks";
import { clsx } from "@/utils";
import { VehicleFilters } from "../features/Filters/VehicleFilters";

export const ArchivedVehiclesActionMenu = () => {
  const [search, setSearch] = useSearch();
  const { clearPagination } = usePagination();
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  if (!isSearchActive && !search) {
    return (
      <div className="flex flex-1 items-center justify-end gap-2">
        <Button
          className="max-xl:[&>span]:hidden"
          variant="tertiary"
          size="sm"
          startIcon="SearchNormal1"
          onClick={() => setIsSearchActive(true)}
        >
          Search
        </Button>
        <FilterButton />
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-end gap-2">
      <SearchInput
        search={search}
        onSearch={handleSearch}
        autoFocus
        placeholder="Search Number Plate, Make and Model"
        className={clsx("w-full max-w-sm p-0 sm:w-fit [&>input]:transition-size", isSearchActive && "[&>input]:w-[280px]")}
        size="sm"
        onClear={() => setIsSearchActive(false)}
      />
    </div>
  );
};

const FilterButton = () => {
  const { width } = useWindowSize();

  return width >= 768 ? <VehicleFilters.Button /> : <VehicleFilters.DrawerButton />;
};
