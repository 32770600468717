import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { GuestBookingTripLog } from "@/redux/slices/guest/types";
import { transformGuestBookingTripLog } from "./helpers";
import { GetGuestBookingTripLogParams } from "./types";

export const guestApi = createApi({
  reducerPath: "guestApi",
  baseQuery: baseQueryWithoutProvider("guest"),
  endpoints: (builder) => ({
    getGuestBookingTripLog: builder.query<GuestBookingTripLog, GetGuestBookingTripLogParams>({
      query: ({ hash, jobId }) => `/job/${jobId}/trip-log/${hash}`,
      transformResponse: transformGuestBookingTripLog,
    }),
  }),
});

export const { useGetGuestBookingTripLogQuery, useLazyGetGuestBookingTripLogQuery } = guestApi;
