import { useMemo } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { Container, Icon } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { ArchivedVehiclesActionMenu } from "@/components/organisms/Vehicles/ArchivedVehicles/ArchivedVehiclesActionMenu";
import { ArchivedVehiclesActions } from "@/components/organisms/Vehicles/ArchivedVehicles/ArchivedVehiclesActions";
import { MyVehiclesActionMenu } from "@/components/organisms/Vehicles/MyVehicles/MyVehiclesActionMenu";
import { MyVehiclesActions } from "@/components/organisms/Vehicles/MyVehicles/MyVehiclesActions";
import { VehicleFiltersProvider } from "@/components/organisms/Vehicles/features/Filters/useVehicleFilters";
import { useOperator } from "@/hooks";
import { useGetVehicleQuery } from "@/redux/apis/vehicle/vehicleApi";
import { RequireAccess } from "@/routes/RequireAccess/RequireAccess";
import { AffiliateVehicles } from "./AffiliateVehicles";
import { ArchivedVehicles } from "./ArchivedVehicles";
import { MyVehicles } from "./MyVehicles";

export const Vehicles = () => {
  const { pathname } = useLocation();
  const { id: operatorId } = useOperator();

  const pageTabs = [
    {
      name: "My Vehicles",
      to: "my-vehicles",
    },
    // {
    //   name: "My Driver’s Vehicle",
    //   to: "affiliate-vehicles",
    // },
    {
      name: "Archived Vehicles",
      to: "archived-vehicles",
    },
    {
      name: "Vehicle Category",
      to: `/operator/${operatorId}/config/pricing/vehicle-categories`,
      icon: <Icon name="ExportSquare" size="md" className="ml-2" />,
    },
  ];

  const isParentPage = Boolean(
    matchPath({ path: "/vehicles/:slug" }, pathname) || matchPath({ path: "operator/:operatorId/vehicles/:slug" }, pathname)
  );

  return (
    <VehicleFiltersProvider>
      <Container className="w-full">
        <div className="sticky top-0 z-30 w-full bg-neutral-surface-gray ">
          <CustomHeader />
          {isParentPage && (
            <div className="flex w-full gap-2 border-b !border-neutral-mid-gray py-1 max-xl:justify-between lg:py-0">
              <Tabs className="no-scrollbar min-w-0 border-none" tabs={pageTabs} />
              <ActionMenu />
            </div>
          )}
          <Actions />
        </div>
        <RequireAccess action="view" subject="providerConfigVehicle" />
      </Container>
    </VehicleFiltersProvider>
  );
};

const ActionMenu = () => {
  const { pathname } = useLocation();

  switch (true) {
    case pathname.includes("/vehicles/my-vehicles"):
      return <MyVehiclesActionMenu />;
    case pathname.includes("/vehicles/archived-vehicles"):
      return <ArchivedVehiclesActionMenu />;
    default:
      return null;
  }
};

const Actions = () => {
  const { pathname } = useLocation();

  switch (true) {
    case pathname.includes("/vehicles/my-vehicles"):
      return <MyVehiclesActions />;
    case pathname.includes("/vehicles/archived-vehicles"):
      return <ArchivedVehiclesActions />;
    default:
      return null;
  }
};

const CustomHeader = () => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { data } = useGetVehicleQuery(vehicleId || "", {
    skip: !vehicleId,
  });

  const moreCrumbs = useMemo(() => {
    if (!vehicleId || !data) return undefined;

    return [
      {
        name: `${data.model.make} ${data.model.model} (${data.registrationNumber})`,
        to: `#`,
        current: true,
      },
    ];
  }, [data, vehicleId]);

  return <PageHeader title="Vehicles" skipCrumbs={1} moreCrumbs={moreCrumbs} />;
};

Vehicles.MyVehicles = MyVehicles;
Vehicles.AffiliateVehicles = AffiliateVehicles;
Vehicles.ArchivedVehicles = ArchivedVehicles;
