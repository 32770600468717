import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Icon, Toggle, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TimeAndDayFormData } from "../../types";

export const SpecificDateField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TimeAndDayFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <div className="flex flex-row items-center gap-2">
        <Typography variant="action">Specific Date?</Typography>
        <Tooltip
          content={
            <Typography variant="action">
              Does this event have a specific date?
            </Typography>
          }
          placement="right"
        >
          <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="lg" />
        </Tooltip>
      </div>
      <Controller
        name="specificDate"
        control={control}
        render={({ field }) => (<Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />)}
      />
      <ErrorMessage errors={errors} name="specificDate" />
    </div>
  );
};
