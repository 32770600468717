import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { Loading } from "@/components/atoms";
import { getStripePublishableKey } from "@/helpers/apiHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetPaymentMethodSecretMutation } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { creditCardPaymentsTakeManualPaymentSelector } from "@/redux/slices/payment/selectors";
import { CreditCardPaymentsItem } from "@/redux/slices/payment/types";
import { addToast } from "@/utils";
import { TakeManualPaymentForm } from "./TakeManualPaymentForm";

interface TakeManualPaymentStep1Props {
  payment: CreditCardPaymentsItem;
}

export const TakeManualPaymentStep1 = ({ payment }: TakeManualPaymentStep1Props) => {
  const stripePublishableKey = getStripePublishableKey();
  const stripePromise = loadStripe(stripePublishableKey);
  const [getPaymentMethodSecret, { isLoading }] = useGetPaymentMethodSecretMutation();
  const { paymentMethodSecret } = useAppSelector(creditCardPaymentsTakeManualPaymentSelector);

  useEffect(() => {
    getPaymentMethodSecret({ id: payment.id })
      .unwrap()
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex w-full">
      {isLoading && <Loading />}
      {stripePromise && paymentMethodSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret: paymentMethodSecret }}>
          <TakeManualPaymentForm payment={payment} />
        </Elements>
      )}
    </div>
  );
};
