import { clsx } from "@/utils";
import { AverageTripCostField, NumberOfAnticipatedAnnualBookingsField, NumberOfIndividualTravelersField, NumberOfInternalDriversField, NumberOfPartnerDriversField, NumberOfVehicleYouOwnAndOperateField, OfficeHandlingOperationsField, TotalAnnualTurnoverField, YearsInOperationField } from "../forms/fields";

export const SignUpFormOperatorStep4 = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx("animate-enter flex flex-col gap-y-3 lg:gap-y-6", className)} {...props}>
      <YearsInOperationField />
      <TotalAnnualTurnoverField />
      <NumberOfAnticipatedAnnualBookingsField />
      <AverageTripCostField />
      <NumberOfIndividualTravelersField />
      <NumberOfVehicleYouOwnAndOperateField />
      <NumberOfInternalDriversField />
      <NumberOfPartnerDriversField />
      <OfficeHandlingOperationsField />
    </div>
  );
};
