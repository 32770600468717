import { Link, useRouteError } from "react-router-dom";
import RMLogo from "@/assets/images/rm-logo-emblem.svg";

export const RouteError = () => {
  const error = useRouteError();

  return (
    <div className="flex min-h-full flex-col py-40">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <Link to="/" className="inline-flex">
            <img className="h-12 w-auto" src={RMLogo} alt="RideMinder" />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-base font-semibold">Page Error</p>
            <h1 className="text-4xl sm:text-5xl mt-2 font-bold tracking-tight text-gray-900">Something went wrong.</h1>
            <pre className="mt-4 text-base text-gray-500">{JSON.stringify(error)}</pre>
            <div className="mt-6">
              <Link to="/" className="text-base font-medium text-primary">
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
