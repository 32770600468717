import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { isEmpty } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Loading, Tooltip } from "@/components/atoms";
import { DataTable, DataTableSetSorting, EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator, useProfile, useSortingState } from "@/hooks";
import { companyApi } from "@/redux/apis";
import { useGenerateInvoiceMutation, useLazyGetJobsQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setJobs } from "@/redux/slices/payment/paymentSlice";
import { invoicingOptionsSelector } from "@/redux/slices/payment/selectors";
import { Job, UninvoicedJobsCompany } from "@/redux/slices/payment/types";
import { addToast, clsx } from "@/utils";
import { GenerateInvoiceModal } from "../contents";
import { generateInvoiceFormSchema } from "../fixtures";
import { generateJobsColumns, generateJobsData, getDefaultEmails } from "../helpers";
import { GenerateInvoiceFormData } from "../types";

interface JobsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  company: UninvoicedJobsCompany;
  companyIndex: number;
  items: Array<Job>;
  clientEmail?: string;
  clientLevelSorting?: SortingState;
  clientLevelSetSorting?: DataTableSetSorting;
}

export const JobsTable = ({
  company,
  companyIndex,
  items,
  clientEmail,
  clientLevelSorting,
  clientLevelSetSorting,
  className,
  ...props
}: JobsTableProps) => {
  const operator = useOperator();
  const dispatch = useAppDispatch();
  const { dateFormat, timeFormat } = useProfile();
  const { sorting, setSorting, sortKey, direction } = useSortingState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currency } = useAppSelector(invoicingOptionsSelector);
  const [getJobs, { isFetching }] = useLazyGetJobsQuery();
  const [generateInvoice, { isLoading }] = useGenerateInvoiceMutation();
  const methods = useForm<GenerateInvoiceFormData>({
    defaultValues: {
      jobs: [],
      emails: getDefaultEmails(company, clientEmail),
    },
    resolver: zodResolver(generateInvoiceFormSchema),
  });
  const { watch, setValue, handleSubmit } = methods;
  const watchJobs = watch("jobs");
  const { id, name, invoice, jobsPage } = company;
  const { current, count } = jobsPage;
  const isClientLevel = invoice.generateOnLevel === "client" || invoice.generateOnLevel === "client_no_invoice";

  useEffect(() => {
    if (!isClientLevel) {
      getJobs({ companyId: id, sort: sortKey, direction, page: "1" })
        .unwrap()
        .then(({ data }) => {
          dispatch(setJobs({ companyIndex, jobs: data.jobs, jobsPage: data.jobsPage }));
        });
    }
  }, [isClientLevel, getJobs, id, sortKey, direction, dispatch, companyIndex]);

  const loadMoreJobs = () => {
    getJobs({ companyId: id, sort: sortKey, direction, page: (current + 1).toString() })
      .unwrap()
      .then(({ data }) => {
        dispatch(setJobs({ companyIndex, jobs: [...items, ...data.jobs], jobsPage: data.jobsPage }));
      });
  };

  const onSend = handleSubmit((data) => {
    const generateInvoiceData = {
      company_uuid: id,
      jobs: data.jobs.map((job) => job.id),
      to: data.emails.map((email) => ({ email })),
      status: "issued",
    };

    generateInvoice(generateInvoiceData)
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Sent the Invoice to ${name}`);
        dispatch(companyApi.util.invalidateTags(["UninvoicedJobsCompanies"]));
      })
      .catch((e) => {
        addToast("danger", "Invoice generation failed, please try again shortly.");
        getErrorMessages(e).forEach((m) => console.error(m));
      });
  });

  const onSave = handleSubmit((data) => {
    const generateInvoiceData = {
      company_uuid: id,
      jobs: data.jobs.map((job) => job.id),
      to: data.emails.map((email) => ({ email })),
      status: "draft",
    };

    generateInvoice(generateInvoiceData)
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Saved the Invoice to ${name} as Draft`);
        dispatch(companyApi.util.invalidateTags(["UninvoicedJobsCompanies"]));
      })
      .catch((e) => {
        addToast("danger", "Invoice generation failed, please try again shortly.");
        getErrorMessages(e).forEach((m) => console.error(m));
      });
  });

  const handleCheckboxChange = (checked: boolean, value: { id: number; cost: number }) => {
    if (checked) {
      setValue("jobs", [...watchJobs, value]);
    } else {
      setValue(
        "jobs",
        watchJobs.filter((job) => job.id !== value.id)
      );
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    if (checked) {
      setValue(
        "jobs",
        items.map((job) => ({ id: job.id, cost: job.cost }))
      );
    } else {
      setValue("jobs", []);
    }
  };

  const renderGenerateInvoiceButton = () => {
    if (operator?.flags.paymentInvoiceConfigured) {
      return (
        <Button startIcon="ReceiptText" size="md" variant="primary" onClick={() => setIsModalOpen(true)} disabled={isEmpty(watchJobs)}>
          Generate Invoice
        </Button>
      );
    } else {
      return (
        <Tooltip content="Please have your account owner complete your payment information to generate invoice" placement="left">
          <span>
            <Button startIcon="ReceiptText" size="md" variant="primary" disabled>
              Generate Invoice
            </Button>
          </span>
        </Tooltip>
      );
    }
  };

  const renderLoadMoreButton = () => {
    const hasMore = current < count;
    if (!isClientLevel && hasMore) {
      return (
        <div className="flex items-center justify-center">
          <Button size="sm" variant="tertiary" onClick={loadMoreJobs} className="!text-info hover:bg-info-light">
            Load more
          </Button>
        </div>
      );
    }
  };

  const data = useMemo(
    () => generateJobsData(items, dateFormat, timeFormat, currency[0]?.value, watchJobs, handleCheckboxChange),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, watchJobs]
  );
  const columns = useMemo(
    () => generateJobsColumns(items, watchJobs, handleSelectAllChange),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, watchJobs]
  );

  return (
    <FormProvider {...methods}>
      <div className={clsx("relative flex w-full flex-col gap-y-3", className)}>
        {isFetching && <Loading className="rounded-lg" />}
        <div className="flex items-center justify-end">{renderGenerateInvoiceButton()}</div>
        {isEmpty(data) ? (
          <EmptyState title="Closed Jobs" description="There are currently no closed jobs available" />
        ) : (
          <div className="flex flex-col gap-y-3 rounded-lg bg-white">
            <DataTable
              className="[&_table]:table-auto overflow-x-auto w-full"
              columns={columns}
              data={data}
              sorting={isClientLevel && clientLevelSorting ? clientLevelSorting : sorting}
              setSorting={isClientLevel && clientLevelSetSorting ? clientLevelSetSorting : setSorting}
              {...props}
            />
            {renderLoadMoreButton()}
          </div>
        )}
        <GenerateInvoiceModal
          open={isModalOpen}
          companyId={id}
          companyName={name}
          isSubmitting={isLoading}
          onClose={() => setIsModalOpen(false)}
          onSend={() => onSend()}
          onSave={() => onSave()}
        />
      </div>
    </FormProvider>
  );
};
