import { Typography } from "@/components/atoms";
import { useSiteConfig } from "@/hooks";
import { clsx } from "@/utils";

export const GeneralFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    name: footerName,
    urls: { contact, privacy, terms },
  } = useSiteConfig();

  return (
    <footer
      className={clsx(
        "flex relative w-full flex-row items-center justify-center flex-shrink-0 gap-2 py-6 px-2 bottom-0 border-y-[1px] border-x-0 border-neutral-mid-gray bg-white",
        className
      )}
      {...props}
    >
      <div className="flex flex-col items-center w-full max-w-[1040px] md:flex-row">
        <div className="flex-1">
          <Typography variant="small" className="text-center">
            © Copyright {new Date().getFullYear()} - {footerName} | Powered by RideMinder
          </Typography>
        </div>
        <div className="flex hidden md:block">
          <ul className="flex justify-center divide-x divide-neutral-black md:justify-end">
            {contact && (
              <li>
                <a href={contact} className="inline-block px-4">
                  <Typography variant="small">Contact Us</Typography>
                </a>
              </li>
            )}
            {privacy && (
              <li>
                <a href={privacy} className="inline-block px-4">
                  <Typography variant="small">Privacy Policy</Typography>
                </a>
              </li>
            )}
            {terms && (
              <li>
                <a href={terms} className="inline-block px-4">
                  <Typography variant="small">Terms & Conditions</Typography>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </footer>
  );
};