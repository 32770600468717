import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Button,
  Divider,
  FileInput,
  Icon,
  IconButton,
  RadioInput,
  TextInput,
  Transition,
  Typography,
  Modal,
  ModalProps,
  SidePanel,
  Loading,
} from "@/components/atoms";
import { DataTable, EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import {
  useAddJobAttachmentMutation,
  useLazyGetJobAttachmentQuery,
  useLazyGetJobAttachmentsQuery,
  useRemoveJobAttachmentMutation,
} from "@/redux/apis/booking/endpoints/actions";
import { BookingAttachment } from "@/redux/slices/booking/types";
import { addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { bookingAttachmentColumns } from "../fixtures";
import { BookingAttachmentData } from "../types";

interface JobAttachmentsProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  file: z.instanceof(FileList),
  permission: z.enum(["driver", "admin"]),
  description: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

export const JobAttachments = ({ open, onClose }: JobAttachmentsProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [getAttachments, { isFetching, data: attachments }] = useLazyGetJobAttachmentsQuery();
  const [getAttachment] = useLazyGetJobAttachmentQuery();
  const [add, { isLoading: isAdding }] = useAddJobAttachmentMutation();
  const [remove, { isLoading: isRemoving }] = useRemoveJobAttachmentMutation();
  const [pendingDeleteAttachmentId, setPendingDeleteAttachmentId] = useState<string | null>(null);
  const { register, watch, resetField, handleSubmit, reset } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      permission: "driver",
      file: undefined,
      description: "",
    },
  });

  useEffect(() => {
    if (open) getAttachments(bookingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const file = watch("file");

  const downloadAttachment = (attachmentId: string) => {
    getAttachment({ bookingId, documentId: attachmentId })
      .unwrap()
      .then((res) => {
        saveAs(res.link);
        addToast("success", "Starting download....");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const removeAttachment = (attachmentId: string) => {
    setPendingDeleteAttachmentId(attachmentId);
  };

  const handleDeleteAttachment = () => {
    if (!pendingDeleteAttachmentId) {
      console.error("No selected attachment to delete");
      return;
    }

    remove({ bookingId, documentId: pendingDeleteAttachmentId })
      .unwrap()
      .then(() => {
        addToast("success", "Attachment removed successfully");
        setPendingDeleteAttachmentId(null);
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleRemoveUpload = () => resetField("file");

  const onSubmit = (body: FormData) => {
    const { file, description, permission } = body;

    const formData = new FormData();
    formData.append("file_upload", file[0]);
    formData.append("description", description ?? "");
    formData.append("permission", permission);

    add({ bookingId, body: formData })
      .unwrap()
      .then(() => {
        addToast("success", "Attachment uploaded successfully");
        reset();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const onConfirmModalClose = () => {
    setPendingDeleteAttachmentId(null);
  };

  const renderModalButtons = () => (
    <>
      <Button variant="secondary" onClick={() => setPendingDeleteAttachmentId(null)} className="px-8">
        Cancel
      </Button>
      <Button variant="primary" onClick={handleDeleteAttachment} className="bg-danger px-8 hover:bg-danger-dark">
        Delete
      </Button>
    </>
  );

  return (
    <>
      <SidePanel open={open} onClose={onClose} width={800} focused>
        <section className="flex items-center justify-between p-5">
          <div className="flex items-center gap-2">
            <Icon name="DocumentText" size="lg" />
            <Typography variant="title">Job Attachments ({bookingId})</Typography>
          </div>
          <div className="ml-3 flex h-7 items-center">
            <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
          </div>
        </section>
        <section className="flex h-full flex-col p-5">
          {isFetching ? (
            <Loading />
          ) : (
            <>
              <form className="rounded-lg bg-neutral-surface-gray p-5" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                  <FileInput className="mr-2" {...register("file")} />
                  {file?.length > 0 && (
                    <IconButton
                      onClick={handleRemoveUpload}
                      iconName="Trash"
                      iconSize="lg"
                      variant="tertiary"
                      className="p-1 text-danger"
                    />
                  )}
                </div>
                <Transition show={file?.length > 0} leave="transition-none">
                  <Divider className="my-4" />
                  <div className="flex">
                    <div className="basis-1/2">
                      <Typography variant="action">Who may access this attachment?</Typography>
                      <div className="mt-3 flex gap-5">
                        <RadioInput label="Admin & Drivers" value="driver" {...register("permission")} />
                        <RadioInput label="Admin Only" value="admin" {...register("permission")} />
                      </div>
                    </div>
                    <div className="basis-1/2">
                      <Typography variant="action">Attachment Description</Typography>
                      <TextInput
                        size="md"
                        className="mt-1"
                        placeholder="Enter Attachment Description"
                        autoFocus
                        {...register("description")}
                      />
                    </div>
                  </div>
                  <Button type="submit" size="sm" className="mt-3">
                    Upload File
                  </Button>
                </Transition>
              </form>
              {!attachments || attachments.length === 0 ? (
                <EmptyState title="Job Attachments" description="Please include relevant attachments for this booking job." />
              ) : (
                <JobAttachmentsTable
                  isLoading={isAdding}
                  attachments={attachments}
                  downloadAttachment={downloadAttachment}
                  removeAttachment={removeAttachment}
                />
              )}
            </>
          )}

          <ConfirmRemoveAttachment open={!!pendingDeleteAttachmentId} onClose={onConfirmModalClose} renderButtons={renderModalButtons}>
            {isRemoving && <Loading />}
            <Typography>
              Are you sure you want to remove{" "}
              <span className="text-info">{attachments?.find((i) => i.id === pendingDeleteAttachmentId)?.filename} </span>
              from the Job ({bookingId}) Attachments?
            </Typography>
          </ConfirmRemoveAttachment>
        </section>
      </SidePanel>
    </>
  );
};

interface JobAttachmentsTableProps {
  isLoading: boolean;
  attachments: BookingAttachment[];
  downloadAttachment: (attachmentId: string) => void;
  removeAttachment: (attachmentId: string) => void;
}

const JobAttachmentsTable = ({ isLoading, attachments, downloadAttachment, removeAttachment }: JobAttachmentsTableProps) => {
  const { dateFormat, fullTimeFormat } = useProfile();
  const data: BookingAttachmentData[] = attachments.map((attachment) => ({
    id: attachment.id,
    dateTime: (
      <div className="flex flex-col">
        <span className="leading-snug">{format(new Date(attachment.createdAt), dateFormat)}</span>
        <span className="leading-snug text-neutral-dark-gray">{format(new Date(attachment.createdAt), fullTimeFormat)}</span>
      </div>
    ),
    filename: (
      <div className="grid ">
        <span className="truncate leading-snug">{attachment.filename}</span>
        <span className="truncate leading-snug text-neutral-dark-gray">{attachment.description}</span>
      </div>
    ),
    permission: <span className="capitalize">{attachment.permission}</span>,
    uploader: (
      <div className="grid">
        <span className="truncate leading-snug">{attachment.uploader.name}</span>
        <span className="truncate leading-snug text-neutral-dark-gray">{attachment.uploader.email}</span>
      </div>
    ),
    actions: (
      <div className="flex justify-center">
        <IconButton
          onClick={() => downloadAttachment(attachment.id)}
          iconName="ImportCurve"
          iconSize="lg"
          variant="tertiary"
          className="p-1 text-primary"
        />
        <IconButton
          onClick={() => removeAttachment(attachment.id)}
          iconName="Trash"
          iconSize="lg"
          variant="tertiary"
          className="p-1 text-danger"
        />
      </div>
    ),
  }));

  if (isLoading) return <Loading />;
  return <DataTable data={data} columns={bookingAttachmentColumns} className="mt-5 [&>table_td]:text-sm" />;
};

const ConfirmRemoveAttachment = ({ open, onClose, renderButtons, children }: ModalProps) => {
  return (
    <Modal open={open} onClose={onClose} title="Delete Attachment" renderButtons={renderButtons} className="w-[515px] !p-5">
      {children}
    </Modal>
  );
};
