import { useLocalStorageValue, useWindowSize } from "@react-hookz/web";
import { isEmpty } from "ramda";
import { useCallback, useMemo, useState } from "react";
import { DataTableRow, DataTableRowProps, DataTableTable, Pagination } from "@/components/molecules";
import { DataTableV2 } from "@/components/molecules/DataTableV2/DataTable";
import { useOperator, usePagination, useProfile, useSorting } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { bookingsPageSelector } from "@/redux/slices/booking/selectors";
import { Booking } from "@/redux/slices/booking/types";
import { clsx } from "@/utils";
import { BookingTableDetails } from "./features/BookingTableDetails/BookingTableDetails";
import { allColumns } from "./fixtures";
import { generateBookingColumns, generateBookingData } from "./helpers";
import { BookingsTableData, TableColumn } from "./types";

const defaultColumns = allColumns.filter((i) => i.isDefault);
const fixedColumns = allColumns.filter((i) => i.isFixed);

export interface BookingsTableV2Props extends React.ComponentPropsWithoutRef<"div"> {
  items: Booking[];
  compactView: boolean;
}

export const BookingsTableV2 = ({ items, compactView = false, ...props }: BookingsTableV2Props) => {
  const { providerCode, id: operatorId } = useOperator();
  const profile = useProfile();
  const { width } = useWindowSize();
  const { sorting, setSorting } = useSorting();
  const [selectedBookingId, setSelectedBookingId] = useState<string>();

  const { value: savedColumns = [] } = useLocalStorageValue(`${operatorId}:booking-columns`, {
    defaultValue: [],
  });

  const data = useMemo(() => generateBookingData(items, profile, providerCode), [items, profile, providerCode]);

  const tableColumns = useMemo(() => {
    const mappedColumns = savedColumns.reduce((acc, i) => {
      const item = allColumns.find((j) => j.id === i);
      if (item) acc.push(item);
      return acc;
    }, [] as TableColumn[]);

    const columns = isEmpty(savedColumns) ? defaultColumns : [fixedColumns[0], ...mappedColumns, fixedColumns[1], fixedColumns[2]];
    return width < 640
      ? generateBookingColumns(columns.filter((i) => i.isVisibleMobile))
      : generateBookingColumns(columns.filter((i) => Boolean(i.isHiddenDesktop) === false));
  }, [savedColumns, width]);

  const getRowProps = useCallback((row: DataTableRow<BookingsTableData>, table: DataTableTable<BookingsTableData>) => {
    const handleRowClick = (row: DataTableRow<BookingsTableData>) => {
      table.toggleAllRowsExpanded(false);
      setSelectedBookingId(row.getIsExpanded() ? undefined : row.original.bookingId);
    };

    const rowProps: Partial<DataTableRowProps<BookingsTableData>> = {
      id: `booking-${row.original.bookingId}`,
      className: clsx(`cursor-pointer booking-row`, {
        "hover:bg-neutral-gray": !row.getIsExpanded(),
        "!bg-primary-light": row.getIsExpanded(),
        "opacity-50": !row.getIsExpanded() && table.getIsSomeRowsExpanded(),
        "bg-[#EDF6FF]": row.original.metadata.transferRate === "daily_rate",
        "hover:!bg-[#DAECFF]": row.original.metadata.transferRate === "daily_rate" && !row.getIsExpanded(),
        "bg-[#FBF4E2]": row.original.metadata.transferRate === "hourly_rate",
        "hover:!bg-[#FFF3D3]": row.original.metadata.transferRate === "hourly_rate" && !row.getIsExpanded(),
        "[&>td]:text-danger": !row.original.metadata.isVerified && !row.getIsExpanded(),
      }),
      onClick: () => handleRowClick(row),
    };

    return rowProps;
  }, []);

  const getIsRowExpanded = useCallback(
    (row: DataTableRow<BookingsTableData>) => {
      return row.original.bookingId === selectedBookingId;
    },
    [selectedBookingId]
  );

  const tableClass = "max-sm:[&_.th]:hidden";
  const rowClass =
    "[&_.tr]:border-bottom-1 [&_.tr]:px-2 xl:[&_.tr]:px-0 [&_.tr]:grid sm:[&_.tr]:flex [&_.tr]:gap-0 [&_.tr]:lg:gap-3 xl:[&_.tr]:gap-0 [&_.tr]:grid-cols-4 [&_.tr.subcomponent]:block [&_.tr.subcomponent]:!bg-primary-light";
  const cellClass =
    "max-sm:[&_.td]:!w-auto [&_.td]:flex-col sm:[&_.td]:flex-row max-sm:[&_.td]:items-start [&_.td]:p-2 sm:[&_.td]:p-2 lg:[&_.td]:p-4 [&_tr.subcomponent_.td]:block [&_tr.subcomponent_.td]:w-full";
  const compactViewClass = "group compact-view [&_.tr]:!p-0 [&_.td]:!p-1 [&_.thead_.th]:!p-1 ";

  return (
    <DataTableV2
      className={clsx(tableClass, rowClass, cellClass, compactView && compactViewClass)}
      canExpand
      columns={tableColumns}
      data={data}
      sorting={sorting}
      setSorting={setSorting}
      renderSubComponent={(row: DataTableRow<BookingsTableData>) => <BookingTableDetails bookingId={row.original.bookingId} />}
      getRowProps={getRowProps}
      getIsRowExpanded={getIsRowExpanded}
      {...props}
    />
  );
};

const BookingsTablePagination = () => {
  const { current, count, total, size } = useAppSelector(bookingsPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("bookings");

  return (
    <Pagination
      className="my-4 max-lg:mb-16"
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      pageSizes={[50, 100, 200]}
      totalItems={total}
      pageCount={count}
    />
  );
};

BookingsTableV2.Pagination = BookingsTablePagination;
