import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { AddTimeAndDayMultiplierModal, EditTimeAndDayMultiplierModal, PageHeader, TimeAndDayTableEditor } from "@/components/organisms";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetTimeOfDayMultipliersQuery } from "@/redux/apis/config/pricing/timeOfDay";
import { useAppSelector } from "@/redux/hooks";
import { timeAndDayPageSelector, timeAndDaySelector } from "@/redux/slices/pricing/selectors";
import { TimeAndDayMultiplier } from "@/redux/slices/pricing/types";
import { addToast } from "@/utils/addToast";

export const TimeAndDayEdit = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<"add" | "edit" | undefined>(undefined);
  const [editItem, setEditItem] = useState<TimeAndDayMultiplier | undefined>(undefined);
  const [getTimeOfDayMultipliers, { isFetching }] = useLazyGetTimeOfDayMultipliersQuery();
  const items = useAppSelector(timeAndDaySelector);
  const { current, count, total, size } = useAppSelector(timeAndDayPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();

  useEffect(() => {
    getTimeOfDayMultipliers({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getTimeOfDayMultipliers]);

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <Panel>
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Time & Day (Multiplier)
            </Typography>
            <Typography className="text-neutral-dark-gray">Configure the Time & Day Multiplier for each trip.</Typography>
          </div>
          <div className="flex items-center gap-4">
            <Button onClick={() => setStep("add")} variant="tertiary" startIcon="Add">
              Add New
            </Button>
            <Button onClick={() => navigate("../")}>Done</Button>
          </div>
        </div>
        {isEmpty(items) ? (
          <EmptyState
            title="Time & Day Multipliers"
            description="It looks like you haven't added any time & day multipliers yet. Begin by adding your first time & day multiplier to get started"
          >
            <Button onClick={() => setStep("add")} startIcon="Add" variant="tertiary">
              Add New
            </Button>
          </EmptyState>
        ) : (
          <TimeAndDayTableEditor
            className="mt-4"
            items={items}
            onEditItem={(item: TimeAndDayMultiplier) => {
              setEditItem(item);
              setStep("edit");
            }}
          />
        )}
        <AddTimeAndDayMultiplierModal open={step === "add"} onClose={() => setStep(undefined)} />
        <EditTimeAndDayMultiplierModal item={editItem} open={step === "edit"} onClose={() => setStep(undefined)} />
      </Panel>
      <Pagination
        onPageClick={handlePageClick}
        onSizeChange={handleSizeChange}
        currentPage={current}
        pageSize={size}
        totalItems={total}
        pageCount={count}
      />
    </>
  );
};
