import { Icon, IconButton, Toggle, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { TransferType } from "@/redux/slices/vehicle/types";
import { TransferTypeTableData, TransferTypeTableDataV2 } from "./types";

export const generateColumns = (data: TransferTypeTableData[]) => {
  const columns: DataTableColumnDef<TransferTypeTableData>[] = [
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "description", header: "Description", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active, enableSorting: false },
    { header: " ", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];

  return columns;
};

export const generateEditorColumns = (data: TransferTypeTableData[]) => {
  const columns: DataTableColumnDef<TransferTypeTableData>[] = [
    {
      accessorKey: "reorder",
      header: "",
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "description", header: "Description", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Increase", enableSorting: false },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active, enableSorting: false },
    { header: " ", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];

  return columns;
};

export const generateEditorColumnsV2 = (data: TransferTypeTableDataV2[]) => {
  const columns: DataTableColumnDef<TransferTypeTableDataV2>[] = [
    {
      accessorKey: "reorder",
      header: "",
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
      size: 50,
    },
    {
      accessorKey: "name",
      header: `Name (${data.length})`,
      size: 300,
      cell: (props) => props.row.original.name,
      enableSorting: false,
    },
    { accessorKey: "description", header: "Description", enableSorting: false },
    { accessorKey: "costIncrease", header: "Cost Adjustment", enableSorting: false },
    { accessorKey: "tripType", header: "Trip Type", enableSorting: false },
    { accessorKey: "dispatchTolerance", header: "Dispatch Tolerance", enableSorting: false },
    { header: "Active", cell: (props) => props.row.original.actions, size: 100, enableSorting: false },
  ];

  return columns;
};

export const generateTableEditorV2Data = (
  items: TransferType[],
  onToggleStatus: (id: string, active: boolean) => void,
  onEditItem: (item: TransferType) => void,
  onDeleteItem: (item: TransferType) => void
): TransferTypeTableDataV2[] => {
  return items.map(
    (i) =>
      ({
        id: i.uuid,
        name: (
          <Typography className="flex items-center gap-2 leading-6">
            {i.name}
            {i.isSpecialEvent && (
              <Tooltip content="Special Event" placement="right">
                <Icon name="Star1" className="-translate-y-[1px] fill-warning text-warning" size="sm" />
              </Tooltip>
            )}
          </Typography>
        ),
        description: i.description,
        costIncrease: `${i.multiplier > 0 ? "+" : ""}${i.multiplier}%`,
        tripType: "",
        dispatchTolerance: "",
        actions: (
          <span className="flex items-center gap-1.5">
            <Toggle size="xs" checked={i.isActive} onChange={(checked) => onToggleStatus(i.uuid, checked)} className="mr-1" />
            <IconButton
              iconName="Edit2"
              variant="tertiary"
              onClick={() => onEditItem(i)}
              className="border-none bg-transparent p-1 !text-info hover:bg-info-light"
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              onClick={() => onDeleteItem(i)}
              className="border-none bg-transparent p-1 !text-danger hover:bg-danger-light"
            />
          </span>
        ),
      } as TransferTypeTableDataV2)
  );
};
