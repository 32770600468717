import { ReactMultiEmail, IReactMultiEmailProps } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { Icon } from "../Icon/Icon";
import { Typography } from "../Typography/Typography";

export interface MultiEmailInputProps extends Partial<IReactMultiEmailProps> {
  emails: string[];
  onChange: (emails: string[]) => void;
}

export const MultiEmailInput = ({ emails, onChange }: MultiEmailInputProps) => {
  return (
    <ReactMultiEmail
      emails={emails}
      onChange={onChange}
      getLabel={(email, index, removeEmail) => {
        return (
          <div data-tag key={index} className="!rounded-[30px] !border !border-neutral-mid-gray !bg-transparent !px-[15px] !py-1">
            <Typography variant="action" data-tag-item>
              {email}
            </Typography>
            <Icon name="close" isCustom size="sm" data-tag-handle onClick={() => removeEmail(index)} />
          </div>
        );
      }}
      inputClassName="!ring-0 !text-base"
      className="!rounded-lg !border-neutral-mid-gray"
    />
  );
};

MultiEmailInput.displayName = "MultiEmailInput";
