import { BooleanDisplay, Loading, Typography } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { useGetOffloadingConfigurationQuery } from "@/redux/apis/addOn/addOnApi";
import { OffloadingConfiguration } from "@/redux/slices/addOn/types";
import { clsx } from "@/utils";
import { renderDispatchTime } from "../../DispatchSettings/helpers";

interface OffloadingDetailsDispatchSettingsProps extends React.HTMLAttributes<HTMLDivElement> {
  configuration?: OffloadingConfiguration; 
}

export const OffloadingDetailsDispatchSettings = ({ configuration, className, ...props }: OffloadingDetailsDispatchSettingsProps) => {
  const { isFetching } = useGetOffloadingConfigurationQuery();

  return (
    <div className={clsx("relative pt-6", className)} {...props}>
      {isFetching && <Loading className="rounded-lg" />}
      <ConfigDetail title="Auto Dispatch to Private Network" description="Set the time before pickup" hideDivider>
        <BooleanDisplay status={configuration?.enabledAutomaticDispatchPrivateNetwork}>
          <Typography variant="paragraph">{configuration?.enabledAutomaticDispatchPrivateNetwork ? "Yes" : "No"}</Typography>
        </BooleanDisplay>
      </ConfigDetail>
      <ConfigDetail title="Standard Hold Time" indentPadding="pl-4">
        {renderDispatchTime(configuration?.dispatchAutoPrivateNetworkTime, configuration?.dispatchAutoPrivateNetworkTimeframe)}
      </ConfigDetail>
      <ConfigDetail title="Auto Dispatch to Public Network" description="Set the time before pickup">
        <BooleanDisplay status={configuration?.enabledAutomaticDispatchPublicNetwork}>
          <Typography variant="paragraph">{configuration?.enabledAutomaticDispatchPublicNetwork ? "Yes" : "No"}</Typography>
        </BooleanDisplay>
      </ConfigDetail>
      <ConfigDetail title="Standard Hold Time" indentPadding="pl-4">
        {renderDispatchTime(configuration?.dispatchAutoPublicNetworkTime, configuration?.dispatchAutoPublicNetworkTimeframe)}
      </ConfigDetail>
    </div>
  );
};
