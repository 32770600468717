import { UserAccountsModals, UserAccountsPanel } from "@/components/organisms";
import { UserAccountsContextProvider } from "@/contexts/userAccounts";

export const UserAccounts = () => {
  return (
    <UserAccountsContextProvider>
      <div className="mb-12 flex flex-1 flex-col">
        <UserAccountsPanel />
        <UserAccountsModals />
      </div>
    </UserAccountsContextProvider>
  );
};
