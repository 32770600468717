import { clsx } from "@/utils";
import { Typography } from "..";

export interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
}

export const Divider = ({ label, className, ...props }: DividerProps) => {
  return (
    <div className={clsx(className, "relative min-h-[1px]")} {...props}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-neutral-mid-gray" />
      </div>
      {label && (
        <div className="relative flex justify-center">
          <Typography className="bg-white px-2 text-neutral-dark-gray" variant="small">
            {label}
          </Typography>
        </div>
      )}
    </div>
  );
};
