import LockIcon from "@/assets/images/lock.png";
import { Button, Container, Typography } from "@/components/atoms";
import { GeneralFooter, GeneralHeader } from "@/components/organisms";
import { useLayout } from "@/hooks";
import { useAppDispatch, useAppResetApi } from "@/redux/hooks";
import { logOut } from "@/redux/slices/auth/authSlice";
import { clsx } from "@/utils";
import { useEffect } from "react";

export const Suspended = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  const { setFullScreen } = useLayout();
  const resetApi = useAppResetApi();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    dispatch(logOut());
    resetApi();
  };

  return (
    <div className="flex flex-row min-h-screen overflow-hidden">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader isPrivateContent />
        <div className={clsx("flex flex-row flex-1 bg-contain bg-[center_bottom_-2rem] bg-no-repeat", className)}>
          <Container className="flex max-w-md flex-col items-center pt-[14vh] px-5 pb-32">
            <img src={LockIcon} alt="Restricted Access" className="max-w-[128px]" />
            <Typography variant="title" className="text-center !text-[24px] sm:!text-[32px] mb-4">
              This account has been suspended
            </Typography>
            <Typography variant="paragraph" className="text-center text-neutral-dark-gray">
              Please contact support@rideminder.com if you are the owner of the account.
            </Typography>
            <Button onClick={handleLogout} className="mt-8" size="lg">
              Logout
            </Button>
          </Container>
          <div className="fixed bottom-0 bg-city min-h-[50%] w-full bg-cover bg-no-repeat pointer-events-none" />
        </div>
        <GeneralFooter />
      </div>
    </div>
  );
};
