import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Button, Icon, Loading, Panel, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useResetPasswordRequestMutation } from "@/redux/apis/auth/authApi";
import { addToast } from "@/utils";
import { isEmpty } from "ramda";
import { useNavigate, useSearchParams } from "react-router-dom";

type FormData = {
  email: string;
};

export const ResetPasswordRequestForm = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const emailParam = params.get("email");
  const [resetPassword, { isLoading }] = useResetPasswordRequestMutation();
  const { register, handleSubmit, watch, setValue, formState: { isDirty }, } = useForm<FormData>();
  const email = watch("email");
  const [errors, setErrors] = useState<string[]>([]);
  const [requestSent, setRequestSent] = useState(false);

  useEffect(() => {
    if (emailParam) setValue("email", emailParam.replaceAll(" ", "+"), { shouldDirty: true });
  }, [emailParam, setValue]);

  const onSubmit = handleSubmit((data) => {
    const { email } = data;
    setErrors([]);

    resetPassword(email)
      .unwrap()
      .then(() => {
        setRequestSent(true);
        addToast("success", "An email has been sent to you with instructions.");
      })
      .catch((e) => {
        if (e.data.detail === "Account not found. Please try again") setErrors(["This account does not exist."]);
        else setErrors(getErrorMessages(e));
      });
  });

  const renderRequestSent = () => (
    <div className="flex flex-col">
      <div className="flex flex-col items-center text-center rounded-lg bg-neutral-surface-gray p-3 mt-6">
        <Typography variant="paragraph">We sent a reset instruction to you at</Typography>
        <Typography variant="action" className="text-primary">{email}</Typography>
      </div>
      <div className="flex flex-col gap-y-2.5 mt-8">
        <Button variant="primary" size="lg" onClick={() => navigate("/login")}>Return to log in</Button>
        <Button variant="secondary" size="lg" onClick={() => setRequestSent(false)}>Resend Reset Instructions</Button>
      </div>
    </div>
  );

  return (
    <Panel>
      <div className="w-full">
        <div className="px-5 text-center">
          <div className="flex items-center justify-center">
            <div className="flex items-center justify-center rounded-lg bg-primary-light p-3 mb-4">
              <Icon
                name="Lock1"
                variant="Bold"
                size={36}
                className="text-primary"
              />
            </div>
          </div>
          <Typography variant="h1">Forgot Password?</Typography>
          <Typography variant="paragraph" className="mt-1 text-neutral-dark-gray">
            Don&apos;t worry, we&apos;ll send you a reset instructions to your email.
          </Typography>
        </div>
        {requestSent ? renderRequestSent() : (
          <form className="mt-4 w-full" onSubmit={onSubmit}>
            <div className="space-y-4 rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="mb-1 block">
                  <Typography>Email</Typography>
                </label>
                <TextInput
                  id="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  placeholder="your.email@gmail.com"
                  autoFocus
                  hasError={!isEmpty(errors)}
                  {...register("email", { required: true })}
                />
              </div>
            </div>
            {errors.length > 0 && (
              <div className="my-4">
                {errors.map((e, i) => (
                  <Alert key={i} type="danger" message={e} className="my-4" />
                ))}
              </div>
            )}
            <div className="relative mt-8">
              {isLoading && <Loading />}
              <Button type="submit" size="lg" className="w-full" disabled={!isDirty}>
                Send Reset Instructions
              </Button>
            </div>
          </form>
        )}
      </div>
    </Panel>
  );
};
