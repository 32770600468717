import { createSlice } from "@reduxjs/toolkit";
import { notificationApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { NotificationState } from "./types";

const initialState: NotificationState = {
  newNotifications: [],
  earlierNotifications: [],
  page: pageState,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(notificationApi.endpoints.getNotifications.matchFulfilled, (state, { payload }) => {
      const { newNotifications, earlierNotifications, page } = payload.data;
      if (page.current > 1) {
        state.newNotifications = [...state.newNotifications, ...newNotifications];
        state.earlierNotifications = [...state.earlierNotifications, ...earlierNotifications];
      } else {
        state.newNotifications = newNotifications;
        state.earlierNotifications = earlierNotifications;
      }

      state.page = page;
    });
  },
});

export default notificationSlice.reducer;
