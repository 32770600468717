import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { operatorApi } from "@/redux/apis/operator/operatorApi";
import { Operator, OperatorState } from "./types";

const initialState: OperatorState = {
  operators: [],
  activeOperator: undefined,
};

export const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    setActiveOperator: (state, action: PayloadAction<Operator>) => {
      state.activeOperator = action.payload;
    },
    switchOperator: (state, action: PayloadAction<Operator>) => {
      state.activeOperator = action.payload;
    },
    updateOperator: (state, action: PayloadAction<Operator>) => {
      const newVal = [...state.operators];
      const index = newVal.findIndex(operator => operator.id === action.payload.id);
      newVal[index] = action.payload;
      state.operators = newVal;
      state.activeOperator = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(operatorApi.endpoints.getOperators.matchFulfilled, (state, { payload }) => {
      state.operators = payload.data.operators;
    });
  },
});

export default operatorSlice.reducer;
export const { setActiveOperator, switchOperator, updateOperator } = operatorSlice.actions;
