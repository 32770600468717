import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Panel, Typography, Skeleton, Icon, Button, Alert, Toggle, AddOnTextInput, ErrorMessage, Loading } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePrompt } from "@/hooks";
import { useGetBillingSettingsQuery, useUpdateBillingSettingsMutation } from "@/redux/apis/config/admin/billingSettings";
import { useAppSelector } from "@/redux/hooks";
import { billingSettingsSelector } from "@/redux/slices/admin/selectors";
import { addToast, clsx } from "@/utils";
import { universalPricingFormSchema } from "./fixtures";

type FormData = z.infer<typeof universalPricingFormSchema>;

export const UniversalPricingEditPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const { isFetching, isSuccess } = useGetBillingSettingsQuery();
  const [updateBillingSettings, { isLoading }] = useUpdateBillingSettingsMutation();
  const {
    details: { defaultUniversalPricingAdjustmentRate },
  } = useAppSelector(billingSettingsSelector);
  const defaultValues = useMemo(
    () => ({
      isPriceIncrease: Number(defaultUniversalPricingAdjustmentRate) > -1,
      universalPricing:
        Number(defaultUniversalPricingAdjustmentRate) < 0
          ? Number(defaultUniversalPricingAdjustmentRate) * -1
          : Number(defaultUniversalPricingAdjustmentRate),
    }),
    [defaultUniversalPricingAdjustmentRate]
  );
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { isDirty, errors, isSubmitted },
  } = useForm<FormData>({
    defaultValues,
    resolver: zodResolver(universalPricingFormSchema),
  });
  const watchIsPriceIncrease = watch("isPriceIncrease");
  const watchUniversalPricing = watch("universalPricing");
  const promptWhen = isDirty && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  useEffect(() => {
    if (isSuccess) reset(defaultValues);
  }, [isSuccess, defaultValues, reset]);

  const onSubmit = handleSubmit((data) => {
    const universalPricing = data.isPriceIncrease ? data.universalPricing : data.universalPricing * -1;
    updateBillingSettings({
      default_universal_pricing_adjustment_rate: universalPricing.toString(),
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated the Universal Pricing Adjustment");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <form onSubmit={onSubmit}>
      {isFetching ? (
        <Skeleton />
      ) : (
        <>
          <Panel className={clsx("relative flex flex-1 flex-col", className)} {...props}>
            {isLoading && <Loading className="rounded-lg" />}
            <div className="flex flex-row">
              <div className="flex flex-col">
                <Typography variant="h3" className="leading-8">
                  Universal Pricing
                </Typography>
                <Typography className="text-neutral-dark-gray">
                  Automatically adjust (add/remove) all dynamic universal pricing by a set percentage. This
                  <br />
                  adjustment will not affect <span className="underline">custom pricing</span> or{" "}
                  <span className="underline">fixed pricing profiles</span>.
                </Typography>
              </div>
              <div className="flex flex-1 flex-row justify-end gap-x-4">
                <Button variant="secondary" onClick={() => navigate("../")}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={!isDirty}>
                  Save
                </Button>
              </div>
            </div>
            <div className="flex">
              <div className="mt-6 flex flex-row items-center gap-x-4">
                <div className="flex flex-row items-center gap-x-3">
                  <Typography variant="action">Price {watchIsPriceIncrease ? "Increase" : "Decrease"}</Typography>
                  <Controller
                    name="isPriceIncrease"
                    control={control}
                    render={({ field }) => (
                      <Toggle
                        checked={field.value}
                        onChange={(checked) => field.onChange(checked)}
                        className={field.value ? "bg-success" : "bg-danger"}
                      >
                        {field.value ? (
                          <Icon name="ArrowUp" size="sm" className="text-success" />
                        ) : (
                          <Icon name="ArrowDown" size="sm" className="text-danger" />
                        )}
                      </Toggle>
                    )}
                  />
                </div>
                <div className="flex">
                  <div className="flex flex-row items-center gap-x-2">
                    <AddOnTextInput
                      type="number"
                      className="w-[100px]"
                      endAddOn="%"
                      step="any"
                      hasError={!!errors.universalPricing}
                      {...register("universalPricing")}
                    />
                    <ErrorMessage errors={errors} name="universalPricing" />
                  </div>
                </div>
              </div>
            </div>
            {watchIsPriceIncrease ? (
              <Alert
                type="success"
                message={`Increase Universal Pricing by ${watchUniversalPricing}%`}
                customIcon={<Icon name="ArrowCircleUp2" variant="Bold" className="text-inherit flex-shrink-0 self-center" />}
                className="mt-4"
              />
            ) : (
              <Alert
                type="danger"
                message={`Decrease Universal Pricing by ${watchUniversalPricing}%`}
                customIcon={<Icon name="ArrowCircleDown2" variant="Bold" className="text-inherit flex-shrink-0 self-center" />}
                className="mt-4"
              />
            )}
          </Panel>
        </>
      )}
    </form>
  );
};
