import { Icon, Modal, Typography } from "@/components/atoms";

interface FleetLeaderModalProps {
  open: boolean;
  onClose: () => void;
}

export const FleetLeaderModal = ({ open, onClose }: FleetLeaderModalProps) => {
  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        What&apos;s a Fleet Leader?
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
    </div>
  );
  
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="w-[85vw] md:w-[514px] lg:w-[514px]"
    >
      {renderHeader()}
      <div className="flex flex-col items-center justify-center bg-neutral-gray rounded-lg p-5 mt-3">
        <div className="border-4 border-success rounded-full p-1">
          <div className="flex items-end justify-center bg-neutral-mid-gray rounded-full w-[54px] h-[54px]">
            <Icon name="Profile" size={48} variant="Bold" className="text-neutral-dark-gray" />
          </div>
        </div>
        <Typography variant="small" className="text-neutral-dark-gray text-center mt-2.5">A green circle around the profile picture identifies a fleet leader.</Typography>
      </div>
      <Typography variant="paragraph" className="mt-6">
        Fleet leaders are defined by operators and have an elevated set of permissions. By being a fleet leader they are able to:<br />
        1. Reassign jobs to other drivers (via the driver app)<br />
        2. Be assigned jobs through the private network<br />
        3. Are visible to operators when searching in the Driver Manager<br />
      </Typography>
    </Modal>
  );
};
