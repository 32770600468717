import { useState } from "react";
import { Button, Panel, Typography } from "@/components/atoms";
import { ReactComponent as WidgetLight } from "@/assets/images/config/widget-light.svg";
import { ReactComponent as WidgetDark } from "@/assets/images/config/widget-dark.svg";
import { useOperator } from "@/hooks";
import { clsx } from "@/utils";
import { WidgetModal } from "./WidgetModal";
import { WidgetType } from "./type";

export const WidgetsPanel = () => {
  const { id } = useOperator();
  const [type, setType] = useState<WidgetType>("default");
  const [showModal, setShowModal] = useState(false);
  const optionClassName = "flex flex-col items-center justify-center py-4 px-[19px] gap-y-2.5 rounded-lg border border-neutral-mid-gray bg-white cursor-pointer hover:text-primary hover:bg-primary-light";

  return (
    <div className="flex flex-col lg:flex-row w-full gap-5">
      <div className="flex-1">
        <Panel className="flex flex-col">
          <div className="flex flex-col gap-y-1">
            <Typography variant="h3">Widgets</Typography>
            <Typography variant="paragraph" className="text-neutral-dark-gray">Embed a quotation widget on your website, choose from the options below.</Typography>
          </div>
          <div className="flex flex-row gap-5 mt-8">
            <div className="flex flex-col gap-y-2.5">
              <Typography variant="action">Option 1</Typography>
              <div className={clsx(optionClassName, { "text-primary bg-primary-light border-primary": type === "default" })} onClick={() => setType("default")}>
                <WidgetLight />
                <Typography variant="action">Default Widget</Typography>
              </div>
            </div>
            <div className="flex flex-col gap-y-2.5">
              <Typography variant="action">Option 2</Typography>
              <div className={clsx(optionClassName, { "text-primary bg-primary-light border-primary": type === "branded" })} onClick={() => setType("branded")}>
                <WidgetDark />
                <Typography variant="action">Branded Widget</Typography>
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <div className="flex">
        <Panel className="flex flex-col items-center w-full lg:w-[615px]">
          <Typography variant="title" className="text-neutral-dark-gray">Widget Preview</Typography>
          <iframe className="w-full max-w-[400px] h-[386px] mt-8" title="Get an instance quote" src={`https://widgets.rideminder.com/instant-quote/?provider=${id}${type === "branded" ? "&use_branding=1" : ""}`} />
          <Button variant="primary" size="lg" onClick={() => setShowModal(true)} className="mt-10">Get the HTML Code</Button>
        </Panel>
      </div>
      <WidgetModal open={showModal} onClose={() => setShowModal(false)} type={type} />
    </div>
  );
};