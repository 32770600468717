import { Icon } from "@/components/atoms";
import { Company } from "@/redux/slices/company/types";
import { getAssetUrl } from "@/utils";

interface CompanyAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  company: Company;
  openUploader: () => void;
}

export const CompanyAvatar = ({ company, openUploader, className, ...props }: CompanyAvatarProps) => {
  const renderCameraIcon = () => (
    <>
      <div className="absolute hidden lg:flex items-center justify-center rounded-full cursor-pointer bg-info hover:opacity-80 bottom-1 right-1 h-8 w-8" onClick={openUploader}>
        <Icon name="Camera" variant="Bold" size="md" className="text-white" />
      </div>
      <div className="absolute flex items-center justify-center rounded-full cursor-pointer bg-info hover:opacity-80 bottom-0 right-0 h-6 w-6 lg:hidden" onClick={openUploader}>
        <Icon name="Camera" variant="Bold" size="sm" className="text-white" />
      </div>
    </>
  );
  
  return (
    <div className={className} {...props}>
      {company.avatar ? (
        <div className="relative h-[72px] w-[72px] lg:h-[124px] lg:w-[124px]">
          <img src={getAssetUrl(company.avatar)} alt={company.name} className="w-full h-full rounded-full" />
          {renderCameraIcon()}
        </div>
      ) : (
        <div className="relative flex h-[72px] w-[72px] lg:h-[124px] lg:w-[124px] items-center justify-center rounded-full bg-primary-light">
          <Icon name="Buildings" className="text-primary-dark w-9 h-9 lg:w-16 lg:h-16" />
          {renderCameraIcon()}
        </div>
      )}
    </div>
  );
};