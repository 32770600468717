import { Icon, Typography } from "@/components/atoms";
import { useAppDispatch } from "@/redux/hooks";
import { setAction } from "@/redux/slices/booking/bookingSlice";
import { clsx } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

export const Passenger = () => {
  const dispatch = useAppDispatch();

  const { account, passenger, stops, referenceNumber, referenceExternal } = useSelectedBooking();

  return (
    <div className="relative space-y-3">
      <Icon name="User" variant="Bold" size="lg" className="absolute -inset-1.5 rounded-full bg-primary p-1 text-white" />
      <div className="!mt-0 flex flex-col   gap-3 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
          Passenger:
        </Typography>
        <div className="flex flex-col gap-1 truncate">
          <Typography
            variant="small"
            onClick={() => dispatch(setAction("reassign-client"))}
            className="cursor-pointer truncate hover:text-info"
          >
            {passenger.name}
          </Typography>
          <Typography variant="small" className="truncate">
            ({passenger.phone})
          </Typography>
        </div>
      </div>
      <div className="flex  flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
        <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
          Pax:
        </Typography>
        <Typography variant="small" className="flex items-center">
          {passenger.count}
        </Typography>
      </div>
      {account && (
        <div className="flex  flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
            Company:
          </Typography>
          <Typography variant="small" className="flex items-center">
            {account.company?.name || "-"}
          </Typography>
        </div>
      )}
      {account && (
        <div className={clsx("flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3", !account.identifier && "hidden")}>
          <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
            Identifier:
          </Typography>
          <Typography variant="small" className="flex items-center">
            {account.identifier || "-"}
          </Typography>
        </div>
      )}
      {account && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
            Client:
          </Typography>
          <Typography variant="small" className="flex items-center truncate">
            {account.name || "-"}
          </Typography>
        </div>
      )}
      {stops[0].flight?.luggage && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
            Luggage:
          </Typography>
          <Typography variant="small" className="flex items-center capitalize">
            {stops[0].flight?.luggage}
          </Typography>
        </div>
      )}
      {referenceNumber && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
            Booking Ref:
          </Typography>
          <Typography variant="small" className="flex items-center capitalize">
            {referenceNumber}
          </Typography>
        </div>
      )}
      {referenceExternal && (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="w-[75px] shrink-0 text-neutral-black/70">
            External Ref:
          </Typography>
          <Typography variant="small" className="flex items-center capitalize">
            {referenceExternal}
          </Typography>
        </div>
      )}
    </div>
  );
};
