import { useEffect } from "react";
import { Panel, Skeleton } from "@/components/atoms";
import { useLazyGetCompanyQuery } from "@/redux/apis/company/companyApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setSelectedCompany } from "@/redux/slices/company/companySlice";
import { companiesPageStateSelector } from "@/redux/slices/company/selectors";
import { CompaniesEditForm } from "./forms/CompaniesEditForm";

interface CompaniesEditPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  companyId: string;
}

export const CompaniesEditPanel = ({ companyId, ...props }: CompaniesEditPanelProps) => {
  const dispatch = useAppDispatch();
  const [getCompany, { isFetching }] = useLazyGetCompanyQuery();
  const { selectedCompany: company } = useAppSelector(companiesPageStateSelector);

  useEffect(() => {
    getCompany(companyId)
      .unwrap()
      .then((company) => {
        dispatch(setSelectedCompany(company));
      })
      .catch(() => {
        throw new Error(`Error fetching company details for : ${companyId}`);
      });

    return () => {
      dispatch(setSelectedCompany(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching || !company) return <Skeleton />;

  return (
    <Panel {...props}>
      <CompaniesEditForm company={company} />
    </Panel>
  );
};
