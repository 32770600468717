import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useCloseJobNoDriverMutation } from "@/redux/apis/booking/endpoints/closeJob";
import { addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { useSelectedBookingId } from "../../hooks/useSelectedBookingId";

interface CloseJobNoDriverProps {
  open: boolean;
  onClose: () => void;
}

export const CloseJobNoDriver = ({ open, onClose }: CloseJobNoDriverProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [_, setSelectedBookingId] = useSelectedBookingId();
  const [closeJobNoDriver, { isLoading: isClosingJob }] = useCloseJobNoDriverMutation();

  const handleConfirm = () => {
    closeJobNoDriver({ bookingId })
      .unwrap()
      .then(() => {
        addToast("success", "The booking has been closed due to unavailability of drivers.");
        setSelectedBookingId(undefined);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderButtons = () => {
    return (
      <div className="contents">
        <Button variant="secondary" onClick={onClose} className="px-8">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm Changes
        </Button>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose} title="No Driver Available" renderButtons={renderButtons} className="w-[515px] !p-5">
      {isClosingJob && <Loading />}
      <Typography>Closing this job due to unavailability of drivers. Are you sure you want to proceed?</Typography>
    </Modal>
  );
};
