import { ComponentProps } from "react";
import { Icon, Typography } from "@/components/atoms";
import { BookingFlightStatus } from "@/redux/slices/booking/types";
import { clsx } from "@/utils";

export interface BookingFlightNumberProps extends ComponentProps<typeof Typography> {
  status: BookingFlightStatus;
  number: string;
  delay?: number;
}

const flightColors: Record<BookingFlightStatus, string> = {
  not_found: "bg-neutral-dark-gray/10 text-neutral-dark-gray",
  unknown: "bg-neutral-dark-gray/10 text-neutral-dark-gray",
  scheduled: "bg-success-light text-success",
  "en-route": "bg-success-light text-success",
  "on-time": "bg-success-light text-success",
  cancelled: "bg-danger-light text-danger",
  delayed: "bg-warning-light text-warning-dark",
  early: "bg-info-light text-info",
  diverted: "bg-[#FF825B]/10 text-[#FF825B]",
  incident: "bg-[#FF825B]/10 text-[#FF825B]",
  landed: "bg-success-light text-success",
};

export const BookingFlightNumber = ({ status, number, delay, className, ...props }: BookingFlightNumberProps) => {
  const activeStatusList = ["scheduled", "en-route"];
  const statusWithDelay =
    (typeof delay === "number" && activeStatusList.includes(status)) ? (delay > 0 ? "delayed" : delay < 0 ? "early" : "on-time") : status;

  return (
    <Typography
      variant="small"
      className={clsx("flex rounded-xl bg-info-light px-4 py-1 uppercase leading-snug", flightColors[statusWithDelay], className)}
      {...props}
    >
      <Icon name="Airplane" size={16} variant="Bold" className="mr-1" />
      {number}
    </Typography>
  );
};
