import { useAppSelector } from "@/redux/hooks";
import { defaultCountrySelector } from "@/redux/slices/operator/selectors";
import { clsx } from "@/utils";
import { CountryCode } from "libphonenumber-js";
import { Control, FieldPath } from "react-hook-form";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { Typography } from "../Typography/Typography";

export interface PhoneNumberInputProps<T extends object> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<T>;
  name: FieldPath<T>;
  disabled?: boolean;
  label?: string;
  rules?: object;
  hasError?: boolean;
  className?: string;
}

export const validatePhoneNumberInput = (phoneNumber?: string) => {
  return phoneNumber === null ? "" : phoneNumber;
};

export const PhoneNumberInput = <T extends object>(phoneNumberInputProps: PhoneNumberInputProps<T>) => {
  const { control, name, disabled = false, hasError = false, rules, label, className, ...props } = phoneNumberInputProps;
  const country = useAppSelector(defaultCountrySelector);

  const errorClass = {
    "border-1 !border-danger !focus:border-danger": hasError,
  };

  return (
    <>
      {label && (
        <Typography variant="paragraph" className="leading-8">
          {label}
        </Typography>
      )}
      <PhoneInputWithCountry
        className={clsx(
          "[&>.PhoneInputCountry]:m-0 [&>.PhoneInputCountry]:rounded-l-lg [&>.PhoneInputCountry]:border [&>.PhoneInputCountry]:border-r-0 [&>.PhoneInputCountry]:border-neutral-mid-gray [&>.PhoneInputCountry]:bg-neutral-gray [&>.PhoneInputCountry]:pl-4 [&>.PhoneInputCountry]:pr-2",
          className
        )}
        defaultCountry={country as CountryCode}
        limitMaxLength
        international
        defaultValue=""
        countryCallingCodeEditable={false}
        name={name as string}
        control={control}
        numberInputProps={{
          className: clsx(
            "h-[46px] px-4 text-base text-neutral-black border border-neutral-mid-gray focus:border-primary focus:ring-0 disabled:bg-neutral-surface-gray disabled:cursor-not-allowed placeholder-neutral-dark-gray rounded-r-lg",
            errorClass
          ),
        }}
        disabled={disabled}
        rules={rules}
        {...props}
      />
    </>
  );
};

PhoneNumberInput.displayName = "PhoneNumberInput";
