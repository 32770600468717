import { useWindowSize } from "@react-hookz/web";
import { flatten } from "ramda";
import { useCallback, useMemo, useState } from "react";
import { Button, Typography, Popover, Drawer } from "@/components/atoms";
import { useGetVehicleFormOptionsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { FilterNavButton } from "./common/FilterNavButton";
import { getFilterDrawer, getFilterPopover } from "./helpers";
import { VehicleFilterKey, useVehicleFilters } from "./useVehicleFilters";

const VehicleFiltersButton = () => {
  useGetVehicleFormOptionsQuery();
  const { filters, setFilter } = useVehicleFilters();
  const numActiveFilters = useMemo(() => flatten(Object.values(filters).filter(Boolean)).length, [filters]);

  const toggleActiveFilters = useCallback(
    (key: VehicleFilterKey) => {
      if (filters[key] === undefined) {
        setFilter(key, null);

        // to avoid adding more state of the popover state, we will just click the button to open the popover
        setTimeout(
          () => document.querySelectorAll(`[data-filter-name="${key}"]`).forEach((button) => (button as HTMLElement).click()),
          100
        );
      } else setFilter(key, undefined);
    },
    [filters, setFilter]
  );

  return (
    <Popover placement="bottom-end">
      <Button variant="tertiary" startIcon="Sort" className="h-[34px] sm:p-3 max-md:[&>span]:hidden" size="sm">
        Filters {numActiveFilters > 0 && `(${numActiveFilters})`}
      </Button>
      <div className="flex w-[225px] flex-col-reverse rounded-lg bg-white shadow-dropdown">
        <nav className="flex flex-col">
          <div className="mt-3 flex list-none flex-col [&>button>.typography]:-mr-1">
            <FilterNavButton onClick={() => toggleActiveFilters("make")} isActive={filters.make !== undefined}>
              Make
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("model")} isActive={filters.model !== undefined}>
              Model
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("minYear")} isActive={filters.minYear !== undefined}>
              Year
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("color")} isActive={filters.color !== undefined}>
              Color
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("minCapacityPax")} isActive={filters.minCapacityPax !== undefined}>
              Capacity
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("minCapacityBags")} isActive={filters.minCapacityBags !== undefined}>
              Number of Bags
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("engineType")} isActive={filters.engineType !== undefined}>
              Engine Type
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("type")} isActive={filters.type !== undefined}>
              Vehicle Type
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("class")} isActive={filters.class !== undefined}>
              Vehicle Class
            </FilterNavButton>
            <FilterNavButton
              onClick={() => toggleActiveFilters("isRegistrationVerified")}
              isActive={filters.isRegistrationVerified !== undefined}
            >
              Status
            </FilterNavButton>
          </div>
        </nav>
      </div>
    </Popover>
  );
};

const VehicleActiveFilters = () => {
  const { sortedFilters, filters, clearFilters, resetFilters } = useVehicleFilters();
  const { width } = useWindowSize();

  const renderFilters = useCallback(
    () =>
      sortedFilters.map(([key, value]) => {
        if (value === undefined) return null;
        return width > 768 ? getFilterPopover(key) : (getFilterDrawer(key, true) as React.ReactElement);
      }),

    [sortedFilters, width]
  );

  const numActiveFilters = flatten(Object.values(filters).filter((f) => f !== undefined)).length;

  if (numActiveFilters === 0) return null;

  return (
    <div className="flex  max-sm:flex-col sm:gap-2 ">
      <div className="thin-scrollbar flex flex-1 items-center gap-2 overflow-x-auto pb-2">
        <Typography variant="action" className="shrink-0 text-neutral-dark-gray max-sm:hidden">
          Selected Filters:
        </Typography>
        <div className="flex flex-1 gap-2">{renderFilters()}</div>
      </div>
      <div className="shink-0 flex self-start max-sm:self-end">
        <Button onClick={clearFilters} variant="tertiary" size="xs" className="text-neutral-dark-gray">
          Clear
        </Button>
        <Button onClick={resetFilters} variant="tertiary" size="xs" className="text-neutral-dark-gray">
          Reset
        </Button>
      </div>
    </div>
  );
};

const VehicleFiltersMenuDrawer = () => {
  const { filters, setFilter } = useVehicleFilters();
  const [open, setOpen] = useState(false);
  const [openChild, setOpenChild] = useState<VehicleFilterKey>();

  const toggleActiveFilters = useCallback(
    (key: VehicleFilterKey) => {
      if (filters[key] !== null) {
        setFilter(key, null);
        setOpenChild(key);
      } else setFilter(key, undefined);
    },
    [filters, setFilter]
  );

  const DrawerComponent = openChild ? getFilterDrawer(openChild) : null;

  return (
    <Drawer
      asNested
      title="Select Filter"
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setOpenChild(undefined);
          setOpen(false);
        }
      }}
    >
      <Button variant="tertiary" startIcon="Sort" className="h-[34px] justify-start sm:p-3 max-md:[&>span]:hidden" size="sm">
        Filters
      </Button>
      <>
        <nav className="flex flex-col">
          <div className="mt-3 flex list-none flex-col [&>button>.typography]:-mr-1">
            <FilterNavButton onClick={() => toggleActiveFilters("make")} isActive={filters.make !== undefined}>
              Make
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("model")} isActive={filters.model !== undefined}>
              Model
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("minYear")} isActive={filters.minYear !== undefined}>
              Year
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("color")} isActive={filters.color !== undefined}>
              Color
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("minCapacityPax")} isActive={filters.minCapacityPax !== undefined}>
              Capacity
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("minCapacityBags")} isActive={filters.minCapacityBags !== undefined}>
              Number of Bags
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("engineType")} isActive={filters.engineType !== undefined}>
              Engine Type
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("type")} isActive={filters.type !== undefined}>
              Vehicle Type
            </FilterNavButton>
            <FilterNavButton onClick={() => toggleActiveFilters("class")} isActive={filters.class !== undefined}>
              Vehicle Class
            </FilterNavButton>
            <FilterNavButton
              onClick={() => toggleActiveFilters("isRegistrationVerified")}
              isActive={filters.isRegistrationVerified !== undefined}
            >
              Status
            </FilterNavButton>
          </div>
        </nav>
        {typeof DrawerComponent === "function" ? (
          <DrawerComponent
            open={!!openChild}
            onOpenChange={(open) => {
              setOpenChild(open ? openChild : undefined);
              if (!open) setOpen(false);
            }}
          />
        ) : null}
      </>
    </Drawer>
  );
};

export const VehicleFilters = () => {
  throw new Error("Use Button or ActiveFilters instead of MyVehiclesFilters");
};

VehicleFilters.Button = VehicleFiltersButton;
VehicleFilters.ActiveFilters = VehicleActiveFilters;
VehicleFilters.DrawerButton = VehicleFiltersMenuDrawer;
