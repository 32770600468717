import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpOperatorFormData } from "../types";
import { businessTypeOptions } from "../../fixtures";

export const BusinessTypeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">What type of Business do you operate?</Typography>
      <Controller
        name="businessType"
        control={control}
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            hasError={!!errors.businessType}
            placeholder="Select Answer"
            options={businessTypeOptions}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            position="bottom"
          />
        )}
      />
      <ErrorMessage errors={errors} name="businessType" />
    </div>
  );
};
