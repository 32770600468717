import { Divider, Typography } from "@/components/atoms";
import { Breadcrumbs } from "@/components/atoms";
import { usePage } from "@/hooks";
import { clsx } from "@/utils";
import { isEmpty } from "ramda";

export interface PageHeaderV2Props extends React.HTMLAttributes<HTMLDivElement> {
  name: string | JSX.Element;
  skipCrumbs?: number;
}

export const PageHeaderV2 = ({ className, name, skipCrumbs = 0, children }: PageHeaderV2Props) => {
  const { pages } = usePage();
  const crumbsPages = pages.splice(skipCrumbs);

  return (
    <header className={clsx("sticky bg-neutral-surface-gray top-0 z-20 -mx-4 px-4 pt-4", className)}>
      <div className="flex flex-row items-center">
        <hgroup className="flex flex-1 items-center">
          {typeof name === "string" ? (
            <Typography variant="h1" className="inline-flex">
              {name}
            </Typography>
          ) : name}
          <Breadcrumbs className={clsx("ml-4 h-6 px-2", { "border-l border-solid border-neutral-dark-gray": !isEmpty(crumbsPages) })} pages={crumbsPages} />
        </hgroup>
        <div className="flex">
          {children}
        </div>
      </div>
      <Divider className="my-2" />
    </header>
  );
};