import SignupConfetti from "@/assets/images/signup/confetti.svg";
import { Typography } from "@/components/atoms";

export const SignUpSuccessDriver = () => (
  <div className="flex flex-col items-center text-center">
    <img src={SignupConfetti} className="h-[256px] w-[256px]" />
    <Typography variant="h2" className="mt-5">Account Pending Approval</Typography>
    <Typography variant="paragraph" className="mt-7 max-w-[503px]">Thank you for signing up! Your account is currently pending approval. We&apos;ll notify you as soon as your account has been approved.</Typography>
  </div>
);
