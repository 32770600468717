// reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
export const getOrdinalNumber = (number: number) => {
  try {
    const pr = new Intl.PluralRules("en-US", { type: "ordinal" });
    const suffixes = new Map([
      ["one", "st"],
      ["two", "nd"],
      ["few", "rd"],
      ["other", "th"],
    ]);
    const rule = pr.select(number);
    const suffix = suffixes.get(rule);
    return `${number}${suffix}`;
  } catch (error) {
    console.error(error);
  }
};
