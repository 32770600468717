import {
  GetDispatchSettingsRawResponse,
  GetDispatchSettingsResponse,
  UpdateDispatchSettingsRawResponse,
  UpdateDispatchSettingsResponse,
} from "./types";

export const transformDispatchSettings = (res: GetDispatchSettingsRawResponse) => {
  const { _links, ...dispatchSettings } = res;
  const response: GetDispatchSettingsResponse = {
    data: {
      details: {
        enabledAutomaticDispatch: dispatchSettings.enabled_automatic_dispatch,
        // dispatchTier0Time: dispatchSettings.dispatch_tier_0_time.toString(),
        // dispatchTier0Timeframe: dispatchSettings.dispatch_tier_0_timeframe,
        dispatchTier1Time: dispatchSettings.dispatch_tier_1_time.toString(),
        dispatchTier1Timeframe: dispatchSettings.dispatch_tier_1_timeframe,
        dispatchTier2Time: dispatchSettings.dispatch_tier_2_time.toString(),
        dispatchTier2Timeframe: dispatchSettings.dispatch_tier_2_timeframe,
        dispatchTier3Time: dispatchSettings.dispatch_tier_3_time.toString(),
        dispatchTier3Timeframe: dispatchSettings.dispatch_tier_3_timeframe,
        dispatchSpecialEventTier0Time: dispatchSettings.dispatch_special_event_tier_0_time.toString(),
        dispatchSpecialEventTier0Timeframe: dispatchSettings.dispatch_special_event_tier_0_timeframe,
        dispatchSpecialEventTier1Time: dispatchSettings.dispatch_special_event_tier_1_time.toString(),
        dispatchSpecialEventTier1Timeframe: dispatchSettings.dispatch_special_event_tier_1_timeframe,
        dispatchSpecialEventTier2Time: dispatchSettings.dispatch_special_event_tier_2_time.toString(),
        dispatchSpecialEventTier2Timeframe: dispatchSettings.dispatch_special_event_tier_2_timeframe,
        dispatchSpecialEventTier3Time: dispatchSettings.dispatch_special_event_tier_3_time.toString(),
        dispatchSpecialEventTier3Timeframe: dispatchSettings.dispatch_special_event_tier_3_timeframe,
        dispatchAutoOwnFleetRetryCount: dispatchSettings.dispatch_auto_own_fleet_retry_count.toString(),
        dispatchAutoPrivateNetworkRetryCount: dispatchSettings.dispatch_auto_private_network_retry_count.toString(),
        enabledHoldBeforeDispatch: dispatchSettings.enabled_hold_before_dispatch,
        dispatchHoldBeforeDispatchTime: dispatchSettings.dispatch_hold_before_dispatch_time.toString(),
        dispatchHoldBeforeDispatchTimeframe: dispatchSettings.dispatch_hold_before_dispatch_timeframe,
        dispatchAsapHoldBeforeDispatchTime: dispatchSettings.dispatch_asap_hold_before_dispatch_time.toString(),
        dispatchAsapHoldBeforeDispatchTimeframe: dispatchSettings.dispatch_asap_hold_before_dispatch_timeframe,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateDispatchSettings = (res: UpdateDispatchSettingsRawResponse) => {
  return {
    links: res._links,
  } as UpdateDispatchSettingsResponse;
};
