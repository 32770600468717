import { StandardCancelFeeInterval } from "@/redux/slices/pricing/types";
import { GetAsapPricingRawResponse, GetAsapPricingResponse, GetStandardPricingRawResponse, GetStandardPricingResponse } from "./types";

export const transformAsapPricing = (res: GetAsapPricingRawResponse) => {
  const { _links, cancellation_fee, fee_free_window, currency } = res;

  const response: GetAsapPricingResponse = {
    data: {
      asapPricing: {
        cancellationFee: cancellation_fee,
        noFeeWindow: fee_free_window,
        currency,
      },
    },
    links: _links,
  };

  return response;
};

export const transformStandardPricing = (res: GetStandardPricingRawResponse) => {
  const { _links, intervals: rawIntervals, base_interval } = res;

  const intervals: StandardCancelFeeInterval[] = rawIntervals.map((i) => ({
    rangeTo: i.range_to,
    rate: i.rate,
  }));

  const response: GetStandardPricingResponse = {
    data: {
      standardPricing: {
        basePrice: {
          rangeTo: base_interval.range_to,
          rate: base_interval.rate,
        },
        intervals,
      },
    },
    links: _links,
  };

  return response;
};
