import { useMemo, useState } from "react";
import { Button, Modal, Loading } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import {
  useDeleteTimeOfDayMultiplierMutation,
  useReorderTimeOfDayMutation,
  useToggleTimeOfDayMultiplierMutation,
} from "@/redux/apis/config/pricing/timeOfDay";
import { useAppDispatch } from "@/redux/hooks";
import { setTimeAndDayMultipliers, toggleTimeAndDayMultiplier } from "@/redux/slices/pricing/pricingSlice";
import { TimeAndDayMultiplier } from "@/redux/slices/pricing/types";
import { addNotification, clsx } from "@/utils";
import { modals } from "./fixtures";
import { generateEditorColumns, generateEditorTableData } from "./helpers";
import { TimeAndDayTableData } from "./types";
import { useProfile } from "@/hooks";

interface TimeAndDayTableEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  items: TimeAndDayMultiplier[];
  onEditItem: (item: TimeAndDayMultiplier) => void;
}

export const TimeAndDayTableEditor = ({ items, onEditItem, className, ...props }: TimeAndDayTableEditorProps) => {
  const { dateFormat, timeFormat } = useProfile();
  const dispatch = useAppDispatch();
  const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null);
  const [toggle, { isLoading: toggleLoading }] = useToggleTimeOfDayMultiplierMutation();
  const [remove, { isLoading: removeLoading }] = useDeleteTimeOfDayMultiplierMutation();
  const [reorder, { isLoading: reorderLoading }] = useReorderTimeOfDayMutation();
  const [activeModal, setActiveModal] = useState<"error" | "exit" | "delete" | null>(null);

  const onDeleteItem = (item: TimeAndDayMultiplier) => {
    setPendingDeleteId(item.id);
    setActiveModal("delete");
  };

  const toggleItemStatus = (id: string, active: boolean) => {
    const item = items.find((i) => i.id === id);
    if (!item) return;

    dispatch(toggleTimeAndDayMultiplier({ id, active }));

    toggle({ uuid: id, is_active: active })
      .unwrap()
      .then(() => addNotification("info", `Changed active status: ${active ? "On" : "Off"}`, `Changed active status for "${item.name}".`))
      .catch(() => setActiveModal("error"));
  };

  const onReorder = (sortedItems: TimeAndDayTableData[]) => {
    const mapping: { [key: string]: number } = {};

    sortedItems.forEach((item, index) => {
      const newOrder = items[index].order;
      mapping[item.id] = newOrder;
    });

    const newItems = items.map((item) => ({ ...item, order: mapping[item.id] })).sort((a, b) => (a.order - b.order));

    dispatch(setTimeAndDayMultipliers(newItems));

    reorder(mapping)
      .unwrap()
      .then(() => {
        addNotification("info", "Updated Order", "Successfully updated time & day multipliers order.");
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const handleDeleteRow = () => {
    setActiveModal(null);
    const item = items.find((i) => i.id === pendingDeleteId);
    if (!item) return;

    remove(item.id)
      .unwrap()
      .then(() => {
        addNotification("info", `Deleted 1 row`, `Deleted "${item.name}"`);
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteRow} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  const data = useMemo(
    () => generateEditorTableData(items, dateFormat, timeFormat, toggleItemStatus, onEditItem, onDeleteItem),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateEditorColumns(data), [data]);

  return (
    <div className="relative">
      {activeModal && (
        <Modal
          open={true}
          title={modals[activeModal].title}
          description={modals[activeModal].description}
          onClose={() => setActiveModal(null)}
          renderButtons={renderModalButtons}
        />
      )}
      {(toggleLoading || removeLoading || reorderLoading) && <Loading className="rounded-lg" />}
      <DataTable
        columns={columns}
        data={data}
        className={clsx("[&_table]:table-auto overflow-x-auto w-full", className)}
        onReorder={onReorder}
        {...props}
      />
    </div>
  );
};
