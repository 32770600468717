import { useEffect, useState } from "react";
import { Icon, Spinner, Typography, Location, LocationInput } from "@/components/atoms";
import { useLazyGetTripCostEstimateQuery } from "@/redux/apis/config/pricing/tripCost";

interface CostEstimateFormProps {
  name: string;
}
export const CostEstimateForm = ({ name, ...props }: CostEstimateFormProps) => {
  const [getTripCostEstimate, { isLoading, data }] = useLazyGetTripCostEstimateQuery();
  const [from, setFrom] = useState<Location | null>(null);
  const [to, setTo] = useState<Location | null>(null);

  useEffect(() => {
    if (!to || !from || !to.id || !from.id) return;
    getTripCostEstimate({ placeFromId: from.id, placeToId: to.id });
  }, [to, from, getTripCostEstimate]);

  return (
    <div className="py-4" {...props}>
      <div className="flex flex-col gap-2">
        <Typography variant="action">{name}</Typography>
        <LocationInput
          placeholder="From"
          renderIcon={() => <Icon name="LocationDiscover" variant="Bold" className="text-info" size="sm" />}
          onLocationChange={(v) => setFrom(v)}
          className="w-full"
        />
        <LocationInput
          placeholder="To"
          renderIcon={() => <Icon name="Location" className="text-danger" variant="Bold" size="sm" />}
          onLocationChange={(v) => setTo(v)}
          className="w-full"
        />
      </div>
      <div className="mt-4 flex flex-col gap-2 ">
        {isLoading ? (
          <div className="flex min-h-[60px] w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex flex-1 justify-between">
              <Typography className="text-neutral-dark-gray">Distance</Typography>
              <Typography className="text-neutral-black" variant="action">
                {data?.data.distance || "0"} {data?.data.unit || "km"}
              </Typography>
            </div>
            <div className="flex flex-1 justify-between">
              <Typography className="text-neutral-dark-gray">Trip Cost</Typography>
              <Typography className="text-neutral-black" variant="title">
                ${data?.data.price || "0"}
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
