import { Typography } from "@/components/atoms";
import { useBookingOfferPageContext } from "./context";
import { BookingNotes } from "./widgets/BookingNotes";
import { Documents } from "./widgets/Documents";
import { ReferenceNumber } from "./widgets/ReferenceNumber";

export const BookingOfferSidebar = () => {
  const {
    bookingOffer: { status },
  } = useBookingOfferPageContext();

  return (
    <div className=" flex w-full flex-col justify-start gap-4 bg-neutral-surface-gray p-3  lg:w-[400px] lg:p-7 lg:pt-20">
      <BookingNotes />
      {status === "driver_allocated_confirmed" && (
        <>
          <ReferenceNumber />
          <Documents />
        </>
      )}

      <div className="hidden w-full flex-1 items-end text-center text-neutral-dark-gray lg:flex">
        <Typography>© Copyright {new Date().getFullYear()} - RideMinder Australia Pty Ltd</Typography>
      </div>
    </div>
  );
};
