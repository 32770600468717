import camelcaseKeys from "camelcase-keys";
import { invalidateOn } from "@/helpers/reduxHelpers";
import { GetTransferTypesResponse as GetTransferTypesResponseV2, TransferType } from "@/redux/slices/vehicle/types";
import { createSearchParams } from "@/utils";
import { pricingApi } from "../pricingApi";
import { transformGetTransferTypesResponse, transformTransferTypes } from "./helpers";
import {
  AddTransferTypeParams,
  AddTransferTypeResponse,
  DeleteTransferTypeResponse,
  EditTransferTypeParams,
  EditTransferTypeResponse,
  GetTransferTypesParams,
  GetTransferTypesResponse,
  RawTransferType,
  ReorderTransferTypesResponse,
  ToggleTransferTypeResponse,
} from "./types";

export const transferTypesApi = pricingApi.enhanceEndpoints({ addTagTypes: ["TransferType"] }).injectEndpoints({
  endpoints: (builder) => ({
    getTransferType: builder.query<TransferType, string>({
      query: (id) => `/transfer-type/${id}`,
      transformResponse: (res: RawTransferType) => camelcaseKeys(res, { deep: true }),
      providesTags: (_, __, id) => [{ type: "TransferType", id }],
    }),
    getVehicleTransferTypes: builder.query<GetTransferTypesResponseV2, GetTransferTypesParams>({
      query: (params) => ({
        url: `/transfer-type?${createSearchParams(params).toString()}`,
      }),
      providesTags: (result) => (result ? result.transferTypes.map(({ uuid }) => ({ type: "TransferType", uuid })) : ["TransferType"]),
      transformResponse: transformGetTransferTypesResponse,
    }),
    getTransferTypes: builder.query<GetTransferTypesResponse, GetTransferTypesParams>({
      query: ({ page, page_size }) => {
        let queryString = "/transfer-type?";
        if (page) queryString = `${queryString}&page=${page}`;
        if (page_size) queryString = `${queryString}&page_size=${page_size}`;
        return queryString;
      },
      providesTags: ["TransferType"],
      transformResponse: transformTransferTypes,
    }),
    addTransferType: builder.mutation<AddTransferTypeResponse, AddTransferTypeParams>({
      query: (body) => ({
        url: "/transfer-type",
        method: "POST",
        body,
      }),
      invalidatesTags: invalidateOn({ success: ["TransferType"] }),
    }),
    editTransferType: builder.mutation<EditTransferTypeResponse, EditTransferTypeParams>({
      query: ({ uuid, ...put }) => ({
        url: `/transfer-type/${uuid}`,
        method: "PUT",
        body: put,
      }),
      invalidatesTags: invalidateOn({ success: ["TransferType"] }),
    }),
    deleteTransferType: builder.mutation<DeleteTransferTypeResponse, string>({
      query: (uuid) => ({
        url: `/transfer-type/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TransferType"],
    }),
    toggleTransferType: builder.mutation<ToggleTransferTypeResponse, Pick<RawTransferType, "uuid"> & { is_active: boolean }>({
      query: ({ uuid, ...body }) => ({
        url: `/transfer-type/${uuid}`,
        method: "PATCH",
        body,
      }),
    }),
    reorderTransferType: builder.mutation<ReorderTransferTypesResponse, Record<string, number>>({
      query: (body) => ({
        url: "/transfer-type/reorder",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetTransferTypeQuery,
  useGetVehicleTransferTypesQuery,
  useLazyGetTransferTypesQuery,
  useAddTransferTypeMutation,
  useToggleTransferTypeMutation,
  useDeleteTransferTypeMutation,
  useEditTransferTypeMutation,
  useReorderTransferTypeMutation,
} = transferTypesApi;
