import { useEffect } from "react";

type MutationObserverParams = {
  element: Element | null;
  callback: MutationCallback;
  options?: MutationObserverInit; 
};

export const useMutationObserver = ({
  element,
  callback,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
}: MutationObserverParams) => {
  useEffect(() => {
    if (element) {
      const observer = new MutationObserver(callback);
      observer.observe(element, options);
      return () => observer.disconnect();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, options]);
};