import { adminApi } from "../adminApi";
import { transformBillingSettings, transformUpdateBillingSettings } from "./helpers";
import { GetBillingSettingsResponse, UpdateBillingSettingsParams, UpdateBillingSettingsResponse } from "./types";

export const billingSettingsApi = adminApi.enhanceEndpoints({ addTagTypes: ["BillingSettings"] }).injectEndpoints({
  endpoints: (builder) => ({
    getBillingSettings: builder.query<GetBillingSettingsResponse, void>({
      query: () => "/billing-settings",
      transformResponse: transformBillingSettings,
      providesTags: ["BillingSettings"],
    }),
    updateBillingSettings: builder.mutation<UpdateBillingSettingsResponse, UpdateBillingSettingsParams>({
      query: (body) => ({
        url: "/billing-settings",
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateBillingSettings,
      invalidatesTags: ["BillingSettings"],
    }),
  }),
});

export const { useGetBillingSettingsQuery, useUpdateBillingSettingsMutation } = billingSettingsApi;
