import { tableClass } from "../DataTable";
import { DataTableHeader } from "../components/DataTableHeader";
import { DataTableRow } from "../components/DataTableRow";
import { DataTableGetRowProps, DataTableRenderSubComponent, DataTableTable } from "../types";

export interface DefaultTableProps<T extends object> {
  table: DataTableTable<T>;
  children?: React.ReactNode;
  getRowProps?: DataTableGetRowProps<T>;
  renderSubComponent?: DataTableRenderSubComponent<T>;
}

export const DefaultTable = <T extends object>({ table, getRowProps, renderSubComponent, children }: DefaultTableProps<T>) => {
  const rows = table.getRowModel().rows;
  const headers = table.getHeaderGroups()[0].headers;

  return (
    <table className={tableClass}>
      <DataTableHeader headers={headers} />
      <tbody className="divide-y divide-neutral-mid-gray divide-opacity-50">
        {rows.map((row) => {
          const rowProps = getRowProps ? getRowProps(row, table) : {};

          return row.getCanExpand() ? (
            <DataTableRow key={row.id} row={row} {...rowProps}>
              {renderSubComponent && row.getIsExpanded() && renderSubComponent(row)}
            </DataTableRow>
          ) : (
            <DataTableRow key={row.id} row={row} />
          );
        })}
        {children}
      </tbody>
    </table>
  );
};
