import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedState } from "@react-hookz/web";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { z } from "zod";
import { Button, Divider, Icon, IconButton, Typography, Modal, SidePanel, Autocomplete } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useReassignToNewClientMutation } from "@/redux/apis/booking/endpoints/actions";
import { useLazySearchClientQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { addNotification, addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

interface ReassignToNewClientProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  clientAccountId: z.string().nonempty(),
});

type FormData = z.infer<typeof schema>;

export const ReassignToNewClient = ({ open, onClose }: ReassignToNewClientProps) => {
  const { id: bookingId, paymentStatus } = useSelectedBooking();
  const [reassign] = useReassignToNewClientMutation();
  const form = useForm({
    defaultValues: {
      clientAccountId: "",
    },
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = form;

  const onSubmit = async (data: FormData) => {
    await reassign({
      bookingId,
      accountId: data.clientAccountId,
    })
      .unwrap()
      .then(() => {
        addNotification("success", "Reassigned new client", "Job successfully reassigned");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const isPaid = paymentStatus === "paid";

  return (
    <>
      <Modal
        open={open && isPaid}
        onClose={onClose}
        className="max-w-[515px]"
        title="Reassign Job to Another Client"
        description="This job has been paid and charged against a client account. You are no longer able to move this job"
      >
        <div className="flex justify-end">
          <Button onClick={onClose} className="self-end">
            Close
          </Button>
        </div>
      </Modal>
      <SidePanel open={open && !isPaid} onClose={onClose} width={550} focused>
        <section className="flex items-center justify-between p-5">
          <div className="flex items-center gap-2">
            <Icon name="User" size="lg" />
            <Typography variant="title">Reassign Job to Another Client</Typography>
          </div>
          <div className="ml-3 flex h-7 items-center">
            <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
          </div>
        </section>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
            <div className="flex-1 p-5">
              <div className="bg-neutral-surface-gray p-5">
                <Typography variant="paragraph">
                  For account reassignment, the client must belong to the same company, and the new account&lsquo;s default payment method
                  will be used for billing.
                </Typography>
                <ClientSearch />
              </div>
            </div>
            <div className="p-5">
              <Divider className="-mx-5" />
              <Button type="submit" disabled={!isDirty || isSubmitting} className="mt-5 w-full" variant="primary" size="lg">
                Reassign Job
              </Button>
            </div>
          </form>
        </FormProvider>
      </SidePanel>
    </>
  );
};

type ClientOption = {
  id: string;
  name: string;
  email: string;
  phone: string;
};

const ClientSearch = () => {
  const { setValue: setFormValue } = useFormContext<FormData>();
  const [value, setValue] = useState<ClientOption | null>(null);
  const [query, setQuery] = useDebouncedState("", 300);
  const [searchClient, { isFetching, data }] = useLazySearchClientQuery();

  useEffect(() => {
    if (query.length > 1) searchClient({ q: query });
  }, [query, searchClient]);

  const options = data?.map(
    ({ name, ...rest }) =>
      ({
        name,
        ...rest,
      } as ClientOption)
  );

  const handleChange = useCallback(
    (option: ClientOption | null) => {
      setFormValue("clientAccountId", option?.id || "", { shouldDirty: true });
      setValue(option);
    },
    [setFormValue]
  );

  return (
    <Autocomplete
      options={options}
      isLoading={isFetching}
      value={value}
      onValueChange={handleChange}
      onQueryChange={setQuery}
      className="mt-4"
      placeholder="Search Client"
      focused
    />
  );
};
