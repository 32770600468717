import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from "react";
import { Icon, IconButton, SidePanel, Skeleton, Typography } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { useGetHistoryLogsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { VehicleHistoryLog } from "@/redux/slices/vehicle/types";
import { useViewVehicleContext } from "../../context";
import { vehicleHistoryColumns } from "./fixtures";
import { getVehicleHistoryActionText, getVehicleHistoryEventText } from "./helpers";
import { VehicleHistoryLogData } from "./types";

interface VehicleHistoryLogsModalProps {
  open: boolean;
  onClose: () => void;
}

export const VehicleHistoryLogsModal = ({ open, onClose }: VehicleHistoryLogsModalProps) => {
  const {
    vehicle: { uuid },
  } = useViewVehicleContext();
  const { data: response } = useGetHistoryLogsQuery(uuid);

  const logs = useMemo(() => {
    if (!response) return undefined;

    return response;
  }, [response]);

  return (
    <SidePanel open={open} onClose={onClose} width={800} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="Clock" size="lg" />
          <Typography variant="title">History Logs</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="p-5">{!logs ? <Skeleton /> : <VehicleHistoryTable logs={logs} />}</section>
    </SidePanel>
  );
};

const VehicleHistoryTable = ({ logs }: { logs: VehicleHistoryLog[] }) => {
  const { dateFormat, fullTimeFormat } = useProfile();

  const data: VehicleHistoryLogData[] = logs.map((i, idx) => ({
    id: idx.toString(),
    dateTime: (
      <div className="grid">
        <span className="leading-snug">{formatInTimeZone(new Date(i.eventTime.timestamp), i.eventTime.timezone, dateFormat)}</span>
        <span className="leading-snug text-neutral-dark-gray">
          {formatInTimeZone(new Date(i.eventTime.timestamp), i.eventTime.timezone, fullTimeFormat)}
        </span>
      </div>
    ),
    modifier:
      i.event.type !== "notification" && i.performedBy ? (
        <span className="leading-none">
          {i.performedBy.firstName} {i.performedBy.lastName}
        </span>
      ) : (
        <span className="leading-none text-neutral-dark-gray">-</span>
      ),
    event: (
      <div className="flex items-center gap-2">
        <Typography variant="small" className="truncate leading-snug">
          {getVehicleHistoryEventText(i.event.type, i.event.name, i.field?.name)}
        </Typography>
      </div>
    ),
    action: i.field ? getVehicleHistoryActionText(i.field.name, i.field.from, i.field.to) : null,
  }));

  return (
    <DataTable
      data={data}
      columns={vehicleHistoryColumns}
      className="mt-5 [&>table_td]:text-sm max-sm:[&>table_td]:max-w-[100px]"
      emptyState={<EmptyState />}
    />
  );
};

const EmptyState = () => {
  return (
    <div className="m-5 place-items-center p-5">
      <Typography>There are currently no history for this vehicle.</Typography>
    </div>
  );
};
