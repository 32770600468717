import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Icon, Tooltip, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { VehicleCategoryFormData } from "../../types";

export const VehicleCategoryMultiplierField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<VehicleCategoryFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph">Vehicle Category Multiplier (%)</Typography>
        <Tooltip content="When selecting this vehicle category, the base trip will be increased by this multiplier %" placement="bottom">
          <span>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="md" />
          </span>
        </Tooltip>
      </div>
      <AddOnTextInput
        className="w-full"
        type="number"
        placeholder="0"
        endAddOn="%"
        step="any"
        hasError={!!errors.vehicleCategoryMultiplier}
        {...register("vehicleCategoryMultiplier")}
      />
      <ErrorMessage errors={errors} name="vehicleCategoryMultiplier" />
    </div>
  );
};
