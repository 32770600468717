import { clsx } from "@/utils";
import { DataTableRowProps } from "../types";
import { DataTableCell } from "./DataTableCell";

export const DataTableRow = <T extends object>({ row, className, children, ...props }: DataTableRowProps<T>) => {
  return (
    <>
      <div className={clsx("tr flex bg-white first:rounded-t-lg last:rounded-b-lg", className)} {...props}>
        {row.getVisibleCells().map((cell) => (
          <DataTableCell key={cell.id} cell={cell} className="py-2 align-middle text-base font-medium text-neutral-black max-lg:p-2" />
        ))}
      </div>
      {children && <div className="tr subcomponent flex border-none py-2">{children}</div>}
    </>
  );
};
