import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { VehicleCategoryFormData } from "../../types";

export const VehicleCategoryNameField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<VehicleCategoryFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Vehicle Category Name</Typography>
      <TextInput
        className="w-full"
        placeholder="Enter Vehicle Category Name"
        maxLength={32}
        hasError={!!errors.vehicleCategoryName}
        {...register("vehicleCategoryName")}
      />
      <ErrorMessage errors={errors} name="vehicleCategoryName" />
    </div>
  );
};
