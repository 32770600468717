import { PropsWithChildren } from "react";
import { ScrollRestoration } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { ConfigProvider, Head, LayoutProvider, PaymentConfigurationBanner, PrivateContent, Sidebar } from "@/components/organisms";
import { useSetupAccount } from "./hooks/useSetupAccount";

export const Private = () => {
  return (
    <ConfigProvider>
      <AccountProvider>
        <LayoutProvider>
          <Head />
          <ScrollRestoration />
          <PaymentConfigurationBanner />
          <Sidebar className="hidden lg:flex" />
          <PrivateContent />
        </LayoutProvider>
      </AccountProvider>
    </ConfigProvider>
  );
};

const AccountProvider = ({ children }: PropsWithChildren) => {
  const { isReady } = useSetupAccount();

  return isReady ? <>{children}</> : <Loading />;
};
