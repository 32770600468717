import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Icon, Tooltip, Typography } from "@/components/atoms";
import { Option } from "@/redux/types";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

interface PricingProfileFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option;
}

export const PricingProfileField = ({ options, className, ...props }: PricingProfileFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  const dataOptions = [
    { name: "Dynamic Pricing", value: "dynamic-pricing" },
    ...Object.entries(options).map(([key, value]) => ({ name: value, value: key })),
  ];

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography>Pricing Profile</Typography>
        <Tooltip content="The Pricing Profile you set determines all bookings costs for this company" placement="right" maxWidth={480}>
          <Icon name="InfoCircle" size="lg" variant="Bold" className="rotate-180 text-neutral-dark-gray" />
        </Tooltip>
      </div>
      <Controller
        control={control}
        name="pricingProfile"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Pricing Profile"
            options={dataOptions}
            value={field.value?.toString()}
            onChange={(value) => field.onChange(value)}
            hasError={!!errors.pricingProfile}
          />
        )}
      />
      <ErrorMessage errors={errors} name="pricingProfile" />
      <div className="mt-1 flex flex-col rounded-lg bg-neutral-gray p-4">
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          Fixed Pricing is set as a fixed-cost for specific distance ranges. Dynamic pricing is set as a per-kilometer-rate for specific
          distance ranges.
        </Typography>
      </div>
    </div>
  );
};
