import { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import z from "zod";
import { Button, IconButton, Spinner, TextArea, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBookingOfferMutation } from "@/redux/apis/bookingOffer/bookingOfferApi";
import { addToast, clsx, parseHtml } from "@/utils";
import { useBookingOfferPageContext } from "../context";

const schema = z.object({
  referenceNumber: z.string(),
  externalNotes: z.string(),
});

type FormData = z.infer<typeof schema>;

export const ReferenceNumber = () => {
  const { bookingOffer, params, isLoading: isLoadingOffer } = useBookingOfferPageContext();
  const [updateBookingOffer] = useUpdateBookingOfferMutation();
  const {
    referenceExternal: referenceNumber,
    notes: { external: externalNotes },
  } = bookingOffer;

  const [editMode, setEditMode] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      referenceNumber,
      externalNotes,
    },
  });

  useLayoutEffect(
    () =>
      reset({
        externalNotes,
        referenceNumber,
      }),
    [editMode, reset, externalNotes, referenceNumber]
  );

  const onSubmit = async (data: FormData) =>
    updateBookingOffer({
      ...params,
      note: data.externalNotes,
      reference_external: data.referenceNumber,
    })
      .unwrap()
      .then(() => addToast("success", "Booking Offer updated successfully"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)))
      .finally(() => setEditMode(false));

  const isLoading = isLoadingOffer || isSubmitting;
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx("flex flex-col gap-4 rounded-lg bg-white p-4 shadow", {
        "pointer-events-none opacity-50": isLoading,
      })}
    >
      <div className="relative flex flex-col gap-1">
        <Typography>Reference Number</Typography>
        {isLoading ? (
          <Spinner />
        ) : editMode ? (
          <TextInput {...register("referenceNumber")} className="w-full" placeholder="Enter Reference Number" />
        ) : (
          <Typography variant="action" className="text-info">
            {referenceNumber || "-"}
          </Typography>
        )}
        {!editMode && (
          <IconButton
            onClick={() => setEditMode(true)}
            className="absolute -right-2 -top-2 text-info"
            iconName="Edit2"
            iconSize="lg"
            variant="tertiary"
          />
        )}
      </div>
      <div className="relative flex flex-col gap-1">
        <Typography>Operator Admin Notes</Typography>
        {isLoading ? (
          <Spinner />
        ) : !editMode ? (
          <Typography className="text-neutral-dark-gray">{externalNotes ? parseHtml(externalNotes) : "No notes available"}</Typography>
        ) : (
          <TextArea {...register("externalNotes")} className="w-full" placeholder="Enter Notes" />
        )}
        <div className={`mt-3 justify-end gap-3 ${editMode ? "flex" : "hidden"}`}>
          <Button variant="secondary" size="sm" onClick={() => setEditMode(false)}>
            Cancel
          </Button>
          <Button variant="primary" size="sm" type="submit" disabled={!isDirty}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
