import { useContext } from "react";
import { IconButton } from "@/components/atoms";
import { SortableItemContext } from "./SortableItem";

export const DragHandle = () => {
  const { attributes, listeners } = useContext(SortableItemContext);

  return (
    <IconButton
      iconSize="lg"
      iconName="drag"
      isCustomIcon
      variant="secondary"
      className="border-none p-1 text-neutral-dark-gray"
      {...attributes}
      {...listeners}
    />
  );
};
