import { DropdownMenu, Icon, Stars, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { RatingItem } from "@/redux/slices/rating/types";
import { RatingLink } from "./RatingLink";
import { RatingsTableData } from "./types";

export const generateRatingsColumns = () => {
  const columns: DataTableColumnDef<RatingsTableData>[] = [
    { 
      accessorKey: "jobId", 
      header: "Job ID", 
      id: "job",
      cell: (props) => props.row.original.jobId
    },
    {
      accessorKey: "passenger",
      header: "Passenger",
      id: "passenger",
      cell: (props) => props.row.original.passenger,
    },
    { 
      accessorKey: "driver", 
      header: "Driver", 
      id: "driver",
      cell: (props) => props.row.original.driver, 
    },
    { accessorKey: "clientsComments", header: "Client's Comments", size: 400, cell: (props) => props.row.original.clientsComments, enableSorting: false },
    {
      accessorKey: "tripRating",
      header: "Trip Rating",
      id: "rating",
      size: 200,
      cell: (props) => props.row.original.tripRating,
    },
    {
      accessorKey: "action",
      header: "Action",
      cell: (props) => props.row.original.action,
      size: 75,
      enableSorting: false,
    },
  ];

  return columns;
};

export const generateRatingsData = (
  items: RatingItem[],
  viewRating: (item?: RatingItem) => void,
) => {
  const data = items.map(
    (i) =>
      ({
        id: i.id,
        jobId: <RatingLink name={i.job.id} path={i.job.path} />,
        passenger: `${i.passenger.name} (${i.passenger.phone})`,
        driver: <RatingLink name={i.driver.name} path={i.driver.path} />,
        clientsComments: (
          <div className="flex">
            {i.comments ? (
              <Tooltip content={i.comments} placement="bottom">
                <span>
                  <Typography variant="paragraph" className="cursor-default line-clamp-1">
                    {i.comments}
                  </Typography>
                </span>
              </Tooltip>
            ) : <Typography variant="paragraph" className="cursor-default line-clamp-1">-</Typography>}
          </div>
        ),
        tripRating: (
          <div className="flex flex-row items-center gap-x-1.5">
            <Typography variant="paragraph">{`${i.rating}`}</Typography>
            <Stars value={1} count={1} edit={false} size={18} color1="transparent" />
          </div>
        ),
        action: (
          <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
            <DropdownMenu.Item onClick={() => viewRating(i)}>
              <Icon name="ArrowRight" size="sm" className="mr-2.5" />
              <Typography>View Rating</Typography>
            </DropdownMenu.Item>
          </DropdownMenu>
        ),
      } as RatingsTableData)
  );

  return data;
};