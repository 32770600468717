import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";
import { VehicleMakesTable } from "./VehicleMakesTable";
import { VehicleModelsTable } from "./VehicleModelsTable";
import { useVehicleCategoryOffloadModelsContext, VehicleCategoryOffloadModelsProvider } from "./context";

export const VehicleCategoryOffloadModelsField = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <VehicleCategoryOffloadModelsProvider>
      <Content {...props} />
    </VehicleCategoryOffloadModelsProvider>
  );
};

const Content = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();
  const { isLoading } = useVehicleCategoryOffloadModelsContext();

  const isEnabled = watch("offloadEnableSpecificModels");

  return (
    <div className={clsx("flex flex-col gap-y-2", className, !isEnabled && "pointer-events-none opacity-50")} {...props}>
      <div
        className={`flex max-w-full divide-x divide-neutral-mid-gray self-start overflow-hidden rounded-lg border border-neutral-mid-gray bg-white ${
          isLoading ? "opacity-50" : ""
        }`}
      >
        <VehicleMakesTable />
        <VehicleModelsTable />
      </div>
      <ErrorMessage errors={errors} name="offloadVehicleModels" />
    </div>
  );
};
