import { HTMLAttributes, forwardRef } from "react";
import { IconButton, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface OnboardingMenuItemProps extends HTMLAttributes<HTMLDivElement> {
  shrinked?: boolean;
  active?: boolean;
  title?: string;
  desc?: string;
}

export const OnboardingMenuItem = forwardRef<HTMLDivElement, OnboardingMenuItemProps>((props, ref) => {
  const { className, active = false, shrinked = false, title = "Optimize Operations", desc = "Operational Highlights", ...rest } = props;

  return (
    <div
      className={clsx(
        "group relative inline-flex w-full cursor-pointer overflow-hidden rounded-lg border border-neutral-gray bg-neutral-gray px-2  hover:border-primary-dark",
        { "is-active border-primary-dark bg-primary-light": active },
        className
      )}
      {...rest}
      ref={ref}
    >
      <div className={clsx("grid min-w-[150px] flex-1 py-2 transition-size", { hidden: shrinked })}>
        <Typography variant="action" className="group-[.is-active]:text-primary">
          {title}
        </Typography>
        <Typography variant="small" className="text-neutral-dark-gray group-[.is-active]:text-primary">
          {desc}
        </Typography>
      </div>
      <IconButton iconName="Setting4" variant="custom" iconSize="lg" className="px-0.5 py-3.5 group-[.is-active]:text-primary" />
    </div>
  );
});

OnboardingMenuItem.displayName = "OnboardingMenuItem";
