import { useMemo, useState } from "react";
import { Button, Modal, Typography, Loading } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useDeleteCostExtraMutation, useReorderCostExtraMutation, useToggleCostExtraMutation } from "@/redux/apis/config/pricing/costExtras/costExtrasApi";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { addNotification, clsx} from "@/utils";
import { generateEditorColumns, generateEditorTableData} from "./helpers";
import { modals } from "./modals";
import { ExtrasTableData } from "./types";
import { useAppDispatch } from "@/redux/hooks";
import { setCostExtras } from "@/redux/slices/pricing/pricingSlice";
import { useOperator } from "@/hooks";

interface ExtrasTableEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  items: CostExtrasItem[];
  onEditItem: (item: CostExtrasItem) => void;
  addExtra: () => void;
}

export const ExtrasTableEditor = ({ items, onEditItem, addExtra, className, ...props }: ExtrasTableEditorProps) => {
  const dispatch = useAppDispatch();
  const { currency } = useOperator();
  const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null);
  const [toggle, { isLoading: toggleLoading }] = useToggleCostExtraMutation();
  const [remove, { isLoading: removeLoading }] = useDeleteCostExtraMutation();
  const [reorder, { isLoading: reorderLoading }] = useReorderCostExtraMutation();
  const [activeModal, setActiveModal] = useState<"error" | "exit" | "delete" | null>(null);

  const toggleItemStatus = (id: string, active: boolean) => {
    const item = items.find((i) => i.id === id);
    if (!item) return;

    toggle({ uuid: id, is_active: active })
      .unwrap()
      .then(() => {
        addNotification("info", `Changed active status: ${active ? "On" : "Off"}`, `Changed active status for "${item.name}".`);
      })
      .catch(() => setActiveModal("error"));
  };

  const onDeleteItem = (item: CostExtrasItem) => {
    setPendingDeleteId(item.id);
    setActiveModal("delete");
  };

  const onReorder = (sortedItems: ExtrasTableData[]) => {
    const mapping: { [key: string]: number } = {};

    sortedItems.forEach((item, index) => {
      const newOrder = items[index].order;
      mapping[item.id] = newOrder;
    });

    const newItems = items.map((item) => ({ ...item, order: mapping[item.id] })).sort((a, b) => (a.order - b.order));

    dispatch(setCostExtras(newItems));

    reorder(mapping)
      .unwrap()
      .then(() => {
        addNotification("info", "Updated Order", "Successfully updated fixed cost extras order.");
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const data = useMemo(
    () => generateEditorTableData(items, currency, toggleItemStatus, onEditItem, onDeleteItem),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => generateEditorColumns(data), []);

  const handleDeleteRow = () => {
    setActiveModal(null);
    const item = items.find((i) => i.id === pendingDeleteId);
    if (!item) return;

    remove(item.id)
      .unwrap()
      .then(() => {
        addNotification("info", `Deleted 1 row`, `Deleted "${item.name}"`);
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteRow} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  return (
    <div className="relative">
      {activeModal && (
        <Modal
          open={true}
          title={modals[activeModal].title}
          description={modals[activeModal].description}
          onClose={() => setActiveModal(null)}
          renderButtons={renderModalButtons}
        />
      )}
      {(toggleLoading || removeLoading || reorderLoading) && <Loading />}
      <DataTable
        columns={columns}
        data={data}
        className={clsx("[&_table]:table-auto overflow-x-auto w-full", className)}
        onReorder={onReorder}
        emptyState={
          <div className="flex flex-col items-center justify-center bg-white py-28">
            <Button variant="tertiary" size="lg" startIcon="Add" onClick={() => addExtra()}>
              Add First Fixed Cost Extra
            </Button>
            <Typography variant="paragraph" className="mt-3 text-center text-neutral-dark-gray">
              It looks like you haven&apos;t added any fixed cost extras yet. Begin by adding your first fixed cost extra to get started.
            </Typography>
          </div>
        }
        {...props}
      />
    </div>
  );
};
