import { Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface NoFilterResultsProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  icon?: "sort" | React.ReactElement;
}

export const NoFilterResults = ({
  className,
  title = "No results found",
  description = "No results match your selected filters. Try broadening your filter criteria or clearing the filters to see more options",
  icon = "sort",
  children,
  ...props
}: NoFilterResultsProps) => {
  const renderIcon = () => {
    if (icon === "sort") return <Icon name="Sort" size="lg" className="mb-3" />;

    return <icon.type {...icon.props} className="mb-3" />;
  };

  return (
    <section className={clsx("flex flex-col place-items-center p-8 lg:p-16", className)} {...props}>
      {renderIcon()}
      <div className="text-center">
        <Typography variant="action" className="mb-1 block">
          {title}
        </Typography>
        <Typography className="max-w-[470px] text-neutral-dark-gray ">{description}</Typography>
      </div>
      <div className="mt-5 flex items-center gap-4">{children}</div>
    </section>
  );
};
