import { toast } from "react-hot-toast";
import { Notification, NotificationType } from "@/components/atoms";

export const addNotification = (type: NotificationType, title: string, message: string, duration = 5000) => {
  const { innerWidth: width } = window;

  toast.custom(
    (t) => <Notification type={type} visible={t.visible} title={title} message={message} onClose={() => toast.dismiss(t.id)} />,
    {
      position: width > 768 ? "top-right" : "top-left",
      duration,
    }
  );
};
