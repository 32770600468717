import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { addOnsDisableMessage } from "../fixtures";

interface AddOnDisableModalProps {
  id: string;
  name: string;
  open: boolean;
  onClose: () => void;
  onDisable: () => void;
  isLoading?: boolean;
}

export const AddOnDisableModal = ({ id, name, open, onClose, onDisable, isLoading }: AddOnDisableModalProps) => {  
  return (
    <Modal 
      open={open} 
      onClose={() => isLoading ? null : onClose()} 
      className="w-[80vw] md:w-[514px] lg:w-[514px]"
    >
      <div className="relative flex flex-col">
        {isLoading && <Loading />}
        <Typography variant="title">Disable {name}</Typography>
        <Typography variant="paragraph" className="mt-3 whitespace-pre-wrap">{addOnsDisableMessage[id]}</Typography>
        <div className="flex flex-row items-center justify-end gap-x-4 mt-[22px]">
          <Button variant="secondary" size="md" onClick={onClose}>Cancel</Button>
          <Button variant="primary" size="md" className="bg-danger hover:bg-danger-dark" onClick={onDisable}>Disable {name}</Button>
        </div>
      </div>
    </Modal>
  );
};
