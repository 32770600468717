import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { AccreditationItem } from "@/redux/slices/accreditation/types";
import { createSearchParams } from "@/utils";
import {
  transformAccreditations,
  transformRegistrationCertificatePath,
  transformAccreditationHistory,
  transformAccreditationItem,
} from "./helpers";
import {
  AddAccreditationResponse,
  ApproveAccreditationResponse,
  DeleteAccreditationResponse,
  DenyAccreditationResponse,
  EditAccreditationParams,
  GetAccreditationsParams,
  GetAccreditationsResponse,
  GetRegistrationCertificatePathResponse,
  RawAccreditation,
  GetAccreditationHistoryResponse,
  GetAccreditationHistoryParams,
} from "./types";

export const accreditationApi = createApi({
  reducerPath: "accreditationApi",
  baseQuery: baseQueryWithProvider("accreditation"),
  endpoints: (builder) => ({
    getAccreditations: builder.query<GetAccreditationsResponse, GetAccreditationsParams>({
      query: (params) => `?${createSearchParams(params).toString()}`,
      providesTags: ["Accreditations"],
      transformResponse: transformAccreditations,
    }),
    getRegistrationCertificatePath: builder.query<GetRegistrationCertificatePathResponse, string>({
      query: (id) => `/${id}`,
      transformResponse: transformRegistrationCertificatePath,
    }),
    addAccreditation: builder.mutation<AddAccreditationResponse, FormData>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Accreditations"],
    }),
    editAccreditation: builder.mutation<RawAccreditation, EditAccreditationParams>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Accreditations"],
    }),
    deleteAccreditation: builder.mutation<DeleteAccreditationResponse, string>({
      query: (id) => ({
        url: `/${id}/action`,
        method: "DELETE",
      }),
      invalidatesTags: ["Accreditations"],
    }),
    approveAccreditation: builder.mutation<ApproveAccreditationResponse, string>({
      query: (id) => ({
        url: `/${id}/action`,
        method: "POST",
        body: { status: "approved" },
      }),
      invalidatesTags: ["Accreditations"],
    }),
    denyAccreditation: builder.mutation<DenyAccreditationResponse, string>({
      query: (id) => ({
        url: `/${id}/action`,
        method: "POST",
        body: { status: "denied" },
      }),
      invalidatesTags: ["Accreditations"],
    }),
    getAccreditationHistory: builder.query<GetAccreditationHistoryResponse, GetAccreditationHistoryParams>({
      query: ({ id, ...params }) => `/${id}/history?${createSearchParams(params).toString()}`,
      transformResponse: transformAccreditationHistory,
    }),
    getAccreditationItem: builder.query<AccreditationItem, string>({
      query: (id) => `/${id}`,
      transformResponse: transformAccreditationItem,
    }),
  }),
  tagTypes: ["Accreditations"],
});

export const {
  useLazyGetAccreditationsQuery,
  useLazyGetRegistrationCertificatePathQuery,
  useAddAccreditationMutation,
  useEditAccreditationMutation,
  useApproveAccreditationMutation,
  useDenyAccreditationMutation,
  useDeleteAccreditationMutation,
  useLazyGetAccreditationHistoryQuery,
  useGetAccreditationItemQuery,
} = accreditationApi;
