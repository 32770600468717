import { useEffect, useMemo, useState } from "react";
import { fetchDirections } from "@/lib/mapbox";
import { BookingTripLog } from "@/redux/slices/booking/types";
import { getAllWayPoints, getCommencedWaypoints, getDriverOnWayPoints, showBookingETA } from "../helpers";

interface UseBookingETAProps {
  tripLog: BookingTripLog;
}

export const useBookingETA = ({ tripLog }: UseBookingETAProps) => {
  const showETA = showBookingETA(tripLog);
  const [ETA, setETA] = useState<number | null>(null);
  const {
    job: { status },
  } = tripLog;

  const waypoints = useMemo(() => {
    if (!showETA) return null;
    switch (status) {
      case "on_way_to_job":
        return getDriverOnWayPoints(tripLog);

      case "commenced":
        return getCommencedWaypoints(tripLog);

      case "awaiting_driver_allocation":
      case "driver_allocated_confirmed":
      case "driver_allocated_not_confirmed":
      default:
        return getAllWayPoints(tripLog);
    }
  }, [showETA, status, tripLog]);

  useEffect(() => {
    if (waypoints)
      fetchDirections(waypoints).then((data) => {
        if (!data) return;

        // Extract the ETA from the response
        const duration = data.routes[0].duration;
        const durationInMinutes = Math.round(duration / 60);
        setETA(durationInMinutes);
      });
  }, [waypoints]);

  return { ETA };
};
