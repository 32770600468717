import { Outlet } from "react-router-dom";
import { SignUpContextProvider } from "@/components/organisms";
import { ForgotPassword, Login, ResetPassword, SignUp, SignUpInvite } from "@/components/pages";

export const authRoutes = [
  {
    path: "login",
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: ":providerCode",
        element: <Login />,
      },
    ],
  },
  {
    path: "signup",
    element: (
      <SignUpContextProvider>
        <Outlet />
      </SignUpContextProvider>
    ),
    children: [
      {
        index: true,
        element: <SignUp />,
      },
      {
        path: ":providerCode",
        element: <SignUp />,
      },
    ],
  },
  {
    path: "invite/:token",
    element: (
      <SignUpContextProvider>
        <SignUpInvite />
      </SignUpContextProvider>
    ),
  },
  {
    path: "forgot-password",
    children: [
      {
        index: true,
        element: <ForgotPassword />,
      },
      {
        path: "reset/:token",
        element: <ResetPassword />,
      },
    ],
  },
];
