import { DataTableColumnDef } from "@/components/molecules";
import { BookingAttachmentData, BookingHistoryItemData, BookingMessageData } from "./types";

export const bookingHistoryColumns: DataTableColumnDef<BookingHistoryItemData>[] = [
  {
    header: "Date",
    cell: (props) => props.row.original.dateTime,
    size: 110,
  },
  {
    header: "Modifier",
    cell: (props) => props.row.original.modifier,
    size: 120,
  },
  {
    header: "Event",
    cell: (props) => props.row.original.event,
    size: 220,
  },
  {
    header: "Action",
    cell: (props) => props.row.original.action,
  },
];

export const bookingAttachmentColumns: DataTableColumnDef<BookingAttachmentData>[] = [
  {
    header: "Creation Date",
    cell: (props) => props.row.original.dateTime,
    size: 120,
  },
  {
    header: "File Details",
    cell: (props) => props.row.original.filename,
    size: 180,
  },
  {
    accessorKey: "permission",
    header: "Permission",
    size: 120,
    cell: (props) => props.row.original.permission,
    enableSorting: false,
  },
  {
    header: "Uploaded By",
    cell: (props) => props.row.original.uploader,
    size: 200,
  },
  {
    header: " ",
    cell: (props) => props.row.original.actions,
    size: 70,
  },
];

export const bookingMessagesColumns: DataTableColumnDef<BookingMessageData>[] = [
  {
    accessorKey: "date",
    header: "Date",
    enableSorting: false,
    size: 120,
  },
  {
    accessorKey: "time",
    header: "Time",
    enableSorting: false,
    size: 120,
  },
  {
    header: "Type",
    size: 20,
    cell: (props) => props.row.original.type,
  },
  {
    header: "From",
    cell: (props) => props.row.original.from,
    size: 120,
  },
  {
    header: "Communication",
    cell: (props) => props.row.original.communication,
  },
];
