import { useFormContext } from "react-hook-form";
import { ErrorMessage, RadioInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpOperatorFormData } from "../types";

export const OfficeHandlingOperationsField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="paragraph">Do you currently have office employees handling your business operations?</Typography>
      <div className="flex flex-row gap-x-10">
        <RadioInput label="Yes" value="YES" {...register("officeHandlingOperations")} />
        <RadioInput label="No" value="NO" {...register("officeHandlingOperations")} />
      </div>
      <ErrorMessage errors={errors} name="officeHandlingOperations" />
    </div>
  );
};
