import { endOfDay, format } from "date-fns";
import { usePagination, useSorting } from "@/hooks";
import { GetCreditCardPaymentsParams } from "@/redux/apis/payment/record/types";
import { usePaymentsDates } from "./usePaymentsDates";
import { usePaymentsStatus } from "./usePaymentsStatus";

export const useGetCreditCardPaymentsParams = () => {
  const { page, pageSize, clearPagination } = usePagination();
  const { sortKey, direction } = useSorting();
  const [selectedDates, setSelectedDates] = usePaymentsDates();
  const [selectedStatus, setSelectedStatus] = usePaymentsStatus();
  const provideDateParams = selectedStatus.status === "paid" || selectedStatus.status === "refund";

  const params: GetCreditCardPaymentsParams = {
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
    from_date: format(selectedDates.fromDate, "yyyy-MM-dd"),
    to_date: format(endOfDay(selectedDates.toDate), "yyyy-MM-dd"),
    status: selectedStatus.status,
  };

  return {
    params,
    page,
    pageSize,
    sort: sortKey,
    direction,
    selectedDates,
    setSelectedDates,
    selectedStatus,
    setSelectedStatus,
    clearPagination,
    provideDateParams,
  };
};
