import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { PageHeader, ProfileDetails, ProfileHeader } from "@/components/organisms";
import { useGetProfileQuery } from "@/redux/apis/profile/profileApi";
import { ProfileChangePassword } from "./ProfileChangePassword";
import { ProfileSecureAccount } from "./ProfileSecureAccount";
import { ProfileEdit } from "./ProfileEdit";
import { ProfileVerifyEmail } from "./ProfileVerifyEmail";
import { ProfileVerifyPhone } from "./ProfileVerifyPhone";
import { ProfileSignUpSuccess } from "./ProfileSignUpSuccess";
import { ProfilePaymentMethodSetup } from "./ProfilePaymentMethodSetup";

export const Profile = () => {
  const [params] = useSearchParams();
  const { isLoading, refetch } = useGetProfileQuery();

  useEffect(() => {
    if (params.get("updated")) refetch();
  }, [refetch, params]);

  if (isLoading) return <Loading />;

  return (
    <>
      <PageHeader skipCrumbs={1} title="Profile" />
      <div className="mb-4 mt-2 overflow-hidden rounded-lg shadow-lg">
        <ProfileHeader />
        <ProfileDetails />
      </div>
    </>
  );
};

Profile.Edit = ProfileEdit;
Profile.ChangePassword = ProfileChangePassword;
Profile.SecureAccount = ProfileSecureAccount;
Profile.VerifyEmail = ProfileVerifyEmail;
Profile.VerifyPhone = ProfileVerifyPhone;
Profile.SignUpSuccess = ProfileSignUpSuccess;
Profile.PaymentMethodSetup = ProfilePaymentMethodSetup;
