import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { useOperator } from "@/hooks";

export const MyDriversEmptyState = ({ hasFilters }: { hasFilters?: boolean; }) => {
  const phpHostUrl = getPhpHostUrl();
  const { id: operatorId } = useOperator();

  if (hasFilters) return <EmptyState title="Manage Drivers" description="There are no drivers matching your filter criteria at the moment." />

  return (
    <EmptyState 
      title="My Drivers" 
      description="It looks like you haven't added any drivers yet. Begin by adding or inviting your first driver to get started" 
    >
      <div className="flex flex-row gap-4">
        <a href={`${phpHostUrl}/o/${operatorId}/member/driver/create`} target="_blank" rel="noreferrer">
          <Button variant="secondary" startIcon="Add" size="md">Add Driver</Button>
        </a>
      </div>
    </EmptyState>
  );
}
