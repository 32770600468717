import { createSlice } from "@reduxjs/toolkit";
import { ratingApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { RatingState } from "./types";

const initialState: RatingState = {
  items: [],
  page: pageState,
};

export const ratingSlice = createSlice({
  name: "rating",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(ratingApi.endpoints.getRatings.matchFulfilled, (state, { payload }) => {
      const { items, page } = payload.data;
      state.items = items;
      state.page = page;
    });
  },
});

export default ratingSlice.reducer;
