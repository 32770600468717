import { Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { companyApi } from "@/redux/apis";
import { useUpdateInvoiceStatusMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { addNotification, addToast } from "@/utils";

interface WriteOffProps {
  onClose: () => void;
}

export const WriteOff = ({ onClose }: WriteOffProps) => {
  const dispatch = useAppDispatch();
  const { selectedInvoice: invoice } = useAppSelector(invoicingPageStateSelector);
  const [updateInvoiceStatus, { isLoading }] = useUpdateInvoiceStatusMutation();
  const description = `Are you sure you want to close the invoice ${invoice?.identifier} as a write-off? This action acknowledges that the funds are unlikely to be recovered.`;

  const handleWriteOffInvoice = () => {
    if (!invoice) return;
    updateInvoiceStatus({ id: invoice.id, status: "written_off" })
      .unwrap()
      .then(() => {
        addNotification("info", `Invoice ${invoice.identifier}`, "Invoice has been updated to Written Off");
        onClose();
        dispatch(companyApi.util.invalidateTags(["InvoicesCompanies"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <div className="relative">
      {isLoading && <Loading />}
      <div className="max-w-lg">
        <div className="flex flex-col gap-y-3">
          <Typography variant="title">Close Invoice as Write-Off</Typography>
          <Typography variant="paragraph">{description}</Typography>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="secondary" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleWriteOffInvoice} size="md" className="bg-danger hover:bg-danger-dark">
          Close Invoice
        </Button>
      </div>
    </div>
  );
};
