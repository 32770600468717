import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { transformRatings } from "./helpers";
import { GetRatingsParams, GetRatingsResponse } from "./types";
import { createSearchParams } from "@/utils";

export const ratingApi = createApi({
  reducerPath: "ratingApi",
  baseQuery: baseQueryWithProvider("rating"),
  endpoints: (builder) => ({
    getRatings: builder.query<GetRatingsResponse, GetRatingsParams>({
      query: (params) => `/driver?${createSearchParams(params).toString()}`,
      providesTags: ["Ratings"],
      transformResponse: transformRatings,
    }),
  }),
  tagTypes: ["Ratings"],
});

export const { useGetRatingsQuery } = ratingApi;
