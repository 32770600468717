import { z } from "zod";

const defaultUniversalPricingFormSchema = z.object({
  isPriceIncrease: z.boolean(),
  universalPricing: z.coerce.number().min(0).step(1, "Field must be a whole number."),
});

const priceIncreaseSchema = z.object({
  isPriceIncrease: z.literal(true),
  universalPricing: z.coerce.number().max(9999),
});

const priceDecreaseSchema = z.object({
  isPriceIncrease: z.literal(false),
  universalPricing: z.coerce.number().max(99),
});

const priceSchemaUnion = z.discriminatedUnion("isPriceIncrease", [priceIncreaseSchema, priceDecreaseSchema]);

export const universalPricingFormSchema = z.intersection(defaultUniversalPricingFormSchema, priceSchemaUnion);
