import { adminApi } from "../adminApi";
import { transformUpdateWebsiteDetails, transformWebsiteDetails } from "./helpers";
import { GetWebsiteDetailsResponse, UpdateWebsiteDetailsParams, UpdateWebsiteDetailsResponse } from "./types";

export const websiteDetailsApi = adminApi.enhanceEndpoints({ addTagTypes: ["WebsiteDetails"] }).injectEndpoints({
  endpoints: (builder) => ({
    getWebsiteDetails: builder.query<GetWebsiteDetailsResponse, void>({
      query: () => `/website-details`,
      transformResponse: transformWebsiteDetails,
      providesTags: ["WebsiteDetails"],
    }),
    updateWebsiteDetails: builder.mutation<UpdateWebsiteDetailsResponse, UpdateWebsiteDetailsParams>({
      query: (body) => ({
        url: `/website-details`,
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateWebsiteDetails,
      invalidatesTags: ["WebsiteDetails"],
    }),
  }),
});

export const { useGetWebsiteDetailsQuery, useUpdateWebsiteDetailsMutation } = websiteDetailsApi;
