import { Loading, Typography } from "@/components/atoms";
import { clsx, formatCurrency } from "@/utils";

interface PaymentsAmountDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  amount?: number;
  currency?: string;
  isLoading?: boolean;
  isActive?: boolean;
}

export const PaymentsAmountDisplay = ({
  title,
  amount,
  currency,
  isLoading,
  isActive,
  className,
  ...props
}: PaymentsAmountDisplayProps) => {
  const isActiveContainerClassName = { "bg-primary-light border border-primary": isActive };
  const isActiveTextClassName = { "text-primary": isActive };
  return (
    <div
      className={clsx(
        "relative flex min-w-[170px] cursor-pointer flex-col rounded-lg bg-neutral-surface-gray p-2.5",
        isActiveContainerClassName,
        className
      )}
      {...props}
    >
      {isLoading && <Loading className="rounded-lg" />}
      <Typography variant="paragraph" className={clsx("text-neutral-dark-gray", isActiveTextClassName)}>
        {title}
      </Typography>
      <Typography variant="action" className={clsx("text-info", isActiveTextClassName)}>
        {currency && (amount || amount === 0) ? `${currency} ${formatCurrency(amount, 2, currency)}` : "-"}
      </Typography>
    </div>
  );
};
