import {
  GetManualPaymentDetailsRawResponse,
  GetManualPaymentDetailsResponse,
  GetManualPaymentReceiptRawResponse,
  GetManualPaymentReceiptResponse,
  GetPayInvoiceRawResponse,
  GetPayInvoiceResponse,
  GetPaymentMethodDetailsRawResponse,
  GetPaymentMethodDetailsResponse,
  GetPaymentMethodSecretRawResponse,
  GetPaymentMethodSecretResponse,
} from "./types";

export const transformPayInvoice = (res: GetPayInvoiceRawResponse) => {
  const response: GetPayInvoiceResponse = {
    data: {
      id: res.uuid,
      identifier: res.identifier,
      issuedBy: res.issued_by,
      company: {
        id: res.company.uuid,
        name: res.company.name,
        phone: res.company.phone,
      },
      status: res.status,
      currency: res.currency,
      createdAt: res.created_at,
      issueDate: res.issue_date,
      dueDate: res.due_date,
      amount: res.amount,
      amountPaid: res.amount_paid,
      amountDue: res.amount_due,
      documents: res.documents,
      metadata: {
        isPayByCreditCardAvailable: res.metadata.is_pay_by_credit_card_available,
      },
    },
  };

  return response;
};

export const transformPaymentMethodSecret = (res: GetPaymentMethodSecretRawResponse) => {
  const response: GetPaymentMethodSecretResponse = {
    data: {
      paymentMethodSecret: res.intent_secret,
    },
  };

  return response;
};

export const transformPaymentMethodDetails = (res: GetPaymentMethodDetailsRawResponse) => {
  const response: GetPaymentMethodDetailsResponse = {
    data: {
      id: res.uuid,
      type: res.type,
      method: res.method,
      paymentMethod: res.payment_method,
    },
  };

  return response;
};

export const transformManualPaymentDetails = (res: GetManualPaymentDetailsRawResponse) => {
  const response: GetManualPaymentDetailsResponse = {
    data: {
      id: res.uuid,
      status: res.status,
      amounts: {
        subTotal: res.amounts.sub_total,
        fees: res.amounts.fees,
        total: res.amounts.total,
      },
    },
  };

  return response;
};

export const transformManualPaymentReceipt = (res: GetManualPaymentReceiptRawResponse) => {
  const response: GetManualPaymentReceiptResponse = {
    data: {
      receipt: res.metadata.receipt,
    },
  };

  return response;
};
