import { decode } from "@mapbox/polyline";
import type { Feature } from "geojson";
import { Layer, Source } from "react-map-gl";

export interface PolylineProps {
  type: "on-route" | "on-trip" | "pre-trip";
  encodedPath: string;
}

const colors = {
  "on-route": "#6FC062",
  "on-trip": "#5B9DFF",
  "pre-trip": "#000000",
};

export const Polyline = ({ encodedPath, type }: PolylineProps) => {
  const feature: Feature = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: decode(encodedPath).map((coord) => [coord[1], coord[0]]),
    },
  };

  return (
    <Source type="geojson" data={feature}>
      <Layer
        id={`line-${type}`}
        type="line"
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": colors[type],
          "line-width": 4,
        }}
      />
    </Source>
  );
};
