import { Divider, Typography } from "@/components/atoms";
import { StripeState } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { renderDetail } from "../helpers";

interface DebitsDetailsProps extends React.HTMLAttributes<HTMLDivElement> {
  debits: StripeState["debits"];
}

export const DebitsDetails = ({ debits, className, ...props }: DebitsDetailsProps) => {
  return (
    <div className={clsx("flex flex-col rounded-lg bg-neutral-surface-gray p-5 lg:w-[573px]", className)} {...props}>
      <Typography variant="action" className="text-neutral-dark-gray">
        Bank Name
      </Typography>
      <Typography variant="title" className="mt-1">
        {renderDetail(debits.bankName)}
      </Typography>
      <Divider className="my-3" />
      <div className="grid grid-cols-2">
        <div className="col-span-1">
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            Bank State Branch
          </Typography>
          <Typography variant="paragraph">{renderDetail(debits.routingNumber)}</Typography>
        </div>
        <div className="col-span-1">
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            Last 4 digits of account number
          </Typography>
          <Typography variant="paragraph">{renderDetail(debits.accountNumber)}</Typography>
        </div>
        <div className="col-span-2 mt-4">
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            Account holder name
          </Typography>
          <Typography variant="paragraph">{renderDetail(debits.accountName)}</Typography>
        </div>
      </div>
    </div>
  );
};
