import { flatten } from "ramda";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Icon, SearchInput, Tooltip, Typography } from "@/components/atoms";
import { usePagination } from "@/hooks";
import { ExternalSuppliersFilterPanel } from "./ExternalSuppliersFilterPanel";
import { ExportExternalSuppliers } from "./actions/ExportExternalSuppliers";
import { defaultFilters } from "./fixtures";
import { useGetExternalSuppliersParams } from "./hooks/useGetExternalSuppliersParams";

export const ExternalSuppliersHeader = () => {
  const navigate = useNavigate();
  const { search, setSearch, filters, setFilters } = useGetExternalSuppliersParams();
  const { clearPagination } = usePagination();
  const [openFilter, setOpenFilter] = useState(false);
  const numActiveFilters = flatten(Object.values(filters).filter(Boolean)).length;

  const handleSearch = (value?: string) => {
    clearPagination();
    setSearch(value);
  };

  const handleClearFilters = () => {
    setFilters(defaultFilters);
  };

  return (
    <>
      <header className="flex flex-col">
        <div className="flex flex-row items-center gap-x-2.5">
          <Typography variant="h3" className="leading-8">
            Manage External Suppliers
          </Typography>
          <Tooltip content="This is where you can add any suppliers that could excel your service" placement="right">
            <Icon name="InfoCircle" variant="Bold" size="lg" className="rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </div>
        <div className="flex flex-col gap-x-3 gap-y-2 mt-3 lg:flex-row">
          <div className="flex flex-1">
            <SearchInput search={search} onSearch={handleSearch} placeholder="Search Supplier" size="md" className="w-full lg:w-fit" />
          </div>
          <div className="flex flex-col gap-x-3 gap-y-2 lg:flex-row">
            {numActiveFilters > 0 && (
              <Button onClick={handleClearFilters} variant="tertiary" startIcon="close" className="text-primary-dark" isCustomIcon>
                Clear Filters
              </Button>
            )}
            <Button variant="secondary" startIcon="Sort" onClick={() => setOpenFilter(true)}>
              Filters {numActiveFilters > 0 && `(${numActiveFilters})`}
            </Button>
            <ExportExternalSuppliers />
            <Button onClick={() => navigate("add")} className="px-6" startIcon="Add">
              Add Supplier
            </Button>
          </div>
        </div>
      </header>
      <ExternalSuppliersFilterPanel filters={filters} setFilters={setFilters} open={openFilter} onClose={() => setOpenFilter(false)} />
    </>
  );
};