import { MapLayerMouseEvent } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useBookingTripMap } from "../hooks/useBookingTripMap";
import { BookingTripPoint } from "../types";
import { LocationPoint } from "./common/LocationPoint";

export const BookingTripPoints = memo(() => {
  const {
    map: { ref: mapRef, tripPoints },
  } = useBookingTripMap();
  const [hoveredPoint, setHoveredPoint] = useState<BookingTripPoint | null>(null);

  const layerIds = useMemo(() => tripPoints.map((_, idx) => `location-point-${idx}-fill`), [tripPoints]);

  const handleMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      try {
        if (!mapRef.current || layerIds.length < 1) return;

        const features = mapRef.current.queryRenderedFeatures(event.point, { layers: layerIds });
        if (features && features.length > 0) {
          const feature = features[0];
          const layerIndex = feature.layer.id.split("-")[2];
          const tripPoint = tripPoints[Number(layerIndex)];
          setHoveredPoint(tripPoint);
          mapRef.current.getCanvas().style.cursor = "pointer";
        } else {
          mapRef.current.getCanvas().style.cursor = "";
          setHoveredPoint(null);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [layerIds, mapRef, tripPoints]
  );

  useEffect(() => {
    if (!mapRef.current) return;

    mapRef.current?.on("mousemove", handleMouseMove);
  }, [handleMouseMove, mapRef]);

  if (!tripPoints) return null;

  return (
    <>
      {tripPoints.map((point) => (
        <LocationPoint
          key={point.id}
          id={point.id}
          isHovered={point.id === hoveredPoint?.id}
          type={point.type}
          dateTime={point.dateTime}
          distance={point.distance}
          point={point.location}
          time={point.time}
        />
      ))}
    </>
  );
});

BookingTripPoints.displayName = "BookingTripPoints";
