import { FieldError, FieldErrors } from "react-hook-form";
import { clsx } from "@/utils";
import { Typography, TypographyVariant } from "../Typography/Typography";

export interface ErrorMessageProps<T extends FieldErrors> {
  name: keyof T;
  errors: T;
  variant?: TypographyVariant;
  label?: string;
  className?: string;
  message?: string;
}

export const ErrorMessage = <T extends FieldErrors>({
  name,
  errors,
  variant = "paragraph",
  label,
  className,
  message,
  ...props
}: ErrorMessageProps<T>) => {
  const error = errors[name] as FieldError;

  return error ? (
    <Typography variant={variant} className={clsx("text-danger", className)} {...props}>
      {label && `${label}: `}
      {message ? message : error.message ? error.message : ""}
    </Typography>
  ) : null;
};
