import { Panel } from "@/components/atoms";
import { CompaniesAddForm } from "./forms/CompaniesAddForm";

export const CompaniesAddPanel = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Panel {...props}>
      <CompaniesAddForm />
    </Panel>
  );
};
