import { Panel, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface HowItWorksProps extends React.HTMLAttributes<HTMLDivElement> {
  description: string;
  items: string[];
}

export const HowItWorks = (howItWorksProps: HowItWorksProps) => {
  const { description, items, className, ...props } = howItWorksProps;

  return (
    <Panel className={clsx("w-full flex-shrink-0 lg:w-[328px]", className)} {...props}>
      <div className="flex flex-col gap-4">
        <Typography variant="h3" className="leading-8">
          How it works
        </Typography>
        <Typography variant="paragraph">{description}</Typography>
        {items.map((item, idx) => {
          const numbering = idx + 1;
          return (
            <div className="flex flex-row gap-2" key={`howItWorks${numbering}`}>
              <div>
                <div className="flex items-center justify-center rounded-full bg-primary-light p-1">
                  <div className="flex h-6 w-6 items-center justify-center">
                    <Typography variant="action" className="text-primary">
                      {numbering}
                    </Typography>
                  </div>
                </div>
              </div>
              <Typography variant="paragraph">{item}</Typography>
            </div>
          );
        })}
      </div>
    </Panel>
  );
};
