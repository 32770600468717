import { GetRatingsRawResponse, GetRatingsResponse } from "./types";

export const transformRatings = (res: GetRatingsRawResponse) => {
  const {
    _embedded: { rating: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetRatingsResponse = {
    data: {
      items: items.map((i) => {
        const { job, ratings, driver, account, passenger } = i;
        return {
          id: i.uuid,
          job: {
            id: job.id.toString(),
            path: job.path,
          },
          comments: i.comments,
          rating: i.rating,
          ratings: {
            bookingExperience: ratings.booking_experience,
            driver: ratings.driver,
            overallService: ratings.overall_service,
            professionalism: ratings.professionalism,
            vehicle: ratings.vehicle,
          },
          driver: {
            id: driver.uuid,
            name: driver.name,
            path: driver.path
          },
          account: {
            id: account.uuid,
            name: account.name,
            path: account.path,
          },
          passenger,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};