import { Skeleton } from "@/components/atoms";
import { EmailNotificationsViewPanel } from "@/components/organisms/EmailNotifications";
import { useGetEmailNotificationsQuery } from "@/redux/apis/config/admin/emailNotifications";
import { useAppSelector } from "@/redux/hooks";
import { emailNotificationsSelector } from "@/redux/slices/admin/selectors";
import { EmailNotificationsEdit } from "./EmailNotificationsEdit";

export const EmailNotifications = () => {
  const { isFetching } = useGetEmailNotificationsQuery();
  const { details } = useAppSelector(emailNotificationsSelector);

  if (isFetching) return <Skeleton />;

  return <EmailNotificationsViewPanel details={details} />;
};

EmailNotifications.Edit = EmailNotificationsEdit;
