import { format } from "date-fns";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { NetworkHistoryItem } from "@/redux/slices/network/types";
import { clsx } from "@/utils";
import { generateNetworkHistoryColumns } from "../helpers";
import { PrivateNetworkHistoryTableData } from "../types";

export interface PrivateNetworkHistoryTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: NetworkHistoryItem[];
}

export const PrivateNetworkHistoryTable = ({ items, className, ...props }: PrivateNetworkHistoryTableProps) => {
  const { dateFormat, timeFormat } = useProfile();

  const renderField = (field: string) => {
    switch (field) {
      case "status":
        return "Status";
      case "paysCommission":
        return "Offload Fee Exemption";
      default:
        return "";
    }
  };

  const renderStatus = (field: string, status: string) => {
    switch (field) {
      case "status":
        switch (status) {
          case "pending":
            return "Invitation Sent";
          case "rejected":
            return "Rejected";
          case "disconnected":
            return "Disconnected";
          case "active":
            return "Connected";
          default:
            return "";
        }
      case "paysCommission":
        switch (status) {
          case "true":
            return "No";
          case "false":
            return "Yes";
          default:
            return "";
        }
      default:
        return "";
    }
  };

  const data = items.map(
    (i) =>
      ({
        ...i,
        dateOfRequest: format(new Date(i.created), dateFormat),
        timeOfRequest: format(new Date(i.created), timeFormat),
        operator: i.operator,
        sender: i.account,
        field: renderField(i.field),
        value: renderStatus(i.field, i.value),
      } as PrivateNetworkHistoryTableData)
  );
  const columns = generateNetworkHistoryColumns();

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable columns={columns} data={data} className="[&_table]:table-auto overflow-x-auto w-full" {...props} />
    </div>
  );
};
