import { Button } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";

interface PricingLocationEmptyStateProps {
  onAddPricingLocation: () => void;
}

export const PricingLocationEmptyState = ({ onAddPricingLocation }: PricingLocationEmptyStateProps) => {
  return (
    <EmptyState title="Location Based Pricing" description="Tailor your pricing to match the designated area radius of your choice.">
      <Button startIcon="Add" onClick={onAddPricingLocation}>
        Add Location
      </Button>
    </EmptyState>
  );
};
