import { forwardRef } from "react";
import { clsx } from "@/utils";
import { Icon, Typography } from "..";
import { baseClass, getVariantClass, iconBaseClass } from "./themes";
import { ButtonProps } from "./types";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((buttonProps, ref) => {
  const {
    variant = "primary",
    size = "md",
    className,
    type = "button",
    startIcon,
    endIcon,
    isCustomIcon = false,
    iconClassName,
    children,
    iconVariant,
    isDropdown,
    disabled,
    ...props
  } = buttonProps;

  const variantClass = getVariantClass(variant);

  const sizeClass = {
    "px-3 py-2 h-[28px]": size === "xs",
    "px-3 py-2 h-[33px]": size === "sm",
    "px-3 sm:px-5 py-1.5 sm:py-2.5 h-[32px] sm:h-[37px]": size === "md",
    "px-6 py-3.5 h-[45px]": size === "lg",
    "px-7 py-4 h-[56px]": size === "xl",
  };

  const dropdownClass = { "relative !pr-[42px]": isDropdown };

  const renderStartIcon = () => {
    if (!startIcon) return null;

    if (typeof startIcon === "string") {
      return (
        <Icon
          name={startIcon}
          size="sm"
          variant={iconVariant}
          className={clsx(iconBaseClass, iconClassName)}
          isCustom={isCustomIcon}
          aria-hidden="true"
        />
      );
    }

    return <startIcon.type {...startIcon.props} />;
  };

  const renderEndIcon = () => {
    if (!endIcon) return null;

    if (typeof endIcon === "string") {
      return (
        <Icon
          name={endIcon}
          size="sm"
          variant={iconVariant}
          className={clsx(iconBaseClass, iconClassName)}
          isCustom={isCustomIcon}
          aria-hidden="true"
        />
      );
    }

    return <endIcon.type {...endIcon.props} />;
  };

  const renderDropdownIndicator = () => {
    if (isDropdown) {
      return (
        <div className={clsx("absolute right-0 flex items-center justify-center h-full rounded-r-md px-2 disabled:!bg-neutral-gray disabled:!text-neutral-dark-gray", {
          "!bg-primary-light !text-white": disabled,
          "!bg-neutral-gray !text-neutral-dark-gray": disabled && variant === "tertiary",
          "bg-primary-dark": variant === "primary",
          "bg-neutral-gray disabled:!bg-primary-light disabled:!text-white": variant === "secondary" 
        })}>
          <Icon name="arrow-down" isCustom size="sm" />
        </div>
      );
    }
  }

  return (
    <button {...props} type={type} className={clsx(baseClass, variantClass, sizeClass, dropdownClass, className)} disabled={disabled} ref={ref}>
      {renderStartIcon()}
      <Typography variant="action" className="leading-5">
        {children}
      </Typography>
      {renderEndIcon()}
      {renderDropdownIndicator()}
    </button>
  );
});

Button.displayName = "Button";
