import { CheckboxInput, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { getAssetUrl } from "@/utils";
import { AssociatedVehiclesTableData } from "./types";

export const generateAssociatedVehiclesColumns = (
  onCheckAllChanged: (checked: boolean) => void,
  allChecked: boolean
): DataTableColumnDef<AssociatedVehiclesTableData>[] => {
  return [
    {
      header: () => (
        <CheckboxInput
          className="[&_.typography]:text-sm [&_.typography]:font-semibold"
          size="sm"
          label="Vehicle"
          checked={allChecked}
          onChange={(e) => onCheckAllChanged(e.target.checked)}
          variant="check"
        />
      ),
      accessorKey: "name",
      cell: (props) => props.row.original.vehicle,
      enableSorting: false,
    },
    {
      header: "Number Plate",
      accessorKey: "registrationNumber",
      cell: (props) => props.row.original.numberPlate,
      size: 150,
      enableSorting: false,
    },
    {
      header: "Year",
      accessorKey: "year",
      cell: (props) => props.row.original.year,
      size: 150,
      enableSorting: false,
    },
    {
      header: "Vehicle Owner",
      accessorKey: "owner",
      cell: (props) => props.row.original.owner,
      enableSorting: false,
    },
  ];
};

export const generateAssociatedVehiclesTableData = (
  vehicles: Vehicle[],
  checkedIds: string[],
  onCheckChanged: (vehicleId: string) => void
): AssociatedVehiclesTableData[] => {
  return vehicles.map((vehicle) => ({
    vehicle: (
      <CheckboxInput
        id={`${vehicle.model.make}-${vehicle.model.model}-${vehicle.uuid}`}
        label={`${vehicle.model.make} ${vehicle.model.model}`}
        checked={checkedIds.includes(vehicle.uuid)}
        onClick={() => onCheckChanged(vehicle.uuid)}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onChange={(e) => {}} // to make ts happy
        variant="check"
        size="sm"
      />
    ),
    numberPlate: vehicle.registrationNumber.toString(),
    year: vehicle.year?.toString() ?? "",
    owner: (
      <div className="flex items-center gap-2">
        <img
          src={getAssetUrl(vehicle.owner.avatar, {
            width: 48,
            height: 48,
            fit: "contain",
          })}
          className="h-6 w-6 rounded-full"
        />
        <Typography className="text-neutral-black">{vehicle.owner.name}</Typography>
      </div>
    ),
  }));
};

export const getMatchedVehicles = (vehicles: Vehicle[], vehicleType: string, vehicleClass: string): Vehicle[] => {
  return vehicles.filter((vehicle) => {
    if (vehicleType && vehicleClass) {
      return vehicle.model.type === vehicleType && vehicle.model.class === vehicleClass;
    } else if (vehicleType) {
      return vehicle.model.type === vehicleType;
    } else if (vehicleClass) {
      return vehicle.model.class === vehicleClass;
    }
    return true;
  });
};
