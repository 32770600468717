import { RouteObject } from "react-router-dom";
import { Home, NotFound, Operators, RouteError } from "@/components/pages";
import { Private } from "@/containers";
import { RequireAuth } from "./RequireAuth/RequireAuth";
import { RequireOperatorAccount } from "./RequireOperatorAccount/RequireOperatorAccount";
import { configRoutes } from "./config";
import { operatorRoutes } from "./operators";

const routes: RouteObject = {
  path: "",
  element: <RequireOperatorAccount />,
  children: [
    {
      index: true,
      element: <Home />,
    },
    {
      path: "operators",
      element: <Operators />,
    },
    configRoutes,
    ...operatorRoutes,
    {
      path: "*",
      element: <NotFound hideHeader />,
    },
  ],
};

export const privateRoutes: RouteObject = {
  path: "/",
  element: <RequireAuth />,
  errorElement: <RouteError />,
  children: [
    {
      path: "operator/:operatorId",
      element: <Private />,
      children: [routes],
    },
    {
      path: "*",
      element: <Private />,
      children: [routes],
    },
  ],
};
