import { clsx } from "@/utils";
import { Typography } from "../Typography/Typography";

export interface ImageSelectItemProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  src?: string;
  icon?: JSX.Element;
  selected?: boolean;
}

export const ImageSelectItem = ({ label, src, icon, selected, className, ...props }: ImageSelectItemProps) => {
  const renderImage = () => {
    if (src) return <img src={src} alt={label} className="h-[48px] w-[48px] select-none md:h-[64px] md:w-[64px]" />;
    if (icon) return icon;
  };

  return (
    <div 
      className={clsx(
        "flex w-[138px] h-[186px] md:w-[184px] items-center justify-center p-1.5 md:p-4 rounded-lg cursor-pointer transition-colors ease-linear border border-neutral-surface-gray bg-neutral-surface-gray hover:border-primary-dark hover:bg-primary-light", 
        { "border-primary-dark bg-primary-light": selected },
        className
      )} 
      {...props}
    >
      <div className="flex flex-col items-center justify-center gap-y-3.5">
        {renderImage()}
        <Typography variant="paragraph" className="text-center">{label}</Typography>
      </div>
    </div>
  );
};

ImageSelectItem.displayName = "ImageSelectItem";
