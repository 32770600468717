import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import {
  transformPayInvoice,
  transformPaymentMethodSecret,
  transformPaymentMethodDetails,
  transformManualPaymentDetails,
  transformManualPaymentReceipt,
} from "./helpers";
import {
  GetPayInvoiceResponse,
  GetPayInvoiceParams,
  GetPaymentMethodSecretResponse,
  GetPaymentMethodSecretParams,
  GetPaymentMethodDetailsResponse,
  GetPaymentMethodDetailsParams,
  GetManualPaymentDetailsResponse,
  GetManualPaymentDetailsParams,
  TriggerManualPaymentResponse,
  TriggerManualPaymentParams,
  GetManualPaymentReceiptResponse,
  GetManualPaymentReceiptParams,
} from "./types";

export const payInvoiceApi = createApi({
  reducerPath: "payInvoiceApi",
  baseQuery: baseQueryWithoutProvider("guest/provider"),
  endpoints: (builder) => ({
    getPayInvoice: builder.query<GetPayInvoiceResponse, GetPayInvoiceParams>({
      query: ({ providerId, companyId, invoiceId, invoiceKey }) => `/${providerId}/company/${companyId}/invoice/${invoiceId}/${invoiceKey}`,
      transformResponse: transformPayInvoice,
    }),
    getPaymentMethodSecret: builder.mutation<GetPaymentMethodSecretResponse, GetPaymentMethodSecretParams>({
      query: ({ providerId, companyId, invoiceId, invoiceKey }) => ({
        url: `/${providerId}/company/${companyId}/invoice/${invoiceId}/${invoiceKey}/payment-method`,
        method: "POST",
      }),
      transformResponse: transformPaymentMethodSecret,
    }),
    getPaymentMethodDetails: builder.query<GetPaymentMethodDetailsResponse, GetPaymentMethodDetailsParams>({
      query: ({ providerId, companyId, invoiceId, invoiceKey, setupId }) =>
        `/${providerId}/company/${companyId}/invoice/${invoiceId}/${invoiceKey}/payment-method/${setupId}`,
      transformResponse: transformPaymentMethodDetails,
    }),
    getManualPaymentDetails: builder.mutation<GetManualPaymentDetailsResponse, GetManualPaymentDetailsParams>({
      query: ({ providerId, companyId, invoiceId, invoiceKey, paymentMethodId }) => ({
        url: `/${providerId}/company/${companyId}/invoice/${invoiceId}/${invoiceKey}/pay`,
        method: "POST",
        body: {
          dry_run: true,
          payment_method_uuid: paymentMethodId,
        },
      }),
      transformResponse: transformManualPaymentDetails,
    }),
    triggerManualPayment: builder.mutation<TriggerManualPaymentResponse, TriggerManualPaymentParams>({
      query: ({ providerId, companyId, invoiceId, invoiceKey, paymentMethodId }) => ({
        url: `/${providerId}/company/${companyId}/invoice/${invoiceId}/${invoiceKey}/pay`,
        method: "POST",
        body: {
          dry_run: false,
          payment_method_uuid: paymentMethodId,
        },
      }),
      transformResponse: transformManualPaymentDetails,
    }),
    getManualPaymentReceipt: builder.query<GetManualPaymentReceiptResponse, GetManualPaymentReceiptParams>({
      query: ({ providerId, companyId, invoiceId, invoiceKey, paymentRecordId }) =>
        `/${providerId}/company/${companyId}/invoice/${invoiceId}/${invoiceKey}/pay/${paymentRecordId}`,
      transformResponse: transformManualPaymentReceipt,
    }),
  }),
});

export const {
  useLazyGetPayInvoiceQuery,
  useGetPaymentMethodSecretMutation,
  useLazyGetPaymentMethodDetailsQuery,
  useGetManualPaymentDetailsMutation,
  useTriggerManualPaymentMutation,
  useLazyGetManualPaymentReceiptQuery,
} = payInvoiceApi;
