import { Button, Modal, Typography } from "@/components/atoms";

interface AccountOwnerAccessRequiredModalProps {
  open: boolean;
  onClose: () => void;
}

export const AccountOwnerAccessRequiredModal = ({ open, onClose }: AccountOwnerAccessRequiredModalProps) => {
  return (
    <Modal open={open} title="Account Owner Access Required" onClose={onClose} className="w-[514px]">
      <Typography variant="paragraph">
        Payment Configuration is restricted to the account owner for security reasons. To gain access, please get in touch with your account
        owner or reach out to us at <span className="text-info">support@rideminder.com</span>.
      </Typography>
      <div className="mt-[22px] flex justify-end">
        <Button variant="secondary" size="md" onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
