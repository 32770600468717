import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, IconButton, Panel, SearchInput, Skeleton, Typography } from "@/components/atoms";
import { useLazyGetNetworkItemQuery } from "@/redux/apis/network/networkApi";
import { addToast, clsx } from "@/utils";
import { PrivateNetworkFleetLeadersGrid } from "./tables/PrivateNetworkFleetLeadersGrid";
import { usePagination } from "@/hooks";
import { networkFleetLeaderItemsSelector } from "@/redux/slices/network/selectors";
import { useAppSelector } from "@/redux/hooks";
import { useGetFleetLeadersParams } from "./hooks/useGetFleetLeadersParams";
import { useGetFleetLeadersQuery } from "@/redux/apis/driver/driverApi";

export const PrivateNetworkFleetLeadersPanel = () => {
  const navigate = useNavigate();
  const { id, networkOperatorId: operatorId } = useParams<{ id: string; networkOperatorId: string }>();
  const [getNetworkItem, { isFetching: isFetchingNetworkItem }] = useLazyGetNetworkItemQuery();
  const [networkName, setNetworkName] = useState("");

  const { clearPagination } = usePagination();
  const { params, search, setSearch } = useGetFleetLeadersParams();
  const { isFetching: isFetchingFleetLeaders } = useGetFleetLeadersQuery(params, { refetchOnMountOrArgChange: true });
  const items = useAppSelector(networkFleetLeaderItemsSelector);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const isFleetLeadersEmpty = items.length < 1 && !search && !isFetchingFleetLeaders;

  if (!id || !operatorId) {
    navigate("../");
    throw new Error("Missing Network ID or Operator ID");
  }

  useEffect(() => {
    getNetworkItem(id)
      .unwrap()
      .then(({ data }) => setNetworkName(data.operator.name))
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [id, navigate, getNetworkItem, operatorId]);

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  const renderSearchInput = () => {
    if (isSearchActive) {
      return (
        <div>
          <SearchInput
            isLoading={isFetchingFleetLeaders}
            search={search}
            onSearch={handleSearch}
            className="w-full max-w-sm sm:w-fit"
            placeholder="Search Fleet Leaders"
            size="md"
          />
        </div>
      );
    }

    return (
      <div>
        <div className="hidden sm:flex sm:flex-row sm:gap-x-1">
          <Button variant="tertiary" size="sm" startIcon="SearchNormal1" onClick={() => setIsSearchActive(true)}>
            Search
          </Button>
        </div>
        {/* mobile view */}
        <div className="flex flex-row gap-x-1 sm:hidden">
          <IconButton variant="tertiary" size="sm" iconName="SearchNormal1" onClick={() => setIsSearchActive(true)} />
        </div>
      </div>
    );
  };

  if (isFetchingNetworkItem) return <Skeleton />;

  return (
    <section className="contents">
      <nav className="pb-4">
        <Button startIcon="ArrowLeft" variant="tertiary" className="!bg-transparent" onClick={() => navigate("../")}>
          Back
        </Button>
      </nav>
      <Panel className="flex flex-col">
        <header className={clsx("flex flex-row gap-2", { "flex-col sm:flex-row": isSearchActive })}>
          <div className="flex flex-1 flex-col">
            <Typography variant="h3" className="leading-8">
              {`${networkName ? `${networkName}'s` : ""} Fleet Leaders`}
            </Typography>
            <Typography className="text-neutral-dark-gray">{`${networkName ?? ""} list of Fleet Leaders`}</Typography>
          </div>
          {renderSearchInput()}
        </header>
        {isFetchingFleetLeaders ? (
          <Skeleton />
        ) : (
          <div className="mt-4">
            <PrivateNetworkFleetLeadersGrid operatorName={networkName} items={items} isEmpty={isFleetLeadersEmpty} />
            <PrivateNetworkFleetLeadersGrid.Pagination />
          </div>
        )}
      </Panel>
    </section>
  );
};
