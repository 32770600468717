import { FixedPricingContextProvider, FixedPricingLocationContextProvider, PricingLocationPanel } from "@/components/organisms";
import { AddLocation } from "./AddLocation";
import { LocationBased } from "./LocationBased";

export const PricingLocation = () => {
  return (
    <FixedPricingContextProvider>
      <FixedPricingLocationContextProvider>
        <PricingLocationPanel />
      </FixedPricingLocationContextProvider>
    </FixedPricingContextProvider>
  );
};

PricingLocation.AddLocation = AddLocation;
PricingLocation.LocationBased = LocationBased;
