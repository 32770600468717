import { DataTableColumnDef } from "@/components/molecules";
import { ArchivedVehicleTableData } from "./types";

export const archivedVehiclesColumns: DataTableColumnDef<ArchivedVehicleTableData>[] = [
  {
    header: "Year",
    accessorKey: "year",
    size: 100,
  },
  {
    header: "Make",
    accessorKey: "make",
    cell: (props) => props.row.original.make,
    size: 150,
  },
  {
    header: "Model",
    accessorKey: "model",
    size: 150,
  },
  {
    header: "Number Plate",
    id: "registration_number",
    accessorKey: "numberPlate",
    cell: (props) => props.row.original.numberPlate,
    size: 150,
  },
  {
    header: "Nickname",
    size: 150,
    id: "identifier",
    accessorKey: "nickname",
  },
  {
    header: "Actions",
    id: "actions",
    cell: (props) => props.row.original.actions,
    maxSize: 75,
  },
];
