import { Alert, Button, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { addToast } from "@/utils";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useEffect, useState } from "react";
import { useUpdateOffloadingConfigurationMutation } from "@/redux/apis/addOn/addOnApi";
import { addOnItemSelector } from "@/redux/slices/addOn/selectors";
import { renderAddOnCost, renderAddOnIcon, renderAddOnLockIcon, renderPostFreeTrialInfo, renderPreFreeTrialInfo } from "../helpers";
import { useProfile } from "@/hooks";
import OffloadingPreview from "@/assets/images/add-ons/offloading-preview.png";
import { OffloadingEdit } from "./OffloadingEdit";
import { OffloadingDetails } from "./OffloadingDetails";
import { addOnsModalDescription } from "../fixtures";
import { AddOnDisableModal } from "../modals/AddOnDisableModal";
import { AddOnSaveModal } from "../modals/AddOnSaveModal";
import { OffloadingConfiguration } from "@/redux/slices/addOn/types";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formSchema } from "./fixtures";
import { isEmpty } from "ramda";
import { AddOnDisableErrorModal } from "../modals/AddOnDisableErrorModal";
import { useToggleAddOn } from "../hooks/useToggleAddOn";
import { RawErrorResponse } from "@/redux/types";

interface OffloadingModalProps {
  open: boolean;
  onClose: () => void;
  isAdmin: boolean;
}

export const OffloadingModal = ({ open, onClose, isAdmin }: OffloadingModalProps) => {
  const { dateFormat } = useProfile();
  const offloading = useAppSelector(addOnItemSelector("offloading"));
  const configuration = offloading?.configuration as OffloadingConfiguration | undefined;
  const [updateOffloadingConfiguration, { isLoading: isLoadingUpdate }] = useUpdateOffloadingConfigurationMutation();
  
  const [modal, setModal] = useState<"disable" | "save" | "disableError" | undefined>(undefined);
  const [action, setAction] = useState<"edit" | undefined>(undefined);

  const methods = useForm<OffloadingConfiguration>({
    resolver: zodResolver(formSchema),
    defaultValues: configuration,
  });
  const { reset, handleSubmit, formState: { isDirty, errors } } = methods;

  const { toggleAddOn, isLoading } = useToggleAddOn({ 
    onEnableSuccess: () => addToast("success", "Offloading has been successfully enabled."),
    onDisableSuccess: () => {
      addToast("info", "Offloading has been disabled.");
      setModal(undefined);
      onClose();
    }, 
    onEnableError: (e: RawErrorResponse) => getErrorMessages(e).forEach((message) => addToast("danger", message)),
    onDisableError: () => setModal("disableError"),
    shouldUpdateOperator: false,
  });

  useEffect(() => {
    reset(configuration);
  }, [configuration, reset]);

  useEffect(() => {
    if (!isEmpty(errors)) setModal(undefined);
  }, [errors]);

  const handleClose = () => {
    if (isDirty) setModal("save");
    else onClose();
  };

  const onSave = handleSubmit((data) => {
    const {
      enabledAutomaticDispatchPrivateNetwork,
      dispatchAutoPrivateNetworkTime,
      dispatchAutoPrivateNetworkTimeframe,
      enabledAutomaticDispatchPublicNetwork,
      dispatchAutoPublicNetworkTime,
      dispatchAutoPublicNetworkTimeframe,
    } = data;

    updateOffloadingConfiguration({
      enabled_automatic_dispatch_private_network: enabledAutomaticDispatchPrivateNetwork,
      dispatch_auto_private_network_time: dispatchAutoPrivateNetworkTime,
      dispatch_auto_private_network_timeframe: dispatchAutoPrivateNetworkTimeframe,
      enabled_automatic_dispatch_public_network: enabledAutomaticDispatchPublicNetwork,
      dispatch_auto_public_network_time: dispatchAutoPublicNetworkTime,
      dispatch_auto_public_network_timeframe: dispatchAutoPublicNetworkTimeframe,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Offloading");
        setAction(undefined);
        setModal(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  });

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Add-ons
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleClose} />
    </div>
  );

  const renderButton = () => {
    if (offloading && offloading.available) {
      if (isAdmin) {
        if (offloading.enabled) return <Button variant="secondary" size="lg" startIcon="MinusCircle" iconVariant="Bold" iconClassName="text-danger" className="w-full mt-8" onClick={() => setModal("disable")}>Disable Offloading</Button>;
        else return (
          <div className="relative mt-8">
            {isLoading && <Loading />}
            <Button variant="primary" size="lg" className="w-full" onClick={() => toggleAddOn("offloading", true)}>Enable Offloading</Button>
          </div>
        );
      } else return <Alert type="info" message={`Request access from your admin for offloading.`} className="w-full mt-4" />;
    }
  };

  const renderModals = () => (offloading && (
    <>
      <AddOnDisableModal id="offloading" name={offloading.name} open={modal === "disable"} onClose={() => setModal(undefined)} onDisable={() => toggleAddOn("offloading", false)} isLoading={isLoading} />
      <AddOnSaveModal open={modal === "save"} onClose={() => setModal(undefined)} onSave={onSave} isLoading={isLoadingUpdate} />
      <AddOnDisableErrorModal 
        open={modal === "disableError"} 
        onClose={() => setModal(undefined)} 
      />
    </>
  ));
  
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="mt-3">
        {renderPreFreeTrialInfo(offloading)}
        <div className="flex flex-col md:flex-row md:items-center bg-neutral-surface-gray rounded-lg gap-y-2.5 py-2.5 pl-2.5 pr-5 mt-3">
          <div className="flex flex-1 items-center flex-row gap-x-4">
            <div className="flex items-center justify-center bg-primary-dark rounded-md p-2.5">
              {renderAddOnIcon("offloading")}
            </div>
            <Typography variant="h3">Offloading</Typography>
          </div>
          <div className="flex flex-col items-start sm:items-end">
            {renderAddOnCost(offloading)}
          </div>
        </div> 
        {offloading?.enabled ? (
          action === "edit" ? (
            <FormProvider {...methods}>
              <OffloadingEdit onSubmit={onSave} onClose={() => setAction(undefined)} isLoading={isLoadingUpdate} />
            </FormProvider>
          ) : <OffloadingDetails onEdit={() => setAction("edit")} configuration={configuration} />
        ) : (
          <div className="relative bg-neutral-gray rounded-lg w-full mt-4 px-[25px] pb-[30px]">
            {renderAddOnLockIcon(false)}
            <img src={OffloadingPreview} />
          </div>
        )}
        <Typography variant="paragraph" className="mt-4">{addOnsModalDescription["offloading"]}</Typography>
        {renderPostFreeTrialInfo(dateFormat, offloading)}
        {renderButton()}
      </div>
      {renderModals()}
    </Modal>
  );
};
