import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpClientFormData, SignUpDriverFormData, SignUpInviteFormData, SignUpOperatorFormData } from "../types";

interface EmailFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

export const EmailField = ({ disabled = false, className, ...props }: EmailFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SignUpDriverFormData | SignUpOperatorFormData | SignUpClientFormData | SignUpInviteFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Email</Typography>
      <TextInput
        className="w-full"
        hasError={!!errors.email}
        placeholder="your.name@email.com"
        maxLength={100}
        type="email"
        disabled={disabled}
        {...register("email")}
      />
      <ErrorMessage errors={errors} name="email" />
    </div>
  );
};
