import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AddOnTextInput, Button, Divider, IconButton, Spinner, Typography, Modal } from "@/components/atoms";
import { IntervalRow } from "@/components/molecules";
import { usePrompt } from "@/hooks/usePrompt";
import { useUpdateDailyHireMutation } from "@/redux/apis/config/pricing/dailyHire";
import { DailyHireInterval } from "@/redux/slices/pricing/types";
import { addToast } from "@/utils";
import { modals } from "./fixtures";
import { DailyHireFormData } from "./types";

interface DailyHireEditorProps {
  basePrice: DailyHireInterval;
  intervals: Array<DailyHireInterval>;
  currency: string;
  currencySymbol: string;
}

export const DailyHireEditor = ({ basePrice, intervals, currency, currencySymbol }: DailyHireEditorProps) => {
  const navigate = useNavigate();
  const [updateDailyHire, { isLoading }] = useUpdateDailyHireMutation();
  const [activeModal, setActiveModal] = useState<"exit" | "delete" | "error" | null>(null);
  const [pendingDelete, setPendingDelete] = useState<number | null>(null);

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<DailyHireFormData>({
    defaultValues: {
      basePrice,
      intervals,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "intervals",
  });

  const handleAddRow = () => {
    append({
      minimumRange: fields.length > 0 ? fields[fields.length - 1].minimumRange + 1 : basePrice.minimumRange + 1,
      rate: 0,
    });
  };

  usePrompt({ when: isDirty && activeModal !== "exit", message: "Changes will not be saved. Do you want to proceed?" });

  const handleConfirmDelete = (i: number) => {
    setPendingDelete(i);
    setActiveModal("delete");
  };

  const handleConfirmCancel = () => {
    navigate(-1);
  };

  const onSubmit = handleSubmit((data) => {
    updateDailyHire({
      base_interval: {
        minimum_range: data.basePrice.minimumRange,
        rate: data.basePrice.rate * 100,
      },
      intervals: data.intervals.map((i) => ({
        minimum_range: i.minimumRange,
        rate: i.rate * 100,
      })),
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Daily Hire");
        navigate(-1);
      })
      .catch(() => setActiveModal("error"));
  });

  const renderRows = () => {
    return fields.map((interval, i) => {
      return (
        <IntervalRow
          key={interval.id}
          label={`Interval ${i + 1}`}
          renderRange={() => (
            <div className="flex flex-1 items-center gap-x-4">
              <Typography variant="small" className="leading-6 text-neutral-dark-gray">
                Minimum Days
              </Typography>
              <div>
                <AddOnTextInput
                  endAddOn="Days"
                  type="number"
                  className="w-[125px] [&_input]:text-right"
                  step="1"
                  min="0"
                  max="999"
                  required
                  {...register(`intervals.${i}.minimumRange`)}
                />
              </div>
            </div>
          )}
          renderRate={() => (
            <div className="flex items-center gap-x-2">
              <div className="flex items-center gap-x-4">
                <Typography variant="small" className="leading-6 text-neutral-dark-gray">
                  Price per day
                </Typography>
                <div>
                  <AddOnTextInput
                    startAddOn={`${currency} ${currencySymbol}`}
                    type="number"
                    step="0.01"
                    min="0"
                    max="999999999"
                    required
                    {...register(`intervals.${i}.rate`)}
                    className="w-[170px]"
                  />
                </div>
              </div>
              <IconButton
                onClick={() => handleConfirmDelete(i)}
                iconName="Trash"
                variant="tertiary"
                className="!text-danger hover:bg-danger-light"
              />
            </div>
          )}
        />
      );
    });
  };

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    const handleDeleteRow = () => {
      if (pendingDelete === null) return;
      remove(pendingDelete);
      setActiveModal(null);
    };

    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteRow} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmCancel} className="bg-primary px-8 hover:bg-primary-dark">
              Discard Changes
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  return (
    <>
      <div className="flex items-center">
        <div className="flex-1">
          <Typography variant="h3" className="leading-8">
            Daily Hire Fees
          </Typography>
          <Typography className="text-neutral-dark-gray">Set the pricing for the daily hire bookings</Typography>
        </div>
        <div className="flex-shrink-0 ">
          <div className="flex items-center gap-4">
            <Button
              onClick={() => {
                isDirty ? setActiveModal("exit") : handleConfirmCancel();
              }}
              variant="secondary"
              className="px-6"
            >
              Cancel
            </Button>
            <Button type="submit" className="bg-primary px-6 hover:bg-primary-dark" form="daily-hire-form" disabled={!isDirty}>
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="relative mt-4">
        {renderModal()}
        {isLoading && (
          <div className="absolute flex h-full w-full items-center justify-center bg-white/90">
            <Spinner className="h-8 w-8" />
          </div>
        )}
        <form onSubmit={onSubmit} id="daily-hire-form">
          <IntervalRow
            label="Minimum Time"
            renderRange={() => (
              <div className="flex flex-1 items-center gap-x-4">
                <Typography variant="small" className="leading-6 text-neutral-dark-gray">
                  Minimum Days
                </Typography>
                <div>
                  <AddOnTextInput
                    endAddOn="Days"
                    type="number"
                    step="1"
                    min="0"
                    max="999"
                    className="w-[125px] [&_input]:text-right"
                    {...register("basePrice.minimumRange")}
                    required
                    disabled
                  />
                </div>
              </div>
            )}
            renderRate={() => (
              <div className="flex items-center gap-x-2">
                <div className="flex items-center gap-x-4">
                  <Typography variant="small" className="leading-6 text-neutral-dark-gray">
                    Price per day
                  </Typography>
                  <div>
                    <AddOnTextInput
                      startAddOn={`${currency} ${currencySymbol}`}
                      type="number"
                      step="0.01"
                      min="0"
                      max="999999999"
                      {...register("basePrice.rate")}
                      required
                      className="w-[170px]"
                    />
                  </div>
                </div>
              </div>
            )}
          />
          <Divider className="my-6" />
          <div className="space-y-4">{renderRows()}</div>
          <div className="mt-4">
            <Button
              variant="tertiary"
              startIcon="Add"
              className="w-full justify-center bg-primary-light text-primary"
              size="xl"
              onClick={handleAddRow}
            >
              Add New Interval
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
