import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TextInput, Typography } from "@/components/atoms";
import { useBookingFilters } from "../../../hooks/useBookingFilters";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";

const schema = z.object({
  passenger: z.string(),
});

type FormData = z.infer<typeof schema>;

export const PassengerFilter = () => {
  const {
    filters: { passenger },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => passenger, [passenger]);

  const onClose = () => setFilter("passenger", undefined);

  return (
    <FilterPopover name="passenger" value={value} label="Passenger" onClose={onClose}>
      <PassengerFilterForm />
    </FilterPopover>
  );
};

interface PassengerFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const PassengerFilterDrawer = ({ open, onOpenChange }: PassengerFilterDrawerProps) => {
  const {
    filters: { passenger },
    setFilter,
  } = useBookingFilters();

  const value = useMemo(() => passenger, [passenger]);

  const onClose = () => setFilter("passenger", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Passenger" onClose={onClose}>
      <PassengerFilterForm />
    </FilterDrawer>
  );
};

const PassengerFilterForm = () => {
  const close = useClose();
  const { setFilter, filters } = useBookingFilters();

  const { watch, register } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { passenger: filters.passenger ? filters.passenger : "" },
  });

  const value = watch("passenger");

  useDebouncedEffect(
    () => {
      setFilter("passenger", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Search Passenger</Typography>
      <TextInput {...register("passenger")} size="md" iconName="SearchNormal1" placeholder="Search Passenger" />
    </form>
  );
};
