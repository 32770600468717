import { clsx } from "@/utils";
import Input from "react-otp-input";
import { TextInput } from "../TextInput/TextInput";

export interface OTPInputProps {
  value: string;
  onChange: (value: string) => void;
  numInputs?: number;
  className?: string;
  inputClassName?: string;
}

export const OTPInput = ({ className, inputClassName, value, onChange, numInputs = 6, ...props }: OTPInputProps) => {
  return (
    <div className={className}>
      <Input
        shouldAutoFocus
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        renderInput={(props) => <TextInput {...props} className={clsx("!w-[46px]", inputClassName)} />}
        containerStyle={{ gap: "0.75rem" }}
        {...props}
      />
    </div>
  );
};
