import BronzeCoupon from "@/assets/images/coupon-bronze.svg";
import GoldCoupon from "@/assets/images/coupon-gold.svg";
import SilverCoupon from "@/assets/images/coupon-silver.svg";
import { clsx } from "@/utils";
import { Typography } from "../Typography/Typography";

export interface CouponProps extends React.HTMLAttributes<HTMLDivElement> {
  type: "gold" | "silver" | "bronze";
  discount: number;
  minimumPurchase: number;
}

const couponImages = {
  gold: GoldCoupon,
  silver: SilverCoupon,
  bronze: BronzeCoupon,
};

export const Coupon = ({ type, discount, minimumPurchase, className, ...props }: CouponProps) => {
  return (
    <div className={clsx("relative flex aspect-coupon min-w-[363px] shadow", className)} {...props}>
      <img src={couponImages[type]} alt="coupon" className="pointer-events-none absolute w-full" />
      <div className="relative flex w-[27.82%] flex-col items-center justify-center text-white">
        <Typography variant="h2" className="leading-6">
          {discount}%
        </Typography>
        <Typography variant="action" className="leading-4">
          off
        </Typography>
      </div>
      <div className="relative flex w-[58.18%] flex-col justify-center p-5">
        <Typography variant="title">{discount}% discount</Typography>
        <Typography className="leading-5">
          If you order <span className="text-info">{minimumPurchase}</span> stickers of more!
        </Typography>
      </div>
    </div>
  );
};
