import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, ProfilePasswordForm } from "@/components/organisms";
import { useLayout } from "@/hooks";

export const ProfileChangePassword = () => {
  const navigate = useNavigate();
  const { setFullScreen } = useLayout();

  useEffect(() => {
    setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    setFullScreen(false);
    navigate("../../profile");
  };

  return (
    <div className="flex flex-row min-h-screen overflow-hidden">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        <Container className="flex flex-1 flex-col w-full max-w-[1040px] gap-5 pt-3 md:pt-[30px] pb-8 md:pb-[100px] px-5">
          <Button startIcon="ArrowLeft" variant="tertiary" size="md" className="flex self-start mb-[22px]" onClick={handleBack}>
            Back
          </Button>
          <div className="self-center w-full max-w-[440px]">
            <ProfilePasswordForm />
          </div>
        </Container>
        <GeneralFooter />
      </div>
    </div>
  );
};
