import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { cancellationFeesApi } from "@/redux/apis/config/pricing/cancellationFees";
import { costExtrasApi } from "@/redux/apis/config/pricing/costExtras";
import { dailyHireApi } from "@/redux/apis/config/pricing/dailyHire";
import { hourlyHireApi } from "@/redux/apis/config/pricing/hourlyHire";
import { timeOfDayApi } from "@/redux/apis/config/pricing/timeOfDay";
import { transferTypesApi } from "@/redux/apis/config/pricing/transferTypes";
import { tripCostApi } from "@/redux/apis/config/pricing/tripCost";
import { unscheduledStopsApi } from "@/redux/apis/config/pricing/unscheduledStops";
import { vehicleCategoriesApi } from "@/redux/apis/config/vehicle/vehicleCategories";
import { ErrorResponse } from "@/redux/types";
import { initialState } from "./fixtures";
import { PricingConfigState } from "./types";

export const pricingReducers = (builder: ActionReducerMapBuilder<PricingConfigState>) => {
  builder
    .addMatcher(tripCostApi.endpoints.getDynamicPricingStates.matchFulfilled, (state, { payload }) => {
      const { pricingStates } = payload.data;
      state.tripCost.dynamicPricing.pricingStates = pricingStates;
    })
    .addMatcher(tripCostApi.endpoints.getDynamicPricingStates.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.tripCost.errors = [...state.tripCost.errors, detail];
    })
    .addMatcher(tripCostApi.endpoints.getPricingProfiles.matchFulfilled, (state, { payload }) => {
      const { pricingProfiles } = payload.data;
      state.tripCost.fixedPricing.pricingProfiles = pricingProfiles;
    })
    .addMatcher(tripCostApi.endpoints.getPricingProfiles.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.tripCost.errors = [...state.tripCost.errors, detail];
    })
    .addMatcher(tripCostApi.endpoints.getNationwideFixedPricing.matchFulfilled, (state, { payload }) => {
      const { nationwidePricing } = payload.data;
      state.tripCost.fixedPricing.nationwidePricing = nationwidePricing;
    })
    .addMatcher(tripCostApi.endpoints.getNationwideFixedPricing.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      (state.tripCost.fixedPricing.nationwidePricing = initialState.tripCost.fixedPricing.nationwidePricing),
        (state.tripCost.errors = [...state.tripCost.errors, detail]);
    })
    .addMatcher(tripCostApi.endpoints.getFixedPricingStates.matchFulfilled, (state, { payload }) => {
      const { pricingStates } = payload.data;
      state.tripCost.fixedPricing.pricingStates = pricingStates;
    })
    .addMatcher(tripCostApi.endpoints.getFixedPricingStates.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.tripCost.errors = [...state.tripCost.errors, detail];
    })
    .addMatcher(tripCostApi.endpoints.getFixedPricingLocations.matchFulfilled, (state, { payload }) => {
      const { pricingLocations } = payload.data;
      state.tripCost.fixedPricing.pricingLocations = pricingLocations;
    })
    .addMatcher(tripCostApi.endpoints.getFixedPricingLocations.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.tripCost.errors = [...state.tripCost.errors, detail];
    })
    .addMatcher(costExtrasApi.endpoints.getCostExtras.matchFulfilled, (state, { payload }) => {
      const { items, page } = payload.data;
      state.costExtras.items = items;
      state.costExtras.page = page;
    })
    .addMatcher(costExtrasApi.endpoints.getCostExtras.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.costExtras.errors = [...state.costExtras.errors, detail];
    })
    .addMatcher(vehicleCategoriesApi.endpoints.getVehicleCategories.matchFulfilled, (state, { payload }) => {
      const { items, page } = payload.data;
      state.vehicleCategories.items = items.slice().sort((a, b) => a.order - b.order);
      state.vehicleCategories.page = page;
    })
    .addMatcher(vehicleCategoriesApi.endpoints.getVehicleCategories.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.vehicleCategories.errors = [...state.vehicleCategories.errors, detail];
    })
    .addMatcher(vehicleCategoriesApi.endpoints.deleteVehicleCategory.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.vehicleCategories.errors = [...state.vehicleCategories.errors, detail];
    })
    .addMatcher(vehicleCategoriesApi.endpoints.reorderVehicleCategories.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.vehicleCategories.errors = [...state.vehicleCategories.errors, detail];
    })
    .addMatcher(vehicleCategoriesApi.endpoints.getVehicleList.matchFulfilled, (state, { payload }) => {
      const { list } = payload.data;
      state.vehicleCategories.list = list;
    })
    .addMatcher(vehicleCategoriesApi.endpoints.getVehicleList.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.vehicleCategories.errors = [...state.vehicleCategories.errors, detail];
    })
    .addMatcher(timeOfDayApi.endpoints.getTimeOfDayMultipliers.matchFulfilled, (state, { payload }) => {
      const { items, page } = payload.data;
      state.timeAndDay.items = items;
      state.timeAndDay.page = page;
    })
    .addMatcher(timeOfDayApi.endpoints.getTimeOfDayMultipliers.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.timeAndDay.errors = [...state.timeAndDay.errors, detail];
    })
    .addMatcher(timeOfDayApi.endpoints.deleteTimeOfDayMultiplier.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.timeAndDay.errors = [...state.timeAndDay.errors, detail];
    })
    .addMatcher(timeOfDayApi.endpoints.reorderTimeOfDay.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.timeAndDay.errors = [...state.timeAndDay.errors, detail];
    })
    .addMatcher(transferTypesApi.endpoints.getTransferTypes.matchFulfilled, (state, { payload }) => {
      const { items, page } = payload.data;
      state.transferTypes.items = items;
      state.transferTypes.page = page;
    })
    .addMatcher(transferTypesApi.endpoints.getTransferTypes.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.transferTypes.errors = [...state.transferTypes.errors, detail];
    })
    .addMatcher(transferTypesApi.endpoints.deleteTransferType.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.transferTypes.errors = [...state.transferTypes.errors, detail];
    })
    .addMatcher(transferTypesApi.endpoints.reorderTransferType.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.transferTypes.errors = [...state.transferTypes.errors, detail];
    })
    .addMatcher(cancellationFeesApi.endpoints.getAsapPricing.matchFulfilled, (state, { payload }) => {
      const { asapPricing } = payload.data;
      state.cancellationFees.asapPricing = asapPricing;
    })
    .addMatcher(cancellationFeesApi.endpoints.getAsapPricing.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.cancellationFees.errors = [...state.cancellationFees.errors, detail];
    })
    .addMatcher(cancellationFeesApi.endpoints.getStandardPricing.matchFulfilled, (state, { payload }) => {
      const { standardPricing } = payload.data;
      state.cancellationFees.standardPricing = standardPricing;
    })
    .addMatcher(cancellationFeesApi.endpoints.getStandardPricing.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.cancellationFees.errors = [...state.cancellationFees.errors, detail];
    })
    .addMatcher(hourlyHireApi.endpoints.getHourlyHire.matchFulfilled, (state, { payload }) => {
      const { pricing } = payload.data;
      state.hourlyHire.pricing = pricing;
    })
    .addMatcher(hourlyHireApi.endpoints.getHourlyHire.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.hourlyHire.errors = [...state.hourlyHire.errors, detail];
    })
    .addMatcher(hourlyHireApi.endpoints.getHourlyHireMinimum.matchFulfilled, (state, { payload }) => {
      const { minimumBookingPeriodMinutes } = payload.data;
      state.hourlyHire.minimumBookingPeriodMinutes = minimumBookingPeriodMinutes;
    })
    .addMatcher(hourlyHireApi.endpoints.getHourlyHireMinimum.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.hourlyHire.errors = [...state.hourlyHire.errors, detail];
    })
    .addMatcher(dailyHireApi.endpoints.getDailyHire.matchFulfilled, (state, { payload }) => {
      const { pricing } = payload.data;
      state.dailyHire.pricing = pricing;
    })
    .addMatcher(dailyHireApi.endpoints.getDailyHire.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.dailyHire.errors = [...state.dailyHire.errors, detail];
    })
    .addMatcher(dailyHireApi.endpoints.getDailyHireMinimum.matchFulfilled, (state, { payload }) => {
      const { minimumBookingPeriodMinutes } = payload.data;
      state.dailyHire.minimumBookingPeriodMinutes = minimumBookingPeriodMinutes;
    })
    .addMatcher(dailyHireApi.endpoints.getDailyHireMinimum.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.dailyHire.errors = [...state.dailyHire.errors, detail];
    })
    .addMatcher(unscheduledStopsApi.endpoints.getUnscheduledStops.matchFulfilled, (state, { payload }) => {
      const { details } = payload.data;
      state.unscheduledStops.details = details;
    })
    .addMatcher(unscheduledStopsApi.endpoints.getUnscheduledStops.matchRejected, (state, { payload }) => {
      if (!payload?.data) return;

      const { detail } = payload.data as ErrorResponse;
      state.unscheduledStops.errors = [...state.unscheduledStops.errors, detail];
    });
};
