import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithAccount } from "@/redux/baseQuery";
import { transformOperators, transformOperator } from "./helpers";
import { GetOperatorsResponse, GetOperatorResponse } from "./types";

export const operatorApi = createApi({
  reducerPath: "operatorApi",
  baseQuery: baseQueryWithAccount("providers"),
  endpoints: (builder) => ({
    getOperators: builder.query<GetOperatorsResponse, void>({
      query: () => "?has_permissions=true&page_size=50",
      transformResponse: transformOperators,
      providesTags: ["Operators"],
    }),
    getOperator: builder.query<GetOperatorResponse, string>({
      query: (id) => `/${id}`,
      transformResponse: transformOperator,
    }),
  }),
  tagTypes: ["Operators"],
});

export const { useLazyGetOperatorsQuery, useLazyGetOperatorQuery } = operatorApi;
