import { Link } from "react-router-dom";
import { Icon, Typography } from "@/components/atoms";
import { ProfileDetail as Detail } from "@/components/molecules";
import { useAppSelector } from "@/redux/hooks";
import { profileSelector } from "@/redux/slices/profile/selectors";

export const ProfileDetails = () => {
  const profile = useAppSelector(profileSelector);

  const { title, firstName, lastName, mobile, mobileVerified, email, emailVerified, timezone, notificationEmail, dateFormat, timeFormat, passwordChangeRequired } =
    profile;

  return (
    <div className="flex flex-col gap-7 bg-white py-8 pl-8 pr-6 lg:pl-14">
      <Detail label="Title">
        <Typography variant="action">{title}</Typography>
      </Detail>
      <Detail label="First Name">
        <Typography variant="action">{firstName}</Typography>
      </Detail>
      <Detail label="Last Name">
        <Typography variant="action">{lastName}</Typography>
      </Detail>
      <Detail label="Mobile Number" icon={!mobileVerified ? <Icon name="InfoCircle" variant="Bold" className="text-danger" /> : undefined}>
        <Typography variant="action">{mobile}</Typography>
        {!mobileVerified && (
          <Link to="verify-phone" className="ml-2">
            <Typography variant="action" className="text-info">
              Verify via Mobile Number
            </Typography>
          </Link>
        )}
      </Detail>
      <Detail
        label="Login Details"
        description="This will be your username login details (Email)."
        icon={!emailVerified ? <Icon name="InfoCircle" variant="Bold" className="text-danger" /> : undefined}
      >
        <Typography variant="action">{email}</Typography>
        {!emailVerified && (
          <Link to="verify-email" className="ml-2">
            <Typography variant="action" className="text-info">
              Verify via Email
            </Typography>
          </Link>
        )}
      </Detail>
      <Detail label="Change Password" icon={passwordChangeRequired ? <Icon name="InfoCircle" variant="Bold" className="text-danger" /> : undefined}>
        <Link to="change-password" className="ml-2 flex flex-row items-center gap-2">
          <Icon name="Lock1" variant="Bold" className="text-info" size="sm" />
          <Typography variant="action" className="text-info">
            Change Password
          </Typography>
        </Link>
      </Detail>
      <Detail label="Notification Email" description="This email will be used for all booking notifications.">
        <Typography variant="action">{notificationEmail}</Typography>
      </Detail>
      <Detail label="Timezone">
        <Typography variant="action">{`(GMT ${timezone.diff}) ${timezone.name}`}</Typography>
      </Detail>
      <Detail label="Date Format">
        <Typography variant="action">{dateFormat}</Typography>
      </Detail>
      <Detail label="Time Format">
        <Typography variant="action">{timeFormat === "12H" ? "12-Hour Format" : "24-Hour Format"}</Typography>
      </Detail>
    </div>
  );
};
