import { Button, Loading, Modal, Typography } from "@/components/atoms";

interface AddOnSaveModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  isLoading?: boolean;
}

export const AddOnSaveModal = ({ open, onClose, onSave, isLoading }: AddOnSaveModalProps) => {  
  return (
    <Modal 
      open={open} 
      onClose={() => isLoading ? null : onClose()} 
      className="w-[80vw] md:w-[514px] lg:w-[514px]"
    >
      <div className="relative flex flex-col">
        {isLoading && <Loading />}
        <Typography variant="title">Save Changes?</Typography>
        <Typography variant="paragraph" className="mt-3">Do you want to save your changes before you exit?</Typography>
        <div className="flex flex-row items-center justify-end gap-x-4 mt-[22px]">
          <Button size="md" variant="secondary" onClick={onClose}>Cancel</Button>
          <Button size="md" variant="primary" onClick={onSave}>Save Changes</Button>
        </div>
      </div>
    </Modal>
  );
};
