import { useCallback, useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { useOperator, usePagination, useSorting } from "@/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { Page } from "@/redux/types";
import { archivedVehiclesColumns } from "./fixtures";
import { generateArchivedVehicleTableData } from "./helpers";

export interface ArchivedVehiclesTableProps {
  items: Vehicle[];
  setPendingReactivate: (vehicle: Vehicle) => void;
}

export const ArchivedVehiclesTable = ({ items, setPendingReactivate }: ArchivedVehiclesTableProps) => {
  const { id: operatorId } = useOperator();
  const { sorting, setSorting } = useSorting();

  const onReactivateClick = useCallback(
    (vehicle: Vehicle) => {
      setPendingReactivate(vehicle);
    },
    [setPendingReactivate]
  );

  const data = useMemo(
    () => generateArchivedVehicleTableData(operatorId, items, onReactivateClick),
    [items, onReactivateClick, operatorId]
  );

  return (
    <DataTable
      className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
      data={data}
      columns={archivedVehiclesColumns}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
};

const ArchivedVehiclesTablePagination = ({ page }: { page: Page }) => {
  const { current, size, total, count } = page;
  const { handlePageClick, handleSizeChange } = usePagination("archived-vehicles", 30);

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

ArchivedVehiclesTable.Pagination = ArchivedVehiclesTablePagination;
