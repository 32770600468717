import Booking404 from "@/assets/images/booking/booking-404.svg";
import { Container, Typography } from "@/components/atoms";
import { GeneralFooterOld, GeneralHeaderOld } from "@/components/organisms";
import { useBranding, useSiteConfig } from "@/hooks";

export const LinkExpired = () => {
  const { branding } = useSiteConfig();

  useBranding(branding);

  return (
    <div className="bg-transparent">
      <GeneralHeaderOld />
      <Container className="relative z-10 flex h-[calc(100vh-150px)] w-full max-w-[768px] flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-6 p-4">
          <img src={Booking404} className="-mt-12 h-[128px] w-[128px]  md:h-[256px] md:w-[256px]" />
          <Typography variant="h2">Page Not Found: Link Has Expired</Typography>
          <Typography className="max-w-full text-center">
            This link is no longer valid. Please reach out to the operator if you believe this to be a mistake, otherwise please double
            check the most recent email you have received and try again. Please note: if the job was removed and reassigned the unique link
            would have changed
          </Typography>
        </div>
      </Container>
      <GeneralFooterOld className="z-20" />
    </div>
  );
};
