import { Navigate } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { AppAction, AppSubject } from "@/casl/types";

export interface ConditionalAccessProps {
  action: AppAction;
  subject: AppSubject;
  truthyPath: string;
  falsyPath: string;
}

export const ConditionalAccess = ({ action, subject, truthyPath, falsyPath }: ConditionalAccessProps) => {
  const ability = useAppAbility();
  return <Navigate to={ability.can(action, subject) ? truthyPath : falsyPath} replace />;
};
