import { isEmpty } from "ramda";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { PageHeader, TimeAndDayTable } from "@/components/organisms";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetTimeOfDayMultipliersQuery } from "@/redux/apis/config/pricing/timeOfDay";
import { useAppSelector } from "@/redux/hooks";
import { timeAndDayPageSelector, timeAndDaySelector } from "@/redux/slices/pricing/selectors";
import { addToast } from "@/utils";
import { TimeAndDayEdit } from "./TimeAndDayEdit";

export const TimeAndDay = () => {
  const navigate = useNavigate();
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();
  const [getTimeOfDayMultipliers, { isFetching }] = useLazyGetTimeOfDayMultipliersQuery();
  const items = useAppSelector(timeAndDaySelector);
  const { current, count, total, size } = useAppSelector(timeAndDayPageSelector);

  useEffect(() => {
    getTimeOfDayMultipliers({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getTimeOfDayMultipliers]);

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <Panel>
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Time & Day (Multiplier)
            </Typography>
            <Typography className="text-neutral-dark-gray">Configure the Time & Day Multiplier for each trip.</Typography>
          </div>
          <div className="flex items-center">
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {isEmpty(items) ? (
          <EmptyState title="Time & Day Multipliers" description="There are currently no time & day multipliers available" />
        ) : (
          <TimeAndDayTable className="mt-4" items={items} />
        )}
      </Panel>
      <Pagination
        onPageClick={handlePageClick}
        onSizeChange={handleSizeChange}
        currentPage={current}
        pageSize={size}
        totalItems={total}
        pageCount={count}
      />
    </>
  );
};

TimeAndDay.Edit = TimeAndDayEdit;
