import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const KeyContactNameField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography>Name</Typography>
      <TextInput
        className="w-full"
        type="text"
        hasError={!!errors.keyContactName}
        placeholder="Enter Name"
        maxLength={100}
        {...register("keyContactName")}
      />
      <ErrorMessage errors={errors} name="keyContactName" />
    </div>
  );
};
