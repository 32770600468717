import { Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface NewBookingNotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  children: string;
  visible?: boolean;
  onClose: () => void;
}

export const NewBookingNotification = ({ visible, children, className, onClose, ...props }: NewBookingNotificationProps) => {
  const transition = {
    "animate-in": visible,
    "animate-out": !visible,
  };

  return (
    <div className={clsx("ring-none cursor-pointer rounded-[30px] bg-info shadow", transition, className)} onClick={onClose} {...props}>
      <div className="flex place-items-center gap-2 p-2 px-4">
        <Icon name="Refresh2" className="text-white" />
        <Typography className="truncate leading-5 text-white">{children}</Typography>
      </div>
    </div>
  );
};
