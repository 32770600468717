import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBookingMutation } from "@/redux/apis/booking/bookingApi";
import { useAppDispatch } from "@/redux/hooks";
import { setAction } from "@/redux/slices/booking/bookingSlice";
import { addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

interface AllocateNewDriverProps {
  open: boolean;
  onClose: () => void;
}

export const AllocateNewDriver = ({ open, onClose }: AllocateNewDriverProps) => {
  const dispatch = useAppDispatch();
  const { id: bookingId } = useSelectedBooking();
  const [updateBooking, { isLoading }] = useUpdateBookingMutation();

  const handleConfirm = () => {
    updateBooking({ bookingId, driver_uuid: null })
      .unwrap()
      .then(() => {
        addToast("success", `Booking has been reopened to allocate new driver (J${bookingId})`);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleCloseBooking = () => {
    dispatch(setAction("close-job-no-cost"));
  };

  const renderButtons = () => {
    return (
      <div className="contents">
        <Button variant="secondary" onClick={handleCloseBooking} className="px-8">
          Close Booking with No Cost
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={isLoading}>
          Allocate New Driver
        </Button>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose} title="No Driver Available" renderButtons={renderButtons} className="w-[515px] !p-5">
      {isLoading && <Loading />}
      <Typography>The driver did not show up, what would you like to do next?</Typography>
    </Modal>
  );
};
