import { useMemo } from "react";
import { usePagination, useSearch, useSorting } from "@/hooks";
import { GetVehicleCategoriesParams } from "@/redux/apis/config/pricing/vehicleCategory/types";

export const useGetVehicleCategoriesParams = () => {
  const { page, pageSize } = usePagination("config-vehicle-categories", 25);
  const { sorting, setSorting, sortKey, direction } = useSorting();
  const [search, setSearch] = useSearch();

  const params: GetVehicleCategoriesParams = useMemo(
    () => ({
      page,
      page_size: pageSize,
      sort: sortKey,
      direction,
      search,
    }),
    [page, pageSize, sortKey, direction, search]
  );

  return {
    params,
    sorting,
    setSorting,
    sortKey,
    direction,
    search,
    setSearch,
  };
};
