import { OffloadDriver } from "@/redux/slices/driver/types";
import { ViewReasonModal } from "../modals/ViewReasonModal";
import { BlockedOffloadDriversStep } from "../types";

interface BlockedOffloadDriversModalsProps {
  step: BlockedOffloadDriversStep;
  setStep: (step: BlockedOffloadDriversStep) => void;
  driver?: OffloadDriver;
  setDriver: (driver?: OffloadDriver) => void;
}

export const BlockedOffloadDriversModals = ({ step, setStep, driver, setDriver }: BlockedOffloadDriversModalsProps) => {
  const onClose = () => {
    setStep(undefined);
    setDriver(undefined);
  };

  return (
    <>
      <ViewReasonModal open={step === "view-reason"} onClose={onClose} driver={driver} />
    </>
  );
};
