import { useFormContext } from "react-hook-form";
import { TextInput, Typography } from "@/components/atoms";
import { ClientsFilterFormData } from "../types";

export const SearchPhoneNumberFilter = () => {
  const { register } = useFormContext<ClientsFilterFormData>();

  return (
    <div className="rounded-lg bg-neutral-surface-gray p-5">
      <Typography variant="paragraph">Search Phone Number</Typography>
      <TextInput placeholder="Search Phone Number" iconName="SearchNormal1" size="md" {...register("phoneNumber")} />
    </div>
  );
};
