import { Operator } from "@/redux/slices/operator/types";
import { RawOperator } from "../operator/types";

export const transformOperator = (operator: RawOperator) => {
  const {
    provider_uuid,
    provider_key,
    domain,
    domain_driver,
    country,
    currency,
    bookable_country_list,
    available_account_types_on_register,
    email,
    joined,
    images,
    name,
    phone,
    primary,
    urls: { brochure, contact_us, privacy_policy, terms },
    branding: { primary_color, secondary_color, tertiary_color },
    deep_links,
    flags: { is_simple_signup_enabled },
  } = operator;

  const { logo, emblem, qr, qr_transparent } = images;

  return {
    id: provider_uuid,
    providerCode: provider_key.toLowerCase(),
    singleTenant: provider_key.toLowerCase() !== "rideminder",
    domain: domain.toLowerCase(),
    domainDriver: domain_driver.toLowerCase(),
    name,
    email,
    joined,
    urls: {
      brochure,
      privacy: privacy_policy,
      terms,
      contact: contact_us,
    },
    images: {
      logo,
      emblem,
      qr,
      qrTransparent: qr_transparent,
    },
    phone,
    primary,
    country,
    currency,
    bookableCountryList: bookable_country_list,
    availableAccountTypesOnRegister: available_account_types_on_register,
    branding: {
      primary: primary_color,
      dark: secondary_color,
      light: tertiary_color,
    },
    registerLinks: {
      driver: deep_links?.driver_registration ?? "",
      client: deep_links?.client_registration ?? "",
    },
    flags: {
      isSimpleSignupEnabled: is_simple_signup_enabled,
    },
  } as Operator;
};
