import { format, parseISO } from "date-fns";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Loading, Panel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useGetDebitRecordsQuery, useLazyGetDebitHistoryItemQuery } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { debitRecordsSelector } from "@/redux/slices/payment/selectors";
import { DebitHistoryItem } from "@/redux/slices/payment/types";
import { addToast, formatCurrency } from "@/utils";
import { DebitRecordsTable } from "./DebitRecordsTable";
import { useGetDebitRecordsParams } from "./hooks/useGetDebitRecordsParams";

export const DebitRecordsPanel = () => {
  const { dateFormat } = useProfile();
  const navigate = useNavigate();
  const { params } = useGetDebitRecordsParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isFetching: isFetchingDebitRecords } = useGetDebitRecordsQuery(params, { refetchOnMountOrArgChange: true });
  const [getDebitHistoryItem, { isFetching: isFetchingDebitHistoryItem }] = useLazyGetDebitHistoryItemQuery();
  const { items } = useAppSelector(debitRecordsSelector);
  const [selectedDebitHistoryItem, setSelectedDebitHistoryItem] = useState<DebitHistoryItem | undefined>(undefined);
  const isDebitRecordsEmpty = isEmpty(items) && !isFetchingDebitRecords;
  const { id } = params;

  useEffect(() => {
    if (id) {
      getDebitHistoryItem({ id })
        .unwrap()
        .then((res) => setSelectedDebitHistoryItem(res.data))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  }, [id, getDebitHistoryItem]);

  useEffect(() => {
    if (selectedDebitHistoryItem) {
      searchParams.set("creationDate", format(parseISO(selectedDebitHistoryItem.creationDate), dateFormat));
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDebitHistoryItem]);

  const renderTotalAmount = () => {
    if (selectedDebitHistoryItem) {
      const { amount, currency } = selectedDebitHistoryItem;
      if (amount && currency) {
        return (
          <Typography variant="paragraph">
            {currency} {formatCurrency(amount, 2, currency)}
          </Typography>
        );
      }
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        <Button startIcon="ArrowLeft" variant="tertiary" size="sm" onClick={() => navigate("../")}>
          Back
        </Button>
      </div>
      <Panel className="mt-5">
        <header className="flex">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Debit Records
            </Typography>
            <Typography className="text-neutral-dark-gray">Debit details</Typography>
          </div>
          <div className="relative flex min-w-[150px] flex-col rounded-lg bg-neutral-surface-gray p-2.5">
            {isFetchingDebitHistoryItem && <Loading className="rounded-lg" />}
            <Typography variant="paragraph" className="text-neutral-dark-gray">
              Total Amount
            </Typography>
            {renderTotalAmount()}
          </div>
        </header>
        {isDebitRecordsEmpty ? (
          <EmptyState title="Debit Records" description="There are currently no debit records available" />
        ) : isFetchingDebitRecords ? (
          <Skeleton />
        ) : (
          <DebitRecordsTable items={items} className="mt-4" />
        )}
      </Panel>
      {!isFetchingDebitRecords && <DebitRecordsTable.Pagination />}
    </div>
  );
};
