import cx from "classnames";
import { Link } from "react-router-dom";
import { Badge, Button, Icon, Typography, AlertType, Tooltip } from "@/components/atoms";

export type PageListProps = React.HTMLAttributes<HTMLDivElement>;
export interface PageListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  icon: string | number;
  title: string;
  to: string;
  description?: string;
  alert?: {
    type: AlertType;
    message: string;
  };
  locked?: boolean;
  managed?: boolean;
}

export const PageList = ({ className, children, ...props }: PageListProps) => {
  return (
    <div className={cx("mt-2 overflow-hidden", className)} {...props}>
      <ul role="list" className="divide-y divide-neutral-mid-gray">
        {children}
      </ul>
    </div>
  );
};

const PageListItem = ({ title, description, to, icon, alert, locked, managed, ...props }: PageListItemProps) => {
  return (
    <li {...props} role="listitem">
      {locked || managed ? (
        <div
          className={cx("group block", {
            "pointer-events-none cursor-not-allowed bg-neutral-gray": locked,
          })}
        >
          <div className="flex items-center px-4 py-5 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center">
              <div
                className={cx("flex-shrink-0 rounded-lg p-3", {
                  "bg-neutral-mid-gray": locked,
                  "bg-neutral-gray": managed,
                })}
              >
                {locked ? (
                  <Icon name="Lock1" variant="Bold" className="text-neutral-black" />
                ) : typeof icon === "number" ? (
                  <div className="flex h-6 w-6 items-center justify-center">
                    <Typography variant="title" className="text-neutral-dark-gray">
                      {icon}
                    </Typography>
                  </div>
                ) : (
                  <Icon name={icon} className="text-neutral-dark-gray" />
                )}
              </div>
              <div className="flex min-w-0 flex-1 px-4">
                <div className="flex-1">
                  <Typography variant="action" className={cx({ "text-neutral-dark-gray": locked })}>
                    {title}
                  </Typography>
                  <Typography variant="paragraph" className="text-neutral-dark-gray">
                    {description}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              {locked ? (
                <Button startIcon="ExportSquare" variant="tertiary" className="bg-transparent text-info">
                  Upgrade Your Plan
                </Button>
              ) : (
                <div className="flex flex-row gap-x-6">
                  <Tooltip
                    content="The pricing is managed by RideMinder. Please contact RideMinder to make changes"
                    placement="left"
                    maxWidth={300}
                  >
                    <span>
                      <Icon name="Lock1" variant="Bold" />
                    </span>
                  </Tooltip>
                  <Icon name="ArrowRight" className="h-6 w-6 text-neutral-black" aria-hidden="true" />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Link to={to} className="group block hover:bg-primary-light">
          <div className="flex items-center px-4 py-5 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center">
              <div className="flex-shrink-0 rounded-lg bg-primary-light p-3 group-hover:bg-primary">
                {typeof icon === "number" ? (
                  <div className="flex h-6 w-6 items-center justify-center">
                    <Typography variant="title" className="text-primary-dark group-hover:text-white">
                      {icon}
                    </Typography>
                  </div>
                ) : (
                  <Icon name={icon} className="text-primary-dark group-hover:text-white" />
                )}
              </div>
              <div className="flex min-w-0 flex-1 px-4">
                <div className="flex-1 group-hover:text-primary-dark">
                  <Typography variant="action">{title}</Typography>
                  <Typography variant="paragraph" className="text-neutral-dark-gray group-hover:text-primary">
                    {description}
                  </Typography>
                </div>
                <div className="hidden items-center justify-end md:flex">
                  {alert && <Badge type={alert.type} message={alert.message} isIconBadge />}
                </div>
              </div>
            </div>
            <Icon name="ArrowRight" className="h-6 w-6 text-neutral-black" aria-hidden="true" />
          </div>
        </Link>
      )}
    </li>
  );
};

PageList.Item = PageListItem;
