import LockIcon from "@/assets/images/lock.png";
import { Button, Container, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export const Restricted = ({ className }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      data-testid="restricted-page"
      className={clsx("bg-city flex flex-1 bg-contain bg-[center_bottom_-2rem] bg-no-repeat", className)}
    >
      <Container className="flex max-w-sm flex-col items-center pt-[14vh] px-5 pb-32">
        <img src={LockIcon} alt="Restricted Access" className="max-w-[128px]" />
        <Typography variant="title" className="mb-4 !text-[24px] sm:!text-[32px]">
          Restricted Access
        </Typography>
        <Typography variant="paragraph" className="text-center text-neutral-dark-gray">
          The page you&apos;re trying to access has restricted access. Please refer to your system administrator.
        </Typography>
        <Button onClick={() => history.back()} className="mt-8" size="lg">
          Go Back
        </Button>
      </Container>
      <div className="fixed bottom-0 bg-city min-h-[50%] w-full bg-cover bg-no-repeat pointer-events-none" />
    </div>
  );
};
