import { Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { companyApi } from "@/redux/apis";
import { useUpdateInvoiceStatusMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { addNotification, addToast } from "@/utils";

interface CancelInvoiceProps {
  onClose: () => void;
}

export const CancelInvoice = ({ onClose }: CancelInvoiceProps) => {
  const dispatch = useAppDispatch();
  const { selectedInvoice: invoice } = useAppSelector(invoicingPageStateSelector);
  const [updateInvoiceStatus, { isLoading }] = useUpdateInvoiceStatusMutation();
  const description =
    "Are you sure you want to cancel the draft for this invoice? This action will discard all changes and reset all jobs back to 'Uninvoiced Jobs'.";
    
  const handleCancelInvoice = () => {
    if (!invoice) return;
    updateInvoiceStatus({ id: invoice.id, status: "cancelled" })
      .unwrap()
      .then(() => {
        addNotification("info", `Invoice ${invoice.identifier}`, "Invoice has been updated to Cancelled");
        onClose();
        dispatch(companyApi.util.invalidateTags(["InvoicesCompanies"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <div className="relative">
      {isLoading && <Loading />}
      <div className="max-w-lg">
        <div className="flex flex-col gap-y-3">
          <Typography variant="title">Cancel Invoice</Typography>
          <Typography variant="paragraph">{description}</Typography>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="secondary" onClick={onClose} size="md">
          Close
        </Button>
        <Button variant="primary" onClick={handleCancelInvoice} size="md" className="bg-danger hover:bg-danger-dark">
          Cancel Invoice
        </Button>
      </div>
    </div>
  );
};
