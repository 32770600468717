import { DisclosurePanel } from "@/components/molecules";
import { clsx } from "@/utils";
import { AdminOnlyTransferTypeField } from "./fields/AdminOnlyTransferTypeField";
import { AutomatedDispatchEnabledField } from "./fields/AutomatedDispatchEnabledField";
import { CostMultiplierField } from "./fields/CostMultiplierField";
import { DescriptionField } from "./fields/DescriptionField";
import { NameField } from "./fields/NameField";
import { StatusField } from "./fields/StatusField";
import { TransferTypeTaxExemptField } from "./fields/TransferTypeTaxExemptField";
import { TreatAsSpecialEventField } from "./fields/TreatAsSpecialEventField";
import { TripTypeField } from "./fields/TripTypeField";

export const TransferTypeForm = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={clsx("flex flex-col gap-y-5", className)} {...props}>
      <NameField />
      <DescriptionField />
      <CostMultiplierField />
      <TreatAsSpecialEventField />
      <TripTypeField />
      <StatusField />
      <DisclosurePanel title="Advanced Settings" className="flex flex-col gap-y-5">
        <AdminOnlyTransferTypeField />
        <AutomatedDispatchEnabledField />
        <TransferTypeTaxExemptField />
      </DisclosurePanel>
    </div>
  );
};
