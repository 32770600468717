import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { GetJobDetailsRawResponse, GetJobDetailsParams, SubmitRatingRawResponse, SubmitRatingParams } from "./types";

export const jobRatingApi = createApi({
  reducerPath: "jobRatingApi",
  baseQuery: baseQueryWithoutProvider("guest/job"),
  endpoints: (builder) => ({
    getJobDetails: builder.query<GetJobDetailsRawResponse, GetJobDetailsParams>({
      query: ({ id, actionKey }) => `/${id}/rate/${actionKey}`,
    }),
    submitRating: builder.mutation<SubmitRatingRawResponse, SubmitRatingParams>({
      query: ({ id, actionKey, ...body }) => ({
        url: `/${id}/rate/${actionKey}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useLazyGetJobDetailsQuery, useSubmitRatingMutation } = jobRatingApi;
