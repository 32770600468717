import { Alert, Badge, Icon, Typography } from "@/components/atoms";
import { AddOnItem } from "@/redux/slices/addOn/types";
import { formatCurrency } from "@/utils";
import { format, isAfter, isEqual } from "date-fns";
import { addOnsIcon } from "./fixtures";

export const renderAddOnCost = (item?: AddOnItem) => {
  if (item) {
    const { cost, available } = item;
    let costDisplay = "";

    if (cost === 0) costDisplay = "Free Feature"; 
    else costDisplay = item.costType === "fixed" ? `${formatCurrency(item.cost, 2, item.currency)} per booking` : `${item.cost}% per booking`;

    if (available) {
      return (
        <div className="flex flex-row items-center text-primary gap-x-1.5">
          <Icon name="DollarCircle" variant="Bold" />
          <Typography variant="action">{costDisplay}</Typography>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row items-center text-primary gap-x-1.5">
          <Typography variant="action">Coming Soon</Typography>
        </div>
      );
    }
  }
};

export const renderAddOnIcon = (id?: string) => {
  if (id) {
    const icon = addOnsIcon[id];
    if (icon) return <Icon name={icon} size="xl" variant="Bold" className="text-white" />;
  }

  return <div className="w-6 h-6" />;
};

export const renderPreFreeTrialInfo = (item?: AddOnItem) => {
  if (item && item.freeTrialPeriod > 0 && !item.freeTrialExpiry) return <Alert type="info" message="Free trial available for 2 weeks after activation" className="mt-3" />;
};

export const renderPostFreeTrialInfo = (dateFormat: string, item?: AddOnItem) => {
  if (item) {
    const expiryDate = new Date(item.freeTrialExpiry);
    const currentDate = new Date();
    const isFreeTrialValid = item.freeTrialExpiry && (isEqual(expiryDate, currentDate) || isAfter(expiryDate, currentDate));

    if (isFreeTrialValid) return <Alert type="info" message={`Free through ${format(expiryDate, dateFormat)}`} className="mt-4" />;
  }
};

export const renderAddOnLockIcon = (enabled?: boolean) => {
  if (!enabled) {
    return (
      <div className="absolute flex items-center justify-center bg-primary-light border border-primary rounded-full p-[7px] right-5 top-5">
        <Icon name="Lock1" variant="Bold" size="sm" className="text-primary" />
      </div>
    );
  }
};

export const renderAddOnStatus = (enabled: boolean, isFreeTrialValid: boolean, expiryDate: Date, dateFormat: string) => {
  if (enabled) {
    return (
      <div className="flex flex-row items-center mt-1 gap-x-1.5 text-success">
        <Icon name="TickCircle" size="sm" variant="Bold" />
        <Typography variant="action">Active{isFreeTrialValid && ` (Free until ${format(expiryDate, dateFormat)})`}</Typography>
      </div>
    );     
  }

  return (
    <div className="flex flex-row items-center mt-1 gap-x-1.5 text-neutral-dark-gray">
      <Icon name="MinusCircle" size="sm" variant="Bold" />
      <Typography variant="action">Inactive</Typography>
    </div>
  );
};

export const renderAddOnBadge = (item: AddOnItem, isFreeTrialValid: boolean) => {
  const { available, recommended, freeTrialPeriod, freeTrialExpiry } = item;
  return (
    <div className="flex flex-row items-center gap-x-1">
      {!available && <Badge type="disabled" size="sm" message="Coming Soon" />}
      {recommended && <Badge type="warning" size="sm" message="Recommended" />}
      {(freeTrialPeriod > 0 && (!freeTrialExpiry || isFreeTrialValid)) && <Badge type="info" size="sm" message="Free Trial Available" />}
    </div>
  );
};