import { createContext, PropsWithChildren, useContext } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useGetTransferTypeQuery } from "@/redux/apis/config/pricing/transferTypes";
import { TransferType } from "@/redux/slices/vehicle/types";

export interface IEditTransferType {
  transferType: TransferType;
}
export const EditTransferTypeContext = createContext<IEditTransferType | null>(null);

export const EditTransferTypeProvider = ({ children }: PropsWithChildren) => {
  const { transferTypeId } = useParams<{ transferTypeId: string }>();

  const { data: transferType, isFetching } = useGetTransferTypeQuery(transferTypeId ?? "", { skip: !transferTypeId });
  if (isFetching || !transferType) return <Loading />;

  return <EditTransferTypeContext.Provider value={{ transferType }}>{children}</EditTransferTypeContext.Provider>;
};

export const useEditTransferTypeContext = () => {
  const context = useContext(EditTransferTypeContext);
  if (!context) {
    throw new Error("useEditTransferTypeContext must be used within an EditVehicleProvider");
  }
  return context;
};
