import { Icon, IconButton, Toggle, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { capitalize, formatCurrency } from "@/utils";
import { ExtrasTableData } from "./types";

export const generateColumns = (data: ExtrasTableData[]) => {
  const cols: DataTableColumnDef<ExtrasTableData>[] = [
    { id: "icon", size: 50, cell: (props) => props.row.original.icon },
    { accessorKey: "name", header: `Name (${data.length})`, cell: (props) => props.row.original.name },
    { accessorKey: "description", header: "Description", size: 600 },
    { accessorKey: "price", header: "Cost" },
    {
      accessorKey: "type",
      header: () => (
        <div className="flex flex-row items-center gap-2">
          <Typography variant="small">Type</Typography>
          <Tooltip
            content={
              <Typography variant="action" className="mb-2">
                Extra: An option selected by the passenger from a <br />
                drop down menu in the Booking Sheet (e.g. Baby Seat) <br />
                Fee: An administrative extra added by your <br /> internal staff (e.g. Booking Fee)
              </Typography>
            }
          >
            <span className="ml-1">
              <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
            </span>
          </Tooltip>
        </div>
      ),
    },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active },
    { header: " ", size: 100, cell: (props) => props.row.original.actions },
  ];

  return cols;
};

export const generateTableData = (items: CostExtrasItem[], currency: string) => {
  return items.map(
    (i) =>
      ({
        id: i.id,
        icon: i.icon ? (
          <div className="flex justify-center">
            <Icon name={`extras-${i.icon}`} isCustom className="flex-shrink-0 rounded-full bg-neutral-gray p-1" size="xl" />
          </div>
        ) : undefined,
        name: (
          <div className="flex flex-row items-center gap-2">
            <Typography>{i.name}</Typography>
            {i.tooltip && (
              <Tooltip content={i.tooltip}>
                <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
              </Tooltip>
            )}
          </div>
        ),
        description: i.description,
        price: formatCurrency(i.price, 2, currency),
        type: capitalize(i.type),
        active: i.active ? (
          <Icon name="TickCircle" variant="Bold" className="text-success" />
        ) : (
          <Icon name="CloseCircle" variant="Bold" className="text-danger" />
        ),
      } as ExtrasTableData)
  );
};

export const generateEditorColumns = (data: ExtrasTableData[]) => {
  const cols: DataTableColumnDef<ExtrasTableData>[] = [
    {
      accessorKey: "reorder",
      header: "",
      size: 100,
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
    },
    { id: "icon", size: 50, cell: (props) => props.row.original.icon },
    { accessorKey: "name", header: `Name (${data.length})`, cell: (props) => props.row.original.name },
    { accessorKey: "description", header: "Description", size: 600 },
    { accessorKey: "price", header: "Cost" },
    {
      accessorKey: "type",
      header: () => (
        <div className="flex flex-row items-center gap-2">
          <Typography variant="small">Type</Typography>
          <Tooltip
            content={
              <Typography variant="action" className="mb-2">
                Extra: An option selected by the passenger from a <br />
                drop down menu in the Booking Sheet (e.g. Baby Seat) <br />
                Fee: An administrative extra added by your <br /> internal staff (e.g. Booking Fee)
              </Typography>
            }
          >
            <span className="ml-1">
              <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
            </span>
          </Tooltip>
        </div>
      ),
    },
    { accessorKey: "active", header: "Active", cell: (props) => props.row.original.active },
    { header: " ", size: 100, cell: (props) => props.row.original.actions },
  ];

  return cols;
};

export const generateEditorTableData = (items: CostExtrasItem[], currency: string, toggleItemStatus: (id: string, active: boolean) => void, onEditItem: (item: CostExtrasItem) => void, onDeleteItem: (item: CostExtrasItem) => void) => {
  return items.map(
    (i) =>
      ({
        id: i.id,
        icon: i.icon ? (
          <div className="flex justify-center">
            <Icon id="icon" name={`extras-${i.icon}`} isCustom className="flex-shrink-0 rounded-full bg-neutral-gray p-1" size="xl" />
          </div>
        ) : undefined,
        name: getNameWithTooltip(i),
        description: i.description,
        price: formatCurrency(i.price, 2, currency),
        type: capitalize(i.type),
        active: !i.universal ? <Toggle size="sm" checked={i.active} onChange={(checked) => toggleItemStatus(i.id, checked)} /> : undefined,
        actions: !i.universal ? (
          <div className="flex gap-2">
            <IconButton
              iconName="Edit2"
              variant="tertiary"
              onClick={() => onEditItem(i)}
              className="border-none bg-transparent p-1.5 !text-info hover:bg-info-light"
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              onClick={() => onDeleteItem(i)}
              className="border-none bg-transparent p-1.5 !text-danger hover:bg-danger-light"
            />
          </div>
        ) : undefined,
      } as ExtrasTableData)
  );
};

export const getNameWithTooltip = (i: CostExtrasItem) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Typography>{i.name}</Typography>
      {i.tooltip && (
        <Tooltip content={i.tooltip}>
          <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
        </Tooltip>
      )}
    </div>
  );
};
