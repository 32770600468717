import { BlockedOffloadDriversPanel, OffloadDriversPanel } from "@/components/organisms";
import { useGetOffloadDriversParams } from "@/components/organisms/Drivers/hooks/useGetOffloadDriversParams";

export const OffloadDrivers = () => {
  const { showBlocked } = useGetOffloadDriversParams();

  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      {showBlocked ? <BlockedOffloadDriversPanel /> : <OffloadDriversPanel />}
    </div>
  );
};
