import { Float, FloatProps } from "@headlessui-float/react";
import { Popover as HUIPopover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { Fragment } from "react";

export interface PopoverProps extends FloatProps {
  position?: FloatProps["placement"];
  children: React.ReactElement[];
}

export const Popover = ({ position = "top", offset = 10, children, ...props }: PopoverProps) => {
  return (
    <HUIPopover>
      <Float offset={offset} placement={position} {...props}>
        <PopoverButton as={Fragment}>{children[0]}</PopoverButton>
        <PopoverPanel>{children[1]}</PopoverPanel>
      </Float>
    </HUIPopover>
  );
};
