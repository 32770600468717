import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const CustomerReferenceField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <Typography>Custom Reference Field Description</Typography>
      <TextInput
        className="w-full"
        hasError={!!errors.customReferenceFieldDescription}
        placeholder="Enter Customer Reference Field Description"
        maxLength={100}
        {...register("customReferenceFieldDescription")}
      />
      <Typography variant="small" className="text-neutral-dark-gray">
        This description will be displayed beneath the custom reference field for all your company bookings.
      </Typography>
      <ErrorMessage errors={errors} name="customReferenceFieldDescription" />
    </div>
  );
};
