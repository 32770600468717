import cx from "classnames";
import { Icon, Typography } from "@/components/atoms";

export interface DocumentListItem extends React.HTMLAttributes<HTMLAnchorElement> {
  icon: JSX.Element;
  title: string;
  subtext: string;
  link: string;
}

export const DocumentList = ({ className, children, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cx("flex w-full gap-4 overflow-x-auto pb-2", className)} {...props}>
      {children}
    </div>
  );
};

const DocumentListItem = ({ className, icon, title, subtext, link, ...props }: DocumentListItem) => {
  return (
    <a
      href={link}
      className={cx(
        "inline-flex min-w-[170px] max-w-[170px] flex-1 shrink-0 flex-col items-center justify-center rounded-lg bg-white p-6 shadow hover:bg-neutral-surface-gray",
        className
      )}
      {...props}
      target="_blank"
      rel="noreferrer"
    >
      <span className="relative mt-2 flex h-12 w-12 items-center justify-center">
        <Icon name="document" isCustom size={96} className="absolute h-full w-full" />
        <span className="z-10">{icon}</span>
      </span>
      <Typography className="mt-4 min-h-[3rem] text-center">{title}</Typography>
      <Typography variant="small" className="mt-2 w-full border-t border-neutral-mid-gray pt-2 text-center text-neutral-dark-gray">
        {subtext}
      </Typography>
    </a>
  );
};

DocumentList.Item = DocumentListItem;
