import { isEmpty } from "ramda";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Userpilot } from "userpilot";
import { useLayout, useOperator, useProfile } from "@/hooks";
import { clsx } from "@/utils";
import { Header } from "..";
import { useGTM } from "gtm-react-hook";

export const PrivateContent = () => {
  const {
    layout: { sidebarOpen, fullScreen },
  } = useLayout();
  const location = useLocation();
  const navigate = useNavigate();
  const { eventGTM } = useGTM();
  const { id: profileId, firstName, lastName, email } = useProfile();
  const { id: operatorId, providerCode, roles, flags } = useOperator();

  useEffect(() => {
    if (flags.isSuspended) navigate("/suspended");

    eventGTM("identifyUser", {
      accountUuid: profileId,
      providerKey: providerCode,
    });

    Userpilot.identify(profileId, {
      name: `${firstName} ${lastName}`,
      email,
      company: {
        id: operatorId,
        name: providerCode,
      },
      role: isEmpty(roles) ? undefined : roles[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerCode]);

  useEffect(() => Userpilot.reload(), [location]);

  if (fullScreen) return <Outlet />;

  return (
    <div
      className={clsx("flex flex-1 flex-col lg:min-w-[768px]", {
        "lg:pl-[85px]": !sidebarOpen,
        "lg:pl-[270px]": sidebarOpen,
      })}
    >
      <Header />
      <main className="flex flex-col items-stretch bg-neutral-surface-gray px-3 sm:min-h-[calc(100vh-65px)] lg:px-5">
        <Outlet />
      </main>
    </div>
  );
};
