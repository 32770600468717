import { saveAs } from "file-saver";
import { FileInput, IconButton, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import {
  useAddBookingOfferDocumentMutation,
  useGetBookingOfferDocumentsQuery,
  useLazyGetBookingOfferDocumentQuery,
  useRemoveBookingOfferDocumentMutation,
} from "@/redux/apis/bookingOffer/bookingOfferApi";
import { useAppSelector } from "@/redux/hooks";
import { bookingOfferDocumentsSelector } from "@/redux/slices/bookingOffer/selectors";
import { addToast, clsx } from "@/utils";
import { useBookingOfferPageContext } from "../context";

export const Documents = () => {
  const { params } = useBookingOfferPageContext();
  const { isLoading } = useGetBookingOfferDocumentsQuery(params);
  const [remove, { isLoading: isRemoving }] = useRemoveBookingOfferDocumentMutation();
  const [add, { isLoading: isAdding }] = useAddBookingOfferDocumentMutation();
  const [getDocument, { isLoading: isGettingDocuments }] = useLazyGetBookingOfferDocumentQuery();
  const documents = useAppSelector(bookingOfferDocumentsSelector);

  const handleAddDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (!files) return;

    Array.from(files).forEach((file) => {
      const formData = new FormData();
      formData.append("file_upload", file);

      add({ ...params, body: formData })
        .unwrap()
        .then(() => addToast("success", "Document uploaded successfully"))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    });

    e.currentTarget.value = "";
  };

  const removeDocument = (documentId: string) => {
    remove({ ...params, documentId })
      .unwrap()
      .then(() => addToast("success", "Document removed successfully"))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const downloadDocument = (documentId: string) => {
    getDocument({ ...params, documentId })
      .unwrap()
      .then((res) => saveAs(res.link))
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const isDisabled = isLoading || isRemoving || isAdding || isGettingDocuments;

  return (
    <div
      className={clsx("flex flex-col gap-4 rounded-lg bg-white p-4 shadow", {
        "pointer-events-none opacity-50": isDisabled,
      })}
    >
      <Typography>Upload Documents</Typography>
      <FileInput onChange={handleAddDocument} className="-mt-2" />
      <div className="mt-2 flex flex-col gap-3">
        {documents &&
          documents?.map((i) => (
            <div key={i.id} className="flex gap-2">
              <Typography className="flex-1">{i.filename}</Typography>
              <div className="flex gap-1">
                {i.permission === "driver" && (
                  <IconButton
                    onClick={() => downloadDocument(i.id)}
                    iconName="ImportCurve"
                    iconSize="lg"
                    variant="tertiary"
                    className="p-1 text-primary"
                  />
                )}
                <IconButton
                  onClick={() => removeDocument(i.id)}
                  iconName="Trash"
                  iconSize="lg"
                  variant="tertiary"
                  className="p-1 text-danger"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
