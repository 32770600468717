import {
  GetDisplayOptionsRawResponse,
  GetDisplayOptionsResponse,
  UpdateDisplayOptionsRawResponse,
  UpdateDisplayOptionsResponse,
} from "./types";

export const transformDisplayOptions = (res: GetDisplayOptionsRawResponse) => {
  const { _links, ...displayOptions } = res;
  const response: GetDisplayOptionsResponse = {
    data: {
      details: {
        bookingMaxNumberOfStops: displayOptions.booking_max_number_of_stops,
        enabledBookingHistoryDisplayOperatorIdentifier: displayOptions.enabled_booking_history_display_operator_identifier,
        enabledBookingHistoryUseOptimizedWideDisplay: displayOptions.enabled_booking_history_use_optimized_wide_display,
        enabledBookingManagerDashboardNoteFunctionality: displayOptions.enabled_booking_manager_dashboard_note_functionality,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateDisplayOptions = (res: UpdateDisplayOptionsRawResponse) => {
  return {
    links: res._links,
  } as UpdateDisplayOptionsResponse;
};
