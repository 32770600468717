import { format, parseISO } from "date-fns";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Loading, Panel, Skeleton, Typography } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useGetPayoutRecordsQuery, useLazyGetPayoutHistoryItemQuery } from "@/redux/apis/payment/record/recordApi";
import { useAppSelector } from "@/redux/hooks";
import { payoutRecordsSelector } from "@/redux/slices/payment/selectors";
import { PayoutHistoryItem } from "@/redux/slices/payment/types";
import { addToast, formatCurrency } from "@/utils";
import { PayoutTable } from "./PayoutTable";
import { renderPayoutHistoryStatus } from "./helpers";
import { useGetPayoutRecordsParams } from "./hooks/useGetPayoutRecordsParams";

export const PayoutPanel = () => {
  const { dateFormat } = useProfile();
  const navigate = useNavigate();
  const { params } = useGetPayoutRecordsParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isFetching: isFetchingPayoutRecords } = useGetPayoutRecordsQuery(params, { refetchOnMountOrArgChange: true });
  const [getPayoutHistoryItem, { isFetching: isFetchingPayoutHistoryItem }] = useLazyGetPayoutHistoryItemQuery();
  const { items } = useAppSelector(payoutRecordsSelector);
  const [selectedPayoutHistoryItem, setSelectedPayoutHistoryItem] = useState<PayoutHistoryItem | undefined>(undefined);
  const isPayoutRecordsEmpty = isEmpty(items) && !isFetchingPayoutRecords;
  const { id } = params;

  useEffect(() => {
    if (id) {
      getPayoutHistoryItem({ id })
        .unwrap()
        .then((res) => setSelectedPayoutHistoryItem(res.data))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  }, [id, getPayoutHistoryItem]);

  useEffect(() => {
    if (selectedPayoutHistoryItem) {
      searchParams.set("arrivalDate", format(parseISO(selectedPayoutHistoryItem.arrivalDate), dateFormat));
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayoutHistoryItem]);

  const renderArrivalDate = () => (
    <Typography variant="paragraph">
      {selectedPayoutHistoryItem?.arrivalDate ? format(parseISO(selectedPayoutHistoryItem.arrivalDate), dateFormat) : "-"}
    </Typography>
  );

  const renderAmount = () => { 
    if (selectedPayoutHistoryItem) {
      const { amount, currency } = selectedPayoutHistoryItem;
      if (amount && currency) {
        return (
          <Typography variant="paragraph">
            {currency} {formatCurrency(amount, 2, currency)}
          </Typography>
        );
      }
    }
  };

  const renderStatus = () => {
    if (selectedPayoutHistoryItem) {
      return renderPayoutHistoryStatus(selectedPayoutHistoryItem);
    }

    return <Typography variant="paragraph">-</Typography>;
  };

  return (
    <div className="flex flex-col">
      <div className="flex">
        <Button startIcon="ArrowLeft" variant="tertiary" size="sm" onClick={() => navigate("../")}>
          Back
        </Button>
      </div>
      <Panel className="mt-5">
        <header className="flex">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Payout
            </Typography>
            <Typography className="text-neutral-dark-gray">Payout Details</Typography>
          </div>
          <div className="flex flex-row gap-x-2.5">
            <div className="relative flex min-w-[150px] flex-col rounded-lg bg-neutral-surface-gray p-2.5">
              {isFetchingPayoutHistoryItem && <Loading className="rounded-lg" />}
              <Typography variant="paragraph" className="text-neutral-dark-gray">
                Arrived in Bank
              </Typography>
              {renderArrivalDate()}
            </div>
            <div className="relative flex min-w-[150px] flex-col rounded-lg bg-neutral-surface-gray p-2.5">
              {isFetchingPayoutHistoryItem && <Loading className="rounded-lg" />}
              <Typography variant="paragraph" className="text-neutral-dark-gray">
                Amount
              </Typography>
              {renderAmount()}
            </div>
            <div className="relative flex min-w-[150px] flex-col rounded-lg bg-neutral-surface-gray p-2.5">
              {isFetchingPayoutHistoryItem && <Loading className="rounded-lg" />}
              <Typography variant="paragraph" className="text-neutral-dark-gray">
                Status
              </Typography>
              {renderStatus()}
            </div>
          </div>
        </header>
        {isPayoutRecordsEmpty ? (
          <EmptyState title="Payout" description="There are currently no payout records available" />
        ) : isFetchingPayoutRecords ? (
          <Skeleton />
        ) : (
          <PayoutTable items={items} className="mt-4" />
        )}
      </Panel>
      {!isFetchingPayoutRecords && <PayoutTable.Pagination />}
    </div>
  );
};
