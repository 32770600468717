import { Account } from "@/redux/slices/account/types";
import { GetAccountsRawResponse, GetAccountsResponse } from "./types";

export const transformAccounts = (res: GetAccountsRawResponse) => {
  const rawAccounts = res._embedded.accounts;

  const accounts = rawAccounts.map((a) => {
    const {
      account_uuid,
      primary,
      _embedded: {
        profile: { first_name, last_name, avatar, mobile, title },
      },
    } = a;

    return {
      id: account_uuid,
      primary,
      profile: {
        id: account_uuid,
        displayName: `${first_name} ${last_name[0]}`,
        firstName: first_name,
        lastName: last_name,
        avatar,
        mobile,
        title,
      },
    } as Account;
  });

  const response: GetAccountsResponse = {
    data: {
      accounts,
    },
    links: res._links,
  };

  return response;
};
