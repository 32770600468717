import { ComponentProps, forwardRef } from "react";
import { Typography } from "@/components/atoms";
import { BookingFlightStatus } from "@/redux/slices/booking/types";
import { clsx } from "@/utils";

export interface BookingFlightStatusCodeProps extends ComponentProps<typeof Typography> {
  status: BookingFlightStatus;
  delay?: number;
  type?: "default" | "vibrant";
}

const flightColorsDefault: Record<BookingFlightStatus, string> = {
  not_found: "bg-neutral-dark-gray/20 text-neutral-dark-gray",
  unknown: "bg-neutral-dark-gray/20 text-neutral-dark-gray",
  scheduled: "bg-success-light text-success",
  "en-route": "bg-success-light text-success",
  "on-time": "bg-success-light text-success",
  cancelled: "bg-danger-light text-danger",
  delayed: "bg-warning-light text-warning-dark",
  early: "bg-info-light text-info",
  diverted: "bg-[#FF825B]/10 text-[#FF825B]",
  incident: "bg-[#FF825B]/10 text-[#FF825B]",
  landed: "bg-success-light text-success",
};

const flightColorsVibrant: Record<BookingFlightStatus, string> = {
  not_found: "bg-neutral-dark-gray",
  unknown: "bg-neutral-dark-gray",
  scheduled: "bg-success",
  "en-route": "bg-success",
  "on-time": "bg-success",
  cancelled: "bg-danger",
  delayed: "bg-warning",
  early: "bg-info",
  diverted: "bg-[#FF825B]",
  incident: "bg-[#FF825B]",
  landed: "bg-success",
};

const statusLabels: Record<BookingFlightStatus, string> = {
  not_found: "Not Found",
  unknown: "Pending",
  scheduled: "On-Time",
  "en-route": "En-Route",
  "on-time": "On-Time",
  cancelled: "Cancelled",
  delayed: "Delayed",
  early: "Early",
  diverted: "Diverted",
  incident: "Incident",
  landed: "Landed",
};

export const BookingFlightStatusCode = forwardRef<HTMLHeadingElement & HTMLSpanElement, BookingFlightStatusCodeProps>((props, ref) => {
  const { status, delay, type = "default", className, ...rest } = props;
  const activeStatusList = ["scheduled", "en-route"];
  const statusWithDelay = (typeof delay === "number" && activeStatusList.includes(status)) ? (delay > 0 ? "delayed" : delay < 0 ? "early" : "on-time") : status;

  return (
    <Typography
      variant="small"
      className={clsx(
        "flex rounded-xl px-4 py-0.5 capitalize leading-snug text-white", 
        type === "vibrant" ? flightColorsVibrant[statusWithDelay] : flightColorsDefault[statusWithDelay], 
        className,
      )}
      ref={ref}
      {...rest}
    >
      {statusLabels[statusWithDelay]}
    </Typography>
  );
});

BookingFlightStatusCode.displayName = "BookingFlightStatusCode";
