import { Button, Icon, Popover } from "@/components/atoms";
import { BookingFilters } from "@/redux/slices/booking/types";
import { clsx } from "@/utils";

interface FilterPopoverProps {
  name: keyof BookingFilters;
  label: string;
  value?: string | boolean | null;
  children: React.ReactElement;
  onClose: () => void;
  className?: string;
}

const statesClass = {
  active: "border-primary-light bg-primary-light enabled:border-primary-light active:border-primary-light",
  inactive:
    "border-neutral-gray bg-neutral-gray text-neutral-dark-gray enabled:border-neutral-gray active:border-neutral-gray hover:!border-neutral-gray hover:!bg-neutral-gray hover:!text-neutral-dark-gray",
};

export const FilterPopover = ({ name, label, value, onClose, children, className }: FilterPopoverProps) => {
  const state = !value ? "inactive" : "active";

  return (
    <Popover placement="bottom-start">
      <Button
        data-filter-name={name}
        variant="secondary"
        className={clsx(
          "h-[26px] items-center gap-0.5 overflow-ellipsis rounded-full !px-2 text-primary !outline-none transition-colors [&>span]:!text-sm",
          "aria-expanded:!border-primary aria-expanded:!bg-primary-light aria-expanded:bg-neutral-gray aria-expanded:!text-primary",
          statesClass[state],
          className
        )}
        endIcon={<Icon onClick={onClose} name="close" isCustom size="sm" className="ml-1 rounded-full hover:bg-primary-light" />}
        size="xs"
      >
        {label}
        {value ? `: ${value}` : ""}
      </Button>
      <div className={clsx("flex max-h-[500px] w-[360px] flex-col overflow-y-auto rounded-lg bg-white p-4 shadow-dropdown", className)}>
        {children}
      </div>
    </Popover>
  );
};
