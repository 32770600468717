import { useCallback, useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { useOperator, usePagination, useSorting } from "@/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { Page } from "@/redux/types";
import { myVehiclesColumns } from "./fixtures";
import { generateMyVehicleTableData } from "./helpers";

export interface MyVehiclesTableProps {
  items: Vehicle[];
  setPendingArchive: (vehicle: Vehicle) => void;
}

export const MyVehiclesTable = ({ items, setPendingArchive }: MyVehiclesTableProps) => {
  const { id: operatorId } = useOperator();
  const { sorting, setSorting } = useSorting();

  const onArchiveClick = useCallback(
    (vehicle: Vehicle) => {
      setPendingArchive(vehicle);
    },
    [setPendingArchive]
  );

  const data = useMemo(() => generateMyVehicleTableData(operatorId, items, onArchiveClick), [items, onArchiveClick, operatorId]);

  return (
    <DataTable
      className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
      data={data}
      columns={myVehiclesColumns}
      sorting={sorting}
      setSorting={setSorting}
    />
  );
};

const MyVehiclesTablePagination = ({ page }: { page: Page }) => {
  const { current, size, total, count } = page;
  const { handlePageClick, handleSizeChange } = usePagination("my-vehicles", 30);

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

MyVehiclesTable.Pagination = MyVehiclesTablePagination;
