import Fuse from "fuse.js";
import { useEffect, useMemo } from "react";
import { Icon, IconButton, Typography, SidePanel, Skeleton } from "@/components/atoms";
import { useLazyGetBookingHistoryActivityQuery } from "@/redux/apis/booking/endpoints/actions";
import { useAppSelector } from "@/redux/hooks";
import { bookingHistoryLogsSelector } from "@/redux/slices/booking/selectors";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { JobHistoryActivityBreakdown } from "./JobHistoryActivityBreakdown";
import { JobHistorySearchFilters } from "./JobHistorySearchFilters";
import { JobHistoryTable } from "./JobHistoryTable";
import { useInfiniteJobHistoryLogs } from "./useInfiniteJobHistoryLogs";
import { useJobHistorySearchFilters } from "./useJobHistorySearchFilters";

interface JobHistoryProps {
  open: boolean;
  onClose: () => void;
}

export const JobHistory = ({ open, onClose }: JobHistoryProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [getBookingHistoryActivity, { isFetching: isActivityLoading, data: activity }] = useLazyGetBookingHistoryActivityQuery();
  const searchFiltersContext = useJobHistorySearchFilters();
  const { mode, search, filters } = searchFiltersContext;
  const { isFetching: isLogsLoading, ref, getBookingHistoryLogs } = useInfiniteJobHistoryLogs();
  const logs = useAppSelector(bookingHistoryLogsSelector);

  const fuse = useMemo(
    () =>
      new Fuse(logs ?? [], {
        keys: ["modifier", "field.name", "field.from", "field.to", "event.name", "event.type"],
      }),
    [logs]
  );

  const data = useMemo(() => {
    if (search) {
      return fuse.search(search).map((i) => i.item);
    }

    if (mode === "filter") {
      // based on filters, return logs where log.event.type is in filters
      return logs?.filter((i) => filters.includes(i.event.type)) ?? [];
    }

    return logs;
  }, [filters, fuse, logs, mode, search]);

  useEffect(() => {
    if (open) {
      getBookingHistoryLogs({ id: bookingId, page: "1" });
      getBookingHistoryActivity(bookingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <SidePanel open={open} onClose={onClose} width={800} focused ref={ref}>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="Car" size="lg" />
          <Typography variant="title">Job History ({bookingId})</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="flex flex-col p-5">
        {isActivityLoading || !activity ? (
          <Skeleton className="w-1/2" variant="short" />
        ) : (
          <JobHistoryActivityBreakdown activity={activity} />
        )}
        <JobHistorySearchFilters searchFiltersContext={searchFiltersContext} />
        {!data ? <Skeleton /> : <JobHistoryTable logs={data} />}
        {isLogsLoading && <Skeleton variant="short" />}
      </section>
    </SidePanel>
  );
};
