import { Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAssociatedClientAction } from "@/redux/slices/company/companySlice";
import { associatedClientActionSelector } from "@/redux/slices/company/selectors";
import { UnableToAddClient } from "./contents/UnableToAddClient";
import { AssociatedClientsAddForm } from "./forms/AssociatedClientsAddForm";

interface AssociatedClientsModalsProps {
  refetchAssociatedClients: () => void;
}

export const AssociatedClientsModals = ({ refetchAssociatedClients }: AssociatedClientsModalsProps) => {
  const action = useAppSelector(associatedClientActionSelector);
  const dispatch = useAppDispatch();

  const onClose = () => dispatch(setAssociatedClientAction(undefined));

  return (
    <>
      <Modal open={action === "addClients"} onClose={onClose}>
        <AssociatedClientsAddForm onClose={onClose} refetchAssociatedClients={refetchAssociatedClients} />
      </Modal>
      <Modal open={action === "unableToAddClient"} onClose={onClose}>
        <UnableToAddClient onClose={onClose} />
      </Modal>
    </>
  );
};
