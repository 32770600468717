import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProviderCountry } from "@/redux/baseQuery";
import { transformTermsAndConditions } from "./helpers";
import { GetTermsAndConditionsResponse } from "./types";

export const termsAndConditionsApi = createApi({
  reducerPath: "termsAndConditionsApi",
  baseQuery: baseQueryWithProviderCountry(),
  endpoints: (builder) => ({
    getTermsAndConditions: builder.query<GetTermsAndConditionsResponse, void>({
      query: () => "/terms-and-conditions",
      transformResponse: transformTermsAndConditions,
    }),
  }),
});

export const { useGetTermsAndConditionsQuery } = termsAndConditionsApi;
