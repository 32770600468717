import { useMemo, useState } from "react";
import { BookingTripMap } from "../../BookingTripMap/BookingTripMap";
import { extractMarkers, extractPolylines, extractTripPoints } from "../../BookingTripMap/helpers";
import { useBookingTripPaths } from "../../BookingTripMap/hooks/useBookingTripPaths";
import { BookingMapState, BookingMarkerState } from "../../BookingTripMap/types";
import { useBookingDriverActivityContext } from "../context";

export const BookingTripMapContainer = () => {
  const { tripLog } = useBookingDriverActivityContext();

  const [markerState, setMarkerState] = useState<BookingMarkerState>({
    pickup: false,
    dropoff: false,
    driver: false,
    "waypoint-1": false,
    "en-route-to-pickup": false,
    "client-dropped-off": false,
    "client-picked-up": false,
  });

  const { preTrip, driverOnWay, commenced } = useBookingTripPaths(tripLog);

  const mapState: BookingMapState = useMemo(() => {
    const points = extractTripPoints(tripLog.locationData, tripLog.tripStops);
    const polylines = extractPolylines(tripLog);
    const markers = extractMarkers(tripLog);

    return {
      tripPoints: points,
      markers,
      polylines: {
        onRoute: polylines.onRoute,
        onTrip: polylines.onTrip,
        preTrip,
        driverOnWay,
        commenced,
      },
    };
  }, [commenced, driverOnWay, preTrip, tripLog]);

  return (
    <div className="h-full min-h-[500px] overflow-hidden rounded-lg border border-neutral-mid-gray max-lg:-order-1">
      <BookingTripMap mode="guest" tripLog={tripLog} markerState={markerState} mapState={mapState} onToggle={setMarkerState} />
    </div>
  );
};
