import camelcaseKeys from "camelcase-keys";
import { GetVehicleCategoriesResponse } from "@/redux/slices/vehicle/types";
import { GetVehicleCategoriesRawResponse } from "./types";

export const transformGetVehicleCategoriesResponse = (response: GetVehicleCategoriesRawResponse): GetVehicleCategoriesResponse => {
  return {
    categories: response._embedded.category.map((category) => camelcaseKeys(category, { deep: true })),
    page: {
      current: response.page,
      count: response.page_count,
      size: response.page_size,
      total: response.total_items,
    },
  };
};
