import { useSearchParams } from "react-router-dom";
import { DataTableSetSorting, DataTableSorting } from "@/components/molecules";

//TODO: implement use-query-param library
export const useSorting = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sort = searchParams.get("sort") ?? "";
  const direction = searchParams.get("direction") ?? "";
  const sorting: DataTableSorting = sort && direction ? [{ id: sort, desc: direction === "desc" }] : [];

  const setSorting: DataTableSetSorting = (updaterOrValue) => {
    let newSorting: DataTableSorting = [];

    if (typeof updaterOrValue === "function") {
      newSorting = updaterOrValue(sorting);
    } else {
      newSorting = updaterOrValue;
    }

    if (newSorting.length < 1) {
      searchParams.delete("sort");
      searchParams.delete("direction");
    } else {
      searchParams.set("sort", newSorting[0].id ?? "");
      searchParams.set("direction", newSorting[0].desc ? "desc" : "asc");
    }
    setSearchParams(searchParams);
  };

  return {
    sorting,
    setSorting,
    sortKey: sort,
    direction,
  };
};
