import { Input } from "@headlessui/react";
import { forwardRef } from "react";
import { clsx } from "@/utils";
import { Icon, IconVariant } from "..";

export interface TextInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
  type?: "text" | "email" | "password" | "number" | "time" | "tel";
  variant?: "standard" | "search-box";
  size?: "sm" | "md" | "lg";
  iconName?: string;
  iconClassName?: string;
  iconVariant?: IconVariant;
  iconPosition?: "start" | "end";
  onIconClick?: () => void;
  hasError?: boolean;
  hasWarning?: boolean;
  containerClass?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((textInputProps, ref) => {
  const {
    type = "text",
    variant = "standard",
    size = "lg",
    iconName,
    className,
    iconClassName,
    iconVariant,
    iconPosition = "start",
    onIconClick,
    min = 0,
    step = 1,
    hasError = false,
    hasWarning = false,
    containerClass,
    ...props
  } = textInputProps;

  const baseClass =
    "block w-full rounded-lg px-4 text-neutral-black focus:border-primary focus:shadow-input focus:ring-0 disabled:cursor-not-allowed disabled:bg-neutral-surface-gray disabled:text-neutral-dark-gray";

  const variantClass = {
    "border-neutral-mid-gray placeholder-neutral-dark-gray": variant === "standard",
    "bg-neutral-gray font-semibold border-neutral-gray placeholder-neutral-dark-gray": variant === "search-box",
  };

  const errorClass = {
    "border-1 !border-danger !focus:border-danger": hasError,
    "border-1 !border-warning !focus:border-warning": hasWarning,
  };

  const sizeClass = {
    "h-[32px] max-md:text-base text-base": size === "sm",
    "h-[38px] max-md:text-base text-base": size === "md",
    "h-[46px] max-md:text-base text-base": size === "lg",
  };

  const iconClass = {
    "pl-10 pr-3": iconName && iconPosition === "start",
    "pr-10 pl-3": iconName && iconPosition === "end",
  };

  const iconColorClass = {
    "text-neutral-mid-gray": variant === "standard",
    "text-neutral-dark-gray": variant === "search-box",
  };

  const iconSizeClass = {
    "h-[32px] pl-3": size === "sm" && iconPosition === "start",
    "h-[38px] pl-3": size === "md" && iconPosition === "start",
    "h-[46px] pl-3": size === "lg" && iconPosition === "start",
    "h-[32px] pr-3": size === "sm" && iconPosition === "end",
    "h-[38px] pr-3": size === "md" && iconPosition === "end",
    "h-[46px] pr-3": size === "lg" && iconPosition === "end",
    "pointer-events-none": !onIconClick,
    "cursor-pointer": !!onIconClick,
  };

  if (iconName)
    return (
      <div className={clsx("relative", containerClass)}>
        <Input
          type={type}
          ref={ref}
          className={clsx(baseClass, variantClass, sizeClass, iconClass, errorClass, className)}
          {...{
            min: type === "number" ? min : undefined,
            step: type === "number" || type === "time" ? step : undefined,
          }}
          {...props}
        />
        {iconName && iconPosition === "start" && (
          <div className={clsx("absolute inset-y-0 left-0 flex items-center", iconSizeClass)} onClick={onIconClick}>
            <Icon className={clsx(iconColorClass, iconClassName)} variant={iconVariant} name={iconName} size="sm" aria-hidden="true" />
          </div>
        )}
        {iconName && iconPosition === "end" && (
          <div className={clsx("absolute inset-y-0 right-0 flex items-center", iconSizeClass)}>
            <Icon
              className={clsx(iconColorClass, iconClassName)}
              variant={iconVariant}
              name={iconName}
              size="sm"
              aria-hidden="true"
              onClick={onIconClick}
            />
          </div>
        )}
      </div>
    );

  return (
    <Input
      type={type}
      ref={ref}
      className={clsx(baseClass, variantClass, sizeClass, iconClass, errorClass, className)}
      {...{
        min: type === "number" ? min : undefined,
        step: type === "number" || type === "time" ? step : undefined,
      }}
      {...props}
    />
  );
});

TextInput.displayName = "TextInput";
