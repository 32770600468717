import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { TransferTypeFormData } from "../types";

export const DescriptionField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TransferTypeFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="action">Description</Typography>
      <TextInput
        className="w-full"
        hasError={!!errors.description}
        placeholder="Enter Description"
        maxLength={128}
        {...register("description")}
      />
      <ErrorMessage errors={errors} name="description" />
    </div>
  );
};
