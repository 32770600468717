import { memo, useMemo } from "react";
import { Loading } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { useUserAccountsContext } from "@/contexts/userAccounts";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRemoveAccessMutation, useResendInviteMutation } from "@/redux/apis/userAccount/userAccountApi";
import { UserAccount } from "@/redux/slices/userAccount/types";
import { addToast, clsx } from "@/utils";
import { generateColumns, generateData } from "./helpers";

interface UserAccountsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: UserAccount[];
}

export const UserAccountsTable = ({ items, className, ...props }: UserAccountsTableProps) => {
  const { setStep, setSelectedUserAccount } = useUserAccountsContext();
  const [resendInvite, { isLoading: isResendLoading }] = useResendInviteMutation();
  const [removeAccess, { isLoading: isCancelLoading }] = useRemoveAccessMutation();

  const onChangeClick = (user: UserAccount) => {
    setSelectedUserAccount(user);
    setStep("edit");
  };

  const onRemoveClick = (user: UserAccount) => {
    setSelectedUserAccount(user);
    setStep("delete");
  };

  const onResendClick = (user: UserAccount) => {
    resendInvite(user.id)
      .unwrap()
      .then(() => {
        addToast("success", `Successfully sent an Invite to “${user.email}”`);
        setStep(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const onCancelClick = (user: UserAccount) => {
    removeAccess(user.id)
      .unwrap()
      .then(() => {
        addToast("success", `Successfully unsent invite to “${user.email}”`);
        setStep(undefined);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const data = useMemo(
    () => generateData(items, onChangeClick, onRemoveClick, onResendClick, onCancelClick),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      {(isResendLoading || isCancelLoading) && <Loading />}
      <MemoizedTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};

const MemoizedTable = memo(DataTable) as typeof DataTable;