import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import { Loading } from "@/components/atoms";
import { getStripePublishableKey } from "@/helpers/apiHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetCompanyPaymentMethodSecretMutation } from "@/redux/apis/company/companyApi";
import { useAppSelector } from "@/redux/hooks";
import { invoicingInvoicesPayByCreditCardSelector } from "@/redux/slices/payment/selectors";
import { Invoice } from "@/redux/slices/payment/types";
import { addToast } from "@/utils";
import { PayByCreditCardForm } from "../forms/PayByCreditCardForm";

interface PayByCreditCardStep1Props {
  invoice: Invoice;
}

export const PayByCreditCardStep1 = ({ invoice }: PayByCreditCardStep1Props) => {
  const stripePublishableKey = getStripePublishableKey();
  const stripePromise = loadStripe(stripePublishableKey);
  const [getCompanyPaymentMethodSecret, { isLoading }] = useGetCompanyPaymentMethodSecretMutation();
  const { paymentMethodSecret } = useAppSelector(invoicingInvoicesPayByCreditCardSelector);

  useEffect(() => {
    getCompanyPaymentMethodSecret({ id: invoice.company.id })
      .unwrap()
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex w-full">
      {isLoading && <Loading />}
      {stripePromise && paymentMethodSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret: paymentMethodSecret }}>
          <PayByCreditCardForm invoice={invoice} />
        </Elements>
      )}
    </div>
  );
};
