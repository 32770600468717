import { forwardRef } from "react";
import { clsx, slugify } from "@/utils";

export interface CheckboxButtonProps extends React.ComponentPropsWithRef<"input"> {
  label: string;
  checked?: boolean;
  disabled?: boolean;
}

export const CheckboxButton = forwardRef<HTMLInputElement, CheckboxButtonProps>(
  ({ id, label, checked, disabled, className, onChange, ...props }, ref) => {
    const getCheckboxId = () => {
      return id ? id : `checkbox-button-${slugify(label)}`;
    };

    return (
      <div className="inline-flex">
        <input id={getCheckboxId()} type="checkbox" className="peer hidden" ref={ref} checked={checked} onChange={onChange} {...props} />
        <label
          htmlFor={getCheckboxId()}
          className={clsx(
            !disabled ? "cursor-pointer focus:outline-none" : "cursor-not-allowed opacity-25",
            "flex select-none items-center justify-center rounded-lg bg-neutral-gray px-6 py-4 text-base font-semibold leading-none text-neutral-black ring-1 ring-neutral-gray hover:bg-primary-light peer-checked:bg-primary-light peer-checked:text-primary peer-checked:ring-1 peer-checked:ring-primary peer-checked:hover:bg-primary-light data-[focus]:ring-2 data-[focus]:ring-primary data-[focus]:ring-offset-2 data-[focus]:peer-checked:ring-2 sm:flex-1 [&:not([data-focus],[peer-checked])]:ring-inset",
            className
          )}
        >
          {label}
        </label>
      </div>
    );
  }
);

CheckboxButton.displayName = "CheckboxButton";
