import {
  ConnectStripeRawResponse,
  ConnectStripeResponse,
  GetDebitsDetailsRawResponse,
  GetDebitsDetailsResponse,
  GetDebitsHistoryRawResponse,
  GetDebitsHistoryResponse,
  GetDebitsSecretRawResponse,
  GetDebitsSecretResponse,
  GetDepositsDetailsRawResponse,
  GetDepositsDetailsResponse,
  GetDepositsHistoryRawResponse,
  GetDepositsHistoryResponse,
} from "./types";

export const transformStripe = (res: ConnectStripeRawResponse) => {
  const response: ConnectStripeResponse = {
    data: {
      config: {
        status: res.status,
        path: res.path,
        openRequirements: res.open_requirements,
      },
    },
  };

  return response;
};

export const transformDebitsSecret = (res: GetDebitsSecretRawResponse) => {
  const response: GetDebitsSecretResponse = {
    data: {
      clientSecret: res.client_secret,
    },
  };

  return response;
};

export const transformDebitsDetails = (res: GetDebitsDetailsRawResponse) => {
  const response: GetDebitsDetailsResponse = {
    data: {
      id: res.uuid,
      routingNumber: res.account_info.routing_number,
      accountNumber: res.account_info.account_number,
      accountName: res.account_info.account_name,
      bankName: res.account_info.bank_name,
      token: res.token,
    },
  };

  return response;
};

export const transformDebitsHistory = (res: GetDebitsHistoryRawResponse) => {
  const {
    _embedded: { history: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetDebitsHistoryResponse = {
    data: {
      history: {
        items: items.map((i) => ({
          id: i.uuid,
          dateTime: i.date_time,
          firstName: i.account.first_name,
          lastName: i.account.last_name,
          event: i.event,
        })),
        page: {
          current: page,
          count: page_count,
          size: page_size,
          total: total_items,
        },
      },
    },
    links: _links,
  };

  return response;
};

export const transformDepositsDetails = (res: GetDepositsDetailsRawResponse) => {
  const response: GetDepositsDetailsResponse = {
    data: {
      id: res.uuid,
      routingNumber: res.account_info.routing_number,
      accountNumber: res.account_info.account_number,
      bankName: res.account_info.bank_name,
    },
  };

  return response;
};

export const transformDepositsHistory = (res: GetDepositsHistoryRawResponse) => {
  const {
    _embedded: { history: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetDepositsHistoryResponse = {
    data: {
      history: {
        items: items.map((i) => ({
          id: i.uuid,
          dateTime: i.date_time,
          firstName: i.account.first_name,
          lastName: i.account.last_name,
          event: i.event,
        })),
        page: {
          current: page,
          count: page_count,
          size: page_size,
          total: total_items,
        },
      },
    },
    links: _links,
  };

  return response;
};
