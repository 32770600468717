import { AccreditationItem } from "@/redux/slices/accreditation/types";
import {
  GetAccreditationHistoryRawResponse,
  GetAccreditationHistoryResponse,
  GetAccreditationItemRawResponse,
  GetAccreditationsRawResponse,
  GetAccreditationsResponse,
  GetRegistrationCertificatePathResponse,
  RawAccreditation,
} from "./types";

export const transformAccreditations = (res: GetAccreditationsRawResponse) => {
  const {
    _embedded: { accreditation: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetAccreditationsResponse = {
    data: {
      items: items.map((i) => ({
        id: i.uuid,
        stateId: i.state.uuid,
        stateAbbrev: i.state.abbreviation,
        stateName: i.state.name,
        regNo: i.document_identifier,
        regCertPath: i.document_path,
        regCertName: i.document_name,
        expiryDate: i.document_expiry_date,
        status: i.status,
        requiresCertificate: i.metadata.requires_certificate,
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};

export const transformRegistrationCertificatePath = (res: RawAccreditation) => {
  const { document_path, _links } = res;

  const response: GetRegistrationCertificatePathResponse = {
    data: {
      documentPath: document_path,
    },
    links: _links,
  };

  return response;
};

export const transformAccreditationHistory = (res: GetAccreditationHistoryRawResponse) => {
  const {
    _embedded: { accreditation: items },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetAccreditationHistoryResponse = {
    data: {
      items: items.map((i) => {
        const { created_at, account, field, to } = i;

        return {
          created: created_at,
          field,
          value: to,
          account: account.name,
        };
      }),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};

export const transformAccreditationItem = (res: GetAccreditationItemRawResponse) => {
  const { state, metadata, _links } = res;

  const response: AccreditationItem = {
    id: res.uuid,
    stateId: state.uuid,
    stateAbbrev: state.abbreviation,
    stateName: state.name,
    regNo: res.document_identifier,
    regCertPath: res.document_path,
    regCertName: res.document_name,
    expiryDate: res.document_expiry_date,
    status: res.status,
    requiresCertificate: metadata.requires_certificate,
  };

  return response;
};
