import { useLocalStorageValue } from "@react-hookz/web";
import { NumberParam, useQueryParams } from "use-query-params";

const PaginationParams = {
  page: NumberParam,
  pageSize: NumberParam,
};

export const usePagination = (name = "pagination", defaultPageSize = 12) => {
  const { value: persistedPageSize, set: setPersistedPageSize } = useLocalStorageValue(name, {
    defaultValue: defaultPageSize,
  });

  const [{ pageSize, page }, setPagination] = useQueryParams(PaginationParams);

  const handlePageClick = (page: number) => {
    setPagination({
      page,
    });
  };

  const handleSizeChange = (size: number) => {
    setPagination({
      page: undefined,
      pageSize: size,
    });
    setPersistedPageSize(size);
  };

  const clearPagination = () => {
    setPagination({
      page: undefined,
    });
  };

  return {
    page: page?.toString() ?? "1",
    pageSize: pageSize?.toString() ?? persistedPageSize?.toString() ?? defaultPageSize?.toString(),
    handlePageClick,
    handleSizeChange,
    clearPagination,
  };
};
