import { useAppAbility } from "@/casl";
import { AppearancePanel, OperatorBrandingPanel, RegistrationToolsPanel, WidgetsPanel } from "@/components/organisms";
import { ClientTools } from "./Registration/ClientTools";
import { DriverTools } from "./Registration/DriverTools";

export const BrandingAndAcquisition = () => {
  const ability = useAppAbility();

  return (
    <div className="flex flex-col items-start gap-5">
      <OperatorBrandingPanel />
      <RegistrationToolsPanel />
      {ability.can("manage", "providerConfigBranding") && <AppearancePanel />}
      <WidgetsPanel />
    </div>
  );
};

BrandingAndAcquisition.DriverTools = DriverTools;
BrandingAndAcquisition.ClientTools = ClientTools;
