import { Container } from "@/components/atoms";
import { PageHeader } from "@/components/organisms";
import { AddOnsPanel } from "@/components/organisms/AddOns/AddOnsPanel";
import { useOperator } from "@/hooks";
import { Restricted } from "../Restricted/Restricted";

export const AddOns = () => {
  const { roles } = useOperator();
  const isAdmin = roles.includes("Fa_Access") || roles.includes("Owner") || roles.includes("Admin");

  if (!isAdmin) return <Restricted />;
  
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <Container className="w-full">
        <PageHeader skipCrumbs={1} title="Add Ons Center" />
        <AddOnsPanel />
      </Container>
    </div>
  );
};