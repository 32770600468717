import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProviderCountry } from "@/redux/baseQuery";
import { RawTransportProviderCode } from "./types";

export const systemApi = createApi({
  reducerPath: "systemApi",
  baseQuery: baseQueryWithProviderCountry(),
  endpoints: (builder) => ({
    updateTransportProviderCode: builder.mutation<RawTransportProviderCode, string>({
      query: (code) => ({
        url: "/provider-key",
        method: "POST",
        body: { provider_key: code },
      }),
    }),
  }),
});

export const { useUpdateTransportProviderCodeMutation } = systemApi;
