import Tippy, { TippyProps } from "@tippyjs/react";
import { isValidElement } from "react";
import "tippy.js/dist/tippy.css";
import { Typography } from "../Typography/Typography";

export interface TooltipProps extends TippyProps {
  content: string | JSX.Element;
  children: JSX.Element;
}

const renderContent = (content: string | JSX.Element) => {
  if (isValidElement(content)) return content;
  return (
    <Typography variant="action" className="leading-4 text-white">
      {content}
    </Typography>
  );
};

export const Tooltip = ({
  content,
  placement = "top",
  children,
  delay = 100,
  maxWidth = 570,
  interactive = false,
  ...props
}: TooltipProps) => {
  const isIcon = children.type.displayName === "Icon";

  return (
    <Tippy interactive={interactive} placement={placement} content={renderContent(content)} maxWidth={maxWidth} delay={delay} {...props}>
      {isIcon ? <i className="inline">{children}</i> : children}
    </Tippy>
  );
};
