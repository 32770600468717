import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ability } from "@/casl";
import { defineAbilities } from "@/casl/ability";
import { useLazyGetAccountsQuery } from "@/redux/apis/account/accountApi";
import { useLazyGetOperatorsQuery } from "@/redux/apis/operator/operatorApi";
import { useLazyGetProfileQuery } from "@/redux/apis/profile/profileApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setActiveAccount, setSetupFinished } from "@/redux/slices/account/accountSlice";
import { switchOperator } from "@/redux/slices/operator/operatorSlice";
import { Operator } from "@/redux/slices/operator/types";
import { Profile } from "@/redux/slices/profile/types";

type UseSetupAccount =
  | {
      isReady: false;
      operators?: Operator[];
      profile?: Profile;
    }
  | {
      isReady: true;
      operators: Operator[];
      profile: Profile;
    };

export const useSetupAccount = (): UseSetupAccount => {
  const dispatch = useAppDispatch();
  const { operatorId } = useParams();
  const isReady = useAppSelector((state) => state.account.setupFinished);
  const activeOperator = useAppSelector((state) => state.operator.activeOperator);

  const [getAccounts] = useLazyGetAccountsQuery();
  const [getOperators, { data: operators, isFetching: isFetchingOperators }] = useLazyGetOperatorsQuery();
  const [getProfile, { data: profile, isFetching: isFetchingProfile }] = useLazyGetProfileQuery();

  const getProfileOperators = useCallback(
    () =>
      getOperators()
        .unwrap()
        .then((res) => {
          const profileOperators = res.data.operators;

          if (operatorId) {
            const operator = profileOperators.find((operator) => operatorId === operator.id);
            if (operator) {
              ability.update(defineAbilities(operator.permissions));
              dispatch(switchOperator(operator));
              dispatch(setSetupFinished(true));
            }
          } else {
            const defaultOperator = profileOperators.find((operator) => operator.primary);
            if (!defaultOperator) throw new Error("Default operator not found: " + JSON.stringify(profileOperators));
            ability.update(defineAbilities(defaultOperator.permissions));
            dispatch(switchOperator(defaultOperator));
            dispatch(setSetupFinished(true));
          }
        })
        .catch((e) => {
          console.error("Error fetching operators: ", { e });
        }),
    [dispatch, getOperators, operatorId]
  );

  const assignOperator = useCallback(
    (id: string) => {
      if (!operators) return;
      const operator = operators.data.operators.find((i) => i.id === id);
      if (operator) {
        ability.update(defineAbilities(operator.permissions));
        dispatch(switchOperator(operator));
        dispatch(setSetupFinished(true));
      } else throw new Error(`Operator with id ${operatorId} not found`);
    },
    [dispatch, operatorId, operators]
  );

  useEffect(() => {
    getAccounts()
      .unwrap()
      .then((res) => {
        const accounts = res.data.accounts;
        const primaryAccount = accounts.find((account) => account.primary);
        if (!primaryAccount) throw new Error("Default account not found: " + JSON.stringify(accounts));
        dispatch(setActiveAccount(primaryAccount));

        getProfile()
          .unwrap()
          .then(() => getProfileOperators())
          .catch((e) => {
            console.error("Error fetching profile: ", { e });
          });
      })
      .catch((e) => {
        console.warn("Error fetching accounts: ", { e });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (operatorId && activeOperator?.id !== operatorId) assignOperator(operatorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorId]);

  const isFetching = isFetchingOperators || isFetchingProfile;

  return {
    isReady: !isFetching && isReady,
    profile,
    operators: operators?.data.operators,
  } as UseSetupAccount;
};
