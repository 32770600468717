import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Children, ComponentProps, forwardRef, useState } from "react";
import { Divider, IconButton, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export type SidePanelProps = ComponentProps<typeof Dialog> & {
  className?: string;
  open: boolean;
  title?: string;
  description?: string;
  focused?: boolean;
  onClose: () => void;
  width?: number;
  children: React.ReactElement | React.ReactElement[];
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  appear?: boolean;
  childContent?: React.ReactElement;
};

export const SidePanel = forwardRef<HTMLDivElement, React.PropsWithChildren<SidePanelProps>>(
  ({ title, description, open, focused = false, onClose, children, className, width = 400, initialFocus, childContent, ...props }, ref) => {
    const header = Children.toArray(children)[0];
    const body = Children.toArray(children)[1];
    const [openChild, setOpenChild] = useState(false);

    const renderHeader = () => {
      if (header && body) return header;

      return (
        <div className="px-6 py-6">
          <div className="flex items-center justify-between">
            <Typography variant="title">{title}</Typography>
            <div className="ml-3 flex h-7 items-center">
              <IconButton
                iconName="close"
                isCustomIcon
                onClick={onClose}
                data-testid="panel-close-button"
                variant="tertiary"
                className="-mr-4 text-neutral-black hover:bg-transparent"
              />
            </div>
          </div>
          {description && <Typography className="text-neutral-dark-gray">{description}</Typography>}
        </div>
      );
    };

    const renderBody = () => {
      if (header && body) return body;

      return children;
    };

    const handleClose = () => {
      setOpenChild(false);
      onClose();
    };

    return (
      <>
        <Transition show={open} appear>
          <Dialog className="relative z-50" onClose={handleClose} initialFocus={initialFocus} {...props}>
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={clsx("fixed inset-0", { "bg-neutral-gray bg-opacity-75 transition-opacity": focused })} />
            </TransitionChild>

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className={clsx("pointer-events-none fixed inset-y-0 right-0 flex max-w-full", className)}>
                  <TransitionChild
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <DialogPanel className="pointer-events-auto" style={{ width: `${width}px` }}>
                      <div className="flex h-full flex-col bg-white shadow-xl">
                        {renderHeader()}
                        <Divider />
                        <div className="relative max-w-[100vw] flex-1 overflow-auto" ref={ref}>
                          {renderBody()}
                        </div>
                        {!!childContent && !openChild && <SidePanelExpandButton openChild={openChild} setOpenChild={setOpenChild} />}
                        {!!childContent && (
                          <SidePanelChild openChild={openChild} setOpenChild={setOpenChild} body={childContent} parentWidth={width} />
                        )}
                      </div>
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
);

SidePanel.displayName = "SidePanel";

const SidePanelChild = ({
  openChild,
  setOpenChild,
  parentWidth,
  body,
}: {
  openChild: boolean;
  setOpenChild: (value: boolean) => void;
  parentWidth: number;
  body: React.ReactElement;
}) => {
  return (
    <Transition
      show={openChild}
      appear
      enter="transform transition ease-in duration-200"
      enterFrom="translate-x-1/2 opacity-0"
      enterTo="opacity-100 translate-x-0"
      leave="ease-in duration-200 opacity-0"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-1/2"
    >
      <div
        className="pointer-events-none absolute inset-y-0 right-0 z-40 flex max-w-full "
        style={{
          right: `${parentWidth}px`,
        }}
      >
        <div className="pointer-events-auto" style={{ width: `${parentWidth}px` }}>
          {openChild && <SidePanelExpandButton openChild={openChild} setOpenChild={setOpenChild} />}
          <div className="relative flex h-full flex-col overflow-y-auto bg-white p-7 shadow">{body}</div>
        </div>
      </div>
    </Transition>
  );
};

const SidePanelExpandButton = ({ openChild, setOpenChild }: { openChild: boolean; setOpenChild: (value: boolean) => void }) => {
  return (
    <IconButton
      iconName={openChild ? "arrow-right-2" : "arrow-left-2"}
      isCustomIcon
      onClick={() => {
        setOpenChild(!openChild);
      }}
      variant="tertiary"
      className="absolute top-1/2 z-10 -mr-4 -translate-x-5 -translate-y-5 rounded-full bg-white shadow-lg"
    />
  );
};
