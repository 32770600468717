import { Button, Icon, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetCompaniesQuery } from "@/redux/apis/company/companyApi";
import { useAppSelector } from "@/redux/hooks";
import { companiesSelector } from "@/redux/slices/company/selectors";
import { PricingProfile } from "@/redux/slices/pricing/types";
import { clsx, getInitials } from "@/utils";
import { CompaniesAttachedEmptyState } from "../emptyStates/CompaniesAttachedEmptyState";
import { CompaniesAttachedSkeleton } from "./CompaniesAttachedSkeleton";

interface CompaniesAttachedProps {
  item: PricingProfile;
  onClose: () => void;
}

export const CompaniesAttached = ({ item, onClose }: CompaniesAttachedProps) => {
  const { id: operatorId } = useOperator();
  const companies = useAppSelector(companiesSelector);
  const { isFetching } = useGetCompaniesQuery({ pricing_profile_uuid: item.id });
  const companiesClassName = "flex min-h-[345px] max-h-[505px] flex-col gap-y-2 overflow-scroll overflow-y-auto overflow-x-hidden";

  return (
    <>
      <div className="flex items-center">
        <div>
          <Typography variant="title">{isFetching ? "" : `${companies.length} `}Companies Attached</Typography>
        </div>
        <button type="button" className="ml-auto rounded-md text-black" onClick={onClose}>
          <span className="sr-only">Close</span>
          <Icon name="close" isCustom size="lg" aria-hidden="true" />
        </button>
      </div>
      <div className="relative">
        <Typography variant="paragraph" className="mb-4 mt-2.5 text-neutral-dark-gray">
          Here is the list of companies to which this pricing profile is attached.
        </Typography>
        {isFetching ? (
          <CompaniesAttachedSkeleton className={companiesClassName} />
        ) : (
          <div className={companiesClassName}>
            {companies.length > 0 ? (
              companies.map((company, i) => (
                <div key={company.id} className="flex flex-row items-center rounded-2xl bg-neutral-surface-gray py-5 pl-5 pr-2.5">
                  <div className="flex flex-row items-center gap-x-4">
                    <div
                      className={clsx(
                        "flex h-10 w-10 items-center justify-center rounded-full text-white",
                        i % 2 === 0 ? "bg-[#87B1E3]" : "bg-[#87B87F]"
                      )}
                    >
                      <Typography variant="action">{getInitials(company.name)}</Typography>
                    </div>
                    <Typography variant="paragraph">{company.name}</Typography>
                  </div>
                  <div className="flex flex-1 items-center justify-end">
                    <a href={`/dashboard/operator/${operatorId}/companies/${company.id}`} target="_blank" rel="noreferrer">
                      <Button variant="tertiary" startIcon="ExportSquare">
                        View Company
                      </Button>
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <CompaniesAttachedEmptyState />
            )}
          </div>
        )}
      </div>
    </>
  );
};
