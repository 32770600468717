import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, ErrorMessage, Icon, Loading, TextInput, Toggle, Tooltip, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetInvoiceSettingsQuery, useUpdateInvoiceSettingsMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppSelector } from "@/redux/hooks";
import { invoicingSettingsSelector } from "@/redux/slices/payment/selectors";
import { addToast, clsx } from "@/utils";
import { invoiceSetupFormSchema } from "../fixtures";
import { InvoiceSetupFormData } from "../types";

export const InvoiceSetupForm = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const [updateInvoiceSettings, { isLoading }] = useUpdateInvoiceSettingsMutation();
  const { isFetching } = useGetInvoiceSettingsQuery();
  const { details } = useAppSelector(invoicingSettingsSelector);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = useForm<InvoiceSetupFormData>({
    resolver: zodResolver(invoiceSetupFormSchema),
    defaultValues: details,
  });

  useEffect(() => {
    reset(details);
  }, [reset, details]);

  const onSubmit = handleSubmit((data) => {
    const updateInvoiceSettingsData = {
      beneficiary_bank_name: data.beneficiaryBankName,
      beneficiary_bank_address: data.beneficiaryBankAddress,
      account_name: data.accountName,
      account_routing_number: data.bsb,
      account_number: data.accountNumber,
      swift_code: data.swiftCode || undefined,
      pay_by_cc_enabled: Number(data.enablePaymentOfInvoicesViaCreditCard),
      pay_by_cc_pass_on_fees_enabled: Number(data.creditCardFeePassThrough),
    };

    updateInvoiceSettings(updateInvoiceSettingsData)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Invoice Setup");
        setTimeout(() => window.location.reload(), 1250);
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <div className="relative">
      {(isFetching || isLoading) && <Loading />}
      <div className={clsx("relative rounded-lg bg-neutral-surface-gray p-5", className)} {...props}>
        <form onSubmit={onSubmit} className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-1">
            <Typography variant="action">Beneficiary Bank Name</Typography>
            <TextInput maxLength={100} hasError={!!errors.beneficiaryBankName} {...register("beneficiaryBankName")} />
            <ErrorMessage errors={errors} name="beneficiaryBankName" />
          </div>
          <div className="flex flex-col gap-y-1">
            <Typography variant="action">Beneficiary Bank Address</Typography>
            <TextInput maxLength={512} hasError={!!errors.beneficiaryBankAddress} {...register("beneficiaryBankAddress")} />
            <ErrorMessage errors={errors} name="beneficiaryBankAddress" />
          </div>
          <div className="flex flex-col gap-y-1">
            <Typography variant="action">SWIFT Code</Typography>
            <TextInput hasError={!!errors.swiftCode} {...register("swiftCode")} />
            <ErrorMessage errors={errors} name="swiftCode" />
          </div>
          <div className="flex flex-col gap-y-1">
            <Typography variant="action">Account Name</Typography>
            <TextInput maxLength={100} hasError={!!errors.accountName} {...register("accountName")} />
            <ErrorMessage errors={errors} name="accountName" />
          </div>
          <div className="flex flex-row gap-x-5">
            <div className="flex w-full flex-col gap-y-1">
              <Typography variant="action">BSB</Typography>
              <TextInput {...register("bsb")} hasError={!!errors.bsb} />
              <ErrorMessage errors={errors} name="bsb" />
            </div>
            <div className="flex w-full flex-col gap-y-1">
              <Typography variant="action">Account Number</Typography>
              <TextInput type="number" hasError={!!errors.accountNumber} {...register("accountNumber")} />
              <ErrorMessage errors={errors} name="accountNumber" />
            </div>
          </div>
          <div className="flex flex-col gap-y-1">
            <div className="flex flex-row items-center">
              <Controller
                name="enablePaymentOfInvoicesViaCreditCard"
                control={control}
                render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} size="sm" />}
              />
              <Typography variant="paragraph" className="ml-2.5">
                Enable payment of invoices via Credit Card
              </Typography>
            </div>
            <ErrorMessage errors={errors} name="enablePaymentOfInvoicesViaCreditCard" />
          </div>
          <div className="flex flex-col gap-y-1">
            <div className="flex flex-row items-center">
              <Controller
                name="creditCardFeePassThrough"
                control={control}
                render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} size="sm" />}
              />
              <Typography variant="paragraph" className="ml-2.5">
                Credit Card Fee Pass-through
              </Typography>
              <Tooltip
                content="Enabling pass through will add credit card fees to the invoice value upon payment by credit card"
                placement="right"
                maxWidth={200}
              >
                <Icon name="InfoCircle" variant="Bold" className="ml-1.5 rotate-180 text-neutral-dark-gray" />
              </Tooltip>
            </div>
            <ErrorMessage errors={errors} name="creditCardFeePassThrough" />
          </div>
          <div className="flex items-center justify-end">
            <Button type="submit" size="sm">
              Update Invoice Setup
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
