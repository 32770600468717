import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Typography } from "@/components/atoms";
import { VehicleColorInput } from "@/components/molecules";
import { useGetVehicleCategoriesFormOptionsQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { capitalize, clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryOffloadColorsField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isFetching, data } = useGetVehicleCategoriesFormOptionsQuery();
  const {
    control,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  const options = useMemo(() => {
    if (!data) return [];
    return Object.entries(data.color);
  }, [data]);

  if (isFetching || !data) return null;

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="paragraph" className="flex items-center gap-2">
        Vehicle Color(s) for this Vehicle Category
      </Typography>
      <Controller
        name="offloadVehicleColors"
        control={control}
        render={({ field }) => (
          <VehicleColorInput className="mt-1">
            {options.map(([value, hex], i) => (
              <VehicleColorInput.Option
                key={i}
                value={value}
                name={capitalize(value.toLowerCase().replace("_", " "))}
                hex={hex}
                isSelected={field.value ? field.value.includes(value) : false}
                onClick={() =>
                  field.onChange(field.value?.includes(value) ? field.value.filter((v) => v !== value) : [...(field.value || []), value])
                }
              />
            ))}
          </VehicleColorInput>
        )}
      />
      <ErrorMessage errors={errors} name="offloadVehicleColors" />
    </div>
  );
};
