import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AddOnTextInput, Alert, Button, ErrorMessage, IconButton, Loading, RadioInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useDisburseFundsMutation } from "@/redux/apis/booking/endpoints/payment";
import { useAppDispatch } from "@/redux/hooks";
import { setPaymentAction } from "@/redux/slices/booking/bookingSlice";
import { BookingPaymentLedgerLog } from "@/redux/slices/booking/types";
import { addToast, getCurrencySymbol } from "@/utils";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";

const schema = z.object({
  amount: z.coerce.number({ invalid_type_error: "This field is required." }).gte(1),
  receiver: z.string().nonempty(),
});

type FormData = z.infer<typeof schema>;

interface ReturnFundsProps {
  payment: BookingPaymentLedgerLog;
}

export const DisburseFunds = ({ payment }: ReturnFundsProps) => {
  const dispatch = useAppDispatch();
  const {
    id: bookingId,
    cost: { currency },
  } = useSelectedBooking();

  const [disburseFunds, { isLoading }] = useDisburseFundsMutation();

  const {
    register,
    formState: { isDirty, isSubmitting, errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      amount: 0,
      receiver: "driver",
    },
  });

  const onSubmit = async (data: FormData) =>
    disburseFunds({
      bookingId,
      paymentId: payment.id,
      amount: data.amount * 100,
      direct_to: data.receiver,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Funds Disbursed Successfully");
        dispatch(setPaymentAction(undefined));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  const handleClose = () => dispatch(setPaymentAction(undefined));

  return (
    <section onSubmit={handleSubmit(onSubmit)} className="relative m-5 bg-neutral-surface-gray p-5">
      {isLoading && <Loading />}
      <header className="space-y-2">
        <Typography variant="title">Disburse Funds</Typography>
        <Typography variant="paragraph">
          This allows you to send funds which maybe stuck in our platform account to another Stripe connected account. Only do this if you
          are sure the funds are in the wrong place.
        </Typography>
        <Typography variant="paragraph">
          Note: Manual reconciliation of Job records maybe required to ensure values add up. E.g. to pay driver, make sure the driver fields
          are correct and payment flags are reset.
        </Typography>
        <IconButton
          onClick={handleClose}
          iconName="close"
          variant="custom"
          isCustomIcon
          iconSize="lg"
          className="absolute right-5 top-5 !mt-0"
        />
      </header>
      <form className="mt-6 space-y-8 ">
        <Alert type="danger" message="Caution: Proceed only if you are certain." />
        <fieldset className="w-1/2">
          <Typography className="block leading-loose">Enter Disburse Amount</Typography>
          <div className="flex items-center gap-3">
            <AddOnTextInput
              startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
              {...register("amount", { valueAsNumber: true })}
              type="number"
              step="0.01"
              autoFocus
            />
            <ErrorMessage className="leading-loose" errors={errors} name="amount" />
          </div>
        </fieldset>
        <fieldset className="block space-y-2">
          <Typography className="leading-loose">Direct Funds to</Typography>
          <div className="flex gap-8">
            <RadioInput label="Driver" value="driver" {...register("receiver")} />
            <RadioInput label="Provider" value="provider" {...register("receiver")} />
            <RadioInput label="Vehicle" value="vehicle" {...register("receiver")} />
            <RadioInput label="Platform Fee" value="platform" {...register("receiver")} />
            <RadioInput label="Platform Offload Fee" value="platform-offload" {...register("receiver")} />
          </div>
        </fieldset>
        <div className="flex justify-end">
          <Button variant="secondary" onClick={handleClose} className="mr-4" size="md">
            Cancel Disburse
          </Button>
          <Button type="submit" disabled={isSubmitting || !isDirty} size="md">
            Process Disbursement
          </Button>
        </div>
      </form>
    </section>
  );
};
