import { Controller, FieldErrors, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { SignUpOperatorFormData } from "../types";

export const BusinessRegistrationTypeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext<SignUpOperatorFormData>();

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <Typography>Business Registration Type</Typography>
      <Controller
        control={control}
        name="businessRegistration.businessRegistrationType"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Type"
            options={[
              { name: "ABN", value: "ABN" },
              { name: "ACN", value: "ACN" },
            ]}
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              clearErrors("businessRegistration.businessRegistrationIdNumber");
            }}
            hasError={!!errors.businessRegistration?.businessRegistrationType}
          />
        )}
      />
      {errors.businessRegistration?.businessRegistrationType && <ErrorMessage errors={errors.businessRegistration as FieldErrors} name="businessRegistrationType" />}
    </div>
  );
};
