import { forwardRef } from "react";
import { clsx } from "@/utils";

export interface FileInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const { accept = "image/*,.pdf", className } = props;
  return (
    <input
      type="file"
      accept={accept}
      className={clsx("rounded-lg bg-neutral-surface-gray p-3 font-sans text-neutral-dark-gray", className)}
      ref={ref}
      {...props}
    />
  );
});

FileInput.displayName = "FileInput";
