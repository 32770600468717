import { payInvoiceApi } from "@/redux/apis";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PayInvoiceState } from "./types";

const initialState: PayInvoiceState = {
  payByCreditCard: {
    paymentStep: 1,
  },
};

export const payInvoiceSlice = createSlice({
  name: "payInvoice",
  initialState,
  reducers: {
    setPayByCreditCardStep: (state, { payload }: PayloadAction<number>) => {
      state.payByCreditCard.paymentStep = payload;
    },
    setPaymentMethodSetupId: (state, { payload }: PayloadAction<string>) => {
      state.payByCreditCard.paymentMethodSetupId = payload;
    },
    resetPayByCreditCard: (state) => {
      state.payByCreditCard = { paymentStep: 1 };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(payInvoiceApi.endpoints.getPayInvoice.matchFulfilled, (state, { payload }) => {
      state.payInvoice = payload.data;
    }),
      builder.addMatcher(payInvoiceApi.endpoints.getPaymentMethodSecret.matchFulfilled, (state, { payload }) => {
        state.payByCreditCard.paymentMethodSecret = payload.data.paymentMethodSecret;
      }),
      builder.addMatcher(payInvoiceApi.endpoints.getPaymentMethodDetails.matchFulfilled, (state, { payload }) => {
        state.payByCreditCard.paymentMethodDetails = payload.data;
      }),
      builder.addMatcher(payInvoiceApi.endpoints.getManualPaymentDetails.matchFulfilled, (state, { payload }) => {
        state.payByCreditCard.manualPaymentDetails = payload.data;
      }),
      builder.addMatcher(payInvoiceApi.endpoints.triggerManualPayment.matchFulfilled, (state, { payload }) => {
        state.payByCreditCard.manualPaymentDetails = payload.data;
      });
  },
});

export const { setPayByCreditCardStep, setPaymentMethodSetupId, resetPayByCreditCard } = payInvoiceSlice.actions;
export default payInvoiceSlice.reducer;
