import { PageHeader, TransferTypesPanel } from "@/components/organisms";
import { Nav } from "../VehicleCategories/VehicleCategories";
import { TransferTypesAdd } from "./TransferTypesAdd";
import { TransferTypesEdit } from "./TransferTypesEdit";

export const TransferTypes = () => {
  return (
    <>
      <PageHeader.Actions />
      <Nav />
      <TransferTypesPanel />
    </>
  );
};

TransferTypes.Edit = TransferTypesEdit;
TransferTypes.Add = TransferTypesAdd;
