import { useEffect } from "react";

type InfinitScrollParams = {
  scroller: HTMLDivElement | Window | null;
  hasMore: boolean;
  pageStart: number;
  loadMore: (value: string) => void;
  isFetching: boolean;
};

export const useInfiniteScroll = ({ scroller, hasMore, pageStart, loadMore, isFetching }: InfinitScrollParams) => {
  useEffect(() => {
    const onScroll = () => {
      if (scroller && hasMore && !isFetching) {
        const isWindow = scroller.toString() === "[object Window]";
        if (isWindow) {
          // scrollHight is subtracted by 1 to give allowance
          const windowScroller = scroller as Window;
          const scrolledToBottom = windowScroller.innerHeight + windowScroller.scrollY >= window.document.documentElement.scrollHeight - 1;
          if (scrolledToBottom) loadMore((pageStart + 1).toString());
        } else {
          // scrollHight is subtracted by 1 to give allowance
          const elementScroller = scroller as HTMLDivElement;
          const scrolledToBottom = elementScroller.offsetHeight + elementScroller.scrollTop >= elementScroller.scrollHeight - 1;
          if (scrolledToBottom) loadMore((pageStart + 1).toString());
        }
      }
    };

    // if the page is expecting more data and it's long enough to display more than one page, it will try to load more data until it becomes scrollable
    onScroll();

    if (scroller) scroller.addEventListener("scroll", onScroll);

    return () => {
      if (scroller) scroller.removeEventListener("scroll", onScroll);
    };
  }, [scroller, hasMore, pageStart, loadMore, isFetching]);
};
