import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { AddOnTextInput, Button, Loading, Panel, Toggle, Typography, Modal, Skeleton, ErrorMessage, Divider, TextInput } from "@/components/atoms";
import { ConfigDetail, DisclosurePanel } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetBillingSettingsQuery, useUpdateBillingSettingsMutation } from "@/redux/apis/config/admin/billingSettings";
import { useAppSelector } from "@/redux/hooks";
import { billingSettingsSelector } from "@/redux/slices/admin/selectors";
import { addToast } from "@/utils/addToast";

const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save Billing Settings",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

const formSchema = z.object({
  defaultOperatorCommissionRate: z.coerce.number().min(0).max(100).step(1, "Field must be a whole number."),
  enabledPassOnCreditCardFees: z.boolean(),
  defaultServiceFeeRate: z.coerce.number().min(0).max(100).step(1, "Field must be a whole number."),
  enabledOperatorPaysOwnDrivers: z.boolean(),
  enabledPriceInGuestEmail: z.boolean(),
  closeJobAutoApproveValue: z.coerce.number().min(0).max(99999).step(0.01, "Field must be a maximum of 2 decimal places."),
});

type FormData = z.infer<typeof formSchema>;

export const BillingSettingsEdit = () => {
  const { isFetching } = useGetBillingSettingsQuery();
  const [updateBillingSettings, { isLoading }] = useUpdateBillingSettingsMutation();
  const { details } = useAppSelector(billingSettingsSelector);
  const defaultValues = useMemo(
    () => ({
      defaultOperatorCommissionRate: Number(details.defaultOperatorCommissionRate),
      enabledPassOnCreditCardFees: details.enabledPassOnCreditCardFees,
      defaultServiceFeeRate: Number(details.defaultServiceFeeRate),
      enabledOperatorPaysOwnDrivers: details.enabledOperatorPaysOwnDrivers,
      enabledPriceInGuestEmail: details.enabledPriceInGuestEmail,
      closeJobAutoApproveValue: Number(details.closeJobAutoApproveValue),
    }),
    [details]
  );
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isDirty, dirtyFields, isSubmitted, errors },
  } = useForm<FormData>({
    defaultValues,
    resolver: zodResolver(formSchema),
  });
  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as FormData;
    const { defaultOperatorCommissionRate, enabledPassOnCreditCardFees, defaultServiceFeeRate, enabledOperatorPaysOwnDrivers, enabledPriceInGuestEmail, closeJobAutoApproveValue } = {
      ...dirtyValues,
    };

    setActiveModal(null);
    updateBillingSettings({
      default_operator_commission_rate: defaultOperatorCommissionRate,
      enabled_pass_on_credit_card_fees: enabledPassOnCreditCardFees,
      default_service_fee_rate: defaultServiceFeeRate,
      enabled_operator_pays_own_drivers: enabledOperatorPaysOwnDrivers,
      enabled_price_in_guest_email: enabledPriceInGuestEmail,
      close_job_auto_approve_value: isNaN(closeJobAutoApproveValue) ? undefined : closeJobAutoApproveValue * 100,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Updated Billing Settings");
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions>
        {entry && !inView && (
          <div className="ml-auto flex animate-enter gap-3">
            <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" disabled={!isDirty || isLoading}>
              Save
            </Button>
          </div>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Billing Settings
              </Typography>
              <Typography className="text-neutral-dark-gray">Set the Billing Settings for this site.</Typography>
            </div>
            <div className="flex gap-3" ref={ref}>
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>

          <ConfigDetail title="Pass on Credit Card Fees to Client" description="When enabled, fees will be passed onto the client">
            <div className="flex flex-row items-center gap-x-2">
              <Controller
                name="enabledPassOnCreditCardFees"
                control={control}
                render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
              />
              <ErrorMessage errors={errors} name="enabledPassOnCreditCardFees" />
            </div>
          </ConfigDetail>
          <ConfigDetail title="Service Fee %" description="Apply a service fee on the entire trip cost for every booking">
            <div className="flex flex-row items-center gap-x-2">
              <AddOnTextInput
                type="number"
                className="w-[100px]"
                endAddOn="%"
                step="any"
                hasError={!!errors.defaultServiceFeeRate}
                {...register("defaultServiceFeeRate")}
              />
              <ErrorMessage errors={errors} name="defaultServiceFeeRate" />
            </div>
          </ConfigDetail>
          <ConfigDetail
            title="Default Operator Commission Rate (e.g. 30%)"
            description="% commission that operator wants to keep by default."
          >
            <div className="flex flex-row items-center gap-x-2">
              <AddOnTextInput
                type="number"
                className="w-[100px]"
                endAddOn="%"
                step="any"
                hasError={!!errors.defaultOperatorCommissionRate}
                {...register("defaultOperatorCommissionRate")}
              />
              <ErrorMessage errors={errors} name="defaultOperatorCommissionRate" />
            </div>
          </ConfigDetail>
          <ConfigDetail title="Operator pays own drivers" description="Does operator want to pay their drivers?">
            <div className="flex flex-row items-center gap-x-2">
              <Controller
                name="enabledOperatorPaysOwnDrivers"
                control={control}
                render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
              />
              <ErrorMessage errors={errors} name="enabledOperatorPaysOwnDrivers" />
            </div>
          </ConfigDetail>
          <ConfigDetail title="Send price information in guest emails" description="In all booking confirmations to guest passengers, include pricing">
            <div className="flex flex-row items-center gap-x-2">
              <Controller
                name="enabledPriceInGuestEmail"
                control={control}
                render={({ field }) => <Toggle checked={field.value} onChange={(checked) => field.onChange(checked)} />}
              />
              <ErrorMessage errors={errors} name="enabledPriceInGuestEmail" />
            </div>
          </ConfigDetail>
          <Divider className="my-6" />
          <DisclosurePanel title="Advanced Settings" className="flex flex-col">
            <ConfigDetail title="Close Job Auto-Approve" description="Specify a threshold for extras. Input an amount to auto-approve extras below this value. '0' auto-approves all. Default is '0.01', sending all extras for approval." hideDivider>
              <div className="flex flex-col">
                <div className="flex flex-row items-center gap-x-2">
                  <TextInput
                    type="number"
                    size="lg"
                    className="w-[182px]"
                    placeholder="0"
                    hasError={!!errors.closeJobAutoApproveValue}
                    step="any"
                    {...register("closeJobAutoApproveValue")}
                  />
                  <ErrorMessage errors={errors} name="closeJobAutoApproveValue" />
                </div>
                <Typography variant="small" className="text-neutral-dark-gray">Enter an amount</Typography>
              </div>
            </ConfigDetail>
          </DisclosurePanel>
        </div>
      </Panel>
    </form>
  );
};
