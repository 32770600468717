import { clsx } from "@/utils";

export const InvoiceNotesSkeleton = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const renderSkeletonItem = () => {
    return (
      <div className="flex flex-col rounded-lg bg-gray-200 px-5 pb-2.5 pt-5">
        <div className="flex flex-row items-center gap-x-3">
          <div className="flex h-8 w-8 flex-shrink-0 rounded-full bg-gray-300" />
          <div className="h-4 w-32 rounded-2xl bg-gray-300" />
        </div>
        <div className="mt-3 flex flex-col gap-y-2">
          <div className="h-4 w-80 rounded-md bg-gray-300" />
          <div className="h-4 w-80 rounded-md bg-gray-300" />
          <div className="h-4 w-80 rounded-md bg-gray-300" />
        </div>
        <div className="mt-2.5 h-4 w-44 rounded-md bg-gray-300" />
      </div>
    );
  };

  const skeletonItems = Array.from({ length: 2 }, (_, x: number) => <div key={x}>{renderSkeletonItem()}</div>);

  return (
    <div className={clsx("animate-pulse", className)} {...props}>
      {skeletonItems}
    </div>
  );
};
