import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AddOnTextInput, Button, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateAsapPricingMutation } from "@/redux/apis/config/pricing/cancellationFees";
import { addToast, clsx, getCurrencySymbol } from "@/utils";
import { AsapPricingFormData } from "./types";
import { useAppSelector } from "@/redux/hooks";
import { asapPricingSelector } from "@/redux/slices/pricing/selectors";

const onError = (messages: string[]) => {
  messages.forEach((m) => addToast("danger", m));
};

export const AsapPricingEditor = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [updateAsapPricing, { isLoading }] = useUpdateAsapPricingMutation();
  const { cancellationFee, noFeeWindow, currency } = useAppSelector(asapPricingSelector);

  const { register, handleSubmit } = useForm<AsapPricingFormData>({
    defaultValues: {
      noFeeWindow: noFeeWindow / 60,
      cancellationFee: cancellationFee / 100,
    },
  });

  const onSubmit = handleSubmit((data) => {
    updateAsapPricing({ cancellation_fee: data.cancellationFee * 100, fee_free_window: data.noFeeWindow * 60 })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully updated asap cancellation settings.");
        navigate(-1);
      })
      .catch((e) => onError(getErrorMessages(e)));
  });

  return (
    <div className={clsx("flex flex-col gap-4 sm:flex-row sm:items-center", className)}>
      <div
        className={clsx("border-1 relative w-[300px] rounded-lg border border-neutral-mid-gray bg-white p-4", {
          "pointer-events-none opacity-50": type !== "free-window",
        })}
        {...props}
      >
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Typography variant="action">Max time for free cancellation</Typography>
            <form className="mt-2 flex items-center gap-4" onSubmit={onSubmit}>
              <AddOnTextInput
                type="number"
                endAddOn="Minutes"
                className="[&>input]:text-right"
                {...register("noFeeWindow")}
                required
                step="1"
                min="0"
                max="999"
              />
              <Button type="submit" className="sm:py-1.5">
                Save
              </Button>
            </form>
          </>
        )}
      </div>
      <div
        className={clsx("border-1 relative w-[300px] rounded-lg border border-neutral-mid-gray bg-white p-4", {
          "pointer-events-none opacity-50": type !== "cancellation-fee",
        })}
        {...props}
      >
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Typography variant="action">Cancellation Fee</Typography>
            <form className="mt-2 flex items-center gap-4" onSubmit={onSubmit}>
              <AddOnTextInput
                type="number"
                startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
                {...register("cancellationFee")}
                required
                step="0.01"
                min="0"
                max="999999999"
              />
              <Button type="submit" className="sm:py-1.5">
                Save
              </Button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
