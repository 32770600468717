import { Button, Icon, Modal, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { copyToClipboard } from "@/utils";
import { CodeBlock, dracula } from "@react-email/code-block";
import { WidgetType } from "./type";

interface WidgetModalProps {
  open: boolean;
  onClose: () => void;
  type: WidgetType;
}

export const WidgetModal = ({ open, onClose, type }: WidgetModalProps) => {
  const { id } = useOperator();
  const code = `<iframe style="display: block; width: 100%; height: 100%; min-height: 454px;" title="Get an instance quote" src="https://widgets.rideminder.com/instant-quote/?provider=${id}${type === "branded" ? "&use_branding=1" : ""}"></iframe>`;

  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Easily Integrate Widgets on Your Website
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
        </div>
      </div>
    </div>
  );

  const renderButtons = () => (
    <div className="mt-8 flex flex-col">
      <Button size="lg" variant="primary" onClick={() => copyToClipboard(code)}>
        Copy HTML Code
      </Button>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <Typography variant="paragraph" className="mt-3 mb-6 text-neutral-dark-gray">Enhance your website&apos;s functionality effortlessly with our seamless widget integration, allowing visitors to request instant transport quotations.</Typography>
      <CodeBlock
        style={{textWrap: "wrap"}}
        code={code}
        theme={dracula}
        language="javascript"
      />
      {renderButtons()}
    </Modal>
  );
};
