import { capitalize } from "@/utils";

export const getVehicleTypeLabel = (value: string) => {
  switch (value) {
    case "SUV_COMPACT":
      return "Compact SUV";
    case "SUV_MID":
      return "Mid-size SUV";
    case "SUV_FULL":
      return "Full-size SUV";

    default:
      return capitalize(value.toLowerCase().replace("_", " "));
  }
};
