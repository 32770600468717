import {
  GetEmailNotificationsRawResponse,
  GetEmailNotificationsResponse,
  UpdateEmailNotificationsRawResponse,
  UpdateEmailNotificationsResponse,
} from "./types";

export const transformGetEmailNotifications = (res: GetEmailNotificationsRawResponse) => {
  const { _links, ...notifications } = res;
  const response: GetEmailNotificationsResponse = {
    data: {
      details: {
        phoneDriverHotline: notifications.phone_driver_hotline,
        emailDriverHotline: notifications.email_driver_hotline,
        enabledBccAllEmails: notifications.enabled_bcc_all_emails,
        emailAddressBccAll: notifications.email_address_bcc_all,
        enabledBookingNotifications: notifications.enabled_booking_notifications,
        emailBookingNotifications: notifications.email_booking_notifications,
        enabledDriverNotifications: notifications.enabled_driver_notifications,
        emailDriverNotifications: notifications.email_driver_notifications,
        enabledLowJobRatingNotifications: notifications.enabled_low_job_rating_notifications,
        emailLowJobNotifications: notifications.email_low_job_notifications,
        enabledMemberRegistrationNotifications: notifications.enabled_member_registration_notifications,
        emailMemberRegistrationNotifications: notifications.email_member_registration_notifications,
        enabledFailedPaymentNotifications: notifications.enabled_failed_payment_notifications,
        emailFailedPaymentNotifications: notifications.email_failed_payment_notifications,
        emailQuoteNotifications: notifications.email_quote_notifications,
        enabledQuoteNotifications: notifications.enabled_quote_notifications,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateEmailNotifications = (res: UpdateEmailNotificationsRawResponse) => {
  return {
    links: res._links,
  } as UpdateEmailNotificationsResponse;
};
