import { useInView } from "react-intersection-observer";
import { Panel, Typography, Skeleton, URLDetail, LinkButton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetWebsiteDetailsQuery } from "@/redux/apis/config/admin/websiteDetails";
import { useAppSelector } from "@/redux/hooks";
import { websiteDetailsSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { WebsiteDetailsEdit } from "./WebsiteDetailsEdit";

export const WebsiteDetails = () => {
  const { isFetching } = useGetWebsiteDetailsQuery();
  const { errors, details } = useAppSelector(websiteDetailsSelector);
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <LinkButton className="animate-enter" startIcon="Edit2" to="edit">
            Edit
          </LinkButton>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Website Details
            </Typography>
            <Typography className="text-neutral-dark-gray">Update your Website details here.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <LinkButton startIcon="Edit2" to="edit">
              Edit
            </LinkButton>
          </div>
        </div>
        {renderErrors(errors)}
        <ConfigDetail title="Brochure Website URL" description="Your public website address.">
          <URLDetail href={details.brochureUrl} />
        </ConfigDetail>
        <ConfigDetail title="Terms & Conditions URL" description="">
          <URLDetail href={details.termsAndConditionsUrl} />
        </ConfigDetail>
        <ConfigDetail title="Meeting points URL" description="A directory of common pickup / drop-off locations.">
          <URLDetail href={details.meetingPointsUrl} />
        </ConfigDetail>
        <ConfigDetail title="Price Guide URL" description="">
          <URLDetail href={details.priceGuideUrl} />
        </ConfigDetail>
        <ConfigDetail title="Find your Driver URL" description="What to do when a passenger cannot find their driver.">
          <URLDetail href={details.findYourDriverUrl} />
        </ConfigDetail>
        <ConfigDetail title="Google Reviews URL" description="https://goo.gl/maps/XXXXXXXXXXXX">
          <URLDetail href={details.googleReviewsUrl} />
        </ConfigDetail>
      </Panel>
    </>
  );
};

WebsiteDetails.Edit = WebsiteDetailsEdit;
