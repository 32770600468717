import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const PaymentTermsField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-1", className)} {...props}>
      <Typography>Payment Terms</Typography>
      <Controller
        control={control}
        name="paymentTerms"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Payment Terms"
            options={[...Array(360).keys()].map((i) => ({
              name: `${i + 1} Days`,
              value: `${i + 1}`,
            }))}
            value={field.value?.toString()}
            onChange={(value) => field.onChange(value)}
            hasError={!!errors.paymentTerms}
          />
        )}
      />
      <ErrorMessage errors={errors} name="paymentTerms" />
    </div>
  );
};
