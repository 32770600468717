import { useSearch } from "@/hooks";
import { GetClientsParams } from "@/redux/apis/client/types";
import { useState } from "react";

export const useGetAddOnsParams = () => {
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useState<string>();

  const params: GetClientsParams = {
    search: search ?? "",
    sort: sort ?? "",
  };

  return {
    params,
    search,
    setSearch,
    setSort,
  };
};
