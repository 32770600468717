import { Recaptcha } from "@/components/atoms";
import { clsx } from "@/utils";
import { PlatformFitField, PlatformGoalsField } from "../forms/fields";

interface SignUpFormOperatorStep5Props extends React.HTMLAttributes<HTMLDivElement> {
  onRecaptchaChange: (token: string | null) => void;
}

export const SignUpFormOperatorStep5 = ({ onRecaptchaChange, className, ...props }: SignUpFormOperatorStep5Props) => {
  return (
    <div className={clsx("animate-enter grid grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6", className)} {...props}>
      <PlatformGoalsField className="col-span-2" />
      <PlatformFitField className="col-span-2" />
      <Recaptcha onChange={onRecaptchaChange} />
    </div>
  );
};
