import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Icon, Tooltip, Typography } from "@/components/atoms";
import { VehicleCategoryList } from "@/redux/slices/pricing/types";
import { clsx } from "@/utils";
import { VehicleCategoryFormData } from "../../types";

interface GlobalVehicleFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  items: VehicleCategoryList;
}

export const GlobalVehicleField = ({ items, className, ...props }: GlobalVehicleFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<VehicleCategoryFormData>();

  const options = items.map(({ name, id }) => ({ value: id, name }));

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph">Map to a Global Vehicle</Typography>
        <Tooltip
          content="When offloading jobs to your public & private networks, jobs will be offered to vehicles which also map to this same global vehicle category. If left blank, you will be unable to offload."
          placement="right"
        >
          <span>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
          </span>
        </Tooltip>
      </div>
      <Controller
        control={control}
        name="globalVehicle"
        render={({ field }) => (
          <DropdownSelect
            className="w-full"
            placeholder="Select Global Vehicle Category"
            options={options}
            value={field.value}
            onChange={(value) => field.onChange(value)}
            hasError={!!errors.globalVehicle}
            isNullable
          />
        )}
      />
      <ErrorMessage errors={errors} name="globalVehicle" />
    </div>
  );
};
