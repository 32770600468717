import { Blockquote, Button, Divider } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useCloseJobNoExtrasMutation } from "@/redux/apis/booking/endpoints/closeJob";
import { BookingDetails } from "@/redux/slices/booking/types";
import { addToast, getAssetUrl } from "@/utils";
import { useSelectedBookingId } from "../../../hooks/useSelectedBookingId";

interface CloseNoExtrasProps {
  booking: BookingDetails;
  onClose: () => void;
}
export const CloseNoExtras = ({ booking, onClose }: CloseNoExtrasProps) => {
  const { id: bookingId, driver, closeMeta } = booking;
  const [_, setSelectedBookingId] = useSelectedBookingId();
  const [closeJob, { isLoading: isCompletingJob }] = useCloseJobNoExtrasMutation();

  const handleApproveForPayment = () => {
    closeJob({ bookingId })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Closed Job J${bookingId} with “Completed - No Extras“`);
        setSelectedBookingId(undefined);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <div className="mt-5 flex flex-1 flex-col">
      <div className="flex-1 space-y-5">
        {closeMeta?.driverNotes && (
          <Blockquote avatarURL={getAssetUrl(driver?.avatar)} heading={driver!.name} subheading="Driver Notes">
            {closeMeta?.driverNotes}
          </Blockquote>
        )}
      </div>
      <div className="w-full">
        <Divider className="-mx-5" />
        <Button onClick={handleApproveForPayment} disabled={isCompletingJob} className="mt-5 w-full" variant="primary" size="lg">
          Approve For Payment
        </Button>
      </div>
    </div>
  );
};
