import { useState } from "react";
import { DataTableSetSorting, DataTableSorting } from "@/components/molecules";

export const useSortingState = () => {
  const [sort, setSort] = useState("");
  const [direction, setDirection] = useState("");
  const sorting: DataTableSorting = sort && direction ? [{ id: sort, desc: direction === "desc" }] : [];

  const setSorting: DataTableSetSorting = (updaterOrValue) => {
    let newSorting: DataTableSorting = [];

    if (typeof updaterOrValue === "function") {
      newSorting = updaterOrValue(sorting);
    } else {
      newSorting = updaterOrValue;
    }

    if (newSorting.length < 1) {
      setSort("");
      setDirection("");
    } else {
      setSort(newSorting[0].id ?? "");
      setDirection(newSorting[0].desc ? "desc" : "asc");
    }
  };

  return {
    sorting,
    setSorting,
    sortKey: sort,
    direction,
  };
};
