import { PayInvoicePanel } from "@/components/organisms/PayInvoice";
import { PayInvoicePageContextProvider } from "@/components/organisms/PayInvoice/context";

export const PayInvoice = () => {
  return (
    <PayInvoicePageContextProvider>
      <PayInvoicePanel />
    </PayInvoicePageContextProvider>
  );
};

// InvoicePayment.Rejected = BookingOfferRejected;
// InvoicePayment.NotFound = BookingOfferNotFound;
