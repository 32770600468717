import { useMemo } from "react";
import { Loading } from "@/components/atoms";
import { DataTable, DataTableSetSorting, DataTableSorting, NoFilterResults, NoSearchResults } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { useReactivateAccountMutation } from "@/redux/apis/client/clientApi";
import { Client } from "@/redux/slices/client/types";
import { addToast, clsx } from "@/utils";
import { generateClientColumns, generateClientData } from "./helpers";

interface ClientsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: Client[];
  sorting: DataTableSorting;
  setSorting: DataTableSetSorting;
  setPendingRemoveAccess: (client: Client) => void;
  isSearchActive: boolean;
}

export const ClientsTable = ({
  items,
  sorting,
  setSorting,
  setPendingRemoveAccess,
  isSearchActive,
  className,
  ...props
}: ClientsTableProps) => {
  const { dateFormat } = useProfile();
  const [reactivateAccount, { isLoading }] = useReactivateAccountMutation();

  const onReactivateClick = (client: Client) => {
    reactivateAccount(client.id)
      .unwrap()
      .then(() => {
        addToast("success", `Reactivated access for ${client.name}`);
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const data = useMemo(
    () => generateClientData(items, dateFormat, setPendingRemoveAccess, onReactivateClick),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateClientColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      {isLoading && <Loading />}
      <DataTable
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns}
        data={data}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={isSearchActive ? <NoSearchResults /> : <NoFilterResults title="No Client Found" />}
        {...props}
      />
    </div>
  );
};
