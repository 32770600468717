import { createSlice } from "@reduxjs/toolkit";
import { resourceApi } from "@/redux/apis";
import { ResourceState } from "./types";

const initialState: ResourceState = {
  states: [],
};

export const resourceSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(resourceApi.endpoints.getCountryStates.matchFulfilled, (state, { payload }) => {
      state.states = payload.data.states;
    });
  },
});

export default resourceSlice.reducer;
