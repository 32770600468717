import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setConfig } from "@/redux/slices/config/configSlice";
import { configSelector } from "@/redux/slices/config/selectors";
import { SiteConfig } from "@/redux/slices/config/types";
import { transformRMConfig } from "../helpers";

type UseRMConfig =
  | {
      isReady: false;
      config?: undefined;
    }
  | {
      isReady: true;
      config: SiteConfig;
    };

const RMConfig = window.rm ? window.rm.config : undefined;

export const useRMConfig = (): UseRMConfig => {
  const dispatch = useAppDispatch();
  const config = useAppSelector(configSelector);

  useEffect(() => {
    if (RMConfig) {
      dispatch(setConfig(transformRMConfig(RMConfig)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!config) return { isReady: false, config: undefined };
  return { isReady: true, config } satisfies UseRMConfig;
};
