import { useMemo } from "react";
import { DataTable, Pagination } from "@/components/molecules";
import { usePagination, useProfile, useSorting } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { debitUpcomingSelector } from "@/redux/slices/payment/selectors";
import { DebitUpcomingItem } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generateCurrentPendingDebitAmountData, generateCurrentPendingDebitAmountColumns } from "./helpers";

interface CurrentPendingDebitAmountTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: DebitUpcomingItem[];
}

export const CurrentPendingDebitAmountTable = ({ items, className, ...props }: CurrentPendingDebitAmountTableProps) => {
  const { dateFormat } = useProfile();
  const { sorting, setSorting } = useSorting();
  const {
    summary: { currency },
  } = useAppSelector(debitUpcomingSelector);

  const data = useMemo(
    () => generateCurrentPendingDebitAmountData(items, dateFormat, currency),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateCurrentPendingDebitAmountColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} sorting={sorting} setSorting={setSorting} {...props} />
    </div>
  );
};

const CurrentPendingDebitAmountTablePagination = () => {
  const {
    page: { current, count, total, size },
  } = useAppSelector(debitUpcomingSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

CurrentPendingDebitAmountTable.Pagination = CurrentPendingDebitAmountTablePagination;
