import { Icon, Typography, Modal, Alert } from "@/components/atoms";
import { isEmpty } from "ramda";

interface ClientsImportResultModalProps {
  importCount: number;
  errors: Array<string>;
  open: boolean;
  onClose: () => void;
}

export const ClientsImportResultModal = ({ importCount, errors, open, onClose }: ClientsImportResultModalProps) => {  
  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Clients Import Result
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="relative w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {renderHeader()}
      <div className="flex flex-col gap-y-2 py-3">
        <Typography variant="action">Number of Clients Imported: {importCount}</Typography>
        {!isEmpty(errors) && (
          <div className="flex flex-col gap-y-1 max-h-[40vh] overflow-auto">
            <Typography variant="action">Error List:</Typography>
            {errors.map(error => (
              <Alert key={error} type="danger" message={error} />
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
