import { Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useDeletePricingProfileMutation } from "@/redux/apis/config/pricing/tripCost";
import { PricingProfile } from "@/redux/slices/pricing/types";
import { addToast } from "@/utils";
import { useNavigate } from "react-router-dom";

interface DeletePricingProfileFormProps {
  item: PricingProfile;
  onClose: () => void;
}

export const DeletePricingProfileForm = ({ item, onClose }: DeletePricingProfileFormProps) => {
  const navigate = useNavigate();
  const [deletePricingProfile, { isLoading }] = useDeletePricingProfileMutation();

  const onDelete = () => {
    deletePricingProfile({ id: item.id })
      .unwrap()
      .then(() => {
        onClose();
        addToast("success", "Successfully Deleted Pricing Profile");
        navigate("../");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <>
      <div className="flex items-center">
        <Typography variant="title">Delete Profile</Typography>
      </div>
      <div className="relative">
        {isLoading && <Loading />}
        <div className="mt-3 flex flex-col gap-y-[22px]">
          <Typography variant="paragraph">
            Are you sure you want to delete this profile? Deleting it will permanently remove all associated data and you won&apos;t be able
            to recover it. Do you wish to continue?
          </Typography>
          <div className="flex flex-row justify-end gap-x-4">
            <Button variant="secondary" onClick={onClose} size="md">
              Cancel
            </Button>
            <Button variant="primary" onClick={onDelete} size="md" className="bg-danger">
              Delete
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
