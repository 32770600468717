import { invalidateOn } from "@/helpers/reduxHelpers";
import { SearchParams } from "@/redux/types";
import { createSearchParams } from "@/utils";
import { paymentApi } from "../paymentApi";
import {
  transformStripe,
  transformDebitsSecret,
  transformDebitsDetails,
  transformDebitsHistory,
  transformDepositsDetails,
  transformDepositsHistory,
} from "./helpers";
import {
  ConnectStripeParams,
  ConnectStripeResponse,
  GetDebitsSecretResponse,
  GetDebitsSecretParams,
  GetDebitsDetailsResponse,
  GetDebitsHistoryResponse,
  GetDepositsDetailsResponse,
  GetDepositsHistoryResponse,
  UpdateDepositsDetailsResponse,
  UpdateDepositsDetailsParams,
} from "./types";

export const stripeApi = paymentApi
  .enhanceEndpoints({
    addTagTypes: ["DepositsDetails"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      connectStripe: builder.mutation<ConnectStripeResponse, ConnectStripeParams>({
        query: (body) => ({
          url: "/configuration/stripe/connect",
          method: "POST",
          body,
        }),
        transformResponse: transformStripe,
      }),
      getDebitsSecret: builder.mutation<GetDebitsSecretResponse, GetDebitsSecretParams>({
        query: (body) => ({
          url: "/configuration/stripe/payment-method/debit",
          method: "POST",
          body,
        }),
        transformResponse: transformDebitsSecret,
      }),
      getDebitsDetails: builder.query<GetDebitsDetailsResponse, void>({
        query: () => "/configuration/stripe/payment-method/debit",
        transformResponse: transformDebitsDetails,
      }),
      getDebitsHistory: builder.query<GetDebitsHistoryResponse, SearchParams>({
        query: (params) => `/configuration/stripe/payment-method/debit/history?${createSearchParams(params).toString()}`,
        transformResponse: transformDebitsHistory,
      }),
      getDepositsDetails: builder.query<GetDepositsDetailsResponse, void>({
        query: () => "/configuration/stripe/payment-method/deposit",
        transformResponse: transformDepositsDetails,
        providesTags: ["DepositsDetails"],
      }),
      getDepositsHistory: builder.query<GetDepositsHistoryResponse, SearchParams>({
        query: (params) => `/configuration/stripe/payment-method/deposit/history?${createSearchParams(params).toString()}`,
        transformResponse: transformDepositsHistory,
      }),
      updateDepositsDetails: builder.mutation<UpdateDepositsDetailsResponse, UpdateDepositsDetailsParams>({
        query: (body) => ({
          url: "/configuration/stripe/payment-method/deposit",
          method: "POST",
          body,
        }),
        invalidatesTags: invalidateOn({ success: ["DepositsDetails"] }),
      }),
    }),
  });

export const {
  useConnectStripeMutation,
  useGetDebitsSecretMutation,
  useGetDebitsDetailsQuery,
  useLazyGetDebitsDetailsQuery,
  useGetDebitsHistoryQuery,
  useGetDepositsDetailsQuery,
  useGetDepositsHistoryQuery,
  useUpdateDepositsDetailsMutation,
} = stripeApi;
