import { createSearchParams } from "@/utils";
import { bookingApi, updateBookingQueryData } from "../bookingApi";
import { CloseJobWithExtraParams, GetCloseJobOptionsParams, GetCloseJobOptionsRawResponse, RawBookingDetails } from "../types";

export const closeEndpoints = bookingApi.injectEndpoints({
  endpoints: (builder) => ({
    getCloseOptions: builder.query<GetCloseJobOptionsRawResponse, GetCloseJobOptionsParams & { bookingId: string }>({
      query: ({ bookingId, ...params }) => ({
        url: `/${bookingId}/status/close/options?${createSearchParams(params).toString()}`,
      }),
      transformResponse: (response: GetCloseJobOptionsRawResponse) => ({
        additional_run_time: response.additional_run_time / 100,
        unscheduled_stops: response.unscheduled_stops / 100,
        wait_time: response.wait_time / 100,
        international_airport_pickup: response.international_airport_pickup / 100,
      }),
    }),
    closeJobNoExtras: builder.mutation<RawBookingDetails, { bookingId: string }>({
      query: ({ bookingId }) => ({
        url: `/${bookingId}/status/close`,
        method: "POST",
        body: {
          reason: "completed_no_extras",
        },
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    closeJobWithExtras: builder.mutation<RawBookingDetails, CloseJobWithExtraParams & { bookingId: string; noShow?: boolean }>({
      query: ({ bookingId, noShow, ...body }) => ({
        url: `/${bookingId}/status/close`,
        method: "POST",
        body: {
          reason: noShow ? "completed_no_show_with_extras" : "completed_with_extras",
          ...body,
        },
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    closeJobNoCost: builder.mutation<RawBookingDetails, { bookingId: string; justification: string }>({
      query: ({ bookingId, justification }) => ({
        url: `/${bookingId}/status/close`,
        method: "POST",
        body: {
          reason: "closed_no_cost",
          driver_notes: justification,
        },
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    closeJobNoShow: builder.mutation<RawBookingDetails, { bookingId: string; driverNotes: string; clientNotes: string }>({
      query: ({ bookingId, driverNotes, clientNotes }) => ({
        url: `/${bookingId}/status/close`,
        method: "POST",
        body: {
          reason: "completed_no_show",
          provider_notes: driverNotes,
          client_notes: clientNotes,
        },
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    closeJobNoDriver: builder.mutation<RawBookingDetails, { bookingId: string }>({
      query: ({ bookingId }) => ({
        url: `/${bookingId}/status/close`,
        method: "POST",
        body: {
          reason: "no_driver",
        },
      }),
      onQueryStarted: updateBookingQueryData,
    }),
  }),
});

export const {
  useCloseJobNoCostMutation,
  useCloseJobNoExtrasMutation,
  useCloseJobNoShowMutation,
  useCloseJobNoDriverMutation,
  useCloseJobWithExtrasMutation,
  useGetCloseOptionsQuery,
} = closeEndpoints;
