import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button, ErrorMessage, Loading, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddInvoiceNoteMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { Invoice } from "@/redux/slices/payment/types";
import { addToast, clsx } from "@/utils";
import { addInvoiceNoteFormSchema } from "./fixtures";
import { AddInvoiceNoteFormData } from "./types";

interface AddInvoiceNoteFormProps extends React.HTMLAttributes<HTMLDivElement> {
  invoice: Invoice;
}

export const AddInvoiceNoteForm = ({ invoice, className, ...props }: AddInvoiceNoteFormProps) => {
  const [addInvoiceNote, { isLoading }] = useAddInvoiceNoteMutation();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<AddInvoiceNoteFormData>({
    resolver: zodResolver(addInvoiceNoteFormSchema),
    defaultValues: {
      note: "",
    },
  });

  const onSubmit = handleSubmit((data) => {
    addInvoiceNote({ id: invoice.id, note: data.note })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Added Note");
        reset();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  });

  return (
    <div className={clsx("relative flex flex-col rounded-lg bg-neutral-surface-gray p-5", className)} {...props}>
      {isLoading && <Loading />}
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Type in a note for this invoice</Typography>
          <TextInput placeholder="Add notes" maxLength={2048} {...register("note")} />
          <ErrorMessage errors={errors} name="note" />
        </div>
        <div className="mt-3 flex justify-end">
          <Button type="submit" variant="primary" size="sm" disabled={!isDirty}>
            Add Note
          </Button>
        </div>
      </form>
    </div>
  );
};
