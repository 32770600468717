import { DataTableHeader as Header } from "../types";

export interface DataTableResizeHandlerProps<T extends object> extends React.ComponentProps<"div"> {
  header: Header<T, unknown>;
}

export const DataTableResizeHandler = <T extends object>({ header }: DataTableResizeHandlerProps<T>) => {
  const resizeHandler = header.getResizeHandler();
  const className = "h-full w-4 right-0 absolute top-0 cursor-col-resize select-none touch-none hover:opacity-100";

  return (
    <div
      {...{
        onMouseDown: resizeHandler,
        onTouchStart: resizeHandler,
        className: `${className} ${header.column.getIsResizing() ? "opacity-100" : "opacity-20"}`,
      }}
    >
      <div className="absolute left-1/2 h-full w-[2px] -translate-x-1/2 bg-neutral-mid-gray"></div>
    </div>
  );
};
