import { adminApi } from "../adminApi";
import { transformDispatchSettings, transformUpdateDispatchSettings } from "./helpers";
import { GetDispatchSettingsResponse, UpdateDispatchSettingsParams, UpdateDispatchSettingsResponse } from "./types";

export const dispatchSettingsApi = adminApi.enhanceEndpoints({ addTagTypes: ["DispatchSettings"] }).injectEndpoints({
  endpoints: (builder) => ({
    getDispatchSettings: builder.query<GetDispatchSettingsResponse, void>({
      query: () => "/dispatch-settings",
      transformResponse: transformDispatchSettings,
      providesTags: ["DispatchSettings"],
    }),
    updateDispatchSettings: builder.mutation<UpdateDispatchSettingsResponse, UpdateDispatchSettingsParams>({
      query: (body) => ({
        url: "/dispatch-settings",
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateDispatchSettings,
      invalidatesTags: ["DispatchSettings"],
    }),
  }),
});

export const { useGetDispatchSettingsQuery, useLazyGetDispatchSettingsQuery, useUpdateDispatchSettingsMutation } = dispatchSettingsApi;
