import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import z from "zod";
import { Button, ErrorMessage, Icon, TextInput, Typography } from "@/components/atoms";

const formSchema = z.object({
  note: z.string().max(32, "Maximum of 32 characters."),
});

type FormData = z.infer<typeof formSchema>;

interface AddNoteFormProps {
  selectedRange: number | null;
  selectedRangeNote: string;
  onAddNote: (note: string) => void;
  onClose: () => void;
}

export const AddNoteForm = ({ selectedRange, selectedRangeNote, onAddNote, onClose }: AddNoteFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { note: selectedRangeNote },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    if (selectedRange != null) onAddNote(data.note);
  });

  return (
    <>
      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex-1">
          Add Notes to Range {selectedRange != null ? selectedRange + 2 : ""}
        </Typography>
        <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
      </div>
      <form className="mt-3 flex flex-col" onSubmit={onSubmit}>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Write additional details or information for this range</Typography>
          <TextInput placeholder="Your Note" maxLength={32} {...register("note")} />
          <ErrorMessage errors={errors} name="note" />
          <Typography variant="small" className="text-neutral-dark-gray">
            Max limit of 32 characters
          </Typography>
        </div>
        <Button type="submit" variant="primary" size="lg" className="mt-[25px]">
          Add Note
        </Button>
      </form>
    </>
  );
};
