import { useNavigate, useParams } from "react-router-dom";
import { Panel, Typography, Skeleton } from "@/components/atoms";
import { AsapPricing, AsapPricingEditor, PageHeader, StandardCancelFeesEditor, StandardCancelFeesTable } from "@/components/organisms/";
import { useGetAsapPricingQuery, useGetStandardPricingQuery } from "@/redux/apis/config/pricing/cancellationFees";
import { useAppSelector } from "@/redux/hooks";
import { standardPricingSelector } from "@/redux/slices/pricing/selectors";
import { clsx } from "@/utils";

export const CancellationFees = () => {
  const navigate = useNavigate();
  const { isFetching: standardLoading } = useGetStandardPricingQuery();
  const { basePrice, intervals } = useAppSelector(standardPricingSelector);
  const { mode, type } = useParams();
  const { isFetching: asapLoading } = useGetAsapPricingQuery();

  return (
    <div>
      <PageHeader.Actions onBack={() => navigate("../")}  />
      <Panel
        className={clsx("mb-6", {
          "pointer-events-none opacity-50": !!type && type === "standard-fees",
        })}
      >
        <Typography variant="h3" className="leading-8">
          ASAP Cancellation Fees
        </Typography>
        <Typography className="text-neutral-dark-gray">
          ASAP Booking: Trips that are booked less than 60 minutes from pick up time.
        </Typography>
        {asapLoading ? (
          <Skeleton variant="short" />
        ) : (
          <>
            {mode === "edit" ? <AsapPricingEditor className="mt-5" /> : <AsapPricing className="mt-5" />}
          </>
        )}
      </Panel>
      <Panel
        className={clsx("mb-6", {
          "pointer-events-none opacity-50": !!type && type !== "standard-fees",
        })}
      >
        {standardLoading ? (
          <Skeleton />
        ) : mode === "edit" && type === "standard-fees" ? (
          <StandardCancelFeesEditor basePrice={basePrice} intervals={intervals} />
        ) : (
          <StandardCancelFeesTable intervals={[basePrice, ...intervals]} />
        )}
      </Panel>
    </div>
  );
};
