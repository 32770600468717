import { Icon, IconButton, Typography, SidePanel } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { resetTakeManualPayment } from "@/redux/slices/payment/paymentSlice";
import { creditCardPaymentsPageStateSelector, creditCardPaymentsTakeManualPaymentSelector } from "@/redux/slices/payment/selectors";
import { TakeManualPaymentStep1 } from "./TakeManualPaymentStep1";
import { TakeManualPaymentStep2 } from "./TakeManualPaymentStep2";
import { TakeManualPaymentStep3 } from "./TakeManualPaymentStep3";

interface TakeManualPaymentPanelProps {
  open: boolean;
  onClose: () => void;
}

export const TakeManualPaymentPanel = ({ open, onClose }: TakeManualPaymentPanelProps) => {
  const dispatch = useAppDispatch();
  const { selectedPayment: payment } = useAppSelector(creditCardPaymentsPageStateSelector);
  const { paymentStep } = useAppSelector(creditCardPaymentsTakeManualPaymentSelector);

  const handleClose = () => {
    dispatch(resetTakeManualPayment());
    onClose();
  };

  const renderSteps = () => {
    if (payment) {
      switch (paymentStep) {
        case 2:
          return <TakeManualPaymentStep2 payment={payment} />;
        case 3:
          return <TakeManualPaymentStep3 payment={payment} onClose={handleClose} />;
        default:
          return <TakeManualPaymentStep1 payment={payment} />;
      }
    }
  };

  return (
    <SidePanel open={open} onClose={handleClose} width={550} focused>
      <div className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="Card" size="lg" />
          <Typography variant="title">Take Manual Payment</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={handleClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <div className="flex h-full">{renderSteps()}</div>
    </SidePanel>
  );
};
