import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Alert, Button, DropdownSelect, ErrorMessage, Loading, PhoneNumberInput, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddExternalSupplierMutation, useGetExternalSuppliersFormOptionsQuery } from "@/redux/apis/externalSupplier/externalSupplierApi";
import { AddExternalSupplierParams } from "@/redux/apis/externalSupplier/types";
import { useAppSelector } from "@/redux/hooks";
import { externalSuppliersFormOptionsSelector } from "@/redux/slices/externalSupplier/selectors";
import { addToast } from "@/utils";
import { addExternalSupplierFormSchema } from "../fixtures";
import { AddExternalSupplierFormData } from "../types";
import { StateField } from "../fields/StateField";
import { useOperator } from "@/hooks";

export const AddExternalSupplierForm = () => {
  const navigate = useNavigate();
  const { bookableCountryList } = useOperator();
  const { isFetching } = useGetExternalSuppliersFormOptionsQuery();
  const [addExternalSupplier, { isLoading }] = useAddExternalSupplierMutation();
  const { types } = useAppSelector(externalSuppliersFormOptionsSelector);
  const [requestErrors, setRequestErrors] = useState<string[]>([]);
  const methods = useForm<AddExternalSupplierFormData>({
    resolver: zodResolver(addExternalSupplierFormSchema),
    criteriaMode: "all",
    defaultValues: {
      type: "",
      state: "",
    },
  });
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors: formErrors },
  } = methods;
  const multipleCountries = bookableCountryList.length > 1;

  const onSubmit = handleSubmit(async (data) => {
    const { name, contactNumber, email, keyContactName, state, type } = data;

    const params: AddExternalSupplierParams = {
      name,
      address_state: state,
      email,
      key_contact_name: keyContactName,
      phone: contactNumber,
      type,
    };
    addExternalSupplier(params)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully added new external supplier.");
        navigate("../");
      })
      .catch((e) => setRequestErrors(getErrorMessages(e)));
  });

  const renderRequestErrors = () => {
    return requestErrors.length > 0 ? (
      <div className="mb-4">
        {requestErrors.map((e, i) => (
          <Alert key={i} type="danger" message={e} className="mb-4" />
        ))}
      </div>
    ) : null;
  };

  return (
    <FormProvider {...methods}>
      <div className="relative pt-8">
        {isFetching && <Loading />}
        {renderRequestErrors()}
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-3 animate-enter gap-x-5 gap-y-6">
            <div className="col-span-3 grid grid-cols-3 gap-x-5 gap-y-6">
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Name</Typography>
                <TextInput className="w-full" hasError={!!formErrors.name} placeholder="Enter your Supplier's Name" {...register("name")} />
                <ErrorMessage errors={formErrors} name="name" />
              </div>
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Type</Typography>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <DropdownSelect
                      className="w-full"
                      options={types}
                      placeholder="Select Type"
                      value={field.value?.toString()}
                      onChange={(value) => field.onChange(value)}
                      hasError={!!formErrors.type}
                    />
                  )}
                />
                <ErrorMessage errors={formErrors} name="type" />
              </div>
              {!multipleCountries && <StateField />}
            </div>
            {multipleCountries && <StateField className="col-span-3 grid grid-cols-3 gap-x-5 gap-y-6" />} 
            <div className="col-span-3 grid grid-cols-3 gap-x-5 gap-y-6">
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Email</Typography>
                <TextInput className="w-full" hasError={!!formErrors.email} placeholder="supplier@email.com" {...register("email")} />
                <ErrorMessage errors={formErrors} name="email" />
              </div>
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Key Contact Name</Typography>
                <TextInput
                  className="w-full"
                  hasError={!!formErrors.keyContactName}
                  placeholder="Contact Name"
                  {...register("keyContactName")}
                />
                <ErrorMessage errors={formErrors} name="keyContactName" />
              </div>
              <div className="col-span-3 flex flex-col gap-1 lg:col-span-1">
                <Typography variant="paragraph">Key Contact Phone</Typography>
                <PhoneNumberInput control={control} name="contactNumber" />
                <ErrorMessage errors={formErrors} name="contactNumber" />
              </div>
            </div>
          </div>

          <div className="mb-4 mt-10 flex justify-end gap-3">
            <Button variant="tertiary" className="px-8 ring-1 ring-neutral-dark-gray" onClick={() => navigate("/external-suppliers")}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" className="relative px-8" disabled={!isDirty || isLoading}>
              {isLoading && <Loading />}
              Add Supplier
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
