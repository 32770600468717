import { createSlice } from "@reduxjs/toolkit";
import { userAccountApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { UserAccountState } from "./types";

const initialState: UserAccountState = {
  accounts: [],
  page: pageState,
};

export const userAccountSlice = createSlice({
  name: "userAccount",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(userAccountApi.endpoints.getUserAccounts.matchFulfilled, (state, { payload }) => {
      const { accounts, page } = payload.data;
      state.accounts = accounts;
      state.page = page;
    });
  },
});

export default userAccountSlice.reducer;
