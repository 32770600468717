import { useState } from "react";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Icon, Modal, Panel, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { useOperator } from "@/hooks";
import { getAssetName } from "./helpers";

export const QRCodeGuide = () => {
  const navigate = useNavigate();
  const { images, country } = useOperator();
  const phpHostURL = getPhpHostUrl();
  const templatesUrl = `${phpHostURL}/qr/qr-code-sticker-templates.zip`;
  const setupUrl = `${phpHostURL}/qr/qr-code-sticker-setup.pdf`;
  const templatesUrlName = getAssetName(templatesUrl);
  const setupUrlName = getAssetName(setupUrl);

  const qrUrl = images?.qr;
  const qrTransparentUrl = images?.qrTransparent;
  const qrUrlName = getAssetName(qrUrl);
  const qrTransparentUrlName = getAssetName(qrTransparentUrl);
  const [showModal, setShowModal] = useState(false);

  const renderContactPrinterSupplier = () => {
    if (country === "AU") {
      return (
        <>
          <div>
            <Button size="md" endIcon="ArrowRight" onClick={() => navigate("qr-templates")}>
              Contact Printer Supplier
            </Button>
          </div>
          <Divider className="my-4" label="OR" />
        </>
      );
    }
  };

  return (
    <Panel className="flex flex-col lg:flex-row flex-shrink-0 gap-5">
      <div className="flex items-center justify-center px-5">
        <div className="relative flex h-60 w-60 items-center justify-center rounded-full bg-primary-light p-5 text-center">
          <div className="flex h-[150px] w-[150px] items-center justify-center rounded-lg bg-white">
            {qrUrl && <img src={qrUrl} alt="qr-code" className="h-[120px] w-[120px] object-scale-down" />}
          </div>
          <div className="absolute -left-[7px] bottom-[63px] flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white">
            <Icon name="Profile2User" size="sm" />
          </div>
          <div className="absolute -bottom-[5px] right-[61px] flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white">
            <Icon name="Global" size="sm" />
          </div>
          <div className="absolute -right-2 top-[45px] flex h-10 w-10 items-center justify-center rounded-full bg-primary text-white">
            <Icon name="Car" size="lg" />
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <Typography variant="h3">
          Scan or Share QR Code to Get Started
        </Typography>
        <Typography variant="paragraph" className="mt-1 mb-4 text-neutral-dark-gray">
          Select the QR templates that you want to Print with our supplier. Hassle Free!
        </Typography>
        {renderContactPrinterSupplier()}
        <Typography variant="action">
          Manually print your own QR code stickers
        </Typography>
        <div className="flex flex-col xl:flex-row gap-3 mt-4">
          <Button
            size="md"
            startIcon="ImportCurve"
            onClick={() => setShowModal(true)}
            disabled={!qrUrl && !qrTransparentUrl}
          >
            Download QR code
          </Button>
          <Button
            variant="secondary"
            size="md"
            startIcon="ImportCurve"
            onClick={() => saveAs(templatesUrl, templatesUrlName)}
          >
            Download Templates
          </Button>
          <Button
            variant="tertiary"
            size="md"
            startIcon="DocumentText"
            onClick={() => saveAs(setupUrl, setupUrlName)}
          >
            How to print your own QR code stickers
          </Button>
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        className="w-[85vw] md:w-[550px] lg:w-[550px]"
        title="Download QR Code"
        description="Choose a QR code style: Opt for a transparent background, ideal for emails, cards, etc., or select a white background, perfect for mobile phones."
      >
        <div className="flex flex-col lg:flex-row w-full gap-y-2 gap-x-4 mt-5">
          <Button variant="primary" size="md" disabled={!qrTransparentUrl} onClick={() => qrTransparentUrl && saveAs(qrTransparentUrl, qrTransparentUrlName)}>Download with Transparent BG</Button>
          <Button variant="secondary" size="md" disabled={!qrUrl} onClick={() => qrUrl && saveAs(qrUrl, qrUrlName)}>Download with White BG</Button>
        </div>
      </Modal>
    </Panel>
  );
};
