import { DPDay } from "@rehookify/datepicker";
import { clsx } from "@/utils";

// export const getMonthClassName = (className: string, { selected, now, disabled }: DPMonth) =>
//   cx(className, {
//     "bg-slate-700 text-white hover:bg-slate-700 opacity-100": selected,
//     "border border-slate-500": now,
//     "opacity-25 cursor-not-allowed": disabled,
//   });

// export const getYearsClassName = (className: string, { selected, now, disabled }: DPYear) =>
//   cx(className, {
//     "bg-slate-700 text-white hover:bg-slate-700 opacity-100": selected,
//     "border border-slate-500": now,
//     "opacity-25 cursor-not-allowed": disabled,
//   });

export const getDayClassName = (className: string, { selected, disabled, inCurrentMonth, now, range }: DPDay) =>
  clsx("day", range, className, {
    "bg-transparent cursor-not-allowed": disabled,
    "text-neutral-mid-gray [&:not(.in-range, .will-be-in-range)]:bg-transparent": !inCurrentMonth,
    "[&>button]:bg-primary [&>button]:text-white [&>button]:hover:text-white": selected,
    "": now,
  });
