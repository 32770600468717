import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Loading } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, ResetPasswordForm } from "@/components/organisms";
import { Expired } from "@/components/pages";
import { useLazyVerifyPasswordTokenQuery } from "@/redux/apis/auth/authApi";

export const ResetPassword = () => {
  const { token } = useParams();
  const [verifyToken, { isLoading, isError }] = useLazyVerifyPasswordTokenQuery();

  useEffect(() => {
    if (!token) return;

    verifyToken(token);
  }, [token, verifyToken]);

  if (isLoading) return <Loading />;

  if (!token || isError) {
    return (
      <Expired
        title="Reset Password Link Expired"
        description="Sorry, the reset password link is not valid anymore."
      />
    );
  }
    
  return (
    <div className="flex flex-row min-h-screen overflow-hidden">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        <Container className="flex flex-1 justify-center w-full max-w-[1040px] gap-5 py-8 md:py-[100px] px-5">
          <div className="w-full max-w-[440px]">
            <ResetPasswordForm token={token} />
          </div>
        </Container>
        <GeneralFooter />
      </div>
    </div>
  );
};
