import { Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useRemoveCompanyMutation } from "@/redux/apis/company/companyApi";
import { useAppSelector } from "@/redux/hooks";
import { selectedCompanySelector } from "@/redux/slices/company/selectors";
import { addToast, clsx } from "@/utils";

export interface CompaniesRemoveProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
}

export const CompaniesRemove = ({ onClose, className, ...props }: CompaniesRemoveProps) => {
  const company = useAppSelector(selectedCompanySelector);
  const [removeAccess, { isLoading }] = useRemoveCompanyMutation();

  const handleRemoveClick = () => {
    if (!company) return;

    removeAccess(company.id)
      .unwrap()
      .then(() => {
        addToast("success", `Removed ${company?.name} company`);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <div className={clsx("relative", className)} {...props}>
      {isLoading && <Loading />}
      <div className="max-w-lg">
        <div className="flex flex-col">
          <Typography variant="title">Remove Company</Typography>
          <div className="mt-3">
            <Typography variant="paragraph">
              Are you sure you want to remove <span className="text-info">{company?.name}</span> company from RideMinder?
            </Typography>
          </div>
        </div>
      </div>
      <div className="mt-[22px] flex justify-end gap-x-4">
        <Button variant="secondary" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button variant="primary" onClick={handleRemoveClick} size="md" className="bg-danger hover:bg-danger-dark">
          Remove
        </Button>
      </div>
    </div>
  );
};
