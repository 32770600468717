import { Controller, useFormContext } from "react-hook-form";
import { DateRangePicker, ErrorMessage, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { ExportReportFormData } from "../types";

export const DateField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { dateFormat } = useProfile();
  const {
    control,
    formState: { errors },
  } = useFormContext<ExportReportFormData>();

  return (
    <div className={className} {...props}>
      <Typography variant="paragraph">Date</Typography>
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <DateRangePicker
            position="bottom-end"
            dates={field.value}
            onDatesChange={(dates) => field.onChange(dates)}
            dateFormat={dateFormat}
          />
        )}
      />
      <ErrorMessage errors={errors} name="date" />
    </div>
  );
};
