import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage, Toggle, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddTransferTypeFormData } from "../../form";

export const TransferTypeIsAdminOnlyField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddTransferTypeFormData>();

  return (
    <div className={clsx("flex w-full items-center gap-x-1.5", className)} {...props}>
      <div className="flex flex-1 flex-col">
        <Typography variant="paragraph">Admin Only Transfer Type</Typography>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          If enabled, only admins can use this transfer type. Clients must contact you to modify or cancel a job with this transfer type.
        </Typography>
      </div>
      <Controller
        name="isAdminOnly"
        control={control}
        render={({ field }) => (
          <div className="flex items-center gap-4">
            <Toggle
              checked={field.value}
              onChange={(checked) => {
                field.onChange(checked);
              }}
            />
          </div>
        )}
      />
      <ErrorMessage errors={errors} name="isAdminOnly" />
    </div>
  );
};
