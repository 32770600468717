import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { driverApi } from "@/redux/apis";
import { pageState } from "@/redux/types";
import { DriverState, fleetOptionsInitialState, offloadOptionsInitialState } from "./types";

const initialState: DriverState = {
  fleet: {
    drivers: [],
    page: pageState,
    options: fleetOptionsInitialState,
  },
  offload: {
    drivers: [],
    page: pageState,
    options: offloadOptionsInitialState,
  },
};

export const driverSlice = createSlice({
  name: "driver",
  initialState,
  reducers: {
    toggleIsFleetLeader: (state, action: PayloadAction<{ id: string; isFleetLeader: boolean }>) => {
      const driver = state.fleet.drivers.find((i) => i.id === action.payload.id);
      if (driver) driver.isFleetLeader = action.payload.isFleetLeader;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(driverApi.endpoints.getFleetDrivers.matchFulfilled, (state, { payload }) => {
      const { drivers, page } = payload.data;
      state.fleet.drivers = drivers;
      state.fleet.page = page;
    });
    builder.addMatcher(driverApi.endpoints.getFleetOptions.matchFulfilled, (state, { payload }) => {
      const { options } = payload.data;
      state.fleet.options = options;
    });
    builder.addMatcher(driverApi.endpoints.getOffloadDrivers.matchFulfilled, (state, { payload }) => {
      const { drivers, page } = payload.data;
      state.offload.drivers = drivers;
      state.offload.page = page;
    });
    builder.addMatcher(driverApi.endpoints.getOffloadOptions.matchFulfilled, (state, { payload }) => {
      const { options } = payload.data;
      state.offload.options = options;
    });
  },
});

export const { toggleIsFleetLeader } = driverSlice.actions;
export default driverSlice.reducer;
