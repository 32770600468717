import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setAction } from "@/redux/slices/booking/bookingSlice";
import { bookingActionSelector } from "@/redux/slices/booking/selectors";
import { AllocateNewDriver } from "./actions/AllocateNewDriver/AllocateNewDriver";
import { CloseJobCompleted } from "./actions/CloseJob/CloseJobCompleted";
import { CloseJobCustomerCancel } from "./actions/CloseJob/CloseJobCustomerCancel";
import { CloseJobNoCost } from "./actions/CloseJob/CloseJobNoCost";
import { CloseJobNoDriver } from "./actions/CloseJob/CloseJobNoDriver";
import { ViewClosingExtrasNotes } from "./actions/CloseJob/ViewClosingExtrasNotes";
import { DriverAssignmentLogs } from "./actions/DriverAssignmentLogs/DriverAssignmentLogs";
import { ForwardBookingConfirmation } from "./actions/ForwardBookingConfirmation/ForwardBookingConfirmation";
import { JobAttachments } from "./actions/JobAttachments/JobAttachments";
import { JobHistory } from "./actions/JobHistory/JobHistory";
import { JobMessagingLog } from "./actions/JobMessagingLog/JobMessagingLog";
import { OffloadJobToNetwork } from "./actions/OffloadJobToNetwork/OffloadJobToNetwork";
import { Payments } from "./actions/Payments/Payments";
import { ReassignToNewClient } from "./actions/ReassignToNewClient/ReassignToNewClient";
import { RecallJob } from "./actions/RecallJob/RecallJob";
import { ReopenJob } from "./actions/ReopenJob/ReopenJob";
import { TripActivity } from "./actions/TripActivity/TripActivity";

export const BookingActions = () => {
  const dispatch = useAppDispatch();
  const action = useAppSelector(bookingActionSelector);

  const onClose = () => {
    dispatch(setAction(undefined));
  };

  return (
    <>
      <ReassignToNewClient open={action === "reassign-client"} onClose={onClose} />
      <ForwardBookingConfirmation open={action === "forward-confirmation"} onClose={onClose} />
      <OffloadJobToNetwork open={action === "offload-to-network"} onClose={onClose} />
      <RecallJob open={action === "recall-job"} onClose={onClose} />
      <JobMessagingLog open={action === "message-log"} onClose={onClose} />
      <JobHistory open={action === "job-history"} onClose={onClose} />
      <JobAttachments open={action === "job-attachments"} onClose={onClose} />
      <Payments open={action === "payment-logs"} onClose={onClose} />
      <CloseJobCustomerCancel open={action === "close-job-customer-cancel"} onClose={onClose} />
      <CloseJobNoCost open={action === "close-job-no-cost"} onClose={onClose} />
      <CloseJobCompleted open={action === "close-job-completed"} onClose={onClose} />
      <CloseJobNoDriver open={action === "close-job-no-driver"} onClose={onClose} />
      <ViewClosingExtrasNotes open={action === "view-closing-extras-notes"} onClose={onClose} />
      <ReopenJob open={action === "reopen-job"} onClose={onClose} />
      <AllocateNewDriver open={action === "allocate-new-driver"} onClose={onClose} />
      <DriverAssignmentLogs open={action === "driver-assignment-logs"} onClose={onClose} />
      <TripActivity open={action === "trip-activity"} onClose={onClose} />
    </>
  );
};
