import { adminApi } from "../adminApi";
import { transformAccessControl, transformUpdateAccessControl } from "./helpers";
import { GetAccessControlResponse, UpdateAccessControlParams, UpdateAccessControlResponse } from "./types";

export const accessControlApi = adminApi.enhanceEndpoints({ addTagTypes: ["AccessControl"] }).injectEndpoints({
  endpoints: (builder) => ({
    getAccessControl: builder.query<GetAccessControlResponse, void>({
      query: () => `/access-control`,
      transformResponse: transformAccessControl,
      providesTags: ["AccessControl"],
    }),
    updateAccessControl: builder.mutation<UpdateAccessControlResponse, UpdateAccessControlParams>({
      query: (body) => ({
        url: `/access-control`,
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateAccessControl,
      invalidatesTags: ["AccessControl"],
    }),
  }),
});

export const { useGetAccessControlQuery, useUpdateAccessControlMutation } = accessControlApi;
