import { AssignedVehicleFilter, AssignedVehicleFilterDrawer } from "./filters/AssignedVehicleFilter";
import { ClientFilter, ClientFilterDrawer } from "./filters/ClientFilter";
import { CompanyFilter, CompanyFilterDrawer } from "./filters/CompanyFilter";
import { DriverFilter, DriverFilterDrawer } from "./filters/DriverFilter";
import { OffloadFilter, OffloadFilterDrawer } from "./filters/OffloadFilter";
import { PassengerFilter, PassengerFilterDrawer } from "./filters/PassengerFilter";
import { StateFilter, StateFilterDrawer } from "./filters/StateFilter";
import { StatusFilter, StatusFilterDrawer } from "./filters/StatusFilter";
import { TransferTypeFilter, TransferTypeFilterDrawer } from "./filters/TransferTypeFilter";
import { ValidatedJobFilter, ValidatedJobFilterDrawer } from "./filters/ValidatedJobFilter";
import { VehicleTypeFilter, VehicleTypeFilterDrawer } from "./filters/VehicleTypeFilter";

export const getFilterPopover = (key: string) => {
  switch (key) {
    case "driver":
      return <DriverFilter key={key} />;
    case "status":
      return <StatusFilter key={key} />;
    case "client":
      return <ClientFilter key={key} />;
    case "company":
      return <CompanyFilter key={key} />;
    case "passenger":
      return <PassengerFilter key={key} />;
    case "state":
      return <StateFilter key={key} />;
    case "assignedVehicle":
      return <AssignedVehicleFilter key={key} />;
    case "transferType":
      return <TransferTypeFilter key={key} />;
    case "vehicleType":
      return <VehicleTypeFilter key={key} />;
    case "offload":
      return <OffloadFilter key={key} />;
    case "onlyShowUnvalidated":
      return <ValidatedJobFilter key={key} />;
    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};

export const getFilterDrawer = (key: string, asJSX = false) => {
  switch (key) {
    case "status":
      return asJSX ? <StatusFilterDrawer key={key} /> : StatusFilterDrawer;
    case "assignedVehicle":
      return asJSX ? <AssignedVehicleFilterDrawer key={key} /> : AssignedVehicleFilterDrawer;
    case "client":
      return asJSX ? <ClientFilterDrawer key={key} /> : ClientFilterDrawer;
    case "company":
      return asJSX ? <CompanyFilterDrawer key={key} /> : CompanyFilterDrawer;
    case "driver":
      return asJSX ? <DriverFilterDrawer key={key} /> : DriverFilterDrawer;
    case "offload":
      return asJSX ? <OffloadFilterDrawer key={key} /> : OffloadFilterDrawer;
    case "passenger":
      return asJSX ? <PassengerFilterDrawer key={key} /> : PassengerFilterDrawer;
    case "state":
      return asJSX ? <StateFilterDrawer key={key} /> : StateFilterDrawer;
    case "onlyShowUnvalidated":
      return asJSX ? <ValidatedJobFilterDrawer key={key} /> : ValidatedJobFilterDrawer;
    case "vehicleType":
      return asJSX ? <VehicleTypeFilterDrawer key={key} /> : VehicleTypeFilterDrawer;
    case "transferType":
      return asJSX ? <TransferTypeFilterDrawer key={key} /> : TransferTypeFilterDrawer;

    default:
      throw new Error(`Unknown filter key: ${key}`);
  }
};
