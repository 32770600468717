import { clsx } from "@/utils";
import { Icon, Typography } from "../";

export interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  type: "success" | "danger" | "info";
  message: string;
  customIcon?: React.ReactNode;
}
export const Toast = ({ visible = false, type = "success", customIcon, className, message, ...props }: ToastProps) => {
  const renderIcon = () => {
    if (customIcon) return customIcon;

    switch (type) {
      case "success":
        return <Icon name="TickCircle" variant="Bold" size="lg" className="shrink-0" />;
      case "danger":
        return <Icon name="CloseCircle" variant="Bold" size="lg" className="shrink-0" />;
      case "info":
        return <Icon name="InfoCircle" variant="Bold" size="lg" className="flex-shrink-0 rotate-180" />;
      default:
        return null;
    }
  };

  const background = {
    "text-success bg-success-light": type === "success",
    "text-danger bg-danger-light": type === "danger",
    "text-info bg-info-light": type === "info",
  };

  const transition = {
    "animate-enter": visible,
    "animate-leave": !visible,
  };

  return (
    <div
      className={clsx(
        "inline-flex cursor-pointer items-center justify-center gap-2 rounded-lg p-4 shadow",
        background,
        className,
        transition
      )}
      {...props}
    >
      {renderIcon()}
      <Typography variant="action">{message}</Typography>
    </div>
  );
};
