import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import RMLogo from "@/assets/images/rm-logo-emblem.svg";
import { Button, Divider, Icon, Loading, Spinner, Typography, Modal } from "@/components/atoms";
import { useSiteConfig } from "@/hooks";
import { useAcceptBookingOfferMutation, useRejectBookingOfferMutation } from "@/redux/apis/bookingOffer/bookingOfferApi";
import { addToast } from "@/utils";
import { BookingOfferSidebar } from "./BookingOfferSidebar";
import { useBookingOfferPageContext } from "./context";
import { OtherInformation } from "./widgets/OtherInformation";
import { When } from "./widgets/When";
import { Where } from "./widgets/Where";

export const BookingOfferPanel = () => {
  const { bookingOffer } = useBookingOfferPageContext();

  if (!bookingOffer) return <Loading />;

  return (
    <div className="flex min-h-screen flex-1 flex-col-reverse lg:flex-row">
      <div className="absolute top-0 mb-0 flex items-center gap-2 p-7 lg:mb-4">
        <img className="flex h-8 max-h-[32px] object-contain" src={RMLogo} alt={"Rideminder"} />
        <Typography variant="h3">RideMinder</Typography>
      </div>
      <BookingOfferSidebar />
      <div className="flex-1 bg-white px-7 py-20 pb-12 md:px-12 lg:py-24">
        <Header />
        <Content />
        <Modals />
      </div>
    </div>
  );
};

const Header = () => {
  const {
    name,
    images: { emblem },
  } = useSiteConfig();

  const {
    bookingOffer: { status, acceptedAt },
    setActiveModal,
  } = useBookingOfferPageContext();

  return (
    <div>
      <div className="flex items-center justify-between">
        {status !== "driver_allocated_not_confirmed" ? (
          <Icon name="TickCircle" variant="Bold" className="h-12 w-12 rounded-lg bg-white  text-success md:h-16 md:w-16" />
        ) : (
          <Icon name="ArchiveBook" className="h-12 w-12 rounded-lg bg-primary-light p-2 text-primary md:h-16 md:w-16" />
        )}
        {status === "driver_allocated_not_confirmed" && (
          <div className="flex gap-3">
            <Button variant="primary" size="lg" onClick={() => setActiveModal("accept")}>
              Accept Booking
            </Button>
            <Button onClick={() => setActiveModal("reject")} variant="secondary" size="lg" className="text-danger">
              Reject Booking
            </Button>
          </div>
        )}
      </div>
      {status !== "driver_allocated_not_confirmed" ? (
        <Typography className="mb-2 mt-4  text-success">
          Booking accepted: {acceptedAt ? format(new Date(acceptedAt), "dd/MM/yyyy 'at' h:mm a") : null}
        </Typography>
      ) : (
        <Typography className="mb-2 mt-4 text-neutral-dark-gray">You have a booking offer from</Typography>
      )}
      <div className="flex items-center gap-3">
        <img src={emblem} alt={name} className="inline h-10 w-10 rounded-full" />
        <Typography variant="h1" className="align-middle">
          {name}
        </Typography>
      </div>
    </div>
  );
};

const Content = () => {
  return (
    <>
      <div className="mt-6">
        <When />
        <Divider className="my-6" />
        <Where />
        <Divider className="my-6" />
        <OtherInformation />
      </div>
    </>
  );
};

const Modals = () => {
  const navigate = useNavigate();
  const { activeModal, setActiveModal, params } = useBookingOfferPageContext();
  const [acceptBooking, { isLoading: isAcceptingBooking }] = useAcceptBookingOfferMutation();
  const [rejectBooking, { isLoading: isRejectingBooking }] = useRejectBookingOfferMutation();

  const handleAccept = () => {
    acceptBooking(params)
      .unwrap()
      .then(() => addToast("success", "Booking has been accepted"))
      .catch((e) => addToast("danger", e))
      .finally(() => setActiveModal(undefined));
  };

  const handleReject = () => {
    rejectBooking(params)
      .unwrap()
      .then(() => {
        addToast("success", "Booking has been rejected");
        setActiveModal(undefined);
        navigate("./rejected");
      })
      .catch((e) => addToast("danger", e));
  };

  return (
    <>
      <Modal open={activeModal === "accept"} title="Accept Booking" onClose={() => setActiveModal(undefined)} className="w-[475px] lg:p-6">
        <Typography>Confirm your acceptance of the booking request. Kindly review the details attentively before proceeding.</Typography>
        <div className="mt-6 flex justify-end gap-3">
          <Button variant="secondary" size="md" onClick={() => setActiveModal(undefined)} disabled={isAcceptingBooking}>
            Cancel
          </Button>
          <Button variant="primary" size="md" onClick={handleAccept} disabled={isAcceptingBooking}>
            {isAcceptingBooking ? <Spinner className="text-white" /> : "Accept Booking"}
          </Button>
        </div>
      </Modal>
      <Modal open={activeModal === "reject"} title="Reject Booking" onClose={() => setActiveModal(undefined)} className="w-[475px] lg:p-6">
        <Typography>
          By confirming, you acknowledge that the booking request will be declined. Kindly review the details before proceeding.
        </Typography>
        <div className="mt-6 flex justify-end gap-3">
          <Button variant="secondary" size="md" onClick={() => setActiveModal(undefined)} disabled={isRejectingBooking}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isRejectingBooking}
            className="bg-danger hover:bg-danger-dark"
            size="md"
            onClick={handleReject}
          >
            {isRejectingBooking ? <Spinner /> : "Reject Booking"}
          </Button>
        </div>
      </Modal>
    </>
  );
};
