export const iconsOptions = [
  {
    name: "Baby",
    icon: "extras-baby-seat",
    value: "baby-seat",
  },
  {
    name: "Stop Over",
    icon: "extras-stop-over",
    value: "stop-over",
  },
  {
    name: "Booking",
    icon: "extras-phone-booking",
    value: "phone-booking",
  },
  {
    name: "Luggage",
    icon: "extras-luggage-or-bag",
    value: "luggage-or-bag",
  },
  {
    name: "Others",
    icon: "extras-others",
    value: "others",
  },
];
