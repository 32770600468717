import { Profile, ProfileOptions } from "@/redux/slices/profile/types";
import { RawProfile, RawProfileOptions } from "./types";

export const transformProfile = (res: RawProfile) => {
  const {
    account_uuid,
    first_name,
    last_name,
    avatar,
    mobile,
    title,
    email,
    metadata: { email_verified, mobile_verified, password_change_required },
    notification_email,
    timezone,
    date_format,
    time_format,
  } = res;

  const response: Profile = {
    id: account_uuid,
    displayName: `${first_name} ${last_name[0]}`,
    firstName: first_name,
    lastName: last_name,
    avatar,
    dateFormat: date_format,
    timeFormat: time_format,
    fullTimeFormat: "HH:mm:ss a",
    mobile,
    title,
    email,
    emailVerified: email_verified,
    mobileVerified: mobile_verified,
    passwordChangeRequired: password_change_required,
    notificationEmail: notification_email,
    timezone,
  };

  return response;
};

export const transformProfileOptions = (res: RawProfileOptions) => {
  const { timezone, date_format, title } = res;

  const response: ProfileOptions = {
    titles: title,
    timezones: timezone,
    dateFormats: date_format,
  };

  return response;
};
