import { Modal, Typography } from "@/components/atoms";
import { ReactComponent as MindyLoader } from "@/assets/images/add-ons/mindy-loader.svg";

interface AddOnRedirectionModalProps {
  name: string;
  open: boolean;
  isActivating?: boolean;
}

export const AddOnRedirectionModal = ({ name, isActivating, open }: AddOnRedirectionModalProps) => {  
  return (
    <Modal
      open={open}
      onClose={() => null}
      className="w-[85vw] md:w-[700px] lg:w-[700px]"
    >
      <div className="flex flex-row gap-x-8">
        <MindyLoader className="-mt-2" />
        <div className="flex flex-col w-full">
          <Typography variant="h3">{`${isActivating ? "Enabling" : "Disabling"} ${name}`}</Typography>
          <Typography variant="paragraph">{isActivating ? "Hang tight, we'll redirect you shortly!" : "We're sorry to see it go. Please wait a moment."}</Typography>
          <div className="mt-8 w-full h-2.5 rounded-full bg-primary animate-pulse" />
        </div>
      </div>
    </Modal>
  );
};
