import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfigState, SiteInfo, SiteConfig } from "./types";

const initialState: ConfigState = {
  config: undefined,
  siteInfo: {} as SiteInfo,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, { payload }: PayloadAction<SiteConfig | undefined>) => {
      state.config = payload;
    },
    setSiteInfo: (state, action: PayloadAction<SiteInfo>) => {
      state.siteInfo = action.payload;
    },
  },
});

export default configSlice.reducer;
export const { setConfig, setSiteInfo } = configSlice.actions;
