import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useGetVehicleQuery } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";

export interface IEditVehicleContext {
  vehicle: Vehicle;
  documentRetryCount: {
    accreditation: number;
    insurance: number;
    registration: number;
  };
  setDocumentRetryCount: React.Dispatch<
    React.SetStateAction<{
      accreditation: number;
      insurance: number;
      registration: number;
    }>
  >;
  isDocumentValidating: boolean;
  setIsDocumentValidating: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EditVehicleContext = createContext<IEditVehicleContext | null>(null);

export const EditVehicleProvider = ({ children }: PropsWithChildren) => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [isDocumentValidating, setIsDocumentValidating] = useState(false);
  const [documentRetryCount, setDocumentRetryCount] = useState({
    accreditation: 0,
    insurance: 0,
    registration: 0,
  });

  const { data: vehicle, isFetching } = useGetVehicleQuery(vehicleId ?? "", { skip: !vehicleId });
  if (isFetching || !vehicle) return <Loading />;

  return (
    <EditVehicleContext.Provider
      value={{ vehicle, documentRetryCount, setDocumentRetryCount, isDocumentValidating, setIsDocumentValidating }}
    >
      {children}
    </EditVehicleContext.Provider>
  );
};

export const useEditVehicleContext = () => {
  const context = useContext(EditVehicleContext);
  if (!context) {
    throw new Error("useEditVehicleContext must be used within an EditVehicleProvider");
  }
  return context;
};
