import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Icon, Panel, Spinner, Typography } from "@/components/atoms";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { useSearchVehicleModelQuery } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { getAssetUrl } from "@/utils";
import { AddVehicleCategoryFormData } from "../form";
import { LookForYourVehicle } from "./LookForYourVehicle";

export const ExampleVehicles = () => {
  const [showTable, setShowTable] = useState(false);
  const { watch } = useFormContext<AddVehicleCategoryFormData>();
  const vehicleType = watch("type");
  const vehicleClass = watch("class");

  const { isFetching, data } = useSearchVehicleModelQuery(
    {
      type: vehicleType,
      class: vehicleClass,
      page_size: 999,
    },
    {
      skip: !vehicleType || !vehicleClass,
    }
  );

  const models = useMemo(() => {
    if (!data) return [];
    return data.models;
  }, [data]);

  return (
    <Panel className={`flex w-full shrink-0 flex-col sm:min-h-[600px] lg:w-[350px] ${!vehicleType && "pointer-events-none opacity-50"}`}>
      <Typography variant="h3">Example Vehicles in this Category</Typography>
      {vehicleType ? (
        <Typography className="text-neutral-black">{getVehicleTypeLabel(vehicleType)} vehicles in this category.</Typography>
      ) : (
        <Typography className="text-neutral-black"></Typography>
      )}

      <div className="flex flex-1 flex-col ">
        {isFetching ? (
          <div className="flex flex-1 flex-col items-center justify-center gap-4 ">
            <Spinner className="h-8 w-8 border-neutral-dark-gray" />
            <Typography className="text-neutral-dark-gray">Updating Vehicle List</Typography>
          </div>
        ) : (
          <div className="mt-2 flex max-h-[400px] flex-1 flex-col gap-2 overflow-auto rounded-lg">
            {models.map((model) => (
              <div key={model.id} className="flex items-center gap-2 rounded-lg bg-neutral-surface-gray p-2">
                <div className="flex h-8 w-8 items-center justify-center  bg-neutral-gray">
                  <img
                    src={getAssetUrl(model.avatar, {
                      width: 36,
                      height: 36,
                      fit: "contain",
                      background: "transparent",
                    })}
                    alt={model.make}
                    className="h-[18px] w-[18px] shrink-0"
                  />
                </div>
                <Typography>
                  {model.make} {model.model}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>

      <footer className="mt-4 text-center">
        <Button
          startIcon={<Icon name="SearchNormal1" className="text-info" size="md" />}
          variant="custom"
          onClick={() => setShowTable(true)}
          className="flex w-full items-center gap-2"
        >
          <Typography variant="action" className="text-info">
            Look for your vehicle
          </Typography>
        </Button>
      </footer>
      <LookForYourVehicle open={showTable} onClose={() => setShowTable(false)} />
    </Panel>
  );
};
