import { Typography } from "@/components/atoms";
import { capitalize, parseHtml } from "@/utils";

export const getVehicleHistoryEventText = (eventType: string, eventName: string, fieldName?: string) => {
  const formatName = (name?: string) => (name ? capitalize(name.replace("_", " ").replace("-", " ").replace(".", " ")) : "");

  switch (eventName) {
    case "vehicle.access":
      return "Role change of vehicle owner/viewer";
    case "vehicle.created":
      return "Vehicle created";
    case "vehicle.update":
      return `Updated ${fieldName}`;
    case "vehicle.update.document.added":
      return `Document added`;
    case "vehicle.update.document.removed":
      return "Document removed";
    case "vehicle.update.category.added":
      return "Category added";
    case "vehicle.update.category.removed":
      return "Category removed";
    case "vehicle.verification":
      return "Vehicle Verification Change";

    default:
      return formatName(fieldName || eventName || eventType);
  }
};

export const getVehicleHistoryActionText = (name: string, from?: string, to?: string) => {
  const render = (value: string) => {
    return <span className="inline">{parseHtml(value, false)}</span>;
  };

  return (
    <div className="flex max-w-[270px] flex-col break-all">
      {to !== undefined && (
        <Typography variant="small" className="">
          <span className="inline-block min-w-[50px]">To:</span> {to ? render(to) : <span className="text-neutral-dark-gray">Empty</span>}
        </Typography>
      )}
      {from !== undefined && (
        <Typography variant="small" className="">
          <span className="inline-block min-w-[50px]">From:</span>{" "}
          {from ? render(from) : <span className="text-neutral-dark-gray">Empty</span>}
        </Typography>
      )}
    </div>
  );
};
