import { Button, Modal, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface VehicleInArchiveModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose: () => void;
  onReactivate: () => void;
}

export const VehicleInArchiveModal = ({ className, open, onClose, onReactivate, ...props }: VehicleInArchiveModalProps) => {
  return (
    <Modal open={open} onClose={onClose} className="max-w-[514px] !p-5">
      <div className={clsx("relative", className)} {...props}>
        <div className="flex max-w-lg flex-col ">
          <div className="mt-3 flex flex-row items-start">
            <Typography variant="title" className="flex-1">
              Vehicle Already in Archive
            </Typography>
          </div>
          <div className="mt-3 ">
            <Typography variant="paragraph">
              It looks like this vehicle is already in our archives. You can reactivate it instead of adding a new entry.
            </Typography>
            <Typography variant="paragraph" className="mt-4">
              Would you like to go to the archives and reactivate this vehicle?
            </Typography>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button variant="secondary" onClick={onClose} size="md">
            Cancel
          </Button>
          <Button variant="primary" onClick={onReactivate} size="md">
            Reactivate Vehicle
          </Button>
        </div>
      </div>
    </Modal>
  );
};
