import { Icon, Typography } from "@/components/atoms";
import { clsx } from "@/utils";

export interface URLDetailProps extends React.LinkHTMLAttributes<HTMLAnchorElement> {
  href?: string;
  hideIcon?: boolean;
}

export const URLDetail = (urlDetailProps: URLDetailProps) => {
  const { href, hideIcon, className, ...props } = urlDetailProps;

  if (!href) return null;

  return (
    <a href={href} target="_blank" rel="noreferrer" className={clsx("flex flex-row items-center gap-3", className)} {...props}>
      {!hideIcon && <Icon name="Link" className="flex-shrink-0 text-info" />}
      <Typography variant="paragraph" className="truncate">
        {href}
      </Typography>
    </a>
  );
};
