import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import { BasicTable, Button, Card, Divider, ErrorMessage, IconButton, TextInput, Typography, SidePanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useCancelBookingMutation } from "@/redux/apis/booking/endpoints/jobStatus";
import { addToast, formatCurrency } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { useSelectedBookingId } from "../../hooks/useSelectedBookingId";

interface CloseJobCustomerCancelProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  cancelledBy: z.string().nonempty({ message: "This field is required" }),
});

type FormData = z.infer<typeof schema>;

export const CloseJobCustomerCancel = ({ open, onClose }: CloseJobCustomerCancelProps) => {
  const { id: operatorId, currency } = useOperator();
  const {
    id: bookingId,
    bookedBy,
    cancellationPolicy: { asap, policy },
  } = useSelectedBooking();
  const [_, setSelectedBookingId] = useSelectedBookingId();
  const [cancelBooking, { isLoading: isCancellingBooking }] = useCancelBookingMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      cancelledBy: bookedBy,
    },
  });

  const onSubmit = async (data: FormData) => {
    return cancelBooking({ bookingId, cancelledBy: data.cancelledBy })
      .unwrap()
      .then(() => {
        addToast("success", "Booking cancelled successfully");
        setSelectedBookingId(undefined);
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const cancelIntervals = [policy.baseInterval, ...policy.intervals].reverse();
  return (
    <SidePanel open={open} onClose={onClose} width={550} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <i className="h-[10px] w-[10px] rounded-full bg-primary"></i>
          <Typography variant="title">Cancelled By Customer ({bookingId})</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
        {/* {isFetching && <Loading />} */}
        <div className="flex-1 p-5">
          <Card>
            <Typography className="mb-1">Cancelled By</Typography>
            <TextInput {...register("cancelledBy")} placeholder="Customer name" autoFocus />
            <ErrorMessage errors={errors} name="cancelledBy" />
          </Card>

          <Typography variant="action" className="mt-5 block">
            Fee calculation breakdown
          </Typography>
          <Typography className="text-neutral-dark-gray">
            Please note, cancellations within 11 hours incur a cancellation fee. ASAP bookings with no driver allocated will not incur a
            fee.
          </Typography>

          <BasicTable className="mt-5">
            <BasicTable.Row>
              <BasicTable.HeaderCell className="w-1/2">Time</BasicTable.HeaderCell>
              <BasicTable.HeaderCell>Booking Charged</BasicTable.HeaderCell>
            </BasicTable.Row>
            <>
              <BasicTable.Row>
                <BasicTable.Cell className="align-top">ASAP bookings</BasicTable.Cell>
                <BasicTable.Cell>
                  <Typography className="block leading-tight">{formatCurrency(asap.cancellationFee, 2, currency)} cancellation fee</Typography>
                  <Typography variant="small" className="leading-tight text-neutral-dark-gray">
                    (10 minute grace period applies)
                  </Typography>
                </BasicTable.Cell>
              </BasicTable.Row>
              {cancelIntervals.map((i, idx) => (
                <BasicTable.Row key={idx}>
                  <BasicTable.Cell>
                    {idx === cancelIntervals.length - 1 ? 0 : cancelIntervals[idx + 1].rangeTo} - {i.rangeTo} hours before pickup
                  </BasicTable.Cell>
                  <BasicTable.Cell>{i.rate}% cancellation fee</BasicTable.Cell>
                </BasicTable.Row>
              ))}
            </>
          </BasicTable>

          <div className="mt-5 flex place-content-center">
            <Link to={`/operator/${operatorId}/config/pricing/cancellation-fees`} target="_blank">
              <Button startIcon="ExportSquare" variant="tertiary">
                Manage Cancellation Fees
              </Button>
            </Link>
          </div>
        </div>
        <div className="p-5">
          <Divider className="-mx-5" />
          <Button type="submit" disabled={isSubmitting || isCancellingBooking} className="mt-5 w-full" variant="primary" size="lg">
            Cancel Booking
          </Button>
        </div>
      </form>
    </SidePanel>
  );
};
