import { Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { companyOptionsSelector } from "@/redux/slices/company/selectors";
import { clsx } from "@/utils";
import { BusinessRegistrationIdNumberField } from "./fields/BusinessRegistrationIdNumberField";
import { BusinessRegistrationTypeField } from "./fields/BusinessRegistrationTypeField";
import { CompanyAddressField } from "./fields/CompanyAddressField";
import { CompanyContactNumberField } from "./fields/CompanyContactNumberField";
import { CompanyNameField } from "./fields/CompanyNameField";
import { CustomerReferenceField } from "./fields/CustomerReferenceField";
import { DriverNotesField } from "./fields/DriverNotesField";
import { NotesField } from "./fields/NotesField";

export const CompaniesFormStep1 = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const options = useAppSelector(companyOptionsSelector);

  return (
    <div className={clsx("flex flex-col", className)} {...props}>
      <div className="grid w-full grid-cols-2 lg:grid-cols-3 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
        <div className="col-span-full">
          <Typography variant="title">Company Details</Typography>
        </div>
        <CompanyNameField className="col-span-full lg:col-span-1" />
        <CompanyAddressField className="col-span-full md:col-span-1 lg:col-span-2" />
        <CompanyContactNumberField className="col-span-full md:col-span-1 lg:col-span-1" />
        <CustomerReferenceField className="col-span-full lg:col-span-2" />
        <BusinessRegistrationTypeField className="col-span-full md:col-span-1 lg:col-span-1" options={options.businessRegistrationType} />
        <BusinessRegistrationIdNumberField className="col-span-full md:col-span-1 lg:col-span-1" />
      </div>
      <div className="mt-4 grid w-full grid-cols-2 gap-x-2.5 gap-y-3 lg:gap-x-5 lg:gap-y-6">
        <NotesField className="col-span-full lg:col-span-1" />
        <DriverNotesField className="col-span-full lg:col-span-1" />
      </div>
    </div>
  );
};
