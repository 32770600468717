import { isEmpty } from "ramda";
import { IconButton, Tooltip } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { newNotificationsSelector } from "@/redux/slices/notification/selectors";
import { clsx } from "@/utils";

interface HeaderNavProps {
  viewTransportProviderCode: () => void;
  viewNotifications: () => void;
}

export const HeaderNav = ({ viewTransportProviderCode, viewNotifications }: HeaderNavProps) => {
  const newNotifications = useAppSelector(newNotificationsSelector);

  const icons = [
    {
      name: "Text",
      hasAlert: false,
      onClick: viewTransportProviderCode,
      tooltip: "Transport Provider Code",
    },
    {
      name: "Notification",
      hasAlert: !isEmpty(newNotifications),
      onClick: viewNotifications,
      tooltip: "Notifications",
    },
  ];

  const iconClass =
    "bg-transparent inline-flex p-0 text-neutral-dark-gray transition-colors hover:bg-transparent hover:text-neutral-black";

  return (
    <nav className="flex items-center justify-end gap-4 ml-4">
      {icons.map(({ onClick, name, hasAlert, tooltip }, i) => (
        <Tooltip key={i} content={tooltip} placement="top" offset={[0, 0]}>
          <div className="relative inline-flex py-2">
            <IconButton
              variant="tertiary"
              onClick={onClick}
              iconName={name}
              iconVariant="Bold"
              iconSize="lg"
              className={clsx("inline", iconClass)}
            />
            {hasAlert && (
              <svg className="absolute left-4 bottom-6 h-3 w-3 text-danger" fill="currentColor" viewBox="0 0 8 8">
                <circle cx={4} cy={4} r={3} />
              </svg>
            )}
          </div>
        </Tooltip>
      ))}
    </nav>
  );
};