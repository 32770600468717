import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Icon, Typography, Modal, Button, MultiEmailInput, Loading } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { companyApi } from "@/redux/apis";
import { useUpdateInvoiceStatusMutation } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch } from "@/redux/hooks";
import { Invoice } from "@/redux/slices/payment/types";
import { addNotification, addToast, formatCurrency } from "@/utils";
import { issueInvoiceFormSchema } from "../fixtures";
import { IssueInvoiceFormData } from "../types";

interface IssueInvoiceModalProps {
  open: boolean;
  invoice: Invoice;
  onClose: () => void;
}

export const IssueInvoiceModal = ({ open, invoice, onClose }: IssueInvoiceModalProps) => {
  const dispatch = useAppDispatch();
  const { id: operatorId } = useOperator();
  const [updateInvoiceStatus, { isLoading }] = useUpdateInvoiceStatusMutation();
  const { watch, handleSubmit, setValue, reset } = useForm<IssueInvoiceFormData>({
    defaultValues: {
      emails: invoice.emailLog,
    },
    resolver: zodResolver(issueInvoiceFormSchema),
  });
  const watchEmails = watch("emails");

  useEffect(() => reset(), [invoice, reset]);

  const onSubmit = handleSubmit((data) => {
    const updateInvoiceData = {
      id: invoice.id,
      to: data.emails.map((email) => ({ email })),
      status: "issued",
    };

    updateInvoiceStatus(updateInvoiceData)
      .unwrap()
      .then(() => {
        addNotification("info", `Invoice ${invoice.identifier}`, "Invoice has been updated to Issued");
        onClose();
        dispatch(companyApi.util.invalidateTags(["InvoicesCompanies"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  });

  const renderHeader = () => (
    <div className="sm:flex sm:items-start">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Issue Invoice
          </Typography>
          <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
        </div>
      </div>
    </div>
  );

  const renderButtons = () => (
    <div className="mt-2.5 flex flex-col">
      <Button size="lg" variant="primary" onClick={onSubmit}>
        Send now to Email(s) listed
      </Button>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        if (!isLoading) onClose();
      }}
      className="relative w-[85vw] md:w-[550px] lg:w-[550px]"
    >
      {isLoading && <Loading />}
      {renderHeader()}
      <div className="flex flex-col gap-y-6 py-3">
        <Typography variant="paragraph">
          Issue an invoice for{" "}
          <Link to={`/operator/${operatorId}/companies/${invoice.company.id}`} target="_blank" className="text-info">
            {invoice.company.name}
          </Link>{" "}
          with {invoice.jobs.length} selected jobs totaling {`${invoice.currency} ${formatCurrency(invoice.amount, 2, invoice.currency)}`}.
          Save as draft or send now. After generation or saving as a draft, you can view the invoice in the &apos;Invoices&apos; tab.
        </Typography>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Send Email(s) to</Typography>
          <MultiEmailInput emails={watchEmails} onChange={(emails) => setValue("emails", emails)} />
        </div>
      </div>
      {renderButtons()}
    </Modal>
  );
};
