import { BookingOfferPanel } from "@/components/organisms/";
import { BookingOfferPageContextProvider } from "@/components/organisms/BookingOffer/context";
import { useBranding, useSiteConfig } from "@/hooks";
import { LinkExpired } from "../Expired/LinkExpired";
import { BookingOfferRejected } from "./BookingOfferRejected";

export const BookingOffer = () => {
  const { branding } = useSiteConfig();
  useBranding(branding);

  return (
    <BookingOfferPageContextProvider>
      <BookingOfferPanel />
    </BookingOfferPageContextProvider>
  );
};

BookingOffer.Rejected = BookingOfferRejected;
BookingOffer.NotFound = LinkExpired;
