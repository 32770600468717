import { RootState } from "@/redux/store";

export const costExtrasSelector = (state: RootState) => state.pricing.costExtras.items;
export const costExtrasPageSelector = (state: RootState) => state.pricing.costExtras.page;
export const vehicleCategoriesSelector = (state: RootState) => state.pricing.vehicleCategories.items;
export const vehicleCategoriesPageSelector = (state: RootState) => state.pricing.vehicleCategories.page;
export const offloadToSelector = (state: RootState) => state.pricing.vehicleCategories.list?.offloadTo;
export const inheritFromSelector = (state: RootState) => state.pricing.vehicleCategories.list?.inheritFrom;

export const dynamicPricingStatesSelector = (state: RootState) => state.pricing.tripCost.dynamicPricing.pricingStates;
export const dynamicPricingStateSelector = (slug: string) => (state: RootState) =>
  state.pricing.tripCost.dynamicPricing.pricingStates.find((l) => l.state === slug);
export const dynamicPricingPageStateSelector = (state: RootState) => state.pricing.tripCost.dynamicPricing.pageState;

export const pricingProfilesSelector = (state: RootState) => state.pricing.tripCost.fixedPricing.pricingProfiles;
export const pricingProfileSelector = (id: string) => (state: RootState) =>
  state.pricing.tripCost.fixedPricing.pricingProfiles.find((p) => p.id === id);
export const nationwideFixedPricingSelector = (state: RootState) => state.pricing.tripCost.fixedPricing.nationwidePricing;
export const fixedPricingStatesSelector = (state: RootState) => state.pricing.tripCost.fixedPricing.pricingStates;
export const fixedPricingStateSelector = (slug: string) => (state: RootState) =>
  state.pricing.tripCost.fixedPricing.pricingStates.find((s) => s.state === slug);
export const fixedPricingLocationsSelector = (state: RootState) => state.pricing.tripCost.fixedPricing.pricingLocations;
export const fixedPricingLocationSelector = (id: string) => (state: RootState) =>
  state.pricing.tripCost.fixedPricing.pricingLocations.find((l) => l.id === id);
export const fixedPricingPageStateSelector = (state: RootState) => state.pricing.tripCost.fixedPricing.pageState;

export const timeAndDaySelector = (state: RootState) => state.pricing.timeAndDay.items;
export const timeAndDayPageSelector = (state: RootState) => state.pricing.timeAndDay.page;
export const transferTypesSelector = (state: RootState) => state.pricing.transferTypes.items;
export const transferTypesPageSelector = (state: RootState) => state.pricing.transferTypes.page;
export const cancellationFeesSelector = (state: RootState) => state.pricing.cancellationFees.standardPricing;
export const asapPricingSelector = (state: RootState) => state.pricing.cancellationFees.asapPricing;
export const standardPricingSelector = (state: RootState) => state.pricing.cancellationFees.standardPricing;
export const hourlyHireSelector = (state: RootState) => state.pricing.hourlyHire.pricing;
export const hourlyHireMinimumSelector = (state: RootState) => state.pricing.hourlyHire.minimumBookingPeriodMinutes;
export const dailyHireSelector = (state: RootState) => state.pricing.dailyHire.pricing;
export const dailyHireMinimumSelector = (state: RootState) => state.pricing.dailyHire.minimumBookingPeriodMinutes;
export const unscheduledStopsSelector = (state: RootState) => state.pricing.unscheduledStops;
