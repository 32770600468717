import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Button, Loading, Panel, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { addToast } from "@/utils";
import { useLazyGetPaymentMethodQuery } from "@/redux/apis/paymentMethod/paymentMethodApi";
import { getErrorMessages } from "@/helpers/reduxHelpers";

export const PaymentMethodSetupPanel = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [getPaymentMethod, { isFetching }] = useLazyGetPaymentMethodQuery();

  const url = `${getPhpHostUrl()}/passenger/booking`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isStripeLoading = !stripe || !elements || isFetching;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isStripeLoading) return;

    setIsSubmitting(true);
    const result = await stripe.confirmSetup({
      elements,
      confirmParams: { return_url: url },
      redirect: "if_required",
    });
    
    if (result.error) {
      addToast("danger", result.error.message || "Something went wrong. Please contact your administrator.");
      setIsSubmitting(false);
    } else {    
      setTimeout(() => {
        getPaymentMethod(result.setupIntent.id)
          .unwrap()
          .then(() => {
            addToast("success", "Successfully added new payment method");  
            window.location.href = url;
          })
          .catch((e) => {
            getErrorMessages(e).forEach((m) => addToast("danger", m));
            setIsSubmitting(false);
          });
      }, 8000);
    }
  };

  return (
    <Panel className="relative flex h-full w-[550px] flex-col">
      {(isSubmitting || isStripeLoading) && <Loading />}
      <form id="payment-method-form" onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <Typography variant="h3">Add New Payment Method</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">Fill up your card information</Typography>
        </div>
        <div className="flex flex-col mt-2">
          <PaymentElement
            options={{
              paymentMethodOrder: ["card", "au_becs_debit"],
              layout: {
                type: "tabs",
                defaultCollapsed: false,
              },
            }}
          />
        </div>
        <Button variant="primary" size="lg" className="w-full mt-8" type="submit" disabled={isStripeLoading}>Save Payment Method</Button>
      </form>
    </Panel>
  );
};
