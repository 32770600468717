import { useState } from "react";
import { Skeleton, Button, IconButton, SearchInput, Container } from "@/components/atoms";
import { PageHeaderV2 } from "@/components/organisms";
import { RatingsTable } from "@/components/organisms/RatingManager";
import { usePagination } from "@/hooks/usePagination";
import { useAppSelector } from "@/redux/hooks";
import { ratingsSelector } from "@/redux/slices/rating/selectors";
import { RatingItem } from "@/redux/slices/rating/types";
import { useGetRatingsParams } from "@/components/organisms/RatingManager/hooks/useGetRatingsParams";
import { useGetRatingsQuery } from "@/redux/apis/rating/ratingApi";
import { RatingsModals } from "@/components/organisms/RatingManager/RatingsModals";
import { RatingsStep } from "@/components/organisms/RatingManager/types";

export const RatingManager = () => {
  const [selectedRating, setSelectedRating] = useState<RatingItem | undefined>(undefined);
  const { clearPagination } = usePagination();

  const { params, search, setSearch } = useGetRatingsParams();
  const { isFetching } = useGetRatingsQuery(params, { refetchOnMountOrArgChange: true });

  const ratings = useAppSelector(ratingsSelector);
  const isRatingsEmpty = ratings.length < 1 && !search && !isFetching;

  const [step, setStep] = useState<RatingsStep>(undefined);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  const renderHeaderActions = () => {
    if (isSearchActive) {
      return (
        <div className="flex items-center">
          <SearchInput
            isLoading={isFetching}
            search={search}
            onSearch={handleSearch}
            className="w-full max-w-sm sm:w-fit"
            placeholder="Search Rating"
            size="md"
          />
        </div>
      );
    }

    return (
      <div className="flex flex-row items-center">
        <div className="hidden sm:flex sm:flex-row sm:gap-x-1">
          <Button variant="tertiary" size="sm" startIcon="SearchNormal1" onClick={() => setIsSearchActive(true)}>
            Search
          </Button>
        </div>
        {/* mobile view */}
        <div className="flex flex-row gap-x-1 sm:hidden">
          <IconButton variant="tertiary" size="sm" iconName="SearchNormal1" onClick={() => setIsSearchActive(true)} />
        </div>
      </div>
    );
  };

  return (
    <Container className="flex flex-1 flex-col w-full mb-12">
      <PageHeaderV2 name="Rating Manager" skipCrumbs={1}>
        {renderHeaderActions()}
      </PageHeaderV2>
      {isFetching ? (
        <Skeleton />
      ) : (
        <div className="mt-2">
          <RatingsTable items={ratings} isEmpty={isRatingsEmpty} setStep={setStep} setSelectedRating={setSelectedRating} />
          <RatingsTable.Pagination />
        </div>
      )}
      <RatingsModals step={step} setStep={setStep} setSelectedRating={setSelectedRating} item={selectedRating} />
    </Container>
  );
};
