import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInvoicingSidePanelAction } from "@/redux/slices/payment/paymentSlice";
import { invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { InvoiceLogsPanel } from "./InvoiceLogsPanel";
import { InvoiceNotesPanel } from "./InvoiceNotesPanel";
import { InvoiceSetupPanel } from "./InvoiceSetupPanel";
import { PayByCreditCardPanel } from "./PayByCreditCardPanel";
import { UninvoicedJobsFilterPanel } from "./UninvoicedJobsFilterPanel";

export const InvoicingSidePanels = () => {
  const dispatch = useAppDispatch();
  const { sidePanelAction } = useAppSelector(invoicingPageStateSelector);

  const onClose = () => dispatch(setInvoicingSidePanelAction(undefined));

  return (
    <>
      {/* <InvoicesFilterPanel open={sidePanelAction === "filter"} onClose={onClose} /> */}
      <InvoiceLogsPanel open={sidePanelAction === "invoiceLogs"} onClose={onClose} />
      <UninvoicedJobsFilterPanel open={sidePanelAction === "uninvoicedJobsFilter"} onClose={onClose} />
      <InvoiceSetupPanel open={sidePanelAction === "invoiceSetup"} onClose={onClose} />
      <InvoiceNotesPanel open={sidePanelAction === "invoiceNotes"} onClose={onClose} />
      <PayByCreditCardPanel open={sidePanelAction === "payByCreditCard"} onClose={onClose} />
    </>
  );
};
