import { z } from "zod";

const defaultPricingAdjustmentSchema = z.object({
  isPriceIncrease: z.boolean(),
  price: z.coerce.number().gt(0).step(0.01, "Field must be a maximum of 2 decimal places."),
  displayToClient: z.boolean(),
  description: z.string().max(128, "Maximum of 128 characters.").optional(),
});
const priceIncreaseSchema = z.object({
  isPriceIncrease: z.literal<boolean>(true),
  price: z.coerce.number().max(9999),
});
const priceDecreaseSchema = z.object({
  isPriceIncrease: z.literal<boolean>(false),
  price: z.coerce.number().max(99),
});
const priceSchemaUnion = z.discriminatedUnion("isPriceIncrease", [priceIncreaseSchema, priceDecreaseSchema]);
const pricingAdjustmentFormSchema = z.intersection(defaultPricingAdjustmentSchema, priceSchemaUnion);

const defaultCompaniesFormSchema = z
  .object({
    companyName: z.string().nonempty("Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
    companyAddress: z.string().max(512, "Maximum of 512 characters.").optional(),
    companyContactNumber: z.string().nullable().optional(),
    customReferenceFieldDescription: z.string().max(100, "Maximum of 100 characters."),
    businessRegistrationType: z.string(),
    businessRegistrationIdNumber: z.string().max(16, "Maximum of 16 characters."),
    notes: z.string().max(1024, "Maximum of 1024 characters.").optional(),
    driverNotes: z.string().max(1024, "Maximum of 1024 characters.").optional(),
    keyContactName: z.string().max(100, "Maximum of 100 characters.").optional(),
    keyContactEmailAddress: z.string().max(100, "Maximum of 100 characters.").optional(),
    keyContactContactNumber: z.string().nullable().optional(),
    enableInvoicing: z.boolean(),
    invoiceGenerateLevel: z.string(),
    invoiceGenerateFrequency: z.string(),
    generateInvoiceOn: z.string(),
    paymentTerms: z.string(),
    billingContacts: z
      .array(
        z.object({
          name: z.string().optional(),
          emailAddress: z.string().optional(),
          contactNumber: z.string().nullable().optional(),
        })
      )
      .optional(),
    pricingAdjustments: z.array(pricingAdjustmentFormSchema).optional(),
    operatorCommissionOverride: z.boolean(),
    percentageCommission: z.coerce.number().step(1, "Field must be a whole number.").optional(),
    pricingProfile: z.string(),
    allowDriverExtras: z.boolean(),
    paidCustomerExtras: z.boolean(),
  })
  .refine((data) => !(data.operatorCommissionOverride && !data.percentageCommission), {
    message: "Field is required and can't be empty.",
    path: ["percentageCommission"],
  })
  .refine((data) => !(data.businessRegistrationType && !data.businessRegistrationIdNumber), {
    message: "Field is required and can't be empty.",
    path: ["businessRegistrationIdNumber"],
  });

const enabledInvoicingBillingContactsSchema = z.object({
  enableInvoicing: z.literal<boolean>(true),
  billingContacts: z
    .array(
      z.object({
        name: z.string().optional(),
        emailAddress: z
          .string()
          .nonempty("Field is required and can't be empty.")
          .email("Email address is not valid")
          .max(100, "Maximum of 100 characters."),
        contactNumber: z.string().nullable().optional(),
      })
    )
    .optional(),
});

const disabledInvoicingBillingContactsSchema = z.object({
  enableInvoicing: z.literal<boolean>(false),
  billingContacts: z
    .array(
      z.object({
        name: z.string().optional(),
        emailAddress: z.string().optional(),
        contactNumber: z.string().nullable().optional(),
      })
    )
    .optional(),
});
const billingContactsSchemaUnion = z.discriminatedUnion("enableInvoicing", [
  enabledInvoicingBillingContactsSchema,
  disabledInvoicingBillingContactsSchema,
]);

export const companiesFormSchema = z.intersection(defaultCompaniesFormSchema, billingContactsSchemaUnion);
