import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { VehicleCategoryFormData } from "../../types";

export const NumberOfPassengersField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<VehicleCategoryFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography variant="paragraph">Number of passengers that fit in vehicle</Typography>
      <TextInput
        className="w-full"
        type="number"
        placeholder="0"
        step="any"
        hasError={!!errors.numberOfPassengers}
        {...register("numberOfPassengers")}
      />
      <ErrorMessage errors={errors} name="numberOfPassengers" />
    </div>
  );
};
