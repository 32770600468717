import { useNavigate } from "react-router-dom";
import { Button, Container, Typography } from "@/components/atoms";
import { GeneralHeader } from "@/components/organisms";

interface NotFoundProps {
  hideHeader?: boolean;
}

export const NotFound = ({ hideHeader }: NotFoundProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row min-h-screen overflow-hidden bg-white">
      <div className="flex flex-1 flex-col relative">
        <div className="fixed bottom-64 md:bottom-44 lg:bottom-72 bg-404-clouds min-h-[100%] w-full bg-cover bg-no-repeat pointer-events-none" />
        {!hideHeader && <GeneralHeader />}
        <Container className="flex flex-1 justify-center w-full pt-[14vh] px-5 pb-32 z-10">
          <div className="flex flex-col items-center">
            <Typography variant="title" className="mb-6 !text-[64px] sm:!text-[160px]">
              404
            </Typography>
            <Typography variant="title" className="mb-3 text-center !text-[32px] md:!text-[48px] lg:!text-[60px]">
              Whoops... this page is not available
            </Typography>
            <Typography variant="paragraph" className="mb-8 text-center">
              We searched everywhere but couldn&apos;t find what you&apos;re looking for. Let&apos;s find a better place for you to go
            </Typography>
            <Button onClick={() => navigate("/")} className="relative z-20" size="lg">
              Go Back
            </Button>
          </div>
        </Container>
        <div className="fixed bottom-0 bg-city min-h-[50%] w-full bg-cover bg-no-repeat pointer-events-none" />
      </div>
    </div>
  );
};
