import { useNavigate } from "react-router-dom";
import { Button, Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setDynamicPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { dynamicPricingPageStateSelector } from "@/redux/slices/pricing/selectors";
import { AddNoteForm } from "../AddNoteForm";
import { modals } from "./fixtures";

interface DynamicPricingEditorModalsProps {
  selectedInterval: number | null;
  selectedIntervalNote: string;
  onDeleteRow: () => void;
  onDeleteDynamicPricing: () => void;
  onAddNote: (note: string) => void;
}

export const DynamicPricingEditorModals = ({
  selectedInterval,
  selectedIntervalNote,
  onDeleteRow,
  onDeleteDynamicPricing,
  onAddNote,
}: DynamicPricingEditorModalsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { action } = useAppSelector(dynamicPricingPageStateSelector);

  const onClose = () => dispatch(setDynamicPricingAction(undefined));

  return (
    <>
      <Modal
        className="w-[85vw] md:w-[510px] lg:w-[510px]"
        open={action === "exit"}
        title={modals["exit"].title}
        description={modals["exit"].description}
        onClose={onClose}
        renderButtons={() => (
          <>
            <Button variant="secondary" size="md" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                onClose();
                navigate("../");
              }}
            >
              Discard Changes
            </Button>
          </>
        )}
      />
      <Modal
        className="w-[85vw] md:w-[510px] lg:w-[510px]"
        open={action === "delete"}
        title={modals["delete"].title}
        description={modals["delete"].description}
        onClose={onClose}
        renderButtons={() => (
          <>
            <Button variant="secondary" size="md" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" size="md" onClick={onDeleteRow} className="bg-danger hover:bg-danger-dark">
              Delete
            </Button>
          </>
        )}
      />
      <Modal
        className="w-[85vw] md:w-[510px] lg:w-[510px]"
        open={action === "resetTripCosts"}
        title={modals["resetTripCosts"].title}
        description={modals["resetTripCosts"].description}
        onClose={onClose}
        renderButtons={() => (
          <>
            <Button variant="secondary" size="md" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" size="md" onClick={onDeleteDynamicPricing} className="bg-danger hover:bg-danger-dark">
              Reset Trip Costs
            </Button>
          </>
        )}
      />
      <Modal className="w-[85vw] md:w-[510px] lg:w-[510px]" open={action === "addNote"} onClose={onClose}>
        <AddNoteForm selectedRange={selectedInterval} selectedRangeNote={selectedIntervalNote} onAddNote={onAddNote} onClose={onClose} />
      </Modal>
    </>
  );
};
