import { AuBankAccountElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Loading, TextInput, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePage } from "@/hooks";
import { useGetDebitsSecretMutation, useLazyGetDebitsDetailsQuery } from "@/redux/apis/payment/stripe/stripeApi";
import { useAppSelector } from "@/redux/hooks";
import { stripeSelector } from "@/redux/slices/payment/selectors";
import { addToast } from "@/utils";
import { auBankAccountOptions } from "../fixtures";
import { Userpilot } from "userpilot";

export const DebitsEditor = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { pathname } = usePage();
  const url = `${getPhpHostUrl()}/dashboard${pathname}`;

  const [getDebitsDetails] = useLazyGetDebitsDetailsQuery();
  const [getDebitsSecret, { isLoading }] = useGetDebitsSecretMutation();
  const {
    debits: { clientSecret },
  } = useAppSelector(stripeSelector);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getDebitsSecret({
      refresh_url: `${url}?refresh=1`, //redirect to view
      redirect_url: url,
    })
      .unwrap()
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const auBankAccount = elements.getElement(AuBankAccountElement);
    if (!auBankAccount || !fullName || !email) return;

    setIsSubmitting(true);
    const result = await stripe.confirmAuBecsDebitSetup(clientSecret, {
      payment_method: {
        au_becs_debit: auBankAccount,
        billing_details: {
          name: fullName,
          email,
        },
      },
    });

    if (result.error) {
      addToast("danger", result.error.message || "Something went wrong. Please contact your administrator.");
      setIsSubmitting(false);
    } else {
      onSuccess();
    }
  };

  const onSuccess = () => {
    setTimeout(() => {
      Userpilot.track("Debits Configured");
      getDebitsDetails();
      addToast("success", "Successfully set up BECS Direct Debit");
      setTimeout(() => window.location.replace("../"), 1250);
    }, 8000);
  };

  return (
    <form id="stripe-debits-form" className="relative flex flex-col " onSubmit={handleSubmit}>
      {isLoading || (isSubmitting && <Loading />)}
      <div className="flex flex-col gap-y-3 rounded-lg bg-neutral-surface-gray p-5 lg:w-[573px]">
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Bank Account</Typography>
          <AuBankAccountElement options={auBankAccountOptions} />
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Email</Typography>
          <TextInput
            placeholder="john.smith@example.com"
            size="md"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Full name</Typography>
          <TextInput
            placeholder="John Smith"
            size="md"
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>
        <Typography variant="small" className="text-neutral-dark-gray">
          By providing your bank account details and confirming this payment, you agree to this Direct Debit Request and the{" "}
          <a href="https://stripe.com/au-becs-dd-service-agreement/legal" className="underline">
            Direct Debit Request service agreement
          </a>{" "}
          and authorize Stripe Payments Australia Pty Ltd ACN 160 180 343 Direct Debit User ID number 507156 (“Stripe”) to debit your
          account through the Bulk Electronic Clearing System (BECS) on behalf of Rideminder Australia Pty Ltd (the “Merchant”) for any
          amounts separately communicated to you by the Merchant. You certify that you are either an account holder or an authorized
          signatory on the account listed above.
        </Typography>
      </div>
      <div className="mt-4 flex flex-row gap-x-4">
        <Button variant="primary" type="submit" form="stripe-debits-form">
          Set up BECS Direct Debit
        </Button>
        <Button variant="secondary" onClick={() => navigate("../")}>
          Cancel
        </Button>
      </div>
    </form>
  );
};
