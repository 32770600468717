import { Card, IconButton, Typography, SidePanel, Blockquote, Icon } from "@/components/atoms";
import { BookingDetails } from "@/redux/slices/booking/types";
import { formatCurrency, getAssetUrl } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";
import { closeJobTypes, extraTypeOptions } from "./fixtures";

interface ViewClosingExtrasNotesProps {
  open: boolean;
  onClose: () => void;
}

export const ViewClosingExtrasNotes = ({ open, onClose }: ViewClosingExtrasNotesProps) => {
  const booking = useSelectedBooking();
  const { closeMeta } = booking;

  if (!closeMeta) {
    return null;
  }

  const renderBody = () => {
    switch (closeMeta.reason) {
      case "auto_closed":
        return <AutoClosed />;
      case "closed_no_cost":
        return <ClosedNoCost justification={closeMeta.driverNotes} />;
      default:
        return <ClosingDetails booking={booking} />;
    }
  };

  const title = closeJobTypes.find((type) => type.value === closeMeta.reason)?.name;

  return (
    <SidePanel open={open} onClose={onClose} width={550} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <i className="h-[10px] w-[10px] rounded-full bg-primary"></i>
          <Typography variant="title">Close Job ({booking.id})</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="flex min-h-full flex-col space-y-4 p-5">
        {title && (
          <Card>
            <Typography variant="title">{title}</Typography>
          </Card>
        )}
        {renderBody()}
      </section>
    </SidePanel>
  );
};

const AutoClosed = () => {
  return (
    <div className="flex flex-1 flex-col space-y-5">
      <Card>
        <Typography>This job was auto closed by the system.</Typography>
      </Card>
    </div>
  );
};

const ClosedNoCost = ({ justification }: { justification: string }) => {
  return (
    <div className="flex flex-1 flex-col space-y-5">
      <Card>
        <Typography variant="title">Closed at No Cost</Typography>
      </Card>
      {justification && (
        <Card>
          <Typography>Justification</Typography>
          <Typography>{justification}</Typography>
        </Card>
      )}
    </div>
  );
};

const ClosingDetails = ({ booking }: { booking: BookingDetails }) => {
  const {
    driver,
    closeMeta,
    cost: { currency },
    stops,
  } = booking;

  if (!closeMeta) return null;

  const { waitTime, additionalRunTime, unscheduledStops, parkingCharges, tollCharges, otherExtras, providerNotes, clientNotes } = closeMeta;
  const hasExtras = Boolean(waitTime || additionalRunTime || unscheduledStops || parkingCharges || tollCharges);
  const hasOtherExtras = Boolean(otherExtras?.length);

  return (
    <div className="space-y-4">
      {driver && closeMeta.driverNotes && (
        <Card className="p-0">
          <Blockquote avatarURL={getAssetUrl(driver?.avatar)} heading={driver.name} subheading="Driver Notes">
            {closeMeta.driverNotes}
          </Blockquote>
        </Card>
      )}

      {hasExtras && (
        <Card>
          <Typography variant="title">Extras</Typography>

          {waitTime && (
            <div className="border-b border-neutral-mid-gray py-6 last:border-b-0">
              <Typography className="mb-1 text-primary">Additional Waiting Time (minutes) after the Courtesy Waiting Times</Typography>
              <Typography variant="small" className="leading-loose text-neutral-dark-gray">
                Courtesy waiting times are: 10 minutes regular pickup, 30 minutes Domestic Airport, 60 minutes International Airport
              </Typography>
              <ul className="mt-4 flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <Icon name="DollarCircle" className="h-[18px] w-[18px]" />
                  <Typography variant="title">
                    {formatCurrency(waitTime.cost, 2, currency, {
                      prependCurrencySymbol: true,
                      skipDecimalConversion: true,
                    })}
                  </Typography>
                </li>
                <li className="flex items-center gap-4  ">
                  <Icon name="Timer1" className="h-[18px] w-[18px]" />
                  <Typography variant="title">{waitTime.name} minutes</Typography>
                </li>
              </ul>
            </div>
          )}

          {additionalRunTime && (
            <div className="border-b border-neutral-mid-gray py-6 last:border-b-0">
              <Typography className="mb-1  text-primary">Additional Waiting Time (minutes) after the Courtesy Waiting Times</Typography>
              <ul className="mt-4 flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <Icon name="DollarCircle" className="h-[18px] w-[18px]" />
                  <Typography variant="title">
                    {formatCurrency(additionalRunTime.cost, 2, currency, {
                      prependCurrencySymbol: true,
                      skipDecimalConversion: true,
                    })}
                  </Typography>
                </li>
                <li className="flex items-center gap-4  ">
                  <Icon name="Timer1" className="h-[18px] w-[18px]" />
                  <Typography variant="title">{additionalRunTime.name}</Typography>
                </li>
              </ul>
            </div>
          )}

          {unscheduledStops && (
            <div className="border-b border-neutral-mid-gray py-6 last:border-b-0">
              <Typography className="mb-1  text-primary">Additional Unscheduled Stops</Typography>
              <ul className="mt-4 flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <Icon name="DollarCircle" className="h-[18px] w-[18px]" />
                  <Typography variant="title">
                    {formatCurrency(unscheduledStops.cost, 2, currency, {
                      prependCurrencySymbol: true,
                      skipDecimalConversion: true,
                    })}
                  </Typography>
                </li>
                <li className="flex items-center gap-4  ">
                  <Icon name="Danger" className="h-[18px] w-[18px]" />
                  <Typography variant="title">{unscheduledStops.name} unscheduled stops</Typography>
                </li>
              </ul>
              {stops.length - 2 > Number(unscheduledStops.name) && (
                <Typography className="mt-2.5">
                  Addresses:{" "}
                  {stops.slice(1, Number(unscheduledStops.name) + 1).map((stop) => {
                    return stop.address;
                  })}
                </Typography>
              )}
            </div>
          )}

          {parkingCharges && (
            <div className="border-b border-neutral-mid-gray py-6 last:border-b-0">
              <Typography className="mb-1 text-primary">Additional Parking Charges</Typography>
              <ul className="mt-4 flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <Icon name="DollarCircle" className="h-[18px] w-[18px]" />
                  <Typography variant="title">
                    {formatCurrency(parkingCharges.cost, 2, currency, {
                      prependCurrencySymbol: true,
                      skipDecimalConversion: true,
                    })}
                  </Typography>
                </li>
                <li className="flex items-center gap-4  ">
                  <Icon name="TickCircle" className="h-[18px] w-[18px]" />
                  <Typography variant="title">
                    {extraTypeOptions.find((option) => option.value === parkingCharges.type)?.name} Payment
                  </Typography>
                </li>
              </ul>
            </div>
          )}

          {tollCharges && (
            <div className="border-b border-neutral-mid-gray py-6 last:border-b-0">
              <Typography className="mb-1 text-primary">Toll Charges (Tolls incurred on the journey)</Typography>
              <ul className="mt-4 flex flex-col gap-4">
                <li className="flex items-center gap-4">
                  <Icon name="DollarCircle" className="h-[18px] w-[18px]" />
                  <Typography variant="title">
                    {formatCurrency(tollCharges.cost, 2, currency, {
                      prependCurrencySymbol: true,
                      skipDecimalConversion: true,
                    })}
                  </Typography>
                </li>
                <li className="flex items-center gap-4  ">
                  <Icon name="TickCircle" className="h-[18px] w-[18px]" />
                  <Typography variant="title">
                    {extraTypeOptions.find((option) => option.value === tollCharges.type)?.name} Payment
                  </Typography>
                </li>
              </ul>
            </div>
          )}
        </Card>
      )}

      {hasOtherExtras && (
        <>
          <Typography variant="action" className="!mt-8 block">
            Other Charges
          </Typography>

          {otherExtras?.map((extra, idx) => {
            return (
              <Card key={extra.name}>
                <Typography className="mb-1 text-primary">
                  Charges Description #{idx + 1}: {extra.name}
                </Typography>
                <ul className="mt-4 flex flex-col gap-4">
                  <li className="flex items-center gap-4">
                    <Icon name="DollarCircle" className="h-[18px] w-[18px]" />
                    <Typography variant="title">
                      {formatCurrency(extra.cost, 2, currency, {
                        prependCurrencySymbol: true,
                        skipDecimalConversion: true,
                      })}
                    </Typography>
                  </li>
                  <li className="flex items-center gap-4  ">
                    <Icon name="TickCircle" className="h-[18px] w-[18px]" />
                    <Typography variant="title">{extraTypeOptions.find((option) => option.value === extra.type)?.name} Payment</Typography>
                  </li>
                </ul>
              </Card>
            );
          })}
        </>
      )}

      {providerNotes && (
        <Card className="space-y-4">
          <Typography>Notes for Editing Extras - To be shared with the driver</Typography>
          <Typography>{providerNotes}</Typography>
        </Card>
      )}

      {clientNotes && (
        <Card className="space-y-4">
          <Typography>Notes to Customer</Typography>
          <Typography>{clientNotes}</Typography>
        </Card>
      )}
    </div>
  );
};
