import { Modal } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setFixedPricingAction } from "@/redux/slices/pricing/pricingSlice";
import { fixedPricingPageStateSelector } from "@/redux/slices/pricing/selectors";
import { AddPricingProfileForm } from "./forms/AddPricingProfileForm";

export const FixedPricingModals = () => {
  const dispatch = useAppDispatch();
  const { action } = useAppSelector(fixedPricingPageStateSelector);

  const onClose = () => dispatch(setFixedPricingAction(undefined));

  return (
    <>
      <Modal className="w-[85vw] md:w-[510px] lg:w-[510px]" open={action === "addPricingProfile"} onClose={onClose}>
        <AddPricingProfileForm onClose={onClose} />
      </Modal>
    </>
  );
};
