import { SignUpForms } from "./SignUpForms";
import { SignUpSuccess } from "./SignUpSuccess";
import { useSignUpContext } from "./context";
import { ProviderSelectForm } from "./forms/ProviderSelectForm";
import { UserSelectForm } from "./forms/UserSelectForm";

export const SignUpSteps = () => {
  const { step } = useSignUpContext();

  switch (step) {
    case "success":
      return <SignUpSuccess />;
    case "provider-select":
      return <ProviderSelectForm />;
    case "signup":
      return <SignUpForms />;
    default:
      return <UserSelectForm />;
  }
};
