import { clsx } from "@/utils";
import { Icon, Typography } from "..";

export type NotificationType = "success" | "danger" | "warning" | "info";
export interface NotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  type: NotificationType;
  title?: string;
  message?: string;
  children?: React.ReactNode;
  visible?: boolean;
  onClose: () => void;
}

export const Notification = ({ type, title, message, visible, className, onClose, children, ...props }: NotificationProps) => {
  const lineColor = {
    "bg-success": type === "success",
    "bg-danger": type === "danger",
    "bg-info": type === "info",
    "bg-warning": type === "warning",
  };

  const transition = {
    "animate-slide-in": visible,
    "animate-leave": !visible,
  };

  return (
    <div
      className={clsx(
        "ring-none pointer-events-auto relative w-[350px] max-w-full overflow-hidden rounded-lg bg-white shadow",
        transition,
        className
      )}
      {...props}
    >
      <button type="button" className="absolute right-2 top-2 inline-flex rounded-md text-black" onClick={onClose}>
        <span className="sr-only">Close</span>
        <Icon name="close" isCustom className="h-5 w-5" aria-hidden="true" />
      </button>
      <div className={clsx("absolute ml-2 mt-2 h-[calc(100%-1rem)] w-1 rounded", lineColor)}>&nbsp;</div>
      <div className="flex-1 p-5 px-7">
        <div className="flex items-center">
          <div className="flex-shrink-0">{renderIcon(type)}</div>
          <div className="ml-2 w-0 flex-1">
            <Typography variant="action">{title}</Typography>
          </div>
        </div>
        <div className="contents">{children ? children : <Typography className="mt-1 truncate leading-5">{message}</Typography>}</div>
      </div>
    </div>
  );
};

const renderIcon = (type: NotificationType) => {
  switch (type) {
    case "info":
      return <Icon className="text-inherit flex-shrink-0 rotate-180 self-center text-info" name="InfoCircle" variant="Bold" size="md" />;
    case "danger":
      return <Icon className="text-inherit flex-shrink-0 self-center text-danger" name="InfoCircle" variant="Bold" size="md" />;
    case "warning":
      return <Icon className="text-inherit flex-shrink-0 self-center text-warning" name="Danger" variant="Bold" size="md" />;
    case "success":
      return <Icon className="text-inherit flex-shrink-0 self-center text-success" name="TickCircle" variant="Bold" size="md" />;
    default:
      return null;
  }
};
