import { useMemo } from "react";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { DebitsHistoryItem } from "@/redux/slices/payment/types";
import { clsx } from "@/utils";
import { generateBankDetailsDebitsHistoryColumns, generateBankDetailsDebitsHistoryData } from "../helpers";

export interface BankDetailsDebitsHistoryTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: DebitsHistoryItem[];
}

export const BankDetailsDebitsHistoryTable = ({ items, className, ...props }: BankDetailsDebitsHistoryTableProps) => {
  const { dateFormat, timeFormat } = useProfile();

  const data = generateBankDetailsDebitsHistoryData(items, dateFormat, timeFormat);
  const columns = useMemo(() => generateBankDetailsDebitsHistoryColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};
