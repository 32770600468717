import { Icon, IconButton, Typography, SidePanel } from "@/components/atoms";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { resetPayByCreditCard } from "@/redux/slices/payment/paymentSlice";
import { invoicingInvoicesPayByCreditCardSelector, invoicingPageStateSelector } from "@/redux/slices/payment/selectors";
import { PayByCreditCardStep1 } from "./contents/PayByCreditCardStep1";
import { PayByCreditCardStep2 } from "./contents/PayByCreditCardStep2";
import { PayByCreditCardStep3 } from "./contents/PayByCreditCardStep3";

interface PayByCreditCardPanelProps {
  open: boolean;
  onClose: () => void;
}

export const PayByCreditCardPanel = ({ open, onClose }: PayByCreditCardPanelProps) => {
  const dispatch = useAppDispatch();
  const { selectedInvoice: invoice } = useAppSelector(invoicingPageStateSelector);
  const { paymentStep } = useAppSelector(invoicingInvoicesPayByCreditCardSelector);

  const handleClose = () => {
    dispatch(resetPayByCreditCard());
    onClose();
  };

  const renderSteps = () => {
    if (invoice) {
      switch (paymentStep) {
        case 2:
          return <PayByCreditCardStep2 invoice={invoice} />;
        case 3:
          return <PayByCreditCardStep3 invoice={invoice} />;
        default:
          return <PayByCreditCardStep1 invoice={invoice} />;
      }
    }
  };

  return (
    <SidePanel open={open} onClose={handleClose} width={550} focused>
      <div className="flex items-center justify-between px-5 py-5">
        <div className="flex flex-row items-center gap-2">
          <Icon name="ReceiptText" size="lg" />
          <Typography variant="title">Invoice #{invoice?.identifier}</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} variant="tertiary" className="-mr-4 hover:bg-transparent" />
        </div>
      </div>
      <div className="flex h-full">{renderSteps()}</div>
    </SidePanel>
  );
};
