import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AddOnTextInput, Button, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateHourlyHireMinimumMutation } from "@/redux/apis/config/pricing/hourlyHire";
import { addToast, clsx } from "@/utils";
import { HourlyHireMinimumFormData } from "./types";

const onError = (messages: string[]) => {
  messages.forEach((m) => addToast("danger", m));
};

interface HourlyHireMinimumEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  hourlyHireMinimum: number;
}

export const HourlyHireMinimumEditor = ({ hourlyHireMinimum, className, ...props }: HourlyHireMinimumEditorProps) => {
  const navigate = useNavigate();
  const [updateHourlyHireMinimum, { isLoading }] = useUpdateHourlyHireMinimumMutation();
  const { register, handleSubmit } = useForm<HourlyHireMinimumFormData>({
    defaultValues: { hourlyHireMinimum },
  });

  const onSubmit = handleSubmit((data) => {
    updateHourlyHireMinimum({ minimum_booking_period_minutes: data.hourlyHireMinimum * 60 })
      .unwrap()
      .then(() => {
        addToast("success", "Successfully updated minimum booking hours.");
        navigate(-1);
      })
      .catch((e) => onError(getErrorMessages(e)));
  });

  return (
    <div className={clsx("relative", className)}>
      {isLoading && <Loading />}
      <div className="flex flex-col">
        <Typography variant="h3" className="leading-8">
          Hourly Hire - Minimum booking time
        </Typography>
        <Typography className="text-neutral-dark-gray">Set the minimum hourly hire that you allow customers to book.</Typography>
      </div>
      <div className="border-1 relative mt-5 w-[300px] rounded-lg border border-neutral-mid-gray bg-white p-4" {...props}>
        <Typography variant="action">Minimum booking hours</Typography>
        <form className="mt-2 flex items-center gap-4" onSubmit={onSubmit}>
          <AddOnTextInput
            type="number"
            endAddOn="Hours"
            className="[&>input]:text-right"
            {...register("hourlyHireMinimum")}
            required
            step="1"
            min="1"
            max="23"
          />
          <Button type="submit" className="py-1.5">
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
