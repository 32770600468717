import { useMemo } from "react";
import { DataTable, NoSearchResults, Pagination } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination } from "@/hooks";
import { useToggleFleetLeaderMutation } from "@/redux/apis/driver/driverApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { toggleIsFleetLeader } from "@/redux/slices/driver/driverSlice";
import { fleetDriversPageSelector } from "@/redux/slices/driver/selectors";
import { FleetDriver } from "@/redux/slices/driver/types";
import { addToast } from "@/utils";
import { generateMyDriversTableColumns, generateMyDriversTableData } from "../helpers";
import { Loading } from "@/components/atoms";
import { MyDriversStep } from "../types";
import { MyDriversEmptyState } from "../contents/MyDriversEmptyState";
import { useGetFleetDriversParams } from "../hooks/useGetFleetDriversParams";

interface MyDriversTableProps {
  items: FleetDriver[];
  setStep: (step: MyDriversStep) => void;
}

export const MyDriversTable = ({ items, setStep }: MyDriversTableProps) => {
  const dispatch = useAppDispatch();
  const [toggle, { isLoading }] = useToggleFleetLeaderMutation();
  const { sorting, setSorting, search, filters } = useGetFleetDriversParams();
  const isFiltersEmpty = Object.values(filters).every(el => el === undefined);

  const toggleFleetLeader = (id: string, isFleetLeader: boolean) => {
    toggle({ id, isFleetLeader })
      .unwrap()
      .then(() => {
        dispatch(toggleIsFleetLeader({ id, isFleetLeader }));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const renderEmptyState = () => {
    if (search) return <NoSearchResults />;
    if (!isFiltersEmpty) return <MyDriversEmptyState hasFilters />;

    return <MyDriversEmptyState />;
  };

  const columns = useMemo(
    () => generateMyDriversTableColumns(setStep), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const data = useMemo(
    () => generateMyDriversTableData(items, toggleFleetLeader, isLoading), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, isLoading]
  );

  return (
    <div className="relative">
      {isLoading && <Loading className="rounded-lg" />}
      <DataTable
        className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
        data={data}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={renderEmptyState()} 
      />
    </div>
  );
};

const MyDriversTablePagination = () => {
  const { current, size, total, count } = useAppSelector(fleetDriversPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("my-drivers");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

MyDriversTable.Pagination = MyDriversTablePagination;
