/* eslint-disable @typescript-eslint/no-explicit-any */
import { bookingApi, updateBookingQueryData } from "../bookingApi";
import { RawBookingDetails } from "../types";

export const statusEndpoints = bookingApi.injectEndpoints({
  endpoints: (builder) => ({
    reopenBooking: builder.mutation<RawBookingDetails, { bookingId: string }>({
      query: ({ bookingId }) => ({
        url: `/${bookingId}/status/reopen`,
        method: "POST",
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    confirmDriver: builder.mutation<RawBookingDetails, { bookingId: string; driverId: string }>({
      query: ({ bookingId, driverId }) => ({
        url: `/${bookingId}/status/confirm`,
        method: "POST",
        body: {
          driver_uuid: driverId,
        },
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    markDriverOnWay: builder.mutation<RawBookingDetails, { bookingId: string }>({
      query: ({ bookingId }) => ({
        url: `/${bookingId}/status/on-way`,
        method: "POST",
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    commenceJob: builder.mutation<RawBookingDetails, { bookingId: string }>({
      query: ({ bookingId }) => ({
        url: `/${bookingId}/status/commence`,
        method: "POST",
      }),
      onQueryStarted: updateBookingQueryData,
    }),
    cancelBooking: builder.mutation<RawBookingDetails, { bookingId: string; cancelledBy: string }>({
      query: ({ bookingId, cancelledBy }) => ({
        url: `/${bookingId}/status/cancel`,
        method: "POST",
        body: {
          cancelled_by: cancelledBy,
        },
      }),
      onQueryStarted: updateBookingQueryData,
    }),
  }),
});

export const {
  useCancelBookingMutation,
  useCommenceJobMutation,
  useConfirmDriverMutation,
  useMarkDriverOnWayMutation,
  useReopenBookingMutation,
} = statusEndpoints;
