import { useMemo } from "react";
import { DataTable, EmptyState } from "@/components/molecules";
import { useSorting } from "@/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { generateAssociatedVehiclesColumns, generateAssociatedVehiclesTableData } from "./helpers";

export interface AssociatedVehiclesTableProps {
  items: Vehicle[];
  checkedIds: string[];
  onCheckAllChanged: (checked: boolean) => void;
  onCheckChanged: (vehicleId: string) => void;
}

export const AssociatedVehiclesTable = ({ items, checkedIds, onCheckChanged, onCheckAllChanged }: AssociatedVehiclesTableProps) => {
  const { sorting, setSorting } = useSorting();
  const allChecked = checkedIds.length > 0 && checkedIds.length === items.length;
  const columns = useMemo(() => generateAssociatedVehiclesColumns(onCheckAllChanged, allChecked), [allChecked, onCheckAllChanged]);
  const data = useMemo(() => generateAssociatedVehiclesTableData(items, checkedIds, onCheckChanged), [checkedIds, items, onCheckChanged]);
  return (
    <DataTable
      className="max-h-[400px] w-full overflow-auto [&_table]:table-auto [&_tr]:bg-white"
      data={data}
      columns={columns}
      sorting={sorting}
      setSorting={setSorting}
      emptyState={<EmptyState title="No Vehicles Found" description="Matched vehicles will show here" />}
    />
  );
};
