import { isEmpty } from "ramda";
import { EmptyState, NoSearchResults } from "@/components/molecules";
import { useInfiniteScroll } from "@/hooks";
import { useGetInvoicesCompaniesQuery, useLazyGetInvoicesCompaniesQuery } from "@/redux/apis/company/companyApi";
import { useAppSelector } from "@/redux/hooks";
import {
  invoicingInvoicesCompaniesPageSelector,
  invoicingInvoicesSelector,
  invoicingOptionsSelector,
} from "@/redux/slices/payment/selectors";
import { CompanyDisclosure } from "./contents";
import { CompanyDisclosureSkeleton } from "./contents/CompanyDisclosureSkeleton";
import { useGetInvoicesCompaniesParams } from "./hooks";
import { InvoicesTable } from "./tables";

export const InvoicesPanel = () => {
  const { params, search } = useGetInvoicesCompaniesParams();
  const { isFetching } = useGetInvoicesCompaniesQuery({ page: "1", ...params }, { refetchOnMountOrArgChange: true });
  const [getInvoicesCompanies, { isFetching: isFetchingLazy }] = useLazyGetInvoicesCompaniesQuery();
  const { companies } = useAppSelector(invoicingInvoicesSelector);
  const { count, current } = useAppSelector(invoicingInvoicesCompaniesPageSelector);
  const { currency } = useAppSelector(invoicingOptionsSelector);
  const containerClassName = "flex flex-1 flex-col gap-y-3";

  useInfiniteScroll({
    scroller: window,
    hasMore: current < count,
    pageStart: current,
    loadMore: (page: string) => getInvoicesCompanies({ page, ...params }),
    isFetching: isFetching || isFetchingLazy,
  });

  const renderEmptyState = () => {
    const isSearchActive = Boolean(search?.length);

    if (isSearchActive) return <NoSearchResults />;

    return <EmptyState title="Companies" description="There are currently no companies available" />;
  };

  if (isFetching) return <CompanyDisclosureSkeleton className={containerClassName} />;

  return (
    <div className={containerClassName}>
      {isEmpty(companies)
        ? renderEmptyState()
        : companies.map((company, i) => (
            <CompanyDisclosure key={company.id} company={company} variant="invoices" currency={currency[0]?.value} order={i + 1}>
              <InvoicesTable company={company} companyIndex={i} items={company.invoices} className="px-5 pb-6 pt-3" />
            </CompanyDisclosure>
          ))}
      {isFetchingLazy && <CompanyDisclosureSkeleton variant="item" />}
    </div>
  );
};
