export const getCountryName = (countryCode: string) => {
  try {
    const countryFormatter = new Intl.DisplayNames(["en-US"], {
      type: "region",
    });
    const country = countryFormatter.of(countryCode);

    return country;
  } catch (error) {
    return undefined;
  }
};
