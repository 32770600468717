import { TermsAndConditions } from "@/redux/slices/documents/types";
import { GetTermsAndConditionsRawResponse, GetTermsAndConditionsResponse } from "./types";

export const transformTermsAndConditions = (res: GetTermsAndConditionsRawResponse) => {
  const {
    _embedded: { terms_and_conditions: termsAndConditions },
  } = res;

  const response: GetTermsAndConditionsResponse = {
    data: {
      termsAndConditions: termsAndConditions.map(
        (i) =>
          ({
            id: i.uuid,
            title: i.title,
            url: i.url,
            createdAt: new Date(i.created_at).toISOString(),
          } as TermsAndConditions)
      ),
    },
  };

  return response;
};