import { Icon, Skeleton, Typography } from "@/components/atoms";
import { DocumentList } from "@/components/molecules";
import { useOperator, usePage } from "@/hooks";
import { useGetOtherLinksQuery } from "@/redux/apis/config/linksAndDocuments";
import { useAppSelector } from "@/redux/hooks";
import { documentsSelector } from "@/redux/slices/documents/selectors";
import { TermsAndConditions } from "./TermsAndConditions/TermsAndConditions";

export const Documents = () => {
  const { name } = useOperator();
  const { pathname } = usePage();
  const { isFetching } = useGetOtherLinksQuery();
  const { otherLinks } = useAppSelector(documentsSelector);

  if (isFetching) return <Skeleton />;

  return (
    <div>
      <Typography variant="h3">Documents</Typography>
      <DocumentList className="mt-6 my-4">
        <DocumentList.Item
          link={`/dashboard${pathname}/terms-and-conditions`}
          title={`${name} Terms and Conditions`}
          subtext="Terms & Conditions"
          icon={<Icon name="Book" size="sm" />}
        />
        {otherLinks.map((i) => (
          <DocumentList.Item
            key={i.id}
            link={i.document.link}
            title={i.document.name}
            subtext={i.document.description}
            icon={i.icon !== "document" ? <Icon name={i.icon} size="sm" isCustom /> : <Icon name="Book" size="sm" />}
          />
        ))}
      </DocumentList>
    </div>
  );
};

Documents.TermsAndConditions = TermsAndConditions;
