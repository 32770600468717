import { PayoutHistoryPanel } from "@/components/organisms";
import { Payout } from "./Payout";

export const PayoutHistory = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PayoutHistoryPanel />
    </div>
  );
};

PayoutHistory.Payout = Payout;
