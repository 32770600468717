import { createSlice } from "@reduxjs/toolkit";
import { linksAndDocumentsApi } from "@/redux/apis/config/linksAndDocuments";
import { DocumentsState } from "./types";
import { termsAndConditionsApi } from "@/redux/apis";

const initialState: DocumentsState = {
  otherLinks: [],
  termsAndConditions: [],
};

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(linksAndDocumentsApi.endpoints.getOtherLinks.matchFulfilled, (state, { payload }) => {
      state.otherLinks = payload.data.otherLinks;
    }),
    builder.addMatcher(termsAndConditionsApi.endpoints.getTermsAndConditions.matchFulfilled, (state, { payload }) => {
      state.termsAndConditions = payload.data.termsAndConditions;
    });
  },
});

export default documentsSlice.reducer;
