import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { createSearchParams } from "@/utils";
import {
  transformFleetDrivers,
  transformFleetLeaders,
  transformFleetOptions,
  transformOffloadDrivers,
  transformOffloadOptions,
} from "./helpers";
import {
  GetFleetDriversParams,
  GetFleetDriversResponse,
  GetFleetLeadersResponse,
  GetFleetOptionsResponse,
  GetOffloadDriversParams,
  GetOffloadDriversResponse,
  GetOffloadOptionsResponse,
  ToggleFleetLeaderParams,
  ToggleFleetLeaderResponse,
} from "./types";

export const driverApi = createApi({
  reducerPath: "driverApi",
  baseQuery: baseQueryWithProvider("driver"),
  endpoints: (builder) => ({
    getFleetDrivers: builder.query<GetFleetDriversResponse, GetFleetDriversParams>({
      query: (params) => `/fleet?${createSearchParams(params).toString()}`,
      providesTags: ["FleetDrivers"],
      transformResponse: transformFleetDrivers,
    }),
    getFleetLeaders: builder.query<GetFleetLeadersResponse, GetFleetDriversParams>({
      query: (params) => `/fleet?${createSearchParams(params).toString()}`,
      transformResponse: transformFleetLeaders,
    }),
    getFleetOptions: builder.query<GetFleetOptionsResponse, void>({
      query: () => "/fleet/options",
      transformResponse: transformFleetOptions,
    }),
    getOffloadDrivers: builder.query<GetOffloadDriversResponse, GetOffloadDriversParams>({
      query: (params) => `/offload?${createSearchParams(params).toString()}`,
      providesTags: ["OffloadDrivers"],
      transformResponse: transformOffloadDrivers,
    }),
    getOffloadOptions: builder.query<GetOffloadOptionsResponse, void>({
      query: () => "/offload/options",
      transformResponse: transformOffloadOptions,
    }),
    toggleFleetLeader: builder.mutation<ToggleFleetLeaderResponse, ToggleFleetLeaderParams>({
      query: ({ id, isFleetLeader }) => ({
        url: `/fleet/${id}`,
        method: "PATCH",
        body: { is_fleet_leader: isFleetLeader },
      }),
    }),
  }),
  tagTypes: ["FleetDrivers", "OffloadDrivers"],
});

export const {
  useGetFleetDriversQuery,
  useGetFleetLeadersQuery,
  useGetFleetOptionsQuery,
  useGetOffloadDriversQuery,
  useGetOffloadOptionsQuery,
  useToggleFleetLeaderMutation,
} = driverApi;
