import { DynamicPricingStateContextProvider, DynamicPricingStatePanel } from "@/components/organisms";
import { StateBased } from "./StateBased";

export const PricingState = () => {
  return (
    <DynamicPricingStateContextProvider>
      <DynamicPricingStatePanel />
    </DynamicPricingStateContextProvider>
  );
};

PricingState.StateBased = StateBased;
