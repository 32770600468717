import { useNavigate } from "react-router-dom";
import { Panel, Typography, Skeleton, Icon, IconButton } from "@/components/atoms";
import { useGetBillingSettingsQuery } from "@/redux/apis/config/admin/billingSettings";
import { useAppSelector } from "@/redux/hooks";
import { billingSettingsSelector } from "@/redux/slices/admin/selectors";
import { clsx } from "@/utils";

export const UniversalPricingViewPanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const navigate = useNavigate();
  const { isFetching } = useGetBillingSettingsQuery();
  const {
    details: { defaultUniversalPricingAdjustmentRate },
  } = useAppSelector(billingSettingsSelector);
  const isPriceIncrease = Number(defaultUniversalPricingAdjustmentRate) > -1;
  const displayValue = isPriceIncrease ? Number(defaultUniversalPricingAdjustmentRate) : Number(defaultUniversalPricingAdjustmentRate) * -1;

  return (
    <div>
      {isFetching ? (
        <Skeleton />
      ) : (
        <>
          <Panel className={clsx("flex flex-1 flex-col", className)} {...props}>
            <div className="flex flex-col">
              <Typography variant="h3" className="leading-8">
                Universal Pricing
              </Typography>
              <Typography className="text-neutral-dark-gray">
                Automatically adjust (add/remove) all dynamic universal pricing by a set percentage. This
                <br />
                adjustment will not affect <span className="underline">custom pricing</span> or{" "}
                <span className="underline">fixed pricing profiles</span>.
              </Typography>
            </div>
            <div className="flex">
              <div className="mt-4 flex flex-row items-center gap-x-9 rounded-lg bg-neutral-surface-gray p-5">
                <div className="flex flex-row items-center gap-x-4">
                  {isPriceIncrease ? (
                    <Icon name="ArrowCircleUp2" variant="Bold" className="text-success" />
                  ) : (
                    <Icon name="ArrowCircleDown2" variant="Bold" className="text-danger" />
                  )}
                  <div className="flex flex-col">
                    <Typography variant="title">{displayValue}%</Typography>
                    <Typography variant="paragraph">Universal Pricing Increase</Typography>
                  </div>
                </div>
                <div className="flex">
                  <IconButton iconName="Edit2" variant="secondary" onClick={() => navigate("./edit")} />
                </div>
              </div>
            </div>
          </Panel>
        </>
      )}
    </div>
  );
};
