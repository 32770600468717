import {
  GetInvoiceSettingsHistoryRawResponse,
  GetInvoiceSettingsHistoryResponse,
  GetInvoiceSettingsRawResponse,
  GetInvoiceSettingsResponse,
  GetInvoicesRawResponse,
  GetInvoicesResponse,
  GetInvoiceRawResponse,
  GetInvoiceResponse,
  GetInvoicingOptionsRawResponse,
  GetInvoicingOptionsResponse,
  GetPaymentHistoryRawResponse,
  GetPaymentHistoryResponse,
  GetInvoiceHistoryRawResponse,
  GetInvoiceHistoryResponse,
  GetClientJobsRawResponse,
  GetClientJobsResponse,
  GetJobsRawResponse,
  GetJobsResponse,
  GetInvoiceNotesRawResponse,
  GetInvoiceNotesResponse,
  GetInvoicePaymentRawResponse,
  GetInvoicePaymentResponse,
  GetInvoiceManualPaymentDetailsRawResponse,
  GetInvoiceManualPaymentDetailsResponse,
  GetInvoiceManualPaymentReceiptResponse,
  GetInvoiceManualPaymentReceiptRawResponse,
} from "./types";

export const transformInvoices = (res: GetInvoicesRawResponse) => {
  const {
    _embedded: { invoice: invoices },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetInvoicesResponse = {
    data: {
      invoices: invoices.map((i) => ({
        id: i.uuid,
        identifier: i.identifier,
        company: {
          id: i.company.uuid,
          name: i.company.name,
        },
        jobs: i.jobs,
        emailLog: i.email_log,
        status: i.status,
        currency: i.currency,
        issueDate: i.issue_date,
        dueDate: i.due_date,
        amount: i.amount,
        amountPaid: i.amount_paid,
        amountDue: i.amount_due,
        clientHeldDays: i.client_held_days,
        documents: {
          pdf: i.documents.pdf,
          csv: i.documents.csv,
        },
        metadata: {
          isWriteOffAvailable: i.metadata.is_write_off_available,
          isCancelAvailable: i.metadata.is_cancel_available,
          isMarkPaidAvailable: i.metadata.is_mark_paid_available,
          isPayByCreditCardAvailable: i.metadata.is_pay_by_credit_card_available,
        },
      })),
      invoicesPage: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformInvoice = (res: GetInvoiceRawResponse) => {
  const response: GetInvoiceResponse = {
    data: {
      id: res.uuid,
      identifier: res.identifier,
      company: {
        id: res.company.uuid,
        name: res.company.name,
      },
      jobs: res.jobs,
      emailLog: res.email_log,
      status: res.status,
      currency: res.currency,
      issueDate: res.issue_date,
      dueDate: res.due_date,
      amount: res.amount,
      amountPaid: res.amount_paid,
      amountDue: res.amount_due,
      clientHeldDays: res.client_held_days,
      documents: {
        pdf: res.documents.pdf,
        csv: res.documents.csv,
      },
      metadata: {
        isWriteOffAvailable: res.metadata.is_write_off_available,
        isCancelAvailable: res.metadata.is_cancel_available,
        isMarkPaidAvailable: res.metadata.is_mark_paid_available,
        isPayByCreditCardAvailable: res.metadata.is_pay_by_credit_card_available,
      },
    },
  };

  return response;
};

export const transformInvoicingOptions = (res: GetInvoicingOptionsRawResponse) => {
  const response: GetInvoicingOptionsResponse = {
    data: {
      options: {
        currency: Object.entries(res.currency).map((i) => ({ name: i[1], value: i[0] })),
        generateOnFrequency: Object.entries(res.generate_on_frequency).map((i) => ({ name: i[1], value: i[0] })),
      },
    },
  };

  return response;
};

export const transformPaymentHistory = (res: GetPaymentHistoryRawResponse) => {
  const {
    _embedded: { invoice_payment: invoicePayments },
  } = res;

  const response: GetPaymentHistoryResponse = {
    data: {
      paymentHistory: invoicePayments.map((i) => ({
        id: i.uuid,
        status: i.status,
        comment: i.comment,
        type: i.type,
        createdAt: i.created_at,
        receivedAt: i.received_at,
        receivedByName: i.received_by.name,
        amountPaid: i.amount_paid,
        metadata: i.metadata,
      })),
    },
  };

  return response;
};

export const transformInvoicePayment = (res: GetInvoicePaymentRawResponse) => {
  const response: GetInvoicePaymentResponse = {
    data: {
      id: res.uuid,
      status: res.status,
      comment: res.comment,
      type: res.type,
      createdAt: res.created_at,
      receivedAt: res.received_at,
      receivedByName: res.received_by.name,
      amountPaid: res.amount_paid,
      metadata: res.metadata,
    },
  };

  return response;
};

export const transformInvoiceHistory = (res: GetInvoiceHistoryRawResponse) => {
  const {
    _embedded: { history: invoiceHistory },
  } = res;

  const response: GetInvoiceHistoryResponse = {
    data: {
      invoiceHistory: invoiceHistory.map((i) => ({
        createdAt: i.created_at,
        field: i.field,
        to: i.to,
        accountName: i.account.name,
      })),
    },
  };

  return response;
};

export const transformClientJobs = (res: GetClientJobsRawResponse) => {
  const {
    _embedded: { pending: clientJobs },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetClientJobsResponse = {
    data: {
      clientJobs: clientJobs.map((i) => ({
        id: i.identifier,
        name: i.name,
        email: i.email,
        count: i.count,
        cost: i.cost,
        jobs: i.jobs.map((job) => ({
          id: job.job_id,
          companyId: job.company_uuid,
          pickupDate: job.pickup_date,
          clientAccountId: job.client_account_uuid,
          clientName: job.client_name,
          clientCostCentre: job.client_cost_centre,
          passengerName: job.job_passenger_name,
          referenceNumber: job.job_reference_number,
          cost: job.job_cost,
        })),
      })),
      clientJobsPage: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformJobs = (res: GetJobsRawResponse) => {
  const {
    _embedded: { pending: jobs },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetJobsResponse = {
    data: {
      jobs: jobs.map((i) => ({
        id: i.job_id,
        companyId: i.company_uuid,
        pickupDate: i.pickup_date,
        clientAccountId: i.client_account_uuid,
        clientName: i.client_name,
        clientCostCentre: i.client_cost_centre,
        passengerName: i.job_passenger_name,
        referenceNumber: i.job_reference_number,
        cost: i.job_cost,
      })),
      jobsPage: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformInvoiceSettingsHistory = (res: GetInvoiceSettingsHistoryRawResponse) => {
  const {
    _embedded: { history: invoiceSettingsHistory },
    page,
    page_count,
    page_size,
    total_items,
  } = res;

  const response: GetInvoiceSettingsHistoryResponse = {
    data: {
      history: invoiceSettingsHistory.map((i) => ({
        createdAt: i.created_at,
        field: i.field,
        to: i.to,
        accountName: i.account.name,
      })),
      historyPage: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformInvoiceSettings = (res: GetInvoiceSettingsRawResponse) => {
  const response: GetInvoiceSettingsResponse = {
    data: {
      details: {
        accountName: res.account_name,
        accountNumber: res.account_number,
        bsb: res.account_routing_number,
        beneficiaryBankName: res.beneficiary_bank_name,
        beneficiaryBankAddress: res.beneficiary_bank_address,
        swiftCode: res.swift_code,
        enablePaymentOfInvoicesViaCreditCard: Boolean(res.pay_by_cc_enabled),
        creditCardFeePassThrough: Boolean(res.pay_by_cc_pass_on_fees_enabled),
      },
    },
  };

  return response;
};

export const transformInvoiceNotes = (res: GetInvoiceNotesRawResponse) => {
  const {
    _embedded: { invoice_notes: invoiceNotes },
  } = res;

  const response: GetInvoiceNotesResponse = {
    data: {
      invoiceNotes: invoiceNotes.map((i) => ({
        id: i.uuid,
        createdAt: i.created_at,
        account: {
          id: i.account.uuid,
          firstName: i.account.first_name,
          lastName: i.account.last_name,
          email: i.account.email,
          avatar: i.account.avatar,
        },
        content: i.content,
        isDeleted: i.is_deleted,
      })),
    },
  };

  return response;
};

export const transformInvoiceManualPaymentDetails = (res: GetInvoiceManualPaymentDetailsRawResponse) => {
  const response: GetInvoiceManualPaymentDetailsResponse = {
    data: {
      id: res.uuid,
      status: res.status,
      amounts: {
        subTotal: res.amounts.sub_total,
        fees: res.amounts.fees,
        total: res.amounts.total,
      },
    },
  };

  return response;
};

export const transformInvoiceManualPaymentReceipt = (res: GetInvoiceManualPaymentReceiptRawResponse) => {
  const response: GetInvoiceManualPaymentReceiptResponse = {
    data: {
      receipt: res.metadata.receipt,
    },
  };

  return response;
};
