import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setInlineEditing } from "@/redux/slices/booking/bookingSlice";
import { bookingsPageStateSelector } from "@/redux/slices/booking/selectors";
import { AdminNotesEdit } from "./inlineActions/AdminNotesEdit";
import { ChangeDriver } from "./inlineActions/ChangeDriver";
import { ChangeVehicle } from "./inlineActions/ChangeVehicle";
import { ClientNotesEdit } from "./inlineActions/ClientNotesEdit";
import { DashboardNotesEdit } from "./inlineActions/DashboardNotesEdit";
import { DriverPercentageEdit } from "./inlineActions/DriverPercentageEdit";
import { ExternalSupplierNotesEdit } from "./inlineActions/ExternalSupplierNotesEdit";
import { OperatorToDriverNotesEdit } from "./inlineActions/OperatorToDriverNotesEdit";

export const BookingInlineActions = () => {
  const dispatch = useAppDispatch();
  const { inlineEditing } = useAppSelector(bookingsPageStateSelector);

  const onClose = () => dispatch(setInlineEditing(undefined));

  return (
    <>
      {inlineEditing === "driver-notes" && <OperatorToDriverNotesEdit open onClose={onClose} />}
      {inlineEditing === "client-notes" && <ClientNotesEdit open onClose={onClose} />}
      {inlineEditing === "admin-notes" && <AdminNotesEdit open onClose={onClose} />}
      {inlineEditing === "external-supplier-notes" && <ExternalSupplierNotesEdit open onClose={onClose} />}
      {inlineEditing === "dashboard-notes" && <DashboardNotesEdit open onClose={onClose} />}
      {inlineEditing === "change-driver" && <ChangeDriver open onClose={onClose} />}
      {inlineEditing === "change-vehicle" && <ChangeVehicle open onClose={onClose} />}
      {inlineEditing === "driver-percentage" && <DriverPercentageEdit open onClose={onClose} />}
    </>
  );
};
