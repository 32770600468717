import { useInView } from "react-intersection-observer";
import { Panel, PhoneNumberDetail, Typography, Skeleton, LinkButton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetBusinessDetailsQuery } from "@/redux/apis/config/admin/businessDetails";
import { useAppSelector } from "@/redux/hooks";
import { businessDetailsSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { BusinessDetailsEdit } from "./BusinessDetailsEdit";

export const BusinessDetails = () => {
  const { isFetching } = useGetBusinessDetailsQuery();
  const { errors, details } = useAppSelector(businessDetailsSelector);
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <LinkButton className="animate-enter" startIcon="Edit2" to="edit">
            Edit
          </LinkButton>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Business Details
            </Typography>
            <Typography className="text-neutral-dark-gray">Update your Business details here.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <LinkButton startIcon="Edit2" to="edit">
              Edit
            </LinkButton>
          </div>
        </div>
        {renderErrors(errors)}
        <ConfigDetail title="Business Name" description="This is the name customers will see.">
          <Typography variant="paragraph" className="leading-8">
            {details.businessName}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Business Tagline" description="A quick snapshot about your business.">
          <Typography variant="paragraph" className="leading-8">
            {details.businessTagline}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Business Address" description="Where is you business located.">
          <Typography variant="paragraph" className="leading-8">
            {details.businessAddress}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Business Registration" description="State your Business Registration Type and Registration ID.">
          <Typography variant="paragraph" className="leading-8">
            Type: {details.businessRegistrationType}
          </Typography>
          <Typography variant="paragraph" className="leading-8">
            ID: {details.businessRegistrationId}
          </Typography>
        </ConfigDetail>
        <ConfigDetail title="Business Phone Number" description="Local and International contact number of you Business.">
          <PhoneNumberDetail number={details.businessPhoneNumberLocal} label="Local:" />
          <PhoneNumberDetail number={details.businessPhoneNumberInternational} label="International:" />
        </ConfigDetail>
        <ConfigDetail
          title="Support Email Address"
          description="General support email address to use in email footers, invoices, private network notifications and more."
        >
          <Typography variant="paragraph" className="leading-8">
            {details.businessSupportEmail}
          </Typography>
        </ConfigDetail>
      </Panel>
    </>
  );
};

BusinessDetails.Edit = BusinessDetailsEdit;
