import { useForm } from "react-hook-form";
import NetworkInvite from "@/assets/images/network/network-invite.svg";
import { Button, Icon, Loading, Modal, TextInput, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useInviteNetworkMutation } from "@/redux/apis/network/networkApi";
import { addToast } from "@/utils";
import { PrivateNetworkStep } from "../types";

type FormData = {
  companyCode: string;
};

interface InviteNetworkCodeModalProps {
  open: boolean;
  onClose: () => void;  
  setStep: (step: PrivateNetworkStep) => void;
}

export const InviteNetworkCodeModal = ({ open, onClose, setStep }: InviteNetworkCodeModalProps) => {
  const [inviteNetwork, { isLoading }] = useInviteNetworkMutation();
  const { register, handleSubmit } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    if (!data) return;

    inviteNetwork(data.companyCode)
      .unwrap()
      .then(() => {
        addToast("success", "Successfully Sent an Invitation");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal open={open} onClose={onClose} className="relative w-[85vw] md:w-[550px] lg:w-[550px]">
      {isLoading && <Loading />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center">
          <button type="button" className="ml-auto rounded-md text-black" onClick={onClose}>
            <span className="sr-only">Close</span>
            <Icon name="close" isCustom size="lg" aria-hidden="true" />
          </button>
          <img src={NetworkInvite} className="mt-3.5 h-[128px] w-[128px]" />
          <div className="flex flex-col items-center justify-center gap-4 text-center">
            <Typography variant="h1">Invite to Join Private Network</Typography>
            <Typography variant="paragraph">
              Once you invite your trusted partner(s) to join your private network, the invitation will only become active once they accept.
            </Typography>
          </div>
          <div className="mt-8 flex w-full flex-col gap-3">
            <TextInput placeholder="Company Code" className="w-full" maxLength={32} required {...register("companyCode")} />
            <Typography variant="paragraph">
              <span className="text-neutral-dark-gray">
                Business or Company you&apos;re trying to invite is not a member of RideMinder yet?{" "}
              </span>
              <span className="cursor-pointer text-primary underline" onClick={() => setStep("inviteEmail")}>
                Click here to send them an email invitation
              </span>
            </Typography>
          </div>
        </div>
        <div className="mb-2 mt-8 flex justify-end">
          <Button type="submit" variant="primary" size="lg" className="w-full">
            Invite to Network
          </Button>
        </div>
      </form>
    </Modal>
  );
};
