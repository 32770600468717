import { createSlice } from "@reduxjs/toolkit";
import { adminReducers } from "./reducers";
import {
  AccessControlInitialState,
  AdminConfigState,
  BillingSettingsInitialState,
  BusinessDetailsInitialState,
  DispatchSettingsInitialState,
  DisplayOptionsInitialState,
  EmailNotificationsInitialState,
  SMSVoiceNotificationsInitialState,
  WaitingTimesInitialState,
  WebsiteDetailsInitialState,
} from "./types";

const initialState: AdminConfigState = {
  businessDetails: BusinessDetailsInitialState,
  websiteDetails: WebsiteDetailsInitialState,
  emailNotifications: EmailNotificationsInitialState,
  smsVoiceNotifications: SMSVoiceNotificationsInitialState,
  billingSettings: BillingSettingsInitialState,
  accessControl: AccessControlInitialState,
  dispatchSettings: DispatchSettingsInitialState,
  waitingTimes: WaitingTimesInitialState,
  displayOptions: DisplayOptionsInitialState,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    adminReducers(builder);
  },
});

export default adminSlice.reducer;
