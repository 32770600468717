import { useParams } from "react-router-dom";
import { usePagination, useSorting } from "@/hooks";
import { GetPayoutRecordsParams } from "@/redux/apis/payment/record/types";

export const useGetPayoutRecordsParams = () => {
  const { page, pageSize } = usePagination();
  const { sortKey, direction } = useSorting();
  const { id } = useParams();

  const params: GetPayoutRecordsParams = {
    id: id ?? "",
    page,
    page_size: pageSize,
    sort: sortKey,
    direction,
  };

  return { params, page, pageSize, sort: sortKey, direction };
};
