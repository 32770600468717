import { useAppSelector } from "@/redux/hooks";
import { bookingsPageStateSelector } from "@/redux/slices/booking/selectors";
import { BookingDetails } from "@/redux/slices/booking/types";

export const useSelectedBooking = (isUndefinable?: boolean) => {
  const { selectedBooking } = useAppSelector(bookingsPageStateSelector);

  if (!isUndefinable && !selectedBooking) throw Error("useSelectedBooking can't be used without selected booking");

  return selectedBooking as BookingDetails;
};
