import { Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { AddOnItem } from "@/redux/slices/addOn/types";
import { clsx } from "@/utils";
import { isAfter, isEqual } from "date-fns";
import { isEmpty } from "ramda";
import { addOnsDescription } from "./fixtures";
import { renderAddOnBadge, renderAddOnIcon, renderAddOnStatus } from "./helpers";
import { ReactComponent as FeaturedIcon1 } from "@/assets/images/add-ons/featured-icon-1.svg";
import { ReactComponent as FeaturedIcon2 } from "@/assets/images/add-ons/featured-icon-2.svg";
import { ReactComponent as FeaturedIcon3 } from "@/assets/images/add-ons/featured-icon-3.svg";
import { ReactComponent as FeaturedIcon4 } from "@/assets/images/add-ons/featured-icon-4.svg";
import { useAppSelector } from "@/redux/hooks";
import { featuredAddOnsSelector } from "@/redux/slices/addOn/selectors";
import { useGetFeaturedAddOnsQuery } from "@/redux/apis/addOn/addOnApi";


interface RecommendedPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  setSelectedAddOn: (id: string) => void;
}

export const RecommendedPanel = ({ setSelectedAddOn, className, ...props }: RecommendedPanelProps) => {
  const { isFetching } = useGetFeaturedAddOnsQuery();
  const featuredAddOns = useAppSelector(featuredAddOnsSelector);

  if (isFetching || isEmpty(featuredAddOns)) return null;

  return (
    <div className={clsx("relative flex items-center justify-center mt-2", className)} {...props}>
      <div className="absolute rounded-lg h-full w-full blur-sm" style={{ backgroundImage: "linear-gradient(45deg, #BC82F3,#F5B9EA,#73547E,purple,#AA6EEE,#9155DD,#FFBA71,#C686FF)" }} />
      <div className="flex items-center justify-center rounded-lg w-full h-full m-1 bg-primary-light z-10">
        <FeaturedIcon1 className="absolute top-7 right-24 lg:top-14 lg:right-52" />
        <FeaturedIcon2 className="absolute top-10 left-16 lg:top-20 lg:left-36" />
        <FeaturedIcon3 className="absolute bottom-8 left-8 lg:bottom-16 lg:left-16" />
        <FeaturedIcon4 className="absolute bottom-3.5 right-16 lg:bottom-7 lg:right-32" />
        <div className="flex flex-col items-center justify-center w-full h-full rounded-lg p-10">
          <Typography variant="h2" className="text-primary text-center">Most Recommended Add Ons</Typography>
          <Typography variant="paragraph" className="text-center mt-2.5">Enhance Your Experience with Our Top-Rated Add-Ons!</Typography>
          <div className="flex flex-col lg:flex-row justify-center gap-5 mt-[30px]">
            {featuredAddOns.map((addOn, i) => {
              if (i < 2) return <RecommendedAddOnItem key={addOn.identifier} addOn={addOn} setSelectedAddOn={setSelectedAddOn} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const RecommendedAddOnItem = ({ addOn, setSelectedAddOn }: { addOn: AddOnItem, setSelectedAddOn: (id: string) => void }) => {
  const { dateFormat } = useProfile();
  const expiryDate = new Date(addOn.freeTrialExpiry);
  const currentDate = new Date();
  const isFreeTrialValid = Boolean(addOn.freeTrialExpiry && (isEqual(expiryDate, currentDate) || isAfter(expiryDate, currentDate)));

  return (
    <div className="flex flex-col group cursor-pointer rounded-lg bg-white shadow-md p-5 max-w-[361px] hover:bg-neutral-surface-gray z-10" onClick={() => setSelectedAddOn(addOn.identifier)}>
      <div className="flex flex-row items-start">
        <div className="flex items-center justify-center bg-primary-dark rounded-lg p-3 group-hover:bg-primary">
          {renderAddOnIcon(addOn.identifier)}
        </div>
        <div className="flex flex-1 justify-end">
          {renderAddOnStatus(addOn.enabled, isFreeTrialValid, expiryDate, dateFormat)}
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-2 mt-4">
        <Typography variant="action" className="group-hover:text-primary">{addOn.name}</Typography>
        {renderAddOnBadge(addOn, isFreeTrialValid)}
      </div>
      <Typography variant="paragraph" className="text-neutral-dark-gray mt-2 group-hover:text-primary">{addOnsDescription[addOn.identifier]}</Typography>
    </div>
  );
};
