import { Link } from "react-router-dom";
import { Icon, IconButton, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const VehicleCategoryDetailCard = () => {
  const operator = useOperator();
  const {
    vehicle: { categories },
    setActiveModal,
  } = useViewVehicleContext();

  const manageCategoryLink = `/operator/${operator.id}/config/pricing/vehicle-categories`;

  const handleClick = () => {
    setActiveModal("vehicle-category");
  };

  const ActionButton = () => {
    return (
      <IconButton
        iconName="ArrowRight2"
        className="!bg-transparent p-1.5 text-neutral-black"
        iconSize="lg"
        variant="tertiary"
        onClick={handleClick}
      />
    );
  };

  const Tooltip = (
    <div className="max-w-[345px] font-medium">
      <Typography>
        This defines the vehicle categories considered within the business for transport services. <br />
        <br />
        <Link to={manageCategoryLink} className="underline">
          Manage Vehicle Categories
        </Link>
      </Typography>
    </div>
  );

  return (
    <VehicleDetailCard
      icon={<Icon name="Car" size="xl" className="text-neutral-black" />}
      title="Vehicle Category"
      description={categories.length > 0 ? `${categories.length} Applied` : "N/A"}
      action={<ActionButton />}
      actionClick={handleClick}
      tooltip={Tooltip}
    />
  );
};
