import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { UpdateBrandingParams } from ".";

export const brandingApi = createApi({
  reducerPath: "brandingApi",
  baseQuery: baseQueryWithProvider("profile"),
  endpoints: (builder) => ({
    updateBranding: builder.mutation<void, UpdateBrandingParams>({
      query: (body) => ({
        url: "/branding",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const { useUpdateBrandingMutation } = brandingApi;
