import { endOfDay, startOfDay } from "date-fns";
import { DateParam, useQueryParams, withDefault } from "use-query-params";

const DEFAULT_DATES = [startOfDay(new Date()), endOfDay(new Date())];

export const usePaymentsDates = () => {
  const [selectedDates, setSelectedDates] = useQueryParams({
    fromDate: withDefault(DateParam, DEFAULT_DATES[0]),
    toDate: withDefault(DateParam, DEFAULT_DATES[1]),
  });

  return [selectedDates, setSelectedDates] as const;
};
