import { Outlet } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { AppAction, AppSubject } from "@/casl/types";
import { Restricted } from "@/components/pages";

export interface RequireAccessProps {
  action: AppAction;
  subject: AppSubject;
}

export const RequireAccess = ({ action, subject }: RequireAccessProps) => {
  const ability = useAppAbility();
  return ability.can(action, subject) ? <Outlet /> : <Restricted className="-mb-12" />;
};
