import { saveAs } from "file-saver";
import { Icon, Spinner, Typography } from "@/components/atoms";
import { useLazyGetRegistrationCertificatePathQuery } from "@/redux/apis/accreditation/accreditationApi";
import { addToast, clsx } from "@/utils";

interface RegistrationCertificateProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  accreditationId?: string;
}

export const RegistrationCertificate = (registrationCertificateProps: RegistrationCertificateProps) => {
  const { name, accreditationId, className, ...props } = registrationCertificateProps;
  const [getRegistrationCertificatePath, { isFetching }] = useLazyGetRegistrationCertificatePathQuery();
  const extension = name.split(".").pop();

  const handleRegCertClick = () => {
    if (accreditationId) {
      getRegistrationCertificatePath(accreditationId)
        .unwrap()
        .then((res) => {
          if (res.data.documentPath) saveAs(res.data.documentPath, name);
        })
        .catch((e) => addToast("danger", e.message));
    }
  };

  return (
    <div className={clsx("group flex cursor-pointer items-center", className)} onClick={handleRegCertClick} {...props}>
      {isFetching ? (
        <Spinner className={clsx("flex-2 mr-2", { "group-hover:border-info": accreditationId })} />
      ) : (
        <Icon className={clsx("flex-2 mr-2", { "group-hover:fill-info": accreditationId })} name={`file-${extension}`} isCustom />
      )}
      <Typography className={clsx("flex-1 break-all", { "group-hover:text-info": accreditationId })} variant="paragraph">
        {name}
      </Typography>
    </div>
  );
};
