import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import z from "zod";
import { AddOnTextInput, Button, ErrorMessage, Loading, Panel, Typography, Modal, Skeleton } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { getDirtyValues } from "@/helpers/formHelpers";
import { usePrompt } from "@/hooks/usePrompt";
import { useGetUnscheduledStopsQuery, useUpdateUnscheduledStopsMutation } from "@/redux/apis/config/pricing/unscheduledStops";
import { useAppSelector } from "@/redux/hooks";
import { unscheduledStopsSelector } from "@/redux/slices/pricing/selectors";
import { addToast, getCurrencySymbol } from "@/utils";

const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  error: {
    title: "Unable to Save Unscheduled Stops",
    description: "There is an error in one of the fields submitted. Please double check your inputs and try again.",
  },
};

const formSchema = z.object({
  unscheduledStopsFee: z.string().nonempty("Field is required and can't be empty."),
});

type FormData = z.infer<typeof formSchema>;

export const UnscheduledStopsEdit = () => {
  const { isFetching } = useGetUnscheduledStopsQuery();
  const { details } = useAppSelector(unscheduledStopsSelector);
  const [updateUnscheduledStops, { isLoading }] = useUpdateUnscheduledStopsMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields, isSubmitted, errors },
  } = useForm<FormData>({
    defaultValues: { unscheduledStopsFee: details.unscheduledStopsFee.toString() },
    resolver: zodResolver(formSchema),
  });
  const [activeModal, setActiveModal] = useState<"exit" | "error" | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    reset({ unscheduledStopsFee: details.unscheduledStopsFee.toString() });
  }, [details, reset]);

  const promptWhen = isDirty && activeModal !== "exit" && !isSubmitted;
  usePrompt({ when: promptWhen, message: "Changes will not be saved. Do you want to proceed?" });

  const onSubmit = handleSubmit((data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data) as FormData;

    setActiveModal(null);
    updateUnscheduledStops({
      unscheduled_stop: Number(dirtyValues.unscheduledStopsFee) * 100,
    })
      .unwrap()
      .then(() => {
        addToast("success", `Successfully Updated Unscheduled Stops`);
        navigate("../");
      })
      .catch(() => setActiveModal("error"));
  });

  const renderModal = () => {
    if (!activeModal) return;

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={modals[activeModal].description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "exit":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)}>
              Cancel
            </Button>
            <Button onClick={() => navigate("../")}>Discard Changes</Button>
          </>
        );
      default:
        return (
          <Button onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <form onSubmit={onSubmit}>
      <PageHeader.Actions />
      <Panel>
        <div className="relative">
          {renderModal()}
          {isLoading && <Loading />}
          <div className="flex items-center gap-4">
            <div className="flex-1">
              <Typography variant="h3" className="leading-8">
                Unscheduled Stops
              </Typography>
              <Typography className="text-neutral-dark-gray">Set the pricing for every unscheduled stops</Typography>
            </div>
            <div className="flex gap-3">
              <Button variant="secondary" onClick={() => (isDirty ? setActiveModal("exit") : navigate("../"))}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          </div>
          <ConfigDetail title="Unscheduled Stop Fee" description="Fee for every unscheduled stops each trip.">
            <div className="flex flex-col">
              <AddOnTextInput
                startAddOn={`${details.currency} ${getCurrencySymbol(details.currency)}`}
                type="number"
                className="w-[211px]"
                min="0"
                max="999999999"
                {...register("unscheduledStopsFee")}
              />
              <ErrorMessage errors={errors} name="unscheduledStopsFee" variant="small" />
            </div>
          </ConfigDetail>
        </div>
      </Panel>
    </form>
  );
};
