import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, Divider, ErrorMessage, Icon, IconButton, TextInput, Typography, SidePanel } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useForwardBookingConfirmationMutation } from "@/redux/apis/booking/endpoints/actions";
import { addNotification, addToast } from "@/utils";
import { useSelectedBooking } from "../../hooks/useSelectedBooking";

interface ForwardBookingConfirmationProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  email: z.string().email("Please input a valid email address").nonempty(),
});

type FormData = z.infer<typeof schema>;

export const ForwardBookingConfirmation = ({ open, onClose }: ForwardBookingConfirmationProps) => {
  const { id: bookingId } = useSelectedBooking();
  const [forwardBookingConfirmation] = useForwardBookingConfirmationMutation();
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting, errors },
  } = form;

  const onSubmit = async (data: FormData) => {
    await forwardBookingConfirmation({
      bookingId,
      email: data.email,
    })
      .unwrap()
      .then(() => {
        addNotification("success", "Forward Booking Confirmation", "Successfully forwarded booking confirmation");
        reset();
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <SidePanel open={open} onClose={onClose} width={550} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="ArchiveBook" size="lg" />
          <Typography variant="title">Forward Booking Confirmation</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
        <div className="flex-1 p-5">
          <div className="bg-neutral-surface-gray p-5">
            <Typography variant="paragraph">Email Address to forward Booking Confirmation for Job {bookingId} to</Typography>
            <TextInput placeholder="Enter an email address" {...register("email")} className="my-1" autoFocus />
            <ErrorMessage errors={errors} name="email" />
          </div>
        </div>
        <div className="p-5">
          <Divider className="-mx-5" />
          <Button type="submit" disabled={!isDirty || isSubmitting} className="mt-5 w-full" variant="primary" size="lg">
            Forward a Copy
          </Button>
        </div>
      </form>
    </SidePanel>
  );
};
