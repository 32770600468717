import {
  GetSMSVoiceNotificationsRawResponse,
  GetSMSVoiceNotificationsResponse,
  UpdateSMSVoiceNotificationsRawResponse,
  UpdateSMSVoiceNotificationsResponse,
} from "./types";

export const transformSMSVoiceNotifications = (res: GetSMSVoiceNotificationsRawResponse) => {
  const { _links, ...smsVoiceNotifications } = res;
  const response: GetSMSVoiceNotificationsResponse = {
    data: {
      details: {
        enabledUnallocatedClosePickupNotifications: smsVoiceNotifications.enabled_unallocated_close_pickup_notifications,
        unallocatedClosePickupPhoneNumber: smsVoiceNotifications.unallocated_close_pickup_phone_number,
        enabledDriverNotOnWayNotifications: smsVoiceNotifications.enabled_driver_not_on_way_notifications,
        driverNotOnWayPhoneNumber: smsVoiceNotifications.driver_not_on_way_phone_number,
        driverNotOnWayEmailAddress: smsVoiceNotifications.driver_not_on_way_email_address,
        enabledSmsCustomerReminderAndOpchangeNotifications: smsVoiceNotifications.enabled_sms_customer_reminder_and_opchange_notifications,
      },
    },
    links: _links,
  };

  return response;
};

export const transformUpdateSMSVoiceNotifications = (res: UpdateSMSVoiceNotificationsRawResponse) => {
  return {
    links: res._links,
  } as UpdateSMSVoiceNotificationsResponse;
};
