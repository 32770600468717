import { useMemo } from "react";
import { DataTable, NoSearchResults, Pagination } from "@/components/molecules";
import { RatingItem } from "@/redux/slices/rating/types";
import { clsx } from "@/utils";
import { generateRatingsColumns, generateRatingsData } from "./helpers";
import { useAppSelector } from "@/redux/hooks";
import { usePagination, useSorting } from "@/hooks";
import { RatingsEmptyState } from "./RatingsEmptyState";
import { ratingsPageSelector } from "@/redux/slices/rating/selectors";
import { RatingsStep } from "./types";

interface RatingsTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: RatingItem[];
  isEmpty: boolean;
  setStep: (step: RatingsStep) => void;
  setSelectedRating: (item?: RatingItem) => void;
}

export const RatingsTable = ({ items, isEmpty, setStep, setSelectedRating, className, ...props }: RatingsTableProps) => {
  const { sorting, setSorting } = useSorting();

  const viewRating = (item?: RatingItem) => {
    setSelectedRating(item);
    setStep("view");
  };

  const data = useMemo(
    () => generateRatingsData(items, viewRating),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateRatingsColumns(), []);

  return (
    <div className={clsx("relative flex bg-white", className)}>
      <DataTable 
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns} 
        data={data} 
        sorting={sorting} 
        setSorting={setSorting} 
        emptyState={isEmpty ? <RatingsEmptyState /> : <NoSearchResults />} 
        {...props} 
      />
    </div>
  );
};

const RatingsTablePagination = () => {
  const { current, count, total, size } = useAppSelector(ratingsPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

RatingsTable.Pagination = RatingsTablePagination;