import { getApiHostUrl } from "@/helpers/apiHelpers";
import { QRTemplate } from "@/redux/slices/qr/types";
import { GetQrTemplatesRawResponse, GetQrTemplatesResponse } from "./types";

export const transformQrTemplates = (res: GetQrTemplatesRawResponse) => {
  const rawTemplates = res._embedded.assets;

  const templates = rawTemplates.map((p) => {
    const { max_quantity, name, slug, path, price } = p;

    return {
      slug: slug,
      name: name,
      maxQty: max_quantity,
      previewUrl: `${getApiHostUrl()}${path}`,
      price: price / 100,
    } as QRTemplate;
  });

  const response: GetQrTemplatesResponse = {
    data: {
      templates,
    },
    links: res._links,
  };

  return response;
};
