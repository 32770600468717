import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Typography } from "@/components/atoms";
import { useGetVehicleFormOptionsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  engineType: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const EngineTypeFilter = () => {
  const {
    filters: { engineType },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!engineType) return undefined;

    return engineType.filter(Boolean).join(", ");
  }, [engineType]);

  const onClose = () => setFilter("engineType", undefined);

  return (
    <FilterPopover name="engineType" value={value} label="Engine Type" onClose={onClose}>
      <EngineTypeFilterForm />
    </FilterPopover>
  );
};

interface EngineTypeFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const EngineTypeFilterDrawer = ({ open, onOpenChange }: EngineTypeFilterDrawerProps) => {
  const {
    filters: { engineType },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => {
    if (!engineType) return undefined;

    return engineType.filter(Boolean).join(", ");
  }, [engineType]);

  const onClose = () => setFilter("engineType", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Engine Type" onClose={onClose}>
      <EngineTypeFilterForm />
    </FilterDrawer>
  );
};

const EngineTypeFilterForm = () => {
  const { setFilter, filters } = useVehicleFilters();
  const { isFetching, data: options } = useGetVehicleFormOptionsQuery();

  const engineTypes = useMemo(() => {
    if (isFetching || !options) return [];

    return options.engineType.map((engineType) => ({
      name: engineType,
      value: engineType,
    }));
  }, [isFetching, options]);

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { engineType: (filters.engineType as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "engineType",
          engineTypes.map((engineType) => engineType.value)
        );
        setValue(
          "engineType",
          engineTypes.map((engineType) => engineType.value)
        );
      } else {
        setFilter("engineType", null);
        setValue("engineType", []);
      }
    },
    [engineTypes, setFilter, setValue]
  );

  const isAllEngineTypesSelected = useMemo(() => {
    if (!filters.engineType) return false;
    return filters.engineType.length === engineTypes.length;
  }, [engineTypes.length, filters.engineType]);

  return (
    <div className="contents">
      <Typography className="mb-4">Engine Type</Typography>
      <CheckboxInput label="All Engine Types" onChange={handleCheckAll} variant="check" checked={isAllEngineTypesSelected} />
      <div className="mt-4 grid gap-4 ">
        <Controller
          control={control}
          name="engineType"
          render={({ field }) => (
            <>
              {engineTypes.map((engineType) => (
                <CheckboxInput
                  key={engineType.value}
                  className="capitalize"
                  label={engineType.name.toLowerCase()}
                  value={engineType.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newEngineType = [...field.value, e.target.value];
                      field.onChange(newEngineType);
                      setFilter("engineType", newEngineType.length > 0 ? newEngineType : null);
                    } else {
                      const newEngineType = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newEngineType);
                      setFilter("engineType", newEngineType.length > 0 ? newEngineType : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(engineType.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
