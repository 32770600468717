import { Loading } from "@/components/atoms";
import { GeneralFooter, GeneralHeader, JobRatingPanel } from "@/components/organisms";
import { useSiteConfig } from "@/hooks";
import { useLazyVerifyProviderQuery } from "@/redux/apis/auth/authApi";
import { useLazyGetJobDetailsQuery } from "@/redux/apis/jobRating/jobRatingApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setActiveOperator } from "@/redux/slices/operator/operatorSlice";
import { activeOperatorSelector } from "@/redux/slices/operator/selectors";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { JobRatingExpired } from "./JobRatingExpired";
import { JobRatingSuccess } from "./JobRatingSuccess";

export const JobRating = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { apiUrl } = useSiteConfig();
  const { jobId, actionKey } = useParams();
  const [getJobDetails, { isError, isFetching, data: job }] = useLazyGetJobDetailsQuery();
  const [verifyProvider] = useLazyVerifyProviderQuery();
  const operator = useAppSelector(activeOperatorSelector);

  useEffect(() => {
    if (jobId && actionKey) getJobDetails({ id: jobId, actionKey });
  }, [jobId, actionKey, getJobDetails]);

  useEffect(() => {
    if (isError) navigate("expired");
  }, [isError, navigate])

  useEffect(() => {
    if (job) {
      if (job.rating.metadata.is_rated) navigate("success");
      else {
        if (job.provider.provider_key !== operator?.providerCode) {
          verifyProvider({ providerCode: job.provider.provider_key, apiUrl })
          .unwrap()
          .then((operator) => dispatch(setActiveOperator(operator)));
        }
      }
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [job]);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden md:flex-row">
      <div className="flex flex-1 flex-col relative">
        <GeneralHeader />
        <div className="relative flex-1 flex-col">
          {isFetching && <Loading />}
          {job && <JobRatingPanel job={job} />}
        </div>
        <GeneralFooter />
      </div>
    </div>
  );
};

JobRating.Success = JobRatingSuccess;
JobRating.Expired = JobRatingExpired;