import { useFormContext } from "react-hook-form";
import { ErrorMessage, TextInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const CompanyNameField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography>
        Name <span className="text-danger">*</span>
      </Typography>
      <TextInput className="w-full" hasError={!!errors.companyName} placeholder="Enter Name" maxLength={100} {...register("companyName")} />
      <ErrorMessage errors={errors} name="companyName" />
    </div>
  );
};
