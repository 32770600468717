import { DataTableColumnDef } from "@/components/molecules";
import { BookingDriverAssignmentLogData } from "../actions/types";

export const driverWarningTooltips = {
  warning_vehicle_type_incorrect: "Driver assigned to a different vehicle type than the job requires.",
  warning_match_driver_declined_client: "Driver has specified a preference to not be paired with this particular client.",
  warning_match_client_declined_driver: "Client has asked not to be matched with this particular driver.",
};

export const driverErrorTooltips = {
  error_match_driver: {
    NOT_OWN_FLEET: "Driver must belong to your own fleet",
    EXT_SUPPLIER: "Network jobs may not be performed by drivers outside of the network",
    UNKNOWN: "This job is unable to accept this job",
  },
};

export const bookingDriverAssignmentColumns: DataTableColumnDef<BookingDriverAssignmentLogData>[] = [
  {
    header: "Date and Time",
    cell: (props) => props.row.original.dateTime,
    size: 140,
  },
  {
    header: "Modifier",
    size: 140,
    cell: (props) => props.row.original.modifier,
  },
  {
    header: "Event",
    size: 220,
    cell: (props) => props.row.original.event,
  },
  {
    header: "Action",
    enableSorting: false,
    cell: (props) => <div className="line-clamp-3">{props.row.original.action}</div>,
  },
];
