import { HourlyHireInterval } from "@/redux/slices/pricing/types";
import { getCurrencySymbol } from "@/utils";
import { GetHourlyHireMinimumRawResponse, GetHourlyHireMinimumResponse, GetHourlyHireRawResponse, GetHourlyHireResponse } from "./types";

export const transformHourlyHire = (res: GetHourlyHireRawResponse) => {
  const { _links, currency, intervals: rawIntervals, base_interval } = res;

  const intervals: HourlyHireInterval[] = rawIntervals.map((i) => ({
    minimumRange: i.minimum_range,
    rate: i.rate / 100,
  }));

  const currencySymbol = getCurrencySymbol(currency) ?? "";

  const response: GetHourlyHireResponse = {
    data: {
      pricing: {
        currency,
        currencySymbol,
        basePrice: {
          minimumRange: base_interval.minimum_range,
          rate: base_interval.rate / 100,
        },
        intervals,
      },
    },
    links: _links,
  };

  return response;
};

export const transformHourlyHireMinimum = (res: GetHourlyHireMinimumRawResponse) => {
  const { _links, minimum_booking_period_minutes } = res;

  const response: GetHourlyHireMinimumResponse = {
    data: {
      minimumBookingPeriodMinutes: minimum_booking_period_minutes / 60,
    },
    links: _links,
  };

  return response;
};
