import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Modal, Skeleton } from "@/components/atoms";
import { Pagination } from "@/components/molecules";
import { AddExtrasForm, EditExtrasForm, ExtrasTableEditor, PageHeader } from "@/components/organisms";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetCostExtrasQuery } from "@/redux/apis/config/pricing/costExtras";
import { useAppSelector } from "@/redux/hooks";
import { costExtrasPageSelector, costExtrasSelector } from "@/redux/slices/pricing/selectors";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { addNotification, addToast } from "@/utils";

export const FixedCostExtrasEdit = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<"add" | "edit" | undefined>(undefined);
  const [editItem, setEditItem] = useState<CostExtrasItem | undefined>(undefined);
  const [getCostExtras, { isFetching }] = useLazyGetCostExtrasQuery();
  const items = useAppSelector(costExtrasSelector);
  const { current, count, total, size } = useAppSelector(costExtrasPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();

  useEffect(() => {
    getCostExtras({ page, pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getCostExtras]);

  useEffect(() => {
    setStep(editItem ? "edit" : undefined);
  }, [editItem]);

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <>
          <PageHeader.Actions />
          <Panel>
            <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
              <div className="flex-1">
                <Typography variant="h3" className="leading-8">
                  Fixed Cost Extras
                </Typography>
                <Typography className="text-neutral-dark-gray">
                  Configure the fixed cost extras your customers can select when making a booking.
                </Typography>
              </div>
              <div className="flex items-center gap-4">
                <Button onClick={() => setStep("add")} variant="tertiary" startIcon="Add">
                  Add Extra
                </Button>
                <Button onClick={() => navigate("../")}>Done</Button>
              </div>
            </div>
            <ExtrasTableEditor
              className="mt-4"
              items={items}
              onEditItem={(item: CostExtrasItem) => setEditItem(item)}
              addExtra={() => setStep("add")}
            />
            <Modal open={step === "add"} title="Add Fixed Cost Extra" onClose={() => setStep(undefined)}>
              <AddExtrasForm
                onClose={() => setStep(undefined)}
                onError={(messages) => messages.forEach((m) => addToast("danger", m))}
                onSuccess={() => {
                  addToast("success", "Successfully added new fixed cost extra.");
                  setStep(undefined);
                }}
              />
            </Modal>
            <Modal open={step === "edit"} title="Edit Fixed Cost Extra" onClose={() => setStep(undefined)}>
              <EditExtrasForm
                item={editItem}
                onClose={() => setEditItem(undefined)}
                onError={(messages) => messages.forEach((m) => addToast("danger", m))}
                onSuccess={() => {
                  addNotification("info", `Updated ${editItem?.name}`, `Successfully updated.`);
                  setEditItem(undefined);
                }}
              />
            </Modal>
          </Panel>
          <Pagination
            onPageClick={handlePageClick}
            onSizeChange={handleSizeChange}
            currentPage={current}
            pageSize={size}
            totalItems={total}
            pageCount={count}
          />
        </>
      )}
    </>
  );
};
