import { endOfMonth, endOfTomorrow, endOfWeek, endOfYear, startOfMonth, startOfTomorrow, startOfWeek, startOfYear } from "date-fns";
import { clsx } from "@/utils";
import { Typography } from "../Typography/Typography";
import { useDateRangePicker } from "./context";

export const Nav = () => {
  const { preset, setPreset, onDatesChange } = useDateRangePicker();

  const handleTodayClick = () => {
    setPreset("today");
    onDatesChange([new Date(), new Date()]);
  };

  const handleTomorrowClick = () => {
    setPreset("tomorrow");
    onDatesChange([startOfTomorrow(), endOfTomorrow()]);
  };

  const handleThisWeekClick = () => {
    setPreset("thisWeek");
    onDatesChange([startOfWeek(new Date()), endOfWeek(new Date())]);
  };

  const handleThisMonthClick = () => {
    setPreset("thisMonth");
    onDatesChange([startOfMonth(new Date()), endOfMonth(new Date())]);
  };

  const handleThisYearClick = () => {
    setPreset("thisYear");
    onDatesChange([startOfYear(new Date()), endOfYear(new Date())]);
  };

  const buttonClass =
    "max-md:border max-md:rounded-2xl max-md:shrink-0 max-md:border-neutral-dark-gray cursor-pointer px-4 py-1.5 justify-center md:py-2 hover:bg-primary hover:text-white max-md:h-[32px] leading-snug";

  return (
    <div className="no-scrollbar h-full gap-2 overflow-auto text-left max-md:flex max-md:w-[375px] max-md:border-b max-md:border-neutral-mid-gray max-md:px-2 max-md:py-2 max-md:text-neutral-dark-gray md:w-[120px] xl:w-[135px]">
      <Typography
        className={clsx(buttonClass, {
          "border-primary bg-primary text-white": preset === "today",
        })}
        onClick={handleTodayClick}
      >
        Today
      </Typography>
      <Typography
        className={clsx(buttonClass, {
          "border-primary bg-primary text-white": preset === "tomorrow",
        })}
        onClick={handleTomorrowClick}
      >
        Tomorrow
      </Typography>
      <Typography
        className={clsx(buttonClass, {
          "border-primary bg-primary text-white": preset === "thisWeek",
        })}
        onClick={handleThisWeekClick}
      >
        This week
      </Typography>
      <Typography
        className={clsx(buttonClass, {
          "border-primary bg-primary text-white": preset === "thisMonth",
        })}
        onClick={handleThisMonthClick}
      >
        This month
      </Typography>
      <Typography
        className={clsx(buttonClass, {
          "border-primary bg-primary text-white": preset === "thisYear",
        })}
        onClick={handleThisYearClick}
      >
        This year
      </Typography>
    </div>
  );
};
