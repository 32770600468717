import { adminApi } from "../adminApi";
import { transformDisplayOptions, transformUpdateDisplayOptions } from "./helpers";
import { GetDisplayOptionsResponse, UpdateDisplayOptionsParams, UpdateDisplayOptionsResponse } from "./types";

export const displayOptionsApi = adminApi.enhanceEndpoints({ addTagTypes: ["DisplayOptions"] }).injectEndpoints({
  endpoints: (builder) => ({
    getDisplayOptions: builder.query<GetDisplayOptionsResponse, void>({
      query: () => "/display-options",
      transformResponse: transformDisplayOptions,
      providesTags: ["DisplayOptions"],
    }),
    updateDisplayOptions: builder.mutation<UpdateDisplayOptionsResponse, UpdateDisplayOptionsParams>({
      query: (body) => ({
        url: "/display-options",
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateDisplayOptions,
      invalidatesTags: ["DisplayOptions"],
    }),
  }),
});

export const { useGetDisplayOptionsQuery, useUpdateDisplayOptionsMutation } = displayOptionsApi;
