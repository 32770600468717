import { createSlice } from "@reduxjs/toolkit";
import { AddOnState } from "./types";
import { addOnApi } from "@/redux/apis";

const initialState: AddOnState = {
  premiumAddOns: [],
  addOns: [],
  featuredAddOns: [],
};

export const addOnSlice = createSlice({
  name: "addOn",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(addOnApi.endpoints.getPremiumAddOns.matchFulfilled, (state, { payload }) => {
      const { addOns } = payload.data;
      state.premiumAddOns = addOns;
    }),
    builder.addMatcher(addOnApi.endpoints.getAddOns.matchFulfilled, (state, { payload }) => {
      const { addOns } = payload.data;
      state.addOns = addOns;
    }),
    builder.addMatcher(addOnApi.endpoints.getFeaturedAddOns.matchFulfilled, (state, { payload }) => {
      const { addOns } = payload.data;
      state.featuredAddOns = addOns;
    }),
    builder.addMatcher(addOnApi.endpoints.getOffloadingConfiguration.matchFulfilled, (state, { payload }) => {
      const offloading = state.addOns.find((a) => a.identifier === "offloading");
      if (offloading) offloading.configuration = payload.data;
    }),
    builder.addMatcher(addOnApi.endpoints.getInvoicingConfiguration.matchFulfilled, (state, { payload }) => {
      const invoicing = state.addOns.find((a) => a.identifier === "invoicing");
      if (invoicing) invoicing.configuration = payload.data;
    }),
    builder.addMatcher(addOnApi.endpoints.getGoogleTagManagerConfiguration.matchFulfilled, (state, { payload }) => {
      const item = state.addOns.find((a) => a.identifier === "enterprise-gtm");
      if (item) item.configuration = payload.data;
    });
  },
});

export default addOnSlice.reducer;
