import { cleanParams } from "./cleanParams";

export const createSearchParams = <T extends object>(params: T): URLSearchParams => {
  const searchParams = Object.entries(cleanParams(params)).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) value.forEach((v, i) => acc.set(`${key}[${i}]`, v));
    else acc.set(key, value);

    return acc;
  }, new URLSearchParams());

  return searchParams;
};
