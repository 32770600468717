import { GuestBookingDriverActivity } from "@/components/organisms";
import { BookingDriverActivityContextProvider } from "@/components/organisms/BookingDriverActivity/context";
import { useBranding, useSiteConfig } from "@/hooks";
import { LinkExpired } from "../Expired/LinkExpired";

export const BookingDriverActivity = () => {
  const { branding } = useSiteConfig();
  useBranding(branding);

  return (
    <BookingDriverActivityContextProvider>
      <GuestBookingDriverActivity />
    </BookingDriverActivityContextProvider>
  );
};

BookingDriverActivity.NotFound = LinkExpired;
