import { minutesToHours, parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { useMemo } from "react";
import { Icon, Typography } from "@/components/atoms";
import { useBookingOfferPageContext } from "../context";

export const When = () => {
  const { bookingOffer } = useBookingOfferPageContext();
  const { pickupTime, duration, timezone } = bookingOffer;
  const tz = timezone.toLowerCase() === "international" ? undefined : timezone;

  const pickupDate = useMemo(() => {
    if (tz) return utcToZonedTime(new Date(pickupTime), tz);

    const parsedDate = parseISO(pickupTime);
    return utcToZonedTime(parsedDate, "Australia/Sydney");
  }, [pickupTime, tz]);

  const durationHours = duration ? minutesToHours(duration) : undefined;
  const durationDays = durationHours && durationHours >= 24 ? durationHours % 24 : undefined;

  return (
    <div className="mt-6">
      <Typography variant="action" className="block w-full rounded-lg bg-neutral-surface-gray px-[10px] py-[6px]">
        When
      </Typography>
      <div className="mt-4 flex gap-4">
        <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
          <Icon name="Calendar" size={24} className=" text-neutral-dark-gray" />
          <Typography className=" text-neutral-dark-gray">Date</Typography>
        </div>
        <div className="flex gap-3">
          <Typography>{format(pickupDate, "dd-MM-yyyy", { timeZone: tz })}</Typography>
        </div>
      </div>
      <div className="mt-4 flex gap-4">
        <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
          <Icon name="Clock" size={24} className=" text-neutral-dark-gray" />
          <Typography className=" text-neutral-dark-gray">Time</Typography>
        </div>
        <div className="flex gap-3">
          <Typography>{format(pickupDate, `h:mm a ${tz ? "'GMT'(xxx)" : "'(Local)'"}`, { timeZone: tz })}</Typography>
        </div>
      </div>
      {duration && (
        <div className="mt-4 flex gap-4">
          <div className="flex w-[120px] shrink-0 items-center gap-2 md:w-[200px]">
            <Icon name="Timer1" size={24} className=" text-neutral-dark-gray" />
            <Typography className=" text-neutral-dark-gray">Duration</Typography>
          </div>
          <div className="flex gap-3">
            <Typography>{`${durationDays ? `${durationDays} days ` : ""}${durationHours ? `${durationHours % 24} hours` : ""}`}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};
