import { useOutlet } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Button, Skeleton } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import { InvoicesCompaniesSearch, InvoicingModals, InvoicingSidePanels, UninvoicedJobsCompaniesSearch } from "@/components/organisms";
import { usePage } from "@/hooks";
import { useGetInvoicingOptionsQuery } from "@/redux/apis/payment/invoice/invoiceApi";
import { useAppDispatch } from "@/redux/hooks";
import { setInvoicingSidePanelAction } from "@/redux/slices/payment/paymentSlice";
import { Restricted } from "../Restricted/Restricted";
import { Invoices } from "./Invoices";
import { UninvoicedJobs } from "./UninvoicedJobs";

type Tab = {
  name: string;
  to: string;
};

const pageTabs: Tab[] = [
  {
    name: "Un-invoiced Jobs",
    to: "./uninvoiced-jobs",
  },
  {
    name: "Invoices",
    to: "./invoices",
  },
];

export const Invoicing = () => {
  const dispatch = useAppDispatch();
  const ability = useAppAbility();
  const outlet = useOutlet();
  const { pathname } = usePage();
  const { isFetching } = useGetInvoicingOptionsQuery();

  const renderInvoiceSetupButton = () => {
    if (ability.can("manage", "providerPaymentsInvoiceSetup")) {
      return (
        <Button variant="secondary" startIcon="Setting2" onClick={() => dispatch(setInvoicingSidePanelAction("invoiceSetup"))}>
          Invoice Setup
        </Button>
      );
    }
  };

  const renderActionHeader = () => {
    if (pathname.includes("uninvoiced-jobs")) {
      return (
        <div className="flex flex-col sm:flex-row sm:items-center gap-4">
          <div className="flex flex-row gap-4">
            {renderInvoiceSetupButton()}
            <Button variant="secondary" startIcon="Sort" onClick={() => dispatch(setInvoicingSidePanelAction("uninvoicedJobsFilter"))}>
              Filter
            </Button>
          </div>
          <UninvoicedJobsCompaniesSearch />
        </div>
      );
    } else if (pathname.includes("invoices")) {
      return (
        <div className="flex flex-row items-center gap-x-4">
          <InvoicesCompaniesSearch />
        </div>
      );
    }
  };

  if (!ability.can("manage", "providerPaymentsInvoice")) return <Restricted />;
  if (isFetching) return <Skeleton />;

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-col xl:flex-row gap-3 mb-4 justify-between">
        <Tabs className="gap-3">
          {pageTabs.map((t, i) => (
            <Tabs.Tab key={i} to={t.to} name={t.name} variant="tertiary" />
          ))}
        </Tabs>
        {renderActionHeader()}
      </div>
      {outlet}
      <InvoicingSidePanels />
      <InvoicingModals />
    </div>
  );
};

Invoicing.UninvoicedJobs = UninvoicedJobs;
Invoicing.Invoices = Invoices;
