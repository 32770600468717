import { invalidateOn } from "@/helpers/reduxHelpers";
import { createSearchParams } from "@/utils";
import { paymentApi } from "../paymentApi";
import {
  transformInvoices,
  transformInvoice,
  transformInvoiceSettings,
  transformInvoiceSettingsHistory,
  transformInvoicingOptions,
  transformPaymentHistory,
  transformInvoicePayment,
  transformInvoiceHistory,
  transformClientJobs,
  transformJobs,
  transformInvoiceNotes,
  transformInvoiceManualPaymentDetails,
  transformInvoiceManualPaymentReceipt,
} from "./helpers";
import {
  GetInvoiceHistoryParams,
  GetInvoiceHistoryResponse,
  GetInvoiceSettingsHistoryResponse,
  GetInvoiceSettingsHistoryParams,
  GetInvoiceSettingsResponse,
  GetInvoicesParams,
  GetInvoicesResponse,
  GetInvoiceParams,
  GetInvoiceResponse,
  GetInvoicingOptionsResponse,
  GetPaymentHistoryParams,
  GetPaymentHistoryResponse,
  GetInvoicePaymentResponse,
  GetInvoicePaymentParams,
  UndoPaymentParams,
  UpdateInvoiceSettingsParams,
  UpdateInvoiceSettingsResponse,
  UpdateInvoiceStatusParams,
  UpdateInvoiceStatusResponse,
  MarkAsPaidInvoiceParams,
  MarkAsPaidInvoiceResponse,
  GetClientJobsResponse,
  GetClientJobsParams,
  GetJobsResponse,
  GetJobsParams,
  GenerateInvoiceParams,
  GenerateInvoiceResponse,
  ResendInvoiceResponse,
  ResendInvoiceParams,
  GeneratePreviewResponse,
  GeneratePreviewParams,
  GetInvoiceNotesResponse,
  GetInvoiceNotesParams,
  AddInvoiceNoteResponse,
  AddInvoiceNoteParams,
  DeleteInvoiceNoteParams,
  GetInvoiceManualPaymentDetailsResponse,
  GetInvoiceManualPaymentDetailsParams,
  TriggerInvoiceManualPaymentResponse,
  TriggerInvoiceManualPaymentParams,
  GetInvoiceManualPaymentReceiptResponse,
  GetInvoiceManualPaymentReceiptParams,
} from "./types";

export const invoiceApi = paymentApi
  .enhanceEndpoints({
    addTagTypes: ["InvoiceNotes"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInvoices: builder.query<GetInvoicesResponse, GetInvoicesParams>({
        query: (params) => `/invoice?page_size=50&${createSearchParams(params).toString()}`,
        transformResponse: transformInvoices,
      }),
      getInvoice: builder.query<GetInvoiceResponse, GetInvoiceParams>({
        query: ({ id }) => `/invoice/${id}`,
        transformResponse: transformInvoice,
      }),
      getInvoicingOptions: builder.query<GetInvoicingOptionsResponse, void>({
        query: () => "/invoice/options",
        transformResponse: transformInvoicingOptions,
      }),
      updateInvoiceStatus: builder.mutation<UpdateInvoiceStatusResponse, UpdateInvoiceStatusParams>({
        query: ({ id, ...body }) => ({
          url: `/invoice/${id}`,
          method: "PATCH",
          body,
        }),
      }),
      markAsPaidInvoice: builder.mutation<MarkAsPaidInvoiceResponse, MarkAsPaidInvoiceParams>({
        query: ({ id, ...body }) => ({
          url: `/invoice/${id}/payment`,
          method: "POST",
          body,
        }),
      }),
      getPaymentHistory: builder.query<GetPaymentHistoryResponse, GetPaymentHistoryParams>({
        query: ({ id }) => `/invoice/${id}/payment?page_size=100`,
        transformResponse: transformPaymentHistory,
      }),
      getInvoicePayment: builder.query<GetInvoicePaymentResponse, GetInvoicePaymentParams>({
        query: ({ invoiceId, paymentId }) => `/invoice/${invoiceId}/payment/${paymentId}`,
        transformResponse: transformInvoicePayment,
      }),
      getInvoiceHistory: builder.query<GetInvoiceHistoryResponse, GetInvoiceHistoryParams>({
        query: ({ id }) => `/invoice/${id}/history?page_size=100`,
        transformResponse: transformInvoiceHistory,
      }),
      undoPayment: builder.mutation<void, UndoPaymentParams>({
        query: ({ invoiceId, paymentId }) => ({
          url: `/invoice/${invoiceId}/payment/${paymentId}`,
          method: "DELETE",
        }),
      }),
      getClientJobs: builder.query<GetClientJobsResponse, GetClientJobsParams>({
        query: ({ companyId, ...params }) =>
          `/invoice/jobs-pending?company_uuid=${companyId}&group=client&page_size=50&${createSearchParams(params).toString()}`,
        transformResponse: transformClientJobs,
      }),
      getJobs: builder.query<GetJobsResponse, GetJobsParams>({
        query: ({ companyId, ...params }) =>
          `/invoice/jobs-pending?company_uuid=${companyId}&page_size=50&${createSearchParams(params).toString()}`,
        transformResponse: transformJobs,
      }),
      getInvoiceSettings: builder.query<GetInvoiceSettingsResponse, void>({
        query: () => "/invoice/settings",
        transformResponse: transformInvoiceSettings,
      }),
      updateInvoiceSettings: builder.mutation<UpdateInvoiceSettingsResponse, UpdateInvoiceSettingsParams>({
        query: (body) => ({
          url: "/invoice/settings",
          method: "POST",
          body,
        }),
      }),
      getInvoiceSettingsHistory: builder.query<GetInvoiceSettingsHistoryResponse, GetInvoiceSettingsHistoryParams>({
        query: (params) => `/invoice/settings/history?${createSearchParams(params).toString()}`,
        transformResponse: transformInvoiceSettingsHistory,
      }),
      generateInvoice: builder.mutation<GenerateInvoiceResponse, GenerateInvoiceParams>({
        query: (body) => ({
          url: "/invoice",
          method: "POST",
          body,
        }),
      }),
      resendInvoice: builder.mutation<ResendInvoiceResponse, ResendInvoiceParams>({
        query: ({ id, ...body }) => ({
          url: `/invoice/${id}/actions/resend`,
          method: "POST",
          body,
        }),
      }),
      generatePreview: builder.mutation<GeneratePreviewResponse, GeneratePreviewParams>({
        query: (body) => ({
          url: "/invoice/generate-preview",
          method: "POST",
          body,
        }),
      }),
      getInvoiceNotes: builder.query<GetInvoiceNotesResponse, GetInvoiceNotesParams>({
        query: ({ id }) => `/invoice/${id}/notes?page_size=100`,
        transformResponse: transformInvoiceNotes,
        providesTags: ["InvoiceNotes"],
      }),
      addInvoiceNote: builder.mutation<AddInvoiceNoteResponse, AddInvoiceNoteParams>({
        query: ({ id, ...body }) => ({
          url: `/invoice/${id}/notes`,
          method: "POST",
          body,
        }),
        invalidatesTags: invalidateOn({ success: ["InvoiceNotes"] }),
      }),
      deleteInvoiceNote: builder.mutation<void, DeleteInvoiceNoteParams>({
        query: ({ invoiceId, noteId }) => ({
          url: `/invoice/${invoiceId}/notes/${noteId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["InvoiceNotes"],
      }),
      getInvoiceManualPaymentDetails: builder.mutation<GetInvoiceManualPaymentDetailsResponse, GetInvoiceManualPaymentDetailsParams>({
        query: ({ id, paymentMethodId }) => ({
          url: `/invoice/${id}/manual-payment`,
          method: "POST",
          body: {
            dry_run: true,
            payment_method_uuid: paymentMethodId,
          },
        }),
        transformResponse: transformInvoiceManualPaymentDetails,
      }),
      triggerInvoiceManualPayment: builder.mutation<TriggerInvoiceManualPaymentResponse, TriggerInvoiceManualPaymentParams>({
        query: ({ id, paymentMethodId }) => ({
          url: `/invoice/${id}/manual-payment`,
          method: "POST",
          body: {
            dry_run: false,
            payment_method_uuid: paymentMethodId,
          },
        }),
        transformResponse: transformInvoiceManualPaymentDetails,
      }),
      getInvoiceManualPaymentReceipt: builder.query<GetInvoiceManualPaymentReceiptResponse, GetInvoiceManualPaymentReceiptParams>({
        query: ({ id, paymentRecordId }) => `/invoice/${id}/manual-payment/${paymentRecordId}`,
        transformResponse: transformInvoiceManualPaymentReceipt,
      }),
    }),
  });

export const {
  useLazyGetInvoicesQuery,
  useLazyGetInvoiceQuery,
  useGetInvoicingOptionsQuery,
  useUpdateInvoiceStatusMutation,
  useMarkAsPaidInvoiceMutation,
  useGetPaymentHistoryQuery,
  useLazyGetInvoicePaymentQuery,
  useGetInvoiceHistoryQuery,
  useUndoPaymentMutation,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceSettingsMutation,
  useGetInvoiceSettingsHistoryQuery,
  useLazyGetInvoiceSettingsHistoryQuery,
  useLazyGetClientJobsQuery,
  useLazyGetJobsQuery,
  useGenerateInvoiceMutation,
  useResendInvoiceMutation,
  useGeneratePreviewMutation,
  useGetInvoiceNotesQuery,
  useAddInvoiceNoteMutation,
  useDeleteInvoiceNoteMutation,
  useGetInvoiceManualPaymentDetailsMutation,
  useTriggerInvoiceManualPaymentMutation,
  useLazyGetInvoiceManualPaymentReceiptQuery,
} = invoiceApi;
