import { Button, Drawer, Icon } from "@/components/atoms";
import { clsx } from "@/utils";

interface FilterDrawerProps {
  label: string;
  value?: string | boolean | null;
  children: JSX.Element;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onClose: () => void;
  minHeight?: number;
}

const statesClass = {
  active: "border-primary-light bg-primary-light enabled:border-primary-light active:border-primary-light",
  inactive:
    "border-neutral-gray bg-neutral-gray text-neutral-dark-gray enabled:border-neutral-gray active:border-neutral-gray hover:!border-neutral-gray hover:!bg-neutral-gray hover:!text-neutral-dark-gray",
};
export const FilterDrawer = ({ open, onOpenChange, label, value, onClose, minHeight, children }: FilterDrawerProps) => {
  const state = !value ? "inactive" : "active";

  return (
    <Drawer open={open} onOpenChange={onOpenChange} minHeight={minHeight}>
      <Button
        variant="secondary"
        className={clsx(
          "!h-[26px] items-center gap-0.5 overflow-ellipsis rounded-full !px-2 text-primary !outline-none transition-colors [&>span]:!text-sm",
          "aria-expanded:!border-primary aria-expanded:!bg-primary-light aria-expanded:bg-neutral-gray aria-expanded:!text-primary",
          statesClass[state]
        )}
        endIcon={<Icon onClick={onClose} name="close" isCustom size="sm" className="ml-1 rounded-full hover:bg-primary-light" />}
      >
        {label}
        {value ? `: ${value}` : ""}
      </Button>
      <div className="contents">
        <Drawer.Close asChild>
          <Button variant="tertiary" className="absolute right-0 -translate-x-1 -translate-y-1 text-info">
            Done
          </Button>
        </Drawer.Close>
        {children}
      </div>
    </Drawer>
  );
};
