import { DPState, useDatePickerState } from "@rehookify/datepicker";
import { createContext, useContext, useState } from "react";

export type DatePreset = "today" | "tomorrow" | "thisWeek" | "thisMonth" | "thisYear" | undefined;

interface IDateRangePicker {
  open: boolean;
  setOpen: (open: boolean) => void;
  dates?: Date[];
  onDatesChange: (dates?: Date[]) => void;
  preset: DatePreset;
  setPreset: (preset: DatePreset) => void;
  dpState: DPState;
  handleClose: () => void;
}

export const DateRangePickerContext = createContext<IDateRangePicker | null>(null);

interface DateRangePickerProviderProps extends React.PropsWithChildren {
  dates?: Date[];
  onDatesChange: (dates?: Date[]) => void;
  preset?: DatePreset;
}

export const DateRangePickerProvider = ({ dates, onDatesChange, preset: initialPreset, children }: DateRangePickerProviderProps) => {
  const [dateValues, setDateValues] = useState(dates);
  const [preset, setPreset] = useState<DatePreset>(initialPreset);
  const [open, setOpen] = useState(false);

  const handleDatesChange = (dates?: Date[]) => {
    //handle selecting the same date
    //when current dateValues has only one date and dates is empty
    //set the second date to the same date
    if (dateValues && dateValues.length === 1 && dates?.length === 0) {
      setDateValues([dateValues[0], dateValues[0]]);
      onDatesChange([dateValues[0], dateValues[0]]);
      setOpen(false);
      return;
    }

    //normal case
    setDateValues(dates);

    if (dates && dates.length > 1) {
      onDatesChange(dates);
      setOpen(false);
    }
  };

  const handleClose = () => {
    if (dateValues && dateValues.length === 1) {
      setDateValues([dateValues[0], dateValues[0]]);
      onDatesChange([dateValues[0], dateValues[0]]);
    }
    setOpen(false);
  };

  const dpState = useDatePickerState({
    selectedDates: dateValues || [],

    onDatesChange: handleDatesChange,
    dates: { toggle: true, mode: "range", selectSameDate: true },
    calendar: {
      offsets: [1],
      startDay: 1,
      mode: "fluid",
    },
  });

  return (
    <DateRangePickerContext.Provider
      value={{
        open,
        setOpen,
        dates: dateValues,
        onDatesChange: handleDatesChange,
        preset,
        setPreset,
        dpState,
        handleClose,
      }}
    >
      {children}
    </DateRangePickerContext.Provider>
  );
};

export const useDateRangePicker = () => {
  const context = useContext(DateRangePickerContext);
  if (!context) {
    throw new Error("useDateRangePicker must be used within a DateRangePickerProvider");
  }

  return context;
};
