import { useMemo } from "react";
import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { AccreditationHistoryItem } from "@/redux/slices/accreditation/types";
import { clsx } from "@/utils";
import { generateHistoryColumns, generateHistoryData } from "../helpers";

interface AccreditationHistoryTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: AccreditationHistoryItem[];
}

export const AccreditationHistoryTable = ({ items, className, ...props }: AccreditationHistoryTableProps) => {
  const { dateFormat, timeFormat } = useProfile();

  const data = useMemo(
    () => generateHistoryData(items, dateFormat, timeFormat),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const columns = useMemo(() => generateHistoryColumns(), []);

  return (
    <div className={clsx("relative flex", className)}>
      <DataTable className="[&_table]:table-auto overflow-x-auto w-full" columns={columns} data={data} {...props} />
    </div>
  );
};
