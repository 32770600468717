import { useParams } from "react-router-dom";
import { Skeleton } from "@/components/atoms";
import { DispatchSettingsDetails } from "@/components/organisms";
import { useGetDispatchSettingsQuery } from "@/redux/apis/config/admin/dispatchSettings";
import { OffloadingPanel } from "@/components/organisms/AddOns/Offloading/OffloadingPanel";
import { DispatchSettingsEditor } from "@/components/organisms/DispatchSettings/DispatchSettingsEditor";
import { clsx } from "@/utils";
import { useGetAddOnsQuery } from "@/redux/apis/addOn/addOnApi";
import { useAppSelector } from "@/redux/hooks";
import { addOnItemSelector } from "@/redux/slices/addOn/selectors";

export const DispatchSettings = () => {
  const { mode, type } = useParams();
  const { isFetching: isFetchingDispatchSettings } = useGetDispatchSettingsQuery();
  const { isFetching: isFetchingAddOns } = useGetAddOnsQuery({}, { refetchOnMountOrArgChange: true });
  const offloading = useAppSelector(addOnItemSelector("offloading"));
  const isFetching = isFetchingDispatchSettings || isFetchingAddOns;

  if (isFetching) return <Skeleton />;

  return (
    <>
      <div className={clsx({ "pointer-events-none opacity-50": mode === "edit" && type === "offloading" })}>
        {mode === "edit" && !type ? <DispatchSettingsEditor /> : <DispatchSettingsDetails />}
      </div>
      {offloading && <OffloadingPanel offloading={offloading} className={clsx("mt-5", { "pointer-events-none opacity-50": mode === "edit" && !type })} />}
    </>
  );
};
