import { useParams } from "react-router-dom";
import { Panel, Skeleton } from "@/components/atoms";
import { Restricted } from "@/components/pages";
import { useFixedNationwidePricingContext, useFixedPricingContext } from "../contexts";
import { NationwideEditor } from "./NationwideEditor";
import { NationwideTable } from "./tables/NationwideTable";

export const NationwidePanel = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { mode } = useParams();
  const { isFetching: isFetchingPricingProfiles, pricingProfile, isRestricted: isRestrictedPricingProfile } = useFixedPricingContext();
  const {
    isFetching: isFetchingNationwidePricing,
    nationwidePricing,
    isRestricted: isRestrictedNationwidePricing,
  } = useFixedNationwidePricingContext();
  const isFetching = isFetchingPricingProfiles || isFetchingNationwidePricing || !pricingProfile || !nationwidePricing;
  const isRestricted = isRestrictedPricingProfile || isRestrictedNationwidePricing;

  if (isRestricted) return <Restricted />;

  return (
    <>
      {isFetching ? (
        <Skeleton />
      ) : (
        <Panel className={className} {...props}>
          {mode === "edit" ? (
            <NationwideEditor pricing={nationwidePricing} pricingProfile={pricingProfile} />
          ) : (
            <NationwideTable pricing={nationwidePricing} />
          )}
        </Panel>
      )}
    </>
  );
};
