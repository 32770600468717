import { Result } from "react-spreadsheet-import/types/types";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { importFields } from "./fixtures";
import { useOperator } from "@/hooks";
import { RegisterProviderClientParams } from "@/redux/apis/auth/types";
import { useRegisterProviderClientMutation } from "@/redux/apis/auth/authApi";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useState } from "react";
import { ClientsImportResultModal } from "./ClientsImportResultModal";
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

interface ClientsImportPanelProps {
  open: boolean;
  onClose: () => void;
  refetchClients: () => void;
}

export const ClientsImportPanel = ({ open, onClose, refetchClients }: ClientsImportPanelProps) => {
  const operator = useOperator();
  const [registerProviderClient] = useRegisterProviderClientMutation();
  const [errors, setErrors] = useState<Array<string>>([]);
  const [importCount, setImportCount] = useState<number>(0);
  const [openResult, setOpenResult] = useState(false);

  const getAccountType = (type?: string) => {
    const typeValue = type?.toLowerCase();

    switch (typeValue) {
      case "client": return "passenger";
      case "executive assistant": return "executive_assistant";
      default: return undefined;
    };
  };

  const handleImport = async (data: Result<string>, _: File) => {
    const importErrors: Array<string> = [];
    let successCount = 0;
    if (!operator) return;

    await Promise.all(data.validData.map(async data => {
      const clientData = {
        providerId: operator.id,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        mobile: data.contactNumber || "",
        provider_code: operator.providerCode,
        registered_device: "browser",
        company_name: data.companyName,
        operator_identifier: data.uniqueIdentifier,
        type: getAccountType(data.accountType as string),
        require_password_change: true,
        send_login_instructions: true,
      } as RegisterProviderClientParams;

      await registerProviderClient(clientData)
        .unwrap()
        .then(() => successCount++)
        .catch((e) => getErrorMessages(e).forEach((m) => importErrors.push(`${data.firstName} ${data.lastName} | ${m}`)));

      setImportCount(successCount);
      setErrors(importErrors);
      setOpenResult(true);
      refetchClients();
    }));
  };

  return (
    <>
      <ReactSpreadsheetImport 
        isOpen={open} 
        onClose={onClose} 
        onSubmit={handleImport} 
        fields={importFields} 
        allowInvalidSubmit={false} 
        customTheme={{
          colors: {
            background: "white",
            rsi: {
              50: operator.branding.light,
              500: operator.branding.primary,
              900: operator.branding.dark,
            },
          },
        }}
        rowHook={(data, addError) => {
          let contactNumber = data.contactNumber as string;
          const country = operator.country as CountryCode;

          if (contactNumber) {
            if (isValidPhoneNumber(contactNumber, country)) contactNumber = parsePhoneNumber(contactNumber, country).number;
            else if (!isValidPhoneNumber(contactNumber)) addError("contactNumber", { message: "Contact Number is invalid", level: "error" });
          }

          return { ...data, contactNumber }
        }}
      />
      <ClientsImportResultModal importCount={importCount} errors={errors} open={openResult} onClose={() => setOpenResult(false)} />
    </>
  );
};
