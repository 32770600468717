import { useFormContext } from "react-hook-form";
import { ErrorMessage, PhoneNumberInput, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { CompaniesFormData } from "../../types";

export const KeyContactContactNumberField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CompaniesFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-1", className)} {...props}>
      <Typography>Contact Number</Typography>
      <PhoneNumberInput control={control} name="keyContactContactNumber" hasError={!!errors.keyContactContactNumber} />
      <ErrorMessage errors={errors} name="keyContactContactNumber" />
    </div>
  );
};
