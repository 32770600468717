import { useLocalStorageValue } from "@react-hookz/web";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, CheckboxInput, Typography, SortableList, Drawer, Tooltip, Icon } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { allColumns } from "../../fixtures";
import { TableColumn } from "../../types";

export interface BookingCustomizeColumnsDrawerProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClose: () => void;
}

type FormData = {
  columns: string[];
};

const selectableColumns = allColumns.filter((i) => !i.isFixed);
const defaultColumns = selectableColumns.filter((i) => i.isDefault);

export const BookingCustomizeColumnsDrawer = ({ open, onOpenChange, onClose }: BookingCustomizeColumnsDrawerProps) => {
  const { id: operatorId } = useOperator();
  const { set: setColumns, value: savedColumns } = useLocalStorageValue(`${operatorId}:booking-columns`, {
    defaultValue: defaultColumns.map((i) => i.id.toString()),
  });

  const [lastSortedColumns, setLastSortedColumns] = useState<TableColumn[]>(selectableColumns);

  const { control, setValue, watch, handleSubmit } = useForm<FormData>({
    defaultValues: { columns: selectableColumns.filter((i) => savedColumns?.includes(i.id)).map((i) => i.id) },
  });

  const selectedColumns = watch("columns");

  const handleSelectAllChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      if (target.checked) {
        setValue(
          "columns",
          selectableColumns.map((i) => i.id)
        );
      } else {
        setValue("columns", []);
      }
    },
    [setValue]
  );

  const handleSortChange = useCallback((items: TableColumn[]) => {
    setLastSortedColumns(items);
  }, []);

  const onSubmit = useCallback(() => {
    const columns = selectedColumns.sort(
      (a, b) => lastSortedColumns.findIndex((i) => i.id === a) - lastSortedColumns.findIndex((i) => i.id === b)
    );

    setColumns(columns);
    onClose();
  }, [lastSortedColumns, onClose, selectedColumns, setColumns]);

  const renderItem = useCallback(
    (item: TableColumn) => (
      <Controller
        control={control}
        name="columns"
        render={({ field }) => (
          <SortableList.Item id={item.id}>
            <div className="flex h-[40px] w-full touch-none items-center justify-between gap-2 overflow-hidden rounded-lg bg-white py-4 ">
              <CheckboxInput
                id={item.id}
                value={item.id}
                label={item.header}
                checked={selectedColumns.includes(item.id)}
                variant="check"
                onChange={({ target }) => {
                  if (target.checked) {
                    field.onChange([...field.value, target.value]);
                  } else {
                    field.onChange(field.value.filter((value) => value !== target.value));
                  }
                }}
              />
              <SortableList.DragHandle />
            </div>
          </SortableList.Item>
        )}
      />
    ),
    [control, selectedColumns]
  );

  return (
    <Drawer open={open} onOpenChange={onOpenChange} header={<Header />} handleOnly>
      <Button
        onClick={() => onOpenChange(true)}
        className="hidden justify-start sm:inline-flex sm:px-2 md:[&>span]:hidden"
        variant="tertiary"
        startIcon="Setting4"
      >
        Customize Columns
      </Button>

      <div className="contents">
        <form id="customize-columns-form" onSubmit={handleSubmit(onSubmit)} className="mt-2">
          <Typography>Job Information</Typography>
          <div className="mt-2 flex flex-col gap-2 ">
            <CheckboxInput
              label="Select all"
              variant="check"
              onChange={handleSelectAllChange}
              checked={selectedColumns.length === selectableColumns.length}
            />
            <SortableList items={lastSortedColumns} onChange={handleSortChange} renderItem={renderItem} />
          </div>
        </form>
      </div>
    </Drawer>
  );
};

const Header = () => {
  return (
    <div className="flex justify-between">
      <Typography className="flex items-center gap-1">
        Customize Columns
        <Tooltip
          content="Select the columns you want to display on your booking dashboard and then drag and move them into the order you want them displayed."
          placement="bottom"
          maxWidth={347}
        >
          <Icon name="InfoCircle" size="md" variant="Bold" className="inline-block rotate-180 text-neutral-dark-gray" />
        </Tooltip>
      </Typography>

      <Button type="submit" variant="tertiary" form="customize-columns-form" className="translate-x-2 text-info">
        Apply
      </Button>
    </div>
  );
};
