import { useFormContext } from "react-hook-form";
import { ErrorMessage, RadioInput, Typography } from "@/components/atoms";
import { useSiteConfig } from "@/hooks";
import { clsx } from "@/utils";
import { AddTransferTypeFormData } from "../form";

export const TransferTypeTripTypeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isMultiTenant } = useSiteConfig();
  const {
    register,
    formState: { errors },
  } = useFormContext<AddTransferTypeFormData>();

  return (
    <div className={clsx("flex flex-col gap-y-5", className)} {...props}>
      <Typography variant="action">Select Trip Type</Typography>
      <div className="flex space-x-8">
        <RadioInput label="Standard" value="journey_rate" {...register("tripType")} />
        <RadioInput label="Hourly" value="hourly_rate" {...register("tripType")} />
        <RadioInput label="Daily" value="daily_rate" {...register("tripType")} />
        <RadioInput label="Fixed" value="fixed_rate" {...register("tripType")} />
        {!isMultiTenant && <RadioInput label="Fixed International" value="fixed_rate_international" {...register("tripType")} />}
      </div>
      <ErrorMessage errors={errors} name="name" />
    </div>
  );
};
