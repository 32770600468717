import { CostExtrasItemType } from "@/redux/slices/pricing/types";
import { GetCostExtrasRawResponse, GetCostExtrasResponse } from "./types";

export const transformCostExtras = (res: GetCostExtrasRawResponse) => {
  const {
    _embedded: { fixed_cost_extras: extras },
    page,
    page_count,
    page_size,
    total_items,
    _links,
  } = res;

  const response: GetCostExtrasResponse = {
    data: {
      items: extras.map((i) => ({
        id: i.uuid,
        active: i.is_active,
        description: i.description,
        icon: i.icon,
        name: i.name,
        price: i.cost,
        type: i.type as CostExtrasItemType,
        universal: false,
        order: i.order_score,
        //    todo: tooltip, universal
      })),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
    links: _links,
  };

  return response;
};
