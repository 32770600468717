import { useFormContext } from "react-hook-form";
import { AddOnTextInput, ErrorMessage, Icon, Tooltip, Typography } from "@/components/atoms";
import { clsx, getCurrencySymbol } from "@/utils";
import { AddVehicleCategoryFormData } from "../form";

interface VehicleCategoryCostFieldsProps extends React.HTMLAttributes<HTMLDivElement> {
  currency: string;
}

export const VehicleCategoryCostFields = ({ currency, className, ...props }: VehicleCategoryCostFieldsProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  return (
    <div className={clsx("flex w-full gap-5", className)} {...props}>
      <div className="flex w-full flex-col gap-y-1">
        <Typography variant="paragraph">Waiting Time /minute</Typography>

        <AddOnTextInput
          className="[&>div]:w-full"
          type="number"
          placeholder="0"
          step="0.01"
          startAddOn={`${currency} ${getCurrencySymbol(currency)}`}
          hasError={!!errors.waitTimeCost}
          {...register("waitTimeCost", {
            valueAsNumber: true,
          })}
        />
        <ErrorMessage errors={errors} name="waitTimeCost" />
      </div>
      <div className="flex w-full flex-col gap-y-1">
        <Typography variant="paragraph" className="flex items-center gap-2">
          Cost Adjustment
          <Tooltip content="The base trip cost will increase by the percentage you’ve entered" placement="right">
            <span>
              <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="md" />
            </span>
          </Tooltip>
        </Typography>
        <AddOnTextInput
          className="[&>div]:w-full"
          type="number"
          placeholder="0"
          endAddOn="%"
          step="0.01"
          hasError={!!errors.costAdjustment}
          {...register("costAdjustment", {
            valueAsNumber: true,
          })}
        />
        <ErrorMessage errors={errors} name="costAdjustment" />
      </div>
    </div>
  );
};
