import { DataTable } from "@/components/molecules";
import { useProfile } from "@/hooks";
import { TimeAndDayMultiplier } from "@/redux/slices/pricing/types";
import { clsx } from "@/utils";
import { useMemo } from "react";
import { generateColumns, generateTableData } from "./helpers";

interface TimeAndDayTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: TimeAndDayMultiplier[];
}

export const TimeAndDayTable = ({ items, className, ...props }: TimeAndDayTableProps) => {
  const { dateFormat, timeFormat } = useProfile();
  const data = useMemo(
    () => generateTableData(items, dateFormat, timeFormat),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateColumns(data), [data]);

  return <DataTable className={clsx("[&_table]:table-auto overflow-x-auto w-full", className)} columns={columns} data={data} {...props} />;
};
