import { SearchVehicleMakeRawResponse, SearchVehicleModelRawResponse, SearchVehicleModelResponse } from "./types";

export const transformVehicleModel = (res: SearchVehicleModelRawResponse) => {
  const {
    _embedded: { model: models },
  } = res;

  const response: SearchVehicleModelResponse = {
    models: models.map((i) => ({
      id: i.uuid,
      make: i.make,
      model: i.model,
      avatar: i.avatar,
      serviceLevel: i.service_level,
      type: i.type,
      class: i.class,
      engine: i.engine,
      maxCapacity: i.max_capacity,
      vehicleType: {
        id: i.vehicle_type.uuid,
        name: i.vehicle_type.name,
        isGlobal: i.vehicle_type.is_global,
      },
    })),
  };

  return response;
};

export const transformVehicleMake = (res: SearchVehicleMakeRawResponse) => {
  const {
    _embedded: { make: makes },
  } = res;

  return { makes };
};
