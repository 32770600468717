import { useDebouncedEffect } from "@react-hookz/web";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, DropdownSelectOption, ErrorMessage, Typography } from "@/components/atoms";
import { useLazySearchCompanyQuery } from "@/redux/apis/typeahead/typeaheadApi";
import { ExportReportFormData } from "../types";

export const AssignedCompanyNameField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const [searchCompany, { isFetching, data: companies }] = useLazySearchCompanyQuery();
  const {
    control,
    formState: { errors },
  } = useFormContext<ExportReportFormData>();
  const [query, setQuery] = useState("");

  useDebouncedEffect(
    () => {
      if (query.length > 2) searchCompany({ q: query });
    },
    [query, searchCompany],
    500
  );

  const options: DropdownSelectOption[] = companies
    ? companies.map((i) => ({
        value: i.id,
        name: i.name,
      }))
    : [];

  return (
    <div className={className} {...props}>
      <Typography variant="paragraph">Assigned Company Name</Typography>
      <Controller
        control={control}
        name="assignedCompanyName"
        render={({ field }) => (
          <Autocomplete
            placeholder="Select Company Name"
            options={options}
            isLoading={isFetching}
            value={options.find((i) => i.value === field.value) ?? null}
            onValueChange={(option) => field.onChange(option?.value ?? "")}
            onQueryChange={setQuery}
            hasError={!!errors.assignedCompanyName}
            className="max-w-md"
            size="md"
          />
        )}
      />
      <ErrorMessage errors={errors} name="assignedCompanyName" />
    </div>
  );
};
