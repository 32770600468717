import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button, IconButton, Modal, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateBookingMutation } from "@/redux/apis/booking/bookingApi";
import { addNotification, addToast } from "@/utils";
import { useSelectedBooking } from "../hooks/useSelectedBooking";

interface ExternalSupplierNotesProps {
  open: boolean;
  onClose: () => void;
}

const schema = z.object({
  externalSupplierNotes: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

export const ExternalSupplierNotesEdit = ({ open, onClose }: ExternalSupplierNotesProps) => {
  const {
    id: bookingId,
    notes: { external },
  } = useSelectedBooking();
  const [updateBooking, { isLoading: isSaving }] = useUpdateBookingMutation();

  const form = useForm<{ externalSupplierNotes?: string }>({
    defaultValues: {
      externalSupplierNotes: external,
    },
  });

  const {
    formState: { isDirty },
    register,
    handleSubmit,
  } = form;

  const onSubmit = async (data: FormData) =>
    updateBooking({
      bookingId,
      external_note: data.externalSupplierNotes,
    })
      .unwrap()
      .then(() => {
        addNotification("success", "Notes Updated", "External supplier notes updated successfully");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));

  return (
    <Modal open={open} onClose={onClose} className="w-[550px]">
      <header className="flex">
        <Typography variant="title" className="flex-1">
          External Supplier Notes
        </Typography>
        <IconButton iconName="close" iconSize="lg" isCustomIcon variant="custom" onClick={onClose} />
      </header>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
        <div className="grid items-center gap-8">
          <TextArea
            {...register("externalSupplierNotes")}
            className="h-[205px]"
            placeholder="External supplier notes"
            autoFocus
            maxLength={2048}
          />
          <Button type="submit" disabled={!isDirty || isSaving} className="w-full" variant="primary" size="lg">
            Update Notes
          </Button>
        </div>
      </form>
    </Modal>
  );
};
