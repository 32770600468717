import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithProvider } from "@/redux/baseQuery";
import { createSearchParams } from "@/utils";
import { transformClient, transformCompany, transformDriver, transformVehicle } from "./helpers";
import {
  SearchClientParams,
  SearchClientResponse,
  SearchCompanyParams,
  SearchCompanyResponse,
  SearchDriverParams,
  SearchDriverResponse,
  SearchVehicleParams,
  SearchVehicleResponse,
} from "./types";

export const typeaheadApi = createApi({
  reducerPath: "typeaheadApi",
  baseQuery: baseQueryWithProvider("typeahead"),
  endpoints: (builder) => ({
    searchClient: builder.query<SearchClientResponse, SearchClientParams>({
      query: ({ q, page_size = 4 }) => `/client?${createSearchParams({ q, page_size }).toString()}`,
      transformResponse: transformClient,
    }),
    searchCompany: builder.query<SearchCompanyResponse, SearchCompanyParams>({
      query: ({ q }) => `/company?${createSearchParams({ q }).toString()}`,
      transformResponse: transformCompany,
    }),
    searchDriver: builder.query<SearchDriverResponse, SearchDriverParams>({
      query: ({ q, job_uuid }) => `/driver?${createSearchParams({ q, job_uuid }).toString()}`,
      transformResponse: transformDriver,
    }),
    searchVehicle: builder.query<SearchVehicleResponse, SearchVehicleParams>({
      query: ({ q, job_uuid, page_size = 99 }) => `/vehicle?${createSearchParams({ q, job_uuid, page_size }).toString()}`,
      transformResponse: transformVehicle,
    }),
  }),
});

export const { useLazySearchClientQuery, useLazySearchCompanyQuery, useLazySearchDriverQuery, useLazySearchVehicleQuery } = typeaheadApi;
