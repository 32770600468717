import { isEmpty } from "ramda";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { PageHeader, TransferTypesTable } from "@/components/organisms";
import { usePagination } from "@/hooks/usePagination";
import { useLazyGetTransferTypesQuery } from "@/redux/apis/config/pricing/transferTypes";
import { useAppSelector } from "@/redux/hooks";
import { transferTypesPageSelector, transferTypesSelector } from "@/redux/slices/pricing/selectors";
import { addToast } from "@/utils";
import { TransferTypesEditLegacy } from "./TransferTypesEditLegacy";

export const TransferTypesLegacy = () => {
  const items = useAppSelector(transferTypesSelector);
  const navigate = useNavigate();
  const [getTransferTypes, { isFetching }] = useLazyGetTransferTypesQuery();
  const { current, count, total, size } = useAppSelector(transferTypesPageSelector);
  const { page, pageSize, handlePageClick, handleSizeChange } = usePagination();

  useEffect(() => {
    getTransferTypes({ page, page_size: pageSize })
      .unwrap()
      .catch((e) => {
        const message = e.data?.detail;
        addToast("danger", message ? message : "Something went wrong.");
      });
  }, [page, pageSize, getTransferTypes]);

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <Panel>
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Transfer Type (Multiplier)
            </Typography>
            <Typography className="text-neutral-dark-gray">Configure the Transfer Type Multiplier for each trip.</Typography>
          </div>
          <div className="flex items-center">
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {isEmpty(items) ? (
          <EmptyState title="Transfer Type" description="There are currently no transfer types available" />
        ) : (
          <TransferTypesTable className="mt-4" items={items} />
        )}
      </Panel>
      <Pagination
        onPageClick={handlePageClick}
        onSizeChange={handleSizeChange}
        currentPage={current}
        pageSize={size}
        totalItems={total}
        pageCount={count}
      />
    </>
  );
};

// eslint-disable-next-line react/display-name
TransferTypesLegacy.Add = () => null;
TransferTypesLegacy.Edit = TransferTypesEditLegacy;
