import { format, parseISO } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, PDFViewer, Panel, Typography, SidePanel, Skeleton, Loading } from "@/components/atoms";
import { ActionList } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useAppSelector } from "@/redux/hooks";
import { addToast } from "@/utils";
import { TermsAndConditionsHistory } from "./TermsAndConditionsHistory";
import { useGetTermsAndConditionsQuery } from "@/redux/apis/config/termsAndConditions";
import { documentsSelector } from "@/redux/slices/documents/selectors";

export const TermsAndConditions = () => {
  const navigate = useNavigate();
  const { isFetching, refetch } = useGetTermsAndConditionsQuery();
  const [showHistory, setShowHistory] = useState(false);
  const { termsAndConditions } = useAppSelector(documentsSelector);

  const handleDownloadClick = () => {
    refetch()
      .unwrap()
      .then((res) => {
        if (res.data.termsAndConditions.length > 0) window.open(res.data.termsAndConditions[0].url, "_blank");
      })
      .catch((e) => addToast("danger", e.message));
  };

  const handleHistoryClick = () => setShowHistory(true);

  const handleViewClick = (id: string) => navigate(id);

  if (isFetching && termsAndConditions.length < 1) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions />
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3">
              Terms and Conditions - Current
            </Typography>
          </div>
          <div className="flex">
            <Button variant="tertiary" startIcon="ImportCurve" className="relative ml-2 text-info" onClick={handleDownloadClick}>
              {isFetching && <Loading />}
              Download PDF
            </Button>
            <Button variant="tertiary" startIcon="Refresh" className="ml-2 text-info" onClick={handleHistoryClick}>
              T&C History
            </Button>
          </div>
        </div>
        <div className="min-h-[500px]">
          <PDFViewer src={termsAndConditions[0].url} className="mt-4 min-h-[70vh] w-full" />
        </div>
      </Panel>
      <SidePanel
        title="Terms and Conditions History"
        description="History logs for all the Terms and Conditions"
        onClose={() => setShowHistory(false)}
        open={showHistory}
      >
        <ActionList>
          {termsAndConditions.map((i) => (
            <ActionList.Item
              key={i.id}
              title={format(parseISO(i.createdAt), "LLLL dd, yyyy")}
              description={i.title}
              onClick={() => handleViewClick(i.id)}
              action={
                <Button variant="tertiary" className="!bg-transparent !text-info">
                  View
                </Button>
              }
            />
          ))}
        </ActionList>
      </SidePanel>
    </>
  );
};

TermsAndConditions.History = TermsAndConditionsHistory;
