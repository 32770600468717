import { CheckboxInput, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { VehicleCategory } from "@/redux/slices/vehicle/types";
import { capitalize } from "@/utils";
import { AssociatedCategoriesTableData } from "./types";

export const generateAssociatedCategoriesColumns = (
  onCheckAllChanged: (checked: boolean) => void,
  allChecked: boolean
): DataTableColumnDef<AssociatedCategoriesTableData>[] => {
  return [
    {
      header: () => (
        <CheckboxInput
          className="[&_.typography]:text-sm [&_.typography]:font-semibold"
          size="sm"
          label="Name"
          checked={allChecked}
          onChange={(e) => onCheckAllChanged(e.target.checked)}
          variant="check"
        />
      ),
      accessorKey: "name",
      cell: (props) => props.row.original.name,
      enableSorting: false,
      size: 300,
    },
    {
      header: "Vehicle Category",
      accessorKey: "vehicleCategory",
      cell: (props) => props.row.original.vehicleCategory,
      size: 200,
      enableSorting: false,
    },
    {
      header: "Capacity",
      accessorKey: "capacity",
      cell: (props) => props.row.original.capacity,
      size: 100,
      enableSorting: false,
    },
    {
      header: "No. of Bags",
      accessorKey: "numBags",
      cell: (props) => props.row.original.numBags,
      size: 100,
      enableSorting: false,
    },
    {
      header: "Cost Adjustment",
      accessorKey: "costAdjustment",
      cell: (props) => props.row.original.costAdjustment,
      size: 150,
      enableSorting: false,
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (props) => props.row.original.status,
      size: 100,
      enableSorting: false,
    },
  ];
};

export const generateAssociatedCategoriesTableData = (
  categories: VehicleCategory[],
  checkedIds: string[],
  onCheckChanged: (categoryId: string) => void
): AssociatedCategoriesTableData[] => {
  return categories.map((category) => ({
    name: (
      <CheckboxInput
        id={`${category.uuid}`}
        label={`${category.name}`}
        checked={checkedIds.includes(category.uuid)}
        onClick={() => onCheckChanged(category.uuid)}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onChange={(e) => {}} // to make ts happy
        variant="check"
        size="sm"
      />
    ),
    vehicleCategory: `${capitalize(category.dispatchRestrictions.vehicleClass.toLowerCase())} ${capitalize(
      category.dispatchRestrictions.vehicleType.toLowerCase()
    )}`,
    capacity: category.vehicleCapacity.pax.toString(),
    numBags: category.vehicleCapacity.bags.toString(),
    costAdjustment: `${category.waitTimeCost > 0 ? "+" : ""}${category.waitTimeCost / 100}%`,
    status: (
      <div className="flex items-center gap-2">
        <span className={`h-2 w-2 shrink-0 rounded-full  ${category.isActive ? "bg-success" : "bg-danger"}`} />
        <Typography className="text-neutral-black">{category.isActive ? "Active" : "Inactive"}</Typography>
      </div>
    ),
  }));
};
