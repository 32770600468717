import { useEffect, useMemo, useState } from "react";
import { fetchDirections } from "@/lib/mapbox";
import { BookingTripLog } from "@/redux/slices/booking/types";
import { getAllWayPoints, getDriverOnWayPoints, getCommencedWaypoints } from "../helpers";

export const useBookingTripPaths = (tripLog: BookingTripLog) => {
  const [preTrip, setPreTrip] = useState("");
  const [driverOnWay, setDriverOnWay] = useState("");
  const [commenced, setCommenced] = useState("");
  const waypoints = useMemo(() => getAllWayPoints(tripLog), [tripLog]);

  useEffect(() => {
    switch (tripLog.job.status) {
      case "on_way_to_job":
        if (tripLog.driverCurrentLocationData && tripLog.bookingStops.pickupLocationData) {
          const onWaypoints = getDriverOnWayPoints(tripLog);

          if (onWaypoints)
            fetchDirections(onWaypoints).then((data) => {
              if (!data) return;
              setDriverOnWay(data.routes[0].geometry);
            });
        }
        break;

      case "commenced":
        if (tripLog.driverCurrentLocationData) {
          const nextStopWaypoints = getCommencedWaypoints(tripLog);
          if (nextStopWaypoints && nextStopWaypoints.length > 1) {
            fetchDirections(nextStopWaypoints).then((data) => {
              if (!data) return;
              setCommenced(data.routes[0].geometry);
            });
          }
        }
        break;

      case "awaiting_driver_allocation":
      case "driver_allocated_confirmed":
      case "driver_allocated_not_confirmed":
      default:
        if (waypoints)
          fetchDirections(waypoints).then((data) => {
            if (!data) return;
            setPreTrip(data.routes[0].geometry);
          });
        break;
    }
  }, [tripLog, tripLog.bookingStops.pickupLocationData, tripLog.driverCurrentLocationData, tripLog.job.status, waypoints]);

  return {
    preTrip,
    onRoute: tripLog.polylineEncodedPaths.onRoute,
    onTrip: tripLog.polylineEncodedPaths.onTrip,
    driverOnWay,
    commenced,
  };
};
