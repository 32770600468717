import { Link } from "react-router-dom";
import { Avatar, DropdownMenu, Icon, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useAppDispatch, useAppResetApi } from "@/redux/hooks";
import { logOut } from "@/redux/slices/auth/authSlice";
import { getAssetUrl } from "@/utils";

export const HeaderProfileMenu = () => {
  const resetApi = useAppResetApi();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    resetApi();
  };

  return (
    <DropdownMenu button={<HeaderProfile />} position="bottom-start">
      <Link to="profile">
        <DropdownMenu.Item className="flex flex-row items-center gap-x-2.5 w-48">
          <Icon name="Profile" size="sm" />
          <Typography>Your Profile</Typography>
        </DropdownMenu.Item>
      </Link>
      <DropdownMenu.Item className="flex flex-row items-center gap-x-2.5 w-48" onClick={handleLogout}>
        <Icon name="LogoutCurve" size="sm" className="rotate-180" />
        <Typography>Logout</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export const HeaderProfile = () => {
  const profile = useProfile();

  const { avatar, displayName } = profile;
  const avatarUrl = getAssetUrl(avatar, { height: 120, width: 120, fit: "cover" });

  return (
    <div className="flex items-center flex-shrink-0 cursor-pointer p-1 gap-x-3 hover:bg-neutral-mid-gray hover:rounded" data-testid="header-profile">
      <Avatar src={avatarUrl} alt={displayName} size="sm" />
      <Typography variant="action" className="hidden sm:block">
        {displayName}
      </Typography>
    </div>
  );
};