import { adminApi } from "../adminApi";
import { transformSMSVoiceNotifications, transformUpdateSMSVoiceNotifications } from "./helpers";
import { GetSMSVoiceNotificationsResponse, UpdateSMSVoiceNotificationsParams, UpdateSMSVoiceNotificationsResponse } from "./types";

export const smsVoiceNotificationsApi = adminApi.enhanceEndpoints({ addTagTypes: ["SMSVoiceNotifications"] }).injectEndpoints({
  endpoints: (builder) => ({
    getSMSVoiceNotifications: builder.query<GetSMSVoiceNotificationsResponse, void>({
      query: () => `/sms-voice-notifications`,
      transformResponse: transformSMSVoiceNotifications,
      providesTags: ["SMSVoiceNotifications"],
    }),
    updateSMSVoiceNotifications: builder.mutation<UpdateSMSVoiceNotificationsResponse, UpdateSMSVoiceNotificationsParams>({
      query: (body) => ({
        url: `/sms-voice-notifications`,
        method: "PUT",
        body: body,
      }),
      transformResponse: transformUpdateSMSVoiceNotifications,
      invalidatesTags: ["SMSVoiceNotifications"],
    }),
  }),
});

export const { useGetSMSVoiceNotificationsQuery, useUpdateSMSVoiceNotificationsMutation } = smsVoiceNotificationsApi;
